import * as yup from "yup";
import { createUseStyles } from 'react-jss';
import { useFormik } from 'formik';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { BUSINESS_CLASSIFICATION, BUSINESS_PROFILE_SECTIONS, REGEX } from '../../../utils/constant';
import TextFieldV2 from '../../atoms/TextFieldV2';
import SelectV2 from '../../atoms/SelectV2';
import ButtonV2 from '../../atoms/ButtonV2';
import { IBusinessProfile } from "../../pages/CustomerManagement/ProfileOverview.page";

const useStyles = createUseStyles((theme: any) => ({
    title: {
        color: theme.palette.text.secondaryDark,
    },
    container: {
        border: `1px solid ${theme.palette.border.primarylightAccent}`,
        borderRadius: "8px",
    },
    detailsContainer: {
        "& span": {
            color: theme.palette.text.primaryLight,
        },
        "& p": {
            color: theme.palette.text.primaryLight,
        }
    }
}));

export interface IUdhyamRequestBody {
    businessType: string;
    udhyamNumber: number | string;
}

interface IUdhyamUpdateTemplateProps {
    updateBusinessProfileSection: (section: string, values: any) => void;
    businessProfile: IBusinessProfile | null;
    setIsEditing: (value: boolean) => void;
}

const UdhyamUpdateTemplate: React.FC<IUdhyamUpdateTemplateProps> = ({ updateBusinessProfileSection, businessProfile, setIsEditing }) => {

    const classes = useStyles();
    const { showSnackbar } = useSnackbar();

    const udhyamSchema = yup.object().shape({
        businessType: yup.string().required('Business Classification is required'),
        udhyamNumber: yup.string().matches(REGEX.UDHYAM, 'Enter a Valid Udhyam Number eg.1234567A1124').required('Udhyam Number is required'),
    });

    const udhyamFormik = useFormik<IUdhyamRequestBody>({
        initialValues: {
            businessType: businessProfile?.udhyam?.businessType || '',
            udhyamNumber: businessProfile?.udhyam?.udhyamNumber || '',
        },
        validationSchema: udhyamSchema,
        onSubmit: async (values: any, { setSubmitting }) => {
            try {
                udhyamFormik.setSubmitting(true);
                const requestBody = {
                    businessType: values?.businessType,
                    udhyamNumber: values?.udhyamNumber,
                };
                await updateBusinessProfileSection(BUSINESS_PROFILE_SECTIONS.UDHYAM, requestBody);
                setIsEditing(false);
            } catch (error) {
                showSnackbar("error", `Business profile update failed: ${error}`);
            } finally {
                setSubmitting(false);
            }
        },
    });

    return (
        <form onSubmit={udhyamFormik.handleSubmit}>
            <div className={`${classes.container} p-6 rounded-lg w-full`}>
                <div className='flex justify-between items-center pb-6 border-b border-grey-500'>
                    <h2 className={`${classes.title} text-2xl font-medium`}>Udhyam Details</h2>
                    <div className='flex gap-4'>
                        <ButtonV2 variant={'tertiaryContained'} label={'Back'} type="button" onClick={() => setIsEditing(false)} />
                        <ButtonV2 variant={'primaryContained'} label={'Save'} type="submit" />
                    </div>
                </div>
                <div className='flex flex-col gap-6 pt-6 w-1/2'>
                    <div className={classes.detailsContainer}>
                        <SelectV2
                            variant={'outlined'}
                            label='Business Classification'
                            fullWidth
                            placeholder="Select Classification"
                            {...udhyamFormik.getFieldProps("businessType")}
                            error={udhyamFormik.touched?.businessType &&
                                Boolean(udhyamFormik.errors?.businessType)}
                            options={BUSINESS_CLASSIFICATION?.map((businessType) => ({
                                value: businessType.id,
                                label: businessType.name,
                            })) || []} />
                    </div>
                    <div className={classes.detailsContainer}>
                        <TextFieldV2
                            label="Enter the udhyam number to fill out the details below"
                            fullWidth
                            placeholder="Enter Udhyam number"
                            {...udhyamFormik.getFieldProps("udhyamNumber")}
                            error={
                                udhyamFormik.touched?.udhyamNumber &&
                                Boolean(udhyamFormik.errors?.udhyamNumber)
                            }
                            helperText={(udhyamFormik.touched?.udhyamNumber &&
                                Boolean(udhyamFormik.errors?.udhyamNumber)) && udhyamFormik.errors?.udhyamNumber}
                            inputProps={{ readOnly: false }}
                        />
                    </div>
                </div>
            </div>
        </form>
    );
};

export default UdhyamUpdateTemplate;
