import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import CancelIcon from '@mui/icons-material/Cancel';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  iconValue: {
    gap: '10px', 
    color: '#3f51b5',
  },
});

const IconValueComponent = ({ status }) => {

  const classes = useStyles();

  const statusValues = {
    "MESSAGE_API_SENT": { value: "Sent", icon: <CheckCircleOutlineIcon sx={{ color: '#9E9E9E' }} /> }, // Gray color
    "MESSAGE_API_DELIVERED": { value: "Delivered", icon: <CheckCircleIcon sx={{ color: '#2196F3' }} /> }, // Blue color
    "MESSAGE_API_READ": { value: "Read", icon: <MarkChatReadIcon sx={{ color: '#4CAF50' }} /> }, // Green color
    "MESSAGE_API_FAILED": { value: "Failed", icon: <CancelIcon sx={{ color: '#F44336' }} /> }, // Red color
    "MESSAGE_API_PROCESSING": { value: "In progress", icon: <PublishedWithChangesIcon sx={{ color: '#FF5722' }} /> }, // Yellow color
  };

  const selectedStatus = statusValues[status] || statusValues["MESSAGE_API_PROCESSING"]; 
  return (
    <div className={classes.iconValue}>
          {selectedStatus.icon}
          <span className='ml-2'>{selectedStatus.value}</span>
      </div>
  );
};

export default IconValueComponent;
