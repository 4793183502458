import React from 'react';
import Chip from '@mui/material/Chip';

export interface ChipItem {
    id: number | string;
    label: string;
}

interface ChipProps {
    values: ChipItem[];
    onDelete: (value: ChipItem) => void;
    disabled?: boolean;
}

const ChipsV2: React.FC<ChipProps> = ({ values, onDelete, disabled }) => {

    const handleDelete = (value: ChipItem) => ()=> 
       !disabled && onDelete(value);
       
    return (
        <div className='flex flex-wrap gap-y-2 gap-x-2'>
            {values?.map((chip) => (
                <Chip
                    key={chip.id}
                    label={chip.label}
                    onDelete={handleDelete(chip)}
                    className='rounded-lg font-medium'
                />
            ))}
        </div>
    );
}

export default ChipsV2;