import { createUseStyles } from "react-jss";
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import TextFieldV2 from "../../atoms/TextFieldV2";
import TableV2, { ColumnType } from "../../organisms/TableV2";
import { useEffect, useState } from "react";
import { groupTypeOptions, industryOptions, initialPaginationValues } from "../../../utils/constant";
import { HTTP_STATUS, IPagination } from "../../../utils/types";
import { useCustomerGroupMasterService } from "../../../services/useCustomerGroupMasterService";
import { ICustomerGroupMaster } from "../../pages/CustomerGroupMaster/CustomerGroupMasterList.page";
import { getLabelByValue } from "../../../utils/helper";


interface CustomerGroupMasterViewTemplateProps {
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
    id: number | null;
}

export interface ICustomerUnderGroup {
    customerId: number;
    customerName: string;
}

export interface ICutomerUnderGroupSearchFilter {
    sort: string;
    customerName: string;
}

const initialCutomerUnderGroupFiltersValues: ICutomerUnderGroupSearchFilter = {
    sort: "createdAt,desc",
    customerName: "",
}

const useStyles = createUseStyles((theme: any) => ({
    dialogContainer: {
        "& .MuiDialog-paper": {
            width: "1028px !important",
            maxWidth: "1028px !important",
            maxHeight: "780px !important",
            borderRadius: "16px"
        },
    },
    mainHeading: {
        color: "#331400"
    },
    closeIcon: {
        color: theme.palette.textV2.tertiary.tertiary700
    },
    container: {
        border: `1px solid #EBEFFA`
    },
    value: {
        color: theme.palette.textV2.tertiary.tertiary900
    },
    descriptionContainer: {
        backgroundColor: "#0a12290d",
    },
    descriptionValue:{
        color: "#0a122999"
    }

}))


const CustomerGroupMasterViewTemplate: React.FC<CustomerGroupMasterViewTemplateProps> = ({ dialogOpen, setDialogOpen, id }) => {

    const classes = useStyles();

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const customerGroupService = useCustomerGroupMasterService()
    const [customerGroupMaster, setCustomerGroupMasterTo] =  useState<ICustomerGroupMaster | null>(null)
	const [customerUnderGroup, setCustomerUnderGroupTo] = useState<ICustomerUnderGroup[]>([])
    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);
    const [cutomerUnderGroupFilters, setCutomerUnderGroupFiltersTo] = useState<ICutomerUnderGroupSearchFilter>(initialCutomerUnderGroupFiltersValues);

    const loadCustomerGroup = async () => {
        if(id){
            try {
                const customerGroupMasterResponse = await customerGroupService.getCustomerGroupById(id);
                if (
                    customerGroupMasterResponse?.status === HTTP_STATUS.OK && customerGroupMasterResponse?.data?.data
                ) {
                    setCustomerGroupMasterTo(customerGroupMasterResponse.data.data);
                } else {
                    throw new Error("Customer Group fetch failed");
                }
            } catch (error) {
                setCustomerGroupMasterTo(null);
            }
        }
    };

    useEffect(() => {
        loadCustomerGroup();
    }, [id]);

    const refreshCustomerUnderGroup = async (page: number, size: number) => {
        if(id){
            try {  
                const customerGroupMasterResponse = await customerGroupService.getCustomerUnderGroupById(id, {
                        page: page,
                        size: size,
                        sort: "createdAt,desc",
                        customerName: cutomerUnderGroupFilters?.customerName.trim(),
                });
                if (
                    customerGroupMasterResponse?.status === HTTP_STATUS.OK &&
                    customerGroupMasterResponse?.data?.data?.content?.length
                ) {
                    setCustomerUnderGroupTo(customerGroupMasterResponse.data.data.content);
                    setPaginationTo((prevPagination: IPagination) => ({
                        ...prevPagination,
                        totalRecords: customerGroupMasterResponse.data.data.totalElements,
                        totalPages: customerGroupMasterResponse.data.data.totalPages,
                    }));
                } else {
                    throw new Error("Customer Group fetch failed");
                }
            } catch (error) {
                setCustomerUnderGroupTo([]);
            }
        }
    };

    useEffect(() => {
        refreshCustomerUnderGroup(pagination.currentPage, pagination.pageSize);
    }, [ pagination.currentPage, pagination.pageSize, id, cutomerUnderGroupFilters?.customerName ]);

    const handleFiltersChange = (event: any) => {
        const { name, value } = event.target;
        setPaginationTo({...pagination, currentPage: 0})
        setCutomerUnderGroupFiltersTo({ ...cutomerUnderGroupFilters, [name]: value ?? "" });
    };


    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage
        }));
    };

	const schema = {
		id: "1",
		title: "",
		pagination: {
			total: pagination?.totalRecords,
			currentPage: pagination?.currentPage,
			isVisible: true,
			limit: pagination?.pageSize,
			handleChangePage: handlePaginationChange,
			handleChangeRowsPerPage: handleRowsPerPageChange,
		},
		columns: [
			{ label: "S.No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
			{ label: "Customer Name", key: "customerName", type: "string" as ColumnType, props: { className: '' } },
			{ label: "Customer ID", key: "customerId", type: "number" as ColumnType, props: { className: '' } },
		],
	};

	const records = customerUnderGroup?.map((customer: ICustomerUnderGroup, index: number) => [
		pagination.currentPage * pagination.pageSize + index + 1,
		customer?.customerName,
		customer?.customerId,
	]);

    return (
        <Dialog fullWidth className={classes.dialogContainer} PaperProps={{ style: { height: 'auto' } }}
            open={dialogOpen} onClose={handleDialogClose} >

            <div className="grid gap-y-8 p-10">
                <div className="grid gap-y-10">
                    <div className="flex justify-between">
                        <div className={`${classes.mainHeading} text-lg font-medium`}>Customer Group</div>
                        <CloseIcon onClick={handleDialogClose}/>
                    </div>
                    <div className={`${classes.container} p-4 grid gap-y-8 rounded-xl`}>
                        <div className="grid gap-y-1">
                            <div className={`${classes.mainHeading} text-sm`}>Group Name</div>
                            <div className={`${classes.value} text-base font-medium`}>{customerGroupMaster?.groupName}</div>
                        </div>
                        <div className={`${classes.descriptionContainer} grid gap-y-1 px-4 py-2 rounded-lg`}>
                            <div className={`${classes.descriptionValue} text-xs`}>About</div>
                            <div className={`${classes.descriptionValue} text-base font-medium`}>{customerGroupMaster?.description}</div>
                        </div>
                        <div className="grid grid-cols-3 gap-x-9">
                            <div className="grid gap-y-1">
                                <div className={`${classes.mainHeading} text-sm`}>Group Type</div>
                                <div className={`${classes.value} text-base font-medium`}>{getLabelByValue(customerGroupMaster?.groupType ?? "", groupTypeOptions)}</div>
                            </div>

                            <div className="grid gap-y-1">
                                <div className={`${classes.mainHeading} text-sm`}>Industry Type</div>
                                <div className={`${classes.value} text-base font-medium`}>{getLabelByValue(customerGroupMaster?.industryType ?? "", industryOptions)}</div>
                            </div>

                            <div className="grid gap-y-1">
                                <div className={`${classes.mainHeading} text-sm`}>Group Owner</div>
                                <div className={`${classes.value} text-base font-medium`}>{customerGroupMaster?.groupOwner}</div>
                            </div>

                        </div>
                    </div>

                </div>
                <div className="grid gap-y-4">
                    <div className={`${classes.mainHeading} text-lg font-medium`}>Customer Under Group</div>
                   <div className="w-80">
                   <TextFieldV2
							className="w-80"
							placeholder="Search by customer name"
							variant="outlined"
							type="text"
							name="customerName"
							value={cutomerUnderGroupFilters.customerName}
							onChange={handleFiltersChange}
						/>
                   </div>

                   <TableV2 schema={schema} records={records} />
                </div>
            </div>
        </Dialog>
    )
}

export default CustomerGroupMasterViewTemplate;