import React, { useState } from 'react'
import SecondaryCatalogueFormTemplate from '../../templates/SecondaryCatalogue/SecondaryCatalogueForm.template'
import { MODE } from '../../../utils/constant'
import { ImageData } from '../../organisms/ImageUploader';

const SecondaryCatalogueCreate: React.FC = () => {
	const [image, setImagesTo] = useState<(ImageData | File)[]>([]);
	
	return (
		<SecondaryCatalogueFormTemplate secondaryCatalogue={null} mode={MODE.ADD} image={image} setImagesTo={setImagesTo} />
	)
}
export default SecondaryCatalogueCreate