import React, { useContext } from 'react';
import ProductDetail from './ProductDetail';
import {
  FormData,
  Procurement,
} from '../../../Constants/Procurement/Indent/types';
import moment from 'moment';
import sanitize from 'sanitize-html';
import { WareHouseContext } from '../../../../contexts/WareHouseDetailContext';
import { PACKAGING_TYPES } from '../../../../utils/constant';

interface CommonProductDetailReviewProps {
  selectedPurchaseRequisition: Procurement[];
  formData: FormData;
  locations?: { id: number; value: string }[];
}
const CommonProductDetailReview: React.FC<CommonProductDetailReviewProps> = ({
  selectedPurchaseRequisition,
  formData,
  locations,
}) => {
  const { warehouseDetails } = useContext(WareHouseContext);
  return (
    <div>
      {/*  details  */}
      <div className="mt-5">
        {formData?.items?.map((procurement, id) => (
          <div className="mb-4" key={id}>
            <ProductDetail productDetail={selectedPurchaseRequisition[id]} />
            <div className="bg-cgray-8 flex  px-6 py-6 mt-4 gap-12">
              <div className=" text-text-color">
                <div className="text-lg">{'Quantity'}</div>
                <div className={'mt-1 font-medium'}>
                  {`${procurement.quantity} MT`}
                </div>
              </div>
              <div className=" text-text-color">
                <div className="text-lg">{'Packaging'}</div>
                <div className={'mt-1 font-medium'}>
                  {
                    PACKAGING_TYPES.filter(
                      (i) =>
                        selectedPurchaseRequisition[id].packagingType ===
                        i.value
                    )[0].label
                  }
                  {}
                </div>
              </div>
              <div className=" text-text-color ">
                <div className="text-lg">{'Maximum Delivery Time'}</div>
                <div className={'mt-1 font-medium'}>
                  {moment
                    .unix(selectedPurchaseRequisition[id].deliveryTime)
                    .format('DD/MM/YYYY')}
                </div>
              </div>
              <div className=" text-text-color ">
                <div className="text-lg">{'Consignee Location'}</div>
                <div className={'mt-1 font-medium'}>
                  {warehouseDetails.find(
                    (warehouse) =>
                      warehouse.id.toString() ==
                      selectedPurchaseRequisition[id]?.warehouseId
                  )?.name || ' '}
                </div>
              </div>
            </div>
            <div className="bg-cgray-8 flex flex-col gap-4 w-full p-6 mt-4">
              <div className="text-lg font-semibold  text-text-color">
                Product Specific Requirements
              </div>
              <div className="font-medium text-text-color w-full">
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitize(selectedPurchaseRequisition[id].remarks),
                  }}
                ></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommonProductDetailReview;
