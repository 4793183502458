import React, { useEffect, useState } from 'react'
import { IBrandFilterRequest, useBrandService } from '../../../../services/useBrandService';
import { IPagination } from '../../../../utils/types';
import BrandListTemplate from '../../../templates/AdminMaster/Brand/BrandList.template';
import { ADMIN_ROUTES, initialPaginationValues } from '../../../../utils/constant';
import TitleHeading from '../../../atoms/CustomHeading/TitleHeading';
import Button from '../../../atoms/Button';
import { useNavigate } from 'react-router-dom';

export interface IBrandFilter {
    manufacturerId: string | number;
    name: string;
    status: string;
    sort: string;
}

/** TODO - move to categories page */
export interface ICategory {
    id: number;
    name: string;
    status: string;
    description: string;
    prefix: string;
    level: number;
    imageId: number;
    createdBy: string;
    updatedBy: string;
    qualityGrades: string;
    standards: string;
    atributes: string;
    parentId: number;
    createdAt: string;
    updatedAt: string;
}

export interface IBrand {
    createdAt: string;
    createdBy: string;
    description: string;
    id: number;
    logoPath: number;
    manufacturerId: number;
    manufacturerName: string;
    name: string;
    prefix: string;
    status: string;
    updatedAt: string;
    updatedBy: string;
    categories?: ICategory[];
}

const initialBrandsFiltersValues: IBrandFilter = {
    manufacturerId: "ALL",
    name: "",
    status: "ALL",
    sort: "createdAt,desc"
}

const BrandListPage: React.FC = () => {

    const [brands, setBrandsTo] = useState<IBrand[]>([]);
    const [brandFilters, setBrandFiltersTo] = useState<IBrandFilter>(initialBrandsFiltersValues);
    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);

    const brandService = useBrandService();

    const getAllBrands = (page: number, size: number) => {
        let params: IBrandFilterRequest = {
            manufacturerId: brandFilters?.manufacturerId === "ALL" ? "" : brandFilters?.manufacturerId,
            status: brandFilters?.status === "ALL" ? "" : brandFilters?.status,
            name: brandFilters?.name.trim(),
            page: page,
            size: size,
            sort: brandFilters?.sort
        }
        brandService.getAllBrands(params)
            .then((brandsResponse) => {
                if (brandsResponse.data.data) {
                    const { totalElements, totalPages } = brandsResponse?.data?.data;
                    setPaginationTo({
                        ...pagination,
                        totalPages: totalPages,
                        totalRecords: totalElements
                    });
                    setBrandsTo(brandsResponse?.data?.data?.content);
                }
                else {
                    setBrandsTo([]);
                }
            })
            .catch((error) => {
                setBrandsTo([]);
                console.error("Brands fetching error - ", error);
            });
    }

    const handleFiltersChange = (event: any) => {
        const { name, value } = event.target;
        setPaginationTo({...pagination, currentPage: 0})
        setBrandFiltersTo({ ...brandFilters, [name]: value ?? "" });
    }
    
    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage
        }));
    };
      

    useEffect(() => {
        getAllBrands(pagination.currentPage, pagination.pageSize);
    }, [pagination.currentPage, pagination.pageSize, brandFilters.manufacturerId, brandFilters.name, brandFilters.status, brandFilters.sort]);

    const navigate = useNavigate();
    
    const handleCreateBrand = () => {
		navigate(`${ ADMIN_ROUTES.BRAND_CREATE }`);
	};

    return (
        <div className="grid gap-y-3">
            <div className="flex justify-between">
                <TitleHeading text="Brand Name" />
                <Button variant="contained" label="Add Brand" onClick={ handleCreateBrand } />
            </div>
            <BrandListTemplate brands={ brands } pagination={ pagination } handlePaginationChange={ handlePaginationChange } handleRowsPerPageChange={ handleRowsPerPageChange } handleFiltersChange={ handleFiltersChange } brandFilters={ brandFilters }/>
        </div>
    )
}

export default BrandListPage;
