import React, { useEffect, useState } from 'react';
import axios from "axios";
import searchIcon from './../../../../Assets/Icons/searchIcon.svg';

import { useNavigate } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';

import { Formik, useFormik } from "formik";
import * as yup from "yup";
import SearchDropDownIndex from './SearchDropDownIndex';
import Drawer from '@mui/material/Drawer';
import StandardSearchList from './StandardsSearchList';
import Chip from '@mui/material/Chip';
import StatusToggle from '../../UPC/StatusToggle/StatusToggle';
import FormGroup from "@mui/material/FormGroup";
import { useAuthenticatedUser } from '../../../../../hooks/useAuthenticatedUser';
import { use } from 'i18next';
import { request } from '../../../../../services';
import { HTTP_STATUS } from '../../../../../utils/types';
import { titleModification } from '../../../../../utils/helper';

const BaseUrl = process.env.REACT_APP_API_V1_URL;
const AddGradeFormik = ({ type = 'ADD', onClose, gradeId = null, updateRecord }) => {
  const { user: authenticatedUser } = useAuthenticatedUser();
  const navigate = useNavigate();

  // Form Data
  const [formData, setFormData] = useState({
    id: null,
    name: '',
    prefix: '',
    standardId: '',
    standardName: '',
    bisHeader: '',
    type: "GRADE",
    // status: 'ACTIVE',
    status: true,
    chemicalComposition: [],
    mechanicalProperties: [],
    tensileProperties: [],
  });
  const [standardError, setStandardError] = useState(false)

  // FORMIK CODE
  var gradeInitialValues = {
    name: '',
    // status: 'ACTIVE',
    status: true,
  };


  // FORMIK CODE 
  const validateFormikFields = yup.object({
    name: yup.string().required("Name is required"),
    status: yup.boolean().required("Status is required")
  });

  var gradeFormik = useFormik({
    initialValues: gradeInitialValues,
    validationSchema: validateFormikFields,


    onSubmit: (values, { setSubmitting, setStatus }) => {
      if (!formData.standardId) {
        setStandardError(true);
        setSubmitting(false)
      } else {
        setSubmitting(true)
        handleCreate(values);
      }
      // Handle form submission here
      // gradeFormik.setFieldValue('standardId', formData.standardId);
    },
  });


  // Form Errors
  const [formErrors, setFormErrors] = useState({
    name: '',
    standardId: null,
  });

  const fetchGradeData = async (id) => {
    try {
      const response = await axios.get(`${BaseUrl}/quality-grade/${id}`, {
        headers: {
          Authorization: `${authenticatedUser.token}`,
          "Content-Type": "application/json",
        }
      }
      );
      const data = response.data.data;

      gradeFormik.setValues({
        name: data.name || '',
        status: data.status === 'ACTIVE' ? true : false,
      });

      var fetchedData = {
        id: data.id,
        prefix: data.prefix,
        standardId: data.standard.id,
        standardName: data.standard.name,
        bisHeader: data.bisHeader,
        type: data.type,
        chemicalComposition: JSON.parse(data.chemicalComposition),
        mechanicalProperties: JSON.parse(data.mechanicalProperties),
        tensileProperties: JSON.parse(data.tensileProperties),
      };
      setFormData(fetchedData);

    } catch (error) {
      console.error('Error fetching grade data:', error);
    }
  };

  // useEffect to fetch formData when gradeId is not null
  useEffect(() => {
    if (gradeId !== null) {
      fetchGradeData(gradeId);
    }
  }, [gradeId]);


  const handleCreate = async (requestPayload) => {
    try {

      const postData = {
        name: requestPayload.name.trim(),
        prefix: formData.prefix,
        standardId: formData.standardId,
        bisHeader: "Bis Header",
        type: formData.type,
        status: requestPayload.status ? 'ACTIVE' : 'INACTIVE',
        chemicalComposition: JSON.stringify(formData.chemicalComposition),
        mechanicalProperties: JSON.stringify(formData.mechanicalProperties),
        tensileProperties: JSON.stringify(formData.tensileProperties),
      };

      // Include 'id' only for "EDIT" type
      if (type === "EDIT") {
        postData.id = formData.id;
      }

      const apiUrl = type === "ADD" ? `${BaseUrl}/quality-grade` : `${BaseUrl}/quality-grade/${gradeId}`;
      const httpMethod = type === "ADD" ? "POST" : "PUT";

      const response = await request(
        httpMethod,
        apiUrl,
        authenticatedUser,
        postData,
        // headers: {
        //   Authorization: `${user.token}`,
        //   "Content-Type": "application/json",
        // }
      );


      // Check if the response status is 200 (success)
      if (response.status === HTTP_STATUS.OK) {
        // You can perform other actions based on the response, such as showing a success message.
        navigate('/grade', {
          state: {
            type: 'Grade'
          }
        });

        if (type === "EDIT") {
          updateRecord(x => x + 1)
          onClose()
        }
      }
      else
        if (response.data.statusMessage === "DUPLICATE_GRADE") {
          alert(`Grade already exists. Please try again`);
        }
        alert(response.data.statusMessage );

    } catch (error) {
      // Handle errors here, such as displaying an error message to the user
      alert(`Something went wrong. Please try again`);
      console.error('Error:', error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };


  const handleFormData = (key, eventOrValue) => {
    const value = eventOrValue.target ? eventOrValue.target.value : eventOrValue;


    setFormData((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const filterUpcCodesFromList = (data) => {
    var mapData = {};
    if (!data || data.length === 0) {
      console.error('Data array is empty or invalid');
      return [];
    }

    const filteredList = [];

    for (const item of data) {
      if (!item.name) {
        console.error('upcCode is missing in an element:', item);
        continue;
      }
      mapData[item.name] = item.id;
      filteredList.push(item.name);
    }
    setStandardmap(mapData)
    return filteredList;
  }
  const [suggestionsList, setSuggestionsList] = useState([]);
  const [standardMap, setStandardmap] = useState({});
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleStandardSelection = (selectedData) => {
    setFormData((prev) => ({
      ...prev,
      standardId: standardMap[selectedData]
    }))
    // setSeletedStandard([selectedData]); 
  };

  const fetchUpcDetails = async (searchUPC) => {
    await axios.get(`${BaseUrl}/standards?search=${searchUPC}&sort=createdAt,desc`, {
      headers: {
        Authorization: `${authenticatedUser.token}`,
        "Content-Type": "application/json",
      }
    }).then(response => {
      setSuggestionsList(filterUpcCodesFromList(response.data.data.content));
    })
      .catch(error => {

      });
  };

  const handleAction = () => {
    if (type === 'ADD') {
      handleCreate(formData);
    } else if (type === 'EDIT') {
      handleCreate(formData);
      return
    }
  }

  const displayTitle = (data) => {
    if (data === "ADD") {
      return "Add"
    } else if (data === "VIEW") {
      return "View"
    } else if (data === "EDIT") {
      return "Edit"
    }
  }

  const disableComponent = (data) => {
    if (data === "VIEW") {
      return true
    } else {
      return false
    }
  }

  const displayNewKeyWord = (data) => {
    if (data === "ADD") {
      return "New"
    }
  }

  const handleReturnToGradeIndex = () => {
    // navigate("/standard/list");
    //old route
    navigate('/grade', {
      state: {
        type: 'Grade'
      }
    });
  };




  // gradeFormik
  // gradeInitialValues


  return (
    <form onSubmit={gradeFormik.handleSubmit} onKeyDown={handleKeyDown}>
      <div
        className={`relative bg-white ${
          type !== "ADD" ? "w-[900px]" : "w-full"
        } flex flex-col items-start justify-start text-left text-[1.25rem] text-blue font-input-label`}
      >
        <div className="self-stretch rounded flex flex-col items-start justify-start">
          <div className="self-stretch flex flex-col items-end justify-start">
            <div className="self-stretch rounded bg-white shadow-[0px_1px_4px_rgba(0,_0,_0,_0.12)] flex flex-col py-[1.5rem] px-[2.5rem] items-start justify-start gap-[0.63rem]">
              <div className="self-stretch flex flex-col items-start justify-start">
                <div className="self-stretch flex flex-col items-start justify-start gap-[1.38rem]">
                  <div className="relative font-semibold text-2xl">
                    {displayTitle(type)} {displayNewKeyWord(type)} Grade
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start text-[1rem] text-text-color">
                    <div className="flex-1 flex flex-row flex-wrap items-start justify-start">
                      <div className="flex-1 flex flex-col items-start justify-start gap-[0.75rem]">
                        {/* CATALOGUE TITLE  */}
                        <TextField
                          className={`h-[3rem] block w-full p-1 border border-gray-500 rounded-md focus:outline-none ring-blue-500 focus:border-blue-500 mb-10`}
                          id="outlined-basic"
                          label="Enter Grade Name"
                          variant="outlined"
                          fullWidth
                          name="name"
                          value={gradeFormik.values.name}
                          onChange={gradeFormik.handleChange}
                          onBlur={(event) => {
                            const camelCasedValue = titleModification(
                              event.target.value
                            );
                            gradeFormik.setFieldValue("name",camelCasedValue);
                            gradeFormik.handleBlur(event);
                          }
                        }
                         
                          error={
                            gradeFormik.touched.name &&
                            Boolean(gradeFormik.errors.name)
                          }
                          // helperText={gradeFormik.touched.name && gradeFormik.errors.name}
                          InputProps={{
                            readOnly: disableComponent(type),
                          }}
                        />

                        {type === "VIEW" ? (
                          // Display TextField for VIEW type
                          <div className="flex flex-row w-full items-center mt-3 mb-3">
                            <TextField
                              className={`h-[3rem] block w-full p-1 border border-gray-500 rounded-md focus:outline-none ring-blue-500 focus:border-blue-500`}
                              id="outlined-basic"
                              label="Associated Standard"
                              variant="outlined"
                              fullWidth
                              name="brandName"
                              value={formData.standardName}
                              InputProps={{
                                readOnly: disableComponent(type),
                              }}
                            />
                            <TextField
                              className={`h-[3rem] block w-full p-1 border border-gray-500 rounded-md focus:outline-none ring-blue-500 focus:border-blue-500`}
                              id="outlined-basic"
                              label="Associated Grade Name Code"
                              variant="outlined"
                              fullWidth
                              name="prefix"
                              value={formData.prefix}
                              InputProps={{
                                readOnly: disableComponent(type),
                              }}
                            />
                          </div>
                        ) : (
                          // Display SearchDropDownIndex for ADD and EDIT types
                          <div className="self-stretch flex flex-col items-start justify-start text-[1rem] text-text-color">
                            {type === "EDIT" ? (
                              <div className="flex flex-row w-full items-center mt-3 mb-2 ml-2">
                                <strong>Associated Standard :</strong>
                                <Chip
                                  label={formData.standardName}
                                  variant="outlined"
                                  style={{ margin: "4px 10px" }}
                                />
                              </div>
                            ) : null}

                            <div className="flex flex-row w-full items-center mt-3 mb-2">
                              <FormControl sx={{ width: "100%" }}>
                                <SearchDropDownIndex
                                  displayLabel={"Select New Standard"}
                                  fetchDetails={fetchUpcDetails}
                                  suggestions={suggestionsList}
                                  updateDetails={handleStandardSelection}
                                  existingUpcCode={formData.standardName}
                                  mode={"EDIT"}
                                  error={standardError}
                                  setError={setStandardError}
                                />
                              </FormControl>
                              <Drawer
                                anchor="right"
                                open={isModalOpen}
                                onClose={toggleModal}
                                sx={{
                                  width: "43%",
                                  flexShrink: 0,
                                  "& .MuiDrawer-paper": {
                                    width: "43%",
                                    boxSizing: "border-box",
                                  },
                                  zIndex: 1100,
                                }}
                              >
                                <StandardSearchList
                                  onClose={toggleModal}
                                  updateStandard={handleFormData}
                                />
                              </Drawer>
                            </div>
                          </div>
                        )}

                        <div className="mt-1 mb-3">
                          <FormGroup>
                            <StatusToggle
                              formData={{ status: gradeFormik.values.status }}
                              handleUpdateFormDetails={(type, value) =>
                                gradeFormik.setFieldValue("status", value)
                              }
                              disabled={disableComponent(type)}
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Display Uploaded Files */}
              <div className="flex justify-end space-x-4 mr-9">
                <button
                  type="button"
                  className="rounded bg-white text-gray-700 border border-gray-300  overflow-hidden flex flex-row py-[0.81rem] px-[1.63rem] items-center justify-center text-[0.94rem]"
                  onClick={type === "ADD" ? handleReturnToGradeIndex : onClose}
                >
                  <div className="relative leading-[1.38rem] font-semibold">
                    Cancel
                  </div>
                </button>

                {type !== "VIEW" && (
                  <button
                    className="outlined rounded bg-blue overflow-hidden flex flex-row py-[0.81rem] px-[1.63rem] items-center justify-center text-[0.94rem] text-white"
                    type="submit"
                  >
                    <div className="relative leading-[1.38rem] font-semibold">
                      {type === "ADD" ? "Add Grade" : "Update Grade"}
                    </div>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddGradeFormik;
