import React, { FC, MouseEvent } from "react";
import Button from "@mui/material/Button";
interface Props {
  name?: string;
  imageSrc: string;
  className?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  isDisabled?: boolean;
}

const ImageButton: FC<Props> = ({
  imageSrc,
  onClick,
  isDisabled,
  name,
  className,
}) => {


  return (
    <button
      name={name}
      disabled={isDisabled}
      onClick={onClick}
      className="lead-management-delete-button flex gap-1"
    >
      <img className={`${className} bg-none`} src={imageSrc} alt="pic" />
    </button>
  );
};

export default ImageButton;
// import React, { FC, MouseEvent } from "react";
// import Button from "@mui/material/Button";

// interface Props {
//   name?: string;
//   imageSrc: string;
//   className?: string;
//   onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
//   isDisabled?: boolean;
// }

// const ImageButton: FC<Props> = ({
//   imageSrc,
//   onClick,
//   isDisabled,
//   name,
//   className,
// }) => {
//   return (
//     <Button
//       name={name}
//       disabled={isDisabled}
//       onClick={onClick}
//       className={`lead-management-delete-button flex gap-1 ${className || ""}`}
//     >
//       <img className="bg-none" src={imageSrc} alt="image" />
//     </Button>
//   );
// };

// export default ImageButton;
