import React from 'react';

import { createUseStyles } from 'react-jss';
import { SelectChangeEvent } from '@mui/material/Select';
import { Field, FieldProps } from 'formik';
import SelectV2 from '../../../atoms/SelectV2';
import { COMMUNICATION_CHANNEL, CONTACT_PREFERENCE, LANGUAGE } from '../../../../utils/constant';


const useStyles = createUseStyles((theme: any) => ({
    title: {
        color: theme.palette.buttonV2.tertiaryContained.background.primaryDark
    },
    select: {
        "& .MuiInputBase-input": {
            padding: "16px",
            fontSize: "16px",
            fontWeight: 500,
            color: theme.palette.textV2.primary.primary900,
        },
        marginTop: "8px !important",
    },
    error: {
        color: 'red',
        fontSize: '13px',
        marginTop: '4px'
    },
    placeholder: {
        color: theme.palette.buttonV2.secondaryText.text.primaryDark,
        fontSize: '16px'
    }
}));

interface CommunicationPreferenceTemplateProps {
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
   formik: any;
}

const CommunicationPreferenceTemplate: React.FC<CommunicationPreferenceTemplateProps> = ({ setFieldValue, formik }) => {
    const classes = useStyles();

    return (
        <div className="flex gap-x-6 mt-6">
            <div className="w-full">
                <div className={`${classes.title} text-base `}>Preferred Communication Channel</div>
                <Field name="communication">
                    {({ field, form }: FieldProps) => (
                        <>
                            <SelectV2
                                {...field}
                                label=""
                                variant='outlined'
                                disableUnderline={true}
                                placeholder="Select Communication Channel"
                                displayEmpty
                                renderValue={field.value ? undefined : () => <span className={classes.placeholder}>Select Preferred Communication Channel</span>}
                                fullWidth
                                onChange={(event: SelectChangeEvent<any>) => setFieldValue("communication", event.target.value)}
                                className={`${classes.select} capitalize`}
                                options={COMMUNICATION_CHANNEL.map((option) => ({
                                    label: option.label,
                                    value: option.value
                                }))}
                            />
                            {formik.errors.communication && formik.touched.communication && (
                                <div className={classes.error}>{formik.errors.communication}</div>
                            )}
                        </>
                    )}
                </Field>
            </div>
            <div className="w-full">
                <div className={`${classes.title} text-base`}>Language Preference</div>
                <Field name="language">
                    {({ field, form }: FieldProps) => (
                        <>
                            <SelectV2
                                {...field}
                                label=""
                                variant='outlined'
                                placeholder="Select Language Preference"
                                disableUnderline={true}
                                displayEmpty
                                renderValue={field.value ? undefined : () => <span className={classes.placeholder}>Select language Preference</span>}
                                fullWidth
                                onChange={(event: SelectChangeEvent<any>) => setFieldValue("language", event.target.value)}
                                className={`${classes.select} capitalize`}
                                options={LANGUAGE.map((option) => ({
                                    label: option.label,
                                    value: option.value
                                }))}
                            />
                            {formik.errors.language && formik.touched.language && (
                                <div className={classes.error}>{formik.errors.language}</div>
                            )}
                        </>
                    )}
                </Field>
            </div>
            <div className="w-full">
                <div className={`${classes.title} text-base`}>Contact Preference</div>
                <Field name="contact">
                    {({ field, form }: FieldProps) => (
                        <>
                            <SelectV2
                                {...field}
                                label=""
                                variant='outlined'
                                disableUnderline={true}
                                placeholder="Select Contact Preference"
                                displayEmpty
                                renderValue={field.value ? undefined : () => <span className={classes.placeholder}>Select Contact Preference</span>}
                                fullWidth
                                onChange={(event: SelectChangeEvent<any>) => setFieldValue("contact", event.target.value)}
                                className={`${classes.select} capitalize`}
                                options={CONTACT_PREFERENCE.map((option) => ({
                                    label: option.label,
                                    value: option.value
                                }))}
                            />
                            {formik.errors.contact && formik.touched.contact  && (
                                <div className={classes.error}>{formik.errors.contact}</div>
                            )}
                        </>
                    )}
                </Field>
            </div>
            
        </div>
    );
};

export default CommunicationPreferenceTemplate;
