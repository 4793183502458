interface Props {
  tab: number;
  setTab: (value: number) => void;
  Tabs: string[];
}

// Todo

const CustomTab = ({ tab, setTab, Tabs }: Props) => {
  return (
    <div className="flex gap-x-5 items-center">
      {Tabs.map((tabName: string, index: number) => (
        <span
          key={tabName}
          onClick={() => setTab(index)}
          className={` cursor-pointer h-fit pb-2  ${
            tab === index
              ? 'border-b-blue border-b-2 text-blue'
              : 'text-text-color'
          }`}
        >
          {tabName}
        </span>
      ))}
    </div>
  );
};

export default CustomTab;
