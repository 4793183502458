import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";

export const SECONDARY_LOTS_URLS = {
  GET_SECONDARY_LOTS_BY_NAME: "/search/secondary-products",
};

interface SecondaryLotsRequest{
  catalogueNames: string[];
}

export const useSecondaryLotsService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const getSecondaryLotsByName = (data: SecondaryLotsRequest) => {
    return request(API_METHOD.POST, SECONDARY_LOTS_URLS.GET_SECONDARY_LOTS_BY_NAME, authenticatedUser, data);
  };
  
  return {
    getSecondaryLotsByName,
  };
};
