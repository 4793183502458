import { DisplayDocuments } from "./FileItem";

const DisplayDocumentStandard = ({ mode, imageData, formImageData, deleteDocumentHandler, handleRemoveImage }) => {
    return (
        <>
            {mode === 'VIEW' && (
                <DisplayDocuments
                    documentsData={imageData}
                    isEdit={false}
                    isForm={false}
                />
            )}

            {mode === 'ADD' && (
                <DisplayDocuments
                    documentsData={imageData}
                    isEdit={true}
                    isForm={true}
                    deleteDocument={handleRemoveImage}
                />
            )}

            {mode === 'EDIT' && (
                <>
                    <DisplayDocuments
                        documentsData={imageData}
                        isEdit={true}
                        isForm={false}
                        deleteDocument={deleteDocumentHandler}
                    />

                    <DisplayDocuments
                        documentsData={formImageData}
                        isEdit={true}
                        isForm={true}
                        deleteDocument={handleRemoveImage}
                    />
                </>
            )}
        </>
    );
}

export default DisplayDocumentStandard;