import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../../../hooks/useSnackBar";
import { IManufacturerRequest, useManufacturerService } from "../../../../services/useManufacturerService";
import { HTTP_STATUS } from "../../../../utils/types";
import { ADMIN_ROUTES, MODE, RESOURCE_TYPE } from "../../../../utils/constant";
import ManufacturerFormTemplate from "../../../templates/AdminMaster/Manufacturer/ManufacturerForm.template";
import { useState } from "react";
import { useFileService } from "../../../../services/useFileService";
import { ImageData } from "../../../organisms/ImageUploader";


const ManufacturerCreatePage: React.FC = () => {

    const navigate = useNavigate();

    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const manufacturerService = useManufacturerService();
    const fileService = useFileService();
    const [image, setImagesTo] = useState<(ImageData | File)[]>([]);
    const [imageIdsToDelete, setImageIdsToDelete] = useState<number[]>([]);


    const createManufacturer = async (manufacturerRequestBody: IManufacturerRequest) => {
        try {
            const manufacturerResponse = await manufacturerService.createManufacturer(manufacturerRequestBody);
            if (manufacturerResponse.status === HTTP_STATUS.OK) {
                const resourceId: number = manufacturerResponse.data.data.id;
                await fileService.uploadImage(image, resourceId, RESOURCE_TYPE.MANUFACTURER);
                navigate(ADMIN_ROUTES.MANUFACTURER_LIST);
            }  
            else if (manufacturerResponse.data.statusMessage === "DUPLICATE_MANUFACTURER") {
                showSnackbar('error', "Manufacturer already exists. Please try again");
            }
        } catch (error) {
            showSnackbar('error', 'Manufacturer Created failed')
        }
    }

    return (
        <div>
            {SnackBarComponent}
            <ManufacturerFormTemplate manufacturer={null} categoryIds={[]} onManufacturerSubmit={createManufacturer} mode={MODE.ADD} image={image} setImagesTo={setImagesTo} imageIdsToDelete={imageIdsToDelete} setImageIdsToDelete = {setImageIdsToDelete}  />
        </div>
    )
}

export default ManufacturerCreatePage;
