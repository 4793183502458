import { Route, Routes } from 'react-router-dom';
import ServiceListPage from '../../../components/pages/VASMaster/Category/ServiceList.page';
import ServiceCreatePage from '../../../components/pages/VASMaster/Category/ServiceCreate.page';
import ServiceUpdatePage from '../../../components/pages/VASMaster/Category/ServiceUpdate.page';
import ServiceViewPage from '../../../components/pages/VASMaster/Category/ServiceView.page';

const VASMasterServicesRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<ServiceListPage />} />
            <Route path="/add" element={<ServiceCreatePage />} />
            <Route path="/:id" element={<ServiceViewPage />} />
            <Route path="/:id/update" element={<ServiceUpdatePage />} />
        </Routes>
    )
}

export default VASMasterServicesRoutes