import { useAuthenticatedUser } from '../hooks/useAuthenticatedUser';
import { API_METHOD } from '../utils/constant';
import { removeEmptyFields, replaceUrlParams } from '../utils/helper';
import { request } from './index';

const VENDOR_APIS = {
  GET_ALL_VENDORS: 'vendors',
  INVITE_VENDORS: 'procurement/indent/:indentId/vendor/invite',
};

export interface FilterType {
  pageSize: number;
  pageNumber: number;
  searchTerm: string;
  locations: string;
  ratings: string;
  vendorGroups: string;
  productCategories: string;
  vendorTypes: string;
}

export const useVendorService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const getVendors = (params: Partial<FilterType>) => {
    params = removeEmptyFields(params);
    return request(
      API_METHOD.GET,
      VENDOR_APIS.GET_ALL_VENDORS,
      authenticatedUser,
      null,
      { params }
    );
  };

  const inviteVendors = (
    indentId: string,
    vendors: { vendorIds: (string | number)[] }
  ) => {
    return request(
      API_METHOD.POST,
      replaceUrlParams(VENDOR_APIS.INVITE_VENDORS, {
        indentId: indentId,
      }),
      authenticatedUser,
      vendors
    );
  };

  return {
    getVendors,
    inviteVendors,
  };
};
