import React from 'react'
import { createUseStyles } from 'react-jss';

import RawMaterialListingTemplate from './RawMaterialListing.template';
import { useNavigate } from 'react-router-dom';
import MaterialOtherPreferencesTemplate from './MaterialOtherPreferences.template';
import MaterialBrandManufacturerTemplate from './MaterialBrandManufacturer.template';
import { MY_MATERIAL_STATE } from '../../../../utils/types';
import Button from '../../../atoms/Button';
import ButtonV2 from '../../../atoms/ButtonV2';
import { ADMIN_ROUTES } from '../../../../utils/constant';
import PreferredProductCategoryTemplate from './PreferredProductCategoy.template';

const useStyles = createUseStyles((theme: any) => ({
  primary500: {
    color: theme.palette.textV2.primary.primary500,
  },
  borderColor: {
    border: `1px solid ${theme.palette.backgroundV2.secondary.secondary50}`
  },
  heading: {
    color: theme.palette.textV2.tertiary.tertiary700
  },
  tertiary100Border: {
    borderColor: theme.palette.textV2.tertiary.tertiary100
  },
  sectionBackground: {
    background: theme.palette.backgroundV2.alphaDark.alphaDark5
  },

}));

interface MainRawMaterialsTemplateProps {
  updateMaterialStateTo: (value: MY_MATERIAL_STATE) => void;
}

const MainRawMaterialsTemplate: React.FC<MainRawMaterialsTemplateProps> = ({ updateMaterialStateTo }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleAddProduct = () => {
    updateMaterialStateTo(MY_MATERIAL_STATE.MY_MATERIAL_CREATE)
  }

  const handleBackToDashboard = () => {
    navigate(ADMIN_ROUTES.CUSTOMER_MANAGEMENT_LIST);
  };

  return (
    <div className='grid gap-6'>
      <div className={`p-4 w-full flex justify-between items-center ${classes.borderColor} rounded-lg`}>
        <span className={`${classes.heading} text-2xl`}>Raw Material Listing</span>
        <ButtonV2 variant='primaryContained' label="+Add New Product" size='large' className='!font-medium' onClick={handleAddProduct} />
      </div>
      <div className={`${classes.tertiary100Border} border-b`}></div>
      <div className={`${classes.sectionBackground} p-4 rounded-lg grid gap-y-6`}>
        <div className={`grid gap-y-6 `}>
          < RawMaterialListingTemplate updateMaterialStateTo={updateMaterialStateTo} />
        </div>
        <div>
          < PreferredProductCategoryTemplate />
        </div>
        <div>
          < MaterialBrandManufacturerTemplate />
        </div>
        <div>
          < MaterialOtherPreferencesTemplate />
        </div>
      </div>
      <div className=' grid justify-items-stretch pt-6'>
        <ButtonV2
          className={` justify-self-end`}
          variant="secondaryContained"
          label="Back to Dashboard"
          onClick={handleBackToDashboard}
        />
      </div>
    </div>
  )
}

export default MainRawMaterialsTemplate