import React, { useEffect, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import ButtonV2 from "../../atoms/ButtonV2";
import { CATEGORY_TYPE, HTTP_STATUS, IPagination } from "../../../utils/types";
import TextFieldV2 from "../../atoms/TextFieldV2";
import { ATTRIBUTES, CLASSES, DEBOUNCE_TIME, SHAPE_TYPES, initialPaginationValues } from "../../../utils/constant";
import { Checkbox, FormControl } from '@mui/material';
import ChipsV2, { ChipItem } from "../../molecules/Chips/ChipsV2";
import TableV2, { ColumnType } from "../../organisms/TableV2";
import { IUpcSearchQueryParams, useCustomerGroupMasterService } from "../../../services/useCustomerGroupMasterService";
import AutoCompleteTextField, { AutoCompleteOption } from '../../molecules/AutoCompleteInput/AutoCompleteInputV2';
import { AttributeSpecification, useCategoryService } from "../../../services/useCategoryService";
import { IBrand, ICategory } from "../../pages/AdminMaster/Brand/BrandList.page";
import { IManufacturer } from "../../pages/AdminMaster/Manufacturer/ManufacturerList.page";
import { Standard, useStandardService } from "../../../services/useStandardService";
import { useManufacturerService } from "../../../services/useManufacturerService";
import { useBrandService } from "../../../services/useBrandService";
import { useQualtiyGradeService } from "../../../services/useQualityGradesService";
import { useAttributeService } from "../../../services/useAttributeService";
import { enumToString, useDebounce } from "../../../utils/helper";

interface UpcMaterialFilterTemplateProps {
    formik: any
}

export interface IUpcsByFilter {
    upcCode: string;
    status: string;
    brand: string;
    upcId: number;
}

export interface IUpcsSearchFilter {
    sort: string;
    upcCode: string;
}

const initialUpcsSearchFiltersValues: IUpcsSearchFilter = {
    sort: "createdAt,desc",
    upcCode: "",
}

interface Attributes {
    Length?: {
        minValue: null;
        maxValue: null;
        values: string[];
    };
    Width?: {
        minValue: null;
        maxValue: null;
        values: string[];
    };
    Thickness?: {
        minValue: null;
        maxValue: null;
        values: string[];
    };
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid #EBEFFA`
    },
    filterContainer: {
        borderTop: `1px solid #EBEFFA`
    },
    filterHeading: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    selectLabel: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    configure: {
        color: theme.palette.textV2.primary.primary500,
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600,
    },
    activeProgressBar: {
        backgroundColor: "#CA4F02"
    },
    inActiveProgressBar: {
        backgroundColor: "#FD6202"
    },
    input: {
        "& .MuiInputBase-root": {
            borderRadius: "8px !important",
        },
    },
    errorMessage: {
        color: theme.palette.textV2.failure.failure600,
    },
}));

const UpcMaterialFilterTemplate: React.FC<UpcMaterialFilterTemplateProps> = ({ formik }) => {
    const classes = useStyles();
    const customerGroupService = useCustomerGroupMasterService();
    const categoryService = useCategoryService();
    const manufacturerService = useManufacturerService();
    const attributeService = useAttributeService();
    const brandService = useBrandService();
    const standardService = useStandardService();
    const qualityGradeService = useQualtiyGradeService();
    const [upcs, setupcsTo] = useState<IUpcsByFilter[]>([]);
    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);
    const [upcsByFilter, setupcsByFilterTo] = useState<IUpcsSearchFilter>(initialUpcsSearchFiltersValues);
    const [superCategoryData, setSuperCategoryData] = useState<ICategory[] | null>([]);
    const [mainCategoryData, setMainCategoryData] = useState<ICategory[] | null>([]);
    const [productCategoryData, setProductCategoryData] = useState<ICategory[] | null>([]);
    const [manufacturerData, setManufacturerData] = useState<IManufacturer[] | null>([]);
    const [brandData, setBrandData] = useState<IBrand[] | null>([]);
    const [standardData, setStandardData] = useState<Standard[] | null>([]);
    const [gradeData, setGradeData] = useState<any[] | null>([]);

    const loadProductCategory = (inputValue?: string) => {
        categoryService.getAllCategories({ level: CATEGORY_TYPE.PRODUCT_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: '', name: inputValue ?? '', status: 'ACTIVE' })
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setProductCategoryData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Category: ", error);
            })
    }

    const loadSuperCategory = (inputValue?: string) => {
        categoryService.getAllCategories({ level: CATEGORY_TYPE.SUPER_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: '', name: inputValue ?? '', status: 'ACTIVE' })
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setSuperCategoryData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Category: ", error);
            })
    }

    const loadMainCategory = (inputValue?: string) => {
        categoryService.getAllCategories({ level: CATEGORY_TYPE.MAIN_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: '', name: inputValue ?? '', status: 'ACTIVE' })
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setMainCategoryData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Category: ", error);
            })
    }

    const loadManufacturers = (inputValue?: string) => {
        manufacturerService.getAllManufacturers({ page: 0, size: 10, sort: 'createdAt,desc', search: inputValue ?? '' })
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setManufacturerData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Category: ", error);
            })
    }

    const loadBrands = (inputValue?: string) => {
        brandService.getAllBrandsByName({ page: 0, size: 10, sort: 'createdAt,desc', name: inputValue ?? '' })
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setBrandData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Category: ", error);
            })
    }

    const loadStandards = (inputValue?: string) => {
        standardService.getAllStandards({ page: 0, size: 10, sort: 'createdAt,desc', search: inputValue ?? '' })
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setStandardData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Category: ", error);
            })
    }

    const loadGrades = (inputValue?: string) => {
        qualityGradeService.getAllQualityGrades({ type: "GRADE", page: 0, size: 10, sort: 'createdAt,desc' })
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setGradeData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Category: ", error);
            })
    }

    useEffect(() => {
        loadMainCategory();
        loadSuperCategory();
        loadProductCategory();
        loadManufacturers();
        loadBrands();
        loadStandards();
        loadGrades();
    }, []);

    const createOptions = (data: any[] | null, keyName: string = "name") => {
        return data?.map((item: any) => ({ label: item[keyName], id: item.id })) ?? [];
    };

    const productCategoryOptions = useMemo(() => createOptions(productCategoryData), [productCategoryData]);

    const superCategoryOptions = useMemo(() => createOptions(superCategoryData), [superCategoryData]);

    const mainCategoryOptions = useMemo(() => createOptions(mainCategoryData), [mainCategoryData]);

    const manufacturerOptions = useMemo(() => createOptions(manufacturerData), [manufacturerData]);

    const brandOptions = useMemo(() => createOptions(brandData), [brandData]);

    const standardOptions = useMemo(() => createOptions(standardData), [standardData]);

    const gradeOptions = useMemo(() => createOptions(gradeData), [gradeData]);

    const [attributeValues, setAttributeValues] = useState<AttributeSpecification[]>([]);

    const loadAttributes = async (attributeType: any) => {
        try {
            const params = {
                attributeType: attributeType
            }
            const response = await attributeService.getAllAttributes(params);
            if (response.status === HTTP_STATUS.OK && response?.data?.data?.content) {
                const attributeValuesArray = response.data.data.content;
                setAttributeValues(attributeValuesArray)
                return attributeValuesArray;
            } else {
                return [];
            }
        } catch (error) {
            console.error("Error fetching attribute values: ", error);
            return [];
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            loadAttributes(ATTRIBUTES.SPECIFICATION)
        }
        fetchData();
    }, []);

    const [filter, setFilter] = useState<IUpcSearchQueryParams>({});

    const refreshUpcs = async (page: number, size: number) => {
        try {
            const upcFilterResponse = await customerGroupService.getUpcByMaterialFilter({
                page: page,
                size: size,
                sort: "createdAt,desc",
                upcCode: upcsByFilter?.upcCode.trim()
            },
                filter
            );
            if (
                upcFilterResponse?.status === HTTP_STATUS.OK &&
                upcFilterResponse?.data?.data?.content?.length
            ) {
                setupcsTo(upcFilterResponse.data.data.content);
                setPaginationTo((prevPagination: IPagination) => ({
                    ...prevPagination,
                    currentPage: page,
                    totalRecords: upcFilterResponse.data.data.totalElements,
                    totalPages: upcFilterResponse.data.data.totalPages,
                }));
            } else {
                throw new Error("Upcs fetch failed");
            }
        } catch (error) {
            setupcsTo([]);
        }
    };

    const handleFiltersChange = (event: any) => {
        const { name, value } = event.target;
        setPaginationTo({ ...pagination, currentPage: 0 })
        setupcsByFilterTo({ ...upcsByFilter, [name]: value ?? "" });
    };

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage
        }));
    };

    useEffect(() => {
        refreshUpcs(pagination.currentPage, pagination.pageSize);
    }, [pagination.currentPage, pagination.pageSize]);

    useEffect(() => {
        refreshUpcs(0, pagination.pageSize);
    }, [filter, upcsByFilter.upcCode]);

    const schema = {
        id: "1",
        title: "",
        pagination: {
            total: pagination?.totalRecords,
            currentPage: pagination?.currentPage,
            isVisible: true,
            limit: pagination?.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange,
        },
        columns: [
            { label: "S.No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "UPC Code", key: "UPC Code", type: "number" as ColumnType, props: { className: '' } },
            { label: "Brand", key: "brand id", type: "string" as ColumnType, props: { className: '' } },
            { label: "Status", key: "status", type: "string" as ColumnType, props: { className: '' } },
            { label: "Select UPC", key: "action", type: "custom" as ColumnType, props: { className: '' } },
        ],
    };

    const handleCheckBoxChange = (upcCode: string, id: number) => () => {
        const isAlreadySelected = formik.values.upcCodes.some(
            (selected: any) => selected.label === upcCode && selected.id === id
        );
        if (isAlreadySelected) {
            const newSelected = formik.values.upcCodes.filter(
                (selected: any) => !(selected.label === upcCode && selected.id === id)
            );
            formik.setFieldValue("upcCodes", newSelected);
        } else {
            formik.setFieldValue("upcCodes", [...formik.values.upcCodes, { label: upcCode, id: id }]);
        }
    };

    const Action = (upcCode: string, id: number) => {
        return (
            <div className={`flex items-center justify-center gap-x-3`}>
                <Checkbox
                    sx={{ padding: 0, color: "#C4C4C4" }}
                    checked={formik.values.upcCodes.some((selected: any) => selected.label === upcCode && selected.id === id)}
                    onChange={handleCheckBoxChange(upcCode, id)}
                />
            </div>
        );
    };

    const records = upcs?.map((upcs: IUpcsByFilter, index: number) => [
        pagination.currentPage * pagination.pageSize + index + 1,
        upcs?.upcCode,
        upcs?.brand,
        enumToString(upcs?.status),
        Action(upcs?.upcCode, upcs?.upcId),
    ]);

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const attributes: Attributes = {};
        if (formik.values.lengthArray?.length > 0) {
            attributes.Length = {
                minValue: null,
                maxValue: null,
                values: formik.values.lengthArray.map((length: any) => length.label)
            };
        }

        if (formik.values.widthArray?.length > 0) {
            attributes.Width = {
                minValue: null,
                maxValue: null,
                values: formik.values.widthArray.map((width: any) => width.label)
            };
        }

        if (formik.values.thicknessArray?.length > 0) {
            attributes.Thickness = {
                minValue: null,
                maxValue: null,
                values: formik.values.thicknessArray.map((thickness: any) => thickness.label)
            };
        }
        setFilter({
            superCategoriesIds: formik.values?.superCategoryArray?.length > 0 ? formik.values.superCategoryArray.map((superCategory: any) => superCategory.id) : null,
            mainCategoriesIds: formik.values?.mainCategoryArray?.length > 0 ? formik.values.mainCategoryArray.map((mainCategory: any) => mainCategory.id) : null,
            productCategoriesIds: formik.values?.productCategoryArray?.length > 0 ? formik.values.productCategoryArray.map((productCategory: any) => productCategory.id) : null,
            standardIds: formik.values?.standardArray?.length > 0 ? formik.values.standardArray.map((standard: any) => standard.id) : null,
            gradeIds: formik.values?.gradeArray?.length > 0 ? formik.values.gradeArray.map((grade: any) => grade.id) : null,
            brandIds: formik.values?.brandArray?.length > 0 ? formik.values.brandArray.map((brand: any) => brand.id) : null,
            shapes: formik.values?.shapeArray?.length > 0 ? formik.values.shapeArray.map((shape: any) => shape.id) : null,
            manufacturerIds: formik.values?.manufacturerArray?.length > 0 ? formik.values.manufacturerArray.map((manufacturer: any) => manufacturer.id) : null,
            classes: formik.values.classesArray?.length > 0 ? formik.values.classesArray.map((classes: any) => classes.id) : ["STANDARD"],
            attributes: Object.keys(attributes).length === 0 ? null : attributes
        })
        formik.setFieldValue("materialFilterFlag", true)

    };

    const updateSelected = (key: string) => (option: AutoCompleteOption | null) => {
        if (option) {
            const filterExists = formik.values[`${key}Array`].some((item: any) => item.id === option?.id);
            if (!filterExists) {
                formik.setFieldValue(`${key}Array`, [...formik.values[`${key}Array`], option]);
            }
            formik.setFieldValue(`${key}Value`, { label: "", id: "" });

        }
    };

    const handleFilterChipDelete = (value: ChipItem, key: string) => {
        formik.setFieldValue(`${key}Array`, formik.values[`${key}Array`].filter((e: any) => e.id !== value.id));
    }

    const handleChipDelete = (value: ChipItem) => {
        formik.setFieldValue("upcCodes", formik.values.upcCodes.filter((upc: any) => upc.id !== value.id));
    }

    const handleReset = () => {
        formik.setValues((prevValues: any) => ({
            ...prevValues,
            materialFilterFlag: false,
            superCategoryArray: [],
            mainCategoryArray: [],
            productCategoryArray: [],
            manufacturerArray: [],
            brandArray: [],
            standardArray: [],
            gradeArray: [],
            shapeArray: [],
            classesArray: [],
            lengthArray: [],
            thicknessArray: [],
            widthArray: [],
        }));
        setFilter({})
    }

    return (
        <div className={`${classes.container} p-6 grid gap-y-5 rounded-lg`}>
            <div className={`${classes.filterHeading} text-base font-medium my-auto`}>If you didn't know UPC, Material filters   </div>

            <div className={`${classes.filterHeading} text-2xl font-medium my-auto`}>Apply material filter</div>

            <div className={`${classes.filterContainer} pt-5`}>
                <div className="grid gap-y-8">
                    <div className="grid gap-y-6">
                        <div className="grid grid-cols-3 gap-x-6 gap-y-6">
                            <div className="grid gap-y-2 h-fit">
                                <FormControl fullWidth className={classes.input}>
                                    <AutoCompleteTextField
                                        options={superCategoryOptions}
                                        label={"Super Category"}
                                        onChange={updateSelected('superCategory')}
                                        onSearch={loadSuperCategory}
                                        isDisabled={false}
                                        value={formik.values.superCategoryValue}
                                        placeHolder="Select Super Category"
                                    />
                                </FormControl>
                                <ChipsV2 values={formik.values.superCategoryArray} onDelete={(value) => handleFilterChipDelete(value, 'superCategory')}
                                />
                            </div>
                            <div className="grid gap-y-2 h-fit">
                                <FormControl fullWidth className={classes.input}>
                                    <AutoCompleteTextField
                                        options={mainCategoryOptions}
                                        label={"Main Category"}
                                        onChange={updateSelected('mainCategory')}
                                        onSearch={loadMainCategory}
                                        isDisabled={false}
                                        value={formik.values.mainCategoryValue}
                                        placeHolder="Select Main Category"
                                    />
                                </FormControl>
                                <ChipsV2 values={formik.values.mainCategoryArray} onDelete={(value) => handleFilterChipDelete(value, 'mainCategory')}
                                />
                            </div>
                            <div className="grid gap-y-2 h-fit">
                                <FormControl fullWidth className={classes.input}>
                                    <AutoCompleteTextField
                                        options={productCategoryOptions}
                                        label={"Product Category"}
                                        onChange={updateSelected('productCategory')}
                                        onSearch={loadProductCategory}
                                        isDisabled={false}
                                        value={formik.values.productCategoryValue}
                                        placeHolder="Select Product Category"
                                    />
                                </FormControl>
                                <ChipsV2 values={formik.values.productCategoryArray} onDelete={(value) => handleFilterChipDelete(value, 'productCategory')}
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-2 gap-x-6 gap-y-6">
                            <div className="grid gap-y-2 h-fit">
                                <FormControl fullWidth className={classes.input}>
                                    <AutoCompleteTextField
                                        options={manufacturerOptions}
                                        label={"Manufacturers"}
                                        onChange={updateSelected('manufacturer')}
                                        onSearch={loadManufacturers}
                                        isDisabled={false}
                                        value={formik.values.manufacturerValue}
                                        placeHolder="Select Manufacturers"
                                    />
                                </FormControl>
                                <ChipsV2 values={formik.values.manufacturerArray} onDelete={(value) => handleFilterChipDelete(value, 'manufacturer')}
                                />
                            </div>
                            <div className="grid gap-y-2 h-fit">
                                <FormControl fullWidth className={classes.input}>
                                    <AutoCompleteTextField
                                        options={brandOptions}
                                        label={"Brands"}
                                        onChange={updateSelected('brand')}
                                        onSearch={loadBrands}
                                        isDisabled={false}
                                        value={formik.values.brandValue}
                                        placeHolder="Select Brands"
                                    />
                                </FormControl>
                                <ChipsV2 values={formik.values.brandArray} onDelete={(value) => handleFilterChipDelete(value, 'brand')}
                                />
                            </div>
                            <div className="grid gap-y-2 h-fit">
                                <FormControl fullWidth className={classes.input}>
                                    <AutoCompleteTextField
                                        options={standardOptions}
                                        label={"Standards"}
                                        onChange={updateSelected('standard')}
                                        onSearch={loadStandards}
                                        isDisabled={false}
                                        value={formik.values.standardValue}
                                        placeHolder="Select Standards"
                                    />
                                </FormControl>
                                <ChipsV2 values={formik.values.standardArray} onDelete={(value) => handleFilterChipDelete(value, 'standard')}
                                />
                            </div>
                            <div className="grid gap-y-2 h-fit">
                                <FormControl fullWidth className={classes.input}>
                                    <AutoCompleteTextField
                                        options={gradeOptions}
                                        label={"Grades"}
                                        onChange={updateSelected('grade')}
                                        onSearch={loadGrades}
                                        isDisabled={false}
                                        value={formik.values.gradeValue}
                                        placeHolder="Select Grades"
                                    />
                                </FormControl>
                                <ChipsV2 values={formik.values.gradeArray} onDelete={(value) => handleFilterChipDelete(value, 'grade')}
                                />
                            </div>
                            <div className="grid gap-y-2 h-fit">
                                <FormControl fullWidth className={classes.input}>
                                    <AutoCompleteTextField
                                        options={SHAPE_TYPES}
                                        label={"Shapes"}
                                        onChange={updateSelected('shape')}
                                        onSearch={() => { }}
                                        isDisabled={false}
                                        value={formik.values.shapeValue}
                                        placeHolder="Select Shapes"
                                    />
                                </FormControl>
                                <ChipsV2 values={formik.values.shapeArray} onDelete={(value) => handleFilterChipDelete(value, 'shape')}
                                />
                            </div>
                            <div className="grid gap-y-2 h-fit">
                                <FormControl fullWidth className={classes.input}>
                                    <AutoCompleteTextField
                                        options={CLASSES}
                                        label={"Class"}
                                        onChange={updateSelected('classes')}
                                        onSearch={() => { }}
                                        isDisabled={false}
                                        value={formik.values.classesValue}
                                        placeHolder="Select Class"
                                    />
                                </FormControl>
                                <ChipsV2 values={formik.values.classesArray} onDelete={(value) => handleFilterChipDelete(value, 'classes')}
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-3 gap-x-6 gap-y-6">
                            <div className="grid gap-y-2 h-fit">
                                <FormControl fullWidth className={classes.input}>
                                    <AutoCompleteTextField
                                        options={(Array.isArray(attributeValues) && attributeValues.length > 0) && JSON.parse(attributeValues?.find(item => item?.name?.toLowerCase().includes("length") ?? "")?.attributeOptions.toString() ?? "").map((option: any, index: any) => (
                                            {
                                                'label': option,
                                                'id': option
                                            }
                                        ))}
                                        label={"Length"}
                                        onChange={updateSelected('length')}
                                        onSearch={() => { }}
                                        isDisabled={false}
                                        value={formik.values.lengthValue}
                                        placeHolder="Select Length"
                                    />
                                </FormControl>
                                <ChipsV2 values={formik.values.lengthArray} onDelete={(value) => handleFilterChipDelete(value, 'length')}
                                />
                            </div>
                            <div className="grid gap-y-2 h-fit">
                                <FormControl fullWidth className={classes.input}>
                                    <AutoCompleteTextField
                                        options={(Array.isArray(attributeValues) && attributeValues.length > 0) && JSON.parse(attributeValues?.find(item => item?.name?.toLowerCase().includes("thickness") ?? "")?.attributeOptions.toString() ?? "").map((option: any, index: any) => (
                                            {
                                                'label': option,
                                                'id': option
                                            }
                                        ))}
                                        label={"Thickness"}
                                        onChange={updateSelected('thickness')}
                                        onSearch={() => { }}
                                        isDisabled={false}
                                        value={formik.values.thicknessValue}
                                        placeHolder="Select Thickness"
                                    />
                                </FormControl>
                                <ChipsV2 values={formik.values.thicknessArray} onDelete={(value) => handleFilterChipDelete(value, 'thickness')}
                                />
                            </div>
                            <div className="grid gap-y-2 h-fit">
                                <FormControl fullWidth className={classes.input}>
                                    <AutoCompleteTextField
                                        options={(Array.isArray(attributeValues) && attributeValues.length > 0) && JSON.parse(attributeValues?.find(item => item?.name?.toLowerCase().includes("width") ?? "")?.attributeOptions.toString() ?? "").map((option: any, index: any) => (
                                            {
                                                'label': option,
                                                'id': option
                                            }
                                        ))}
                                        label={"Width"}
                                        onChange={updateSelected('width')}
                                        onSearch={() => { }}
                                        isDisabled={false}
                                        value={formik.values.widthValue}
                                        placeHolder="Select Width"
                                    />
                                </FormControl>
                                <ChipsV2 values={formik.values.widthArray} onDelete={(value) => handleFilterChipDelete(value, 'width')}
                                />
                            </div>
                        </div>

                        <div className="flex justify-end">
                            <div className="flex gap-x-5">
                                <ButtonV2 variant="tertiaryContained" size="large" label={"Reset"} onClick={handleReset} />
                                <ButtonV2 variant="primaryContained" size="large" label={"Search"} onClick={handleSubmit} />
                            </div>
                        </div>
                    </div>


                    {
                        formik.values.materialFilterFlag &&
                        <div className={`${classes.filterContainer} pt-8 grid gap-y-6`}>
                            <div className="flex justify-between">
                                <div className={`${classes.filterHeading} text-base font-medium my-auto`}>UPC list as per selection</div>
                                <div className="w-80">
                                    <TextFieldV2
                                        className="w-80"
                                        placeholder="Search by UPC Code"
                                        variant="outlined"
                                        type="text"
                                        name="upcCode"
                                        value={upcsByFilter.upcCode}
                                        onChange={handleFiltersChange}
                                    />
                                </div>
                            </div>
                            <div>
                                <ChipsV2 values={formik.values.upcCodes ?? []} onDelete={handleChipDelete} />
                            </div>
                            <div>
                                <TableV2 schema={schema} records={records} />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default UpcMaterialFilterTemplate;

