import { createUseStyles } from "react-jss";
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from "react";
import { IPagination } from "../../../utils/types";
import { initialPaginationValues } from "../../../utils/constant";
import MasterBrandListTemplate from "./MasterBrandList.Template";
import ButtonV2 from "../../atoms/ButtonV2";
import { IUPCRelatedBrandsRequest, useMasterUpcService } from "../../../services/useMasterUpcService";
import { IRequestBodyValues } from "../../pages/UpcListing/UpcListing.page";

interface MasterBrandListModalTemplateProps {
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
    selectedMasterUpcId: number;
    selectedMasterUpcCode: string;
    selectedMasterUpcTitle: string;
    refreshMasterUpc: (page: number, size: number) => void;
    upcPagination: IPagination;
}

export interface IBrandFilter {
    status: string;
    sort: string;
    search: string;
}

const initialBrandFiltersValues: IBrandFilter = {
    status: "ALL",
    sort: "createdAt,desc",
    search: "",
}

export interface IBrandUpcs {
    id: number;
    upcCode: string;
    brandName: string;
    brandUpcPrice: string;
    status: string;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        "& .MuiDialog-paper": {
            width: "783px !important",
            maxWidth: "1297px !important",
            height: "684px",
            borderRadius: "16px"
        }
    },
    image: {
        height: "687px"
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    subHeading: {
        color: theme.palette.textV2.primary.primary870
    }
}));

const MasterBrandListModalTemplate: React.FC<MasterBrandListModalTemplateProps> = ({ dialogOpen, setDialogOpen, selectedMasterUpcId, selectedMasterUpcCode, selectedMasterUpcTitle, refreshMasterUpc, upcPagination }) => {
    const classes = useStyles();
    const [brandUpcs, setBrandUpcsTo] = useState<IBrandUpcs[]>([]);
    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);
    const [brandFilters, setBrandFiltersTo] = useState<IBrandFilter>(initialBrandFiltersValues);
    const [requestBodyValues, setRequestBodyValuesTo] = useState<IRequestBodyValues>({ upcIds: [], brandIds: [] });
    const masterUpcService = useMasterUpcService();

    const refreshBrands = (page: number, size: number) => {
        let params: IUPCRelatedBrandsRequest = {
            status: 'ACTIVE',
            page: page,
            size: size,
            sort: brandFilters?.sort,
            search: brandFilters?.search,
            upcId: selectedMasterUpcId
        }

        masterUpcService.getUpcRelatedBrand(params,requestBodyValues)
            .then((upcResponse) => {
                if (upcResponse.data.data) {
                    const { totalElements, totalPages } = upcResponse?.data?.data;
                    setPaginationTo({
                        ...pagination,
                        totalPages: totalPages,
                        totalRecords: totalElements
                    });
                    setBrandUpcsTo(upcResponse?.data?.data?.content);
                }
                else {
                    setBrandUpcsTo([]);
                }
            })
            .catch((error) => {
                console.error("Brands fetching error - ", error);
            });
    }

    const handleDialogClose = () => {
        refreshMasterUpc(upcPagination.currentPage, upcPagination.pageSize);
        setPaginationTo(initialPaginationValues);
        setDialogOpen(false);
    };

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleFiltersChange = (name: string, value: any) => {
        setPaginationTo({ ...pagination, currentPage: 0 });
        setBrandFiltersTo({ ...brandFilters, [name]: value ?? "" });
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage
        }));
    };

    useEffect(() => { 
        if (selectedMasterUpcId) {
            refreshBrands(pagination.currentPage, pagination.pageSize);
        }    }, [pagination.currentPage, pagination.pageSize, brandFilters.search, brandFilters.status, brandFilters.sort, selectedMasterUpcId, requestBodyValues]);

    return (
        <>
            <Dialog fullWidth className={classes.container}
                open={dialogOpen} onClose={handleDialogClose}>
                <div className={`flex gap-x-6 px-6 mb-6`}>
                    <div className="grid gap-y-6 mt-5 m-auto flex-1 ">
                        <div className="flex justify-between">
                            <div>
                                <div className={`${classes.heading} text-2xl font-medium`}>{selectedMasterUpcTitle}</div>
                                <div className={`${classes.subHeading} text-base `}>{selectedMasterUpcCode}</div>
                            </div>
                            <div className={`cursor-pointer`} onClick={handleDialogClose}>
                                <CloseIcon/>
                            </div>
                        </div>
                        <div className="">
                            <MasterBrandListTemplate brandUpcs={brandUpcs} pagination={pagination} handlePaginationChange={handlePaginationChange} handleRowsPerPageChange={handleRowsPerPageChange} selectedMasterUpcId={selectedMasterUpcId} selectedMasterUpcTitle={selectedMasterUpcTitle} selectedMasterUpcCode={selectedMasterUpcCode} refreshBrands={refreshBrands} setRequestBodyValuesTo={setRequestBodyValuesTo} handleFiltersChange={handleFiltersChange}/>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end mr-6 mb-6">
                <ButtonV2 variant="secondaryContained"
                            onClick={handleDialogClose}
                            label={"Done"} />
                </div>
            </Dialog>
        </>
    );
};

export default MasterBrandListModalTemplate;
