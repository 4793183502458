import { UPCItem } from './types';

export const IndentTabs = ['All Indents', 'My Indents', 'My Drafts'];

export const PAYMENT_MODE: { name: string; value: number }[] = [
  { name: 'Advanced', value: 0 },
  { name: 'Within 7 Days', value: 7 },
  { name: 'Within 15 Days', value: 15 },
  { name: 'Within 30 Days', value: 30 },
];

export const TRANSPORTATION_MODE: { name: string; value: string }[] = [
  { name: 'By Road', value: 'ROAD' },
  { name: 'By Sail', value: 'SAIL' },
  // { name: "Self", value: "SELF" },
];

export const COMMENT_RESOURCE_TYPE = {
  INDENT: 'INDENT_CREATE',
  AUCTION: 'AUCTION_CREATE',
};

export const QUALITY_CHECK: { name: string; value: string }[] = [
  { name: 'At Vendor Site', value: 'AT_VENDOR' },
  { name: 'After Delivery', value: 'ON_DELIVERY' },
];

export const PURCHASE_TYPE: { name: string; value: string }[] = [
  { name: 'Direct', value: 'DIRECT' },
  { name: 'Auction', value: 'AUCTION' },
  { name: 'Both ', value: 'AUCTION_OR_DIRECT' },
];

export const IndentStatus: { name: string; value: string }[] = [
  { name: 'All', value: 'All' },
  { name: 'Created', value: 'CREATED' },
  { name: 'Added Remarks', value: 'APPROVAL_REMARKS' },
  { name: 'Remarks Resolved', value: 'APPROVAL_REMARKS_RESOLVED' },
  { name: 'Approved', value: 'APPROVED' },
  { name: 'Auction Created', value: 'AUCTION_CREATED' },
  { name: 'Auction Approved', value: 'AUCTION_APPROVED' },
  { name: 'Vendors Invited', value: 'VENDOR_INVITED' },
  { name: 'Purchase Order Created', value: 'PURCHASE_ORDER_CREATED' },
  { name: 'Gate Entry Created', value: 'GATE_ENTRY_CREATED' },
  { name: 'GRN Created', value: 'GRN_CREATED' },
  { name: 'QC Created', value: 'QC_CREATED' },
  { name: 'Putaway Created', value: 'PUTAWAY_CREATED' },
  { name: 'Canceled', value: 'CANCELED' },
  { name: 'Rejected', value: 'REJECTED' },
];

export const productDescription = (item: UPCItem) => {
  if (item) {
    return (
      item.brand +
      ' ' +
      item.productCategory +
      ' ' +
      item.shape +
      ' ' +
      item.standard +
      ' ' +
      item.grade
    );
  } else {
    return '';
  }
};

export const INDENT_LIST_PAGINATION_TEXT = {
  showingItems: (pageNumber: number, totalItems: number) =>
    `${totalItems > 0 ? pageNumber * 10 + 1 : 0}-${
      totalItems < pageNumber * 10 + 10 ? totalItems : pageNumber * 10 + 10
    } from ${totalItems} Indent`,
};

export const PRID_LIST_PAGINATION_TEXT = {
  showingItems: (pageNumber: number, totalItems: number) =>
    `${totalItems > 0 ? pageNumber * 10 + 1 : 0}-${
      totalItems < pageNumber * 10 + 10 ? totalItems : pageNumber * 10 + 10
    } from ${totalItems} PRIDs`,
};

export const ProductAttributeFields = [
  {
    name: 'Length (MM)',
    property: 'Length',
  },
  {
    name: 'Width (MM)',
    property: 'Width',
  },
  {
    name: 'Thickness (MM)',
    property: 'Thickness',
  },
  {
    name: 'Temper',
    property: 'temper',
  },

  {
    name: 'Color',
    property: 'Color',
  },
  {
    name: 'Surface Finish',
    property: 'surface_finish',
  },
];

export const INDENT_TEXT = {
  fromDateFilterLabel: 'From Date :',
  toDateFilterLabel: 'To Date :',
  indentListSearchPageText: 'Search',
  createReviewButtonText: '+ Create new Indent',
  selectAndAddPRText: 'Select and Add PRID',
  prEnterLabelText: 'PRID :',
  prEnterPlaceHolderText: 'Enter..',
  deliveryLocationRadioGroupLabelText: 'Select Delivery Location',
  productNameSearchPlaceHolderText: 'Search Product Name or UPC',
  IndentStepper: ['Select Products', 'Add Product Details', 'Review'],
  createIndentPageHeading: 'Create New Indent',
  PRlistDetailHeading: 'Add Material Details',
  PRlistDetailSummary: 'Please Add Details Product wise.',
  PRAddTermsAndConditionHeading: 'Add Indent T&C',
  PRAddBillToDetailsHeading: 'Buyer (Bill To)',
  PRAddShipToDetailsHeading: 'Consignee Location (Ship To)',
  PRAddTaxDetailsHeading: 'Statutory Tax Details',
  PRAddPaymentDetailsHeading: 'Payment & Delivery Details',
  PRAddQcDetailsHeading: 'QC Details',
  PRAddRecommendedPurchaseTypeHeading: 'Recommended Purchase Type',
  PRAddTnCHeading: 'Any Other Terms & Conditions',
  PRAddBackButtonLabel: 'Back',
  PRAddNextButtonLabel: 'Next',
  PRAddDeleteProcurementButtonLabel: 'Delete Product',
  PRAdduploadButtonText: 'Upload Attachment ( Optional )',
  PRReviewHeading: 'Review Indent',
  PRReviewSummary: 'Please every details to create an indent.',
  PRProductDetailHeading: 'Product Details',
  PREditButtonLabel: 'Edit',
  IndentSaveAsDraftLabel: 'Save as draft',
  IndentSubmitLabel: 'Submit for Approval',
  ReviewIndentHeading: 'Review Indent',
  IndentIDLabel: 'Indent ID: ',
  ReviewSaveButtonLabel: 'Save',
  ReviewCancelButtonLabel: 'Cancel',
  RejectIndentButtonLabel: 'Reject',
  ApproveIndentButtonLabel: 'Approve',
  IndentAddRemarkButton: 'Add Remarks',
  SendRemarkIndentButtonLabel: 'Send Remarks',
  ReviewTnCHeading: 'Indent T&C',
  RemarkComponentheading: 'Remark',
  IndentReviewAttachmentHeading: 'Attachments',
};
