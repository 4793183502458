import React, { useMemo } from 'react'
import { IPagination } from '../../../utils/types';
import { useNavigate } from 'react-router-dom';
import Table, { ColumnType } from '../../organisms/Table';
import { ADMIN_ROUTES, statusOptions } from '../../../utils/constant';
import viewEyeIcon from "./../../../assets/icons/viewEyeIcon.svg"
import editPenIcon from "./../../../assets/icons/editPenIcon.svg";
import { enumToString, makeRoute } from '../../../utils/helper';
import moment from 'moment';
import Select from '../../atoms/Select';
import TextField from '../../atoms/TextField';
import { IWhatsappTemplate, IWhatsappTemplateFilter } from '../../pages/WhatsappTemplate/WhatsappTemplateList.page';
import deleteBinIcon from "./../../../assets/icons/deleteBinIcon.svg";
import ResourceStatus from '../../atoms/ResourceStatus/ResourceStatus';

interface IWhatsappTemplateListProps {
    whatsappTemplates: IWhatsappTemplate[];
    pagination: IPagination;
    handleFiltersChange?: any;
    handlePaginationChange: any;
    handleRowsPerPageChange: any;
    whatsappTemplateFilter: IWhatsappTemplateFilter;
    deleteTemplate: any;
}

const WhatsappTemplateListTemplate: React.FC<IWhatsappTemplateListProps> = ({ deleteTemplate, whatsappTemplates, pagination, handleFiltersChange, handlePaginationChange, handleRowsPerPageChange, whatsappTemplateFilter }) => {

    const navigate = useNavigate();

    const schema = {
        id: 'template-list',
        pagination: {
            total: pagination.totalRecords,
            currentPage: pagination.currentPage,
            isVisible: true,
            limit: pagination.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange
        },
        columns: [

            { label: "Sr No.", key: "id", type: "number" as ColumnType, props: {} },
            { label: "Template Name", key: "name", type: "string" as ColumnType, props: {} },
            { label: "Language", key: "language", type: "string" as ColumnType, props: {} },
            { label: "Parameters", key: "noofParameters", type: "number" as ColumnType, props: {} },
            { label: "Created Date", key: "createdAt", type: "datetime" as ColumnType, props: {} },
            { label: "Last Modified", key: "updatedAt", type: "datetime" as ColumnType, props: {} },
            { label: "Created By", key: "createdBy", type: "number" as ColumnType, props: {} },
            { label: "Status", key: "status", component: ({ value }: { value: string }) => <ResourceStatus status={value} />, type: "custom" as ColumnType, props: {} }, 
            { label: "Action", key: "action", type: "custom" as ColumnType, props: {} },
        ]
    }

    const handleViewClick = (id: number) => (e: any) => {
        navigate(makeRoute(ADMIN_ROUTES.WHATSAPP_TEMPLATE_VIEW, {params:  { id: id } }));
    };

    const handleEditClick = (id: number) => (e: any) => {
        navigate(makeRoute(ADMIN_ROUTES.WHATSAPP_TEMPLATE_UPDATE, {params:  { id: id } }));
    };

    const handleDeleteClick = (id: number) => (e: any) => {
        const confirmed = window.confirm("Are you sure you want to delete this template?");
        if (confirmed) {
            deleteTemplate(id)
        }
    };

    const Action = (id: number) => {
        return (<div>
            <button onClick={handleViewClick(id)}><img src={viewEyeIcon} alt={viewEyeIcon} /></button>
            <button onClick={handleEditClick(id)}><img src={editPenIcon} alt={editPenIcon} /></button>
            <button onClick={handleDeleteClick(id)}><img src={deleteBinIcon} alt={deleteBinIcon} /></button>
        </div>)
    }

    const records = useMemo(() => {

        return whatsappTemplates.map((whatsappTemplate: IWhatsappTemplate, index: number) => {

            const parameters = whatsappTemplate.parameterValues.length

            return [
                pagination.currentPage * pagination.pageSize + index + 1,
                whatsappTemplate.templateName,
                whatsappTemplate.language,
                parameters,
                whatsappTemplate.createdAt === undefined ? "" : moment(whatsappTemplate.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                whatsappTemplate.updatedAt === undefined ? "" : moment(whatsappTemplate.updatedAt).format("YYYY-MM-DD HH:mm:ss"),
                whatsappTemplate.createdBy,
                enumToString(whatsappTemplate.status),
                Action(whatsappTemplate.id)
            ];
        });
    }, [whatsappTemplates]);


    return (
        <div className='grid gap-y-1.5'>
            <div className='flex justify-between'>
                <div className='flex gap-x-3'>
                    <div>
                        <Select
                            variant="outlined"
                            className="w-44"
                            label="Status"
                            name="status"
                            id="status"
                            value={whatsappTemplateFilter.status}
                            onChange={(e) => handleFiltersChange(e)}
                            options={statusOptions}
                        />
                    </div>
                    <div>
                        <Select
                            variant="outlined"
                            className="w-fit"
                            label="Sort"
                            value={whatsappTemplateFilter.sort}
                            onChange={(e) => handleFiltersChange(e)}
                            name="sort"
                            id="sort"
                            options={[
                                { value: "createdAt,asc", label: "Created Date (Ascending)" },
                                { value: "createdAt,desc", label: "Created Date (Decending)" }
                            ]}
                        /> 
                    </div>
                </div>
                <div>
                    <TextField
                        className="w-72"
                        type="text"
                        label="Search by Name"
                        variant="outlined"
                        name="search"
                        value={whatsappTemplateFilter.search}
                        onChange={handleFiltersChange}
                    />
                </div>
            </div>
            <Table schema={schema} records={records} />
        </div>
    )
}

export default WhatsappTemplateListTemplate;