import React, { useEffect, useState } from 'react'
import { FormikErrors, FormikProps } from 'formik';
import { createUseStyles } from 'react-jss';
import { useCategoryService } from '../../../../services/useCategoryService';
import { HTTP_STATUS } from '../../../../utils/types';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import AddMaterialFormTemplate, { IStandards } from './AddMaterialForm.template';
import { ADMIN_ROUTES, MODE } from '../../../../utils/constant';
import { IMaterialAttribute, IMaterialRequirement, IMaterialRequirementFormik } from '../../../pages/CustomerManagement/MyMaterialProduct.page';
import { CustomAccordion, IStatus } from '../../../molecules/Accordion/CustomAccordion';
import ButtonV2 from '../../../atoms/ButtonV2';
import { useStandardService } from '../../../../services/useStandardService';
import { IMY_MATERIAL_STATE } from '../../../pages/CustomerManagement/MyMaterial.page';
import { useNavigate } from 'react-router-dom';

interface AddMaterialProductTemplateProps {
  handleOnBack: () => void;
  handleOnNext: () => void;
  formik: FormikProps<IMaterialRequirementFormik>
  myMaterialState: IMY_MATERIAL_STATE
}

const useStyles = createUseStyles((theme: any) => ({
  section: {
    color: theme.palette.textV2.secondary.secondary800,
  },
  highlightedText: {
    color: theme.palette.textV2.primary.primary500
  },
  divider: {
    height: '1px',
    background: theme.palette.textV2.tertiary.tertiary100
  },
  tertiary100Border: {
    border: `1px solid ${theme.palette.textV2.tertiary.tertiary100}`
  },
  darkHeading: {
    color: theme.palette.textV2.primary.primary900
  },
}));

export interface IConfigurationShape {
  name: string,
  label: string,
  prefix: string
}

const AddMaterialProductTemplate: React.FC<AddMaterialProductTemplateProps> = ({ formik, handleOnBack, myMaterialState }) => {
  const classes = useStyles();
  const { showSnackbar, SnackBarComponent } = useSnackbar();
  const [attributes, setAttributes] = useState<IMaterialAttribute[]>([]);
  const [attributesOriginal, setAttributesOrigianl] = useState<IMaterialAttribute[]>([]);
  const [activeAccordion, setActiveAccordion] = useState<number>(0);
  const [standards, setStandards] = useState<IStandards[]>([]);
  const [standardList, setStandardList] = useState<number[]>([]);
  const standardService = useStandardService();
  const categoryService = useCategoryService();
  const navigate = useNavigate();

  const loadProductCategory = () => {
    categoryService.getAllCategories({ id: parseInt(formik.values?.categoryId as string ?? myMaterialState.other?.id), level: 2, page: 0, size: 1 })
      .then((res: any) => {
        if (res?.status === HTTP_STATUS.OK) {
          setStandardList(res.data.data.content?.[0].standards)
          parseAttribute(res?.data?.data?.content?.[0].attributeDetails?.SPECIFICATION ?? []);
        }
      }).catch((error: any) => {
        showSnackbar('error', "Error Fetching Category");
      })
  }

  const loadStandard = (inputValue?: string) => {
    standardService.getAllStandards({ search: inputValue ?? '', page: 0, size: 100, sort: 'createdAt,desc' })
      .then((res : any) => {
        if (res?.status === HTTP_STATUS.OK)
          setStandards(res?.data?.data?.content.filter((standard: any) => standardList.includes(standard.id)))
      }).catch((error :any) => {
        console.error("Error Fetching Standards: ", error);
      })
  }

  const parseAttribute = (attributes: IMaterialAttribute[]) => {
    attributes.forEach((attribute: IMaterialAttribute) => {
      if (typeof attribute.attributeOptions === 'string')
        attribute.attributeOptions = JSON.parse(attribute.attributeOptions);
    })
    const attribute = JSON.parse(JSON.stringify(attributes));
    const attributeOriginal = JSON.parse(JSON.stringify(attributes));

    setAttributes(attribute);
    setAttributesOrigianl(attributeOriginal)
    const materialRequirementData: IMaterialRequirement[] = formik?.values?.noOfRows ? Array.from({ length: parseInt(formik?.values?.noOfRows.toString()) }, (_, i) => (
      {
        id: i,
        productCode: "",
        categoryId: null,
        classType: "",
        standardId: null,
        gradeId: null,
        shape: "",
        attributes: attributes.map((attribute: IMaterialAttribute) => ({ ...attribute, attributeOptions: null })),
        applicationType: "",
        otherSpecificRequirement: "",
        primarySourcingPurpose: "",
        frequency: "",
        quantity: null,
        standard: null,
        grade: null,
        isDuplicate: false
      }
    )) : [];
    formik.setFieldValue('materialRequirement', materialRequirementData);
  }

  const handleCurrentActiveAccordion = (index: number) => {
    setActiveAccordion(activeAccordion === index ? -1 : index);
  }

  const handleOnDelete = (id: number) => {
    const filteredMaterialRequirements = formik.values.materialRequirement.filter((materialRequirement, i) => materialRequirement.id != id)
    formik.setFieldValue('materialRequirement', filteredMaterialRequirements);
    formik.setFieldValue('noOfRows', filteredMaterialRequirements.length);
    formik.setFieldValue(`deletedRowIndex`, [...formik.values.deletedRowIndex as number[], id]);
  }

  useEffect(() => {
    loadProductCategory();
    loadStandard();
  }, [])

  const getFormStatus = (formik: FormikProps<IMaterialRequirementFormik>, index: number): IStatus => {
    if (formik.values?.materialRequirement?.[index]?.isDuplicate) {
      return { message: "Duplicate combination, Please fill the unique combination", type: "error" }
    } else if (formik?.touched?.materialRequirement?.[index])
      if (formik.errors?.materialRequirement?.[index] === undefined)
        return { message: "Filled", type: "success" }
      else
        return { message: "Please fill the data", type: "error" }
    else {
      return { message: "", type: "error" }
    }
  }

  const checkValidSubmit = (errors: FormikErrors<IMaterialRequirementFormik>) => {
    const error = (errors.materialRequirement as FormikErrors<IMaterialRequirement>[])?.filter((_, i) => !formik.values.deletedRowIndex?.includes(i))
    if(!formik.values.noOfRows)return true
    else if(!!error?.filter(el => el).length) return true
    else if(formik.values.noOfRows !== 0 && !!error?.filter(el => el).length) return true
    else return false
  }

  const handleBackToDashboard = () => {
    navigate(ADMIN_ROUTES.CUSTOMER_MANAGEMENT_LIST);
};

const handleAddAnotherProduct = () =>{
  console.log('Add Another Product clicked');
  const newProduct = {
    id: formik.values.materialRequirement.length,
    productCode: "",
    categoryId: null,
    classType: "",
    standardId: null,
    gradeId: null,
    shape: "",
    attributes: attributes.map((attribute: IMaterialAttribute) => ({ ...attribute, attributeOptions: null })),
    applicationType: "",
    otherSpecificRequirement: "",
    primarySourcingPurpose: "",
    frequency: "",
    quantity: null,
    standard: null,
    grade: null,
    isDuplicate: false
  }
  formik.setFieldValue('materialRequirement', [
    ...formik.values.materialRequirement,
    newProduct,
  ]);
  formik.setFieldValue('noOfRows', (formik.values?.noOfRows as number)+1)
}

  return (
    <div className={`p-6 `}>
      {SnackBarComponent}
      <div className='flex justify-between'>
        <div className={`text-2xl font-medium ${classes.highlightedText}`}>{formik.values.categoryName ?? myMaterialState.other?.name}</div>
        <div className={`text-[18px] font-medium ${classes.darkHeading}`}>No of product selected: <span>{formik.values.noOfRows}</span></div>
      </div>
      <div className={`${classes.divider} mt-6 mb-8`}></div>
      <form onSubmit={formik.handleSubmit}>
        <div className='flex flex-col gap-y-8'>
          {attributes && formik.values.materialRequirement?.map((materialRequirement, index) =>
          (
            <CustomAccordion
              index={materialRequirement?.id as number}
              expanded={activeAccordion}
              component={<AddMaterialFormTemplate formik={formik} index={index} attributes={attributes ?? []} attributesOriginal={attributesOriginal ?? []} setAttributes={setAttributes} standards={standards} loadStandard={loadStandard} mode={MODE.ADD} />}
              heading={`Product Detail ${index + 1}`}
              onChange={handleCurrentActiveAccordion}
              onDelete={handleOnDelete}
              isDeletable
              status={getFormStatus(formik, materialRequirement?.id as number)}
            />))}
        </div>
        <div className='flex justify-end gap-x-3 mt-6'>
        <ButtonV2
                    className={` justify-self-end`}
                    variant="secondaryContained"
                    label="Back to Dashboard"
                    onClick={handleBackToDashboard}
                />
          <ButtonV2 label='Back' variant='tertiaryContained' type='button' onClick={handleOnBack} />
          <ButtonV2 label='+ Add Another Product' type='button' variant='tertiaryContained' onClick={handleAddAnotherProduct} />
          <ButtonV2 label='Add' variant='secondaryContained' type='submit' disabled={checkValidSubmit(formik.errors)} isLoading={formik.isSubmitting}/>
        </div>
      </form>
    </div>
  )
}

export default AddMaterialProductTemplate