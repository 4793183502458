const AttributeEnum = {
    SPECIFICATION: "SPECIFICATION",
    CLASSIFICATION: "CLASSIFICATION",
    GENERALIZATION: "GENERALIZATION",
};

export const mapAttributeStatusEnumToString = (enumValue) => {
    switch (enumValue) {
      case AttributeEnum.SPECIFICATION:
        return "Specification";
      case AttributeEnum.CLASSIFICATION:
        return "Classification";
      default:
        return "Generalization";
    }
};