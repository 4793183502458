import React, { useState } from "react";
import CustomTimePicker from "../../../../Shared/CustomDateTimePicker/CustomTimePicker";
import { AUCTION } from "../../../../Constants/Procurement/LiveAuction/text";
import { Button } from "@mui/material";
import moment from "moment";

interface RestartModalProps {
  handleRestart: (val: Date) => void;
}
const RestartModal = ({ handleRestart }: RestartModalProps) => {
  const [time, setTime] = useState<Date | undefined>();
  const handleDate = () => {
    if (time) handleRestart(time);
  };

  const getDifference = (time: Date) => {
    const diff = Math.trunc(
      (time.getTime() - new Date().getTime()) / (1000 * 60)
    );

    return diff > 0 ? diff : 0;
  };

  return (
    <div className="h-[450px] flex flex-col justify-center items-center">
      <div>
        <div className="text-blue font-bold mb-1">{AUCTION.AddTime}</div>
        <CustomTimePicker value={time} onChange={(val) => setTime(val)} />
        <div className="text-blue font-bold my-4">
          {AUCTION.TotalTime} {time ? getDifference(time) : 0} min
        </div>
        <div className="flex justify-end mt-4">
          <Button
            onClick={handleDate}
            className="rounded h-12 normal-case text-white bg-blue hover:bg-blueHover w-[104px]"
          >
            {AUCTION.Done}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RestartModal;
