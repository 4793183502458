import React from 'react';
import { createUseStyles } from 'react-jss';
import noData from '../../../assets/images/noDataImage.svg';
import ButtonV2 from '../../atoms/ButtonV2';
import { useNavigate } from 'react-router-dom';
import { ADMIN_ROUTES } from '../../../utils/constant';

const useStyles = createUseStyles((theme: any) => ({
    primaryContainer: {
        border: `1px solid ${theme.palette.border.primarylightAccent}`,
        background: theme.palette.background._secondaryDarkAccent,
    },
    heading: {
        color: theme.palette.textV2.secondary.secondary800,
    }
}));

const EmptyVendorListingTemplate: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleAddNewVendors = () => {
        navigate(ADMIN_ROUTES.VENDOR_MANAGEMENT_CREATE)
      }

    return (
        <div className={`${classes.primaryContainer} py-14 px-6  flex justify-center w-full rounded-lg`}>
            <div className='grid gap-y-4'>
                <div className='grid gap-y-8 justify-items-stretch'>
                    <div className='justify-self-center'>
                        <img src={noData} alt="noDataImage" className="" />
                    </div>
                    <div className={`justify-self-center font-normal text-lg ${classes.heading}`}>
                        You haven't provided vendor details for your business.
                    </div>
                </div>
                <div className='grid justify-items-stretch'>
                    <ButtonV2
                        className={` justify-self-center w-auto`}
                        variant="contained"
                        label="+Add New Vendor"
                        onClick={handleAddNewVendors}
                    />
                </div>
            </div>
        </div>
    )
};
export default EmptyVendorListingTemplate;
