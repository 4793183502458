import { createUseStyles } from "react-jss";
import ProductSpecificationSearchTemplate from "./ProductSpecificationSearch.template";

interface ProductSpecificationSectionIndexTemplateProps {
    updateSearchParams: any;
    attributes: any;
    formik: any;
    mode: string;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        backgroundColor: theme.palette.background.primaryLight,
    },
    heading: {
        color: theme.palette.text.primaryDarkLight,
    },
}));

const ProductSpecificationSectionIndexTemplate: React.FC<ProductSpecificationSectionIndexTemplateProps> = ({ updateSearchParams, attributes, formik, mode }) => {
    const classes = useStyles()
    return (
        <div className={`${classes.container} grid gap-y-4 p-6 rounded`}>
            <div className={` ${classes.heading} text-lg font-semibold`}>
                Product Specification
            </div>
            <ProductSpecificationSearchTemplate updateSearchParams={updateSearchParams} attributes={attributes} formik={formik} mode={mode}/>
        </div>
    )
}
export default ProductSpecificationSectionIndexTemplate;