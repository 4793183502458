import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { HTTP_STATUS, MANUFACTURER_SECTIONS } from '../../../../utils/types';
import { FormikProps } from 'formik';
import { IManufacturerForm } from './ManufacturerForm.template';
import { useNavigate } from 'react-router-dom';
import TextField from '../../../atoms/TextField';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useCategoryService } from '../../../../services/useCategoryService';
import { ICategoriesSearch } from '../Brand/BrandForm.template';
import { ADMIN_ROUTES, CATEGORIES, DOCUMENT_TYPES, MODE, RESOLUTIONS } from '../../../../utils/constant';
import CategoriesDisplayTemplate from '../../Category/CategoriesDisplay.template';
import CategorySearchTemplate from '../../Category/CategorySearch.template';
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button';
import { capitalizeFirstLetter, toCamelCase, titleModification } from '../../../../utils/helper';
import CloseIcon from "@mui/icons-material/Close";
import ImageUploader, { ImageData } from '../../../organisms/ImageUploader';
import UploadImages from '../../../organisms/UploadImages';
import { useFileService } from '../../../../services/useFileService';
import { useSnackbar } from '../../../../hooks/useSnackBar';

const useStyles = createUseStyles((theme: any) => ({
    errorMessage: {
        color: theme.palette.action.danger,
    },
    mainHeading:{
        color: theme.palette.text.primaryDarkLight
    },
    headingContainer: {
        borderBottom: `1px solid ${ theme.palette.border.primaryDark }`
    }
}));

interface ManufacturerDetailsTemplateProps {
    setCurrentSectionTo: (section: MANUFACTURER_SECTIONS) => void;
    categoryIds: number[] | [];
    formik: FormikProps<IManufacturerForm>;
    id: number | null;
    mode: string;
    setCategoriesTo: Dispatch<SetStateAction<number[]>>;
    image: (ImageData | File)[]; 
	setImagesTo: (images:any) => void;
    imageIdsToDelete: number[];
    setImageIdsToDelete: (id: number[]) => void;

}

const ManufacturerDetailsTemplate: React.FC<ManufacturerDetailsTemplateProps> = ({ setCurrentSectionTo, categoryIds, formik, id, mode, setCategoriesTo, image, setImagesTo, imageIdsToDelete, setImageIdsToDelete  }) => {

    const navigate = useNavigate();
    const classes = useStyles();

    const categoryService = useCategoryService();
    const fileService = useFileService();

    const [selectedCategories, setSelectedCategoriesTo] = useState<ICategoriesSearch[]>([]);
    const [categoryErrorState, setCategoryErrorStateTo] = useState<boolean>(false);
    const redirectToManufacturerList = () => {
        navigate(ADMIN_ROUTES.MANUFACTURER_LIST);
    }

    const getCategoryById = async (id: number) => {
        if (id) {
            try {
                const categoryResponse = await categoryService.getCategoryById(id);
                if (categoryResponse.status !== HTTP_STATUS.OK) 
                    return null;
                    return {
                        superCategory: {
                            label: categoryResponse?.data?.data?.ancestors?.superParentCategory?.name,
                            id: categoryResponse?.data?.data?.ancestors?.superParentCategory?.id
                        },
                        mainCategory: {
                            label: categoryResponse?.data?.data?.ancestors?.parentCategory?.name,
                            id: categoryResponse?.data?.data?.ancestors?.parentCategory?.id,
                        },
                        productCategory: {
                            label: categoryResponse?.data?.data?.name,
                            id: categoryResponse?.data?.data?.id
                        },
                    };
            } catch (error) {
                console.error(`Error fetching category for ID ${id}:`, error);
                return null;
            }
        }
    };

    const getProductCategoriesByIds = async () => {
        if (categoryIds) {
            try {
                const categories: (ICategoriesSearch | null | undefined)[] =
                await Promise.all(categoryIds?.map((id: number) => getCategoryById(id)));
                const filteredCategory: ICategoriesSearch[] = categories.filter((category: ICategoriesSearch | null | undefined) => category !== null && category !== undefined) as ICategoriesSearch[];
                setSelectedCategoriesTo(filteredCategory);
                return filteredCategory;
            } catch (error) {
                console.error("Error fetching categories for IDs:", error);
            }
        }
    };

    const updateSearchParams = (index: number, key: string, category: ICategoriesSearch) => {
        setSelectedCategoriesTo((previousSelectedCategories: ICategoriesSearch[]) => {
            const updatedSelectedCategories = [...previousSelectedCategories];
            updatedSelectedCategories[index] = {
                ...updatedSelectedCategories[index],
                [key]: category,
            };
            if (key === CATEGORIES.PRODUCT_CATEGORY && category !== null) {
                setCategoryErrorStateTo(false);
            }
            return updatedSelectedCategories;
        });
    };

    const removeCategorySearch = (index: number) => {
        setSelectedCategoriesTo((prevSelectedCategories) => {
            if (prevSelectedCategories.length >= 1) {
                const updatedSelectedCategories = [...prevSelectedCategories];
                updatedSelectedCategories.splice(index, 1);
                return updatedSelectedCategories;
            }
            return prevSelectedCategories;
        });
        setCategoryErrorStateTo(false);
    };

    const addCategorySearch = () => {
        const newCategory = {
            id: null,
            superCategory: { label: "", id: null },
            mainCategory: { label: "", id: null },
            productCategory: { label: "", id: null },
        };
        setSelectedCategoriesTo((prevSelectedCategories) => [
            ...prevSelectedCategories,
            newCategory,
        ]);
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (mode !== MODE.VIEW) {
            const requiredFields = ["manufacturerType", "manufacturerName", "shortName", "manufacturerCode", "status", "addressLine1", "addressLine2", "country", "state", "city", "pinCode"];
            let fieldError = false;
            for (const key of requiredFields) {
                if (key in formik.errors) {
                    formik.setFieldTouched(key, true);
                    fieldError = true;
                }
            }
            if (selectedCategories.length === 0) {
                setCategoryErrorStateTo(true);
                return;
            }
            for (let index in selectedCategories) {
                if (
                    selectedCategories[index]?.productCategory?.id === undefined ||
                    selectedCategories[index]?.productCategory?.id === null
                ) {
                    setCategoryErrorStateTo(true);
                    return;
                }
            }
            setCategoryErrorStateTo(false);
            if (fieldError === true) return;
            const categories = selectedCategories.map((category: any) => category?.productCategory?.id);
            setCategoriesTo(categories);
            formik.setValues((prevValues: IManufacturerForm) => ({
                ...prevValues,
                manufacturerType: formik.values.manufacturerType,
                manufacturerName: formik.values.manufacturerName,
                shortName: formik.values.shortName,
                manufacturerCode: formik.values.manufacturerCode,
                status: formik.values.status,
                addressLine1: formik.values.addressLine1,
                addressLine2: formik.values.addressLine2,
                country: formik.values.country,
                state: formik.values.state,
                city: formik.values.city,
                pinCode: formik.values.pinCode,
                categoryIds: categories
            }));
        }
        setCurrentSectionTo(MANUFACTURER_SECTIONS.MANUFACTURING_LOCATION_DETAILS);
    };
    
    const CategoryView = useMemo(() => {
        return mode === MODE.VIEW ? (
            <CategoriesDisplayTemplate categories={ selectedCategories } />
        ) : (
            <CategorySearchTemplate
                updateSearchParams={ updateSearchParams }
                selectedCategories={ selectedCategories }
                addCategorySearch={ addCategorySearch }
                removeCategorySearch={ removeCategorySearch }
                mode={ mode }
            />
        );
    }, [mode, selectedCategories]);

    useEffect(() => {
        if(categoryIds?.length)
            getProductCategoriesByIds();
    }, [categoryIds]);


	const removeImage = useCallback((id: number) => {
		const updatedImages = image.filter((_, currentIndex) => currentIndex !== id);
        const imageData = image[id] as ImageData;
        const imageDeleteIds = [...imageIdsToDelete, imageData.id as number]
        setImageIdsToDelete(imageDeleteIds);
        setImagesTo(updatedImages);
	}, [image]);

	const updatedImages = useCallback((images: any) => {
		setImagesTo((prevImages:any) => [...prevImages, ...images]);
	}, [image]);

	// const updatedImages = useCallback((images: (ImageData | File)[]) => {                   *** to remove any and make this function like this : fix this later
	// 	setImagesTo((prevImages: (ImageData | File)[]) => [...prevImages, ...images]);
	// }, [setImagesTo]);

	const imageUploaderComponent = useMemo(() => (
		<ImageUploader
			mode={mode}
			images={image}
			removeImage={removeImage}
            isSubmitting = {formik.isSubmitting}
            imageIdsToDelete = {imageIdsToDelete}

		/>
	), [mode, image, removeImage, formik.isSubmitting]);

	const uploadImagesComponent = useMemo(() => (
		mode !== MODE.VIEW ? (
			<UploadImages
				title="Upload Images"
                currentImageCount = {image.length}
				updateImages={updatedImages}
                configuration={{
                    maxImageCount: 4,
					maxfilesize: 200,             
					documentTypes: [DOCUMENT_TYPES.IMAGE_JPEG, DOCUMENT_TYPES.IMAGE_PNG],
					documentResolution: RESOLUTIONS.SECONDARY_INVENTORY,
				}}
			/>
		) : null
	), [mode, updatedImages]);

    return (
        <div className="p-6 grid gap-y-6">
            <div className={`${classes.headingContainer} flex justify-between pb-4`}>
                <div className={`${classes.mainHeading} font-semibold text-2xl`}>{capitalizeFirstLetter(mode)} Manufacturer</div>
                <CloseIcon
                    onClick={ redirectToManufacturerList }
                    className="cursor-pointer text-4xl"
                />
            </div>
            {/***************************	TODO- Make a Generic Component********************** */}
            <div className="w-full  m-auto flex justify-between gap-x-0.5 text-var(--black, #4D4D4D) text-center font-roboto text-xs font-normal leading-normal">
                <div className="grid gap-y-1 w-1/3">
                    <div>Manufacturer Details</div>
                    <div className="h-3 bg-blue"></div>
                </div>
                <div className="grid gap-y-1 w-1/3">
                    <div> Manufacturing Location details</div>
                    <div className="h-3 bg-gray-300"></div>
                </div>
                <div className="grid gap-y-1 w-1/3">
                    <div>Add Point of Contact</div>
                    <div className="h-3 bg-gray-300"></div>
                </div>
            </div>
            {/***************************	TODO- Make a Generic Component********************** */}
            <div className='grid gap-y-4'>
                <div className="grid grid-cols-2 gap-4">
                    <div className='grid'>
                        <FormControl variant="outlined" className="w-full">
                            <InputLabel id="manufacturerType-label">Select Manufacturer Type*</InputLabel>
                            <Select
                                labelId="manufacturerType-label"
                                { ...formik.getFieldProps("manufacturerType") }
                                name="manufacturerType"
                                readOnly={ mode === MODE.VIEW ? true : false }
                                label="Select Manufacturer Type*"
                                onBlur={ formik.handleBlur }
                            >
                                <MenuItem value="PRIMARY_MANUFACTURER">Primary Manufacturer</MenuItem>
                                <MenuItem value="SECONDARY_MANUFACTURER">Secondary Manufacturer</MenuItem>
                                <MenuItem value="END_MANUFACTURER">End Manufacturer</MenuItem>
                            </Select>
                        </FormControl>
                        { formik.touched.manufacturerType && formik.errors && formik.errors.manufacturerType && (
                            <div className={ classes.errorMessage }>
                                <small>{ formik.errors.manufacturerType }</small>
                            </div>
                        )}
                    </div>
                    <div className='grid'>
                        <TextField
                            { ...formik.getFieldProps("manufacturerName") }
                            name="manufacturerName"
                            id="outlined-basic"
                            label="Manufacturer name*"
                            variant="outlined"
                            onBlur={(event) => {
                                const newValue = titleModification(event.target.value);
                                formik.setFieldValue('manufacturerName', newValue);
                            }}
                            inputProps={{ readOnly: mode === MODE.VIEW ? true : false, maxLength: 100 }}
                        />
                        { formik.touched && formik.touched?.manufacturerName && formik.errors?.manufacturerName && (
                            <div className={ classes.errorMessage }>
                                <small>{ formik.errors.manufacturerName }</small>
                            </div>
                        )}
                    </div>
                    <div className='grid'>
                        <TextField
                            { ...formik.getFieldProps("shortName") }
                            name="shortName"
                            id="outlined-basic"
                            label="Manufacturer short name*"
                            variant="outlined"
                            inputProps={{ readOnly: mode === MODE.VIEW ? true : false, maxLength: 50 }}
                            onBlur={(event) => {
                                const newValue = toCamelCase(event.target.value);
                                formik.setFieldValue('shortName', newValue);
                            }}
                        />
                        { formik.touched && formik.touched?.shortName && formik.errors?.shortName && (
                            <div className={ classes.errorMessage }>
                                <small>{ formik.errors?.shortName }</small>
                            </div>
                        )}
                    </div>
                    <div className='grid'>
                        <TextField
                            { ...formik.getFieldProps("manufacturerCode") }
                            name="manufacturerCode"
                            id="outlined-basic"
                            label="Manufacturer code*"
                            variant="outlined"
                            inputProps={{ readOnly: mode === MODE.VIEW ? true : false, maxLength: 50 }}
                        />
                        { formik.touched && formik.touched?.manufacturerCode && formik?.errors?.manufacturerCode && (
                            <div className={ classes.errorMessage }>
                                <small>{ formik.errors.manufacturerCode }</small>
                            </div>
                        )}
                    </div>
                </div>
                <div className='grid gap-y-3'>
                    <div className={ `font-semibold text-base ${ classes.mainHeading } mb-2` }>Categories Manufacturer Deals With</div>
                    { CategoryView }
                    { categoryErrorState && (<div className={ classes.errorMessage }>Please Add Categories</div>) }
                    <div className="w-1/3 mt-4">
                        <TextField
                            id="status"
                            label="Select Status*"
                            fullWidth
                            select
                            variant="outlined"
                            { ...formik.getFieldProps("status") }
                            name="status"
                            inputProps={{ readOnly: mode === MODE.VIEW ? true : false }}
                        >
                            <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                            <MenuItem value="INACTIVE">INACTIVE</MenuItem>
                        </TextField>
                        { formik.touched && formik.touched?.status && formik.errors?.status && (
                            <div className={classes.errorMessage}>
                                <small>{ formik.errors.status }</small>
                            </div>
                        )}
                    </div>
                </div>
                <div className='grid gap-y-1'>
                    <div className={ `font-semibold text-base ${ classes.mainHeading }` }>Registered Location</div>
                    <div className='grid gap-y-4'>
                        <div className='grid gap-y-4'>
                            <div className="grid mt-4">
                                <TextField
                                    { ...formik.getFieldProps("addressLine1") }
                                    name="addressLine1"
                                    id="outlined-basic"
                                    label="Address Line 1*"
                                    variant="outlined"
                                    inputProps={{ readOnly: mode === MODE.VIEW ? true : false, maxLength: 250 }}
                                />
                                { formik.touched?.addressLine1 && formik.errors && formik.errors?.addressLine1 && (
                                    <div className={ classes.errorMessage }>
                                        <small>{ formik.errors.addressLine1 }</small>
                                    </div>
                                )}
                            </div>
                            <div className="grid">
                                <TextField
                                    id="outlined-basic"
                                    { ...formik.getFieldProps("addressLine2") }
                                    name="addressLine2"
                                    label="Address Line 2*"
                                    variant="outlined"
                                    inputProps={{ readOnly: mode === MODE.VIEW ? true : false, maxLength: 250, }}
                                />
                                { formik.touched && formik.touched.addressLine2 && formik.errors && formik.errors?.addressLine2 && (
                                    <div className={ classes.errorMessage }>
                                        <small>{ formik.errors.addressLine2 }</small>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='grid grid-cols-2 gap-4'>
                            <div className="grid">
                                <TextField
                                    id="outlined-basic"
                                    { ...formik.getFieldProps("country") }
                                    label="Country*"
                                    variant="outlined"
                                    inputProps={{ readOnly: mode === MODE.VIEW ? true : false }}
                                />
                                { formik.touched && formik.touched?.country && formik.errors && formik.errors?.country && (
                                    <div className={ classes.errorMessage }>
                                        <small>{ formik.errors.country }</small>
                                    </div>
                                )}
                            </div>
                            <div className="grid">
                                <TextField
                                    id="outlined-basic"
                                    { ...formik.getFieldProps("state") }
                                    label="State*"
                                    variant="outlined"
                                    inputProps={{ readOnly: mode === MODE.VIEW ? true : false, maxLength: 50 }}
                                />
                                { formik.touched && formik.touched?.state && formik.errors && formik.errors?.state && (
                                    <div className={ classes.errorMessage }>
                                        <small>{ formik.errors.state }</small>
                                    </div>
                                )}
                            </div>
                            <div className="grid">
                                <TextField
                                    id="outlined-basic"
                                    { ...formik.getFieldProps("city") }
                                    label="City*"
                                    variant="outlined"
                                    inputProps={{ readOnly: mode === MODE.VIEW ? true : false, maxLength: 100 }}
                                />
                                { formik.touched && formik.touched?.city && formik.errors && formik.errors.city && (
                                    <div className={ classes.errorMessage }>
                                        <small>{ formik.errors.city }</small>
                                    </div>
                                )}
                            </div>
                            <div className="grid">
                                <TextField
                                    id="outlined-basic"
                                    type="number"
                                    { ...formik.getFieldProps("pinCode") }
                                    label="Pincode*"
                                    variant="outlined"
                                    inputProps={{ readOnly: mode === MODE.VIEW ? true : false }}
                                    value={formik.values.pinCode}
                                />
                                { formik.touched && formik.touched?.pinCode && formik.errors && formik.errors?.pinCode && (
                                    <div className={ classes.errorMessage }>
                                        <small>{ formik.errors.pinCode }</small>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {imageUploaderComponent}
				{uploadImagesComponent}
            </div>
            <div className="flex justify-end">
                <div className="flex gap-x-5">
                    <Button
                        variant="outlined"
                        fullWidth
                        label="Cancel"
                        onClick={ redirectToManufacturerList }
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        label="Next"
                        onClick={ handleSubmit }
                    />
                </div>
            </div>
        </div>
    );
}

export default ManufacturerDetailsTemplate;
