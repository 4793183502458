import React, { useCallback, useMemo, useState } from 'react'
import { ISecondaryCatalogueForm, secondaryCatalogueSectionProps } from './SecondaryCatalogueForm.template'
import CustomHeading from '../../atoms/CustomHeading/CustomHeading'
import Button from '../../atoms/Button'
import { SECONDARY_CATALOGUE_SECTIONS } from '../../../utils/types'
import ImageUploader, { ImageData } from '../../organisms/ImageUploader'
import UploadImages from '../../organisms/UploadImages'
import { DOCUMENT_TYPES, MODE, RESOLUTIONS } from '../../../utils/constant'
import { useFileService } from '../../../services/useFileService'

const ImageUploadTemplate: React.FC<secondaryCatalogueSectionProps> = ({ formik, id, mode, setCurrentSectionTo, image, setImagesTo }) => {

  const fileService = useFileService();
  const [imageIdsToDelete, setImageIdsToDelete] = useState<number[]>([]);

  
  const handleBack = () => {
    setCurrentSectionTo(SECONDARY_CATALOGUE_SECTIONS.PRICE_DETAILS);
  };
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
const isImagePresent = image.length > 0;
    let fieldError = false;
      if (!isImagePresent) {
        alert("Please upload image for the catalogue");
        fieldError = true;
      }
    
    formik.setValues((prevValues: ISecondaryCatalogueForm) => ({
      ...prevValues,

    }));
    if (fieldError === true) return;
    setCurrentSectionTo(SECONDARY_CATALOGUE_SECTIONS.PREVIEW);
  };

	const removeImage = useCallback((id: number) => {
		const updatedImages = image.filter((_, currentIndex) => currentIndex !== id);
		const imageData = image[id] as ImageData;
        setImageIdsToDelete((prevImageIds: number[]) => [...prevImageIds,imageData.id as number]);
		setImagesTo(updatedImages);
	}, [image]);

	const updatedImages = useCallback((images: any) => {
		setImagesTo((prevImages:any) => [...prevImages, ...images]);
	}, [image]);

	// const updatedImages = useCallback((images: (ImageData | File)[]) => {                   *** to remove any and make this function like this : fix this later
	// 	setImagesTo((prevImages: (ImageData | File)[]) => [...prevImages, ...images]);
	// }, [setImagesTo]);

	const imageUploaderComponent = useMemo(() => (
		<ImageUploader
			mode={mode}
			images={image}
			removeImage={removeImage}
			isSubmitting = {formik.isSubmitting}
			imageIdsToDelete = {imageIdsToDelete}

		/>
	), [mode, image, removeImage,formik.isSubmitting]);

	const uploadImagesComponent = useMemo(() => (
		mode !== MODE.VIEW ? (
			<UploadImages
				title="Upload Images"
				currentImageCount = {image.length}
				updateImages={updatedImages}
				configuration={{
					maxImageCount: 4,
					maxfilesize: 200,     
					documentTypes: [DOCUMENT_TYPES.IMAGE_JPEG, DOCUMENT_TYPES.IMAGE_PNG],
					documentResolution: RESOLUTIONS.SECONDARY_INVENTORY,
				}}
			/>
		) : null
	), [mode, updatedImages]);
  
  return (
    <div className=' rounded bg-white py-6 px-10 '>
    <CustomHeading text={" Image Upload"} status="" />

    {imageUploaderComponent}
		{uploadImagesComponent} 

    <div className="flex justify-end mt-4">
      <div className="flex gap-x-3">
        <Button variant="outlined" label="Back" onClick={handleBack} />
        <Button variant="contained" label="Next" onClick={handleSubmit} />
      </div>
    </div>
  </div>
  )
}

export default ImageUploadTemplate