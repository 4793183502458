import React, { useEffect, useState } from 'react'
import { useStandardService } from '../../../services/useStandardService';
import { useParams } from 'react-router-dom';
import { IStandard } from './StandardList.page';
import { MODE, RESOURCE_TYPE } from '../../../utils/constant';
import { useSnackbar } from '../../../hooks/useSnackBar';
import StandardFormTemplate from '../../templates/Standard/StandardForm.template';
import { ImageData } from '../../organisms/ImageUploader';
import { HTTP_STATUS } from '../../../utils/types';
import { useFileService } from '../../../services/useFileService';

const StandardViewPage: React.FC = () => {

    const params = useParams();
    const standardId = Number(params.id);
    const standardService = useStandardService();
    const fileService = useFileService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [standard, setStandardTo] = useState<IStandard | null>(null);
    const [image, setImagesTo] = useState<(ImageData | File)[]>([]);


    const fetchStandardById = async () => {
        if (standardId) {
            try {
                const standardResponse = await standardService.getStandardById(standardId);
                (standardResponse.status === HTTP_STATUS.OK) && setStandardTo(standardResponse?.data?.data);
                const fileResponse = await fileService.getFilesById(standardId, RESOURCE_TYPE.STANDARD_GRADE);
                (fileResponse.status === HTTP_STATUS.OK) && setImagesTo(fileResponse?.data?.data);
            } catch (error) {
                showSnackbar("error", 'Standard fetch failed');
                setStandardTo(null);
                setImagesTo([]);
            }
        }
    }
    
    useEffect(() => {
        fetchStandardById()
    }, [standardId])

    return (
        <div>
            {SnackBarComponent}
            <StandardFormTemplate standard={standard} mode={MODE.VIEW} image={image} setImagesTo={setImagesTo}/>
        </div>
    )
}
export default StandardViewPage;