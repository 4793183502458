import { createUseStyles } from "react-jss";
import { ADMIN_ROUTES, FINISHED_PRODUCTS } from "../../../../utils/constant";
import { useNavigate, useParams } from "react-router-dom";
import FinishedProductListingTemplate from "./FinishedProductListing.template";
import { useEffect, useState } from "react";
import { useFinishedProductService } from "../../../../services/useFinishedProductService";
import { HTTP_STATUS, IPagination, MY_MATERIAL_STATE } from "../../../../utils/types";
import { useSnackbar } from "../../../../hooks/useSnackBar";
import NoDataFrameTemplate from "../NoDataFrame.template";
import Button from "../../../atoms/Button";
import { UploadedImageData } from "../../../pages/CustomerManagement/FinishedProductCreation.page";
import { useBusinessProfileService } from "../../../../services/useBusinessProfileService";
import { IOTHER_PROPS } from "../../../pages/CustomerManagement/MyMaterial.page";
import ButtonV2 from "../../../atoms/ButtonV2";

const useStyles = createUseStyles((theme: any) => ({
    finishedProduct: {
        color: theme.palette.textV2.tertiary.tertiary700,
    }
}))

export interface FinishedProductsProps {
    id: number,
    name: string,
    productDescription: string,
    applicationType: string[],
    images: UploadedImageData[],
    imageListToDelete?: number[]
}

interface FinishedProductTemplateProps {
    updateMaterialStateTo: (value: MY_MATERIAL_STATE, other?: IOTHER_PROPS) => void;
}

const FinishedProductTemplate: React.FC<FinishedProductTemplateProps> = ({ updateMaterialStateTo }) => {

    const classes = useStyles();
    const navigate = useNavigate();
    const [finishedProducts, setFinishedProducts] = useState<FinishedProductsProps[]>([]);
    const finishedProductService = useFinishedProductService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [pagination, setPaginationTo] = useState<IPagination>({
        currentPage: 0,
        totalRecords: 0,
        pageSize: 10,
        totalPages: 0
    });

    const params = useParams();
    const businessId = Number(params.id);
    const bussinessProfileService = useBusinessProfileService();
    const [businessProfile, setBusinessProfileTo] = useState<{ userId: number } | null>(null);

    const getBusinessProfile = async () => {
        try {
            const businessProfileResponse = await bussinessProfileService.getBusinessProfile(businessId);
            if (businessProfileResponse.status === HTTP_STATUS.OK) {
                const profileData = businessProfileResponse?.data?.data;
                setBusinessProfileTo(profileData);
            }
        } catch (error) {
            showSnackbar('error', 'Business Profile fetch failed');
        }
    };

    const handleAddNewProduct = () => {
        updateMaterialStateTo(MY_MATERIAL_STATE.FINISHED_PRODUCT_CREATE)
    }

    const loadFinishedProduct = async () => {
        try {
            const finishedProduct = await finishedProductService.getAllFinishedProducts(businessProfile?.userId as number, { size: pagination.pageSize, page: pagination.currentPage });
            if (finishedProduct.status === HTTP_STATUS.OK) {
                setFinishedProducts(finishedProduct.data.data.content);
                setPaginationTo({
                    ...pagination,
                    totalPages: finishedProduct.data.data.totalPages,
                    totalRecords: finishedProduct.data.data.totalElements
                });
            }
        }
        catch (error) {
            showSnackbar("error", "Error Fetching Data");
        }
    }

    useEffect(() => {
        if (businessProfile)
            loadFinishedProduct();
    }, [pagination.currentPage, pagination.pageSize, businessProfile])

    useEffect(() => {
        getBusinessProfile()
    }, [])

    const handleBackToDashboard = () => {
        navigate(ADMIN_ROUTES.CUSTOMER_MANAGEMENT_LIST);
    };

    return (
        <div className="grid gap-4">
            {SnackBarComponent}
            <div className="flex justify-between pb-4 border-b">
                <span className={`text-2xl font-medium ${classes.finishedProduct}`}>{FINISHED_PRODUCTS.FINISHED_PRODUCTS_LISTING}</span>
                <ButtonV2 variant="primaryContained" label="+Add New Product" className="!font-medium" onClick={handleAddNewProduct} />
            </div>
            <div >
                {!!finishedProducts.length ?
                    <FinishedProductListingTemplate finishedProducts={finishedProducts} loadFinishedProduct={loadFinishedProduct} updateMaterialStateTo={updateMaterialStateTo} setPaginationTo={setPaginationTo} pagination={pagination} />
                    : <div className="mt-20">
                        <NoDataFrameTemplate title="No Result Found" message="You haven't provided Finished Product for your business. " />
                    </div>}
            </div>
            <div className=' grid justify-items-stretch pt-6'>
                <ButtonV2
                    className={` justify-self-end`}
                    variant="secondaryContained"
                    label="Back to Dashboard"
                    onClick={handleBackToDashboard}
                />
            </div>
        </div>
    )
}

export default FinishedProductTemplate;