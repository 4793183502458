import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CachedIcon from "@mui/icons-material/Cached";
import ListAltIcon from "@mui/icons-material/ListAlt";
import completedBackgroundPng from "../../../assets/images/cardBackgroundRed.png";
import listBackgroundPng from "../../../assets/images/cardBackgroundGreen.png";
import ongoingBackgroundPng from "../../../assets/images/cardBackgroundOrange.png";
import cancelBackgroundPng from "../../../assets/images/cardBackgroundTurquoise.png";
import TitleHeading from "../../atoms/CustomHeading/TitleHeading";
import { IPagination } from "../../../utils/types";
import RecordCard from "../../atoms/Cards/RecordCard";
import { initialPaginationValues } from "../../../utils/constant";
import { useOrderService } from "../../../services/useOrderService";
import OrderMasterListTemplate from "../../templates/Order/OrderMasterList.template";

export interface IOrder {
	id: number;
	orderNo: string;
	customerId: number;
	orderTotal: number;
	orderStatus: string;
	paymentStatus: string;
	productItem: number;
	paymentMode: string;
	createdBy: number;
	createdAt: string;
	updatedBy: number;
	customerName: string;
	companyName: string;
	totalQuantity: number;
	consigneeDetails: string;
	customerDetails: string;
	vehicleDetails: string;
	quotationId: number;
}

interface IOrderCounts {
	total: number;
	ongoing: number;
	completed: number;
	cancel: number;
}

export interface IOrderMasterFilter {
	customerName: string;
	status: string;
}

const initialOrderFiltersValues: IOrderMasterFilter = {
	customerName: "",
	status: "ALL",
};

export interface IStatusUpdate {
	id: number | null;
	orderStatus: string;
	paymentStatus: string;
}

const initialStatusUpdateValues: IStatusUpdate = {
	id: null,
	orderStatus: "",
	paymentStatus: "",
};

const OrderMasterList: React.FC = () => {
	const orderService = useOrderService();
	const [orderMaster, setOrderMasterTo] = useState<IOrder[]>([]);
	const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);
	const [orderMasterFilters, setOrderMasterFiltersTo] = useState<IOrderMasterFilter>(initialOrderFiltersValues);
	const [statusUpdate, setStatusUpdate] = useState<IStatusUpdate>(initialStatusUpdateValues);
	const [orderMasterCount, setOrderMasterCountTo] = useState<IOrderCounts>({
		total: 0,
		ongoing: 0,
		completed: 0,
		cancel: 0,
	});

	const refreshOrderMasters = async (page: number, size: number) => {
		try {
			const params = {
				page: page,
				size: size,
				orderStatus: orderMasterFilters?.status === "ALL" ? "" : orderMasterFilters?.status,
				customerName: orderMasterFilters?.customerName.trim(),
				sort: "createdAt,desc",
			};
			const orderMasterResponse = await orderService.getOrderMaster(params);
			if (orderMasterResponse?.status === 200 && orderMasterResponse?.data?.data?.orderMaster?.content?.length) {
				const { orderMaster, orderMasterStats } = orderMasterResponse.data.data;
				setOrderMasterTo(orderMaster.content);
				setPaginationTo((prevPagination: IPagination) => ({
					...prevPagination,
					totalRecords: orderMaster.totalElements,
					totalPages: orderMaster.totalPages,
				}));
				setOrderMasterCountTo(orderMasterStats);
			} else {
				throw new Error("Leads fetch failed");
			}
		} catch (error) {
			setOrderMasterTo([]);
		}
	};

	const orderStats = [
		{
			backgroundImg: listBackgroundPng,
			icon: <ListAltIcon className="text-white" sx = {{ fontSize: 50 }} />,
			title: "All Orders",
			value: orderMasterCount.total ? orderMasterCount.total : 0,
		},
		{
			backgroundImg: ongoingBackgroundPng,
			icon: <CachedIcon className="text-white" sx = {{ fontSize: 50 }} />,
			title: "Ongoing Orders",
			value: orderMasterCount.ongoing ? orderMasterCount.ongoing : 0,
		},
		{
			backgroundImg: completedBackgroundPng,
			icon: <CheckCircleIcon className="text-white" sx = {{ fontSize: 50 }} />,
			title: "Completed Orders",
			value: orderMasterCount.completed ? orderMasterCount.completed : 0,
		},
		{
			backgroundImg: cancelBackgroundPng,
			icon: <CancelIcon className="text-white" sx = {{ fontSize: 50 }} />,
			title: "Cancel Orders",
			value: orderMasterCount.cancel ? orderMasterCount.cancel : 0,
		},
	];

	const handleFiltersChange = (event: any) => {
		const { name, value } = event.target;
		setPaginationTo({...pagination, currentPage: 0})
		setOrderMasterFiltersTo({ ...orderMasterFilters, [name]: value ?? "" });
	};

	const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPaginationTo((prevPagination) => ({
			...prevPagination,
			currentPage: newPage,
		}));
	};

	const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newRowsPerPage = parseInt(event.target.value, 10);
		setPaginationTo((prevPagination) => ({
			...prevPagination,
			pageSize: newRowsPerPage,
		}));
	};

	const handleUpdateQuotation = (id: number) => {
		setStatusUpdate({ ...statusUpdate, id: id });
	};

	const handleSubmit = async () => {
		let data = {};
		if (statusUpdate.orderStatus.length > 0 && statusUpdate.paymentStatus.length === 0) {
			data = {
				orderStatus: statusUpdate.orderStatus,
			};
		} else if (statusUpdate.paymentStatus.length > 0 && statusUpdate.orderStatus.length === 0) {
			data = {
				paymentStatus: statusUpdate.paymentStatus,
			};
		} else if (statusUpdate.paymentStatus.length > 0 && statusUpdate.orderStatus.length > 0) {
			data = {
				orderStatus: statusUpdate.orderStatus,
				paymentStatus: statusUpdate.paymentStatus,
			};
		}
		if (statusUpdate.id !== null) {
			try {
				const response = await orderService.updateOrderMaster(statusUpdate.id, data);
				if (response.status === 200 && response?.data?.data) {
					setStatusUpdate({
						id: null,
						orderStatus: "",
						paymentStatus: "",
					});
					refreshOrderMasters(pagination.currentPage, pagination.pageSize);
				} else {
					console.error("Failed to update quotation data:", response);
				}
			} catch (error) {
				throw error;
			}
		}
	};

	useEffect(() => {
		refreshOrderMasters(pagination.currentPage, pagination.pageSize);
	}, [pagination.currentPage, pagination.pageSize, orderMasterFilters.status, orderMasterFilters.customerName]);

	return (
		<div className="grid gap-y-4">
			<div className="flex justify-between">
				<TitleHeading text="Order Management" />
			</div>
			<div className="grid gap-y-4">
				<RecordCard cards = { orderStats } />
				<OrderMasterListTemplate
					data = { orderMaster }
					pagination = { pagination }
					handlePaginationChange = { handlePaginationChange }
					handleRowsPerPageChange = { handleRowsPerPageChange }
					orderMasterFilters = { orderMasterFilters}
					handleFiltersChange = { handleFiltersChange }
					setStatusUpdate = { setStatusUpdate }
					statusUpdate = { statusUpdate }
					handleUpdateQuotation = { handleUpdateQuotation }
					handleSubmit = { handleSubmit }
				/>
			</div>
		</div>
	);
};

export default OrderMasterList;
