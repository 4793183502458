import React from 'react'
import { useSnackbar } from '../../../hooks/useSnackBar';
import { ADMIN_ROUTES } from '../../../utils/constant';
import SuperCategoryFormTemplate from '../../templates/Category/SuperCategoryForm.template';
import { Category, CategoryPayload, useCategoryService } from '../../../services/useCategoryService';
import { HTTP_STATUS } from '../../../utils/types';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { makeRoute } from '../../../utils/helper';

interface SuperCategoryUpdatePageProps {
  mode: string
  category: Category
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Category Name is required"),
  description: Yup.string().required("Category Description is required")
  .test('no-p-br-tags', 'Defect details cannot be Empty', ((value:any)=> value !== '<p><br></p>')),
});

const SuperCategoryUpdatePage:React.FC<SuperCategoryUpdatePageProps> = ({mode, category}) => {
  const { showSnackbar, SnackBarComponent } = useSnackbar();
  const categoryService = useCategoryService();
  const navigate = useNavigate();

  const params = useParams();
  const categoryId = Number(params.id);

  const onBack = () => {
    navigate(makeRoute(ADMIN_ROUTES.CATEGORY, {query: { type: 'SUPER_CATEGORY'}}));
  }

  const updateCategory = async (category: CategoryPayload) => {
    categoryService.updateCategory(categoryId, category)
    .then(res => {
      if (res.status === HTTP_STATUS.OK) {
        onBack();
      } else if (res.data.statusMessage === "DUPLICATE_CATEGORY") {
        showSnackbar('error', "Category already exists. Please try again");
      }
    }).catch((error) => {
      console.error("Error updating Category: ", error);
      showSnackbar('error', "Error updating Super category");
    })
  }

  const formik = useFormik<CategoryPayload>({
    initialValues: {
      name: category?.name ?? "",
      status: category?.status,
      description: category?.description ?? "",
      level: 0,
      prefix: null,
      parentId: null
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      updateCategory({ ...values, status: values.status })
    }
  })

  return (
    <div>
      {SnackBarComponent}
      <SuperCategoryFormTemplate mode={mode} onBack={onBack} formik={formik}/>
    </div>
  )
}

export default SuperCategoryUpdatePage