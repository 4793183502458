import React, { useState } from 'react';
import { createUseStyles } from "react-jss";
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';

interface DocumentViewTemplateProps {
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
    url: string;
    documentType: string;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        "& .MuiDialog-paper": {
            width: "694px !important",
            maxWidth: "694px !important",
            borderRadius: "16px"
        },
    },
    closeIcon: {
        color: theme.palette.textV2.secondary.secondary500
    }
}));

const DocumentViewTemplate: React.FC<DocumentViewTemplateProps> = ({ dialogOpen, setDialogOpen, url, documentType }) => {
    const classes = useStyles();
    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const renderContent = () => {
        const imageTypes = ['jpeg', 'jpg', 'png', 'gif'];
        if (imageTypes.includes(documentType)) {
            return <img src={url} alt="document" style={{ maxWidth: '100%', maxHeight: '100%' }} />;
        } else if (documentType === 'pdf') {
            return (
                <div>
                <embed src={url} width="694px" height="870px" type="application/pdf" />
                </div>
            );
        } else {
            return <p>Unsupported document type</p>;
        }
    };

    return (
        <Dialog
            fullWidth
            className={classes.container}
            PaperProps={{ style: { height: 'auto' } }}
            open={dialogOpen}
            onClose={handleDialogClose}
        >
            <div className={`absolute top-0 right-0 p-4 cursor-pointer`} onClick={handleDialogClose}>
                <CloseIcon className={`${classes.closeIcon}`} />
            </div>
            <div className={`flex gap-x-6`}>
                <div className="grid gap-y-8 p-10 ">
                    {renderContent()}
                </div>
            </div>
        </Dialog>
    );
};

export default DocumentViewTemplate;