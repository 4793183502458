
import React, { useEffect, useState } from 'react';
import { createUseStyles } from "react-jss";
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';

import { IVendorStatutoryDetailsForm } from '../../../pages/VendorManagement/VendorStatutoryDetail.page';
import { FormikErrors, FormikProps } from 'formik';
import ButtonV2 from '../../../atoms/ButtonV2';
import TextFieldV2 from '../../../atoms/TextFieldV2';
import Checkbox from '@mui/material/Checkbox';
import AlertPopupModelTemplate from '../../Dashboard/AlertPopupModel.template';
import { IBankAccounts } from './VendorUploadBankDetails.template';

interface IPopUpModalTemplateProps {
    dialogOpen: boolean;
    formik: FormikProps<IVendorStatutoryDetailsForm>;
    handleDialogClose: (value: boolean) => void;
    index: number;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        "& .MuiDialog-paper": {
            width: "1032px !important",
            maxWidth: "1032px !important",
            borderRadius: "16px"
        },
    },
    closeIcon: {
        color: theme.palette.textV2.secondary.secondary500
    },
    heading: {
        color: theme.palette.text._secondaryLight
    },
    textColor: {
        color: theme.palette.textV2.tertiary.tertiary900
    },
    button: {
        paddingLeft: "89px !important",
        paddingRight: "89px !important",
        color: theme.palette.button.secondaryDark,
    },
    errorMessage: {
        color: theme.palette.textV2.failure.failure500,
    },
    primaryText: {
        color: theme.palette.textV2.primary.primary950
    },
    checkBoxBorder:{
        color: theme.palette.textV2.tertiary.tertiary300
    },
    checkBox:{
        color: theme.palette.textV2.primary.primary950
    }
}));

const EditBankDetailModalTemplate: React.FC<IPopUpModalTemplateProps> = ({ dialogOpen, handleDialogClose, formik , index }) => {
    const classes = useStyles();
    const [isAlertModalOpen, setIsAlertModalOpen] = useState<boolean>(false);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        if (checked) {
            const hasDefaultBankDetails = formik.values.bankDetails.some(detail => detail.isDefault === true);
            if(hasDefaultBankDetails){setIsAlertModalOpen(true)}
            formik.setFieldValue(`bankDetails.${index}.isDefault`, true);
        } else {
            formik.setFieldValue(`bankDetails.${index}.isDefault`, false);
        }
    };

    const handleDialogCloseAlert = (event: boolean) => {
        if (event) {
            setIsAlertModalOpen(false);
            const updatedBankDetails = formik.values.bankDetails.map(detail => ({
                ...detail,
                isDefault: false
            }));
            formik.setFieldValue("bankDetails", updatedBankDetails);

            formik.setFieldValue(`bankDetails.${index}.isDefault`, true);
        } else {
            setIsAlertModalOpen(false);
            formik.setFieldValue(`bankDetails.${index}.isDefault`, false);
        }
    };
    const handleClose = (event : boolean) =>{
      !(!!formik.errors.bankDetails) && handleDialogClose(event)
    };

    const onChangeField = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        formik.setFieldValue(`bankDetails.${index}.${name}`, value);
    }
    return (
        <Dialog
            fullWidth
            className={classes.container}
            PaperProps={{ style: { height: 'auto' } }}
            open={dialogOpen}
            onClose={() => handleDialogClose(false)}
        >

            <div className='m-6'>
              <div className=' flex justify-between'>
                  <div className={`${classes.textColor} text-2xl font-medium  `}>Edit Vendor Bank Details</div>
                  <div  onClick={() => handleDialogClose(false)}><CloseIcon className={classes.closeIcon} /></div>
               </div>
              <div className=' flex  gap-6 mt-4'>
                <div className='w-1/3'>
                <TextFieldV2
                        className="bg-white rounded-lg"
                        variant="outlined"
                        label="Account Holder Name"
                        placeholder="Enter Name"
                        sx={{ width: '30%' }}
                        name="accountName"
                        value={formik.values.bankDetails[index].accountName}
                        onChange={onChangeField}
                    />
                    { formik.errors && formik?.errors?.bankDetails && (formik.errors.bankDetails as FormikErrors<IBankAccounts>[])[index].accountName && (
                        <div className={classes.errorMessage}>
                            <small>{(formik.errors.bankDetails as FormikErrors<IBankAccounts>[])?.[index].accountName}</small>
                        </div>
                    )}
                </div>
                    
                <div className='w-1/3'>
                    <TextFieldV2
                        className="bg-white rounded-lg"
                        variant="outlined"
                        label="Account Number"
                        placeholder="Enter Number"
                        fullWidth
                        name="accountNumber"
                        value={formik.values.bankDetails[index].accountNumber}
                        onChange={onChangeField}
                    />
                    { formik.errors && formik.errors.bankDetails && (formik.errors.bankDetails as FormikErrors<IBankAccounts>[])[index].accountNumber && (
                        <div className={classes.errorMessage}>
                            <small>{(formik.errors.bankDetails as FormikErrors<IBankAccounts>[])[index].accountNumber}</small>
                        </div>
                    )}
                    </div>
                    <div className='w-1/3'>
                 <TextFieldV2
                        className="bg-white rounded-lg"
                        variant="outlined"
                        label="IFSC Code"
                        placeholder="Enter IFSC Code"
                        fullWidth
                        name="ifsc"
                        value={formik.values.bankDetails[index].ifsc}
                        onChange={onChangeField}
                    />
                    { formik.errors && formik.errors.bankDetails && (formik.errors.bankDetails as FormikErrors<IBankAccounts>[])[index].ifsc && (
                        <div className={classes.errorMessage}>
                            <small>{(formik.errors.bankDetails as FormikErrors<IBankAccounts>[])[index].ifsc}</small>
                        </div>
                    )}
                    </div>
                </div>
                <div className=' flex gap-6 mt-6'>
                <div className='w-1/3'>
                    <TextFieldV2
                        className="bg-white rounded-lg"
                        variant="outlined"
                        label="Bank Name"
                        placeholder="Enter bank Name"
                        fullWidth
                        name="bankName"
                        value={formik.values.bankDetails[index].bankName}
                        onChange={onChangeField}
                    />
                   {formik.errors && formik.errors.bankDetails && (formik.errors.bankDetails as FormikErrors<IBankAccounts>[])[index].bankName && (
                        <div className={classes.errorMessage}>
                            <small>{(formik.errors.bankDetails as FormikErrors<IBankAccounts>[])[index].bankName}</small>
                        </div>
                    )}
                    </div>
                    <div className='w-1/3'>
                    <TextFieldV2
                        className="bg-white rounded-lg"
                        variant="outlined"
                        label="Branch Name"
                        placeholder="Enter Branch Name"
                        fullWidth
                        name="branch"
                        value={formik.values.bankDetails[index].branch}
                        onChange={onChangeField}
                    />
                    { formik.errors && formik.errors.bankDetails && (formik.errors.bankDetails as FormikErrors<IBankAccounts>[])[index].branch && (
                        <div className={classes.errorMessage}>
                            <small>{(formik.errors.bankDetails as FormikErrors<IBankAccounts>[])[index].branch}</small>
                        </div>
                    )}
                    </div>
                    {
                    isAlertModalOpen &&
                    <AlertPopupModelTemplate
                        dialogOpen={isAlertModalOpen}
                        heading={"Change Primary Bank Account"}
                        text={"You have already added the primary bank account are you sure you want to change the primary bank account ?"}
                        handleDialogClose={handleDialogCloseAlert}
                    />
                }
                    <div className='w-1/3 grid content-end'>
                    <div className='flex '>
                    <Checkbox
                        checked={formik.values.bankDetails[index].isDefault}
                        onChange={handleCheckboxChange}
                        name="isDefault"
                        sx={{
                            color: '#B3B3B3',
                            '&.Mui-checked': {
                              color: '#FD6102',
                            },
                        }}
                    />
                    <text className={`${classes.primaryText} self-center text-base font-normal mr-4`}>Primary Bank Account</text>
                    </div>
                    </div>
                </div>

                <div className="flex mt-6 justify-end  gap-4">
                <ButtonV2
                    variant="tertiaryContained"
                    label="Cancel"
                    onClick={() => handleDialogClose(false)}
                />
                <ButtonV2
                    variant="primaryContained"
                    label="Save"
                    onClick={() => handleClose(true)}
                    disabled={!!formik.errors.bankDetails}
                />
            </div>
            </div>   
        </Dialog>
    );
}

export default EditBankDetailModalTemplate;


