import { Route, Routes } from 'react-router-dom';
import ManufacturerListPage from '../../../components/pages/AdminMaster/Manufacturer/ManufacturerList.page';
import ManufacturerCreatePage from '../../../components/pages/AdminMaster/Manufacturer/ManufacturerCreate.page';
import ManufacturerUpdatePage from '../../../components/pages/AdminMaster/Manufacturer/ManufacturerUpdate.page';
import ManufacturerViewPage from '../../../components/pages/AdminMaster/Manufacturer/ManufacturerView.page';

const ManufacturerRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<ManufacturerListPage />} />
            <Route path="/add" element={<ManufacturerCreatePage />} />
            <Route path="/:id" element={<ManufacturerViewPage />} />
            <Route path="/:id/update" element={<ManufacturerUpdatePage />} />
        </Routes>
    )
}

export default ManufacturerRoutes;
