import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import LiveAuctionCustomHeading from '../../../Components/Procurement/LiveAuction/LiveAuctionCustomHeading';
import { AUCTION } from '../../../Constants/Procurement/LiveAuction/text';
import BidDetails from '../../../Components/Procurement/LiveAuction/BidDetails';
import ProductDetails from '../../../Components/Procurement/LiveAuction/ProductDetails';
import AuctionButtons from '../../../Components/Procurement/LiveAuction/AuctionButtons';
import PriceDetails from '../../../Components/Procurement/LiveAuction/PriceDetails';
import StartEndTime from '../../../Components/Procurement/LiveAuction/StartEndTime';
import { useEffect, useState } from 'react';
import CustomModal from '../../../Shared/Modal/CustomModal';
import { AxiosResponse } from 'axios';
import {
  Auction,
  AuctionProduct,
  AuctionProductList,
  Bidder,
  ModalType,
} from '../../../Constants/Procurement/LiveAuction/constants';
import ProductModal from '../../../Components/Procurement/LiveAuction/Modals/ProductModal';
import AwardModal from '../../../Components/Procurement/LiveAuction/Modals/AwardModal';
import CloseModal from '../../../Components/Procurement/LiveAuction/Modals/CloseModal';
import RestartModal from '../../../Components/Procurement/LiveAuction/Modals/RestartModal';
import ReScheduleModal from '../../../Components/Procurement/LiveAuction/Modals/ReScheduleModal';
import ExtendTimeModal from '../../../Components/Procurement/LiveAuction/Modals/ExtendTimeModal';
import ModalWrapper from '../../../Components/Procurement/LiveAuction/Modals/ModalWrapper';
import { NavigationURL } from '../../../Constants/EndPoints';
import {
  dateFormat,
  timeFormat,
} from '../../../Constants/Procurement/constants';
import { ERROR_MESSAGE } from '../../../../utils/helper';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import StatusChips, { StatusType } from '../../../Shared/Chips/StatusChips';
import { useLiveAuctionService } from '../../../../services/useLiveAuctionService';
import { usePurchaseRequisition } from '../../../../services/usePurchaseRequisition';

let auctionTimeout: NodeJS.Timeout;
let dateInterval: NodeJS.Timeout;
const timer = 10000;

const LiveAuction = () => {
  const params = useParams();
  const auctionId = params.auctionId;
  const navigate = useNavigate();
  const { showSnackbar, SnackBarComponent } = useSnackbar();
  const { token }: any = useAuthenticatedUser().user;

  const [auction, setAuction] = useState<Auction>();
  const [quantity, setQuantity] = useState<number>(0);
  const [productList, setProductList] = useState<AuctionProductList[]>([]);
  const [bidder, setBidder] = useState<Bidder>();

  const [date, setDate] = useState(
    moment().format('dddd, DD MMM, YYYY, hh:mm:ss A')
  );
  const [pause, setPause] = useState(true);
  const [sta, setSta] = useState(false);
  const [live, setLive] = useState(false);
  const [awarded, setAwarded] = useState(false);
  const [hasEnded, setHasEnded] = useState(false);
  const [rescheduleStart, setRescheduleStart] = useState<Date>();
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const [open, setOpen] = useState<ModalType | null>(null);

  const {
    cancelAuction,
    closeAuction,
    rescheduleAuction,
    extendAuction,
    awardAuction,
    resumeAuction,
    pauseAuction,
    fetchAuction,
  } = useLiveAuctionService();
  const { getPurchaseRequistions } = usePurchaseRequisition();

  const handleCloseAuction = () => {
    let request;
    if (open === 'close') {
      request = closeAuction(auctionId!);
    } else {
      request = cancelAuction(auctionId!);
    }
    request
      .then((response: AxiosResponse) => {
        const responseData: Auction = response.data.data;
        setAuction({ ...auction, ...responseData });
        if (open === 'cancel') {
          navigate(NavigationURL.liveAuction);
        }
      })
      .catch((err) => {
        showSnackbar('error', ERROR_MESSAGE(err));
      })
      .finally(() => handleClose());
  };
  const handleReScheduleStart = (val: Date) => {
    setRescheduleStart(val);
    setIsEnd(true);
    setOpen('rescheduleEnd');
  };

  const handleReScheduleEnd = (val: Date, isRestart?: boolean) => {
    const auctionFromDate = isRestart
      ? moment().unix()
      : moment(rescheduleStart).unix();
    const auctionToDate = moment(val).unix();

    rescheduleAuction(auctionId!, auctionFromDate, auctionToDate)
      .then((response: AxiosResponse) => {
        const responseData: Auction = response.data.data;
        setAuction({ ...auction, ...responseData });

        setIsEnd(false);
      })
      .catch((err) => {
        showSnackbar('error', ERROR_MESSAGE(err));
      })
      .finally(() => handleClose());
  };

  const handleExtendTime = (val: Date) => {
    const auctionToDate = moment(val).unix();

    extendAuction(auctionId!, auctionToDate)
      .then((response: AxiosResponse) => {
        const responseData: Auction = response.data.data;
        setAuction({ ...auction, ...responseData });
        setIsEnd(false);
      })
      .catch((err) => {
        showSnackbar('error', ERROR_MESSAGE(err));
      })
      .finally(() => handleClose());
  };

  let timeout: NodeJS.Timeout;
  const handleAward = () => {
    awardAuction(auctionId!, bidder?.id!)
      .then((response: AxiosResponse) => {
        const responseData: Auction = response.data.data;
        setAuction({ ...auction, ...responseData });
        setAwarded(true);
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          setAwarded(false);
          handleClose();
        }, 1000);
      })
      .catch((err) => {
        showSnackbar('error', ERROR_MESSAGE(err));
      });
  };

  const handlePause = () => {
    let request;
    if (pause) {
      request = resumeAuction(auctionId!);
    } else {
      request = pauseAuction(auctionId!);
    }
    request
      .then((response: AxiosResponse) => {
        const responseData: Auction = response.data.data;
        setAuction({ ...auction, ...responseData });
        setPause(!pause);
      })
      .catch((err) => {
        showSnackbar('error', ERROR_MESSAGE(err));
      });
  };

  const handleDownload = () => {
    if (auction && auction.rfqDocURLs && auction.rfqDocURLs.length > 0) {
      window.open(auction.rfqDocURLs[0], '_blank');
    }
  };

  useEffect(() => {
    if (dateInterval) clearInterval(dateInterval);
    dateInterval = setInterval(() => {
      setDate(moment().format('dddd, DD MMM, YYYY, hh:mm:ss A'));
    }, 1000);

    return () => {
      if (dateInterval) clearInterval(dateInterval);
    };
  }, []);

  useEffect(() => {
    if (auctionTimeout) clearInterval(auctionTimeout);
    if (hasEnded && auction) {
      updateStates(auction);
      return;
    }
    fetchAuctionDetails();
    auctionTimeout = setInterval(() => {
      fetchAuctionDetails();
    }, timer);
    return () => clearInterval(auctionTimeout);
  }, [open, bidder, hasEnded, auction?.status, productList]);

  const handleClose = () => {
    setOpen(null);
  };

  const fetchAuctionDetails = () => {
    fetchAuction(auctionId!)
      .then((response) => {
        const {
          items,
          auction,
        }: { items: AuctionProduct[]; auction: Auction } = response?.data.data;
        updateStates(auction, items);
      })
      .catch((err) => {
        showSnackbar('error', ERROR_MESSAGE(err));
      });
  };

  const updateStates = (auction: Auction, items?: AuctionProduct[]) => {
    if (auction) {
      setPause(auction?.status?.toUpperCase() === 'PAUSED');
      const currentTime = moment().unix();
      const auctionEnded = auction.endDateTime <= currentTime;
      const auctionStarted = auction.startDateTime <= currentTime;
      setHasEnded(auctionEnded);

      setSta(
        auctionEnded &&
          auction.status.toUpperCase() === 'READY_FOR_GO_LIVE' &&
          Boolean(bidder?.bidAmount)
      );
      const quantity =
        items?.reduce((a: number, b: AuctionProduct) => a + b.quantity, 0) || 0;
      setQuantity(quantity);
      setLive(auctionStarted);
      setAuction(auction);
      if (items && items.length > 0 && productList.length === 0)
        fetchProductList(items);
    }
  };

  const fetchProductList = (items: AuctionProduct[]) => {
    if (productList.length > 0) return;
    const prIds = items?.reduce((acc, item, index) => {
      if (index === 0) {
        return item.purchaseRequisitionId.toString();
      } else {
        return acc + ',' + item.purchaseRequisitionId;
      }
    }, '');
    const queryParams: Record<string, string | number> = {
      prIDs: prIds,
      pageSize: 100,
      pageNumber: 0,
    };

    getPurchaseRequistions(queryParams)
      .then((response: AxiosResponse) => {
        const products = response.data?.data?.content;

        setProductList(products);
      })
      .catch((err) => {
        showSnackbar('error', ERROR_MESSAGE(err));
      });
  };

  const handleStatus = (status: string) => {
    switch (status) {
      case 'READY_FOR_GO_LIVE':
        if (live && !hasEnded) {
          return 'Live';
        }
        if (hasEnded) return 'Ended';
        return 'Ready for Go Live';
      case 'AWARDED':
        return 'Completed';
      case 'CLOSED':
        return 'Closed';
      case 'PAUSED':
        return 'Paused';
      case 'CANCELLED':
        return 'Cancelled';
      default:
        return status;
    }
  };
  const handleStatusType = (status: string): StatusType => {
    switch (status) {
      case 'READY_FOR_GO_LIVE':
        if (live && !hasEnded) return 'success';
        return 'default2';
      case 'LIVE':
        return 'ongoing';
      case 'CLOSED':
        return 'cancelled';
      case 'PAUSED':
        return 'pending';
      case 'CANCELLED':
        return 'cancelled';
      default:
        return 'default';
    }
  };

  return (
    <>
      {SnackBarComponent}
      <div className="grid gap-y-3">
        <header className="text-blue">
          <div className="flex justify-between p-3">
            <div className="text-[32px] font-semibold">
              {live ? AUCTION.LiveAuction : AUCTION.ScheduleAuction}
            </div>
            <div>
              <div className="font-medium">{date}</div>
              {auction && auction.status ? (
                <StatusChips
                  text={handleStatus(auction.status)}
                  type={handleStatusType(auction.status)}
                />
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="flex px-4 py-5 rounded-md bg-cgray-16 my-4 justify-between ">
            <div className="flex items-center gap-10 py-2">
              <div className="text-[20px] font-semibold">
                {AUCTION.AuctionId} {auctionId}
              </div>
              <div className="text-[20px] font-semibold">
                {AUCTION.IndentId} {auction?.entityID}
              </div>
            </div>
            {sta ? (
              <div className="border border-yellow-variant2 text-yellow-variant2 bg-yellow-variant1 w-[94px] h-[52px] rounded-md flex items-center justify-center">
                STA
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="flex justify-between bg-cgray-2 px-4 py-3 my-4 rounded-md">
            <LiveAuctionCustomHeading
              label={AUCTION.AuctionName}
              value={auction?.auctionName || ''}
              variant="medium"
            />
            <LiveAuctionCustomHeading
              label={AUCTION.Quantity}
              value={quantity.toString()}
              variant="medium"
            />

            <div className="flex gap-10">
              <LiveAuctionCustomHeading
                label={!sta ? AUCTION.StartDate : AUCTION.StartTime}
                value={
                  auction?.startDateTime
                    ? moment
                        .unix(auction.startDateTime)
                        .format(sta ? timeFormat : dateFormat)
                    : ''
                }
                variant="small"
              />
              <LiveAuctionCustomHeading
                label={!sta ? AUCTION.EndDate : AUCTION.EndTime}
                value={
                  auction?.endDateTime
                    ? moment
                        .unix(auction.endDateTime)
                        .format(sta ? timeFormat : dateFormat)
                    : ''
                }
                variant="small"
              />
            </div>
          </div>
          {sta ? (
            ''
          ) : (
            <StartEndTime
              startDateTime={auction?.startDateTime || 0}
              endDateTime={auction?.endDateTime || 0}
              pause={pause}
              handleExtendTime={() => setOpen('extend')}
              handlePause={handlePause}
              isLive={live}
              hasEnded={hasEnded}
              open={open}
            />
          )}
        </header>
        <PriceDetails
          live={live}
          auctionStartAmount={auction?.auctionStartAmount || 0}
          reserveAmount={auction?.reserveAmount || 0}
          targetAmount={auction?.targetAmount || 0}
          minBidStep={auction?.minBidStep || 0}
          currentBid={bidder?.bidAmount}
        />
        {live ? (
          <BidDetails
            hasEnded={hasEnded}
            live={live}
            auctionId={auctionId}
            setCurrentBidder={(val: Bidder) => setBidder(val)}
          />
        ) : (
          ''
        )}
        <AuctionButtons
          handleCancel={() => setOpen('cancel')}
          handleReSchedule={() => setOpen('rescheduleStart')}
          handleAward={() => setOpen('award')}
          handleClose={() => setOpen('close')}
          handleRestart={() => setOpen('restart')}
          handleDownload={handleDownload}
          hasBids={Boolean(bidder?.bidAmount)}
          status={auction?.status}
          hasEnded={hasEnded}
          islive={live}
        />
        <div>
          <div className="text-text-color font-semibold text-2xl py-3 border-b border-cgray-3">
            {AUCTION.ProductDetails}
          </div>
          <div>
            <ProductDetails
              indentId={auction?.entityID || ''}
              products={productList}
              quantity={quantity}
              onClick={() => setOpen('product')}
            />
          </div>
        </div>
        <div className="h-screen"></div>
      </div>
      <CustomModal open={Boolean(open)} onClose={handleClose}>
        {open !== 'award' && open !== 'close' && open !== 'cancel' ? (
          <ModalWrapper handleClose={handleClose} type={open}>
            {open === 'product' ? (
              <ProductModal productList={productList} />
            ) : null}
            {open === 'restart' ? (
              <RestartModal
                handleRestart={(val) => handleReScheduleEnd(val, true)}
              />
            ) : null}
            {open === 'rescheduleStart' ? (
              <ReScheduleModal
                value={rescheduleStart}
                onChange={(val) => handleReScheduleStart(val)}
              />
            ) : null}
            {open === 'rescheduleEnd' ? (
              <ReScheduleModal
                onChange={(val) => handleReScheduleEnd(val)}
                isEnd={isEnd}
                handleBack={() => setOpen('rescheduleStart')}
              />
            ) : null}
            {open === 'extend' ? (
              <ExtendTimeModal
                startDateTime={auction?.startDateTime || 0}
                remainingTime={auction?.endDateTime || 0}
                handleExtendTime={(val) => handleExtendTime(val)}
                isLive={live}
                isPause={pause}
                open={open}
              />
            ) : null}
          </ModalWrapper>
        ) : open === 'award' ? (
          <AwardModal
            onClose={handleClose}
            handleAward={handleAward}
            bidder={bidder}
            success={awarded}
          />
        ) : (
          <CloseModal
            isCancel={open === 'cancel'}
            onClose={handleClose}
            handleCloseAuction={handleCloseAuction}
          />
        )}
      </CustomModal>
    </>
  );
};

export default LiveAuction;
