
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import SyncIcon from '@mui/icons-material/Sync';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Collapse
} from "@mui/material";

import debounce from 'lodash/debounce';
import TablePagination from "../../../AdminMaster/Product Standard/Grade/TablePagination";
import IconValueComponent from "./IconComponent";
import Chip from '@mui/material/Chip';
import CustomInputTagsIndex from "../../../../Shared/Chips/CustomInputTags";
import Box from '@mui/material/Box';
import { useAuthenticatedUser } from "../../../../../hooks/useAuthenticatedUser";

const BaseUrl = process.env.REACT_APP_API_V1_URL;

const CustomerTableHeadings = () => {

  return (



    <div className="relative bg-blue w-full flex flex-row p-[0.75rem] box-border items-end justify-start gap-[0.75rem] text-left text-[0.88rem] text-white font-roboto">
  <div className="flex-1 flex flex-col items-start justify-start">
    <div className="flex items-center justify-left w-full sm:w-[calc(11rem / 1.6)]">
      <div className="relative font-semibold text-left">Sr.no</div>
    </div>
  </div>

  <div className="flex-1 flex flex-col items-center justify-left">
    <div className="relative font-semibold text-left w-full sm:w-[calc(16rem / 1.6)] bg-red">Mobile Number</div>
  </div>

  <div className="flex-1 flex flex-col items-start justify-start">
    <div className="relative font-semibold text-left w-full sm:w-[calc(16rem / 1.6)]">Customer Name</div>
  </div>

  <div className="flex-1 flex flex-col items-center justify-left">
    <div className="relative font-semibold text-left w-full sm:w-[calc(16rem / 1.6)]">Company Name</div>
  </div>

  <div className="flex-1 flex flex-col items-center justify-start">
    <div className="relative font-semibold text-center w-full sm:w-[calc(56rem / 1.6)]">Tags</div>
  </div>

  <div className="flex-1 flex flex-col items-center justify-start">
    <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">Whatsapp Status</div>
  </div>
</div>

  );
};

const CustomerRecords = ({ catalogueDetails, curIndex = 0 }) => {


  const [expandedRows, setExpandedRows] = useState({});

  useEffect(()=>{
      const initialExpandedRows = catalogueDetails.reduce((acc, item) => {
        if (item?.tags.length > 4) {
          acc[item.id] = false;
        }
        return acc;
      }, {});
      setExpandedRows(initialExpandedRows);
  }, [])

  const toggleExpansion = (index) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [index]: !prevExpandedRows[index],
    }));
  };

  if (!catalogueDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {catalogueDetails.length > 0 &&
        catalogueDetails.map((item, index) => (
          <div key={index} className="catalogue-details self-stretch flex flex-row p-[0.75rem] items-center justify-start gap-[0.75rem] border-b border-gray-300">

            <div className="flex-1 flex flex-col items-center justify-left">
              <div className="relative  w-full sm:w-[calc(11rem / 1.6)] font-normal  text-left ml-10">{curIndex + index + 1}</div>
            </div>

            <div className="flex-1 flex flex-col items-center justify-start">
              <div className="relative  w-full sm:w-[calc(16rem / 1.6)] font-normal text-left">{item.mobileNumber}</div>
            </div>

            <div className="flex-1 flex flex-col items-center justify-start">
              <div className="relative  w-full sm:w-[calc(16rem / 1.6)] font-normal text-left ">
                {item.fullName}
              </div>
            </div>

            <div className="flex-1 flex flex-col items-center justify-start">
              <div className="relative w-full sm:w-[calc(16rem / 1.6)] font-normal text-left">{item.businessName}</div>
            </div>

            <div className="flex-1 flex flex-col items-center justify-start">
                <div className="relative  w-full sm:w-[calc(56rem / 1.6)] text-center">
                {item?.tags.slice(0, expandedRows[item.id] ? item.tags.length : 3).map((tag, tagIndex) => (
                  <Chip
                    key={tagIndex}
                    label={tag}
                    variant="outlined"
                    style={{ margin: "4px" }}
                  />
                ))}
                  {item.tags.length > 3 && (
                    <>
                      <Collapse in={!expandedRows[item.id]} timeout="auto" unmountOnExit>
                        <Button onClick={() => toggleExpansion(item.id)} color="primary" size="small">
                          Show More
                        </Button>
                      </Collapse>

                      <Collapse in={expandedRows[item.id]} timeout="auto" unmountOnExit>
                        <Button onClick={() => toggleExpansion(item.id)} color="primary" size="small">
                          Show Less
                        </Button>
                      </Collapse>
                    </>
                  )}
                </div>
            </div>

            <div className="flex-1 flex flex-col items-center justify-start">
              <div className="relative  w-full sm:w-[calc(11rem / 1.6)]  text-center"><IconValueComponent status={item.status}/></div>
            </div>
          </div>
        ))}
    </div>
  );

};

const CatalogueUserStatusTable = ({userTemplateStatus=[], catalogueId, mode = "VIEW"}) => {
  const { user } = useAuthenticatedUser();
  const location = useLocation();
  const { cataloguesIds ,categoryId, category, catalogueName } = location?.state ?? {};
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalRecords, setTotalRecords] = useState(1);
  const [sorting, setSorting] = useState('createdAt,desc');
  const [status, setStatus] = useState('All');
  const [customerData, setCustomerData] = useState([]);
  const [selectedCustomerData, setSelectedCustomerData] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [retryUserData, setRetryUserData] = useState([]);
  const [tags, setTagsTo] =useState([]);
  const navigate = useNavigate();

  const handleSortingChange = (e) => {
    setSorting(e.target.value);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    debouncedSearch(e.target.value);
  };

  const updateFieldValues = (fieldsValues) => {
    setTagsTo(fieldsValues);
  }

  const handleDeleteTag = (tagToDelete) => {
    var updatedDetails = tags.filter((tag) => tag !== tagToDelete)
    setTagsTo(updatedDetails);
  };

  const arrayToString = (arr) => {
    try {
      if (!Array.isArray(arr)) {
        throw new Error("Input is not an array");
      }
  
      if (arr.length === 0) {
        return false;
      }
  
      const trimmedArray = arr.map(str => str.trim());
      const result = trimmedArray.join(',');
      return result;
    } catch (error) {
      console.error("Error in arrayToString:", error.message);
      return false;
    }
  };

  const fetchCatalogueUserData = () => {
    const baseUrl = `${BaseUrl}/catalogue/users`;
    var statusValue = status;
    var sortingValue = sorting;

    if (sortingValue === "All") {
      sortingValue = '';
    }

    if (statusValue === "All") {
      statusValue = '';
    }
    var updatedTags=arrayToString(tags);
    let queryParams = `?page=${pageNumber}&size=${pageSize}&sort=${sorting}&category=${category}`;

    if (searchQuery.trim() !== '') {
      queryParams += `&search=${searchQuery}`;
    }

    if (statusValue.trim() !== '') {
      queryParams += `&status=${statusValue}`;
    }

    if (updatedTags) {
      queryParams += `&tags=${updatedTags}`;
    }

    if (catalogueId !== null && catalogueId !== undefined) {
      queryParams += `&catalogueId=${catalogueId}`;
    }

    if(userTemplateStatus.length > 0){
        const failedUsers = userTemplateStatus.filter(userStatus => userStatus.id === null);
        const messageIdsString = userTemplateStatus.map(userStatus => userStatus.messageId).join(',');
        queryParams += `&messageIds=${messageIdsString}`
        setRetryUserData(failedUsers);
    }

    const url = `${baseUrl}${queryParams}`;

    axios
      .get(url, {
        headers: {
          Authorization: `${user.token}`,
          "Content-Type": "application/json",
        }
      }
      )
      .then((response) => {
        if ((response.data && response.data.data && response.data.data.content)) {
          setTotalRecords(response.data.data.totalElements);
          setCustomerData(response.data.data.content);
          return;
        }
      })
      .catch(() => {
        return
      });
  };

  const debouncedSearch = debounce(() => {
    fetchCatalogueUserData();
  }, 300);

  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery, sorting, status, pageNumber, pageSize, tags]);

  const handleRefresh = () => {
    fetchCatalogueUserData();
  }

  const navigateCatalogueTable = () => {
    navigate("/catalogue/", {
      state:{
        category:category
      }
    })
    return 
  }

  return (
    <div className="grid gap-y-3 gap-x-4 mt-5 mb-5 w-full">

      <div className="flex gap-x-4">

          <FormControl >
            <InputLabel id="sorting-label">Sort</InputLabel>
            <Select
              labelId="sorting-label"
              id="sorting-select"
              label="Sort"
              value={sorting}
              onChange={handleSortingChange}
            >
              <MenuItem value="u.fullName,asc">Name (Ascending)</MenuItem>
              <MenuItem value="u.fullName,desc">Name (Descending)</MenuItem>
              <MenuItem value="createdAt,asc">Created Date(Ascending)</MenuItem>
              <MenuItem value="createdAt,desc">Created Date(Descending)</MenuItem>
            </Select>
          </FormControl>

          <FormControl >
            <InputLabel id="sorting-label">Message status</InputLabel>
            <Select
              labelId="sorting-label"
              id="sorting-select"
              label="Message status"
              value={status}
              onChange={handleStatusChange}
              sx={{minWidth:"250px"}}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="MESSAGE_API_SENT">Sent</MenuItem>
              <MenuItem value="MESSAGE_API_PROCESSING">In Progress</MenuItem>
              <MenuItem value="MESSAGE_API_DELIVERED">Delivered</MenuItem>
              <MenuItem value="MESSAGE_API_READ">Read</MenuItem>
              <MenuItem value="MESSAGE_API_FAILED">Failed</MenuItem>
            </Select>

          </FormControl>

          <FormControl >
            <CustomInputTagsIndex
              updateFieldData={updateFieldValues}
              displayLabel={"Search by Tags"}
              initialValue={tags}
              mode={false}
              isAddDisable={true}
              validationRegex={/^.*$/}
              error={null}
              setError={() => { }}
            />
          </FormControl>

          <FormControl>
            <TextField
              className={`h-[3rem] block w-full p-1 border border-gray-500 rounded-md focus:outline-none ring-blue-500 focus:border-blue-500`}
              id="outlined-basic"
              label="Search"
              variant="outlined"
              fullWidth
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </FormControl>

          <FormControl >
            <Button
              variant="outlined"
              color="primary"
              startIcon={<SyncIcon />}
              onClick={() => handleRefresh()}
              sx={{ height:"100%", margin: "0px 10px 10px 0px" }}
            >
              Refresh
            </Button>
          </FormControl>

      </div>

      <div>
      {tags && tags.length > 0 && (
            <div className="flex mb-5">
              <div className="h-fit my-auto ">Tags: </div>
              <Box>
                {tags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    onDelete={() => handleDeleteTag(tag)}
                    variant="outlined"
                    style={{ margin: "4px" }}
                  />
                ))}
              </Box>
            </div>
          )}
        <CustomerTableHeadings />
        <CustomerRecords
          catalogueDetails={customerData}
          curIndex={pageSize * pageNumber}
        />
        <TablePagination
          pageNumber={pageNumber}
          pageSize={pageSize}
          handlePageSize={setPageSize}
          handlePageNumber={setPageNumber}
          totalRecordsData={totalRecords}
        />
      </div>
          {mode === "SHARE" && (
              <div className='flex justify-end space-x-4 mr-9'>
                  <button
                      className='rounded bg-white text-gray-700 border border-gray-300  overflow-hidden flex flex-row py-[0.81rem] px-[1.63rem] items-center justify-center text-[0.94rem]'
                      onClick={navigateCatalogueTable}
                  >
                      <div className='relative leading-[1.38rem] font-semibold'>
                          Cancel
                      </div>
                  </button>

              </div>
          )}
    </div>
  );
};

export default CatalogueUserStatusTable;

