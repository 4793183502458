import { useFormik } from "formik";
import Select from "../../atoms/Select";
import * as Yup from "yup";
import { ADMIN_ROUTES, COUNTRY_CODES, REGEX } from "../../../utils/constant";
import TextField from "../../atoms/TextField";
import { createUseStyles } from "react-jss";
import searchIconV2 from "../../../assets/icons/searchIconV2.svg";
import ButtonV2 from "../../atoms/ButtonV2";
import { useEffect, useState } from "react";
import { GstinResponseDTO, useGstinService } from "../../../services/useGstinService";
import { useBusinessProfileService } from "../../../services/useBusinessProfileService";
import { GST_STATUS_CODE, HONORIFICS, HTTP_STATUS, USER_DETAILS_DUPLICATION_CODE } from "../../../utils/types";
import { useSnackbar } from "../../../hooks/useSnackBar";
import SelectV2 from "../../atoms/SelectV2";
import { useNavigate } from "react-router-dom";
import TextFieldV2 from "../../atoms/TextFieldV2";
import { convertToCamelCase, createGstAddressLines } from "../../../utils/helper";

const useStyles = createUseStyles((theme: any) => ({
    title: {
        color: theme.palette.textV2.primary.primary550
    },
    selectField: {
        width: "160px"
    },
    textField: {
        width: "566px"
    },
    searchButton: {
        height: "20px",
        width: "20px",
        padding: "14px",
        borderRadius: "14px",
        background: theme.palette.buttonV2.primaryContained.background.primaryLight
    },
    errorMessage: {
        color: theme.palette.textV2.failure.failure500,
    },
    gstContainer: {
        border: `1px solid ${theme.palette.borderV2.primary.primary50}`
    },
    lineSeparator: {
        borderBottom: `1px solid ${theme.palette.borderV2.primary.primary50}`
    }
}));

export interface ICustomerDetailsForm {
    prefix: string;
    firstName: string;
    lastName: string;
    countryCode: string;
    mobileNumber: string;
    email: string;
    gstNumber: string;
    verifiedGstNumber: string;
    companyName: string;
    addressLine1: string;
    addressLine2: string;
    pincode: string;
    city: string;
    state: string;
}

const CustomerDetailsPage: React.FC = () => {

    const classes = useStyles();
    const gstinService = useGstinService();
    const businessProfileService = useBusinessProfileService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const navigate = useNavigate();

    const [gstinDetail, setGstinDetail] = useState<GstinResponseDTO | null>(null);

    const validationSchema = Yup.object().shape({
        prefix: Yup.string().required("Prefix is required"),
        firstName: Yup.string().required("First Name is required"),
        lastName: Yup.string().required("Last Name is required"),
        countryCode: Yup.string().required("Country code is required"),
        mobileNumber: Yup.string().required("Phone Number is required").matches(REGEX.PHONE_NUMBER, 'Entered Invalid phone number'),
        email: Yup.string().required("Email is required").matches(REGEX.EMAIL, 'Entered Invalid Email ID'),
        gstNumber: Yup.string().required("GST Number is required").matches(REGEX.GST, 'Entered Invalid GST number.'),
        verifiedGstNumber: Yup.string().required("GST Number is required").matches(REGEX.GST, 'Entered Invalid GST number.')
    });

    const formik = useFormik<ICustomerDetailsForm>({
        initialValues: {
            prefix: "",
            firstName: "",
            lastName: "",
            countryCode: "+91",
            mobileNumber: "",
            email: "",
            gstNumber: "",
            companyName: "",
            addressLine1: "",
            addressLine2: "",
            pincode: "",
            city: "",
            state: "",
            verifiedGstNumber: ""
        },
        validationSchema,
        validateOnChange: true,
        validateOnMount: true,
        validateOnBlur: true,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            const requestBody = {
                prefix: values?.prefix,
                firstName: values?.firstName,
                lastName: values?.lastName,
                countryCode: values?.countryCode,
                mobileNumber: values?.mobileNumber,
                email: values?.email,
                gstNumber: values?.verifiedGstNumber,
                companyName: values?.companyName,
                addressLine1: values?.addressLine1,
                addressLine2: values?.addressLine2,
                pincode: values?.pincode,
                city: values?.city,
                state: values?.state
            }
            try {
                const businessProfileResponse = await businessProfileService.createBusinessProfile(requestBody);
                if (businessProfileResponse.status === HTTP_STATUS.OK) {
                    showSnackbar("success", `New Customer added`);
                    navigate(ADMIN_ROUTES.CUSTOMER_MANAGEMENT_LIST);
                } else if (businessProfileResponse.status === HTTP_STATUS.BAD_REQUEST) {
                    if (businessProfileResponse.data.statusMessage === USER_DETAILS_DUPLICATION_CODE.MOBILE_NUMBER_DUPLICATION) {
                        showSnackbar("error", `Business Profile creation failed: Mobile number already registered.`);
                        return;
                    }
                    else if (businessProfileResponse.data.statusMessage === USER_DETAILS_DUPLICATION_CODE.EMAIL_DUPLICATION) {
                        showSnackbar("error", `Business Profile creation failed: Email already registered.`);
                        return;
                    }
                }
            } catch (error) {
                showSnackbar("error", `Business Profile creation failed: ${error}`);
            }
        }
    });

    const validateGstin = async () => {
        if (formik.values.gstNumber.length === 15 && !formik.errors.gstNumber) {
            const gstin = formik.values.gstNumber;
            const response = await gstinService.searchGstin({ gstin });
            const gstStatusCode = response?.data?.data?.gstStatusCode;
            if (response?.data?.data?.valid) {
                setGstinDetail(response.data.data);
                const gstSplitAddress = createGstAddressLines(response?.data?.data);
                formik.setFieldValue("verifiedGstNumber", formik?.values?.gstNumber);
                formik.setFieldValue("companyName", response?.data?.data?.legalNameOfBusiness);
                formik.setFieldValue("addressLine1", gstSplitAddress[0]);
                formik.setFieldValue("addressLine2", gstSplitAddress[1]);
                formik.setFieldValue("pincode", response?.data?.data?.pincode);
                formik.setFieldValue("city", response?.data?.data?.city[0]);
                formik.setFieldValue("state", response?.data?.data?.state[0][0]);
            }
            if (gstStatusCode === GST_STATUS_CODE.DUPLICATE_GST) {
                setGstinDetail(null);
                formik.setFieldError("gstNumber", "GST number already registered.");
                formik.setFieldTouched("gstNumber", true, false);
            }
            else if (gstStatusCode === GST_STATUS_CODE.INVALID_GST) {
                setGstinDetail(null);
                formik.setFieldError("gstNumber", 'Entered Invalid GST number.');
                formik.setFieldTouched("gstNumber", true, false);
            }
        }
    };

    const navigateToCustomerManagementTable = () => {
        navigate(ADMIN_ROUTES.CUSTOMER_MANAGEMENT_LIST);
    };

    return (
        <div className="grid gap-y-8">
            <div>
                <div className={`text-2xl font-medium ${classes.title} pb-6`}>Add Customer Details</div>
                <div className={classes.lineSeparator}></div>
            </div>
            <div className="grid gap-y-8">
                <div className="flex gap-x-6">
                    <div className={`${classes.selectField}`}>
                        <SelectV2
                            variant="outlined"
                            label="Prefix"
                            placeholder="Select Prefix"
                            fullWidth
                            {...formik.getFieldProps("prefix")}
                            error={
                                formik.touched.prefix &&
                                Boolean(formik.errors.prefix)
                            }
                            options={
                                Object.values(HONORIFICS)?.map((prefix: string) => ({
                                    value: prefix,
                                    label: prefix
                                })) || []
                            }
                            helperText={formik?.errors?.prefix}
                        />
                    </div>
                    <div className="flex gap-x-6 w-full">
                        <div className="w-1/2">
                            <TextFieldV2
                                {...formik.getFieldProps("firstName")}
                                value={convertToCamelCase(formik.values.firstName)}
                                className=" text-base font-medium"
                                type="text"
                                label="Enter First Name"
                                placeholder="Enter Name"
                                fullWidth
                                required
                                error={formik.touched.firstName && !!formik.errors.firstName}
                                helperText={(formik.touched?.firstName &&
                                    Boolean(formik.errors?.firstName)) && formik.errors?.firstName}
                            />
                        </div>
                        <div className="w-1/2">
                            <TextFieldV2
                                {...formik.getFieldProps("lastName")}
                                value={convertToCamelCase(formik.values.lastName)}
                                className=" text-base font-medium"
                                type="text"
                                label="Enter Last Name"
                                placeholder="Enter Name"
                                fullWidth
                                error={formik.touched.lastName && !!formik.errors.lastName}
                                helperText={(formik.touched?.lastName &&
                                    Boolean(formik.errors?.lastName)) && formik.errors?.lastName}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex gap-x-6">
                    <div className={`${classes.selectField} text-base font-medium`}>
                        <SelectV2
                            variant="outlined"
                            label="Country Code"
                            placeholder="IND (+91)"
                            fullWidth
                            disabled={true}
                            {...formik.getFieldProps("countryCode")}
                            error={
                                formik.touched.countryCode &&
                                Boolean(formik.errors.countryCode)
                            }
                            options={
                                COUNTRY_CODES.map((country) => ({
                                    value: country.code,
                                    label: `${country.name} (${country.code})`
                                })) || []
                            }
                            helperText={formik?.errors?.countryCode}
                        />
                    </div>
                    <div className="flex gap-x-6 w-full">
                        <div className="w-1/2">
                            <TextFieldV2
                                {...formik.getFieldProps("mobileNumber")}
                                className=" text-base font-medium"
                                type="number"
                                label="Enter Phone number"
                                placeholder="Enter Phone number"
                                fullWidth
                                error={formik.touched.mobileNumber && !!formik.errors.mobileNumber}
                                helperText={(formik.touched?.mobileNumber &&
                                    Boolean(formik.errors?.mobileNumber)) && formik.errors?.mobileNumber}
                            />
                        </div>
                        <div className="w-1/2">
                            <TextFieldV2
                                {...formik.getFieldProps("email")}
                                className=" text-base font-medium"
                                type="text"
                                label="Enter Email ID"
                                placeholder="Enter Email ID"
                                fullWidth
                                required
                                error={formik.touched.email && !!formik.errors.email}
                                helperText={(formik.touched?.email &&
                                    Boolean(formik.errors?.email)) && formik.errors?.email}
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.lineSeparator}></div>
                <div className={`grid gap-y-8 p-4 rounded-xl ${classes.gstContainer}`}>
                    <div className={`${classes.textField} flex gap-x-4`}>
                        <div className="w-full">
                            <TextFieldV2
                                {...formik.getFieldProps("gstNumber")}
                                className=" text-base font-medium"
                                type="text"
                                label="GST Registered Number"
                                placeholder="Enter GST number"
                                fullWidth
                                required
                                error={formik.touched.gstNumber && !!formik.errors.gstNumber}
                                onChange={(event: any) => {
                                    formik.handleChange({
                                        target: { name: 'gstNumber', value: (event.target.value).toUpperCase() },
                                    });
                                }}
                                helperText={(formik.touched?.gstNumber &&
                                    Boolean(formik.errors?.gstNumber)) && formik.errors?.gstNumber}
                            />
                        </div>
                        <ButtonV2
                            className="mt-8"
                            variant="primaryContained"
                            iconButton={<img src={searchIconV2} />}
                            onClick={validateGstin}
                            size='small'
                        />
                    </div>
                    <div className={`grid gap-y-4 ${classes.textField}`}>
                        <div>
                            <TextFieldV2
                                {...formik.getFieldProps("companyName")}
                                className=" text-base font-medium"
                                type="text"
                                label="Enter Company Name"
                                placeholder="Enter Company"
                                fullWidth
                                required
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </div>
                        <div>
                            <TextFieldV2
                                {...formik.getFieldProps("addressLine1")}
                                className=" text-base font-medium"
                                type="text"
                                label="Address Line 1"
                                placeholder="Enter Address"
                                fullWidth
                                required
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </div>
                        <div>
                            <TextFieldV2
                                {...formik.getFieldProps("addressLine2")}
                                className=" text-base font-medium"
                                type="text"
                                label="Address Line 2"
                                placeholder="Enter Address"
                                fullWidth
                                required
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </div>
                        <div className="flex gap-x-4">
                            <div className="grid">
                                <TextFieldV2
                                    {...formik.getFieldProps("pincode")}
                                    className=" text-base font-medium"
                                    type="text"
                                    label="Pincode"
                                    placeholder="Enter Pincode"
                                    fullWidth
                                    required
                                    inputProps={{
                                        readOnly: true
                                    }}
                                />
                            </div>
                            <div>
                                <TextFieldV2
                                    {...formik.getFieldProps("city")}
                                    className=" text-base font-medium"
                                    type="text"
                                    label="City"
                                    placeholder="Enter City"
                                    fullWidth
                                    required
                                    inputProps={{
                                        readOnly: true
                                    }}
                                />
                            </div>
                            <div>
                                <TextFieldV2
                                    {...formik.getFieldProps("state")}
                                    className=" text-base font-medium"
                                    type="text"
                                    label="State"
                                    placeholder="Enter State"
                                    fullWidth
                                    required
                                    inputProps={{
                                        readOnly: true
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end gap-x-2">
                <div>
                    <ButtonV2
                        variant="tertiaryContained"
                        label="Back"
                        onClick={navigateToCustomerManagementTable}
                    />
                </div>
                <div>
                    <ButtonV2
                        variant="primaryContained"
                        label="Save"
                        onClick={() => formik.handleSubmit()}
                    />
                </div>
            </div>
        </div>
    )
}

export default CustomerDetailsPage;
