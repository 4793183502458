import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { HTTP_STATUS } from '../../../utils/types';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import VendorLocationTemplate from '../../templates/VendorManagement/Location/VendorLocation.template';
import { useVendorServiceV2 } from '../../../services/useVendorServiceV2';

export interface IGstnState {
  gstin:string;
  state:string;
}
export interface ILocationGstinForm {
  locations: IGstnState[];
  noOfRows: number|null;
  newLocations: IGstnState[];
}
const initialValues = {
  locations:[],
  noOfRows:null,
  newLocations: []
}
const VendorLocationPage: React.FC = () => {
  const { showSnackbar, SnackBarComponent } = useSnackbar();
  const { user: authenticatedUser } = useAuthenticatedUser();
  const vendorService = useVendorServiceV2();
  const [vendorLocation, setVendorLocationTo] = useState<ILocationGstinForm>(initialValues)
  const [isLoading, setIsLoading]= useState<boolean>(false);

  const params = useParams();
  const id = Number(params.id);

  const validationSchema = Yup.object().shape({
    locations: Yup.array().of(
      Yup.object().shape({
        gstin: Yup.string().required('GSTIN is required'),
        state: Yup.string().required('State is required')
      })
    ),
    noOfRows: Yup.number()
        .required('Atleast one no is required')
        .transform(value => (isNaN(value) ? undefined : value)) 
        .test(
            'isValidNumber',
            'Must be a positive number between 1 and 9',
            value => value === undefined || (value > 0 && value < 10)
        ),
    newLocations:Yup.array().of(
      Yup.object().shape({
        gstin: Yup.string()
        .required('GST Number is required')
        .matches(/^[0-9]{2}[A-Z,a-z]{5}[0-9]{4}[A-Z,a-z]{1}[0-9]{1}[A-Z,a-z]{1}[0-9,A-Z,a-z]{1}?$/, 'This GSTIN is not valid'),
        state: Yup.string().required('state is required')
      })
    )
  });
  
  const getBusinessProfile = async () => {
    setIsLoading(false);
    try {
      const VendorResponse = await  vendorService.getVendor(id);
      if (VendorResponse.status === HTTP_STATUS.OK) {
        const profileData = VendorResponse?.data?.data;
        setVendorLocationTo({ locations: profileData.locations || [], noOfRows: null, newLocations: [] });
        setIsLoading(true);
      }
    } catch (error) {
      showSnackbar('error', 'Business Profile fetch failed');
    }
};
  const formik = useFormik<ILocationGstinForm>({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const body = [
        ...formik.values.locations,
        ...formik.values.newLocations.map(location => ({ ...location, gstin: location.gstin.toUpperCase() })),
      ];
    try {
     const updateVendorResponse = await vendorService.updateVendorSections(id, body, 'LOCATIONS');
     resetForm({ values: formik.values });
    } catch (error) {
      showSnackbar("error", `Business profile update  failed ${error}`)
    }
    },
  });
  
  useEffect(() => {
    formik.setValues({
      ...formik.values,
      ...vendorLocation
    })
  }, [vendorLocation])
 
  useEffect(() => {
    getBusinessProfile();
  }, [formik.isSubmitting]);
  return (
     isLoading ? <VendorLocationTemplate formik={formik}/> : null
  )
}
export default VendorLocationPage