import { useEffect, useState } from 'react';
import axios from 'axios';
import viewEyeIcon from "./../../../../Assets/Icons/viewEyeIcon.svg";
import editPenIcon from "./../../../../Assets/Icons/editPenIcon.svg";
import { FormControl, InputLabel, Select, MenuItem, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PropertiesDialogPopUp from './PropertiesDialogPopUp';
import TablePagination from './TablePagination';
import debounce from 'lodash/debounce';
import GradePropertiesTableIndex from './GradePropertiesTableIndex';
import AddGradeFormik from './AddGradeFormik';
import { formatDate } from '../../../../Core/Handlers/DateFormat';
import { useAuthenticatedUser } from '../../../../../hooks/useAuthenticatedUser';
import { mapStandardStatusEnumToString } from '../../../../Constants/Enums/StandardStatusEnum';

const BaseUrl = process.env.REACT_APP_API_V1_URL;


const GradeTableIndex = () => {
    const { user } = useAuthenticatedUser();
    const navigate = useNavigate();
    // State to store the fetched data
    const [gradesData, setGradesData] = useState([]);
    const [showProperties, setShowProperties] = useState(false);
    const [associatedGrade, setAssociatedGrade] = useState(null);
    const [open, setOpen] = useState(false);
    const [dialogType, setDialogType] = useState(0);
    const [gradeDetails, setGradeDetails] = useState(null);

    // Filters and Pagination
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalRecords, setTotalRecords] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [standardQuery, setStandardQuery] = useState('');
    const [status, setStatus] = useState('All');
    const [sorting, setSorting] = useState('createdAt,desc');
    const [gradeType, setGradeType] = useState('GRADE');
    const [updateTable, setupdateTable] = useState(0);
    const [seletectRow, setSeletectRow] = useState(null);
    useEffect(() => {
        debouncedSearch(searchQuery);
    }, [searchQuery, status, sorting, pageSize, pageNumber, updateTable]);

    const fetchData = () => {
        const baseUrl = `${BaseUrl}/quality-grades`;

        let searchName = searchQuery.trim();
        let standardName = standardQuery.trim();
        // &status=${statusValue}
        var queryParams = `?search=${searchName}&page=${pageNumber}&size=${pageSize}&sort=${sorting}&standardName=${standardName}&type=${gradeType}&standardType=`;

        let statusValue = status
        if (status !== "All") {
            queryParams += `&status=${statusValue}`;
        } 

        const url = `${baseUrl}${queryParams}`;

        axios
            .get(url ,{
                headers: {
                   Authorization: `${user.token}`,
                   "Content-Type": "application/json",
                }
             })
            .then((response) => {

                if (response.data && response.data.data) {
                    setTotalRecords(response.data.data.totalElements);
                    setGradesData(response.data.data.content);
                }
            })
            .catch((err) => {
                return
            });
    };

    const GradesTableHeadings = () => {
        return (


            <div className="relative bg-blue w-full flex flex-row p-[0.75rem] box-border items-end justify-start gap-[0.75rem] text-left text-[0.88rem] text-white font-roboto">
                <div className="flex-1 flex flex-col items-start justify-start">
                    <div className="relative font-semibold text-left w-full sm:w-[calc(11rem / 1.6)]">Sr No.</div>
                </div>
                <div className="flex-1 flex flex-col items-center justify-start">
                    <div className="relative font-semibold text-left w-full sm:w-[calc(24rem / 1.6)]">Grade Name</div>
                </div>
                <div className="flex-1 flex flex-col items-center justify-start">
                    <div className="relative font-semibold text-left w-full sm:w-[calc(24rem / 1.6)]">Grade Name Code</div>
                </div>
                <div className="flex-1 flex flex-col items-center justify-start">
                    <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">Associated Standard</div>
                </div>
                <div className="flex-1 flex flex-col items-center justify-start">
                    <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">Created Date</div>
                </div>
                <div className="flex-1 flex flex-col items-center justify-start">
                    <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">Last Modified</div>
                </div>
                <div className="flex-1 flex flex-col items-center justify-start">
                    <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">Status</div>
                </div>
                <div className="flex-1 flex flex-col items-center justify-start">
                    <div className="relative font-semibold text-center w-full sm:w-[calc(21rem / 1.6)]">Action</div>
                </div>
            </div>

        );
    };

    const handleViewClick = (grade, type) => {
        setGradeDetails(grade.id);
        setDialogType(type);
        setOpen(true);
        // navigate(`/grade/view/${id}`);
    }
    // const handleOpenDialog = (type) => {
    //     setDialogType(type);
    //     setOpen(true);
    // };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    const handleReturnToGradeIndex = () => {
        navigate('/standard/list', {
            state: {
              type: 'Grade'
            }
          });
    };

    function parseJsonArray(jsonArrayString) {
        try {
            const parsedArray = JSON.parse(jsonArrayString);
            return parsedArray !== null ? parsedArray : [];
        } catch (error) {
            console.error("Error parsing JSON array:", error);
            return []; // Return an empty array in case of an error
        }
    }

    const updatePropertiesAndGrade = (index, showProperties, grade) => {
        if (!associatedGrade || grade.id !== associatedGrade.id) {
          setShowProperties(true);
          setAssociatedGrade(grade);
          setSeletectRow(index);
        } else {
          setShowProperties(!showProperties);
          setSeletectRow(showProperties ? null : index);
        }
      };

    const debouncedSearch = debounce((value) => {
        fetchData();
    }, 500);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        debouncedSearch(e.target.value);
    };

    // const handleSearchStandardChange = (e) => {
    //     setStandardQuery(e.target.value);
    //     debouncedSearch(e.target.value);
    // };

    const GradeDetailsRecords = ({ records, curIndex=0 }) => {
        if (!records) {
            return null;
        }
        return (

            <>

                {records.map((grade, index) => (
                    <div key={index}
                        className={`${seletectRow === grade.id ? 'record-selected' : 'catalogue-details'} self-stretch flex flex-row p-[0.75rem] items-center justify-start gap-[0.75rem] border-b border-gray-300 `}
                    >

                        <div className="flex-1 flex flex-col items-center justify-start">
                            <div className="relative  w-full sm:w-[calc(11rem / 1.6)]   text-left ">{curIndex + index + 1}</div>
                        </div>

                        <div className="flex-1 flex flex-col items-center justify-start">
                            <div className="relative  w-full sm:w-[calc(34rem / 1.6)]   text-left ">{grade.name}</div>
                        </div>
                        <div className="flex-1 flex flex-col items-center justify-start">
                            <div className="relative  w-full sm:w-[calc(34rem / 1.6)]   text-center ">{grade.prefix}</div>
                        </div>

                        <div className="flex-1 flex flex-col items-center justify-start">
                            <div className="relative  w-full sm:w-[calc(11rem / 1.6)]   text-center ">{grade.standardName}</div>
                        </div>

                        <div className="flex-1 flex flex-col items-center justify-start">
                            <div className="relative  w-full sm:w-[calc(11rem / 1.6)]   text-center ">{formatDate(grade.createdAt)}</div>
                        </div>

                        <div className="flex-1 flex flex-col items-center justify-start">
                            <div className="relative  w-full sm:w-[calc(11rem / 1.6)]   text-center ">{formatDate(grade.updatedAt)}</div>
                        </div>
                        <div className={`flex-1 flex flex-col items-center justify-start ${grade.status === "ACTIVE" ? "text-green" : "text-inactive"}`}>
                            <div className="relative  w-full sm:w-[calc(11rem / 1.6)]   text-center "> {mapStandardStatusEnumToString(grade.status)}</div>
                        </div>

                        <div className="flex-1 flex flex-col items-center justify-start text-center ">
                            <div className="relative  w-full sm:w-[calc(11rem / 1.6)]  text-center">

                                <button
                                    className="relative h-[1.13rem] overflow-hidden shrink-0 text-center mr-5 pb-5 font-bold text-blue"
                                    onClick={() => updatePropertiesAndGrade(grade.id, showProperties, grade)}
                                >
                                    CC/MP

                                </button>

                                <button
                                    className="relative w-[1.13rem] h-[1.13rem] overflow-hidden shrink-0 text-center mr-2"
                                    onClick={(e) => handleViewClick(grade, 1)}
                                >
                                    <img className="w-full h-full" alt="" src={viewEyeIcon} />
                                </button>

                                <button
                                    className="relative w-[1.13rem] h-[1.13rem] overflow-hidden shrink-0 text-center mr-2 "
                                    onClick={(e) => handleViewClick(grade, 2)}
                                >
                                    <img className="w-full h-full" alt="" src={editPenIcon} />
                                </button>

                            </div>
                        </div>

                    </div>

                ))
                }
            </>
        )
    }

    return (
        <div className="grid gap-y-3 gap-x-4 m-t-10 p-x-10">
            <div className="w-full"> {/* Ensure the parent container has full width */}

                <div className="flex justify-between mb-3">
                    <div className="flex gap-x-5">

                        {/* <FormControl sx={{ width: "200px" }}>
                            <InputLabel id="status-label">Associated Standard</InputLabel>
                            <Select
                                labelId="status-label"
                                id="status-select"
                                // value={status}
                                // onChange={handleStatusChange}
                                label="Associated Standard"
                                sx={{ color: "black" }}
                            >
                                <MenuItem value="All">All</MenuItem>
                                <MenuItem value="PUBLISHED">Published</MenuItem>
                                <MenuItem value="UNPUBLISHED">Unpublished</MenuItem>
                            </Select>
                        </FormControl> */}

                        {/* <FormControl sx={{ width: "345px" }}
                        // sx={{ width: "345px" }}
                        >
                            <TextField
                                className={`h-[3rem] block w-full p-1 border border-gray-500 rounded-md focus:outline-none ring-blue-500 focus:border-blue-500`}
                                id="outlined-basic-1"
                                label="Associated Standard"
                                variant="outlined"
                                fullWidth
                                value={standardQuery}
                                onChange={(e) => handleSearchStandardChange(e)}
                            />
                        </FormControl> */}

                    <FormControl sx={{ width: "170px" }}>
                        <InputLabel id="status-label">Status</InputLabel>
                        <Select
                            labelId="status-label"
                            id="status-select"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            label="Status"
                            sx={{ color: "black" }}
                        >
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="ACTIVE">Active</MenuItem>
                            <MenuItem value="INACTIVE">Inactive</MenuItem>
                        </Select>
                    </FormControl>



                        <FormControl sx={{ width: "250px" }}>
                            <InputLabel id="sorting-label">Sort</InputLabel>
                            <Select
                                labelId="sorting-label"
                                id="sorting-select"
                                label="Sort"
                                value={sorting}
                                onChange={(e) => setSorting(e.target.value)}
                                sx={{ width: "180%", maxWidth: "250px" }}
                            // sx={{ width: "250px" }}
                            >

                                <MenuItem value="name,asc">Name (Ascending)</MenuItem>
                                <MenuItem value="name,desc">Name (Descending)</MenuItem>
                                <MenuItem value="createdAt,asc">Created Date(Ascending)</MenuItem>
                                <MenuItem value="createdAt,desc">Created Date(Descending)</MenuItem>

                            </Select>
                        </FormControl>

                    </div>

                    <div>
                        <FormControl sx={{ width: "345px" }}
                        // sx={{ width: "345px" }}
                        >
                            <TextField
                                className={`h-[3rem] block w-full p-1 border border-gray-500 rounded-md focus:outline-none ring-blue-500 focus:border-blue-500`}
                                id="outlined-basic"
                                label="Search by Name"
                                variant="outlined"
                                fullWidth
                                value={searchQuery}
                                onChange={(e) => handleSearchChange(e)}
                            />
                        </FormControl>
                    </div>
                </div>

                <GradesTableHeadings />
                <GradeDetailsRecords records={gradesData} curIndex={ pageNumber*pageSize }/>
                {/* ---------------------Pagination-------------------------- */}
                <TablePagination
                    pageNumber={pageNumber}
                    pageSize={pageSize}
                    handlePageSize={setPageSize}
                    handlePageNumber={setPageNumber}
                    totalRecordsData={totalRecords}
                />
            </div>

            {showProperties && (
                <div className="grid gap-y-3 gap-x-4 m-t-10 p-x-10">
                    <GradePropertiesTableIndex gradeId={associatedGrade.id} />
                </div>
            )}

            <PropertiesDialogPopUp open={open} onClose={handleCloseDialog}>

                {dialogType === 0 &&
                    <div className="w-full">
                        <AddGradeFormik
                            type={'ADD'}
                            onClose={handleReturnToGradeIndex}
                            gradeId={null}
                            updateRecord={setupdateTable}
                        />
                    </div>
                }

                {dialogType === 1 &&
                    <AddGradeFormik
                        type={'VIEW'}
                        onClose={handleCloseDialog}
                        gradeId={gradeDetails}
                        updateRecord={setupdateTable}
                    />
                }

                {dialogType === 2 &&
                    <AddGradeFormik
                        type={'EDIT'}
                        onClose={handleCloseDialog}
                        gradeId={gradeDetails}
                        updateRecord={setupdateTable}
                    />
                }

            </PropertiesDialogPopUp>

        </div>

    );

};

export default GradeTableIndex;
