import React, { useEffect, useState } from 'react';
import ButtonV2 from '../../atoms/ButtonV2';
import { initialPaginationValues } from '../../../utils/constant';
import { HTTP_STATUS, IPagination, IWarehouse } from '../../../utils/types';
import TableV2, { ColumnType } from '../../organisms/TableV2';
import { createUseStyles } from 'react-jss';
import { IMasterInventoriesRequest, useMasterUpcService } from '../../../services/useMasterUpcService';
import TextFieldV2 from '../../atoms/TextFieldV2';
import FilterChipV2 from '../../molecules/FilterChip/FilterChipV2';
import { useInventoryService } from '../../../services/useInventoryService';
import { IMultiSelectOption } from '../../atoms/MultiSelectInput2';
import { InputAdornment } from '@mui/material';
import search from '../../../assets/images/search.svg';
import downloadExcelIcon from '../../../assets/icons/downloadExcelIcon.svg';


export interface IMasterInventory {
    id: number;
    warehouseName: string;
    availableInventory: string;
}

interface IMasterInventoryProps {
    id: number;
}

interface IInventoriesFilter {
    search?: string;
    warehouse?: number[];
}

const initialInventoriesFiltersValues: IInventoriesFilter = {
    warehouse: [],
    search: ""
}

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.buttonV2.secondaryContained.text.primaryDark
    }
}));

const MasterInventoryTemplate: React.FC<IMasterInventoryProps> = ({ id }) => {
    const classes = useStyles();
    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);
    const [inventory, setInventory] = useState<IMasterInventory[]>([]);
    const [inventoriesFilters, setInventoriesFiltersTo] = useState<IInventoriesFilter>(initialInventoriesFiltersValues);
    const masterUpcService = useMasterUpcService();
    const [warehouses, setWarehousesTo] = useState<IWarehouse[]>([]);
    const inventoryService = useInventoryService();
    const [selectedValues, setSelectedValues] = useState<IMultiSelectOption[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');

    useEffect(() => {
        const params = {
            type: "AVIGHNA",
            search: searchTerm,
            page: 0,
            size: 10
        };

        inventoryService.getWarehouses(params).then((response) => {
            const { content } = response?.data?.data;
            setWarehousesTo(content);
        })
            .catch((error) => {
                console.error("Warehouses fetching Error - ", error);
            });
    }, [searchTerm]);

    const fetchUpc = async (page: number, size: number) => {
        if (id) {
            try {
                let params: IMasterInventoriesRequest = {
                    warehouseId: inventoriesFilters?.warehouse?.join(',') ?? "",
                    search: inventoriesFilters?.search ?? '',
                    page: page,
                    size: size,
                }
                const upcResponse = await masterUpcService.getInventoriesById(id, params);
                if (upcResponse?.status === HTTP_STATUS.OK) {
                    const { totalElements, totalPages } = upcResponse.data.data;
                    setPaginationTo(prevPagination => ({
                        ...prevPagination,
                        totalPages: totalPages,
                        totalRecords: totalElements
                    }));
                    setInventory(upcResponse?.data?.data?.content);
                }
                else {
                    throw new Error("Inventories fetch failed");
                }
            } catch (error) {
                console.error("error", `Inventories fetch failed`)
            }
        }
    }
    useEffect(() => {
        fetchUpc(pagination.currentPage, pagination.pageSize)
    }, [id, inventoriesFilters]);

    const handleFiltersChange = (name: string, value: any) => {
        setPaginationTo({ ...pagination, currentPage: 0 });
        setInventoriesFiltersTo({ ...inventoriesFilters, [name]: value ?? "" });
    };

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage
        }));
    };

    const schema = {
        id: "1",
        title: "",
        pagination: {
            total: pagination.totalRecords,
            currentPage: pagination.currentPage,
            isVisible: true,
            limit: pagination.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange,
        },
        columns: [
            { label: "S No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "Warehouse", key: "warehouseName", type: "string" as ColumnType, props: { className: '' } },
            { label: "Available Quantity (MT)", key: "availableInventory", type: "string" as ColumnType, props: { className: '' } },
        ],
    };
    const records = inventory.map((inventory: IMasterInventory, index: number) => [
        pagination.currentPage * pagination.pageSize + index + 1,
        inventory.warehouseName,
        inventory.availableInventory,
    ]);

    const handleSelectionChange = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setSelectedValues(selected);
        clearWithCrossIcon && handleApplyClick(selected);
    };

    const handleClearClick = () => {
        setSelectedValues([]);
    };

    const handleApplyClick = (selectedValues: IMultiSelectOption[]) => {
        const selectedValuesOnly = selectedValues.map(option => option.value);
        setInventoriesFiltersTo({ ...inventoriesFilters, warehouse: selectedValuesOnly as number[] ?? [] });
    };

    return (
        <div>
            <div className={`${classes.heading} text-lg font-semibold mt-5`}>Inventory Management</div>
            <div className="flex justify-between items-center">
                <div className='flex items-center gap-3'>
                    <FilterChipV2
                        options={warehouses ? warehouses.map(warehouse => ({ label: warehouse.name, value: warehouse.id })) : []}
                        label=" "
                        value={selectedValues}
                        onchange={handleSelectionChange}
                        placeholder="Warehouse"
                        ClearButtonComponent={ButtonV2}
                        textFieldPlaceholder='Search...'
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        buttonLabel={"Clear all"}
                        buttonOnClick={handleClearClick}
                        SaveButton={ButtonV2}
                        saveButtonLabel='Apply'
                        saveButtonOnClick={() => handleApplyClick(selectedValues)}
                    />
                </div>
                <div className='flex items-center gap-x-4'>
                    <TextFieldV2
                        className="w-72"
                        type="text"
                        placeholder='Search By Warehouse..'
                        label=" "
                        variant="outlined"
                        name="search"
                        value={inventoriesFilters.search}
                        onChange={(e) => handleFiltersChange("search", e.target.value)}
                        InputProps={{
                            startAdornment: <InputAdornment position="start" > <img src={search} alt="icon" /></InputAdornment>,
                        }}
                    />
                    <ButtonV2 variant={"tertiaryContained"} label={"Excel"} iconButton={<img src={downloadExcelIcon} alt="Excel Icon" />} buttonWithImg={true} disabled/>
                </div>
            </div>

            <div className='mt-5'>
                <TableV2 schema={schema} records={records} />
            </div>
        </div>
    )
}
export default MasterInventoryTemplate