import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { Category, useCategoryService } from '../../../services/useCategoryService';
import { CATEGORY_TYPE, HTTP_STATUS } from '../../../utils/types';
import SuperCategoryUpdatePage from './SuperCategoryUpdate.page';
import MainCategoryUpdatePage from './MainCategoryUpdate.page';
import ProductCategoryUpdatePage from './ProductCategoryUpdate.page';
import { ADMIN_ROUTES, MODE } from '../../../utils/constant';

const CategoryUpdatePage = () => {
    const params = useParams();
    const [searchParams] = useSearchParams();

    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const categoryService = useCategoryService();
    const [category, setCategory] = useState<Category | null>(null);
    const navigate = useNavigate();
    const mode: string = searchParams.get('mode') ?? MODE.VIEW;
    const categoryId = Number(params.id);

    useEffect(() => {
        categoryService.getCategoryById(categoryId)
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    let category = res.data.data
                    setCategory({
                        ...category,
                        superParent: category?.ancestors?.superParentCategory,
                        parentCategory: category?.ancestors?.parentCategory
                    })
                }
            }).catch((error) => {
                setCategory(null);
                console.error("Error Fetching Category: ", error);
                showSnackbar('error', "Error while fetching category data");
            })
    }, [categoryId])

    const categoryComponent = useMemo(() => {
        if (!category) { 
            navigate(ADMIN_ROUTES.CATEGORY) 
            return; 
        }
        switch (category.level) {
            case CATEGORY_TYPE.MAIN_CATEGORY: return <MainCategoryUpdatePage mode={mode} category={category} />
            case CATEGORY_TYPE.PRODUCT_CATEGORY: return <ProductCategoryUpdatePage mode={mode} category={category} />
            default: return <SuperCategoryUpdatePage mode={mode} category={category} />;
        }
    }, [category])

    return <>
        {SnackBarComponent}
        {categoryComponent}
    </>
}

export default CategoryUpdatePage