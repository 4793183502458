import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { HTTP_STATUS } from '../../../utils/types';
import { ADMIN_ROUTES, GENERIC_EXCEPTION_CODE, MODE } from '../../../utils/constant'
import { IProductUpcCreateRequest, useUpcService } from '../../../services/useUpcService';
import UpcFormTemplate from '../../templates/UPC/UpcForm.template';

const UpcCreatePage: React.FC = () => {

    const navigate = useNavigate();
    const upcService = useUpcService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const createUpc = async (upcRequestBody: IProductUpcCreateRequest) => {
        try {
            const upcResponse = await upcService.createProductUPC(upcRequestBody);
            if (upcResponse.status === HTTP_STATUS.OK) {
                navigate(ADMIN_ROUTES.UPC_LIST);
            } else if (upcResponse.status === HTTP_STATUS.INTERNAL_SERVER_ERROR) {
                showSnackbar("error", `something went wrong. Please try again`);
            } else if(upcResponse?.data.exceptionCode === GENERIC_EXCEPTION_CODE.DUPLICATE_ENTRY){
                showSnackbar("error", "UPC already exist");
            }     
        } catch (error) {
            showSnackbar("error", `UPC creation failed ${error}`)
        }
    }

    return (
        <div>
            { SnackBarComponent }
            <UpcFormTemplate upc={ null } onUpcSubmit={ createUpc } mode={ MODE.ADD}/>
        </div>
    )
}

export default UpcCreatePage;
