import React, { useEffect, useState } from 'react';
import { PocSectionProps } from './KeyPersonDetails.template';
import { createUseStyles } from 'react-jss';
import { FormikErrors } from 'formik';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useFileService } from '../../../services/useFileService';
import {
    IKeyDetail,
    ISocialMediaLink,
} from '../../pages/CustomerManagement/KeyPersonDetails.page';
import {
    HTTP_STATUS,
    KEY_PERSON_DETAIL_STATES,
    WHATSAPP_LINK,
} from '../../../utils/types';
import {
    ADMIN_ROUTES,
    DOCUMENT_RESOURCE_TYPE,
    HONORIFICS,
    SOCIAL_LINKS,
} from '../../../utils/constant';
import ImageUploadModalTemplate from '../Dashboard/ImageUpload.template';
import SelectV2 from '../../atoms/SelectV2';
import ButtonV2 from '../../atoms/ButtonV2';
import noProfileImage from '../../../assets/icons/noProfileImage.svg';
import Checkbox from '@mui/material/Checkbox';
import TextFieldV2 from '../../atoms/TextFieldV2';
import addOutlinedBlueIcon from '../../../assets/icons/addOutlinedBlueIcon.svg';
import { useNavigate, useParams } from 'react-router-dom';
import deleteIcon from '../../../assets/icons/deleteIcon.svg';
import MultiSelectInput2, {
    IMultiSelectOption,
} from '../../atoms/MultiSelectInput2';
import TextField from '../../atoms/TextField';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.primaryDark}`,
    },
    title: {
        color: theme.palette.textV2.primary.primary870,
    },
    select: {
        width: '384px !important'
    },
    errorField: {
        color: theme.palette.action.danger,
    },
    helperText: {
        position: 'absolute',
        bottom: '-20px',
    },
    imageContainer: {
        '&:hover $editButton': {
            opacity: 1,
            color: 'blue',
        },
    },
    editButton: {
        background: theme.palette.textV2.secondary.secondary100,
        opacity: 0,
        transition: 'opacity 0.3s ease',
        padding: '10px',
        gap: '8px',
        borderRadius: '6px',
    },
    imageHeading: {
        color: theme.palette.text.secondaryDark,
    },
    text: {
        color: theme.palette.text.secondaryDarkLight,
    },
    checkedInputColor: {
        color: theme.palette.textV2.tertiary.tertiary300,
        '&.Mui-checked': {
            color: theme.palette.textV2.primary.primary400,
        },
    },
    social: {
        color: theme.palette.textV2.primary.primary550
    },
}));

const AddNewKeyPersonDetilsTemplate: React.FC<PocSectionProps> = ({
    formik,
    setCurrentSectionTo,
}) => {
    const classes = useStyles();
    const fileService = useFileService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const navigate = useNavigate();

    const params = useParams();
    const id = Number(params.id);

    const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [currentIndex, setCurrentIndex] = useState<number | null>(null);
    const [errors, setErrors] = useState<{
        [key: number]: { [key: string]: string };
    }>({});

    const handleImageModalOpen = (index: number) => {
        setCurrentIndex(index);
        setIsImageModalOpen(true);
    };

    const handleMultiSelectChange =
        (index: number) => (selectedOptions: IMultiSelectOption[]) => {
            const updatedSocialLinks: ISocialMediaLink = {};
            selectedOptions.forEach((option) => {
                updatedSocialLinks[option.value] = '';
            });
            formik.setFieldValue(`newPocs.${index}.socialLinks`, updatedSocialLinks);
        };

    const handleTextFieldChange =
        (key: string, index: number) => (event: any) => {
            const value = event.target.value;
            formik.setFieldValue(`newPocs.${index}.socialLinks.${key}`, value);

        };

    const handleImageModalClose = () => {
        setIsImageModalOpen(false);
    };

    const handleWhatsAppLinkChange = (value: any, index: number) => {
        const currentValue = formik.values.newPocs[index].whatsAppLink;
        formik.setFieldValue(
            `newPocs.${index}.whatsAppLink`,
            currentValue === value ? null : value
        );
    };

    const addNewForm = () => {
        const emptyPoc = {
            name: '',
            email: '',
            mobile: '',
            department: '',
            designation: '',
            profilePic: '',
            countryCode: '+91',
            socialLinks: {},
            isAuthorized: false,
            alternateEmail: '',
            alternateMobile: '',
            prefix: '',
            modeOfCommunication: '',
            whatsAppLink: '',
        };
        formik.setFieldValue('newPocs', [...formik.values.newPocs, emptyPoc]);
    };

    const validatePoc =
        (pocs: IKeyDetail[], newPocs: IKeyDetail[], type: string) =>
            (event: any) =>
                async (index: number) => {
                    const { name, value } = event.target;
                    const nameField = name.split('.')[0];
                    formik.setFieldValue(`${nameField}[${index}].${type}`, value);

                    const field = name.split('.').pop();
                    if (!field) return;

                    const currentErrors = { ...errors };
                    currentErrors[index] = currentErrors[index] || {};

                    let errorMessage = '';
                    const fieldsToCheck: (keyof IKeyDetail)[] = [
                        'email',
                        'alternateEmail',
                        'mobile',
                        'alternateMobile',
                    ];

                    if (!value) {
                        currentErrors[index][type] = '';
                        setErrors(currentErrors);
                        formik.setFieldTouched(`newPocs[${index}].${type}`, false, false);
                        return;
                    }

                    if (
                        pocs.some((poc: any) => poc[field] === value) ||
                        newPocs.some(
                            (poc: any, idx: number) => idx !== index && poc[field] === value
                        )
                    ) {
                        errorMessage = `${field.charAt(0).toUpperCase() + field.slice(1)
                            } already exists`;
                    }

                    for (let checkField of fieldsToCheck) {
                        if (checkField !== field && newPocs[index]?.[checkField] === value) {
                            errorMessage = `${field.charAt(0).toUpperCase() + field.slice(1)
                                } can not be the same as ${checkField.charAt(0).toUpperCase() + checkField.slice(1)
                                }`;
                        }
                    }

                    currentErrors[index][type] = errorMessage;
                    setErrors(currentErrors);

                    if (errorMessage) {
                        formik.setFieldValue(`newPocs[${index}].${type}`, null);
                        formik.setFieldTouched(`newPocs[${index}].${type}`, true, false);
                    }
                };

    const isFormValid = () => {
        if (!formik.values.newPocs || !Array.isArray(formik.values.newPocs)) {
            return false;
        }

        return formik.values.newPocs.every((poc: any, index: number) => {
            const pocErrors = formik.errors.newPocs?.[index];
            if (typeof pocErrors === 'string') {
                return false;
            }
            const hasValidFields = Object.keys(poc).every((key) => {
                const keyTyped = key as keyof IKeyDetail;
                return !(pocErrors && pocErrors[keyTyped]);
            });
            return hasValidFields;
        });
    };

    const handleSave = () => {
        if (isFormValid()) {
            formik.handleSubmit();
            setCurrentSectionTo(KEY_PERSON_DETAIL_STATES.VIEW_KEY_PERSON_DETAILS);
        } else {
            showSnackbar(
                'error',
                'Please resolve all validation errors before submitting.'
            );
        }
    };

    const handleSubmit = async (event: any) => {
        try {
            if (selectedFile) {
                const presignedUrlResponse = await fileService.generatePresignedUrl(
                    selectedFile.name,
                    DOCUMENT_RESOURCE_TYPE.BUSINESS_PROFILE_KEY_PERSON_DETAILS,
                    1
                );
                if (
                    presignedUrlResponse &&
                    presignedUrlResponse.data &&
                    presignedUrlResponse.data.data &&
                    presignedUrlResponse.data.data.presignedUrl
                ) {
                    const presignedUrl = presignedUrlResponse.data.data.presignedUrl;
                    const imageUploadResponse =
                        await fileService.uploadImageUsingPreSignedUrls(
                            selectedFile,
                            presignedUrl
                        );
                    const pocProfilePicUrl = await fileService.getImagePreSignedUrl(
                        presignedUrlResponse.data.data.objectKey
                    );
                    if (pocProfilePicUrl.status === HTTP_STATUS.OK) {
                        formik.setFieldValue(
                            `newPocs[${currentIndex}].profilePic`,
                            pocProfilePicUrl?.data?.data
                        );
                    }
                } else {
                    showSnackbar('error', 'Failed to generate presigned URL');
                }
            }
        } catch (error) {
            showSnackbar('error', 'File upload failed:');
        } finally {
            setIsImageModalOpen(false);
            setSelectedFile(null);
        }
    };

    const handleBackToDashboard = () => {
        navigate(ADMIN_ROUTES.CUSTOMER_MANAGEMENT_LIST);
    };

    const deletePoc = async (index: number) => {
        const newPocs = [...formik.values.newPocs];
        newPocs.splice(index, 1);
        formik.setFieldValue('newPocs', newPocs);
    };

    const handleBackToViewPage = () => {
        formik.setFieldValue('newPocs', []);
        setCurrentSectionTo(KEY_PERSON_DETAIL_STATES.VIEW_KEY_PERSON_DETAILS);
    };

    useEffect(() => {
        formik.resetForm({
            ...formik,
            touched: {},
        });
    }, []);

    return (
        <form>
            {isImageModalOpen && (
                <ImageUploadModalTemplate
                    dialogOpen={isImageModalOpen}
                    setDialogOpen={handleImageModalClose}
                    onSubmit={(e: any) => handleSubmit(e)}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                />
            )}
            {formik.values?.newPocs?.map((poc: any, index: number) => (
                <div
                    key={index}
                    className={`${classes.container} mt-6 p-6  rounded-lg`}
                >
                    <div className="border-b-2 font-medium text-2xl items-center mb-6 flex justify-between">
                        <span className={`flex mb-6 ${classes.imageHeading}`}>
                            Key Person Details {index + 1}
                        </span>
                        <div className="flex items-center gap-6 mb-6">
                            <div>
                                <Checkbox
                                    id={`isAuthorized-${index}`}
                                    name={`poc[${index}].isAuthorized`}
                                    className={` ${classes.checkedInputColor}`}
                                    checked={poc.isAuthorized}
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            `poc[${index}].isAuthorized`,
                                            e.target.checked
                                        )
                                    }
                                />
                                <label className="text-base ml-0.5">Authorized Person</label>
                            </div>
                            <div>
                                <img
                                    src={deleteIcon}
                                    alt="deleteicon"
                                    className="blink-on-hover blink-on-click"
                                    onClick={() => deletePoc(index)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="gap-y-1 gap-x-6 flex">
                        <div>
                            <span
                                className={`text-base font-medium flex pb-3 ${classes.imageHeading}`}
                            >
                                Key Person Images
                            </span>
                            <div
                                className={`${classes.imageContainer} rounded-lg w-36 h-40 flex relative justify-between`}
                            >
                                <div>
                                    <img
                                        src={poc?.profilePic || noProfileImage}
                                        alt="Profile"
                                        className="w-full h-full  rounded-xl object-cover"
                                    />
                                </div>
                                <img
                                    src={addOutlinedBlueIcon}
                                    alt="Profile"
                                    className={`${classes.editButton} cursor-pointer absolute top-2 right-2 z-10`}
                                    onClick={() => handleImageModalOpen(index)}
                                />
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="grid gap-y-6 mb-6">
                                <div className="grid grid-cols-2 gap-x-6">
                                    <div className="grid gap-y-1">
                                        <SelectV2
                                            {...formik.getFieldProps(`newPocs.${index}.prefix`)}
                                            variant="outlined"
                                            label="Prefix"
                                            value={formik.values.newPocs[index]?.prefix}
                                            id="sort"
                                            options={HONORIFICS}
                                        />
                                    </div>
                                    <div className="grid gap-y-1">
                                        <TextFieldV2
                                            label="Name"
                                            placeholder="Enter Name"
                                            variant="outlined"
                                            {...formik.getFieldProps(`newPocs.${index}.name`)}
                                            error={
                                                formik.touched.newPocs?.[index]?.name &&
                                                Boolean(
                                                    formik.errors.newPocs &&
                                                    Array.isArray(formik.errors.newPocs) &&
                                                    (
                                                        formik.errors
                                                            .newPocs as FormikErrors<IKeyDetail>[]
                                                    )[index]?.name
                                                )
                                            }
                                            helperText={
                                                formik.touched.newPocs?.[index]?.name &&
                                                formik.errors.newPocs &&
                                                Array.isArray(formik.errors.newPocs) &&
                                                (formik.errors.newPocs as FormikErrors<IKeyDetail>[])[
                                                    index
                                                ]?.name
                                            }
                                            FormHelperTextProps={{
                                                className: `${classes.helperText}`,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-x-6 gap-y-1">
                                <div className="grid gap-y-1 ">
                                    <TextFieldV2
                                        label="Department"
                                        placeholder="Enter Department"
                                        variant="outlined"
                                        {...formik.getFieldProps(`newPocs.${index}.department`)}
                                        error={
                                            formik.touched.newPocs?.[index]?.department &&
                                            Boolean(
                                                formik.errors.newPocs &&
                                                Array.isArray(formik.errors.newPocs) &&
                                                (formik.errors.newPocs as FormikErrors<IKeyDetail>[])[
                                                    index
                                                ]?.department
                                            )
                                        }
                                        helperText={
                                            formik.touched.newPocs?.[index]?.department &&
                                            formik.errors.newPocs &&
                                            Array.isArray(formik.errors.newPocs) &&
                                            (formik.errors.newPocs as FormikErrors<IKeyDetail>[])[
                                                index
                                            ]?.department
                                        }
                                        FormHelperTextProps={{ className: `${classes.helperText}` }}
                                    />
                                </div>
                                <div className="grid gap-y-1">
                                    <TextFieldV2
                                        label="Designation"
                                        placeholder="Enter Designation"
                                        variant="outlined"
                                        {...formik.getFieldProps(`newPocs.${index}.designation`)}
                                        error={
                                            formik.touched.newPocs?.[index]?.designation &&
                                            Boolean(
                                                formik.errors.newPocs &&
                                                Array.isArray(formik.errors.newPocs) &&
                                                (formik.errors.newPocs as FormikErrors<IKeyDetail>[])[
                                                    index
                                                ]?.designation
                                            )
                                        }
                                        helperText={
                                            formik.touched.newPocs?.[index]?.designation &&
                                            formik.errors.newPocs &&
                                            Array.isArray(formik.errors.newPocs) &&
                                            (formik.errors.newPocs as FormikErrors<IKeyDetail>[])[
                                                index
                                            ]?.designation
                                        }
                                        FormHelperTextProps={{ className: `${classes.helperText}` }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-6 gap-y-1 mb-6 pt-6">
                        <div className="grid gap-y-1">
                            <TextFieldV2
                                label="Email ID"
                                placeholder="Enter email"
                                variant="outlined"
                                {...formik.getFieldProps(`newPocs.${index}.email`)}
                                error={
                                    formik.touched.newPocs?.[index]?.email &&
                                    Boolean(
                                        formik.errors.newPocs &&
                                        Array.isArray(formik.errors.newPocs) &&
                                        (formik.errors.newPocs as FormikErrors<IKeyDetail>[])[
                                            index
                                        ]?.email
                                    )
                                }
                                helperText={
                                    errors[index]?.email || (
                                        formik.touched.newPocs?.[index]?.email &&
                                        formik.errors.newPocs &&
                                        Array.isArray(formik.errors.newPocs) &&
                                        (formik.errors.newPocs as FormikErrors<IKeyDetail>[])[index]?.email
                                    )
                                }
                                onChange={(event) =>
                                    validatePoc(
                                        formik.values.pocs,
                                        formik.values.newPocs,
                                        'email'
                                    )(event)(index)
                                }
                            />
                        </div>
                        <div className="grid gap-y-1">
                            <TextFieldV2
                                label="Alternate Email ID"
                                placeholder="Enter email"
                                variant="outlined"
                                {...formik.getFieldProps(`newPocs.${index}.alternateEmail`)}
                                error={
                                    formik.touched.newPocs?.[index]?.alternateEmail &&
                                    Boolean(
                                        formik.errors.newPocs &&
                                        Array.isArray(formik.errors.newPocs) &&
                                        (formik.errors.newPocs as FormikErrors<IKeyDetail>[])[
                                            index
                                        ]?.alternateEmail
                                    )
                                }
                                helperText={
                                    errors[index]?.alternateEmail || (
                                        formik.touched.newPocs?.[index]?.alternateEmail &&
                                        formik.errors.newPocs &&
                                        Array.isArray(formik.errors.newPocs) &&
                                        (formik.errors.newPocs as FormikErrors<IKeyDetail>[])[index]?.alternateEmail
                                    )
                                }
                                onChange={(event) =>
                                    validatePoc(
                                        formik.values.pocs,
                                        formik.values.newPocs,
                                        'alternateEmail'
                                    )(event)(index)
                                }
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-6 gap-y-1 mb-6">
                        <div className="grid gap-y-1">
                            <label className={`${classes.text}`}>
                                Phone Number{' '}
                            </label>
                            <div className="flex gap-x-2">
                                <div className="w-1/4">
                                    <TextFieldV2
                                        variant="outlined"
                                        value="IND (+91)"
                                        disabled={true}
                                    />
                                </div>
                                <div className="w-3/4">
                                    <TextFieldV2
                                        placeholder="Enter number"
                                        variant="outlined"
                                        {...formik.getFieldProps(`newPocs.${index}.mobile`)}
                                        error={
                                            formik.touched.newPocs?.[index]?.mobile &&
                                            Boolean(
                                                formik.errors.newPocs &&
                                                Array.isArray(formik.errors.newPocs) &&
                                                (formik.errors.newPocs as FormikErrors<IKeyDetail>[])[
                                                    index
                                                ]?.mobile
                                            )
                                        }
                                        inputProps={{
                                            maxLength: 10,
                                        }}
                                        helperText={
                                            errors[index]?.mobile || (
                                                formik.touched.newPocs?.[index]?.mobile &&
                                                formik.errors.newPocs &&
                                                Array.isArray(formik.errors.newPocs) &&
                                                (formik.errors.newPocs as FormikErrors<IKeyDetail>[])[index]?.mobile
                                            )
                                        }
                                        onChange={(event) =>
                                            validatePoc(
                                                formik.values.pocs,
                                                formik.values.newPocs,
                                                'mobile'
                                            )(event)(index)
                                        }
                                    />
                                </div>
                            </div>
                            <label className={`${classes.text}`}>
                                <Checkbox
                                    checked={
                                        formik.values.newPocs[index].whatsAppLink ===
                                        WHATSAPP_LINK.PRIMARY
                                    }
                                    onChange={() =>
                                        handleWhatsAppLinkChange(WHATSAPP_LINK.PRIMARY, index)
                                    }
                                    id={`mobile-${index}`}
                                    className={`${classes.checkedInputColor}`}
                                />
                                Check this if this is your Whatsapp Number{' '}
                            </label>
                        </div>
                        <div className="grid gap-y-1">
                            <label className={`${classes.text}`}>
                                Alternate Phone Number{' '}
                            </label>
                            <div className="flex gap-x-2">
                                <div className="w-1/4">
                                    <TextFieldV2
                                        {...formik.getFieldProps(`newPocs.${index}.countryCode`)}
                                        variant="outlined"
                                        value="IND (+91)"
                                        disabled={true}
                                    />
                                </div>
                                <div className="w-3/4">
                                    <TextFieldV2
                                        placeholder="Enter number"
                                        variant="outlined"
                                        {...formik.getFieldProps(
                                            `newPocs.${index}.alternateMobile`
                                        )}
                                        error={
                                            formik.touched.newPocs?.[index]?.alternateMobile &&
                                            Boolean(
                                                formik.errors.newPocs &&
                                                Array.isArray(formik.errors.newPocs) &&
                                                (formik.errors.newPocs as FormikErrors<IKeyDetail>[])[
                                                    index
                                                ]?.alternateMobile
                                            )
                                        }
                                        inputProps={{
                                            maxLength: 10,
                                        }}
                                        helperText={
                                            errors[index]?.alternateMobile || (
                                                formik.touched.newPocs?.[index]?.alternateMobile &&
                                                formik.errors.newPocs &&
                                                Array.isArray(formik.errors.newPocs) &&
                                                (formik.errors.newPocs as FormikErrors<IKeyDetail>[])[index]?.alternateMobile
                                            )
                                        }
                                        onChange={(event) =>
                                            validatePoc(
                                                formik.values.pocs,
                                                formik.values.newPocs,
                                                'alternateMobile'
                                            )(event)(index)
                                        }
                                    />
                                </div>
                            </div>
                            <label className={`${classes.text}`}>
                                <Checkbox
                                    checked={
                                        formik.values.newPocs[index].whatsAppLink ===
                                        WHATSAPP_LINK.SECONDARY
                                    }
                                    onChange={() =>
                                        handleWhatsAppLinkChange(WHATSAPP_LINK.SECONDARY, index)
                                    }
                                    id={`alternateMobile-${index}`}
                                    className={` ${classes.checkedInputColor}`}
                                />
                                Check this if this is your Whatsapp Number{' '}
                            </label>
                        </div>
                    </div>
                    <div className={`grid gap-y-1`}>
                        <div className={classes.select}>
                            <MultiSelectInput2
                                options={SOCIAL_LINKS.map((link) => ({
                                    label: link.label,
                                    value: link.label,
                                }))}
                                label="Social Media Links"
                                value={Object.keys(poc.socialLinks).map((value) => ({
                                    label: value,
                                    value: value,
                                }))}
                                onchange={handleMultiSelectChange(index)}
                            />
                        </div>
                        {Object.keys(formik.values.newPocs[index].socialLinks).map((socialTag) => (
                            <TextFieldV2
                                {...formik.getFieldProps(`newPocs[${index}].socialLinks[${socialTag}]`)}
                                variant="outlined"
                                label={
                                    <span className='flex gap-1 text-base font-medium !mt-5 !mb-2'>
                                        <span className={classes.title}>Enter Link </span>
                                        <span className={classes.social}>({socialTag})</span>
                                    </span>
                                }
                                value={formik.values.newPocs[index].socialLinks.socialTag}
                                onChange={handleTextFieldChange(socialTag, index)}
                                error={
                                    formik.touched.newPocs?.[index]?.socialLinks?.[socialTag] &&
                                    ((formik.errors.newPocs as any)?.[index]?.socialLinks?.[socialTag])
                                }
                                helperText={
                                    Boolean(formik.touched.newPocs?.[index]?.socialLinks) &&
                                    ((formik.errors.newPocs as any)?.[index]?.socialLinks?.[socialTag])
                                }
                            />
                        )
                        )}
                    </div>
                </div>
            ))}
            <div className="flex mt-6 relative">
                <div className="!absolute right-0 top-0 flex items-center gap-2">
                    <ButtonV2
                        className={` justify-self-end`}
                        variant="secondaryContained"
                        label="Back to Dashboard"
                        onClick={handleBackToDashboard}
                    />
                    <ButtonV2
                        variant="tertiaryContained"
                        label="Add New Key Person"
                        onClick={addNewForm}
                    />
                    <ButtonV2
                        variant="tertiaryContained"
                        label="Back"
                        onClick={handleBackToViewPage}
                    />
                    <ButtonV2
                        variant="contained"
                        label="Save"
                        onClick={handleSave}
                        disabled={!isFormValid()}
                    />
                </div>
            </div>
        </form>
    );
};
export default AddNewKeyPersonDetilsTemplate;
