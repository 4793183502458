import React from 'react'
import { createUseStyles } from 'react-jss';
import TextFieldV2 from '../../../atoms/TextFieldV2';
import { MODE } from '../../../../utils/constant';

interface ServicePriceFormTemplateProps {
    mode: string,
    formik: any;
}

const useStyles = createUseStyles((theme: any) => ({
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
}));

const ServicePriceFormTemplate: React.FC<ServicePriceFormTemplateProps> = ({ mode, formik }) => {
    const classes = useStyles();

    return (
        <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
            <div className={`${classes.heading} text-base font-medium`}>Service Price</div>
            <div className='grid grid-cols-3 gap-x-4'>
                <div className="grid gap-y-1">
                    <TextFieldV2
                        label="Normal Service Charges/MT"
                        placeholder='Enter Here'
                        {...formik.getFieldProps("serviceCharge.normalServiceCharge")}
                        error={formik.touched.serviceCharge?.normalServiceCharge && Boolean(formik.errors.serviceCharge?.normalServiceCharge)}
                        inputProps={{ readOnly: mode === MODE.VIEW }}
                        type='number'
                    />
                    {formik.errors.serviceCharge?.normalServiceCharge && formik.touched.serviceCharge?.normalServiceCharge && (
                        <div className={`${classes.errorMessage} text-xs`}>{formik.errors.serviceCharge?.normalServiceCharge}</div>
                    )}
                </div>

                <div className="grid gap-y-1">
                    <TextFieldV2
                        label="Minimum Service Charges/MT"
                        placeholder='Enter Here'
                        {...formik.getFieldProps("serviceCharge.minimumServiceCharge")}
                        error={formik.touched.serviceCharge?.minimumServiceCharge && Boolean(formik.errors.serviceCharg?.minimumServiceCharge)}
                        inputProps={{ readOnly: mode === MODE.VIEW }}
                        type='number'
                    />
                    {formik.errors.serviceCharge?.minimumServiceCharge && formik.touched.serviceCharge?.minimumServiceCharge && (
                        <div className={`${classes.errorMessage} text-xs`}>{formik.errors.serviceCharge?.minimumServiceCharge}</div>
                    )}
                </div>

                <div className="grid gap-y-1">
                    <TextFieldV2
                        label="Maximum Service Charges/MT"
                        placeholder='Enter Here'
                        {...formik.getFieldProps("serviceCharge.maximumServiceCharge")}
                        error={formik.touched.serviceCharge?.maximumServiceCharge && Boolean(formik.errors.serviceCharge?.maximumServiceCharge)}
                        inputProps={{ readOnly: mode === MODE.VIEW }}
                        type='number'
                    />
                    {formik.errors.serviceCharge?.maximumServiceCharge && formik.touched.serviceCharge?.maximumServiceCharge && (
                        <div className={`${classes.errorMessage} text-xs`}>{formik.errors.serviceCharge?.maximumServiceCharge}</div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ServicePriceFormTemplate;