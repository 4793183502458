import { FormikProps, useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { IInstantQuotation, IInstantQuotationAddressDetails } from '../../pages/ManageQuotation/QuoteCreate.page';
import { createUseStyles } from 'react-jss';
import SelectV2 from '../../atoms/SelectV2';
import { ADDRESS_TYPES, LEAD_PRIORITY, LEAD_SOURCE, MODE, QUOTATION_STATUS, REGEX, STATUS_CONST } from '../../../utils/constant';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import TextFieldV2 from '../../atoms/TextFieldV2';
import { BillDetailsModalTemplate } from './BillDetailsModal.template';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import edit from '../../../assets/icons/edit.svg'
import * as yup from "yup";

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.primaryDark}`,
        maxWidth: '500px'
    },
    selectField: {
        width: "160px"
    },
    ellipsis: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    text: { color: theme.palette.textV2.primary.primary870 },
    errorMessage: {
		color: theme.palette.action.danger,
	},
    textHeader: { color: theme.palette.textV2.tertiary.tertiary900 },
    textField: {
        "& .MuiInputBase-root": {
            border: "1px solid",
            borderColor: theme.palette.borderV2.tertiary.tertiary200,
            fontSize: "16px",
            fontWeight: 500,
            borderRadius: 8,
            color: theme.palette.textV2.primary.primary900,
            "& .Mui-disabled": {
                backgroundColor: 'white !important',
                fontWeight: 500,
                borderColor: theme.palette.textV2.tertiary.tertiary200,
                borderRadius: "8px",
                fontSize: "16px"
            },
        },
    },
    datePicker: {
        border: "1px solid",
        borderColor: "#DDD",
        fontSize: "16px",
        fontWeight: 500,
        borderRadius: 8,
        "&:hover": {
            borderColor: "#FD6202",
            borderWidth: 2,
            outline: "none",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: "#DDD",
        },
        "&:focus-within": {
            outline: "none",
            borderColor: `#FD6202 !important`,
            borderWidth: "2px !important",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: `#FD6202 !important`,
        },
    },
}));

export interface IQuotationDetailProps {
    formik: FormikProps<IInstantQuotation>;
    isEditable: boolean;
    mode: string;
    currentStatus: any
}

export const QuotationDetailsTemplate: React.FC<IQuotationDetailProps> = ({ formik, isEditable, mode, currentStatus }) => {

    const classes = useStyles();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [typeOf, setTypeOf] = useState<string>('');
    const [isEditing, setIsEditing] = useState(false);
    const [filledDetails, setFilledDetails] = useState<{
        billing?: IInstantQuotationAddressDetails | null;
        shipping?: IInstantQuotationAddressDetails | null;
    }>({
        billing: formik.values.billToAddressDetails ?? null,
        shipping: formik.values.shipToAddressDetails ?? null,
    });

    const initialValues : IInstantQuotationAddressDetails = {
        companyName: null,
        addressLine1: null,
        addressLine2: null,
        pincode: null,
        city: null,
        state: null,
        emailId: null,
        phoneNumber: null,
        gstIn: null,
    };

    const validationSchema = yup.object().shape({
        companyName: yup.string().required('Company name is required'),
        addressLine1: yup.string().required('Address line 1 is required'),
        addressLine2: yup.string().required('Address line 2 is required'),
        pincode: yup.string().required('Pincode is required').matches(REGEX.PINCODE, 'Enter a valid pincode'),
        city: yup.string().required('City is required').matches(REGEX.FULL_NAME, 'Enter a valid City'),
        state: yup.string().required('State is required').matches(REGEX.FULL_NAME, 'Enter a valid state'),
        emailId: yup.string().required("Email is required").matches(REGEX.EMAIL, 'Entered Invalid Email ID'),
        phoneNumber: yup.string().required("Phone Number is required").matches(REGEX.PHONE_NUMBER, 'Entered Invalid phone number'),
        gstIn: yup.string().required("GST Number is required").matches(REGEX.GST, 'Entered Invalid GST number.'),
    });

    const formikForPopup = useFormik<IInstantQuotationAddressDetails>({
        initialValues,
        validationSchema,
        validateOnChange: true,
        validateOnMount: true,
        validateOnBlur: true,
        onSubmit: async (values, { setSubmitting }) => {
        }
    });


    const STATUS_TRANSITIONS:Record<any, String[]>  = {
        DRAFT: [STATUS_CONST.SENT, STATUS_CONST.CLOSED_LOST],
        SENT: [STATUS_CONST.CLOSED_WIN, STATUS_CONST.IN_PROGRESS, STATUS_CONST.CLOSED_LOST],
        IN_PROGRESS: [STATUS_CONST.CLOSED_WIN, STATUS_CONST.IN_PROGRESS, STATUS_CONST.CLOSED_LOST],
        CLOSED_WIN: [],
        CLOSED_LOST: [],
    };
    
    const handleStatusChange = (event: any) => {
        const newStatus = event.target.value;
        formik.setFieldValue('status', newStatus);
    };

    const getAllowedOptions = () => {
        const allowedValues = STATUS_TRANSITIONS[currentStatus as keyof typeof STATUS_TRANSITIONS] || [STATUS_CONST.DRAFT];
        return QUOTATION_STATUS.filter(option => allowedValues.includes(option.value));
    }

    const handleAddBillClick = (type: string) => {
        setTypeOf(type);
        formikForPopup.setValues({});
        formikForPopup.resetForm();
        if (type === ADDRESS_TYPES.BILLING) {
            formikForPopup.setValues({...formik?.values?.billToAddressDetails});
        } else {
            formikForPopup.setValues({...formik?.values?.shipToAddressDetails});
        }
        setIsModalOpen(true);

    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveDetails = (details: IInstantQuotationAddressDetails) => {
        if (Object.keys(formikForPopup.errors).length > 0) {
            return;
        }
        setFilledDetails(prevDetails => ({
            ...prevDetails,
            [typeOf]: details,
        }));
        if(typeOf === ADDRESS_TYPES.BILLING){
            formik.setFieldValue('billToAddressDetails',details);
        }else{
            formik.setFieldValue('shipToAddressDetails',details);
        }
        setIsModalOpen(false);
    };

    const handleBack = () => {
        if(typeOf === ADDRESS_TYPES.BILLING){
            formikForPopup.setValues({...formik?.values?.billToAddressDetails});
        }else{
            formikForPopup.setValues({...formik?.values?.shipToAddressDetails});
        }
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (mode === MODE.VIEW.toLowerCase() || mode === MODE.UPDATE.toLowerCase()) {
            setFilledDetails({
                billing: formik?.values?.billToAddressDetails ?? null,
                shipping: formik?.values?.shipToAddressDetails ?? null,
            })
        }
    }, [formik?.values?.billToAddressDetails, formik?.values?.shipToAddressDetails])

    return (
        <div className='grid gap-6'>
            <BillDetailsModalTemplate
                dialogOpen={isModalOpen}
                setDialogOpen={setIsModalOpen}
                formik={formikForPopup}
                type={typeOf}
                setFilledDetails={handleSaveDetails}
                handleBack={handleBack}
            />
            <div className='flex justify-between'>
                <div className='flex flex-col gap-4'>
                <div className='grid grid-cols-2  gap-x-4 items-center'>
                    <div className={`${classes.container} mt-6 p-4 rounded-lg`}>
                        Supplier
                        <div className={`font-medium ${classes.textHeader} ellipsis`}>
                            AVIGHNA STEEL PRIVATE LIMITED
                        </div>
                        <div className={`${classes.text} grid gap-1`}>
                            <div className="ellipsis">A-88, MIDC</div>
                            <div className="ellipsis">Taloja Industrial Area</div>
                            <div className="ellipsis">Dist. Raigad, Maharashtra 410208</div>
                            <div className="ellipsis">Phone: +91 9717817357</div>
                            <div className="ellipsis">Email: sales@avighnasteel.in</div>
                            <div className="ellipsis">GSTIN: 27AATCA6123E1ZN</div>
                        </div>
                    </div>
                    </div>
                    <div className='grid grid-cols-2  gap-x-4 items-center'>
                        <div className={`${classes.container} p-4 rounded-lg cursor-pointer h-min ellipsis`}
                            onClick={() => !filledDetails.billing && handleAddBillClick(ADDRESS_TYPES.BILLING)}
                        >
                            {Object.keys(formik.values?.billToAddressDetails ?? {}).length > 0 ? (
                                <div>
                                    <div className='flex justify-between'>Bill To {mode === 'add' && <img src={edit} alt='editicon' onClick={() => isEditable && handleAddBillClick('billing')} />}</div>
                                    <div className={`font-medium ${classes.textHeader} ${classes.ellipsis}`}>
                                        {formik.values?.billToAddressDetails?.companyName}
                                    </div>
                                    <div className={`${classes.text} grid gap-1`}>
                                        <div className={`${classes.ellipsis}`}>{formik.values?.billToAddressDetails?.addressLine1}</div>
                                        <div className={`${classes.ellipsis}`}>
                                            {formik.values?.billToAddressDetails?.addressLine2}
                                        </div>
                                        <div className={`${classes.ellipsis}`}>Dist: {formik.values?.billToAddressDetails?.city}, {formik.values?.billToAddressDetails?.state}  {formik.values?.billToAddressDetails?.pincode}</div>
                                        <div className={`${classes.ellipsis}`}>Phone Number: {formik.values?.billToAddressDetails?.phoneNumber}</div>
                                        <div className={`${classes.ellipsis}`}>Email ID: {formik.values?.billToAddressDetails?.emailId}</div>
                                        <div className={`${classes.ellipsis}`}>GSTIN: {formik.values?.billToAddressDetails?.gstIn}</div>
                                    </div>
                                </div>
                            ) : (
                                '+Add Bill to Details'
                            )}
                        </div>
                        <div className={`${classes.container} p-4 rounded-lg cursor-pointer h-min ellipsis`}
                            onClick={() => !filledDetails.shipping && handleAddBillClick(ADDRESS_TYPES.SHIPPING)}
                        >
                            {Object.keys(formik.values?.shipToAddressDetails ?? {}).length > 0 ? (
                                <div>
                                    <div className='flex justify-between'>Ship To {mode === 'add' && <img src={edit} alt='editicon' onClick={() => isEditable && handleAddBillClick('shipping')} />}</div>
                                    <div className={`font-medium ${classes.textHeader} ellipsis`}>
                                        {formik.values?.shipToAddressDetails?.companyName}
                                    </div>
                                    <div className={`${classes.text} grid gap-1`}>
                                        <div className={`${classes.ellipsis}`}>{formik.values?.shipToAddressDetails?.addressLine1}</div>
                                        <div className={`${classes.ellipsis}`}>
                                            {formik.values?.shipToAddressDetails?.addressLine2}
                                        </div>
                                        <div className={`${classes.ellipsis}`}>Dist: {formik.values?.shipToAddressDetails?.city}, {formik.values?.shipToAddressDetails?.state}  {formik.values?.shipToAddressDetails?.pincode}</div>
                                        <div className={`${classes.ellipsis}`}>Phone Number: {formik.values?.shipToAddressDetails?.phoneNumber}</div>
                                        <div className={`${classes.ellipsis}`}>Email ID: {formik.values?.shipToAddressDetails?.emailId}</div>
                                        <div className={`${classes.ellipsis}`}>GSTIN: {formik.values?.shipToAddressDetails?.gstIn}</div>
                                    </div>
                                </div>
                            ) : (
                                '+Add Ship to Details'
                            )}
                        </div>
                    </div>
                </div>
                <div className='flex items-start gap-4 w-[480px]'>
                    <div className='flex flex-col gap-y-4 w-1/2'>
                        <div>
                            {(mode !== MODE.ADD.toLowerCase()) && <TextFieldV2
                                {...formik.getFieldProps("quotationCode")}
                                className=" text-base font-medium"
                                type="text"
                                label="Quotation ID"
                                placeholder="Enter Code"
                                disabled
                            />}
                        </div>
                        <div className='flex flex-col'>
                            <label>Quotation Start Date</label>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    value={formik?.values?.quotationDate ? moment(formik.values.quotationDate, 'YYYY-MM-DD') : null}
                                    format="DD-MM-YYYY"
                                    onChange={(newValue) => {
                                        const formattedDate = moment(newValue).format('YYYY-MM-DD');
                                        formik.setFieldValue('quotationDate', formattedDate);
                                    }}
                                    className={classes.datePicker}
                                    disabled={!isEditable}
                                />
                            </LocalizationProvider>
                            {formik.touched && formik.touched?.quotationDate && formik.errors?.quotationDate && (
								<div className={classes.errorMessage}>
									<small>{formik.errors?.quotationDate}</small>
								</div>
							)}
                        </div>
                        <div className='flex flex-col '>
                            <label>Expected Closure Date</label>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    value={formik?.values?.closureDate ? moment(formik.values.closureDate, 'YYYY-MM-DD') : null}
                                    format="DD-MM-YYYY"
                                    onChange={(newValue) => {
                                        const formattedDate = moment(newValue).format('YYYY-MM-DD');
                                        formik.setFieldValue('closureDate', formattedDate);
                                    }}
                                    className={classes.datePicker}
                                    disabled={!isEditable || formik.values?.quotationDate === ''}
                                />
                            </LocalizationProvider>
                            {formik.touched && formik.touched?.closureDate && formik.errors?.closureDate && (
								<div className={classes.errorMessage}>
									<small>{formik.errors?.closureDate}</small>
								</div>
							)}
                        </div>
                        <div className='flex flex-col'>
                            <label>Quotation Validity Date</label>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    value={formik?.values?.validityDate ? moment(formik.values.validityDate, 'YYYY-MM-DD') : null}
                                    format="DD-MM-YYYY"
                                    onChange={(newValue) => {
                                        const formattedDate = moment(newValue).format('YYYY-MM-DD');
                                        formik.setFieldValue('validityDate', formattedDate);
                                    }}
                                    className={classes.datePicker}
                                    disabled={!isEditable || formik.values?.closureDate === ''}
                                />
                            </LocalizationProvider>
                            {formik.touched && formik.touched?.validityDate && formik.errors?.validityDate && (
								<div className={classes.errorMessage}>
									<small>{formik.errors?.validityDate}</small>
								</div>
							)}
                        </div>
                    </div>
                    <div className='flex flex-col gap-4 w-1/2'>
                        <div>
                            {(mode !== MODE.ADD.toLowerCase()) && <TextFieldV2
                                value={`V ${formik.values.version}`}
                                className=" text-base font-medium"
                                type="text"
                                label="Quotation Version"
                                placeholder="Version"
                                fullWidth
                                disabled
                            />}
                        </div>
                        <div >
                            <div >
                                <><SelectV2
                                    {...formik.getFieldProps("leadSource")}
                                    variant="outlined"
                                    label="Lead Source"
                                    placeholder="Select Lead Source"
                                    fullWidth
                                    options={
                                        Object.values(LEAD_SOURCE)?.map((option: any) => ({
                                            value: option.value,
                                            label: option.label
                                        })) || []
                                    }
                                    disabled={!isEditable}
                                />
                                    {formik.errors?.leadSource && formik.touched?.leadSource && (
                                        <div className={classes.errorMessage}>
                                            <small>{formik.errors?.leadSource}</small>
                                        </div>
                                    )}
                                </>

                            </div>
                        </div>
                        <div >
                            <div>
                               <>
                               <SelectV2
                                    variant="outlined"
                                    label="Lead Priority"
                                    placeholder="Select Lead Priority"
                                    fullWidth
                                    {...formik.getFieldProps("leadPriority")}
                                    options={
                                        Object.values(LEAD_PRIORITY)?.map((option: any) => ({
                                            value: option.value,
                                            label: option.label
                                        })) || []
                                    }
                                    disabled={!isEditable}
                                />
                                {formik.errors?.leadPriority && formik.touched?.leadPriority && (
                                        <div className={classes.errorMessage}>
                                            <small>{formik.errors?.leadPriority}</small>
                                        </div>
                                    )}
                                </>

                            </div>
                        </div>
                        <div >
                            <div>
                                <>
                                <SelectV2
                                    variant="outlined"
                                    label="Lead Status"
                                    placeholder="Select Status"
                                    fullWidth
                                    {...formik.getFieldProps("status")}
                                    options={
                                       getAllowedOptions()
                                    }
                                    onChange={handleStatusChange}
                                    disabled={mode === MODE.VIEW.toLowerCase() || formik.values.status === STATUS_CONST.REVISED}
                                />
                                {formik.errors?.status && formik.touched?.status && (
                                        <div className={classes.errorMessage}>
                                            <small>{formik.errors?.status}</small>
                                        </div>
                                    )}
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <TextFieldV2
                    {...formik.getFieldProps("message")}
                    className={`text-base font-medium ${classes.textField}`}
                    multiline
                    type="text"
                    label={
                        <div className="flex ">
                            Message
                            <img src={edit} alt="editicon" className="ml-2 cursor-pointer" onClick={handleEditClick} />
                        </div>
                    }
                    placeholder="Enter Data"
                    fullWidth
                    disabled={!isEditable || !isEditing}
                />
            </div>
        </div>

    )
}
