import React, { useMemo, useState } from 'react'
import { MODE, MY_MATERIAL, MY_MATERIAL_STATE } from '../../../utils/types';
import MyMaterialListingPage from './MyMaterialListing.page';
import MyMaterialAddProductPage from './MyMaterialProduct.page';
import ScrapProductCreationPage from './ScrapProductCreation.page';
import ScrapProductTemplate from '../../templates/Customer-Management/ScrapProducts/ScrapProduct.template';
import FinishedProductCreationPage from './FinishedProductCreation.page';
import FinishedProductTemplate from '../../templates/Customer-Management/FinishedProducts/FinishedProduct.template';


export interface IOTHER_PROPS {
    id?: number,
    name?: string
}

export interface IMY_MATERIAL_STATE {
    state: MY_MATERIAL_STATE,
    other?: IOTHER_PROPS
}

const MyMaterialPage = () => {
    const [myMaterialState, setMyMaterialState] = useState<IMY_MATERIAL_STATE>({ state: MY_MATERIAL_STATE.MY_MATERIAL_VIEW });
    const updateMaterialStateTo = (state: MY_MATERIAL_STATE, props?: IOTHER_PROPS) => {
        setMyMaterialState({ state, other: props })
    }

    const myMaterialStateView = useMemo(() => {
        switch (myMaterialState.state) {
            case MY_MATERIAL_STATE.MY_MATERIAL_CREATE: return <MyMaterialAddProductPage updateMaterialStateTo={updateMaterialStateTo} myMaterialState={myMaterialState} />;
            case MY_MATERIAL_STATE.FINISHED_PRODUCT_CREATE: return <FinishedProductCreationPage updateMaterialStateTo={updateMaterialStateTo} myMaterialState={myMaterialState} mode={MODE.ADD} />;
            case MY_MATERIAL_STATE.FINISHED_PRODUCT_UPDATE: return <FinishedProductCreationPage updateMaterialStateTo={updateMaterialStateTo} myMaterialState={myMaterialState} mode={MODE.EDIT} />;
            case MY_MATERIAL_STATE.FINISHED_PRODUCT_LISTING: return <FinishedProductTemplate updateMaterialStateTo={updateMaterialStateTo} />;
            case MY_MATERIAL_STATE.SCRAP_CREATE: return <ScrapProductCreationPage updateMaterialStateTo={updateMaterialStateTo} myMaterialState={myMaterialState} mode={MODE.ADD} />;
            case MY_MATERIAL_STATE.SCRAP_UPDATE: return <ScrapProductCreationPage updateMaterialStateTo={updateMaterialStateTo} myMaterialState={myMaterialState} mode={MODE.EDIT} />;
            case MY_MATERIAL_STATE.SCRAP_LISTING: return <ScrapProductTemplate updateMaterialStateTo={updateMaterialStateTo} />;
            default: return <MyMaterialListingPage updateMaterialStateTo={updateMaterialStateTo} />
        }
    }, [myMaterialState])

    return (
        <div>
            {myMaterialStateView}
        </div>
    )
}

export default MyMaterialPage