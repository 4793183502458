import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

const SECONDARY_CATALOGUE_URLS = {
	GET_ALL_SECONDARY_CATALOGUE: "/secondary-catalogues",
	GET_SECONDARY_CATALOGUE: "/secondary-catalogue/:id",
	CREATE_SECONDARY_CATALOGUE: "/secondary-catalogue",
	UPDATE_SECONDARY_CATALOGUE: "/secondary-catalogue/:id",
	DELETE_SECONDARY_CATALOGUE: "/secondary-catalogue/:id",
};

interface ISecondaryCatalogueFilterRequest {
	status?: string;
	catalogueName?: string;
	categoryId?: number;
	page: number;
	size: number;
	sort?: string;
}

interface ICategoryFilterRequest {
	name?: string;
	standardIds?: number[];
	gradeIds?: number[];
	brandIds?: number[];
	shapes?: string[];
	manufacturerIds?: number[];
	classes?: string[];
	categoryId?: number;
}
interface ISecondaryCatalogueBody {
	secondaryProductId: number | null;
	catalogueName: string;
	minimumQuantity: number | null;
	availableQuantity: number | null;
	application: string;
	defectDetails: string;
	additionalProductDetails: string;
	keywords: string;
	price: {[key: string]: number;}| null;
	packagingType: string;
	termsConditions: string;
	status: string;
}

export const useSecondaryCatalogueService = () => {
	const { user: authenticatedUser } = useAuthenticatedUser();

	const createSecondaryCatalogue = (data: ISecondaryCatalogueBody) => {
		return request(API_METHOD.POST, SECONDARY_CATALOGUE_URLS.CREATE_SECONDARY_CATALOGUE, authenticatedUser, data);
	};

	const updateSecondaryCatalogue = (id: number, data: ISecondaryCatalogueBody) => {
		return request(API_METHOD.PUT, replaceUrlParams(SECONDARY_CATALOGUE_URLS.UPDATE_SECONDARY_CATALOGUE, { id }), authenticatedUser, data);
	};

	const getAllSecondaryCatalogues = (data: ICategoryFilterRequest, params: ISecondaryCatalogueFilterRequest) => {
		return request(API_METHOD.POST, SECONDARY_CATALOGUE_URLS.GET_ALL_SECONDARY_CATALOGUE, authenticatedUser, data, { params });
	};

	const getSecondaryCatalogueById = (id: number) => {
		return request(API_METHOD.GET, replaceUrlParams(SECONDARY_CATALOGUE_URLS.GET_SECONDARY_CATALOGUE, { id }), authenticatedUser);
	};

	const deleteSecondaryCatalogue = (id: number) => {
		return request(API_METHOD.DELETE, replaceUrlParams(SECONDARY_CATALOGUE_URLS.DELETE_SECONDARY_CATALOGUE, { id }), authenticatedUser);
	};

	return {
		createSecondaryCatalogue,
		updateSecondaryCatalogue,
		getAllSecondaryCatalogues,
		getSecondaryCatalogueById,
		deleteSecondaryCatalogue,
	}
};
