import React, { useEffect, useState } from 'react'
import ButtonV2 from '../../atoms/ButtonV2';
import { HTTP_STATUS, IPagination } from '../../../utils/types';
import { ADMIN_ROUTES, QUOTATION_STATUS, STATUS_CONST, VENDOR_TYPE, initialPaginationValuesV2, initialPaginationValues } from '../../../utils/constant';
import { useSnackbar } from '../../../hooks/useSnackBar';
import activeCustomers from '../../../assets/images/activeCustomers.svg'
import inactiveCustomers from '../../../assets/images/inactiveCustomers.svg'
import totalCustomers from '../../../assets/images/totalCustomers.svg'
import newCustomers from '../../../assets/images/newCustomers.svg'
import { Dayjs } from 'dayjs';
import StatusCard from '../../atoms/Cards/StatusCard';
import { useNavigate } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import GenericDateRangePicker from '../../molecules/DatePicker/CustomizedDatePicker';
import { VendorListTemplate } from '../../templates/VendorManagement/VendorList.template';
import { ICustomerFilterRequest, useVendorServiceV2 } from '../../../services/useVendorServiceV2';
import TextFieldV2 from '../../atoms/TextFieldV2';
import FilterChip, { IMultiSelectOption } from '../../molecules/FilterChip/FilterChip';
import { InputAdornment } from '@mui/material';
import search from '../../../assets/images/search.svg'
import EmptyVendorListingTemplate from '../../templates/VendorManagement/EmptyVendorListing.template';
import { IQuotationFilterRequest, useInstantQuotationService } from '../../../services/useInstantQuotationService';
import { QuotationListTemplate } from '../../templates/InstantQuotation/QuotationListTemplate';
import SelectV2 from '../../atoms/SelectV2';

const useStyles = createUseStyles((theme: any) => ({
  container: {
    // borderBottom: `1px solid ${theme.palette.border.tritiaryLight}`
  },
  text: {
    color: theme.palette.textV2.primary.primary900
  },
  selectField: {
    width: "200px"
  },
}));

export interface IQuotationTemplate {
  id: number;
  leadPriority: string;
  quotationCode: string;
  status: string;
  version: number;
  quotationDate: string;
  closureDate: string;
  validityDate: string;
  billToAddressDetails: string;
}

export interface IQuotationFilter {
  searchByName: string;
  sort: any;
  status?: string | null;
}

const initialQuotationFilterValues: IQuotationFilter = {
  searchByName: "",
  sort: "createdAt,desc",
  status: null
}

interface IQuotationCounts {
  total: number,
  accepted: number,
  rejected: number,
  drafts: number,
}

const QuotationLisitingPage: React.FC = () => {

  const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValuesV2);
  const [quotation, setQuotationsTo] = useState<IQuotationTemplate[]>([]);
  const [quotationFilters, setQuotationFiltersTo] = useState<IQuotationFilter>(initialQuotationFilterValues);
  const { showSnackbar, SnackBarComponent } = useSnackbar();

  const quotationService = useInstantQuotationService();
  const navigate = useNavigate();
  const classes = useStyles();

  const [quotationCount, setQuotationCountTo] = useState<IQuotationCounts>({
    total: 0,
    accepted: 0,
    rejected: 0,
    drafts: 0,
    });

    const quotationStatus = () => {
      quotationService.getInstantQuotationStats() 
      .then((statusResponse) => {
        if (statusResponse.status===HTTP_STATUS.OK) {     
          setQuotationCountTo(statusResponse?.data?.data)
        }
        else {
          setQuotationCountTo(quotationCount);
      }
      })
      .catch((error) => {
        setQuotationCountTo(quotationCount);
        showSnackbar('error', 'Quotation stats fetch failed')
      });
    }  
    useEffect(()=>{
      quotationStatus()
    },[])
  
  const fetchProfile = () => {

    const params: IQuotationFilterRequest = {
      searchByName: quotationFilters?.searchByName.trim(),
      page: pagination.currentPage,
      size: pagination.pageSize,
      sort: quotationFilters?.sort,
      status: quotationFilters?.status,
    }
    quotationService.getAllInstantQuotation(params)
      .then((quotationResponse) => {
        if (quotationResponse?.data?.data) {          
         const { totalElements, totalPages, content } = quotationResponse?.data?.data;
          setPaginationTo({
            ...pagination,
            totalPages: totalPages,
            totalRecords: totalElements
          });
          setQuotationsTo(content)
        }
        else {
          setQuotationsTo([]);
        }
      })
      .catch((error) => {
        setQuotationsTo([]);
        showSnackbar('error', 'Users fetch failed')
      });
  }

  useEffect(() => {
    fetchProfile()
  }, [pagination.currentPage, pagination.pageSize, quotationFilters])

  const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPaginationTo((prevPagination) => ({
      ...prevPagination,
      currentPage: newPage
    }));
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setPaginationTo((prevPagination) => ({
      ...prevPagination,
      pageSize: newRowsPerPage
    }));
  };

  const handleFiltersChange = (event: any) => {
    const { name, value } = event.target;
    setPaginationTo({ ...pagination, currentPage: 0 })
    setQuotationFiltersTo({ ...quotationFilters, [name]: value ?? "" });
  }

  const handleAddNewQuotation = () => {
    navigate(ADMIN_ROUTES.INSTANT_QUOTATION_CREATE)
  }

  const quotationStats = [
    {
      icon: totalCustomers,
      title: "Total Quotation",
      value: quotationCount.total ? quotationCount?.total : 0,
    },
    {
      icon: activeCustomers,
      title: "Accepted Leads",
      value: quotationCount.accepted ? quotationCount?.accepted : 0,
    },
    {
      icon: inactiveCustomers,
      title: "Rejected Leads",
      value: quotationCount.rejected ? quotationCount?.rejected : 0,
    },
    {
      icon: newCustomers,
      title: "Draft Leads",
      value: quotationCount.drafts ? quotationCount?.drafts : 0,
    },
  ];

  return (
    <>
      <div className={`flex justify-between py-8 items-center ${classes.container}`}>
        <span className={`font-medium text-2xl ${classes.text}`}>Instant Quotation Dashboard</span>
        <ButtonV2
          label="+ Add New Quotation"
          variant='primaryContained'
          onClick={handleAddNewQuotation}
        />
      </div>
      <div className='grid'>
        <StatusCard cards={quotationStats} />
      </div>
      <div className={`flex justify-between py-8`}>
        <div className={`${classes.selectField}`}>
          <SelectV2
            variant="outlined"
            label="Status"
            placeholder="status"
            name="status"
            value={quotationFilters.status}
            onChange={handleFiltersChange}
            options={QUOTATION_STATUS}
          />
        </div>
        <div>
          <TextFieldV2
            label='Search'
            variant="outlined"
            placeholder="Search.."
            name='searchByName'
            value={quotationFilters.searchByName}
            onChange={handleFiltersChange}
            InputProps={{
              startAdornment: <InputAdornment position="start" > <img src={search} alt="icon" /></InputAdornment>,
            }}
          />
        </div>
      </div>
      <QuotationListTemplate quotation={quotation} pagination={pagination} handleFiltersChange={handleFiltersChange} handleRowsPerPageChange={handleRowsPerPageChange} handlePaginationChange={handlePaginationChange} customerFilter={quotationFilters} />
    </>
  )
}
export default QuotationLisitingPage;