import { request } from "."
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";

interface AdditionalAddress {
    street: string;
    locality: string;
    state: string;
    city: string;
    pincode: string;
    buildingName: string;
    buildingNo: string;
}

export interface GstinResponseDTO {
    gstin: string;
    legalNameOfBusiness: string;
    tradeNameOfBusiness: string;
    registrationDate: string;
    constitutionOfBusiness: string;
    gstStatus: string;
    city: string[];
    state: string[][];
    country: string[];
    addressLine: string;
    pincode: string;
    additionalAddress: AdditionalAddress[];
    valid: boolean;
}

export const GST_URL = {
    GST_VERIFY: "/gstin",
    GET_GST: "/gstin/details",
    GET_VENDOR_GSTIN: "/vendor/gstin"
}
export const useGstinService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();

    const searchGstin = (data: { gstin?: string, userId?: string }) => {
        return request(API_METHOD.POST, GST_URL.GST_VERIFY, authenticatedUser, data);
    }
    const getGstin = (data: { gstin?: string, userId?: string }) => {
        return request(API_METHOD.POST, GST_URL.GET_GST, authenticatedUser, data);
    }

    const getVendorGstin = (data: { gstin?: string, userId?: string }) => {
        return request(API_METHOD.POST, GST_URL.GET_VENDOR_GSTIN, authenticatedUser, data);
    }

    return {
        searchGstin,
        getGstin,
        getVendorGstin
    }
};