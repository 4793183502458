import { useAuthenticatedUser } from '../hooks/useAuthenticatedUser';
import { API_METHOD } from '../utils/constant';
import { request } from './index';

const INDENT_URLs = {
  FETCH_AVIGHNA_WAREHOUSE: 'business/85',
  FETCH_WAREHOUSE: 'warehouse',
};

export const useWarehouseService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const getBillAddress = () => {
    return request(
      API_METHOD.GET,
      INDENT_URLs.FETCH_AVIGHNA_WAREHOUSE,
      authenticatedUser,
      null
    );
  };

  const getWarehouse = () => {
    return request(
      API_METHOD.GET,
      INDENT_URLs.FETCH_WAREHOUSE,
      authenticatedUser,
      null
    );
  };

  return {
    getBillAddress,
    getWarehouse,
  };
};
