import './indent.css';
import moment from 'moment';
import { IndentTableData } from '../../../Constants/Procurement/Indent/types';
import { createUseStyles } from 'react-jss';
interface Props {
  indentList: IndentTableData[];
  showIndentHandler: (id: string) => void;
  currentPage: number;
}

const useStyles = createUseStyles({
  tableBody: {
    '& tr:nth-child(even)': {
      background: '#ededed',
    },
    '& tr:nth-child(odd)': {
      background: '#f4f5fa',
    },
  },
});

const IndentListTable: React.FC<Props> = ({
  indentList,
  showIndentHandler,
  currentPage,
}) => {
  const classes = useStyles();

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
        <div className="py-2 inline-block min-w-full">
          <div className="overflow-hidden">
            <table className="min-w-full">
              <thead className="bg-blue">
                <tr>
                  <th
                    scope="col"
                    className="text-sm font-semibold text-left  text-white px-6 py-4 "
                  >
                    Sr No.
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-semibold text-left  text-white px-6 py-4 "
                  >
                    Indent IDs
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-semibold text-center  text-white px-6 py-4 "
                  >
                    No of PRIDs
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-semibold text-center  text-white px-6 py-4 "
                  >
                    Created Date
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-semibold text-center  text-white px-6 py-4 "
                  >
                    Created By
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-semibold text-center  text-white px-6 py-4 "
                  >
                    Indent Purchase Type
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-semibold text-center  text-white px-6 py-4 "
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className={`${classes.tableBody} capitalize`}>
                {indentList.map((indent, i) => {
                  const statusClass = `${
                    {
                      created: 'warning-tab',
                      approved: 'success-tab',
                      draft: 'warning-tab',
                      remarks_incorporated: 'info-tab',
                      approval_required: 'info-tab',
                      rejected: 'danger-tab',
                    }[indent?.status?.toLowerCase()] || 'info-tab'
                  }`;
                  return (
                    <tr className="text-text-color font-normal" key={i}>
                      <td className="px-6 py-4 whitespace-nowrap text-left text-sm">
                        {currentPage ? currentPage * 10 + (i + 1) : i + 1}
                      </td>
                      <td className="text-sm text-gray-900  px-6 py-4 whitespace-nowrap text-left">
                        <span
                          className="font-semibold  text-blue  underline cursor-pointer"
                          onClick={() => showIndentHandler(indent.id)}
                        >
                          {indent?.id}
                        </span>

                        {indent.isNew ? (
                          <span className="new-tag">New</span>
                        ) : (
                          ''
                        )}
                      </td>

                      <td className="text-sm  px-6 py-4 whitespace-nowrap text-center">
                        {indent?.items || 0}
                      </td>
                      <td className="text-sm  px-6 py-4 whitespace-nowrap text-center ">
                        {indent.createdAt
                          ? moment
                              .unix(indent.createdAt)
                              .format('DD/MM/YYYY (hh:mm A)')
                          : ''}
                      </td>
                      <td className="text-sm  px-6 py-4 whitespace-nowrap text-center ">
                        {indent?.createdBy || ''}
                      </td>
                      <td className="text-sm  px-6 py-4 whitespace-nowrap text-center ">
                        {indent?.purchaseType}
                      </td>
                      <td className="text-sm  px-6 py-4 whitespace-nowrap text-center ">
                        <span className={statusClass}>
                          {indent?.status?.toLowerCase()}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndentListTable;
