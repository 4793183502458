import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react'
import { ICustomerMappingForm } from '../../pages/CustomerManagement/CustomerMapping.page';
import ButtonV2 from '../../atoms/ButtonV2';
import { createUseStyles } from 'react-jss';
import { useUserService } from '../../../services/useUserService';
import { useNavigate } from 'react-router-dom';
import { ADMIN_ROUTES } from '../../../utils/constant';
import { useCustomerGroupMasterService } from '../../../services/useCustomerGroupMasterService';
import FilterChip, { IMultiSelectOption } from '../../molecules/FilterChip/FilterChip';

export interface ICustomerMappingProps {
    formik: FormikProps<ICustomerMappingForm>;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.secondaryLightAccent}`
    },
    heading: {
        color: theme.palette.textV2.secondary.secondary800,
    },
    content: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    headingtext: {
        color: theme.palette.textV2.tertiary.tertiary700
    },
    labelcolor: {
        color: theme.palette.textV2.primary.primary850
    },
    textCustomer: {
        color: theme.palette.textV2.primary.primary500
    },
    seprator: {
        color: theme.palette.textV2.primary.primary1000
    }

}));

const CustomerMappingTemplate: React.FC<ICustomerMappingProps> = ({ formik }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [searchGroupName, setSearchGroupName] = useState<string>('');
    const userService = useUserService();
    const customerGroupMasterService = useCustomerGroupMasterService();
    const [relationManagerOptions, setRelationManagerOptions] = useState<IMultiSelectOption[]>([]);
    const [customerTypeOptions, setCustomerTypeOptions] = useState<IMultiSelectOption[]>([]);
    const [selectedValues, setSelectedValues] = useState<IMultiSelectOption[]>([]);
    const [selectedRelationManagerValues, setSelectedRelationManagerValues] = useState<IMultiSelectOption[]>([]);
    const [isCustomerTypeEditing, setIsCustomerTypeEditing] = useState<boolean>(false);
    const [isRelatationEditing, setIsRelationEditing] = useState<boolean>(false);

    useEffect(() => {
            setSelectedValues(
                formik.values.customerType.map((user: { groupName: string; id: number }) => ({
                    label: user.groupName,
                    value: user.id,
                }))
            );
    }, [formik.values.customerType]);

    useEffect(() => {
        setSelectedRelationManagerValues(
            formik.values.relationshipManager.map((user: { fullName: string; id: number }) => ({
                label: user.fullName,
                value: user.id,
            }))
        );
    }, [formik.values.relationshipManager]);

    const handleBackToDashboard = () => {
        navigate(ADMIN_ROUTES.CUSTOMER_MANAGEMENT_LIST);
    };

    const handleSave = () => {
        isCustomerTypeEditing && formik.handleSubmit();
        setIsCustomerTypeEditing(!isCustomerTypeEditing)
    };

    const handleRelationManagerSave = () => {
        isRelatationEditing && formik.handleSubmit();
        setIsRelationEditing(!isRelatationEditing);
    };

    const handleSelectionChange = (selected: IMultiSelectOption[]) => {
        setSelectedValues(selected);
        formik.setFieldValue('newCustomerType', selected);
    };


    const handleRelationManagerSelectionChange = (selected: IMultiSelectOption[]) => {
        let filteredSelected = selected.filter(value => 
            value.label !== (formik.values.newRelationshipManager.length >0 ? formik.values.newRelationshipManager[0]?.label : formik.values.relationshipManager[0]?.fullName)
        );
        setSelectedRelationManagerValues(filteredSelected);
        formik.setFieldValue('newRelationshipManager', filteredSelected)
    };
    

    const fetchAllUser = async () => {
        const params = {
            search: searchTerm,
            userType: 'ADMIN',
        };
        const users = await userService.getAllUser(params);
        let options = users.data.data.content.map((user: { name: string; userId: number }) => ({
            label: user.name,
            value: user.userId,
        }));
        selectedRelationManagerValues.forEach(selectedValue => {
            if (!options.some((option: any) => option.value === selectedValue.value)) {
                options.push(selectedValue);
            }
        });

        setRelationManagerOptions(options);
    };
    const fetchAllGroupType = async () => {

        const groups = await customerGroupMasterService.getAllCustomerGroup(
            {
                page: 0,
                size: 10,
                sort: "createdAt,desc",
                groupName: searchGroupName,
            },
            {}
        );
        let options = groups.data.data.content.map((user: { groupName: string, groupId: number }) => ({
            label: user.groupName,
            value: user.groupId,
            
        }));
        selectedValues.forEach(selectedValue => {
            if (!options.some((option: any) => option.value === selectedValue.value)) {
                options.push(selectedValue);
            }
        });
        setCustomerTypeOptions(options);
    }

    useEffect(() => {
        fetchAllUser();
    }, [searchTerm])

    useEffect(() => {
        fetchAllGroupType();
    }, [searchGroupName])

    const groupNames = formik?.values?.customerType?.map((customer, index) => (
        <span key={index}>
            {customer?.groupName}
            {index < formik?.values?.customerType?.length - 1 && <span className={`${classes.seprator} px-2`}> | </span>}
        </span>
    ));
    return (
        <div>
            <div className={` rounded-2xl flex justify-between items-center gap-x-7 p-6 mt-6 ${classes.container}`}>
                <div className='w-1/2'>
                    {isCustomerTypeEditing ?
                            <FilterChip
                                options={ customerTypeOptions}
                                label="Customer Group"
                                value={selectedValues}
                                onchange={handleSelectionChange}
                                placeholder="Search Customer Group"
                                ButtonComponent={null}
                                textFieldPlaceholder='Enter Name'
                                textFieldLabel="Enter Group Name"
                                searchTerm={searchGroupName}
                                setSearchTerm={setSearchGroupName}
                            /> :
                        <div className='grid gap-4'>
                            <div className={`text-base font-medium ${classes.labelcolor}`}>Selected Customer Group: </div>
                            <div className={`text-base ${classes.textCustomer}`}>  {groupNames}</div>
                        </div>
                    }

                </div>
                <div className='mt-8'>
                    <ButtonV2
                        variant={isCustomerTypeEditing ? 'contained' : 'tertiaryContained'}
                        label={isCustomerTypeEditing ? 'Save' : 'Edit'}
                        onClick={handleSave}
                        disabled={formik.values.newCustomerType.length > 0 ? false : true && isCustomerTypeEditing}
                    />
                </div>
            </div>
            <div className={` rounded-2xl flex justify-between items-center gap-x-7 p-6 mt-6 ${classes.container}`}>
                <div className='w-1/2'>

                    {isRelatationEditing ?
                            <FilterChip
                                options={relationManagerOptions?.length > 0 ? relationManagerOptions : []}
                                label="Relationship Manager Mapping"
                                value={selectedRelationManagerValues}
                                onchange={handleRelationManagerSelectionChange}
                                placeholder="Search Customer Group"
                                ButtonComponent={null}
                                textFieldPlaceholder='Enter Name'
                                textFieldLabel="Enter RelationManager Name"
                                searchTerm={searchTerm}
                                setSearchTerm={setSearchTerm}
                            /> :
                        formik?.values?.relationshipManager?.map((manager: { fullName: string; department: string; designation: string }, index: number) => (
                            <div key={index} className="flex justify-between ">
                                <div className={`${classes.headingtext}  text-sm grid gap-2`}>
                                    <div className="text-sm">Full Name</div>
                                    <div className={`text-base ${classes.headingtext}`}>{manager.fullName}</div>
                                </div>
                                <div className={`${classes.headingtext}  text-sm grid gap-2`}>
                                    <div className="text-sm">Department</div>
                                    <div className={`text-base justify-self-center ${classes.headingtext}`}>{manager.department || 'No Data'}</div>
                                </div>
                                <div className={`${classes.headingtext}  text-sm grid gap-2`}>
                                    <div className="text-sm">Designation</div>
                                    <div className={`text-base ${classes.headingtext}`}>{manager.designation || 'No Data'}</div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className='mt-8'>
                    <ButtonV2
                        variant={isRelatationEditing ? 'contained' : 'tertiaryContained'}
                        label={isRelatationEditing ? 'Save' : 'Edit'}
                        onClick={handleRelationManagerSave}
                        disabled={formik.values.newRelationshipManager.length > 0 ? false : true && isRelatationEditing}
                    />
                </div>
            </div>

            <div className=' grid justify-items-stretch pt-6'>
                <ButtonV2
                    className={` justify-self-end`}
                    variant="secondaryContained"
                    label="Back to Dashboard"
                    onClick={handleBackToDashboard}
                />
            </div>
        </div>

    )
}

export default CustomerMappingTemplate