import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "../../../hooks/useSnackBar";
import { useFinishedProductService } from "../../../services/useFinishedProductService";
import AddFinishedProductTemplate from "../../templates/Customer-Management/FinishedProducts/AddFinishedProduct.template";
import { DOCUMENT_RESOURCE_TYPE, FINISHED_PRODUCTS, GENERIC_EXCEPTION_CODE } from "../../../utils/constant";
import { createUseStyles } from "react-jss";
import { useFormik } from "formik";
import * as yup from "yup";
import { HTTP_STATUS, MODE, MY_MATERIAL_STATE } from "../../../utils/types";
import { useEffect, useState } from "react";
import { useFileService } from "../../../services/useFileService";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { IMultiSelectOption } from "../../atoms/MultiSelectInput2";
import { IMY_MATERIAL_STATE, IOTHER_PROPS } from "./MyMaterial.page";
import { useBusinessProfileService } from "../../../services/useBusinessProfileService";

export interface FinishedProductsFormik {
    productName: string,
    applicationType: IMultiSelectOption[],
    productDescription: string,
    images: (UploadedImageData | File)[],
    imageListToDelete?: number[]
}

export interface UploadedImageData {
    id?: number | null;
    location: string;
    path: string;
    resourceId?: number | null;
    resourceType: string;
    mimeType: string;
    createdAt: string;
}

const useStyles = createUseStyles((theme: any) => ({
    section: {
        color: theme.palette.textV2.secondary.secondary800,
    }
}));

const initialValues = {
    productName: "",
    applicationType: [],
    productDescription: "",
    images: [],
    imageListToDelete: []
}

const validationSchema = yup.object().shape({
    productName: yup.string().required("Product Name is required"),
    applicationType: yup.array()
        .of(yup.object())
        .min(1, 'You must select at least one application type')
        .required('You must select at least one application type'),
    productDescription: yup.string().required("Product Description is required"),
})

interface FinishedProductCreationPageProps{
    updateMaterialStateTo: (state: MY_MATERIAL_STATE, props?: IOTHER_PROPS) => void,
    myMaterialState: IMY_MATERIAL_STATE
    mode: MODE
}

const FinishedProductCreationPage: React.FC<FinishedProductCreationPageProps> = ({mode, updateMaterialStateTo, myMaterialState}) => {
    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const finishedProductService = useFinishedProductService();
    const fileService = useFileService();
    const params = useParams();
    const businessId = Number(params.id);
    const bussinessProfileService = useBusinessProfileService();
    const [businessProfile, setBusinessProfileTo] = useState<{ userId: number } | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getBusinessProfile = async () => {
        try {
            const businessProfileResponse = await bussinessProfileService.getBusinessProfile(businessId);
            if (businessProfileResponse.status === HTTP_STATUS.OK) {
                const profileData = businessProfileResponse?.data?.data;
                setBusinessProfileTo(profileData);
            }
        } catch (error) {
            showSnackbar('error', 'Business Profile fetch failed');
        }
    };
  
    const handleOnBack = () => {
        updateMaterialStateTo(MY_MATERIAL_STATE.MY_MATERIAL_VIEW)
    }

    const uploadImage = async (id: number) => {
        try {
            const result = [];
            const fileToUpload = formik.values.images.filter(image => image instanceof File);
            for (let image of fileToUpload) {
                if (image instanceof File) {
                    const presignedUrlResponse = await fileService.generatePresignedUrl(image.name, DOCUMENT_RESOURCE_TYPE.BUSINESS_PROFILE_FINISHED_PRODUCT(((businessProfile?.userId as number).toString())), id);
                    if (presignedUrlResponse && presignedUrlResponse.data && presignedUrlResponse.data.data && presignedUrlResponse.data.data.presignedUrl) {
                        const presignedUrl = presignedUrlResponse.data.data.presignedUrl;
                        const imageUploadResponse = await fileService.uploadImageUsingPreSignedUrls(image, presignedUrl);
                        if (imageUploadResponse.status === HTTP_STATUS.OK) {
                            let res = await fileService.saveFile({
                                resourceType: 'BUSINESS_PROFILE_FINISHED_PRODUCT',
                                resourceId: id,
                                path: `business_profile/${businessProfile?.userId as number}/finished_product/${id}/${image.name.split(" ").join("_")}`,
                                mimeType: image.type
                            })
                            if (res?.status === HTTP_STATUS.OK) {
                                result.push(true)
                            }
                            else {
                                result.push(false)
                                showSnackbar("error", `Failed to upload image: ${image.name}`)
                            }
                        } else {
                            result.push(false)
                            showSnackbar("error", `Failed to upload image: ${image.name}`)
                        }
                    } else {
                        result.push(false)
                        showSnackbar("error", 'Failed to generate presigned URL')
                    }
                } else {
                    result.push(false)
                    showSnackbar("success", "Finished product created successfully");
                }
            }
            if (!result.includes(false)) {
                setIsLoading(false);
                showSnackbar("success", "Finished Product uploaded successfully");
                handleOnBack();
            } else {
                showSnackbar("error", 'File to upload Images:')
            }
        } catch (error) {
            setIsLoading(false);
            showSnackbar("error", 'File upload failed:')
        }
    }
    const formik = useFormik<FinishedProductsFormik>({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            if (values.images.length === 0) {
                showSnackbar('error', 'At least one image should be selected');
                return;
            }
            setIsLoading(true);
            const payload = {
                name: values.productName,
                applicationType: values.applicationType.map(option => option.value as string),
                productDescription: values.productDescription,
                imageListToDelete: (formik.values.imageListToDelete as number[])?.join(",")
            }
            try {
                if (mode === MODE.EDIT) {
                    let res = await finishedProductService.updateFinishedProductItem(payload, Number(myMaterialState.other?.id));
                    if (res?.status === HTTP_STATUS.OK) {
                        await uploadImage(res.data.data.id)
                    } else if(res?.data.exceptionCode === GENERIC_EXCEPTION_CODE.DUPLICATE_ENTRY){
                        setIsLoading(false);
                        showSnackbar("error", "Duplicate entry not allowed");
                    }
                    else{
                        setIsLoading(false);
                        showSnackbar("error", "Finished Product not updated");
                    }
                }
                else {
                    let res = await finishedProductService.createFinishedProduct(payload, businessProfile?.userId as number);
                    if (res?.status === HTTP_STATUS.OK) {
                        uploadImage(res.data.data.id)
                    } else if(res?.data.exceptionCode === GENERIC_EXCEPTION_CODE.DUPLICATE_ENTRY){
                        setIsLoading(false);
                        showSnackbar("error", "Duplicate entry not allowed");
                    }
                    else{
                        setIsLoading(false);
                        showSnackbar("error", "Finished Product not created");
                    }

                }
            } catch (error) {
                showSnackbar("error", "Finished Product failed");
            }
        }
    })

    const loadFinishedProductById = async () => {
        try {
            let res = await finishedProductService.getFinishedProductId(myMaterialState.other?.id as number);
            if (res?.status === HTTP_STATUS.OK) {
                formik.setValues({
                    applicationType: res.data.data.applicationType.map((option: any) => ({ label: option, value: option })),
                    productName: res.data.data.name,
                    productDescription: res?.data?.data?.productDescription,
                    images: res?.data?.data?.images ?? [],
                    imageListToDelete: []
                })
            }
        }
        catch (error) {
            showSnackbar("error", "Error Fetching Data");
        }
    }

    useEffect(() => {
        if (myMaterialState.other?.id) {
            loadFinishedProductById();
        }
    }, [myMaterialState.other?.id])

    useEffect(() => {
        getBusinessProfile();
    }, [])

    return (
        <div className="grid gap-y-6">
            {SnackBarComponent}
            <div className={`${classes.section} text-lg font-semibold`}>{FINISHED_PRODUCTS.MY_MATERIALS}</div>
            <AddFinishedProductTemplate handleOnBack={handleOnBack} formik={formik} setSelectedFile={() => { }} cardId={Number(myMaterialState.other?.id)} isLoading={isLoading}/>
            
        </div>
    )
}

export default FinishedProductCreationPage;