export const AUCTION = {
  ScheduleAuction: "Schedule Auction",
  LiveAuction: "Live Auction",
  AuctionName: "Auction Name",
  Quantity: "Quantity (MT)",
  StartDate: "Start Date",
  EndDate: "End Date",
  StartTime: "Start Time",
  EndTime: "End Time",
  PriceDetails: "Price Details (Per MT)",
  AuctionId: "Auction Id:",
  IndentId: "Indent Id:",
  BidIncrement: "Bid Increment",
  StartPrice: "Start Price",
  ReservePrice: "Reserve Price",
  TargetPrice: "Target Price",
  BidDetails: "Bid details",
  PaginationText: "Auction",
  CancelAuction: "Cancel Auction",
  RescheduleAuction: "Re-Schedule Auction",
  ProductDetails: "Product Details",
  TotalQty: "Total Qty:",
  CloseAuction: "Close Auction",
  AwardToLowest: "Award to Lowest",
  DownloadReport: "Download Auction Report",
  Restart: "Restart",
  PriceAchieved: "Target Price Achieved",
  PriceNotAchieved: "Target Price Not Achieved",
  ReserveAchieved: "Reserve Price Achieved",
  Pause: "Pause",
  Resume: "Resume",
  Award: "Award price",
  CurrentBidPrice: "Current Bid Price :",
  ExtendTime: "Extend Time",
  RestartAuction: "Restart Auction",
  ReScheduleAuction: "Reschedule Auction",
  ReScheduleAuctionStart: "Reschedule Auction(Enter Start Date)",
  ReScheduleAuctionEnd: "Reschedule Auction(Enter End Date)",
  EnterStartDate: "Enter Start Date",
  EnterEndDate: "Enter End Date",
  CloseAuctionMessage: "Do you really want to Close the auction?",
  Yes: "Yes",
  No: "No",
  Hurray: "Hurrayy!",
  SuccessMessage: "You’ve successfully offered an award to Satyam Tiwari.",
  CompanyName: "Company name",
  Next: "Next",
  Done: "Done",
  Back: "Back",
  AddStartTime: "Add Start Time ( Hrs : min )",
  AddEndTime: "Add End Time ( Hrs : min )",
  AddTime: "Add Time ( Hrs : min )",
  TotalTime: "Total Time :",
  RemainingTime: "Remaining Time :",
};
