import { createUseStyles } from "react-jss";
import ButtonV2 from "../../atoms/ButtonV2";
import { getLastThreeFiscalYears } from "../../../utils/helper";

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.primarylightAccent}`,
    },
    title: {
        color: theme.palette.text.primaryLight,
    },
    detailsContainer: {
        "& span": {
            color: theme.palette.text.primaryLight,
        },
        "& p": {
            color: theme.palette.text.primaryLight,
        }
    }
}));

interface ITurnOverViewTemplateProps {
    setIsEditing: (value: boolean) => void;
    turnOverMap: { [key: string]: number };
}

const TurnOverViewTemplate: React.FC<ITurnOverViewTemplateProps> = ({ setIsEditing, turnOverMap }) => {
    const classes = useStyles();

    const lastThreeFiscalYears = getLastThreeFiscalYears();

    return (
        <div className={`${classes.container} p-6 rounded-lg w-full`}>
            <div className='flex justify-between items-center pb-6 border-b border-grey-500'>
                <h2 className={`${classes.title} text-2xl font-medium`}>Turn over Details</h2>
                <ButtonV2 variant={'tertiaryContained'} label={'Edit'} onClick={() => setIsEditing(true)} />
            </div>
            <div className='grid grid-cols-3 gap-x-9 pt-6'>
                {lastThreeFiscalYears.map((year, index) => (
                    <div key={index} className={`${classes.detailsContainer} flex flex-col`}>
                        <span>FY {year}</span>
                        <p className='font-medium'>₹ {turnOverMap[year] ? `${(turnOverMap[year]).toLocaleString()}` : ''}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default TurnOverViewTemplate;
