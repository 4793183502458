import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "../../../../hooks/useSnackBar";
import { IAddress, IManufacturerRequest, IPointOfContact, useManufacturerService } from "../../../../services/useManufacturerService";
import { HTTP_STATUS } from "../../../../utils/types";
import { ADMIN_ROUTES, MODE, RESOURCE_TYPE } from "../../../../utils/constant";
import { useEffect, useState } from "react";
import ManufacturerFormTemplate from "../../../templates/AdminMaster/Manufacturer/ManufacturerForm.template";
import { ImageData } from "../../../organisms/ImageUploader";
import { useFileService } from "../../../../services/useFileService";


export interface IManufacturer {
    id: number;
    name: string;
    shortName: string;
    code: string;
    logoPath: number;
    status: string;
    manufacturerType: string;
    createdBy: number;
    updatedBy: number;
    registeredAddressId: number;
    manufacturingLocationId: number;
    registeredAddress: IAddress;
    manufacturingLocationAddress: IAddress[];
    createdAt: string;
    updatedAt: string;
    poc: IPointOfContact[];
    categories: number[];
}

const ManufacturerUpdatePage: React.FC = () => {

    const navigate = useNavigate();

    const params = useParams();
    const manufacturerId = Number(params.id);
    const fileService = useFileService();
    const [image, setImagesTo] = useState<(ImageData | File)[]>([]);

    const [manufacturerDetails, setManufacturerDetailsTo] = useState<IManufacturer | null>(null);
    const [productCategories, setProductCategroiesTo] = useState<number[]>([]);

    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const manufacturerService = useManufacturerService();
    const [imageIdsToDelete, setImageIdsToDelete] = useState<number[]>([]);


    const updateManufacturerById = async (manufacturerRequestBody: IManufacturerRequest) => {
        if (manufacturerId) {
            try {
                const manufacturerResponse = await manufacturerService.updateManufacturer(manufacturerId, manufacturerRequestBody);
                if (manufacturerResponse.status === HTTP_STATUS.OK) {
                    const resourceId : number  = manufacturerResponse.data.data.id;
                    await fileService.uploadImage(image,resourceId,RESOURCE_TYPE.MANUFACTURER);
                    navigate(ADMIN_ROUTES.MANUFACTURER_LIST);
                }
                else if(manufacturerResponse.status === HTTP_STATUS.BAD_REQUEST){
                    showSnackbar('error', manufacturerResponse.data.statusMessage) 
                }
            } catch (error) {
                showSnackbar('error', 'Manufacturer updated failed')
            }
        }
    }

    const fetchManufactureById = async () => {
        if (manufacturerId) {
            try {
                const manufactureResponse = await manufacturerService.getManufacturerById(manufacturerId);
                if(manufactureResponse.status === HTTP_STATUS.OK) {
                    setManufacturerDetailsTo(manufactureResponse?.data?.data);
                    setProductCategroiesTo(manufactureResponse?.data?.data?.categories) ;
                }
                const fileResponse = await fileService.getFilesById(manufacturerId, RESOURCE_TYPE.MANUFACTURER);
                (fileResponse.status === HTTP_STATUS.OK) && setImagesTo(fileResponse?.data?.data);
            } catch (error) {
                showSnackbar("error", 'Standard fetch failed');
                setManufacturerDetailsTo(null);
                setProductCategroiesTo([])
                setImagesTo([]);
            }
        }
    }

    useEffect(() => {
        fetchManufactureById();
    }, [manufacturerId]);

    return (
        <div>
            { SnackBarComponent }
            <ManufacturerFormTemplate manufacturer={ manufacturerDetails } categoryIds={ productCategories } onManufacturerSubmit={ updateManufacturerById } mode={ MODE.UPDATE } image={image} setImagesTo={setImagesTo} imageIdsToDelete = {imageIdsToDelete} setImageIdsToDelete = {setImageIdsToDelete}  />
        </div>
    );
}

export default ManufacturerUpdatePage;
