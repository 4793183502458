import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import ApartmentIcon from "@mui/icons-material/Apartment";
import gstSvg from "../../../assets/icons/gst.svg";
import { useLeadService } from "../../../services/useLeadService";
import Button from "../../atoms/Button";
import TitleHeading from "../../atoms/CustomHeading/TitleHeading";
import CustomerDetailTemplate from "../../templates/Lead/CustomerDetail.template";
import ViewUpcModalTemplate from "../../templates/Lead/ViewUpcModal.template";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Table, { ColumnType } from "../../organisms/Table";
import { createUseStyles } from "react-jss";
import { ADMIN_ROUTES } from "../../../utils/constant";
import ViewSecondaryUpcModalTemplate from "../../templates/Lead/ViewSecondaryUpcModal.template";
import { CLASS_TYPE } from "../../../utils/types";

interface ILead {
	id: number;
	upcId: number;
	upcCode: string;
	secondarySkuId: number;
	skuCode: string;
	quantity: number;
	createdAt: string;
	updatedAt: string;
	catalogueName: string;
}

export interface IDetail {
	customerName: string;
	companyName: string;
	email: string;
	gstNumber: string;
	userId: number;
	userMobileNumber: string;
	catalogueType: string;
}

export const initialDetails: IDetail = {
	customerName: "",
	companyName: "",
	email: "",
	gstNumber: "",
	userId: 0,
	userMobileNumber: "",
	catalogueType: ""
};

const useStyles = createUseStyles((theme: any) => ({
	iconContainer: {
		color: theme.palette.icon.primaryLight,
	},
}));

const LeadView: React.FC = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const params = useParams();
	const leadService = useLeadService();
	const [lead, setLeadTo] = useState<ILead[]>([]);
	const [detail, setDetailTo] = useState<IDetail>(initialDetails);

	const loadLead = async () => {
		try {
			const leadResponse = await leadService.getLeadById(Number(params.id));
			if (leadResponse.data?.data) {
				setLeadTo(leadResponse.data.data.leadUpcResponseDTO);
				setDetailTo(leadResponse.data.data);
			} else {
				throw new Error("Lead fetch failed");
			}
		} catch (error) {
			setLeadTo([]);
			setDetailTo(initialDetails);
		}
	};

	useEffect(() => {
		loadLead();
	}, [params.id]);

	const handleViewQuotation = () => {
		navigate(`${ ADMIN_ROUTES.LEAD_VIEW }/${ Number(params.id) }${ ADMIN_ROUTES.LEAD_QUOTATION }`);
	};

	const detailItems = [
		{ icon: <PersonIcon />, value: detail?.customerName },
		{ icon: <EmailIcon />, value: detail?.email },
		{ icon: <ApartmentIcon />, value: detail?.companyName },
		{ icon: <PhoneIcon />, value: detail?.userMobileNumber },
		{ icon: <img className="w-4" src={ gstSvg } alt="gstIcon" />, value: detail?.gstNumber },
	];

	const schema = {
		id: "1",
		pagination: {
			total: 0,
			currentPage: 0,
			isVisible: false,
			limit: 0,
		},
		columns: [
			{ label: "Sr No.", key: "id", type: "number" as ColumnType, props: { className: "" } },
			{ label: "Product Code", key: "upcCode", type: "string" as ColumnType, props: { className: "" } },
			{ label: "Product Name", key: "catalogueName", type: "string" as ColumnType, props: { className: "" } },
			{ label: "Product Quantity", key: "quantity", type: "number" as ColumnType, props: { className: "" } },
			{ label: "Action", key: "action", type: "custom" as ColumnType, props: { className: "" } },
		],
	};

	const Action = (id: number) => {
		return (
			<div className={`${classes.iconContainer} flex gap-x-2 justify-end`}>
				<RemoveRedEyeIcon className="text-secondary cursor-pointer" onClick={(e) => handleViewUpcDetail(id)} />
			</div>
		);
	};
	const records = lead.map((lead: ILead, index: number) => [
		index + 1,
		lead.upcCode || lead.skuCode,
		lead.catalogueName,
		lead.quantity,
		Action(lead.upcId || lead.secondarySkuId),
	]);

	const [isViewUpcModalOpen, setIsViewUpcModalOpen] = useState(false);
	const [selectedUpcId, setSelectedUpcId] = useState<any>({
		id: null,
		type: ""
	});

	const handleViewUpcDetail = (id: number) => {
		const selectedItem = lead.find((item) => item.upcId === id || item.secondarySkuId === id);
		if (selectedItem) {
			if (selectedItem.upcId !== null) {
				setSelectedUpcId({ id: selectedItem.upcId, type: CLASS_TYPE.STANDARD });
				setIsViewUpcModalOpen(true);
			} else if (selectedItem.secondarySkuId !== null) {
				setSelectedUpcId({ id: selectedItem.secondarySkuId, type: CLASS_TYPE.SECONDARY });
				setIsViewUpcModalOpen(true);
			}
		}
	};

	const handleCloseViewUpcModal = () => {
		setSelectedUpcId({ id: null, type: "" });
		setIsViewUpcModalOpen(false);
	};

	return (
		<div className="grid gap-y-3">
			<TitleHeading text={ `Lead Id - ${ Number(params.id) }` } />
			<div className="grid gap-y-3">
				<div className="flex justify-between py-3 ">
					<div className="grid grid-cols-2 gap-y-1 w-3/4 ">
						{detailItems.map((item: any) => (
							<CustomerDetailTemplate 
								key = { item.key } 
								icon = { item.icon } 
								value = { item.value } 
							/>
						))}
					</div>
					<div className="w-1/4 my-auto flex justify-end">
						<Button variant="contained" onClick={ handleViewQuotation } label="Quotation" />
					</div>
				</div>

				<Table schema={schema} records={records} />
				{isViewUpcModalOpen && selectedUpcId !== null && (
    				selectedUpcId.type === CLASS_TYPE.STANDARD ? (
        				<ViewUpcModalTemplate 
            				item={selectedUpcId}
            				onUpcClose={handleCloseViewUpcModal}
        				/>
    				) : (
        				<ViewSecondaryUpcModalTemplate 
            				item={selectedUpcId}
							classType={detail.catalogueType}
            				onUpcClose={handleCloseViewUpcModal}
        				/>
    				)
				)}
			</div>
		</div>
	);
};

export default LeadView;
