import React, { useEffect, useState } from 'react'
import { FormikProps } from 'formik';
import { createUseStyles } from 'react-jss';
import { HTTP_STATUS, PRODUCT_SERVICE_FORM_STATE } from '../../../../utils/types';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { ServicePayload, SubAttributes } from '../../../../services/useVASMasterCategoryService';
import ButtonV2 from '../../../atoms/ButtonV2';
import { Attribute, SubAttribute, useAttributeServiceV2 } from '../../../../services/useAttributeServiceV2';
import { Checkbox } from '@mui/material';
import StatusV2 from '../../../atoms/Status/StatusV2';
import { SERVICE_STATUS_OPTIONS } from '../../../../utils/constant';
import deleteOutlinedSmall from "../../../../assets/icons/deleteOutlinedSmall.svg"

interface AttributeManagementForm2TemplateProps {
    mode: string;
    onClose: () => void;
    formik: FormikProps<ServicePayload>;
    setFormStateTo: (state: PRODUCT_SERVICE_FORM_STATE) => () => void;
}

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
    },
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    previousProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary600
    },
    activeProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary400
    },
    inActiveProgressBar: {
        backgroundColor: theme.palette.backgroundV2.tertiary.tertiary100
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    checkBox: {
        color: theme.palette.textV2.tertiary.tertiary300,
        '&.Mui-checked': {
            color: theme.palette.textV2.primary.primary400,
        },
    },
    value: {
        color: theme.palette.textV2.primary.primary950
    },
    errorMessage: {
        color: theme.palette.action.danger,
    }
}));

const AttributeManagementForm2Template: React.FC<AttributeManagementForm2TemplateProps> = ({ mode, formik, setFormStateTo }) => {
    const classes = useStyles();
    const attributeService = useAttributeServiceV2();
    const [attribute, setAttribute] = useState<Attribute[] | null>(null);
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const loadAttribute = () => {
        if (formik.values?.superService?.id) {
            attributeService.getAllAttributes({ page: 0, size: 10, sort: 'created_at,desc', status: 'ACTIVE' })
                .then(res => {
                    if (res.status === HTTP_STATUS.OK)
                        setAttribute(res?.data?.data?.content)
                }).catch((error) => {
                    console.error("Error Fetching Attribute: ", error);
                    showSnackbar('error', "Error while fetching Attribute data");
                })
        }
    }

    useEffect(() => {
        loadAttribute()
    }, [])

    const handleAttributeChange = (attributeId: number) => () => {
        const updatedAttributes = formik.values?.attributes.filter((attribute: any) => attribute.id !== attributeId);

        const updatedSubAttributeIds = { ...formik.values?.subAttributeIds };
        const attribute = formik.values?.attributes.find((attr: any) => attr.id === attributeId);
    
        if (attribute && attribute.subAttributes) {
            attribute.subAttributes.forEach((subAttr: any) => {
                delete updatedSubAttributeIds[subAttr.id];
            });
        }
    
        formik.setFieldValue("attributes", updatedAttributes);
        formik.setFieldValue("subAttributeIds", updatedSubAttributeIds);
    };

    const handleNext = () => {
        formik.setTouched({ superService: true, mainService: true, parentId: true, name: true, description: true, attributes: true, subAttributeIds: true, status: true }, true)
        const errors = Object.keys(formik.errors).find(key => ['superService', 'mainService', 'parentId', 'name', 'description', "attributes", "subAttributeIds", "status"].includes(key))
        if (errors) return;
        setFormStateTo(PRODUCT_SERVICE_FORM_STATE.REVIEW)();
    }

    const [selectedSubAttribute, setSelectedSubAttributes] = useState<{ [key: string]: SubAttributes }>({});

    const handleSubAttributeChange = (item: SubAttribute) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedSubAttributes = { ...selectedSubAttribute }
        setSelectedSubAttributes((prev) => {
            if (event.target.checked) {
                updatedSubAttributes[item.id] = {
                    minTolerance: 0,
                    maxTolerance: 0,
                };
            } else {
                delete updatedSubAttributes[item.id];
            }
            return updatedSubAttributes;
        });
        formik.setFieldValue("subAttributeIds", updatedSubAttributes);
    };

    useEffect(() => {
        if (formik?.values?.subAttributeIds)
            setSelectedSubAttributes(formik?.values?.subAttributeIds)
    }, [formik?.values?.subAttributeIds])

    return (
        <div className="grid gap-y-8" >
            {SnackBarComponent}

            <div className={`w-full m-auto flex justify-between gap-x-2 text-center text-sm `}>
                <div className="grid gap-y-3 w-1/3">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.previousProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto text-sm`}>Service Category Details</div>
                    </div>
                    <div className={`${classes.previousProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/3">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} text-sm my-auto font-semibold`}>Attribute Management</div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/3">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.inActiveProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} text-sm my-auto`}>Review</div>
                    </div>
                    <div className={`${classes.inActiveProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>

            <div className='grid gap-y-4'>
                <div className='flex justify-between'>
                    <div className='grid gap-y-0.5'>
                        <div className={`${classes.mainHeading} text-base font-medium`}>Attribute Management</div>
                        <div className={`${classes.barHeading} text-xs`}>Please enter the attribute details for the service category you want</div>
                    </div>
                    <div className={`${classes.mainHeading} text-base font-medium`}>
                        Part 2/2
                    </div>
                </div>
                <div className={`${classes.border} grid gap-y-4 p-6 rounded-xl`} >
                    <div className={`${classes.heading} text-base font-medium`}>Select Attributes Values</div>
                    {
                        formik.values?.attributes.map((attribute: Attribute) => (
                            <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`} key={attribute.id}>
                                <div className='flex justify-between'>
                                    <div className={`${classes.heading} text-base font-medium`}>{attribute.name}(Min-Max)</div>
                                    <img className='cursor-pointer' src={deleteOutlinedSmall} alt="deleteIcon" onClick={handleAttributeChange(attribute.id)} />
                                </div>
                                <div className='grid grid-cols-5 gap-4'>
                                    {
                                        attribute.subAttributes.map((subAttribute: SubAttribute) => (
                                            <div className="flex gap-x-2" key={subAttribute.id}>
                                                <div className=''>
                                                    <Checkbox
                                                        sx={{ padding: 0, width: "18px", height: "18px", }}
                                                        className={classes.checkBox}
                                                        checked={Boolean(selectedSubAttribute[subAttribute.id])}
                                                        onChange={handleSubAttributeChange(subAttribute)}
                                                    />
                                                </div>
                                                <div className={`${classes.value} my-auto text-base font-normal`}>{subAttribute.minValue}-{subAttribute.maxValue} {attribute.uom}</div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ))}
                    {formik.errors.subAttributeIds && formik.touched.subAttributeIds && (
                        <div className={`${classes.errorMessage} text-xs`}>{formik.errors.subAttributeIds}</div>
                    )}
                </div>
                <StatusV2 heading="Service Status" options={SERVICE_STATUS_OPTIONS} formik={formik} name="status" />
            </div>

            <div className="flex justify-end gap-4">
                <ButtonV2 variant="tertiaryContained" label='Back' onClick={setFormStateTo(PRODUCT_SERVICE_FORM_STATE.ATTRIBUTE_MANAGEMENT_FORM_1)} />
                <ButtonV2 variant="primaryContained" onClick={handleNext} label={"Review"} />
            </div>

        </div>
    )
}

export default AttributeManagementForm2Template