import React from 'react';
import Typography from '@mui/material/Typography';

const SearchResultsCount = ({ title="Showing", sutTitle="result", count }) => {
  const formattedCount = new Intl.NumberFormat().format(count);

  const textStyle = {
    fontWeight: 'bold',
    color: 'darkblue',
  };

  const countStyle = {
    fontStyle: 'italic',
    color: 'darkgreen',
  };

  const resultText = count === 1 ? sutTitle : sutTitle+"s";

  return (
    <Typography variant="subtitle1">
      <span style={textStyle}>{title}:</span> <span style={countStyle}>{formattedCount} {resultText}</span> 
    </Typography>
  );
};

export default SearchResultsCount;
