import React, { useEffect } from 'react'
import { IInstantQuotation } from '../../pages/ManageQuotation/QuoteCreate.page';
import { FormikProps } from 'formik';
import { Grid } from '@mui/material';
import TextFieldV2 from '../../atoms/TextFieldV2';
import { createUseStyles } from 'react-jss';

export interface IQuotationDetailProps {
  formik: FormikProps<IInstantQuotation>;
  isEditable: boolean;
}

const useStyles = createUseStyles((theme: any) => ({
  text: {
    color: theme.palette.textV2.tertiary.tertiary900
  }
}));

const particularsLabels = [
  'Warehousing Cost',
  'Handling/Labor Charge',
  'Interest Charges',
  'Freight and shipping costs',
  'Packaging Cost'
];

export const AdditionalChargesTemplate: React.FC<IQuotationDetailProps> = ({ formik, isEditable }) => {
  const classes = useStyles();

  useEffect(() => {
    particularsLabels?.forEach((label, index) => {
      formik.setFieldValue(`additionalCharges[${index}].particulars`, label);
    });
  }, []);

  return (
    <div className='flex justify-between'>
      <div>
        <span className={`text-base font-medium ${classes.text}`}>Additional Charges </span>
        {particularsLabels.map((label, index) => (
          <div className='pt-3'>
            <Grid container spacing={2} key={index}>
              <Grid item xs={3}>
                <TextFieldV2
                  label={label}
                  placeholder='Enter Data'
                  name={`additionalCharges[${index}].particulars`}
                  value={formik?.values?.additionalCharges[index]?.particulars || label}
                  onChange={formik.handleChange}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <TextFieldV2
                  label="Quantity"
                  type="number"
                  placeholder='Enter Quantity (MT)'
                  name={`additionalCharges[${index}].quantity`}
                  value={formik?.values?.additionalCharges[index]?.quantity || ''}
                  onChange={formik.handleChange}
                  inputProps={{ min: 0 }}
                  disabled={!isEditable}
                />
              </Grid>
              <Grid item xs={3}>
                <TextFieldV2
                  label="Charge"
                  type="number"
                  placeholder='Enter Number'
                  name={`additionalCharges[${index}].charge`}
                  value={formik?.values?.additionalCharges[index]?.charge || ''}
                  onChange={formik.handleChange}
                  inputProps={{ min: 0 }}
                  disabled={!isEditable}
                />
              </Grid>
              <Grid item xs={3}>
                <TextFieldV2
                  label="Total Amount"
                  type="number"
                  placeholder='Enter Number'
                  name={`additionalCharges[${index}].totalAmount`}
                  value={formik?.values?.additionalCharges[index]?.totalAmount || ''}
                  onChange={formik.handleChange}
                  disabled
                />
              </Grid>
            </Grid>
          </div>

        ))}
        <div className='py-3'>
          <TextFieldV2
            label="Grand Total Price"
            placeholder='Grand'
            type="number"
            value={formik?.values?.totalCharges?.totalAdditionalCharges || ''}
            InputProps={{ readOnly: true }}
            disabled
          />
        </div>
      </div>

      <div className='flex flex-col gap-y-3'>
        <TextFieldV2
          label='Sub-Total(A+B)'
          placeholder='Enter Data'
          name={`totalCharges.totalProductAndVASAmount`}
          value={formik?.values?.totalCharges?.totalProductAndVASAmount || ''}
          onChange={formik.handleChange}
          disabled
        />
        <TextFieldV2
          label='Charges'
          placeholder='Enter Data'
          name={`totalCharges.totalAdditionalCharges`}
          value={formik?.values?.totalCharges?.totalAdditionalCharges || ''}
          onChange={formik.handleChange}
          disabled
        />
        <TextFieldV2
          label='Total Tax Amt.'
          placeholder='Enter Data'
          name={`totalCharges.totalTaxAmount`}
          value={formik?.values?.totalCharges?.totalTaxAmount || ''}
          onChange={formik.handleChange}
          disabled
        />
        <TextFieldV2
          label='TCS 1%'
          placeholder='Enter Data'
          name={`totalCharges.tcs`}
          value={formik?.values?.totalCharges?.tcs || ''}
          onChange={formik.handleChange}
          disabled
        />
        <TextFieldV2
          label='Grand Total'
          placeholder='Enter Data'
          name={`totalCharges.grandTotal`}
          value={formik?.values?.totalCharges?.grandTotal || ''}
          onChange={formik.handleChange}
          disabled
        />
      </div>
    </div>
  );
};
