import React, { useEffect, useState } from 'react';
import ReverseAuctionStepper from '../../../Components/Procurement/ReverseActionRFQ/ReverseAuctionStepper';
import IndentDetails from '../../../Components/Procurement/ReverseActionRFQ/IndentDetails';
import AddAuctionDetails from '../../../Components/Procurement/ReverseActionRFQ/AddAuctionDetails';
import ReviewAddActionDetails from '../../../Components/Procurement/ReverseActionRFQ/ReviewAddActionDetails';
import UploadButton from '../../../Components/Procurement/Indent/UploadButton';
import { useNavigate } from 'react-router-dom';
import { AUCTION_TEXT } from '../../../Constants/Procurement/ReverseAuctionRFQ/constants';
import {
  Auction,
  AuctionFormData,
} from '../../../Constants/Procurement/ReverseAuctionRFQ/types';
import { usePRContext } from '../../../../hooks/useProcurementContext';
import { FormData, Indent } from '../../../Constants/Procurement/Indent/types';
import moment from 'moment';
import { uploadFiles } from '../../../Services/fileuploadutil';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import { useReverseAuctionService } from '../../../../services/useReverseAuctionService';
import { ViewUploadFiles } from '../../../Components/Procurement/Indent/ViewUploadFiles';

const AddReverseAuction = () => {
  const navigate = useNavigate();
  const { RFQParams, setRFQParams } = usePRContext();
  const { user }: any = useAuthenticatedUser();
  const [showIndent, setShowIndent] = useState<boolean>(false);

  const initalFormData = {
    id: '',
    auctionName: '',
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    bestPrice: false,
    winnerName: false,
    maxAutoBid: '',
    dynamicTime: false,
    autoBidAllow: false,
    dynamicTimeExt: '',
    decrementBidAmount: '',
    auctionStartPrice: '',
    auctionReversedPrice: '',
    maxAutoBidAllow: false,
    auctionTargetPrice: '',
    tnc: '',
  };

  const [formData, setFormData] = useState<AuctionFormData>(initalFormData);
  const [step, setStep] = useState<number>(1);
  const handleBack = (): void => {
    setStep(1);
  };

  const { createAuction } = useReverseAuctionService();
  // for document upload
  const [fileArray, setFileArray] = useState<File[]>([]);

  // auction detail submit
  const handleAuctionSubmit = async () => {
    const { auction } = RFQParams.selectedAuction as Auction;
    try {
      const startDateTime =
        formData.startTime &&
        formData.startDate &&
        formData.startDate +
          formData.startTime -
          moment.unix(formData.startTime).startOf('day').unix();

      const endDateTime =
        formData.endTime &&
        formData.endDate &&
        formData.endDate +
          formData.endTime -
          moment.unix(formData.endTime).startOf('day').unix();

      // upload files
      const links = await uploadFiles(
        fileArray,
        user.token,
        'AUCTION_RFQ_UPLOAD'
      );

      // convert to payload
      const payload = {
        id: auction.id,
        auctionName: formData.auctionName,
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        auctionStartAmount: formData.auctionStartPrice,
        reserveAmount: formData.auctionReversedPrice,
        targetAmount: formData.auctionTargetPrice,
        minBidStep: formData.decrementBidAmount,
        showBestPriceToBidders: formData.bestPrice,
        showWinnerNameToBidders: formData.winnerName,
        autoBiddingEnabled: formData.autoBidAllow,
        dynamicTimeExtensionInSec: formData.dynamicTimeExt,
        maxAutoBid: formData.maxAutoBid,
        tnc: formData.tnc,
        attachments: links,
      };

      const response = await createAuction(payload);

      if (response.data?.status) {
        alert(`DONE! ${response.data?.status}`);
        navigate('/procurement/reverse-auction/');
      }
    } catch (e) {
      console.log(e);
      alert('Unable to submit auction rfq');
    }
  };

  const handleSaveToDraft = (): void => {
    if (formData) {
      localStorage.setItem('auction', JSON.stringify(formData));
      navigate('/procurement/reverse-auction');
    }
  };

  const [indentDetails, setIndentDetails] = useState<FormData>();

  // check for auction id
  // if not present go back to auction list
  useEffect(() => {
    if (step === 1) {
      if (!RFQParams.selectedAuction) {
        navigate('/procurement/reverse-auction/');
      } else {
        const { auction, ...Indentdetails } =
          RFQParams.selectedAuction as Auction;
        setIndentDetails(indentToFormData(Indentdetails));
        setFormData((prev) => ({
          ...prev,
          id: (RFQParams.selectedAuction as Auction)?.auction?.id || '',
        }));
      }
    }
  }, [step]);

  //
  const indentToFormData = (formData: Indent) => {
    return {
      id: formData.id,
      items: formData.items.map((procurement) => ({
        purchase_requisition_id: procurement.purchaseRequisitionId,
        quantity: procurement.quantity,
        uom: procurement.uom,
      })),
      cess: formData.cess,
      gst: formData.gst,
      tcs: formData.tcs,
      payment_terms: formData.paymentTerms.days?.toString(),
      quality_check: formData.qualityCheck,
      purchase_type: formData.purchaseType,
      tnc: formData.tnc,
      transportation_mode: formData.transportationMode,
      warehouse_id: formData.warehouseId,
      buyer_id: formData.buyerID,
    };
  };

  return (
    <div className="grid  px-3">
      <div className="text-xl font-semibold text-blue my-6">
        {AUCTION_TEXT.addAuctionHeading}
      </div>
      <ReverseAuctionStepper step={step} />
      <div className="">
        {step === 2 && (
          <div className="flex text-blue rounded-md bg-cgray-2 justify-between items-center text-xl font-semibold py-[10px] px-[16px]">
            <div className=" flex flex-col">
              <div className="text-xl font-semibold text-blue ">
                {AUCTION_TEXT.reviewAuctionHeading}
              </div>
              <div className="text-xs font-medium text-cgray-1">
                {AUCTION_TEXT.reviewAuctionHeadingSummary}
              </div>
            </div>
          </div>
        )}
        <div className="p-6">
          <div className="flex text-blue rounded-md bg-cgray-2 justify-between items-center text-xl font-semibold px-[10px] py-[16px]  mb-4">
            <div>Indent ID : {indentDetails?.id}</div>
            <div className="flex items-center gap-6">
              {`Quantity :  ${
                indentDetails &&
                indentDetails.items
                  .reduce((total, item) => total + item.quantity, 0)
                  ?.toFixed(2)
              } MT`}
              <span
                className="cursor-pointer p-2"
                onClick={() => setShowIndent((prev) => !prev)}
              >
                {showIndent ? (
                  <svg
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 5.41699L5 0.625325L0 5.41699L10 5.41699Z"
                      fill="#5B5B5B"
                    />
                  </svg>
                ) : (
                  <svg
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 0.583008L5 5.37467L10 0.583008H0Z"
                      fill="#5B5B5B"
                    />
                  </svg>
                )}
              </span>
            </div>
          </div>
          {showIndent && (
            <div className="mb-4">
              {indentDetails && <IndentDetails indentDetails={indentDetails} />}
            </div>
          )}
        </div>
        {step === 1 && (
          <AddAuctionDetails
            setStepper={setStep}
            formData={formData}
            setFormData={setFormData}
            setFileArray={setFileArray}
            fileArray={fileArray}
          />
        )}
        {step === 2 && (
          <div className="flex flex-col gap-6 px-6">
            <div className="text-2xl font-semibold text-blue">
              Action Details
            </div>
            <div className="border-solid border-b border-cgray-3 flex flex-row justify-between w-full  py-2 ">
              <div id="Heading" className="text-2xl font-semibold  text-blue">
                Auction ID :{' '}
                {(RFQParams.selectedAuction as Auction)?.auction?.id || ''}
              </div>
              <button
                className="text-sm font-semibold leading-[22px]  text-white bg-blue flex flex-row justify-center px-6 py-3 rounded-md"
                onClick={handleBack}
              >
                Edit
              </button>
            </div>
            <ReviewAddActionDetails formData={formData} />
            {/* upload Document Button */}
            {fileArray.length > 0 && (
              <div className="mt-4">
                <ViewUploadFiles fileList={fileArray} />
              </div>
            )}
            {/* down Button */}
            <div className="flex justify-end my-4">
              <div className="flex ml-auto gap-3 ">
                <button
                  className="text-sm font-semibold text-black border border-blue-900 flex flex-row justify-center py-3 px-8 rounded"
                  onClick={handleSaveToDraft}
                >
                  Save As Draft
                </button>
                <button
                  className="text-sm font-semibold text-white bg-blue flex flex-row justify-center py-3 px-8 rounded"
                  onClick={handleAuctionSubmit}
                >
                  Submit for approval
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddReverseAuction;
