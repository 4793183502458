import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { ITemplateRequest, useWhatsappTemplateService } from '../../../services/useWhatsappTemplateService';
import { ADMIN_ROUTES, MODE } from '../../../utils/constant';
import { HTTP_STATUS } from '../../../utils/types';
import WhatsappTemplateFormTemplate from '../../templates/WhatsappTemplate/WhatsappTemplateForm.template';

const WhatsappTemplateCreatePage: React.FC = () => {

  const navigate = useNavigate();

  const { showSnackbar, SnackBarComponent } = useSnackbar();

  const whatsappTemplateService = useWhatsappTemplateService();

  const createTemplate = async (templateRequestBody: ITemplateRequest) => {
    await whatsappTemplateService.createTemplate(templateRequestBody)
      .then((response) => {
        if (response.status === HTTP_STATUS.OK) {
          navigate(ADMIN_ROUTES.WHATSAPP_TEMPLATE_LIST)
        }
        else {
          throw new Error("Template creation failed");
        }
      })
      .catch((error) => {
        showSnackbar(error, 'Template creation failed')
      })
  }

  return (
    <div>
      {SnackBarComponent}
      <WhatsappTemplateFormTemplate template={null} onTemplateSubmit={createTemplate} mode={MODE.ADD} />
    </div>
  )
}
export default WhatsappTemplateCreatePage