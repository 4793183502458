import React, { useEffect, useState } from 'react'

import { MODE, initialPaginationValues } from '../../../../utils/constant';
import { CLASS_STATES, HTTP_STATUS, IPagination, MY_MATERIAL_STATE } from '../../../../utils/types';

import EditIcon from '../../../../assets/icons/editOutline.svg'
import DeleteIconn from '../../../../assets/icons/outlineTrash.svg'
import ViewEyeIcon from '../../../../assets/icons/outlineView.svg'
import { useNavigate, useParams } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { useMaterialRequirementService } from '../../../../services/useMaterialRequirementService';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import MaterialListingActionTemplate from './MaterialListingAction.template';
import TableV2, { ColumnType } from '../../../organisms/TableV2';
import ButtonV2 from '../../../atoms/ButtonV2';
import CustomRadioGroup from '../../../molecules/CustomRadioGroup/CustomRadioGroup';
import { IOTHER_PROPS } from '../../../pages/CustomerManagement/MyMaterial.page';
import { useBusinessProfileService } from '../../../../services/useBusinessProfileService';
import NoDataFrameTemplate from '../NoDataFrame.template';
import { Formik } from 'formik';

interface RawMaterialListingTableTemplateProps {
    category: { id: number, label: string },
    updateMaterialStateTo: (value: MY_MATERIAL_STATE, other?: IOTHER_PROPS) => void;
}

export interface IAttribute {
    id: number;
    name: string;
    uom?: string;
    fieldType?: string;
    attributeOptions: number | string | string[];
}

export interface IMaterialRequirementListing {
    id: number,
    productCode: string;
    categoryId: number;
    classType: string;
    standardId: string;
    standard?: string;
    gradeId: string;
    grade?: string;
    shape: string;
    attributes: IAttribute[];
    applicationType: string;
    otherSpecificRequirement: string;
    primarySourcingPurpose: string;
    frequency: string;
    quantity: number;
    preferredDeliveryDate: string;
    preferredDeliveryLocation: string;
    customization: string;
    tradeType: string;
}

const radioGroupOption = [
    { value: "STANDARD", label: "Standard" },
    { value: "NONSTANDARD", label: "Customized" },
]

const useStyles = createUseStyles((theme: any) => ({
    primary500: {
        color: theme.palette.textV2.primary.primary500,
    },
    radioColor: {
        color: theme.palette.textV2.tertiary.tertiary700
    }
}));

const RawMaterialListingTableTemplate: React.FC<RawMaterialListingTableTemplateProps> = ({ category, updateMaterialStateTo }) => {
    const classes = useStyles();
    const [selectedClassType, setSelectedClassTypeValue] = useState<string>('STANDARD');
    const [pagination, setPagination] = useState<IPagination>(initialPaginationValues);
    const [materialRequirements, setMaterialRequirements] = useState<IMaterialRequirementListing[]>([]);
    const materialRequirementService = useMaterialRequirementService();
    const [isDialogActive, setIsDialogueActive] = useState<boolean>(false);
    const [dialogueMode, setDialogueMode] = useState<string>(MODE.EDIT);
    const [selectedId, setSelectedId] = useState<number | null>(null);
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const params = useParams();
    const businessId = Number(params.id);
    const bussinessProfileService = useBusinessProfileService();
    const [businessProfile, setBusinessProfileTo] = useState<{ userId: number } | null>(null);

    const getBusinessProfile = async () => {
        try {
            const businessProfileResponse = await bussinessProfileService.getBusinessProfile(businessId);
            if (businessProfileResponse.status === HTTP_STATUS.OK) {
                const profileData = businessProfileResponse?.data?.data;
                setBusinessProfileTo(profileData);
            }
        } catch (error) {
            showSnackbar('error', 'Business Profile fetch failed');
        }
    };

    const getMaterialRequirements = () => {
        materialRequirementService.getAllMaterialRequirements({ categoryId: category.id, classType: selectedClassType }, businessProfile?.userId as number, {size: pagination.currentPage, page: pagination.currentPage})
            .then(res => {
                if (res?.status === HTTP_STATUS.OK) {
                    setPagination({
                        ...pagination,
                        totalPages: res?.data?.data?.totalPages,
                        totalRecords: res?.data?.data?.totalElements
                    });
                    setMaterialRequirements(res?.data?.data?.content ?? [])
                }
            }).catch((error) => {
                showSnackbar('error', "Error while fetching material requirements");
            })
    }

    useEffect(() => {
        if(businessProfile)
         getMaterialRequirements();
    }, [category, selectedClassType, pagination.currentPage, pagination.pageSize, businessProfile])

    useEffect(() => {
       getBusinessProfile();
    }, [])

    useEffect(() => {
        !isDialogActive && businessProfile != null && getMaterialRequirements();
    }, [isDialogActive])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedClassTypeValue(event.target.value);
    };

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 0,
            pageSize: newRowsPerPage
        }));
    };

    const handleDelete = (id: number) => () => {
        materialRequirementService.deleteMaterialRequirementById(id)
            .then((res) => {
                if (res?.status === HTTP_STATUS.OK) {
                    getMaterialRequirements();
                    showSnackbar('success', "Successfully deleted material requirement")
                }
            }).catch((error) => {
                showSnackbar('error', "Error while deleting material requirements");
            })

    }

    const Action = (id: number) => (
        <div className='flex items-center justify-center gap-x-3'>
            <button onClick={handleDelete(id)}><img src={DeleteIconn} alt={DeleteIconn} /></button>
            <button onClick={handleAction(id, MODE.EDIT)}><img src={EditIcon} alt={EditIcon} /></button>
            <button onClick={handleAction(id, MODE.VIEW)}><img src={ViewEyeIcon} alt={ViewEyeIcon} /></button>
        </div>
    )

    const ProductCodeColumn = (id: number, productCode: string) => (
        <div onClick={handleAction(id, MODE.VIEW)}>
            <span>{productCode}</span>
        </div>
    )

    const handleAction = (id: number, mode: string) => () => {
        setDialogueMode(mode);
        setSelectedId(id);
        setIsDialogueActive(true);
    }

    const getTableAttributeValue = (attributes: IAttribute[], value: 'Thickness' | 'Width' | 'Length') => {
        return attributes.find((attribute: IAttribute) => attribute.name === value)?.attributeOptions ?? '-';
    }

    const getTableColumns = () => [
        { label: "S No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
        { label: `${selectedClassType === CLASS_STATES.STANDARD ? "UPC Code" : "MPC Code"}`, key: "productCode", type: "custom" as ColumnType, props: { className: `underline font-medium ${classes.primary500} cursor-pointer` } },
        { label: "Standard", key: "standard", type: "customm" as ColumnType, props: { className: '' } },
        { label: "Grade", key: "grade", type: "custom" as ColumnType, props: { className: '' } },
        { label: "Thickness\n(mm)", key: "thickness", type: "custom" as ColumnType, props: { className: 'flex flex-col break-word' } },
        { label: "Width\n(mm)", key: "width", type: "custom" as ColumnType, props: { className: '' } },
        { label: "Length\n(mm)", key: "length", type: "custom" as ColumnType, props: { className: '' } },
        { label: `Monthly\nRequirement`, key: "monthlyRequirement", type: "custom" as ColumnType, props: { className: '' } },
        { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } }
    ]

    const getRecords = () => materialRequirements.map((materialRequirement: IMaterialRequirementListing, index) => [
        pagination.currentPage * pagination.pageSize + index + 1,
        ProductCodeColumn(materialRequirement.id, materialRequirement.productCode),
        materialRequirement.standard,
        materialRequirement.grade,
        getTableAttributeValue(materialRequirement.attributes, 'Thickness') || '-',
        getTableAttributeValue(materialRequirement.attributes, 'Width') || '-',
        getTableAttributeValue(materialRequirement.attributes, 'Length') || '-',
        materialRequirement?.quantity || '-',
        Action(materialRequirement.id)
    ])

    const getSchema = () => ({
        id: "1",
        pagination: {
            total: pagination.totalRecords,
            currentPage: pagination.currentPage,
            isVisible: true,
            limit: pagination.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange
        },
        columns: getTableColumns() ?? []
    })

    const handleAddProductByCategory = () => {
        updateMaterialStateTo(MY_MATERIAL_STATE.MY_MATERIAL_CREATE, {id: category.id, name: category.label})
    }

    return (
        <div className='grid gap-y-6 pt-3'>
            {SnackBarComponent}
            {isDialogActive && <MaterialListingActionTemplate isDialogActive={isDialogActive} mode={dialogueMode} toggleDialogue={setIsDialogueActive} id={selectedId} />}
            <div className='flex justify-between'>
                <CustomRadioGroup
                    options={radioGroupOption}
                    value={selectedClassType}
                    onChange={handleChange} 
                    className={classes.radioColor}/>
                <ButtonV2 variant='tertiaryContained' label={'+Add New'} onClick={handleAddProductByCategory} />
            </div>
            <div className='w-full'>
                {materialRequirements.length ? <TableV2 schema={getSchema()} records={getRecords()} />
                    : <NoDataFrameTemplate title="No Result Found" message="You haven't provided Raw material for your business. " />}
            </div>
            
        </div>
    )
}

export default RawMaterialListingTableTemplate