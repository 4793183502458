import React, { useEffect, useMemo, useState } from 'react'
import { Category, CategoryPayload, useCategoryService } from '../../../services/useCategoryService';
import { createUseStyles } from 'react-jss';
import { FormikProps } from 'formik';
import { MODE, STATUS } from '../../../utils/constant';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import FormGroup from "@mui/material/FormGroup";
import Toggler from '../../atoms/Toggler';
import JoditEditor from 'jodit-react';
import Button from '../../atoms/Button';
import { FormControl } from '@mui/material';
import { CATEGORY_TYPE, HTTP_STATUS } from '../../../utils/types';
import { useSnackbar } from '../../../hooks/useSnackBar';
import AutoCompleteTextField, { AutoCompleteOption } from '../../molecules/AutoCompleteInput/AutoCompleteInput';
import { titleModification } from '../../../utils/helper';

interface MainCategoryFormTemplateProps {
    mode: string,
    formik: FormikProps<CategoryPayload>;
    onBack: () => void,
}

const useStyles = createUseStyles((theme: any) => ({
    textColor: {
        color: theme.palette.text.primaryDarkLight
    },
    border: {
        borderBottom: `1px solid ${theme.palette.border.primaryDark}`
    },
    errorMessage: {
        color: theme.palette.action.danger,
    }
}));

const MainCategoryFormTemplate: React.FC<MainCategoryFormTemplateProps> = ({ mode, onBack, formik }) => {
    const classes = useStyles();
    const descriptionEditor = React.useRef(null);
    const categoryService = useCategoryService();
    const [superCategories, setSuperCategories] = useState<Category[] | null>(null);
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const joditConfiguration = React.useMemo(() => {
        return {
            readonly: mode === MODE.VIEW,
            placeholder: "Start typings...",
        };
    }, []);

    useEffect(() => {
        fetchSuperCategories();
    }, [])

    const handleCategoryChange = (category: AutoCompleteOption | null) => {
        formik.setFieldValue('parentCategory', category);
        formik.setFieldValue('parentId', category?.id ?? null);
    };

    const fetchSuperCategories = (searchText?: string) => {
        categoryService.getAllCategories({ level: CATEGORY_TYPE.SUPER_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', name: searchText ?? '', status: 'ACTIVE' })
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setSuperCategories(res?.data?.data?.content)
            }).catch((error) => {
                showSnackbar('error', "Error while fetching Super Category data");
            })
    }

    const superCategoryOptions = useMemo(() => (
         superCategories?.map((superCategory: Category) => ({label: superCategory.name, id: superCategory.id})) ?? []
    ), [superCategories])
    
    const handleToggleChange = (type: any, value: boolean) =>
    formik.setFieldValue("status", value ? STATUS.ACTIVE : STATUS.INACTIVE)

    const handleSubmit = () => formik.handleSubmit();

    return (
        <form className="p-6 border flex flex-col gap-6">
            {SnackBarComponent}
            <div className={`flex justify-between w-full pb-6 items-center text-2xl font-semibold ${classes.textColor} ${classes.border}`}>
                <h3>{mode === MODE.UPDATE ? "Update" : mode === MODE.VIEW ? "View" : "Add New"} Main Categories</h3>
                <CloseIcon className='close-icon' onClick={() => { onBack() }} />
            </div>
            <div className='flex gap-4'>
                {(mode === MODE.ADD || superCategories) && <FormControl fullWidth>
                    <AutoCompleteTextField
                        options={superCategoryOptions}
                        label="Select Super Category"
                        onChange={handleCategoryChange}
                        onSearch={fetchSuperCategories}
                        isDisabled={mode === MODE.VIEW}
                        value={formik.values.parentCategory}
                        error={!!formik.errors.parentId && formik.touched.parentId} 
                    />
                    {formik.errors.parentId && formik.touched.parentId && (
                        <div className={`${classes.errorMessage} text-xs mt-1`}>
                            {formik.errors.parentId}
                        </div>
                    )}
                </FormControl>}
                <FormControl fullWidth>
                    <TextField
                        className={`blank-feild ${mode === MODE.VIEW && 'pointer-events-none'}`}
                        id="outlined-basic"
                        label="Enter Main Category Name"
                        variant="outlined"
                        {...formik.getFieldProps("name")}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        inputProps={{
                            readOnly: mode === MODE.VIEW,
                            maxLength: 100,
                        }}
                        onBlur={(event) => {
                            const newValue = titleModification(event.target.value);
                            formik.setFieldValue('name', newValue);
                        }}
                    />
                    {formik.errors.name && formik.touched.name && (
                        <div className={`${classes.errorMessage} text-xs mt-1`}>
                            {formik.errors.name}
                        </div>
                    )}
                </FormControl>
            </div>

            <div className={`${mode === MODE.VIEW && 'pointer-events-none'}`}>
                <FormGroup>
                    <Toggler
                        title="Status"
                        currentState={formik.values.status === STATUS.ACTIVE}
                        handleToggleChange={handleToggleChange}
                        disabled={mode === MODE.VIEW}
                    />
                </FormGroup>
            </div>
            <FormControl fullWidth>
                <JoditEditor
                    ref={descriptionEditor}
                    value={formik.values.description}
                    onBlur={(newContent) =>
                        formik.setFieldValue(
                            "description",
                            newContent
                        )
                    }
                    config={joditConfiguration}
                />
                {formik.errors.description && formik.touched.description && (
                    <div className={`${classes.errorMessage} text-xs mt-1`}>
                        {formik.errors.description}
                    </div>
                )}
            </FormControl>
            <div className="flex justify-end gap-4">
                <Button variant="outlined" label='Cancel' onClick={onBack} />
                {mode !== MODE.VIEW && <Button variant="contained" onClick={handleSubmit} label={mode} />}
            </div>
        </form>
    )
}

export default MainCategoryFormTemplate;