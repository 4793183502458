import React, { useEffect, useState } from "react";
import cancelIcon from "./../../Assets/Icons/cancelIcon.svg";
import sampleFrameImage from "./../../Assets/Images/samplePerson.jpg";

export const FilesWrapper = ({ heading, children }) => {
  return (
    <div className="self-stretch flex flex-col items-start justify-start gap-[12px] overflow-auto mt-5">
      <div className="relative font-semibold lg:text-xl">{heading}</div>
      <div className="flex flex-row items-start justify-start gap-[34px]">
        {children}
      </div>
    </div>
  );
};
