import React from "react";
import { Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../../atoms/Button";
import { formatJoeditData } from "../../atoms/JoeditDiplayFormatter";
import TitleHeading from "../../atoms/CustomHeading/TitleHeading";

interface TermsConditionModalTemplateProps {
	open: boolean;
	onClose: () => void;
	termsAndCondition: string | null;
}

const TermsConditionModalTemplate: React.FC<TermsConditionModalTemplateProps> = ({ open, onClose, termsAndCondition }) => {

	return (
		<Modal open = { open } onClose = { onClose } closeAfterTransition>
			<div className="w-2/3 m-auto mt-16 bg-white grid gap-y-6 p-6">
				<div className="flex justify-between pb-4">
					<TitleHeading text="Terms and Conditions" />
					<CloseIcon className="cursor-pointer" onClick={ onClose } />
				</div>
				<div className="p-4">
					<p>{ termsAndCondition ? formatJoeditData(termsAndCondition) : "No Data Found" }</p>
				</div>
				<div className="flex justify-end">
					<Button variant = "outlined" onClick = { onClose } label="Cancel" />
				</div>
			</div>
		</Modal>
	);
};
export default TermsConditionModalTemplate;
