import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export const ORDERS_URL = {
  GET_ALL_ORDER_MASTER: "/order-masters",
  GET_ORDER_MASTER: "/order/:id",
  UPDATE_ORDER_MASTER: "/order-master/:id",
  GET_ORDER: "/order",
  UPDATE_ORDER: "/order/:id",
};
interface IConsignee {
  name: string;
  companyName: string;
  email: string;
  gstn: string;
  phoneNumber: string;
};

interface OrderMasterRequestBody {
  orderStatus?: string ;
  paymentStatus?: string ;
  consigneeDetails?: IConsignee;
}

interface IOrderFilterRequest{
  customerName?: string;
  orderStatus?: string;
  page: number;
  size: number;
  sort?: string;
}

export const useOrderService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const getOrderMaster = (params: IOrderFilterRequest) => {
    return request(API_METHOD.GET, ORDERS_URL.GET_ALL_ORDER_MASTER, authenticatedUser, null, { params } );
  };

  const getOrders = (params: any) => {
    return request(API_METHOD.GET, ORDERS_URL.GET_ORDER, authenticatedUser, null, { params } );
  };

  const getOrderMasterById = (id: number) => {
    return request(API_METHOD.GET, replaceUrlParams( ORDERS_URL.GET_ORDER_MASTER, { id } ), authenticatedUser);
  };

  const updateOrderMaster = (id: number, data: OrderMasterRequestBody) => {
    return request(API_METHOD.PUT, replaceUrlParams(ORDERS_URL.UPDATE_ORDER_MASTER, { id }), authenticatedUser, data );
  };

  const updateOrder = (id: number, data: { status: string | null }) => {
    return request(API_METHOD.PUT, replaceUrlParams(ORDERS_URL.UPDATE_ORDER, { id }), authenticatedUser, data);
  };

  return {
    updateOrderMaster,
    updateOrder,
    getOrderMaster,
    getOrders,
    getOrderMasterById
  };
};
