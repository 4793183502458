const StatusEnum = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  PRIMARY_MANUFACTURER: "PRIMARY_MANUFACTURER",
  SECONDARY_MANUFACTURER: "SECONDARY_MANUFACTURER",
  END_MANUFACTURER: "END_MANUFACTURER",
};

export const mapStandardStatusEnumToString = (enumValue) => {
  switch (enumValue) {
    case StatusEnum.ACTIVE:
      return "Active";
    case StatusEnum.INACTIVE:
      return "Inactive";
    case StatusEnum.PRIMARY_MANUFACTURER:
      return "Primary Manufacturer";
    case StatusEnum.SECONDARY_MANUFACTURER:
      return "Secondary Manufacturer";
    case StatusEnum.END_MANUFACTURER:
      return "End Manufacturer";
    default:
      return "";
  }
};
