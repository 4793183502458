import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePRContext } from '../../../../hooks/useProcurementContext';
import { Button, FormControl, MenuItem, Select } from '@mui/material';
import VendorViewComponent from '../../../Components/Procurement/VendorDiscovery/VendorViewComponent';
import ListPaginationButton from '../../../Components/Procurement/Indent/ListPaginationButton';
import {
  SELECT_VENDOR_LIST_PAGINATION_TEXT,
  VendorGroupList,
  VendorTypeList,
} from '../../../Constants/Procurement/VendorDiscovery/constants';
import {
  FilterType,
  Vendor,
} from '../../../Constants/Procurement/VendorDiscovery/types';

import NoDataFound from '../../../../components/atoms/NoDataFound/NoDataFound';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import { useVendorService } from '../../../../services/useVendorService';
import CustomCheckBox from '../../../Shared/CustomInputs/CustomCheckBox';

const VendorSelection = () => {
  const { vendorDiscoveryParams } = usePRContext();
  const { user }: any = useAuthenticatedUser();
  const navigate = useNavigate();

  const [vendorId, setVendorId] = useState<string>('');
  const [vendorType, setVendorType] = useState<string>(VendorTypeList[0].value);
  const [vendorGroup, setVendorGroup] = useState<string>(
    VendorGroupList[0].value
  );

  const [searchPage, setSearchPage] = useState<number>(0);
  const [vendorList, setVendorList] = useState<Vendor[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectedVendors, setSelectedVendors] = useState<(number | string)[]>(
    []
  );
  const pageSize = 10;

  const { getVendors, inviteVendors } = useVendorService();

  const [filters, setFilters] = useState<FilterType>({
    pageSize: pageSize,
    pageNumber: pageNumber,
    searchTerm: '',
    locations: '',
    ratings: '',
    vendorGroups: '',
    productCategories: '',
    vendorTypes: '',
  });

  const searchHandler = (value: string) => {
    setVendorId(value);
    setPageNumber(0);
    const params: FilterType = {
      ...filters,
      pageNumber: 0,
      searchTerm: value,
    };
    setFilters(params);
    fetchVendors(params);
  };

  const fetchVendors = async (params: FilterType): Promise<void> => {
    if (pageNumber >= 0) {
      const queryParams: any = params;

      try {
        const response = await getVendors(queryParams);
        if (response?.data?.data?.content) {
          setTotalRecords(response.data.data?.totalElements);
          setTotalPages(response.data.data.totalPages);
          setPageNumber(response.data.data.pageable.pageNumber);
          const data: any = response.data.data.content;
          let vendors = data.map((data: any) => {
            return {
              id: data?.id || '',
              name: data.businessName || '',
              address:
                `${data?.address.city}, ${data?.address?.state}, ${data?.address?.country}` ||
                '',
              type: data?.type || '',
              category: data?.group || '',
              ratings: data?.ratings || '',
            };
          });
          setVendorList(vendors);
          setSearchPage(response.data.data.pageable.pageNumber);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setTotalRecords(0);
        setTotalPages(0);
        setPageNumber(0);
        setVendorList([]);
        setSearchPage(0);
      }
    }
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      const IDs = vendorList.map((vendor) => vendor.id);
      setSelectedVendors(IDs);
    } else {
      setSelectedVendors([]);
    }
    setSelectAll((prev) => !prev);
  };

  const handlePagination = (type: 'prev' | 'next' | 'search'): void => {
    if (type === 'prev') {
      if (pageNumber > 0) {
        setPageNumber((prev) => prev - 1);
        const params: FilterType = {
          ...filters,
          pageNumber: filters.pageNumber - 1,
        };
        setFilters(params);
        fetchVendors(params);
      }
    } else if (type === 'next') {
      if (pageNumber < totalPages) {
        setPageNumber((prev) => prev + 1);
        const params: FilterType = {
          ...filters,
          pageNumber: filters.pageNumber + 1,
        };
        setFilters(params);
        fetchVendors(params);
      }
    } else if (type === 'search') {
      if (searchPage >= 0 && pageNumber !== searchPage) {
        setPageNumber(searchPage);
        const params: FilterType = {
          ...filters,
          pageNumber: searchPage,
        };
        setFilters(params);
        fetchVendors(params);
      }
    }
  };

  const handleChange = ({ name, value }: { name: string; value: string }) => {
    if (value) {
      if (name === 'vendorType') {
        setVendorType(value);
        const params: FilterType = {
          ...filters,
          vendorTypes: value,
        };
        setFilters(params);
        fetchVendors(params);
      } else if (name === 'vendorGroup') {
        setVendorGroup(value);
        const params: FilterType = {
          ...filters,
          vendorGroups: value,
        };
        setFilters(params);
        fetchVendors(params);
      }
    }
  };

  const handleToggle = (id: string | number) => {
    let newSelectedVendors = [...selectedVendors];
    if (newSelectedVendors.find((itemId) => itemId === id)) {
      newSelectedVendors = newSelectedVendors.filter((itemId) => itemId !== id);
    } else {
      newSelectedVendors.push(id);
    }
    setSelectedVendors(newSelectedVendors);
    setSelectAll(false);
  };

  const handleSendInvites = async () => {
    const indentId = vendorDiscoveryParams?.selectedAuction?.id;
    if (pageNumber >= 0 && indentId) {
      const payload = {
        vendorIds: selectedVendors,
      };
      try {
        const response = await inviteVendors(indentId, payload);
        if (response?.data?.status === 'OK') {
          navigate('/procurement/reverse-auction/vendor-discovery/');
        }
      } catch (error) {
        console.log('Error in the invite vendors', error);
        alert('Error in the invite vendors');
      }
    }
  };

  useEffect(() => {
    if (!vendorDiscoveryParams.selectedAuction) {
      navigate('/procurement/reverse-auction/vendor-discovery/');
    } else {
      fetchVendors(filters);
    }
  }, []);

  return (
    <div className="grid  px-3">
      <div className="text-xl font-semibold text-blue my-6">Select Vendors</div>
      <div className="bg-cgray-2 flex flex-row justify-between w-full items-center py-[10px] px-[16px] gap-4">
        <div className="text-xl font-semibold text-text-color">
          <div className="flex flex-row justify-center items-center gap-3">
            <div className="flex flex-row">
              <CustomCheckBox
                name="selectAll"
                checked={selectAll}
                handleChange={() => handleSelectAll()}
                className="w-6 h-6 "
              />
            </div>
            <div className="text-base text-nowrap"> Select All</div>
          </div>
        </div>
        <div className="flex flex-row gap-5 items-center justify-between">
          <div className="flex  item-center relative">
            <div className="flex justify-center items-center gap-3  w-full ">
              <div className="text-blue font-semibold text-base text-nowrap">{`${'Types of Vendors'} :`}</div>
              <FormControl sx={{ minWidth: '150px', maxWidth: '170px' }}>
                <Select
                  sx={{ color: 'black', backgroundColor: 'white' }}
                  value={vendorType}
                  onChange={({ target }) => handleChange(target)}
                  name="vendorType"
                >
                  {VendorTypeList.map((status, id) => (
                    <MenuItem key={id} value={status.value}>
                      {status.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="flex  item-center relative">
            <div className="flex justify-center items-center gap-3  w-full ">
              <div className="text-blue font-semibold text-base text-nowrap">{`${'Vendors Group'} :`}</div>
              <FormControl sx={{ minWidth: '150px', maxWidth: '170px' }}>
                <Select
                  sx={{ color: 'black', backgroundColor: 'white' }}
                  value={vendorGroup}
                  onChange={({ target }) => handleChange(target)}
                  name="vendorGroup"
                >
                  {VendorGroupList.map((status, id) => (
                    <MenuItem key={id} value={status.value}>
                      {status.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="flex  item-center relative">
            <FormControl sx={{ width: '308px' }}>
              <input
                name="search"
                value={vendorId}
                onChange={(e) => searchHandler(e.target.value)}
                type="text"
                placeholder={'Search'}
                className="bg-white  h-14 pl-2 pr-14 border border-gray border-cgray-3 rounded-lg focus:outline-blue"
              />
            </FormControl>
            <button
              type="submit"
              className="bg-blue flex items-center w-14 justify-center absolute right-0 h-14 rounded-r-lg"
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.3854 15.3781L22.5432 22.5452M17.8079 9.52309C17.8079 14.0997 14.0978 17.8099 9.52114 17.8099C4.94449 17.8099 1.23438 14.0997 1.23438 9.52309C1.23438 4.94644 4.94449 1.23633 9.52114 1.23633C14.0978 1.23633 17.8079 4.94644 17.8079 9.52309Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div>
            <button className=" w-12 border h-12 border-cgray-3 rounded-lg flex justify-center items-center bg-white">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 2.408C1 1.91515 1 1.66873 1.09591 1.48049C1.18028 1.31491 1.31491 1.18028 1.48049 1.09591C1.66873 1 1.91515 1 2.408 1H15.432C15.9248 1 16.1713 1 16.3595 1.09591C16.5251 1.18028 16.6597 1.31491 16.7441 1.48049C16.84 1.66873 16.84 1.91515 16.84 2.408V3.93679C16.84 4.15203 16.84 4.25965 16.8157 4.36092C16.7941 4.45072 16.7586 4.53656 16.7103 4.61529C16.6559 4.7041 16.5798 4.7802 16.4276 4.93239L11.0924 10.2676C10.9402 10.4198 10.8641 10.4959 10.8097 10.5847C10.7614 10.6634 10.7259 10.7493 10.7043 10.8391C10.68 10.9404 10.68 11.048 10.68 11.2632V13.32L7.16 16.84V11.2632C7.16 11.048 7.16 10.9404 7.13569 10.8391C7.11413 10.7493 7.07857 10.6634 7.03032 10.5847C6.9759 10.4959 6.8998 10.4198 6.74761 10.2676L1.41239 4.93239C1.2602 4.7802 1.1841 4.7041 1.12968 4.61529C1.08143 4.53656 1.04587 4.45072 1.02431 4.36092C1 4.25965 1 4.15203 1 3.93679V2.408Z"
                  stroke="#4D4D4D"
                  strokeWidth="1.76"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {vendorList.length <= 0 ? (
        <div className="py-32">
          <NoDataFound />
        </div>
      ) : (
        <>
          <div className="my-5 flex flex-col gap-2">
            {vendorList.map((vendorDetail, id) => (
              <div className="" key={id}>
                <VendorViewComponent
                  handleToggle={handleToggle}
                  selectedVendors={selectedVendors}
                  vendorDetail={vendorDetail}
                />
              </div>
            ))}
          </div>
          <div className="flex justify-end mb-5">
            <Button
              variant="outlined"
              className="flex items-center h-[48px] w-[119px] bg-blue text-white normal-case hover:bg-blue rounded-none"
              onClick={handleSendInvites}
            >
              Send Invites
            </Button>
          </div>
          <ListPaginationButton
            PaginationText={SELECT_VENDOR_LIST_PAGINATION_TEXT.showingItems(
              pageNumber,
              totalRecords
            )}
            pageNumber={pageNumber}
            handlePagination={handlePagination}
            searchPage={searchPage}
            setSearchPage={setSearchPage}
            totalPages={totalPages}
            totalRecords={totalRecords}
          />
        </>
      )}
    </div>
  );
};

export default VendorSelection;
