import React, { useState } from "react";
import useCreate from "../../Core/CustomHooks/useCreate";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { Typography, Button, TextField } from "@mui/material";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";

export default function ChangeNameDialog({ isOpen, onClose, previousData = '' }) {
  //formik
  // const errorMessageColor = "#D32F2F";
  // const [formData, setFormData] = useState({
  //   selectedTitle: "",
  //   fullName: "",
  // });

  // var NameInitialValues = {
  //   // selectedTitle: "",
  //   fullName: "",
  // };
  // var validateFormikFields = yup.object({
  //   // selectedTitle: yup.string().required("title is required"),
  //   fullName: yup.string().required("name is required"),
  // });
  // const NameFormik = useFormik({
  //   initialValues: NameInitialValues,
  //   validationSchema: validateFormikFields,
  //   onSubmit: async (values, { setSubmitting, setStatus }) => {
  //     console.log("onSubmit function called");
  //     // Handle form submission here
  //     setSubmitting(true);
  //     console.log("NAME FORMIK DATA - ", values);
  //     // return
  //     console.log("baseurl",BaseUrl)
  //      var response = await handleCreate(values);

  //   },
  // });

  // useEffect(() => {
  //   console.log("NAME useefeect DATA - ", NameFormik);
  // }, [NameFormik]);
  // const displayTitle = (data) => {
  //   if (data === "EDIT") {
  //     return "Edit";
  //   }
  // };
  // Form Errors
  // const [formErrors, setFormErrors] = useState({
  //   selectedTitle: "",
  //   fullName: "",
  // });

  // const handleCreate = async (recordData) => {
  //   try {
  //     // Create an object containing the data you want to send to the API
  //     const postData = {
  //       // selectedTitle: recordData.selectedTitle,
  //       fullName: recordData.fullName,
  //     };

  //     console.log("FORM DATA - ", postData);

  //     // Make the Axios PUT request
  //     const apiUrl = `${BaseUrl}/user`;
  //     const httpMethod= "PUT";
  //     const response = await axios({
  //       method: httpMethod,
  //       url: apiUrl,
  //       data: postData,
  //     });
  //     // Handle the response as needed
  //       console.log('Response from server:', response);
  //       if (response.status === 200) {
  //         console.log('Response from server 2:', response.data);
  //         onClose();
  //       } else {
  //         console.error('Error:', response.statusText);
  //       }

  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };
  // const [content, setContent] = useState("");

  // const handleFormData = (key, event) => {
  //   console.log("KEY - DATA - ", key, event.target.value);
  //   setFormData((prev) => ({
  //     ...prev,
  //     [key]: event.target.value,
  //   }));
  // };

  // };
  const { user, setAuthenticatedUser } = useAuthenticatedUser();
  const [fullName, setFullName] = useState(previousData);
  const [fullNameError, setFullNameError] = useState(false);
  const { loading, putRequest } = useCreate();
  const [errorMessage, setErrorMessage] = useState("Enter valid name");
  const fullNameRegex = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/;

  const handleFullNameChange = (e) => {
    const value = e.target.value;
    setFullName(value);
    setFullNameError(!isFullNameValid(value));
  };
  const isFullNameValid = (value) => {
    if (value.length === 0 || value.length < 3) {
      return false;
    } else if (!fullNameRegex.test(value)) {
      return  false
    }
    return true;
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    const isValidName = fullNameRegex.test(fullName);
    if (!isValidName) {
      setFullNameError(true)
      setErrorMessage("Please enter a valid name")
      return;
    }

    let apiRes = await putRequest("/user", { fullName: fullName });
    if (apiRes === undefined) {
      console.log("Internal server error occured");
      return;
    }
    if (apiRes?.status === 400) {
      console.log("name updated");
    } else if (apiRes?.status === 200) {
      setAuthenticatedUser({ ...user, fullName: fullName });
    } else {
      console.log("Something went wrong");
    }
    onClose();
  };

  const handleCancel = () => {
    setFullName("");
  };
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm">
      <div style={{ padding: "1.5rem 2.5rem 2.5rem 2.5rem" }}>
        <Typography variant="h6" gutterBottom style={{ color: "#1D3679" }}>
          Change Your Name
        </Typography>
        <Typography variant="body1" paragraph>
          If you want to change the name associated with your Amazon customer
          account, you may do so below. Be sure to click the Save Changes button
          when you are done.
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: "16px",
          }}
        >
         
          <TextField
            required
            label="Full Name"
            variant="outlined"
            value={fullName}
            onChange={handleFullNameChange}
            fullWidth
            error={fullNameError}
            // helperText={fullNameError? "Enter valid name":""}
            helperText={fullNameError&& errorMessage}
            autoComplete="false"

     
          />
        </div>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={onClose}
            style={{ color: "#1D3679" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleUpdate}
           
            style={{ color: "#fff", background: "#1D3679" }}
          >
            Update
          </Button>
        </DialogActions>
       
      </div>
    
    </Dialog>
  );
}
