import React, { useEffect, useState } from 'react';
import CustomHeading from '../../../Shared/CustomHeading/CustomHeading';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ProductDataType,
  ProductFormData,
} from '../../../Constants/Procurement/PurchaseRequisition/types';
import ReviewCard from '../../../Components/Procurement/PurchaseRequisition/ReviewCard';
import { NavigationURL } from '../../../Constants/EndPoints';
import { useAuthenticatedUser } from './../../../../hooks/useAuthenticatedUser';
import { usePurchaseRequisition } from '../../../../services/usePurchaseRequisition';

const PurchaseRequisitionDetails = () => {
  const { user }: any = useAuthenticatedUser();
  const id = useParams().id || '';
  const navigate = useNavigate();
  const { getPurchaseRequistions } = usePurchaseRequisition();
  const [pr, setPR] = useState<ProductDataType>();
  const [value, setValue] = useState<ProductFormData>();
  const fetchRequisitionData = (): void => {
    const queryParams: Record<string, string | number> = {
      prIDs: id,
      pageSize: 1,
      pageNumber: 0,
    };

    getPurchaseRequistions(queryParams)
      .then((response) => {
        const product = response?.data.data.content[0];
        setPR(product.item);
        const values: ProductFormData = {
          consigneeLocation: product.consigneeLocation,
          packagingType: product.packagingType,
          warehouseId: product.warehouseId,
          quantity: product.quantity,
          deliveryTime: product.deliveryTime,
          uom: product.uom,
          tnc: product.tnc,
          docURL: [product?.item?.productCategoryImageURL],
          remarks: product.remarks,
          itemId: product.id,
          productRequirement: '',
        };
        setValue(values);
      })
      .catch((err) => {
        console.log('Purchase Requisition Error - ', err);
      });
  };
  useEffect(() => {
    fetchRequisitionData();
  }, []);

  const handleBack = () => {
    navigate(NavigationURL.purchaseRequisition);
  };

  return (
    <div>
      <div className="flex bg-cgray-2 justify-between my-4">
        <div className="text-blue py-6 px-4">
          <CustomHeading text={`PRID: ${id}`} status="" />
        </div>
      </div>
      {pr && value ? (
        <ReviewCard
          item={{
            ...pr,
            productCategoryImageURL:
              value.docURL && value.docURL.length !== 0 ? value.docURL[0] : '',
          }}
          index={0}
          values={value}
          handleBack={handleBack}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default PurchaseRequisitionDetails;
