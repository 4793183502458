import axios from "axios";
import { useEffect, useState } from "react";

import { useLocation } from 'react-router-dom';
import ViewCatalogue from "./ViewCatalogue";
import SelectTemplate from "./SelectTemplate";
import CatalogueUserStatusTable from "./WhatsAppIntegration/Users/CatalogueUserStatusTable";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
const BaseUrl = process.env.REACT_APP_API_V1_URL;

const ShareCatalogue = () => {
  const { user } = useAuthenticatedUser();
  const location = useLocation();
  const { catalogueRecordId } = location.state || {};
  const [activeState, setActiveState] = useState(1);
  const [selectedCustomers, setSelectedCustomers] = useState({});
  const [templateData, setTemplateData] = useState([]);
  const [catalogueDetails, setCatalogueDetails] = useState([]);
  const [userTemplateStatus, setUserTemplatetatus] = useState([]);

  let activeComponent = null;

   if (activeState === 1)  {
    activeComponent = <SelectTemplate
      nextStage={setActiveState}
      catalogueId={catalogueRecordId}
      selectedCustomers={selectedCustomers}
      templateData={templateData}
      updateCustomers={setSelectedCustomers}
      updateTemplateDetails={setTemplateData}
      catalogueDetails={catalogueDetails}
      updateTemplateUserStatus={setUserTemplatetatus}
    />
  } else {
    activeComponent = <CatalogueUserStatusTable 
        userTemplateStatus={userTemplateStatus}
        catalogueId={catalogueRecordId}
    />
  }
  return (
    <>
      {activeComponent}
    </>
  );
};

export default ShareCatalogue;
