import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { makeRoute } from '../../../../utils/helper';
import { HTTP_STATUS, VAS_MASTER_CATEGORY_TYPE } from '../../../../utils/types';
import { ServicePayload, useVASMasterCategoryService } from '../../../../services/useVASMasterCategoryService';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { ADMIN_ROUTES, EXCEPTION_CODE, MODE, STATUS } from '../../../../utils/constant';
import MainServiceFormTemplate from '../../../templates/VASMaster/Categories/MainServiceForm.template';

const validationSchema = Yup.object().shape({
    name: Yup.string().max(30, "Main Service Name cannot be more than 30 characters").required("Main Service Name is required"),
    description: Yup.string().max(500, "Description cannot be more than 500 characters").required("Description is required"),
    parentId: Yup.number().required("Super Service is required"),
    superService: Yup.object().shape({
        id: Yup.number().required('Super Service is required'),
        label: Yup.string().required('Super Service is required')
    }).required('Super Service is required'),
});

const MainServiceCreatePage: React.FC = () => {
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const vasMasterCategoryService = useVASMasterCategoryService();
    const navigate = useNavigate();

    const onBack = () => {
        navigate(makeRoute(ADMIN_ROUTES.VAS_MASTER_CATEGORIES_LIST, { query: { type: 'MAIN_SERVICE' } }));
    }

    const createService = async (service: ServicePayload) => {
        const servicePayload = {
            name: service.name,
            status: service.status,
            description: service.description,
            parentId: service.parentId,
            level: service.level,
        };
        vasMasterCategoryService.createService(servicePayload)
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    showSnackbar('success', "New Main Service Created");
                    onBack();
                } else if (res.data.exceptionCode === EXCEPTION_CODE.DUPLICATE_ENTRY) {
                    showSnackbar('error', "Main Service already exists. Please try again");
                }
            }).catch((error) => {
                showSnackbar('error', "Failed to crate Main Service");
            })
    }

    const formik = useFormik<ServicePayload>({
        initialValues: {
            name: "",
            status: STATUS.ACTIVE,
            description: "",
            parentId: null,
            level: VAS_MASTER_CATEGORY_TYPE.MAIN_SERVICE,
            superService: {
                id: "",
                label: ""
            },
        },
        validationSchema,
        onSubmit: async (values) => {
            createService({ ...values, status: values.status })
        }
    })

    return (
        <div>
            {SnackBarComponent}
            <MainServiceFormTemplate mode={MODE.ADD} onBack={onBack} formik={formik} />
        </div>
    )
}

export default MainServiceCreatePage