import React from "react";
import ImageButton from "../../../Shared/ImageButton/ImageButton";
import closeBlackSvg from "../../../Assets/Images/closeBlack.svg";
import { CustomButton } from "../../../Shared/Button/CustomButton";

const QuotationDetailsModal = ({ onClose, upcSkuEditDetails, charges, totalQuantity }) => {
  let total = 0;
  let singleQuantity = 0;
  let productOtherChargesRatio = 1;
  if (upcSkuEditDetails) {
    for (const key in upcSkuEditDetails) {
      const item = upcSkuEditDetails[key];
      const quantity = parseFloat(item.quantity); 
      const price = parseFloat(item.price); 
      singleQuantity += quantity;
      const totalPrice = price * quantity; 
      total += totalPrice;
    }
  }
  const warehousePrice =( charges[0]?.warehouseCharges|| 0) * singleQuantity;
  const handlingPrice = (charges[0]?.handlingCharges|| 0) * singleQuantity;
  const packagingPrice = (charges[0]?.packagingCharges|| 0) * singleQuantity;
  const otherPrice = + (charges[0]?.otherCharges || 0);
  productOtherChargesRatio = singleQuantity/totalQuantity;
  const subTotal = total + warehousePrice + handlingPrice + packagingPrice + (productOtherChargesRatio*otherPrice);
  const totalGst = ((charges[0]?.gst || 0) * (subTotal)) / 100;
  const totalTcs = ((charges[0]?.tcs || 0) * (subTotal)) / 100;

  return (
    <div className="fixed z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white z-20 rounded shadow-lg w-1/2">
        <div className="product-detail-container grid gap-6  p-6  text-[#2A2A2A] ">
          <div className=" grid gap-2">
            <div className="">
              <div className="font-medium text-xl pb-3 flex justify-between w-full ">
                <p>Payment Information</p>
                <div className="rounded">
                  <ImageButton
                    className="p-1.5 bg-white rounded"
                    name="delete-lead-upc"
                    imageSrc={closeBlackSvg}
                    onClick={onClose}
                  />
                </div>
              </div>
              <div className="border"></div>
            </div>
            <div className="grid gap-3 text-[#2A2A2A]  pb-2">
              <div className="flex justify-between text-base font-medium">
                {/* <>{JSON.stringify(upcSkuEditDetails)}</> */}
                <p>Sub Total ({Object.keys(upcSkuEditDetails).length} SKU)</p>
                <p>&#8377; {new Intl.NumberFormat().format(total)}</p>
              </div>
              <div className="flex justify-between text-base font-normal ">
                <p>
                  Warehousing charges{" "}
                  <span className="text-sm">({singleQuantity} MT)</span>
                </p>
                <p>&#8377; {new Intl.NumberFormat().format(warehousePrice)}</p>
              </div>
              <div className="flex justify-between text-base font-normal ">
                <p>
                  Handling charges{" "}
                  <span className="text-sm">({singleQuantity} MT)</span>
                </p>
                <p>&#8377; {new Intl.NumberFormat().format(handlingPrice)}</p>
              </div>
              <div className="flex justify-between text-base font-normal ">
                <p>
                  Packaging charges{" "}
                  <span className="text-sm">({singleQuantity} MT)</span>
                </p>
                <p>&#8377; {new Intl.NumberFormat().format(packagingPrice)}</p>
              </div>
              <div className="flex justify-between text-base font-normal ">
                <p>Other charges</p>
                <p>&#8377; {new Intl.NumberFormat().format(productOtherChargesRatio*otherPrice)}</p>
              </div>
              <div className="flex justify-between text-base font-semibold  ">
                <p>Subtotal</p>
                <p>&#8377; {new Intl.NumberFormat().format(subTotal)}</p>
              </div>
              <div className="border"></div>
              <div className="flex justify-between text-base font-normal ">
                <p>GST</p>
                <p>&#8377; {new Intl.NumberFormat().format(totalGst)}</p>
              </div>
              <div className="flex justify-between text-base font-normal ">
                <p>TCS</p>
                <p>&#8377; {new Intl.NumberFormat().format(totalTcs)}</p>
              </div>
              <div className="border"></div>
            </div>
            <div className="">
              <div className="flex justify-between text-[#2A2A2A] text-xl font-medium">
                <p>Total amount</p>
                <p>
                  &#8377;{" "}
                  {new Intl.NumberFormat().format(
                    subTotal + totalGst + totalTcs
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className=" flex justify-end">
            <CustomButton onClick={onClose}>Cancel</CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuotationDetailsModal;
