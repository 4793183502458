import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';

interface IDetailsTableTemplateProps {
    columnNames: string[];
    triggerComponent: React.ReactElement;
    details: any[];
    renderRow: (detail: any, index: number) => React.ReactNode;
}

const useStyles = createUseStyles((theme: any) => ({
    header: {
        background: theme.palette.background._primaryLightAccent,
    },
}));

const DetailsTableTemplate: React.FC<IDetailsTableTemplateProps> = ({
    columnNames,
    triggerComponent,
    details,
    renderRow,
    
}) => {

    const classes = useStyles();

    return (
      <div className="border rounded-2xl overflow-hidden">
      <div className={`text-sm text-left p-4 font-medium ${classes.header}`}>
          <div className="grid grid-cols-[50px_1fr_1fr_1fr_1fr_1fr_1fr_1fr_1fr_50px] items-center gap-4">
              {columnNames?.map((columnName, index) => (
                  <div key={index}  className={`${index === 1 ? 'col-span-2' : ''}`}>{columnName}</div>
              ))}
          </div>
      </div>
      <div className="p-4">
          {details?.map((detail, index) => (
              <div key={index}>
                  <div className="grid grid-cols-[50px_1fr_1fr_1fr_1fr_1fr_1fr_1fr_1fr_50px] gap-3 p-4">
                      {renderRow(detail, index)}
                  </div>
              </div>
          ))}
                <div className="p-4 text-center" >
                    {triggerComponent}
                </div>
      </div>
  </div>
    );
};

export default DetailsTableTemplate;
