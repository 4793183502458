import React from 'react'
import { createUseStyles } from 'react-jss';
import TextFieldV2 from '../../../atoms/TextFieldV2';
import { MODE } from '../../../../utils/constant';
import { titleModification } from '../../../../utils/helper';

interface MachineDescriptionFormTemplateProps {
    mode: string,
    formik: any;
}

const useStyles = createUseStyles((theme: any) => ({
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
}));

const MachineDescriptionFormTemplate: React.FC<MachineDescriptionFormTemplateProps> = ({ mode, formik }) => {
    const classes = useStyles();

    return (
        <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
            <div className={`${classes.heading} text-base font-medium`}>Machine Description</div>
            <div className="grid gap-y-1">
                <TextFieldV2
                    variant="outlined"
                    label="Add Description"
                    placeholder='Enter Here'
                    multiline
                    maxRows={4}
                    {...formik.getFieldProps("machineDescription")}
                    error={formik.touched.machineDescription && Boolean(formik.errors.machineDescription)}
                    inputProps={{ readOnly: mode === MODE.VIEW, maxLength: 500 }}
                    onBlur={(event) => {
                        const newValue = titleModification(event.target.value);
                        formik.setFieldValue('machineDescription', newValue);
                    }}
                />
                {formik.errors.machineDescription && formik.touched.machineDescription && (
                    <div className={`${classes.errorMessage} text-xs`}>{formik.errors.machineDescription}</div>
                )}
            </div>
        </div>
    )
}

export default MachineDescriptionFormTemplate;