const ProductAttributesEnum = {
    manufactureName: "Manufacture",
    brand: "Brand",
    superCategory: "Super Category",
    mainCategory: "Main Category",
    productCategory: "Product Category",
    grade: "Grade",
    standard: "Standard",
    productProperties: "Product Properties",
    shape: "Shape",
    classType: "Class",
    length: 'Length',
    thickness: 'Thickness',
    width: 'Width',
    temper: 'Temper',
    surfaceCoating: 'Surface Coating',
    oiling: 'Oiling',
  };
  
  export default ProductAttributesEnum;
