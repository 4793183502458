import React, { useEffect, useMemo, useState } from 'react'
import { CategoryPayload } from '../../../services/useCategoryService';
import { FormikProps } from 'formik';
import { HTTP_STATUS, PRODUCT_CATEGORY_FORM_STATE } from '../../../utils/types';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { Checkbox, FormControl } from '@mui/material';
import Button from '../../atoms/Button';
import { Standard, useStandardService } from '../../../services/useStandardService';
import AutoCompleteTextField, { AutoCompleteOption } from '../../molecules/AutoCompleteInput/AutoCompleteInput';
import { MODE } from '../../../utils/constant';
import Chips, { ChipItem } from '../../molecules/Chips/Chips';
import { createUseStyles } from 'react-jss';
import { useConfigurationService } from '../../../services/useConfigurationService';

interface StandardManagementFormTemplateProps {
  mode: string;
  onClose: () => void;
  formik: FormikProps<CategoryPayload>;
  setFormStateTo: (state: PRODUCT_CATEGORY_FORM_STATE) => () => void;
}

const useStyles = createUseStyles((theme: any) => ({
  textColor: {
    color: theme.palette.general.seperator
  },
  border: {
    borderBottom: `1px solid ${theme.palette.border.primaryDark}`
  },
  value: {
  },
  errorMessage: {
    color: theme.palette.action.danger,
  },
}));


const StandardManagementFormTemplate: React.FC<StandardManagementFormTemplateProps> = ({ mode, formik, setFormStateTo, onClose }) => {
  const classes = useStyles();
  const { showSnackbar, SnackBarComponent } = useSnackbar();
  const standardService = useStandardService();
  const configurationService = useConfigurationService();

  const [standardOptions, setStandardOptions] = useState<AutoCompleteOption[] | []>([]);
  const [customizationOptions, setCustomizationOptions] = useState<any[]>([]);

  const updateStandards = (standards: AutoCompleteOption[]) => {
    const standardIds = standards?.map(selected => selected.id);
    formik.setFieldValue('standardOptions', standards)
    formik.setFieldValue('standards', standardIds);
  }

  const handleChipDelete = (value: ChipItem) => {
    const standardOptions = formik.values.standardOptions?.filter((chip) => chip.id !== value.id) ?? null;
    if (!standardOptions) return;
    updateStandards(standardOptions);
  }

  const updateSelectedStandards = (value: AutoCompleteOption | null) => {
    if (!value) return;
    if (formik.values.standardOptions?.some(selectedStandard => selectedStandard.id === value?.id)) return
    updateStandards([...formik.values.standardOptions ?? [], { ...value }])
  };

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (!formik.values.standardOptions?.length)
      return showSnackbar('error', 'Select atleast one standard')
    formik.handleSubmit();
  }

  const fetchStandards = (search: string = "") => {
    standardService.getAllStandards({ page: 0, size: 10, sort: "createdAt,desc", search })
      .then(res => {
        if (res.status === HTTP_STATUS.OK) {
          const standardOptions = res?.data?.data?.content?.map((standard: Standard) => ({ label: standard.name, id: standard.id }))
          setStandardOptions(standardOptions)
        }
      }).catch(error => {
        showSnackbar('error', 'Failed to fetch standards')
      })
  }

  const fetchConfiguration = (search: string = "") => {
    configurationService.getConfiguration({ contexts: "CUSTOMIZATION" })
      .then(res => {
        if (res.status === HTTP_STATUS.OK) {
          setCustomizationOptions(res?.data?.data[0]?.data)
        }
      }).catch(error => {
        showSnackbar('error', 'Failed to fetch standards')
      })
  }
  const [selectedRequirements, setSelectedRequirements] = useState<Array<string>>([]);

  const handleRequirementChange = (value: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    let local = [];
    if (event.target.checked) {
      setSelectedRequirements([...selectedRequirements, value]);
      local = [...selectedRequirements, value]
    } else {
      setSelectedRequirements(selectedRequirements.filter(item => item !== value));
      local = selectedRequirements.filter(item => item !== value)
    }
    formik.setFieldValue("customization", local);
  };

  useEffect(() => {
    fetchStandards()
  }, [])

  useEffect(() => {
    fetchConfiguration()
  }, [])

  useEffect(() => {
    setSelectedRequirements(formik?.values?.customization || [])
  }, [formik?.values?.customization])

  return (
    <>
      {SnackBarComponent}
      <div className='grid gap-y-6'>
        <div className=''>
          <div className={`flex justify-between pb-4 ${classes.border} ${classes.textColor} !text-2xl font-semibold select-none`}>
            <h3>Standards</h3>
          </div>
          <div className='flex flex-row w-full items-center mt-4'>
            {mode !== MODE.VIEW && <FormControl fullWidth>
              <AutoCompleteTextField
                options={standardOptions}
                label="Select Standards"
                onChange={updateSelectedStandards}
                onSearch={fetchStandards}
                isDisabled={mode === MODE.VIEW}
              />
            </FormControl>}
          </div>
          <Chips values={formik.values.standardOptions ?? []} onDelete={handleChipDelete} disabled={mode === MODE.VIEW} />
        </div>

        <div className=''>
          <div className={`flex justify-between pb-4 ${classes.border} ${classes.textColor} !text-2xl font-semibold select-none`}>
            <h3>Customisation</h3>
          </div>
          <div className='grid mt-4'>
            <div className='flex gap-x-8'>
              {customizationOptions?.map((item: any) => (
                <div className="flex gap-x-2" key={item.id}>
                  <div>
                    <Checkbox
                      sx={{ padding: 0, color: "#C4C4C4" }}
                      checked={selectedRequirements.includes(item.value)}
                      onChange={handleRequirementChange(item.value)}
                      disabled={mode===MODE.VIEW}
                    />
                  </div>
                  <div className={`${classes.value} my-auto text-base font-normal`}>{item.name}</div>
                </div>
              ))}

            </div>
            {formik.touched && formik.touched?.customization && formik.errors?.customization && (
              <div className={`${classes.errorMessage}`}>
                <small>{formik.errors.customization}</small>
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-end gap-4">
          <Button variant="outlined" label='Back' onClick={setFormStateTo(PRODUCT_CATEGORY_FORM_STATE.ATTRIBUTE_MANAGEMENT)} />
          {mode !== MODE.VIEW && <Button variant="contained" onClick={handleSubmit} label={"Save"} />}
          {mode === MODE.VIEW && <Button variant="contained" label='Cancel' onClick={onClose} />}
        </div>
      </div>
    </>
  )
}

export default StandardManagementFormTemplate
