import React from "react";
interface CustomHeadingProps {
  text: string;
  status: string; // Add status prop
}

const CustomHeading: React.FC<CustomHeadingProps> = ({ text, status }) => {
  let headingColor: string;

  // Conditionally determine the color based on the status prop
  switch (status) {
    case "COMPLETED":
      headingColor = "#009F11";
      break;
    case "CANCELLED":
      headingColor = "#F04438";
      break;
    case "FAIL":
      headingColor = "#F04438";
      break;
    case "SUCCESS":
      headingColor = "#009F11";
      break;
    case "ONGOING":
      headingColor = "#525252";
      break;
    default:
      headingColor = "#1d3679"; // Default color if status is not recognized
  }
  const headingStyle: React.CSSProperties = {
    color: headingColor,
    fontFamily: "Roboto",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  };
  return <h1 style={headingStyle}>{text}</h1>;
};
export default CustomHeading;
