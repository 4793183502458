import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";
export const LEAD_URLS = {
  CREATE_LEAD: "/lead",
  GET_ALL_LEAD: "/leads",
  GET_LEAD: "/lead/:id",
  CREATE_LEAD_UPC: "/lead-upc",
  DELETE_LEAD: "/lead-upc/:id",
};

interface ILeadFilterRequest{
  search?: string;
  status?: string;
  actorId?: number;
  page: number;
  size: number;
  sort?: string;
  catalogueType?: string;
}

export const useLeadService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const createLead = (data: any) => {
    return request(API_METHOD.POST, LEAD_URLS.CREATE_LEAD, authenticatedUser, data);
  };

  const getAllLeads = (params: ILeadFilterRequest) => {
    return request(API_METHOD.GET, LEAD_URLS.GET_ALL_LEAD, authenticatedUser, null, { params } );
  };

  const getLeadById = (id: number) => {
    return request(API_METHOD.GET, replaceUrlParams( LEAD_URLS.GET_LEAD, { id } ), authenticatedUser);
  };

  const createLeadUpc = (data: any) => {
    return request(API_METHOD.POST, LEAD_URLS.CREATE_LEAD_UPC, authenticatedUser, data);
  };

  const deleteLeadUpc = (id: number) => {
    return request(API_METHOD.DELETE, replaceUrlParams( LEAD_URLS.DELETE_LEAD, { id } ), authenticatedUser);
  };

  return {
    createLead,
    getAllLeads,
    getLeadById,
    createLeadUpc,
    deleteLeadUpc,
  };
};
