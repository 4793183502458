import React, { useState } from 'react'
import { useSnackbar } from '../../../hooks/useSnackBar';
import ImageMasterCreatePageTemplate from '../../templates/ImageMaster/ImageMasterCreate.template';
import TitleHeading from '../../atoms/CustomHeading/TitleHeading';

const ImageMasterCreatePage: React.FC = () => {

    const { showSnackbar, SnackBarComponent } = useSnackbar();

    return (
        <div>
            { SnackBarComponent }
            <TitleHeading text="Add Image Master" />
            <ImageMasterCreatePageTemplate mode='ADD'/>
        </div>
    )
}

export default ImageMasterCreatePage;
