import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createUseStyles } from "react-jss";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import CachedIcon from "@mui/icons-material/Cached";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { useQuotationService } from "../../../services/useQuotationService";
import Select from "../../atoms/Select";
import TextField from "../../atoms/TextField";
import Table, { ColumnType } from "../../organisms/Table";
import { CLASS_TYPE, IPagination } from "../../../utils/types";
import { ADMIN_ROUTES, LEAD_STATUS, initialPaginationValues } from "../../../utils/constant";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { enumToString, makeRoute } from "../../../utils/helper";
import ResourceStatus from "../../atoms/ResourceStatus/ResourceStatus";

interface QuotationListTemplateProps { }

interface IQuotations {
	id: number;
	leadId: number;
	actorId: number;
	companyName: string;
	actorName: string;
	termsAndCondition: string;
	publishStatus: string;
	status: string;
	createdAt: string;
	updatedAt: string;
	quotationUpcResponseDTO: {
		id: number;
		quotationId: number;
		upcId: number;
		upcCode: string;
		quantity: number;
		createdAt: string;
		updatedAt: string;
		secondarySkuId: number;
	}[];
	quotationUpcResponseDTOLength: number;
	catalogueType: string;
}

interface IQuotation {
	id: number;
	leadId: number;
	actorId: number;
	companyName: string;
	actorName: string;
	termsAndCondition: string;
	publishStatus: string;
	status: string;
	createdAt: string;
	updatedAt: string;
	charges: {
		warehouseCharges: number;
		handlingCharges: number;
		packagingCharges: number;
		otherCharges: number;
		gst: number;
		tcs: number;
	};
	quotationUpcResponseDTO: {
		id: number;
		quotationId: number;
		upcId: number;
		upcCode: string;
		skuData: {
			skuId: number;
			skuCode: string;
			quantity: number;
			profit: number;
			basePrice: number;
			packaging: string;
			warehouse: string;
		}[];
		quantity: number;
		createdAt: string;
		updatedAt: string;
	}[];
}

export interface IQuotationFilter {
	status: string;
	publishStatus: string;
	companyName: string;
}

const initialQuotationFiltersValues: IQuotationFilter = {
	status: "ALL",
	publishStatus: "ALL",
	companyName: "",
};

const useStyles = createUseStyles((theme: any) => ({
	viewIconContainer: {
		color: theme.palette.icon.primaryLight,
	},
	editIconContainer: {
		color: theme.palette.icon.secondryLight,
	},
	updateIconContainer: {
		color: theme.palette.icon.primaryDark,
	},
}));

const QuotationListTemplate: React.FC<QuotationListTemplateProps> = () => {
	const classes = useStyles();
	const quotationService = useQuotationService();
	const { user } = useAuthenticatedUser();
	const [quotations, setQuotaionsTo] = useState<IQuotations[]>([]);
	const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);
	const [quotationFilters, setQuotationFiltersTo] = useState<IQuotationFilter>(initialQuotationFiltersValues);
	const navigate = useNavigate();

	const handleViewQuotation = (quotationId: number) => {
		navigate(`${ ADMIN_ROUTES.QUOTATION }/${ quotationId }`);
	};

	const handleEditQuotation = (id: number, catalogueType: string) => () => {
		if (catalogueType === CLASS_TYPE.STANDARD) {
			navigate(makeRoute(ADMIN_ROUTES.QUOTATION_UPDATE, { params:  { id: id } }));
        } else if (catalogueType === CLASS_TYPE.SECONDARY) {
            navigate(makeRoute(ADMIN_ROUTES.SECONDARY_QUOTATION_UPDATE, { params:  { id: id } }));
        } else if (catalogueType === CLASS_TYPE.MAKE_TO_ORDER) {
            navigate(makeRoute(ADMIN_ROUTES.MTO_QUOTATION_UPDATE, { params:  { id: id } }));
        }
	};

	const loadQuotations = async (page: number, size: number) => {
		try {
			const params = {
				page: page,
				size: size,
				actorId: Number(user?.id),
				status: quotationFilters?.status === "ALL" ? "" : quotationFilters?.status,
				search: quotationFilters?.companyName,
				publishStatus: quotationFilters?.publishStatus === "ALL" ? "" : quotationFilters?.publishStatus,
				sort: "createdAt,desc",
			};
			const quotationsResponse = await quotationService.getAllQuotations(params);
			if (quotationsResponse?.status === 200 && quotationsResponse?.data?.data) {
				const { content, totalElements, totalPages } = quotationsResponse.data.data;
				const formattedQuotationData = content.map((quotation: IQuotations) => ({
					...quotation,
					quotationUpcResponseDTOLength: quotation.quotationUpcResponseDTO.length,
				})
				);
				setQuotaionsTo(formattedQuotationData);
				setPaginationTo((prevPagination: IPagination) => ({
					...prevPagination,
					totalRecords: totalElements,
					totalPages: totalPages,
				}));
			} else {
				throw new Error("Quotations fetch failed");
			}
		} catch (error) {
			setQuotaionsTo([]);
		}
	};

	const [editId, setEditIdTo] = useState<number | null>(null);
	const [quotationStatus, setQuotationStatusTo] = useState<string | null>(null);
	const [quotationPublishStatus, setQuotationPublishStatusTo] = useState<string | null>(null);
	const [singleQuotationData, setSingleQuotationDataTo] = useState<IQuotation[]>([]);
	const [quotationUpcUpdateRequestBodyDTO, setQuotationUpcUpdateRequestBodyDTOTo] = useState([]);

	const loadQuotation = async (editId: number) => {
		try {
			const quotationResponse = await quotationService.getQuotationById(editId);
			if (quotationResponse?.status === 200 && quotationResponse?.data?.data) {
				setSingleQuotationDataTo(quotationResponse.data.data);
				setQuotationUpcUpdateRequestBodyDTOTo(
					quotationResponse.data.data.quotationUpcResponseDTO
				);
				setEditIdTo(editId);
			} else {
				throw new Error("Quotation fetch failed");
			}
		} catch (error) {
			setSingleQuotationDataTo([]);
			setQuotationUpcUpdateRequestBodyDTOTo([]);
			setEditIdTo(null);
		}
	};

	const handleUpdateQuotation = (id: any) => () => {
		loadQuotation(id);
	};

	const handleSubmit = async () => {
		let requestBody
		if (quotationPublishStatus && quotationStatus == null) {
			const updatedData = {
				...singleQuotationData,
				publishStatus: quotationPublishStatus,
				id: editId,
				quotationUpcUpdateRequestBodyDTO: quotationUpcUpdateRequestBodyDTO,
			};
			requestBody = updatedData

		} else if (quotationStatus && quotationPublishStatus == null) {
			const updatedData = {
				...singleQuotationData,
				status: quotationStatus,
				id: editId,
				quotationUpcUpdateRequestBodyDTO: quotationUpcUpdateRequestBodyDTO,
			};
			requestBody = updatedData

		} else if (quotationStatus && quotationPublishStatus) {
			const updatedData = {
				...singleQuotationData,
				status: quotationStatus,
				publishStatus: quotationPublishStatus,
				id: editId,
				quotationUpcUpdateRequestBodyDTO: quotationUpcUpdateRequestBodyDTO,
			};
			requestBody = updatedData
		}
		if (editId !== null) {
			try {
				const response = await quotationService.updateQuotation(
					editId,
					requestBody
				);
				if (
					response &&
					response.status === 200 &&
					response.data &&
					response.data.data
				) {
					setEditIdTo(null);
				} else {
					console.error("Failed to update quotation data:", response);
				}
			} catch (error) {
				throw error;
			}
		}
	};


	const handleFiltersChange = (event: any) => {
		const { name, value } = event.target;
		setPaginationTo({...pagination, currentPage: 0})
		setQuotationFiltersTo({ ...quotationFilters, [name]: value ?? "" });
	};

	const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPaginationTo((prevPagination) => ({
			...prevPagination,
			currentPage: newPage
		}));
	};

	const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newRowsPerPage = parseInt(event.target.value, 10);
		setPaginationTo((prevPagination) => ({
			...prevPagination,
			pageSize: newRowsPerPage
		}));
	};


	useEffect(() => {
		loadQuotations(pagination.currentPage, pagination.pageSize);
	}, [pagination.currentPage, pagination.pageSize, quotationFilters.status, quotationFilters.publishStatus, quotationFilters.companyName, editId]);

	const schema = {
		id: "1",
		pagination: {
			total: pagination.totalRecords,
			currentPage: pagination.currentPage,
			isVisible: true,
			limit: pagination.pageSize,
			handleChangePage: handlePaginationChange,
			handleChangeRowsPerPage: handleRowsPerPageChange,
		},
		columns: [
			{ label: "Sr No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
			{ label: "Quotation ID", key: "id", type: "number" as ColumnType, props: { className: '' } },
			{ label: "Sales Representative", key: "companyName", type: "string" as ColumnType, props: { className: '' } },
			{ label: "Lead Id", key: "upcIdLength", type: "number" as ColumnType, props: { className: '' } },
			{ label: "Company Name", key: "upcIdLength", type: "string" as ColumnType, props: { className: '' } },
			{ label: "Publish Status", key: "upcIdLength", type: "custom" as ColumnType, props: { className: '' } },
			{ label: "Total Products", key: "createdAt", type: "number" as ColumnType, props: { className: '' } },
			{ label: "Status", key: "status", type: "custom" as ColumnType, props: { className: '' } },
			{ label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } },
		],
	};

	const statusInput = (id: number, status: string) => {
		return (
			<>
				{editId === id ? (
					<select
						className="w-28"
						value={ quotationStatus || "" }
						onChange={ (e) => setQuotationStatusTo(e.target.value) }
					>
						<option value="" disabled>Select Status</option>
						<option value="ONGOING">ONGOING</option>
						<option value="ACCEPTED">ACCEPTED</option>
						<option value="REJECTED">REJECTED</option>
					</select>
				) : (
					<div>
						<ResourceStatus status={enumToString(status)} />
					</div>
				)}
			</>
		);
	};

	const publishStatusInput = (id: number, publishStatus: string) => {
		return (
			<>
				{editId === id ? (
					<select
						className="w-28"
						value={ quotationPublishStatus || "" }
						onChange={ (e) => setQuotationPublishStatusTo(e.target.value) }
					>
						<option value="" disabled>Select Publish Status</option>
						<option value="PUBLISHED">PUBLISHED</option>
						<option value="NOTPUBLISHED">NOTPUBLISHED</option>
					</select>
				) : (
					<div>
						<ResourceStatus status={enumToString(publishStatus)} />
					</div>
				)}
			</>
		);
	};

	const Action = (id: number, status: string, catalogueType: string) => {
		return (
			<div className="flex gap-x-2 justify-end">
				{editId === id ? (
					<>
						<DoneIcon className={ `${ classes.updateIconContainer } text-secondary cursor-pointer` } onClick={ () => handleSubmit() } />
						<CloseIcon className={ `${ classes.updateIconContainer } text-secondary cursor-pointer` } onClick={ () => {
							setEditIdTo(null);
							setQuotationStatusTo(null);
							setQuotationPublishStatusTo(null);
						}}
						/>
					</>
				) : (
					<>
						<RemoveRedEyeIcon className={ `${ classes.viewIconContainer } text-secondary cursor-pointer` } onClick={ (e) => handleViewQuotation(id) } />
						{status === LEAD_STATUS.ACCEPTED ? null :
							<>
								<ModeEditOutlineIcon className={ `${ classes.editIconContainer } text-secondary cursor-pointer` } onClick={ handleEditQuotation(id, catalogueType) } />
								<CachedIcon className={ `${ classes.updateIconContainer } text-secondary cursor-pointer` } onClick={ handleUpdateQuotation(id) } />
							</>
						}
					</> 
				)}
			</div>
		);
	};

	const records = quotations.map((quotation: IQuotations, index: number) => [
		pagination.currentPage * pagination.pageSize + index + 1,
		quotation.id,
		quotation.actorName,
		quotation.leadId,
		quotation.companyName,
		publishStatusInput(quotation.id, quotation.publishStatus),
		quotation.quotationUpcResponseDTOLength,
		statusInput(quotation.id, quotation.status),
		Action(quotation.id, quotation.status, quotation.catalogueType),
	]);

	return (
		<div className="grid gap-y-1.5">
			<div className="flex justify-between">
				<div className=" flex gap-x-4 w-96">
					<Select
					    variant="outlined"
						className="h-full"
						label="Status"
						value={ quotationFilters.status }
						onChange={ handleFiltersChange }
						name="status"
						id="status"
						options={[
							{ value: LEAD_STATUS.ALL, label: LEAD_STATUS.ALL },
							{ value: LEAD_STATUS.ONGOING, label: LEAD_STATUS.ONGOING },
							{ value: LEAD_STATUS.ACCEPTED, label: LEAD_STATUS.ACCEPTED },
							{ value: LEAD_STATUS.REJECTED, label: LEAD_STATUS.REJECTED },
						]}
					/>
					<Select
					    variant="outlined"
						className="h-full"
						label="Publish Status"
						value={ quotationFilters.publishStatus }
						onChange={ handleFiltersChange }
						name="publishStatus"
						id="publishStatus"
						options={[
							{ value: LEAD_STATUS.ALL, label: LEAD_STATUS.ALL },
							{ value: LEAD_STATUS.PUBLISHED, label: LEAD_STATUS.PUBLISHED },
							{ value: LEAD_STATUS.NOTPUBLISHED, label: LEAD_STATUS.NOTPUBLISHED },
						]}
					/>
				</div>
				<TextField
					className="w-60"
					label="Search By Company Name..."
					variant="outlined"
					type="text"
					name="companyName"
					value={ quotationFilters.companyName }
					onChange={ handleFiltersChange }
				/>
			</div>
			<Table schema={ schema } records={ records } />
		</div>
	);
};
export default QuotationListTemplate;
