import React, { useState } from 'react'
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    errorBig: {
      color: theme.palette.textV2.failure.failure500,
      position: 'absolute',
    },
    errorSmall: {
      color: theme.palette.textV2.failure.failure500,
      position: 'absolute',
    }
  }));
  
interface ErrorMessageProps {
    message?: string
    size?: string
}
const ErrorMessage: React.FC<ErrorMessageProps> = ({message, size}) => {
    const classes = useStyles();
  return (
    <div className={`${ size === 'small' ? classes.errorSmall : classes.errorBig } text-xs -bottom-5`}>{message}</div>
  )
}

export default ErrorMessage