import React from 'react';
import CategorySearchTemplate from './CategorySearch.template';
import ManufactureSearchTemplate from './ManufactureSearch.template';
import { createUseStyles } from 'react-jss';
import { Category } from '../../../../services/useCategoryService';

interface CategoryAndManufacturerSearchTemplateProps {
    handleSpecification: (productCategory: Category) => void;
    formik: any;
    mode: string;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        backgroundColor: theme.palette.background.primaryLight,
    },
    heading: {
        color: theme.palette.text.primaryDarkLight,
    },
}));

const CategoryAndManufacturerSearchTemplate: React.FC<CategoryAndManufacturerSearchTemplateProps> = ({ handleSpecification, formik, mode }) => {

    const classes = useStyles();

    return (
        <div className={`${classes.container} grid gap-y-4 p-6 rounded`}>
            <div className={` ${classes.heading} text-lg font-semibold`}>
                Category
            </div>
            <div className='grid gap-y-4'>
                <CategorySearchTemplate
                    handleSpecification={handleSpecification}
                    formik = {formik}
                    mode={mode}
                />
                {/* <ManufactureSearchTemplate formik = {formik} mode={mode}/> */}
            </div>
        </div>
    );
};
export default CategoryAndManufacturerSearchTemplate;
