import * as yup from "yup";
import { createUseStyles } from 'react-jss';
import SearchIcon from '@mui/icons-material/Search';
import { useFormik } from 'formik';
import { HTTP_STATUS } from '../../../utils/types';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useGstinService } from '../../../services/useGstinService';
import { BUSINESS_PROFILE_SECTIONS, REGEX } from '../../../utils/constant';
import TextFieldV2 from '../../atoms/TextFieldV2';
import { createGstAddressLines } from '../../../utils/helper';
import ButtonV2 from '../../atoms/ButtonV2';
import { IBusinessProfile } from "../../pages/CustomerManagement/ProfileOverview.page";

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.primarylightAccent}`,
        borderRadius: "8px",
    },
    primaryContainer: {
        border: `1px solid ${theme.palette.border.tritiaryLight}`,
    },
    heading: {
        color: theme.palette.text.primaryLight,
    },
    gstSearch: {
        background: theme.palette.textV2.primary.primary500,
        color: "#FFF",
        padding: "14px",
        borderRadius: "10px",
        height: "56px",
    },
}));

export interface IGSTAddress {
    line1: string;
    line2: string;
    pincode: string;
    city: string;
    state: string;
}
export interface IGSTDetails {
    gstin: string;
    pan: string;
    address: IGSTAddress;
}

interface IGSTUpdateTemplateProps {
    updateBusinessProfileSection: (section: string, values: any) => void;
    businessProfile: IBusinessProfile | null;
    setIsEditing: (value: boolean) => void;
}

const GSTUpdateTemplate: React.FC<IGSTUpdateTemplateProps> = ({ updateBusinessProfileSection, businessProfile, setIsEditing }) => {

    const classes = useStyles();

    const gstinService = useGstinService();
    const { showSnackbar } = useSnackbar();

    const GSTSchema = yup.object().shape({
        gstin: yup.string().required('GST is required').matches(REGEX.GST, 'Enter valid GST eg.22AAAAA0000A1Z5'),
        pan: yup.string(),
        address: yup.object().shape({
            line1: yup.string().required('Address is required'),
            pincode: yup.string().required('Pincode is required'),
            city: yup.string().required('City is required'),
            state: yup.string().required('State is required'),
        })
    });

    const getPANFromGSTIn = (gstin: string) => {
        if (gstin.length >= 15) {
            return gstin.substring(2, gstin.length - 3);
        }
        return gstin;
    };

    const gstFormik = useFormik<IGSTDetails>({
        initialValues: {
            gstin: businessProfile?.gstin || '',
            pan: businessProfile?.gstin ? getPANFromGSTIn(businessProfile?.gstin) : "",
            address: {
                line1: businessProfile?.address?.line1 || '',
                line2: businessProfile?.address?.line2 || '',
                pincode: businessProfile?.address?.pincode || '',
                city: businessProfile?.address?.city || '',
                state: businessProfile?.address?.state || '',
            }
        },
        validationSchema: GSTSchema,
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: async (values: any) => {
            try {
                gstFormik.setSubmitting(true);
                const requestBody = {
                    gstin: values?.gstin,
                    pan: values?.pan,
                }
                await updateBusinessProfileSection(BUSINESS_PROFILE_SECTIONS.GST, requestBody);
                setIsEditing(false);
            } catch (error) {
                showSnackbar("error", `Business profile update failed: ${error}`);
            } finally {
                gstFormik.setSubmitting(false);
            }
        },
    });

    const getGSTInDetails = async (gstin: string) => {
        try {
            const payload = {
                "gstin": gstin
            }
            const gstDetailsResponse = await gstinService.getGstin(payload);
            if (gstDetailsResponse.status === HTTP_STATUS.OK) {
                const details = gstDetailsResponse?.data?.data;
                const gstSplitAddress = createGstAddressLines(details);
                gstFormik.setFieldValue("address.line1", gstSplitAddress[0]);
                gstFormik.setFieldValue("address.line2", gstSplitAddress[1]);
                gstFormik.setFieldValue("address.pincode", details.pincode);
                gstFormik.setFieldValue("address.city", details.city[0]);
                gstFormik.setFieldValue("address.state", details.state[0][0]);
            }
        } catch (error) {
            showSnackbar('error', 'Failed to fetch GST details');
        }
    };

    return (
        <form onSubmit={gstFormik.handleSubmit}>
            <div className={`${classes.container} p-6 rounded-lg w-full`}>
                <div className='flex justify-between items-center pb-6 border-b border-grey-500'>
                    <h2 className={`${classes.heading} text-2xl font-medium`}>GST Details</h2>
                    <div className='flex gap-4'>
                        <ButtonV2 variant={'tertiaryContained'} label={'Back'} type="button" onClick={() => setIsEditing(false)} />
                        <ButtonV2 variant={'primaryContained'} label={'Save'} type="submit" />
                    </div>
                </div>
                <div className='flex flex-col gap-6 pt-6'>
                    <div className='grid grid-cols-2 gap-x-6'>
                        <div className={`flex gap-x-4 rounded-lg items-end `}>
                            <div className='flex-1'>
                                <TextFieldV2
                                    label="GST Registered Number"
                                    fullWidth
                                    {...gstFormik.getFieldProps("gstin")}
                                    onChange={(event: any) => {
                                        gstFormik.handleChange({
                                            target: { name: 'gstin', value: (event.target.value).toUpperCase() },
                                        });
                                    }}
                                    error={gstFormik.touched.gstin && Boolean(gstFormik.errors.gstin)}
                                    helperText={gstFormik.touched.gstin && gstFormik.errors.gstin}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className={`${classes.gstSearch} `} onClick={() => getGSTInDetails(gstFormik?.values?.gstin)}>
                                <SearchIcon />
                            </div>
                        </div>
                        <TextFieldV2
                            label="PAN"
                            fullWidth
                            {...gstFormik.getFieldProps("pan")}
                            error={gstFormik.touched.pan && Boolean(gstFormik.errors.pan)}
                            inputProps={{ readOnly: true }}
                        />
                    </div>
                    <div className={`${classes.primaryContainer} p-4 grid gap-y-8 rounded-xl`}>
                        <p className={`${classes.heading} font-medium text-base`}>Principal Place of Business</p>
                        <div className='grid gap-y-4'>
                            <TextFieldV2
                                label="Address Line 1"
                                fullWidth
                                {...gstFormik.getFieldProps("address.line1")}
                                error={gstFormik.touched.address?.line1 && Boolean(gstFormik.errors.address?.line1)}
                                helperText={gstFormik.touched.address?.line1 && gstFormik.errors.address?.line1 ? gstFormik.errors.address?.line1 : ''}
                                inputProps={{ readOnly: true }}
                            />
                            <TextFieldV2
                                label="Address Line 2"
                                fullWidth
                                {...gstFormik.getFieldProps("address.line2")}
                                error={gstFormik.touched.address?.line2 && Boolean(gstFormik.errors.address?.line2)}
                                helperText={gstFormik.touched.address?.line2 && gstFormik.errors.address?.line2 ? gstFormik.errors.address?.line2 : ''}
                                inputProps={{ readOnly: true }}
                            />
                            <div className='grid grid-cols-3 gap-3'>
                                <TextFieldV2
                                    label="Pincode"
                                    fullWidth
                                    {...gstFormik.getFieldProps("address.pincode")}
                                    error={gstFormik.touched.address?.pincode && Boolean(gstFormik.errors.address?.pincode)}
                                    helperText={gstFormik.touched.address?.pincode && gstFormik.errors.address?.pincode ? gstFormik.errors.address?.pincode : ''}
                                    inputProps={{ readOnly: true }}
                                />
                                <TextFieldV2
                                    label="City"
                                    fullWidth
                                    {...gstFormik.getFieldProps("address.city")}
                                    error={gstFormik.touched.address?.city && Boolean(gstFormik.errors.address?.city)}
                                    helperText={gstFormik.touched.address?.city && gstFormik.errors.address?.city ? gstFormik.errors.address?.city : ''}
                                    inputProps={{ readOnly: true }}
                                />
                                <TextFieldV2
                                    label="State"
                                    fullWidth
                                    {...gstFormik.getFieldProps("address.state")}
                                    error={gstFormik.touched.address?.state && Boolean(gstFormik.errors.address?.state)}
                                    helperText={gstFormik.touched.address?.state && gstFormik.errors.address?.state ? gstFormik.errors.address?.state : ''}
                                    inputProps={{ readOnly: true }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default GSTUpdateTemplate;
