import React from "react";
import TablePagination from '@mui/material/TablePagination';
import DateCell from "../atoms/DateCell";
import DateTimeCell from "../atoms/DateTimeCell";
import CurrencyCell from "../atoms/CurrencyCell";
import NumberCell from "../atoms/NumberCell";
import StringCell from "../atoms/StringCell";
import { createUseStyles, useTheme } from "react-jss";
export interface Pagination {
  limit: number;
  isVisible: boolean;
  currentPage: number;
  total: number;
  handleChangePage?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => void;
  handleChangeRowsPerPage?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export type ColumnType = "number" | "string" | "date" | "datetime" | "custom" | "currency";
export interface TableColumn {
  label: string;
  key: string;
  isSortable?: boolean;
  isFilterable?: boolean;
  component?: (props: any) => React.ReactNode
  type: ColumnType;
  props: { [key: string]: any };
}
export interface TableSchema {
  id: string;
  title?: string;
  pagination: Pagination;
  sort?: {
    sortBy: "asc" | "desc";
    sortOn: string;
  }
  filter?: {
    [key: string]: any;
  }
  columns: TableColumn[];
}
interface TableProps {
  schema: TableSchema;
  records: any[][];
}
const useStyles = createUseStyles({
  header: {
    backgroundColor: ({ theme }: any) => theme.palette.table.headerBackground,
  },
  title: {
    color: ({ theme }: any) => theme.palette.button.primaryDark,
  },
  tableBody: {
    color: ({ theme }: any) => theme.palette.table.textPrimary,
  },
  tableRow: {
    '&:nth-child(odd)': {
      backgroundColor: ({ theme }: any) => theme.palette.table.rowOdd,
    },
    '&:nth-child(even)': {
      backgroundColor: ({ theme }: any) => theme.palette.table.rowEven,
    },
  },
  recordWrapper: {
    maxWidth: "160px",
  },
  cellWrap: {
    maxWidth: "50px",
    whiteSpace: "normal",
    wordBreak: "break-all", // Change from wordWrap to wordBreak
  },
});
const getCellView = (data: any, columnProps: TableColumn) => {
  const { type, component, props } = columnProps;
  if (type === "custom" && component) {
    return component({ value: data, ...props });
  }
  switch (type) {
    case "number":
      return <NumberCell data={ data } props={ props } />;
    case "date":
      return <DateCell data={ data } props={ props } />;
    case "datetime":
      return <DateTimeCell data={ data } props={ props } />;
    case "currency":
      return <CurrencyCell data={ data } props={ props } />;
    default:
      return <StringCell data={ data } props={ props } />;
  }
};
const ROWS_PER_PAGE_OPTIONS = [5, 10, 15, 25];
const Table: React.FC<TableProps> = ({ schema, records }) => {
  const theme = useTheme<Jss.Theme>();
  const styles = useStyles({ theme });

  const { total, isVisible, currentPage, limit, handleChangePage = () => {}, handleChangeRowsPerPage= () => {} } = schema.pagination;
  return (
    <div>
      {schema.title && <h2 className={`${styles.title} text-xl font-semibold`}>{schema.title}</h2>}
      <table className="table-auto w-full">
        <thead className={`${styles.header} text-white text-sm font-semibold text-left`}>
          <tr className="h-12 text-center">
            {schema.columns.map((column, index) => (
              <th className="last:text-right first:text-left last:pr-3 first:pl-3" key={index}>{column.label}</th>
            ))}
          </tr>
        </thead>
        <tbody className={`${styles.tableBody} text-sm font-normal`}>
          {records.map((record) => (
            <tr key={`row-${record[0]}`} className={`${styles.tableRow} text-center h-12`}>
              {schema.columns.map((column, columnIndex) => (
                <td
                  className={`${styles.recordWrapper} last:text-right first:text-left last:pr-3 first:pl-3 ${styles.cellWrap}`}
                  key={`col-${column.key}`}
                >
                  {getCellView(record[columnIndex], column)}
                </td>

              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className={ !isVisible? `hidden`:`flex gap-x-6 justify-end`}>
        <TablePagination
          component="div"
          count={total}
          page={currentPage}
          rowsPerPage={limit}
          onPageChange={handleChangePage}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};
export default Table;