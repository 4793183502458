import React from 'react';
import { createUseStyles } from 'react-jss';
import AddLocation from '../../../../App/Assets/Images/AddLocation.svg';

import { FormikProps } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { LOCATION_STATES } from '../../../../utils/types';
import { ILocationGstinForm } from '../../../pages/VendorManagement/VendorLocation.page';
import { ADMIN_ROUTES } from '../../../../utils/constant';
import ButtonV2 from '../../../atoms/ButtonV2';
import { LocationSectionProps } from './VendorLocation.template';


const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.primarylightAccent}`,
    }
}));

const AddVendorLocationTemplate: React.FC<LocationSectionProps> = ({ formik, setCurrentSectionTo }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleBackToDashboard = ()=> {
        navigate(ADMIN_ROUTES.VENDOR_MANAGEMENT_LIST);
     };
    return (
        <div className=' grid gap-8'>
            <div className={`${classes.container} py-14 px-6  rounded-lg`}>
                <div className='grid gap-y-4'>
                    <div className='grid gap-y-8 justify-items-stretch'>
                        <div className='justify-self-center'>
                            <img src={AddLocation} alt="locationImage" className="" />
                        </div>
                        <div className='justify-self-center font-normal text-lg text-[#142552]'>
                            You haven't provide a location for your business.
                        </div>
                    </div>
                    <div className='grid justify-items-stretch'>
                        <ButtonV2
                            className={` justify-self-center w-auto`}
                            variant="primaryContained"
                            label="Add Location"
                            onClick={() => { setCurrentSectionTo(LOCATION_STATES.ADD_LOCATION_NO) }}
                        />
                    </div>
                </div>
            </div>
            <div className=' grid justify-items-stretch'>
                        <ButtonV2
                            className={` justify-self-end`}
                            variant="secondaryContained"
                            label="Back to Dashboard"
                            onClick={handleBackToDashboard}
                        />
                    </div>
        </div>

    )
};
export default AddVendorLocationTemplate;
