import { Button } from '@mui/material';
import awardimg from '../../../../Assets/Images/award.svg';
import { AUCTION } from '../../../../Constants/Procurement/LiveAuction/text';
import { Bidder } from '../../../../Constants/Procurement/LiveAuction/constants';

interface AwardProps {
  onClose: () => void;
  success: boolean;
  bidder?: Bidder;
  handleAward: () => void;
}

const AwardModal = ({ onClose, handleAward, success, bidder }: AwardProps) => {
  return (
    <div className="w-[452px] h-[401px] flex-col gap-y-2 rounded-2xl flex justify-center items-center text-center bg-white">
      <span>
        <img src={awardimg} alt="awardimg" />
      </span>
      <div className="text-blue font-bold mt-1">
        {success ? AUCTION.Hurray : AUCTION.Award}
      </div>
      <div className="text-blue font-bold text-[20px] px-5">
        {success
          ? AUCTION.SuccessMessage
          : `${AUCTION.CurrentBidPrice} ₹ ${bidder?.bidAmount}`}
      </div>
      <div className="text-left rounded-[20px] border p-5 gap-12 border-cgray-4 flex justify-between items-center">
        <div className="flex items-center gap-4 text-cgray-19">
          <div>
            <div className="font-semibold">{bidder?.createdByName}</div>
            <div className="text-sm font-medium">{bidder?.vendorId}</div>
          </div>
        </div>
        <div>
          <div className="text-cgray-1 font-normal text-xs">
            {AUCTION.CompanyName}
          </div>
          <div className="text-cgray-18 font-medium text-sm">
            {bidder?.vendorName}
          </div>
        </div>
      </div>
      {success ? (
        ''
      ) : (
        <div className="flex justify-center items-center gap-10 mt-4">
          <Button
            onClick={onClose}
            variant={'outlined'}
            className="border w-[129.5px] border-blue text-blue h-12 normal-case rounded hover:bg-white"
          >
            {AUCTION.No}
          </Button>
          <Button
            onClick={handleAward}
            className="bg-blue w-[129.5px] h-12 normal-case text-white rounded hover:bg-blueHover"
          >
            {AUCTION.Yes}
          </Button>
        </div>
      )}
    </div>
  );
};

export default AwardModal;
