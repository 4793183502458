import React, { useEffect, useMemo, useState } from "react";
import LoginWithMobileTemplate from "../../templates/Authentication/LoginWithMobile.template";
import LoginWithEmailTemplate from "../../templates/Authentication/LoginWithEmail.template";
import AuthenticationTemplate from "../../templates/Authentication/Authentication.template";
import { AUTH_STATES } from "../../../utils/types";
import SignUpWithMobileTemplate from "../../templates/Authentication/SignUpWithMobile.template";
import UserDetailsFormTemplate from "../../templates/Authentication/UserDetailsForm.template";
import OtpVerificationTemplate from "../../templates/Authentication/OtpVerification.template";
import { useAuthService } from "../../../services/useAuthService";
import { createUseStyles, useTheme } from "react-jss";
import { useSearchParams } from "react-router-dom";
import { AUTH_PARAMS } from "../../../utils/constant";

const useStyles = createUseStyles((theme: any) => ({
    errorMessage: {
        color: theme.palette.action.danger
    }
}));

const Authentication: React.FC = () => {
    const [authState, setAuthState] = useState<AUTH_STATES>(AUTH_STATES.LOGIN_WITH_EMAIL);
    const [mobileNumber, setMobileNumber] = useState("");
    const authService = useAuthService();
    const theme = useTheme<Jss.Theme>();
    const styles = useStyles({ theme });
    const [searchParams] = useSearchParams();

    const [otpVerificationProps, setOtpVerificationProps] = useState<({ otpVerifier: (verificationInfo: any) => Promise<any>, onBack: () => void })>({
        otpVerifier: authService.login,
        onBack: () => setAuthState(AUTH_STATES.LOGIN_WITH_MOBILE)
    });

    useEffect(()=>{
        if (searchParams.get('key') === AUTH_PARAMS.KEY) {
            setAuthState(AUTH_STATES.USER_SIGNUP)
        }
    },[])

    useEffect(() => {
        if (authState === AUTH_STATES.LOGIN_WITH_MOBILE || authState === AUTH_STATES.USER_SIGNUP) {
            setMobileNumber("");
            setOtpVerificationProps({
                otpVerifier: authState === AUTH_STATES.LOGIN_WITH_MOBILE ? authService.login : authService.signUp,
                onBack: () => setAuthState(authState)
            });
        }
    }, [ authState ]);

    const authStateView = useMemo(() => {
        switch (authState) {
            case AUTH_STATES.LOGIN_WITH_MOBILE: return <LoginWithMobileTemplate setAuthState={setAuthState} mobileNumber={mobileNumber} setMobileNumber={setMobileNumber} styles={styles} />;
            case AUTH_STATES.USER_SIGNUP: return <SignUpWithMobileTemplate setAuthState={setAuthState} mobileNumber={mobileNumber} setMobileNumber={setMobileNumber} />;
            case AUTH_STATES.OTP_VERIFICATION: return <OtpVerificationTemplate setAuthState={setAuthState} mobileNumber={mobileNumber} {...otpVerificationProps} />;
            case AUTH_STATES.USER_FORM: return <UserDetailsFormTemplate setAuthState={setAuthState} />;
            default: return <LoginWithEmailTemplate setAuthState={setAuthState} styles={styles} />;
        }
    }, [ authState, setAuthState, setMobileNumber, mobileNumber, otpVerificationProps, styles ]);

    return (
        <AuthenticationTemplate>
            {authStateView}
        </AuthenticationTemplate>
    );
}

export default Authentication;