import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export const INVENTORY_URLS = {
  GET_ALL_INVENTORY: "/inventories",
  GET_INVENTORY: "/inventory/:id",
  CREATE_INVENTORY: "/inventory",
  UPDATE_INVENTORY: "/inventory/:id",
  GET_INVENTORY_BY_UPC_ID: "product-upc/:upcId/inventory",
};

export const WAREHOUSE_URLS = {
  GET_ALL_WAREOUSE: "/warehouses",
  GET_WAREOUSE: "/warehouse/:id",
};

interface IInventoryFilterRequest{
  upcClassType: string;
  warehouse?: string;
  upcCode?: string;
  page: number;
  size: number;
  sort?: string;
}

interface IWarehouseFilterRequest{
  search: string;
  page?: number;
  size?: number;
  sort?: string;
}

interface IWarehouseRequest{
  type: string;
  search: string;
  page?: number;
  size?: number;
  sort?: string;
}

export interface IInvetoryRequest{
  upcId: number;
  basePrice: number;
  netWeight: number;
  grossWeight: number;
  uom: string;
  warehouseId: number;
  warehousePlacement: string;
}

export const useInventoryService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();
  const BASE_URL_V2 = process.env.REACT_APP_API_V2_URL;

  const getInventoryById = (id: number) => {
    return request(API_METHOD.GET, replaceUrlParams( INVENTORY_URLS.GET_INVENTORY, { id } ), authenticatedUser);
  };

  const getAllInventories = (params: IInventoryFilterRequest) => {
    return request(API_METHOD.GET, INVENTORY_URLS.GET_ALL_INVENTORY, authenticatedUser, null, { params } );
  };

  const createInventory = (data: IInvetoryRequest) => {
    return request(API_METHOD.POST, INVENTORY_URLS.CREATE_INVENTORY, authenticatedUser, data);
  };

  const updateInventory = (id: number, data: IInvetoryRequest) => {
    return request(API_METHOD.PUT, replaceUrlParams( INVENTORY_URLS.UPDATE_INVENTORY, { id } ), authenticatedUser, data);
  };

  const getInventoryByUpcId = (upcId: number) => {
    return request(API_METHOD.GET, replaceUrlParams(INVENTORY_URLS.GET_INVENTORY_BY_UPC_ID, { upcId } ), authenticatedUser );
  };

  const getAllWarehouses = (params: IWarehouseFilterRequest) => {
    return request(API_METHOD.GET,  WAREHOUSE_URLS.GET_ALL_WAREOUSE, authenticatedUser, null, { params } );
  };

  const getWarehouses = (params: IWarehouseRequest) => {
    return request(API_METHOD.GET,  WAREHOUSE_URLS.GET_ALL_WAREOUSE, authenticatedUser, null, { params }, null, BASE_URL_V2 );
  };

  const getWarehouseById = (id: number) => { 
    return request(API_METHOD.GET, replaceUrlParams( WAREHOUSE_URLS.GET_WAREOUSE, { id } ), authenticatedUser);
  };
  
  return {
    getWarehouseById,
    getAllWarehouses,
    getAllInventories,
    getInventoryById,
    createInventory,
    updateInventory,
    getWarehouses,
    getInventoryByUpcId,
  };
};
