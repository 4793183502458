import { ProductAttributeFields } from '../../../Constants/Procurement/Indent/constants';
import { Attribute } from '../../../Constants/Procurement/Indent/types';

interface PRspecificationTabsProps {
  attributeMap: Attribute[];
}

const ProductAttributeList: React.FC<PRspecificationTabsProps> = ({
  attributeMap,
}) => {
  const getValue = (propertyName: string) => {
    return (
      attributeMap?.find((spec: Attribute) => spec.name === propertyName)
        ?.attributeOptions || ''
    );
  };
  return (
    <>
      {ProductAttributeFields.map(({ name, property }, index) => {
        return (
          <div className="flex flex-col gap-1 items-start" key={index}>
            <div className=" text-xs text-cgray-1 ">{name}</div>
            <div className=" text-sm font-medium text-text-color ">
              {getValue(property) || '--'}
            </div>
          </div>
        );
      })}
    </>
  );
};
export default ProductAttributeList;
