import { useState } from 'react';
import PaginationButton from '../../Button/PaginationButton';
import { TextField } from '@mui/material';
import VariantButton from '../../Button/VariantButton';
import TableEntries from './TableEntries';

interface CustomTableProps<T> {
  headings: any[];
  totalPages: number;
  fields: any[];
  currentPage: number;
  totalRecords: number;
  itemList: T[];
  paginationText?: string;
  className?: string;
  pagination?: true | false;
  id?: string;
  setPageSize?: (event: any) => void;
  setCurrentPage: (page: number) => void;
  handleView?: (event: any) => void;
}

const CustomTable = <T,>(props: CustomTableProps<T>) => {
  const {
    headings,
    totalPages,
    currentPage,
    totalRecords,
    itemList,
    paginationText,
    setCurrentPage,
    fields,
  } = props;
  const [page, setPage] = useState((currentPage + 1).toString());

  return (
    <div className="grid  gap-y-3 mt-1.5 m-auto w-full">
      <div>
        <TableEntries
          itemList={itemList}
          headings={headings}
          fields={fields}
          currentPage={currentPage}
        />
        <div className="shadow-pagination px-4 py-2 z-50 bg-white mt-2 w-full text-blue flex justify-between items-center rounded-lg">
          <div className="w-full"></div>
          <div className="w-full flex justify-center">
            <PaginationButton
              pageNumber={currentPage}
              setPageNumber={setCurrentPage}
              totalItems={totalRecords}
              totalPages={totalPages}
              text={paginationText}
            />
          </div>
          <div className="flex items-center gap-x-2 w-full justify-end">
            <div className="flex items-center gap-x-2">
              <span>Page</span>
              <div>
                <TextField
                  id="outlined-size-small"
                  name="page"
                  value={page}
                  size="small"
                  type="number"
                  onChange={({ target }) => setPage(target.value)}
                  className="bg-white border w-[51px] border-gray rounded focus:outline-blue"
                />
              </div>
            </div>
            <div className="text-sm">
              <VariantButton
                size="small"
                disabled={+page <= 0 || +page > totalPages}
                onClick={() => setCurrentPage(+page - 1)}
              >
                Search
              </VariantButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomTable;
