import React, { useContext, useEffect, useState, useRef } from "react";
import {
  FormControl,
  TextField
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import uploadIcon from "./../../Assets/Icons/uploadIcon.svg";
import { DisplayDocuments } from "../../Shared/Files/FileItem";
import { FilesWrapper } from "../../Shared/Files/DisplayFiles";
import UpcDetailItem from "./UpcDetailsDisplay";
import CatalogueEnum from "./CatalogueEnum";
import FindUpcSearchComponent from "./FindUpcSearch";
import Typography from '@mui/material/Typography';
import CataloguePackingTypes from "./CataloguePackingTypes";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { makeRoute } from "../../../utils/helper";
import { CATALOGUE_TYPES, CLASS_TYPE,ADMIN_ROUTES } from "./../../../utils/constant";

const BaseUrl = process.env.REACT_APP_API_V1_URL;

const UploadProductImages = ({ updateImages, currentImageCount = 0, maxImageCount }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);

  const handleFileSelect = (event) => {
    if (!event.target.files) return;
    const newFiles = Array.from(event.target.files);
    setSelectedFiles(newFiles);
    if (newFiles.length + currentImageCount > maxImageCount) {
      alert(`Maximum of ${maxImageCount} images allowed`);
      return;
    }
    updateImages(newFiles);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
  }
  };

  const handleDropDragOver = (event) => {
    event.preventDefault();
    const newFiles = Array.from(event.dataTransfer.files);
    setSelectedFiles(newFiles);
    if (newFiles.length + currentImageCount <= maxImageCount) {
      updateImages(newFiles);
    }
    else {
      alert(`Maximum of ${maxImageCount} images allowed`);
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
  }
  };

  return (
    <div className="relative w-full flex flex-col gap-3 text-left text-text-color font-roboto my-2">
      <div className="relative w-full flex flex-row items-center justify-start gap-2 text-left text-2xl text-blue font-roboto">
        <div className="font-semibold text-base md:text-lg lg:text-xl">Upload Product Images* </div>
        <Typography style={{
          color: '#A9A5A5',
          fontSize: '18px',
          margin: '0px'
        }}>{`(Maximum-${maxImageCount})`}</Typography>
      </div>

      <div
        className="relative w-full h-[16.13rem] border-[2px] border-dashed border-gainsboro flex items-center justify-center"
        onDrop={handleDropDragOver}
        onDragOver={handleDropDragOver}
      >
        <div className="flex flex-col items-center gap-4">
          <div className="flex flex-col items-center w-[10.56rem] h-[4.94rem]">
            <img
              className="w-[2.25rem] h-[2.25rem] overflow-hidden"
              alt=""
              src={uploadIcon}
            />
            <div className="mt-3 text-[0.8rem] tracking-[0.3px] font-medium text-text-color">
              Drag your files here - there
            </div>
          </div>
          <div className="text-[1rem] tracking-[0.3px]">or</div>

          <div className="flex flex-col items-center rounded bg-blue flex items-center justify-center text-[0.94rem] text-white font-semibold">
            <input
              type="file"
              multiple
              className="hidden "
              onChange={handleFileSelect}
              id="fileInput"
              ref={fileInputRef}
            />
            <label htmlFor="fileInput" className="cursor-pointer  py-[0.81rem] px-[1.63rem]">
              Browse
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

const UPCDisplayDetails = ({
  catalogueUpcDetails,
  updateUPCDetails,
  formErrors,
  updateUPCErrors,
  type
}) => {

  useEffect(() => {
  }, []);


  const handleMarginChange = (id, key, value) => {
    const floatRegex = key === 'profitMargin' ? /^(\d{1,3}(\.\d{0,2})?|\.\d{1,2})$/ : /^(\d*\.?\d{0,3})?$/;
    if (floatRegex.test(value) || value === '') {
      const floatValue = value;
      const updatedErrors = { ...formErrors };

      const updatedDetails = catalogueUpcDetails.map((detail, index) =>
        index === id ? { ...detail, [key]: floatValue } : detail
      );
      if (floatValue === '') {
        updatedErrors[id] = { [key]: true };
      } else {
        updatedErrors[id] = { [key]: false };
      }
      updateUPCErrors(updatedErrors);
      updateUPCDetails(updatedDetails);
    }
  };
  const handleMoqChange = (id, key, value) => {
    const floatRegex =/^(\d{0,3}(\.\d{0,3})?)$/;
    if (floatRegex.test(value) || value === '') {

      const floatValue = value;
      const updatedErrors = { ...formErrors };

      const updatedDetails = catalogueUpcDetails.map((detail, index) =>
        index === id ? { ...detail, [key]: floatValue } : detail
      );
      if (floatValue === '') {
        updatedErrors[id] = { [key]: true };
      } else {
        updatedErrors[id] = { [key]: false };
      }

      updateUPCErrors(updatedErrors);
      updateUPCDetails(updatedDetails);
    }

  };


  const handleStatusChange = (index, value) => {
    const updatedDetails = [...catalogueUpcDetails];
    updatedDetails[index] = { ...updatedDetails[index], status: value };
    updateUPCDetails(updatedDetails);
  };

  const handleRemoveUPCDetails = (id) => {
    const updatedDetails = catalogueUpcDetails.filter((detail, index) => index !== id);
    updateUPCDetails(updatedDetails);
  };


  return (
    <>
      {catalogueUpcDetails &&
        catalogueUpcDetails.map((upcDetail, index) => (

          <UpcDetailItem
            mode={"EDIT"}
            index={index}
            upcDetail={upcDetail}
            priceDetails={upcDetail.priceRange}
            formErrors={formErrors}
            handleRemoveUPCDetails={handleRemoveUPCDetails}
            handleStatusChange={handleStatusChange}
            handleMarginChange={handleMarginChange}
            handleMoqChange={handleMoqChange}
            type={type}
          />

        ))}
    </>
  );
};

const AddCatalogue = () => {
  const { user } = useAuthenticatedUser();
  const errorMessageColor = '#D32F2F'
  // Form Data
  const [formData, setFormData] = useState({
    catalogueTitle: "",
    catalogueDescription: "",
    upcList: [],
    upcSearch: "",
  });
  const location = useLocation();
  const category = location?.state.category ?? {};
  const catalogueType= location?.state.catalogueType ?? {};
  const [upcErrorState, setErrorState] = useState(false);

  // Form Errors
  const [formErrors, setFormErrors] = useState({
    catalogueTitle: false,
    catalogueDescription: false,

    upcList: {},
  });

  // Images Uploaded
  const [imageData, setImageData] = useState([]);
  const [addCatalogueCategory, setCatalogueCategory] = useState(category || "STANDARD");
  const [selectedPackagingTypes, setSelectedPackingTypes] = useState([]);
  const [packingTypes, setPackingTypes] = useState([]);

  const getPackagingTypes = (ConfigurationData) => {
    try {
      const packagingData = ConfigurationData[0]?.data;
      return packagingData;
    } catch {
      return []
    }
  }

  const fetchConfiguration = () => {
    const baseUrl = `${BaseUrl}/configurations/`;
    axios.get(baseUrl,
      {
        params: {
          contexts: "PACKAGING"
        },
        headers: {
          Authorization: `${user.token}`,
          "Content-Type": "application/json",
        },
      }
    ).then((response) => {
      if (response.data && response.data.data) {
        const packagingTypeList = getPackagingTypes(response.data.data);
        setPackingTypes(packagingTypeList);
        return;
      }
    })
      .catch((err) => {
        return
      });
  }

  useEffect(() => {
    fetchConfiguration();
  }, []);


  const handleCatalogueChange = (event) => {
    try {
      const { name, value } = event.target;
      let trimmedValue = value;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: trimmedValue,
      }));
  
      // Validate form fields and set errors
      if (name === "catalogueTitle") {
        setFormErrors((prevFormErrors) => ({
          ...prevFormErrors,
          catalogueTitle: trimmedValue === "",
        }));
      } else if (name === "catalogueDescription") {
        setFormErrors((prevFormErrors) => ({
          ...prevFormErrors,
          catalogueDescription: trimmedValue === "",
        }));
      }
    } catch (error) {
      return 
    }
  };
  const updateUPCDetails = (updatedDetails) => {
    if (updatedDetails.length === 0) {
      setErrorState(true)
    } else {
      setErrorState(false)
    }
    if (Array.isArray(updatedDetails) && updatedDetails.length > 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        upcList: updatedDetails,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        upcList: [],
      }));
    }
  };


  const updateUPCErrors = (updatedErrors) => {
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      upcList: updatedErrors,
    }));
  };

  const handleRemoveImage = (id, isForm) => {
    const updatedDetails = imageData.filter(
      (detail, currentIndex) => currentIndex !== id
    );
    setImageData(updatedDetails);
  };

  const updateImages = (images) => {
    const invalidImages = [];
    images?.forEach((image) => {
      if (image.type === 'image/webp') {
        invalidImages.push(image.name);
      } else {
        setImageData((prevImages) => [...prevImages, image]);
      }
    });
    if (invalidImages.length > 0) {
      const invalidImageNames = invalidImages.join(', ');
      alert(`${invalidImageNames} files are of type 'webp' which is not allowed. Please select PNG or JPEG files.`);
    }
  };

  const navigate = useNavigate();

  const navigateToCatalogueTable = () => {
    navigate(makeRoute(ADMIN_ROUTES.CATALOGUE, {query: { "catalogueType": CATALOGUE_TYPES[catalogueType], "category": CLASS_TYPE[category] } }));
  };


  const convertUPCDetails = () => {
    var catalogueUPCMappingDetails = [];
    for (const key in formData.upcList) {
      const upcItemDetails = formData.upcList[key];
      catalogueUPCMappingDetails.push({
        upcId: upcItemDetails.upcDetails.id,
        upcPrice: upcItemDetails.upcPrice === '' ? 0 : parseFloat(upcItemDetails.upcPrice),
        minimumSellingPrice: upcItemDetails.minimumSellingPrice === '' ? 0 : parseFloat(upcItemDetails.minimumSellingPrice),
        minimumOrderQuantity: upcItemDetails.minimumOrderQuantity === '' ? 0 : parseFloat(upcItemDetails.minimumOrderQuantity),
        profitMargin: upcItemDetails.profitMargin === '' ? 0 : upcItemDetails.profitMargin,
        status: upcItemDetails.status,
      });
    }
    return catalogueUPCMappingDetails;
  };

  const convertPackagingTypeDetails = () => {
    var packaginTypeDetails = [];
    for (const packagingTypeIndex in selectedPackagingTypes) {
      packaginTypeDetails.push(selectedPackagingTypes[packagingTypeIndex]?.value)
    }
    return packaginTypeDetails;
  };

  const handleCatalogueImageData = async (
    imageData,
    resourceId,
    resourceType,
    createdBy
  ) => {

    if (!resourceId || !resourceType) {
      return false;
    }

    const formData = new FormData();

    for (let i = 0; i < imageData.length; i++) {
      formData.append(`files`, imageData[i]);
    }

    const uploadFilesData = {
      resourceType: resourceType,
      resourceId: resourceId,
      createdBy: createdBy,
    };

    formData.append(
      "resource",
      new Blob([JSON.stringify(uploadFilesData)], {
        type: "application/json",
      })
    );

    try {
      await axios
        .post(`${BaseUrl}/files/`, formData, {
          headers: {
            Authorization: `${user.token}`
          },
        })
        .then((data) => {
          return true;
        });
    } catch (error) {
      return false;
    }
  };


  // const validateUpcDetails = (catalogueData) => {
  //   const upcDataList = catalogueData?.upcList
  //   const initialUpcDetails = upcDataList[0].upcDetails;
  //   const areUpcsEqual = upcDataList.every(item => { return areObjectsEqual(item.upcDetails, initialUpcDetails) });

  //   if (!areUpcsEqual) {
  //     alert("Please Select All the Products of same Category.");
  //     return true
  //   }
  //   return false
  // }


  const validateUpcDetails = (catalogueData) => {
    const upcDataList = catalogueData?.upcList;
 
    if (!upcDataList || upcDataList.length === 0) {
      (category === 'NONSTANDARD' ? alert("No MPC data found.") : alert("No UPC data found."));
      return false;
    }
    return false;
  }


  const identifyFieldsErrors = () => {
    let flag = false;
    const errors = {};

    const fieldsToValidate = [
      "catalogueTitle",
      "catalogueDescription",
      "upcList",
    ];

    if (selectedPackagingTypes.length === 0) {
      errors['packagingType'] = true
      flag = true;
    }

    fieldsToValidate.forEach((fieldValue) => {
      if (fieldValue !== "upcList" && formData[fieldValue] !== undefined && formData[fieldValue].trim().length === 0) {
        errors[fieldValue] = true;
        flag = true;
      } else if (fieldValue === "upcList" && Array.isArray(formData[fieldValue])) {
        const upcListErrors = formData[fieldValue].map((upc) => {
          const upcErrors = {};
          if (upc.upcPrice === '' || parseFloat(upc.upcPrice)===0 ) {
            upcErrors.upcPrice = true;
            flag = true;
          }
          if (upc.minimumSellingPrice === '' || parseFloat(upc.minimumSellingPrice)===0 ) {
            upcErrors.minimumSellingPrice = true;
            flag = true;
          }
          if (upc.minimumOrderQuantity === '' || parseFloat(upc.minimumOrderQuantity)===0 ) {
            upcErrors.minimumOrderQuantity = true;
            flag = true;
          }
          return upcErrors;
        });
        errors[fieldValue] = upcListErrors;
      }
    });

    if (flag) {
      setFormErrors(errors);
    }

    return flag;
  };

  const handleCreateNewCatalogue = async () => {
    if (validateUpcDetails(formData)) {
      return
    }
    if (identifyFieldsErrors()) {
      return;
    }
    if (formData.upcList.length === 0) {
      setErrorState(true);
      return;
    }

    const isImagePresent = imageData.length > 0 ; 
    // if (!isImagePresent) {
    //   alert("Please upload image for the catalogue.");
    //   return;
    // }


    const createCatalogueDetails = {
      name: formData.catalogueTitle?.trim(),
      description: formData.catalogueDescription?.trim(),
      createdBy: 101,
      updatedBy: 101,
      status: "PUBLISHED",
      category: addCatalogueCategory,
      upcMappings: convertUPCDetails(),
      packagingTypes: convertPackagingTypeDetails(),
      catalogueType: catalogueType
    };

    try {
      const response = await axios.post(
        `${BaseUrl}/catalogue/`,
        createCatalogueDetails,
        {
          headers: {
            Authorization: `${user.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response) {
        if (response.status === 200) {
          // Upload the Images
          var resourceId = response.data.data.id;
          var resourceType = "CATALOGUE";
          var createdBy = response.data.data.createdBy;

          if (
            handleCatalogueImageData(
              imageData,
              resourceId,
              resourceType,
              createdBy
            )
          ) {
            navigateToCatalogueTable();
            return;
          }
        } 
      }
      return
    } catch (error) {
      if (error.response.status === 400) {
        alert("Catalogue cannot be Created - Products exists in other Catalogue");
      } else if (error.response.status === 500) {
        alert("Catalogue already exists or something went wrong. Please try again");
      } else {
        alert("Error in Creating the Catalogue");
      }
      return
    }
  };

  return (
    <div className="relative w-full flex flex-col items-start justify-start text-left text-[1.25rem] text-blue font-input-label">
      <div className="self-stretch rounded flex flex-col items-start justify-start">
        <div className="self-stretch flex flex-col items-end justify-start">
          <div className="self-stretch rounded bg-white shadow-[0px_1px_4px_rgba(0,_0,_0,_0.12)] flex flex-col py-[1.5rem] px-[2.5rem] items-start justify-start gap-[0.63rem]">
            <div className="self-stretch flex flex-col items-start justify-start">
              <div className="self-stretch flex flex-col items-start justify-start gap-[1.38rem]">
                <div className="relative font-semibold">Add {CatalogueEnum[addCatalogueCategory]} Catalogue</div>
                <div className="self-stretch flex flex-row items-start justify-start text-[1rem] text-text-color">
                  <div className="flex-1 flex flex-row flex-wrap items-start justify-start">
                    <div className="flex-1 flex flex-col items-start justify-start gap-[0.75rem]">
                      {/* CATALOGUE TITLE  */}

                      <TextField
                        className={`block w-full border  ${formErrors.catalogueTitle
                          ? "border-red-500"
                          : "border-gray-300"
                          } rounded-md focus:outline-none ${formErrors.catalogueTitle
                            ? "ring-red-500"
                            : "ring-blue-500"
                          } focus:border-blue-500`}
                        id="outlined-basic"
                        label="Enter Title"
                        variant="outlined"
                        fullWidth
                        name="catalogueTitle"
                        value={formData.catalogueTitle}
                        onChange={handleCatalogueChange}
                        error={formErrors.catalogueTitle}
                        required
                        inputProps={{
                          maxLength: 250
                        }}
                      />

                      <TextField
                        id="outlined-textarea"
                        label="Description"
                        placeholder="Write Description about the Catalogue ..."
                        multiline
                        fullWidth
                        rows={4}
                        variant="outlined"
                        value={formData.catalogueDescription}
                        name="catalogueDescription"
                        onChange={handleCatalogueChange}
                        error={formErrors.catalogueDescription}
                        inputProps={{
                          maxLength: 65000,
                          classes: {
                            root: "h-[7.7rem] p-2",
                            notchedOutline: "border-0",
                            focused: "focus:ring-blue-500",
                          },
                          disableUnderline: true,
                        }}
                        required
                      />

                      <FormControl fullWidth>
                        {formErrors?.packagingType && selectedPackagingTypes.length === 0 && (<div style={{ color: errorMessageColor }}>{"Please Select Packaging type"}</div>)}
                        <CataloguePackingTypes packingTypesData={packingTypes} formErrors={formErrors} initialValues={selectedPackagingTypes} updateFunction={setSelectedPackingTypes} mode={"ADD"} />
                      </FormControl>

                      {/* Display All UPC Codes */}
                      <UPCDisplayDetails
                        catalogueUpcDetails={formData.upcList}
                        updateUPCDetails={updateUPCDetails}
                        updateUPCErrors={updateUPCErrors}
                        formErrors={formErrors.upcList}
                        type={addCatalogueCategory}
                      />


                      {/* Display validation errors */}
                      {upcErrorState && (
                        <div style={{ color: errorMessageColor }}>{ category === 'NONSTANDARD' ? "Please Add Master Product Code" : "Please Add Universal Product Code" }</div>
                      )}

                      <FindUpcSearchComponent
                        type={addCatalogueCategory}
                        catalogueId={null}
                        catalogueUpcDetails={formData.upcList}
                        updateUPCDetails={updateUPCDetails}
                        isCheckBoxView={true}
                        catalogueType={catalogueType}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Display Uploaded Files */}

           { imageData.length!==0 && <FilesWrapper heading={"Product Images"}>
              <DisplayDocuments
                documentsData={imageData}
                isEdit={true}
                isForm={true}
                deleteDocument={handleRemoveImage}
              />
            </FilesWrapper>}

            {/* Upload Image Section */}
            <UploadProductImages updateImages={updateImages} currentImageCount={imageData.length} maxImageCount={4}/>

            <div className='flex justify-end space-x-4 mr-9 mt-2'>

              <button
                className='rounded bg-white text-gray-700 border border-gray-300  overflow-hidden flex flex-row py-[0.81rem] px-[1.63rem] items-center justify-center text-[0.94rem]'
                onClick={navigateToCatalogueTable}
              >
                <div className='relative leading-[1.38rem] font-semibold'>
                  Cancel
                </div>
              </button>

              <button
                className="outlined rounded bg-blue overflow-hidden flex flex-row py-[0.81rem] px-[1.63rem] items-center justify-center text-[0.94rem] text-white"
                onClick={handleCreateNewCatalogue}
                type="submit"
              >
                Add Catalogue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCatalogue;