import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { createUseStyles } from "react-jss";
import { Grid } from "@mui/material";
import { Charges, Quotation } from "../../pages/Lead/QuotationView.page";
import { useUpcService } from "../../../services/useUpcService";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import PaymentInfoModalTemplate from "./PaymentInfoModal.template";
import { useSecondaryUpcService } from "../../../services/useSecondaryUpcService";
import ProductDetailTemplate from "./ProductDetails.template";
import { CLASS_TYPE } from "../../../utils/types";
import { CATALOGUE_TYPES, LEAD_CATALOGUE_TYPES } from "../../../utils/constant";
import { getKeyByValue } from "../../../utils/helper";

interface ViewQuotationProps {
	quotationItem: Quotation | null;
	charges: Charges | null;
	classType: CLASS_TYPE;
}

const useStyles = createUseStyles((theme: any) => ({
	container: {
		background: theme.palette.background.secondaryLight
	},
	productDetailContainer: {
		border: `1px solid ${ theme.palette.background.primaryGrey }`,
		boxShadow: `0px 4px 45px 1px ${theme.palette.boxShadow.primaryLight}`,
	},
	paymentContainer: {
		boxShadow: `0px 1px 4px 0px ${theme.palette.boxShadow.primaryDarkAccent}`,
		text: theme.palette.text.darkGrey,
	},
	mainHeading: {
		color: theme.palette.text.primaryDarkLight,
	}
}));

const QuotationViewTemplate: React.FC<ViewQuotationProps> = ({ quotationItem, charges, classType}) => {
	const classes = useStyles();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedQuotation, setSelectedQuotation] = useState<number | null>(null);
	const handleOpenModal = (quotationId: number) => {
		setSelectedQuotation(quotationId);
		setIsModalOpen(true);
	};

	const handleCloseModal = () => {
		setSelectedQuotation(null);
		setIsModalOpen(false);
	};
	const upcService = useUpcService();
	const secondaryUpcService = useSecondaryUpcService();
	const [productUpcData, setProductUpcData] = useState<any[]>([]);

	const fetchSingleProductUpc = async (upcId: number) => {
		try {
			const params = {
				upcId: upcId,
			};
			const response = await upcService.searchUpc(params);
			if (response && response.status === 200 && response.data && response.data.data) {
				const newData = response.data.data;
				return newData;
			} else {
				console.error("Failed to fetch product upc data:", response);
			}
		} catch (error) {
			throw error;
		}
	};

	const fetchAllProductUpcs = async (upcIds: number[]) => {
		const upcFetchDetails = [];
		if (upcIds && Array.isArray(upcIds) && upcIds.length > 0 && productUpcData.length === 0) {
			for (const x of upcIds) {
				let upcDetails = await fetchSingleProductUpc(x);
				upcFetchDetails.push(upcDetails);
			}
			setProductUpcData(upcFetchDetails);
		}
	};

	const fetchSingleSecondaryProduct = async (skuId: number) => {
		try {
			const catalogueClass = classType === LEAD_CATALOGUE_TYPES.MAKE_TO_ORDER ? LEAD_CATALOGUE_TYPES.MAKE_TO_ORDER : getKeyByValue(CATALOGUE_TYPES,CATALOGUE_TYPES.CURRENT_INVENTORY);
			const response = await secondaryUpcService.getSecondaryProductUpc(skuId,{classType: catalogueClass});
			if (response?.status === 200 && response?.data?.data) {
				return response?.data?.data;
			} else {
				console.error("Failed to fetch secondary product upc data:", response);
			}
		} catch (error) {
			setProductUpcData([])
		}
	};

	const fetchAllSecondaryProduct = async (skuIds: number[]) => {
		const skuFetchDetails = [];
		if (skuIds && Array.isArray(skuIds) && skuIds.length > 0 && productUpcData.length === 0) {
			for (const skuId of skuIds) {
				let skuDetails = await fetchSingleSecondaryProduct(skuId);
				skuFetchDetails.push(skuDetails);
			}
			setProductUpcData(skuFetchDetails);
		}
	};

	useEffect(() => {
		if (quotationItem?.quotationUpcResponseDTO.length) {
			const upcIds = [];
			const skuIds = [];
			for (let quotation of quotationItem?.quotationUpcResponseDTO) {
				upcIds.push(quotation.upcId)
				skuIds.push(quotation.secondarySkuId)
			}
			if(classType === CLASS_TYPE.STANDARD){
				fetchAllProductUpcs(upcIds);
			}else{
				fetchAllSecondaryProduct(skuIds)
			}
		}
	}, [quotationItem?.quotationUpcResponseDTO])

	function calculateProfitLossPercentage(basePrice: number, offerededPrice: number) {
		return (((offerededPrice - basePrice) / basePrice) * 100);
	}
   
	return (
		<div className="grid gap-y-3 ">
			{quotationItem && quotationItem?.quotationUpcResponseDTO &&
				quotationItem?.quotationUpcResponseDTO.map((quotation, index) => (
					<div key={ quotation?.id }>
						<div className={ `${ classes.container } ${ classes.mainHeading } flex justify-between p-3` }>
							<div className="text-xl font-semibold">
							Catalogue Name : {quotation?.catalogueName}							</div>
							<div className="flex justify-between gap-x-6">
								<div className="font-semibold text-base">
									Require Order Quantity : {quotation?.quantity} /MT
								</div>
								
								<RemoveRedEyeIcon className="cursor-pointer" onClick={() => handleOpenModal(quotation.id)} />
								{isModalOpen && selectedQuotation === quotation.id && (
									<PaymentInfoModalTemplate
										open={isModalOpen}
										charges={charges}
										upcSkuEditDetails={quotation.skuData}
										onClose={handleCloseModal}
									/>
								)}			
							</div>
						</div>

						<div className={ `${ classes.productDetailContainer } bg-white p-3 flex justify-between gap-x-3`}>
							{classType === CLASS_TYPE.STANDARD ? (
								<>
									{productUpcData && productUpcData.filter((item) => quotation.upcId === item[0]?.id).map((item, index) => (
										<ProductDetailTemplate
										attributes = { item[0]?.attributes }
										imagePath = { item[0]?.catalogueImages[0]?.path }
										classType = {CLASS_TYPE.STANDARD}
									  />
									))}
								</>
							) : (
								<>
									{productUpcData && productUpcData.filter((item) => quotation.secondarySkuId === item?.id).map((item, index) => (
										<ProductDetailTemplate
										attributes = { item?.attributes }
										imagePath = { item?.catalogueImages[0]?.path }
										classType = {CLASS_TYPE.SECONDARY}
									  />
									))}
								</>
							)}
							<div className="w-3/4 mt-0 m-auto grid gap-y-3">
								{quotation.skuData &&
									quotation.skuData.map((sku, index) => {
										return (
											<div className={ `${ classes.productDetailContainer } grid gap-y-2 p-3 rounded bg-white` } key={ index }>
												<div className={ `flex justify-between gap-x-8 ${ classes.mainHeading }` }>
													<div className="text-base font-medium">
														SKU Code:{" "}
														<span className="font-normal">
															{ sku.skuCode }
														</span>
													</div>
													<div className="text-base font-medium">
														Warehouse:{" "}
														<span className="font-normal">
															{ sku.warehouse }
														</span>
													</div>
													<div className="text-base font-medium">
														BasePrice:{" "}
														<span className="font-normal">
															{ sku.basePrice }
														</span>
													</div>
												</div>
												<div className=" flex justify-between gap-x-2">
													<div className="flex w-1/4  ">
														<div className="w-full">
															<TextField
																required
																type="number"
																name="quantity"
																id="outlined-basic"
																label=" Enter Quantity"
																variant="outlined"
																fullWidth
																className="pointer-events-none"
																value={ sku?.quantity }
															/>
														</div>
														<div className={ `h-full rounded py-3 px-5 text-2xl ${ classes.container }` }>MT</div>
													</div>
													<div className="flex w-1/4 ">
														<div className="w-full">
															<TextField
																required
																type="number"
																name="profit"
																id="outlined-basic"
																label="Price /MT"
																variant="outlined"
																fullWidth
																className="pointer-events-none"
																value={ sku?.price }
															/>
														</div>
														<div className={ `h-full rounded py-3 px-5 text-2xl  ${ classes.container }` }>%</div>
													</div>
													<div className="w-1/4 ">
														<TextField
															required
															name="price"
															id="outlined-basic"
															label="Profit % /MT"
															variant="outlined"
															fullWidth
															className="pointer-events-none"
															value={ calculateProfitLossPercentage(sku?.basePrice, sku?.price).toFixed(2) }
														/>
													</div>
													<div className="w-1/4 ">
														<TextField
															name="packaging"
															id="outlined-basic"
															label="Packaging"
															variant="outlined"
															fullWidth
															className="pointer-events-none"
															value={ sku?.packaging }
														/>
													</div>
												</div>
											</div>
										);
									})}
							</div>
						</div>
					</div>
				))}


			<div className={` ${ classes.paymentContainer } grid rounded p-3 bg-white gap-4` }>
				<div className={` ${ classes.mainHeading } text-2xl font-semibold border-b-2 border-solid pb-3` } >
					Additional Charges
				</div>

				<Grid container spacing={ 2 }>
					<Grid item xs={ 12 } sm={ 6 } md={ 4 }>
						<TextField
							type="number"
							name="warehouseCharges"
							id="outlined-basic"
							label="Warehouse Charges /MT"
							variant="outlined"
							fullWidth
							className="pointer-events-none"
							value={ charges?.warehouseCharges ?? 0 }
						/>
					</Grid>
					<Grid item xs={ 12 } sm={ 6 } md={ 4 }>
						<TextField
							type="number"
							name="handlingCharges"
							id="outlined-basic"
							label="Handling Charges /MT"
							variant="outlined"
							value={ charges?.handlingCharges ?? 0 }
							className="pointer-events-none"
							fullWidth
						/>
					</Grid>
					<Grid item xs={ 12 } sm={ 6 } md={ 4 }>
						<TextField
							type="number"
							name="packagingCharges"
							id="outlined-basic"
							label="Packaging Charges /MT"
							variant="outlined"
							value={ charges?.packagingCharges ?? 0 }
							className="pointer-events-none"
							fullWidth
						/>
					</Grid>
					<Grid item xs={ 12 } sm={ 6 } md={ 4 }>
						<TextField
							type="number"
							name="otherCharges"
							id="outlined-basic"
							label="Other Charges"
							variant="outlined"
							className="pointer-events-none"
							value={ charges?.otherCharges ?? 0 }
							fullWidth
						/>
					</Grid>
					<Grid item xs={ 12 } sm={ 6 } md={ 4 }>
						<TextField
							type="number"
							name="gst"
							id="outlined-basic"
							label="GST %"
							variant="outlined"
							value={ charges?.gst ?? 0 }
							className="pointer-events-none"
							fullWidth
						/>
					</Grid>
					<Grid item xs={ 12 } sm={ 6 } md={ 4 }>
						<TextField
							type="number"
							name="tcs"
							id="outlined-basic"
							label="TCS %"
							variant="outlined"
							value={ charges?.tcs ?? 0 }
							className="pointer-events-none"
							fullWidth
						/>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

export default QuotationViewTemplate;