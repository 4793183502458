import styles from "./CustomButton.module.css";
import React from "react";
import { Button } from "@mui/material";
export interface IButtonProps {
  variant?: "Default" | "Primary" | "Disabled" | "Link";
  children?: React.ReactNode;
  className?: string;
  color?: any;
  id?: string;
  type?: any;
  onClick?: (event: any) => void;
  size?: "small" | "medium" | "large";
  enableArrowForLink?: boolean;
  href?: string;
  textForLink?: string;
  isDisabled?: boolean;
}
export const CustomButton = (props: IButtonProps) => {
  const {
    variant = "Default",
    children,
    className,
    id,
    onClick,
    size = "small",
    enableArrowForLink = "false",
    href,
    textForLink,
    isDisabled = false,
    color,
    type,
  } = props;
  let variantClass: string = "defaultButton";
  if (variant === "Primary") {
    variantClass = "primaryButton";
  } else if (variant === "Link") {
    variantClass = "linkButton";
  }

  return (
    <>
      <button
        id={id}
        color={color}
        className={`${styles["button"]} ${className} ${styles[variantClass]} ${
          styles[size]
        } ${isDisabled ? styles["disabled"] : ""} `}
        onClick={onClick}
        disabled={isDisabled}
        type={type}
      >
        {children}
      </button>
    </>
  );
};

// import React from "react";
// import { Button } from "@mui/material";
// import styles from "./CustomButton.module.css";

// export interface IButtonProps {
//   variant?: "default" | "primary" | "disabled" | "link"; // Adjust the casing to match Material-UI's variants
//   children?: React.ReactNode;
//   className?: string;
//   id?: string;
//   onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
//   size?: "small" | "medium" | "large";
//   enableArrowForLink?: boolean;
//   href?: string;
//   textForLink?: string;
//   isDisabled?: boolean;
// }

// export const CustomButton = (props: IButtonProps) => {
//   const {
//     variant = "default", // Adjust the default value
//     children,
//     className,
//     id,
//     onClick,
//     size = "small",
//     enableArrowForLink = false, // Adjust the default value
//     href,
//     textForLink,
//     isDisabled = false,
//   } = props;

//   let muiVariant: "outlined" | "contained" | undefined = undefined;
//   if (variant === "primary") {
//     muiVariant = "contained";
//   } else if (variant === "link") {
//     muiVariant = "outlined";
//   }

//   return (
//     <Button
//       id={id}
//       className={`${styles["button"]} ${className}`}
//       variant={muiVariant}
//       size={size}
//       disabled={isDisabled}
//       onClick={onClick}
//       href={href}
//     >
//       {children}
//     </Button>
//   );
// };
