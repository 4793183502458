import noData from '../../../assets/images/noDataImage.svg';
import { useState, useMemo } from 'react';
import { getProfileDisplayData } from '../../../utils/helper';
import BusinessProfileNoDataTemplate from './BusinessProfileNoData.template';
import AboutUsUpdateTemplate from './AboutUsUpdate.template';
import { IBusinessProfile } from '../../pages/CustomerManagement/ProfileOverview.page';
import AboutUsViewTemplate from './AboutUsView.template';

interface BussinessProfileAboutUsTemplateProps {
    businessProfile: IBusinessProfile | null;
    updateBusinessProfileSection: (section: string, values: any) => void;
}

const AboutUsTemplate: React.FC<BussinessProfileAboutUsTemplateProps> = ({ businessProfile, updateBusinessProfileSection }) => {
    const [isEditing, setIsEditing] = useState(false);

    const AboutUsSectionView = useMemo(() => {
        if (isEditing) {
            return (
                <AboutUsUpdateTemplate
                    businessProfile={businessProfile}
                    updateBusinessProfileSection={updateBusinessProfileSection}
                    setIsEditing={setIsEditing}
                />
            );
        }
        if (businessProfile?.description) {
            return (
                <AboutUsViewTemplate
                    setIsEditing={setIsEditing}
                    description={getProfileDisplayData(businessProfile?.description)}
                />
            );
        }
        return (
            <BusinessProfileNoDataTemplate
                heading="You haven't provided Description for your business."
                setIsEditing={setIsEditing}
                noDataImg={noData}
                label="Add About Us"
            />
        );
    }, [isEditing, businessProfile, updateBusinessProfileSection]);

    return <>{AboutUsSectionView}</>;
};

export default AboutUsTemplate;
