import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import CloseIcon from "@mui/icons-material/Close";
import FormGroup from "@mui/material/FormGroup";
import Button from '../../atoms/Button';
import { ADMIN_ROUTES, MODE, STATUS, initialPaginationValues } from '../../../utils/constant';
import { IProductUpcCreateRequest } from '../../../services/useUpcService';
import TagInputGroup from '../../molecules/TagInputGroup/TagInputGroup';
import Toggler from '../../atoms/Toggler';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { AttributeSpecification, Category } from '../../../services/useCategoryService';
import { FormControl } from "@mui/material"
import { IBrand } from '../../pages/AdminMaster/Brand/BrandList.page';
import { useBrandService } from '../../../services/useBrandService';
import { CLASS_TYPE, HTTP_STATUS, IPagination } from '../../../utils/types';
import AutoCompleteTextField, { AutoCompleteOption } from '../../molecules/AutoCompleteInput/AutoCompleteInput';
import Chips, { ChipItem } from '../../molecules/Chips/Chips';
import { IProductUPCBrandMappingRequest, IUpcData, useProductUPCBrandMappingService } from '../../../services/useProductUPCBrandMappingService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import CategoryAndManufacturerSearchTemplate from '../UPC/CategorySearch/CategoryAndManufacturerSearch.template';
import StandardSectionIndexTemplate from '../UPC/StandardSearch/StandardSectionIndex.template';
import ProductClassificationSearchTemplate from '../UPC/ProductClassificationSearch/ProductClassificationSearch.template';
import ProductSpecificationSectionIndexTemplate from '../UPC/ProductSpecificationSearch/ProductSpecificationSectionIndex.template';
import UPCMappingTemplate from '../UPC/UPCMapping.template';
import { IMpc } from '../../pages/MPC/MpcUpdate.page';

interface IMpcFormProps {
	onMpcSubmit: (upcRequestBody: any) => void;
	mpc: IMpc | null;
	mode: string;
}

export interface AttributeDetails {
	SPECIFICATION: AttributeSpecification[];
	GENERALIZATION: AttributeSpecification[];
}

interface IMpcForm {
	upcCode: string
	status: boolean;
	classType: string;
	attributes: {
		SPECIFICATION: any[];
		CLASSIFICATION: any[];
		GENERALIZATION: any[];
	};
	shape: string;
	categoryId: number | null;
	grade: any;
	gradeId: number | null;
	keywords: string[];
	productCode: string;
	title: string;
	productId: any;
	standard: {
		label: string;
		id: number | null;
	};
	superCategory: {
		label: string;
		id: number | null;
	};
	mainCategory: {
		label: string;
		id: number | null;
	};
	productCategory: {
		label: string;
		id: number | null;
	};
	brandOptions?: AutoCompleteOption[] | null;
	brandIds: number[];
}

const useStyles = createUseStyles((theme: any) => ({
	textColor: {
		color: theme.palette.text.primaryDarkLight
	},
	errorMessage: {
		color: theme.palette.action.danger,
	},
	subContainer: {
		backgroundColor: theme.palette.background.primaryLight,
	},
	heading: {
		color: theme.palette.text.primaryDarkLight,
	},
	container: {
		backgroundColor: theme.palette.background.primaryLight,
	},
}));

const MpcFormTemplate: React.FC<IMpcFormProps> = ({ mpc, onMpcSubmit, mode }) => {

	const navigate = useNavigate();
	const classes = useStyles();
	const brandService = useBrandService();
	const upcBrandMappingService = useProductUPCBrandMappingService();
	const params = useParams();
	const mpcId = Number(params.id);

	const [attributes, setAttributesTo] = useState<any>([]);
	const [brands, setBrandsTo] = useState<IBrand[]>([])
	const [brandOptions, setBrandOptionsTo] = useState<AutoCompleteOption[] | []>([]);
	const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);
	const [upcBrandMapping, setUpcBrandMappingTo] = useState<IUpcData[]>([]);
	const { showSnackbar, SnackBarComponent } = useSnackbar();

	const validationSchema = Yup.object().shape({
		superCategory: Yup.object().shape({
			id: Yup.number().required('Super Category is required'),
			label: Yup.string().required('Super Category is required')
		}).required('Super Category is required'),
		mainCategory: Yup.object().shape({
			id: Yup.number().required('Main Category is required'),
			label: Yup.string().required('Main Category is required')
		}).required('Main Category is required'),
		productCategory: Yup.object().shape({
			id: Yup.number().required('Product Category is required'),
			label: Yup.string().required('Product Category is required')
		}).required('Product Category is required'),
		standard: Yup.object().shape({
			id: Yup.number().required('standard is required'),
			label: Yup.string().required('standard is required')
		}).required('standard is required'),
		gradeId: Yup.number().nullable().required('Grade is required'),
		shape: Yup.string().required('Shape is required'),
		classType: Yup.string().required('Class is required'),
		attributes: Yup.object().shape({
			SPECIFICATION: Yup.array().min(mode === MODE.ADD && attributes?.SPECIFICATION?.length, 'All the Product Specification is required').required("Specifications are required"),
			CLASSIFICATION: Yup.array(),
			GENERALIZATION: Yup.array(),
		}).required("Attributes are required"),
		keywords: Yup.array().of(Yup.string()).min(1, 'At least one keyword is required').required('Keywords are required'),
		status: Yup.boolean().required("Status is required"),
		brandIds: Yup.array().of(Yup.number()).required('Brand IDs must be an array of numbers')
	});

	const handleSpecification = (productCategory: Category) => {
		if (productCategory) {
			handleUpdateMPCFormDetails('productId', productCategory.id);
			const attributeType = productCategory?.attributeDetails;
			if (attributeType) {
				Object.entries(attributeType).forEach(([key, value]) => {
					attributeType[key as keyof AttributeDetails].forEach((attribute: any) => {
						try {
							attribute['attributeOptions'] = JSON.parse(attribute['attributeOptions']);
						} catch (error) {
							console.error('Error parsing attributeOptions:', error);
							attribute['attributeOptions'] = [];
						}
					});
				});
			}
			setAttributesTo(attributeType);
			formik.setFieldValue('categoryId', productCategory?.id);
		}
	};

	const navigateToMPCTable = () => {
		navigate(-1);
	};

	const handleUpdateMPCFormDetails = (key: any, payload: any) => {
		formik.setFieldValue(key, payload);
	};

	const handleUpdateMPCAttributesFormDetails = (key: any, payload: any) => {
		formik.setFieldValue(`attributes.${key}`, payload);
	};

	const loadBrands = (inputValue?: string) => {
		if (formik?.values?.productCategory?.id) {
			brandService.getAllBrands({ page: 0, size: 10, sort: 'createdAt,desc', name: inputValue ?? '', manufacturerId: '', productCategoryId: formik.values.productCategory.id })
				.then(response => {
					if (response.status === HTTP_STATUS.OK)
						setBrandsTo(response?.data?.data?.content)
					const brandOptions: AutoCompleteOption[] = response?.data?.data?.content?.map((brand: any) => ({ label: brand.name, id: brand.id }))
					setBrandOptionsTo(brandOptions)
				}).catch((error) => {
					console.error("Error Fetching Category: ", error);
				})
		}
	}

	const formik = useFormik<IMpcForm>({
		initialValues: {
			categoryId: mpc?.product?.categoryId ?? null,
			grade: "",
			productId: "",
			standard: {
				id: mpc?.product?.standard?.id ?? null,
				label: mpc?.product?.standard?.name ?? ""
			},
			gradeId: mpc?.product?.gradeId ?? null,
			shape: mpc?.shape ?? "",
			classType: mpc?.classType ?? "",
			attributes: {
				'SPECIFICATION': mpc?.attributes?.SPECIFICATION || [],
				'CLASSIFICATION': mpc?.attributes?.CLASSIFICATION || [],
				'GENERALIZATION': mpc?.attributes?.GENERALIZATION || [],
			},
			keywords: mpc?.product?.keywords.split(",") ?? [],
			status: mpc?.status === STATUS.ACTIVE ? true : false ,
			upcCode: mpc?.upcCode ?? "",
			productCode: mpc?.product?.productCode ?? "",
			title: mpc?.product?.title ?? "",
			superCategory:
			{
				id: mpc?.product?.superCategory?.id ?? null,
				label: mpc?.product?.superCategory?.name ?? ""
			},
			mainCategory:
			{
				id: mpc?.product?.mainCategory?.id ?? null,
				label: mpc?.product?.mainCategory?.name ?? ""
			},
			productCategory:
			{
				id: mpc?.product?.productCategory?.id ?? null,
				label: mpc?.product?.productCategory?.name ?? ""
			},
			brandIds: []
		},

		validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			if (mode === MODE.ADD) {
				if (values.brandIds.length === 0) {
					formik.setFieldError('brandIds', 'At least one brand must be selected');
					return;
				}
				setSubmitting(true);
				const upcRequestBody: IProductUpcCreateRequest = {
					status: values.status ? STATUS.ACTIVE : STATUS.INACTIVE,
					classType: values.classType,
					attributes: values.attributes,
					shape: values.shape,
					upcCode: '12378956',
					product: {
						categoryId: values.productCategory?.id,
						gradeId: values.gradeId,
						keywords: values.keywords.join(','),
						productCode: "Test Product Code",
						title: "",
						productProperties: [],
					},
					brandIds: values.brandIds
				};
				upcRequestBody['upcCode'] = ""
				onMpcSubmit(upcRequestBody);
			} else if (mode === MODE.EDIT) {
				const brandIds: number[] = [];
				if (upcBrandMapping) {
					for (const item of upcBrandMapping) {
						brandIds.push(item.brandId);
					}
				}
				if (values.brandIds.length === 0 && upcBrandMapping.length === 0) {
					formik.setFieldError('brandIds', 'At least one brand must be selected');
					return;
				}
				setSubmitting(true);
				const commonBrandIds = values.brandIds.some(id => brandIds.includes(id));
				if (commonBrandIds) {
					showSnackbar("warning", "The selected brands already exist.");
					return;
				}
				const combinedBrandIds = values.brandIds.concat(brandIds);
				const uniqueBrandIds: number[] = [];
				for (const id of combinedBrandIds) {
					if (!uniqueBrandIds.includes(id)) {
						uniqueBrandIds.push(id);
					}
				}
				const updatedMpc = {
					...mpc,
					status: values.status ? STATUS.ACTIVE : STATUS.INACTIVE,
					brandIds: uniqueBrandIds,
					product: {
						...mpc?.product,
						keywords: values.keywords.join(','),
					}
				};
				onMpcSubmit(updatedMpc);
			}
		},
	});

	const updatekeywordsValues = (data: string[]) => {
		formik.setFieldValue('keywords', data);
	}

	const updateBrands = (brands: AutoCompleteOption[]) => {
		const brandIds = brands?.map(selected => selected.id);
		formik.setFieldValue('brandOptions', brands)
		formik.setFieldValue('brandIds', brandIds);
	}

	const handleChipDelete = (value: ChipItem) => {
		const brandOptions = formik.values.brandOptions?.filter((chip) => chip.id !== value.id) ?? null;
		if (!brandOptions) return;
		updateBrands(brandOptions);
	}

	const updateSelectedBrands = (value: AutoCompleteOption | null) => {
		if (!value) return;
		const brandOptions = formik.values.brandOptions ?? [];
		if (brandOptions.some(selectedBrand => selectedBrand.id === value.id)) return;
		const uniqueBrandsSet = new Set([...brandOptions, value].map(brand => JSON.stringify(brand)));
		const uniqueBrandsArray = Array.from(uniqueBrandsSet).map(brandString => JSON.parse(brandString));
		updateBrands(uniqueBrandsArray);
	};


	useEffect(() => {
		formik.setValues({
			status: mpc?.status === STATUS.ACTIVE ? true :  false ,
			classType: mpc?.classType ?? "",
			attributes: {
				'SPECIFICATION': mpc?.attributes?.SPECIFICATION?.sort((a: { id: number }, b: { id: number }) => a?.id - b?.id) || [],
				'CLASSIFICATION': mpc?.attributes?.CLASSIFICATION || [],
				'GENERALIZATION': mpc?.attributes?.GENERALIZATION || [],
			},
			categoryId: mpc?.product?.categoryId ?? null,
			shape: mpc?.shape ?? "",
			upcCode: mpc?.upcCode ?? "",
			gradeId: mpc?.product?.gradeId ?? null,
			keywords: mpc?.product?.keywords.split(",") ?? [],
			productCode: mpc?.product?.productCode ?? "",
			title: mpc?.product?.title ?? "",
			standard:{
				id: mpc?.product?.standard?.id ?? null,
				label: mpc?.product?.standard?.name ?? ""
			},
			grade: "",
			productId: "",
			superCategory:
			{
				id: mpc?.product?.superCategory?.id ?? null,
				label: mpc?.product?.superCategory?.name ?? ""
			},
			mainCategory:
			{
				id: mpc?.product?.mainCategory?.id ?? null,
				label: mpc?.product?.mainCategory?.name ?? ""
			},
			productCategory:
			{
				id: mpc?.product?.productCategory?.id ?? null,
				label: mpc?.product?.productCategory?.name ?? ""
			},
			brandIds: []
		});
	}, [mpc]);

	useEffect(() => {
		loadBrands();
	}, []);

	const loadUpcBrandMapping = async (page: number, size: number) => {
		try {
			const params: IProductUPCBrandMappingRequest = {
				upcId: mpcId,
				page: page,
				size: size,
			};
			const upcResponse = await upcBrandMappingService.getProductUPCBrandMapping(params);

			if (upcResponse?.data?.data) {
				const { totalElements, totalPages } = upcResponse?.data?.data;
				setPaginationTo({
					...pagination,
					totalPages: totalPages,
					totalRecords: totalElements
				});
				setUpcBrandMappingTo(upcResponse?.data?.data?.content);
			} else {
				setUpcBrandMappingTo([]);
			}
		} catch (error) {
			setUpcBrandMappingTo([]);
			showSnackbar("error", `Unable to get Product Upc Brand Mapping`);
		}
	}

	const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPaginationTo((prevPagination) => ({
			...prevPagination,
			currentPage: newPage
		}));
	};

	const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newRowsPerPage = parseInt(event.target.value, 10);
		setPaginationTo((prevPagination) => ({
			...prevPagination,
			pageSize: newRowsPerPage
		}));
	};

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	};

	useEffect(() => {
		if (mpcId) {
			loadUpcBrandMapping(pagination.currentPage, pagination.pageSize);
		}
	}, [pagination.currentPage, pagination.pageSize, mpcId]);

	return (
		<div className="">
			<form className=" w-full" onSubmit={formik.handleSubmit}>
				<div className="grid gap-y-6 w-full">
					<div className=" flex justify-between items-center border-b">
						<div className={`font-semibold text-2xl ${classes.textColor} py-5`}>
							{mode === MODE.EDIT ? "Update Master Product Code" : "Add Master Product Code"}</div>
						<div>
							<CloseIcon
								className="close-icon cursor-pointer"
								onClick={navigateToMPCTable}
							/>
						</div>
					</div>

					<div className="grid gap-y-4">
						<CategoryAndManufacturerSearchTemplate handleSpecification={handleSpecification} formik={formik} mode={mode} />

						<StandardSectionIndexTemplate formik={formik} mode={mode} />

						<ProductClassificationSearchTemplate formik={formik} type={CLASS_TYPE.NONSTANDARD} mode={mode} />

						<ProductSpecificationSectionIndexTemplate updateSearchParams={handleUpdateMPCAttributesFormDetails} attributes={attributes} formik={formik} mode={mode} />

						<div className='px-6'>
							<TagInputGroup
								onTagChange={updatekeywordsValues}
								displayLabel={'Keywords'}
								tags={formik.values.keywords}
								disabled={mode === MODE.VIEW}
							/>
							{formik.touched && formik.touched?.keywords && formik.errors?.keywords && (
								<div className={classes.errorMessage}>
									<small>{formik.errors.keywords}</small>
								</div>
							)}
							<p className={`${classes.textColor} text-xs pt-0.5`}>The Keywords are to be used for easy and efficient searching of Product.</p>
						</div>

						<div className='px-6'>
							<FormGroup>
								<Toggler
									title="Status"
									currentState={formik.values.status}
									handleToggleChange={(type: any, value: string) =>
										formik.setFieldValue("status", value)
									}
									disabled={mode === MODE.VIEW}
								/>
							</FormGroup>
						</div>
						{mode !== MODE.VIEW && <div>
							<div className={`${classes.container} grid gap-y-4 p-6 rounded`}>
								<div className={` ${classes.heading} text-lg font-semibold`}>
									Select Brands
								</div>
								<div className='grid gap-y-4'>
									<FormControl fullWidth>
										<AutoCompleteTextField
											options={brandOptions}
											label="Select Brand"
											onChange={updateSelectedBrands}
											onSearch={loadBrands}
											isDisabled={mode === MODE.VIEW}
											onKeyDown={handleKeyDown}
										/>
										{formik?.errors?.brandIds && (
											<div className={`${classes.errorMessage} text-xs mt-1`}>
												{formik?.errors?.brandIds}
											</div>
										)}
									</FormControl>
								</div>
							</div>
							<Chips values={formik.values.brandOptions ?? []} onDelete={handleChipDelete} disabled={mode === MODE.VIEW} />
						</div>}
						<div>
							{SnackBarComponent}
							{mode !== MODE.ADD && <UPCMappingTemplate upcs={upcBrandMapping} mode={mode} setUpcBrandMappingTo={setUpcBrandMappingTo} pagination={pagination} handlePaginationChange={handlePaginationChange} handleRowsPerPageChange={handleRowsPerPageChange} />}
						</div>
						<div className="flex gap-x-4 justify-end h-12">
							{mode === MODE.VIEW ? (
								<Button
									variant="contained"
									label="Close"
									onClick={navigateToMPCTable}
								/>
							) : (
								<>
									<Button
										variant="outlined"
										label="Cancel"
										onClick={navigateToMPCTable}
									/>
									<Button
										variant="contained"
										type="submit"
										label={mode === MODE.EDIT ? "Update MPC" : "Add MPC"}
									/>
								</>
							)}
						</div>
					</div>
				</div>
			</form>
		</div>

	);
};

export default MpcFormTemplate;
