import { createUseStyles } from "react-jss";
import TextField from "../../../atoms/TextField";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../../../atoms/Button";
import { MANUFACTURER_SECTIONS } from "../../../../utils/types";
import { IManufacturingLocation, changeSectionTo } from "./ManufacturerForm.template";
import { useNavigate } from "react-router-dom";
import { ADMIN_ROUTES, MODE } from "../../../../utils/constant";
import { Dispatch, SetStateAction, useMemo } from "react";

const useStyles = createUseStyles((theme: any) => ({
	errorMessage: {
		color: theme.palette.action.danger,
	},
    mainHeading: {
        color: theme.palette.text.primaryDarkLight
    },
    headingContainer: {
        borderBottom: `1px solid ${ theme.palette.border.primaryDark }`
    },
	formContainer: {
		border: `1px solid ${ theme.palette.border.primaryDark }`
	},
	buttonContainer: {
		background: theme.palette.background.primaryLightAccent
	}
}));

interface ManufacturingLocationDetailsTemplateProps {
	setCurrentSectionTo: (section: MANUFACTURER_SECTIONS) => void;
	formik: any;
	id: number | null;
	categoryIds: number[] | null;
	mode: string;
	setCategoriesTo: Dispatch<SetStateAction<number[]>>;
}

const ManufacturingLocationDetailsTemplate: React.FC<ManufacturingLocationDetailsTemplateProps> = ({ setCurrentSectionTo, categoryIds, formik, id, mode, setCategoriesTo }) => {
	
	const classes = useStyles();

	const navigate = useNavigate();

	const redirectToManufacturerList = () => {
		navigate(ADMIN_ROUTES.MANUFACTURER_LIST);
	}

	const addManufacturingLocations = () => {
		const newManufacturingLocation: any = {
			id: null,
			manufacturingAddressLine1: "",
			manufacturingAddressLine2: "",
			manufacturingCountry: "",
			manufacturingState: "",
			manufacturingCity: "",
			manufacturingPinCode: "",
		};
		formik.setFieldValue("manufacturingLocations", [
			...formik.values.manufacturingLocations,
			newManufacturingLocation,
		]);
	};

	const removeManufacturingLocations = (index: number) => {
		formik.setFieldValue(
			"manufacturingLocations",
			formik.values.manufacturingLocations.filter((_: any, i: number) => i !== index)
		);
	};

	const removeManufacturingLocation = (index: number) => () => {
		removeManufacturingLocations(index);
	};

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		const requiredFields: (keyof Omit<IManufacturingLocation, 'id'>)[] = ["manufacturingAddressLine1", "manufacturingAddressLine2", "manufacturingCountry", "manufacturingState", "manufacturingCity", "manufacturingPinCode"];
		const manufacturingErrorsExist = formik.errors?.manufacturingLocations?.some((location: {[key: string]: any}) => {
			return requiredFields.some(fieldName => location[fieldName]);
		});
		if (manufacturingErrorsExist) {
			formik.touched["manufacturingLocations"] = Array(formik.values.manufacturingLocations?.length ?? 0).fill({
				manufacturingAddressLine1: true, manufacturingAddressLine2: true, manufacturingCountry: true, manufacturingState: true, manufacturingCity: true, manufacturingPinCode: true
			})
			formik.setTouched({...formik.touched});
		} else {
			setCurrentSectionTo(MANUFACTURER_SECTIONS.POINT_OF_CONTACT);
		}
	};

	const AddProductCategory = useMemo(() => {
        if (mode !== MODE.VIEW) {
            return (
                <div className={ `${ classes.buttonContainer } rounded-xl text-white py-3 px-4 cursor-pointer w-fit` } onClick={ addManufacturingLocations }>
                    <AddRoundedIcon />
                </div>
            );
        }
        return null;
    }, [mode, addManufacturingLocations]);

	return (
		<div className="p-6 grid gap-y-6">
			<div className={ `${ classes.headingContainer } flex justify-between pb-4` }>
                <div className={ `${ classes.mainHeading } font-semibold text-2xl` }>Manufacturing Location details</div>
                <CloseIcon
                    onClick={ redirectToManufacturerList }
                    className="cursor-pointer text-4xl"
                />
            </div>
			{/***************************	TODO- Make a Generic Component********************** */}
			<div className="w-full  m-auto flex justify-between gap-x-0.5 text-var(--black, #4D4D4D) text-center font-roboto text-xs font-normal leading-normal">
				<div className="grid gap-y-1 w-1/3 ">
					<div className="text-gray-700">Manufacturer Details</div>
					<div className="h-3 bg-gray-300"></div>
				</div>
				<div className="grid gap-y-1 w-1/3">
					<div className="text-blue-600"> Manufacturing Location details</div>
					<div className="h-3 bg-blue"></div>
				</div>
				<div className="grid gap-y-1 w-1/3">
					<div className="">Add Point of Contact</div>
					<div className="h-3 bg-gray-300"></div>
				</div>
			</div>
			{/***************************	TODO- Make a Generic Component********************** */}
			<div className="grid gap-y-4" >
				{ formik.values.manufacturingLocations &&
					formik.values.manufacturingLocations.map((location: any, index: number) => (
						<div className={ `${ classes.formContainer } grid gap-y-4 p-3 rounded` } key={ index }>
							
							<div className="flex justify-between">
							   <div className={ `font-semibold text-base ${ classes.mainHeading }` }>Manufacturing Location - { index + 1 }</div>
							   { index > 0 && mode !== "VIEW" && (
								<div onClick={ removeManufacturingLocation(index) }>
									<CloseIcon className="cursor-pointer text-4xl"/>
								</div>
							   )}
							</div>
							<div className="grid gap-y-4">
								<div className="grid">
									<TextField
										id={ `manufacturingAddressLine1-${ index }` }
										label="Address Line 1*"
										variant="outlined"
										{ ...formik.getFieldProps(`manufacturingLocations[${ index }].manufacturingAddressLine1`) }
										name={ `manufacturingLocations[${ index }].manufacturingAddressLine1` }
										inputProps={{ readOnly: mode === MODE.VIEW ? true : false }}
									/>
									{ formik.touched.manufacturingLocations &&
										formik.touched.manufacturingLocations[index] &&
										formik.touched.manufacturingLocations[index].manufacturingAddressLine1 &&
										formik.errors.manufacturingLocations &&
										formik.errors.manufacturingLocations[index] &&
										formik.errors.manufacturingLocations[index]?.manufacturingAddressLine1 && (
											<div className={ classes.errorMessage }>
												<small>
													{ formik.errors.manufacturingLocations[index].manufacturingAddressLine1 }
												</small>
											</div>
										)}
										</div>
										<div className="grid">
									<TextField
										id={ `manufacturingAddressLine2-${ index }` }
										label="Address Line 2*"
										variant="outlined"
										{ ...formik.getFieldProps(`manufacturingLocations[${ index }].manufacturingAddressLine2`) }
										name={ `manufacturingLocations[${ index }].manufacturingAddressLine2` }
										inputProps={{ readOnly: mode === MODE.VIEW ? true : false }}
									/>
									{ formik.touched.manufacturingLocations &&
										formik.touched.manufacturingLocations[index] &&
										formik.touched.manufacturingLocations[index].manufacturingAddressLine2 &&
										formik.errors.manufacturingLocations &&
										formik.errors.manufacturingLocations[index] &&
										formik.errors.manufacturingLocations[index].manufacturingAddressLine2 && (
											<div className={ classes.errorMessage }>
												<small>
													{ formik.errors.manufacturingLocations[index].manufacturingAddressLine2 }
												</small>
											</div>
										)}
								</div>
							</div>
							<div className="grid grid-cols-2 gap-4">
									<div className="grid">
										<TextField
											id={ `manufacturingCountry-${ index }` }
											label="Country*"
											variant="outlined"
											{ ...formik.getFieldProps(`manufacturingLocations[${ index }].manufacturingCountry`) }
											name={ `manufacturingLocations[${ index }].manufacturingCountry` }
											inputProps={{ readOnly: mode === MODE.VIEW ? true : false }}
										/>
										{ formik.touched.manufacturingLocations &&
											formik.touched.manufacturingLocations[index] &&
											formik.touched.manufacturingLocations[index].manufacturingCountry &&
											formik.errors.manufacturingLocations &&
											formik.errors.manufacturingLocations[index] &&
											formik.errors.manufacturingLocations[index].manufacturingCountry && (
												<div className={ classes.errorMessage }>
													<small>
														{ formik.errors.manufacturingLocations[index].manufacturingCountry }
													</small>
												</div>
										)}
									</div>
									<div className="grid">
										<TextField
											id={ `manufacturingState-${ index }` }
											label="State*"
											variant="outlined"
											{ ...formik.getFieldProps(`manufacturingLocations[${ index }].manufacturingState`) }
											name={ `manufacturingLocations[${ index }].manufacturingState` }
											inputProps={{ readOnly: mode === MODE.VIEW ? true : false }}
										/>
										{ formik.touched.manufacturingLocations &&
											formik.touched.manufacturingLocations[index] &&
											formik.touched.manufacturingLocations[index].manufacturingState &&
											formik.errors.manufacturingLocations &&
											formik.errors.manufacturingLocations[index] &&
											formik.errors.manufacturingLocations[index].manufacturingState && (
											<div className={ classes.errorMessage }>
												<small>
													{ formik.errors.manufacturingLocations[index].manufacturingState }
												</small>
											</div>
										)}
									</div>
									<div className="grid">
										<TextField
											id={ `manufacturingCity-${ index }` }
											label="City*"
											variant="outlined"
											{ ...formik.getFieldProps(`manufacturingLocations[${ index }].manufacturingCity`) }
											name={ `manufacturingLocations[${ index }].manufacturingCity` }
											inputProps={{ readOnly: mode === MODE.VIEW ? true : false }}
										/>
										{ formik.touched.manufacturingLocations &&
											formik.touched.manufacturingLocations[index] &&
											formik.touched.manufacturingLocations[index].manufacturingCity &&
											formik.errors.manufacturingLocations &&
											formik.errors.manufacturingLocations[index] &&
											formik.errors.manufacturingLocations[index].manufacturingCity && (
											<div className={ classes.errorMessage }>
												<small>
													{ formik.errors.manufacturingLocations[index].manufacturingCity	}
												</small>
											</div>
										)}
									</div>
									<div className="grid">
										<TextField
											id={ `manufacturingPinCode-${ index }` }
											type="number"
											label="Pincode*"
											variant="outlined"
											{ ...formik.getFieldProps(`manufacturingLocations[${ index }].manufacturingPinCode`) }
											name={ `manufacturingLocations[${ index }].manufacturingPinCode` }
											inputProps={{ readOnly: mode === MODE.VIEW ? true : false}}
										/>
										{ formik.touched.manufacturingLocations &&
											formik.touched.manufacturingLocations[index] &&
											formik.touched.manufacturingLocations[index].manufacturingPinCode &&
											formik.errors.manufacturingLocations &&
											formik.errors.manufacturingLocations[index] &&
											formik.errors.manufacturingLocations[index].manufacturingPinCode && (
												<div className={ classes.errorMessage }>
													<small>
														{ formik.errors.manufacturingLocations[index].manufacturingPinCode }
													</small>
												</div>
										)}
									</div>
							</div>
						</div>
				))}
				{ AddProductCategory }
			</div>
			<div className="flex justify-end">
                <div className="flex gap-x-5">
					<Button
						variant="outlined"
						onClick={ changeSectionTo(setCurrentSectionTo)(MANUFACTURER_SECTIONS.MANUFACTURER_DETAILS) }
						label="Back"
					/>
					<Button
						variant="contained"
						type="submit"
						label="Next"
						onClick={ handleSubmit }
					/>
				</div>
			</div>
		</div>
	);
}

export default ManufacturingLocationDetailsTemplate;
