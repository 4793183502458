import React, { useContext, useEffect, useState } from "react";
import closePng from "../../../Assets/Images/close.png";
import axios from "axios";
import InventoryContext from "../../../Core/Context/inventory/NewInventoryContext.tsx";
import { CustomButton } from "../../../Shared/Button/CustomButton";
import { useNavigate } from "react-router-dom";
import ImageButton from "../../../Shared/ImageButton/ImageButton";
import { FilesWrapper } from "../../../Shared/Files/DisplayFiles";
import { DisplayDocuments } from "../../../Shared/Files/FileItem";
import Chip from '@mui/material/Chip';
import { convertToReadableFormat } from "../../AdminMaster/MPCTEMP/PreviewMPC_refactor/PreviewMPC_refactor";
import { useAuthenticatedUser } from "../../../../hooks/useAuthenticatedUser";
import { LOCAL_PREVIOUS_URL_SECTIONS } from "../../../../utils/constant";
const BaseUrl = process.env.REACT_APP_API_V1_URL;


const DisplayProductSpecificationDetails = ({ productSpecificationDetails }) => {
  try {
    const attributes = JSON.parse(productSpecificationDetails);

    if (attributes?.SPECIFICATION) {
      return attributes.SPECIFICATION.map((specificationItem, index) => (

        <div key={index} className="grid gap-y-1.5">

          <div className="m-auto">{specificationItem.name}</div>
          <div className="mx-4 px-1.5 rounded-full border border-input-border">
            {specificationItem.attributeOptions}
          </div>
        </div>
      ));
    } else {
      return <div>No specification data available.</div>;
    }
  } catch (error) {
    console.error('Error parsing attributes:', error);
    return <div>No Specification Details</div>;
  }
};
function DisplayKeywords({ keywords }) {

  if (!keywords) {
    return null;
  }
  var arrayOfValues = []
  try {
    arrayOfValues = keywords.split(',');
  } catch (error) {
    return null;
  }

  return (
    <div className="flex gap-x-3">
      {arrayOfValues.map((keyword, index) => (
        <Chip
          variant="outlined"
          key={index}
          label={keyword}
        />
      ))}
    </div>
  );
}
const DisplayProductGeneralizationDetails = ({ productSpecificationDetails }) => {
  try {
    const attributes = JSON.parse(productSpecificationDetails);

    if (attributes?.GENERALIZATION) {
      return attributes.GENERALIZATION.map((specificationItem, index) => (

        <div key={index} className="grid gap-y-1.5">

          <div className="m-auto">{specificationItem.name}</div>
          <div className="mx-4 px-1.5 rounded-full border border-input-border">
            {specificationItem.attributeOptions}
          </div>
        </div>
      ));
    } else {
      return <div>No Generalization data available.</div>;
    }
  } catch (error) {
    console.error('Error parsing attributes:', error);
    return <div>No Generalization Details</div>;
  }
};

const FinalPreview = () => {
  const navigate = useNavigate();
  const { user } = useAuthenticatedUser();
  const inventoryContextData = useContext(InventoryContext);
  const [imageData, setImageData] = useState([]);
  const [uploadedImageData, setUploadedImageData] = useState([]);
  const [warehouseDetails, setWarehouseDetails] = useState();
  const [upcCode, setUpcCode] = useState();
  const [selectedUpcDetails, setSelectedUpcDetails] = useState([]);
  const [imageIdsToDelete, setImageIdsToDelete] = useState([]);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  useEffect(() => {
    if (
      Object.keys(inventoryContextData.inventoryDetailState.inventoryFormData)
        .length === 0
    ) {
      navigate(
        `/inventory/step3?id=${urlParams.get(
          "id"
        )}&skuMode=${urlParams.get("skuMode")}&mode=${urlParams.get("mode")}`
      );
    }
  }, []);
  let [formData, setFormData] = useState({
    upcId: 0,
    warehouse: "",
    warehousePlacement: "",
    warehouseId: "",
    netWeight: 0,
    grossWeight: 0,
    basePrice: 0,
  });
  let [inventoryId, setInventoryId] = useState();
  let [mode, setMode] = useState();
  let [skuMode, setSkuMode] = useState();
  useEffect(() => {
    setFormData(inventoryContextData?.inventoryDetailState?.inventoryFormData);
    setMode(inventoryContextData?.inventoryDetailState?.mode);
    setImageData(inventoryContextData?.inventoryDetailState?.imageData);
    setSkuMode(inventoryContextData?.inventoryDetailState?.skuMode);
    setInventoryId(inventoryContextData?.inventoryDetailState?.inventoryId);
    setImageIdsToDelete(inventoryContextData?.inventoryDetailState?.imageIdsToDelete);
  }, []);

  useEffect(() => {
    fetchUpcDetails(
      inventoryContextData?.inventoryDetailState?.inventoryFormData?.upcId
    );
    setFormData(inventoryContextData?.inventoryDetailState?.inventoryFormData);
    fetchWarehouseDetails(
      inventoryContextData?.inventoryDetailState?.inventoryFormData?.warehouseId
    );
    fetchDocuments(
      inventoryContextData?.inventoryDetailState?.inventoryFormData?.id,
      "INVENTORY"
    );
  }, [upcCode]);

  const fetchWarehouseDetails = (warehouseId) => {
    axios
      .get(`${BaseUrl}/warehouse/${warehouseId}`,{
        headers: {
           Authorization: `${user.token}`,
           "Content-Type": "application/json",
        }
     }
   )
      .then((response) => {
        setWarehouseDetails(response?.data?.name);
      })
      .catch((error) => {
        console.error("Error fetching UPC details:", error);
        setWarehouseDetails(null);
      });
  };
  const fetchUpcDetails = (upcId) => {
    axios
      .post(`${BaseUrl}/search/upc`, {
        upcId: upcId,
        classType: skuMode,
      },{
        headers: {
           Authorization: `${user.token}`,
           "Content-Type": "application/json",
        }
     }).then((response) => {
        setUpcCode(response?.data?.data[0]?.upcCode);
        setSelectedUpcDetails(response?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching UPC details:", error);
        setSelectedUpcDetails(null);
      });
  };
  const fetchDocuments = async (resId, resType) => {
    try {
      if (!resId || !resType) {
        return;
      }
      const apiUrl = `${BaseUrl}/files/${resType}/${resId}`;

      const response = await axios.get(apiUrl,{
        headers: {
           Authorization: `${user.token}`
        }
     }).then((res) => {
        setUploadedImageData(res.data.data);
        return;
      });
    } catch (err) {
      return;
    }
  };

  const handleInventoryImageData = async (
    imageData,
    resourceId,
    resourceType,
    createdBy
  ) => {
    if (!resourceId || !resourceType) {
      console.log("Haven't Received the ResourceId, ResourceType");
      return false;
    }

    const formData = new FormData();

    for (let i = 0; i < imageData.length; i++) {
      formData.append(`files`, imageData[i]);
    }

    const uploadFilesData = {
      resourceType: resourceType,
      resourceId: resourceId,
      createdBy: createdBy,
    };

    formData.append(
      "resource",
      new Blob([JSON.stringify(uploadFilesData)], {
        type: "application/json",
      })
    );

    try {
      await axios.post(`${BaseUrl}/files/`, formData,{
        headers: {
           Authorization: `${user.token}`
        }
     }).then((data) => {
        return true;
      });
    } catch (error) {
      console.log("Error in POST -", error);
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let payload = {
      upcId: formData?.upcId,
      basePrice: formData?.basePrice,
      netWeight: formData?.netWeight,
      grossWeight: formData?.grossWeight,
      uom: "MT",
      warehouse: "MUMBAI",
      warehouseId: formData?.warehouseId,
      warehousePlacement: formData?.warehousePlacement,
      createdBy: 1,
      updatedBy: 2,
    };
    if(imageIdsToDelete!== undefined &&  imageIdsToDelete.length){
      const combinedimageIds = imageIdsToDelete.join(", ");
      const apiUrl = `${BaseUrl}/files/${combinedimageIds}`;
      const response = await axios.delete(apiUrl,{
        headers: {
           Authorization: `${user.token}`
        }
     }).then((res) => {        
        return;
      }); 
    }

    if (mode === "ADD") {
      axios.post(`${BaseUrl}/inventory/`, payload,{
        headers: {
           Authorization: `${user.token}`,
           "Content-Type": "application/json",
        }
     }).then((response) => {
        if (response?.status === 200) {
          var resourceId = response?.data?.data?.id;
          var resourceType = "INVENTORY";
          var createdBy = response?.data?.data?.createdBy;

          if (
            handleInventoryImageData(
              imageData,
              resourceId,
              resourceType,
              createdBy
            )
          ) {
            inventoryContextData?.resetInventoryContext();
          }
        } else {
          inventoryContextData.resetInventoryContext();
          console.log("Error in Response - ", response);
        }

        handleRedirect();
      });
    } else {
      axios
        .put(`${BaseUrl}/inventory/${inventoryId}`, payload,{
          headers: {
             Authorization: `${user.token}`,
             "Content-Type": "application/json",
          }
       })
        .then((response) => {
          if (response.status === 200) {
            var resourceId = response?.data?.data?.id;
            var resourceType = "INVENTORY";
            var createdBy = response?.data?.data?.createdBy;

            if (
              handleInventoryImageData(
                imageData,
                resourceId,
                resourceType,
                createdBy
              )
            ) {
              inventoryContextData.resetInventoryContext();
            }
          } else {
            inventoryContextData.resetInventoryContext();
            console.log("Error in Response - ", response);
          }

          handleRedirect();
        });
    }
  };

  const handleRedirect = () => {
    if (skuMode === "STANDARD") {
      const lastVisitedUrl = localStorage.getItem(LOCAL_PREVIOUS_URL_SECTIONS.STANDARD_INVENTORY_PREVIOUS_URL);
      navigate(`/inventory/standard-prime${lastVisitedUrl}`);
    } else if (skuMode === "NONSTANDARD") {
      const lastVisitedUrl = localStorage.getItem(LOCAL_PREVIOUS_URL_SECTIONS.NON_STANDARD_INVENTORY_PREVIOUS_URL);
      navigate(`/inventory/non-standard-prime${lastVisitedUrl}`);
    }
  };

  return (
    <div className="mt-3 sku-background grid gap-y-5 py-5">
      <div className="flex justify-center text-label font-roboto text-5xl font-semibold">
        {inventoryContextData.inventoryDetailState.mode === "ADD"
          ? "Add New"
          : "Edit"}{" "}
        SKU
      </div>
      <div className="w-4/5 mt-4 m-auto flex justify-between gap-x-0.5 text-var(--black, #4D4D4D) text-center font-roboto text-xs font-normal leading-normal">
        <div className="grid gap-y-1 w-1/4">
          <div className="h-3 bg-blue"></div>
        </div>
        <div className="grid gap-y-1 w-1/4">
          <div className="h-3  bg-blue"></div>
        </div>
        <div className="grid gap-y-1 w-1/4">
          <div className="h-3  bg-blue"></div>
        </div>
        <div className="grid gap-y-1 w-1/4">
          <div className="h-3 bg-blue"></div>
        </div>
      </div>
      <div className="grid gap-y-6 p-6 w-4/5 m-auto  bg-white">
        <div className="pb-4 flex justify-between border-b border-input-border">
          <div className="text-text-color font-roboto text-xl font-semibold leading-5">
            {inventoryContextData?.inventoryDetailState?.mode === "ADD"
              ? ""
              : `SKU CODE: ${inventoryContextData?.inventoryDetailState?.inventoryFormData?.skuCode}`}
          </div>
          <div>
            <ImageButton onClick={handleRedirect} imageSrc={closePng} />
          </div>
        </div>
        <div className="grid gap-y-4">
          <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
            <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
              Category
            </div>
            <div className="flex gap-x-6">
              <div className="grid gap-y-1.5">
                <div className="text-text-color font-roboto text-base font-normal">
                  Super Category
                </div>
                <div className="text-text-color font-roboto text-base font-medium">
                  {selectedUpcDetails[0]?.superCategory}
                </div>
              </div>
              <div className="grid gap-y-1.5">
                <div className="text-text-color font-roboto text-base font-normal">
                  Main Category
                </div>
                <div className="text-text-color font-roboto text-base font-medium">
                  {selectedUpcDetails[0]?.mainCategory}
                </div>
              </div>
              <div className="grid gap-y-1.5">
                <div className="text-text-color font-roboto text-base font-normal">
                  Product Category
                </div>
                <div className="text-text-color font-roboto text-base font-medium">
                  {selectedUpcDetails[0]?.productCategory}
                </div>
              </div>
              <div className="grid gap-y-1.5">
                <div className="text-text-color font-roboto text-base font-normal">
                  Manufacturer
                </div>
                <div className="text-text-color font-roboto text-base font-medium">
                  {selectedUpcDetails[0]?.manufactureName}
                </div>
              </div>
              <div className="grid gap-y-1.5">
                <div className="text-text-color font-roboto text-base font-normal">
                  Brand
                </div>
                <div className="text-text-color font-roboto text-base font-medium">
                  {selectedUpcDetails[0]?.brand}
                </div>
              </div>
            </div>
          </div>

          <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
            <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
              Standard
            </div>
            <div className="flex gap-x-6">
              <div className="grid gap-y-1.5">
                <div className="text-text-color font-roboto text-base font-normal">
                  Grade
                </div>
                <div className="text-text-color font-roboto text-base font-medium">
                  {selectedUpcDetails[0]?.grade}
                </div>
              </div>
              <div className="grid gap-y-1.5">
                <div className="text-text-color font-roboto text-base font-normal">
                  Standard
                </div>
                <div className="text-text-color font-roboto text-base font-medium">
                  {selectedUpcDetails[0]?.standard}
                </div>
              </div>
            </div>
          </div>

          {/* <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
            <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
              General Info
            </div>
            <div className="flex gap-x-6">
            <DisplayProductGeneralizationDetails productSpecificationDetails={selectedUpcDetails[0]?.attributes} />
          </div>
          </div> */}

          <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
            <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
              Product Classification
            </div>
            <div className="flex gap-x-6">
              <div className="grid gap-y-1.5">
                <div className="text-text-color font-roboto text-base font-normal">
                  Shape
                </div>
                <div className="text-text-color font-roboto text-base font-medium">
                  { convertToReadableFormat(selectedUpcDetails[0]?.shape) }
                </div>
              </div>
              <div className="grid gap-y-1.5">
                <div className="text-text-color font-roboto text-base font-normal">
                  Class
                </div>
                <div className="text-text-color font-roboto text-base font-medium">
                  {selectedUpcDetails[0]?.classType}
                </div>
              </div>
            </div>
          </div>

          <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
            <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
              Product Specification
            </div>
            <div className="flex gap-x-6">

            <DisplayProductSpecificationDetails productSpecificationDetails={selectedUpcDetails[0]?.attributes} />

          </div>
          </div>

          <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
            <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
              Keywords
            </div>
            <DisplayKeywords keywords={selectedUpcDetails[0]?.keywords} />

            {/**    <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
              Status:Active
            </div>*/}
          </div>
          <div className="flex justify-between">
            <div className="grid grid-cols-2 grid-rows-2 gap-6 my-6 ">
              <div>
                <div className="properties-names">Warehouse</div>
                <div className="properties-values">
                  {formData && warehouseDetails}
                </div>
              </div>
              <div>
                <div className="properties-names">Warehouse Placement</div>
                <div className="properties-values">
                  {" "}
                  {formData && formData?.warehousePlacement}
                </div>
              </div>

              <div>
                <div className="properties-names">Net Weight in MT</div>
                <div className="properties-values">
                  {formData && formData?.netWeight}
                </div>
              </div>
              <div>
                <div className="properties-names">Gross Weight in MT</div>
                <div className="properties-values">
                  {formData && formData?.grossWeight}
                </div>
              </div>
            </div>
            <div className=" px-14 py-14 bg-gray-300">
            <div className="properties-names">Total Price</div>
              <div className="properties-values">
                {formData &&
                  new Intl.NumberFormat().format(
                    formData?.basePrice * formData?.netWeight
                  )}{" "}
                Rs
              </div>
            </div>
          </div>
          <div>
            <div className="product-description">
              <h2>Uploaded Product Images & Video</h2>
            </div>
            <FilesWrapper heading={""}>
              <DisplayDocuments
                documentsData={imageData}
                isEdit={false}
                isForm={true}
                // deleteDocument={deleteDocument}
              />
              <DisplayDocuments
                documentsData={uploadedImageData}
                isEdit={false}
                isForm={false}
                // deleteDocument={handleRemoveImage}
              />
            </FilesWrapper>
          </div>

          <div className="flex justify-between">
            <div className="grid   mt-1 ">
            <CustomButton
              className="w-full"
              onClick={() => {
                navigate(
                  `/inventory/step3?id=${urlParams.get(
                    "id"
                  )}&skuMode=${urlParams.get("skuMode")}&mode=${urlParams.get(
                    "mode"
                  )}`
                );
              }}
            >
              Back
            </CustomButton>
            </div>
            <div className="flex gap-5 justify-end">

            <div className="grid   mt-1 ">
              <CustomButton
                className="w-full"
                onClick={handleRedirect}
                type="submit"
              >
                Cancel
              </CustomButton>
            </div>
            <div className="grid  justify-start mt-1 ">
              <CustomButton
                className="w-full"
                variant="Primary"
                type="submit"
                onClick={handleSubmit}
              >
                Submit
              </CustomButton>
            </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalPreview;
