import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import TableV2, { ColumnType } from "../../organisms/TableV2";
import { IUpcsByFilter } from "./UpcMaterialFilterForm.template";
import { Checkbox } from "@mui/material";
import { HTTP_STATUS, IPagination } from "../../../utils/types";
import { initialPaginationValues } from "../../../utils/constant";
import { useCustomerGroupMasterService } from "../../../services/useCustomerGroupMasterService";

interface CustomerGroupUpcsPreviewTemplateProps {
    formik: any;
}

interface ChipProps {
    value: string;
};
interface MaterialFiltersProps {
    label: string;
    values: any[];
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid #EBEFFA`
    },
    filterContainer: {
        borderTop: `1px solid #EBEFFA`
    },
    filterHeading: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    label: {
        color: theme.palette.textV2.tertiary.tertiary600,
        fontSize: "14px",
        fontWeight: 400,
    },
    configure: {
        color: theme.palette.textV2.primary.primary500,
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600,
    },
    chipBackgroundColor: {
        backgroundColor: theme.palette.textV2.tertiary.tertiary50,
    },
    activeProgressBar: {
        backgroundColor: "#FD6202"
    },
    inActiveProgressBar: {
        backgroundColor: "#EBEFFA"
    },
    input: {
        "& .MuiInputBase-root": {
            borderRadius: "8px !important",
        },
    },
    errorMessage: {
        color: theme.palette.textV2.failure.failure600,
    },
}));

const Chip: React.FC<ChipProps> = ({ value }) => {
    const classes = useStyles();
    return (
        <div className={`${classes.chipBackgroundColor} py-1.5 px-3 rounded-lg text-xs font-medium h-min`}>
            {value}
        </div>
    );
};

const MaterialFilters: React.FC<MaterialFiltersProps> = ({ label, values }) => {
    const classes = useStyles();
    if (values.length === 0) return null;
    return (
        <div className="grid content-start gap-y-2 w-44">
            <div className={`${classes.label} text-base`}>{label}</div>
            <div className="flex flex-wrap gap-2">
                {values.map((item, index) => (
                    <Chip key={index} value={item.label} />
                ))}
            </div>
        </div>
    );
};

const CustomerGroupUpcsPreviewTemplate: React.FC<CustomerGroupUpcsPreviewTemplateProps> = ({ formik }) => {
    const classes = useStyles();
    const customerGroupService = useCustomerGroupMasterService();
    const [upcs, setupcsTo] = useState<IUpcsByFilter[]>([]);
    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);

    const refreshUpcs = async (page: number, size: number) => {
        try {
            const upcFilterResponse = await customerGroupService.getUpcByMaterialFilter({
                page: page,
                size: size,
                sort: "createdAt,desc",
            },
                {
                    upcCodes: formik.values.upcCodes?.length > 0 ? formik.values.upcCodes.map((upcCode: any) => upcCode.label) : [],
                }
            );
            if (
                upcFilterResponse?.status === HTTP_STATUS.OK &&
                upcFilterResponse?.data?.data?.content?.length
            ) {
                setupcsTo(upcFilterResponse.data.data.content);
                setPaginationTo((prevPagination: IPagination) => ({
                    ...prevPagination,
                    totalRecords: upcFilterResponse.data.data.totalElements,
                    totalPages: upcFilterResponse.data.data.totalPages,
                }));
            } else {
                throw new Error("Upcs fetch failed");
            }
        } catch (error) {
            setupcsTo([]);
        }
    };

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage
        }));
    };

    useEffect(() => {
        refreshUpcs(pagination.currentPage, pagination.pageSize);
    }, [pagination.currentPage, pagination.pageSize]);

    const schema = {
        id: "1",
        title: "",
        pagination: {
            total: pagination?.totalRecords,
            currentPage: pagination?.currentPage,
            isVisible: true,
            limit: pagination?.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange,
        },
        columns: [
            { label: "S.No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "UPC Code", key: "UPC Code", type: "number" as ColumnType, props: { className: '' } },
            { label: "Brand", key: "brand id", type: "string" as ColumnType, props: { className: '' } },
            { label: "Status", key: "status", type: "string" as ColumnType, props: { className: '' } },
            { label: "Select UPC", key: "action", type: "custom" as ColumnType, props: { className: '' } },
        ],
    };

    const handleCheckBoxChange = (upcCode: string, id: number) => () => {
        const isAlreadySelected = formik.values.upcCodes.some(
            (selected: any) => selected.label === upcCode && selected.id === id
        );
        if (isAlreadySelected) {
            const newSelected = formik.values.upcCodes.filter(
                (selected: any) => !(selected.label === upcCode && selected.id === id)
            );
            formik.setFieldValue("upcCodes", newSelected);
        } else {
            formik.setFieldValue("upcCodes", [...formik.values.upcCodes, { label: upcCode, id: id }]);
        }
    };

    const Action = (upcCode: string, id: number) => {
        return (
            <div className={`flex items-center justify-center gap-x-3`}>
                <Checkbox
                    sx={{ padding: 0, color: "#C4C4C4" }}
                    checked={formik.values.upcCodes.some((selected: any) => selected.label === upcCode && selected.id === id)}
                    onChange={handleCheckBoxChange(upcCode, id)}
                    disabled
                />
            </div>
        );
    };

    const records = upcs?.map((upcs: IUpcsByFilter, index: number) => [
        pagination.currentPage * pagination.pageSize + index + 1,
        upcs?.upcCode,
        upcs?.brand,
        upcs?.status,
        Action(upcs?.upcCode, upcs?.upcId),
    ]);

    return (
        <div>
            <div className="flex justify-between pb-4">
                <div className={`${classes.filterHeading} text-lg font-bold my-auto`}>Material Filter</div>
            </div>
            <div className={`${classes.container} p-6 grid gap-y-6 rounded-lg`}>

                {(formik.values.superCategoryArray.length > 0 || formik.values.mainCategoryArray.length > 0 || formik.values.productCategoryArray.length > 0) &&
                    <div className="flex">
                        <MaterialFilters label={'Super Category'} values={formik.values.superCategoryArray} />
                        <MaterialFilters label={'Main Category'} values={formik.values.mainCategoryArray} />
                        <MaterialFilters label={'Product Category'} values={formik.values.productCategoryArray} />
                    </div>}
                {(formik.values.manufacturerArray.length > 0 || formik.values.brandArray.length > 0) &&
                    <div className="flex">
                        <MaterialFilters label={'Manufacturer'} values={formik.values.manufacturerArray} />
                        <MaterialFilters label={'Brand'} values={formik.values.brandArray} />
                    </div>}
                {(formik.values.standardArray.length > 0 || formik.values.gradeArray.length > 0) &&
                    <div className="flex ">
                        <MaterialFilters label={'Standard'} values={formik.values.standardArray} />
                        <MaterialFilters label={'Grade'} values={formik.values.gradeArray} />
                    </div>}
                {(formik.values.shapeArray.length > 0 || formik.values.classesArray.length > 0) &&
                    <div className="flex ">
                        <MaterialFilters label={'Shape'} values={formik.values.shapeArray} />
                        <MaterialFilters label={'Class'} values={formik.values.classesArray} />
                    </div>}
                {(formik.values.lengthArray.length > 0 || formik.values.thicknessArray.length > 0 || formik.values.widthArray.length > 0) &&
                    <div className="flex ">
                        <MaterialFilters label={'Length'} values={formik.values.lengthArray} />
                        <MaterialFilters label={'Thickness'} values={formik.values.thicknessArray} />
                        <MaterialFilters label={'Width'} values={formik.values.widthArray} />
                    </div>}

                <TableV2 schema={schema} records={records} />

            </div>
        </div>


    );
};

export default CustomerGroupUpcsPreviewTemplate;