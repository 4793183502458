import React from "react";
import { theme } from "../../../utils/theme";
import { RESOURCE_STATUS } from "../../../utils/constant";

interface ResourceStatusProps {
    status: string;
    colourMap?: { [key: string]: string }
}

const STATUS_COLOR = {
    [RESOURCE_STATUS.PUBLISHED]: theme.palette.resourceStatus.primaryLight,
    [RESOURCE_STATUS.ACTIVE]: theme.palette.resourceStatus.primaryLight,
    [RESOURCE_STATUS.COMPLETED]: theme.palette.resourceStatus.primaryLight,
    [RESOURCE_STATUS.PARTIALLY_COMPLETED]: theme.palette.resourceStatus.secondaryDark,
    [RESOURCE_STATUS.ACCEPTED]: theme.palette.resourceStatus.primaryLight,
    [RESOURCE_STATUS.PENDING]: theme.palette.resourceStatus.secondaryLight,
    [RESOURCE_STATUS.ONGOING]: theme.palette.resourceStatus.secondaryLight,
    [RESOURCE_STATUS.INACTIVE]: theme.palette.resourceStatus.primaryDark,
    [RESOURCE_STATUS.UNPUBLISHED]: theme.palette.resourceStatus.primaryDark,
    [RESOURCE_STATUS.REJECTED]: theme.palette.resourceStatus.primaryDark,
    [RESOURCE_STATUS.CANCELLED]: theme.palette.resourceStatus.primaryDark,
    [RESOURCE_STATUS.FAILED]: theme.palette.resourceStatus.primaryDark,
    [RESOURCE_STATUS.INVALID_SHEET]: theme.palette.resourceStatus.primaryDark,
};

const ResourceStatus: React.FC<ResourceStatusProps> = ({ status, colourMap }) => {
    const getStatusColor = (status: string) => {
        return colourMap ? colourMap[status] : STATUS_COLOR[status];
    };
    return <span style={{ color: `${getStatusColor(status)}` }}>{status}</span>;
}

export default ResourceStatus;