import React, { useEffect, useState } from "react";
import { BULK_UPLOAD_FILE_TYPE, BULK_UPLOAD_TEMPLATE_TYPE, RESOURCES, RESOURCE_STATUS } from "../../../utils/constant";
import { useBulkUploadService } from "../../../services/useBulkUploadService";
import { createUseStyles } from "react-jss";
import UploadActionFeedbackTemplate from "../../templates/BulkUpload/UploadActionFeedback.template";
import { HTTP_STATUS } from "../../../utils/types";
import UploadActionProgressTemplate from "../../templates/BulkUpload/UploadActionProgress.template";
import { AxiosProgressEvent } from "axios";
interface BulkUploadTemplateProps {
    icon: string;
    section: string;
}

const useStyles = createUseStyles((theme: any) => ({
    uploadButtonWrapper: {
        display: "flex",
        padding: "13px 26px",
        justifyContent: "center",
        alignItems: "center",
        gap: "12px",
        borderRadius: "4px",
        border: `1px solid ${theme.palette.border.primaryLightAccent}`,
        cursor: "pointer",
        position: "relative",
        overflow: 'hidden',
    },
    uploadWrapper: {
        display: "flex",
        color: theme.palette.text.primaryDarkLight,
        fontFamily: "Roboto",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: "600",
        textTransform: "capitalize",
        cursor: "pointer",
    },
    fileInput: {
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: 0,
        width: '100%',
        height: '100%',
        cursor: 'pointer',
      },
}))

const BulkUploadTemplate: React.FC<BulkUploadTemplateProps> = ({ icon, section }) => {

    const classes = useStyles();
    const [excelFile, setExcelFile] = useState<File>();
    const [uploadedFileStatus, setUploadedFileStatus] = useState<number>(-1);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadSpeed, setUploadSpeed] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    const [fileSize, setFileSize] = useState('');
    const bulkUploadService = useBulkUploadService();
    const [bulkUploadData, setBulkUploadData] = useState({
        fileName: "",
        section: section,
        status: RESOURCE_STATUS.PENDING,
        path: null,
        mimeType: null,
    });
    const [progress, setProgress] = useState<number>(0);

    useEffect(() => {
        readBulkUploadData();
    }, [excelFile, bulkUploadData])

    useEffect(() => {
        bulkUploadData.section = section;
    }, [section]);

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const file = event.target.files[0];
            if (file.type !== BULK_UPLOAD_FILE_TYPE.EXCEL_XLSX) {
                alert("Please upload a valid Excel File");
                return;
            }
            else {
                setBulkUploadData({ ...bulkUploadData, fileName: event.target.files[0].name });
                setExcelFile(event.target.files[0]);
                const fileSize = event.target.files[0].size;
                setFileSize((fileSize / 1024).toFixed(2));
            }
        }
    };
    const handleUploadProgress = (progressEvent: AxiosProgressEvent) => {
        if (progressEvent.total) {
            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            setProgress(progress);
        }
        let startTime: any;
        if (!startTime) {
            startTime = new Date();
        }
        let startBytes = 0;
        const currentTime: any = new Date();
        const elapsedTime: any = (currentTime - startTime) / 1000;
        const bytesUploaded = progressEvent.loaded - startBytes;
        const uploadSpeed = bytesUploaded / elapsedTime;
        startBytes = progressEvent.loaded;
        setUploadSpeed(uploadSpeed);
    }

    const readBulkUploadData = async () => {
        try {
            if (!excelFile) {
                throw new Error("Please select at least one file.");
            }
            const formData = new FormData();
            const jsonBlob = new Blob([JSON.stringify(bulkUploadData)], { type: BULK_UPLOAD_TEMPLATE_TYPE.APPLICATION_JSON });
            formData.append('resource', jsonBlob);
            formData.append('files', excelFile);
            setIsLoading(true);
            setShowPopup(true);
            const uploadFileDetails = await bulkUploadService.createBulkUploadRegistry(formData, handleUploadProgress);
            if (uploadFileDetails && uploadFileDetails.data && uploadFileDetails.data.data) {
                setIsLoading(false);
                setUploadedFileStatus(uploadFileDetails.status);
                bulkUploadService.readExcelBulkUpload(uploadFileDetails.data.data.id)
                    .then(() => {
                    })
                    .catch((error: any) => {
                        console.error("Error occurred while reading Excel bulk upload:", error);
                    });
            }
        } catch (error) {
            console.log("Error occurred while reading excel", error);
        }
    };

    return (
        <>
         <div  className={`${classes.uploadButtonWrapper} gap-y-3`}>
                <input
                    id="fileInput"
                    type="file"
                    accept=".xlsx, .xls, .csv"
                    onChange={handleFileChange}
                    className={classes.fileInput}
                />
                <label htmlFor="fileInput"className={`${classes.uploadWrapper} `} >
                    <img src={icon} className="mr-2" alt="upload" />
                    {RESOURCES.BULK_UPLOAD}
                </label>
            </div>
            {
                isLoading ? (
                    showPopup && (
                        <UploadActionProgressTemplate
                            uploadSpeed={uploadSpeed}
                            fileSize={fileSize}
                            setShowPopup={setShowPopup}
                            fileName={bulkUploadData.fileName}
                            progressPercentage={progress}
                        />
                    ))
                    :
                    (
                        showPopup && uploadedFileStatus != -1 && (
                            <UploadActionFeedbackTemplate
                                status={uploadedFileStatus === HTTP_STATUS.OK ? true : false}
                                setShowPopup={setShowPopup}
                            />
                        )
                    )
            }
        </>
    );
};

export default BulkUploadTemplate;