
import searchIcon from "./../../Assets/Icons/searchIcon.svg";
import axios from 'axios';
import {
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import UpcDialogModal from "./UpcDialogModel";
import Autocomplete from '@mui/material/Autocomplete';
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
const BaseUrl = process.env.REACT_APP_API_V1_URL;
const FindUpcSearchComponent = ({ type, catalogueId, catalogueUpcDetails, updateUPCDetails, existingUpcCode = "", isCheckBoxView = false , catalogueType}) => {
  const { user } = useAuthenticatedUser();
  const [searchUPC, setSearchUPC] = useState(existingUpcCode);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [suggestionsList, setSuggestionsList] = useState([]);
  const [upcPriceDetails, setUpcPriceDetails] = useState({});
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  useEffect(() => {
  }, []);

  const filterUpcCodesFromList = (data) => {

    if (!data || data.length === 0) {
      console.error('Data array is empty or invalid');
      return [];
    }

    const filteredList = [];

    for (const item of data) {
      if (!item.upcCode) {
        console.error('upcCode is missing in an element:', item);
        continue;
      }

      filteredList.push(item.upcCode);
    }
    const filterData = [...new Set(filteredList)]
    return filterData;
  }

  const fetchUpcDetails = async () => {

    await axios.post(`${BaseUrl}/search/upc`,
      {
        upcCode: searchUPC,
        classType: type,
        status: "ACTIVE"
      }, {
      headers: {
        Authorization: `${user.token}`,
        "Content-Type": "application/json",
      }
    }
    ).then(response => {
      setSuggestionsList(filterUpcCodesFromList(response.data.data));
    })
      .catch(error => {

      });
  };

  useEffect(() => {
    fetchUpcDetails()
  }, []);

  const fetchUpcPriceDetails = async (upcId) => {
    return new Promise((resolve, reject) => {
      axios.get(`${BaseUrl}/sku`, {
        params: {
          id: upcId
        },
        headers: {
          Authorization: `${user.token}`,
          "Content-Type": "application/json",
        },
      })
        .then(response => {
          setUpcPriceDetails(response.data.data);
          resolve(response.data.data);
        })
        .catch(error => {
          console.error("Error fetching price details:", error);
          reject(error);
        });
    });
  };


  const handleUpdateUpcDetails = async (upcSearchResults) => {
    if (upcSearchResults === null || Object.entries(upcSearchResults).length === 0 || !upcSearchResults.id) {
      return null;
    }

    try {
      const existingDetail = {
        catalogueId: catalogueId,
        upcId: upcSearchResults.id,
        status: "ACTIVE",
        profitMargin: 0,
        "upcDetails": upcSearchResults,
        "priceRange": null,
        upcPrice: '',
        minimumSellingPrice: '',
        minimumOrderQuantity:''
      };
      try {
        const priceDetails = await fetchUpcPriceDetails(upcSearchResults.id);
        if (priceDetails !== null) {
          existingDetail["priceRange"] = priceDetails;
        }
      } catch (error) {
        console.error("Error fetching price details:", error);
      }

      if (existingDetail) {
        return existingDetail;
      }

      return null;
    } catch (err) {
      return null;
    }
  };

  function areObjectsEqual(obj1, obj2) {
    let compareDetails = [
      'brand',
      'classType',
      'grade',
      'shape',
      'mainCategory',
      'productCategory',
      'superCategory',
      'standard',
    ]
    for (const key of compareDetails) {
      if (obj1[key] !== obj2[key]) {
        return true;
      }
    }
    return false;

  }


  function alertMessage(stringsArray) {
    const message = stringsArray.join(', ');
    const result = window.confirm(`Ensure that the chosen UPCs are distinct while belonging to the same category`);
  }

  const updateCatalogueUpcDetails = (upcDetails) => {
    const existingUpcs = [];
    for (const detail of upcDetails) {
      if(catalogueType==="CURRENT_INVENTORY"){
        catalogueUpcDetails=[detail]
      }else{
        const isDetailPresent = catalogueUpcDetails.some((existingDetail) => existingDetail.upcId === detail.upcDetails.id|| areObjectsEqual(existingDetail.upcDetails,detail.upcDetails));
        if (!isDetailPresent) {
          catalogueUpcDetails.push(detail);
        } else {
          existingUpcs.push(detail.upcDetails.upcCode)
        }
      }
    }

    if (existingUpcs.length) {
      alertMessage(existingUpcs)
    }

    const updatedDetailsList = [...catalogueUpcDetails];
    updateUPCDetails(updatedDetailsList)

  }
  const handleAddUpcs = async (upcSearchResultsList) => {
    var updatedDetailsList = [];
    for (const upcSearchResults of upcSearchResultsList) {
      var result = await handleUpdateUpcDetails(upcSearchResults);
      if (result) {
        updatedDetailsList.push(result);
      }
    }
    updateCatalogueUpcDetails(updatedDetailsList)
    return;
  };


  const handleUpcSearch = async () => {
    if (searchUPC.trim() === "") {
      setIsInvalid(true);
      return false;
    }

    try {
      const response = await axios.post(`${BaseUrl}/search/upc`, {
        upcCode: searchUPC,
        classType: type
      }, {
        headers: {
          Authorization: `${user.token}`,
          "Content-Type": "application/json",
        }
      });

      const upcSearchResults = response.data.data;
      if (upcSearchResults.length > 0) {
        const newUpcDetails = await handleUpdateUpcDetails(upcSearchResults[0]);
        if (newUpcDetails) {
          var updatedDetailsList = [...catalogueUpcDetails, newUpcDetails];
          updateCatalogueUpcDetails([newUpcDetails]);
        }

        setSearchUPC('');
        setIsInvalid(false);
        return newUpcDetails;
      } else {
        setIsInvalid(true);
        return false;
      }
    } catch (err) {
      return false;
    }
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const delayedFetchUpcDetails = debounce(fetchUpcDetails, 700);

  const handleSearchUPCChange = (value) => {
    setSearchUPC(value);
    setIsInvalid(false);
    if (value.trim()) {
      delayedFetchUpcDetails(value);
    } else {
      setSuggestionsList([]);
    }
  };

  const displayProductCode = (data) => {
    if (data === "STANDARD") {
      return "Enter Universal Product Code"
    } else {
      return "Enter Master Product Code"
    }
  }

  return (
    <div className="self-stretch flex flex-row items-start justify-start gap-[1.38rem]">
      <div className="flex-1 flex flex-row flex-wrap items-start justify-start">
        <div className="flex-1 h-[3rem] flex flex-row items-start justify-start">
          <div className="flex-1 h-[3rem] flex flex-row items-center justify-start">
            <Autocomplete
              className={`flex-1 rounded-md focus:outline-none ${isInvalid ? 'ring-red-500' : 'ring-blue-500'
                } focus:border-blue-500`}
              id="upc-autocomplete"
              options={suggestionsList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={`flex-1 border ${isInvalid ? 'border-red-500' : 'border-gray-300'
                    } rounded-md focus:outline-none ${isInvalid ? 'ring-red-500' : 'ring-blue-500'
                    } focus:border-blue-500`}
                  label={displayProductCode(type)}
                  variant="outlined"
                  fullWidth
                  value={searchUPC}
                  onChange={(e) => handleSearchUPCChange(e.target.value)}
                  required
                  error={isInvalid}
                />
              )}
              onInputChange={(event, newValue) => handleSearchUPCChange(newValue)}
              inputValue={searchUPC}
            />

            <button
              className="h-[3.3rem] bg-gainsboro-200 w-[4rem] flex items-center justify-center border-[1px] border-solid border-gainsboro-200"
              onClick={handleUpcSearch}
            >
              <img
                className="max-w-full max-h-full"
                alt=""
                src={searchIcon}
              />
            </button>
          </div>

        </div>
      </div>

      <div>
        <button
          className="rounded overflow-hidden flex flex-row py-[0.81rem] px-[1.63rem] items-center justify-center text-[2.5rem] border-[1px] border-solid border-blue"
          onClick={toggleModal}
        >
          <div className="relative leading-[1.38rem] font-semibold">+</div>
        </button>

        <UpcDialogModal type={type} open={isModalOpen} onClose={toggleModal} updateUpcSearchDetails={handleAddUpcs} isCheckBoxView={isCheckBoxView} />
      </div>

    </div>

  );
};

export default FindUpcSearchComponent;