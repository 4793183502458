import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from '@mui/material';
import styled from '@emotion/styled';
import StatusChips, { StatusType } from '../../Chips/StatusChips';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  Field,
  TableColors,
  TableFont,
} from '../../../Constants/Procurement/constants';
import { memo } from 'react';

interface Props<T> {
  itemList: T[];
  headings: Field[];
  fields: Field[];
  handleView?: (id: string) => void;
  handleEdit?: (id: string) => void;
  handleDelete?: (id: string) => void;
  currentPage?: number;
}

// Todo

const TableEntries = <T,>({
  itemList,
  headings,
  fields,
  currentPage,
}: Props<T>) => {
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: TableColors.blue,
      fontWeight: 'bold',
      color: 'white',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: TableFont.headerText,
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: TableColors.gray,
    },
    '&:nth-of-type(even)': {
      backgroundColor: TableColors.lightGray,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const renderFieldValue = <T extends Record<string, any>>(
    fieldName: string,
    item: T
  ): React.ReactNode => {
    switch (fieldName) {
      case 'createdAt':
        return (
          <>{moment.unix(item['createdAt']).format('DD/MM/YYYY (h:mm A)')}</>
        );
      case 'updatedAt':
      case 'lastBidTime':
        return (
          <span>{moment(item[fieldName]).format('DD/MM/YY (h:mm A)')}</span>
        );
      case 'quantity':
        return <>{`${item[fieldName]} MT`}</>;
      case 'downloadUrl':
        return (
          <div className="flex w-[80%] justify-center">
            <a
              href={item.docURL}
              target="_blank"
              rel="noreferrer"
              className={`${
                item.docURL ? 'cursor-pointer' : 'cursor-not-allowed'
              }`}
            >
              <svg
                width="21"
                height="18"
                viewBox="0 0 21 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.5538 10.6699C18.8598 10.6699 18.2959 11.2338 18.2959 11.9278V15.5061H2.70316V11.9278C2.70316 11.2338 2.1393 10.6699 1.44533 10.6699C0.751353 10.6699 0.1875 11.2338 0.1875 11.9278V16.7422C0.1875 17.4362 0.751353 18 1.44533 18H19.5538C20.2477 18 20.8116 17.4362 20.8116 16.7422V11.9278C20.8116 11.2338 20.2477 10.6699 19.5538 10.6699Z"
                  className={`${item.docURL ? 'fill-blue' : 'fill-cgray-11'}`}
                />
                <path
                  d="M9.63978 13.1205C9.87834 13.359 10.2036 13.4892 10.5289 13.4892C10.8542 13.4892 11.1795 13.359 11.4181 13.1205L15.1482 9.39036C15.647 8.89156 15.647 8.11084 15.1482 7.61204C14.6494 7.11325 13.8687 7.11325 13.3699 7.61204L11.7868 9.19519V1.25783C11.7868 0.563853 11.2229 0 10.5289 0C9.83496 0 9.27111 0.563853 9.27111 1.25783V9.23855L7.6446 7.61204C7.1458 7.11325 6.36508 7.11325 5.86628 7.61204C5.36749 8.11084 5.36749 8.89156 5.86628 9.39036L9.63978 13.1205Z"
                  className={`${item.docURL ? 'fill-blue' : 'fill-cgray-11'}`}
                />
              </svg>
            </a>
          </div>
        );
      case 'status':
        const status: string = item[fieldName].toUpperCase();
        let type: StatusType;

        switch (status) {
          case 'AWARDED':
          case 'CREATED':
            type = 'success';
            break;
          case 'STA':
          case 'DRAFT':
            type = 'pending';
            break;
          case 'ONGOING':
            type = 'ongoing';
            break;
          case 'CANCELLED':
            type = 'cancelled';
            break;
          case 'NOT_STARTED':
          case 'CLOSED':
            type = 'warning';
            break;
          case 'RE_SCHEDULE':
          case 'RESTART':
            type = 'default2';
            break;
          default:
            type = 'default';
        }

        return <StatusChips text={status} type={type} />;
      case 'productName':
        const productName = item?.item?.productCategory;
        return <>{productName}</>;
      case 'vendorName':
      case 'vendorCode':
        return <div className="text-blue font-semibold">{item[fieldName]}</div>;
      case 'id':
        return (
          <>
            <Link
              className="text-blue font-semibold underline"
              to={`${item[fieldName]}`}
            >
              {item[fieldName]}
            </Link>
            {item.new ? <span className="new-tag">New</span> : ''}
          </>
        );
      case 'auctionIds':
        return (
          <>
            <Link
              className="text-blue font-semibold underline"
              to={`${item[fieldName]}`}
            >
              {item[fieldName]}
            </Link>
            {item.new ? <span className="new-tag">New</span> : ''}
          </>
        );
      case 'type':
        const bidStatus = item[fieldName];
        let bidType: StatusType = bidStatus === 'MANUAL' ? 'manual' : 'auto';
        return <StatusChips text={bidStatus} type={bidType} />;

      default:
        return <>{item[fieldName] || ''}</>;
    }
  };

  return (
    <div className="">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {headings.map((heading: Field) => (
                <StyledTableCell
                  component="th"
                  key={heading.name}
                  align={heading.align || 'center'}
                >
                  {heading.name}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {itemList.map((itemList: T, i: number) => (
              <StyledTableRow key={i}>
                {fields.map((field: Field, index: number) => (
                  <StyledTableCell
                    component="td"
                    scope="row"
                    key={field.name}
                    align={field.align || 'center'}
                  >
                    {index === 0
                      ? currentPage
                        ? currentPage * 10 + (i + 1)
                        : i + 1
                      : renderFieldValue(
                          field.name,
                          itemList as Record<string, any>
                        )}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default memo(TableEntries);
