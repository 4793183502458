import React, { FC, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../../atoms/Button";
import { Modal } from "@mui/material";
import { useUpcService } from "../../../services/useUpcService";
import ResourceStatus from "../../atoms/ResourceStatus/ResourceStatus";
import { enumToString, getShapeName, showAttributesUom } from "../../../utils/helper";

interface ViewUpcModalTemplateProps {
	item: any;
	onUpcClose: () => void;
}

interface Attribute {
	uom: string;
	name: string;
	attributeOptions: string;
}

export interface UPCItemProps {
	subHeading: string;
	values: any;
}

export interface UPCContainerProps {
	heading: string;
	items: UPCItemProps[];
}

interface UpcData {
	superCategory: string;
	mainCategory: string;
	productCategory: string;
	manufactureName: string;
	brand: string;
	grade: string;
	standard: string;
	shape: string;
	classType: string;
	attributes?: Attribute[];
	upcCode?: string;
	keywords?: string;
	status: string;
}

const useStyles = createUseStyles((theme: any) => ({
	subContainer: {
		backgroundColor: theme.palette.background.primaryLight,
	},
	heading: {
		color: theme.palette.text.black,
	},
	subHeading: {
		color: theme.palette.text.grey,
	},
	values: {
		color: theme.palette.text.grey,
	},
	mainHeading: {
		color: theme.palette.text.grey,
	},
}));

export const UPCContainer: FC<UPCContainerProps> = ({ heading, items }) => {
	const classes = useStyles();
	return (
		<div className={`${classes.subContainer} grid gap-y-4 p-6 rounded`}>
			<div className={` ${classes.heading} text-lg font-semibold`}>{heading}</div>
			<div className="flex gap-x-6">
				{items.map((item, index) => (
					<UPCItem key={index} {...item} />
				))}
			</div>
		</div>
	);
};

export const UPCItem: FC<UPCItemProps> = ({ subHeading, values }) => {
	const classes = useStyles();
	return (
		<div className={`grid gap-y-1.5`}>
			<div className={` ${classes.subHeading} text-base font-normal`}>{subHeading}</div>
			<div className={` ${classes.values} text-base font-medium`}>{values}</div>
		</div>
	);
};

const ViewUpcModalTemplate: FC<ViewUpcModalTemplateProps> = ({ item, onUpcClose }) => {

	const classes = useStyles();
	const upcService = useUpcService();
	const [upc, setUpcTo] = useState<UpcData[] | null>(null);

	const loadUpc = async () => {
		try {
			const params = {
				upcId: item.id,
			};
			const response = await upcService.searchUpc(params);
			if (response && response.status === 200 && response.data && response.data.data) {
				setUpcTo(response.data.data);
			} else {
				console.error("Failed to fetch product upc data:", response);
			}
		} catch (error) {
			throw error;
		}
	};

	useEffect(() => {
		loadUpc();
	}, [item.id]);

	const transform = (data: any, type: string) => {
		; try {
			if (data) {
				var parsedData = JSON.parse(data);
				var specificData = parsedData[type] || [];
				return specificData;
			} else {
				return [];
			}
		} catch (error) {
			console.error("Error parsing JSON:", error);
			return [];
		}
	}

	const upcSchema: UPCContainerProps[] = [
		{
			heading: "Category",
			items: [
				{ subHeading: "Super Category", values: upc && upc[0]?.superCategory },
				{ subHeading: "Main Category", values: upc && upc[0]?.mainCategory },
				{ subHeading: "Product Category", values: upc && upc[0]?.productCategory },
				{ subHeading: "Manufacturer", values: upc && upc[0]?.manufactureName },
				{ subHeading: "Brand", values: upc && upc[0]?.brand },
			],
		},
		{
			heading: "Standard",
			items: [
				{ subHeading: "Grade", values: upc && upc[0]?.grade },
				{ subHeading: "Standard", values: upc && upc[0]?.standard },
			],
		},
		{
			heading: "Product Classification",
			items: [
				{ subHeading: "Shape", values: upc && getShapeName(upc[0]?.shape) },
				{ subHeading: "Class", values: upc && upc[0]?.classType },
			],
		}
	];

	return (
		<Modal open={true} onClose={onUpcClose} closeAfterTransition>
			<div className="bg-white m-auto mt-16 rounded w-3/5 h-4/5">
				<div className="grid gap-6 h-full p-6 overflow-y-auto">
					<div className="grid gap-3">
						<div className="pb-3 flex justify-between border-b-2 border-solid">
							<p className={` ${classes.mainHeading} text-2xl font-semibold`}>
								Product Code : <span>{upc && upc[0]?.upcCode}</span>
							</p>
							<CloseIcon className="cursor-pointer" onClick={onUpcClose} />
						</div>

						{upcSchema.map((schema, index) => (
							<UPCContainer key={index} heading={schema.heading} items={schema.items.map(item => ({
								subHeading: item.subHeading,
								values: item.values,
							}))} />
						))}

						{/* <div className={ `${ classes.subContainer } grid gap-y-4 p-6 rounded` }>
							<div className={ `${ classes.heading } text-lg font-semibold` }>General Info</div>
							<div className="grid grid-cols-5 gap-x-6 gap-y-3">
								{upc && upc[0] && upc[0].attributes && transform(upc[0].attributes, "GENERALIZATION").map(
									(item: Attribute, index: number) => (
										<div className="grid gap-y-1.5" key={ index }>
											<div className={` ${ classes.subHeading } text-base font-normal m-auto` }>{ item.name }</div>
											<div className={` ${ classes.values } w-fit mx-auto px-3 py-auto h-fit py-1 text-base font-medium rounded-full border border-input-border` }>{ item.attributeOptions }</div>
										</div>
									)
								)}
							</div>
						</div> */}

						<div className={`${classes.subContainer} grid gap-y-4 p-6 rounded`}>
							<div className={`${classes.heading} text-lg font-semibold`}>Product Specification</div>
							<div className="grid grid-cols-5 gap-x-6 gap-y-3">
								{upc && upc[0] && upc[0].attributes && transform(upc[0].attributes, "SPECIFICATION").map(
									(item: Attribute, index: number) => (
										<div className="grid gap-y-1.5" key={index}>
											<div className={` ${classes.subHeading} text-base font-normal m-auto`}>{item.name}</div>
											<div className={` ${classes.values} w-fit mx-auto px-3 py-auto h-fit py-1 text-base font-medium rounded-full border border-input-border`}>
												{showAttributesUom(item.attributeOptions, item.attributeOptions  , item.uom )}
											</div>
										</div>
									)
								)}
							</div>
						</div>

						<div className={`${classes.subContainer} grid gap-y-4 p-6 rounded`}>
							<div className={`${classes.heading} text-lg font-semibold`}>Keywords</div>
							<div className="flex gap-x-3 gap-y-3">
								{upc && upc[0] && upc[0].keywords && upc[0].keywords.toLowerCase().split(',').map(
									(item: string, index: number) => (
										<div className="grid gap-y-1.5" key={index}>
											<div className={` ${classes.values} w-fit mx-auto px-3 py-auto h-fit py-1 text-base font-medium rounded-full border border-input-border`}>
												{item.trim()} {/* Displaying keyword here */}
											</div>
										</div>
									)
								)}
							</div>
							<div className="font-semibold">
								Status:{" "}{upc && <ResourceStatus status={enumToString(upc[0]?.status)} />}
							</div>
						</div>
					</div>

					<div className="flex justify-end">
						<Button variant="outlined" onClick={onUpcClose} label="Cancel" />
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ViewUpcModalTemplate;
