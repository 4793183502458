import { useAuthenticatedUser } from '../hooks/useAuthenticatedUser';
import { API_METHOD } from '../utils/constant';
import { request } from '.';
import { replaceUrlParams } from '../utils/helper';

const FINISHED_PRODUCT_URLS = {
    GET_ALL_FINISHED_PRODUCTS: "/customers/:userId/finished-products",
    CREATE_FINISHED_PRODUCTS: "/finished-products/customer/:userId",
    FINISHED_PRODUCT_ITEM: "/finished-products/:id",
    DELETE_FINISHED_PRODUCT_ITEM: "/finished-products/:id/:imageIds"
}

interface FinishedProductsGetRequestBody {
    name: string,
    applicationType: string[],
    productDescription: string,
}

interface IFinishedProductParams {
    page: number;
    size: number;
    sort?: string;
}

export const useFinishedProductService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();

    const getAllFinishedProducts = (userId: number, params: IFinishedProductParams ) => {
        return request(API_METHOD.GET, replaceUrlParams(FINISHED_PRODUCT_URLS.GET_ALL_FINISHED_PRODUCTS, { userId }), authenticatedUser, null, { params });
    }

    const createFinishedProduct = (data: FinishedProductsGetRequestBody, userId: number) => {
        return request(API_METHOD.POST, replaceUrlParams(FINISHED_PRODUCT_URLS.CREATE_FINISHED_PRODUCTS, { userId }), authenticatedUser, data);
    };

    const getFinishedProductId = (id: number) => {
        return request(API_METHOD.GET, replaceUrlParams(FINISHED_PRODUCT_URLS.FINISHED_PRODUCT_ITEM, { id }), authenticatedUser);
    }

    const updateFinishedProductItem = (data: FinishedProductsGetRequestBody, id: number) => {
        return request(API_METHOD.PUT, replaceUrlParams(FINISHED_PRODUCT_URLS.FINISHED_PRODUCT_ITEM, { id }), authenticatedUser, data);
    }

    const deleteFinishedProductItem = (id: number, imageIds: string) => {
        return request(API_METHOD.DELETE, replaceUrlParams(FINISHED_PRODUCT_URLS.DELETE_FINISHED_PRODUCT_ITEM, { id, imageIds }), authenticatedUser);
    }

    return {
        getAllFinishedProducts,
        createFinishedProduct,
        getFinishedProductId,
        updateFinishedProductItem,
        deleteFinishedProductItem,
    };
};
