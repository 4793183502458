import CategoryFilterTemplate from "./CategoryFilter.template";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState, useMemo } from "react";
import { createUseStyles } from "react-jss";
import { ICategoriesSearch, ICategoriesSelectedByManufacturer } from "../AdminMaster/Brand/BrandForm.template";

const useStyles = createUseStyles((theme: any) => ({
	addIcon: {
		backgroundColor: theme.palette.background.primaryLightAccent,
	}
}));

interface ICategorySearchTemplateProps {
	updateSearchParams: (index: number, key: string, payload: ICategoriesSearch) => void;
	selectedCategories: ICategoriesSearch[];
	addCategorySearch: () => void;
	removeCategorySearch: (index: number) => void;
	mode: string;
	superCategories?: ICategoriesSelectedByManufacturer[];
	mainCategories?: ICategoriesSelectedByManufacturer[];
	productCategories?: ICategoriesSelectedByManufacturer[];
}

const CategorySearchTemplate: React.FC<ICategorySearchTemplateProps> = ({ updateSearchParams, selectedCategories, addCategorySearch, removeCategorySearch, mode, superCategories, mainCategories, productCategories }) => {

	const classes = useStyles();

	const [categories, setCategoriesTo] = useState<ICategoriesSearch[]>([]);

	const handleRemoveCategorySearchHandler = (index: number) => () => {
		removeCategorySearch(index);
	};
	
	useEffect(() => {
		setCategoriesTo(selectedCategories);
	}, [selectedCategories])

	const removeCategorySearchFilter = useMemo(() => {
		return categories?.map((category: ICategoriesSearch, index: number) => (
			<div className="flex mb-4 gap-3" key={ index }>
				<CategoryFilterTemplate
					updateSearchParams={ (key: string, payload: ICategoriesSearch) => updateSearchParams(index, key, payload) }
					searchPayload={ category }
					superCategories={superCategories || []} mainCategories={mainCategories || []} productCategoriesManufacturerDealsIn={productCategories || []}
				/>
				{ mode !== "VIEW" && (
					<div className={`border add-icon mr-2 cursor-pointer`} key={ index } onClick={ handleRemoveCategorySearchHandler(index) }>
						<CloseIcon />
					</div>) }
			</div>
		));
	}, [categories, mode]);

	const addCategorySearchFilter = useMemo(() => {
		return mode !== "VIEW" && (
			<div className={`${ classes.addIcon } p-4 text-white rounded-lg inline-block mr-2 cursor-pointer`} onClick={ addCategorySearch }>
				<AddRoundedIcon />
			</div>);
	}, [mode]);

	return (
		<div className="w-full gap-y-2">
			{ removeCategorySearchFilter }
			{ addCategorySearchFilter }
		</div>
	);
}

export default CategorySearchTemplate;
