import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { IBrandRequest, useBrandService } from '../../../../services/useBrandService';
import BrandFormTemplate from '../../../templates/AdminMaster/Brand/BrandForm.template';
import { HTTP_STATUS } from '../../../../utils/types';
import { ADMIN_ROUTES, MODE, RESOURCE_TYPE } from '../../../../utils/constant';
import { ImageData } from '../../../organisms/ImageUploader';

import { useFileService } from '../../../../services/useFileService';

const BrandCreatePage: React.FC = () => {

    const navigate = useNavigate();

    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const fileService = useFileService();
    const brandService = useBrandService();
    const [image, setImagesTo] = useState<(ImageData | File)[]>([]);

    const createBrand = async (brandRequestBody: IBrandRequest) => {
        try {
            const brandResponse = await brandService.createBrand(brandRequestBody);
            if (brandResponse.status === HTTP_STATUS.OK) {
                const resourceId: number = brandResponse.data.data.id ;
                await fileService.uploadImage(image,resourceId,RESOURCE_TYPE.BRAND);
                navigate(ADMIN_ROUTES.BRAND_LIST);
            } else if (brandResponse.data.statusMessage === "DUPLICATE_BRAND") {
                showSnackbar("error", `Brand already exists. Please try again`);
            }
        } catch (error) {
            showSnackbar("error", `Brand creation failed ${error}`)
        }
    }

    return (
        <div>
            { SnackBarComponent }
            <BrandFormTemplate brand={ null } categoryIds={ [] } onBrandSubmit={ createBrand } mode={ MODE.ADD} image={image} setImagesTo={setImagesTo} />
        </div>
    )
}

export default BrandCreatePage;
