import { FormControl, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { INDENT_TEXT } from '../../../Constants/Procurement/Indent/constants';

interface Props {
  searchHandler: (value: string) => void;
  fromDateHandler: (value: Moment) => void;
  toDateHandler: (valeu: Moment) => void;
  toDate: Moment;
  fromDate: Moment;
  searchText: string;
}
const VendorTableFilter = ({
  searchHandler,
  fromDateHandler,
  toDateHandler,
  searchText,
  toDate,
  fromDate,
}: Props) => {
  const handleChange = ({ name, value }: { name: string; value: string }) => {
    if (name === 'search') {
      searchHandler(value);
    }
  };

  return (
    <div className="flex justify-end px-4 py-3 bg-cgray-15 items-center gap-4 w-full ">
      <div className="flex items-center gap-x-8">
        <div className="flex items-center gap-x-3">
          <div className="text-blue font-semibold text-base text-nowrap">
            {INDENT_TEXT.fromDateFilterLabel}
          </div>
          <FormControl
            sx={{
              minWidth: '150px',
              maxWidth: '170px',
              borderRadius: '8px',
            }}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  borderColor: '#DDDDDD',
                }}
                format="DD/MM/YYYY"
                value={fromDate}
                name="fromDate"
                onChange={(date) => {
                  if (date) {
                    fromDateHandler(date);
                  }
                }}
              />
            </LocalizationProvider>
          </FormControl>
        </div>
        <div className="flex items-center gap-x-3">
          <div className="text-blue font-semibold text-base text-nowrap">
            {INDENT_TEXT.toDateFilterLabel}
          </div>
          <FormControl sx={{ minWidth: '150px', maxWidth: '170px' }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '8px',
                }}
                format="DD/MM/YYYY"
                maxDate={moment(new Date())}
                value={toDate}
                name="createdDate"
                onChange={(date) => {
                  if (date) {
                    toDateHandler(date);
                  }
                }}
              />
            </LocalizationProvider>
          </FormControl>
        </div>
        <div className="w-full">
          <FormControl fullWidth sx={{ minWidth: '350px' }}>
            <TextField
              sx={{
                backgroundColor: 'white',
                borderRadius: '8px',
              }}
              name="search"
              label="Search"
              variant="outlined"
              value={searchText}
              onChange={({ target }) => handleChange(target)}
              type="text"
            />
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default VendorTableFilter;
