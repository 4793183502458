import React, { useMemo, useState } from 'react'
import CustomHeading from '../../atoms/CustomHeading/CustomHeading'
import Button from '../../atoms/Button'
import { ISecondaryCatalogueForm, secondaryCatalogueSectionProps } from './SecondaryCatalogueForm.template'
import { SECONDARY_CATALOGUE_SECTIONS } from '../../../utils/types'
import { createUseStyles } from 'react-jss'
import { formatJoeditData } from '../../atoms/JoeditDiplayFormatter'
import Toggler from "../../atoms/Toggler";
import { MODE } from '../../../utils/constant';
import FormGroup from "@mui/material/FormGroup";
import ImageUploader from '../../organisms/ImageUploader'

const useStyles = createUseStyles((theme: any) => ({
	heading: {
		color: theme.palette.text.primaryDark,
		fontFamily: "Roboto",
		fontSize: "18px",
		fontStyle: "normal",
		fontWeight: 600,
		lineHeight: "20px",
	},
	subHeading: {
		color: theme.palette.text.secondaryDark,
		fontFamily: "Roboto",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: 400,
		lineHeight: "normal",
	},
	values: {
		color: theme.palette.text.secondaryDark,
		fontFamily: "Roboto",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: 500,
		lineHeight: "normal",
	},
}));

const SecondaryCataloguePreviewTemplate: React.FC<secondaryCatalogueSectionProps> = ({ formik, id, mode, setCurrentSectionTo, image, setImagesTo }) => {
	const classes = useStyles();

	const handleBack = () => {
		setCurrentSectionTo(SECONDARY_CATALOGUE_SECTIONS.IMAGES_UPLOAD);
	};
	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		const requiredFields = ["superCategory", "mainCategory", "productCategoryId", "gradeId", "shape"];
		let fieldError = false;
		for (const key of requiredFields) {
			if (key in formik.errors) {
				formik.setFieldTouched(key, true);
				fieldError = true;
			}
		}
		formik.setValues((prevValues: ISecondaryCatalogueForm) => ({
			...prevValues,

		}));
		if (fieldError === true) return;
		formik.handleSubmit();
	};

	const imageUploaderComponent = useMemo(() => (
		<ImageUploader
			mode={MODE.VIEW}
			images={image}
			isSubmitting = {formik.isSubmitting}
		/>
	), [mode, image, formik.isSubmitting]);
	return (
		<div className=' rounded bg-white py-6 px-10 '>
			<CustomHeading text={" Preview  Page"} status="" />

			<div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
				<div className={classes.heading}>Product Information</div>
				<div className="flex gap-x-6">
					<div className="grid gap-y-1.5">
						<div className={classes.subHeading}>Catalogue Name</div>
						<div className={classes.values}>{formik.values?.catalogueName}</div>
					</div>
					<div className="grid gap-y-1.5">
						<div className={classes.subHeading}>Minimum order Quantity</div>
						<div className={classes.values}>{formik.values?.minimumQuantity}</div>
					</div>
					<div className="grid gap-y-1.5">
						<div className={classes.subHeading}>Maximum Available Quantity</div>
						<div className={classes.values}>{formik.values?.availableQuantity}</div>
					</div>
				</div>
			</div>

			<div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
				<div className={classes.heading}>Applications</div>
				<div className="flex gap-x-6">
					<div className="grid gap-y-1.5">
						<div className={classes.subHeading}>{formik.values?.application?.join(', ')}</div>
					</div>
				</div>
			</div>
			<div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
				<div className={classes.heading}>Defects</div>
				<div className="flex gap-x-6">
					<div className="grid gap-y-1.5">
						<div className={classes.subHeading}>{formik.values?.defectDetails ? formatJoeditData(formik.values?.defectDetails) : 'No Data Found'}</div>
					</div>
				</div>
			</div>
			<div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
				<div className={classes.heading}>Additional Product detail</div>
				<div className="flex gap-x-6">
					<div className="grid gap-y-1.5">
						<div className={classes.subHeading}>{formik.values?.additionalProductDetails ? formatJoeditData(formik.values?.additionalProductDetails) : 'No Data Found'}</div>
					</div>
				</div>
			</div>
			<div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
				<div className={classes.heading}>Keywords</div>
				<div className="flex gap-x-6">
					<div className="grid gap-y-1.5">
						<div className={classes.subHeading}>{formik.values?.keywords?.join(', ')}</div>
					</div>
				</div>
			</div>
			<div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
				<div className={classes.heading}>Pricing</div>
				<div className="flex gap-x-6">
					<div className="grid gap-y-1.5">
						<div className={classes.subHeading}>{formik.values?.price && Object.entries(formik.values.price).map(([key, value]) => (<span key={key}> {key}: {value} </span>))}</div>
					</div>
				</div>
			</div>
			<div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
				<div className={classes.heading}>Packaging</div>
				<div className="flex gap-x-6">
					<div className="grid gap-y-1.5">
						<div className={classes.subHeading}>PackagingTypes</div>
						<div className={classes.values}>{formik.values?.packagingType?.join(', ')}</div>
					</div>
				</div>
			</div>
			<div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
				<div className={classes.heading}>Terms and condition</div>
				<div className="flex gap-x-6">
					<div className="grid gap-y-1.5">
						<div className={classes.subHeading}>{formik.values?.termsConditions ? formatJoeditData(formik.values?.termsConditions) : 'No Data Found'}</div>
					</div>
				</div>
			</div>

			<FormGroup>
				<Toggler
					title="Status"
					currentState={formik.values.status === 'ACTIVE' ? true : false}
					handleToggleChange={(type: any, value: string) =>
						value ? formik.setFieldValue("status", "ACTIVE") : formik.setFieldValue("status", "INACTIVE")
					}
					disabled={mode === MODE.VIEW ? true : false}
				/>
			</FormGroup>

			<div>
				{imageUploaderComponent}
			</div>

			<div className="flex justify-end mt-4">
				<div className="flex gap-x-3">
					<Button variant="outlined" label="Back" onClick={handleBack} />
					<Button variant="contained" label="Submit" onClick={handleSubmit} />
				</div>
			</div>
		</div>
	)
}

export default SecondaryCataloguePreviewTemplate