import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "../../../hooks/useSnackBar";
import { HTTP_STATUS } from "../../../utils/types";
import { ADMIN_ROUTES, MODE } from "../../../utils/constant";
import { useEffect, useState } from "react";
import { useUpcService } from "../../../services/useUpcService";
import MpcFormTemplate from "../../templates/MPC/MpcForm.template";
import { IMpc } from "./MpcUpdate.page";

const MpcViewPage: React.FC = () => {

    const params = useParams();
    const navigate = useNavigate();
    const upcService = useUpcService();

    const mpcId = Number(params.id);
    const [mpc, setMpcTo] = useState<IMpc | null>(null);
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const updateMpc = async (mpcUpdateRequestBody: any) => {
        if (mpcId) {
            try {
                const mpcResponse = await upcService.updateProductUPC(mpcId, mpcUpdateRequestBody);
                if (mpcResponse.status === HTTP_STATUS.OK) {
                    navigate(ADMIN_ROUTES.UPC_LIST);
                }
            } catch (error) {
                showSnackbar("error", `MPC updation failed`)
            }
        }
    }

    const fetchMpc = async () => {
        if (mpcId) {
            try {
                const mpcResponse = await upcService.getProductUPC(mpcId);
                if (mpcResponse?.status === HTTP_STATUS.OK) {
                    setMpcTo(mpcResponse?.data?.data);
                }
                else {
                    throw new Error("MPC fetch failed");
                }
            } catch (error) {
                showSnackbar("error", `MPC fetch failed`)
                setMpcTo(null);
            }
        }
    }

    useEffect(() => {
        fetchMpc()
    }, [mpcId]);

    return (
        <div>
            {SnackBarComponent}
            <MpcFormTemplate mpc={mpc} onMpcSubmit={updateMpc} mode={MODE.VIEW} />
        </div>
    )
}

export default MpcViewPage;