import React, { useEffect, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import ButtonV2 from "../../atoms/ButtonV2";
import { useCustomerGroupMasterService } from "../../../services/useCustomerGroupMasterService";
import { HTTP_STATUS } from "../../../utils/types";
import { useFormik } from "formik";
import { IMaterialFilterData } from "./GenericSerachDialog.template";
import { PRODUCT_SECTION, SORT_OPTION, STATUS_OPTION } from "../../../utils/constant";
import { IMultiSelectOption } from "../../atoms/MultiSelectInput2";
import FilterChip from "../../molecules/FilterChip/FilterChip";

interface OtherFilterFormTemplateProps {
    materialFilters: IMaterialFilterData;
    sectionType: string;
    setFilterData: (materialFilters: IMaterialFilterData) => void;
    onFilterSubmit: () => void;
    handleClose: () => void;
    handleFilterClear: () => void;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid #EBEFFA`
    },
    filterHeading: {
        color: theme.palette.textV2.primary.primary900,
    },
    selectLabel: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    configure: {
        color: theme.palette.textV2.primary.primary500,
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600,
    },
    activeProgressBar: {
        backgroundColor: "#CA4F02"
    },
    inActiveProgressBar: {
        backgroundColor: "#FD6202"
    },
    input: {
        "& .MuiInputBase-root": {
            borderRadius: "8px !important",
        },
    },
    errorMessage: {
        color: theme.palette.textV2.failure.failure600,
    },
}));

export interface ICustomer {
    name: string;
    userId: number;
}



const OtherFilterFormTemplate: React.FC<OtherFilterFormTemplateProps> = ({ materialFilters, sectionType, setFilterData, onFilterSubmit, handleClose, handleFilterClear }) => {
    const classes = useStyles();
    const customerGroupService = useCustomerGroupMasterService();
    const [customers, setcustomersTo] = useState<ICustomer[]>([]);

    const materialFilterData = {
        customerArray: materialFilters.customer ?? [],
        customerValue: {
            label: "",
            id: ""
        },
        statusArray: materialFilters.status ?? [],
        statusValue: {
            label: "",
            id: ""
        },
        sortArray: materialFilters.sort ?? [],
        sortValue: {
            label: "",
            id: ""
        },
    }
    const formik = useFormik<any>({
        initialValues: materialFilterData,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            onFilterSubmit()
            handleClose()

        }
    });

    const refreshCustomer = async (inputValue?: string) => {
        try {
            const customerResponse = await customerGroupService.getAllUser({
                page: 0,
                size: 10,
                sort: "createdAt,desc",
                userType: 'BUYER',
                search: inputValue?.trim() ?? ''
            }
            );
            if (
                customerResponse?.status === HTTP_STATUS.OK &&
                customerResponse?.data?.data?.content?.length
            ) {
                setcustomersTo(customerResponse.data.data.content);
            } else {
                throw new Error("Customer fetch failed");
            }
        } catch (error) {
            setcustomersTo([]);
        }
    };

    useEffect(() => {
        if (Object.keys(materialFilters).length === 0) {
            formik.resetForm();
            formik.setValues(materialFilterData);
        }
    }, [materialFilters]);


    const customerOptions = useMemo(() => {
        return customers?.map((customer: ICustomer) => ({ label: `${customer.name} - ${customer.userId}`, value: customer.userId })) ?? []
    }, [customers])

    useEffect(() => {
        refreshCustomer();
    }, []);

    const updateSelected = (key: string) => (data: IMultiSelectOption[] | null) => {
        if (data && key === 'customer') {
            formik.setFieldValue(`${key}Array`, data);
            setFilterData({
                ...materialFilters,
                customer: data
            })
        } else if (data) {
            formik.setFieldValue(`${key}Array`, [data[0]]);
            setFilterData({
                ...materialFilters,
                [key]: [data[0]]
            })
        }
    };

    const handleClearClick = (key: string) => {
        if (key === 'customer') {
            formik.setFieldValue(`${key}Array`, []);
            setFilterData({
                ...materialFilters,
                customer: []
            })

        }
        else {
            formik.setFieldValue(`${key}Array`, []);
            setFilterData({
                ...materialFilters,
                [key]: null
            })

        }
    };

    return (
        <div className="grid gap-y-4">
            <div className={`${classes.container} p-4 m-4 grid gap-y-5 rounded-lg`}>
                <div className="flex justify-between">
                    <div className={`${classes.filterHeading} text-lg font-medium my-auto`}>Search By Others</div>
                </div>
                <div className="grid grid-cols-3 gap-x-6 gap-y-6">

                    <div className="grid gap-y-2 h-fit">
                        <FilterChip
                            options={customerOptions}
                            label="Customer Name+ID"
                            value={formik.values.customerArray}
                            onchange={updateSelected('customer')}
                            placeholder="Search Customer"
                            ButtonComponent={ButtonV2}
                            buttonLabel="Clear All"
                            buttonOnClick={() => handleClearClick('customer')}
                            textFieldPlaceholder='Search...'
                            setSearchTerm={refreshCustomer}
                            optionToShow={1}
                        />
                    </div>
                    <div className="grid gap-y-2 h-fit">
                        <FilterChip
                            options={STATUS_OPTION.map(status => ({ label: status.label, value: status.id }))}
                            label="Status"
                            value={formik.values.statusArray}
                            onchange={updateSelected('status')}
                            placeholder="Select Status"
                            ButtonComponent={ButtonV2}
                            buttonLabel="Clear All"
                            buttonOnClick={() => handleClearClick('status')}
                            textFieldPlaceholder='Search...'
                            setSearchTerm={() => { }}
                            optionToShow={1}
                            isSingleSelect={true}
                            isSearchable={false}
                            disabled={sectionType === PRODUCT_SECTION.SECONDARY}
                        />
                    </div>
                    <div className="grid gap-y-2 h-fit">
                        <FilterChip
                            options={SORT_OPTION.map(sort => ({ label: sort.label, value: sort.id }))}
                            label="Sort By"
                            value={formik.values.sortArray}
                            onchange={updateSelected('sort')}
                            placeholder="Select Sort By"
                            ButtonComponent={ButtonV2}
                            buttonLabel="Clear All"
                            buttonOnClick={() => handleClearClick('sort')}
                            textFieldPlaceholder='Search...'
                            setSearchTerm={() => { }}
                            optionToShow={1}
                            isSingleSelect={true}
                            isSearchable={false}
                        />

                    </div>

                </div>
            </div>
        </div >
    );
};

export default OtherFilterFormTemplate;