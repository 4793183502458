import { ProductAttributeHeading } from '../../../Shared/CustomHeading/ProductAttributeHeading';
import { ProductAttributeFields } from '../../../Constants/Procurement/PurchaseRequisition/constants';
import { AttributeProps } from '../../../Constants/Procurement/PurchaseRequisition/types';

const PurchaseAttributeCard = ({ attributes = [] }: AttributeProps) => {
  const attribute = (property: string) => {
    return attributes.find((attribute) => attribute.name === property)
      ?.attributeOptions;
  };

  return (
    <div
      className={`flex flex-wrap mx-auto gap-4 px-4 py-2 rounded group-hover:bg-cgray-13 bg-cgray-5 justify-between`}
    >
      {ProductAttributeFields.map(
        ({ name, property }: { name: string; property: string }) => (
          <div key={name + property}>
            <ProductAttributeHeading
              title={name}
              value={attribute(property) || '--'}
              className={'text-sm mt-0.5'}
            />
          </div>
        )
      )}
    </div>
  );
};

export default PurchaseAttributeCard;
