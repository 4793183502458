import { useState, useMemo } from 'react';
import noData from '../../../assets/images/noDataImage.svg';
import BusinessProfileNoDataTemplate from './BusinessProfileNoData.template';
import GSTUpdateTemplate from './GSTUpdate.template';
import { IBusinessProfile } from '../../pages/CustomerManagement/ProfileOverview.page';
import GSTViewTemplate from './GSTView.template';

interface IGSTTemplateProps {
    businessProfile: IBusinessProfile | null;
    updateBusinessProfileSection: (section: string, values: any) => void;
    isEditButtonVisible?: boolean;
}

const GSTTemplate: React.FC<IGSTTemplateProps> = ({ businessProfile, updateBusinessProfileSection, isEditButtonVisible }) => {

    const [isEditing, setIsEditing] = useState<Boolean>(false);

    const GSTSectionView = useMemo(() => {
        if (isEditing) {
            return (
                <GSTUpdateTemplate
                    businessProfile={businessProfile}
                    updateBusinessProfileSection={updateBusinessProfileSection}
                    setIsEditing={setIsEditing}
                />
            );
        }
        if (businessProfile?.gstin) {
            return (
                <GSTViewTemplate
                    setIsEditing={setIsEditing}
                    businessProfile={businessProfile}
                    isEditButtonVisible={isEditButtonVisible}
                />
            );
        }
        return (
            <BusinessProfileNoDataTemplate
                heading="You haven't provided GST details for your business."
                setIsEditing={setIsEditing}
                noDataImg={noData}
                label="Add CIN Details"
            />
        );
    }, [isEditing, businessProfile, updateBusinessProfileSection]);

    return <>{GSTSectionView}</>;
};

export default GSTTemplate;
