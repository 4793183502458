import { Route, Routes } from 'react-router-dom';
import MpcListPage from '../../../components/pages/MPC/MpcList.page';
import MpcCreatePage from '../../../components/pages/MPC/MpcCreate.page';
import MpcUpdatePage from '../../../components/pages/MPC/MpcUpdate.page';
import MpcViewPage from '../../../components/pages/MPC/MpcView.page';

const MpcRouter = () => {
  return (
    <div>
      <Routes>
        <Route path="" element={<MpcListPage />} />
        <Route path='/add' element={<MpcCreatePage />} />
        <Route path='/:id/update' element={<MpcUpdatePage />} />
        <Route path='/:id' element={<MpcViewPage />} />
      </Routes>
    </div>
  )
}
export default MpcRouter;