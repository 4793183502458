import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { FormControl } from "@mui/material";
import JoditEditor from "jodit-react";
import FormGroup from "@mui/material/FormGroup";
import CloseIcon from "@mui/icons-material/Close";
import { IBrandRequest } from "../../../../services/useBrandService";
import { useManufacturerService } from "../../../../services/useManufacturerService";
import { useCategoryService } from "../../../../services/useCategoryService";
import Toggler from "../../../atoms/Toggler";
import CategoriesDisplayTemplate from "../../Category/CategoriesDisplay.template";
import CategorySearchTemplate from "../../Category/CategorySearch.template";
import Button from "../../../atoms/Button";
import { createUseStyles } from "react-jss";
import { IBrand } from "../../../pages/AdminMaster/Brand/BrandList.page";
import { IManufacturer } from "../../../pages/AdminMaster/Manufacturer/ManufacturerList.page";
import TextField from "../../../atoms/TextField";
import Select from "../../../atoms/Select";
import { ADMIN_ROUTES, CATEGORIES, DOCUMENT_TYPES, MODE, RESOLUTIONS, STATUS } from "../../../../utils/constant";
import UploadImages from "../../../organisms/UploadImages";
import { useFileService } from "../../../../services/useFileService";
import { HTTP_STATUS } from "../../../../utils/types";
import ImageUploader, { ImageData } from "../../../organisms/ImageUploader";
import { useSnackbar } from "../../../../hooks/useSnackBar";
import { titleModification } from "../../../../utils/helper";


const useStyles = createUseStyles((theme: any) => ({
	textColor: {
		color: theme.palette.text.primaryDarkLight
	},
	errorMessage: {
		color: theme.palette.action.danger,
	},
}));

interface IBrandFormProps {
	onBrandSubmit: (brandRequestBody: IBrandRequest) => void;
	brand: IBrand | null;
	categoryIds: number[] | [];
	mode: string;
	image: (ImageData | File)[];
	setImagesTo: (images: any) => void;
}

interface IBrandForm {
	id?: number | null;
	name: string;
	prefix?: string;
	status: boolean;
	logoPath?: number;
	manufacturerId: number | null;
	categoryIds?: number[];
	description: string;
}

export interface ICategoriesSearch {
	id?: number | null;
	superCategory: {
		label: string | null;
		id: number | null;
	};
	mainCategory: {
		label: string | null;
		id: number | null;
	};
	productCategory: {
		label: string | null;
		id: number | null;
	};
}

export interface ICategoriesSelectedByManufacturer {
	label: string;
	id: number;
}


const BrandFormTemplate: React.FC<IBrandFormProps> = ({ brand, categoryIds = [], onBrandSubmit, mode, image, setImagesTo }) => {

	const navigate = useNavigate();
	const editor = useRef(null);
	const classes = useStyles();

	const manufacturerService = useManufacturerService();
	const categoryService = useCategoryService();
	const fileService = useFileService();

	const [manufacturers, setManufacturersTo] = useState<IManufacturer[]>([]);
	const [selectedCategories, setSelectedCategoriesTo] = useState<ICategoriesSearch[]>([]);
	const [categoryErrorState, setCategoryErrorStateTo] = useState<boolean>(false);
	const [categoryError, setCategoryErrorTo] = useState<string>("");
	const { showSnackbar, SnackBarComponent } = useSnackbar();
	const [manufacturerCategoriesIds, setManufacturerCategoriesIds] = useState<number[] | []>([]);
	const [superCategories, setSuperCategoriesTo] = useState<ICategoriesSelectedByManufacturer[]>([]);
	const [mainCategories, setMainCategoriesTo] = useState<ICategoriesSelectedByManufacturer[]>([]);
	const [productCategories, setProductCategoriesTo] = useState<ICategoriesSelectedByManufacturer[]>([]);
	const [imageIdsToDelete, setImageIdsToDelete] = useState<number[]>([]);
	const [categories, setCategoriesTo] = useState<number[]>(categoryIds);


	const validationSchema = Yup.object().shape({
		name: Yup.string().required("Brand Name is required"),
		manufacturerId: Yup.string().required("Manufacturer Name is required"),
		description: Yup.string().min(1, "Brand Description is required"),
		status: Yup.boolean().required("Status is required"),
		prefix: Yup.string().required("prefix is required").matches(/^[A-Za-z]{2}$/, 'Prefix must be exactly two alphabetic characters'),
	});

	const getCategoryById = async (id: number) => {
		if (id) {
			try {
				let productCategory: any, superCategoryDetails: any;
				const categoryResponse = await categoryService.getCategoryById(id);
				if (categoryResponse.status === HTTP_STATUS.OK) {
					productCategory = categoryResponse;
				}
				const productCategoryDetails = productCategory?.data?.data;
				if (productCategoryDetails?.ancestors.parentCategory?.parentId) {
					const superCategoryResponse = await categoryService.getCategoryById(productCategoryDetails?.ancestors.parentCategory?.parentId);
					if (superCategoryResponse.status === HTTP_STATUS.OK) {
						superCategoryDetails = superCategoryResponse;
					}
				}
				const brandCategories: ICategoriesSearch = {
					superCategory: {
						label: superCategoryDetails?.data?.data?.name,
						id: productCategoryDetails?.ancestors.parentCategory?.parentId,
					},
					mainCategory: {
						label: productCategoryDetails?.ancestors.parentCategory?.name,
						id: productCategoryDetails.ancestors.parentCategory.id,
					},
					productCategory: {
						label: productCategoryDetails?.name,
						id: productCategoryDetails?.id,
					},
				};
				return brandCategories;
			} catch (error) {
				console.error(`Error fetching data for ID ${id}:`, error);
				return null;
			}
		}
	};

	const getBrandCategoriesByIds = async () => {
		try {
			const brandCategories: (ICategoriesSearch | null | undefined)[] = await Promise.all(manufacturerCategoriesIds.map((id: number) => getCategoryById(id)));
			const filteredBrandCategories: ICategoriesSearch[] = brandCategories.filter((brandCategory: ICategoriesSearch | null | undefined) => brandCategory !== null && brandCategory !== undefined) as ICategoriesSearch[];
			setSelectedCategoriesTo(filteredBrandCategories);
			setSuperCategoriesTo(filteredBrandCategories.map((item: any) => item.superCategory));
			setMainCategoriesTo(filteredBrandCategories.map((item: any) => item.mainCategory));
			setProductCategoriesTo(filteredBrandCategories.map((item: any) => item.productCategory));
			return filteredBrandCategories;
		} catch (error) {
			console.error("Error fetching data for IDs:", error);
		}
		return [];
	};

	useEffect(() => {
		const fetchData = async () => {
			if (manufacturerCategoriesIds) {
				await getBrandCategoriesByIds();
			}
		};

		fetchData();
	}, [manufacturerCategoriesIds]);

	const navigateToBrandTable = (path: string) => () => {
		navigate(path);
	};

	const formik = useFormik<IBrandForm>({
		initialValues: {
			name: brand?.name ?? "",
			manufacturerId: brand?.manufacturerId ?? null,
			description: brand?.description ?? "",
			status: true,
			prefix: brand?.prefix ?? ""
		},
		validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			if (mode !== MODE.VIEW) {
				if (selectedCategories.length === 0) {
					setCategoryErrorTo("Please Add Categories")
					setCategoryErrorStateTo(true);
					return;
				}

				const serializedData = selectedCategories.map(item => JSON.stringify(item));
				const uniqueSerializedData = new Set(serializedData);
				const uniqueData = Array.from(uniqueSerializedData).map(item => JSON.parse(item));

				if (selectedCategories.length > 1 && selectedCategories.length !== uniqueData.length) {
					setCategoryErrorTo("Duplicate categories found")
					setCategoryErrorStateTo(true)
					return;
				}
				for (let index in selectedCategories) {
					if (
						selectedCategories[index]?.productCategory?.id === undefined ||
						selectedCategories[index]?.productCategory?.id === null
					) {
						setCategoryErrorTo("Please Add Categories")
						setCategoryErrorStateTo(true);
						return;
					}
				}
				setCategoryErrorStateTo(false);
				setSubmitting(true);
				const categories: number[] = (selectedCategories ?? []).flatMap((item: ICategoriesSearch) => item?.productCategory?.id !== null ? [item.productCategory.id] : [])
				setCategoriesTo(categories);
				const brandRequestBody: IBrandRequest = {
					name: values.name.trim(),
					prefix: values.prefix,
					description: values.description,
					logoPath: null,
					status: values.status ? STATUS.ACTIVE : STATUS.INACTIVE,
					categoryIds: categories,
					manufacturerId: values.manufacturerId,
				};
				onBrandSubmit(brandRequestBody);
			} else {
				navigate(ADMIN_ROUTES.BRAND_LIST);
			}
		},
	});

	const getAllManufacturers = () => {
		let queryParams = {
			search: "",
			page: 0,
			size: 100,
			sort: "createdAt,desc",
		};
		manufacturerService
			.getAllManufacturers(queryParams)
			.then((response) => {
				if (response.data.data) {
					setManufacturersTo(response?.data?.data?.content);
				} else {
					setManufacturersTo([]);
				}
			})
			.catch((error) => {
				console.error("Manufacturers fetch failed - ", error);
				setManufacturersTo([]);
			});
	};

	const removeCategorySearch = (index: number) => {
		setSelectedCategoriesTo((prevSelectedCategories) => {
			if (prevSelectedCategories.length >= 1) {
				const updatedSelectedCategories = [...prevSelectedCategories];
				updatedSelectedCategories.splice(index, 1);
				return updatedSelectedCategories;
			}
			return prevSelectedCategories;
		});
		setCategoryErrorStateTo(false);
	};

	const addCategorySearch = () => {
		const newCategory = {
			superCategory: { label: "", id: null },
			mainCategory: { label: "", id: null },
			productCategory: { label: "", id: null },
		};
		setSelectedCategoriesTo((prevSelectedCategories) => [
			...prevSelectedCategories,
			newCategory,
		]);
	};

	const updateSearchParams = (index: number, key: string, category: ICategoriesSearch) => {
		setSelectedCategoriesTo((previousSelectedCategories: ICategoriesSearch[]) => {
			const updatedSelectedCategories = [...previousSelectedCategories];
			updatedSelectedCategories[index] = {
				...updatedSelectedCategories[index],
				[key]: category,
			};
			if (key === CATEGORIES.PRODUCT_CATEGORY && category !== null) {
				setCategoryErrorStateTo(false);
			}
			return updatedSelectedCategories;
		});
	};

	useEffect(() => {
		getBrandCategoriesByIds();
	}, [categoryIds]);

	useEffect(() => {
		if (mode === MODE.UPDATE || mode === MODE.VIEW) {
			formik.setValues({
				...formik.values,
				...brand,
				status: brand?.status === STATUS.ACTIVE ? true : false,
			});
		}
	}, [brand, categoryIds, mode]);

	useEffect(() => {
		getAllManufacturers();
	}, []);

	useEffect(() => {
		const fetchManufacturerDetails = async () => {
			if (formik.values.manufacturerId) {
				try {
					const manufacturerDetails: any = await manufacturerService.getManufacturerById(formik.values.manufacturerId);
					setManufacturerCategoriesIds(manufacturerDetails.data.data.categories);
				} catch (error) {
					console.error("Error while fetching manufacturer details:", error);
				}
			}
		};
		fetchManufacturerDetails();
	}, [formik.values.manufacturerId]);

	const CategoryView = useMemo(() => {
		return mode === MODE.VIEW ? (
			<CategoriesDisplayTemplate categories={selectedCategories} />
		) : (
			<CategorySearchTemplate
				updateSearchParams={updateSearchParams}
				selectedCategories={selectedCategories}
				addCategorySearch={addCategorySearch}
				removeCategorySearch={removeCategorySearch}
				mode={mode}
				superCategories={superCategories} mainCategories={mainCategories} productCategories={productCategories}
			/>
		);
	}, [mode, selectedCategories]);

	useEffect(() => {
		setCategoriesTo(categoryIds);
	}, [categoryIds])

	const cancelButton = useMemo(() => {
		if (mode === MODE.VIEW) {
			return null;
		}
		return (
			<Button
				variant="outlined"
				fullWidth
				label="Cancel"
				onClick={navigateToBrandTable(ADMIN_ROUTES.BRAND_LIST)}
			/>
		);
	}, [mode]);

	const config = useMemo(() => {
		return {
			readonly: mode === MODE.VIEW,
			placeholder: "Start typing...",
		};
	}, []);

	const removeImage = useCallback((id: number) => {
		const updatedImages = image.filter((_, currentIndex) => currentIndex !== id);
		const imageData = image[id] as ImageData;
		setImageIdsToDelete((prevImageIds: number[]) => [...prevImageIds, imageData.id as number]);
		setImagesTo(updatedImages);
	}, [image]);

	const updatedImages = useCallback((images: any) => {
		setImagesTo((prevImages: any) => [...prevImages, ...images]);
	}, [image]);

	const imageUploaderComponent = useMemo(() => (
		<ImageUploader
			mode={mode}
			images={image}
			removeImage={removeImage}
			isSubmitting={formik.isSubmitting}
			imageIdsToDelete={imageIdsToDelete}
		/>
	), [mode, image, removeImage, formik.isSubmitting]);

	const uploadImagesComponent = useMemo(() => (
		mode !== MODE.VIEW ? (
			<UploadImages
				title="Upload Images"
				currentImageCount={image.length}
				updateImages={updatedImages}
				configuration={{
					maxImageCount: 4,
					maxfilesize: 200,
					documentTypes: [DOCUMENT_TYPES.IMAGE_JPEG, DOCUMENT_TYPES.IMAGE_PNG, DOCUMENT_TYPES.IMAGE_SVG],
					documentResolution: RESOLUTIONS.BRAND,
				}}
			/>
		) : null
	), [mode, updatedImages]);

	return (
		<div className="p-3">
			<form className=" w-full" onSubmit={formik.handleSubmit}>
				<div className="grid gap-y-4 w-full">
					<div className=" flex justify-between items-center">
						<div className={`font-semibold text-2xl text-blue ${classes.textColor}`}>{mode === MODE.UPDATE ? "Update" : mode === MODE.VIEW ? "View" : "Add"} Brand</div>
						<div>
							<CloseIcon
								className="close-icon cursor-pointer"
								onClick={navigateToBrandTable(ADMIN_ROUTES.BRAND_LIST)}
							/>
						</div>
					</div>
					<div className="grid gap-y-3">
						<TextField
							type="text"
							label="Enter Brand Name"
							fullWidth
							{...formik.getFieldProps("name")}
							error={formik.touched.name && Boolean(formik.errors.name)}
							inputProps={{
								readOnly: mode === MODE.VIEW ? true : false,
								maxLength: 100,
							}}
							onBlur={(event) => {
								const camelCasedValue = titleModification(
									event.target.value
								);
								formik.setFieldValue("name", camelCasedValue);

							}}
							required
						/>
						<>
							<TextField
								{...formik.getFieldProps("prefix")}
								type="text"
								label="Enter Brand Code"
								fullWidth
								inputProps={{readOnly: mode === MODE.VIEW ? true : false,maxLength: 2,}}
								onChange={(event: any) => {
									formik.handleChange({
										target: { name: 'prefix', value: (event.target.value).toUpperCase() }
									});
								}}
								required
							/>
							{formik.touched && formik.touched?.prefix && formik.errors?.prefix && (
								<div className={classes.errorMessage}>
									<small>{formik.errors?.prefix}</small>
								</div>
							)}
						</>
						<Select
							variant="outlined"
							className="w-44"
							label="Select Manufacturer*"
							fullWidth
							{...formik.getFieldProps("manufacturerId")}
							error={
								formik.touched.manufacturerId &&
								Boolean(formik.errors.manufacturerId)
							}
							inputProps={{ readOnly: mode === MODE.VIEW ? true : false }}
							options={
								manufacturers?.map((manufacturer: IManufacturer) => ({
									value: String(manufacturer.id),
									label: manufacturer.name,
								})) || []
							}
						/>
						<h3 className={`font-semibold text-base ${classes.textColor}`}>Categories Manufacturer Deals With</h3>
						{formik.values.manufacturerId && CategoryView}
						{categoryErrorState && (<div className={classes.errorMessage}>{categoryError}</div>)}
						<div className="mt-3 mb-3">
							<FormGroup>
								<Toggler
									title="Status"
									currentState={formik.values.status}
									handleToggleChange={(type: any, value: string) =>
										formik.setFieldValue("status", value)
									}
									disabled={mode === MODE.VIEW ? true : false}
								/>
							</FormGroup>
						</div>
						<h3 className={`font-semibold text-base ${classes.textColor}`}>Enter Brand Description</h3>
						<div className="w-full">
							<FormControl fullWidth>
								<JoditEditor
									ref={editor}
									value={formik.values.description ?? ""}
									onBlur={(newContent) =>
										formik.setFieldValue(
											"description",
											newContent
										)
									}
									config={config}
								/>
								{formik.errors.description && formik.touched.description && (
									<div className={classes.errorMessage}>
										{formik.errors.description}
									</div>
								)}
							</FormControl>
						</div>
					</div>
				</div>
				{imageUploaderComponent}
				{uploadImagesComponent}
				<div className="flex justify-end w-full space-x-4 mr-9">
					<div>{cancelButton}</div>
					<Button
						variant="contained"
						type="submit"
						label={mode === MODE.VIEW ? "Close" : "Submit"}
					/>
				</div>
			</form>
		</div>
	);
};
export default BrandFormTemplate;