import { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import * as yup from "yup";
import { useFormik } from 'formik';
import { HTTP_STATUS, MODE } from '../../../../utils/types';
import { useMaterialRequirementOtherPreferenceService } from '../../../../services/useMaterialRequirementOtherPreferenceService';
import { GENERIC_EXCEPTION_CODE, MATERIAL_REQUIREMENT_CONSTANT } from '../../../../utils/constant';
import MaterialBrandManufacturerFormTemplate from './MaterialBrandManufacturerForm.template';
import MaterialBrandManufacturerViewTemplate from './MaterialBrandManufacturerView.template';

import { useSnackbar } from '../../../../hooks/useSnackBar';
import { IManufacturer } from '../../../pages/AdminMaster/Manufacturer/ManufacturerList.page';
import { IMultiSelectOption } from '../../../atoms/MultiSelectInput2';
import { useManufacturerService } from '../../../../services/useManufacturerService';
import { useBrandService } from '../../../../services/useBrandService';
import { useParams } from 'react-router-dom';
import { useBusinessProfileService } from '../../../../services/useBusinessProfileService';

export interface IMaterialBrandManufacturer {
    brands: { label: string, value: number }[],
    manufacturers: { label: string, value: number }[],
}

export interface IBrand {
    id: number,
    name: string,
    manufacturerId: number
}

interface MaterialOtherPreference {
    brands: string[] | null,
    customization: string[] | null,
}

const initialValues = {
    manufacturers: [],
    brands: []
}

const validationSchema = yup.object().shape({
    materialRequirement: yup.array().of(
        yup.object().shape({
            brands: yup.array()
                .of(yup.string())
                .min(1, 'You must select at least one brand')
                .required('You must select at least one brand'),
        })
    )
})

const MaterialBrandManufacturerTemplate = () => {
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [mode, setModeTo] = useState<MODE>(MODE.VIEW);
    const [isFormMode, setIsFormModeTo] = useState<boolean>(false);
    const materialRequirementOtherPreferenceService = useMaterialRequirementOtherPreferenceService();
    const manufacturerService = useManufacturerService();
    const brandService = useBrandService();
    const [manufacturers, setManufacturers] = useState<IManufacturer[]>([]);
    const [brands, setBrands] = useState<IBrand[]>([]);
    const params = useParams();
    const businessId = Number(params.id);
    const bussinessProfileService = useBusinessProfileService();
    const [businessProfile, setBusinessProfileTo] = useState<{ userId: number } | null>(null);

    const getBusinessProfile = async () => {
        try {
            const businessProfileResponse = await bussinessProfileService.getBusinessProfile(businessId);
            if (businessProfileResponse.status === HTTP_STATUS.OK) {
                const profileData = businessProfileResponse?.data?.data;
                setBusinessProfileTo(profileData);
            }
        } catch (error) {
            showSnackbar('error', 'Business Profile fetch failed');
        }
    };

    const formik = useFormik<IMaterialBrandManufacturer>({
        initialValues,
        validationSchema,
        onSubmit: async (value) => {
            const payload = {
                manufacturerIds: value.manufacturers.map(option => option.value),
                brands: value.brands.map(option => option.label)
            }
            const res = await materialRequirementOtherPreferenceService.updateMaterialRequirementsOtherPreference(payload, MATERIAL_REQUIREMENT_CONSTANT.BRAND_MANUFACTURER, businessProfile?.userId as number)
            if (res?.status === HTTP_STATUS.OK) {
                setIsFormModeTo(false);
                setModeTo(MODE.EDIT);
                showSnackbar('success', 'Successfully updated preference')
            } else {
                showSnackbar('error', 'Failed to update preference')
            }
        }
    })

    const getMaterialRequirementOtherPreference = async () => {
        const res = await materialRequirementOtherPreferenceService.getMaterialRequirementOtherPreference(businessProfile?.userId as number)
        if (res?.status === HTTP_STATUS.OK) {
            if (res.data.data.brands === null)
                setModeTo(MODE.ADD)
            else {
                formik.setValues({
                    ...formik.values,
                    ...{
                        brands: (res?.data?.data?.brands ?? []).map((brand: any) => ({ label: brand, value: brand })),
                        manufacturerIds: res?.data?.data?.manufacturerIds ?? [],
                    }
                })
                setModeTo(MODE.EDIT)
            }
            return await res?.data?.data;
        } else if (res?.status === HTTP_STATUS.BAD_REQUEST && res.data.exceptionCode === GENERIC_EXCEPTION_CODE.DATA_NOT_FOUND) {
            return setModeTo(MODE.ADD)
        } else {
            setModeTo(MODE.ADD)
        }
        return null;
    }

    const getManufacturers = () => {
        manufacturerService.getAllManufacturers({ page: 0, size: 100, sort: 'createdAt,desc' })
            .then((res: any) => {
                if (res?.status === HTTP_STATUS.OK)
                    setManufacturers(res?.data?.data?.content)
            })
    }

    const getBrandsByManufacturerIds = (manufacturerIds: number[]) => {
        brandService.getAllBrandsByManufacturerIds(manufacturerIds)
            .then((res: any) => {
                if (res?.status === HTTP_STATUS.OK) {
                    setBrands(res.data.data)
                }
            })
            .catch((error: any) => {
                console.error("ERROR FETCHING BRANDS: ", error)
            })
    }

    useEffect(() => {
        if (businessProfile) {
            getMaterialRequirementOtherPreference();
            getManufacturers();
        }
    }, [businessProfile])

    useEffect(() => {
        getBusinessProfile();
    }, [])

    const formatFormikData = async (manufacturers: IManufacturer[]) => {
        const res = await getMaterialRequirementOtherPreference();
        if (!res) {
            console.error("Failed to fetch Material Other Preference")
        }
        const manufacturerPayload = [];
        const brands = res?.brands?.map((brand: any) => ({ label: brand, value: brand }));
        for (let manufactureId of res?.manufacturerIds ?? []) {
            for (let manufacturer of manufacturers) {
                if (manufactureId === manufacturer.id)
                    manufacturerPayload.push({ label: manufacturer.name, value: manufacturer.id })
            }
        }
        formik.setValues({ manufacturers: manufacturerPayload, brands })

        if (mode === MODE.EDIT)
            getBrandsByManufacturerIds(res.manufacturerIds);
    }

    useEffect(() => {
        if (!manufacturers.length || mode === MODE.ADD) return;
        formatFormikData(manufacturers);
    }, [manufacturers])

    const handleSelectChange = (key: string) => (option: IMultiSelectOption[]) => {
        formik.setFieldValue(key, option);
        if (key === 'manufacturers') {
            getBrandsByManufacturerIds(option.map(option => option.value as number))
            formik.setFieldValue('brands', []);
        }
    };

    return (
        <>
            {SnackBarComponent}
            {isFormMode ? <MaterialBrandManufacturerFormTemplate
                handleSelectChange={handleSelectChange}
                formik={formik}
                manufacturers={manufacturers}
                brands={brands}
            />
                : <MaterialBrandManufacturerViewTemplate formik={formik} setModeTo={setModeTo} mode={mode} setIsFormModeTo={setIsFormModeTo} />}
        </>
    )
}

export default MaterialBrandManufacturerTemplate