import { Route, Routes } from 'react-router-dom';

import CategoryListPage from '../../../components/pages/Category/CategoryList.page'
import CategroyCreatePage from '../../../components/pages/Category/CategoryCreate.page'
import CategoryUpdatePage from '../../../components/pages/Category/CategoryUpdate.page'

const CategoryRoutes = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<CategoryListPage />} />
                <Route path='/create' element={<CategroyCreatePage />} />
                <Route path='/:id' element={<CategoryUpdatePage/>} />
            </Routes>
        </div>
    )
}

export default CategoryRoutes;