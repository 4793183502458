import moment from 'moment';
import React from 'react';
import sanitizeHtml from 'sanitize-html';
import { AUCTION_TEXT } from './../../../Constants/Procurement/ReverseAuctionRFQ/constants';

interface ReviewActionDetailsProps {
  formData: any;
}
const ReviewAddActionDetails: React.FC<ReviewActionDetailsProps> = ({
  formData,
}) => {
  return (
    <div className="flex flex-col gap-6">
      {/* Action Name */}
      <div>
        <div className="flex flex-col gap-4 bg-[rgba(237,_237,_237,_0.29)] p-4">
          <div className="font-semibold text-lg  text-text-color">
            {AUCTION_TEXT.reviewAuctionNameHeading}
          </div>
          <div className=" flex flex-col  text-text-color">
            <div className="text-base font-normal">Name Of Auction</div>
            <div className={'mt-1 font-medium text-text-color'}>
              {formData?.auctionName || '--'}
            </div>
          </div>
        </div>
      </div>
      {/* Auction Schedule */}
      <div>
        <div className="flex flex-col gap-4 bg-[rgba(237,_237,_237,_0.29)] p-4">
          <div className="font-semibold text-lg  text-text-color">
            {AUCTION_TEXT.reviewAuctionScheduleHeading}
          </div>
          <div className="flex gap-20">
            <div className="flex gap-4">
              <div className=" flex flex-col text-text-color">
                <div className="text-base font-normal">Start Date</div>
                <div className={'mt-1 font-medium text-text-color'}>
                  {moment.unix(formData?.startDate).format('DD/MM/YYYY') ||
                    '--'}
                </div>
              </div>
              <div className=" flex flex-col text-text-color">
                <div className="text-base font-normal">Start Time</div>
                <div className={'mt-1 font-medium text-text-color'}>
                  {moment.unix(formData?.startTime).format('HH:mm a') || '--'}
                </div>
              </div>
            </div>
            <div className="flex gap-4">
              <div className=" flex flex-col text-text-color">
                <div className="text-base font-normal">End Date</div>
                <div className={'mt-1 font-medium text-text-color'}>
                  {moment.unix(formData?.endDate).format('DD/MM/YYYY') || '--'}
                </div>
              </div>
              <div className=" flex flex-col text-text-color">
                <div className="text-base font-normal">End Time</div>
                <div className={'mt-1 font-medium text-text-color'}>
                  {moment.unix(formData?.endTime).format('HH:mm a') || '--'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Auction Setting */}
      <div>
        <div className="flex flex-col gap-4 bg-[rgba(237,_237,_237,_0.29)] p-4">
          <div className="font-semibold text-lg  text-text-color">
            {AUCTION_TEXT.reviewAuctionSettingHeading}
          </div>
          <div className="flex gap-7">
            <div className="flex gap-4">
              <div className=" flex flex-col text-text-color">
                <div className="text-base font-normal">
                  {AUCTION_TEXT.addAuctionCheckBoxBestPriceLabel}
                </div>
                <div
                  className={`mt-1 font-medium  ${
                    formData?.bestPrice ? 'text-approved' : 'text-rejected'
                  }`}
                >
                  {formData?.bestPrice ? 'Enable' : 'Disable'}
                </div>
              </div>
              <div className=" flex flex-col text-text-color">
                <div className="text-base font-normal">
                  {AUCTION_TEXT.addAuctionCheckBoxWinnerNameLabel}
                </div>
                <div
                  className={`mt-1 font-medium  ${
                    formData?.winnerName ? 'text-approved' : 'text-rejected'
                  }`}
                >
                  {formData?.winnerName ? 'Enable' : 'Disable'}
                </div>
              </div>
            </div>
            <div className="flex gap-4">
              <div className=" flex flex-col text-text-color">
                <div className="text-base font-normal">
                  {AUCTION_TEXT.addAuctionCheckBoxAllowBidLabel}
                </div>
                <div
                  className={`mt-1 font-medium  ${
                    formData?.autoBidAllow ? 'text-approved' : 'text-rejected'
                  }`}
                >
                  {formData?.autoBidAllow
                    ? `Enable (${formData?.maxAutoBid} times)`
                    : 'Disable'}
                </div>
              </div>
              <div className=" flex flex-col text-text-color">
                <div className="text-base font-normal">
                  {AUCTION_TEXT.addAuctionCheckBoxDynamicTimeLabel}
                </div>
                <div
                  className={`mt-1 font-medium  ${
                    formData?.dynamicTime ? 'text-approved' : 'text-rejected'
                  }`}
                >
                  {formData?.dynamicTime
                    ? `Enable (${formData?.dynamicTimeExt} times)`
                    : 'Disable'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Price Management */}
      <div>
        <div className="flex flex-col gap-4 bg-[rgba(237,_237,_237,_0.29)] p-4">
          <div className="font-semibold text-lg  text-text-color">
            {AUCTION_TEXT.reviewPriceManagementHeading}
          </div>
          <div className="flex gap-7">
            <div className="flex gap-4">
              <div className=" flex flex-col text-text-color">
                <div className="text-base font-normal">
                  {AUCTION_TEXT.reviewAuctionTargetPriceHeading}
                </div>
                <div className={`mt-1 font-medium text-text-color`}>
                  ₹{formData?.auctionTargetPrice || '--'}
                </div>
              </div>
              <div className=" flex flex-col text-text-color">
                <div className="text-base font-normal">
                  {AUCTION_TEXT.reviewAuctionReservePriceHeading}
                </div>
                <div className={`mt-1 font-medium text-text-color`}>
                  ₹{formData?.auctionReversedPrice || '--'}
                </div>
              </div>
            </div>
            <div className="flex gap-4">
              <div className=" flex flex-col text-text-color">
                <div className="text-base font-normal">
                  {AUCTION_TEXT.reviewAuctionStartPriceHeading}
                </div>
                <div className={`mt-1 font-medium text-text-color`}>
                  ₹{formData?.auctionStartPrice || '--'}
                </div>
              </div>
              <div className=" flex flex-col text-text-color">
                <div className="text-base font-normal">
                  {AUCTION_TEXT.reviewAuctionDecrementPriceHeading}
                </div>
                <div className={`mt-1 font-medium text-text-color`}>
                  ₹{formData?.decrementBidAmount || '--'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Auction Terms and Condition */}
      <div>
        <div className="flex flex-col gap-4 bg-[rgba(237,_237,_237,_0.29)] p-4">
          <div className="font-semibold text-lg  text-text-color">
            {AUCTION_TEXT.addAuctionTNCHeading}
          </div>
          <div className=" flex flex-col  text-text-color">
            <div
              className="font-medium text-text-color w-full"
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(formData.tnc || ''),
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewAddActionDetails;
