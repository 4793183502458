import React from "react";
import noImage from "../../../assets/images/noImage.jpg";
import { SkuData } from "../../pages/Lead/QuotationView.page";
import { createUseStyles } from "react-jss";
import { getShapeName } from "../../../utils/helper";

interface ProductDetailTemplateProps {
    orderProductUpc: any;
    skuData: SkuData[];
}

const useStyles = createUseStyles((theme: any) => ({
    title: {
        color: theme.palette.text.primaryDarkLight,
    },
    property: {
        color: theme.palette.text.secondaryDarkAccent,
        background: theme.palette.background.secondaryDark,
    }
}));

const ProductDetailTemplate: React.FC<ProductDetailTemplateProps> = ({ orderProductUpc, skuData }) => {
    const classes = useStyles();
    const transform = (data: any) => {
        try {
        if (data) {
            var specificationData = JSON.parse(data);
            specificationData = specificationData?.SPECIFICATION || [];
            return specificationData;
        } else {
            return [];
        }
        } catch (error) {
            console.error("Error parsing JSON:", error);
            return [];
        }
    };

    const productDetail = [
        { label: "Manufacturer Company", value: orderProductUpc?.manufactureName },
        { label: "Class", value: "STANDARD" },
        { label: "Shape", value: getShapeName(orderProductUpc?.shape) },
        { label: "Standard", value: orderProductUpc?.standard },
        { label: "Grade", value: orderProductUpc?.grade },
    ];

  return (
    <div className="flex gap-x-5">
        <div className="w-1/5">
            <img src={ orderProductUpc?.catalogueImages?.[0]?.path } alt="ProductImage" />
        </div>

        <div className={ `w-4/5 grid gap-y-4` }>
            <div className={ `${ classes.title} flex gap-x-12 w-fit` }>
                {productDetail.map((detail, index) => (
                    <div className="grid gap-y-1.5 text-sm" key={ index }>
                        <p className="font-normal">{ detail.label }</p>
                        <p className="font-medium">{ detail.value }</p>
                    </div>
                ))}
            </div>

            <div className={ `${ classes.property } w-fit flex gap-x-6 p-3 rounded` }>
                <div className={ `flex gap-x-6` }>
                    {orderProductUpc?.attributes && transform(orderProductUpc?.attributes).map(
                        (property: any, i: any) => (
                        <div className="grid text-sm" key={ i }>
                            <p className="font-normal">{ property?.name } {property?.uom && ` (${property.uom})`}</p>
                            <p className="font-medium">{ property?.attributeOptions }</p>
                        </div>
                        )
                    )}
                </div>
            </div>

            <div className={ `${ classes.title } w-fit flex gap-x-8` }>
                <div className="grid gap-y-3">
                    <p className="text-base font-normal">Packaging Types</p>
                    <p className="text-base font-medium">{skuData.map((e: any) => e.packaging).join(', ') }</p>
                </div>
                <div className="grid gap-y-3">
                    <p className="text-base font-normal">Warehouse</p>
                    <p className="text-base font-medium">{ skuData.map((e: any) => e.warehouse).join(', ') }</p>
                </div>
            </div>
        </div>
    </div>
  );
};

export default ProductDetailTemplate;
