import React, { useContext, useEffect, useState } from "react";
import "./InventoryTable.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  SET_INVENTORY_FORM_DATA,
  SET_MODE,
  SET_INVENTORY_ID,
  SET_SKU_MODE,
} from "../../../Core/Context/inventory/NewInventoryProvider";
import InventoryContext from "../../../Core/Context/inventory/NewInventoryContext";
import axios from "axios";
import { CustomTable } from "../../../Shared/CustomTable/CustomTable";
import { CustomButton } from "../../../Shared/Button/CustomButton";
import { Select,MenuItem,InputLabel,FormControl } from "@mui/material";
import { SHAPES } from "../../Catalogue/Filters/InventoryFilters";
import debounce from 'lodash/debounce';
import FiltersIndex, { FILTERS_TYPE } from "../../Catalogue/Filters/FiltersIndex";
import CustomHeading from "../../../Shared/CustomHeading/CustomHeading";
import { useAuthenticatedUser } from "../../../../hooks/useAuthenticatedUser";
import { BULK_UPLOAD_TEMPLATE_TYPE, LOCAL_PREVIOUS_URL_SECTIONS, RESOURCE_TYPE } from "../../../../utils/constant";
import excelIcon from "../../../../assets/icons/excel.svg";
import BulkUploadTemplateDownload from "../../../../components/organisms/BulkUpload/BulkUploadTemplateDownload.tsx"
import { useBulkUploadService } from "../../../../services/useBulkUploadService";
import BulkUploadTemplate from "../../../../components/organisms/BulkUpload/BulkUploadTemplate.tsx";
import uploadIcon from "../../../../assets/icons/upload.svg";

const BaseUrl = process.env.REACT_APP_API_V1_URL;

const InventoryTable2 = () => {
  const { user } = useAuthenticatedUser();

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search); 

  const [inventoryData, setInventoryData] = useState([]);
  const [currentPage, setCurrentPage] = useState(parseInt(queryParams.get('page')) || 0);
  const [pageSize, setPageSize] = useState(parseInt(queryParams.get('size')) || 10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [warehouse, setWarehouse] = useState(queryParams.get('warehouse') || "ALL");

  const [warehousesFromDb, setWarehousesFromDb] = useState([]);
  const [status, setStatus] = useState(queryParams.get('status') || "ALL");
  const [upcCode, setUpcCode] = useState(queryParams.get('upcCode') || "");
  const inventoryContextData = useContext(InventoryContext);
  const [ selectedProductCategory, setSelectedProductCategory ] = useState(queryParams.get('categoryId') || "ALL");
  const [ productCategories, setProductCategories ] = useState([]);
  const [selectedIds, setSelectedIdsTo] = useState({
    standards: [],
    grades: [],
    manufacturers: [],
    brands: [],
    shapes: []
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [sorting, setSorting] = useState(queryParams.get('sort') || 'createdAt,desc');

  const headings = [
    "Sr. No.",
    "SKUCode",
    "MPC Code",
    "Product",
    "Quantity",
    "Warehouse",
    "Created Date",
    "Modified Date",
    // "Status",
    "Action",
  ];

  const fields = [
    { name: "skuCode" },
    { name: "upcCode" },
    { name: "productName" },
    { name: "netWeight" },
    { name: "warehouseName" },
    { name: "createdAt" },
    { name: "updatedAt" },
    // { name: "status" },
  ];

  const bulkUploadService = useBulkUploadService();

  const getShapeNamesByIds = (ids) => {
    if (!ids || ids.length === 0) {
      return [];
    }
    return ids.map(id => {
      const shape = SHAPES.find(shape => shape.id === id);
      return shape ? shape.name : null;
    });
  };

  const buildPayload = (filterData) => {
    return Object.keys(filterData).reduce((payload, key) => {
      if (filterData[key] && filterData[key].length > 0) {
        const payloadKey = key === 'shapes' ? key : key.slice(0, -1) + 'Ids';
        payload[payloadKey] = key === 'shapes' ? getShapeNamesByIds(filterData[key]) : filterData[key];
      }
      return payload;
    }, {});
  };

  const fetchData = (filterData,page ,size) => {
    var wareStatus = warehouse;
    var statusState = status;
    var categoryId = selectedProductCategory;
    if (warehouse === "ALL") {
      wareStatus = "";
    }
    if (status === "ALL") {
      statusState = "";
    }
    if(selectedProductCategory==="ALL"){
      categoryId=""
    }
    // const url = `${BaseUrl}/inventories-new/NONSTANDARD?page=${page}&size=${size}&warehouse=${wareStatus}&status=${statusState}&categoryId=${categoryId}&upcCode=${upcCode}&sort=createdAt,desc`;
    const url = `${BaseUrl}/inventories-new/NONSTANDARD?page=${page}&size=${size}&sort=${sorting}&warehouse=${wareStatus}&status=${statusState}&categoryId=${categoryId}&upcCode=${upcCode.trim()}`;
    const payload = buildPayload(filterData);
    axios
      .post(url, payload, {
        headers: {
           Authorization: `${user.token}`,
           "Content-Type": "application/json",
        }
     })
      .then((response) => {
        const { content, totalElements } = response?.data?.data;

        setTotalPages(response?.data?.data?.totalPages);
        setInventoryData(content);
        setTotalRecords(totalElements);
      })
      .catch((err) => {
        console.log("Catalogue Table Error - ", err);
      });
  };
  const fetchWarehouse = () => {
    const url = `${BaseUrl}/warehouse/all?page=0&size=5&search=AVIGHNA`;
    axios
      .get(url,{
        headers: {
           Authorization: `${user.token}`,
           "Content-Type": "application/json",
        }
     })
      .then((response) => {
        const { content, totalElements } = response?.data?.data;

        setWarehousesFromDb(content);
        warehousesFromDb?.map((curwareh) => {
          return curwareh?.name;
        });
      })
      .catch((err) => {
        console.log("Catalogue Table Error - ", err);
      });
  };

  const debouncedSearch = debounce((value) => {
    fetchData(selectedIds, currentPage, pageSize);
  }, 300);

  useEffect(() => {
    debouncedSearch();
    inventoryContextData.updateInventoryDetailsState(
      "NONSTANDARD",
      SET_SKU_MODE
    );
  }, [currentPage, pageSize, warehouse, status, sorting, upcCode, selectedProductCategory]);

  useEffect(() => {
    const params = new URLSearchParams();
  
    currentPage && params.set('page', currentPage);
    pageSize && params.set('size', pageSize);
    warehouse && params.set('warehouse', warehouse);
    status && params.set('status', status);
    sorting && params.set('sort', sorting);
    upcCode && params.set('upcCode', upcCode.trim());
    selectedProductCategory && params.set('categoryId', selectedProductCategory);
  
    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  }, [
    currentPage, pageSize, warehouse, status, sorting, upcCode, selectedProductCategory
  ]);

  const handleApplyFiltersCatalogue = (filterData) => {
    setSelectedIdsTo(filterData);
    fetchData(filterData, currentPage, pageSize)
  }

  const handleSortingChange = (e) => {
    setSorting(e.target.value);
  };

  const handleView = async (id) => {
    let res = await axios.get(`${BaseUrl}/inventory/${id}`,{
      headers: {
         Authorization: `${user.token}`,
         "Content-Type": "application/json",
      }
   });
    let formData = res?.data?.data;
    inventoryContextData.updateInventoryDetailsState(
      formData,
      SET_INVENTORY_FORM_DATA
    );
    const skuMode = "NONSTANDARD";

    const mode = "VIEW";
    navigate(`/inventory/preview?id=${id}&skuMode=${skuMode}&mode=${mode}`);
  };

  const handleEdit = async (id) => {
    let res = await axios.get(`${BaseUrl}/inventory/${id}`,{
      headers: {
         Authorization: `${user.token}`,
         "Content-Type": "application/json",
      }
   });
    let formData = res?.data?.data;

    inventoryContextData.updateInventoryDetailsState(
      formData,
      SET_INVENTORY_FORM_DATA
    );
    inventoryContextData.updateInventoryDetailsState(
      "NONSTANDARD",
      SET_SKU_MODE
    );
    inventoryContextData.updateInventoryDetailsState("EDIT", SET_MODE);
    inventoryContextData.updateInventoryDetailsState(id, SET_INVENTORY_ID);

    const skuMode = "NONSTANDARD";
    const mode = "EDIT";
    const preUrl = location?.search;
    localStorage.setItem(LOCAL_PREVIOUS_URL_SECTIONS.NON_STANDARD_INVENTORY_PREVIOUS_URL, preUrl);
    navigate(`/inventory/step1?id=${id}&skuMode=${skuMode}&mode=${mode}`);
  };

  const handleDelete = async (inventoryId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Catalogue?"
    );
    if (confirmDelete) {
      axios
        .delete(`${BaseUrl}/inventory/${inventoryId}`,{
          headers: {
             Authorization: `${user.token}`,
             "Content-Type": "application/json",
          }
       })
        .then((response) => {
          if (response?.data) {
            let filteredData = inventoryData?.filter(
              (x) => x.id !== inventoryId
            );
            setInventoryData(filteredData);

            inventoryContextData.retreiveDetails(
              inventoryContextData?.inventoryDetailState?.pageSize,
              inventoryContextData?.inventoryDetailState?.pageNumber
            );

            return;
          }
        })
        .catch((error) => {
          console.error("Error deleting item:", error);
        });
    }
  };

  const addSku = () => {
    inventoryContextData.updateInventoryDetailsState(
      {},
      SET_INVENTORY_FORM_DATA
    );
    inventoryContextData.updateInventoryDetailsState("ADD", SET_MODE);
    inventoryContextData.updateInventoryDetailsState(
      "NONSTANDARD",
      SET_SKU_MODE
    );
    const skuMode = "NONSTANDARD";
    const mode = "ADD";
    const preUrl = location?.search;
    localStorage.setItem(LOCAL_PREVIOUS_URL_SECTIONS.NON_STANDARD_INVENTORY_PREVIOUS_URL, preUrl);
    navigate(`/inventory/step1?id=${null}&skuMode=${skuMode}&mode=${mode}`);
  };
  
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "warehouse") {
      setWarehouse(value);
    } else if (name === "status") {
      setStatus(value);
    } else if (name === "upcCode") {
      setUpcCode(value);
    }
  };

  const handleProductCategory = (e) => {
    setSelectedProductCategory(e.target.value);
  };
  
  const convertToOptions = (data) => {
    if (!Array.isArray(data)) {
      throw new Error('Input data should be an array.');
    }

    return data.map((item) => {
      if (
        !item ||
        typeof item !== 'object' ||
        !item.hasOwnProperty('id') ||
        !item.hasOwnProperty('name')
      ) {
        throw new Error('Invalid item in data array.');
      }

      return {
        label: item.name,
        id: item.id,
      };
    });
  };

  useEffect(() => {
    axios
      .get(`${BaseUrl}/categories?sort=created_at,desc`, {
        params: {
          level: 2,
          status: "ACTIVE",
          page: 0,
          size: 100
        },
        headers: {
          Authorization: `${user.token}`,
          "Content-Type": "application/json",
        }
      })
      .then((response) => {
        const categoryOptions = convertToOptions(response?.data?.data?.content)
        setProductCategories(categoryOptions);
      })
      .catch((error) => {
        console.error('Error fetching product categories:', error);
      });
      fetchWarehouse();
}, []);

const getTemplateFile = () => {
  return bulkUploadService.getBulkUploadTemplate({},
      { resourceType: RESOURCE_TYPE.NON_STANDARD_INVENTORY });
}
  return (
    <div className="grid gap-y-3 m-auto">
      <div className="flex justify-between mt-4 ">
        <CustomHeading text={"Non Standard Prime"} status="" />
      
        <div className="flex gap-3">
          <BulkUploadTemplateDownload icon={excelIcon} getTemplateFile={getTemplateFile} type={BULK_UPLOAD_TEMPLATE_TYPE.APPLICATION_OCTET_STREAM} />
          <BulkUploadTemplate icon={uploadIcon} section={RESOURCE_TYPE.NON_STANDARD_INVENTORY} />
          <CustomButton variant="Primary" onClick={addSku}>
            Add SKU
          </CustomButton>
        </div>
      </div>
      <div className="flex justify-between mt-2">
        <div className="flex gap-x-4 w-3/5">
          <div className="w-1/5">
            <FormControl fullWidth>
              <InputLabel id="status-label">Warehouses</InputLabel>
              <Select
                name="warehouse"
                label="Warehouses"
                value={warehouse || ""}
                onChange={handleChange}
                id="select"
              >
                <MenuItem key={0} value="ALL">
                  All
                </MenuItem>
                {warehousesFromDb?.map((curwarehouse, index) => (
                  <MenuItem key={index} value={curwarehouse?.id}>
                    {curwarehouse?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="w-2/5">
            <FormControl fullWidth>
              <InputLabel id="sorting-label">Sort</InputLabel>
              <Select
                labelId="sorting-label"
                id="sorting-select"
                label="Sort"
                value={sorting}
                onChange={handleSortingChange}
              >
                <MenuItem value="createdAt,asc">
                  Created Date(Ascending)
                </MenuItem>
                <MenuItem value="createdAt,desc">
                  Created Date(Descending)
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="w-1/5">
            <FormControl fullWidth>
              <InputLabel id="sorting-label">Product Category</InputLabel>
              <Select
                labelId="sorting-label"
                id="sorting-select"
                label="Product Category"
                value={selectedProductCategory}
                onChange={handleProductCategory}
                
              >
                <MenuItem key={0} value="ALL">
                  All
                </MenuItem>
                {productCategories.map((option, index) => (
                  <MenuItem key={index} value={option?.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="hidden">
            <FiltersIndex
              filterType={FILTERS_TYPE.INVENTORY}
              updateFilters={handleApplyFiltersCatalogue}
              filtersData={selectedIds}
            />
          </div>
        </div>
        <div className="h-full">
          <input
            className=" rounded border py-4 px-3 h-full"
            name="upcCode"
            value={upcCode}
            onChange={handleChange}
            type="text"
            placeholder="Search By MPC Code"
          />
        </div>
      </div>
      <CustomTable
        currentPage={currentPage}
        totalPages={totalPages}
        totalRecords={totalRecords}
        pageSize={pageSize}
        headings={headings}
        data={inventoryData}
        fields={fields}
        inventoryData={inventoryData}
        entries={1}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        handleView={handleView}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
    </div>
  );
};

export default InventoryTable2;
