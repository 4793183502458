import { InputLabel } from '@mui/material';

interface CustomLabelProps {
  disabled: boolean;
  name: string;
  label: string;
  value?: boolean;
  error?: boolean;
}

const CustomLabel: React.FC<CustomLabelProps> = ({
  disabled,
  name,
  label,
  error = false,
  value,
}) => {
  return (
    <InputLabel
      sx={{
        color: error ? '#d32f2f' : '',
        '&.Mui-focused': {
          mt: '0px !important',
          color: error ? '#d32f2f' : '',
          backgroundImage: disabled
            ? 'linear-gradient(to bottom, #fff 50%, #F3F3F3 50%)'
            : '',
        },
        '&.MuiInputLabel-animated': {
          mt: value ? '' : '-3.5px',
          px: '2px',
          left: '-4px',
          color: error ? '#d32f2f' : '',
          backgroundColor: disabled ? 'transparent' : '',
          backgroundImage:
            disabled && value
              ? 'linear-gradient(to bottom, #fff 50%, #F3F3F3 50%)'
              : '',
        },
      }}
      htmlFor={`outlined-${name}-input`}
    >
      {label}
    </InputLabel>
  );
};

export default CustomLabel;
