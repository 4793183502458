import React, { useContext } from 'react'
import bellIcon from "../../../Assets/Images/bell 2.svg"
import { Avatar } from '@mui/material';
import "./AdminHeader.css"
import { AuthenticatedUserContext } from '../../../../contexts/AuthenticatedUserContext';
import { useNavigate } from 'react-router-dom';
import { ADMIN_ROUTES } from '../../../../utils/constant';

const AdminHeader = () => {
    const navigate = useNavigate();
    const authenticationContext = useContext(AuthenticatedUserContext);
    const { user } = authenticationContext;

    const handleRedirect = () =>{
        navigate(ADMIN_ROUTES.USER_PROFILE)
    }
    return (
        <div className="pannel-navbar ">
            <div className="pannel-navbar-container flex">
                <div></div>
                <div className="pannel-navbar-right-section flex gap-6">
                    <div className="notfication-icon">
                        <img src={bellIcon} alt="bellIcon" />
                    </div>
                    <div className="user-profile flex gap-4 cursor-pointer" onClick={handleRedirect}>
                        <span>{user?.fullName || ""}</span>
                        <Avatar alt={user?.fullName} src="" sx={{ width: 40, height: 40 }} />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AdminHeader