import { useEffect, useState } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  Box,
  Skeleton,
  Grid,
} from '@mui/material';
import { ProductCard } from './ProductCard';
import CustomHeading from '../../../Shared/CustomHeading/CustomHeading';
import PaginationButton from '../../../Shared/Button/PaginationButton';
import VariantButton from '../../../Shared/Button/VariantButton';
import { ProductDataType } from '../../../Constants/Procurement/PurchaseRequisition/types';
import { PROCUREMENT_TEXT } from '../../../Constants/Procurement/PurchaseRequisition/constants';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import { usePurchaseRequisition } from '../../../../services/usePurchaseRequisition';

interface ProductSelectProps {
  onSubmit: (items: ProductDataType[]) => void;
}

const LocalData = (): ProductDataType[] => {
  const itemsFromLocalStorage = localStorage.getItem('selectProducts');
  return itemsFromLocalStorage ? JSON.parse(itemsFromLocalStorage) : [];
};

const ProductSelect = ({ onSubmit }: ProductSelectProps) => {
  const { user }: any = useAuthenticatedUser();

  const [selectedItems, setSelectedItems] = useState<ProductDataType[]>(
    LocalData()
  );
  const [productList, setProductList] = useState<ProductDataType[]>([]);
  const [productCategories, setProductCategories] = useState<any[]>([]);
  const [selectedProductCategory, setSelectedProductCategory] = useState('All');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState<boolean>(true);
  const { searchUpcByParams, getProductCategory } = usePurchaseRequisition();

  const handleSelect = (item: ProductDataType) => {
    const selected = () => {
      const isSelected = selectedItems.some(
        (selected) => selected.id === item.id
      );
      if (isSelected) {
        return selectedItems.filter(
          (selectedItem) => selectedItem.id !== item.id
        );
      } else {
        return [...selectedItems, item];
      }
    };
    localStorage.setItem('selectProducts', JSON.stringify(selected()));
    setSelectedItems(selected());
  };

  const fetchProductList = () => {
    const queryParams = {
      pageSize: 10,
      pageNumber: currentPage,
      productCategory:
        selectedProductCategory !== 'All' ? selectedProductCategory : undefined,
      searchTerm: searchText,
    };

    searchUpcByParams(queryParams)
      .then((response) => {
        const { content, totalElements, totalPages } = response?.data.data;
        setProductList(content);
        setTotalPages(totalPages);
        setTotalItems(totalElements);
        setLoading(false);
      })
      .catch((err) => {
        console.log('ProductList Error - ', err);
      });
  };

  const fetchProductCategory = () => {
    getProductCategory()
      .then((response) => {
        setProductCategories(response.data?.data?.content);
      })
      .catch((err) => {
        console.log('ProductList Error - ', err);
      });
  };

  useEffect(() => {
    fetchProductList();
  }, [currentPage, selectedProductCategory]);

  useEffect(() => {
    fetchProductCategory();
  }, []);

  return (
    <div>
      <div className="flex bg-cgray-2 justify-between ">
        <div className="text-blue py-6 px-4">
          <CustomHeading text="Select & Add Products" status="" />
        </div>
        <div className="flex gap-x-5 items-center px-2">
          <div className=" flex gap-x-2 items-center p-2 ">
            <span className="text-blue font-semibold">
              {PROCUREMENT_TEXT.productSelectedCategoryFilterLabel}
            </span>
            <FormControl sx={{ width: '190px' }}>
              <Select
                name="productCategory"
                value={selectedProductCategory || ''}
                onChange={(e) => setSelectedProductCategory(e.target.value)}
                id="productCategory"
                className="bg-white"
              >
                <MenuItem key={'productCategory' + 0} value="All">
                  All
                </MenuItem>
                {productCategories?.map((productCategory, index) => (
                  <MenuItem
                    key={'productCategory' + index}
                    value={productCategory.name}
                  >
                    {productCategory.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="flex p-2 item-center relative">
            <FormControl sx={{ width: '308px' }}>
              <input
                name="search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                type="text"
                onKeyUp={(e) => e.key === 'Enter' && fetchProductList()}
                placeholder="Search Product Name or UPC"
                className="bg-white  h-14 pl-2 pr-14 border border-gray rounded focus:outline-blue"
              />
            </FormControl>
            <button
              type="submit"
              className="bg-blue flex items-center w-14 justify-center absolute right-0 h-14 rounded-r"
              onClick={() => fetchProductList()}
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.3854 15.3781L22.5432 22.5452M17.8079 9.52309C17.8079 14.0997 14.0978 17.8099 9.52114 17.8099C4.94449 17.8099 1.23438 14.0997 1.23438 9.52309C1.23438 4.94644 4.94449 1.23633 9.52114 1.23633C14.0978 1.23633 17.8079 4.94644 17.8079 9.52309Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <button
            className="mx-2 w-12 border h-12 border-gray rounded flex justify-center items-center bg-white"
            onClick={() => null}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 2.408C1 1.91515 1 1.66873 1.09591 1.48049C1.18028 1.31491 1.31491 1.18028 1.48049 1.09591C1.66873 1 1.91515 1 2.408 1H15.432C15.9248 1 16.1713 1 16.3595 1.09591C16.5251 1.18028 16.6597 1.31491 16.7441 1.48049C16.84 1.66873 16.84 1.91515 16.84 2.408V3.93679C16.84 4.15203 16.84 4.25965 16.8157 4.36092C16.7941 4.45072 16.7586 4.53656 16.7103 4.61529C16.6559 4.7041 16.5798 4.7802 16.4276 4.93239L11.0924 10.2676C10.9402 10.4198 10.8641 10.4959 10.8097 10.5847C10.7614 10.6634 10.7259 10.7493 10.7043 10.8391C10.68 10.9404 10.68 11.048 10.68 11.2632V13.32L7.16 16.84V11.2632C7.16 11.048 7.16 10.9404 7.13569 10.8391C7.11413 10.7493 7.07857 10.6634 7.03032 10.5847C6.9759 10.4959 6.8998 10.4198 6.74761 10.2676L1.41239 4.93239C1.2602 4.7802 1.1841 4.7041 1.12968 4.61529C1.08143 4.53656 1.04587 4.45072 1.02431 4.36092C1 4.25965 1 4.15203 1 3.93679V2.408Z"
                stroke="#4D4D4D"
                strokeWidth="1.76"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>

      <div className="border border-cgray-4  p-4 rounded-lg grid gap-4 my-2 w-full">
        {loading &&
          [...Array(10)].map((_, index) => (
            <div className="border border-cgray-4 rounded-md px-3 py-4 hover:shadow-productBox cursor-pointer transition-all duration-100 group ">
              <Grid container alignItems={'center'} spacing={2}>
                <Grid item xs={2.5}>
                  <div className="flex items-center gap-x-4">
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      width={24}
                      height={24}
                    />
                    <Skeleton variant="rectangular" width={56} height={48} />
                    <div className="flex flex-col flex-grow">
                      <Skeleton height={15} width="60%" />
                      <Skeleton height={19} width="100%" />
                    </div>
                  </div>
                </Grid>
                {/* <Box sx={{ width: '20%' }}> */}
                {/* </Box> */}
                <Grid item xs={4.2}>
                  <Skeleton height={15} width="60%" />
                  <Skeleton height={19} />
                </Grid>
                <Grid item xs={5.3}>
                  <Skeleton height={66} />
                </Grid>
              </Grid>
            </div>
          ))}

        {productList.map((product, index) => (
          <ProductCard
            key={product.id + product.brand + index}
            product={product}
            checkbox={true}
            selected={selectedItems.some(
              (selectedProduct) => selectedProduct.id === product.id
            )}
            click={() => handleSelect(product)}
          />
        ))}
        <div className="shadow-pagination px-4 py-3 sticky mt-4 z-50 bg-white bottom-4 w-full text-blue flex justify-between items-center rounded-lg">
          <div className="w-full"></div>
          <PaginationButton
            pageNumber={currentPage}
            setPageNumber={setCurrentPage}
            totalItems={totalItems}
            totalPages={totalPages}
            text="Products"
          />
          <div className="flex items-center justify-end w-full gap-x-4">
            <span className="text-oreng">
              {selectedItems.length} {PROCUREMENT_TEXT.productSelectedText}
            </span>
            <VariantButton size="large" onClick={() => onSubmit(selectedItems)}>
              {PROCUREMENT_TEXT.next}
            </VariantButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSelect;
