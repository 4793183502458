import { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { useUserService } from '../../../services/useUserService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { HTTP_STATUS } from '../../../utils/types';
import { ADMIN_ROUTES, PASSWORD_CHECKLIST, REGEX } from '../../../utils/constant';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import * as Yup from "yup";
import Visiblity from "../../../assets/icons/outlinedVisiblity.svg"
import VisiblityCrossed from "../../../assets/icons/outlinedVisiblityCrossed.svg"

import {
  TextField,
  Checkbox,
  FormHelperText,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { useFormik } from 'formik';
import SuccessDialogTemplate from '../../templates/Customer-Management/SuccessDialog.template';
import TextFieldV2 from '../../atoms/TextFieldV2';
import ButtonV2 from '../../atoms/ButtonV2';
import { useNavigate, useParams } from 'react-router-dom';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';

const useStyles = createUseStyles((theme: any) => ({
  titleText: {
    color: theme.palette.textV2.secondary.secondary800
  },
  container: {
    border: `1px solid ${theme.palette.backgroundV2.secondary.secondary50}`
  },
  successGreenColor: {
    color: theme.palette.backgroundV2.success.success800
  },
  content: {
    color: theme.palette.button.primaryLight
  },
  checkContainer: {
    background: theme.palette.backgroundV2.tertiary.tertiary100
  },
  label: {
    color: theme.palette.textV2.primary.primary850
  },
  defaultLabel: {
    color: theme.palette.textV2.secondary.secondary700
  },
  errorRed: {
    color: theme.palette.textV2.failure.failure500
  }
}));

export interface VerifyTokenParams {
  token: string | null
}

export interface IPasswordPayload {
  oldPassword: string,
  newPassword: string,
  confirmPassword: string
}

const initialValues = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: ""
}

interface ShowPassword {
  old: boolean,
  new: boolean,
  confirm: boolean
}

interface PASSWORD_CHECKS {
  uppercase: boolean,
  minLength: boolean,
  lowercase: boolean,
  symbol: boolean,
  number: boolean,
}

const showPasswordInitialValue = {
  old: false, new: false, confirm: false
}

const validationSchema = Yup.object({
  newPassword: Yup.string().required('New Password is required'),
  confirmPassword: Yup.string().oneOf([Yup.ref('newPassword')], 'Passwords must match').required('Confirm Password is required')
})

const ChangePasswordPage = () => {
  const classes = useStyles();
  const params = useParams();
  const businessId = Number(params.id);
  const userService = useUserService();
  const { showSnackbar, SnackBarComponent } = useSnackbar();
  const bussinessProfileService = useBusinessProfileService();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<ShowPassword>(showPasswordInitialValue);
  const [isSaveActive, setIsSaveActive] = useState<boolean>(false);
  const [isSuccessDialogActive, setIsDialogActive] = useState(false);
  const [businessProfile, setBusinessProfileTo] = useState<{ userId: number } | null>(null);
  const [passwordChecks, setPasswordChecks] = useState<PASSWORD_CHECKS>({
    uppercase: false,
    minLength: false,
    lowercase: false,
    symbol: false,
    number: false,
  });

  const toggleShowPassword = (key: string) => () =>
    setShowPassword({ ...showPassword, [key]: !showPassword[key as keyof ShowPassword] })

  const toggleIsSaveDisable = () => {
    setIsSaveActive(
      Object.values(passwordChecks).every(check => check) &&
      !!!Object.keys(formik.errors).find(key => ['newPassword', 'oldPassword', 'confirmPassword'].includes(key))
    )
  }

  const handlePasswordChange = (value: string) => {
    formik.setFieldValue('newPassword', value)
    setPasswordChecks({
      uppercase: REGEX.UPPERCASE.test(value),
      minLength: value.length >= 8,
      lowercase: REGEX.LOWERCASE.test(value),
      symbol: REGEX.SYMBOL.test(value),
      number: REGEX.NUMBER.test(value),
    });
  };

  const handlePasswordSuccessResponse = (isDialogActive: boolean) => {
    setIsDialogActive(false);
  }

  const formik = useFormik<IPasswordPayload>({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      userService.updateCustomerPassword({ newPassword: values.newPassword }, businessProfile?.userId as number)
        .then(res => {
          if (res?.status === HTTP_STATUS.OK) {
            resetForm();
            setIsDialogActive(true);
          } else if (res?.status === HTTP_STATUS.BAD_REQUEST) {
            showSnackbar('error', res?.data?.statusMessage)
          } else {
            showSnackbar('error', "Error while updating password")
          }
        }).catch(error => {
          showSnackbar('error', "Something went wrong")
          console.error("Error Update Password", error)
        }).finally(() => setSubmitting(false))
    }
  });

  const getBusinessProfile = async () => {
    try {
      const businessProfileResponse = await bussinessProfileService.getBusinessProfile(businessId);
      if (businessProfileResponse.status === HTTP_STATUS.OK) {
        const profileData = businessProfileResponse?.data?.data;
        setBusinessProfileTo(profileData);
      }
    } catch (error) {
      showSnackbar('error', 'Business Profile fetch failed');
    }
  };

  useEffect(() => {
    toggleIsSaveDisable();
    handlePasswordChange(formik.values.newPassword)
  }, [formik.errors])

  useEffect(() => {
    getBusinessProfile();
  }, [businessId])

  const handleBackToDashboard = () => {
    navigate(ADMIN_ROUTES.CUSTOMER_MANAGEMENT_LIST);
  };

  return (
    <div>
      <div className={`${classes.titleText} font-semibold text-lg mb-6`}>Change Password</div>
      <SuccessDialogTemplate
        title='Password Changed!'
        content='Customer password has been changed successfully. Customer can now use their new password for future activities.'
        dialogOpen={isSuccessDialogActive}
        setDialogOpen={handlePasswordSuccessResponse}
      />
      <div className={`${classes.container} p-6 rounded-lg`}>
        <div className="auth-right-child-container flex flex-col w-full" >
          {SnackBarComponent}
          <div className="form-container flex flex-col grow w-full">
            <form className="flex flex-col gap-y-6 items-start mb-3 text-lg w-1/2" onSubmit={formik.handleSubmit}>
              <div className='w-full flex flex-col gap-y-6'>
                <div>
                  <TextFieldV2
                    label="Please Enter Your New Password"
                    {...formik.getFieldProps("newPassword")}
                    type={showPassword.new ? "text" : "password"}
                    onChange={(e) => handlePasswordChange(e.target.value)}
                    variant="outlined"
                    fullWidth
                    className='!mt-0'
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={toggleShowPassword('new')}
                          edge="end"
                          className=''
                        >
                          {showPassword.new ? <img src={VisiblityCrossed} /> : <img src={Visiblity} />}
                        </IconButton>
                      </InputAdornment>,
                    }}
                    error={formik.touched?.newPassword && Boolean(formik.errors?.newPassword)}
                  />
                  {formik.touched?.newPassword && Boolean(formik.errors?.newPassword) &&
                    <div className='flex w-full'>
                      <FormHelperText error className=''>{formik.errors?.newPassword}</FormHelperText>
                    </div>
                  }
                </div>
                <div className={`grid w-full grid-cols-2 py-4 px-5 rounded select-none pointer-none text-sm ${classes.checkContainer}`}>
                  {Object.entries(PASSWORD_CHECKLIST).map(([key, value]) => (
                    <div key={key}>
                      <Checkbox
                        size='small'
                        checked={passwordChecks[key as keyof PASSWORD_CHECKS]}
                        icon={formik.touched.newPassword && !passwordChecks[key as keyof PASSWORD_CHECKS] ? <CancelIcon className={`${classes.errorRed}`} /> : <CheckCircleOutlineIcon className={`${classes.defaultLabel}`} />}
                        checkedIcon={<CheckCircleIcon className={`${classes.successGreenColor}`} />}
                        disabled
                      />
                      <span className={` ${formik.touched.newPassword && !passwordChecks[key as keyof PASSWORD_CHECKS] ? classes.errorRed : passwordChecks[key as keyof PASSWORD_CHECKS] ? classes.successGreenColor : classes.defaultLabel}`}>
                        {value}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className='w-full'>
                <TextFieldV2
                  label="Please Confirm Your New Password"
                  {...formik.getFieldProps("confirmPassword")}
                  type={showPassword.confirm ? "text" : "password"}
                  variant="outlined"
                  className='!m-0'
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleShowPassword('confirm')}
                        edge="end"
                      >
                        {showPassword.confirm ? <img src={VisiblityCrossed} /> : <img src={Visiblity} />}
                      </IconButton>
                    </InputAdornment>,
                  }}
                  error={formik.touched?.confirmPassword && Boolean(formik.errors?.confirmPassword)}
                />
                {formik.touched?.confirmPassword && Boolean(formik.errors?.confirmPassword) &&
                  <div className='flex w-full'>
                    <FormHelperText error className=''>{formik.errors?.confirmPassword}</FormHelperText>
                  </div>
                }
              </div>
              <ButtonV2 variant="contained" label="Save" type="submit" className={`!h-12 !text-lg !mr-0 !px-6 !py-3`} isLoading={formik.isSubmitting} disabled={!isSaveActive || formik.isSubmitting}
              />
            </form>
          </div>
        </div>
      </div>
      <div className=' grid justify-items-stretch pt-6'>
        <ButtonV2
          className={` justify-self-end`}
          variant="secondaryContained"
          label="Back to Dashboard"
          onClick={handleBackToDashboard}
        />
      </div>
    </div>
  )
}

export default ChangePasswordPage