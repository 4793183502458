import React from 'react'
import TextField from '../../atoms/TextField';
import { ICategoriesSearch } from '../AdminMaster/Brand/BrandForm.template';


interface ICategoriesDisplayProps {
	categories: ICategoriesSearch[];
}

const CategoriesDisplayTemplate: React.FC<ICategoriesDisplayProps> = ({ categories }) => {
	return (
		<div className='w-full'>
			{ categories?.map((category: ICategoriesSearch, index: number) => (
				<div
					className="mt-2 w-full self-stretch flex justify-between gap-3 mb-5"
					key={ index }
				>
					<TextField
						fullWidth
						label="Super Category"
						value={ category?.superCategory?.label || "N/A" }
						inputProps={{
							readOnly: true,
							disableUnderline: true,
						}}
					/>
					<TextField
						fullWidth
						label="Main Category"
						value={ category?.mainCategory?.label || "N/A" }
						inputProps={{
							readOnly: true,
							disableUnderline: true,
						}}
					/>
					<TextField
						fullWidth
						label="Product Category"
						value={ category?.productCategory?.label || "N/A" }
						inputProps={{
							readOnly: true,
							disableUnderline: true,
						}}
					/>
				</div>
			))}
		</div>
	);
}

export default CategoriesDisplayTemplate;
