import { Route, Routes } from 'react-router-dom';

import ImageMasterCreatePage from '../../../components/pages/ImageMaster/ImageMasterCreate.page';
import ImageMasterViewPage from '../../../components/pages/ImageMaster/ImageMasterView.page';
import ImageMasterListPage from '../../../components/pages/ImageMaster/ImageMasterList.page';
import ImageMasterUpdatePage from '../../../components/pages/ImageMaster/ImageMasterUpdate.page';

const ImageMasterRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<ImageMasterListPage />} />
            <Route path="/add" element={<ImageMasterCreatePage />} />
            <Route path="/:id" element={<ImageMasterViewPage />} />
            <Route path="/:id/update" element={<ImageMasterUpdatePage />} />
        </Routes>
    )
}

export default ImageMasterRoutes
