import { useNavigate } from "react-router-dom";
import viewEyeIcon from "./../../../assets/icons/viewEyeIcon.svg";
import editPenIcon from "./../../../assets/icons/editPenIcon.svg";
import moment from "moment";
import Table, { ColumnType } from "../../organisms/Table";
import { IImageMaster, IImageMasterFilter } from "../../pages/ImageMaster/ImageMasterList.page";
import { IPagination } from "../../../utils/types";
import { catalogueClassType, makeRoute } from "../../../utils/helper";
import { ADMIN_ROUTES } from "../../../utils/constant";
import Select from "../../atoms/Select";
import TextField from "../../atoms/TextField";
import { useSecondaryInventoryService } from "../../../services/useSecondaryInventoryService";
import { useEffect, useState } from "react";
import { useBrandService } from "../../../services/useBrandService";

interface IImageMasterListTemplateProps {
	imageMasters: IImageMaster[];
	pagination: IPagination;
	handleFiltersChange: (event: any) => void;
	handlePaginationChange: any;
	handleRowsPerPageChange: any;
	imageMasterFilter: IImageMasterFilter;
}

const ImageMasterListTemplate: React.FC<IImageMasterListTemplateProps> = ({ imageMasters, pagination, handleFiltersChange, handlePaginationChange, handleRowsPerPageChange, imageMasterFilter }) => {

	const navigate = useNavigate();
	const secondaaryInventoryService = useSecondaryInventoryService();
	const brandService = useBrandService();
	const [productCategoryFilterValues, setProductCategoryFilterValues] = useState<{ value: any, label: string }[]>([{ value: null, label: "All" }]);
	const [brandFilterValues, setBrandFilterValues] = useState<{ value: any, label: string }[]>([{ value: null, label: "All" }]);

	const handleViewClick = (id: number) => (e: any) => {
		navigate(makeRoute(ADMIN_ROUTES.IMAGE_MASTER_VIEW, { params: { id: id } }));
	};

	const handleEditClick = (id: number) => (e: any) => {
		navigate(makeRoute(ADMIN_ROUTES.IMAGE_MASTER_UPDATE, { params: { id: id } }));
	};


	const schema = {
		id: "1",
		title: "",
		pagination: {
			total: pagination.totalRecords,
			currentPage: pagination.currentPage,
			isVisible: true,
			limit: pagination.pageSize,
			handleChangePage: handlePaginationChange,
			handleChangeRowsPerPage: handleRowsPerPageChange
		},
		columns: [
			{ label: "Sr No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
			{ label: "Category", key: "productCategoryName", type: "string" as ColumnType, props: { className: '' } },
			{ label: "Catalogue Type", key: "catalogueType", type: "number" as ColumnType, props: { className: '' } },
			{ label: "Brand", key: "brandName", type: "string" as ColumnType, props: { className: '' } },
			{ label: "Catalogue Shape", key: "shape", type: "string" as ColumnType, props: { className: '' } },
			{ label: "Image Count", key: "numberOfImages", type: "string" as ColumnType, props: { className: '' } },
			{ label: "Created Date", key: "createdAt", type: "datetime" as ColumnType, props: { className: '' } },
			{ label: "Updated By", key: "updatedBy", type: "string" as ColumnType, props: { className: '' } },
			{ label: "Last Modified", key: "updatedAt", type: "datetime" as ColumnType, props: { className: '' } },
			{ label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } },
		],
	};

	const Action = (id: number) => {
		return <div>
			<button onClick={handleViewClick(id)}><img src={viewEyeIcon} alt={viewEyeIcon} /></button>
			<button onClick={handleEditClick(id)}><img src={editPenIcon} alt={editPenIcon} /></button>
		</div>
	}

	const records = imageMasters.map((imageMaster: IImageMaster, index: number) => [
		pagination.pageSize * pagination.currentPage + index + 1,
		imageMaster.productCategoryName,
		catalogueClassType(imageMaster.catalogueType, imageMaster.classType),
		imageMaster.brandName,
		imageMaster.shape,
		imageMaster.numberOfImages,
		moment(imageMaster.createdAt).format("YYYY-MM-DD HH:mm:ss"),
		imageMaster.updatedBy,
		moment(imageMaster.updatedAt).format("YYYY-MM-DD HH:mm:ss"),
		Action(imageMaster.id),
	]);

	const getAllCategories = () => {
		let queryParams = {
			name: '',
			level: 2,
			parentId: null,
			page: 0,
			size: 100
		};
		secondaaryInventoryService.getCategories(queryParams)
			.then((response) => {
				if (response.data) {
					let newProductCategoryFilterValues = [{ value: null, label: "All" }];
					response?.data?.data?.content.forEach((productCategory: any) => {
						newProductCategoryFilterValues.push({ value: productCategory.id, label: productCategory.name });
					});
					setProductCategoryFilterValues(newProductCategoryFilterValues);
				}
			})
			.catch((error) => {
				setProductCategoryFilterValues([]);
			});
	};

	const getAllBrands = () => {
		let params = {
			page: 0,
			size: 100,

		}
		brandService.getAllBrands(params)
			.then((brandsResponse) => {
				if (brandsResponse?.data?.data) {
					let newBrandFilterValues = [{ value: null, label: "All" }];
					brandsResponse?.data?.data?.content.forEach((brand: any) => {
						newBrandFilterValues.push({ value: brand.id, label: brand.name });
					});
					setBrandFilterValues(newBrandFilterValues);
				}
			})
			.catch((error) => {
				setBrandFilterValues([]);
			});
	}

	useEffect(() => {
		getAllCategories();
		getAllBrands();
	}, [])

	return (
		<div className='grid gap-y-1.5'>
			<div className="flex justify-between">
				<div className="flex gap-x-4">
					<div>
						<Select
							variant="outlined"
							className="w-44"
							label="Product Category"
							name="productCategory"
							id="product-catgeory"
							value={imageMasterFilter.productCategory}
							onChange={(e) => handleFiltersChange(e)}
							options={productCategoryFilterValues}
						/>
					</div>
					<div>
						<Select
							variant="outlined"
							className='w-44'
							label="brand"
							id="catalogue-type"
							name="brand"
							value={imageMasterFilter.brand}
							onChange={(e) => handleFiltersChange(e)}
							options={brandFilterValues}
						/>
					</div>
				</div>
				<TextField
					className="w-96"
					type="text"
					label="Search by Brand or Product Category Name"
					variant="outlined"
					name="searchName"
					value={imageMasterFilter.searchName}
					onChange={handleFiltersChange}
				/>
			</div>
			<Table schema={schema} records={records} />
		</div>
	)
}

export default ImageMasterListTemplate;
