import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";

export const ATTRIBUTE_URLS = {
  GET_ALL_ATTRIBUTE: "/attributes",
};
interface IAttributeFilterRequest{
  status?: string;
  parentId?: number;
  attributeType?: string;
  name?: string;
  page?: number;
  size?: number;
  sort?: string;
}

export const useAttributeService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const getAllAttributes = (params: IAttributeFilterRequest) => {
    return request(API_METHOD.GET, ATTRIBUTE_URLS.GET_ALL_ATTRIBUTE, authenticatedUser, null, { params } );
  };

  return {
    getAllAttributes,
  };
};
