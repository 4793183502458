import { createUseStyles } from "react-jss";
import StandardGradeSearchTemplate from "./StandardGradeSearch.template";

interface StandardSectionIndexTemplateProps {
    formik: any;
    mode: string;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        backgroundColor: theme.palette.background.primaryLight,
    },
    heading: {
        color: theme.palette.text.primaryDarkLight,
    },
}));

const StandardSectionIndexTemplate: React.FC<StandardSectionIndexTemplateProps> = ({ formik, mode }) => {
    const classes = useStyles();
    return (
        <div className={`${classes.container} grid gap-y-4 p-6 rounded`}>
            <div className={` ${classes.heading} text-lg font-semibold`}>
                Standard
            </div>
            <div className='grid gap-x-4'>
                <StandardGradeSearchTemplate formik={formik} mode={mode}/>
            </div>
        </div>
    )
}
export default StandardSectionIndexTemplate;

