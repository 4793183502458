import React, { Dispatch, SetStateAction } from 'react';
import { createUseStyles } from 'react-jss';
import AddLocation from '../../../App/Assets/Images/AddLocation.svg';

import { LOCATION_STATES } from '../../../utils/types';
import { FormikProps } from 'formik';
import { ILocationGstinForm } from '../../pages/Dashboard/Location/BusinessLocation.page';
import Button from '../../atoms/Button';
import ButtonV2 from '../../atoms/ButtonV2';
import { useNavigate, useParams } from 'react-router-dom';
import { makeRoute } from '../../../utils/helper';
import { ADMIN_ROUTES, CUSTOMER_MANAGEMENT_TABS } from '../../../utils/constant';


export interface LocationSectionProps {
    currentSection? :  LOCATION_STATES;
    setCurrentSectionTo: (section: LOCATION_STATES) => void;
    formik: FormikProps<ILocationGstinForm>;
    setButtonShow? : Dispatch<SetStateAction<boolean>>
}
const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.primarylightAccent}`,
    }
}));

const AddBusinessLocationTemplate: React.FC<LocationSectionProps> = ({ formik, setCurrentSectionTo }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleBackToDashboard = ()=> {
        navigate(ADMIN_ROUTES.CUSTOMER_MANAGEMENT_LIST);
     };
    return (
        <div className=' grid gap-8'>
            <div className={`${classes.container} py-14 px-6  rounded-lg`}>
                <div className='grid gap-y-4'>
                    <div className='grid gap-y-8 justify-items-stretch'>
                        <div className='justify-self-center'>
                            <img src={AddLocation} alt="locationImage" className="" />
                        </div>
                        <div className='justify-self-center font-normal text-lg text-[#142552]'>
                            You haven't provide a location for your business.
                        </div>
                    </div>
                    <div className='grid justify-items-stretch'>
                        <ButtonV2
                            className={` justify-self-center w-auto`}
                            variant="primaryContained"
                            label="Add Location"
                            onClick={() => { setCurrentSectionTo(LOCATION_STATES.ADD_LOCATION_NO) }}
                        />
                    </div>
                </div>
            </div>
            <div className=' grid justify-items-stretch'>
                        <ButtonV2
                            className={` justify-self-end`}
                            variant="secondaryContained"
                            label="Back to Dashboard"
                            onClick={handleBackToDashboard}
                        />
                    </div>
        </div>

    )
};
export default AddBusinessLocationTemplate;
