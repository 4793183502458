import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { Checkbox, InputAdornment, TextField } from "@mui/material";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import Search from "@mui/icons-material/Search";

const useStyles = createUseStyles(() => ({
  title: {
    color: '#4D4D4D',
  },
  value: {
    color: '#5B5B5B',
  },
  search: {
    background: "#EDEDED",
    borderRadius: "4px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    width:"100%"
  },
  searchIcon: {
    color: "#A1A1A1"
  },
}));

export const ResourceFilter = ({
  label,
  isSearchable,
  defaultSize,
  resourceOptions,
  onChange,
  selectedOptions,
  renderType,
}) => {
  const classes = useStyles();
  const [showAll, setShowAll] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
  }, [renderType]);

  if (!resourceOptions) {
    return <div>No data available</div>;
  }

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.substring(0, maxLength) + '...';
    }
  }

  const handleClick = () => {
    setShowAll(!showAll);
  };

  const displayedData = Array.isArray(resourceOptions)
    ? showAll
      ? resourceOptions
      : resourceOptions.slice(0, defaultSize)
    : [];

    const searchData = displayedData.filter((item) =>
    item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleOnChange = (id) => (e) => {
    onChange(id, e.target.checked);
  };

  return (
    <div className="grid gap-y-2 p-4 border-b rounded-md">
      <div className={`${classes.title} text-base font-semibold`}>{label}</div>
      {isSearchable && (
        <div className="flex gap-x-2 rounded bg-blue">
          <TextField
            size="small"
            className={classes.search}
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              className: "px-2 h-8 text-base",
              startAdornment: (
                <InputAdornment position="start">
                  <Search className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
          />
        </div>
      )}
      {searchData &&
        searchData?.map((item) => (
          <div className="flex gap-x-2" key={item?.id}>
            <div>
              <Checkbox
                sx={{ padding: 0, color: "#C4C4C4" }}
                checked={selectedOptions?.includes(item.id)}
                onChange={handleOnChange(item.id)}
              />
            </div>
            <div className={`${classes.value} my-auto text-sm font-normal`} title={item.name}>
              {truncateText(item.name, 40)}
            </div>
          </div>
        ))}
      {resourceOptions.length > defaultSize && (
        <div className="flex gap-x-2 cursor-pointer" onClick={handleClick}>
          {showAll ? <ArrowDropUp /> : <ArrowDropDown />}
          <div
            className={`${classes.value} my-auto text-sm font-normal `}
          >
            {showAll ? "See Less" : "See More"}
          </div>
        </div>
      )}
    </div>
  );
};
