import React, { Dispatch, SetStateAction } from 'react';
import { createUseStyles } from 'react-jss';
import EmptyCertificate from '../../../assets/icons/emptyCertificate.svg';
import { FormikProps } from 'formik';
import ButtonV2 from '../../atoms/ButtonV2';


 interface IEmptyBussinessDocumentTemplate {
    setEdit: Dispatch<SetStateAction<boolean>>
}
const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.secondaryLightAccent}`
    },
    heading: {
        color: theme.palette.textV2.secondary.secondary800,
    }
}));

const EmptyBussinessDocumentTemplate: React.FC<IEmptyBussinessDocumentTemplate> = ({ setEdit }) => {
    const classes = useStyles();
    return (
        <div className={`${classes.container} py-14 px-6  rounded-lg`}>
            <div className='grid gap-y-4'>
                <div className='grid gap-y-8 justify-items-stretch'>
                    <div className='justify-self-center'>
                        <img src={EmptyCertificate} alt="documentImage" className="" />
                    </div>
                    <div className={`justify-self-center font-normal text-lg ${classes.heading}`}>
                        You haven't provided certificate/documents for your business.
                    </div>
                </div>
                <div className='grid justify-items-stretch'>
                    <ButtonV2
                        className={` justify-self-center w-auto`}
                        variant="contained"
                        label="Upload certificate documents"
                        onClick={() => {setEdit(true) }}
                    />
                </div>
            </div>
        </div>
    )
};
export default EmptyBussinessDocumentTemplate;
