interface CustomPalette {
    background: Record<string, string>;
    sidebarBackground: Record<string, string>;
    text: Record<string, string>;
    action: Record<string, string>;
    button: Record<string, string>;
    general: Record<string, string>;
    border: Record<string, string>;
    boxShadow: Record<string, string>;
    resourceStatus: Record<string, string>;
    icon: Record<string, string>;
    table: Record<string, string>;
    buttonV2: Record<string, any>;
    textV2: Record<string, any>;
    backgroundV2: Record<string, any>;
    borderV2: Record<string, any>;
    pagination: Record<string, any>;
}

interface CustomTheme extends Jss.Theme {
    name: string;
    palette: CustomPalette;
}

export const theme: CustomTheme = {
    name: "default",
    palette: {
        background: {
            primaryDark: "#F6F6F6",
            primaryLight: "#ededed4a",
            primaryLightAccent: "#5B5B5B",
            secondaryLight: "#DDD",
            secondaryDark: "#1c3f9f1a",
            secondaryDarkAccent: "#F3F3F4",
            _primaryLightAccent: "#F2F2F2",
            _secondaryDarkAccent: "#eeeeee4d",
        },
        sidebarBackground: {
            primaryLight: "#E9ECEF",
            primaryLightAccent: "#FFF1DB",
            secondaryLight: "#DDE5FF",
        },
        text: {
            primaryDark: "#393839",
            primaryDarkLight: "#5B5B5B",
            secondaryDark: "#46505A",
            secondaryLight: "#808080",
            secondaryDarkAccent: "#525252",
            secondaryLigthAccent: "#5A5A5A",
            primaryLight: "#4D4D4D",
            primaryLightAccent: "#1D3679",
            primaryDarkAccent: '#4D4D4D',
        },
        action: {
            active: "#001E3C",
            danger: "#EF0000",
            success: "#4caf50",
        },
        button: {
            primaryDark: "#1D3679",
            primaryLight: "#495985",
        },
        general: {
            seperator: "#5d5d5d",
        },
        table: {
            headerBackground: "#1D3679",
            textPrimary: "#5B5B5B",
            rowOdd: "#1C3F9F08",
            rowEven: "#F7931E08",
        },
        border: {
            primaryDark: "#DDDDDD",
            primaryLight: "#F1F1F1",
            secondaryLight: "#FD6202",
            secondaryDark: "#CCCCCC",
            primaryLightAccent: "#1D3679",
            primaryDarkAccent: "#DDD",
            primarylightAccent:"#EBEFFA",
            secondarylightAccent:'#ffffff',
            tritiaryLight:"#E6E6E6",
            secondaryLightAccent:'#DDD',
            _primaryLight: "#3152E6",
        },
        icon: {
            primaryLight: "#FD6202",
            primaryDark: "#FF0000",
            secondryDark: "#1D3679"
        },
        boxShadow: {
            primaryLight: "#0000000d",
            primaryDark: "#00000040",
            primaryDarkAccent: "#00000029",
            secondaryDark: "#1d36791f",
            secondaryLight: "#4d4d4d40"
        },
        resourceStatus: {
            primaryLight: "#0AC282",
            secondaryLight: "#FBB040",
            primaryDark: "#F04438",
            secondaryDark: "#1D3679"
        },
        textV2: {
            primary: {
                primary25: "#FFF",
                primary50: "#FFEFE6",
                primary100: "#FFE0CC",
                primary200: "#FEC09A",
                primary300: "#FEA167",
                primary400: "#FD8235",
                primary500: "#FD6202",
                primary550: "#FD6102",
                primary600: "#983B01",
                primary700: "#983B01",
                primary800: "#652701",
                primary850: "#6F6F6F",
                primary870: "#666",
                primary900: "#331400",
                primary950: "#190A00",
                primary1000: "#D9D9D9"
            },
            secondary: {
                secondary50: "#EBEFFA",
                secondary100: "#D6DEF5",
                secondary200: "#ADBEEB",
                secondary300: "#849DE1",
                secondary400: "#5B7CD7",
                secondary500: "#1E3679",
                secondary600: "#2849A4",
                secondary700: "#1E377B",
                secondary800: "#142552",
                secondary900: "#0A1229",
            },
            tertiary: {
                tertiary50: "#EEEEEE",
                tertiary100: "#E6E6E6",
                tertiary200: "#CCCCCC",
                tertiary300: "#B3B3B3",
                tertiary400: "#999999",
                tertiary500: "#FFFFFF",
                tertiary600: "#666666",
                tertiary700: "#4D4D4D",
                tertiary800: "#333333",
                tertiary900: "#1A1A1A",
                tertiary925: "#333",
                tertiary950: "#0D0D0D",
            },
            success: {
                success50: "#EAFAEF",
                success100: "#D5F6DE",
                success200: "#ACECBE",
                success300: "#82E39D",
                success400: "#58DA7D",
                success500: "#24A147",
                success600: "#25A74A",
                success700: "#1C7D37",
                success800: "#135325",
                success900: "#092A12",
                success950: "#051509",
            },
            failure: {
                failure50: "#FCE9EA",
                failure100: "#F9D2D4",
                failure200: "#F3A5A9",
                failure300: "#EC797E",
                failure400: "#E64C53",
                failure500: "#DA1E27",
                failure600: "#B31920",
                failure700: "#861318",
                failure800: "#5A0C10",
                failure900: "#2D0608",
                failure950: "#160304",
            },
            warning: {
                warning50: "#FFF9E6",
                warning100: "#FEF3CD",
                warning200: "#FEE79A",
                warning300: "#FDDA68",
                warning400: "#FDCE35",
                warning500: "#FDD03A",
                warning600: "#CA9B02",
                warning700: "#977402",
                warning800: "#654E01",
                warning900: "#322701",
                warning950: "#191300",
            },
            alphaDark: {
                alphaDark5: "#0a12290d", //rgba(10, 18, 41, 0.05)
                alphaDark10: "#0a12291a", //rgba(10, 18, 41, 0.1)
                alphaDark20: "#0a122933", //rgba(10, 18, 41, 0.2)
                alphaDark30: "#0a12294d", //rgba(10, 18, 41, 0.3)
                alphaDark40: "#0a122966", //rgba(10, 18, 41, 0.4)
                alphaDark50: "#0a122980", //rgba(10, 18, 41, 0.5)
                alphaDark60: "#0a122999", //rgba(10, 18, 41, 0.6)
                alphaDark70: "#0a1229b3", //rgba(10, 18, 41, 0.7)
                alphaDark80: "#0a1229cc", //rgba(10, 18, 41, 0.8)
                alphaDark90: "#0a1229e6" //rgba(10, 18, 41, 0.9)
            }
        },
        buttonV2: {
            primaryContained: {
                text: {
                    primaryLight: "#FFF",
                    secondaryLight: "#0a122999",
                },
                border: {
                    primaryLight: "#983B01"
                },
                background: {
                    primaryLight: "#FD6202",
                    primaryLightAccent: "#CA4F02",
                    primaryDark: "#983B01",
                    secondaryLight: "#0a12290d",
                }
            },
            secondaryContained: {
                text: {
                    primaryLight: "#983B01",
                    primaryLightAccent: "#652701",
                    primaryDark: "#331400",
                    secondaryLight: "#0a122999",
                },
                border: {
                    primaryLight: "#983B01"
                },
                background: {
                    primaryLight: "#FFE0CC",
                    primaryLightAccent: "#FEC09A",
                    primaryDark: "#FEA167",
                    secondaryLight: "#0a12290d",
                }
            },
            tertiaryContained: {
                text: {
                    primaryLight: "#333",
                    secondaryLight: "#0a122966",
                },
                border: {
                    primaryLight: "#333",
                    primaryDark: "#1A1A1A",
                    secondaryLight: "#0a122966",
                },
                background: {
                    primaryLight: "#EEE",
                    primaryDark: "#666"
                }
            },
            primaryText: {
                text: {
                    primaryLight: "#FD8235",
                    primaryDark: "#FD6202",
                    secondaryLight: "#0a122966",
                    secondaryDark: "#333"
                },
                border: {
                    primaryLight: "#333"
                },
            },
            secondaryText: {
                text: {
                    primaryLight: "#0a122933",
                    primaryDark: "#999",
                    secondaryLight: "#1E377B",
                    secondaryDark: "#142552"
                },
                border: {
                    primaryLight: "#999"
                },
            },
            containedGrey: {
                text: {
                    primaryLight: "#333333",
                    primaryDark: "#999",
                    secondaryLight: "#1E377B",
                    secondaryDark: "#142552"
                },
                border: {
                    primaryLight: '#333333'
                }
            }
        },
        borderV2: {
            tertiary:{
                tertiary50: "#EEEEEE",
                tertiary100: "#E6E6E6",
                tertiary200: "#CCCCCC",
                tertiary300: "#B3B3B3",
                tertiary400: "#999999",
                tertiary500: "#FFFFFF",
                tertiary600: "#666666",
                tertiary700: "#4D4D4D",
                tertiary800: "#333333",
                tertiary900: "#1A1A1A",
                tertiary950: "#0D0D0D",
            }, 
            primary:{
                primary50: "#FFEFE6",
                primary100: "#FFE0CC",
                primary200: "#FEC09A",
                primary300: "#FEA167",
                primary400: "#FD8235",
                primary500: "#FD6202",
                primary600: "#983B01",
                primary700: "#983B01",
                primary800: "#652701",
                primary900: "#331400",
                primary950: "#190A00",
            },
        },
        backgroundV2: {
            primary:{
                primary50: "#FFEFE6",
                primary100: "#FFE0CC",
                primary150: "#C4C4C4",
                primary200: "#FEC09A",
                primary300: "#FEA167",
                primary400: "#FD8235",
                primary500: "#FD6202",
                primary600: "#983B01",
                primary700: "#983B01",
                primary800: "#652701",
                primary900: "#331400",
                primary950: "#190A00",
            },
            secondary: {
                secondary50: "#EBEFFA",
                secondary100: "#D6DEF5",
                secondary200: "#ADBEEB",
                secondary300: "#849DE1",
                secondary400: "#5B7CD7",
                secondary500: "#1E3679",
                secondary600: "#2849A4",
                secondary700: "#1E377B",
                secondary800: "#142552",
                secondary900: "#0A1229",
            },
            tertiary: {
                tertiary50: "#EEEEEE",
                tertiary100: "#E6E6E6",
                tertiary200: "#CCCCCC",
                tertiary300: "#B3B3B3",
                tertiary400: "#999999",
                tertiary500: "#FFFFFF",
                tertiary600: "#666666",
                tertiary700: "#4D4D4D",
                tertiary800: "#333333",
                tertiary900: "#1A1A1A",
                tertiary925: "#333",
                tertiary950: "#0D0D0D",
            },
            success: {
                success50: "#EAFAEF",
                success100: "#D5F6DE",
                success200: "#ACECBE",
                success300: "#82E39D",
                success400: "#58DA7D",
                success500: "#24A147",
                success600: "#25A74A",
                success700: "#1C7D37",
                success800: "#135325",
                success900: "#092A12",
                success950: "#051509",
            },
            failure: {
                failure50: "#FCE9EA",
                failure100: "#F9D2D4",
                failure200: "#F3A5A9",
                failure300: "#EC797E",
                failure400: "#E64C53",
                failure500: "#DA1E27",
                failure600: "#B31920",
                failure700: "#861318",
                failure800: "#5A0C10",
                failure900: "#2D0608",
                failure950: "#160304",
                failure1000: "#BABEC8",
            },
            warning: {
                warning50: "#FFF9E6",
                warning100: "#FEF3CD",
                warning200: "#FEE79A",
                warning300: "#FDDA68",
                warning400: "#FDCE35",
                warning500: "#FDD03A",
                warning600: "#CA9B02",
                warning700: "#977402",
                warning800: "#654E01",
                warning900: "#322701",
                warning950: "#191300",
            },
            alphaDark: {
                alphaDark5: "#0a12290d", //rgba(10, 18, 41, 0.05)
                alphaDark10: "#0a12291a", //rgba(10, 18, 41, 0.1)
                alphaDark20: "#0a122933", //rgba(10, 18, 41, 0.2)
                alphaDark30: "#0a12294d", //rgba(10, 18, 41, 0.3)
                alphaDark40: "#0a122966", //rgba(10, 18, 41, 0.4)
                alphaDark50: "#0a122980", //rgba(10, 18, 41, 0.5)
                alphaDark60: "#0a122999", //rgba(10, 18, 41, 0.6)
                alphaDark70: "#0a1229b3", //rgba(10, 18, 41, 0.7)
                alphaDark80: "#0a1229cc", //rgba(10, 18, 41, 0.8)
                alphaDark90: "#0a1229e6" //rgba(10, 18, 41, 0.9)
            },
            alphaLight: {
                alphaLight60: "#ffffff99"
            }
        },
        pagination: {
            text: {
                primaryLight: "1C3F9F",
                secondaryLight: "#1E3679"
            },
            border: {
                primaryLight: "#4200FF"
            }
        },
    },

};

export const defaultTheme: Jss.Theme = theme;

