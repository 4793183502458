import { FormikProps } from 'formik';
import React, { useState } from 'react'
import { createUseStyles } from 'react-jss';

import { useNavigate } from 'react-router-dom';
import { IVendorStatutoryDetailsForm } from '../../../pages/VendorManagement/VendorStatutoryDetail.page';
import { ADMIN_ROUTES } from '../../../../utils/constant';
import ButtonV2 from '../../../atoms/ButtonV2';
import UploadCertificatesDocumentTemplate from '../../Dashboard/UploadCertificatesDocument.template';
import VendorUploadBankDetailsTemplate from './VendorUploadBankDetails.template';

export interface IVendorStatutoryDetailProps {
    formik: FormikProps<IVendorStatutoryDetailsForm>;
}
const useStyles = createUseStyles((theme: any) => ({
    titleText: {
        color: theme.palette.textV2.secondary.secondary800
    }
}));

const VendorStatutoryDetailTemplate: React.FC<IVendorStatutoryDetailProps> = ({ formik }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleBackToDashboard = () => {
        navigate(ADMIN_ROUTES.VENDOR_MANAGEMENT_LIST);
    };
    return (
        <div className='grid gap-6 justify-items-stretch'>
            <div className={`${classes.titleText} font-semibold text-lg`}>Statutory Documents</div>
            <UploadCertificatesDocumentTemplate formik={formik} />
            <VendorUploadBankDetailsTemplate formik={formik} />
            <div className=' grid justify-items-stretch'>
                <ButtonV2
                    className={` justify-self-end`}
                    variant="secondaryContained"
                    label="Back to Dashboard"
                    onClick={handleBackToDashboard}
                />
            </div>
        </div>
    )
}
export default VendorStatutoryDetailTemplate