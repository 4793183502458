import { Route, Routes } from 'react-router-dom';
import MachineListPage from '../../../components/pages/VASMaster/Machine/MachineList.page';
import MachineCreatePage from '../../../components/pages/VASMaster/Machine/MachineCreate.page';
import MachineViewPage from '../../../components/pages/VASMaster/Machine/MachineView.page';
import MachineUpdatePage from '../../../components/pages/VASMaster/Machine/MachineUpdate.page';

const VASMasterMachinesRoutes = () => {
    return (
        <Routes>
             <Route path="" element={<MachineListPage />} />
            <Route path="/add" element={<MachineCreatePage />} />
            <Route path="/:id" element={<MachineViewPage />} />
            <Route path="/:id/update" element={<MachineUpdatePage />} />
    
        </Routes>
    )
}

export default VASMasterMachinesRoutes