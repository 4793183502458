import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export const INSTANT_QUOTATION_URLS = {
    CREATE_INSTANT_QUOTATION: "/instant-quotations",
    UPDATE_INSTANT_QUOTATION: "/instant-quotations/:id",
    GET_ALL_INSTANT_QUOTATIONS: "/instant-quotations",
    GET_INSTANT_QUOTATION: "/instant-quotations/:id",
    DOWNLOAD_INSTANT_QUOTATION: '/instant-quotations/share/:id',
    INSTANT_QUOTATION_STATS: "/instant-quotations/stats",
}

export interface IQuotationFilterRequest {
    searchByName?: string;
    page?: number;
    size?: number;
    sort?: any;
    status?: string | null;
}

export const useInstantQuotationService = () => {
    const { user } = useAuthenticatedUser();
    const BASE_URL_V1 = process.env.REACT_APP_API_V1_URL;

    const createInstantQuotation = (data: any) => {
        return request(API_METHOD.POST, INSTANT_QUOTATION_URLS.CREATE_INSTANT_QUOTATION, user, data, undefined, null, BASE_URL_V1);
    }

    const updateInstantQuotation = (data: any, id: number) => {
        return request(API_METHOD.PUT, replaceUrlParams(INSTANT_QUOTATION_URLS.UPDATE_INSTANT_QUOTATION, { id }), user, data, undefined, null, BASE_URL_V1);
    }

    const getInstantQuotation = (id: number) => {
        return request(API_METHOD.GET, replaceUrlParams(INSTANT_QUOTATION_URLS.GET_INSTANT_QUOTATION, { id }), user, null, undefined, null, BASE_URL_V1);
    }

    const getAllInstantQuotation = (params: IQuotationFilterRequest) => {
        return request(API_METHOD.GET, INSTANT_QUOTATION_URLS.GET_ALL_INSTANT_QUOTATIONS, user, null, { params }, null, BASE_URL_V1)
    }

    const shareInstantQuotation = (id: number) => {
        return request(API_METHOD.GET, replaceUrlParams(INSTANT_QUOTATION_URLS.DOWNLOAD_INSTANT_QUOTATION, { id }), user, null, undefined, null, BASE_URL_V1);
    }

    const getInstantQuotationStats = () => {
        return request(API_METHOD.GET, INSTANT_QUOTATION_URLS.INSTANT_QUOTATION_STATS, user, null, undefined, null, BASE_URL_V1);
    }


  return  {
    createInstantQuotation,
    updateInstantQuotation,
    getInstantQuotation,
    getAllInstantQuotation,
    shareInstantQuotation,
    getInstantQuotationStats

  }
}
