import React, { useEffect } from "react";
import { Modal } from "@mui/material";
import { TextField } from "@mui/material";
import { useFormik } from "formik";
import { InputAdornment } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TitleHeading from "../../atoms/CustomHeading/TitleHeading";
import Button from "../../atoms/Button";
import { useOrderService } from "../../../services/useOrderService";
import * as Yup from "yup";

interface ConsigneeFormModalTemplateProps {
	open: boolean;
	onClose: () => void;
	id: number;
	consignee: any;
	loadOrderMaster: any;
}

interface IConsigneeForm {
	id: number;
	name: string;
	companyName: string;
	email: string;
	gstn: string;
	phoneNumber: string;
}

const ConsigneeFormModalTemplate: React.FC<ConsigneeFormModalTemplateProps> = ({ open, onClose, id, consignee, loadOrderMaster }) => {
	const orderService = useOrderService();
	const initialValues: IConsigneeForm = {
		id: id,
		name: consignee ? consignee.name : "",
		companyName: consignee ? consignee.companyName : "",
		email: consignee ? consignee.email : "",
		gstn: consignee ? consignee.gstn : "",
		phoneNumber: consignee ? consignee.phoneNumber : "",
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().trim().required("Name is required").matches(/^[a-zA-Z ]+$/, "Name should only contain letters"),
		companyName: Yup.string().trim().required("Company Name is required"),
		email: Yup.string().email("Invalid email format").required("Email is required"),
		gstn: Yup.string().length(15, "GSTN should be 15 characters long").required("GSTN is required"),
		phoneNumber: Yup.string().matches(/^\d{10}$/, "Please enter a 10-digit phone number").required("Phone number is required"),
	});

	const formik = useFormik<IConsigneeForm>({
		initialValues,
		validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			const data = {
				consigneeDetails: {
					name: values.name,
					companyName: values.companyName,
					email: values.email,
					gstn: values.gstn,
					phoneNumber: values.phoneNumber,
				},
			};
			try {
				const updateConsigneeResponse = await orderService.updateOrderMaster(id, data);
				if (updateConsigneeResponse?.status === 200 && updateConsigneeResponse?.data?.data) {
					onClose();
				} else {
					console.error("Failed to update consignee data:", updateConsigneeResponse);
				}
				loadOrderMaster();
			} catch (error) {
				throw error;
			}
		},
	});

	useEffect(() => {
		formik.setValues({
			...formik.values,
			...initialValues,
		});
	}, [consignee]);

	return (
		<Modal open = { open } onClose = { onClose } closeAfterTransition>
			<div className="w-2/4 m-auto mt-16 bg-white grid gap-y-6 p-6">
				<div className="flex justify-between pb-4">
					{ consignee ? (<TitleHeading text="Update Consignee" />) : (<TitleHeading text="Add Consignee" />) }
					<CloseIcon className="cursor-pointer" onClick = { onClose } />
				</div>

				<form onSubmit = { formik.handleSubmit }>
					<div className="grid gap-y-3">
						<TextField
							fullWidth
							label = "Name"
							name = "name"
							variant = "outlined"
							value = { formik.values.name }
							onChange = { formik.handleChange }
							error = { formik.touched.name && Boolean(formik.errors.name) }
							helperText = { formik.touched.name && formik.errors.name }
						/>
						<TextField
							fullWidth
							label = "Company Name"
							name = "companyName"
							variant = "outlined"
							value = { formik.values.companyName }
							onChange = { formik.handleChange }
							error = { formik.touched.companyName && Boolean(formik.errors.companyName) }
							helperText = { formik.touched.companyName && formik.errors.companyName }
						/>
						<TextField
							fullWidth
							label ="Email"
							name ="email"
							variant ="outlined"
							value ={ formik.values.email }
							onChange = { formik.handleChange }
							error = { formik.touched.email && Boolean(formik.errors.email) }
							helperText = { formik.touched.email && formik.errors.email }
						/>
						<TextField
							fullWidth
							label = "GSTN"
							name = "gstn"
							variant = "outlined"
							value = { formik.values.gstn }
							onChange = { formik.handleChange }
							error = { formik.touched.gstn && Boolean(formik.errors.gstn) }
							helperText = { formik.touched.gstn && formik.errors.gstn }
						/>
						<TextField
							fullWidth
							label = "Phone Number"
							name = "phoneNumber"
							variant = "outlined"
							type = "tel"
							value = { formik.values.phoneNumber }
							onChange = { formik.handleChange }
							error = { formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber) }
							helperText = { formik.touched.phoneNumber && formik.errors.phoneNumber }
							InputProps = {{
								startAdornment: (
									<InputAdornment position="start">+91</InputAdornment>
								),
								inputProps: { maxLength: 10 },
							}}
						/>
						<div className="flex justify-end">
							<div className="flex gap-x-5">
								<Button variant = "outlined" onClick = { onClose } label = "Cancel" />
								<Button variant = "contained" type = "submit" label = { consignee ? "Update" : "Create" } />
							</div>
						</div>
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default ConsigneeFormModalTemplate;
