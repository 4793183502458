import React, { useMemo } from 'react'
import TableV2, { ColumnType } from '../../organisms/TableV2';
import { useNavigate } from 'react-router';
import ResourceStatusV2 from '../../atoms/ResourceStatus/ResourceStatusV2';
import { enumToString, makeRoute } from '../../../utils/helper';
import { ADMIN_ROUTES, CUSTOMER_MANAGEMENT_TABS, VENDOR_TYPE } from '../../../utils/constant';
import { ICustomerFilter, ICustomerTemplate } from '../../pages/VendorManagement/VendorListing.page';
import { IPagination } from '../../../utils/types';
import viewEyeIcon from '../../../assets/icons/viewOutlinedIcon.svg'
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        borderTop: `1px solid ${theme.palette.border.tritiaryLight}`
    },
    customerCount: {
        borderRadius: '17px',
        background: theme.palette.textV2.failure.failure500,
        padding: '2px 6px'
    }
}));

interface ICustomerListProps {
    customerTemplate: ICustomerTemplate[];
    pagination: IPagination;
    handleFiltersChange?: any;
    handlePaginationChange: any;
    handleRowsPerPageChange: any;
    customerFilter: ICustomerFilter;
}

export const VendorListTemplate: React.FC<ICustomerListProps> = ({ customerTemplate, pagination, handleFiltersChange, handlePaginationChange, handleRowsPerPageChange, customerFilter }) => {

    const navigate = useNavigate();
    const classes = useStyles();

    const schema = {
        id: 'template-list',
        pagination: {
            total: pagination.totalRecords,
            currentPage: pagination.currentPage,
            isVisible: true,
            limit: pagination.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange
        },
        columns: [

            { label: "Sr No.", key: "id", type: "number" as ColumnType, props: {} },
            { label: "Vendor ID", key: "CustomerID", type: "string" as ColumnType, props: {} },
            { label: "Company Name", key: "companyName", type: "string" as ColumnType, props: {} },
            // { label: "Group Name", key: "customerType", type: "string" as ColumnType, props: {} },
            { label: "Business Entity", key: "vendorType", type: "string" as ColumnType, props: {} },
            { label: "Location", key: "location", type: "string" as ColumnType, props: {} },
            { label: "Relationship Manager", key: "relationshipManager", type: "string" as ColumnType, props: {} },
            { label: "Created By", key: "createdBy", type: "number" as ColumnType, props: {} },
            { label: "Status", key: "status", component: ({ value }: { value: string }) => <ResourceStatusV2 status={value} />, type: "custom" as ColumnType, props: {} },
            { label: "Action", key: "action", type: "custom" as ColumnType, props: {} },
        ]
    }

    const handleViewClick = (id: number) => (e: any) => {
        const route = makeRoute(ADMIN_ROUTES.VENDOR_MANAGEMENT_VIEW, { query: { tab: CUSTOMER_MANAGEMENT_TABS.PROFILE }, params: { id: id } });
        navigate(`${route}`);
    };

    const Action = (id: number) => {
        return (<div>
            <button onClick={handleViewClick(id)}><img src={viewEyeIcon} alt={viewEyeIcon} /></button>
        </div>)
    }

    const getVendorTypeLabel = (value: any) => {
        const vendorType = VENDOR_TYPE.find((type) => type.value === value);
        return vendorType ? vendorType.label : value;
      };

    const records = useMemo(() => {
        return customerTemplate.map((customerTemplate: ICustomerTemplate, index: number) => {

            return [
                pagination.currentPage * pagination.pageSize + index + 1,
                customerTemplate.id,
                customerTemplate.companyName,
                // customerTemplate.customerType,
                getVendorTypeLabel(customerTemplate.vendorType),
                customerTemplate.location,
                customerTemplate?.relationshipManager[0]?.fullName ?? '',
                customerTemplate.createdBy,
                enumToString(customerTemplate.status),
                Action(customerTemplate.id)
            ];
        });
    }, [customerTemplate]);

    return (
        <div>
            <div className={`${classes.container}  pt-8`}>
                <TableV2 schema={schema} records={records} />
            </div>
        </div>
    )
}