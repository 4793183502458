import React, { useEffect, useState } from 'react'
import { Category, ICategoryFilterRequest, useCategoryService } from '../../../services/useCategoryService'
import { CATEGORY_TYPE, HTTP_STATUS, IPagination } from '../../../utils/types'
import { ADMIN_ROUTES, MODE, initialPaginationValues } from '../../../utils/constant'
import { useSnackbar } from '../../../hooks/useSnackBar'
import Table, { ColumnType } from '../../organisms/Table'
import viewEyeIcon from "../../../assets/icons/viewEyeIcon.svg";
import editPenIcon from "../../../assets/icons/editPenIcon.svg";
import { useNavigate } from 'react-router-dom'
import moment from "moment";
import { enumToString, makeRoute } from '../../../utils/helper'
import Select from '../../atoms/Select'
import Button from '../../atoms/Button'
import ResourceStatus from '../../atoms/ResourceStatus/ResourceStatus'
import TextField from '../../atoms/TextField'


interface CategoriesListTableTemplateProps {
    level: number
}

const initialCategoryFiltersValues = {
    name: "",
    status: "ALL",
    sort: "created_at,desc",
}

const categoryColumns = [
    [
        { label: "Category Name", key: "categoryName", type: "string" as ColumnType, props: { className: '' } }
    ],
    [
        { label: "Main Category", key: "mainCategory", type: "string" as ColumnType, props: { className: '' } },
        { label: "Super Category", key: "superCategory", type: "string" as ColumnType, props: { className: '' } }
    ],
    [
        { label: "Product Category", key: "productCategory", type: "string" as ColumnType, props: { className: '' } },
        { label: "Main Category", key: "mainCategory", type: "string" as ColumnType, props: { className: '' } },
        { label: "Super Category", key: "superCategory", type: "string" as ColumnType, props: { className: '' } }
    ]
]

const categoryRecord = ((category: Category, level: number) => [
    [category.name],
    [category.name, category.parentCategory],
    [category.name, category.parentCategory, category.superParentCategory]
][level])

const CategoriesListTableTemplate: React.FC<CategoriesListTableTemplateProps> = ({ level }) => {
    const categoryService = useCategoryService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [filters, setFilters] = useState(initialCategoryFiltersValues);
    const [pagination, setPagination] = useState<IPagination>(initialPaginationValues);
    const [tableContent, setTableContent] = useState<Category[] | []>([]);
    const navigate = useNavigate();

    const fetchTableContent = async (params: ICategoryFilterRequest) => {
        await categoryService.getAllCategories(params)
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    const { totalElements, totalPages } = res?.data?.data;
                    setPagination({
                        ...pagination,
                        totalPages: totalPages,
                        totalRecords: totalElements
                    });
                    setTableContent(res?.data?.data?.content)
                }
            }).catch(error => {
                showSnackbar('error', error);
                setTableContent([]);
            })
    }
    const handleFiltersChange = (event: any) => {
        const { name, value } = event.target;
        setPagination({...pagination, currentPage: 0})
        setFilters({ ...filters, [name]: value ?? "" });
    }

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPagination((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage
        }));
    };

    const handleActionRoute = (id:number, mode: string) => () => {
        navigate(makeRoute(ADMIN_ROUTES.CATEGORY_UPDATE(id), {query: { mode }}));
    }

    const Action = (id: number) => (
        <div>
            <button onClick={handleActionRoute(id,MODE.VIEW)}><img src={viewEyeIcon} alt={viewEyeIcon} /></button>
            <button onClick={handleActionRoute(id,MODE.UPDATE)}><img src={editPenIcon} alt={editPenIcon} /></button>
        </div>
    )

    useEffect(() => {
        const params: ICategoryFilterRequest = {
            level,
            name: filters.name.trim(),
            status: filters.status === "ALL" ? "" : filters.status,
            page: pagination.currentPage,
            size: pagination.pageSize,
            sort: filters.sort
        }
        fetchTableContent(params)
    }, [level, filters, pagination.currentPage, pagination.pageSize])

    const getRecords = () => tableContent?.map((category: Category, index) => [
        pagination.currentPage * pagination.pageSize + index + 1,
        ...categoryRecord(category, level),
        moment(category.createdAt).format("YYYY-MM-DD HH:mm:ss"),
        moment(category.updatedAt).format("YYYY-MM-DD HH:mm:ss"),
        enumToString(category.status),
        Action(category.id)
    ])

    const getTableColumns = () => [
        { label: "Sr No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
        ...categoryColumns[level],
        { label: "Created Date", key: "createdAt", type: "datetime" as ColumnType, props: { className: '' } },
        { label: "Last Modified", key: "updatedAt", type: "datetime" as ColumnType, props: { className: '' } },
        { label: "Status", key: "status", component: ({ value }: { value: string }) => <ResourceStatus status={value} />, type: "custom" as ColumnType, props: {} }, 
        { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } },
    ]

    const getSchema = () => ({
        id: "1",
        pagination: {
            total: pagination.totalRecords,
            currentPage: pagination.currentPage,
            isVisible: true,
            limit: pagination.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange
        },
        columns: getTableColumns() ?? []
    })

    const handleAddCategory = () => {
        navigate(makeRoute(ADMIN_ROUTES.CATEGORY_CREATE, {query: { type: Object.keys(CATEGORY_TYPE).filter(key => isNaN(parseInt(key)))[level] }}));
    }

    return (
        <>
            {SnackBarComponent}
            <div>
                <div className='flex justify-between pb-1.5'>
                    <div className='flex gap-x-4'>
                        <div>
                            <Select
                                variant="outlined"
                                className="w-44"
                                label="Status"
                                value={filters.status}
                                onChange={(event) => handleFiltersChange(event)}
                                name="status"
                                id="status"
                                options={[
                                    { value: "ALL", label: "ALL" },
                                    { value: "ACTIVE", label: "ACTIVE" },
                                    { value: "INACTIVE", label: "INACTIVE" }
                                ]}
                            />

                        </div>
                        <div>
                            <Select
                                variant="outlined"
                                className="w-fit"
                                label="Sort"
                                value={filters.sort}
                                onChange={(event) => handleFiltersChange(event)}
                                name="sort"
                                id="sort"
                                options={[
                                    { value: "name,asc", label: "Name (Ascending)" },
                                    { value: "name,desc", label: "Name (Decending)" },
                                    { value: "created_at,asc", label: "Created Date (Ascending)" },
                                    { value: "created_at,desc", label: "Created Date (Decending)" }
                                ]}
                            />

                        </div>
                        <div>
                            <TextField
                                className="w-fit"
                                label="Search By Name..."
                                variant="outlined"
                                type="text"
                                name="name"
                                value={filters.name}
                                onChange={handleFiltersChange}
                            />

                        </div>
                    </div>
                    <Button label={`Add ${level === CATEGORY_TYPE.SUPER_CATEGORY ? "Super" : level === CATEGORY_TYPE.MAIN_CATEGORY ? "Main" : "Product"} Category`} variant='contained' onClick={handleAddCategory} />
                </div>
            </div>
            <Table schema={getSchema()} records={getRecords()} />
        </>
    )
}

export default CategoriesListTableTemplate