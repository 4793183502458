import React, { useEffect, useState, ReactNode } from "react";
import NewInventoryContext from "./NewInventoryContext";
import axios from "axios";

// CONSTANTS
export const SET_INVENTORY_ID = "SET_INVENTORY_ID";
export const SET_INVENTORY_DETAILS = "SET_INVENTORY_DETAILS";
export const SET_INVENTORY_FORM_DATA = "SET_INVENTORY_FORM_DATA";
export const SET_PAGE_DETAILS = "SET_PAGE_DETAILS";
export const SET_PAGESIZE_PAGENUMBER = "SET_PAGESIZE_PAGENUMBER";
export const SET_MODE = "SET_MODE";
export const SET_SKU_MODE = "SET_SKU_MODE";
export const SET_IMAGE_DATA = "SET_IMAGE_DATA";
export const SET_IMAGE_IDS_DELETE = "SET_IMAGE_IDS_DELETE" 
// Define types


interface InventoryState {
  inventoryId: number | null;
  inventoryFormData: any;
  imageData:any[];
  mode: any;
  imageIdsToDelete:number[];
}

interface InventoryProviderProps {
  children: ReactNode;
}
export const initialInventoryState = {
  inventoryId: null,
  inventoryFormData: {},
  imageData:[],
  mode: null,
  imageIdsToDelete:[]
};


const NewInventoryProvider: React.FC<InventoryProviderProps> = ({ children }) => {
  const [inventoryDetailState, setInventoryDetailState] =
    useState<InventoryState>(initialInventoryState);
    const resetInventoryContext = () => {
      setInventoryDetailState(initialInventoryState);
    };
  

  const updateInventoryDetailsState = (payload: any, type: string) => {
    switch (type) {
      case SET_INVENTORY_FORM_DATA:
        setInventoryDetailState((prevState) => ({
          ...prevState,
          inventoryFormData: payload,
        }));
        break;
      case SET_MODE:
        setInventoryDetailState((prevState) => ({
          ...prevState,
          mode: payload,
        }));
        break;
      case SET_SKU_MODE:
        setInventoryDetailState((prevState) => ({
          ...prevState,
          skuMode: payload,
        }));
        break;
      case SET_INVENTORY_ID:
        setInventoryDetailState((prevState) => ({
          ...prevState,
          inventoryId: payload,
        }));
        break;
        case SET_IMAGE_DATA:
          setInventoryDetailState((prevState) => ({
            ...prevState,
            imageData: payload,
          }));
          break;
          case SET_IMAGE_IDS_DELETE:
            setInventoryDetailState((prevState) => ({
              ...prevState,
              imageIdsToDelete: payload,
            }));
            break;
      default:
        return;
    }
  };

  return (
    <NewInventoryContext.Provider
      value={{
        inventoryDetailState,
        updateInventoryDetailsState,
        // retreiveDetails,
        resetInventoryContext
      }}
    >
      {children}
    </NewInventoryContext.Provider>
  );
};

export default NewInventoryProvider;
