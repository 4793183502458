import React from 'react';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';

export interface ChipItem {
    id: number | string;
    label: string;
}

interface ChipProps {
    values: ChipItem[];
    onDelete: (value: ChipItem) => void;
    disabled?: boolean;
}

const Chips: React.FC<ChipProps> = ({ values, onDelete, disabled }) => {

    const handleDelete = (value: ChipItem) => ()=> 
       !disabled && onDelete(value);
       
    return (
        <Box mt={2}>
            {values?.map((chip) => (
                <Chip
                    variant="outlined"
                    key={chip.id}
                    label={chip.label}
                    onDelete={handleDelete(chip)}
                    className='!m-1'
                />
            ))}
        </Box>
    );
}

export default Chips;