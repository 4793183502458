import { useEffect, useState } from "react";
import { IManufacturerFilterRequest, useManufacturerService } from "../../../../services/useManufacturerService";
import { IPagination } from "../../../../utils/types";
import ManufacturerListTemplate from "../../../templates/AdminMaster/Manufacturer/ManufacturerList.template";
import { ADMIN_ROUTES, initialPaginationValues } from "../../../../utils/constant";
import TitleHeading from "../../../atoms/CustomHeading/TitleHeading";
import Button from "../../../atoms/Button";
import { useNavigate } from "react-router-dom";

export interface IManufacturer {
    id: number;
    name: string;
    shortName: string;
    code: string;
    logoPath: number;
    status: string;
    manufacturerType: string;
    createdBy: number;
    updatedBy: number;
    registeredAddressId: number;
    manufacturingLocationId: number;
    categoryIds: string;
    poc: string;
    createdAt: string;
    updatedAt: string;
}

export interface IManufaturerFilter {
    search: string;
    manufacturerType: string;
    status: string;
    sort: string;
}

const initialManufacturerFiltersValues: IManufaturerFilter = {
    search: "",
    manufacturerType: "ALL",
    status: "ALL",
    sort: "createdAt,desc"
}

const ManufacturerListPage: React.FC = () => {

    const [manufacturers, setmanufacturersTo] = useState<IManufacturer[]>([]);
    const [manufacturersFilters, setmanufacturersFiltersTo] = useState<IManufaturerFilter>(initialManufacturerFiltersValues);
    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);

    const manufacturerService = useManufacturerService();

    const getAllManufacturers = (page: number, size: number) => {
        let params: IManufacturerFilterRequest = {
            search: manufacturersFilters?.search === "ALL" ? "" : manufacturersFilters?.search.trim(),
            status: manufacturersFilters?.status === "ALL" ? "" : manufacturersFilters?.status,
            page: page,
            size: size,
            sort: manufacturersFilters?.sort,
            manufacturerType: manufacturersFilters?.manufacturerType === "ALL" ? "" : manufacturersFilters?.manufacturerType
        }
        manufacturerService.getAllManufacturers(params)
            .then((manufacturersResponse) => {
                if (manufacturersResponse.data.data) {
                    const { totalElements, totalPages } = manufacturersResponse?.data?.data;
                    setPaginationTo({
                        ...pagination,
                        totalPages: totalPages,
                        totalRecords: totalElements
                    });
                    setmanufacturersTo(manufacturersResponse?.data?.data?.content);
                }
                else {
                    setmanufacturersTo([]);
                }
            })
            .catch((error) => {
                console.error("Brands fetching error - ", error);
            });
    }

    const handleFiltersChange = (event: any) => {
        const { name, value } = event.target;
        setPaginationTo({...pagination, currentPage: 0})
        setmanufacturersFiltersTo({ ...manufacturersFilters, [name]: value ?? "" });
    }

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage
        }));
    };

    useEffect(() => {
        getAllManufacturers(pagination.currentPage, pagination.pageSize);
    }, [pagination.currentPage, pagination.pageSize, manufacturersFilters.search, manufacturersFilters.status, manufacturersFilters.sort, manufacturersFilters.manufacturerType]);


    const navigate = useNavigate();
    
    const handleCreateManufacturer = () => {
		navigate(`${ ADMIN_ROUTES.MANUFACTURER_CREATE }`);
	};

    return (
        <div className="grid gap-y-3">
            <div className="flex justify-between">
                <TitleHeading text="Manufacturer Name" />
                <Button variant="contained" label="Add Manufacturer" onClick={ handleCreateManufacturer } />
            </div>
            <ManufacturerListTemplate manufacturers={ manufacturers } pagination={ pagination } handlePaginationChange={ handlePaginationChange } handleRowsPerPageChange = { handleRowsPerPageChange }  handleFiltersChange={ handleFiltersChange } manufacturersFilters={ manufacturersFilters } />
        </div>
    )
}

export default ManufacturerListPage;
