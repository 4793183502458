import { useAuthenticatedUser } from '../hooks/useAuthenticatedUser';
import { API_METHOD } from '../utils/constant';
import { removeEmptyFields, replaceUrlParams } from '../utils/helper';
import { request } from './index';
import {
  AuctionFormData,
  RFQContextTypes,
} from '../App/Constants/Procurement/ReverseAuctionRFQ/types';

const PURCHASE_REQUISITION_URLS = {
  GET_ALL_AUCTION: 'procurement/indent/auction',
  GET_AUCTION_INDENT: 'procurement/indent/auction/:auctionId',
  CREATE_AUCTION: 'auction',
  REJECT_AUCTION: 'procurement/indent/auction/:auctionId/reject',
  APPROVE_AUCTION: 'procurement/indent/auction/:auctionId/approve',
};

export const useReverseAuctionService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const getAuctions = (params: Partial<RFQContextTypes>) => {
    params = removeEmptyFields(params);
    return request(
      API_METHOD.GET,
      PURCHASE_REQUISITION_URLS.GET_ALL_AUCTION,
      authenticatedUser,
      null,
      { params }
    );
  };

  const getIndentPlusAuction = (auctionId: string) => {
    return request(
      API_METHOD.GET,
      replaceUrlParams(PURCHASE_REQUISITION_URLS.GET_AUCTION_INDENT, {
        auctionId: auctionId,
      }),
      authenticatedUser
    );
  };

  const createAuction = (payload: Partial<AuctionFormData>) => {
    return request(
      API_METHOD.PUT,
      PURCHASE_REQUISITION_URLS.CREATE_AUCTION,
      authenticatedUser,
      payload
    );
  };

  const rejectAuction = (auctionId: string) => {
    return request(
      API_METHOD.PUT,
      replaceUrlParams(PURCHASE_REQUISITION_URLS.REJECT_AUCTION, {
        auctionId: auctionId,
      }),
      authenticatedUser
    );
  };

  const approveAuction = (auctionId: string) => {
    return request(
      API_METHOD.PUT,
      replaceUrlParams(PURCHASE_REQUISITION_URLS.APPROVE_AUCTION, {
        auctionId: auctionId,
      }),
      authenticatedUser
    );
  };

  return {
    getAuctions,
    getIndentPlusAuction,
    createAuction,
    rejectAuction,
    approveAuction,
  };
};
