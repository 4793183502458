import React, { useEffect, useState } from 'react';
import rightArrowIcon from "./../../../../Assets/Icons/rightArrowIcon.svg";
import leftArrowIcon from "./../../../../Assets/Icons/leftArrowicon.svg";

const TablePagination = ({ pageNumber, pageSize, handlePageSize, handlePageNumber, totalRecordsData }) => {

    const [currentPage, setCurrentPage] = useState(pageNumber);
    const [totalRecords, setTotalRecords] = useState(totalRecordsData);

    const handlePageSizeLocalUpdate = (e) => {
        const newPageSize = parseInt(e.target.value);
        setCurrentPage(0);
        handlePageNumber(0);
        handlePageSize(newPageSize);
    };

    useEffect(()=>{
        setCurrentPage(0);
        handlePageNumber(0);
    },[totalRecordsData])

    const handlePageNumberLocalUpdate = (type) => {
        if (type === "pageBack" && currentPage > 0) {
            setCurrentPage(currentPage - 1);
            handlePageNumber(currentPage - 1);
        } else if (type === "pageForward" && currentPage < Math.ceil(totalRecords / pageSize) - 1) {
            setCurrentPage(currentPage + 1);
            handlePageNumber(currentPage + 1);
        }
    };

    const CalculatePaginationDetails = () => {
        const totalPages = totalRecords > 0 ? Math.ceil(totalRecords / pageSize) : 1;
        const currentPageNumber = currentPage + 1; // Add 1 to make it 1-based index
        return (
            <>{` Page ${currentPage + 1} of ${totalPages}`}</>
        );
    };


    useEffect(() => {
        setTotalRecords(totalRecordsData);
    }, [totalRecordsData]);


    return (
        <div className="relative w-full flex flex-row py-[0.5rem] px-[0rem] box-border items-center justify-end gap-[1.5rem] text-left text-[1rem] text-text-color font-roboto mr-6">
            <div className="flex flex-row items-start justify-start gap-[0.75rem]">
                <div className="relative">Rows per page</div>
                <div className="flex flex-row items-center justify-start gap-[0.25rem]">
                    <div className="relative">
                        <select
                            onChange={handlePageSizeLocalUpdate}
                            value={pageSize}
                            // value={2}
                            name="pageSize"
                        >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value={25}>25</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="flex flex-row items-start justify-start">
                <div className="relative">
                    <CalculatePaginationDetails />
                </div>

            </div>
            <button
                name="pageBack"
                onClick={() => handlePageNumberLocalUpdate("pageBack")}
            >
                <img
                    className="relative w-[0.31rem] h-[0.81rem] overflow-hidden shrink-0"
                    alt=""
                    src={leftArrowIcon}
                />
            </button>
            <button
                name="pageForward"
                onClick={() => handlePageNumberLocalUpdate("pageForward")}
                disabled={currentPage === Math.ceil(totalRecords / pageSize)}
            >
                <img
                    className="relative w-[0.31rem] h-[0.81rem] overflow-hidden shrink-0"
                    alt=""
                    src={rightArrowIcon}
                />
            </button>
        </div>
    );
};

export default TablePagination;