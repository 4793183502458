import { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import * as yup from "yup";
import { useFormik } from 'formik';
import { HTTP_STATUS, MODE } from '../../../../utils/types';
import { useMaterialRequirementOtherPreferenceService } from '../../../../services/useMaterialRequirementOtherPreferenceService';
import { GENERIC_EXCEPTION_CODE, MATERIAL_REQUIREMENT_CONSTANT } from '../../../../utils/constant';
import PreferredProductCategoryFormTemplate from './PreferredProductCategoryForm.template';
import PreferredProductCategoryViewTemplate from './PreferredProductCategoryView.template';

import { useSnackbar } from '../../../../hooks/useSnackBar';
import { IManufacturer } from '../../../pages/AdminMaster/Manufacturer/ManufacturerList.page';
import { IMultiSelectOption } from '../../../atoms/MultiSelectInput2';
import { useManufacturerService } from '../../../../services/useManufacturerService';
import { useBrandService } from '../../../../services/useBrandService';
import { useParams } from 'react-router-dom';
import { useBusinessProfileService } from '../../../../services/useBusinessProfileService';

export interface IPreferredProductCategory {
    productCategories: number[]
}

const initialValues = {
    productCategories: [],
}

const validationSchema = yup.object().shape({
    materialRequirement: yup.array().of(
        yup.object().shape({
            productCategories: yup.array()
                .of(yup.number())
                .min(1, 'You must select at least one product category')
                .required('You must select at least one product category'),
        })
    )
})

const PreferredProductCategoryTemplate = () => {
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [mode, setModeTo] = useState<MODE>(MODE.VIEW);
    const [isFormMode, setIsFormModeTo] = useState<boolean>(false);
    const materialRequirementOtherPreferenceService = useMaterialRequirementOtherPreferenceService();
    
    const params = useParams();
    const businessId = Number(params.id);
    const bussinessProfileService = useBusinessProfileService();
    const [businessProfile, setBusinessProfileTo] = useState<{ userId: number } | null>(null);

    const getBusinessProfile = async () => {
        try {
            const businessProfileResponse = await bussinessProfileService.getBusinessProfile(businessId);
            if (businessProfileResponse.status === HTTP_STATUS.OK) {
                const profileData = businessProfileResponse?.data?.data;
                setBusinessProfileTo(profileData);
            }
        } catch (error) {
            showSnackbar('error', 'Business Profile fetch failed');
        }
    };

    const formik = useFormik<IPreferredProductCategory>({
        initialValues,
        validationSchema,
        onSubmit: async (value) => {
            const payload = {
                productCategories: value.productCategories
            }
            const res = await materialRequirementOtherPreferenceService.updateMaterialRequirementsOtherPreference({productCategories: value.productCategories}, MATERIAL_REQUIREMENT_CONSTANT.PRODUCT_CATEGORY, businessProfile?.userId as number)
            if (res?.status === HTTP_STATUS.OK) {
                setIsFormModeTo(false);
                setModeTo(MODE.EDIT);
                showSnackbar('success', 'Successfully updated preference')
            } else {
                showSnackbar('error', 'Failed to update preference')
            }
        }
    })

    const getMaterialRequirementOtherPreference = async () => {
        const res = await materialRequirementOtherPreferenceService.getMaterialRequirementOtherPreference(businessProfile?.userId as number)
        if (res?.status === HTTP_STATUS.OK) {
            if (res.data.data.productCategories === null)
                setModeTo(MODE.ADD)
            else {
                formik.setValues({
                    productCategories: res?.data?.data?.productCategories,
            })
                setModeTo(MODE.EDIT)
            }
            return await res?.data?.data;
        } else if (res?.status === HTTP_STATUS.BAD_REQUEST && res.data.exceptionCode === GENERIC_EXCEPTION_CODE.DATA_NOT_FOUND) {
            return setModeTo(MODE.ADD)
        } else {
            setModeTo(MODE.ADD)
        }
        return null;
    }

    useEffect(() => {
        if (businessProfile) {
            getMaterialRequirementOtherPreference();
        }
    }, [businessProfile])

    useEffect(() => {
        getBusinessProfile();
    }, [])

    const handleSelectChange = (key: string) => (option: IMultiSelectOption[]) => {
        formik.setFieldValue(key, option);
    };

    return (
        <>
            {SnackBarComponent}
            {isFormMode ? <PreferredProductCategoryFormTemplate
                handleSelectChange={handleSelectChange}
                formik={formik}
            />
                : <PreferredProductCategoryViewTemplate formik={formik} setModeTo={setModeTo} mode={mode} setIsFormModeTo={setIsFormModeTo} />}
        </>
    )
}

export default PreferredProductCategoryTemplate