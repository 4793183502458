import { ReactNode } from "react";
import { Modal } from "@mui/material";

// Todo
interface ModalProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
}

const CustomModal = ({ open, onClose, children }: ModalProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="absolute flex justify-center items-center w-full h-screen">
        <div className="max-w-[1270px] max-h-[90vh] overflow-hidden">
          {children}
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;
