import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';

import { useNavigate } from 'react-router-dom';
import { HTTP_STATUS, SECONDARY_CATALOGUE_SECTIONS } from '../../../utils/types';
import { FormikProps, useFormik } from 'formik';
import ProductSelectionTemplate from './ProductSelection.template';
import CatalogueDetailTemplate from './CatalogueDetail.template';
import PriceFormTemplate from './PriceForm.template';
import ImageUploadTemplate from './ImageUpload.template';
import SecondaryCataloguePreviewTemplate from './SecondaryCataloguePreview.template';
import { ISecondaryCatalogueChangeResponse } from '../../pages/SecondaryCatalogue/SecondaryCatalogueUpdate.page';
import { useFileService } from '../../../services/useFileService';
import { useSecondaryCatalogueService } from '../../../services/useSecondaryCatalogueService';
import { ADMIN_ROUTES, MODE, RESOURCE_TYPE } from '../../../utils/constant';
import { ImageData } from '../../organisms/ImageUploader';
import { useSnackbar } from '../../../hooks/useSnackBar';

interface ISecondaryCatalogueFormProps {
  secondaryCatalogue: ISecondaryCatalogueChangeResponse | null;
  mode: string;
  image: (ImageData | File)[];  
  setImagesTo: (images:any) => void;
}

export interface secondaryCatalogueSectionProps {
  setCurrentSectionTo: (section: SECONDARY_CATALOGUE_SECTIONS) => void;
  formik: FormikProps<ISecondaryCatalogueForm>;
  id: number | null;
  mode: string;
  image: (ImageData | File)[];  
  setImagesTo: (images:any) => void;
}
export interface ISecondaryCatalogueForm {
  secondaryProductId: number | null;
  catalogueName: string;
  minimumQuantity: number | null;
  availableQuantity: number | null;
  application: string[];
  defectDetails: string;
  additionalProductDetails: string;
  keywords: string[];
  price: {[key: string]: number;} | null;
  quantity: number | null;
  gradeId: number | null;
  packagingType: any;
  termsConditions: string;
  status: string;
}

const SecondaryCatalogueFormTemplate: React.FC<ISecondaryCatalogueFormProps> = ({ secondaryCatalogue, mode, image, setImagesTo }) => {
  const navigate = useNavigate();
  const secondaryCatalogueService = useSecondaryCatalogueService();
  const fileService = useFileService();
  const { showSnackbar, SnackBarComponent } = useSnackbar();
  const [currentSection, setCurrentSectionTo] = useState<SECONDARY_CATALOGUE_SECTIONS>(SECONDARY_CATALOGUE_SECTIONS.SECONDARY_INVENTORY);

  const initialValues: ISecondaryCatalogueForm = {
    secondaryProductId: secondaryCatalogue?.secondaryProductId ?? null,
    catalogueName: secondaryCatalogue?.catalogueName ?? '',
    minimumQuantity: secondaryCatalogue?.minimumQuantity ?? null,
    availableQuantity: secondaryCatalogue?.availableQuantity ?? null,
    application: secondaryCatalogue?.application ?? [], 
    defectDetails: secondaryCatalogue?.defectDetails ?? '',
    additionalProductDetails: secondaryCatalogue?.additionalProductDetails ?? '',
    keywords: secondaryCatalogue?.keywords ?? [],
    price: secondaryCatalogue?.price ?? null,
    quantity: secondaryCatalogue?.quantity ?? null,
    gradeId: secondaryCatalogue?.gradeId ?? null,
    packagingType: secondaryCatalogue?.packagingType ?? [],
    termsConditions: secondaryCatalogue?.termsConditions ?? '',
    status: secondaryCatalogue?.status ?? 'ACTIVE',
  };
  const validationSchema = Yup.object().shape({
    secondaryProductId: Yup.number().required('Product is required to choose'),
    catalogueName: Yup.string().required('Catalogue name is required'),
    minimumQuantity: Yup.number().required('Minimum Quantity is required'),
    availableQuantity: Yup.number().required('Maxmimum Quantity is required'),
    application: Yup.array()
    .of(Yup.string().required('Application is required'))
    .min(1, 'At least one Application is required'),
    defectDetails: Yup.string().required('Defect details are required'),
    additionalProductDetails: Yup.string().required('Additional product details are required'),
    keywords: Yup.array()
    .of(Yup.string().required('Keyword is required'))
    .min(1, 'At least one keyword is required'),
    price: Yup.object().shape({
      default: Yup.string().required('Default price is required'),
    }),
    quantity: Yup.number().nullable(),
    gradeId: Yup.number().nullable(),
    packagingType: Yup.array()
    .of(Yup.string().required('PackagingType is required'))
    .min(1, 'At least one PackageType is required'),
    termsConditions: Yup.string().required('Terms and conditions are required'),
    status: Yup.string().required('Status is required'),
  });

  const formik = useFormik<ISecondaryCatalogueForm>({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      const application = formik.values.application.join(', ');
      const keywords = formik.values.keywords.join(', ');
  
      const body = {
        secondaryProductId : formik.values?.secondaryProductId,
        catalogueName: formik.values.catalogueName,
        minimumQuantity: formik.values?.minimumQuantity,
        availableQuantity: formik.values?.availableQuantity,
        application,
        defectDetails: formik.values.defectDetails,
        additionalProductDetails: formik.values.additionalProductDetails,
        keywords,
        status: formik.values.status,
        packagingType: formik.values.packagingType,
        termsConditions:  formik.values.termsConditions,
        price: formik.values?.price ,
    }

    try {
      const secondaryCatalogueResponse = await (mode === MODE.ADD ? secondaryCatalogueService.createSecondaryCatalogue(body) : secondaryCatalogueService.updateSecondaryCatalogue(secondaryCatalogue?.id as number, body));
      if (secondaryCatalogueResponse.status === HTTP_STATUS.OK) {
        const resourceId = mode === MODE.ADD ? secondaryCatalogueResponse.data.data.id : secondaryCatalogue?.id as number;
        await fileService.uploadImage(image,resourceId,RESOURCE_TYPE.SECONDARY_CATALOGUE);
        navigate(ADMIN_ROUTES.SECONDARY_CATALOGUE_LIST);
      }
    } catch (error) {
      showSnackbar("error", `Secondary Catalogue creation failed ${error}`)
    }
    },
  });

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      ...secondaryCatalogue
    })
  }, [secondaryCatalogue])

  const secondaryCatalogueSectionProps: secondaryCatalogueSectionProps = {
    setCurrentSectionTo: setCurrentSectionTo,
    formik: formik,
    id: secondaryCatalogue?.id || null,
    mode: mode,
    image: image,
    setImagesTo: setImagesTo,
  };

  const secondaryCatalogueSectionView = useMemo(() => {
    switch (currentSection) {
      case SECONDARY_CATALOGUE_SECTIONS.SECONDARY_INVENTORY:
        return <ProductSelectionTemplate {...secondaryCatalogueSectionProps} />;

      case SECONDARY_CATALOGUE_SECTIONS.CATALOGUE_DETAILS:
        return <CatalogueDetailTemplate {...secondaryCatalogueSectionProps} />;

      case SECONDARY_CATALOGUE_SECTIONS.PRICE_DETAILS:
        return <PriceFormTemplate {...secondaryCatalogueSectionProps} />;

      case SECONDARY_CATALOGUE_SECTIONS.IMAGES_UPLOAD:
        return <ImageUploadTemplate {...secondaryCatalogueSectionProps} />;

      case SECONDARY_CATALOGUE_SECTIONS.PREVIEW:
        return <SecondaryCataloguePreviewTemplate {...secondaryCatalogueSectionProps} />;

      default:
        return <ProductSelectionTemplate {...secondaryCatalogueSectionProps} />;
    }
  }, [currentSection, formik]);

  return (
    <div>{secondaryCatalogueSectionView}</div>
  )
}

export default SecondaryCatalogueFormTemplate