import React, { useEffect, useState } from 'react'
import TabsV2, { ITabsSchema } from '../../molecules/TabsV2/TabsV2';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import KeyPersonDetailsPage from './KeyPersonDetails.page';
import Preferences from '../../templates/Preferences/Preferences';
import { makeRoute } from '../../../utils/helper';
import { ADMIN_ROUTES } from '../../../utils/constant';
import BusinessLocationPage from '../Dashboard/Location/BusinessLocation.page';
import StatutoryDetailPage from '../Dashboard/Statutory/StatutoryDetail.page';
import BusinessProfilePage from './BusinessProfile.page';
import ProfileOverviewPage from './ProfileOverview.page';
import { createUseStyles } from 'react-jss';
import ChangePasswordPage from './ChangePassword.page';
import CustomerMappingPage from './CustomerMapping.page';
import MyMaterialPage from './MyMaterial.page';
import CustomerId from '../../organisms/CustomerDetails/CustomerId';

const useStyles = createUseStyles((theme: any) => ({
  container: {
    borderBottom: `1px solid ${theme.palette.border.tritiaryLight}`
  },
  text: {
    color: theme.palette.textV2.primary.primary900,
    borderBottom: `1px solid ${theme.palette.border.tritiaryLight}`
  }
}));

export const CustomerDetailsViewPage: React.FC = () => {

  const classes = useStyles();

  const schema: ITabsSchema[] = [
    { label: 'Profile', component: <ProfileOverviewPage /> },
    { label: 'Business Profile', component: <BusinessProfilePage /> },
    { label: 'Business Location', component: <BusinessLocationPage /> },
    { label: 'Key Person Detail', component: <KeyPersonDetailsPage /> },
    { label: 'My Materials', component: <MyMaterialPage /> },
    { label: 'Statutory Documents ', component: <StatutoryDetailPage /> },
    { label: 'Preferences', component: <Preferences /> },
    { label: 'Change Password', component: <ChangePasswordPage /> },
    { label: 'Customer Mapping', component: <CustomerMappingPage /> },
  ]

  const [searchParams, setSearchParams] = useSearchParams();

  const currentTab = searchParams.get('tab') || "1";
  const params = useParams();
  const id = Number(params.id);
  const [activeTab, setActiveTab] = useState<string>("1");
  const navigate = useNavigate();

  const handleTabChange = (tab: string | number) => {
    setActiveTab(tab.toString());
    const route = makeRoute(ADMIN_ROUTES.CUSTOMER_MANAGEMENT_VIEW, { query: { tab: tab.toString() }, params: { id: id } });
    navigate(`${route}`);
  };

  useEffect(() => {
    setActiveTab(currentTab);
  }, [currentTab])

  return (
    <div>
      <div className={`flex justify-between ${classes.text}`}>
        <div className={`text-2xl font-medium pb-6`}>Customer Management</div>
        <div><CustomerId customerId={id} title='Customer ID' /></div>
      </div>
      <div className='pt-6'>
        <TabsV2 schema={schema} value={activeTab} setValue={handleTabChange} />
      </div>
    </div>
  )
}
