import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export const CONFIGURATION_URLS = {
  CREATE_CONFIGURATION: "/configuration/",
  GET_CONFIGURATION: "/configurations/",
  UPDATE_CONFIGURATION: "/configuration/:id",
  CONFIGURATION: "/configurations/"
};

interface IConfigurationFilterRequest {
  contexts?: string;
}

interface IConfigurationRequestBody {
    context: string;
    data: any;
}

export const useConfigurationService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const createConfiguration = (data: IConfigurationRequestBody) => {
    return request(API_METHOD.POST, CONFIGURATION_URLS.CREATE_CONFIGURATION, authenticatedUser, data);
  };

  const getConfiguration = (params: IConfigurationFilterRequest) => {
    return request(API_METHOD.GET, CONFIGURATION_URLS.GET_CONFIGURATION, authenticatedUser, null, { params } );
  };

  const getConfiguration1 = (params: any) => {
    return request(API_METHOD.GET, CONFIGURATION_URLS.CONFIGURATION, authenticatedUser, null, { params } );
  };

  const updateConfiguration = (id: number, data: IConfigurationRequestBody) => {
    return request(API_METHOD.PUT, replaceUrlParams( CONFIGURATION_URLS.UPDATE_CONFIGURATION, { id } ), authenticatedUser, data);
  };

  return {
    createConfiguration,
    getConfiguration,
    updateConfiguration,
    getConfiguration1
  };
};
