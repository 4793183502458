import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export const QUALITY_GRADE_URLS = {
  GET_ALL_QUALITY_GRADE: "/quality-grades",
  GET_QUALITY_GRADE: "/quality-grade/:id"
};

export interface IQualityGradeFilter{
  standardId?: number;
  search?: string;
  standardName?: string;
  type: string;
  page: number;
  size: number;
  sort?: string;
}

export const useQualtiyGradeService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const getAllQualityGrades = (params: IQualityGradeFilter) => {
    return request(API_METHOD.GET, QUALITY_GRADE_URLS.GET_ALL_QUALITY_GRADE, authenticatedUser, null, { params } );
  };

  const getQualityGrade = (id: number) => {
    return request(API_METHOD.GET, replaceUrlParams( QUALITY_GRADE_URLS.GET_QUALITY_GRADE, { id } ), authenticatedUser);
  };

  return {
    getAllQualityGrades,
    getQualityGrade
  };
};
