import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { ServicePayload, useVASMasterCategoryService } from '../../../../services/useVASMasterCategoryService';
import { makeRoute } from '../../../../utils/helper';
import { ADMIN_ROUTES, EXCEPTION_CODE, MODE } from '../../../../utils/constant';
import { HTTP_STATUS } from '../../../../utils/types';
import SuperServiceViewTemplate from '../../../templates/VASMaster/Categories/SuperServiceView.template';

interface SuperServiceUpdatePageProps {
    mode: string
    service: any
}

const validationSchema = Yup.object().shape({
    name: Yup.string().max(30, "Super Service Name cannot be more than 30 characters").required("Super Service Name is required"),
    description: Yup.string().max(500, "Service Description cannot be more than 500 characters").required("Service Description is required"),
});

const SuperServiceViewPage: React.FC<SuperServiceUpdatePageProps> = ({ mode, service }) => {
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const vasMasterCategoryService = useVASMasterCategoryService();
    const navigate = useNavigate();

    const params = useParams();
    const serviceId = Number(params.id);

    const onBack = () => {
        navigate(makeRoute(ADMIN_ROUTES.VAS_MASTER_CATEGORIES_LIST, { query: { type: 'SUPER_SERVICE' } }));
    }

    const updateService = async (service: ServicePayload) => {
        vasMasterCategoryService.updateCategory(serviceId, service)
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    showSnackbar('success', "Status Updated");
                    onBack();
                } else if (res.data.exceptionCode === EXCEPTION_CODE.DUPLICATE_ENTRY) {
                    showSnackbar('error', "Super Service already exists. Please try again");
                }
            }).catch((error) => {
                console.error("Error updating Super Service: ", error);
                showSnackbar('error', "Error updating Super Service");
            })
    }

    const formik = useFormik<ServicePayload>({
        initialValues: {
            name: service?.name ?? "",
            serviceCode: service?.serviceCode ?? "",
            status: service?.status,
            description: service?.description ?? "",
            level: 0,
            parentId: null
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            updateService({ ...values, status: values.status })
        }
    })

    return (
        <div>
            {SnackBarComponent}
            <SuperServiceViewTemplate mode={MODE.VIEW} onBack={onBack} formik={formik} />
        </div>
    )
}

export default SuperServiceViewPage