import axios from 'axios';
import {
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Autocomplete from '@mui/material/Autocomplete';

const SearchDropDownIndex = ({ displayLabel, fetchDetails, suggestions, updateDetails, existingUpcCode = '', mode = 'ADD', error, setError }) => {

  const [searchUPC, setSearchUPC] = useState(existingUpcCode);
  const [suggestion, setSuggestion] = useState(suggestions);

  useEffect(() => {
    setSuggestion(suggestions)
  }, [suggestions]);

  const fetchUpcDetails = async () => {
    fetchDetails(searchUPC);
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const delayedFetchUpcDetails = debounce(fetchUpcDetails, 200);

  const handleSearchUPCChange = (value) => {
    setSearchUPC(value);
    setError(false);
  };

  const handleOptionChange = (event, newValue) => {
    updateDetails(newValue);
    setSearchUPC("");
  }

  useEffect(() => {
    fetchUpcDetails()
  }, []);

  useEffect(() => {
    setSuggestion([]);
    delayedFetchUpcDetails();
  }, [searchUPC]);

  return (
    <div className="self-stretch flex flex-row items-start justify-start gap-[1.38rem]">
      <div className="flex-1 flex flex-row flex-wrap items-start justify-start">
        <div className="flex-1 h-[3rem] flex flex-row items-start justify-start">
          <div className="flex-1 h-[3rem] flex flex-row items-center justify-start">
            <Autocomplete
              className={`flex-1 rounded-md focus:outline-none ${error ? 'ring-red-500' : 'ring-blue-500'
                } focus:border-blue-500`}
              id="upc-autocomplete"
              options={suggestion}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={`flex-1 border ${error ? 'border-red-500' : 'border-gray-300'
                    } rounded-md focus:outline-none ${error ? 'ring-red-500' : 'ring-blue-500'
                    } focus:border-blue-500`}
                  label={displayLabel}
                  variant="outlined"
                  fullWidth
                  value={searchUPC}
                  onChange={(e) => handleSearchUPCChange(e.target.value)}
                  required={mode === 'EDIT' ? false : true}
                  error={error}
                />
              )}
              onInputChange={(event, newValue) => handleSearchUPCChange(newValue)}
              inputValue={searchUPC}
              onChange={(event, newValue) => handleOptionChange(event, newValue)}
            />
          </div>
        </div>
      </div>
    </div>

  );
};

export default SearchDropDownIndex;