import { useState, useMemo } from 'react';
import noData from '../../../assets/images/noDataImage.svg';
import BusinessProfileNoDataTemplate from './BusinessProfileNoData.template';
import CINUpdateTemplate from './CINUpdate.template';
import { IBusinessProfile } from '../../pages/CustomerManagement/ProfileOverview.page';
import CINViewTemplate from './CINView.template';

interface ICINTemplateProps {
    businessProfile: IBusinessProfile | null;
    updateBusinessProfileSection: (section: string, values: any) => void;
}

const CINTemplate: React.FC<ICINTemplateProps> = ({ businessProfile, updateBusinessProfileSection }) => {

    const [isEditing, setIsEditing] = useState(false);

    const CINSectionView = useMemo(() => {
        if (isEditing) {
            return (
                <CINUpdateTemplate
                    businessProfile={businessProfile}
                    updateBusinessProfileSection={updateBusinessProfileSection}
                    setIsEditing={setIsEditing}
                />
            );
        }
        if (businessProfile?.cin) {
            return (
                <CINViewTemplate
                    setIsEditing={setIsEditing}
                    businessProfile={businessProfile}
                />
            );
        }
        return (
            <BusinessProfileNoDataTemplate
                heading="You haven't provided CIN details for your business."
                setIsEditing={setIsEditing}
                noDataImg={noData}
                label="Add CIN Details"
            />
        );
    }, [isEditing, businessProfile, updateBusinessProfileSection]);

    return <>{CINSectionView}</>;
};

export default CINTemplate;
