import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomHeading from "../../../Shared/CustomHeading/CustomHeading";
import { CustomButton } from "../../../Shared/Button/CustomButton";
import TermsModal from "../../../Shared/TermsAndCondition/TermsModal";
import ImageButton from "../../../Shared/ImageButton/ImageButton";
import viewBlackSvg from "../../../Assets/Images/viewBlack.svg";
import closeBlackSvg from "../../../Assets/Images/closeBlack.svg";
import productPng from "../../../Assets/Images/product.png";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import axios from "axios";
import QuotationDetailsModal from "./QuotationDetailsModal";
import { capitalizeFirstLetter } from "./CreateQuotation";
import { useAuthenticatedUser } from "../../../../hooks/useAuthenticatedUser";

const UpdateSecondaryQuotation = () => {

  const navigate = useNavigate();
  const { user } = useAuthenticatedUser();
  const params = useParams();
  const mode = "EDIT";

  const [ leadId, setLeadId ] = useState()

  const [ singleQuotationData, setSingleQuotationData ] = useState([]);
  const [ termsAndCondition, setTermsAndCondition ] = useState("");
  const [leadQuantity, setLeadQuantity] = useState(0);

  const fetchQuotation = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_V1_URL}/quotation/${Number(params.id)}`,
        {
          headers: {
            Authorization: `${user.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setLeadId(response.data.data.leadId);
      setTermsAndCondition(response.data.data.termsAndCondition);
      setCharges(response?.data?.data?.charges);
      setSingleQuotationData(response.data.data.quotationUpcResponseDTO);
    } catch (error) {
      console.error("Error fetching lead details:", error);
    }
  };

  //--------------------Fetch LeadUpc data--------------------

  const [leadUpcData, setLeadUpcData] = useState([]);
  const [comapanyName, setCompanyName] = useState("");
  const [actorId, setActorId] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [skuIds, setSkuIds] = useState(null); 
  const [productUpcData, setProductUpcData] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteUpcId, setDeleteUpcId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [upcSkuEditDetails, setUpcSkuEditDetails] = useState({});
  const [content, setContent] = useState("");

  const fetchLeadUpc = async (leadId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_V1_URL}/lead/${leadId}`,
        {
          headers: {
            Authorization: `${user.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      var upcData = response.data.data.leadUpcResponseDTO;
      setLeadUpcData(upcData);
      setCompanyName(response.data.data.companyName);
      setActorId(response.data.data.actorId);
      setQuantity(
        response.data.data.leadUpcResponseDTO.reduce(
          (total, item) => total + item.quantity,
          0
        )
      );
      setSkuIds(
        response?.data?.data?.leadUpcResponseDTO.map((item) => item.secondarySkuId)
      );
    } catch (error) {
      console.error("Error fetching lead details:", error);
    }
  };

  //-----------Terms and Condition Modal------------------

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  //---------------------Fetch UPC Data-------------------------------------

  const handleDeleteLeadUpc = async (leadUpcId) => {
    setDeleteUpcId(leadUpcId);
    const confirmed = window.confirm(
      "Are you sure you want to remove this Product ?"
    );

    if (confirmed) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_V1_URL}/lead-upc/${leadUpcId}`,
          {
            headers: {
              Authorization: `${user.token}`,
              "Content-Type": "application/json",
            },
          }
        );
        window.alert("Product removed successfully!");
      } catch (error) {
        console.error("Error deleting leadUpc:", error);
      }
    }
  };

  // -----------------------------------------------------------------

  const fetchSingleProductUpc = async (skuId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_V1_URL}/search/secondary-product/${skuId}?catalogueName=${singleQuotationData[0].catalogueName}`,
        {
          headers: {
            Authorization: `${user.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const newData = response.data.data;
      return newData;
    } catch (error) {
      console.error(`Error fetching lead details for upcId ${skuId}:`, error);
    }
  };

  const fetchAllProductUpcs = async (skuIds) => {
    const upcFetchDetails = [];
    if (
      skuIds &&
      Array.isArray(skuIds) &&
      skuIds.length > 0 &&
      productUpcData.length === 0
    ) {
      for (const x of skuIds) {
        let skuDetails = await fetchSingleProductUpc(x);
        upcFetchDetails.push(skuDetails);
      }
      setProductUpcData(upcFetchDetails);
    }
  };


  useEffect(() => {
    if (skuIds && Array.isArray(skuIds) && skuIds.length > 0) {
      fetchAllProductUpcs(skuIds);
    }
  }, [skuIds]);

  useEffect(()=>{
    fetchQuotation();
  },[]);

  useEffect(() => {
    if(leadId){
      fetchLeadUpc(leadId);
    }
  }, [leadId, deleteUpcId]);

  const handleRedirectBack = () => {
    navigate((-1));
  };

  const handleOpenModal = (id) => {
    setIsModalOpen(id);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const [charges, setCharges] = useState([
    {
      warehouseCharges: "",
      handlingCharges: "",
      packagingCharges: "",
      otherCharges: "",
      gst: "",
      tcs: "",
    },
  ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (value >= 0) {
      setCharges([{ ...charges[0], [name]: +value }]);
    }
  };

  const handleUpdateDetails = (
    secondarySkuId,
    key,
    payload,
    skuCode,
    warehouse,
    basePrice,
    availableQuantity
  ) => {
    if(payload.target.name !== "packaging" && !/^[0-9]*$/.test(payload.target.value) )
    return
    if (payload.target.name === "quantity") {
      if(payload.target.value > availableQuantity){
        alert(`Quantity entered is more than available quantity - ${availableQuantity} MT`)
        payload.target.value = availableQuantity
      }
      if (payload.target.value >= 0) {
        setUpcSkuEditDetails((prevDetails) => {
          const updatedDetails = {
            ...prevDetails,
            [secondarySkuId]: {
              ...(prevDetails[secondarySkuId] || {}),
                [key]: payload.target.value,
                skuCode: skuCode,
                warehouse: warehouse,
                basePrice: basePrice,
             
            },
          };
          return updatedDetails;
        });
      }
    } else {

      setUpcSkuEditDetails((prevDetails) => {
        const updatedDetails = {
          ...prevDetails,
          [secondarySkuId]: {
            ...(prevDetails[secondarySkuId] || {}),
              [key]: payload.target.value,
              skuCode: skuCode,
              warehouse: warehouse,
              basePrice: basePrice,
           
          },
        };
        return updatedDetails;
      });
    }
  };

  useEffect(()=>{

  },[])

  const formatDataForPost = () => {
    const quotationUpcRequestBodyDTO = [];
    Object.keys(upcSkuEditDetails).forEach((secondarySkuId) => {
      const exampleItem = singleQuotationData.find(
        (item) => item.secondarySkuId === parseInt(secondarySkuId)
      );
      const id = exampleItem ? exampleItem.id : null;
      const upcData = {
        id: id,
        upcId: null,
        upcCode:null,
        quantity: parseFloat(upcSkuEditDetails[secondarySkuId]?.quantity) || 0,
        skuData: [{
            skuId: secondarySkuId,
            skuCode: upcSkuEditDetails[secondarySkuId]?.skuCode,
            quantity:  parseFloat(upcSkuEditDetails[secondarySkuId]?.quantity),
            profit:  upcSkuEditDetails[secondarySkuId]?.profit,
            price:  upcSkuEditDetails[secondarySkuId]?.price,
            packaging:  upcSkuEditDetails[secondarySkuId]?.packaging,
            warehouse:  upcSkuEditDetails[secondarySkuId]?.warehouse,
            basePrice:  upcSkuEditDetails[secondarySkuId]?.basePrice,
          }],
        secondarySkuId: secondarySkuId,
      };

      quotationUpcRequestBodyDTO.push(upcData);
    });

    return {
      leadId: leadId,
      actorId: actorId,
      termsAndCondition: content,
      companyName: comapanyName,
      publishStatus: "PUBLISHED",
      status: "ONGOING",
      charges: charges,
      quotationUpcUpdateRequestBodyDTO: quotationUpcRequestBodyDTO,
      catalogueType: 'SECONDARY'
    };
  };

  const handlePublish = async () => {
    const formattedData = formatDataForPost();
    formattedData.publishStatus = "PUBLISHED";
    try {
      await axios.put(
        `${process.env.REACT_APP_API_V1_URL}/quotation/${Number(params.id)}`,
        formattedData,
        {
          headers: {
            Authorization: `${user.token}`,
            "Content-Type": "application/json",
          },
        }
      ).then((response) => {
        if(response.status === 200){
          handleRedirectBack();
          return
        }
        alert("Error in updating quotation - Please try again");
        return 
      });

    } catch (error) {
      console.error("Error posting quotation:", error);
    }
  };

  const handleSave = async () => {
    const formattedData = formatDataForPost();
    formattedData.publishStatus = "NOTPUBLISHED";
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_V1_URL}/quotation/${Number(params.id)}`,
        formattedData,
        {
          headers: {
            Authorization: `${user.token}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error("Error posting quotation:", error);
    }
  };

  function calculateSubTotal(item) {
    const quantity = parseFloat(item?.quantity || 0);
    const pricePerMT = parseFloat(item?.price || 0);
    return (quantity * pricePerMT);
  }
  const [pricingData, setPricingData] = useState({
    productsSubTotal:0,
    warehousePrice: 0,
    handlingPrice: 0,
    packagingPrice: 0,
    otherPrice: 0,
    subTotal: 0,
    totalGst: 0,
    totalTcs: 0,
  });

  useEffect(() => {
    let productsSubTotal = 0
    let quotationQuantity = 0
    let calculatedPrices = {
      productsSubTotal: 0,
      warehousePrice: 0,
      handlingPrice: 0,
      packagingPrice: 0,
      otherPrice: 0,
      subTotal: 0,
      totalGst: 0,
      totalTcs: 0,
      totalQuantity: 0,
      grandTotal: 0
    }
    for (const key in upcSkuEditDetails) {
      productsSubTotal += calculateSubTotal(upcSkuEditDetails[key]);
      quotationQuantity += parseFloat(upcSkuEditDetails[key]?.quantity) || 0
    }
    calculatedPrices.productsSubTotal = productsSubTotal;
    calculatedPrices.totalQuantity = quotationQuantity;
    calculatedPrices.warehousePrice = charges[0].warehouseCharges * quotationQuantity;
    calculatedPrices.handlingPrice = charges[0].handlingCharges * quotationQuantity;
    calculatedPrices.packagingPrice = charges[0].packagingCharges * quotationQuantity;
    calculatedPrices.otherPrice = +charges[0].otherCharges;

    calculatedPrices.subTotal =
      productsSubTotal +
      calculatedPrices.warehousePrice +
      calculatedPrices.handlingPrice +
      calculatedPrices.packagingPrice +
      calculatedPrices.otherPrice;
    calculatedPrices.totalGst = ((charges[0]?.gst || 0) * (calculatedPrices.subTotal)) / 100;
    calculatedPrices.totalTcs = ((charges[0]?.tcs || 0) * (calculatedPrices.subTotal)) / 100;
    calculatedPrices.grandTotal = calculatedPrices.subTotal + calculatedPrices.totalGst + calculatedPrices.totalTcs;
    setLeadQuantity(quotationQuantity);
    setPricingData(calculatedPrices);
  }, [upcSkuEditDetails, charges])

    
  function calculateProfitLossPercentage(basePrice, offerededPrice) {
    return (((offerededPrice - basePrice) / basePrice) * 100);
  }

  useEffect(() => {
    const updatedDetails = { ...upcSkuEditDetails };

    singleQuotationData.forEach((quotationData) => {
      const { secondarySkuId, skuData } = quotationData;

      if (skuData && skuData.length > 0) {
        const skuCode = skuData[0].skuCode || '';
        const quantity = skuData[0].quantity || 0;
        const price = skuData[0].price || 0;
        const packaging = skuData[0].packaging || '';
        const warehouse = skuData[0].warehouse || '';
        const basePrice = skuData[0].basePrice || 0;
        updatedDetails[secondarySkuId] = {
          ...updatedDetails[secondarySkuId],
          skuCode,
          quantity,
          price,
          packaging,
          warehouse,
          basePrice,
        };
      }
    });
    setUpcSkuEditDetails(updatedDetails);
  }, [singleQuotationData]);



  return (
    <div className="grid gap-y-3 mt-4 m-auto">
      <div className="flex justify-between">
        {mode === "VIEW" ? (
        <CustomHeading text={`Quotation Id - ${Number(params.id)}`} status="" />
        ):(
          <CustomHeading text={`Update Quotation Id - ${Number(params.id)}`} status="" />
        )}
        <div className="m-auto mr-0 flex gap-x-3">
          <CustomButton onClick={handleOpen}>Terms and Condition</CustomButton>
          <TermsModal
            open={open}
            mode={mode}
            termsAndCondition={termsAndCondition}
            setContent={setContent}
            onClose={handleClose}
          />
        </div>
      </div>

      {leadUpcData &&
        leadUpcData.map((leadUpc, index) => {
          return (
            <div className="" key={leadUpc.id}>
              <div className="flex justify-between bg-gray-300 p-3">
                <div className="m-auto ml-0">
                  <div className="text-gray-600 font-roboto text-xl font-semibold">
                   Catalogue Name : {leadUpc?.catalogueName}
                  </div>
                </div>
                <div className="flex justify-between gap-x-6">
                  <div className="m-auto text-gray-500 font-roboto font-semibold text-base">
                    Require Order Quantity : {leadUpc?.quantity || 0} MT
                  </div>
                  <div className="m-auto text-gray-500 font-roboto font-semibold text-base">
                  Packaging Required : {capitalizeFirstLetter(leadUpc?.packagingType)}
                </div>
                  {upcSkuEditDetails[leadUpc.upcId] ? (
                    <div className="m-auto">
                      <ImageButton
                        name="view-quotation-details"
                        imageSrc={viewBlackSvg}
                        onClick={() => handleOpenModal(leadUpc.upcId)}
                      />
                      {isModalOpen === leadUpc.upcId && isModalOpen && (
                        <QuotationDetailsModal
                          upcSkuEditDetails={upcSkuEditDetails[leadUpc.upcId]}
                          charges={charges}
                          onClose={handleCloseModal}
                          totalQuantity={leadQuantity}
                        />
                      )}
                    </div>
                  ) : null}
                  <div className="rounded hidden">
                    <ImageButton
                      className="p-1.5 bg-white rounded"
                      name="delete-lead-upc"
                      imageSrc={closeBlackSvg}
                      onClick={() => handleDeleteLeadUpc(leadUpc.id)}
                    />
                  </div>
                </div>
              </div>

              <div>
                {productUpcData &&
                  productUpcData
                    .filter((item) => leadUpc.secondarySkuId === item.id)
                    .map((item, index) => {
                      return (
                        <div className="product-detail-container bg-white p-3 flex justify-between gap-x-3  w-full">
                          <div className=" flex flex-col gap-y-2 w-1/5 ">
                            <img
                              className="w-max rounded"
                              src={item?.catalogueImages[0]?.path}
                              alt="productImage"
                            />
                            <div
                              className="flex rounded bg-gray-300  h-fit w-full"
                              key={index}
                            >
                              <div
                                className={`grid gap-2 grid-cols-3 p-2 w-full`}
                              >
                                {item.attributes &&
                                  item.attributes.productSpecification.map(
                                    (property, i) => (
                                      <div
                                        className="flex flex-col gap-y-1"
                                        key={i}
                                      >
                                        <div className="text-gray-500 font-roboto text-sm font-normal">
                                          {property?.name}
                                        </div>
                                        <div className="text-gray-500 font-roboto text-sm font-medium flex">
                                        {property?.minValue === property?.maxValue ?
                                            `${property?.minValue} ${property?.uom ? `${property.uom}` : ''}`
                                            : `${property?.minValue} - ${property?.maxValue} ${property?.uom ? `${property.uom}` : ''}`
                                          }
                                        </div>
                                      </div>
                                    )
                                  )}
                              </div>
                            </div>
                          </div>
                          <div
                          className="product-detail-container grid gap-y-2 p-3 rounded bg-white h-36 w-4/5"
                        >
                          <div className="flex justify-between gap-x-8">
                            <div className="text-gray-500 font-roboto text-base font-normal leading-6">
                              SKU Id :{" "}
                              <span className="text-gray-400 font-roboto text-base font-normal leading-6">
                                {item.skuCode}
                              </span>
                            </div>
                            <div className="text-gray-500 font-roboto text-base font-normal leading-6">
                              Warehouse :{" "}
                              <span className="text-gray-400 font-roboto text-base font-normal leading-6">
                                {item.warehouse}
                              </span>
                            </div>
                            <div className="text-gray-500 font-roboto text-base font-normal leading-6">
                              Base Price : Rs.{" "}
                              <span className="text-gray-400 font-roboto text-base font-normal leading-6">
                                {item.basePrice} /MT
                              </span>
                            </div>
                           
                          </div>
                            {singleQuotationData
                              ?.filter((quotationData) => quotationData.secondarySkuId === leadUpc.secondarySkuId)
                              .map((quotationData, index) => {
                                return (
                                  <div key={index} className="flex justify-between gap-x-2">
                                    <div className="flex w-1/4 ">
                                      <div className="w-full">
                                        <TextField
                                          required
                                          type="number"
                                          name="quantity"
                                          id="outlined-basic"
                                          label="Enter Quantity"
                                          variant="outlined"
                                          fullWidth
                                          value={
                                            upcSkuEditDetails[leadUpc.secondarySkuId]?.quantity === '' ? '' : upcSkuEditDetails[leadUpc.secondarySkuId]?.quantity || quotationData?.skuData[0]?.quantity 
                                          }
                                          onChange={(event) =>
                                            handleUpdateDetails(
                                              leadUpc.secondarySkuId,
                                              "quantity",
                                              event,
                                              item.skuCode,
                                              item.warehouse,
                                              item.basePrice,
                                              item?.netWeight
                                            )
                                          }
                                          InputProps={{
                                            inputProps: {
                                              min: 1,
                                              max: item?.netWeight,
                                              step: 1,
                                              readOnly: mode === "VIEW" ? true : false
                                            },
                                          }}
                                        />
                                      </div>
                                      <div className="rounded py-4 px-5 bg-gray-300">MT</div>
                                    </div>
                                    <div className="w-1/4 ">
                                      <TextField
                                        required
                                        name="price"
                                        id="outlined-basic"
                                        label="Offered Price /MT"
                                        variant="outlined"
                                        fullWidth 
                                        value={
                                          upcSkuEditDetails[leadUpc.secondarySkuId]?.price === '' ? '' : upcSkuEditDetails[leadUpc.secondarySkuId]?.price || quotationData?.skuData[0]?.price
                                        }
                                        onChange={(event) =>
                                          handleUpdateDetails(
                                            leadUpc.secondarySkuId,
                                            "price",
                                            event,
                                            item.skuCode,
                                            item.warehouse,
                                            item.basePrice,
                                            item.netWeight
                                          )
                                        }
                                        inputProps={{ readOnly: mode === "VIEW" ? true : false }}
                                      />
                                    </div>
                                    <div className="flex w-1/4 ">
                              <div className="w-full">
                                <TextField
                                  className="pointer-events-none"
                                  type="number"
                                  name="profit"
                                  id="outlined-basic"
                                  label="Profit % /MT"
                                  variant="outlined"
                                  fullWidth                       
                                  value={
                                    calculateProfitLossPercentage(
                                      item.basePrice,
                                      upcSkuEditDetails[leadUpc.secondarySkuId]?.price ||  quotationData?.skuData[0].price
                                    ).toFixed(2) || ""
                                  }
                                  onChange={(event) =>
                                    handleUpdateDetails(
                                      leadUpc.secondarySkuId,
                                      "profit",
                                      event,
                                      item.skuCode,
                                      item.warehouse,
                                      item.basePrice,
                                      item.netWeight
                                    )
                                  }
                                  InputProps={{
                                    inputProps: { readonly: true }
                                  }}
                                />
                              </div>
                              <div className="rounded py-4 px-5 bg-gray-300">
                                %
                              </div>
                            </div>
                            <div className="w-1/4 ">
                              <FormControl fullWidth>
                                <InputLabel id="label">
                                  Select Packaging*
                                </InputLabel>
                                <Select
                                  required
                                  name="packaging"
                                  id="select"
                                  label="Select Packaging*"
                                  value={
                                    upcSkuEditDetails[leadUpc.secondarySkuId]?.packaging === '' ? '' : upcSkuEditDetails[leadUpc.secondarySkuId]?.packaging || quotationData?.skuData[0]?.packaging
                                  }
                                  onChange={(event) =>
                                    handleUpdateDetails(
                                      leadUpc.secondarySkuId,
                                      "packaging",
                                      event,
                                      item.skuCode,
                                      item.warehouseName,
                                      item.basePrice,
                                      item.netWeight
                                    )
                                  }
                                  inputProps={{ readOnly: mode === "VIEW" ? true : false }}
                                >
                                 {leadUpc.cataloguePackagingType &&
                                    JSON.parse(
                                      leadUpc.cataloguePackagingType
                                    ).map((packagingType, i) => (
                                      <MenuItem key={i} value={capitalizeFirstLetter(packagingType)}>
                                        {capitalizeFirstLetter(packagingType)}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </div>
                                  </div>
                                );
                              })}
                          
                          <div className="text-gray-500 font-roboto text-xs font-normal flex justify-between ">
                            <div>
                              {" "}
                              Available quantity : {item.netWeight} MT
                            </div>
                          </div>
                        </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          );
        })}

      <div className="product-detail-container grid gap-3  p-3  text-[#2A2A2A] ">
        <div className="font-medium text-xl ">
          <div className="pb-3">Additional Charges</div>
          <div className="border"></div>
        </div>

        <div className="flex gap-2">
          <TextField
            required
            type="number"
            name="warehouseCharges"
            id="outlined-basic"
            label="Warehouse Charges /MT"
            variant="outlined"
            value={charges[0].warehouseCharges}
            onChange={handleInputChange}
            fullWidth
            inputProps={{ readOnly: mode === "VIEW" ? true : false }}
          />
          <TextField
            required
            type="number"
            name="handlingCharges"
            id="outlined-basic"
            label="Handling Charges /MT"
            variant="outlined"
            value={charges[0].handlingCharges}
            onChange={handleInputChange}
            fullWidth
            inputProps={{ readOnly: mode === "VIEW" ? true : false }}
          />
          <TextField
            required
            type="number"
            name="packagingCharges"
            id="outlined-basic"
            label="Packaging Charges /MT"
            variant="outlined"
            value={charges[0].packagingCharges}
            onChange={handleInputChange}
            fullWidth
            inputProps={{ readOnly: mode === "VIEW" ? true : false }}
          />
        </div>
        <div className="flex gap-2">
          <TextField
            required
            type="number"
            name="otherCharges"
            id="outlined-basic"
            label="Other Charges"
            variant="outlined"
            value={charges[0].otherCharges}
            onChange={handleInputChange}
            fullWidth
            inputProps={{ readOnly: mode === "VIEW" ? true : false }}
          />
          <TextField
            required
            type="number"
            name="gst"
            id="outlined-basic"
            label="GST %"
            variant="outlined"
            value={charges[0].gst}
            onChange={handleInputChange}
            fullWidth
            inputProps={{ readOnly: mode === "VIEW" ? true : false }}
          />
          <TextField
            required
            type="number"
            name="tcs"
            id="outlined-basic"
            label="TCS %"
            variant="outlined"
            value={charges[0].tcs}
            onChange={handleInputChange}
            fullWidth
            inputProps={{ readOnly: mode === "VIEW" ? true : false }}
          />
        </div>
      </div>

      <div className="product-detail-container grid gap-6  p-3  text-[#2A2A2A] ">
        <div className=" grid gap-2">
          <div className="">
            <div className="font-medium text-xl pb-3 flex justify-start w-full ">
              <p>Payment Information</p>
            </div>
            <div className="border"></div>
          </div>
          <div className="grid gap-3 text-[#2A2A2A]  pb-2">
            <div className="flex justify-between text-base font-medium">
              <p>Sub Total</p>
              <p>&#8377; {new Intl.NumberFormat().format(pricingData?.productsSubTotal || 0)}</p>
            </div>
            <div className="flex justify-between text-base font-normal ">
              <p>
                Warehousing charges{" "}
                <span className="text-sm">({leadQuantity.toFixed(3)} MT)</span>
              </p>
              <p>&#8377; {new Intl.NumberFormat().format(pricingData?.warehousePrice || 0)}</p>
            </div>
            <div className="flex justify-between text-base font-normal ">
              <p>
                Handling charges{" "}
                <span className="text-sm">({leadQuantity.toFixed(3)} MT)</span>
              </p>
              <p>&#8377; {new Intl.NumberFormat().format(pricingData?.handlingPrice || 0)}</p>
            </div>
            <div className="flex justify-between text-base font-normal ">
              <p>
                Packaging charges{" "}
                <span className="text-sm">({leadQuantity.toFixed(3)} MT)</span>
              </p>
              <p>&#8377; {new Intl.NumberFormat().format(pricingData?.packagingPrice || 0)}</p>
            </div>
            <div className="flex justify-between text-base font-normal ">
              <p>Other charges</p>
              <p>&#8377; {new Intl.NumberFormat().format(pricingData?.otherPrice || 0)}</p>
            </div>
            <div className="flex justify-between text-base font-semibold  ">
              <p>Sub Total (Incl Charges)</p>
              <p>&#8377; {new Intl.NumberFormat().format(pricingData?.subTotal || 0)}</p>
            </div>
            <div className="border"></div>
            <div className="flex justify-between text-base font-normal ">
              <p>GST</p>
              <p>&#8377; {new Intl.NumberFormat().format(pricingData?.totalGst) || 0}</p>
            </div>
            <div className="flex justify-between text-base font-normal ">
              <p>TCS</p>
              <p>&#8377; {new Intl.NumberFormat().format(pricingData?.totalTcs) || 0}</p>
            </div>
            <div className="border"></div>
          </div>
          <div className="">
            <div className="flex justify-between text-[#2A2A2A] text-xl font-medium">
              <p>Total amount</p>
              <p>
                &#8377;{" "}
                {new Intl.NumberFormat().format(pricingData?.grandTotal || 0)}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="m-auto mr-0 flex gap-x-3">
        <CustomButton onClick={handleRedirectBack}>Cancel</CustomButton>
       {mode !== "VIEW" && 
       <>
        <CustomButton
          onClick={() => {
            handleSave();
            handleRedirectBack();
          }}
        >
          Save
        </CustomButton>
        <CustomButton
          variant="Primary"
          onClick={() => {
            handlePublish();
          }}
        >
          Update
        </CustomButton>
       </>}
      </div>
    </div>
  );
};

export default UpdateSecondaryQuotation;
