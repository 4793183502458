import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { HTTP_STATUS } from '../../../utils/types';
import { ADMIN_ROUTES, MODE } from '../../../utils/constant'
import { IProductUpcCreateRequest, useUpcService } from '../../../services/useUpcService';
import MpcFormTemplate from '../../templates/MPC/MpcForm.template';

const MpcCreatePage: React.FC = () => {

    const navigate = useNavigate();
    const upcService = useUpcService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const createMpc = async (mpcRequestBody: IProductUpcCreateRequest) => {
        try {
            const mpcResponse = await upcService.createProductUPC(mpcRequestBody);
            if (mpcResponse.status === HTTP_STATUS.OK) {
                navigate(ADMIN_ROUTES.MPC_LIST);
            } else if (mpcResponse.status === HTTP_STATUS.INTERNAL_SERVER_ERROR) {
                showSnackbar("error", `MPC already exists or something went wrong. Please try again`);
            }
        } catch (error) {
            showSnackbar("error", `MPC creation failed ${error}`)
        }
    }

    return (
        <div>
            {SnackBarComponent}
            <MpcFormTemplate mpc={null} onMpcSubmit={createMpc} mode={MODE.ADD} />
        </div>
    )
}

export default MpcCreatePage;
