import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IManufacturer } from './ManufacturerUpdate.page';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { useManufacturerService } from '../../../../services/useManufacturerService';
import ManufacturerFormTemplate from '../../../templates/AdminMaster/Manufacturer/ManufacturerForm.template';
import { ADMIN_ROUTES, MODE, RESOURCE_TYPE } from '../../../../utils/constant';
import { ImageData } from '../../../organisms/ImageUploader';
import { useFileService } from '../../../../services/useFileService';
import { HTTP_STATUS } from '../../../../utils/types';

const ManufacturerViewPage: React.FC = () => {

    const navigate = useNavigate();

    const params = useParams();
    const manufactureId = Number(params.id);

    const [manufaturerDetails, setManufacturerDetailsTo] = useState<IManufacturer | null>(null);
    const [productCategories, setProductCategroiesTo] = useState<number[]>([]);
    const [image, setImagesTo] = useState<(ImageData | File)[]>([]);
    const [imageIdsToDelete, setImageIdsToDelete] = useState<number[]>([]);


    const fileService = useFileService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const manufacturerService = useManufacturerService();

    const redirectToManufacturerList = () => {
        navigate(ADMIN_ROUTES.MANUFACTURER_LIST);
    }

    const fetchManufactureById = async () => {
        if (manufactureId) {
            try {
                const manufactureResponse = await manufacturerService.getManufacturerById(manufactureId);
                if(manufactureResponse.status === HTTP_STATUS.OK) {
                    setManufacturerDetailsTo(manufactureResponse?.data?.data);
                    setProductCategroiesTo(manufactureResponse?.data?.data?.categories) ;
                }
                const fileResponse = await fileService.getFilesById(manufactureId, RESOURCE_TYPE.MANUFACTURER);
                (fileResponse.status === HTTP_STATUS.OK) && setImagesTo(fileResponse?.data?.data);
            } catch (error) {
                showSnackbar("error", 'Standard fetch failed');
                setManufacturerDetailsTo(null);
                setProductCategroiesTo([])
                setImagesTo([]);
            }
        }
    }

    useEffect(() => {
        fetchManufactureById();
    }, [manufactureId]);
    
    return (
        <div>
            { SnackBarComponent }
            <ManufacturerFormTemplate manufacturer={ manufaturerDetails } categoryIds={ productCategories } onManufacturerSubmit={ redirectToManufacturerList } mode={ MODE.VIEW } image={image} setImagesTo={setImagesTo} imageIdsToDelete = {imageIdsToDelete} setImageIdsToDelete = {setImageIdsToDelete} />
        </div>
    )
}

export default ManufacturerViewPage;
