import React from 'react';
import { CATALOGUE_TYPES, CLASS_TYPE, ADMIN_ROUTES } from "./../../../utils/constant";
import { getShapeName, makeRoute, showAttributesUom } from "../../../utils/helper";
import { useLocation, useNavigate } from "react-router-dom";
import StatusToggle from '../AdminMaster/UPC/StatusToggle/StatusToggle';
import { formatJoeditData } from '../../Core/Handlers/JoeditDisplayFormatter';
import { CustomButton } from '../../Shared/Button/CustomButton';
import { DisplayDocuments } from '../../Shared/Files/FileItem';
import { FilesWrapper } from '../../Shared/Files/DisplayFiles';
import CatalogueUserStatusTable from '../Catalogue/WhatsAppIntegration/Users/CatalogueUserStatusTable';
const BaseUrl = process.env.REACT_APP_API_V1_URL;

const SecondaryCataloguePreview = ({
    status,
    setStatus,
    catalogueName,
    minimumQuantity,
    availableQuantity,
    application,
    additionalProductDetails,
    keywords,
    defectDetails,
    price,
    packagingType,
    termsConditions,
    secondaryProductId,
    catalogueMode,
    imageData,
    formErrors,
    mode,
    formImageData,
    formDatas,
    gradeName,
    warehouseDetails,
    productCategoryName,
    mainCategoryName,
    superCategoryName,
    catalogueType,
    category,
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const getbyId = location.state?.catalogueId;
    const DisplayProductSpecificationDetails = ({ productSpecificationDetails }) => {
        try {
            if (productSpecificationDetails) {
                return productSpecificationDetails.map((specificationItem, index) => (
                    <div key={index} className="grid gap-y-1.5">
                        <div className="m-auto">{specificationItem.name}</div>
                              {showAttributesUom(specificationItem.minValue, specificationItem.maxValue , specificationItem.uom  )}
                    </div>
                ));
            } else {
                return <div>No Generalization data available.</div>;
            }
        } catch (error) {
            console.error('Error parsing attributes:', error);
            return <div>No Generalization Details</div>;
        }
    };
    const DisplayProductGeneralizationDetails = ({ productSpecificationDetails }) => {
        try {
            if (productSpecificationDetails) {
                return productSpecificationDetails.map((specificationItem, index) => (

                    <div key={index} className="grid gap-y-1.5">

                        <div className="m-auto">{specificationItem.name}</div>
                        <div className="mx-4 px-1.5 rounded-full border border-input-border">
                            {specificationItem.value}
                        </div>
                    </div>
                ));
            } else {
                return <div>No Generalization data available.</div>;
            }
        } catch (error) {
            console.error('Error parsing attributes:', error);
            return <div>No Generalization Details</div>;
        }
    };
    return (
        <>
            <div className="grid gap-y-4 mt-3">
                {mode === 'View' && (formDatas && formDatas.length > 0) &&

                    formDatas.map((formData) => <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
                        <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
                            {formData?.skuCode}
                        </div>
                        <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
                            Category
                        </div>
                        <div className="flex gap-x-6">
                            <div className="grid gap-y-1.5">
                                <div className="text-text-color font-roboto text-base font-normal">
                                    Super Category
                                </div>
                                <div className="text-text-color font-roboto text-base font-medium">
                                    {superCategoryName}
                                </div>

                            </div>
                            <div className="grid gap-y-1.5">
                                <div className="text-text-color font-roboto text-base font-normal">
                                    Main Category
                                </div>
                                <div className="text-text-color font-roboto text-base font-medium">
                                    {mainCategoryName}
                                </div>
                            </div>
                            <div className="grid gap-y-1.5">
                                <div className="text-text-color font-roboto text-base font-normal">
                                    Product Category
                                </div>
                                <div className="text-text-color font-roboto text-base font-medium">
                                    {productCategoryName}
                                </div>
                            </div>
                        </div>

                        <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
                            Product Standard
                        </div>
                        <div className="flex gap-x-6">
                            <div className="grid gap-y-1.5">
                                <div className="text-text-color font-roboto text-base font-normal">
                                    Grade
                                </div>
                                <div className="text-text-color font-roboto text-base font-medium">
                                    {gradeName}
                                </div>

                            </div>
                            <div className="grid gap-y-1.5">
                                <div className="text-text-color font-roboto text-base font-normal">
                                    Shape
                                </div>
                                <div className="text-text-color font-roboto text-base font-medium">
                                    {formData && getShapeName(formData.shape)}
                                </div>
                            </div>

                        </div>

                        {formData?.attributes?.productSpecification.length > 0 && <>
                            <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
                                Product Specification
                            </div>
                            <div className="flex gap-x-6">
                                <DisplayProductSpecificationDetails productSpecificationDetails={formData?.attributes?.productSpecification} />
                            </div></>}

                        {formData?.attributes?.productClassification?.length > 0 && <><div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
                            Product Classification
                        </div>
                            <div className="flex gap-x-6">
                                <DisplayProductGeneralizationDetails productSpecificationDetails={formData?.attributes?.productClassification} />
                            </div></>}

                        {formData?.attributes?.productGeneralization?.length > 0 && <><div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
                            Product Generalization
                        </div>
                            <div className="flex gap-x-6">
                                <DisplayProductGeneralizationDetails productSpecificationDetails={formData?.attributes?.productGeneralization} />
                            </div></>}

                        <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
                            Defect Details
                        </div>
                        <div className="flex gap-x-6">
                            <div className="grid gap-y-1.5">
                                <div className="text-text-color font-roboto text-base font-normal">
                                    {formData?.defectDetails ? formatJoeditData(formData?.defectDetails) : 'No Data Found'}
                                </div>
                            </div>
                        </div>

                        <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
                            Warehouse Details
                        </div>
                        <div className="flex gap-x-6">
                            <div className="grid gap-y-1.5">
                                <div className="text-text-color font-roboto text-base font-normal">
                                    Warehouse
                                </div>
                                <div className="text-text-color font-roboto text-base font-medium">
                                    {warehouseDetails}
                                </div>

                            </div>
                            <div className="grid gap-y-1.5">
                                <div className="text-text-color font-roboto text-base font-normal">
                                    Warehouse Placement
                                </div>
                                <div className="text-text-color font-roboto text-base font-medium">
                                    {formData?.warehousePlacement}
                                </div>
                            </div>

                        </div>

                        <div className="flex gap-x-6">
                            <div className="grid gap-y-1.5">
                                <div className="text-text-color font-roboto text-base font-normal">
                                    Net Weight
                                </div>
                                <div className="text-text-color font-roboto text-base font-medium">
                                    {formData?.netWeight} {formData?.uom}
                                </div>

                            </div>
                            <div className="grid gap-y-1.5">
                                <div className="text-text-color font-roboto text-base font-normal">
                                    Gross Weight
                                </div>
                                <div className="text-text-color font-roboto text-base font-medium">
                                    {formData?.grossWeight} {formData?.uom}
                                </div>
                            </div>

                        </div>
                        <div className="flex gap-x-6">
                            {/* <div className="grid gap-y-1.5">
                                <div className="text-text-color font-roboto text-base font-normal">
                                    Quantity
                                </div>
                                <div className="text-text-color font-roboto text-base font-medium">
                                    {formData?.quantity}
                                </div>

                            </div> */}
                            <div className="grid gap-y-1.5">
                                <div className="text-text-color font-roboto text-base font-normal">
                                    Total Price
                                </div>
                                <div className="text-text-color font-roboto text-base font-medium">
                                    {new Intl.NumberFormat().format(
                                        formData?.basePrice * formData?.netWeight
                                    )}
                                    Rs
                                </div>
                            </div>

                        </div>
                    </div>)}

                <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">

                    <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
                        Product Information
                    </div>
                    <div className="flex gap-x-6">
                        <div className="grid gap-y-1.5">
                            <div className="text-text-color font-roboto text-base font-normal">
                                Cataloge Name
                            </div>
                            <div className="text-text-color font-roboto text-base font-medium">
                                {catalogueName}
                            </div>

                        </div>
                        <div className="grid gap-y-1.5">
                            <div className="text-text-color font-roboto text-base font-normal">
                                Minimum order Quantity
                            </div>
                            <div className="text-text-color font-roboto text-base font-medium">
                                {minimumQuantity}
                            </div>
                        </div>
                        {/* <div className="grid gap-y-1.5">
                            <div className="text-text-color font-roboto text-base font-normal">
                                Maximum Available Quantity
                            </div>
                            <div className="text-text-color font-roboto text-base font-medium">
                                {availableQuantity}
                            </div>
                        </div> */}
                    </div>

                </div>

                <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">

                    <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
                        Applications
                    </div>
                    <div className="grid gap-y-1.5">
                        <div className="text-text-color font-roboto text-base font-normal">
                            {application}
                        </div>
                    </div>

                </div>
                <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
                    <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
                        Defects
                    </div>
                    <div className="grid gap-y-1.5">
                        <div className="text-text-color font-roboto text-base font-normal">
                            {formatJoeditData(defectDetails)}
                        </div>
                    </div>

                </div>

                <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
                    <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
                        Additional Product
                    </div>
                    <div className="grid gap-y-1.5">
                        <div className="text-text-color font-roboto text-base font-normal">
                            {formatJoeditData(additionalProductDetails)}
                        </div>
                    </div>

                </div>

                <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
                    <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
                        KeyWords
                    </div>
                    <div className="grid gap-y-1.5">
                        <div className="text-text-color font-roboto text-base font-normal">
                            {keywords}
                        </div>
                    </div>

                </div>


                <div className="grid gap-y-4 p-6 bg-gray-300 bg-opacity-30 rounded">
                    <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
                        Pricing
                    </div>
                    <div className="grid gap-y-1.5">
                        {`Default price: ${price}`}
                    </div>
                </div>



                <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
                    <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
                        Packaging
                    </div>
                    <div className="grid gap-y-1.5">
                        <div className="text-text-color font-roboto text-base font-normal">
                            Packaging Type
                        </div>
                        <div className="text-text-color font-roboto text-base font-medium">
                            {mode === "View" ? packagingType?.map((type, index) => (
                                <span key={index}>
                                    {index > 0 && ', '}
                                    {type}
                                </span>
                            )) : packagingType?.map((type, index) => (
                                <span key={index}>
                                    {index > 0 && ', '}
                                    {type}
                                </span>
                            ))
                            }
                        </div>
                    </div>

                </div>

                <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
                    <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
                        Terms and Condition
                    </div>
                    <div className="grid gap-y-1.5">
                        <div className="text-text-color font-roboto text-base font-normal">
                            {formatJoeditData(termsConditions)}
                        </div>

                    </div>

                </div>

                <div className="mt-3 mb-3">
                    <StatusToggle
                        formData={{ status: status }}
                        handleUpdateFormDetails={(type, value) => setStatus(value ? 'ACTIVE' : 'INACTIVE')}
                        disabled={catalogueMode === 'VIEW'}
                        error={formErrors?.status}
                    />
                </div>

            </div >
            <div className="grid product-description m-auto gap-y-2">
                {
                    (mode === 'View' || mode === 'EDIT') ? <FilesWrapper heading={"Product Images"}>
                        <DisplayDocuments
                            documentsData={imageData}
                            isEdit={false}
                            isForm={false}
                        // deleteDocument={deleteDocument}
                        />
                        <DisplayDocuments
                            documentsData={formImageData}
                            isEdit={false}
                            isForm={true}
                        />
                    </FilesWrapper> :

                        <FilesWrapper heading={"Product Images"}>
                            <DisplayDocuments
                                documentsData={imageData}
                                isEdit={false}
                                isForm={true}
                            // deleteDocument={deleteDocument}
                            />
                        </FilesWrapper>
                }
                {(
                    mode !== 'add' &&
                    <>
                        <div className="flex-1 relative font-semibold mt-5">Catalogue Whatsapp Status</div>
                        <CatalogueUserStatusTable
                            catalogueId={getbyId}
                        />
                    </>)}

            </div>
            {
                mode === 'View' &&
                <div className=" w-full flex justify-end m-auto">
                    <CustomButton
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        Cancel
                    </CustomButton>
                </div>
            }

        </>
    )
}

export default SecondaryCataloguePreview