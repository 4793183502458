import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomHeading from "../../../Shared/CustomHeading/CustomHeading";
import { CustomButton } from "../../../Shared/Button/CustomButton";
import TermsModal from "../../../Shared/TermsAndCondition/TermsModal";
import ImageButton from "../../../Shared/ImageButton/ImageButton";
import viewBlackSvg from "../../../Assets/Images/viewBlack.svg";
import closeBlackSvg from "../../../Assets/Images/closeBlack.svg";
import productPng from "../../../Assets/Images/product.png";
import upSvg from "../../../Assets/Images/up.svg";
import downSvg from "../../../Assets/Images/down.svg";
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import QuotationDetailsModal from "./QuotationDetailsModal";
import { capitalizeFirstLetter } from "./CreateQuotation";
import { useAuthenticatedUser } from "../../../../hooks/useAuthenticatedUser";

const UpdateQuotation = () => {
  const { user } = useAuthenticatedUser();
  const params = useParams();
  const mode = "EDIT";

  const [ leadId, setLeadId ] = useState();
  const [ upcData, setUpcData ] = useState({});

  const [ singleQuotationData, setSingleQuotationData ] = useState([]);
  const [ charges, setCharges ] = useState([]);
  const [ termsAndCondition, setTermsAndCondition ] = useState("");
  const [leadQuantity, setLeadQuantity] = useState(0);


  useEffect(() => {
    if (singleQuotationData.length === 0) return;
    let skuData = {};
    singleQuotationData.forEach((quotation) => {
      quotation.skuData.forEach(sku => {
        skuData[ quotation.upcId ] = {
          ...(skuData[ quotation.upcId ] || {}) , [ sku.skuId ]: sku
        }
      });
    })
    setUpcSkuEditDetails(skuData)
  }, [ singleQuotationData ])


  const fetchQuotation = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_V1_URL}/quotation/${Number(params.id)}`,
        {
          headers: {
            Authorization: `${user.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setLeadId(response.data.data.leadId);
      setTermsAndCondition(response.data.data.termsAndCondition);
      setCharges(response.data.data.charges[0]);
      setSingleQuotationData(response.data.data.quotationUpcResponseDTO);
    } catch (error) {
      console.error("Error fetching lead details:", error);
    }
  };

  // console.log("termsAndCondition-1",termsAndCondition)

  //--------------------Fetch LeadUpc data--------------------

  const [leadUpcData, setLeadUpcData] = useState([]);
  const [comapanyName, setCompanyName] = useState("");
  const [actorId, setActorId] = useState(0);
  const [quantity, setQuantity] = useState(0);

  const [upcIds, setUpcIds] = useState(null);
  const fetchLeadUpc = async (leadId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_V1_URL}/lead/${leadId}`,
        {
          headers: {
            Authorization: `${user.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      var upcData = response.data.data.leadUpcResponseDTO;
      // console.log("--------------------------",response.data.data);
      setLeadUpcData(upcData);
      setCompanyName(response.data.data.companyName);
      setActorId(response.data.data.actorId);
      setQuantity(
        response.data.data.leadUpcResponseDTO.reduce(
          (total, item) => total + item.quantity,
          0
        )
      );

      var upcSkuDetails = {};
      for (let index = 0; index < upcData.length; index++) {
        const upcItem = upcData[index];

        var skuDetails = await fetchUpc(upcItem.upcId);
        upcSkuDetails = { ...upcSkuDetails, [upcItem.upcId]: skuDetails };
      }
      setUpcData(upcSkuDetails);
      setUpcIds(
        response.data.data.leadUpcResponseDTO.map((item) => item.upcId)
      );
    } catch (error) {
      console.error("Error fetching lead details:", error);
    }
  };

  //-----------Terms and Condition Modal------------------

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //---------------------Fetch UPC Data-------------------------------------

  const fetchUpc = async (upcId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_V1_URL}/inventory/upcs?upcIds=${upcId}`,
        {
          headers: {
            Authorization: `${user.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // console.log(response.data);
      return response.data.data[upcId];
    } catch (error) {
      console.error("Error fetching lead details:", error);
      return {};
    }
  };

  const [selectedUpcId, setSelectedUpcId] = useState([]);
  const [isTableOpen, setIsTableOpen] = useState({});

  //---------------------------checkbox functionality-------------

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState([]);

  const [ selectedRowsMap, setSelectedRowsMap ] = useState({});

  useEffect(() => {
    const updatedSelectedRows = {};
    singleQuotationData?.forEach(quotation => {
      quotation.skuData.forEach(sku => {
        updatedSelectedRows[ sku.skuId ] = [
          ...upcData[ quotation.upcId ].filter(el => el.id === sku.skuId)
        ];
      })
    })
    setSelectedRowsMap(updatedSelectedRows);
  }, [ upcData ])

  const checkSku = (upcId) => {
    if (selectedRowsMap[ upcId ]) {
      return true;
    } else {
      return false;
    }
  };
  const handleChange1 = (event, skuId, rowId, skuData) => {
    const isChecked = event.target.checked;
    const updatedSelectedRows = { ...selectedRowsMap };
    if (isChecked) {
      updatedSelectedRows[skuId] = [
        ...(updatedSelectedRows[skuId] || []),
        skuData,
      ];
      setSelectedRowsMap(updatedSelectedRows);
    } else {
      handleRemoveRow(skuId, rowId);
    }
  };

  const handleRemoveRow = (rowId, upcId) => {
    let updatedData = {...selectedRowsMap}
    delete updatedData[rowId];
    setSelectedRowsMap(updatedData);
    let updatedSkuEditDetails = {...upcSkuEditDetails}
    delete updatedSkuEditDetails[upcId][rowId];
    setUpcSkuEditDetails(updatedSkuEditDetails)
  };


  const clearSelection = () => {
    setSelectedRows([]);
    setSelectedRowData([]);
  };
  const [deleteUpcId, setDeleteUpcId] = useState();

  const handleDeleteLeadUpc = async (leadUpcId) => {
    setDeleteUpcId(leadUpcId);
    const confirmed = window.confirm(
      "Are you sure you want to remove this Product ?"
    );

    if (confirmed) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_V1_URL}/lead-upc/${leadUpcId}`,
          {
            headers: {
              Authorization: `${user.token}`,
              "Content-Type": "application/json",
            },
          }
        );
        window.alert("Product removed successfully!");
        // setDeleteUpcId(leadUpcId);
      } catch (error) {
        console.error("Error deleting leadUpc:", error);
      }
    }
  };

  const [productUpcData, setProductUpcData] = useState([]);
  const fetchSingleProductUpc = async (upcId) => {
    try {
      const response = await axios.post(
        // `${process.env.REACT_APP_API_V1_URL}/product-upc/${upcId}`,
        `${process.env.REACT_APP_API_V1_URL}/search/upc`,
        {
          upcId: upcId,
        },
        {
          headers: {
            Authorization: `${user.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const newData = response.data.data;
      return newData;
    } catch (error) {
      console.error(`Error fetching lead details for upcId ${upcId}:`, error);
    }
  };

  const fetchAllProductUpcs = async (upcIds) => {
    const upcFetchDetails = [];
    if (
      upcIds &&
      Array.isArray(upcIds) &&
      upcIds.length > 0 &&
      productUpcData.length === 0
    ) {
      for (const x of upcIds) {
        let upcDetails = await fetchSingleProductUpc(x);
        upcFetchDetails.push(upcDetails);
      }
      setProductUpcData(upcFetchDetails);
    }
  };

  useEffect(() => {
    if (upcIds && Array.isArray(upcIds) && upcIds.length > 0) {
      fetchAllProductUpcs(upcIds);
    }
  }, [upcIds]);

  const transform = (data) => {
    try {
      if (data) {
        var specificationData = JSON.parse(data);
        specificationData = specificationData?.SPECIFICATION || [];
        return specificationData;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return []; // Return a default value in case of error
    }
  };
  const specificationThicknessMassager = (data) =>{
    let arr = data.filter(el => el.name !== 'Thickness')
    let thickness = data.filter(el => el.name === 'Thickness')
    arr.unshift(thickness[0])
    return arr;
  }


  useEffect(() => {
    fetchUpc();
    fetchLeadUpc(leadId);
    fetchQuotation();
  }, [ params.id, leadId ]);

  const navigate = useNavigate();

  const handleRedirectBack = () => {
    navigate((-1));
  };

  const DisplayUpcSkuDetails = ({ upcId }) => {
    const upcSkuItemDetails = upcData[ upcId ];
    if (!upcSkuItemDetails) {
      return <></>;
    }

    return (
      <div>
        {upcSkuItemDetails.map((upcItem, index) => (
          <div
            className="lead-management-tablebody flex justify-between items-start gap-x-2"
            key={upcItem.id}
          >
            <div className="lead-management-tablebody-heading">
              <Checkbox
                checked={checkSku(upcItem.id)}
                onChange={(event) => {
                  handleChange1(event, upcItem.id, upcId, upcItem);
                }}
                color="primary"
              />
            </div>
            <div className="lead-management-tablebody-heading w-40 pt-3">
              {upcItem?.skuCode}
            </div>
            <div className="lead-management-tablebody-heading w-48 pt-3">
              {upcItem?.warehouseName}
            </div>
            <div className="lead-management-tablebody-heading w-20 pt-3">
              {upcItem?.basePrice}
            </div>
            <div className="lead-management-tablebody-heading w-32 pt-3 mr-3 text-right">
              {upcItem?.netWeight}  {upcItem?.uom}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const addToSelectedIds = (upcId) => {
    setSelectedUpcId((prevIds) => [...prevIds, upcId]);
  };

  const removeToSelectedIds = (upcId) => {
    setSelectedUpcId((prevIds) => prevIds.filter((id) => id !== upcId));
  };

  const isUpcIdSelected = (upcId) => {
    return selectedUpcId.includes(upcId);
  };

  const isUpcIdTableSelected = (upcId) => {
    return isTableOpen[upcId] === true;
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = (id) => {
    setIsModalOpen(id);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const [upcSkuEditDetails, setUpcSkuEditDetails] = useState({});
  const [content, setContent] = useState("");

  const [updatedCharges, setUpdatedCharges] = useState([

    {
      warehouseCharges: charges?.warehouseCharges ?? 0,
      handlingCharges: charges?.handlingCharges ?? 0,
      packagingCharges: charges?.packagingCharges ?? 0,
      otherCharges: charges?.otherCharges ?? 0,
      gst: charges?.gst ?? 0,
      tcs: charges?.tcs ?? 0,
    },
  ]);

  useEffect(() => {
    setUpdatedCharges([
      {
        warehouseCharges: charges?.warehouseCharges ?? 0,
        handlingCharges: charges?.handlingCharges ?? 0,
        packagingCharges: charges?.packagingCharges ?? 0,
        otherCharges: charges?.otherCharges ?? 0,
        gst: charges?.gst ?? 0,
        tcs: charges?.tcs ?? 0,
      },
    ]);
  }, [charges]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (value >= 0) {
      setUpdatedCharges([{ ...updatedCharges[0], [name]: +value }]);
    }
  };

  const handleUpdateDetails = (
    upcId,
    skuId,
    key,
    payload,
    skuCode,
    warehouseName,
    basePrice,
    availableQuantity
  ) => {
    if(payload.target.name !== "packaging" && !/^[0-9.]*$/.test(payload.target.value) )
    return
    
    if (payload.target.name === "quantity") {
      if(payload.target.value > availableQuantity){
        alert(`Quantity entered is more than available quantity - ${availableQuantity} MT`)
        payload.target.value = availableQuantity
      }
      if (payload.target.value >= 0) {
        setUpcSkuEditDetails((prevDetails) => {
          const updatedDetails = {
            ...prevDetails,
            [upcId]: {
              ...(prevDetails[upcId] || {}),
              [skuId]: {
                ...(prevDetails[upcId]?.[skuId] || {}),
                [key]: payload.target.value,
                skuCode: skuCode,
                warehouse: warehouseName,
                basePrice: basePrice,
              },
            },
          };
          return updatedDetails;
        });
      }
    } else {
      setUpcSkuEditDetails((prevDetails) => {
        const updatedDetails = {
          ...prevDetails,
          [upcId]: {
            ...(prevDetails[upcId] || {}),
            [skuId]: {
              ...(prevDetails[upcId]?.[skuId] || {}),
              [key]: payload.target.value,
              skuCode: skuCode,
              warehouse: warehouseName,
              basePrice: basePrice,
            },
          },
        };
        return updatedDetails;
      });
    }
  };

  const formatDataForPost = () => {
    const quotationUpcUpdateRequestBodyDTO = [];

    Object.keys(upcSkuEditDetails).forEach((upcId) => {
      const skuData = upcSkuEditDetails[upcId] || [];
      let totalQuantity = 0;
      Object.keys(skuData).forEach((skuId) => {
        const skuEditDetails = skuData[skuId] || {};
        totalQuantity += parseFloat(skuEditDetails.quantity) || 0;
      });
      const exampleItem = singleQuotationData.find(
        (item) => item.upcId === parseInt(upcId)
      );
      const id = exampleItem ? exampleItem.id : null;
      const upcData = {
        id: id,
        upcId: upcId,
        upcCode:
          leadUpcData.filter((item) => item.upcId == upcId)[0]?.upcCode || "",
        quantity: totalQuantity,
        skuData: Object.keys(skuData).map((skuId) => {
          const skuEditDetails = upcSkuEditDetails[upcId]?.[skuId];
          const selectedSku = {};
          return {
            skuId: skuId,
            skuCode: skuEditDetails?.skuCode,
            quantity: skuEditDetails?.quantity || selectedSku.quantity,
            profit: skuEditDetails?.profit || selectedSku.profit,
            price: skuEditDetails?.price || selectedSku.price,
            packaging: skuEditDetails?.packaging || selectedSku.packaging,
            warehouse: skuEditDetails?.warehouse,
            basePrice: skuEditDetails?.basePrice,
          };
        }),
      };

      quotationUpcUpdateRequestBodyDTO.push(upcData);
    });
    return {
      leadId: leadId,
      actorId: actorId,
      companyName: comapanyName,
      termsAndCondition: content,
      publishStatus: "PUBLISHED",
      status: "ONGOING",
      charges: updatedCharges,
      quotationUpcUpdateRequestBodyDTO: quotationUpcUpdateRequestBodyDTO,
      catalogueType: 'STANDARD'
    };
  };

  const handlePublish = async () => {
    try {
    const formattedData = formatDataForPost();
    const isAnyChargeEmpty = Object.values(updatedCharges[0]).some(charge => charge === "");

    if (isAnyChargeEmpty) {
      alert("Please fill in all the charges before publishing.");
      return;
    }
    if (formattedData.quotationUpcUpdateRequestBodyDTO.length === 0) {
      alert("Cannot publish without any SKU data. Please add SKUs before publishing.");
      return;
    }

    if (productUpcData.length !== formattedData.quotationUpcUpdateRequestBodyDTO.length) {
      alert("Cannot publish with an empty SKU data. Please add all SKUs before publishing.");
      return;
    }

    const isPriceSelected = (skuDataArray) => {
      return skuDataArray.every((upcData) =>
        upcData.skuData.every((sku) => sku.price !== undefined && sku.price !== "")
      );
    };

    if (!isPriceSelected(formattedData.quotationUpcUpdateRequestBodyDTO)) {
      alert("Please enter price for all SKUs before publishing.");
      return;
    }

    const isQuantityNonZero = formattedData.quotationUpcUpdateRequestBodyDTO.every(upcData =>
      upcData.skuData.every(sku => parseInt(sku.quantity) !== 0)
    );

    if (!isQuantityNonZero) {
      alert("Please ensure that quantity for all SKUs is greater than zero before publishing.");
      return;
    }

    const isPackagingSelected = (skuDataArray) => {
      return skuDataArray.every((upcData) =>
        upcData.skuData.every((sku) => sku.packaging !== undefined && sku.packaging !== "")
      );
    };
    if (!isPackagingSelected(formattedData.quotationUpcUpdateRequestBodyDTO)) {
      alert("Please select packaging for all SKUs before publishing.");
      return;
    }

      await axios.put(
        `${process.env.REACT_APP_API_V1_URL}/quotation/${Number(params.id)}`,
        formattedData,
        {
          headers: {
            Authorization: `${user.token}`,
            "Content-Type": "application/json",
          },
        }
      ).then((response) => {
        if (response.status === 200) {
          handleRedirectBack();
          return
        }
        alert("Error in updating quotation - Please try again");
        return
      });
    } catch (error) {
      console.error("Error posting quotation:", error);
    }
  };

  const handleNotPublish = async () => {
    const formattedData = formatDataForPost();
    const isAnyChargeEmpty = Object.values(updatedCharges[0]).some(charge => charge === "");

    if (isAnyChargeEmpty) {
      alert("Please fill in all the charges before publishing.");
      return;
    }
    if (formattedData.quotationUpcUpdateRequestBodyDTO.length === 0) {
      alert("Cannot publish without any SKU data. Please add SKUs before publishing.");
      return;
    }

    if (productUpcData.length !== formattedData.quotationUpcUpdateRequestBodyDTO.length) {
      alert("Cannot publish with an empty SKU data. Please add all SKUs before publishing.");
      return;
    }

    const isPriceSelected = (skuDataArray) => {
      return skuDataArray.every((upcData) =>
        upcData.skuData.every((sku) => sku.price !== undefined && sku.price !== "")
      );
    };

    if (!isPriceSelected(formattedData.quotationUpcUpdateRequestBodyDTO)) {
      alert("Please enter price for all SKUs before publishing.");
      return;
    }

    const isQuantityNonZero = formattedData.quotationUpcUpdateRequestBodyDTO.every(upcData =>
      upcData.skuData.every(sku => parseInt(sku.quantity) !== 0)
    );

    if (!isQuantityNonZero) {
      alert("Please ensure that quantity for all SKUs is greater than zero before publishing.");
      return;
    }

    const isPackagingSelected = (skuDataArray) => {
      return skuDataArray.every((upcData) =>
        upcData.skuData.every((sku) => sku.packaging !== undefined && sku.packaging !== "")
      );
    };
    if (!isPackagingSelected(formattedData.quotationUpcUpdateRequestBodyDTO)) {
      alert("Please select packaging for all SKUs before publishing.");
      return;
    }

    formattedData.publishStatus = "NOTPUBLISHED";
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_V1_URL}/quotation/${Number(params.id)}`,
        formattedData,
        {
          headers: {
            Authorization: `${user.token}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error("Error posting quotation:", error);
    }
  };

  function calculateSubTotal(item) {
    const quantity = parseFloat(item?.quantity || 0);
    const pricePerMT = parseFloat(item?.price || 0);
    return (quantity * pricePerMT);
  }
  const [pricingData, setPricingData] = useState({
    productsSubTotal:0,
    warehousePrice: 0,
    handlingPrice: 0,
    packagingPrice: 0,
    otherPrice: 0,
    subTotal: 0,
    totalGst: 0,
    totalTcs: 0,
  });

  useEffect(() => {
    let productsSubTotal = 0
    let quotationQuantity = 0
    let calculatedPrices = {
      productsSubTotal: 0,
      warehousePrice: 0,
      handlingPrice: 0,
      packagingPrice: 0,
      otherPrice: 0,
      subTotal: 0,
      totalGst: 0,
      totalTcs: 0,
      totalQuantity: 0,
      grandTotal: 0
    }
    for (const key in upcSkuEditDetails) {
      for (const subKey in upcSkuEditDetails[key]) {
        productsSubTotal += calculateSubTotal(upcSkuEditDetails[key][subKey]);
        quotationQuantity += parseFloat(upcSkuEditDetails[key][subKey]?.quantity) || 0
      }
    }
    calculatedPrices.productsSubTotal = productsSubTotal;
    calculatedPrices.totalQuantity = quotationQuantity;
    calculatedPrices.warehousePrice = updatedCharges[0].warehouseCharges * quotationQuantity;
    calculatedPrices.handlingPrice = updatedCharges[0].handlingCharges * quotationQuantity;
    calculatedPrices.packagingPrice = updatedCharges[0].packagingCharges * quotationQuantity;
    calculatedPrices.otherPrice = +updatedCharges[0].otherCharges;

    calculatedPrices.subTotal =
      productsSubTotal +
      calculatedPrices.warehousePrice +
      calculatedPrices.handlingPrice +
      calculatedPrices.packagingPrice +
      calculatedPrices.otherPrice;
    calculatedPrices.totalGst = ((updatedCharges[0]?.gst || 0) * (calculatedPrices.subTotal)) / 100;
    calculatedPrices.totalTcs = ((updatedCharges[0]?.tcs || 0) * (calculatedPrices.subTotal)) / 100;
    calculatedPrices.grandTotal = calculatedPrices.subTotal + calculatedPrices.totalGst + calculatedPrices.totalTcs;
    setLeadQuantity(quotationQuantity);
    setPricingData(calculatedPrices);
  }, [upcSkuEditDetails, updatedCharges])



  function listSkuData() {
    let arr = [];
    for (let leadUPc of leadUpcData) {
      for (let quotation of singleQuotationData) {
        if (leadUPc.upcId === quotation.upcId) {
          arr.push(leadUPc);
        }
      }
    }
    return arr;
  }

  function calculateProfitLossPercentage(basePrice, offerededPrice) {
    return (((offerededPrice - basePrice) / basePrice) * 100) || 0;
  }

  return (
    <div className="grid gap-y-3 mt-4 m-auto">
      <div className="flex justify-between">
        <CustomHeading
          text={`Update Quotation Id - ${Number(params.id)}`}
          status=""
        />
        <div className="m-auto mr-0 flex gap-x-3">
          <CustomButton onClick={handleOpen}>Terms and Condition</CustomButton>
          <TermsModal
            open={open}
            mode={mode}
            termsAndCondition={termsAndCondition}
            setContent={setContent}
            onClose={handleClose}
          />
        </div>
      </div>

      {leadUpcData &&
        listSkuData().map((leadUpc, index) => (
          <div className="" key={leadUpc.id}>
            <div className="flex justify-between bg-gray-300 p-3">
              <div className="m-auto ml-0">
                <div className="text-gray-600 font-roboto text-xl font-semibold">
                 Catalogue Name : {leadUpc?.catalogueName}
                </div>
              </div>
              <div className="flex justify-between gap-x-6">
                <div className="m-auto text-gray-500 font-roboto font-semibold text-base">
                  Require Order Quantity : {leadUpc?.quantity || 0} MT
                </div>
                <div className="m-auto text-gray-500 font-roboto font-semibold text-base">
                  Required Packaging : {capitalizeFirstLetter(leadUpc?.packagingType)}
                </div>
                {upcSkuEditDetails[leadUpc.upcId] ? (
                  <div className="m-auto">
                    <ImageButton
                      name="view-quotation-details"
                      imageSrc={viewBlackSvg}
                      onClick={() => handleOpenModal(leadUpc.upcId)}
                    />
                    {isModalOpen === leadUpc.upcId && isModalOpen && (
                      <QuotationDetailsModal
                        upcSkuEditDetails={upcSkuEditDetails[leadUpc.upcId]}
                        onClose={handleCloseModal}
                        charges={updatedCharges}
                        totalQuantity={leadQuantity}
                      />
                    )}
                  </div>
                ) : null}
                <div className="rounded hidden">
                  <ImageButton
                    className="p-1.5 bg-white rounded"
                    name="delete-lead-upc"
                    imageSrc={closeBlackSvg}
                    onClick={() => handleDeleteLeadUpc(leadUpc.id)}
                  />
                </div>
              </div>
            </div>
            <div className="product-detail-container bg-white p-3 flex justify-between gap-x-3">
              {productUpcData &&
                productUpcData
                .filter((item) => leadUpc.upcId === item[ 0 ]?.id)
                .map((item, index) => {
                  return (
                    <div className=" flex flex-col w-1/5">
                      <img
                        className="w-max rounded"
                        src={item[0]?.catalogueImages[0]?.path}
                        alt="productImage"
                      />
                      <div
                        className="flex rounded bg-gray-300 mt-2 w-full px-1 h-fit"
                        key={index}
                      >
                        <div className={`grid gap-2 grid-cols-3 p-2 w-full`}>
                          {item[0]?.attributes &&
                            specificationThicknessMassager(transform(item[0]?.attributes)).map((property, i) => (
                              property?.name &&  <div className="flex flex-col gap-y-1" key={i}>
                                <div className="text-gray-500 font-roboto text-sm font-normal">
                                  {property?.name}
                                  
                                </div>
                                <div className="text-gray-600 font-roboto text-sm font-medium flex">
                                  {property?.attributeOptions} {property?.uom && `${property?.uom}`}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>

                    </div>
                  );
                })}
              <div className="w-1/5 grow">
                <div className="product-detail-container rounded bg-white">
                  <div className=" flex justify-between gap-x-2 p-3 ">
                    {isUpcIdTableSelected(leadUpc?.upcId) &&
                      isUpcIdSelected(leadUpc?.upcId) ? (


                      <button
                        className="flex justify-between w-full"
                        onClick={() => {
                          setIsTableOpen((prev) => ({
                            ...prev,
                            [leadUpc.upcId]: false,
                          }));
                        }}
                      >
                        <div className="text-gray-400 font-roboto text-base font-normal leading-6">
                          {" "}
                          Close SKU
                        </div>{" "}
                        <img src={upSvg} alt="upSvg" />
                      </button>
                    ) : (
                      <button
                        className=" flex justify-between w-full"
                        onClick={() => {
                          // setIsTableOpen(true);
                          setIsTableOpen((prev) => ({
                            ...prev,
                            [leadUpc.upcId]: true,
                          }));
                          // fetchUpc(leadUpc.upcId);
                          addToSelectedIds(leadUpc.upcId);
                        }}
                      >
                        <div className="text-gray-400 font-roboto text-base font-normal leading-6">
                          {" "}
                          Choose SKU
                        </div>{" "}
                        <img src={downSvg} alt="downSvg" />
                      </button>
                    )}
                  </div>

                 {isUpcIdTableSelected(leadUpc.upcId) &&
                      isUpcIdSelected(leadUpc.upcId) && (
                        <div>
                          <div className="lead-management-tablehead flex justify-between items-start  pt-3 pb-3 border gap-x-2">
                            <div className="lead-management-tablehead-heading  w-7  ml-3"></div>
                            <div className="lead-management-tablehead-heading  w-40">
                              SKU Code.
                            </div>
                            <div className="lead-management-tablehead-heading  w-48">
                              Warehouse
                            </div>
                            <div className="lead-management-tablehead-heading  w-20">
                              Price
                            </div>
                            <div className="lead-management-tablehead-heading   w-32 mr-3 text-right">
                              Available Quantity
                            </div>
                          </div>
                          <DisplayUpcSkuDetails upcId={leadUpc.upcId} />
                        </div>
                      )}
                  </div>
                {
                  Object.keys(selectedRowsMap)?.map((selectedSkuId) => {
                    const selectedSkuDetails = selectedRowsMap[ selectedSkuId ];

                    if (!selectedSkuDetails) {
                      console.error(
                        "Invalid or empty selectedUpcData for selectedUpcId:",
                        selectedSkuId
                      );
                      return null;
                    }
                    if (
                      selectedSkuDetails[ 0 ]?.upcId !== leadUpc.upcId
                    ) {
                      return null;
                    }
                   
                    return (
                      selectedSkuDetails.map(selectedRow => (
                        <div
                          className="product-detail-container grid gap-y-2 p-3 mt-4 rounded bg-white"
                          key={`${selectedSkuId}-${selectedRow.id}`}
                        >
                          <div className="flex justify-between gap-x-8">
                            <div className="text-gray-500 font-roboto text-base font-normal leading-6">
                              SKU Code : {" "}
                              <span className="text-gray-400 font-roboto text-base font-normal leading-6">
                                {selectedRow?.skuCode}
                              </span>
                            </div>
                            <div className="text-gray-500 font-roboto text-base font-normal leading-6">
                              Warehouse :{" "}
                              <span className="text-gray-400 font-roboto text-base font-normal leading-6">
                                {selectedRow?.warehouseName}
                              </span>
                            </div>
                            <div className="text-gray-500 font-roboto text-base font-normal leading-6">
                              Base Price : Rs.{" "}
                              <span className="text-gray-400 font-roboto text-base font-normal leading-6">
                                {selectedRow?.basePrice || 0} /MT
                              </span>
                            </div>
                            <div>
                              <ImageButton
                                className=""
                                name="delete"
                                onClick={() => {
                                  handleRemoveRow(selectedRow.id, leadUpc.upcId);
                                  clearSelection();
                                }}
                                imageSrc={closeBlackSvg}
                              />
                            </div>
                          </div>
                          <div className=" flex justify-between gap-x-2">
                            
                            <div className="flex w-1/4  ">
                              <div className="w-full">
                                <TextField
                                  required
                                  type="number"
                                  name="quantity"
                                  id="outlined-basic"
                                  label=" Enter Quantity"
                                  variant="outlined"
                                  fullWidth
                                  value={
                                    upcSkuEditDetails[leadUpc.upcId]?.[
                                      selectedRow.id
                                    ]?.quantity || ""
                                  }
                                  // onChange={handleQuantityChange}
                                  onChange={(event) =>
                                    handleUpdateDetails(
                                      leadUpc.upcId,
                                      selectedRow.id,
                                      "quantity",
                                      event,
                                      selectedRow.skuCode,
                                      selectedRow.warehouseName,
                                      selectedRow.basePrice,
                                      selectedRow.netWeight
                                    )
                                    }                     
                                    InputProps={{
                                      inputProps: { min: 1, max: selectedRow?.netWeight, step: 1 }
                                    }}
                                />
                              </div>
                              <div className="rounded py-4 px-5 bg-gray-300">
                                MT
                              </div>
                            </div>
                            <div className="w-1/4 ">
                              <TextField
                                required
                                name="price"
                                id="outlined-basic"
                                label="Offered Price /MT"
                                variant="outlined"
                                fullWidth
                                
                                value={
                                  upcSkuEditDetails[leadUpc.upcId]?.[
                                    selectedRow.id
                                  ]?.price
                                }
                                onChange={(event) =>
                                  handleUpdateDetails(
                                    leadUpc.upcId,
                                    selectedRow.id,
                                    "price",
                                    event,
                                    selectedRow.skuCode,
                                    selectedRow.warehouseName,
                                    selectedRow.basePrice
                                  )
                                }
                              />
                            </div>
                            <div className="flex w-1/4 ">
                              <div className="w-full">
                                <TextField
                                  
                                  type="number"
                                  name="profit"
                                  id="outlined-basic"
                                  label="Profit % /MT"
                                  variant="outlined"
                                  fullWidth
                                  className="pointer-events-none"
                                  value={
                                    calculateProfitLossPercentage(selectedRow.basePrice ,upcSkuEditDetails[leadUpc.upcId]?.[
                                      selectedRow.id
                                    ]?.price).toFixed(2)
                                  }
                                  onChange={(event) =>
                                    handleUpdateDetails(
                                      leadUpc.upcId,
                                      selectedRow.id,
                                      "profit",
                                      event,
                                      selectedRow.skuCode,
                                      selectedRow.warehouseName,
                                      selectedRow.basePrice
                                    )
                                  }
                                />
                              </div>
                              <div className="rounded py-4 px-5 bg-gray-300">
                                %
                              </div>
                            </div>
                            <div className="w-1/4 ">
                              <FormControl fullWidth>
                                <InputLabel id="label">
                                  Select Packaging*
                                </InputLabel>
                                <Select
                                  required
                                  name="packaging"
                                  id="select"
                                  label="Select Packaging*"
                                  value={
                                    upcSkuEditDetails[leadUpc.upcId]?.[
                                      selectedRow.id
                                    ]?.packaging || ""
                                  }
                                  onChange={(event) =>
                                    handleUpdateDetails(
                                      leadUpc.upcId,
                                      selectedRow.id,
                                      "packaging",
                                      event,
                                      selectedRow.skuCode,
                                      selectedRow.warehouseName,
                                      selectedRow.basePrice
                                    )
                                  }
                                >
                                  {leadUpc.cataloguePackagingType &&
                                    JSON.parse(
                                      leadUpc.cataloguePackagingType
                                    ).map((packagingType, i) => (
                                      <MenuItem key={i} value={capitalizeFirstLetter(packagingType)}>
                                        {capitalizeFirstLetter(packagingType)}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                          <div className="text-gray-500 font-roboto text-xs font-normal flex justify-between">
                            <div>
                              {" "}
                              Available quantity : {selectedRow?.netWeight} MT
                            </div>
                            <div>
                              {" "}
                              {/* Base Price : Rs.{selectedRow.basePrice} /MT */}
                            </div>
                          </div>
                        </div>
                      ))
                    );
                  })}
              </div>
            </div>
          </div>
        ))}

      <div className="product-detail-container grid gap-3  p-3  text-[#2A2A2A] ">
        <div className="font-medium text-xl ">
          <div className="pb-3">Additional Charges</div>
          <div className="border"></div>
        </div>

        <div className="flex gap-2">
          <TextField
            required
            type="number"
            name="warehouseCharges"
            id="outlined-basic"
            label="Warehouse Charges /MT"
            variant="outlined"
            value={updatedCharges[0]?.warehouseCharges}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            required
            type="number"
            name="handlingCharges"
            id="outlined-basic"
            label="Handling Charges /MT"
            variant="outlined"
            value={updatedCharges[0]?.handlingCharges}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            required
            type="number"
            name="packagingCharges"
            id="outlined-basic"
            label="Packaging Charges /MT"
            variant="outlined"
            value={updatedCharges[0]?.packagingCharges}
            onChange={handleInputChange}
            fullWidth
          />
        </div>
        <div className="flex gap-2">
          <TextField
            required
            type="number"
            name="otherCharges"
            id="outlined-basic"
            label="Other Charges"
            variant="outlined"
            value={updatedCharges[0]?.otherCharges}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            required
            type="number"
            name="gst"
            id="outlined-basic"
            label="GST %"
            variant="outlined"
            value={updatedCharges[0]?.gst}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            required
            type="number"
            name="tcs"
            id="outlined-basic"
            label="TCS %"
            variant="outlined"
            value={updatedCharges[0]?.tcs}
            onChange={handleInputChange}
            fullWidth
          />
        </div>
      </div>

      <div className="product-detail-container grid gap-6  p-3  text-[#2A2A2A] ">
        <div className=" grid gap-2">
          <div className="">
            <div className="font-medium text-xl pb-3 flex justify-start w-full ">
              <p>Payment Information</p>
            </div>
            <div className="border"></div>
          </div>
          <div className="grid gap-3 text-[#2A2A2A]  pb-2">
            <div className="flex justify-between text-base font-medium">
              <p>Sub Total</p>
              <p>
                &#8377;
                {new Intl.NumberFormat().format(pricingData?.productsSubTotal || 0)}
              </p>
            </div>
            <div className="flex justify-between text-base font-normal ">
              <p>
                Warehousing charges{" "}
                <span className="text-sm">({leadQuantity.toFixed(3)} MT)</span>
              </p>
              <p>
                &#8377;
                {new Intl.NumberFormat().format(pricingData?.warehousePrice || 0)}
              </p>
            </div>
            <div className="flex justify-between text-base font-normal ">
              <p>
                Handling charges{" "}
                <span className="text-sm">({leadQuantity.toFixed(3)} MT)</span>
              </p>
              <p>
                &#8377;
                {new Intl.NumberFormat().format(pricingData?.handlingPrice || 0)}
              </p>
            </div>
            <div className="flex justify-between text-base font-normal ">
              <p>
                Packaging charges{" "}
                <span className="text-sm">({leadQuantity.toFixed(3)} MT)</span>
              </p>
              <p>
                &#8377;
                {new Intl.NumberFormat().format(pricingData?.packagingPrice || 0)}
              </p>
            </div>
            <div className="flex justify-between text-base font-normal ">
              <p>Other charges</p>
              <p>
                &#8377;
                {new Intl.NumberFormat().format(pricingData?.otherPrice || 0)}
              </p>
            </div>
            <div className="flex justify-between text-base font-semibold  ">
              <p>Sub Total (Incl Charges)</p>
              <p>
                &#8377;
                {new Intl.NumberFormat().format(pricingData?.subTotal || 0)}
              </p>
            </div>
            <div className="border"></div>
            <div className="flex justify-between text-base font-normal ">
              <p>GST</p>
              <p>
                &#8377;
                {new Intl.NumberFormat().format(pricingData?.totalGst) || 0}
              </p>
            </div>
            <div className="flex justify-between text-base font-normal ">
              <p>TCS</p>
              <p>
                &#8377;
                {new Intl.NumberFormat().format(pricingData?.totalTcs) || 0}
              </p>
            </div>
            <div className="border"></div>
          </div>
          <div className="">
            <div className="flex justify-between text-[#2A2A2A] text-xl font-medium">
              <p>Total amount</p>
              <p>
                &#8377;{" "}
                {new Intl.NumberFormat().format(pricingData?.grandTotal || 0)}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="m-auto mr-0 flex gap-x-3">
        <CustomButton onClick={handleRedirectBack}>Cancel</CustomButton>
        <CustomButton
          onClick={() => {
            handleNotPublish(Number(params.id));
            handleRedirectBack();
          }}
        >
          Save
        </CustomButton>

        <CustomButton
          variant="Primary"
          onClick={() => {
            handlePublish(Number(params.id));
          }}
        >
          Update
        </CustomButton>
      </div>
    </div>
  );
};

export default UpdateQuotation;
