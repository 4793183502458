import React from 'react';
import { createUseStyles } from 'react-jss';
import { FormikProps } from 'formik';
import { LOCATION_STATES } from '../../../utils/types';
import { LocationSectionProps } from './AddBusinessLocation.template';
import ButtonV2 from '../../atoms/ButtonV2';
import TextField from '../../atoms/TextField';
import plusIcon from '../../../assets/icons/plus.svg'
import { useNavigate, useParams } from 'react-router-dom';
import { makeRoute } from '../../../utils/helper';
import { ADMIN_ROUTES, CUSTOMER_MANAGEMENT_TABS } from '../../../utils/constant';
import TextFieldV2 from '../../atoms/TextFieldV2';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.primarylightAccent}`,
    },
    helperText: {
        position: 'absolute',
        bottom: '-20px',
    },
    button: {
        color: theme.palette.border._secondaryDark
    },
}));

const GetNumberOfBusinessLocationTemplate: React.FC<LocationSectionProps> = ({ formik, setCurrentSectionTo, setButtonShow, currentSection }) => {
    const classes = useStyles();
    const params = useParams();
    const id = Number(params.id);
    const navigate = useNavigate();

    const handleBackToDashboard = ()=> {
        navigate(ADMIN_ROUTES.CUSTOMER_MANAGEMENT_LIST);
     };
    const handleNext = (event: React.FormEvent) => {
        event.preventDefault();
        const requiredFields = ["noOfRows"];
        let fieldError = false;
        for (const key of requiredFields) {
            if (key in formik.errors) {
                formik.setFieldTouched(key, true);
                fieldError = true;
            }
        }
        if (fieldError === true) return;
        const numberOfRows = formik.values.noOfRows ?? 0;
        const emptyLocation = { gstin: '', state: '' };
        const newLocationsValues = Array.from({ length: numberOfRows }, () => ({ ...emptyLocation }));
        formik.setFieldValue('newLocations', newLocationsValues)
        setCurrentSectionTo(LOCATION_STATES.ADD_LOCATIONS);
    };
    const handleBack = () => {
        setCurrentSectionTo(LOCATION_STATES.VIEW_LOCATIONS) ;
        formik.setTouched({});
    };
    const handleBackOnpreviousPage = () => {
        setButtonShow && setButtonShow(true);
    };
    return (
        <div className=' grid gap-8'>
        <div className={`${classes.container}  border py-6 px-6 rounded-lg`}>
            <div className='flex gap-6  items-end '>
                <div className='grid  w-1/3'>
                    <TextFieldV2
                        className="bg-white rounded-lg"
                        variant="outlined"
                        label="Number of states you are registered with GST"
                        placeholder="Enter data"
                        
                        fullWidth
                     {...formik.getFieldProps("noOfRows")}
                     type="number"
                     error={
                         formik.touched.noOfRows &&
                         Boolean(formik.errors.noOfRows)
                     }
                     helperText={
                         Boolean(formik.touched?.noOfRows) &&
                         formik.errors?.noOfRows
                     }
                     FormHelperTextProps={{ className: `${classes.helperText}` }}
                    />
                </div>
                <div>
                    <ButtonV2
                        variant="contained"
                        iconButton={<img src={plusIcon} />}
                        onClick={handleNext}
                        size='small'
                    />
                </div>
            </div>
        </div>
        <div className=' flex items-end gap-2 justify-self-end'>
                        <ButtonV2
                            className={` justify-self-end`}
                            variant="secondaryContained"
                            label="Back to Dashboard"
                            onClick={handleBackToDashboard}
                        />
                        <ButtonV2
                             className={`${classes.button}`}
                             variant="tertiaryContained"
                             label="Back"
                             onClick={currentSection === LOCATION_STATES.VIEW_LOCATIONS ? handleBackOnpreviousPage : handleBack}

                         />
                    </div>
        </div>
    )
};
export default GetNumberOfBusinessLocationTemplate;
