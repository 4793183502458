import { useEffect, useState } from 'react';
import moment from 'moment';
import axios, { AxiosResponse } from 'axios';
import CustomTab from '../../../Shared/CustomTabs/CustomTab';
import {
  AuctionParamsType,
  LiveAuctionFields,
  LiveAuctionListType,
  LiveAuctionStatusType,
  LiveAuctionTableHeadings,
  LiveAuctionTabs,
} from '../../../Constants/Procurement/LiveAuction/constants';
import TableFilters from '../../../Components/Procurement/Indent/TableFilters';
import CustomTable from '../../../Shared/CustomTable/Procurement/CustomTable';
import { AUCTION } from '../../../Constants/Procurement/LiveAuction/text';
import { usePRContext } from '../../../../hooks/useProcurementContext';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import NoDataFound from '../../../../components/atoms/NoDataFound/NoDataFound';
import { useReverseAuctionService } from '../../../../services/useReverseAuctionService';

const LiveAuctionList = () => {
  const { token }: any = useAuthenticatedUser().user;
  const { liveAuctionParams, setliveAuctionParams } = usePRContext();
  const [searchText, setSearchText] = useState('');
  const [status, setStatus] = useState<string>(LiveAuctionStatusType[0].value);
  const [loading, setLoading] = useState<boolean>(false);
  const [requitionTab, setRequisitionTab] = useState<number>(0);

  const [liveAuctionList, setLiveAuctionList] =
    useState<LiveAuctionListType[]>();

  const [currentPage, setCurrentPage] = useState<number>(
    liveAuctionParams.pageNumber
  );
  const [pageNumber, setPageNumber] = useState<number>(
    liveAuctionParams.pageNumber
  );

  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);

  const [fromDate, setFromDate] = useState(
    moment.unix(liveAuctionParams.fromDate)
  );
  const [toDate, setToDate] = useState(moment.unix(liveAuctionParams.toDate));

  const { getAuctions } = useReverseAuctionService();

  const fetchAuctionListData = async (params: AuctionParamsType) => {
    setliveAuctionParams(params);
    params = {
      ...params,
      ...{
        statuses:
          params.statuses === 'All'
            ? LiveAuctionStatusType[0].value
            : params.statuses,
      },
    };

    try {
      setLoading(true);
      const response: AxiosResponse = await getAuctions(params);
      const { content, totalElements, totalPages, pageable } =
        response?.data.data;
      setTotalPages(totalPages);

      const data: LiveAuctionListType[] = content.map((content: any) => {
        return {
          indentIds: content?.entityID,
          auctionIds: content?.id,
          noofPRID: content?.purchaseRequistionCount,
          createdAt: content?.createdAt,
          createdBy: content?.createdBy,
          status: content?.status,
          new:
            content?.auction?.createdAt &&
            -content?.auction?.createdAt >=
              moment().subtract(15, 'days').unix(),
        };
      });

      setLiveAuctionList(data);
      setTotalItems(totalElements);
      setPageNumber(pageable.pageNumber);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let params = liveAuctionParams;

    if (
      params.fromDate !== fromDate.startOf('day').unix() ||
      params.toDate !== toDate.endOf('day').unix()
    ) {
      params = {
        ...params,
        pageNumber: 0,
        fromDate: fromDate.startOf('day').unix(),
        toDate: toDate.endOf('day').unix(),
      };
    } else if (
      (requitionTab === 1 && !params.isUserAuctions) ||
      (requitionTab === 2 && !params.isUserDrafts)
    ) {
      params = {
        ...params,
        pageNumber: 0,
        isUserAuctions: requitionTab === 1,
        isUserDrafts: requitionTab === 2,
      };
    } else {
      params = {
        ...params,
        pageNumber: currentPage,
      };
    }

    fetchAuctionListData(params);
  }, [toDate, fromDate, requitionTab, currentPage]);

  let timeout: NodeJS.Timeout;
  const searchHandler = (value: string) => {
    setSearchText(value);
    setCurrentPage(0);
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      const params: AuctionParamsType = {
        ...liveAuctionParams,
        ...{
          pageNumber: 0,
          searchTerm: value,
        },
      };
      fetchAuctionListData(params);
    }, 500);
  };

  const statusChangeHandler = (value: string) => {
    if (value === status) return;
    setCurrentPage(0);
    setStatus(value);
    const params = {
      ...liveAuctionParams,
      ...{
        pageNumber: 0,
        statuses: value,
      },
    };
    fetchAuctionListData(params);
  };

  return (
    <div className="grid gap-y-3">
      <div className="flex justify-between mt-2">
        <CustomTab
          Tabs={LiveAuctionTabs}
          tab={requitionTab}
          setTab={setRequisitionTab}
        />
      </div>
      <TableFilters
        selectLabel={'Auction'}
        searchHandler={searchHandler}
        fromDateHandler={setFromDate}
        toDateHandler={setToDate}
        statusList={LiveAuctionStatusType}
        statusChange={statusChangeHandler}
        searchText={searchText}
        status={status}
        toDate={toDate}
        fromDate={fromDate}
      />
      <div>
        {liveAuctionList && liveAuctionList.length === 0 && !loading ? (
          <NoDataFound />
        ) : (
          <CustomTable
            totalPages={totalPages}
            currentPage={pageNumber}
            totalRecords={totalItems}
            headings={LiveAuctionTableHeadings}
            fields={LiveAuctionFields}
            itemList={liveAuctionList ?? []}
            setCurrentPage={setCurrentPage}
            paginationText={AUCTION.PaginationText}
          />
        )}
      </div>
    </div>
  );
};

export default LiveAuctionList;
