import { useEffect, useState } from "react";
import { CLASS_TYPE, IPagination, RESOURCE_TYPE } from "../../../utils/types";
import { ADMIN_ROUTES, BULK_UPLOAD_TEMPLATE_TYPE, SHAPE_TYPES } from "../../../utils/constant";
import TitleHeading from "../../atoms/CustomHeading/TitleHeading";
import Button from "../../atoms/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { IProductUPCRequest, useUpcService } from "../../../services/useUpcService";
import BulkUploadTemplateDownload from "../../organisms/BulkUpload/BulkUploadTemplateDownload";
import excelIcon from "../../../assets/icons/excel.svg";
import uploadIcon from "../../../assets/icons/upload.svg";
import { useBulkUploadService } from "../../../services/useBulkUploadService";
import BulkUploadTemplate from "../../organisms/BulkUpload/BulkUploadTemplate";
import MpcListTemplate from "../../templates/MPC/MpcList.template";

export interface IMpcs {
    id: number;
    upcCode: string
    productId: number;
    productCategory: string;
    classType: string;
    shape: string;
    status: string;
    attributes: string;
    createdAt: string;
    updatedAt: string;
}

export interface IMpcFilter {
    status: string;
    sort: string;
    search: string;
    productCategory: string;
}

const MpcListPage: React.FC = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search); 

    const initialProductMPCFiltersValues: IMpcFilter = {
        status: "ALL",
        sort: "createdAt,desc",
        search: "",
        productCategory: "ALL",
    }
    const initialPaginationValues: IPagination = {
        currentPage:  parseInt(queryParams.get('page') || '0') || 0,
        pageSize: parseInt(queryParams.get('size') || '10') || 10,
        totalPages: 1,
        totalRecords: 0,
    }

    const [mpcs, setMpcsTo] = useState<IMpcs[]>([]);
    const [mpcFilters, setMpcFiltersTo] = useState<IMpcFilter>(initialProductMPCFiltersValues);
    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);

    const upcService = useUpcService();
    const bulkUploadService = useBulkUploadService();

    const refreshMpc = (page: number, size: number) => {
        let params: IProductUPCRequest = {
            upcCode: mpcFilters?.search === "ALL" ? "" : mpcFilters?.search.trim(),
            status: mpcFilters?.status === "ALL" ? "" : mpcFilters?.status,
            page: page,
            size: size,
            sort: mpcFilters?.sort,
            categoryId: mpcFilters.productCategory === "ALL" ? undefined : parseInt(mpcFilters.productCategory, 10),
            classType: CLASS_TYPE.NONSTANDARD
        }
        upcService.getProductUPCs(params)
            .then((mpcResponse) => {
                if (mpcResponse.data.data) {
                    const { totalElements, totalPages } = mpcResponse?.data?.data;
                    setPaginationTo({
                        ...pagination,
                        totalPages: totalPages,
                        totalRecords: totalElements
                    });
                    setMpcsTo(mpcResponse?.data?.data?.content);
                }
                else {
                    setMpcsTo([]);
                }
            })
            .catch((error) => {
                console.error("Brands fetching error - ", error);
            });
    }

    const handleFiltersChange = (event: any) => {
        const { name, value } = event.target;
        setPaginationTo({...pagination, currentPage: 0})
        setMpcFiltersTo({ ...mpcFilters, [name]: value ?? "" });
    }

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage
        }));
    };

    useEffect(() => {
        refreshMpc(pagination.currentPage, pagination.pageSize);
    }, [pagination.currentPage, pagination.pageSize, mpcFilters.search, mpcFilters.status, mpcFilters.sort, mpcFilters.productCategory]);


    const navigate = useNavigate();
    
    const handleCreateMpc = () => {
		navigate(`${ ADMIN_ROUTES.MPC_CREATE }`);
	};

    const getTemplateFile = () => {
        if (!parseInt(mpcFilters.productCategory)) {
            return false;
        }
        return bulkUploadService.getBulkUploadTemplate({
            categoryId: parseInt(mpcFilters.productCategory),
            shapes: SHAPE_TYPES.map(item => item.id),
            classes: [CLASS_TYPE.NONSTANDARD],
        },
            { resourceType: RESOURCE_TYPE.PRODUCT_MPCS });
    }

    useEffect(() => {
        const params = new URLSearchParams();

        pagination.pageSize && params.set('size', pagination.pageSize.toString());
        pagination.currentPage && params.set('page', pagination.currentPage.toString());
        mpcFilters.status && params.set('status', mpcFilters.status);
        mpcFilters.sort && params.set('sort', mpcFilters.sort);
        mpcFilters.search && mpcFilters.search && params.set('search', mpcFilters.search);
        mpcFilters.productCategory && params.set('productCategory', mpcFilters.productCategory);
    
        navigate(`${location.pathname}?${params.toString()}`, { replace: true });
    }, [pagination, mpcFilters]);
    
    return (
        <div className="grid gap-y-3">
            <div className="flex justify-between">
                <TitleHeading text="Master Product Code" />
                <div className="flex gap-3">
                    <BulkUploadTemplateDownload icon={excelIcon} getTemplateFile={getTemplateFile} type={BULK_UPLOAD_TEMPLATE_TYPE.APPLICATION_OCTET_STREAM} productCategory={mpcFilters.productCategory} />
                    <BulkUploadTemplate icon={uploadIcon} section={RESOURCE_TYPE.PRODUCT_MPCS} />
                    <Button variant="contained" label="Add MPC" onClick={handleCreateMpc} />
                </div>
            </div>
            <MpcListTemplate mpcs={ mpcs } pagination={ pagination } handlePaginationChange={ handlePaginationChange } handleRowsPerPageChange = { handleRowsPerPageChange }  handleFiltersChange={ handleFiltersChange } mpcFilters={ mpcFilters } />
        </div>
    )
}

export default MpcListPage;
