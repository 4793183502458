
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';

interface DropDownGroupProps {
    label: string;
    value: any;
    onChange: any;
    options: any;
    disabled: boolean;
    defaultValue: string;
}

const DropDownGroup: React.FC<DropDownGroupProps> = ({
    label,
    value,
    onChange,
    options,
    disabled }) => {

    if (!options || options.length === 0) {
        options = [];
    }
    return (
        <FormControl fullWidth className='bg-white'>
            <InputLabel id='label'>{ label }</InputLabel>
            <Select
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 350
                        },
                    },
                }}
                labelId='label'
                id='select'
                label={ label }
                value={ value }
                onChange={ onChange }
                disabled={ disabled }
            >
                { options?.map((option: any) => (
                    <MenuItem key={ option.id } value={ option.id }>
                        { option.label }
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default DropDownGroup
