import React from 'react'
import { createUseStyles } from 'react-jss';
import { FormikProps } from 'formik';
import { ADMIN_ROUTES, MODE, SERVICE_STATUS_OPTIONS } from '../../../../utils/constant';
import { makeRoute } from '../../../../utils/helper';
import ButtonV2 from '../../../atoms/ButtonV2';
import StatusV2 from '../../../atoms/Status/StatusV2';
import { ServicePayload } from '../../../../services/useVASMasterCategoryService';
import { useNavigate, useParams } from 'react-router-dom';
import editIcon from "../../../../assets/icons/editPenWhiteOutlinedIcon.svg"

interface SuperServiceFormTemplateProps {
    mode: string,
    formik: FormikProps<ServicePayload>,
    onBack: () => void
}

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
    },
    textColor: {
        color: theme.palette.text.primaryDarkLight
    },
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
    property:{
        color: theme.palette.textV2.tertiary.tertiary700,
    }
}));

const MainServiceViewTemplate: React.FC<SuperServiceFormTemplateProps> = ({ mode, onBack, formik }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const handleSubmit = () => formik.handleSubmit();

    const params = useParams();

    const handleActionRoute = (id: number, mode: string) => () => {
        navigate(makeRoute(ADMIN_ROUTES.VAS_MASTER_CATEGORIES_UPDATE(id), { query: { mode } }));
    }

    return (
        <form className="grid gap-y-8" >
            <div className='flex justify-between'>
                <div className={`${classes.mainHeading} text-lg font-semibold my-auto`}>{formik.values?.name} - {formik.values?.serviceCode}</div>
                <ButtonV2 onClick={handleActionRoute(Number(params.id), MODE.UPDATE)} variant='primaryContained' label={"Edit Main Service"}  startIcon={<img src={editIcon} alt= "editIcon"/>}/>
            </div>

            <div className='grid gap-y-4'>
                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Main Service Details</div>
                    <div className='grid grid-cols-6 gap-x-4'>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Main Service ID</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.serviceCode}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Main Service Name</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.name}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Linked Super Service</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.superService?.label}</div>
                        </div>
                    </div>
                    <div className="grid gap-y-1">
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Description</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.description}</div>
                        </div>
                    </div>
                </div>
                <StatusV2 heading="Service Status" options={SERVICE_STATUS_OPTIONS} formik={formik} name="status" mode = {mode} />
            </div>

            <div className="flex justify-end gap-4">
                <ButtonV2 variant="tertiaryContained" label='Back' onClick={onBack} />
                {mode !== MODE.VIEW && <ButtonV2 variant="secondaryContained" onClick={handleSubmit} label={`+${mode}`} />}
            </div>
        </form>
    )
}

export default MainServiceViewTemplate