import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom';
import { VAS_MASTER_CATEGORY_TYPE } from '../../../../utils/types';
import SuperServiceCreatePage from './SuperServiceCreate.page';
import MainServiceCreatePage from './MainServiceCreate.page';
import ServiceCategoryCreatePage from './ServiceCategoryCreate.page';

const ServiceCreatePage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const categoryType: VAS_MASTER_CATEGORY_TYPE = VAS_MASTER_CATEGORY_TYPE[(searchParams.get('type') ?? "SUPER_SERVICE") as keyof typeof VAS_MASTER_CATEGORY_TYPE];

    const categoryComponent = useMemo(() => {
        switch (categoryType) {
            case VAS_MASTER_CATEGORY_TYPE.MAIN_SERVICE: return <MainServiceCreatePage />
            case VAS_MASTER_CATEGORY_TYPE.SERVICE_CATEGORY: return <ServiceCategoryCreatePage />
            default: return <SuperServiceCreatePage />
        }
    }, [categoryType])

    return <>
        {categoryComponent}
    </>
}

export default ServiceCreatePage