import { request } from ".";
import { UploadedImageData } from "../components/pages/CustomerManagement/FinishedProductCreation.page";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";
import { Attribute } from "./useAttributeServiceV2";

export const SERVICE_URLS = {
  CREATE_SERVICE: "/vas/service",
  GET_SERVICES: "/vas/services",
  GET_SERVICE: "/vas/service/:id",
  UPDATE_SERVICE: "/vas/service/:id",

  CREATE_USC: "/vas/usc",
  GET_USCS: "/vas/uscs",
  GET_USC: "/vas/usc/:id",
  UPDATE_USC: "/vas/usc/:id",

  CREATE_MACHINE: "/vas/machine",
  GET_MACHINES: "/vas/machines",
  GET_MACHINE: "/vas/machine/:id",
  UPDATE_MACHINE: "/vas/machine/:id",
};

export interface IServiceSearchParams {
  level?: number;
  page?: number;
  size?: number;
  sort?: string;
  name?: string;
  status?: string;
  parentId?: number;
}

export interface IServiceRequestParams {
  superServiceIds?: number[] | null;
  mainServiceIds?: number[] | null;
  serviceCategoryIds?: number[] | null;
}

export interface Services {
  id: number;
  name: string;
  status: string;
  serviceCode: string;
  level: number;
  parentService: string;
  superParentService: string;
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
}

export interface Service {
  id: number;
  name: string;
  serviceCode: string;
  status: string;
  description: string;
  level: number;
  parentId: number | null;
  attributes: Attribute[];
  ancestors: {
    parentService: {
      id: number;
      name: string;
      serviceCode: string;
      status: string;
      description: string;
      level: number;
      parentId: number | null;
      createdBy: number;
      updatedBy: number;
      createdAt: string;
      updatedAt: string;
    };
    superParentService: {
      id: number;
      name: string;
      serviceCode: string;
      status: string;
      description: string;
      level: number;
      parentId: number | null;
      createdBy: number;
      updatedBy: number;
      createdAt: string;
      updatedAt: string;
    };
  };
}

export interface ServicePayload {
  name: string;
  serviceCode?: string;
  status: string;
  description: string;
  level: number;
  parentId: number | null;
  superParentId?: number | null;
  attributes?: any;
  subAttributeIds?: {
    [key: string]: SubAttributes;
  };
  superService?: {
    label: string;
    id: string | number;
  };
  mainService?: {
    label: string;
    id: string | number;
  };
}

export interface SubAttributes {
  minTolerance: number;
  maxTolerance: number;
}

export interface IUSCSearchParams {
  page?: number;
  size?: number;
  sort?: string;
  name?: string;
  status?: string;
}

export interface IUSCRequestParams {
  superServiceIds?: number[] | null;
  mainServiceIds?: number[] | null;
  serviceCategoryIds?: number[] | null;
}

export interface USCPayload {
  name: string;
  uscCode?: string;
  status: string;
  description: string;
  productServiceId: number | null;
  attributes: any;
  subAttributeIds?: {
    [key: string]: SubAttributes;
  };
  superService?: {
    label: string;
    id: string | number;
  };
  mainService?: {
    label: string;
    id: string | number;
  };
  productService?: {
    label: string;
    id: string | number;
  };
}
export interface IUSCs {
  id: number;
  uscCode: string;
  superService: string;
  mainService: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;  
  updatedBy: string; 
  status: string;
  serviceCategory: string;
}

export interface IUSC {
  id: number;
  name: string;
  uscCode: string;
  description: string;
  status: string;
  services: any;
  attributes: Attribute[];
  createdAt: string;
  updatedAt: string;
  createdBy: number;
  updatedBy: number;
}

export interface IMachineSearchParams {
  page?: number;
  size?: number;
  sort?: string;
  search?: string;
  status?: string;
}

export interface IMachineRequestParams {
  uscIds?: number[] | null;
  serviceCategoryIds?: number[] | null;
}
export interface MachinePayload {
  uscId: number | null;
  serviceCategory:{
    label: string;
    id: string | number;
  };
  usc: {
    label: string;
    id: string | number;
  };
  subAttributeIds?: {
    [key: string]: SubAttributes;
  };
  machineName: string;
  machineVendor: string;
  modelNumber: string;
  location: string;
  machineType: string;
  manufactureYear: number | null;
  operationalSpeed: string;
  equipmentName: string;
  equipmentNames: any[];
  equipmentDescription: string;
  materialHandle: string;
  qualityStandard: string;
  materialGrade: string;
  serviceability: string;
  minOrderQuantity: number | null;
  maxOrderQuantity: number | null;
  serviceCharge: {
    normalServiceCharge: number | null;
    minimumServiceCharge: number | null;
    maximumServiceCharge: number | null;
  };
  machineDescription: string;
  status: string;
  attributes: any;
  images: (UploadedImageData | File)[],
  imageListToDelete?: number[]
}

export interface IMachines {
  id: number;
  modelNumber: string;
  uscCode: string;
  serviceCategory: any;
  description: string;
  status: string;
  createdAt: string;
  createdBy: number;
}

export const useVASMasterCategoryService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();
  const createService = (data: ServicePayload) => {
    return request(API_METHOD.POST, SERVICE_URLS.CREATE_SERVICE, authenticatedUser, data);
  };

  const getAllService = (params: IServiceSearchParams, data: IServiceRequestParams) => {
    return request(API_METHOD.POST, SERVICE_URLS.GET_SERVICES, authenticatedUser, data, { params });
  };

  const getServiceById = (id: number) => {
    return request(API_METHOD.GET, replaceUrlParams(SERVICE_URLS.GET_SERVICE, { id }), authenticatedUser);
  };

  const updateCategory = (id: number, data: ServicePayload) => {
    return request(API_METHOD.PUT, replaceUrlParams(SERVICE_URLS.UPDATE_SERVICE, { id }), authenticatedUser, data);
  };

  const createUSC = (data: USCPayload) => {
    return request(API_METHOD.POST, SERVICE_URLS.CREATE_USC, authenticatedUser, data);
  };

  const getUSCs = (params: IUSCSearchParams, data: IUSCRequestParams) => {
    return request(API_METHOD.POST, SERVICE_URLS.GET_USCS, authenticatedUser, data, { params });
  };

  const getUSCById = (id: number) => {
    return request(API_METHOD.GET, replaceUrlParams(SERVICE_URLS.GET_USC, { id }), authenticatedUser);
  };

  const updateUSC = (id: number, data: USCPayload) => {
    return request(API_METHOD.PUT, replaceUrlParams(SERVICE_URLS.UPDATE_USC, { id }), authenticatedUser, data);
  };

  const createMachine = (data: MachinePayload, params: any) => {
    return request(API_METHOD.POST, SERVICE_URLS.CREATE_MACHINE, authenticatedUser, data, { params });
  };

  const getMachines = (params: IMachineSearchParams, data: IMachineRequestParams) => {
    return request(API_METHOD.POST, SERVICE_URLS.GET_MACHINES, authenticatedUser, data, { params });
  };

  const getMachineById = (id: number) => {
    return request(API_METHOD.GET, replaceUrlParams(SERVICE_URLS.GET_MACHINE, { id }), authenticatedUser);
  };

  return {
    createService,
    getAllService,
    getServiceById,
    updateCategory,

    createUSC,
    getUSCs,
    getUSCById,
    updateUSC,

    createMachine,
    getMachines,
    getMachineById
  };
};
