import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";
import { HTTP_STATUS } from "../utils/types";
import { ImageData } from "../components/organisms/ImageUploader";

export const IMAGE_MASTER = {
    GET_ALL_IMAGEMASTER: "/image-master",
    GET_REMAINING_CATEGORIES: "/image-master/categories",
    GET_REMAINING_BRANDS: "/image-master/brands",
    GET_REMAINING_SHAPES: "/image-master/shapes",
    GET_IMAGEMASTER: "/image-master/:id",
    CREATE_IMAGEMASTER: "/image-master",
    UPDATE_IMAGEMASTER: "/image-master/:id",
}

export interface ICreatePreSignedUrl {
    productCategoryId: number;
    isRepresentationImage: boolean;
    brandId: number;
    catalogueType: string;
    classType: string;
    shape: string;
    fileNames: string[];
}

export interface IImageMasterBrandCategoryShapeRequest {
    catalougeType: string;
    classType: string;
    productCategoryId?: number|null;
    shape?: string|null;
}
export interface IImageMasterFilterRequest {
    searchByName?: string;
    page: number;
    size: number;
    sort?: string;
    brandId?: number | null;
    productCategoryId?: number | null;
}
export const useImageMasterService = () => {

    const { user: authenticatedUser } = useAuthenticatedUser();

    const createPreSignedUrls = (data: ICreatePreSignedUrl[]) => {
        return request(API_METHOD.POST, IMAGE_MASTER.CREATE_IMAGEMASTER, authenticatedUser, { "presignedUrlRequestBody": data });
    };
    const updateImageMaster = (data: ICreatePreSignedUrl[], id: number) => {
        return request(API_METHOD.PUT,replaceUrlParams(IMAGE_MASTER.UPDATE_IMAGEMASTER, { id }) , authenticatedUser, { "presignedUrlRequestBody": data });
    };

    const getImageMasterById = (id: number) => {
        return request(API_METHOD.GET, replaceUrlParams(IMAGE_MASTER.GET_IMAGEMASTER, { id }), authenticatedUser);
    };

    const getAllImageMaster = (params: IImageMasterFilterRequest) => {
        return request(API_METHOD.GET, IMAGE_MASTER.GET_ALL_IMAGEMASTER, authenticatedUser, null, { params });
    };

    const getRemainingProductCategories = (params: IImageMasterBrandCategoryShapeRequest) => {
        return request(API_METHOD.GET, IMAGE_MASTER.GET_REMAINING_CATEGORIES, authenticatedUser, null, { params });
    };

    const getRemainingBrands = (params: IImageMasterBrandCategoryShapeRequest) => {
        return request(API_METHOD.GET, IMAGE_MASTER.GET_REMAINING_BRANDS, authenticatedUser, null, { params });
    };

    const getRemainingShapes = (params: IImageMasterBrandCategoryShapeRequest) => {
        return request(API_METHOD.GET, IMAGE_MASTER.GET_REMAINING_SHAPES, authenticatedUser, null, { params });
    };

    const uploadImageUsingPreSignedUrls = (image: File | ImageData, url : string) => {
        const headers = {
            'Content-Type': image instanceof File ? image.type : image.mimeType,
        };
        return request(API_METHOD.PUT, url, null ,image, null, undefined, process.env.REACT_APP_API_V1_URL ,headers);
    }

    return {
        createPreSignedUrls,
        updateImageMaster,
        getImageMasterById,
        getAllImageMaster,
        getRemainingProductCategories,
        getRemainingBrands,
        getRemainingShapes,
        uploadImageUsingPreSignedUrls
    };
}