import React, { useState, useEffect } from "react";
import OtpInputs from "../../Shared/OtpValidation/OtpInputs";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";


const VerifyEmailDialog = ({ open, onClose, openOtpmail }) => {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [resendCount, setResendCount] = useState(0);
  const [otpErrorMessage, setOtpErrorMessage] = useState();
  const [otpError, setOtpError] = useState(false);

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  useEffect(() => {
    setOtp(["", "", "", ""]);
  }, [8779843398]);

  const handleVerify = () => {
    console.log("Verifying OTP:", otp);
    onClose(); // Close the dialog after verification
  };

  const handleCancel = () => {
    console.log("Cancel clicked");
     onClose(); // Close the dialog on cancel
  };
  const [editMailDialogOpen, setEditMailDialogOpen] = useState(false);

  const openEditMailDialog = () => {
    setEditMailDialogOpen(true);
    // onClose();
  };

  const closeEditMailDialog = () => {
    setEditMailDialogOpen(false);
  };

  const navigateBack = () => {
    closeEditMailDialog(); // Close the EditNumberDialog
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
       <div style={{ padding: '1.5rem 2.5rem 2.5rem 2.5rem' }}>
      <DialogTitle>Verify Email Address</DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          biswabijay1021@gmail.com:
          <a style={{ color: "orange" }} variant="outlined" onClick={() => openOtpmail()}>
           &nbsp; Change
          </a>
        </Typography>
        <Typography variant="body1" paragraph>
          We've sent a One Time Password (OTP) to your email address. Please
          enter it below.
        </Typography>

        <span>Enter OTP</span>
        <div className="mb-5">
          <OtpInputs
            otp={otp}
            setOtp={setOtp}
            otpError={otpError}
            setOtpError={setOtpError}
            otpErrorMessage={otpErrorMessage}
          />
        </div>
         <a style={{ color: "orange" }} variant="outlined" >
                &nbsp;  Resend OTP
                </a>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: '#1D3679' }}variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
        <Button style={{ color: '#fff', background: '#1D3679' }}variant="contained"  onClick={handleVerify}>
          Verify
        </Button>
      </DialogActions>
      </div>
    </Dialog>
  );
};

export default VerifyEmailDialog;
