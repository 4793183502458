import { createUseStyles } from 'react-jss';
import * as yup from "yup";
import { useFormik } from 'formik';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import TextFieldV2 from '../../atoms/TextFieldV2';
import ButtonV2 from '../../atoms/ButtonV2';
import { IBusinessProfile } from '../../pages/CustomerManagement/ProfileOverview.page';
import { BUSINESS_PROFILE_SECTIONS } from '../../../utils/constant';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.primarylightAccent}`,
    },
    title: {
        color: theme.palette.text.primaryLight,
    },
    property: {
        color: theme.palette.text.primaryLight,
    }
}));

export interface IAboutUsForm {
    description: string;
}

interface IAboutUsUpdateTemplateProps {
    updateBusinessProfileSection: (section: string, values: any) => void;
    businessProfile: IBusinessProfile | null;
    setIsEditing: (value: boolean) => void;
}

const AboutUsUpdateTemplate: React.FC<IAboutUsUpdateTemplateProps> = ({ updateBusinessProfileSection, businessProfile, setIsEditing }) => {

    const classes = useStyles();
    const { showSnackbar } = useSnackbar();

    const aboutusSchema = yup.object().shape({
        description: yup.string().max(150, 'Description must be at most 150 characters'),
    });

    const aboutusformik = useFormik<IAboutUsForm>({
        initialValues: {
            description: businessProfile?.description || '',
        },
        validationSchema: aboutusSchema,
        enableReinitialize: true,
        onSubmit: async (values: any, { setSubmitting }) => {
            try {
                setSubmitting(true);
                const requestBody = {
                    description: values.description,
                };
                await updateBusinessProfileSection(BUSINESS_PROFILE_SECTIONS.OTHER_INFORMATION, requestBody);
                setIsEditing(false);
            } catch (error) {
                showSnackbar("error", `Business profile update failed: ${error}`);
            } finally {
                setSubmitting(false);
            }
        },
    });

    return (
        <form onSubmit={aboutusformik.handleSubmit}>
            <div className={`${classes.container} p-6 rounded-lg mt-3`}>
                <div className='flex justify-between items-center pb-6 border-b border-grey-500'>
                    <h2 className={`${classes.title} text-2xl font-medium`}>About Us</h2>
                    <div className='flex gap-4'>
                        <ButtonV2 variant={'tertiaryContained'} label={'Back'} type="button" onClick={() => setIsEditing(false)} />
                        <ButtonV2 variant={'primaryContained'} label={'Save'} type="submit" />
                    </div>
                </div>
                <div>
                    <p className={`mt-6 mb-2 ${classes.property}`}>About Us</p>
                    <TextFieldV2
                        className="w-44"
                        fullWidth
                        placeholder="Enter About Us"
                        {...aboutusformik.getFieldProps("description")}
                    />
                </div>

            </div>
        </form>
    );
};

export default AboutUsUpdateTemplate;
