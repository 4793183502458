import Card from "./Card";

export const PurchaseHistoryCard = () => {
  return (
    <div className="bg-white flex border border-cgray-6 rounded-lg py-4 justify-between px-3">
      <Card title={"Vendor name"} value={"TATA Steel"} size={"small"} />
      <Card title={"POID"} value={"PO12345"} size={"small"} />
      <Card title={"Date of purchase"} value={"13-04-2024"} size={"small"} />
      <Card title={"Quantity"} value={"120 mtn"} size={"small"} />
      <Card title={"Purchase/MT"} value={"₹ 2,30,000"} size={"small"} />
    </div>
  );
};
