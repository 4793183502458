import React from 'react'
import { IPagination } from '../../../../utils/types';
import { IManufacturer, IManufaturerFilter } from '../../../pages/AdminMaster/Manufacturer/ManufacturerList.page';
import { useNavigate } from 'react-router-dom';
import Table, { ColumnType } from '../../../organisms/Table';
import { ADMIN_ROUTES, sortOptions, statusOptions } from '../../../../utils/constant';
import { createUseStyles } from "react-jss";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import TextField from '../../../atoms/TextField';
import Select from '../../../atoms/Select';
import moment from 'moment';
import { enumToString, makeRoute, snakeCaseToTitleCase } from '../../../../utils/helper';
import ResourceStatus from '../../../atoms/ResourceStatus/ResourceStatus';

interface IManufacturerTemplateProps {
    manufacturers: IManufacturer[];
    pagination: IPagination;
    handleFiltersChange: (event: any) => void;
    handlePaginationChange: any;
    manufacturersFilters: IManufaturerFilter;
    handleRowsPerPageChange: any;
}

const useStyles = createUseStyles((theme: any) => ({
    viewIconContainer: {
      color: theme.palette.icon.primaryLight,
    },
    editIconContainer: {
      color: theme.palette.icon.secondryLight,
    }
  }));

const ManufacturerListTemplate: React.FC<IManufacturerTemplateProps> = ({ manufacturers, pagination, handleFiltersChange, handlePaginationChange, manufacturersFilters, handleRowsPerPageChange }) => {

    const classes = useStyles();

    const navigate = useNavigate();

    const schema = {
        id: "1",
        title: "",
        pagination: {
            total: pagination.totalRecords,
            currentPage: pagination.currentPage,
            isVisible: true,
            limit: pagination.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange,
        },
        columns: [
            { label: "Sr No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "Manufacturer Name", key: "name", type: "string" as ColumnType, props: { className: '' } },
            { label: "Manufacturer type", key: "manufacturerType", type: "number" as ColumnType, props: { className: '' } },
            { label: "Created Date", key: "createdAt", type: "datetime" as ColumnType, props: { className: '' } },
            { label: "Last Modified", key: "updatedAt", type: "datetime" as ColumnType, props: { className: '' } },
            { label: "Status", key: "status", component: ({ value }: { value: string }) => <ResourceStatus status={value} />, type: "custom" as ColumnType, props: {} }, 
            { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } },
        ],
    };

    const handleViewClick = (id: number) => {
        navigate(makeRoute(ADMIN_ROUTES.MANUFACTURER_VIEW, {params:  { id: id } }));
    };

    const handleEditClick = async (id: number) => {
        navigate(makeRoute(ADMIN_ROUTES.MANUFACTURER_UPDATE, {params:  { id: id } }));
    };

    const Action = (id: number) => {
        return <div className="flex gap-x-2 justify-end">
            <RemoveRedEyeIcon className={ `${ classes.viewIconContainer } text-secondary cursor-pointer`} onClick={ (e) => handleViewClick(id) } />
            <ModeEditOutlineIcon className={ `${ classes.editIconContainer } text-secondary cursor-pointer`} onClick={ (e) => handleEditClick(id) } /> 
        </div>
    }

    const records = manufacturers.map((manufacturer: IManufacturer, index: number) => [
        pagination.currentPage * pagination.pageSize + index + 1,
        manufacturer.name,
        snakeCaseToTitleCase(manufacturer.manufacturerType),
        moment(manufacturer.createdAt).format("YYYY-MM-DD HH:mm:ss"),
        moment(manufacturer.updatedAt).format("YYYY-MM-DD HH:mm:ss"),
        enumToString(manufacturer.status),
        Action(manufacturer.id)
    ]);

    const manufacturerTypeOptions = [
        { value: "ALL", label: "All" },
        { value: "PRIMARY_MANUFACTURER", label: "Primary Manufacturer" },
        { value: "SECONDARY_MANUFACTURER", label: "Secondary Manufacturer" },
        { value: "END_MANUFACTURER", label: "End Manufacturer" }
    ];

    return (
		<div className='grid gap-y-1.5'>
			<div className="flex justify-between">
			    <div className="flex gap-x-3">
                   <div>
                        <Select
                            variant="outlined"
                            className="w-44"
                            label="ManufacturerType"
                            name="manufacturerType"
                            id="manufacturerType"
                            value={ manufacturersFilters.manufacturerType }
                            onChange={ (e) => handleFiltersChange(e) }
                            options={ manufacturerTypeOptions }
                        />
                    </div>
					<div>
                        <Select
                            variant="outlined"
                            className="w-44"
                            label="Status"
                            name="status"
                            id="status"
                            value={ manufacturersFilters.status }
                            onChange={ (e) => handleFiltersChange(e) }
                            options={ statusOptions }
                        />
                    </div>
                    <div>
                        <Select
                            variant="outlined"
                            className='w-64'
                            id="sort"
                            label="Sort"
                            name="sort"
                            value={ manufacturersFilters.sort }
                            onChange={ (e) => handleFiltersChange(e) }
                            options={ sortOptions }
                        />
                    </div>
				</div>
				<TextField
					className="w-72"
					type="text"
					label="Search by Name"
					variant="outlined"
					name="search"
					value={ manufacturersFilters.search }
					onChange={ handleFiltersChange }
				/>
			</div>
			<Table schema={ schema } records={ records } />
		</div>
	)
}

export default ManufacturerListTemplate;
