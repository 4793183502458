import React, { useState } from "react";
import { AUTH_STATES, HTTP_STATUS } from "../../../utils/types";
import OtpInputGroup from "../../molecules/OtpInputGroup/OtpInputGroup";
import CountDownTimer from "../../atoms/CountDownTimer";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { useSnackbar } from "../../../hooks/useSnackBar";
import Button from "../../atoms/Button";

interface OtpVerificationTemplateProps {
    setAuthState: (state: AUTH_STATES) => void;
    mobileNumber: string;
    onBack: () => void
    otpVerifier: (verificationInfo: any) => Promise<any>
}

const OtpVerificationTemplate: React.FC<OtpVerificationTemplateProps> = ({ setAuthState, mobileNumber, otpVerifier, onBack }) => {
    const [otpErrorMessage, setOtpErrorMessage] = useState<string>("");
    const [otp, setOtp] = useState<string[]>(['', '', '', '']);
    const [isTimerActive, setIsTimerActive] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const { setAuthenticatedUser } = useAuthenticatedUser();

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const otpCode = otp.join('');

        if (otpCode.length !== 4)   
            return setOtpErrorMessage("Enter valid OTP");

        otpVerifier({ mobileNumber, otp: otpCode })
            .then((res) => {
                if (res?.status === HTTP_STATUS.OK) {
                    let { user, token } = res?.data?.data ?? {};
                    setAuthenticatedUser({ ...user, token })
                    if (!user.email)
                        setAuthState(AUTH_STATES.USER_FORM)
                } else if (res?.status === HTTP_STATUS.BAD_REQUEST) {
                    setOtpErrorMessage("Enter correct OTP")
                } else showSnackbar('error', 'Server not responding, Please try again later')
            }).catch(error => {
                console.error("Otp Verification:", error)
            }).finally(() => setIsLoading(false))
    };

    const handleResendOtp = () => {
        setIsTimerActive(true);
        setOtp(['', '', '', '']);
        otpVerifier({ mobileNumber })
            .then(res => {
                if (res.status === HTTP_STATUS.BAD_REQUEST) 
                    showSnackbar('error', 'OTP not sent');
                else if (res.status === HTTP_STATUS.OK) {
                    setIsTimerActive(true);
                    showSnackbar('success', 'OTP sent successfully');
                }
                else showSnackbar('error', 'Server not responding, Please try again later')
            }).catch(error => {
                console.error("Otp Verification - Resend Otp:", error);
            }).finally(() => setIsLoading(false))
    }

    const onTimerComplete = () => {
        setIsTimerActive(false);
    }

    return (
        <div className="auth-right-child-container p-10 flex flex-col">
            {SnackBarComponent}
            <div className="form-header flex flex-col">
                <h1 className="text-3xl font-bold sm:text-fs-24 md:text-fs-24">Verify OTP</h1>
                <p className="text-base font-normal sm:text-sm md:text-sm">We've sent a One Time Password (OTP) to the mobile number above. Please enter it to complete verification</p>
            </div>
            <div className="form-container flex flex-col grow">
                <form className="flex flex-col mt-4 mb-9" onSubmit={handleSubmit}>
                    <div className='mb-4'>+91 <span>{mobileNumber}</span> <span onClick={onBack} className='text-secondary cursor-pointer font-normal text-base'>Change</span></div>
                    <span>Enter OTP</span>
                    <div className="mb-7">
                        <OtpInputGroup otp={otp} setOtp={setOtp} otpErrorMessage={otpErrorMessage} setOtpErrorMessage={setOtpErrorMessage} />
                    </div>           
                     <Button type="submit" variant="contained" label="Verify" isLoading={isLoading}/>
                </form>
                <div className=' text-center'>
                    <span className={`text-secondary cursor-pointer font-medium text-lg ${isTimerActive ? "disable" : ""}`} onClick={handleResendOtp}>Resend OTP
                        {isTimerActive && <span> <span>(</span> <CountDownTimer timeInSeconds={60} onComplete={onTimerComplete} /> )</span>}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default OtpVerificationTemplate;