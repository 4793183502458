import { createUseStyles } from "react-jss";
import uploadImageIcon from "../../../../assets/images/uploadImage.svg";
import { ADMIN_ROUTES, DOCUMENT_RESOURCE_TYPE, FINISHED_PRODUCTS, FINISHED_PRODUCTS_APPLICATION_TYPE } from "../../../../utils/constant";
import { useEffect, useState } from "react";
import { FormikProps } from "formik";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFinishedProductService } from "../../../../services/useFinishedProductService";
import { useSnackbar } from "../../../../hooks/useSnackBar";
import { useFileService } from "../../../../services/useFileService";
import { HTTP_STATUS } from "../../../../utils/types";
import filledPlusIcon from '../../../../assets/icons/filledPlus.svg'
import filledEditIcon from '../../../../assets/icons/filledEdit.svg'
import { FinishedProductsFormik, UploadedImageData } from "../../../pages/CustomerManagement/FinishedProductCreation.page";
import { isNumber } from 'lodash';
import MultiSelectInput2, { IMultiSelectOption } from "../../../atoms/MultiSelectInput2";
import ImageUploadModalTemplate from "../../Dashboard/ImageUploadModal.template";
import TextFieldV2 from "../../../atoms/TextFieldV2";
import Button from "../../../atoms/Button";
import DisplayImageV2 from "../../../molecules/ImageUpload/DisplayImageV2";
import ButtonV2 from "../../../atoms/ButtonV2";
import { convertToCamelCase } from "../../../../utils/helper";
import { useNavigate } from "react-router-dom";

interface AddFinishedProductTemplateProps {
    handleOnBack: () => void;
    formik: FormikProps<FinishedProductsFormik>;
    setSelectedFile: (file: (string | null)[]) => void | undefined;
    cardId: number;
    isLoading: boolean
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.textV2.secondary.secondary50}`,
    },
    finishedProduct: {
        color: theme.palette.textV2.tertiary.tertiary700,
        borderBottom: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
    },
    uploadImageWrapper: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    imagePreview: {
        width: "140px",
        height: "160px",
    },
    select: {
        color: theme.palette.textV2.primary.primary900,
        background: theme.palette.backgroundV2.tertiary.tertiary500,
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary200}`,
        "& .MuiInputBase-input": {
            background: "none",
        },
        "& .MuiSvgIcon-root": {
            cursor: "pointer",
            marginRight: "4px",
        },
    },
    addButton: {
        "&.MuiButton-root": {
            border: "none",
        },
    },
    imageContainer: {
        width: '141px',
        height: '159px',
        borderRadius: '8px'
    },
    alphaBackground: {
        background: 'rgba(0,0,0,0.1)',
        border: `1px solid ${theme.palette.backgroundV2.tertiary.tertiary500}`
    }
}))

const AddFinishedProductTemplate: React.FC<AddFinishedProductTemplateProps> = ({ handleOnBack, formik, isLoading }) => {

    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);
    const [selectedFile, setSelectedFileTo] = useState<File | null>(null);
    const [hoverId, setHoverIdTo] = useState<string | null>(null);
    const [selectedPreviewImagePath, setSelectedPreviewImagePath] = useState<string>("");
    const [imageDialogOpen, setImageDialogOpenTo] = useState(false);
    const [selectedIndex, setSelectedImageIndex] = useState<number | null>(null)
    const navigate = useNavigate();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isNumber(selectedIndex) && formik.values.images[selectedIndex] !== null && !(formik.values.images[selectedIndex] instanceof File)) {
            formik.setFieldValue('imageListToDelete', [...formik.values.imageListToDelete as number[], (formik.values.images[selectedIndex] as UploadedImageData)?.id])
        }
        formik.setFieldValue(`images[${selectedIndex}]`, selectedFile as File)
        setSelectedFileTo(null);
        setIsImageModalOpen(false);
    };

    const handleUpload = (index: number) => (e: any) => {
        e.stopPropagation();
        setSelectedFileTo(null);
        setSelectedImageIndex(index);
        setIsImageModalOpen(true);
    }

    const handleSelectChange = (options: IMultiSelectOption[]) => {
        formik.setFieldValue('applicationType', options);
    }

    const handleImageModalClose = () => {
        setIsImageModalOpen(false);
    };

    const handleImageView = (path: string) => () => {
        !imageDialogOpen && setSelectedPreviewImagePath(path);
        setImageDialogOpenTo(!imageDialogOpen);
    }

    const handleBackToDashboard = () => {
        navigate(ADMIN_ROUTES.CUSTOMER_MANAGEMENT_LIST);
    };

    return (
        <div className={`flex flex-col rounded-lg p-6 ${classes.container}`}>
            {SnackBarComponent}
            <span className={`text-2xl font-medium pb-8 ${classes.finishedProduct}`}>{FINISHED_PRODUCTS.FINISHED_PRODUCT}</span>
            <div className={`pt-8 ${classes.uploadImageWrapper}`}>
                <span className="text-base font-medium">{FINISHED_PRODUCTS.UPLOAD_IMAGE}</span>
                <div className="flex gap-5 mt-3">
                    {Array(formik.values?.images?.length < 5 ? formik.values?.images?.length + 1 : 5).fill(null).map((_, index) => (
                        <div key={index}>
                            {index !== formik.values?.images?.length ?
                                <div className={`overflow-hidden relative ${classes.imageContainer}`} onMouseEnter={() => setHoverIdTo(`EDIT-${index}`)} onMouseLeave={() => setHoverIdTo(null)}>
                                    <img className={`rounded-lg cursor-pointer ${classes.imagePreview}`}
                                        src={formik.values?.images?.[index] instanceof File ?
                                            URL?.createObjectURL(formik.values?.images?.[index] as File)
                                            :
                                            (formik.values?.images?.[index] as UploadedImageData)?.path} alt="icon" />
                                    {hoverId === `EDIT-${index}` && <span className={`${classes.alphaBackground} rounded-lg absolute top-0 right-0 w-full h-full z-0`}
                                        onClick={handleImageView(formik.values?.images?.[index] instanceof File ?
                                            URL?.createObjectURL(formik.values?.images?.[index] as File)
                                            :
                                            (formik.values?.images?.[index] as UploadedImageData)?.path)}>
                                        <img src={filledEditIcon} alt="" onClick={handleUpload(index)} className="absolute top-0 right-0 mt-2 mr-2 z-10 cursor-pointer" />
                                    </span>}
                                </div>
                                : (
                                    <div className={`relative `} onMouseEnter={() => setHoverIdTo(`ADD-${index}`)} onMouseLeave={() => setHoverIdTo(null)}>
                                        <img src={uploadImageIcon} alt="" className="z-0 h-full w-full" />
                                        {hoverId === `ADD-${index}` && <span className={`${classes.alphaBackground} rounded-lg absolute top-0 right-0 w-full h-full`}>
                                            <img src={filledPlusIcon} alt="" onClick={handleUpload(index)} className="absolute top-0 right-0 mt-2 mr-2 z-10 cursor-pointer" />
                                        </span>}
                                    </div>
                                )}
                            {
                                <ImageUploadModalTemplate
                                    dialogOpen={isImageModalOpen}
                                    setDialogOpen={handleImageModalClose}
                                    onSubmit={handleFileChange}
                                    selectedFile={selectedFile}
                                    setSelectedFile={setSelectedFileTo}
                                />}
                        </div>
                    ))}
                </div>
            </div>
            <form onSubmit={formik.handleSubmit} className="mt-6">
                <div className="grid grid-cols-2 gap-x-4">
                    <TextFieldV2
                        label="Product Name"
                        {...formik.getFieldProps("productName")}
                        value={convertToCamelCase(formik.values.productName)}
                        placeholder="Enter Product Name"
                        fullWidth
                        error={formik.touched.productName && Boolean(formik.errors.productName)}
                        helperText={formik.errors?.productName}
                    />

                    <MultiSelectInput2
                        {...formik.getFieldProps('applicationType')}
                        options={FINISHED_PRODUCTS_APPLICATION_TYPE?.map(option => ({ label: option.label, value: option.value }))}
                        label="Application Type"
                        value={formik.values.applicationType}
                        onchange={handleSelectChange}
                        fullWidth
                        placeholder='Select Application Type'
                        error={formik.touched.applicationType && Boolean(formik.errors.applicationType)}
                        helperText={formik.errors.applicationType as string}
                    />

                </div>
                <div className="mt-6">
                    <TextFieldV2
                        label="Product Description"
                        {...formik.getFieldProps("productDescription")}
                        placeholder="Enter Description"
                        fullWidth
                        error={formik.touched.productDescription && Boolean(formik.errors.productDescription)}
                        helperText={formik.errors?.productDescription}
                    />
                </div>
                <div className="flex justify-end mt-8 gap-x-4">
                        <ButtonV2
                            className={` justify-self-end`}
                            variant="secondaryContained"
                            label="Back to Dashboard"
                            onClick={handleBackToDashboard}
                        />
                    <ButtonV2 label="Back" variant="tertiaryContained" onClick={handleOnBack} />
                    <ButtonV2 type="submit" label="Add" variant="primaryContained" className={classes.addButton} isLoading={isLoading} />
                </div>
            </form>
            <DisplayImageV2 path={selectedPreviewImagePath} imageDialogOpen={imageDialogOpen} setImageDialogOpenTo={setImageDialogOpenTo} />
        </div>
    )
}

export default AddFinishedProductTemplate;