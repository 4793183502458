import React from 'react';
import comingSoonLogo from '../../../assets/images/comingSoonLogo.svg';


const MasterPurchaseOrderTemplate: React.FC = () => {
    return (
      <div className='flex justify-center mb-32 mt-20 items-center'>
            <img src={comingSoonLogo} alt="coming soon logo" style={{ maxWidth: '100%', maxHeight: '100%' }} />
        </div>
    )
}
export default MasterPurchaseOrderTemplate