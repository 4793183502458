import React from "react";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import AddCustomerGroupFormTemplate from "../../templates/CustomerGroupMaster/AddCustomerGroup.template";
import { ICustomerGroupMasterRequestBody, useCustomerGroupMasterService } from "../../../services/useCustomerGroupMasterService";
import { HTTP_STATUS } from "../../../utils/types";
import { ADMIN_ROUTES } from "../../../utils/constant";
import { useSnackbar } from "../../../hooks/useSnackBar";
import sanitize from 'sanitize-html';

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary900,
    },
}));

const CustomerGroupMasterCreatePage: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const customerGroupService = useCustomerGroupMasterService()
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const createCustomerGroupMaster = async (requestBody: any) => {
        const payload: ICustomerGroupMasterRequestBody = {
            groupName: requestBody?.groupName,
            groupOwner: requestBody?.groupOwner,
            productCategoryId: requestBody?.productCategoryId,
            description: requestBody?.description,
            groupType: requestBody?.groupType,
            industryType: requestBody?.industryType,
            customerIds: requestBody?.customerIds,
        };
        try {
            const enquiryResponse = await customerGroupService.createCustomerGroup(payload);
            if (enquiryResponse?.status === HTTP_STATUS.OK) {
                navigate(`${ADMIN_ROUTES.CUSTOMER_GROUP_MASTER_LIST}`);
            }else if(enquiryResponse?.status === 409){
                showSnackbar("error","Duplicate Group, Group name is already taken");
            } else {
                throw new Error("Customer Group Master creation failed");
            }
        } catch (error) {
            showSnackbar("error","Customer Group Master creation failed");
            return false;
        }
    };

    return (
        <div className="grid gap-y-8">
            <div className={` ${classes.mainHeading} text-2xl font-medium`}>Create Customer Group</div>
            <AddCustomerGroupFormTemplate onSubmit={createCustomerGroupMaster} />
        </div>
    );
};

export default CustomerGroupMasterCreatePage;