import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Category, CategoryPayload, useCategoryService } from '../../../services/useCategoryService';
import { createUseStyles } from 'react-jss';
import { DOCUMENT_TYPES, MODE, RESOLUTIONS } from '../../../utils/constant';
import { FormikProps } from 'formik';
import TextField from '@mui/material/TextField';
import FormGroup from "@mui/material/FormGroup";
import Toggler from '../../atoms/Toggler';
import JoditEditor from 'jodit-react';
import Button from '../../atoms/Button';
import { FormControl } from '@mui/material';
import { CATEGORY_TYPE, HTTP_STATUS, PRODUCT_CATEGORY_FORM_STATE } from '../../../utils/types';
import { useSnackbar } from '../../../hooks/useSnackBar';
import AutoCompleteTextField, { AutoCompleteOption } from '../../molecules/AutoCompleteInput/AutoCompleteInput';
import UploadImages from '../../organisms/UploadImages';
import ImageUploader, { ImageData } from '../../organisms/ImageUploader';
import { useFileService } from '../../../services/useFileService';
import * as Yup from "yup";
import { titleModification } from '../../../utils/helper';

export interface ProductCategoryDetailsFormTemplateProps {
  mode: string;
  onClose: () => void;
  formik: FormikProps<CategoryPayload>;
  setFormStateTo: (state: PRODUCT_CATEGORY_FORM_STATE) => () => void;
	image: (ImageData | File)[];
	setImagesTo: (images: any) => void;
  imageIdsToDelete: number[];
  setImageIdsToDelete: (id: number[]) => void;

}

const useStyles = createUseStyles((theme: any) => ({
  textColor: {
    color: theme.palette.text.primaryDarkLight
  },
  border: {
    borderBottom: `1px solid ${theme.palette.border.primaryDark}`
  },
  errorMessage: {
    color: theme.palette.action.danger,
  }
}));

const ProductCategoryDetailsFormTemplate: React.FC<ProductCategoryDetailsFormTemplateProps> = ({ mode, onClose, formik, setFormStateTo ,image, setImagesTo, imageIdsToDelete, setImageIdsToDelete }) => {
  const classes = useStyles();
  const categoryService = useCategoryService();
  const { showSnackbar, SnackBarComponent } = useSnackbar();
  const editor = React.useRef(null);
  const [mainCategoryData, setMainCategoryData] = useState<Category[] | null>(null);
  const [superCategoryData, setSuperCategoryData] = useState<Category[] | null>(null);


  const fileService = useFileService();
  const config = React.useMemo(() => {
    return {
      readonly: mode === MODE.VIEW,
      placeholder: "Start typings...",
    };
  }, []);

  const fetchMainCategory = (inputValue?: string) => {
    if(formik.values.superParentCategory?.id){

      categoryService.getAllCategories({ level: CATEGORY_TYPE.MAIN_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: formik.values.superParentCategory?.id ?? '', name: inputValue ?? '', status: 'ACTIVE' })
        .then(res => {
          if (res.status === HTTP_STATUS.OK)
            setMainCategoryData(res?.data?.data?.content)
        }).catch((error) => {
          console.error("Error Fetching Category: ", error);
          showSnackbar('error', "Error while fetching Super Category data");
        })
    }
  }

  const fetchSuperCategories = (inputValue?: string) => {
    categoryService.getAllCategories({ level: CATEGORY_TYPE.SUPER_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', name: inputValue ?? '', status: 'ACTIVE' })
      .then(res => {
        if (res.status === HTTP_STATUS.OK)
          setSuperCategoryData(res?.data?.data?.content)
      }).catch((error) => {
        console.error("Error Fetching Category: ", error);
        showSnackbar('error', "Error while fetching Super Category data");
      })
  }

  useEffect(() => {
    if (!superCategoryData) {
        fetchSuperCategories();
    }
}, [superCategoryData]);

  useEffect(() => {
    if (formik?.values?.superParentCategory != null) {
      fetchMainCategory();
    }
}, [formik?.values?.superParentCategory]);

  const handleNext = () => {
    formik.setTouched({ superParentCategory: true, parentId: true, name: true, prefix: true, description: true, superParentId: true }, true)
    const errors = Object.keys(formik.errors).find(key => ['superParentCategory', 'parentId', 'superParentId', 'name', 'prefix', 'description'].includes(key))
    if (errors || !image.length)
     return !image.length && showSnackbar('error', 'Please upload image')
    setFormStateTo(PRODUCT_CATEGORY_FORM_STATE.ATTRIBUTE_MANAGEMENT)();
  }

  const updateSelectedCategory = (parentCategory: string[]) => (category: AutoCompleteOption | null) => {
    formik.setFieldValue(parentCategory[0], category);
    parentCategory[1] && formik.setFieldValue(parentCategory[1], category?.id ?? null);

    if(parentCategory[0] === 'superParentCategory'){
      formik.setFieldValue('parentCategory', null)
      formik.setFieldValue('parentId', null)
      setMainCategoryData(null)
    } 
  };

  const superCategoryOptions = useMemo(() => {
    return superCategoryData?.map((superCagtegory: Category) => ({ label: superCagtegory.name, id: superCagtegory.id })) ?? []
  }, [superCategoryData])

  const mainCategoryOptions = useMemo(() => {
    return mainCategoryData?.map((mainCategory: Category) => ({ label: mainCategory.name, id: mainCategory.id })) ?? []
  }, [mainCategoryData])

	const removeImage = useCallback((id: number) => {
		const updatedImages = image.filter((_, currentIndex) => currentIndex !== id);
    const imageData = image[id] as ImageData;
    const imageDeleteIds = [...imageIdsToDelete, imageData.id as number]
    setImageIdsToDelete(imageDeleteIds);
		setImagesTo(updatedImages);
	}, [image]);

	const updatedImages = useCallback((images: any) => {
		setImagesTo((prevImages: any) => [...prevImages, ...images]);
	}, [image]);

	const imageUploaderComponent = useMemo(() => (
		<ImageUploader
			mode={mode}
			images={image}
			removeImage={removeImage}
      isSubmitting = {formik.isSubmitting}
      imageIdsToDelete = {imageIdsToDelete}
      

		/>
	), [mode, image, removeImage, formik.isSubmitting]);

	const uploadImagesComponent = useMemo(() => (
		mode !== MODE.VIEW ? (
			<UploadImages
				title="Upload Images*"
        currentImageCount = {image.length}
				updateImages={updatedImages}
        configuration={{
          maxImageCount: 4,
					maxfilesize: 200,             
					documentTypes: [DOCUMENT_TYPES.IMAGE_JPEG, DOCUMENT_TYPES.IMAGE_PNG],
					documentResolution: RESOLUTIONS.SECONDARY_INVENTORY,
				}}
			/>
		) : null
	), [mode, updatedImages]);

  return (
    <div className='flex flex-col gap-6'>
      {SnackBarComponent}
      <div className='flex gap-4'>
        {((superCategoryData && mode === MODE.ADD) || (superCategoryData)) && <FormControl fullWidth>
          <AutoCompleteTextField
            options={superCategoryOptions}
            label="Select Super Category"
            onChange={updateSelectedCategory(['superParentCategory','superParentId'])}
            onSearch={fetchSuperCategories}
            isDisabled={mode === "VIEW"}
            value={formik.values.superParentCategory }
            error={!!formik.errors.superParentId && formik.touched.superParentId}
          />
          {formik.errors.superParentId && formik.touched.superParentId && (
            <div className={`${classes.errorMessage} text-xs mt-1`}>
              {formik.errors.superParentId}
            </div>
          )}
        </FormControl>}

        {((mode === MODE.ADD) || (mainCategoryData)) && <FormControl fullWidth>
          <AutoCompleteTextField
            options={mainCategoryOptions}
            label="Select Main Category"
            onChange={updateSelectedCategory(['parentCategory', 'parentId'])}
            onSearch={fetchMainCategory}
            isDisabled={mode === "VIEW"}
            value={formik.values.parentCategory}
            error={!!formik.errors.parentId && formik.touched.parentId}
          />
          {formik.errors.parentId && formik.touched.parentId && (
            <div className={`${classes.errorMessage} text-xs mt-1`}>
              {formik.errors.parentId}
            </div>
          )}
        </FormControl>}

      </div>
      <div>
        <FormControl fullWidth>
          <TextField
            {...formik.getFieldProps("name")}
            className={`blank-feild ${mode === MODE.VIEW && 'pointer-events-none'}`}
            id="outlined-basic"
            label="Enter Product Category Name"
            variant="outlined"
            name="name"
            error={formik.touched.name && Boolean(formik.errors.name)}
            inputProps={{
              readOnly: mode === MODE.VIEW,
              maxLength: 100,
            }}
            onBlur={(event) => {
              const newValue = titleModification(event.target.value);
              formik.setFieldValue('name', newValue);
          }}
          />
          {formik.errors.name && formik.touched.name && (
            <div className={`${classes.errorMessage} text-xs mt-1`}>
              {formik.errors.name}
            </div>
          )}
        </FormControl>
      </div>
      <div>
        <FormControl fullWidth>
          <TextField
            {...formik.getFieldProps("prefix")}
            className={`blank-feild ${mode === MODE.VIEW && 'pointer-events-none'}`}
            id="outlined-basic"
            label="Enter Product Category Code"
            variant="outlined"
            name="prefix"
            error={formik.touched.name && Boolean(formik.errors.name)}
            inputProps={{
              readOnly: mode === MODE.VIEW,
              maxLength: 2,
            }}
            onChange={(event: any) => {
              formik.handleChange({
                  target: { name: 'prefix', value: (event.target.value).toUpperCase() },
              });
          }}
            
          />
         {formik.touched && formik.touched?.prefix && formik.errors?.prefix && (
								<div className={classes.errorMessage}>
									<small>{formik.errors?.prefix}</small>
								</div>
							)}
        </FormControl>
       </div>
      <div className={`${mode === MODE.VIEW && 'pointer-events-none'}`}>
        <FormGroup>
          <Toggler
            title="Status"
            currentState={formik.values.status === "ACTIVE"}
            handleToggleChange={(type: any, value: boolean) =>
              formik.setFieldValue("status", value ? "ACTIVE" : "INACTIVE")
            }
            disabled={mode === MODE.VIEW}
          />
        </FormGroup>
      </div>
      <FormControl fullWidth>
        <JoditEditor
          ref={editor}
          value={formik.values.description}
          onBlur={(newContent) => {
            formik.setFieldValue(
              "description",
              newContent
            )
          }
          }
          config={config}
        />
        {formik.errors.description && formik.touched.description && (
          <div className={`${classes.errorMessage} text-xs mt-1`}>
            {formik.errors.description}
          </div>
        )}
      </FormControl>
			{imageUploaderComponent}
			{uploadImagesComponent}
      <div className="flex justify-end gap-4">
        <Button variant="outlined" label='Cancel' onClick={onClose} />
        <Button variant="contained" onClick={handleNext} label={"next"} />
      </div>
    </div>
  )
}

export default ProductCategoryDetailsFormTemplate
