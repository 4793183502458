import React, { useEffect, useState, useCallback,useMemo } from 'react'
import { createUseStyles } from 'react-jss';
import { useNavigate, useParams } from 'react-router-dom';
import { useSecondaryCatalogueService } from '../../../services/useSecondaryCatalogueService';
import { useSecondaryInventoryService } from '../../../services/useSecondaryInventoryService';
import { ADMIN_ROUTES, MODE, RESOURCE_TYPE } from '../../../utils/constant';
import { ISecondaryInventoryResponse } from '../../pages/SecondaryInventory/SecondaryInventoryUpdate.page';
import { formatJoeditData } from '../../atoms/JoeditDiplayFormatter';
import Button from '../../atoms/Button';
import FormGroup from "@mui/material/FormGroup";
import Toggler from "../../atoms/Toggler";
import { useFileService } from '../../../services/useFileService';
import ImageUploader, { ImageData } from '../../organisms/ImageUploader';
import { HTTP_STATUS } from '../../../utils/types';
import { useSnackbar } from '../../../hooks/useSnackBar';


const useStyles = createUseStyles((theme: any) => ({
	heading: {
		color: theme.palette.text.primaryDark,
		fontFamily: "Roboto",
		fontSize: "18px",
		fontStyle: "normal",
		fontWeight: 600,
		lineHeight: "20px",
	},
	subHeading: {
		color: theme.palette.text.secondaryDark,
		fontFamily: "Roboto",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: 400,
		lineHeight: "normal",
	},
	values: {
		color: theme.palette.text.secondaryDark,
		fontFamily: "Roboto",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: 500,
		lineHeight: "normal",
	},
}));

export interface ISecondaryCatalogueResponse {
	id: number;
	secondaryProductId: number;
	catalogueName: string;
	minimumQuantity: number;
	availableQuantity: number;
	application: string;
	defectDetails: string;
	additionalProductDetails: string;
	keywords: string;
	price: {[key: string]: number ;};
	quantity: number;
	gradeId: number;
	packagingType: string;
	termsConditions: string;
	status: string;
	createdAt: string;
	updatedAt: string;
}

const SecondaryCatalogueViewTemplate: React.FC = () => {
	const params = useParams();
	const navigate = useNavigate();
	const classes = useStyles();
	const fileService = useFileService();
	const secondaryCatalogueId = parseInt(params.id ?? '0', 10);
	const secondaryInventoryService = useSecondaryInventoryService();
	const secondaaryCatalogueService = useSecondaryCatalogueService();

	const [secondaryCatalogue, setSecondaryCatalogueTo] = useState<ISecondaryCatalogueResponse>();
	const [secondaryInventory, setSecondaryInventoryTo] = useState<ISecondaryInventoryResponse>();
	const [warehouse, setWarehouseTo] = useState<string | null>(null);
	const [gradeName, setGradeName] = useState<string | null>(null);
	const [productCategoryName, setProductCategoryNameTo] = useState();
	const [mainCategoryName, setMainCategoryNameTo] = useState();
	const [superCategoryName, setSuperCategoryNameTo] = useState();
	const [image, setImagesTo] = useState<(ImageData | File)[]>([]);
	const { showSnackbar, SnackBarComponent } = useSnackbar();


	const handleRedirect = () => {
		navigate(`${ADMIN_ROUTES.SECONDARY_CATALOGUE_LIST}`);
	};

	const getSecondaryCatalogueById = async (secondaryCatalogueId: number) => {
		if (secondaryCatalogueId) {
			try {
				const secondaryCatalogueResponse = await secondaaryCatalogueService.getSecondaryCatalogueById(secondaryCatalogueId);
				if(secondaryCatalogueResponse.status === HTTP_STATUS.OK){
					setSecondaryCatalogueTo(secondaryCatalogueResponse?.data?.data);
					getSecondaryInventoryById(secondaryCatalogueResponse?.data?.data?.secondaryProductId);
				}
				const fileResponse = await fileService.getFilesById(secondaryCatalogueId, RESOURCE_TYPE.SECONDARY_CATALOGUE);
                (fileResponse.status === HTTP_STATUS.OK) && setImagesTo(fileResponse?.data?.data);
			} catch (error) {
				showSnackbar("error", 'Secondary Catalogue fetch failed');
				setImagesTo([]);
			}
		}
	};

	const getSecondaryInventoryById = (secondaryId: number) => {
		if (secondaryId) {
			secondaryInventoryService.getSecondaryInventoryById(secondaryId)
				.then((response) => {
					setSecondaryInventoryTo(response.data.data);
					getWarehouseById(response.data.data.warehouseId);
					getGradeById(response.data.data.gradeId);
					getProductCategory(response.data.data.productCategoryId);
				})
				.catch((error) => {
					console.error("Error fetching warehouse details ", error);
				});
		}
	};

	const getWarehouseById = (warehouseId: number) => {
		if (warehouseId) {
			secondaryInventoryService.getWarehouseById(warehouseId)
				.then((response) => {
					setWarehouseTo(response?.data?.name);
				})
				.catch((error) => {
					console.error("Error fetching warehouse details ", error);
					setWarehouseTo(null);
				});
		}
	};

	const getGradeById = (gradeId: number) => {
		if (gradeId) {
			secondaryInventoryService.getGradeById(gradeId)
				.then((response) => {
					setGradeName(response?.data?.data?.name);
				})
				.catch((error) => {
					console.error("Error fetching warehouse details ", error);
					setGradeName(null);
				});
		}
	}

	const getProductCategory = (produtCategoryId: number) => {
		if (produtCategoryId) {
			secondaryInventoryService.getCategoryById(produtCategoryId)
				.then((response) => {
					setProductCategoryNameTo(response.data.data.name);
					getMainCategory(response.data.data.parentId);
				})
				.catch((error) => {
					console.error("Error fetching warehouse details ", error);

				});
		}
	}

	const getMainCategory = (mainCategoryId: number) => {
		if (mainCategoryId) {
			secondaryInventoryService.getCategoryById(mainCategoryId)
				.then((response) => {
					setMainCategoryNameTo(response.data.data.name);
					getSuperCategory(response.data.data.parentId);
				})
				.catch((error) => {
					console.error("Error fetching warehouse details ", error);
				});
		}
	}

	const getSuperCategory = (superCategoryId: number) => {
		if (superCategoryId) {
			secondaryInventoryService.getCategoryById(superCategoryId)
				.then((response) => {
					setSuperCategoryNameTo(response.data.data.name);
				})
				.catch((error) => {
					console.error("Error fetching warehouse details ", error);
				});
		}
	}


	const removeExistingAddedImage = (id: number) => {
		fileService.deleteFile(id)
			.then((response) => {
			})
			.catch((error) => {
				console.error("Error in delete images", error);
			});
	}

	const removeImage = useCallback((id: number) => {
		if ('id' in image[id]) {
			const imageData = image[id] as ImageData;
			if (imageData.id) {
				removeExistingAddedImage(imageData.id);
			}
		}
		const updatedImages = image.filter((_, currentIndex) => currentIndex !== id);
		setImagesTo(updatedImages);
	}, [image]);

	const imageUploaderComponent = useMemo(() => (
		<ImageUploader
			mode={MODE.VIEW}
			images={image}
		/>
	), [image, removeImage]);

	useEffect(() => {
		getSecondaryCatalogueById(secondaryCatalogueId);
	}, [secondaryCatalogueId]);
	return (
		<div className="search-upc-container1  mt-4 m-auto">
			<div className="grid gap-y-3 mx-6 pb-6">

				<div className="grid gap-y-4">
					<div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
						<div className={classes.heading}>{secondaryInventory?.skuCode}</div>
						<div className={classes.heading}>Category</div>
						<div className="flex gap-x-6">
							<div className="grid gap-y-1.5">
								<div className={classes.subHeading}>Super Category</div>
								<div className={classes.values}>{superCategoryName}</div>
							</div>
							<div className="grid gap-y-1.5">
								<div className={classes.subHeading}>Main Category</div>
								<div className={classes.values}>{mainCategoryName}</div>
							</div>
							<div className="grid gap-y-1.5">
								<div className={classes.subHeading}>Product Category</div>
								<div className={classes.values}>{productCategoryName}</div>
							</div>
						</div>

						<div className={classes.heading}>Product Standard</div>
						<div className="flex gap-x-6">
							<div className="grid gap-y-1.5">
								<div className={classes.subHeading}>Grade</div>
								<div className={classes.values}>{gradeName}</div>
							</div>
							<div className="grid gap-y-1.5">
								<div className={classes.subHeading}>Shape</div>
								<div className={classes.values}>{secondaryInventory?.shape}</div>
							</div>
							<div className="grid gap-y-1.5">
								<div className={classes.subHeading}>Class</div>
								<div className={classes.values}>Secondary</div>
							</div>
						</div>

						<div className={classes.heading}>General Info</div>
						<div className="flex gap-x-6">
							{/* <DisplayProductGeneralizationDetails productSpecificationDetails={ selectedUpc?.attributes }/> */}
						</div>

						<div className={classes.heading}>Defect Details</div>
						<div className="flex gap-x-6">
							<div className="grid gap-y-1.5">
								<div className={classes.subHeading}>{secondaryInventory?.defectDetails ? formatJoeditData(secondaryInventory?.defectDetails) : 'No Data Found'}</div>
							</div>
						</div>

						<div className={classes.heading}>warehouse</div>
						<div className="flex gap-x-6">
							<div className="grid gap-y-1.5">
								<div className={classes.subHeading}>Warehouse</div>
								<div className={classes.values}>{warehouse}</div>
							</div>
							<div className="grid gap-y-1.5">
								<div className={classes.subHeading}>Warehouse Placement</div>
								<div className={classes.values}>{secondaryInventory?.warehousePlacement}</div>
							</div>
						</div>
						<div className="flex gap-x-6">
							<div className="grid gap-y-1.5">
								<div className={classes.subHeading}>Net Weight</div>
								<div className={classes.values}>{secondaryInventory?.netWeight}</div>
							</div>
							<div className="grid gap-y-1.5">
								<div className={classes.subHeading}>Gross Weight</div>
								<div className={classes.values}>{secondaryInventory?.grossWeight}</div>
							</div>
						</div>
						<div className="flex gap-x-6">
							<div className="grid gap-y-1.5">
								<div className={classes.subHeading}>Total Price</div>
								<div className={classes.values}>{new Intl.NumberFormat().format(secondaryInventory?.basePrice! * secondaryInventory?.netWeight!)} Rs</div>
							</div>
						</div>
					</div>

					<div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
						<div className={classes.heading}>Product Information</div>
						<div className="flex gap-x-6">
							<div className="grid gap-y-1.5">
								<div className={classes.subHeading}>Catalogue Name</div>
								<div className={classes.values}>{secondaryCatalogue?.catalogueName}</div>
							</div>
							<div className="grid gap-y-1.5">
								<div className={classes.subHeading}>Minimum order Quantity</div>
								<div className={classes.values}>{secondaryCatalogue?.minimumQuantity}</div>
							</div>
							<div className="grid gap-y-1.5">
								<div className={classes.subHeading}>Maximum Available Quantity</div>
								<div className={classes.values}>{secondaryCatalogue?.availableQuantity}</div>
							</div>
						</div>
					</div>

					<div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
						<div className={classes.heading}>Applications</div>
						<div className="flex gap-x-6">
							<div className="grid gap-y-1.5">
								<div className={classes.subHeading}>{secondaryCatalogue?.application ? formatJoeditData(secondaryCatalogue?.application) : 'No Data Found'}</div>
							</div>
						</div>
					</div>
					<div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
						<div className={classes.heading}>Defects</div>
						<div className="flex gap-x-6">
							<div className="grid gap-y-1.5">
								<div className={classes.subHeading}>{secondaryCatalogue?.defectDetails ? formatJoeditData(secondaryCatalogue?.defectDetails) : 'No Data Found'}</div>
							</div>
						</div>
					</div>
					<div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
						<div className={classes.heading}>Additional Product detail</div>
						<div className="flex gap-x-6">
							<div className="grid gap-y-1.5">
								<div className={classes.subHeading}>{secondaryCatalogue?.additionalProductDetails ? formatJoeditData(secondaryCatalogue?.additionalProductDetails) : 'No Data Found'}</div>
							</div>
						</div>
					</div>
					<div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
						<div className={classes.heading}>Keywords</div>
						<div className="flex gap-x-6">
							<div className="grid gap-y-1.5">
								<div className={classes.subHeading}>{secondaryCatalogue?.keywords ? formatJoeditData(secondaryCatalogue?.keywords) : 'No Data Found'}</div>
							</div>
						</div>
					</div>
					<div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
						<div className={classes.heading}>Pricing</div>
						<div className="flex gap-x-6">
							<div className="grid gap-y-1.5">
								<div className={classes.subHeading}>{secondaryCatalogue?.price && Object.entries(secondaryCatalogue?.price).map(([key, value]) => (<span key={key}> {key}: {value} </span>))}</div>
							</div>
						</div>
					</div>
					<div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
						<div className={classes.heading}>Packaging</div>
						<div className="flex gap-x-6">
							<div className="grid gap-y-1.5">
								<div className={classes.subHeading}>PackagingTypes</div>
								<div className={classes.values}>{secondaryCatalogue?.packagingType ? formatJoeditData(secondaryCatalogue?.packagingType) : 'No Data Found'}</div>
							</div>
						</div>
					</div>
					<div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
						<div className={classes.heading}>Terms and condition</div>
						<div className="flex gap-x-6">
							<div className="grid gap-y-1.5">
								<div className={classes.subHeading}>{secondaryCatalogue?.termsConditions ? formatJoeditData(secondaryCatalogue?.termsConditions) : 'No Data Found'}</div>
							</div>
						</div>
					</div>
				</div>

				<FormGroup>
					<Toggler
						title="Status"
						currentState={secondaryCatalogue?.status === 'ACTIVE' ? true : false}
						handleToggleChange={(type: any, value: string) =>
							console.log('nothing is change')
						}
						disabled={true}
					/>
				</FormGroup>

				<div>
					{imageUploaderComponent}
				</div>
				<div className="flex justify-end w-full m-auto">
					<Button variant="outlined" label="Cancel" onClick={handleRedirect} />
				</div>
			</div>
		</div>
	)
}
export default SecondaryCatalogueViewTemplate