import React from 'react';
import viewFieldPng from '../../Assets/Images/view.svg';
import editFieldPng from '../../Assets/Images/edit.svg';
import deleteFieldPng from '../../Assets/Images/deleteField.png';
import leftArrowPng from '../../Assets/Images/leftArrow.png';
import rightArrowPng from '../../Assets/Images/rightArrow.png';

import Entry from './Entry';
import ImageButton from '../ImageButton/ImageButton';
import Heading from '../Heading/Heading';
export interface TableProps {
  children?: React.ReactNode;
  headings: any[];
  totalPages?: number;
  fields: any[];
  data: any[];
  pageSize: number;
  currentPage: number;
  totalRecords: number;
  inventoryData: any[];
  entries: number;
  className?: string;
  id?: string;
  onClick?: () => void;
  setPageSize?: (event: any) => void;
  setCurrentPage?: (event: any) => void;
  handleBackClick?: (event: any) => void;
  handleView?: (event: any) => void;
  handleEdit?: (event: any) => void;
  handleDelete?: (event: any) => void;
}

// Todo

export const CustomTable: React.FC<TableProps> = (props: TableProps) => {
  const {
    children,
    className,
    id,
    headings,
    totalPages,
    currentPage,
    totalRecords,
    pageSize,
    inventoryData,
    entries,
    setPageSize,
    setCurrentPage,
    handleView,
    handleEdit,
    handleDelete,
    fields,
  } = props;
  return (
    <div className="grid  gap-y-3 mt-1.5 m-auto w-full">
      <div>
        <div className="">
          <Heading headings={headings} />
        </div>
        <Entry
          inventoryData={inventoryData}
          headings={headings}
          fields={fields}
          handleView={handleView}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          viewFieldPng={viewFieldPng}
          editFieldPng={editFieldPng}
          deleteFieldPng={deleteFieldPng}
          curIndex={currentPage * pageSize}
        />
        {/* ---------------------Pagination------------------------------------------ */}
        <div className=" flex gap-x-6 justify-end py-3 mr-3">
          <div>
            <div>
              <label>Rows per page:</label>
              <select
                onChange={(e) => setPageSize && setPageSize(e.target.value)}
                value={pageSize}
              >
                <option value={2}>2</option>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
              </select>
            </div>
          </div>
          <div>
            <p>Page{` ${currentPage + 1} of ${totalPages}`}</p>
          </div>
          <div className="flex ">
            <div className="flex gap-x-6 m-auto">
              <ImageButton
                name="pageBack"
                isDisabled={currentPage === 0}
                onClick={() =>
                  setCurrentPage && setCurrentPage(currentPage - 1)
                }
                className="relative w-[0.31rem] h-[0.81rem] overflow-hidden shrink-0"
                imageSrc={leftArrowPng}
              />
              <ImageButton
                name="pageBack"
                isDisabled={
                  currentPage >= Math.ceil(totalRecords / pageSize - 1)
                }
                onClick={() =>
                  setCurrentPage && setCurrentPage(currentPage + 1)
                }
                className="relative w-[0.31rem] h-[0.81rem] overflow-hidden shrink-0"
                imageSrc={rightArrowPng}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
