import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import { useFormik } from 'formik';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import { useParams } from 'react-router-dom';
import { REGEX } from '../../../utils/constant';
import { HTTP_STATUS } from '../../../utils/types';
import VendorStatutoryDetailTemplate from '../../templates/VendorManagement/statutory/VendorStatutoryDetail.template';
import { useVendorServiceV2 } from '../../../services/useVendorServiceV2';
import VendorBrandCenterTemplate from '../../templates/VendorManagement/BrandCenter/VendorBrandCenter.template';

interface BrandIdDetail {
    brandId: number;
    brandName: string;
}

export interface IVendorBrandCenterForm {
    brandIds : BrandIdDetail[];
    newBrandIds : number[];
}

const initialValues = {
    brandIds : [],
    newBrandIds: [],
}
const VendorBrandCenterPage: React.FC = () => {
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const vendorService = useVendorServiceV2();
    const [brandCenterDetail, setBrandCenterDetailTo] = useState<IVendorBrandCenterForm>(initialValues);
    const params = useParams();
    const id = Number(params.id);

    const getBusinessProfile = async () => {
        try {
            const businessProfileResponse = await vendorService.getVendorMappedBrands(id);
            if (businessProfileResponse.status === HTTP_STATUS.OK) {
                const profileData = businessProfileResponse?.data?.data;
                setBrandCenterDetailTo({
                    brandIds: profileData || [], newBrandIds: []
                });
            }
        } catch (error) {
            showSnackbar('error', 'Business Profile fetching failed');
        }
    };
    const formik = useFormik<IVendorBrandCenterForm>({
        initialValues,
        onSubmit: async () => {
            if (formik.values.newBrandIds.length > 0) {
                const body = { brandIds: formik.values.newBrandIds.map((item: any) => item.value) };
                try {
                    const vendorResponse = await vendorService.updateVendorMappedBrands(id, body);
                    if (vendorResponse?.status === HTTP_STATUS.OK) {
                        formik.resetForm();
                        return true;
                    } else {
                        throw new Error("Updating the Communication preference failed");
                    }
                    
                } catch (error) {
                    showSnackbar("error", `Business profile update  failed ${error}`)
                }
            }
        },
    });

    useEffect(() => {
        formik.setValues({
            ...formik.values,
            ...brandCenterDetail
        })
    }, [brandCenterDetail])

    useEffect(() => {
        getBusinessProfile();
    }, [formik.isSubmitting]);

    return (
        <VendorBrandCenterTemplate formik={formik} />
    )
}
export default VendorBrandCenterPage

