import SelectUpcAttributes from './../../SelectUpcAttributes';

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuthenticatedUser } from '../../../../../hooks/useAuthenticatedUser';
const BaseUrl = process.env.REACT_APP_API_V1_URL;
const CategorySearch = ({
  updateSearchParams,
  searchPayload,
  handleAttributeData,
  defaultText,
}) => {
  const { user } = useAuthenticatedUser();
  const [ productCategories, setProductCategories ] = useState([]);
  const [ categoryOptions, setCategoryOptions ] = useState({
    superCategories: [],
    mainCategories: [],
    productCategories: [],
  });

  const [ selectedCategories, setSelectedCategories ] = useState({
    superCategory: searchPayload?.superCategory ?? null,
    mainCategory: searchPayload?.mainCategory ?? null,
    productCategory: searchPayload?.productCategory ?? null,
  });

  const [ pageInfo, setPageInfo ] = useState({
    superCategory: { pageSize: 10, pageNumber: 1 },
    mainCategory: { pageSize: 10, pageNumber: 1 },
    productCategory: { pageSize: 10, pageNumber: 1 },
  });


  const updatePageInfo = (type, pageNumber, pageSize) => {
    setPageInfo((prevPageInfo) => ({
      ...prevPageInfo,
      [ type ]: {
        ...prevPageInfo[ type ],
        pageNumber,
        pageSize,
      },
    }));
  };

  const updateCategoryOptions = (type, data) => {
    setCategoryOptions((prevState) => ({
      ...prevState,
      [ type ]: data,
    }));
  };

  const updateCategoryInput = (type, event, options) => {
    if (Array.isArray(options)) {
      const foundData = options.find((item) => item.id === event.target.value);
      if (typeof handleAttributeData === 'function') {
        const productAttributes = productCategories.find((productCategory) => {
          return productCategory.id === event.target.value;
        });


        handleAttributeData(productAttributes);
      }

      if (foundData) {
        setSelectedCategories((prevState) => ({
          ...prevState,
          [ type ]: foundData,
        }));


        updateSearchParams(type, foundData);
      }

      if (type === "superCategory") {
        setSelectedCategories((prevState) => ({
          ...prevState,
          [ "mainCategory" ]: { label: '', id: null },
          [ "productCategory" ]: { label: '', id: null }
        }));
        updateSearchParams("mainCategory", { label: '', id: null });
        updateSearchParams("productCategory", { label: '', id: null });
        setCategoryOptions((prev)=>({
          ...prev, ["productCategories"] : []
        }))
      } else if (type === "mainCategory") {
        setSelectedCategories((prevState) => ({
          ...prevState,
          [ "productCategories" ]: { label: '', id: null }
        }))
        updateSearchParams("productCategory", { label: '', id: null });
      }
    }
  };

  const convertToOptions = (data) => {
    if (!Array.isArray(data)) {
      throw new Error('Input data should be an array.');
    }

    return data.map((item) => {
      if (
        !item ||
        typeof item !== 'object' ||
        !item.hasOwnProperty('id') ||
        !item.hasOwnProperty('name')
      ) {
        throw new Error('Invalid item in data array.');
      }

      return {
        label: item.name,
        id: item.id,
      };
    });
  };

  useEffect(() => {
    // Fetch super categories
    axios
      .get(`${BaseUrl}/categories?sort=created_at,desc`, {
        params: {
          name: '',
          level: 0,
          parentId: '',
          page: 0,
          size: 100
        },
        headers: {
          Authorization: `${user.token}`,
          "Content-Type": "application/json",
        }
      })
      .then((response) => {
        updateCategoryOptions(
          'superCategories',
          convertToOptions(response.data.data.content)
        );
      })
      .catch((error) => {
        console.error('Error fetching super categories:', error);
      });
  }, []);

  useEffect(() => {
    // Fetch main categories based on selected super category
    if (selectedCategories?.superCategory?.id) {
      axios
        .get(`${BaseUrl}/categories?sort=created_at,desc`, {
          params: {
            name: '',
            level: 1,
            parentId: selectedCategories.superCategory.id,
            page: 0,
            size: 100
          },
          headers: {
            Authorization: `${user.token}`,
            "Content-Type": "application/json",
          }
        })
        .then((response) => {
          updateCategoryOptions(
            'mainCategories',
            convertToOptions(response.data.data.content)
          );
        })
        .catch((error) => {
          console.error('Error fetching main categories:', error);
        });
    }
  }, [ selectedCategories?.superCategory ]);

  useEffect(() => {
    // Fetch product categories based on selected main category
    if (selectedCategories?.mainCategory?.id) {
      axios
        .get(`${BaseUrl}/categories?sort=created_at,desc`, {
          params: {
            name: '',
            level: 2,
            parentId: selectedCategories.mainCategory.id,
            page: 0,
            size: 100
          },
          headers: {
            Authorization: `${user.token}`,
            "Content-Type": "application/json",
          }
        })
        .then((response) => {

          setProductCategories(response.data.data.content);

          updateCategoryOptions(
            'productCategories',
            convertToOptions(response.data.data.content)
          );
        })
        .catch((error) => {
          console.error('Error fetching product categories:', error);
        });
    }
  }, [ selectedCategories?.mainCategory ]);

  return (
    <div className='w-full self-stretch flex flex-row flex-wrap items-start justify-start gap-[12px]'>
      <div className='flex-1 flex flex-col items-center justify-start'>
        <SelectUpcAttributes
          defaultValue={defaultText ? defaultText.superCategory : null}
          label='Super Category'
          value={selectedCategories?.superCategory?.id}
          onChange={(event) =>
            updateCategoryInput(
              'superCategory',
              event,
              categoryOptions.superCategories
            )
          }
          options={categoryOptions.superCategories}
          disabled={false}
        />
      </div>

      <div className='flex-1 flex flex-col items-center justify-start'>
        <SelectUpcAttributes
          defaultValue={defaultText ? defaultText.mainCategory : null}
          label='Main Category'
          value={selectedCategories?.mainCategory?.id}
          onChange={(event) =>
            updateCategoryInput(
              'mainCategory',
              event,
              categoryOptions.mainCategories
            )
          }
          options={categoryOptions.mainCategories}
          disabled={!selectedCategories.superCategory}
        />
      </div>

      <div className='flex-1 flex flex-col items-center justify-start'>
        <SelectUpcAttributes
          defaultValue={defaultText ? defaultText.productCategory : null}
          label='Product Category'
          value={selectedCategories?.productCategory?.id}
          onChange={(event) =>
            updateCategoryInput(
              'productCategory',
              event,
              categoryOptions.productCategories
            )
          }
          options={categoryOptions.productCategories}
          disabled={!selectedCategories.mainCategory}
        />
      </div>
    </div>
  );
};

export default CategorySearch;
