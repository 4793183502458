import { request } from ".";
import { API_METHOD } from '../utils/constant';
import { useAuthenticatedUser } from '../hooks/useAuthenticatedUser';
import { replaceUrlParams } from "../utils/helper";

export const UPC_URLS = {
    GET_PRODUCT_UPC_BRAND_MAPPING_BY_ID: "/product-upc-brands",
  };

  export interface IProductUPCBrandMappingRequest{
    upcId:  number;
    page?: number;
    size?: number;
  }

 export  interface IUpcData {
    id: number ;
    brandName: string;
    upcCode: string;
    brandCode: string;
    masterUpcCode:  string;
    brandId: number;
  }
  

export const useProductUPCBrandMappingService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

    const getProductUPCBrandMapping = (params: IProductUPCBrandMappingRequest) => {
        return request(API_METHOD.POST, UPC_URLS.GET_PRODUCT_UPC_BRAND_MAPPING_BY_ID, authenticatedUser,{}, {params} );
      };

  return {
    getProductUPCBrandMapping
  }
}
