import { createUseStyles } from 'react-jss';

interface DownloadLinkProps {
    icon: string,
    downloadUrl: string,
}

const useStyles = createUseStyles(() => ({
    downloadIconWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
}))

const DownloadLink: React.FC<DownloadLinkProps> = ({ icon, downloadUrl }) => {
    const classes = useStyles();

    const handleClick = () => {
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', '');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className={classes.downloadIconWrapper} onClick={handleClick}>
            <img src={icon} alt="download" />
        </div>
    );
};

export default DownloadLink;
