import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import { useFormik } from 'formik';
import BusinessLocationTemplate from '../../../templates/Dashboard/BusinessLocation.template';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { HTTP_STATUS } from '../../../../utils/types';
import { useBusinessProfileService } from '../../../../services/useBusinessProfileService';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import { useParams } from 'react-router-dom';

export interface IGstnState {
  gstin:string;
  state:string;
}
export interface ILocationGstinForm {
  locations: IGstnState[];
  noOfRows: number|null;
  newLocations: IGstnState[];
}
const initialValues = {
  locations:[],
  noOfRows:null,
  newLocations: []
}
const BusinessLocationPage: React.FC = () => {
  const { showSnackbar, SnackBarComponent } = useSnackbar();
  const { user: authenticatedUser } = useAuthenticatedUser();
  const bussinessProfileService = useBusinessProfileService();
  const [businessProfile, setBusinessProfileTo]= useState();
  const [bussinessLocation, setBussinessLocationTo] = useState<ILocationGstinForm>(initialValues)
  const [isLoading, setIsLoading]= useState<boolean>(false);

  const params = useParams();
  const id = Number(params.id);

  const validationSchema = Yup.object().shape({
    locations: Yup.array().of(
      Yup.object().shape({
        gstin: Yup.string().required('GSTIN is required'),
        state: Yup.string().required('State is required')
      })
    ),
    noOfRows: Yup.number()
        .required('Atleast one no is required')
        .transform(value => (isNaN(value) ? undefined : value)) 
        .test(
            'isValidNumber',
            'Must be a positive number between 1 and 9',
            value => value === undefined || (value > 0 && value < 10)
        ),
    newLocations:Yup.array().of(
      Yup.object().shape({
        gstin: Yup.string()
        .required('GST Number is required')
        .matches(/^[0-9]{2}[A-Z,a-z]{5}[0-9]{4}[A-Z,a-z]{1}[0-9]{1}[A-Z,a-z]{1}[0-9,A-Z,a-z]{1}?$/, 'This GSTIN is not valid'),
        state: Yup.string().required('state is required')
      })
    )
  });
  
  const getBusinessProfile = async () => {
    setIsLoading(false);
    try {
      const businessProfileResponse = await  bussinessProfileService.getBusinessProfile(id);
      if (businessProfileResponse.status === HTTP_STATUS.OK) {
        const profileData = businessProfileResponse?.data?.data;
        setBusinessProfileTo(profileData);
        setBussinessLocationTo({ locations: profileData.locations || [], noOfRows: null, newLocations: [] });
        setIsLoading(true);
      }
    } catch (error) {
      showSnackbar('error', 'Business Profile fetch failed');
    }
};
  const formik = useFormik<ILocationGstinForm>({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const body = [
        ...formik.values.locations,
        ...formik.values.newLocations.map(location => ({ ...location, gstin: location.gstin.toUpperCase() })),
      ];
    try {
     await bussinessProfileService.updateBusinessProfile(id, body, 'LOCATIONS');
     resetForm({ values: formik.values });
    } catch (error) {
      showSnackbar("error", `Business profile update  failed ${error}`)
    }
    },
  });
  
  useEffect(() => {
    formik.setValues({
      ...formik.values,
      ...bussinessLocation
    })
  }, [bussinessLocation])
 
  useEffect(() => {
    getBusinessProfile();
  }, [formik.isSubmitting]);
  return (
     isLoading ? <BusinessLocationTemplate formik={formik}/> : null
  )
}
export default BusinessLocationPage