import React, { useState } from "react";
import DateHeader from "./uitls/DateHeader";
import DateValue from "./uitls/DateValue";
import Days from "./uitls/Days";

interface DatePickerProps {
  minDate?: Date;
  maxDate?: Date;
  value?: Date;
  onChange: (date: Date) => void;
}

// Todo

const CustomDatePicker = ({
  minDate,
  maxDate,
  value,
  onChange,
}: DatePickerProps) => {
  const [currentMonth, setCurrentMonth] = useState<number>(
    new Date().getMonth()
  );
  const [currentYear, setCurrentYear] = useState<number>(
    new Date().getFullYear()
  );

  const handleDate = (value: Date) => {
    onChange(value);
  };

  return (
    <div className="w-[504px] rounded-2xl bg-datePicker-bg p-6">
      <DateValue
        currentYear={currentYear}
        setCurrentYear={setCurrentYear}
        date={value}
      />
      <DateHeader
        monthIndex={currentMonth}
        year={currentYear}
        setYear={(val) => setCurrentYear(val)}
        setMonthIndex={(val) => setCurrentMonth(val)}
      />
      <Days
        currentYear={currentYear}
        currentMonth={currentMonth}
        onSelect={handleDate}
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  );
};

export default CustomDatePicker;
