import React, { useState } from 'react';
import { IMasterUpcs } from '../../pages/UpcListing/UpcListing.page';
import { IPagination } from '../../../utils/types';
import TableV2 from '../../organisms/TableV2';
import { ColumnType } from '../../organisms/Table';
import ResourceStatus from '../../atoms/ResourceStatus/ResourceStatus';
import { enumToString } from '../../../utils/helper';
import ButtonV2 from '../../atoms/ButtonV2';
import MasterBrandListModalTemplate from './MasterbrandListModal.Template';
import MasterUpcListingPage from '../../pages/MasterUPCListingPage/MasterUpcListing.page';
import { useNavigate } from 'react-router-dom';
import FilterChip, { IMultiSelectOption } from '../../molecules/FilterChip/FilterChip';
import ResourceStatusV2 from '../../atoms/ResourceStatus/ResourceStatusV2';

interface IMasterUpcTemplateProps {
    masterUpcs: IMasterUpcs[];
    pagination: IPagination;
    handlePaginationChange: any;
    handleRowsPerPageChange: any;
    refreshMasterUpc: (page: number, size: number) => void;
}

const MasterUpcListTemplate: React.FC<IMasterUpcTemplateProps> = ({ masterUpcs, pagination, handlePaginationChange, handleRowsPerPageChange, refreshMasterUpc }) => {
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [selectedMasterUpcId, setSelectedMasterUpcId] = useState<number>(0);
    const [selectedMasterUpcCode, setSelectedMasterUpcCode] = useState<string>('');
    const [selectedMasterUpcTitle, setSelectedMasterUpcTitle] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedValues, setSelectedValues] = useState<IMultiSelectOption[]>([]);


    const navigate = useNavigate();

    const openBrandDialog = (id: number, upcCode: string, upcTitle: string) => {
        setSelectedMasterUpcId(id);
        setSelectedMasterUpcCode(upcCode);
        setSelectedMasterUpcTitle(upcTitle);
        setDialogOpen(true);
    }


    const Action = (brand: number, id: number, upcCode: string, upcTitle: string) => {
        return (
            <div className="flex justify-center">
                <ButtonV2
                    variant="tertiaryText"
                    onClick={() => openBrandDialog(id, upcCode, upcTitle)}
                    label={brand?.toString()}
                    size="small"
                />
            </div>
        );
    }

    const ActionCode = (upcCode: string, id: number, upcTitle: string) => {

        const handleNavigation = () => {
            navigate(`/upc-listing-page/${id}`);
        };

        return (
            <div className="flex justify-center">
                <ButtonV2
                    variant="tertiaryText"
                    onClick={handleNavigation}
                    label={upcCode}
                    size="small"
                />
            </div>
        );
    };

    const ActionCustomer = (customer: number, id: number, upcCode: string) => {
        const handleNavigation = () => {
            navigate(`/upc-listing-page/${id}`, { state: {tabName: 'CUSTOMER_WITH_UPC' } });
        };

        return (
            <div className="flex justify-center">
                <ButtonV2
                    variant="tertiaryText"
                    onClick={handleNavigation}
                    label={customer.toString()}
                    size="small"
                />
            </div>
        );
    };

    const schema = {
        id: "1",
        title: "",
        pagination: {
            total: pagination.totalRecords,
            currentPage: pagination.currentPage,
            isVisible: true,
            limit: pagination.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange,
        },
        columns: [
            { label: "S No.", key: "upcId", type: "number" as ColumnType, props: { className: '' } },
            { label: "Master UPC", key: "upcCode", type: "string" as ColumnType, props: { className: '' } },
            { label: "UPC Title", key: "upcTitle", type: "string" as ColumnType, props: { className: '' } },
            { label: "No. of Brands", key: "brandUpcCount", type: "number" as ColumnType, props: { className: '' } },
            { label: "Current USN Stocks(MT)", key: "availableStock", type: "number" as ColumnType, props: { className: '' } },
            { label: "Customers", key: "customers", type: "number" as ColumnType, props: { className: '' } },
            { label: "Vendors", key: "vendors", type: "number" as ColumnType, props: { className: '' } },
            { label: "Status", key: "status", component: ({ value }: { value: string }) => <ResourceStatusV2 status={value} />, type: "custom" as ColumnType, props: {} },
        ],
    };

    const records = masterUpcs.map((masterUpc, index) => [
        pagination.currentPage * pagination.pageSize + index + 1,
        ActionCode(masterUpc.upcCode, masterUpc.upcId, masterUpc.upcTitle),
        masterUpc.upcTitle,
        Action(masterUpc.brandUpcCount, masterUpc.upcId, masterUpc.upcCode, masterUpc.upcTitle),
        masterUpc.availableStock,
        ActionCustomer(masterUpc.customers, masterUpc.upcId, masterUpc.upcCode),
        masterUpc.vendors,
        enumToString(masterUpc.status),
    ]);

    return (
        <div>
            <TableV2 schema={schema} records={records} />
            <MasterBrandListModalTemplate
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                selectedMasterUpcId={selectedMasterUpcId}
                selectedMasterUpcCode={selectedMasterUpcCode}
                selectedMasterUpcTitle={selectedMasterUpcTitle}
                refreshMasterUpc={refreshMasterUpc}
                upcPagination={pagination}
            />
        </div>
    )
}
export default MasterUpcListTemplate;
