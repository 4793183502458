import ShapeClassSearch from "./ShapeClassSearch";
const ProductClassificationSectionIndex = ({updateSearchParams, searchPayload , type}) => {
    return (

        <div className="self-stretch bg-whitesmoke flex flex-col p-6 items-start justify-start gap-[18px] text-base text-text-color">
            <div className="relative text-lg leading-[20px] font-semibold text-black">
                Product Classification
            </div>
            <ShapeClassSearch updateSearchParams={updateSearchParams} searchPayload={searchPayload} type={type}/>
        </div>
    )
}
export default ProductClassificationSectionIndex;