import React, { FC } from "react";
import ImageButton from "../ImageButton/ImageButton";
import { formatDate } from "../../Core/Handlers/DateFormat";
interface Field {
  name: string;
}
interface InventoryData {
  [key: string]: any;
}
interface Props {
  inventoryData: InventoryData[];
  headings: string[];
  fields: Field[];
  handleView?: (id: string) => void;
  handleEdit?: (id: string) => void;
  handleDelete?: (id: string) => void;
  viewFieldPng: string;
  editFieldPng: string;
  deleteFieldPng: string;
  curIndex?: number;
}
const getStatusColor = (status: string) => {
  switch (status) {
    case "SUCCESS":
      return "#009F11";
    case "ACTIVE":
      return "#009F11";
    case "FAIL":
      return "#F04438";
    case "INACTIVE":
      return "#F04438";
    case "ONGOING":
      return "#525252";
    default:
      return "#1D3679";
  }
};
const Entry: FC<Props> = ({
  inventoryData,
  headings,
  fields,
  handleView,
  handleEdit,
  handleDelete,
  viewFieldPng,
  editFieldPng,
  deleteFieldPng,
  curIndex = 0,
}) => {
  return (
    <div className="">
      {inventoryData.length > 0 &&
        inventoryData.map((item, index) => (
          <div
            key={index}
            className=" lead-management-tablebody flex gap-x-2 justify-between pt-3 pb-3"
          >
            <div className=" lead-management-tablebody-heading  w-14  ml-3 ">
              {curIndex + index + 1}
            </div>
            {fields.map((field, id) => (
              <div
                key={id}
                className={` lead-management-tablebody-heading
               `}
                style={
                  field.name === "status"
                    ? {
                        color: getStatusColor(item[field.name]),
                        fontFamily: "Roboto",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                      }
                    : field.name === "skuCode"
                    ? { width: "144px" }
                    : field.name === "upcCode"
                    ? { width: "128px" }
                    : field.name === "productName"
                    ? { width: "128px" }
                    : field.name === "netWeight"
                    ? { width: "80px" }
                    : field.name === "warehouseName"
                    ? { width: "112px" }
                    : field.name === "createdAt"
                    ? { width: "144px" }
                    : field.name === "updatedAt"
                    ? { width: "144px" }
                    : field.name === "status"
                    ? { width: "112px" }
                    : field.name === "quantity"
                    ? { width: "80px" }
                    : {}
                }
              >
                {field.name === "createdAt" || field.name === "updatedAt"
                  ? formatDate(item[field.name])
                  : field.name === "netWeight" || field.name === "quantity"
                  ? `${item[field.name].toFixed(3)} MT`
                  : item[field.name]}
              </div>
            ))}
            <div className="lead-management-tablebody-heading w-16 mr-3 flex justify-end">
              <ImageButton
                onClick={() => handleView && handleView(item.id)}
                imageSrc={viewFieldPng}
              />
              <ImageButton
                onClick={() => handleEdit && handleEdit(item.id)}
                imageSrc={editFieldPng}
              />
              {/* <ImageButton
                onClick={() => handleDelete && handleDelete(item.id)}
                imageSrc={deleteFieldPng}
              /> */}
            </div>
          </div>
        ))}
    </div>
  );
};
export default Entry;
