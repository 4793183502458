import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import BusinessAddressTemplate from './BusinessAddress.template';
import SocialMediaLinks from '../../molecules/socialMediaLinks/SocialMediaLinks';
import Twitter from '../../../assets/icons/xMedium.svg';
import Facebook from "../../../assets/icons/facebookMedium.svg";
import Instagram from "../../../assets/icons/instagramMedium.svg";
import LinkedIn from "../../../assets/icons/linkedinMedium.svg";
import Whatsapp from "../../../assets/icons/whatsappMedium.svg"
import { ADMIN_ROUTES, INDUSTRY_STATES, USER_STATUS_OPTIONS } from '../../../utils/constant';
import { convertDate, getProfileDisplayData, useDebounce } from '../../../utils/helper';
import { IBusinessProfile } from '../../pages/CustomerManagement/ProfileOverview.page';
import Status from '../../atoms/Status/Status';
import { useNavigate } from 'react-router-dom';
import ButtonV2 from '../../atoms/ButtonV2';
import { IBusinessDetails } from '../VendorManagement/ProfileOverview.template';

const useStyles = createUseStyles((theme: any) => ({
    section: {
        color: theme.palette.textV2.secondary.secondary800,
    },
    container: {
        border: `1px solid ${theme.palette.border.primarylightAccent}`,
        width: "100%"
    },
    title: {
        color: theme.palette.textV2.tertiary.tertiary925,
        fontSize: "24px"
    },
    value: {
        color: theme.palette.text.primaryLight,
    },
    property: {
        color: theme.palette.text.primaryLight,
    },
    icon: {
        color: theme.palette.button.primaryDark,
        width: "33px",
    },
    websiteUrl: {
        color: "#FD6202"
    }
}));

export interface ProfileOverviewTemplateProps {
    businessProfile: IBusinessProfile;
    updateStatus: () => void;
    statusDetails: { status: string; comments: string };
    setStatusDetailsTo: any;
}

export interface IRegisteredAddress {
    addressLine1: string;
    addressLine2: string;
    pincode: string;
    city: string;
    state: string;
}

export interface Address {
    id: number;
    type: string;
    email: string;
    city: string;
    state: string;
    country: string;
    pincode: string;
    gmapUrl: string;
    line1: string;
    line2: string;
    countryCode: string;
    mobileNumber: string;
    stdCode: string;
    landline: string;
    resourceType: string;
    createdBy: string;
    updatedBy: string;
    resourceId: number;
    createdAt: string;
    updatedAt: string;
}

const ProfileOverviewTemplate: React.FC<ProfileOverviewTemplateProps> = ({ businessProfile, updateStatus, statusDetails, setStatusDetailsTo }) => {

    const classes = useStyles();
    const navigate = useNavigate();

    const [gstRegisteredAddress, setGstRegisteredAddressTo] = useState<IRegisteredAddress | null>(null);
    const [cinRegisteredAddress, setCinRegisteredAddressTo] = useState<IRegisteredAddress | null>(null);
    const [businessDetails, setBusinessDetailsTo] = useState<IBusinessDetails>();

    const USER_DETAILS_SCHEMA = [{ label: "Full Name", value: "fullName" }, { label: "Department", value: "department" }, { label: "Designation", value: "designation" }, { label: "Email Address", value: "email" }, { label: "Phone", value: "mobileNumber" }, { label: "Social Media Link", value: "socialLinks" }];
    const BUSINESS_INFORMATION_SCHEMA = [{ label: "Company Name", value: "name" }, { label: "Year of Establishment", value: "dateOfEstablishment" }, { label: "Number of Employees", value: "totalEmployees" }, { label: "Business Classification", value: "businessType" }, { label: "Entity Type", value: "entityType" }, { label: "Business Type", value: "businessType" }, { label: "Industry Type", value: "industry" }, { label: "Website", value: "websiteUrl" }];

    const getIndustryType = (value: string): string => {
        const industry = INDUSTRY_STATES[value as keyof typeof INDUSTRY_STATES];
        return industry !== undefined ? industry : value;
    }

    const formatSocialLinks = (socialLinks: any) => {
        if (!socialLinks) return null;
        const logoMapping: { [key: string]: string } = {
            Twitter,
            Facebook,
            Instagram,
            LinkedIn,
            Whatsapp
        };

        const socialMediaSchema = Object.keys(socialLinks).map((key) => ({
            key: key,
            link: socialLinks[key] || "",
            logo: logoMapping[key] || `path/to/${key}Logo.png`
        }));
        return <SocialMediaLinks schema={socialMediaSchema} />;
    };

    const handleBackToDashboard = () => {
        navigate(ADMIN_ROUTES.CUSTOMER_MANAGEMENT_LIST);
    };

    useEffect(() => {
        if (businessProfile) {
            setGstRegisteredAddressTo({
                addressLine1: businessProfile?.address?.line1 || "",
                addressLine2: businessProfile?.address?.line2 || "",
                pincode: businessProfile?.address?.pincode || "",
                city: businessProfile?.address?.city || "",
                state: businessProfile?.address?.state || "",
            });
            setCinRegisteredAddressTo({
                addressLine1: businessProfile?.cinRegisteredAddress?.line1 || "",
                addressLine2: businessProfile?.cinRegisteredAddress?.line2 || "",
                pincode: businessProfile?.cinRegisteredAddress?.pincode || "",
                city: businessProfile?.cinRegisteredAddress?.city || "",
                state: businessProfile?.cinRegisteredAddress?.state || "",
            });
            if (businessProfile?.userProfileTypeEntity) {
                const userStatusDetails = {
                    status: businessProfile?.userProfileTypeEntity?.status || "",
                    comments: "",
                };
                setStatusDetailsTo(userStatusDetails);
                const userData = businessProfile?.user;
                setBusinessDetailsTo({
                    fullName: userData?.fullName,
                    department: userData?.department,
                    designation: userData?.designation,
                    email: userData?.email,
                    mobileNumber: userData?.mobileNumber,
                    socialLinks: getProfileDisplayData(businessProfile?.user?.socialLinks) === "No Data" ? getProfileDisplayData(businessProfile?.user?.socialLinks) : formatSocialLinks(businessProfile?.user?.socialLinks),
                    name: businessProfile?.name,
                    dateOfEstablishment: convertDate(businessProfile?.dateOfEstablishment),
                    totalEmployees: businessProfile?.numberOfEmployees,
                    businessClassification: businessProfile?.udhyam?.businessType,
                    entityType: businessProfile?.cin?.entityType || businessProfile?.entityType,
                    businessType: businessProfile?.businessType,
                    industry: getIndustryType(businessProfile?.industry),
                    websiteUrl: getProfileDisplayData(businessProfile.websiteUrl) === "No Data" ? getProfileDisplayData(businessProfile?.websiteUrl) : <a href={businessProfile.websiteUrl} className={classes.websiteUrl} target="_blank" rel="noopener noreferrer">{businessProfile.websiteUrl}</a>,
                });
            }
        }
    }, [businessProfile]);

    return (
        <div className='grid gap-y-6'>
            <div className='grid gap-y-3'>
                <div className={`p-6 grid gap-y-6 rounded-lg ${classes.container}`}>
                    <div className={`${classes.title} text-2xl font-medium`}>User Basic Information</div>
                    <div className='grid grid-cols-3 w-full justify-between gap-y-8 gap-x-9 pt-6 border-t border-gray-300'>
                        {USER_DETAILS_SCHEMA.map((key: any, index: number) => (
                            <div key={key.label}>
                                <div className="my-auto w-5"><img src={key.icon} className="m-auto" alt="" /></div>
                                <div className="grid gap-y-1">
                                    <div className={`${classes.value} font-normal text-sm`}>{key.label}</div>
                                    <div className={`${classes.property} font-medium text-base`}>
                                        <div className="flex gap-x-2">{getProfileDisplayData((businessDetails as any)?.[key.value])}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={`p-6 grid gap-y-6 rounded-lg ${classes.container}`}>
                    <div className={`${classes.title} text-2xl font-medium`}>Business Information</div>
                    <div className='grid grid-cols-4 w-full justify-between gap-y-8 gap-x-9 pt-6 border-t border-gray-300'>
                        {BUSINESS_INFORMATION_SCHEMA.map((key: any, index: number) => (
                            <div key={key.label}>
                                <div className="my-auto w-5"><img src={key.icon} className="m-auto" alt="" /></div>
                                <div className="grid gap-y-1">
                                    <div className={`${classes.value} font-normal text-sm`}>{key.label}</div>
                                    <div className={`${classes.property} font-medium text-base`}>
                                        <div className="flex gap-x-2">{getProfileDisplayData((businessDetails as any)?.[key.value])}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='w-full grid gap-y-6'>
                        <div className={`${classes.title} text-2xl font-medium`}>Business Places</div>
                        <div className='flex gap-x-6 w-full pt-6 border-t border-gray-300'>
                            <div className='w-1/2'>
                                {cinRegisteredAddress && <BusinessAddressTemplate title="Registered Office Address" address={cinRegisteredAddress} />}
                            </div>
                            <div className='w-1/2'>
                                {gstRegisteredAddress && <BusinessAddressTemplate title="Principle Office Address (as per GST)" address={gstRegisteredAddress} />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`p-4 grid gap-y-6 rounded-lg ${classes.container}`}>
                    <div className={`${classes.title} text-2xl font-medium`}>About Us</div>
                    <div className={`border-t-neutral-400 ${classes.property} font-medium text-base`}>{getProfileDisplayData(businessProfile?.description)}</div>
                </div>
                <div className={`p-4 grid gap-y-6 rounded-lg ${classes.container}`}>
                    <div className={`${classes.title} text-2xl font-medium`}>Select Status</div>
                    <div className={`border-t-neutral-400 ${classes.property} font-medium text-base`}><Status name="Status" options={USER_STATUS_OPTIONS} onSubmit={updateStatus} details={statusDetails} setDetailsTo={setStatusDetailsTo} initialStatusValue={businessProfile?.userProfileTypeEntity?.status} /></div>
                </div>
            </div>
            <div className='flex justify-end'>
                <ButtonV2
                    className={` justify-self-end`}
                    variant="secondaryContained"
                    label="Back to Dashboard"
                    onClick={handleBackToDashboard}
                />
            </div>
        </div>
    )
}

export default ProfileOverviewTemplate;
