import moment from "moment";
import React, { useState } from "react";

const dayNames = ["S", "M", "T", "W", "T", "F", "S"];

interface DaysProps {
  currentYear: number;
  currentMonth: number;
  onSelect: (value: Date) => void;
  date?: Date;
  minDate?: Date;
  maxDate?: Date;
}

// Todo

const Days = ({
  currentYear,
  currentMonth,
  onSelect,
  date,
  minDate,
  maxDate,
}: DaysProps) => {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(date);

  const getNumberOfDaysInMonth = (year: number, month: number) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getStartDayIndex = (year: number, month: number) => {
    return new Date(year, month).getDay();
  };

  const dateRange = (start: number, end: number) => {
    const length = Math.abs(end - start);
    return Array.from({ length }, (_, i) => start + i);
  };

  const startDayIndex = getStartDayIndex(currentYear, currentMonth);
  const daysInMonth = getNumberOfDaysInMonth(currentYear, currentMonth);
  const minDateUnix = minDate ? moment(minDate).startOf("day").unix() : null;
  const maxDateUnix = maxDate ? moment(maxDate).endOf("day").unix() : null;
  const todayUnix = moment().startOf("day").unix();
  const firstDayOffset = (startDayIndex + 7) % 7;

  const handleSelectDate = (date: number) => {
    const newDate = new Date(currentYear, currentMonth, date);
    setSelectedDate(newDate);
    onSelect(newDate);
  };

  return (
    <>
      <div className="grid grid-cols-7 gap-x-10 gap-y-5 w-full">
        {dayNames.map((day, index) => (
          <span
            key={index}
            className="text-center font-medium text-[#808080] w-7 h-7 text-[20px] drop-shadow-day"
          >
            {day}
          </span>
        ))}
        {dateRange(1 - firstDayOffset, daysInMonth + 1).map((date, index) => {
          const isInCurrentMonth = date > 0 && date <= daysInMonth;
          const day = moment(new Date(currentYear, currentMonth, date)).unix();
          const isDisabled =
            (minDateUnix && day < minDateUnix) ||
            (maxDateUnix && day > maxDateUnix);
          const isSelected = day === moment(selectedDate).unix();
          const isToday = day === todayUnix;
          return (
            <span
              key={index}
              onClick={() => !isDisabled && handleSelectDate(date)}
              className={`cursor-pointer w-7 h-7 flex items-center justify-center ${
                isToday ? "border border-red-500 rounded-full" : ""
              } ${
                isSelected ? "bg-blue rounded-full text-datePicker-bg" : ""
              } ${isDisabled ? "text-[#808080]" : "text-[#101010]"}`}
            >
              <span className="font-normal text-sm drop-shadow-d">
                {isInCurrentMonth ? date : ""}
              </span>
            </span>
          );
        })}
      </div>
    </>
  );
};

export default Days;
