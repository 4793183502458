

import SelectUpcAttributes from '../../SelectUpcAttributes';

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuthenticatedUser } from '../../../../../hooks/useAuthenticatedUser';
const BaseUrl = process.env.REACT_APP_API_V1_URL;

const StandardGradeSearch = ({ updateSearchParams, searchPayload }) => {
  const { user } = useAuthenticatedUser();
  const [categoryOptions, setCategoryOptions] = useState({
    standards: [],
    grades: []
  });

  const [selectedCategories, setSelectedCategories] = useState({
    standard: searchPayload?.standard ?? null,
    grade: searchPayload?.grade ?? null,
    productCategory : searchPayload?.productCategory ?? null,
  });

  const updateCategoryOptions = (type, data) => {
    setCategoryOptions(prevState => ({
      ...prevState,
      [type]: data,
    }));
  };

  const updateCategoryInput = (type, event, options) => {

    if (Array.isArray(options)) {
      const foundData = options.find(item => item.id === event.target.value);
      if (foundData) {
        setSelectedCategories(prevState => ({
          ...prevState,
          [type]: foundData
        }));
        if(type === "grade"){
          updateSearchParams("grade", foundData);
        }
        if(type === "standard"){
          updateSearchParams("standard", foundData);
        }
      }
    }
  };

  const convertToOptions = (data) => {
    if (!Array.isArray(data)) {
      throw new Error('Input data should be an array.');
    }

    return data.map(item => {
      if (!item || typeof item !== 'object' || !item.hasOwnProperty('id') || !item.hasOwnProperty('name')) {
        throw new Error('Invalid item in data array.');
      }

      return {
        label: item.name,
        id: item.id
      };
    });
  };

  // useEffect(() => {

  //   axios.get(`${BaseUrl}/standards`, {
  //     params: {
  //       search: '',
  //       sort:'createdAt,desc',
  //         page:0,
  //         size:100
  //     },
  //   }).then(response => {
  //     updateCategoryOptions('standards', convertToOptions(response.data.data.content));
  //   })
  //     .catch(error => {
  //       console.error('Error fetching super categories:', error);
  //     });
  // }, []);


  useEffect(() => {
    if (searchPayload?.productCategory?.id) {
      const categoryId = searchPayload.productCategory.id;
  
      // If selectedCategories.productCategory.id is present, call API1
      axios
        .get(`${BaseUrl}/category/${categoryId}`,       {headers: {
          Authorization: `${user.token}`,
          "Content-Type": "application/json",
        }})
        .then((response) => {
          // Handle the response and set the details accordingly
          // Example: setDetails(response.data);
          // console.log(
          //   "Standard Details from category - 1 ",
          //   response?.data?.data?.standardDetails
          // );
          updateCategoryOptions(
            'standards',
            convertToOptions(response?.data?.data?.standardDetails)
          );
        })
        .catch((error) => {
          console.error('Error fetching details from API1:', error);
        });
    } else {
      // If selectedCategories.productCategory.id is not set, call the original API
      axios
        .get(`${BaseUrl}/standards`, {
          params: {
            search: '',
            sort: 'createdAt,desc',
            page: 0,
            size: 100,
          },
          headers: {
            Authorization: `${user.token}`,
            "Content-Type": "application/json",
          }
        })
        .then((response) => {
          // console.log(
          //   "Standard Details  - ",
          //   response?.data?.data?.content
          // );
          updateCategoryOptions(
            'standards',
            convertToOptions(response?.data?.data?.content)
          );
        })
        .catch((error) => {
          console.error('Error fetching super categories:', error);
        });
    }
  }, [searchPayload]); // Include selectedCategories in the dependency array
  
  

  useEffect(() => {

    if (selectedCategories?.standard?.id) {
      axios.get(`${BaseUrl}/quality-grades`, {
        params: {
          search: '',
          type: 'GRADE',
          standardId: selectedCategories.standard.id,
          sort:'createdAt,desc',
          page:0,
          size:100
        },
        headers: {
          Authorization: `${user.token}`,
          "Content-Type": "application/json",
        }
      })
        .then(response => {
          updateCategoryOptions('grades', convertToOptions(response.data.data.content));
        })
        .catch(error => {
          console.error('Error fetching main categories:', error);
        });
    }
  }, [selectedCategories?.standard]);



  return (
    <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[12px]">
      <div className="flex-1 flex flex-col items-center justify-start">
        <SelectUpcAttributes
          label="Standard"
          value={selectedCategories?.standard?.id}
          onChange={event => updateCategoryInput('standard', event, categoryOptions.standards)}
          options={categoryOptions.standards}
        // disabled={false} 
        />
      </div>


      <div className="flex-1 flex flex-col items-center justify-start">
        <SelectUpcAttributes
          label="Grade"
          value={selectedCategories?.grade?.id}
          onChange={event => updateCategoryInput('grade', event, categoryOptions.grades)}
          options={categoryOptions.grades}
          disabled={!selectedCategories.standard}
        />
      </div>
    </div>
  )
};

export default StandardGradeSearch;
