import { ReactNode } from "react";

interface CardType {
  title: string;
  value?: string;
  border?: boolean;
  size: string;
  children?: ReactNode;
}

const Card: React.FC<CardType> = ({ title, value, border, size, children }) => {
  const small = { title: "text-xs", value: "text-sm font-medium" };
  const large = { title: "text-sm", value: "text-lg font-medium" };

  return (
    <div
      className={`${
        border ? "border bg-white border-cgray-6 rounded-lg p-4" : ""
      }`}
    >
      <div
        className={`text-cgray-1 ${
          size === "large" ? large.title : small.title
        }`}
      >
        {title}
      </div>
      {value && (
        <div
          className={`text-black ${
            size === "large" ? large.value : small.value
          }`}
        >
          {value}
        </div>
      )}
      {children}
    </div>
  );
};

export default Card;
