import { useContext, useEffect, useState } from 'react';
import { CustomButton } from '../../../Shared/Button/CustomButton';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  PRParamsType,
  PurchaseRequisitionListType,
} from '../../../Constants/Procurement/PurchaseRequisition/types';
import {
  PROCUREMENT_TEXT,
  PurchaseRequisitionFields,
  PurchaseRequisitionTableHeadings,
  RequitionTabs,
  StatusType,
} from '../../../Constants/Procurement/PurchaseRequisition/constants';
import CustomTab from '../../../Shared/CustomTabs/CustomTab';
import TableFilters from '../../../Components/Procurement/Indent/TableFilters';
import CustomTable from '../../../Shared/CustomTable/Procurement/CustomTable';

import { usePRContext } from '../../../../hooks/useProcurementContext';
import { NavigationURL } from '../../../Constants/EndPoints';
import NoDataFound from '../../../../components/atoms/NoDataFound/NoDataFound';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import { WareHouseContext } from '../../../../contexts/WareHouseDetailContext';
import { usePurchaseRequisition } from '../../../../services/usePurchaseRequisition';

export const PurchaseRequisition = () => {
  const { user }: any = useAuthenticatedUser();
  const { warehouseDetails } = useContext(WareHouseContext);
  const navigate = useNavigate();
  const { PRParams, setPRParams } = usePRContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [prid, setPrid] = useState(PRParams.prIDs);
  const [status, setStatus] = useState<string>(PRParams.statuses);
  const requisitionTabNumber = PRParams.isUserPRs
    ? 1
    : PRParams.isUserDrafts
    ? 2
    : 0;
  const [requitionTab, setRequisitionTab] =
    useState<number>(requisitionTabNumber);
  const [purchaseRequisitionList, setPurchaseRequisitionList] = useState<
    PurchaseRequisitionListType[]
  >([]);
  const [currentPage, setCurrentPage] = useState<number>(PRParams.pageNumber);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);

  const [fromDate, setFromDate] = useState(moment.unix(PRParams.fromDate));
  const [toDate, setToDate] = useState(moment.unix(PRParams.toDate));

  const { getPurchaseRequistions } = usePurchaseRequisition();
  const fetchRequisitionListData = async (
    params: PRParamsType
  ): Promise<void> => {
    setIsLoading(true);
    setPRParams(params);
    params = {
      ...params,
      ...{
        statuses: params.statuses === 'All' ? '' : params.statuses,
      },
    };
    try {
      const response = await getPurchaseRequistions(params);

      const { content, totalElements, totalPages, pageable } =
        response?.data.data;
      setTotalPages(totalPages);
      const PRs = content.map((PR: PurchaseRequisitionListType) => {
        return {
          ...PR,
          status:
            StatusType.find((status) => status.value === PR.status)?.name || '',
          consigneeLocation: warehouseDetails.find(
            (warehouse) => warehouse.id === PR.warehouseId
          )?.name,
        };
      });

      setPurchaseRequisitionList(PRs);
      setTotalItems(totalElements);
      setCurrentPage(pageable.pageNumber);
    } catch (err) {
      console.log('Purchase Requisition List Error - ', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let params = PRParams;
    if (
      warehouseDetails &&
      (PRParams.fromDate !== fromDate.startOf('day').unix() ||
        PRParams.toDate !== toDate.endOf('day').unix())
    ) {
      params = {
        ...PRParams,
        pageNumber: 0,
        fromDate: fromDate.startOf('day').unix(),
        toDate: toDate.endOf('day').unix(),
      };
    }
    fetchRequisitionListData(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toDate, fromDate, warehouseDetails]);

  const createPurchaseRequisition = () => {
    navigate(NavigationURL.createPurchaseRequisition);
  };
  let timeout: NodeJS.Timeout;
  const searchHandler = (value: string) => {
    setPrid(value);
    setCurrentPage(0);
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      const params = {
        ...PRParams,
        ...{
          pageNumber: 0,
          prIDs: value,
        },
      };
      fetchRequisitionListData(params);
    }, 500);
  };

  const statusChangeHandler = (value: string) => {
    if (value === status) return;
    setCurrentPage(0);
    setStatus(value);
    const params = {
      ...PRParams,
      ...{
        pageNumber: 0,
        statuses: value,
      },
    };
    fetchRequisitionListData(params);
  };

  const handleTabChange = (tab: number) => {
    const params = {
      ...PRParams,
      pageNumber: 0,
      isUserPRs: tab === 1,
      isUserDrafts: tab === 2,
    };
    setRequisitionTab(tab);
    setCurrentPage(0);
    fetchRequisitionListData(params);
  };
  const handlePageChange = (page: number) => {
    const params = {
      ...PRParams,
      pageNumber: page,
    };
    setCurrentPage(page);
    fetchRequisitionListData(params);
  };

  return (
    <div className="grid gap-y-3">
      <div className="flex justify-between mt-2">
        <CustomTab
          Tabs={RequitionTabs}
          tab={requitionTab}
          setTab={(tab) => handleTabChange(tab)}
        />
        <div>
          <CustomButton variant="Primary" onClick={createPurchaseRequisition}>
            + Create new Purchase Requisition
          </CustomButton>
        </div>
      </div>
      <TableFilters
        selectLabel={'Status'}
        searchHandler={searchHandler}
        fromDateHandler={setFromDate}
        toDateHandler={setToDate}
        statusList={StatusType}
        statusChange={statusChangeHandler}
        searchText={prid}
        status={status}
        toDate={toDate}
        fromDate={fromDate}
      />
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div>
          {purchaseRequisitionList.length <= 0 ? (
            <div className="py-32">
              <NoDataFound />
            </div>
          ) : (
            <CustomTable<PurchaseRequisitionListType>
              totalPages={totalPages}
              currentPage={currentPage}
              totalRecords={totalItems}
              headings={PurchaseRequisitionTableHeadings}
              fields={PurchaseRequisitionFields}
              itemList={purchaseRequisitionList}
              paginationText={PROCUREMENT_TEXT.paginationText}
              setCurrentPage={(page) => handlePageChange(page)}
            />
          )}
        </div>
      )}
    </div>
  );
};
