import { AUCTION } from '../../../Constants/Procurement/LiveAuction/text';
import {
  Bidder,
  LiveAuctionBid,
  LiveAuctionBidTableFields,
  LiveAuctionBidTableHeadings,
} from '../../../Constants/Procurement/LiveAuction/constants';
import TableEntries from '../../../Shared/CustomTable/Procurement/TableEntries';
import { useEffect, useState } from 'react';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import { useLiveAuctionService } from '../../../../services/useLiveAuctionService';

interface Props {
  auctionId: string | undefined;
  live: boolean;
  hasEnded: boolean;
  setCurrentBidder: (bidder: Bidder) => void;
}
let interval: NodeJS.Timeout;
const timer = 10000;

const BidDetails = ({ auctionId, setCurrentBidder, live, hasEnded }: Props) => {
  const { token }: any = useAuthenticatedUser().user;
  const [bids, setBids] = useState<LiveAuctionBid[]>([]);
  const { fetchBids: requestBids } = useLiveAuctionService();
  useEffect(() => {
    fetchBids();
    if (hasEnded) {
      clearInterval(interval);
      return;
    }
    if (live) {
      if (interval) clearInterval(interval);
      interval = setInterval(() => {
        fetchBids();
      }, timer);
    }
    return () => clearInterval(interval);
  }, [live, hasEnded]);

  const fetchBids = () => {
    requestBids(auctionId!)
      .then((response) => {
        const { content, totalElements, totalPages } = response?.data.data;
        setBids(content);
        setCurrentBidder(content[0]);
      })
      .catch((err) => {
        console.log('BidList Error - ', err);
      });
  };

  return (
    <div>
      <div className="text-text-color font-semibold text-2xl py-3 border-b border-cgray-3 my-4">
        {AUCTION.BidDetails}
      </div>
      <div>
        {bids.length > 0 ? (
          <TableEntries
            itemList={bids}
            fields={LiveAuctionBidTableFields}
            headings={LiveAuctionBidTableHeadings}
          />
        ) : (
          <div className="flex justify-center items-center h-40">
            No Bids Found
          </div>
        )}
      </div>
    </div>
  );
};

export default BidDetails;
