import React, { useMemo, useState } from "react";
import MuiButton, { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { createUseStyles, useTheme } from "react-jss";

type CustomVariant = "primaryContained" | "secondaryContained" | "tertiaryContained" | "primaryText" | "secondaryText" | "contained"| 'underlined' | "tertiaryText";
type CustomSize = "small" | "medium" | "large";

const useStyles = createUseStyles({ 
    iconButton: {
        color: 'white', 
        width: 28,
        borderRadius: "45px",
        height: "40px",
        alignContent:'center',
        paddingLeft:'5px'
    },
    iconWithText: {
        color: 'white', 
        borderRadius: "45px",
        alignContent:'center',
    },
    root:{
        minWidth: "auto",
        padding: "0px",
        lineHeight: "1 !important",
        "&:hover": {
            backgroundColor: "none",
        },
    },
    underlined: {
        color: ({ theme }: any) => theme.palette.text.secondaryDark,
        textDecoration: "underline"
    },
    contained: {
        color: ({ theme }: any) => theme.palette.buttonV2.primaryContained.text.primaryLight,
        borderRadius: "10px",
        height: "48px",
        backgroundColor: ({ theme }: any) => theme.palette.buttonV2.primaryContained.background.primaryLight,
        "&:hover": {
            backgroundColor: ({ theme }: any) => theme.palette.buttonV2.primaryContained.background.primaryLightAccent,
        },
        "&:disabled": {
            color: ({ theme }: any) => theme.palette.buttonV2.secondaryContained.text.secondaryLight,
            backgroundColor: ({ theme }: any) =>  theme.palette.buttonV2.secondaryContained.background.secondaryLight,
        },
    },
    primaryContained: {
        color: 'white',
        borderRadius: "10px",
        backgroundColor: ({ theme }: any) => theme.palette.buttonV2.primaryContained.background.primaryLight,
        "&:hover": {
             backgroundColor: ({ theme }: any) => theme.palette.buttonV2.primaryContained.background.primaryLightAccent,
        },
        "&:disabled": {
            color: ({ theme }: any) => theme.palette.buttonV2.primaryContained.text.secondaryLight,
            backgroundColor:({ theme }: any) => theme.palette.buttonV2.primaryContained.background.secondaryLight,
        },
        '&:active': {
            backgroundColor:({ theme }: any) => theme.palette.buttonV2.primaryContained.background.primaryDark,
          }, 
    },
    secondaryContained: {
        color: ({ theme }: any) => theme.palette.buttonV2.secondaryContained.text.primaryLight,
        borderRadius: "10px",
        backgroundColor: ({ theme }: any) => theme.palette.buttonV2.secondaryContained.background.primaryLight,
        "&:hover": {
            color: ({ theme }: any) => theme.palette.buttonV2.secondaryContained.text.primaryLightAccent,
            backgroundColor: ({ theme }: any) => theme.palette.buttonV2.secondaryContained.background.primaryLightAccent,
        },
        "&:disabled": {
            color: ({ theme }: any) => theme.palette.buttonV2.secondaryContained.text.secondaryLight,
            backgroundColor:({ theme }: any) => theme.palette.buttonV2.secondaryContained.background.secondaryLight,
        },
        '&:focus': {
            border: ({ theme }: any) => `2px solid ${theme.palette.buttonV2.secondaryContained.border.primaryLight}`
        },
        '&:active': {
            backgroundColor:({ theme }: any) => theme.palette.buttonV2.secondaryContained.background.primaryDark,
          },
    },
    tertiaryContained: {
        color: ({ theme }: any) => theme.palette.buttonV2.tertiaryContained.text.primaryLight,
        borderRadius: "10px",
        backgroundColor: "white",
        border:  ({ theme }: any) => `1px solid ${theme.palette.buttonV2.tertiaryContained.border.primaryLight}`,
        "&:hover": {
            backgroundColor: ({ theme }: any) => theme.palette.buttonV2.tertiaryContained.background.primaryLight,
        },
        "&:disabled": {
            color: ({ theme }: any) => theme.palette.buttonV2.tertiaryContained.text.secondaryLight,
            border:  ({ theme }: any) => `1px solid ${theme.palette.buttonV2.tertiaryContained.border.secondaryLight}`,
        },
        '&:focus': {
            border: ({ theme }: any) => `2px solid ${theme.palette.buttonV2.tertiaryContained.border.primaryDark}`
        },
        '&:active': {
            color: "white",
            backgroundColor:({ theme }: any) => theme.palette.buttonV2.tertiaryContained.background.primaryDark,
          },
    },
    primaryText: {
        color: ({ theme }: any) => theme.palette.buttonV2.primaryText.text.secondaryDark,
        borderRadius: "10px",
        backgroundColor: "white",
        textDecoration: "underline",
        "&:hover": {
            color: ({ theme }: any) => theme.palette.buttonV2.primaryText.text.primaryLight,
        },
        "&:disabled": {
            color: ({ theme }: any) => theme.palette.buttonV2.primaryText.text.secondaryLight,
        },
        '&:focus': {
            color: ({ theme }: any) => theme.palette.buttonV2.primaryText.text.secondaryDark,
            border: ({ theme }: any) => `1px solid ${theme.palette.buttonV2.primaryText.border.primaryLight}`
        },
        '&:active': {
            color: ({ theme }: any) => theme.palette.buttonV2.primaryText.text.primaryDark,
          },
    },
    tertiaryText: {
        color: ({ theme }: any) => theme.palette.buttonV2.primaryText.text.secondaryDark,
        backgroundColor: "white",
        textDecoration: "underline",
        "&:hover": {
            color: ({ theme }: any) => theme.palette.buttonV2.primaryText.text.primaryLight,
            textDecoration: "underline",
            backgroundColor: "white",
        },
        "&:disabled": {
            color: ({ theme }: any) => theme.palette.buttonV2.primaryText.text.secondaryLight,
        }
    },
    secondaryText: {
        color: ({ theme }: any) => theme.palette.buttonV2.secondaryText.text.primaryDark,
        borderRadius: "10px",
        backgroundColor: "white",
        textDecoration: "underline",
        // "&:hover": {
        //     color: ({ theme }: any) => theme.palette.buttonV2.primaryText.text.primaryLight,
        // },
        "&:disabled": {
            color: ({ theme }: any) => theme.palette.buttonV2.secondaryText.text.primaryLight,
        },
        '&:focus': {
            color: ({ theme }: any) => theme.palette.buttonV2.secondaryText.text.primaryDark,
            border: ({ theme }: any) => `1px solid ${theme.palette.buttonV2.secondaryText.border.primaryLight}`
        },
        '&:active': {
            color: ({ theme }: any) => theme.palette.buttonV2.secondaryText.text.secondaryDark,
          },
    },
    label: {
       textTransform: "capitalize",
    },
    small: {
        height: "fit-content",
        fontSize: "14px",
        padding: "10px 16px",
    },
    medium: {   
        height: "fit-content",     
        fontSize: "16px",
        padding: "10px 20px",
    },
    large: {
        height: "fit-content",
        fontSize: "18px",
        padding: "12px 24px",
    },
});

interface ButtonProps extends Omit<MuiButtonProps, "variant"> {
    variant: CustomVariant;
    label?: string | null;
    isLoading?: boolean;
    iconButton?: React.ReactNode;
    size? : CustomSize;
    buttonWithImg? : boolean;
}

const ButtonV2: React.FC<ButtonProps> = ({
    variant,
    label,
    isLoading,
    iconButton,
    size = "large",
    buttonWithImg = false,
    ...props
}) => {
    
    const theme = useTheme<Jss.Theme>();
    const styles = useStyles({ theme });

    const buttonView = useMemo(() => {
        if (buttonWithImg) {
            return (
                <span className="flex items-center">
                    {iconButton && <span className={styles.iconWithText}>{iconButton}</span>}
                    {label && <span className="ml-2">{label}</span>}
                </span>
            );
        }
        return iconButton ? <span className={styles.iconButton}>{iconButton}</span> : label;
    }, [iconButton, label, styles, buttonWithImg]);
    return (
        <MuiButton
            {...props}
            className={`${styles[variant]} ${styles[size]} ${props.className ? props.className : ''} ${styles.label} font-medium`}
            disabled={props.disabled || !!isLoading}
            classes={{ root: styles.root }}
        >
            {isLoading ? <CircularProgress size={20}/> : buttonView}
        </MuiButton>
    );
};
export default ButtonV2;