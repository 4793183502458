import viewEyeIcon from "./../../Assets/Icons/viewEyeIcon.svg";
import editPenIcon from "./../../Assets/Icons/editPenIcon.svg";
import deleteBinIcon from "./../../Assets/Icons/deleteBinIcon.svg";
import rightArrowIcon from "./../../Assets/Icons/rightArrowIcon.svg";
import leftArrowIcon from "./../../Assets/Icons/leftArrowicon.svg";
import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { mapCatalogueStatusEnumToString } from "../../Constants/Enums/CatalogueStatusEnum";
import SecondaryCatalogueTableHeadings from "../SecondaryCatalogue/SecondaryCatalogueTableHeadings";
import SecondaryCatalogueRecord from "../SecondaryCatalogue/SecondaryCatalogueRecord";
import { getKeyByValue } from "../../../utils/helper";
import excelIcon from "../../../assets/icons/excel.svg";
import BulkUploadTemplateDownload from "../../../components/organisms/BulkUpload/BulkUploadTemplateDownload.tsx"
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Typography
} from "@mui/material";
import ShareIcon from '@mui/icons-material/Share';
import CatalogueEnum from "./CatalogueEnum";
import debounce from 'lodash/debounce';
import Checkbox from '@mui/material/Checkbox';
import CatalogueSelection from "./WhatsAppIntegration/Templates/CatalogueSelection";
import FiltersIndex, { FILTERS_TYPE } from "./Filters/FiltersIndex";
import { SHAPES } from "./Filters/CatalogueFilters";
import { formatDate } from "../../Core/Handlers/DateFormat";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { BULK_UPLOAD_TEMPLATE_TYPE, CATALOGUE_TYPES, CLASS_TYPE, RESOURCE_TYPE } from "./../../../utils/constant";
import { useBulkUploadService } from "../../../services/useBulkUploadService";
import BulkUploadTemplate from "../../../components/organisms/BulkUpload/BulkUploadTemplate.tsx";
import uploadIcon from "../../../assets/icons/upload.svg";


const BaseUrl = process.env.REACT_APP_API_V1_URL;
const CatalogueHeader = ({ category, catalogueType, getTemplateFile ,selectedProductCategory}) => {
  const navigate = useNavigate();
  const [resourceType, setResourceType] = useState();

  const navigateToAddCatalogue = () => {
    if (category === "STANDARD" || category === "NONSTANDARD") {
      navigate("/catalogue/add", { state: { category: category, catalogueType: catalogueType } });
    }
    else if (category === "SECONDARY") {
      navigate("/catalogue/secondary-add", { state: { category: category, mode: 'add', catalogueType: catalogueType } })
    }
  };

  let buttonText = `Add ${CatalogueEnum[category]}`;
  if (category === "SECONDARY" && catalogueType === "MAKE_TO_ORDER") {
    buttonText += " Lots";
  }else if(category === "STANDARD" && catalogueType === "MAKE_TO_ORDER") {
    buttonText += " MTO";
  }

  useEffect(() => {
    let resourceType;
    if (category === "SECONDARY" || category === "secondary") {
      if (catalogueType === "MAKE_TO_ORDER" || catalogueType === "makeToOrder") {
        resourceType = RESOURCE_TYPE.SECONDARY_LOT_CATALOGUES;
      } else {
        resourceType = RESOURCE_TYPE.SECONDARY_CATALOGUE;
      }
    } else {
      resourceType = category?.toLowerCase() === CLASS_TYPE.STANDARD ? catalogueType === "CURRENT_INVENTORY" ? CATALOGUE_TYPES.STANDARD_CURRENT_INVENTORY_CATALOGUES : CATALOGUE_TYPES.STANDARD_MAKE_TO_ORDER_CATALOGUES : RESOURCE_TYPE.NONSTANDARD_CATALOGUES;
    }
    setResourceType(resourceType);
  }, [catalogueType, category]);

  return (
    <div className="relative w-full flex flex-row items-center justify-between gap-4 text-left text-2xl text-blue font-roboto">
      <button
        className="rounded bg-customBlue overflow-hidden flex py-2 px-4 items-center justify-center text-lg text-white font-semibold"
        onClick={navigateToAddCatalogue}
      >
        {buttonText} Catalogue
      </button>
      <div className="flex gap-3">
        <BulkUploadTemplateDownload icon={excelIcon} getTemplateFile={getTemplateFile} type={BULK_UPLOAD_TEMPLATE_TYPE.APPLICATION_OCTET_STREAM} productCategory={selectedProductCategory} />
        {resourceType && <BulkUploadTemplate icon={uploadIcon} section={resourceType} />}
      </div>
    </div>
  );
};

const CatalogueTableHeadings = (category) => {
  return (
    <div className="relative bg-blue w-full flex flex-row p-[0.75rem] box-border items-end justify-start gap-[0.75rem] text-left text-[0.88rem] text-white font-roboto">

      <div className="flex-1 flex flex-col items-center justify-start">
        <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">Sr.no</div>
      </div>

      <div className="flex-1 flex flex-col items-start justify-start">
        <div className="relative font-semibold text-left w-full sm:w-[calc(24rem / 1.6)]">Catalogue Name</div>
      </div>

      <div className="flex-1 flex flex-col items-center justify-start">
        <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">{category.category === "STANDARD" ? "No of UPC Code" : "No of MPC Code"}</div>
      </div>
      <div className="flex-1 flex flex-col items-center justify-start">
        <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">Created Date</div>
      </div>
      <div className="flex-1 flex flex-col items-center justify-start">
        <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">Updated Date</div>
      </div>
      <div className="flex-1 flex flex-col items-center justify-start">
        <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">Status</div>
      </div>
      <div className="flex-1 flex flex-col items-center justify-start">
        <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">Action</div>
      </div>
    </div>
  );
};


const CatalogueRecord = ({ catalogueType, catalogueDetails, updateCatalogueDetails, updateSelectedCustomers, selectedCustomers, category, curIndex = 0, enableCatalogueShare, selectedCatalogueName, updateCatalogueName }) => {
  const { user } = useAuthenticatedUser();
  const navigate = useNavigate();

  if (!catalogueDetails) {
    return <div>Loading...</div>;
  }


  const handleAction = (catalogueId, type) => {
    navigate(`/catalogue/${type}`, {
      state: {
        catalogueRecordId: catalogueId,
        category: category,
        catalogueType: catalogueType
      }
    });
  };

  const handleDeleteClick = (catalogueId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Catalogue?"
    );
    if (confirmDelete) {
      var deleteCataloguePayload = { catalogueID: catalogueId };

      axios
        .delete(`${BaseUrl}/catalogue/${catalogueId}`, {
          headers: {
            Authorization: `${user.token}`,
            "Content-Type": "application/json",
          }
        }
        )
        .then((response) => {
          if (response.status === 200) {
            updateCatalogueDetails(
              catalogueDetails.filter((item) => item.id !== catalogueId)
            );
            return;
          }
        })
        .catch((error) => {
          // Handle error here
          console.error("Error deleting item:", error);
        });
    }
  };

  const handleCustomerCheck = (customerId, catalogueName) => {
    const updatedCustomers = { ...selectedCustomers };
    if (updatedCustomers[customerId]) {
      delete updatedCustomers[customerId];
    } else {
      updatedCustomers[customerId] = true;
    }
    if (!selectedCatalogueName) {
      updateCatalogueName(catalogueName)
    }
    updateSelectedCustomers(updatedCustomers)
  }

  return (
    <div>
      {catalogueDetails.length > 0 &&
        catalogueDetails.map((item, index) => (
          <div key={index} className="catalogue-details self-stretch flex flex-row p-[0.75rem] items-center justify-start gap-[0.75rem] border-b border-gray-300">

            <div className="flex-1 flex flex-col items-start justify-left">
              <div className="flex items-center justify-center w-full sm:w-[calc(11rem / 1.6)]">
                {enableCatalogueShare && (
                  <div className="relative text-left mr-2">
                    <Checkbox key={index} checked={selectedCustomers[item?.id] || false} onClick={(event) => handleCustomerCheck(item?.id, item?.name)} disabled={item.status === "UNPUBLISHED"} />
                  </div>)}
                <div className="relative text-left">{curIndex + index + 1}</div>
              </div>
            </div>

            <div className="flex-1  flex flex-col items-start justify-start">
              <div className="relative inline-block  w-full sm:w-[calc(30rem / 1.6)] text-left ">
                {item.name}
              </div>
            </div>


            <div className="flex-1 flex flex-col items-center justify-start">
              <div className="relative w-full sm:w-[calc(11rem / 1.6)]  text-center">{item.upcCount != null ? item.upcCount : 0}</div>
            </div>

            <div className="flex-1 flex flex-col items-center justify-start">
              <div className="relative  w-full sm:w-[calc(11rem / 1.6)]  text-center">{formatDate(item.createdAt)}</div>
            </div>
            <div className="flex-1 flex flex-col items-center justify-start">
              <div className="relative  w-full sm:w-[calc(11rem / 1.6)] text-center">{formatDate(item.updatedAt)}</div>
            </div>

            <div
              className={`flex-1 flex flex-col items-center justify-start ${item.status === "PUBLISHED" ? "text-green" : "text-inactive"
                }`}
            >
              <div className="flex-1 flex flex-col items-center justify-start">
                <div className="relative  w-full sm:w-[calc(11rem / 1.6)]  text-center">
                  {mapCatalogueStatusEnumToString(item.status)}
                </div>
              </div>
            </div>

            <div className="flex-1 flex flex-col items-center justify-start text-center">
              <div className=" w-full sm:w-[calc(11rem / 1.6)]  text-center">
                <button
                  className="relative w-[1.13rem] h-[1.13rem] overflow-hidden shrink-0 text-center mr-2"
                  onClick={(e) => handleAction(item.id, "view")}
                >
                  <img className="w-full h-full" alt="" src={viewEyeIcon} />
                </button>

                <button
                  className="relative w-[1.13rem] h-[1.13rem] overflow-hidden shrink-0 text-center mr-2"
                  onClick={(e) => handleAction(item.id, "edit")}
                >
                  <img className="w-full h-full" alt="" src={editPenIcon} />
                </button>

                <button
                  className="relative w-[1.13rem] h-[1.13rem] overflow-hidden shrink-0 text-center mr-2"
                  onClick={(e) => handleDeleteClick(item.id)}
                >
                  <img className="w-full h-full" alt="" src={deleteBinIcon} />
                </button>

              </div>
            </div>

          </div>
        ))}
    </div>
  );

};

const Pagination = ({ pageNumber, pageSize, handlePageSize, handlePageNumber, totalRecordsData }) => {

  const [currentPage, setCurrentPage] = useState(pageNumber);
  const [totalRecords, setTotalRecords] = useState(totalRecordsData);

  const handlePageSizeLocalUpdate = (e) => {
    const newPageSize = parseInt(e.target.value);
    setCurrentPage(0);
    handlePageNumber(0);
    handlePageSize(newPageSize);
  };




  const handlePageNumberLocalUpdate = (type) => {
    if (type === "pageBack" && currentPage > 0) {
      setCurrentPage(currentPage - 1);
      handlePageNumber(currentPage - 1);
    } else if (type === "pageForward" && currentPage < Math.ceil(totalRecords / pageSize) - 1) {
      setCurrentPage(currentPage + 1);
      handlePageNumber(currentPage + 1);
    }
  };

  const CalculatePaginationDetails = () => {
    const totalPages = totalRecords > 0 ? Math.ceil(totalRecords / pageSize) : 1;
    const currentPageNumber = currentPage + 1; // Add 1 to make it 1-based index
    return (
      <>{` Page ${currentPage + 1} of ${totalPages}`}</>
    );
  };


  useEffect(() => {
    setTotalRecords(totalRecordsData);
  }, [totalRecordsData]);


  return (
    <div className="relative w-full flex flex-row py-[0.5rem] px-[0rem] box-border items-center justify-end gap-[1.5rem] text-left text-[1rem] text-text-color font-roboto mr-6">
      <div className="flex flex-row items-start justify-start gap-[0.75rem]">
        <div className="relative">Rows per page</div>
        <div className="flex flex-row items-center justify-start gap-[0.25rem]">
          <div className="relative">
            <select
              onChange={handlePageSizeLocalUpdate}
              value={pageSize}
              // value={2}
              name="pageSize"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={25}>25</option>
            </select>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-start justify-start">
        <div className="relative">
          <CalculatePaginationDetails />
        </div>

      </div>
      <button
        name="pageBack"
        onClick={() => handlePageNumberLocalUpdate("pageBack")}
      >
        <img
          className="relative w-[0.31rem] h-[0.81rem] overflow-hidden shrink-0"
          alt=""
          src={leftArrowIcon}
        />
      </button>
      <button
        name="pageForward"
        onClick={() => handlePageNumberLocalUpdate("pageForward")}
        disabled={currentPage === Math.ceil(totalRecords / pageSize)}
      >
        <img
          className="relative w-[0.31rem] h-[0.81rem] overflow-hidden shrink-0"
          alt=""
          src={rightArrowIcon}
        />
      </button>
    </div>
  );
};

const CatalogueTableDisplay = () => {
  const { user } = useAuthenticatedUser();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(parseInt(queryParams.get('pageSize')) || 10);
  const [secondaryPageSize, setSecondaryPageSize] = useState(parseInt(queryParams.get('secondaryPageSize')) || 10);
  const [pageNumber, setPageNumber] = useState(parseInt(queryParams.get('pageNumber')) || 0);
  const [secondaryPageNumber, setSecondaryPageNumber] = useState(parseInt(queryParams.get('secondaryPageNumber')) || 0);
  const [totalRecords, setTotalRecords] = useState(1);
  const [secondaryTotalRecords, setSecondaryTotalRecords] = useState(1);
  const [status, setStatus] = useState(queryParams.get('status') || 'All');
  const [sorting, setSorting] = useState(queryParams.get('sorting') || 'createdAt,desc');
  const [catalogueData, setCatalogueData] = useState([]);
  const [secodaryCatalogueData, setSecodaryCatalogueData] = useState([]);
  const [searchQuery, setSearchQuery] = useState(queryParams.get('searchQuery') || '');

  const [selectedCustomerData, setSelectedCustomerData] = useState({});
  const [secondaryCatalogueRefresh, setSecondaryCatalogueRefresh] = useState();
  const [enableCatalogueShare, setEnableCatalogueShare] = useState(false);
  const [productCategories, setProductCategories] = useState([]);
  const [selectedProductCategory, setSelectedProductCategory] = useState(queryParams.get('selectedProductCategory') || "ALL");
  const [renderCount, setRenderCount] = useState(1);
  const [selectedCatalogueName, setSelectedCatalogueName] = useState("");
  const selectedCategory = queryParams.get('category') || CLASS_TYPE.STANDARD;
  const selectedCatalogueType = queryParams.get('catalogueType') || CATALOGUE_TYPES.CURRENT_INVENTORY;
  const [selectedCategoryParam, setSelectedCategoryParam] = useState(selectedCategory);
  const [catalogueTypeParam, setcatalogueTypeParam] = useState(selectedCatalogueType);
  const bulkUploadService = useBulkUploadService();

  const [selectedIds, setSelectedIdsTo] = useState({
    standards: [],
    grades: [],
    manufacturers: [],
    brands: [],
    shapes: []
  })

  const [selectedIdsSecondary, setSelectedIdsSecondaryTo] = useState({
    grades: [],
    shapes: []
  })

  useEffect(() => {
    const params = new URLSearchParams();
  
    pageSize && params.set('pageSize', pageSize);
    secondaryPageSize && params.set('secondaryPageSize', secondaryPageSize);
    pageNumber && params.set('pageNumber', pageNumber);
    secondaryPageNumber && params.set('secondaryPageNumber', secondaryPageNumber);
    status && params.set('status', status);
    sorting && params.set('sorting', sorting);
    searchQuery && params.set('searchQuery', searchQuery);
    selectedProductCategory && params.set('selectedProductCategory', selectedProductCategory);
    selectedCategory && params.set('category', selectedCategory);
    selectedCatalogueType && params.set('catalogueType', selectedCatalogueType);
  
    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  }, [
    pageSize, secondaryPageSize, pageNumber, secondaryPageNumber,
    status, sorting, searchQuery, selectedProductCategory,
    selectedCategory, selectedCatalogueType
  ]);

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const updateSelectedCustomers = (data) => {
    setSelectedCustomerData(data);
  };


  const handleSortingChange = (e) => {
    setSorting(e.target.value);
  };

  const handleProductCategory = (e) => {
    setSelectedProductCategory(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    // debouncedSearch(e.target.value);
  };


  const handleShare = () => {
    navigate(`/catalogue/share`, {
      state: {
        cataloguesIds: selectedCustomerData,
        categoryId: selectedProductCategory,
        category: selectedCategoryParam,
        catalogueName: selectedCatalogueName,
        catalogueType: catalogueTypeParam
      }
    });
  };

  const updateCatalogueDetails = (data) => {
    setCatalogueData(data);
    setRenderCount(prev => prev + 1);
  };

  const debouncedSearch = debounce((value) => {
    if (getKeyByValue(CLASS_TYPE, selectedCategory) !== "SECONDARY") {
      fetchData(selectedIds);
    }
  }, 300);

  const convertToOptions = (data) => {
    if (!Array.isArray(data)) {
      throw new Error('Input data should be an array.');
    }

    return data.map((item) => {
      if (
        !item ||
        typeof item !== 'object' ||
        !item.hasOwnProperty('id') ||
        !item.hasOwnProperty('name')
      ) {
        throw new Error('Invalid item in data array.');
      }

      return {
        label: item.name,
        id: item.id,
      };
    });
  };

  useEffect(() => {
    axios
      .get(`${BaseUrl}/categories?sort=created_at,desc`, {
        params: {
          level: 2,
          status: "ACTIVE",
          page: 0,
          size: 100
        },
        headers: {
          Authorization: `${user.token}`,
          "Content-Type": "application/json",
        }
      })
      .then((response) => {
        const categoryOptions = convertToOptions(response?.data?.data?.content)
        setProductCategories(categoryOptions);
      })
      .catch((error) => {
        console.error('Error fetching product categories:', error);
      });

  }, []);


  const getShapeNamesByIds = (ids) => {
    if (!ids || ids.length === 0) {
      return [];
    }

    return ids.map(id => {
      const shape = SHAPES.find(shape => shape.id === id);
      return shape ? shape.name : null;
    });
  };

  const buildPayload = (filterData) => {
    const isEmpty = Object.values(filterData).every(value => Array.isArray(value) && value.length === 0);
    if (isEmpty) {
      return Object.keys(filterData).reduce((payload, key) => {
        const payloadKey = key === 'shapes' ? key : key.slice(0, -1) + 'Ids';
        payload[payloadKey] = null;
        return payload;
      }, {});
    } else {
      return Object.keys(filterData).reduce((payload, key) => {
        if (Array.isArray(filterData[key]) && filterData[key].length > 0) {
          const payloadKey = key === 'shapes' ? key : key.slice(0, -1) + 'Ids';
          payload[payloadKey] = key === 'shapes' ? getShapeNamesByIds(filterData[key]) : filterData[key];
        } else {
          const payloadKey = key === 'shapes' ? key : key.slice(0, -1) + 'Ids';
          payload[payloadKey] = [];
        }
        return payload;
      }, {});
    }
  };

  const fetchData = (filterData) => {
    var classType = getKeyByValue(CLASS_TYPE, selectedCategory);
    var catalogueType = getKeyByValue(CATALOGUE_TYPES, selectedCatalogueType);
    setSelectedCategoryParam(classType);
    setcatalogueTypeParam(catalogueType);
    const baseUrl = `${BaseUrl}/catalogues/`;
    var statusValue = status;
    var sortingValue = sorting;
    var categoryId = selectedProductCategory;
    if (statusValue === "All") {
      statusValue = '';
    }
    if (sortingValue === "All") {
      sortingValue = '';
    }
    if (selectedProductCategory === "ALL") {
      categoryId = ""
    }
    let queryParams = `?search=${encodeURIComponent(searchQuery.trim())}&page=${pageNumber}&size=${pageSize}&sort=${sorting}&category=${classType}&status=${statusValue}&categoryId=${categoryId}&catalogueType=${catalogueType}`;
    const url = `${baseUrl}${queryParams}`;

    const payload = buildPayload(filterData);
    // return 
    axios
      .post(url, payload, {
        headers: {
          Authorization: `${user.token}`,
          "Content-Type": "application/json",
        }
      }).then((response) => {
        if ((response?.data && response?.data?.data && response?.data?.data?.content)) {
          setTotalRecords(response?.data?.data?.totalElements);
          setCatalogueData(response?.data?.data?.content);
          return;
        }
      })
      .catch((err) => {
        return
      });
  };

  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery, pageNumber, pageSize, status, sorting, renderCount, selectedProductCategory, selectedCategory, selectedCatalogueType]);

  useEffect(() => {
    setSelectedCustomerData({});
  }, [selectedCategory, selectedProductCategory]);

  const fetchSecondary = (filterData) => {
    var classType = getKeyByValue(CLASS_TYPE, selectedCategory);
    var catalogueType = getKeyByValue(CATALOGUE_TYPES, selectedCatalogueType);
    setSelectedCategoryParam(classType);
    setcatalogueTypeParam(catalogueType);
    var statusValue = status;
    var categoryId = selectedProductCategory;
    if (statusValue === "All") {
      statusValue = '';
    }
    if (statusValue === "PUBLISHED") {
      statusValue = "ACTIVE";
    }
    if (statusValue === "UNPUBLISHED") {
      statusValue = "INACTIVE";
    }
    if (selectedProductCategory === "ALL") {
      categoryId = ""
    }
    const payload = buildPayload(filterData);
    const url = `${BaseUrl}/secondary-catalogues?page=${secondaryPageNumber}&size=${secondaryPageSize}&status=${statusValue}&catalogueName=${encodeURIComponent(searchQuery)}&sort=${sorting}&categoryId=${categoryId}&catalogueType=${catalogueType}`;
    axios
      .post(url, payload, {
        headers: {
          Authorization: `${user.token}`,
          "Content-Type": "application/json",
        }
      }
      )
      .then((response) => {
        if ((response?.data && response?.data?.data && response?.data?.data?.content)) {
          setSecondaryTotalRecords(response?.data?.data?.totalElements);
          setSecodaryCatalogueData(response?.data?.data?.content);
          return;
        }
      })
      .catch((err) => {
        return
      });
  }

  useEffect(() => {
    setSelectedCustomerData({});
  }, [selectedProductCategory]);

  useEffect(() => {
    if (getKeyByValue(CLASS_TYPE, selectedCategory) === "SECONDARY") {
      fetchSecondary(selectedIdsSecondary);
    }

  }, [secondaryPageNumber, secondaryPageSize, status, secondaryCatalogueRefresh, sorting, selectedProductCategory, encodeURIComponent(searchQuery), selectedCategory, selectedCatalogueType]);

  const handleApplyFiltersCatalogue = (filterData) => {
    setSelectedIdsTo(filterData);
    if (getKeyByValue(CLASS_TYPE, selectedCategory) !== "SECONDARY") {
      fetchData(filterData);
    }
  }
  useEffect(() => {
    setSorting('createdAt,desc');
  }, [selectedCategory]);

  const handleApplyFiltersSecondaryCatalogue = (filterData) => {
    setSelectedIdsSecondaryTo(filterData);
    if (getKeyByValue(CLASS_TYPE, selectedCategory) === "SECONDARY") {
      fetchSecondary(filterData);
    }

  }

  const ShareComponent = ({ text, onClickAction, enableCatalogueShare, disableButton }) => {
    const buttonStyles = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: disableButton ? 'not-allowed' : 'pointer',
      padding: '12px 10px',
      border: 'none',
      borderRadius: '4px',
      backgroundColor: enableCatalogueShare ? '#ff0000' : 'transparent',
      color: disableButton ? '#808080' : enableCatalogueShare ? 'white' : 'black',
      width: '230px',
      margin: '0px 5px',
    };

    return (
      <button style={buttonStyles} onClick={disableButton ? null : onClickAction} disabled={disableButton}>
        <ShareIcon sx={{ marginRight: '10px' }} />
        <Typography variant="h6">
          {!enableCatalogueShare ? 'Share Catalogues' : 'Cancel Sharing'}
        </Typography>
      </button>
    );
  };

  const getTemplateFile = () => {
    if (!parseInt(selectedProductCategory)) {
      return false;
    }
    const categoryId = parseInt(selectedProductCategory);
    let resourceType;
    if (selectedCategory === CLASS_TYPE.NONSTANDARD) {
      resourceType = RESOURCE_TYPE.NONSTANDARD_CATALOGUES;
    }
    else if(selectedCategory === CLASS_TYPE.STANDARD){
      resourceType = RESOURCE_TYPE.STANDARD_CATALOGUES;
    }
    else if(selectedCategoryParam === "SECONDARY" && catalogueTypeParam === "MAKE_TO_ORDER")
      resourceType = RESOURCE_TYPE.SECONDARY_LOT_CATALOGUES;
    else if(selectedCategoryParam === "SECONDARY")
      resourceType = RESOURCE_TYPE.SECONDARY_CATALOGUE;
    return bulkUploadService.getBulkUploadTemplate(
      {
      categoryId: categoryId,
      catalogueType:getKeyByValue(CATALOGUE_TYPES, selectedCatalogueType)
    },
      { resourceType: resourceType }
    );
  }

  return (
    <div className="grid gap-y-3  ">


      <div className="py-4">
        <CatalogueHeader category={selectedCategoryParam} catalogueType={catalogueTypeParam} getTemplateFile={getTemplateFile} selectedProductCategory={selectedProductCategory}/>
      </div>


      <div className="flex justify-right">

        {(selectedCategoryParam === "STANDARD" || selectedCategoryParam === "NONSTANDARD") &&
          <FormControl sx={{ width: "100%", maxWidth: "170px", margin: "0px 5px" }}>
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              id="status-select"
              value={status}
              onChange={handleStatusChange}
              label="Status"
              sx={{ color: "black" }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="PUBLISHED">Published</MenuItem>
              <MenuItem value="UNPUBLISHED">Unpublished</MenuItem>
            </Select>
          </FormControl>
        }

        {selectedCategoryParam === "SECONDARY" &&
          <FormControl sx={{ width: "100%", maxWidth: "170px", margin: "0px 5px" }}>
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              id="status-select"
              value={status}
              onChange={handleStatusChange}
              label="Status"
              sx={{ color: "black" }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="PUBLISHED">Active</MenuItem>
              <MenuItem value="UNPUBLISHED">InActive</MenuItem>
            </Select>
          </FormControl>
        }

        {selectedCategoryParam === "SECONDARY" &&
          (
            <>
              <FormControl sx={{ width: "100%", maxWidth: "250px", margin: "0px 5px" }}>
                <InputLabel id="sorting-label">Sort</InputLabel>
                <Select
                  labelId="sorting-label"
                  id="sorting-select"
                  label="Sort"
                  value={sorting}
                  onChange={handleSortingChange}
                  sx={{ width: "180%", maxWidth: "250px" }}
                >
                  <MenuItem value="createdAt,asc">Created Date(Ascending)</MenuItem>
                  <MenuItem value="createdAt,desc">Created Date(Descending)</MenuItem>

                </Select>
              </FormControl>

              {/* <FormControl sx={{ width: "100%", maxWidth: "250px", margin: "0px 5px" }}>
                <InputLabel id="sorting-label">Product Category</InputLabel>
                <Select
                  labelId="sorting-label"
                  id="sorting-select"
                  label="Product Category"
                  value={selectedProductCategory}
                  onChange={handleProductCategory}
                  sx={{ width: "180%", maxWidth: "250px" }}
                // sx={{ width: "250px" }}
                >
                  {productCategories.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </>

          )
        }

        {(selectedCategoryParam === "STANDARD" || selectedCategoryParam === "NONSTANDARD") &&
          (
            <>
              <FormControl sx={{ width: "100%", maxWidth: "250px", margin: "0px 5px" }}>
                <InputLabel id="sorting-label">Sort</InputLabel>
                <Select
                  labelId="sorting-label"
                  id="sorting-select"
                  label="Sort"
                  value={sorting}
                  onChange={handleSortingChange}
                  sx={{ width: "180%", maxWidth: "250px" }}
                // sx={{ width: "250px" }}
                >
                  <MenuItem value="name,asc">Name (Ascending)</MenuItem>
                  <MenuItem value="name,desc">Name (Descending)</MenuItem>
                  <MenuItem value="createdAt,asc">Created Date(Ascending)</MenuItem>
                  <MenuItem value="createdAt,desc">Created Date(Descending)</MenuItem>
                </Select>
              </FormControl>
            </>
          )
        }
        <FormControl sx={{ width: "100%", maxWidth: "250px", margin: "0px 5px" }}>
          <InputLabel id="sorting-label">Product Category</InputLabel>
          <Select
            labelId="sorting-label"
            id="sorting-select"
            label="Product Category"
            value={selectedProductCategory}
            onChange={handleProductCategory}
            sx={{ width: "180%", maxWidth: "250px" }}
          // sx={{ width: "250px" }}
          >
            <MenuItem key={0} value="ALL">
              All
            </MenuItem>
            {productCategories.map((option, index) => (
              <MenuItem key={index} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>


        <div className=" flex">
          <FormControl sx={{ width: "100%", maxWidth: "345px", margin: "0px 10px 0px 5px" }}>
            <TextField
              id="outlined-basic"
              label="Search by Name"
              variant="outlined"
              fullWidth
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </FormControl>

        </div>
        {(selectedCategoryParam === "STANDARD" || selectedCategoryParam === "NONSTANDARD") && (
          <FiltersIndex
            filterType={FILTERS_TYPE.CATALOGUE}
            updateFilters={handleApplyFiltersCatalogue}
            filtersData={selectedIds}
          />
        )}

        {selectedCategoryParam === "SECONDARY" && (
          <FiltersIndex
            filterType={FILTERS_TYPE.SECONDARY_CATALOGUE}
            updateFilters={handleApplyFiltersSecondaryCatalogue}
            filtersData={selectedIdsSecondary}
          />
        )}

        <FormControl >
          <ShareComponent text="Share" onClickAction={() => setEnableCatalogueShare((prev) => !prev)} enableCatalogueShare={enableCatalogueShare} disableButton={selectedProductCategory === "ALL"} />
        </FormControl>

      </div>
      {/* ----------------------Catalogue Table-------------------- */}
      <div>
        {/* Table Heading Rows */}
        {(selectedCategoryParam === "STANDARD" || selectedCategoryParam === "NONSTANDARD") && (
          <>
            {enableCatalogueShare && (<CatalogueSelection selectedRecordsCount={Object.entries(selectedCustomerData)?.length || 0} onButtonClick={handleShare} />)}
            <CatalogueTableHeadings category={selectedCategoryParam} />
            <CatalogueRecord
              catalogueType={catalogueTypeParam}
              catalogueDetails={catalogueData}
              updateCatalogueDetails={updateCatalogueDetails}
              updateSelectedCustomers={updateSelectedCustomers}
              selectedCustomers={selectedCustomerData}
              category={selectedCategoryParam}
              curIndex={pageSize * pageNumber}
              enableCatalogueShare={enableCatalogueShare}
              selectedCatalogueName={selectedCatalogueName}
              updateCatalogueName={setSelectedCatalogueName}
            />
          </>)
        }

        {selectedCategoryParam === "SECONDARY" &&
          <>
            {enableCatalogueShare && (<CatalogueSelection selectedRecordsCount={Object.entries(selectedCustomerData)?.length || 0} onButtonClick={handleShare} />)}
            <SecondaryCatalogueTableHeadings />
            <SecondaryCatalogueRecord
              catalogueDetails={secodaryCatalogueData}
              updateCatalogueDetails={updateCatalogueDetails}
              setSecondaryCatalogueRefresh={setSecondaryCatalogueRefresh}
              curIndex={secondaryPageSize * secondaryPageNumber}
              updateSelectedCustomers={updateSelectedCustomers}
              catalogueType={catalogueTypeParam}
              category={selectedCategoryParam}
              selectedCustomers={selectedCustomerData}
              enableCatalogueShare={enableCatalogueShare}
              selectedCatalogueName={selectedCatalogueName}
              updateCatalogueName={setSelectedCatalogueName}
            />
          </>
        }

        {/* ---------------------Pagination-------------------------- */}
        {(selectedCategoryParam === "STANDARD" || selectedCategoryParam === "NONSTANDARD") &&
          <Pagination
            pageNumber={pageNumber}
            pageSize={pageSize}
            handlePageSize={setPageSize}
            handlePageNumber={setPageNumber}
            totalRecordsData={totalRecords}
          />
        }

        {selectedCategoryParam === "SECONDARY" &&
          <Pagination
            pageNumber={secondaryPageNumber}
            pageSize={secondaryPageSize}
            handlePageSize={setSecondaryPageSize}
            handlePageNumber={setSecondaryPageNumber}
            totalRecordsData={secondaryTotalRecords}
          />
        }
      </div>
    </div>
  );
};
const CatalogueTable = () => {
  return (
    <div>
      <CatalogueTableDisplay />
    </div>
  );
};
export default CatalogueTable;

