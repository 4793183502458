import React, { ReactNode } from "react";
import bgImg from "../../../assets/images/authBackground.png"
import { createUseStyles } from "react-jss";
import CompanyInformationTemplate from "./CompanyInformation.template";

interface AuthenticationTemplateProps {
    children: ReactNode
}

const useStyles = createUseStyles({
    backgroundImg: {
        backgroundImage: `url(${bgImg})`,
    }
})

const AuthenticationTemplate: React.FC<AuthenticationTemplateProps> = ({ children }) => {
    const classes = useStyles();
    return (
        <>
            <div className={`${classes.backgroundImg} flex justify-center items-center h-screen w-full bg-no-repeat bg-center bg-cover`} >
                <div className="md:w-9/12 columns-2 flex h-5/6 mx-auto relative z-10">
                    <div className='w-full flex items-center'>
                        <CompanyInformationTemplate />
                    </div>
                    <div className=' w-full bg-white text-label h-full text-textSecondary'>
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}

export default AuthenticationTemplate;