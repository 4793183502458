import { Route, Routes } from "react-router"
import VendorListingPage from "../../../components/pages/VendorManagement/VendorListing.page"
import { VendorDetailsViewPage } from "../../../components/pages/VendorManagement/VendorDetailsView.page"
import VendorCreatePage from "../../../components/pages/VendorManagement/VendorCreate.page"

const VendorManagementRoutes = () => {
    return (
        <div>
            <Routes>
                <Route path="/add" element={<VendorCreatePage />} />
                <Route path="/" element={<VendorListingPage />} />
                <Route path="/:id" element={<VendorDetailsViewPage />} />
            </Routes>
        </div>
    )
}

export default VendorManagementRoutes
