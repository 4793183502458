import React, { useMemo, useRef } from 'react';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormHelperText,
  SelectChangeEvent,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import UploadButton from './UploadButton';
import JoditEditor from 'jodit-react';
import {
  PAYMENT_MODE,
  TRANSPORTATION_MODE,
  QUALITY_CHECK,
  INDENT_TEXT,
} from '../../../Constants/Procurement/Indent/constants';
import * as Yup from 'yup';
import { FieldArray, Form, Formik } from 'formik';
import ProductCardDetails from './ProductCardDetail';
import {
  BillToAddress,
  Procurement,
  ShipToLocation,
  FormData,
  ProcurementItem,
} from '../../../Constants/Procurement/Indent/types';
import IndentStepper from './IndentStepper';
import { createUseStyles } from 'react-jss';
import CustomTextField from '../../../Shared/CustomInputs/CustomTextField';
import CustomSelectField from '../../../Shared/CustomInputs/CustomSelectField';
import CustomCheckBox from '../../../Shared/CustomInputs/CustomCheckBox';
import { PURCHASE_TYPE } from './../../../Constants/Procurement/Indent/constants';
import { ViewUploadFiles } from './ViewUploadFiles';

interface AddProductDetailsProps {
  handleNext: () => void;
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  handleBack: () => void;
  fileArray: File[];
  setFileArray: React.Dispatch<React.SetStateAction<File[]>>;
  selectedPurchaseRequisition: Procurement[];
  setSelectedPurchaseRequisition: React.Dispatch<
    React.SetStateAction<Procurement[]>
  >;
  billToAddress: BillToAddress | undefined;
  setBillToAddress: React.Dispatch<
    React.SetStateAction<BillToAddress | undefined>
  >;
  shipToData: ShipToLocation | undefined;
  setShipToData: React.Dispatch<
    React.SetStateAction<ShipToLocation | undefined>
  >;
  wareHouseLocationList: { id: string; value: string; name: string }[];
  shipToAddress: ShipToLocation[];
}

const validationSchema = Yup.object().shape({
  items: Yup.array().of(
    Yup.object().shape({
      quantity: Yup.number()
        .required('Quantity is required')
        .test(
          'is-less-than-current-quantity',
          'Quantity must be less than or equal to current quantity',
          function (value) {
            const currentQuantiy = this.parent.currentQuantity || 0;
            return value <= currentQuantiy;
          }
        ),
    })
  ),
  buyerGstApplicable: Yup.boolean(),
  gst: Yup.string().when('buyerGstApplicable', {
    is: (value: boolean) => value === true,
    then: () => Yup.string().required('GST is required'),
    otherwise: () => Yup.string(),
  }),
  buyerTcsApplicable: Yup.boolean(),
  tcs: Yup.string().when('buyerTcsApplicable', {
    is: (value: boolean) => value === true,
    then: () => Yup.string().required('TCS is required'),
    otherwise: () => Yup.string(),
  }),
  buyerCessApplicable: Yup.boolean(),
  cess: Yup.string().when('buyerCessApplicable', {
    is: (value: boolean) => value === true,
    then: () => Yup.string().required('CESS is required'),
    otherwise: () => Yup.string(),
  }),
  warehouse_id: Yup.string().required('Consignee Location is required'),
  payment_terms: Yup.string().required('Payment Terms is required'),
  quality_check: Yup.string().required('Quality Check is required'),
  purchase_type: Yup.string().required('Purchase Type is required'),
  transportation_mode: Yup.string().required('Transportation Mode is required'),
});

const useStyles = createUseStyles({
  indentAddDetailsTable: {
    border: '1px solid #dddddd',
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
});

const AddProductDetails: React.FC<AddProductDetailsProps> = ({
  handleNext,
  formData,
  setFormData,
  handleBack,
  fileArray,
  setFileArray,
  selectedPurchaseRequisition,
  setSelectedPurchaseRequisition,
  wareHouseLocationList,
  // ship to / bill to details
  billToAddress,
  shipToData,
  setShipToData,
  shipToAddress,
}) => {
  const classes = useStyles();
  const editor = useRef<any>(null);

  const config = useMemo(() => {
    return {
      readonly: false,
      placeholder: 'Start typing...',
    };
  }, []);

  const handleChangeForWareHouseId = (e: SelectChangeEvent<string>) => {
    const { value } = e.target;
    if (value) {
      const filterWareHouseData: ShipToLocation = shipToAddress.filter(
        (item) => item.id === parseInt(value)
      )[0];
      setShipToData(filterWareHouseData);
    }
  };

  const removePR = (procurement: any) => {
    const newList = selectedPurchaseRequisition.filter(
      (item) => item.id !== procurement.purchase_requisition_id
    );
    setSelectedPurchaseRequisition(newList);
    if (newList.length === 0) {
      handleBack();
    }
  };

  const handleSubmitForm = (values: FormData) => {
    setFormData(values);
    handleNext();
  };

  const initialValues: FormData = {
    items: selectedPurchaseRequisition.map((purchaseReq, index) => ({
      purchase_requisition_id: purchaseReq.id,
      quantity:
        (formData.items &&
          formData.items[index] &&
          formData.items[index].quantity) ||
        purchaseReq.quantity ||
        0,
      uom: 'MT',
      currentQuantity: purchaseReq.quantity,
      packagingType: purchaseReq.packagingType,
      warehouseId: purchaseReq.warehouseId,
      deliveryTime: purchaseReq.deliveryTime,
      remarks: purchaseReq.remarks,
    })),
    cess: formData?.cess || '',
    gst: formData?.gst || '',
    tcs: formData?.tcs || '',
    payment_terms: formData?.payment_terms || '',
    quality_check: formData?.quality_check || '',
    purchase_type: formData?.purchase_type || '',
    tnc: formData?.tnc || '',
    transportation_mode: formData?.transportation_mode || '',
    buyerGstApplicable: formData?.buyerGstApplicable || false,
    buyerTcsApplicable: formData?.buyerTcsApplicable || false,
    buyerCessApplicable: formData?.buyerCessApplicable || false,
    warehouse_id: formData?.warehouse_id || '',
  };

  const removeFile = (index: number) => {
    const newArray = fileArray.filter((_, i) => i !== index);
    if (setFileArray) {
      setFileArray(newArray);
    }
  };

  return (
    <div className="grid">
      <div className="grid m-t-10 p-x-10">
        <div className="text-2xl font-bold text-blue my-3">
          {INDENT_TEXT.createIndentPageHeading}
        </div>
        <IndentStepper step={2} />
        {/* add product info table */}
        <div className={`${classes.indentAddDetailsTable} bg-cgray-14 p-4`}>
          <div className="bg-cgray-2 flex flex-col justify-center p-4 gap-px w-full  rounded-md ">
            <div className="text-xl font-semibold text-blue ">
              {INDENT_TEXT.PRlistDetailHeading}
            </div>
            <div className="text-xs font-medium text-cgray-1">
              {INDENT_TEXT.PRlistDetailSummary}
            </div>
          </div>

          {/* pr details table */}
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              handleSubmitForm(values);
            }}
            validationSchema={validationSchema}
          >
            {({
              values,
              setFieldValue,
              touched,
              errors,
              handleBlur,
              handleChange,
            }) => (
              <Form>
                <FieldArray name="items" validateOnChange={false}>
                  {({ remove }) => (
                    <div className="border border-cgray-4 mb-4 rounded-md">
                      {values.items.length > 0 &&
                        values.items.map((formData: ProcurementItem, index) => (
                          <div key={index}>
                            <ProductCardDetails
                              key={'productCard' + index}
                              index={index}
                              consigneeLocationList={wareHouseLocationList}
                              item={selectedPurchaseRequisition[index]}
                              formData={formData}
                              handleDelete={() => {
                                removePR(formData);
                                remove(index);
                              }}
                            />
                            <div></div>
                            <div
                              className={`border-solid border-t-[2px] border-cgray-4 w-[90%] m-auto px-10 ${
                                selectedPurchaseRequisition?.length - 1 ===
                                index
                                  ? 'hidden'
                                  : ''
                              }`}
                            />
                          </div>
                        ))}
                    </div>
                  )}
                </FieldArray>

                <div className="border border-cgray-4 p-4  rounded-md flex flex-col gap-4 bg-white   ">
                  <div className="text-xl font-semibold text-blue mb-[32px]">
                    {INDENT_TEXT.PRAddTermsAndConditionHeading}
                  </div>
                  <div className="flex flex-row gap-5 md:flex-wrap lg:flex-nowrap">
                    {/* Buyer (Bill To) */}
                    <div className="border border-gray-300 p-4  rounded-md flex flex-col gap-3 bg-white w-full">
                      <div className="text-l font-semibold text-text-color mb-2">
                        {INDENT_TEXT.PRAddBillToDetailsHeading}
                      </div>
                      <div className="w-full flex gap-4 my-1">
                        <CustomTextField
                          name="buyerGstin"
                          value={billToAddress?.business?.gstin || ' '}
                          label="GSTIN"
                          disabled={true}
                        />
                      </div>
                      <div className="w-full flex gap-4 my-1">
                        <CustomTextField
                          name="buyerCompanyName"
                          value={billToAddress?.business?.name || ' '}
                          label="Buyer's Company Name"
                          disabled={true}
                        />
                      </div>
                      <div className="w-full flex gap-4 my-1">
                        <CustomTextField
                          name="buyerAddressLine1"
                          value={billToAddress?.address?.line1 || ' '}
                          label="Address Line 1"
                          disabled={true}
                        />
                      </div>
                      <div className="w-full flex gap-4 my-1">
                        <CustomTextField
                          name="buyerAddressLine2"
                          value={billToAddress?.address?.line2 || ' '}
                          disabled={true}
                          label="Address Line 2"
                        />
                      </div>
                      <div className="w-full flex gap-4 my-1">
                        <CustomTextField
                          name="buyer_pincode"
                          value={billToAddress?.address?.pincode || ' '}
                          label="Pincode"
                          disabled={true}
                        />

                        <CustomTextField
                          name="buyer_city"
                          value={billToAddress?.address?.city || ' '}
                          disabled={true}
                        />

                        <CustomTextField
                          name="buyer_state"
                          value={billToAddress?.address?.state || ' '}
                          label="State"
                          disabled={true}
                        />
                      </div>
                    </div>

                    {/* Consignee Location (Ship To) */}

                    <div className="border border-gray-300 p-4  rounded-md flex flex-col gap-3 bg-white w-full ">
                      <div className="text-l font-semibold text-text-color mb-2">
                        {INDENT_TEXT.PRAddShipToDetailsHeading}
                      </div>
                      <div className="w-full flex gap-4 my-1">
                        <CustomSelectField
                          name="warehouse_id"
                          value={String(values.warehouse_id) || ''}
                          onChange={(e) => {
                            handleChangeForWareHouseId(e);
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          label="Warehouse Code"
                          error={
                            touched.warehouse_id && Boolean(errors.warehouse_id)
                          }
                          options={shipToAddress.map((item) => ({
                            id: item.id,
                            value: item.name,
                          }))}
                        />
                        <CustomTextField
                          name="buyer_delivery_gstin"
                          value={shipToData?.gstin || ' '}
                          label="GSTIN"
                          disabled={true}
                        />
                      </div>
                      <div className="w-full flex gap-4 my-1">
                        <CustomTextField
                          name="buyer_delivery_warehouse_location"
                          value={shipToData?.name || ' '}
                          label="Consignee Name"
                          disabled={true}
                        />
                      </div>
                      <div className="w-full flex gap-4 my-1">
                        <CustomTextField
                          name="buyer_delivery_address_line1"
                          value={shipToData?.address || ' '}
                          label="Address Line 1"
                          disabled={true}
                        />
                      </div>
                      <div className="w-full flex gap-4 my-1">
                        <CustomTextField
                          name="buyer_delivery_address_line2"
                          value={shipToData?.address || ' '}
                          label="Address Line 2"
                          disabled={true}
                        />
                      </div>
                      <div className="w-full flex gap-4 mt-1">
                        <CustomTextField
                          name="buyer_pincode"
                          value={shipToData?.pincode || ' '}
                          label="Pincode"
                          disabled={true}
                        />

                        <CustomTextField
                          name="buyer_city"
                          value={shipToData?.city?.name || ' '}
                          label="City"
                          disabled={true}
                        />

                        <CustomTextField
                          name="buyer_state"
                          value={shipToData?.city?.state?.name || ' '}
                          label="State"
                          disabled={true}
                        />
                      </div>
                      {touched.warehouse_id && errors.warehouse_id && (
                        <FormHelperText
                          className="-mt-2"
                          error={
                            touched.warehouse_id && Boolean(errors.warehouse_id)
                          }
                        >
                          {errors.warehouse_id}
                        </FormHelperText>
                      )}
                    </div>
                  </div>

                  {/* Statutory Tax Detail */}

                  <div className="border border-gray-300 p-4  rounded-md flex flex-col gap-3 bg-white">
                    <div className="text-l font-semibold text-text-color mb-2">
                      {INDENT_TEXT.PRAddTaxDetailsHeading}
                    </div>
                    <div className="w-full flex flex-row gap-4 my-1">
                      <div className="w-full">
                        <div className="flex flex-row flex-nowrap items-center justify-start gap-2 mb-3">
                          <div className="flex flex-row items-center">
                            <CustomCheckBox
                              name="buyerGstApplicable"
                              checked={Boolean(values.buyerGstApplicable)}
                              handleChange={(e) => {
                                handleChange(e);
                                if (!e.target.checked) {
                                  setFieldValue('gst', '');
                                }
                              }}
                            />
                          </div>
                          <label
                            className="text-text-color font-semibold"
                            htmlFor="buyerGstApplicable"
                          >
                            GST applicable
                          </label>
                          <InfoOutlinedIcon
                            sx={{ fontSize: 'medium', color: '#1D3679' }}
                          />
                        </div>
                        <CustomTextField
                          name="gst"
                          disabled={!values.buyerGstApplicable}
                          value={values.gst || ' '}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="GST"
                          adornment="%"
                          error={
                            values.buyerGstApplicable && Boolean(errors.gst)
                          }
                          helperText={values.buyerGstApplicable && errors.gst}
                        />
                      </div>

                      <div className="w-full">
                        <div className="flex flex-row flex-nowrap items-center justify-start gap-2 mb-3">
                          <div className="flex flex-row  items-center">
                            <CustomCheckBox
                              name="buyerTcsApplicable"
                              checked={Boolean(values.buyerTcsApplicable)}
                              handleChange={(e) => {
                                handleChange(e);
                                if (!e.target.checked) {
                                  setFieldValue('tcs', '');
                                }
                              }}
                            />
                          </div>
                          <label
                            htmlFor="buyerTcsApplicable"
                            className=" text-text-color font-semibold"
                          >
                            TCS applicable
                          </label>
                          <InfoOutlinedIcon
                            sx={{ fontSize: 'medium', color: '#1D3679' }}
                          />
                        </div>
                        <CustomTextField
                          name="tcs"
                          value={values.tcs || ' '}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={!values.buyerTcsApplicable}
                          label="TCS"
                          adornment="%"
                          error={
                            values.buyerTcsApplicable && Boolean(errors.tcs)
                          }
                          helperText={values.buyerTcsApplicable && errors.tcs}
                        />
                      </div>
                      <div className="w-full">
                        <div className="flex flex-row flex-nowrap items-center justify-start gap-2 mb-3">
                          <div className=" flex flex-row  items-center">
                            <CustomCheckBox
                              name="buyerCessApplicable"
                              checked={Boolean(values.buyerCessApplicable)}
                              handleChange={(e) => {
                                handleChange(e);
                                if (!e.target.checked) {
                                  setFieldValue('cess', '');
                                }
                              }}
                            />
                          </div>
                          <label
                            htmlFor="buyerTcsApplicable"
                            className=" text-text-color font-semibold"
                          >
                            Cess applicable
                          </label>

                          <InfoOutlinedIcon
                            sx={{ fontSize: 'medium', color: '#1D3679' }}
                          />
                        </div>
                        <CustomTextField
                          name="cess"
                          disabled={!values.buyerCessApplicable}
                          value={values.cess || ' '}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Cess"
                          adornment="%"
                          error={
                            values.buyerCessApplicable && Boolean(errors.cess)
                          }
                          helperText={values.buyerCessApplicable && errors.cess}
                        />
                      </div>
                    </div>
                  </div>

                  {/*  Payment and delivery Details  */}
                  <div className="border border-gray-300 p-4  rounded-md flex flex-col gap-3 bg-white">
                    <div className="text-l font-semibold text-text-color mb-2">
                      {INDENT_TEXT.PRAddPaymentDetailsHeading}
                    </div>
                    <div className="w-full flex gap-4 my-1">
                      <CustomSelectField
                        label="Payment Terms"
                        name="payment_terms"
                        onBlur={handleBlur}
                        value={values.payment_terms}
                        onChange={handleChange}
                        error={
                          touched.payment_terms && Boolean(errors.payment_terms)
                        }
                        options={PAYMENT_MODE.map(({ name, value }) => ({
                          id: name,
                          value: name,
                        }))}
                        helperText={
                          touched.payment_terms && errors.payment_terms
                        }
                      />
                      <CustomSelectField
                        label="Transportation mode"
                        name="transportation_mode"
                        onBlur={handleBlur}
                        value={values.transportation_mode}
                        error={
                          touched.transportation_mode &&
                          Boolean(errors.transportation_mode)
                        }
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        options={TRANSPORTATION_MODE.map(({ name, value }) => ({
                          id: value,
                          value: name,
                        }))}
                        helperText={
                          touched.transportation_mode &&
                          errors.transportation_mode
                        }
                      />
                    </div>
                  </div>

                  {/* QC details */}
                  <div className="border border-gray-300 p-4  rounded-md flex flex-col gap-3 bg-white">
                    <div className="text-l font-semibold text-text-color mb-2">
                      {INDENT_TEXT.PRAddQcDetailsHeading}
                    </div>
                    <div className="w-full flex gap-4 my-1">
                      <CustomSelectField
                        label="Quality Check"
                        name="quality_check"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.quality_check}
                        error={
                          touched.quality_check && Boolean(errors.quality_check)
                        }
                        options={QUALITY_CHECK.map(({ name, value }) => ({
                          id: value,
                          value: name,
                        }))}
                        helperText={
                          touched.quality_check
                            ? errors.quality_check || ''
                            : ''
                        }
                      />
                    </div>
                  </div>

                  {/* Recommended Purchase Type */}
                  <div className="border border-gray-300 p-4  rounded-md flex flex-col gap-3 bg-white">
                    <div className="text-l font-semibold text-text-color mb-2">
                      {INDENT_TEXT.PRAddRecommendedPurchaseTypeHeading}
                    </div>
                    <div className="w-full flex gap-4 my-1 text-text-color ">
                      <FormControl>
                        <RadioGroup
                          row
                          name="purchase_type"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.purchase_type || ''}
                          sx={{
                            '& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked':
                              { color: '#1d3679' },
                          }}
                        >
                          {PURCHASE_TYPE.map(({ name, value }) => (
                            <FormControlLabel
                              key={name + value}
                              value={value}
                              control={<Radio />}
                              label={name}
                            />
                          ))}
                        </RadioGroup>
                        {touched.purchase_type && errors.purchase_type && (
                          <FormHelperText
                            error={
                              touched.purchase_type &&
                              Boolean(errors.purchase_type)
                            }
                          >
                            {errors.purchase_type}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  </div>

                  {/* Any Other T&C */}

                  <div className="border border-gray-300 p-4  rounded-md flex flex-col gap-3 bg-white">
                    <div className="text-l font-semibold text-text-color mb-2">
                      {INDENT_TEXT.PRAddTnCHeading}
                    </div>
                    <JoditEditor
                      className="w-full"
                      ref={editor}
                      value={values.tnc || ''}
                      onBlur={(newContent) => setFieldValue('tnc', newContent)}
                      config={config}
                    />
                  </div>
                  <div className="border border-gray-300 p-4  rounded-md flex flex-col gap-3 bg-white">
                    <ViewUploadFiles
                      removeFile={removeFile}
                      fileList={fileArray}
                    />
                    {/* upload document */}
                    <UploadButton
                      fileList={fileArray}
                      buttonText={INDENT_TEXT.PRAdduploadButtonText}
                      setFileArray={setFileArray}
                      acceptFiles={'.jpeg, .jpg, .pdf, .doc, .docx'}
                    />

                    {/* down Button */}
                    <div className="flex ml-auto gap-3  py-4 px-3">
                      <button
                        type="button"
                        className="text-sm font-semibold text-black border border-blue-900 flex flex-row justify-center py-3 px-8 rounded"
                        onClick={handleBack}
                      >
                        {INDENT_TEXT.PRAddBackButtonLabel}
                      </button>
                      <button
                        className="text-sm font-semibold text-white bg-blue flex flex-row justify-center py-3 px-8 rounded"
                        type="submit"
                      >
                        {INDENT_TEXT.PRAddNextButtonLabel}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AddProductDetails;
