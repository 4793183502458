import React, { useRef } from 'react'
import { ISecondaryCatalogueForm, secondaryCatalogueSectionProps } from './SecondaryCatalogueForm.template'
import TextField from '../../atoms/TextField'
import JoditEditor from 'jodit-react'
import FormHelperText from "@mui/material/FormHelperText";
import CustomHeading from '../../atoms/CustomHeading/CustomHeading';
import Button from '../../atoms/Button';
import { SECONDARY_CATALOGUE_SECTIONS } from '../../../utils/types';
import TagInputGroup from '../../molecules/TagInputGroup/TagInputGroup';
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
	textColor: {
		color: theme.palette.text.primaryDarkLight
	},
	errorMessage: {
		color: theme.palette.action.danger,
	},
}));

const CatalogueDetailTemplate: React.FC<secondaryCatalogueSectionProps> = ({ formik, id, mode, setCurrentSectionTo }) => {
	const classes = useStyles();

	const handleBack = () => {
		setCurrentSectionTo(SECONDARY_CATALOGUE_SECTIONS.SECONDARY_INVENTORY);
	};
	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		const requiredFields = ["catalogueName", "minimumQuantity", "availableQuantity", "keywords", "defectDetails", "additionalProductDetails", "application"];
		let fieldError = false;
		for (const key of requiredFields) {
			if (key in formik.errors) {
				formik.setFieldTouched(key, true);
				fieldError = true;
			}
		}
		formik.setValues((prevValues: ISecondaryCatalogueForm) => ({
			...prevValues,

		}));
		if (fieldError === true) return;
		setCurrentSectionTo(SECONDARY_CATALOGUE_SECTIONS.PRICE_DETAILS);
	};

	const descriptionEditor = useRef(null);
	const onDefectsChange = (data: any) => {
		formik.setFieldValue("defectDetails", data);
	}
	const descriptionEditor2 = useRef(null);
	const onAdditionalProductChange = (data: any) => {
		formik.setFieldValue("additionalProductDetails", data);
	}

	const updatekeywordsValues = (data: string[]) => {
		formik.setFieldValue('keywords', data);
	}
	const updateApplicationValues = (data: string[]) => {
		formik.setFieldValue('application', data);
	}
	
	return (
		<div className=' rounded bg-white py-6 px-10 '>
			<CustomHeading text={" Product Information"} status="" />
			<div className='grid grid-flow-col justify-stretch gap-4 mt-4 '>
				<TextField
					label='Enter Catalogue Name*'
					variant='outlined'
					{...formik.getFieldProps('catalogueName')}
					error={formik.touched.catalogueName && Boolean(formik.errors.catalogueName)}
				/>

				<TextField
					label='Minimum Order Quantity*'
					variant='outlined'
					{...formik.getFieldProps('minimumQuantity')}
					error={formik.touched.minimumQuantity && Boolean(formik.errors.minimumQuantity)}
				/>

				<TextField
					label='Maximum Available Quantity*'
					variant='outlined'
					{...formik.getFieldProps('availableQuantity')}
					error={formik.touched.availableQuantity && Boolean(formik.errors.availableQuantity)}
				/>
			</div>

			<div className='mt-4 '>
				<TagInputGroup
					onTagChange={updatekeywordsValues}
					displayLabel={'Keywords'}
					tags={formik.values.keywords}
				/>
				{formik.touched && formik.touched?.keywords && formik.errors?.keywords && (
					<div className={classes.errorMessage}>
					<small>{formik.errors.keywords}</small>
				</div>
				)}
			</div>


			<div className='mt-4 gap-1 grid'>
				<CustomHeading text={"Defects (if any)*"} status="" />
				<JoditEditor
					ref={descriptionEditor}
					value={formik.values.defectDetails}
					onBlur={(newContent) => onDefectsChange(newContent)}
				/>
				{formik.touched.defectDetails && formik.errors.defectDetails ? (
					<div className={classes.errorMessage}>
					<small>{formik.errors.defectDetails}</small>
				</div>
				) : null}
			</div>

			<div className='mt-4  gap-1 grid'>
				<CustomHeading text={"Additional Product Details"} status="" />
				<JoditEditor
					ref={descriptionEditor2}
					value={formik.values.additionalProductDetails}
					onBlur={(newContent) => onAdditionalProductChange(newContent)}
				/>
				{formik.touched.additionalProductDetails && formik.errors.additionalProductDetails ? (
					<FormHelperText>{formik.errors.additionalProductDetails}</FormHelperText>
				) : null}
			</div>

			<div className='mt-4 '>
				<TagInputGroup
					onTagChange={updateApplicationValues}
					displayLabel={'Application'}
					tags={formik.values.application}
				/>
				{formik.touched && formik.touched?.application && formik.errors?.application && (
					<div className={classes.errorMessage}>
						<small>{formik.errors.application}</small>
					</div>
				)}
			</div>
			

			<div className="flex justify-end mt-4">
				<div className="flex gap-x-3">
					<Button variant="outlined" label="Back" onClick={handleBack} />
					<Button variant="contained" label="Next" onClick={handleSubmit} />
				</div>
			</div>
		</div>
	)
}

export default CatalogueDetailTemplate