import React, { useEffect, useMemo } from 'react'
import { ITemplateRequest } from '../../../services/useWhatsappTemplateService';
import { IWhatsappTemplate } from '../../pages/WhatsappTemplate/WhatsappTemplateList.page';
import { useNavigate } from 'react-router-dom';
import { ADMIN_ROUTES, MODE, STATUS, LANGUAGE_CODE } from '../../../utils/constant';
import { createUseStyles } from "react-jss";
import * as yup from "yup";
import { useFormik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import Button from '../../atoms/Button';
import TextField from '../../atoms/TextField';
import FormGroup from "@mui/material/FormGroup";
import Toggler from '../../atoms/Toggler';
import Select from '../../atoms/Select';
import { ParameterValuesFormTemplate } from './ParameterValuesForm.template';

const useStyles = createUseStyles((theme: any) => ({
    textColor: {
        color: theme.palette.text.primaryDarkLight
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
}));

interface ITemplateFormProps {
    onTemplateSubmit?: (templateRequestBody: ITemplateRequest) => void;
    template: IWhatsappTemplate | null;
    mode: string;
}

export interface ITemplateForm {
    templateName: string;
    language: string;
    status: string;
    attachmentType: string;
    noOfParameters: number | null;
    parameterValues: { type: string; value: string; fallBackValue: string; }[] | [];
}

const WhatsappTemplateFormTemplate: React.FC<ITemplateFormProps> = ({ template, onTemplateSubmit = () => { }, mode }) => {

    const navigate = useNavigate();
    const classes = useStyles();

    const navigateToWhatsappTemplateTable = () => {
        navigate(ADMIN_ROUTES.WHATSAPP_TEMPLATE_LIST);
    };

    var validationSchema = yup.object().shape({
        templateName: yup.string().trim().min(4, "Template name should be atleast 4 letters long").required("Template name is required"),
        language: yup.string().required("Language is Required"),
        status: yup.string().required("Status is required"),
        attachmentType: yup.string().required("Attachment type is required"),
        noOfParameters: yup.number().integer('Number of parameters must be a valid number').max(30, "Parameters cannot exceed 30").required('Number of parameters is required'),
        parameterValues: yup.array().of(
            yup.object().shape({
                type: yup.string().oneOf(["DYNAMIC", "STATIC"]).required("Type is required"),
                value: yup.string().required("Value is required"),
                fallBackValue: yup.string().notRequired()
            })
        ).required("parameterValues is required")
    });

    const initialValues: ITemplateForm = {
        templateName: template?.templateName ?? '',
        language: template?.language ?? 'en_GB',
        status: template?.status ?? STATUS.ACTIVE,
        attachmentType: template?.attachmentType ?? '',
        noOfParameters: 1,
        parameterValues: []
    }

    const formik = useFormik<ITemplateForm>({
        initialValues,
        validationSchema,
        validateOnMount: true,
        onSubmit: async (values, { setSubmitting }) => {
            if (mode !== MODE.VIEW) {
                setSubmitting(true);
                const templateRequestBody: ITemplateRequest = {
                    templateName: values.templateName?.trim(),
                    language: values.language,
                    status: values.status,
                    attachmentType: values.attachmentType,
                    parameterValues: values.parameterValues.map(parameter => ({
                        ...parameter,
                        value: parameter.value?.trim(),
                        fallBackValue: parameter.fallBackValue?.trim(),
                    })),
                }
                onTemplateSubmit(templateRequestBody);
            }
            navigate(ADMIN_ROUTES.WHATSAPP_TEMPLATE_LIST);
        }
    })

    useEffect(() => {
        if (template) {
            formik.setValues({
                templateName: template?.templateName?.trim() || '',
                language: template?.language || '',
                status: template?.status || '',
                noOfParameters: template?.parameterValues.length || null,
                attachmentType: template?.attachmentType || '',
                parameterValues: Array.isArray(template?.parameterValues)
                    ? template.parameterValues.map((param: any) => ({
                        type: 'STATIC',
                        ...param
                    }))
                    : []
            });
        }
    }, [template, formik.setValues]);

    const cancelButton = useMemo(() => {
        if (mode === MODE.VIEW) {
            return null;
        }
        return (
            <Button
                variant="outlined"
                fullWidth
                label="Cancel"
                onClick={navigateToWhatsappTemplateTable}
            />
        );
    }, [mode]);

    const handleParameterData = (parameterData: []) => {
        console.log("..._>",parameterData)
        formik.setValues({
            ...formik.values,
            parameterValues: parameterData
        })
    }

    return (
        <div>
            <form className=" w-full" onSubmit={formik.handleSubmit}>
                <div className="grid gap-y-4 w-full">
                    <div className=" flex justify-between items-center">
                        <div className={`font-semibold text-2xl text-blue ${classes.textColor}`}>Template</div>
                        <div>
                            <CloseIcon
                                className="close-icon cursor-pointer"
                                onClick={navigateToWhatsappTemplateTable}
                            />
                        </div>
                    </div>
                    <div className='grid gap-y-3'>
                        <div>
                            <TextField
                                fullWidth
                                {...formik.getFieldProps('templateName')}
                                label="Enter Template Name"
                                name="templateName"
                                variant="outlined"
                                inputProps={{
                                    readOnly: mode === MODE.VIEW ? true : false,
                                    maxLength: 100,
                                }}
                                
                            />
                            {formik.touched && formik.touched?.templateName && formik.errors?.templateName && (
                                <div className={classes.errorMessage}>
                                    <small>{formik.errors.templateName}</small>
                                </div>
                            )}
                        </div>
                        <div>
                            <Select
                                {...formik.getFieldProps('language')}
                                variant="outlined"
                                id="language"
                                label="Select Language"
                                name="language"
                                value={formik.values.language}
                                options={LANGUAGE_CODE}
                                inputProps={{
                                    readOnly: mode === MODE.VIEW ? true : false,
                                    maxLength: 100,
                                }}
                            />
                        </div>
                        <div>
                            <Select
                                variant="outlined"
                                {...formik.getFieldProps('attachmentType')}
                                label="Attachment Type"
                                name="attachmentType"
                                id="attachmentType"
                                value={formik.values.attachmentType}
                                options={[
                                    { value: "text", label: "Text" },
                                    { value: "image", label: "Image" },
                                    { value: "document", label: "Document" },
                                    { value: "video", label: "Video" },
                                ]}
                                inputProps={{
                                    readOnly: mode === MODE.VIEW ? true : false,
                                    maxLength: 100,
                                }}
                            />
                            {formik.touched && formik.touched?.attachmentType && formik.errors?.attachmentType && (
                                <div className={classes.errorMessage}>
                                    <small>{formik.errors.attachmentType}</small>
                                </div>
                            )}
                        </div>
                        <div>
                            <TextField
                                type="number"
                                fullWidth
                                {...formik.getFieldProps('noOfParameters')}
                                label="Enter number of parameters"
                                name="noOfParameters"
                                variant="outlined"
                                onKeyDownCapture={(e) => formik.setTouched({noOfParameters:true})}
                                inputProps={{
                                    readOnly: mode === MODE.VIEW ? true : false,
                                    maxLength: 100
                                }}
                                InputLabelProps={{ shrink: true }}
                            />
                            {formik.touched && formik.touched?.noOfParameters && formik.errors?.noOfParameters && (
                                <div className={classes.errorMessage}>
                                    <small>{formik.errors.noOfParameters}</small>
                                </div>
                            )}
                            {formik.values.noOfParameters && formik.values.noOfParameters > 0 && (
                                <div className='flex flex-col gap-4 m-2'>
                                    <h3 className='font-medium font-semibold m-2'> Parameters</h3>
                                    <ParameterValuesFormTemplate noOfParameters={formik.values.noOfParameters} parameterData={formik.values.parameterValues} updateParameterData={handleParameterData} mode={mode} />
                                </div>
                            )}
                        </div>
                        <div className="mt-3 mb-3">
                            <FormGroup>
                                <Toggler
                                    title="Status"
                                    currentState={formik.values.status === STATUS.ACTIVE}
                                    handleToggleChange={(type: any, value: string) =>
                                        formik.setFieldValue("status", value ? STATUS.ACTIVE : STATUS.INACTIVE)
                                    }
                                    disabled={mode === MODE.VIEW ? true : false}
                                />
                            </FormGroup>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end w-full space-x-4 mr-9">
                    <div>{cancelButton}</div>
                    {mode !== MODE.VIEW && (
                        <Button
                            variant="contained"
                            type="submit"
                            label="Submit"
                        />
                    )}
                </div>
            </form>
        </div>
    )
}
export default WhatsappTemplateFormTemplate;