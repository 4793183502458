import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomHeading from "../../../Shared/CustomHeading/CustomHeading";
import { CustomButton } from "../../../Shared/Button/CustomButton";
import TermsModal from "../../../Shared/TermsAndCondition/TermsModal";
import ImageButton from "../../../Shared/ImageButton/ImageButton";
import viewBlackSvg from "../../../Assets/Images/viewBlack.svg";
import closeBlackSvg from "../../../Assets/Images/closeBlack.svg";
import upSvg from "../../../Assets/Images/up.svg";
import downSvg from "../../../Assets/Images/down.svg";
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import QuotationDetailsModal from "./QuotationDetailsModal";
import { useAuthenticatedUser } from "../../../../hooks/useAuthenticatedUser";
import { showAttributesUom, toInr } from "../../../../utils/helper";

export const capitalizeFirstLetter = (input) => {
  return input.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase());
};

const CreateQuotation = () => {
  const { user } = useAuthenticatedUser();
  const params = useParams();
  const [upcData, setUpcData] = useState({});

  //--------------------Fetch LeadUpc data--------------------

  const [leadUpcData, setLeadUpcData] = useState([]); 
  const [comapanyName, setCompanyName] = useState("");
  const [actorId, setActorId] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [leadQuantity, setLeadQuantity] = useState(0);
  const [upcIds, setUpcIds] = useState(null);

  const fetchLeadUpc = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_V1_URL}/lead/${Number(params.id)}`,
        {
          headers: {
            Authorization: `${user.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      var upcData = response.data.data.leadUpcResponseDTO;
      setLeadUpcData(upcData);
      setCompanyName(response.data.data.companyName);
      setActorId(response.data.data.actorId);
      setQuantity(
        response.data.data.leadUpcResponseDTO.reduce(
          (total, item) => total + item.quantity,
          0
        )
      );
      setLeadQuantity(
        response.data.data.leadUpcResponseDTO.reduce(
          (total, item) => total + item.quantity,
          0
        )
      )
      var upcSkuDetails = {};
      for (let index = 0; index < upcData.length; index++) {
        const upcItem = upcData[index];

        var skuDetails = await fetchUpc(upcItem.upcId);
        upcSkuDetails = { ...upcSkuDetails, [upcItem.upcId]: skuDetails };
      }
      setUpcData(upcSkuDetails);
      setUpcIds(
        response?.data?.data?.leadUpcResponseDTO.map((item) => item.upcId)
      );
    } catch (error) {
      console.error("Error fetching lead details:", error);
    }
  };

  //-----------Terms and Condition Modal------------------

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //---------------------Fetch UPC Data-------------------------------------

  const fetchUpc = async (upcId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_V1_URL}/inventory/upcs?upcIds=${upcId}`,
        {
          headers: {
            Authorization: `${user.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.data[upcId];
    } catch (error) {
      console.error("Error fetching lead details:", error);
      return {};
    }
  };

  const [selectedUpcId, setSelectedUpcId] = useState([]);
  const [isTableOpen, setIsTableOpen] = useState({});
  //---------------------------checkbox functionality-------------

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState([]);

  const [selectedRowsMap, setSelectedRowsMap] = useState({});

  const checkSku = (upcId) => {
    if (selectedRowsMap[upcId]) {
      return true;
    } else {
      // console.log(`No selected rows for UPC ${upcId}`);
      // return false;
    }
  };
  const handleChange1 = (event, upcId, rowId, rowData) => {
    const isChecked = event.target.checked;
    const updatedSelectedRows = { ...selectedRowsMap };
    if (isChecked) {
      updatedSelectedRows[upcId] = [
        ...(updatedSelectedRows[upcId] || []),
        rowData,
      ];
      setSelectedRowsMap(updatedSelectedRows);
    } else {
      handleRemoveRow(upcId,rowId);                          
    }

  };

  const handleRemoveRow = (rowId, upcId) => {
    const updatedData = {};
    for (const key in selectedRowsMap) {
      if (key == rowId) {
      } else {
        updatedData[key] = selectedRowsMap[key];
      }
    }
    setSelectedRowsMap(updatedData);
    const updatedData1 = { ...upcSkuEditDetails };
    for (const key in upcSkuEditDetails[upcId]) {
      if (key == rowId) {
        delete updatedData1[upcId][key];
        if (Object.keys(updatedData1[upcId]).length === 0) {
          delete updatedData1[upcId];
        }
      }
    }
    setUpcSkuEditDetails(updatedData1)
  };




  const clearSelection = () => {
    setSelectedRows([]);
    setSelectedRowData([]);
  };
  const [deleteUpcId, setDeleteUpcId] = useState();

  const handleDeleteLeadUpc = async (leadUpcId) => {
    setDeleteUpcId(leadUpcId);
    const confirmed = window.confirm(
      "Are you sure you want to remove this Product ?"
    );

    if (confirmed) {
      // console.log(leadUpcId);
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_V1_URL}/lead-upc/${leadUpcId}`,
          {
            headers: {
              Authorization: `${user.token}`,
              "Content-Type": "application/json",
            },
          }
        );
        window.alert("Product removed successfully!");
        // setDeleteUpcId(leadUpcId);
      } catch (error) {
        console.error("Error deleting leadUpc:", error);
      }
    }
  };
  // -----------------------------------------------------------------
  // console.log("upcIds", upcIds);
  const [productUpcData, setProductUpcData] = useState([]);
  const fetchSingleProductUpc = async (upcId,catalogueName) => {
    try {
      const response = await axios.post(
        // `${process.env.REACT_APP_API_V1_URL}/product-upc/${upcId}`,
        `${process.env.REACT_APP_API_V1_URL}/search/upc`,
        {
          upcId: upcId,
          catalogueName: catalogueName,
        },
        {
          headers: {
            Authorization: `${user.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("LeadData--------------------------", response.data.data);
      const newData = response.data.data;
      return newData;
    } catch (error) {
      console.error(`Error fetching lead details for upcId ${upcId}:`, error);
    }
  };

  const fetchAllProductUpcs = async (upcIds) => {
    const upcFetchDetails = [];
    if (
      upcIds &&
      Array.isArray(upcIds) &&
      upcIds.length > 0 &&
      productUpcData.length === 0
    ) {
      for (let i = 0; i < leadUpcData.length; i++) {
        const catalogueName = leadUpcData[i].catalogueName;
        const upcId = upcIds[i];
        let upcDetails = await fetchSingleProductUpc(upcId, catalogueName);
        upcFetchDetails.push(upcDetails);
      }
            // console.log("upcFetchDetails", upcFetchDetails);
      // setProductUpcData((prevData) => [...prevData, ...upcFetchDetails]);
      setProductUpcData(upcFetchDetails);
    }
  };
  


  useEffect(() => {
    if (upcIds && Array.isArray(upcIds) && upcIds.length > 0) {
      fetchAllProductUpcs(upcIds);
    }
  }, [upcIds]);

  const transform = (data) => {
    try {
      if (data) {
        var specificationData = JSON.parse(data);
        specificationData = specificationData?.SPECIFICATION || [];
        return specificationData;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return []; // Return a default value in case of error
    }
  };

  useEffect(() => {
    fetchUpc();
    fetchLeadUpc();
    // fetchProductUpc();
    // fetchSingleProductUpc();
    // fetchAllProductUpcs();
  }, [params.id, selectedRowsMap, deleteUpcId]);

  const navigate = useNavigate();

  // const handleView1 = (leadId) => {
  //   navigate(
  //     `/lead-management/lead-id:${leadId}/quotation/create-quotation/search-upc`,
  //     {
  //       state: {
  //         leadId,
  //       },
  //     }
  //   );
  // };

  const handleRedirectBack = () => {
    navigate((-1));
  };

  const DisplayUpcSkuDetails = ({ upcId }) => {
    const upcSkuItemDetails = upcData[upcId];

    if (!upcSkuItemDetails) {
      // console.log("SKU DETAILS , UPC ID- ", upcSkuItemDetails, upcId);
      return <></>;
    }

    return (
      <div>
        {upcSkuItemDetails &&
          upcSkuItemDetails?.map((upcItem, index) => (
            <div
              className="lead-management-tablebody flex justify-between items-start gap-x-2"
              key={upcItem.id}
            >
              <div className="lead-management-tablebody-heading">
                <Checkbox
                  checked={checkSku(upcItem?.id)}
                  onChange={(event) => {
                    handleChange1(event, upcItem.id, upcId, upcItem);
                  }}
                  color="primary"
                />
              </div>
              <div className="lead-management-tablebody-heading w-40 pt-3">
                {upcItem?.skuCode}
              </div>
              <div className="lead-management-tablebody-heading w-48 pt-3">
                {upcItem?.warehouseName}
              </div>
              <div className="lead-management-tablebody-heading w-20 pt-3">
                {upcItem?.basePrice}
              </div>
              <div className="lead-management-tablebody-heading w-32 pt-3 mr-3 text-right">
                {upcItem?.netWeight} / {upcItem?.uom}
              </div>
            </div>
          ))}
      </div>
    );
  };

  const addToSelectedIds = (upcId) => {
    // console.log("DROP DOWN SELECTED - ", selectedUpcId);
    setSelectedUpcId((prevIds) => [...prevIds, upcId]);
  };

  const removeToSelectedIds = (upcId) => {
    // console.log("DROP DOWN REMOVED - ", selectedUpcId);
    setSelectedUpcId((prevIds) => prevIds.filter((id) => id !== upcId));
  };

  // Function to check if an `upcId` is present in the list
  const isUpcIdSelected = (upcId) => {
    // console.log("DROP DOWN SHOWN - ", selectedUpcId);
    return selectedUpcId.includes(upcId);
  };

  const isUpcIdTableSelected = (upcId) => {
    // console.log("DROP DOWN TABLE SHOWN - ", upcId);
    return isTableOpen[upcId] === true;
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = (id) => {
    setIsModalOpen(id);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const [upcSkuEditDetails, setUpcSkuEditDetails] = useState({});
  const [content, setContent] = useState("");
  const editor = useRef(null);
  const config = useMemo(() => {
    return {
      readonly: false,
      placeholder: "Start typings...",
    };
  }, []);

  const [charges, setCharges] = useState([
    {
      warehouseCharges: "",
      handlingCharges: "",
      packagingCharges: "",
      otherCharges: "",
      gst: "",
      tcs: "",
    },
  ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (value >= 0) {
      setCharges([{ ...charges[0], [name]: +value }]);
    }
  };

  const handleUpdateDetails = (
    upcId,
    skuId,
    key,
    payload,
    skuCode,
    warehouseName,
    basePrice,
    availableQuantity
  ) => {
    if(payload.target.name !== "packaging" && !/^[0-9]*(\.[0-9]*)?$/.test(payload.target.value) )
    return

    if (payload.target.name === "quantity") {
      if(payload.target.value > availableQuantity){
        alert(`Quantity entered is more than available quantity - ${availableQuantity} MT`)
        payload.target.value = availableQuantity
      }
      if (payload.target.value >= 0) {
        setUpcSkuEditDetails((prevDetails) => {
          const updatedDetails = {
            ...prevDetails,
            [upcId]: {
              ...(prevDetails[upcId] || {}),
              [skuId]: {
                ...(prevDetails[upcId]?.[skuId] || {}),
                [key]: payload.target.value,
                skuCode: skuCode,
                warehouse: warehouseName,
                basePrice: basePrice,
              },
            },
          };
          return updatedDetails;
        });
      }
    } else {
      setUpcSkuEditDetails((prevDetails) => {
        const updatedDetails = {
          ...prevDetails,
          [upcId]: {
            ...(prevDetails[upcId] || {}),
            [skuId]: {
              ...(prevDetails[upcId]?.[skuId] || {}),
              [key]: payload.target.value,
              skuCode: skuCode,
              warehouse: warehouseName,
              basePrice: basePrice,
            },
          },
        };
        return updatedDetails;
      });
    }

    // console.log("EXTRA DETAILS - ", upcSkuEditDetails);
  };

  const formatDataForPost = () => {
    const quotationUpcRequestBodyDTO = [];
    Object.keys(upcSkuEditDetails).forEach((upcId) => {
      const skuData = upcSkuEditDetails[upcId] || [];
      let totalQuantity = 0;
      Object.keys(skuData).forEach((skuId) => {
        const skuEditDetails = skuData[skuId] || {};
        totalQuantity += parseFloat(skuEditDetails.quantity) || 0;
      });
      const upcData = {
        upcId: upcId,
        upcCode: leadUpcData.filter((item) => item.upcId == upcId)[0]?.upcCode || "",
        quantity: totalQuantity || 0,
        catalogueName: leadUpcData.filter((item) => item.upcId == upcId)[0]?.catalogueName || "", 
        skuData: Object.keys(skuData).map((skuId) => {
          const skuEditDetails = upcSkuEditDetails[upcId]?.[skuId];
          const selectedSku = {};
          return {
            skuId: skuId,
            skuCode: skuEditDetails?.skuCode,
            quantity: skuEditDetails?.quantity || selectedSku.quantity,
            profit: skuEditDetails?.profit || selectedSku.profit,
            price: skuEditDetails?.price || selectedSku.price,
            packaging: skuEditDetails?.packaging || selectedSku.packaging,
            warehouse: skuEditDetails?.warehouse,
            basePrice: skuEditDetails?.basePrice,
          };
        }),
      };
      quotationUpcRequestBodyDTO.push(upcData);
    });
   
    if(charges[0].otherCharges === ""){
      charges[0].otherCharges = 0;
    }
    return {
      leadId: Number(params.id),
      actorId: actorId,
      termsAndCondition: content,
      companyName: comapanyName,
      publishStatus: "PUBLISHED",
      charges: charges,
      quotationUpcRequestBodyDTO: quotationUpcRequestBodyDTO,
      catalogueType: 'STANDARD'
    };
  };

  const handlePublish = async () => {
    const formattedData = formatDataForPost();

    const isAnyChargeEmpty = Object.values(charges[0]).some(charge => charge === "");

    if (isAnyChargeEmpty) {
      alert("Please fill in all the charges before publishing.");
      return;
    }
    if (formattedData.quotationUpcRequestBodyDTO.length === 0) {
      alert("Cannot publish without any SKU data. Please add SKUs before publishing.");
      return;
    }

    if (productUpcData.length !== formattedData.quotationUpcRequestBodyDTO.length) {
      alert("Cannot publish with an empty SKU data. Please add all SKUs before publishing.");
      return;
    }

    const isPriceSelected = (skuDataArray) => {
      return skuDataArray.every((upcData) =>
        upcData.skuData.every((sku) => sku.price !== undefined && sku.price !== "")
      );
    };

    if (!isPriceSelected(formattedData.quotationUpcRequestBodyDTO)) {
      alert("Please enter price for all SKUs before publishing.");
      return;
    }

    const isQuantityNonZero = formattedData.quotationUpcRequestBodyDTO.every(upcData =>
      upcData.skuData.every(sku => parseInt(sku.quantity) !== 0)
    );

    if (!isQuantityNonZero) {
      alert("Please ensure that quantity for all SKUs is greater than zero before publishing.");
      return;
    }

    const isPackagingSelected = (skuDataArray) => {
      return skuDataArray.every((upcData) =>
        upcData.skuData.every((sku) => sku.packaging !== undefined && sku.packaging !== "")
      );
    };
    if (!isPackagingSelected(formattedData.quotationUpcRequestBodyDTO)) {
      alert("Please select packaging for all SKUs before publishing.");
      return;
    }
    formattedData.publishStatus = "PUBLISHED";
    try {
      await axios.post(
        `${process.env.REACT_APP_API_V1_URL}/quotation`,
        formattedData,
        {
          headers: {
            Authorization: `${user.token}`,
            "Content-Type": "application/json",
          },
        }
      ).then((response) => {
        if (response.status === 200) {
          handleRedirectBack();
          return
        }
        alert("Error in creating quotation - Please try again");
        return
      })
    } catch (error) {
      if (error.response.status === 500) {
        alert("Enter all the fields");
      }
    }
  };

  const handleSave = async () => {
    const formattedData = formatDataForPost();
    if(isFieldsValid(formattedData)){
    formattedData.publishStatus = "NOTPUBLISHED";
    try {
      await axios.post(
        `${process.env.REACT_APP_API_V1_URL}/quotation`,
        formattedData,
        {
          headers: {
            Authorization: `${user.token}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error("Error saving quotation:", error);
    }
    handleRedirectBack();
  }
  };

  function isFieldsValid(formattedData){
    const isAnyChargeEmpty = Object.values(charges[0]).some(charge => charge === "");

    if (isAnyChargeEmpty) {
      alert("Please fill in all the charges before publishing.");
      return false;
    }
    if (!formattedData) {
      return false;
    }
    if (formattedData.quotationUpcRequestBodyDTO.length === 0) {
      alert("Cannot publish without any SKU data. Please add SKUs before publishing.");
      return false;
    }

    if (productUpcData.length !== formattedData.quotationUpcRequestBodyDTO.length) {
      alert("Cannot publish with an empty SKU data. Please add all SKUs before publishing.");
      return false;
    }

    const isPriceSelected = (skuDataArray) => {
      return skuDataArray.every((upcData) =>
        upcData.skuData.every((sku) => sku.price !== undefined && sku.price !== "")
      );
    };

    if (!isPriceSelected(formattedData.quotationUpcRequestBodyDTO)) {
      alert("Please enter price for all SKUs before publishing.");
      return false;
    }

    const isQuantityNonZero = formattedData.quotationUpcRequestBodyDTO.every(upcData =>
      upcData.skuData.every(sku => parseInt(sku.quantity) !== 0)
    );

    if (!isQuantityNonZero) {
      alert("Please ensure that quantity for all SKUs is greater than zero before publishing.");
      return false;
    }

    const isPackagingSelected = (skuDataArray) => {
      return skuDataArray.every((upcData) =>
        upcData.skuData.every((sku) => sku.packaging !== undefined && sku.packaging !== "")
      );
    };
    if (!isPackagingSelected(formattedData.quotationUpcRequestBodyDTO)) {
      alert("Please select packaging for all SKUs before publishing.");
      return false;
    }
    return true;
  }


  function calculateSubTotal(item) {
    const quantity = parseFloat(item?.quantity || 0);
    const pricePerMT = parseFloat(item?.price || 0);
    return (quantity * pricePerMT);
  }
  const [pricingData, setPricingData] = useState({
    productsSubTotal:0,
    warehousePrice: 0,
    handlingPrice: 0,
    packagingPrice: 0,
    otherPrice: 0,
    subTotal: 0,
    totalGst: 0,
    totalTcs: 0,
  });

  useEffect(() => {
    let productsSubTotal = 0
    let quotationQuantity = 0
    let calculatedPrices = {
      productsSubTotal: 0,
      warehousePrice: 0,
      handlingPrice: 0,
      packagingPrice: 0,
      otherPrice: 0,
      subTotal: 0,
      totalGst: 0,
      totalTcs: 0,
      totalQuantity: 0,
      grandTotal: 0
    }
    for (const key in upcSkuEditDetails) {
      for (const subKey in upcSkuEditDetails[key]) {
        productsSubTotal  += calculateSubTotal(upcSkuEditDetails[key][subKey]);
        quotationQuantity += parseFloat(upcSkuEditDetails[key][subKey]?.quantity) || 0
      }
    }
    calculatedPrices.productsSubTotal = productsSubTotal;
    calculatedPrices.totalQuantity = quotationQuantity;
    calculatedPrices.warehousePrice = charges[0].warehouseCharges * quotationQuantity;
    calculatedPrices.handlingPrice = charges[0].handlingCharges * quotationQuantity;
    calculatedPrices.packagingPrice = charges[0].packagingCharges * quotationQuantity;
    calculatedPrices.otherPrice = +charges[0].otherCharges;

    calculatedPrices.subTotal =
      productsSubTotal +
      calculatedPrices.warehousePrice +
      calculatedPrices.handlingPrice +
      calculatedPrices.packagingPrice +
      calculatedPrices.otherPrice;
    calculatedPrices.totalGst = ((charges[0]?.gst || 0) * (calculatedPrices.subTotal)) / 100;
    calculatedPrices.totalTcs = ((charges[0]?.tcs || 0) * (calculatedPrices.subTotal)) / 100;
    calculatedPrices.grandTotal = calculatedPrices.subTotal + calculatedPrices.totalGst + calculatedPrices.totalTcs;
    setLeadQuantity(quotationQuantity);
    setPricingData(calculatedPrices);
  }, [upcSkuEditDetails,charges])



  function calculateProfitLossPercentage(basePrice, offerededPrice) {
    return (((offerededPrice - basePrice) / basePrice) * 100);
  }

  const specificationThicknessMassager = (data) => {
    let arr = data.filter(el => el.name !== 'Thickness')
    let thickness = data.filter(el => el.name === 'Thickness')
    arr.unshift(thickness[0])
    return arr.filter(el => el);
  }
  return (
    <div className="grid gap-y-3 mt-4 m-auto">
      <div className="flex justify-between">
        <CustomHeading text="Create Quotation" status="" />
        <div className="m-auto mr-0 flex gap-x-3">
          <CustomButton onClick={handleOpen}>Terms and Condition</CustomButton>
          <TermsModal
            open={open}
            editor={editor}
            config={config}
            content={content}
            setContent={setContent}
            onClose={handleClose}
          />
          {/* <div className="hidden">
            <CustomButton variant="Primary" onClick={() => handleView1(leadId)}>
              Add UPC
            </CustomButton>
          </div> */}
        </div>
      </div>

      {leadUpcData &&
        leadUpcData.map((leadUpc, index) => {
          return (  
            <div className="" key={leadUpc.id}>
              <div className="flex justify-between bg-gray-300 p-3">
                <div className="m-auto ml-0">
                  <div className="text-gray-600 font-roboto text-xl font-semibold">
                    Catalogue Name : {leadUpc?.catalogueName}
                  </div>
                </div>
                <div className="flex justify-between gap-x-6">
                  <div className="m-auto text-gray-500 font-roboto font-semibold text-base">
                    Request Price : {leadUpc?.requestPrice || 0}/MT
                  </div>
                  <div className="m-auto text-gray-500 font-roboto font-semibold text-base">
                    Require Order Quantity : {leadUpc?.quantity || 0} MT
                  </div>
                  <div className="m-auto text-gray-500 font-roboto font-semibold text-base">
                  Required Packaging : {capitalizeFirstLetter(leadUpc?.packagingType)}
                </div>
                  {upcSkuEditDetails[leadUpc.upcId] ? (
                    <div className="m-auto">
                      <ImageButton
                        name="view-quotation-details"
                        imageSrc={viewBlackSvg}
                        onClick={() => handleOpenModal(leadUpc.upcId)}
                      />
                      {isModalOpen === leadUpc.upcId && isModalOpen && (
                        <QuotationDetailsModal
                          upcSkuEditDetails={upcSkuEditDetails[leadUpc.upcId]}
                          charges={charges}
                          onClose={handleCloseModal}
                          totalQuantity={leadQuantity}
                        />
                      )}
                    </div>
                  ) : null}
                  <div className="rounded hidden">
                    <ImageButton
                      className="p-1.5 bg-white rounded"
                      name="delete-lead-upc"
                      imageSrc={closeBlackSvg}
                      onClick={() => handleDeleteLeadUpc(leadUpc.id)}
                    />
                  </div>
                </div>
              </div>

              <div className="product-detail-container bg-white p-3 flex justify-between gap-x-3 w-full">
                {productUpcData &&
                  productUpcData
                    .filter((item) => leadUpc.upcId === item[0]?.id)
                    .map((item, index) => {
                      return (
                        <div className=" flex flex-col w-1/5">
                          <img
                            className="w-max rounded"
                            src={item[0]?.catalogueImages[0]?.path}
                            alt="productImage"
                          />
                          <div
                            className="flex rounded bg-gray-300 mt-2 h-fit w-full px-1"
                            key={index}
                          >
                            <div className={`grid gap-2 grid-cols-3 p-2 w-full`}>
                              {item[ 0 ].attributes &&
                                specificationThicknessMassager(transform(item[ 0 ].attributes)).map((property, i) => (
                                  property?.name && <div className="flex flex-col gap-y-1" key={i}>
                                    <div className="text-gray-500 font-roboto text-sm font-normal">
                                      {property?.name }

                                    </div>
                                    <div className="text-gray-500 font-roboto text-sm font-medium flex">
                                      {showAttributesUom(property.attributeOptions, property.attributeOptions , property.uom  )}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>

                        </div>
                      );
                    })}

                <div className="w-1/5 grow">
                  <div className="product-detail-container rounded bg-white">
                    <div className=" flex justify-between gap-x-2 p-3 ">
                      {/* <div className="text-gray-400 font-roboto text-base border font-normal leading-6">
                        Choose SKU
                      </div> */}
                      {isUpcIdTableSelected(leadUpc.upcId) &&
                        isUpcIdSelected(leadUpc.upcId) ? (
                        <button
                          className="flex justify-between w-full"
                          onClick={() => {
                            setIsTableOpen((prev) => ({
                              ...prev,
                              [ leadUpc.upcId ]: false,
                            }));
                            // removeToSelectedIds(leadUpc.upcId);
                          }}
                        >
                          <div className="text-gray-400 font-roboto text-base font-normal leading-6">
                            {" "}
                            Close SKU
                          </div>{" "}
                          <img src={upSvg} alt="upSvg" />
                        </button>
                      ) : (
                        // <ImageButton
                        //   className="border w-3/4"
                        //   name="close"
                        //   imageSrc={upSvg}
                        //   onClick={() => {
                        //     setIsTableOpen((prev) => ({
                        //       ...prev,
                        //       [leadUpc.upcId]: false,
                        //     }));
                        //     // removeToSelectedIds(leadUpc.upcId);
                        //   }}
                        // />

                        <button
                          className=" flex justify-between w-full"
                          onClick={() => {
                            // setIsTableOpen(true);
                            setIsTableOpen((prev) => ({
                              ...prev,
                              [leadUpc.upcId]: true,
                            }));
                            // fetchUpc(leadUpc.upcId);
                            addToSelectedIds(leadUpc.upcId);
                          }}
                        >
                          <div className="text-gray-400 font-roboto text-base font-normal leading-6">
                            {" "}
                            Choose SKU
                          </div>{" "}
                          <img src={downSvg} alt="downSvg" />
                        </button>
                        // <ImageButton
                        //   name="show"
                        //   imageSrc={downSvg}
                        //   onClick={() => {
                        //     // setIsTableOpen(true);
                        //     setIsTableOpen((prev) => ({
                        //       ...prev,
                        //       [leadUpc.upcId]: true,
                        //     }));
                        //     // fetchUpc(leadUpc.upcId);
                        //     addToSelectedIds(leadUpc.upcId);
                        //   }}
                        // />
                      )}
                    </div>

                    {isUpcIdTableSelected(leadUpc.upcId) &&
                      isUpcIdSelected(leadUpc.upcId) && (
                        <div>
                          <div className="lead-management-tablehead flex justify-between items-start  pt-3 pb-3 border gap-x-2">
                            <div className="lead-management-tablehead-heading  w-7  ml-3"></div>
                            <div className="lead-management-tablehead-heading  w-40">
                              SKU Code.
                            </div>
                            <div className="lead-management-tablehead-heading  w-48">
                              Warehouse
                            </div>
                            <div className="lead-management-tablehead-heading  w-20">
                              Price
                            </div>
                            <div className="lead-management-tablehead-heading   w-32 mr-3 text-right">
                              Available Quantity
                            </div>
                          </div>
                          <DisplayUpcSkuDetails upcId={leadUpc.upcId} />
                        </div>
                      )}
                  </div>

                  {isUpcIdSelected(leadUpc.upcId) &&
                    Object.keys(selectedRowsMap).map((selectedSkuId, index) => {
                      const selectedSkuDetails = selectedRowsMap[selectedSkuId];

                      if (!selectedSkuDetails) {
                        console.error(
                          "Invalid or empty selectedUpcData for selectedUpcId:",
                          selectedSkuId
                        );
                        return null;
                      }
                      if (
                        selectedSkuDetails.length > 0 &&
                        selectedSkuDetails[0].upcId !== leadUpc.upcId
                      ) {
                        return null;
                      }

                      const selectedRow = selectedSkuDetails[0];
                      // console.log(
                      //   "SKU ID,  SKU DETAILS - ",
                      //   selectedSkuId,
                      //   selectedRow
                      // );

                      return (
                        <div
                          className="product-detail-container grid gap-y-2 p-3 mt-4 rounded bg-white"
                          key={`${selectedSkuId}-${selectedRow.id}`}
                        >
                          <div className="flex justify-between gap-x-8">
                            <div className="text-gray-500 font-roboto text-base font-normal leading-6">
                              SKU Id :{" "}
                              <span className="text-gray-400 font-roboto text-base font-normal leading-6">
                                {selectedRow.skuCode}
                              </span>
                            </div>
                            <div className="text-gray-500 font-roboto text-base font-normal leading-6">
                              Warehouse :{" "}
                              <span className="text-gray-400 font-roboto text-base font-normal leading-6">
                                {selectedRow.warehouseName}
                              </span>
                            </div>
                            <div className="text-gray-500 font-roboto text-base font-normal leading-6">
                              Base Price : Rs.{" "}
                              <span className="text-gray-400 font-roboto text-base font-normal leading-6">
                                {selectedRow.basePrice} /MT
                              </span>
                            </div>
                            <div>
                              <ImageButton
                                className=""
                                name="delete"
                                onClick={() => {
                                  handleRemoveRow(selectedRow.id, leadUpc.upcId);
                                  clearSelection();
                                }}
                                imageSrc={closeBlackSvg}
                              />
                            </div>
                          </div>
                          <div className=" flex justify-between gap-x-2">
                            <div className="flex w-1/4  ">
                              <div className="w-full">
                                <TextField
                                  required
                                  type="text"
                                  name="quantity"
                                  id="outlined-basic"
                                  label=" Enter Quantity"
                                  variant="outlined"
                                  fullWidth
                                  value={
                                    upcSkuEditDetails[leadUpc.upcId]?.[
                                      selectedRow.id
                                    ]?.quantity || ""
                                  }
                                  // onChange={handleQuantityChange}
                                  onChange={(event) =>
                                    handleUpdateDetails(
                                      leadUpc.upcId,
                                      selectedRow.id,
                                      "quantity",
                                      event,
                                      selectedRow.skuCode,
                                      selectedRow.warehouseName,
                                      selectedRow.basePrice,
                                      selectedRow.netWeight
                                    )
                                  }
                                  InputProps={{
                                    inputProps: { min: 1, max: selectedRow?.netWeight, step: 1 }
                                  }}
                                />
                              </div>
                              <div className="rounded py-4 px-5 bg-gray-300">
                                MT
                              </div>
                            </div>
                            <div className="w-1/4 ">
                              <TextField
                                required
                                type="number"
                                name="price"
                                id="outlined-basic"
                                label="Offered Price /MT"
                                variant="outlined"
                                fullWidth
                                value={
                                  upcSkuEditDetails[leadUpc.upcId]?.[
                                    selectedRow.id
                                  ]?.price
                                }
                                onChange={(event) =>
                                  handleUpdateDetails(
                                    leadUpc.upcId,
                                    selectedRow.id,
                                    "price",
                                    event,
                                    selectedRow.skuCode,
                                    selectedRow.warehouseName,
                                    selectedRow.basePrice
                                  )
                                }
                              />
                            </div>
                            <div className="flex w-1/4 ">
                              <div className="w-full">
                                <TextField
                                  className="pointer-events-none"
                                  type="number"
                                  name="profit"
                                  id="outlined-basic"
                                  label="Profit % /MT"
                                  variant="outlined"
                                  fullWidth
                                  value={
                                    calculateProfitLossPercentage(selectedRow.basePrice, upcSkuEditDetails[ leadUpc.upcId ]?.[
                                      selectedRow.id
                                    ]?.price).toFixed(2)
                                  }
                                  onChange={(event) =>
                                    handleUpdateDetails(
                                      leadUpc.upcId,
                                      selectedRow.id,
                                      "profit",
                                      event,
                                      selectedRow.skuCode,
                                      selectedRow.warehouseName,
                                      selectedRow.basePrice
                                    )
                                  }
                                  InputProps={{
                                    inputProps: { readonly: true }
                                  }}
                                />
                              </div>
                              <div className="rounded py-4 px-5 bg-gray-300">
                                %
                              </div>
                            </div>
                            <div className="w-1/4 ">
                              <FormControl fullWidth>
                                <InputLabel id="label">
                                  Select Packaging*
                                </InputLabel>
                                <Select
                                  required
                                  name="packaging"
                                  id="select"
                                  label="Select Packaging*"
                                  value={
                                    upcSkuEditDetails[leadUpc.upcId]?.[
                                      selectedRow.id
                                    ]?.packaging || ""
                                  }
                                  onChange={(event) =>
                                    handleUpdateDetails(
                                      leadUpc.upcId,
                                      selectedRow.id,
                                      "packaging",
                                      event,
                                      selectedRow.skuCode,
                                      selectedRow.warehouseName,
                                      selectedRow.basePrice
                                    )
                                  }
                                >
                                  {leadUpc.cataloguePackagingType &&
                                    JSON.parse(
                                      leadUpc.cataloguePackagingType
                                    ).map((packagingType, i) => (
                                      <MenuItem key={i} value={capitalizeFirstLetter(packagingType)}>
                                        {capitalizeFirstLetter(packagingType)}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                          <div className="text-gray-500 font-roboto text-xs font-normal flex justify-between">
                            <div>
                              {" "}
                              Available quantity : {selectedRow.netWeight} MT
                            </div>
                            <div>
                              {" "}
                              {/* Base Price : Rs.{selectedRow.basePrice} /MT */}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          );
        })}

      <div className="product-detail-container grid gap-3  p-3  text-[#2A2A2A] ">
        <div className="font-medium text-xl ">
          <div className="pb-3">Additional Charges</div>
          <div className="border"></div>
        </div>

        <div className="flex gap-2">
          <TextField
            required
            type="number"
            name="warehouseCharges"
            id="outlined-basic"
            label="Warehouse Charges /MT"
            variant="outlined"
            value={charges[0].warehouseCharges || ""}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            required
            type="number"
            name="handlingCharges"
            id="outlined-basic"
            label="Handling Charges /MT"
            variant="outlined"
            value={charges[0].handlingCharges || ""}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            required
            type="number"
            name="packagingCharges"
            id="outlined-basic"
            label="Packaging Charges /MT"
            variant="outlined"
            value={charges[0].packagingCharges || ""}
            onChange={handleInputChange}
            fullWidth
          />
        </div>
        <div className="flex gap-2">
          <TextField
            type="number"
            name="otherCharges"
            id="outlined-basic"
            label="Other Charges"
            variant="outlined"
            value={charges[0].otherCharges || ""}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            required
            type="number"
            name="gst"
            id="outlined-basic"
            label="GST %"
            variant="outlined"
            value={charges[0].gst || ""}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            required
            type="number"
            name="tcs"
            id="outlined-basic"
            label="TCS %"
            variant="outlined"
            value={charges[0].tcs || ""}
            onChange={handleInputChange}
            fullWidth
          />
        </div>
      </div>

      <div className="product-detail-container grid gap-6  p-3  text-[#2A2A2A] ">
        <div className=" grid gap-2">
          <div className="">
            <div className="font-medium text-xl pb-3 flex justify-start w-full ">
              <p>Payment Information</p>
            </div>
            <div className="border"></div>
          </div>
          <div className="grid gap-3 text-[#2A2A2A]  pb-2">
            <div className="flex justify-between text-base font-medium">
              <p>Sub Total</p>
              <p>&#8377; {new Intl.NumberFormat().format(pricingData?.productsSubTotal || 0)}</p>
            </div>
            <div className="flex justify-between text-base font-normal ">
              <p>
                Warehousing charges{" "}
                <span className="text-sm">({leadQuantity.toFixed(3)} MT)</span>
              </p>
              <p>&#8377; {new Intl.NumberFormat().format(pricingData?.warehousePrice || 0)}</p>
            </div>
            <div className="flex justify-between text-base font-normal ">
              <p>
                Handling charges{" "}
                <span className="text-sm">({leadQuantity.toFixed(3)} MT)</span>
              </p>
              <p>&#8377; {new Intl.NumberFormat().format(pricingData?.handlingPrice || 0)}</p>
            </div>
            <div className="flex justify-between text-base font-normal ">
              <p>
                Packaging charges{" "}
                <span className="text-sm">({leadQuantity.toFixed(3)} MT)</span>
              </p>
              <p>&#8377; {new Intl.NumberFormat().format(pricingData?.packagingPrice || 0)}</p>
            </div>
            <div className="flex justify-between text-base font-normal ">
              <p>Other charges</p>
              <p>&#8377; {new Intl.NumberFormat().format(pricingData?.otherPrice || 0)}</p>
            </div>
            <div className="flex justify-between text-base font-semibold  ">
              <p>Sub Total(Incl Charges)</p>
              <p>&#8377; {new Intl.NumberFormat().format(pricingData?.subTotal || 0)}</p>
            </div>
            <div className="border"></div>
            <div className="flex justify-between text-base font-normal ">
              <p>GST</p>
              <p>&#8377; {new Intl.NumberFormat().format(pricingData?.totalGst) || 0}</p>
            </div>
            <div className="flex justify-between text-base font-normal ">
              <p>TCS</p>
              <p>&#8377; {new Intl.NumberFormat().format(pricingData?.totalTcs) || 0}</p>
            </div>
            <div className="border"></div>
          </div>
          <div className="">
            <div className="flex justify-between text-[#2A2A2A] text-xl font-medium">
              <p>Total amount</p>
              <p>
                &#8377;{" "}
                { toInr(pricingData?.grandTotal || 0)}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="m-auto mr-0 flex gap-x-3">
        <CustomButton onClick={handleRedirectBack}>Cancel</CustomButton>
        <CustomButton
          onClick={() => {
            handleSave();
          }}
        >
          Save
        </CustomButton>
        <CustomButton
          variant="Primary"
          onClick={() => {
            handlePublish();
          }}
        >
          Publish
        </CustomButton>
      </div>
    </div>
  );
};

export default CreateQuotation;
