
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import { MODE } from "../../../../utils/constant";

interface SpecificationDropdownTemplateProps {
    type: string;
    specification: any;
    value: any;
    onChange: any;
    mode: string;
}

    const SpecificationDropdownTemplate: React.FC<SpecificationDropdownTemplateProps> = ({ type, specification, value = '', onChange, mode}) => {

    const [dropDownValue, setDropDownValue] = useState(value);

    const handleDropDownOption = (data: any, value: any) => {
        setDropDownValue(value)
        return onChange(type, {
            id: data.id,
            name: data.name,
            attributeType: data.attributeType,
            uom: data.uom,
            fieldType: data.fieldType,
            attributeOptions: value
        })
    }

    const getSortedAttributeOptions = (attributeValue: any) => {
		return attributeValue.sort((a: any, b: any) => parseFloat(a) - parseFloat(b));
	}

    if (
        specification.fieldType === "DROPDOWN" &&
        specification.attributeOptions &&
        JSON.parse(specification.attributeOptions)?.length > 0
    ) {
        return (
            <FormControl fullWidth className="bg-white">
                <InputLabel id={`label-${specification.id}`}>
                    Select {specification.name}
                </InputLabel> 
                <Select
                    labelId={`label-${specification.id}`}
                    id={`select-${specification.id}`}
                    label={`Select ${specification.name}`}
                    value={dropDownValue}
                    onChange={(e) => handleDropDownOption(specification, e.target.value)}
                    inputProps={{ readOnly: mode !== MODE.ADD }}
                >
                    {getSortedAttributeOptions(JSON.parse(specification.attributeOptions)).map((option: any, index: any) => (
                        <MenuItem key={index} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    } else {
        return null;
    }

}

export default SpecificationDropdownTemplate;
