import { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import {
  IndentContextType,
  IndentTableData,
} from '../../../Constants/Procurement/Indent/types';
import {
  INDENT_LIST_PAGINATION_TEXT,
  IndentStatus,
  PURCHASE_TYPE,
} from './../../../Constants/Procurement/Indent/constants';
import { usePRContext } from '../../../../hooks/useProcurementContext';
import TableFilters from '../../../Components/Procurement/Indent/TableFilters';
import IndentListTable from '../../../Components/Procurement/Indent/IndentListTable';
import ListPaginationButton from '../../../Components/Procurement/Indent/ListPaginationButton';
import NoDataFound from '../../../../components/atoms/NoDataFound/NoDataFound';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import { useNavigate } from 'react-router-dom';
import { useIndentService } from '../../../../services/useIndentService';

interface IndentFilterOptionsProps {
  tabChange: number;
}

const IndentFilterOptions: React.FC<IndentFilterOptionsProps> = ({
  tabChange,
}) => {
  const { user }: any = useAuthenticatedUser();
  const [indentList, setIndentList] = useState<IndentTableData[]>([]);
  const { indentParams, setIndentParams } = usePRContext();
  const [fromDate, setFromDate] = useState<moment.Moment>(
    moment.unix(indentParams.fromDate)
  );
  const [toDate, setToDate] = useState<moment.Moment>(
    moment.unix(indentParams.toDate)
  );

  const [indentStatus, setIndentStatus] = useState<string>(
    indentParams.statuses
  );
  const [indentId, setIndentId] = useState<string>(indentParams.indentIds);

  // for pagination
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(indentParams.pageNumber);
  const [searchPage, setSearchPage] = useState<number>(indentParams.pageNumber);
  const { getIndents } = useIndentService();
  const fetchIndents = async (params: IndentContextType): Promise<void> => {
    setIsLoading(true);
    setIndentParams(params);
    let url;
    if (pageNumber >= 0) {
      let queryParams = {
        ...params,
        statuses:
          params.statuses === 'All' ? '' : params.statuses.toUpperCase(),
        toDate: params.toDate + 5 * 60,
      };

      try {
        const response = await getIndents(queryParams);
        if (response?.data?.data?.content) {
          setTotalRecords(response.data.data?.totalElements);
          setTotalPages(response.data.data.totalPages);
          setPageNumber(response.data.data.pageable.pageNumber);
          setPageSize(response.data.data.size);
          const data = response.data.data.content;
          const indents = data.map((indent: any) => {
            return {
              status: IndentStatus.find((status) => {
                return status.value === indent.status;
              })?.name,
              items: indent?.items?.length || 0,
              purchaseType: indent.approvedPurchaseType
                ? PURCHASE_TYPE.find(
                    (type) => type.value === indent?.approvedPurchaseType
                  )?.name
                : PURCHASE_TYPE.find(
                    (type) => type.value === indent?.purchaseType
                  )?.name,
              createdAt: indent.createdAt,
              createdBy: indent.createdBy,
              isNew: moment().diff(moment.unix(indent.createdAt), 'days') <= 3, // 3days
              id: indent.id,
            };
          });
          setIndentList(indents);
          setSearchPage(response.data.data.pageable.pageNumber);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setTotalRecords(0);
        setTotalPages(0);
        setPageNumber(0);
        setPageSize(10);
        setIndentList([]);
        setSearchPage(0);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const statusChangeHandler = (value: string) => {
    if (value === indentStatus) return;
    setIndentStatus(value);
    const params = {
      ...indentParams,
      ...{
        pageNumber: 0,
        statuses: value,
      },
    };
    fetchIndents(params);
  };

  useEffect(() => {
    let params: IndentContextType = indentParams;
    params = {
      isUserIndents: tabChange === 1,
      isUserDrafts: tabChange === 2,
      indentIds: '',
      statuses: 'All',
      pageSize: 10,
      pageNumber: 0,
      fromDate: moment().subtract(3, 'months').startOf('day').unix(),
      toDate: moment().endOf('day').unix(),
    };
    setIndentStatus(params.statuses);
    setSearchPage(params.pageNumber);
    setIndentId(params.indentIds);
    fetchIndents(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabChange]);

  const navigate = useNavigate();

  const showIndentHandler = (indentID: string): void => {
    if (indentID) {
      navigate(`/procurement/indent/review-indent/${indentID}`);
    }
  };

  const handlePagination = (type: 'prev' | 'next' | 'search'): void => {
    let params: IndentContextType;
    if (type === 'prev') {
      if (pageNumber > 0) {
        setPageNumber((prev) => prev - 1);
        params = {
          ...indentParams,
          ...{
            pageNumber: pageNumber - 1,
          },
        };
        fetchIndents(params);
      }
    } else if (type === 'next') {
      if (pageNumber < totalPages) {
        setPageNumber((prev) => prev + 1);
        params = {
          ...indentParams,
          ...{
            pageNumber: pageNumber + 1,
          },
        };
        fetchIndents(params);
      }
    } else if (type === 'search') {
      if (searchPage >= 0) {
        setPageNumber(searchPage);
        params = {
          ...indentParams,
          ...{
            pageNumber: searchPage,
          },
        };
        fetchIndents(params);
      }
    }
  };

  const searchHandler = (value: string) => {
    setIndentId(value);
    setPageNumber(0);
    const params: IndentContextType = {
      ...indentParams,
      ...{
        pageNumber: 0,
        indentIds: value,
      },
    };
    fetchIndents(params);
  };

  const handleFromDate = (value: Moment) => {
    let params: IndentContextType = indentParams;
    const fromDate = value.startOf('day').unix();
    if (indentParams.fromDate !== fromDate) {
      params = {
        ...indentParams,
        pageNumber: 0,
        fromDate: fromDate,
      };
      setFromDate(value);
    }
    fetchIndents(params);
  };

  const handleToDate = (value: Moment) => {
    let params: IndentContextType = indentParams;
    const toDate = value.endOf('day').unix();
    if (indentParams.toDate !== toDate) {
      params = {
        ...indentParams,
        pageNumber: 0,
        toDate: toDate,
      };
      setToDate(value);
    }
    fetchIndents(params);
  };

  return (
    <div className="flex flex-col gap-y-3 w-full">
      <TableFilters
        selectLabel={'Indent'}
        searchHandler={searchHandler}
        fromDateHandler={handleFromDate}
        toDateHandler={handleToDate}
        statusList={IndentStatus}
        status={indentStatus}
        toDate={toDate}
        fromDate={fromDate}
        statusChange={statusChangeHandler}
        searchText={indentId}
      />
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          {indentList.length <= 0 ? (
            <div className="py-32">
              <NoDataFound />
            </div>
          ) : (
            <IndentListTable
              indentList={indentList}
              showIndentHandler={showIndentHandler}
              currentPage={pageNumber}
            />
          )}
          <ListPaginationButton
            PaginationText={INDENT_LIST_PAGINATION_TEXT.showingItems(
              pageNumber,
              totalRecords
            )}
            pageNumber={pageNumber}
            searchPage={searchPage}
            setSearchPage={setSearchPage}
            handlePagination={handlePagination}
            totalPages={totalPages}
            totalRecords={totalRecords}
          />
        </>
      )}
    </div>
  );
};

export default IndentFilterOptions;
