import React from 'react';
import NoDataFoundImage from '../../../App/Assets/Images/no_data_found.svg';

const NoDataFound: React.FC = () => {
  return (
    <div className="px-16 flex flex-col gap-8">
      <div className="px-8 flex justify-center">
        <img src={NoDataFoundImage} alt="nodatafound" />
      </div>
      <div className="flex flex-col justify-center items-center gap-2">
        <div className="text-[32px] font-bold text-blue ">No Result Found</div>
        <div className="text-[16px] font-normal text-text-color">
          Try adjusting your search or filter to find what your are looking for
        </div>
      </div>
    </div>
  );
};

export default NoDataFound;
