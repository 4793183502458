import React, { useState } from "react";
import useCreate from "../../Core/CustomHooks/useCreate";
import { Dialog, Typography, DialogContent, DialogActions, Button, TextField } from "@mui/material";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";

const EditEmailDialog = ({ open, openOtpmail, onClose,  }) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("Enter valid email address");
  const { loading, putRequest } = useCreate();
  const [errors, setErrors] = useState("");
  const { user, setAuthenticatedUser } = useAuthenticatedUser();

  const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setEmailError(!isEmailValid(value));
  };

  const isEmailValid = (value) => {
    if (!emailRegex.test(value)) {
      return  false
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValidemail = emailRegex.test(email);
    if (!isValidemail) {
      setEmailError(true)
      setEmailErrorMsg("Please enter valid email address")
      return;
    }
    if(!email){
      setEmailError(true)
      setEmailErrorMsg("Please enter email address")
      return;
    }
    let apiRes = await putRequest("/user", { email: email });
    try {
      if (apiRes === undefined) {
        console.log("Internal server error occured");
        return;
      }
      if (apiRes?.status === 200) {
        setAuthenticatedUser({ ...user, email: email });
        onClose();
      } else if (apiRes?.status === 400 || apiRes.data.statusMessage === "DUPLICATE EMAIL") {
        setEmailError(true);
        setEmailErrorMsg("This email address is already registered");
      } else {
        console.log("Something went wrong");
      }
    } catch (error) {
      console.error("Error:", error);
      setEmailErrorMsg("Internal server error occurred");
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ padding: "1.5rem 2.5rem 2.5rem 2.5rem" }}>
        <Typography variant="h6" gutterBottom style={{ color: "#1D3679" }}>
          Change Your Email Address
        </Typography>
        <DialogContent sx={{ padding: "8px 0px" }}>
          <Typography variant="body1" paragraph>
            Current email address:
            <br />
            {user.email}
          </Typography>
          <Typography variant="body1" paragraph>
            Enter the new email address you would like to associate with your
            account below. We will send a One Time Password (OTP) to that
            address.
          </Typography>

          <TextField
            label="Email ID"
            type="text"
            value={email}
            fullWidth
            variant="outlined"
            onChange={handleEmailChange}
            error={emailError}
            helperText={emailError && emailErrorMsg}
            autoComplete="false"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            style={{ color: "#1D3679" }}
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            style={{ color: "#fff", background: "#1D3679" }}
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default EditEmailDialog;
