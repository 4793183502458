import StandardGradeSearch from "./StandardGradeSearch";
const StandardSectionIndex = ({ updateSearchParams, searchPayload }) => {
    return (

        <div className="self-stretch bg-whitesmoke flex flex-col p-6 items-start justify-start gap-[18px] text-base text-text-color">
            <div className="relative text-lg leading-[20px] font-semibold text-black">
                Standard
            </div>
            <StandardGradeSearch updateSearchParams={updateSearchParams} searchPayload={searchPayload} />

        </div>
    )
}
export default StandardSectionIndex;