import { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import {
    FormControl,
    TextField,
} from '@mui/material';

const CustomAttributeInputTags = ({ existingValues, updateFieldData, displayLabel = 'Add Values', initialValue = [], mode = false, validationRegex = /^[a-zA-Z0-9\s\-!"#$&'*,./:?@_`~]+$/, errorMessage, error, setError, isAddDisable = false, setErrorMessageTo = false }) => {
    const [tag, setTag] = useState('');
    const [tags, setTags] = useState([]);
    const [inputError, setInputError] = useState(null);
    const [invalidError, setInvalidError] = useState(errorMessage);

    useEffect(() => {
        setTags([...initialValue.attributeOptions]);
    }, [initialValue])

    const handleTagChange = (e) => {
        setError((prev) => ({ ...prev, error: false }))
        setTag(e.target.value);
        if (validationRegex && !validationRegex.test(e.target.value) && e.target.value.length) {
            setInputError(invalidError);
        } else {
            setInputError(null);
        }
    };
    const SortAttributeOptions = (a, b) => {
        const isNumericA = !isNaN(parseInt(a));
        const isNumericB = !isNaN(parseInt(b));
        if (isNumericA && isNumericB) {
            return parseInt(a) - parseInt(b);
        } else if (isNumericA) {
            return -1;
        } else if (isNumericB) {
            return 1;
        }
        return a.localeCompare(b, 'en', { numeric: true });
    };

    const sortTags = (tags) => {
        return tags.sort(SortAttributeOptions);
    };

    const handleAddTag = (e) => {
        e.preventDefault()
        setError((prev) => ({ ...prev, error: false }));
        const newTags = initialValue.name.toLowerCase() === "thickness" ? parseFloat(tag).toFixed(2).toString().split(',').map((tag) => tag.trim()).filter(Boolean) : tag.split(',').map((tag) => tag.trim()).filter(Boolean);
        const uniqueNewTags = Array.from(new Set(newTags));
        const duplicates = uniqueNewTags.filter((newTag) => tags.includes(newTag));
        if (duplicates.length === 0 && newTags.length > 0 && (!validationRegex || validationRegex.test(tag))) {
            setTags([...tags, ...uniqueNewTags]);
            updateFieldData([...tags, ...uniqueNewTags]);
            setTag('');
            setInputError(null);
        }
        else {
            setError((prev) => ({ ...prev, error: true, msg: "Please enter different value" }))
            setInvalidError("Please enter different value")
            setInputError(invalidError);
        }
    };

    const handleDeleteTag = (tagToDelete) => {
        setError((prev) => ({ ...prev, error: false }))
        var updatedDetails = tags.filter((tag) => tag !== tagToDelete)
        setTags(updatedDetails);
        updateFieldData(updatedDetails)
    };

    return (
        <div>
            <div className='flex flex-row w-full mb-2'>
                <FormControl sx={{ width: '100%' }}>
                    <TextField
                        label={displayLabel}
                        variant="outlined"
                        value={tag}
                        onChange={handleTagChange}
                        disabled={mode}
                        error={!!inputError || error?.error}
                        helperText={error?.error ? error.msg : inputError}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleAddTag(e);
                            }
                        }}
                    />
                </FormControl>
                {!mode && !isAddDisable && (
                    <div
                        className="outlined rounded bg-blue overflow-hidden flex flex-row py-[0.81rem] px-[1.63rem] items-center justify-center text-[0.94rem] text-white ml-3 border max-h-12 mt-0.5"
                        onClick={handleAddTag}
                        style={{ cursor: 'pointer' }}
                    >
                        <div className="relative  leading-[1.38rem] font-semibold">
                            {'Add'}
                        </div>
                    </div>
                )}
            </div>
            {!isAddDisable && <Box mt={2}>
                {sortTags(tags).map((tag, index) => (
                    <Chip
                        key={index}
                        label={tag}
                        onDelete={!existingValues.includes(tag) && !mode ? () => handleDeleteTag(tag) : undefined}
                        variant="outlined"
                        style={{ margin: '4px' }}
                    />
                ))}
            </Box>}
        </div >
    );
};

export default CustomAttributeInputTags;
