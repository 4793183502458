import React from "react";

interface headingProps {
  label: string;
  value: string;
  variant: "small" | "medium";
}

const LiveAuctionCustomHeading = ({ label, value, variant }: headingProps) => {
  const variantClass = {
    small: "text-cgray-1 font-light",
    medium: "text-text-color font-semibold",
  };
  return (
    <div>
      <div className={variantClass[variant]}>{label}</div>
      <div
        className={`text-blue font-semibold ${
          variant === "medium" ? "text-[20px]" : ""
        }`}
      >
        {value}
      </div>
    </div>
  );
};

export default LiveAuctionCustomHeading;
