import React from "react";
import { createUseStyles } from "react-jss";
import { toInr } from "../../utils/helper";
import { IChargesProps } from "../../utils/constant";
import Tooltip from "../atoms/Tooltip";

const useStyles = createUseStyles((theme: any) => ({
  price: {
    color: theme.palette.text.secondaryDark,
  },
}));

interface IPriceInfoProps{
  data: IChargesProps;
  totalQuantity: number;
  uom: string;
  label?: string;
}
const PricingInfo: React.FC<IPriceInfoProps> = ({ data, totalQuantity, uom, label }) => {
  const classes = useStyles();
  return (
    <div className="flex justify-between">
		<div className="">
			<div className={ `${ classes.price } text-base font-normal` }>
				{ data?.title } {data?.showFullQuantity ? `(${totalQuantity.toFixed(3)} ${uom})` : null }
				{data?.icon && (
					<Tooltip title={data?.iconInfo}>
					<span className="">{data?.icon}</span>
					</Tooltip>
				)}
			</div>
			{label === "CART" ? (
				<div className={`${classes.price} text-xs font-normal`}>{data?.title === "Sub Totals" ? "Incl. Packaging Charges" : data?.subTitle}</div>
			) : (
				(data?.subTitle && <div className={`${classes.price} text-xs font-normal`}>{data?.subTitle}</div>)
			)}
		</div>
		<div className={ `${ classes.price } text-base font-normal` }>
			{ toInr(data?.price || 0) }
		</div>
    </div>
  );
};

export default PricingInfo;
