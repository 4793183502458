import React, { FormEvent, useState } from "react";
import { AUTH_STATES, HTTP_STATUS } from "../../../utils/types";
import { InputAdornment } from '@mui/material';
import { useAuthService } from "../../../services/useAuthService";
import { validatePhoneNumber } from "../../../utils/helper";
import Button from "../../atoms/Button";
import TextField from "../../atoms/TextField";
import { useSnackbar } from "../../../hooks/useSnackBar";
import AuthFooter from "../../molecules/AuthFooter/AuthFooter";

interface SignUpWithMobileTemplateProps {
    mobileNumber: string;
    setAuthState: (state: AUTH_STATES) => void;
    setMobileNumber: (state: string) => void;
}

const SignUpWithMobileTemplate: React.FC<SignUpWithMobileTemplateProps> = ({ setAuthState,  mobileNumber, setMobileNumber }) => {
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const authService = useAuthService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length > 10) return;
        setMobileNumber(event.target.value);
        if (!validatePhoneNumber(event.target.value))
            setErrorMessage('Please enter a valid phone number');
        else setErrorMessage('');
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (errorMessage) return;
        if (mobileNumber.length < 10) {
            setErrorMessage('Please enter a valid phone number');
            return;
        }
        authService.signUp({ mobileNumber })
            .then(res => {
                if (res?.status === HTTP_STATUS.BAD_REQUEST) {
                    setErrorMessage(res.data.statusMessage);
                } else if (res?.status === HTTP_STATUS.OK) {
                    setAuthState(AUTH_STATES.OTP_VERIFICATION)
                } else showSnackbar('error', 'Server not responding, Please try again later')
            }).catch(error => {
                console.error("Error while Signup:", error)
            }).finally(() => setIsLoading(false))
    };

    const setAuthStateTo = (authState: AUTH_STATES) => () => setAuthState(authState);

    return (
        <div className="auth-right-child-container p-10 flex flex-col">
            {SnackBarComponent}
            <div className="form-header mb-9">
                <h1 className="text-3xl font-bold sm:text-fs-24 md:text-fs-24">Sign Up</h1>
                <p className="text-base font-normal sm:text-sm md:text-sm">Stay updated on your professional world</p>
            </div>
            <div className="form-container flex flex-col grow">
                <form className="flex flex-col gap-y-5 mb-4" onSubmit={handleSubmit}>
                    <TextField
                        label="Phone Number"
                        variant="outlined"
                        type="tel"
                        placeholder="Enter Your Phone Number"
                        value={mobileNumber}
                        onChange={handlePhoneNumberChange}
                        error={!!errorMessage}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                        }}
                        helperText={errorMessage}
                    />              
                    <Button type="submit" variant="contained" label='Get OTP' isLoading={isLoading}/>
                </form>
                <AuthFooter setAuthStateTo={setAuthStateTo} isLogin={false}/>  
            </div>
        </div>
    );
}

export default SignUpWithMobileTemplate;