import React from 'react';

const StatusToggle = ({ formData, handleUpdateFormDetails, disabled = false }) => {
  // console.log("status data - ", formData)
  const handleChange = (e) => {
    handleUpdateFormDetails('status', e.target.checked);
  };
  return (
    <div className='flex flex-col gap-2'>
      <div className='flex gap-5'>
        
      <h3 className='font-medium font-semibold text-xl'>Status</h3>
        <label className='switch'>
          <input
            name='status'
            type='checkbox'
            onChange={handleChange}
            checked={formData.status}
            disabled={disabled}
          />
          <span className='slider round'></span>
        </label>
      </div>
      {/* <p>Status is now 
        <span style="font-weight: bold; color: {{formData.status ? 'green' : 'red'}}">
          { formData.status ? 'Active' : 'Inactive' }
          </span>,
         if you want 
         {formData.status ? 'Inactive' : 'Active'} use toggle
         </p> */}

      {!disabled && (<p>Status is now <span className="font-bold">{formData.status ? 'Active' : 'Inactive'}</span>, if you want {formData.status ? 'Inactive' : 'Active'} use toggle
      </p>)}
    </div>
  );
};

export default StatusToggle;
