import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { HTTP_STATUS, USER_DETAILS_DUPLICATION_CODE } from '../../../utils/types';
import ButtonV2 from '../../atoms/ButtonV2';
import AddKeyPersonDetailsTemplate from './AddKeyPersonDetails.template';
import deleteIcon from '../../../assets/icons/deleteIcon.svg'
import Checkbox from '@mui/material/Checkbox';
import { ADMIN_ROUTES } from '../../../utils/constant';
import { useNavigate, useParams } from 'react-router-dom';
import { PocSectionProps } from './KeyPersonDetaills.template';
import { ViewKeyPersonDetailsTemplate } from './ViewKeyPersonDetails.template';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.primaryDark}`
    },
    authorizedText: {
        color: theme.palette.textV2.primary.primary500
    },
    text: {
        color: theme.palette.text.secondaryDark
    },
    checkedInputColor: {
        color: theme.palette.textV2.tertiary.tertiary300,
        '&.Mui-checked': {
            color: theme.palette.textV2.primary.primary400,
        },
    }
}));

export const HeaderKeyPersonDetailsTemplate: React.FC<PocSectionProps> = ({ formik, setCurrentSectionTo }) => {

    const classes = useStyles()
    const bussinessProfileService = useBusinessProfileService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const navigate = useNavigate();

    const params = useParams();
    const id = Number(params.id);

    const [isEditing, setIsEditing] = useState<number | null>(null);

    const handleEditClick = async (index: number) => {
        if (isEditing === index) {
            if (Object.keys(formik.errors).length === 0) {
                formik.setFieldValue("noOfRows", 1);
                const response = await submitForm();
            } else {
                showSnackbar('error', 'Errors in fields');
            }
        } else {
            setIsEditing(index);
        }
    };

    const submitForm = async () => {
        const body = [
            ...formik.values.pocs,
            ...formik.values.newPocs,
        ];
        try {
            const response = await bussinessProfileService.updateBusinessProfile(id, body, 'POCS');
            if (response?.status === HTTP_STATUS.OK) {
                showSnackbar("success", "Business profile updated successfully");
                setIsEditing(null);
                return { status: HTTP_STATUS.OK };
            } else if (response?.status === HTTP_STATUS.BAD_REQUEST) {
                if (response?.data?.statusMessage === USER_DETAILS_DUPLICATION_CODE.MOBILE_NUMBER_DUPLICATION) {
                    showSnackbar('error', `Business Profile updation failed: Whatsapp number already registered.`);
                    return
                } else {
                    showSnackbar('error', response?.data?.statusMessage);
                    return
                }
            }
        } catch (error) {
            showSnackbar("error", `Business profile update failed ${error}`);
            return { status: 'error' };
        }
    };

    const keyDetailProps: PocSectionProps = {
        setCurrentSectionTo: setCurrentSectionTo,
        formik: formik
    };

    const deletePoc = async (index: number) => {
        const pocs = [...formik.values.pocs];
        pocs.splice(index, 1);
        formik.setFieldValue('pocs', pocs);
        try {
            const response = await bussinessProfileService.updateBusinessProfile(id, pocs, 'POCS');
            if (response?.status === HTTP_STATUS.OK) {
                showSnackbar("success", "Business profile updated successfully");
                window.location.reload();
            } else if (response?.status === HTTP_STATUS.BAD_REQUEST) {
                showSnackbar('error', response?.data?.statusMessage);
            } else {
                showSnackbar('error', "Error while updating business profile");
            }
        } catch (error) {
            showSnackbar("error", `Business profile update  failed ${error}`);
        }
    };

    const handleBackToDashboard = () => {
        navigate(ADMIN_ROUTES.VENDOR_MANAGEMENT_LIST);
    };

    return (
        <>
            {formik?.values?.pocs?.map((poc: any, index: number) => {
                return <div className={`${classes.container} mt-6 p-6  rounded-lg`} key={index}>
                    <div className='border-b-2 font-medium mb-6  pb-6 flex justify-between text-2xl'>
                        <div>
                            <span className={` ${classes.text}`}>{poc.name}</span>
                            <span style={{ marginLeft: '10px' }}>
                                {poc.isAuthorized ? (
                                    <span className={classes.authorizedText}>(Authorized Account)</span>
                                ) : (
                                    <span className={classes.authorizedText}>(Unauthorized Account)</span>
                                )}
                            </span>
                        </div>
                        <div className='flex items-center gap-x-4 '>
                            {isEditing === index ? (
                                <div className="flex items-center">
                                    <Checkbox
                                        id={`isAuthorized-${index}`}
                                        name={`poc[${index}].isAuthorized`}
                                        checked={poc.isAuthorized}
                                        className={` ${classes.checkedInputColor}`}
                                        onChange={(e) => formik.setFieldValue(`poc[${index}].isAuthorized`, e.target.checked)} />
                                    <label className='text-base ml-0.5'>Authorized Person</label>
                                </div>
                            ) : null}

                            <ButtonV2
                                variant={isEditing === index ? 'primaryContained' : 'tertiaryContained'}
                                className='flex items-center'
                                label={isEditing === index ? 'Save' : 'Edit'} onClick={() => handleEditClick(index)}
                                size='small'
                            />
                            {!poc.isAuthorized ? (
                                <img src={deleteIcon} alt="deleteicon" className="blink-on-hover blink-on-click" onClick={() => deletePoc(index)} />
                            ) : (
                                null
                            )}
                        </div>
                    </div>
                    {isEditing === index ? (<AddKeyPersonDetailsTemplate  {...keyDetailProps} poc={poc} index={index} />)
                        :
                        (<ViewKeyPersonDetailsTemplate  {...keyDetailProps} poc={poc} index={index} />)
                    }
                </div>
            })}
            <div className=' grid justify-items-stretch pt-6'>
                <ButtonV2
                    className={` justify-self-end`}
                    variant="secondaryContained"
                    label="Back to Dashboard"
                    onClick={handleBackToDashboard}
                />
            </div>
        </>
    )
}
