import React, { useEffect, useRef } from 'react'
import Typography from "@mui/material/Typography";
import uploadIcon from "../../assets/icons/uploadIcon.svg";
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    textColor: {
        color: theme.palette.button.primaryDark
    },
    background: {
        backgroundColor: theme.palette.button.primaryDark
    },
    helperText: {
        color: theme.palette.text.secondaryLigthAccent,
        fontSize: "18px",
        margin: "0px",
    },
}));

interface IConfiguration {
    maxImageCount: number;
    maxfilesize : number;
    documentTypes: string[];
    documentResolution: { width: number, height: number, tolerance: number };
}

interface UploadImagesProps {
    updateImages: (images: any) => void;
    title: string;
    currentImageCount?: number;
    configuration: IConfiguration;
}
const UploadImages: React.FC<UploadImagesProps> = ({ updateImages, title, currentImageCount = 0, configuration }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const classes = useStyles();

    const handleUploadImage = (event: React.ChangeEvent<HTMLInputElement> | any) => {
        event.preventDefault();
        let uploadedImages: File[] = [];
    
        if (event.target.files) {
            uploadedImages = Array.from(event.target.files);
        } else if (event.dataTransfer.files) {
            uploadedImages = Array.from(event.dataTransfer.files);
        }
    
        const validImages: File[] = [];
        const invalidImages: string[] = [];
        const promises: Promise<void>[] = [];
    
        if (uploadedImages.length + currentImageCount > configuration.maxImageCount) {
            alert(`Maximum of ${configuration.maxImageCount} images allowed`);
            return;
        }
    
        uploadedImages.forEach((uploadedImage: File) => {
            if (configuration.documentTypes.includes(uploadedImage.type) && uploadedImage.size <= (configuration.maxfilesize * 1024)) {
                const newImage = new Image();
                newImage.src = URL.createObjectURL(uploadedImage);
                
                const promise = new Promise<void>((resolve) => {
                    newImage.onload = function () {
                        if (Math.abs(newImage.width - configuration.documentResolution.width) > configuration.documentResolution.tolerance ||
                            Math.abs(newImage.height - configuration.documentResolution.height) > configuration.documentResolution.tolerance) {
                            invalidImages.push(uploadedImage.name);
                        } else {
                            validImages.push(uploadedImage);
                        }
                        resolve();
                    };
                });
    
                promises.push(promise);
            } else {
                invalidImages.push(uploadedImage.name);
            }
        });
    
        Promise.all(promises).then(() => {
            updateImages(validImages);
            if (invalidImages.length > 0) {
                const invalidImage = invalidImages.join(', ');
                alert(`${invalidImage } files are not valid . Please check configuration . Size and Resolution should be less than ${configuration.maxfilesize } kb , ${configuration.documentResolution.width} * ${configuration.documentResolution.height} `);
            }
        });
    
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    return (
        <div className="w-full flex flex-col gap-3 font-roboto my-2">
            <div className={`w-full flex justify-start text-2xl ${classes.textColor}`}>
                <div className="font-semibold text-base md:text-lg lg:text-xl">
                    {title}
                </div>
                <Typography
                    className={`${classes.helperText}`}
                >
                    {`(Maximum-${configuration.maxImageCount})`}
                </Typography>
            </div>
            <div
                className="w-full border-2 h-64 border-dashed flex items-center justify-center"
                onDrop={handleUploadImage}
                onDragOver={handleUploadImage}
            >
                <div className="flex flex-col items-center gap-4">
                    <div className="flex flex-col items-center w-40 h-20">
                        <img
                            className="w-9 h-9"
                            alt="uploadIcon"
                            src={uploadIcon}
                        />
                        <div className="mt-3 text-xs font-medium text-text-color">
                            Drag your files here - there
                        </div>
                    </div>
                    <div className="text-base">or</div>
                    <div className={`flex flex-col items-center rounded ${classes.background} justify-center text-base text-white font-semibold`}>
                        <input
                            type="file"
                            multiple
                            className="hidden"
                            onChange={handleUploadImage}
                            id="fileInput"
                            ref={fileInputRef}
                        />
                        <label
                            htmlFor="fileInput"
                            className="cursor-pointer py-4 px-6"
                        >
                            Browse
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default UploadImages;
