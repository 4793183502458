import { useAuthenticatedUser } from '../hooks/useAuthenticatedUser';
import { API_METHOD } from '../utils/constant';
import { request } from '.';
import { replaceUrlParams } from '../utils/helper';

const SCRAP_PRODUCT_URLS = {
    CREATE_ALL_SCRAP_PRODUCTS: "/scraps/customer/:userId",
    GET_ALL_SCRAP_PRODUCTS: "/scraps/customer/:userId",
    SCRAP_PRODUCT_ITEM: "/scraps/:id",
    DELETE_SCRAP_PRODUCT_ITEM: "/scraps/:id/:imageIds"
}

interface ScrapProductsGetRequestBody {
    scrapCategory: string,
    type: string,
    monthlyProduction: number | null,
    saleMode: string,
    imageListToDelete?: string
}

interface IScrapParams {
    page: number;
    size: number;
    sort?: string;
}


export const useScrapProductService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();

    const getAllScrapProducts = (userId: number, params: IScrapParams) => {
        return request(API_METHOD.GET, replaceUrlParams(SCRAP_PRODUCT_URLS.GET_ALL_SCRAP_PRODUCTS, { userId }), authenticatedUser, null, { params });
    }

    const create = (data: ScrapProductsGetRequestBody, userId: number) => {
        return request(API_METHOD.POST, replaceUrlParams(SCRAP_PRODUCT_URLS.CREATE_ALL_SCRAP_PRODUCTS, { userId }), authenticatedUser, data);
    };

    const getScrapProductId = (id: number) => {
        return request(API_METHOD.GET, replaceUrlParams(SCRAP_PRODUCT_URLS.SCRAP_PRODUCT_ITEM, { id }), authenticatedUser);
    }

    const updateScrapProductItem = (data: ScrapProductsGetRequestBody, id: number) => {
        return request(API_METHOD.PUT, replaceUrlParams(SCRAP_PRODUCT_URLS.SCRAP_PRODUCT_ITEM, { id }), authenticatedUser, data);
    }

    const deleteScrapProductItem = (id: number, imageIds: string) => {
        return request(API_METHOD.DELETE, replaceUrlParams(SCRAP_PRODUCT_URLS.DELETE_SCRAP_PRODUCT_ITEM, { id, imageIds }), authenticatedUser);
    }

    return {
        getAllScrapProducts,
        create,
        getScrapProductId,
        updateScrapProductItem,
        deleteScrapProductItem,
    };
};
