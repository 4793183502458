import LinearProgress from '@mui/material/LinearProgress';
import { useCountUp } from 'use-count-up';
import { createUseStyles } from 'react-jss';
import { BULK_UPLOAD_TEMPLATE_POPUP } from '../../../utils/constant';
import excelIcon from "../../../assets/icons/excel.svg";
import ClearIcon from '@mui/icons-material/Clear';
import { useEffect } from 'react';

interface UploadActionProgressTemplateProps {
  uploadSpeed: number;
  fileSize: string;
  setShowPopup: (value: boolean) => void;
  fileName: string;
  progressPercentage: number;
}

const useStyles = createUseStyles((theme: any) => ({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: `${theme.palette.background.primaryLightAccent}80`,
    zIndex: 999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressBarWrapper: {
    width: "974px",
    height: "321px",
    background: "#FFF",
    zIndex: "9",
  },
  contentWrapper: {
    display: "flex",
    width: "710px",
    padding: "12px 24px",
    justifyContent: 'center',
    alignItems: 'center',
    gap: "24px",
    borderRadius: "6px",
    border: `1px solid ${theme.palette.border.primaryDark}`,
  },
  imageWrapper: {
    width: "38px", 
    height: "35px",
  },
  fileDetailsWrapper: {
    width: "610px",
  },
  linearProgressBar: {
    borderRadius: "20px",
    height: "16px",
    background: theme.palette.background.secondaryLight,
  }
}));

const UploadActionProgressTemplate: React.FC<UploadActionProgressTemplateProps> = ({ uploadSpeed, fileSize, setShowPopup, fileName, progressPercentage }) => {

  const classes = useStyles();
  const { value } = useCountUp({
    isCounting: true,
    duration: 5,
    easing: 'linear',
    start: 0,
    end: 100,
    onComplete: () => ({
      shouldRepeat: true,
      delay: 2,
    }),
  });

  const handleClose = () => {
    setShowPopup(false);
  }

  useEffect(() => {
  }, [progressPercentage, uploadSpeed]);

  return (
    <div className={classes.overlay}>
      <div className={`${classes.progressBarWrapper} bg-white rounded-3xl relative overflow-hidden`}>
        <ClearIcon className='absolute right-3 top-8 mr-6 cursor-pointer' onClick={handleClose} />
          <div className='text-center mt-12'>
            <span className={"!text-sky-800 !text-xl font-semibold text-slate-50"}>{BULK_UPLOAD_TEMPLATE_POPUP.PLEASE_WAIT}</span>
            <p className="text-base text-gray-400">{BULK_UPLOAD_TEMPLATE_POPUP.UPLOAD_IN_PROGRESS}</p>
          </div>
          <div className={`${classes.contentWrapper} mt-4 ml-32`}>
            <img src={excelIcon} className={classes.imageWrapper} alt="excel" />
            <div>
              <div className='mb-2'>
                <span className='mr-5'>{fileName}</span>
                <span className='text-gray-400'>{`${fileSize} ${Number(fileSize) >= 1024 ? 'mb' : 'kb'}`}</span>
              </div>
              <LinearProgress
                variant='determinate'
                value={Number(value!)}
                className={classes.linearProgressBar}
              />
              <div className={`${classes.fileDetailsWrapper} flex justify-between mt-2 text-gray-400`}>
                <span>{`${Number(progressPercentage!)}% done`}</span>
                {/* Todo will uncomment once backend change is done */}
                <span>{uploadSpeed}</span>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
}

export default UploadActionProgressTemplate;