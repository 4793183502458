import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import ButtonV2 from "../../atoms/ButtonV2";
import { CUSTOMER_GROUP_SECTIONS } from "../../../utils/types";
import { useNavigate } from "react-router-dom";
import { ADMIN_ROUTES } from "../../../utils/constant";
import addCustomerIcon from "../../../assets/images/addCustomerIcon.svg"
import { changeSectionTo } from "./AddCustomerGroup.template";

interface CustomerGroupConfigurationFormTemplateProps {
    setCurrentSectionTo: (section: CUSTOMER_GROUP_SECTIONS) => void;
    formik: any;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid #EBEFFA`
    },
    filterContainer: {
        borderTop: `1px solid #EBEFFA`
    },
    filterHeading: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    selectLabel: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    configure: {
        color: theme.palette.textV2.primary.primary500,
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600,
    },
    activeProgressBar: {
        backgroundColor: "#CA4F02"
    },
    inActiveProgressBar: {
        backgroundColor: "#FD6202"
    },
    cardContainer: {
        borderRadius: "20px",
        border: `1px solid #FD6202`,
        "&:hover": {
            border: "none",
            backgroundColor: "#FFE0CC"
        },
    },
    cardContainerSelected: {
        borderRadius: "20px",
        border: "none",
        backgroundColor: "#FFE0CC",
    },
    cardLabel: {
        color: theme.palette.textV2.primary.primary500,
    }
}));

enum CARD_TYPES {
	ADDITIONAL = "ADDITIONAL",
}
const CustomerGroupConfigurationFormTemplate: React.FC<CustomerGroupConfigurationFormTemplateProps> = ({ setCurrentSectionTo, formik }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [selectedCard, setSelectedCard] = useState<string>(formik.values.selectedCard);

    const handleCardClick = (card: string) => () => {
        setSelectedCard(card);
        formik.setFieldValue("selectedCard", card);
        if(card === "UPC"){
            setCurrentSectionTo(CUSTOMER_GROUP_SECTIONS.UPC_SEARCH_FORM);
        }else{
            setCurrentSectionTo(CUSTOMER_GROUP_SECTIONS.ADDITIONAL_CUSTOMER_FORM);
        }
    };

    const handleCancel = () => {
        navigate(`${ADMIN_ROUTES.CUSTOMER_GROUP_MASTER_LIST}`);
    };

    return (

        <div className="grid gap-y-4">
            <div className={`w-full m-auto flex justify-between gap-x-2 text-var(--black, #4D4D4D) text-center text-sm `}>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-1 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-2 h-2 mx-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto font-semibold`}>Customer Group Master</div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-1 mx-auto'>
                        <div className={`${classes.inActiveProgressBar} w-2 h-2 mx-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Customer Group Configuration</div>
                    </div>
                    <div className={`${classes.inActiveProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>

            <div className={`${classes.container} p-6 grid gap-y-5 rounded-lg`}>
                <div className="flex justify-between">
                    <div className={`${classes.filterHeading} text-2xl font-medium my-auto`}>Add Customer Group</div>
                </div>

                <div className={`${classes.filterContainer} pt-5 flex gap-x-12`}>
                    <div className={`${selectedCard === 'UPC' ? classes.cardContainerSelected : classes.cardContainer} grid gap-y-6 w-1/2 py-16 cursor-pointer`}
                        onClick={handleCardClick('UPC')}>
                        <img className="mx-auto" src={addCustomerIcon} alt="addCustomerIcon" />
                        <div className={`${classes.cardLabel} mx-auto text-lg font-semibold`}>Add customer via UPC or material</div>
                    </div>
                    <div className={`${selectedCard === CARD_TYPES.ADDITIONAL ? classes.cardContainerSelected : classes.cardContainer} grid gap-y-6 w-1/2 py-16 cursor-pointer`}
                        onClick={handleCardClick(CARD_TYPES.ADDITIONAL)}>
                        <img className="mx-auto" src={addCustomerIcon} alt="addCustomerIcon" />
                        <div className={`${classes.cardLabel} mx-auto text-lg font-semibold`}>Add additional customer</div>
                    </div>
                </div>

                <div className="flex justify-between">
                    <ButtonV2 variant="secondaryContained" size="large" label={"Cancel"} onClick={handleCancel} />
                    <ButtonV2 variant="tertiaryContained" size="large" label={"Back"} onClick={changeSectionTo(setCurrentSectionTo)(CUSTOMER_GROUP_SECTIONS.CUSTOMER_GROUP_MASTER_FORM)} />
                </div>
            </div>
        </div>
    );
};

export default CustomerGroupConfigurationFormTemplate;