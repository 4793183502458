import React, { useContext, useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import FormHelperText from "@mui/material/FormHelperText";
import InventoryContext from "../../../Core/Context/inventory/NewInventoryContext";
import { SET_INVENTORY_FORM_DATA } from "../../../Core/Context/inventory/NewInventoryProvider";
import { CustomButton } from "../../../Shared/Button/CustomButton";
import axios from "axios";
import { useAuthenticatedUser } from "../../../../hooks/useAuthenticatedUser";
const BaseUrl = process.env.REACT_APP_API_V1_URL;
const FormStep2 = () => {
  const { user } = useAuthenticatedUser();
  const navigate = useNavigate();
  const inventoryContextData = useContext(InventoryContext);
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [warehousesFromDb, setWarehousesFromDb] = useState([]);
  let [formData, setFormData] = useState({});
  var inventoryData = null;
  const [formErrors, setFormErrors] = useState({});

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const id = urlParams.get("id");
  const skuMode = urlParams.get("skuMode");
  const mode = urlParams.get("mode");

  useEffect(() => {
    if (
      Object.keys(inventoryContextData.inventoryDetailState.inventoryFormData)
        .length === 0
    ) {
      navigate(
        `/inventory/step1?id=${id}&skuMode=${skuMode}&mode=${mode}`
      );
    }
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    // let trimmedValue = value.toString().trim();
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === "warehouseId") {
      curWarehouseFromDb(value);
      setSelectedWarehouse(value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        warehouse: selectedWarehouse,
      }));
    }

    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [name]: "",
    }));
  };

  const fetchWarehouse = () => {
    const url = `${BaseUrl}/warehouse/all?page=0&size=5&search=AVIGHNA`;
    axios
      .get(url ,{
        headers: {
           Authorization: `${user.token}`,
           "Content-Type": "application/json",
        }
     })
      .then((response) => {
        const { content, totalElements } = response?.data?.data;
        setWarehousesFromDb(content);
        warehousesFromDb.map((curwareh) => {
          return curwareh?.name;
        });
      })
      .catch((err) => {
        console.log("Catalogue Table Error - ", err);
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const regex = /^[0-9\b]+$/;
    const errors = {};
    const fieldsToValidate = [
      "warehouseId",
      "warehousePlacement",
      "basePrice",
      "netWeight",
      "grossWeight",
    ];

    fieldsToValidate?.forEach((field, index) => {
      if (
        !formData[field]?.toString() ||
        formData[field] === undefined ||
        formData[field]?.toString().trim().length === 0
      ) {
        errors[field] = true;
      }
      if(field === "basePrice") {
        if (!/^[1-9]\d*(\.\d+)?$/.test(formData[field]?.toString())) {
          errors["basePrice"] = true;
        }
      }
      if(field === "netWeight") {
        if (!/^[0-9]\d*(\.\d+)?$/.test(formData[field]?.toString())) {
          errors["netWeight"] = true;
        }
      }
      if(field === "grossWeight") {
        if (!/^[0-9]\d*(\.\d+)?$/.test(formData[field]?.toString())) {
          errors["grossWeight"] = true;
        }
      }
      if (field === "warehousePlacement") {
        if (!/^(?=.*[a-zA-Z])[\w\s!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/.test(formData[field]?.toString())) {
          errors["warehousePlacement"] = true;
        }
      }
      formData[field] = formData[field]?.toString().trim();
    });

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    inventoryContextData.updateInventoryDetailsState(
      formData,
      SET_INVENTORY_FORM_DATA
    );

    navigate(`/inventory/step3?id=${id}&skuMode=${skuMode}&mode=${mode}`);
  };
  const curWarehouseFromDb = (warehouseId) => {
    const url = `${BaseUrl}/warehouse/${warehouseId}`;
    axios
      .get(url, {
        headers: {
           Authorization: `${user.token}`,
           "Content-Type": "application/json",
        }
     })
      .then((response) => {
        const { content } = response;
        // setSelectedWarehouse(response.data.name)
      })
      .catch((err) => {
        console.log("Catalogue Table Error - ", err);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  useEffect(() => {
    inventoryData =
      inventoryContextData?.inventoryDetailState?.inventoryFormData;
    fetchWarehouse();
    setFormData(inventoryContextData?.inventoryDetailState?.inventoryFormData);
  }, [inventoryData]);

  return (
    <div className="mt-3 sku-background grid gap-y-5 py-5">
      <div className="flex justify-center text-label font-roboto text-5xl font-semibold">
        {inventoryContextData?.inventoryDetailState?.mode === "ADD"
          ? "Add New"
          : "Edit"}{" "}
        SKU
      </div>
      <div className="w-4/5 mt-4 m-auto flex justify-between gap-x-0.5 text-var(--black, #4D4D4D) text-center font-roboto text-xs font-normal leading-normal">
        <div className="grid gap-y-1 w-1/4">
          <div className="h-3 bg-blue"></div>
        </div>
        <div className="grid gap-y-1 w-1/4">
          <div className="h-3 bg-blue"></div>
        </div>
        <div className="grid gap-y-1 w-1/4">
          <div className="h-3 bg-gray-300"></div>
        </div>
        <div className="grid gap-y-1 w-1/4">
          <div className="h-3 bg-gray-300"></div>
        </div>
      </div>
      <div className=" search-upc-container grid w-1/2 gap-y-4 p-6   m-auto  my-8  bg-white">
        <div className="product-description">
          <h2>Please Enter Details</h2>
        </div>
        <form className="flex flex-col gap-y-5" onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
          <div className="form-container flex flex-col grow">
            <FormControl fullWidth error={!!formErrors?.warehouse}>
              <InputLabel id="label">Select Warehouse</InputLabel>
              <Select
                name="warehouseId"
                value={formData?.warehouseId || ""}
                onChange={handleChange}
                id="select"
                label="Select Warehouse*"
                error={!!formErrors?.warehouseId}
              >
                {warehousesFromDb.map((curwarehouse, index) => (
                  <MenuItem key={index} value={curwarehouse?.id}>
                    {curwarehouse?.name}
                  </MenuItem>
                ))}
              </Select>
              {formErrors?.warehouseId && (
                <FormHelperText style={{ color: '#d32f2f' }}>
                  Choose your warehouse from the list
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div>
            <FormControl fullWidth error={!!formErrors?.warehousePlacement}>
              <TextField
                name="warehousePlacement"
                value={formData?.warehousePlacement}
                onChange={handleChange}
                id="outlined-basic"
                label=" Warehouse Placement*"
                type="text"
                variant="outlined"
                fullWidth
                error={!!formErrors?.warehousePlacement}
                helperText={
                  formErrors?.warehousePlacement &&
                  "Please enter valid warehouse placement"
                }
              />
            </FormControl>
          </div>
          <div className="flex">
            <TextField
              name="basePrice"
              value={formData?.basePrice || ""}
              onChange={handleChange}
              id="outlined-basic"
              label=" Price on Invoice Per MT*"
              type="number"
              variant="outlined"
              fullWidth
              error={!!formErrors?.basePrice}
              helperText={formErrors?.basePrice && "Please enter invoice Price"}
            />
          </div>
          <div className="flex">
            <TextField
              name="netWeight"
              value={formData?.netWeight}
              onChange={handleChange}
              id="outlined-basic"
              type="number"
              label=" Net Weight in MT*"
              variant="outlined"
              fullWidth
              error={!!formErrors?.netWeight}
              helperText={formErrors?.netWeight && "Please enter net weight"}
            />
            <div className="search-button-container h-14 px-4 py-4 rounded-r bg-gray-300">
              MT
            </div>
          </div>
          <div className="flex">
            <TextField
              name="grossWeight"
              value={formData?.grossWeight}
              onChange={handleChange}
              id="outlined-basic"
              type="number"
              label=" Gross Weight in MT*"
              variant="outlined"
              fullWidth
              error={!!formErrors?.grossWeight}
              helperText={
                formErrors?.grossWeight && "Please enter Gross weight"
              }
            />
            <div className="search-button-container h-14 px-4 py-4 rounded-r bg-gray-300">
              MT
            </div>
          </div>
          <div className="text-center flex gap-x-4  ">
            <CustomButton
              className="w-full"
              onClick={() => {
                navigate(
                  `/inventory/step1?id=${urlParams.get(
                    "id"
                  )}&skuMode=${urlParams.get("skuMode")}&mode=${urlParams.get(
                    "mode"
                  )}`
                );
              }}
            >
              Back
            </CustomButton>
            <CustomButton className="w-full" variant="Primary" type="submit">
              Next
            </CustomButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormStep2;
