import { createUseStyles } from "react-jss";
import ButtonV2 from "../../atoms/ButtonV2";
import { getProfileDisplayData } from "../../../utils/helper";
import { IBusinessProfile } from "../../pages/CustomerManagement/ProfileOverview.page";

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.primarylightAccent}`,
    },
    addressContainer: {
        border: `1px solid ${theme.palette.border.primarylightAccent}`,
    },
    heading: {
        color: theme.palette.text.secondaryDark,
    },
    value: {
        "& span": {
            color: theme.palette.text.primaryLight,
        },
        "& p": {
            color: theme.palette.text.primaryLight,
        }
    }
}));

interface ICINViewTemplateProps {
    businessProfile: IBusinessProfile | null;
    setIsEditing: (value: boolean) => void;
}

const CINViewTemplate: React.FC<ICINViewTemplateProps> = ({ businessProfile, setIsEditing }) => {

    const classes = useStyles();

    return (
        <div className={`${classes.container} p-6 rounded-lg w-full`}>
            <div className='flex justify-between items-center pb-6 border-b border-grey-500'>
                <h2 className={`${classes.heading} text-2xl font-medium`}>CIN Details</h2>
                <ButtonV2 variant={'tertiaryContained'} label={'Edit'} onClick={() => setIsEditing(true)} />
            </div>
            {businessProfile?.cin?.entityType && (
                <div>
                    <div className='grid grid-cols-3 gap-x-9 py-6'>
                        <div className={classes.value}>
                            <span >Entity Type</span>
                            <p className='font-medium'>{getProfileDisplayData(businessProfile?.cin?.entityType)}</p>
                        </div>
                        <div className={classes.value}>
                            <span>Date of Incorporation</span>
                            <p className='font-medium'>{getProfileDisplayData(businessProfile?.cin?.dateOfIncorportation)}</p>
                        </div>
                        <div className={classes.value}>
                            <span>CIN Number</span>
                            <p className='font-medium'>{getProfileDisplayData(businessProfile?.cin?.cin)}</p>
                        </div>
                    </div>

                    <div className={`${classes.addressContainer} p-4 rounded-xl w-1/2`}>
                        <h5 className={`${classes.heading} font-medium text-base mb-8`}>Registered Address as per ROC</h5>
                        <div className={`${classes.value} max-w-lg mb-3 break-all`}>
                            <span>Address Line 1</span>
                            <p className='font-medium'>{getProfileDisplayData(businessProfile?.cinRegisteredAddress?.line1)}</p>
                        </div>
                        <div className={`${classes.value} max-w-lg mb-3 break-all`}>
                            <span>Address Line 2</span>
                            <p className='font-medium'>{getProfileDisplayData(businessProfile?.cinRegisteredAddress?.line2)}</p>
                        </div>
                        <div className='grid grid-cols-3 gap-3'>
                            <div className={classes.value}>
                                <span>Pincode</span>
                                <p className='font-medium'>{getProfileDisplayData(businessProfile?.cinRegisteredAddress?.pincode)}</p>
                            </div>
                            <div className={classes.value}>
                                <span>City</span>
                                <p className='font-medium'>{getProfileDisplayData(businessProfile?.cinRegisteredAddress?.city)}</p>
                            </div>
                            <div className={classes.value}>
                                <span>State</span>
                                <p className='font-medium'>{getProfileDisplayData(businessProfile?.cinRegisteredAddress?.state)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default CINViewTemplate;
