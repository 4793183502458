import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { FormControl } from '@mui/material';
import { Category, useCategoryService } from '../../../../services/useCategoryService';
import { MODE } from '../../../../utils/constant';
import { CATEGORY_TYPE, HTTP_STATUS } from '../../../../utils/types';
import AutoCompleteTextField, { AutoCompleteOption } from '../../../molecules/AutoCompleteInput/AutoCompleteInput';

interface CategorySearchTemplateProps {
    handleSpecification: (productCategory: Category) => void;
    formik: any;
    mode: string;
}

const useStyles = createUseStyles((theme: any) => ({
    errorMessage: {
        color: theme.palette.action.danger,
    },
}));

const CategorySearchTemplate: React.FC<CategorySearchTemplateProps> = ({ handleSpecification, formik, mode }) => {
    const classes = useStyles();
    const categoryService = useCategoryService();
    const [superCategoryData, setSuperCategoryData] = useState<Category[] | null>(null);
    const [mainCategoryData, setMainCategoryData] = useState<Category[] | null>(null);
    const [productCategoryData, setProductCategoryData] = useState<Category[] | null>([]);

    useEffect(() => {
        if (formik?.values?.productCategory?.id) {
            if (Array.isArray(productCategoryOptions)) {
                const productAttributes = productCategoryData?.find((productCategory) => {
                    return productCategory.id === formik?.values?.productCategory?.id;
                });
                if (productAttributes) {
                    handleSpecification(productAttributes);
                }
            }
        }
    }, [formik?.values?.productCategory?.id])

    const loadSuperCategory = (inputValue?: string) => {
        categoryService.getAllCategories({ level: CATEGORY_TYPE.SUPER_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', name: inputValue ?? '', status: 'ACTIVE' })
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setSuperCategoryData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Category: ", error);
            })
    }

    const loadMainCategory = (inputValue?: string) => {
        if (formik?.values?.superCategory?.id) {
            categoryService.getAllCategories({ level: CATEGORY_TYPE.MAIN_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: formik?.values?.superCategory?.id ?? '', name: inputValue ?? '', status: 'ACTIVE' })
                .then(res => {
                    if (res.status === HTTP_STATUS.OK)
                        setMainCategoryData(res?.data?.data?.content)
                }).catch((error) => {
                    console.error("Error Fetching Category: ", error);
                })
        }
    }

    const loadProductCategory = (inputValue?: string) => {
        if (formik?.values?.mainCategory?.id) {
            categoryService.getAllCategories({ level: CATEGORY_TYPE.PRODUCT_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: formik?.values?.mainCategory?.id ?? '', name: inputValue ?? '', status: 'ACTIVE' })
                .then(res => {
                    if (res.status === HTTP_STATUS.OK)
                        setProductCategoryData(res?.data?.data?.content)
                }).catch((error) => {
                    console.error("Error Fetching Category: ", error);
                })
        }
    }

    const updateSelectedCategory = (key: string) => (option: AutoCompleteOption | null) => {
        formik.setFieldValue(key, option);
        if(key === "superCategory"){
            formik.setFieldValue("mainCategory", {label: "", id: null});
            formik.setFieldValue("productCategory", {label: "", id: null});
        }
        if(key === "mainCategory"){
            formik.setFieldValue("productCategory", {label: "", id: null});
        }
    };

    const superCategoryOptions = useMemo(() => {
        return superCategoryData?.map((superCategory: Category) => ({ label: superCategory.name, id: superCategory.id })) ?? []
    }, [superCategoryData])

    const mainCategoryOptions = useMemo(() => {
        return mainCategoryData?.map((mainCategory: Category) => ({ label: mainCategory.name, id: mainCategory.id })) ?? []
    }, [mainCategoryData])

    const productCategoryOptions = useMemo(() => {
        return productCategoryData?.map((productCategory: Category) => ({ label: productCategory.name, id: productCategory.id })) ?? []
    }, [productCategoryData])

    useEffect(() => {
        loadSuperCategory();
        loadMainCategory();
        loadProductCategory();
    }, [formik]);

    return (
        <div className='w-full flex gap-x-3'>
            <div className='w-full grid'>
                <FormControl fullWidth>
                    <AutoCompleteTextField
                        options={superCategoryOptions}
                        label="Select Super Category"
                        onChange={updateSelectedCategory('superCategory')}
                        onSearch={loadSuperCategory}
                        isDisabled={mode !== MODE.ADD}
                        value={formik.values.superCategory}
                        error={!!formik.errors.superCategory && formik.touched.superCategory}
                    />
                    {formik.errors.superCategory?.id && formik.touched.superCategory?.id && (
                        <div className={`${classes.errorMessage} text-xs`}>
                            {formik.errors.superCategory?.id}
                        </div>
                    )}
                </FormControl>
            </div>

            <div className='w-full grid'>
                <FormControl fullWidth>
                    <AutoCompleteTextField
                        options={mainCategoryOptions}
                        label="Select Main Category"
                        onChange={updateSelectedCategory('mainCategory')}
                        onSearch={loadMainCategory}
                        isDisabled={mode !== MODE.ADD}
                        value={formik.values.mainCategory}
                        error={!!formik.errors.mainCategory && formik.touched.mainCategory}
                    />
                    {formik.errors.mainCategory?.id && formik.touched.mainCategory?.id && (
                        <div className={`${classes.errorMessage} text-xs`}>
                            {formik.errors.mainCategory?.id}
                        </div>
                    )}
                </FormControl>
            </div>

            <div className='w-full grid'>
                <FormControl fullWidth>
                    <AutoCompleteTextField
                        options={productCategoryOptions}
                        label="Select Product Category"
                        onChange={updateSelectedCategory('productCategory')}
                        onSearch={loadProductCategory}
                        isDisabled={mode !== MODE.ADD}
                        value={formik.values.productCategory}
                        error={!!formik.errors.productCategory && formik.touched.productCategory}
                    />
                    {formik.errors.productCategory?.id && formik.touched.productCategory?.id && (
                        <div className={`${classes.errorMessage} text-xs`}>
                            {formik.errors.productCategory?.id}
                        </div>
                    )}
                </FormControl>
            </div>
        </div>
    );
};

export default CategorySearchTemplate;
