import { createUseStyles } from "react-jss";
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";
import ButtonV2 from "../../atoms/ButtonV2";
import { useMasterUpcService } from "../../../services/useMasterUpcService";
import FilterChip, { IMultiSelectOption } from "../../molecules/FilterChip/FilterChip";
import { useSnackbar } from "../../../hooks/useSnackBar";
import { ICustomerMasterUpcs } from "./MasterCustomerWithUpc.template";

interface AddMasterCustomerModalTemplateProps {
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
    userNameOptionsTo: IMultiSelectOption[];
    setUserNameOptionsTo: (options: IMultiSelectOption[]) => void;
    handleUserSelectionChange: (selection: IMultiSelectOption[]) => void;
    selectedUserValues: IMultiSelectOption[];
    setSelectedUserValuesTo: (selected: IMultiSelectOption[]) => void;
    searchTerm: string;
    setSearchTerm: (search: string) => void;
    setCustomerDetails: (details: ICustomerMasterUpcs[]) => void;
    mapNewCustomerWithUpc: () => void;

}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        "& .MuiDialog-paper": {
            width: "783px !important",
            maxWidth: "1297px !important",
            height: "250px",
            borderRadius: "16px"
        }
    },
    image: {
        height: "687px"
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    subHeading: {
        color: theme.palette.textV2.primary.primary900,
    },
    closeIcon: {
        // color: "red"
    }
}));

const AddMasterCustomerModalTemplate: React.FC<AddMasterCustomerModalTemplateProps> = ({ dialogOpen, setDialogOpen, userNameOptionsTo, setUserNameOptionsTo, handleUserSelectionChange, selectedUserValues, setSelectedUserValuesTo, searchTerm, setSearchTerm, mapNewCustomerWithUpc }) => {
    const classes = useStyles();
    const masterUpcService = useMasterUpcService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();


    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleAddCustomerDetails = () => {
        if (selectedUserValues?.length === 0) {
            showSnackbar("error", "Kindly select a customer.");
        }
        else {
            mapNewCustomerWithUpc();
        }
    };


    return (
        <>
            <Dialog fullWidth className={classes.container}
                open={dialogOpen} onClose={handleDialogClose}>
                <div className={`flex gap-x-6 px-6 mb-6`}>
                    <div className="grid gap-y-6 mt-5 m-auto flex-1">
                        <div className="flex justify-between">
                            <div>
                                <div className={`${classes.heading} text-2xl font-medium`}>Add New Customer</div>
                            </div>
                            <div className={`cursor-pointer`} onClick={handleDialogClose}>
                                <CloseIcon className={`${classes.closeIcon}`} />
                            </div>
                        </div>
                        <div>
                            <FilterChip
                                options={userNameOptionsTo}
                                label="Search Customer ID or Customer Name"
                                value={selectedUserValues}
                                onchange={handleUserSelectionChange}
                                placeholder="Search Customer ID or Name "
                                ButtonComponent={null}
                                textFieldPlaceholder='Search...'
                                textFieldLabel="Enter Customer Id or Name"
                                searchTerm={searchTerm}
                                setSearchTerm={setSearchTerm}
                                maxHeight="40%"
                            />
                        </div>
                    </div>
                    <div className="flex absolute bottom-4 right-4">
                        <div className="mr-6">
                            <ButtonV2 variant="tertiaryContained"
                                onClick={handleDialogClose}
                                label={"Back"} />
                        </div>
                        <ButtonV2 variant="primaryContained"
                            onClick={handleAddCustomerDetails}
                            label={"+Add"} />
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default AddMasterCustomerModalTemplate;
