import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { DisplayDocuments } from "../../Shared/Files/FileItem";

import { FilesWrapper } from "../../Shared/Files/DisplayFiles";
import CatalogueEnum from "./CatalogueEnum";
import UpcDetailItem from "./UpcDetailsDisplay";
import { useLocation } from 'react-router-dom';
import CataloguePackingTypes from "./CataloguePackingTypes";
import CatalogueUserStatusTable from "./WhatsAppIntegration/Users/CatalogueUserStatusTable";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { makeRoute } from "../../../utils/helper";
import { CATALOGUE_TYPES, CLASS_TYPE,ADMIN_ROUTES } from "./../../../utils/constant";

const BaseUrl = process.env.REACT_APP_API_V1_URL;
const UPCDisplayDetails = ({ mode, cataloguUpcDetails, updateUPCDetails, type }) => {

  useEffect(() => {
  }, []);

  const handleMarginChange = (id, value) => {
    const updatedDetails = cataloguUpcDetails.map((detail) =>
      detail.id === id ? { ...detail, margin: value } : detail
    );
    updateUPCDetails(updatedDetails);
  };

  const handleStatusChange = (id, value) => {
    const updatedDetails = cataloguUpcDetails.map((detail) =>
      detail.id === id ? { ...detail, status: value } : detail
    );
    updateUPCDetails(updatedDetails);
  };

  const handleRemoveUPCDetails = (id) => {
    const updatedDetails = cataloguUpcDetails.filter((detail) => detail.id !== id);
    updateUPCDetails(updatedDetails);
  };

  return (
    <>
      {cataloguUpcDetails &&
        cataloguUpcDetails.map((upcDetail, index) => (

          <UpcDetailItem
            mode={mode}
            index={index}
            upcDetail={upcDetail}
            priceDetails={upcDetail.priceRange}
            formErrors={null}
            handleRemoveUPCDetails={handleRemoveUPCDetails}
            handleStatusChange={handleStatusChange}
            handleMarginChange={handleMarginChange}
            type={type}
          />

        ))}
    </>
  );
};

const ViewCatalogue = ({ share = false, nextStage }) => {
  const { user } = useAuthenticatedUser();
  const catalogueMode = "VIEW";
  const navigate = useNavigate();
  const location = useLocation();
  const { catalogueRecordId, category, catalogueType } = location.state || {};
  const [selectedCategory, setSelectedCatagory] = useState(category || "STANDARD");

  const [catalogueId, setCatalogueId] = useState(catalogueRecordId);
  const [catalogueDetails, setCatalogueDetails] = useState([]);
  const [packingTypes, setPackingTypes] = useState([]);
  const [selectedPackagingTypes, setSelectedPackingTypes] = useState([]);

  const navigateToEditCatalogue = (id) => {
    navigate("/catalogue/edit", {
      state: {
        catalogueRecordId: id,
        category: selectedCategory,
        catalogueType: catalogueType
      }
    });
  };

  const navigateCatalogueTable = () => {
    navigate(-1);
    return
  }

  // Images Uploaded
  const [imageData, setImageData] = useState([]);

  const modifyUPCDetails = (upcDetail) => {
    const modifiedUPCDetail = {
      ...upcDetail,
      upcCode: "JSW-HRPO-1000037",
      manufactureName: "JSW Steel Ltd",
      brand: "Tata Astrum",
      superCategory: "Carbon Flat Rolled",
      mainCategory: "Hot Rolled",
      productCategory: "Carbon Flat Rolled",
      grade: "Double Reduced",
      standard: "IS 1993",
      thickness: "1.5mm",
      width: "2mm",
      class: "Standard Prime",
      shape: "Single Length Coil",
      hsCode: "123245",
    };

    return modifiedUPCDetail;
  };

  const updateUPCDetails = (updatedDetails) => {
    setCatalogueDetails(updatedDetails);
  };

  const getPackagingTypes = (initialCatalogueList, ConfigurationData) => {
    // const packagingItems = ConfigurationData.find((configurationItem) => configurationItem.context === 'PACKAGING');
    const packagingData = ConfigurationData[0]?.data;
    // Set initialCatalogueList to an empty array if it's null
    if (initialCatalogueList === null) {
      initialCatalogueList = [];
    }

    if (Array.isArray(packagingData) && packagingData.length > 0) {

      const compareMap = {};
      const cataloguePackagingTypes = new Map(initialCatalogueList.map(item => [item, true]));
      const matchedList = [];
      packagingData.forEach(packaging => {
        if (cataloguePackagingTypes.has(packaging.value)) {
          matchedList.push(packaging);
        }
      });
      setSelectedPackingTypes(matchedList);
      return packagingData;
    }
    return [];
  }

  const fetchConfiguration = async (initialList) => {
    const baseUrl = `${BaseUrl}/configurations/`;
    axios.get(baseUrl,
      {
        params: {
          contexts: "PACKAGING"
        },
        headers: {
          Authorization: `${user.token}`,
          "Content-Type": "application/json",
        },
      }
    ).then((response) => {
      if (response.data && response.data.data) {
        const packagingTypeList = getPackagingTypes(initialList, response.data.data);
        setPackingTypes(packagingTypeList);
        return;
      }
    })
      .catch((err) => {
        return
      });
  }


  const fetchDocuments = async (resId, resType) => {
    try {
      if (!resId || !resType) {
        return;
      }
      const apiUrl = `${BaseUrl}/files/${resType}/${resId}`;

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `${user.token}`
        }
      }).then((res) => {
        setImageData(res.data.data);
        return;
      });
    } catch (err) {
      return;
    }
  };

  const fetchCatalogueDetails = async (catalogueId) => {
    try {
      if (!catalogueId) {
        return;
      }

      const apiUrl = `${BaseUrl}/catalogue/${catalogueId}`;
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `${user.token}`,
          "Content-Type": "application/json",
        }
      });
      const fetchedCatalogueData = response.data.data;
      setCatalogueDetails(fetchedCatalogueData);
      await fetchConfiguration(fetchedCatalogueData?.packagingTypes);

    } catch (err) {
      return;
    }
  };

  useEffect(() => {
    fetchCatalogueDetails(catalogueId);
    fetchDocuments(catalogueId, "CATALOGUE");
  }, []);

  const CatalogueStatus = ({ statusValue }) => {
    return (
      <FormControl fullWidth className="bg-white">
        <InputLabel id="label">Select Status</InputLabel>
        <Select
          labelId="label"
          id="select"
          label="Select Status*"
          value={statusValue}
          disabled={catalogueMode === "view"}
        >
          <MenuItem value={"PUBLISHED"}>Published</MenuItem>
          <MenuItem value={"UNPUBLISHED"}>Unpublished</MenuItem>
        </Select>
      </FormControl>
    );
  };


  const ProductCatalogueDetails = ({ catalogueDetails, catalogueMode }) => {

    return (
      <div className="relative w-full flex flex-col items-end justify-start text-left text-base text-text-color font-input-label">
        <div className="self-stretch bg-white shadow-[0px_1px_4px_rgba(0,_0,_0,_0.12)] flex flex-col pt-6 px-10 pb-10 items-start justify-start gap-[18px]">
          <div className="self-stretch flex flex-col items-start justify-start">
            <div className="self-stretch flex flex-col items-start justify-start gap-[22px]">
              <div className="self-stretch flex flex-row items-center justify-start gap-[22px] text-[20px] text-blue2">

                <div className="flex-1 relative font-semibold">
                  {catalogueDetails.name} (<span className="font-semi-bold">{CatalogueEnum[catalogueDetails.category]}</span>)
                </div>
                {
                  !share && (
                    <>
                      <div className="w-[196px] h-12 flex flex-row py-[8.5px] px-3.5 items-center justify-start gap-[8px] text-base text-text-color ">
                        <CatalogueStatus statusValue={catalogueDetails.status} />
                      </div>

                      <button
                        className="rounded bg-customBlue overflow-hidden flex py-2 px-4 items-center justify-center text-lg text-white font-semibold"
                        onClick={() => navigateToEditCatalogue(catalogueRecordId)}
                      >
                        Edit
                      </button>
                    </>
                  )
                }


              </div>
              <div className="flex flex-col items-start justify-start gap-[12px]">
                <div className="relative font-semibold inline-block w-[345px] h-[19px] shrink-0">
                  Description
                </div>
                <div className="relative leading-[23px] inline-block w-[1111px]">
                  {catalogueDetails.description}
                </div>
              </div>

              {!share && (
                <FormControl fullWidth>
                  <CataloguePackingTypes packingTypesData={packingTypes} formErrors={null} initialValues={selectedPackagingTypes} updateFunction={setSelectedPackingTypes} mode={"VIEW"} />
                </FormControl>
              )}


              {/* UPC Details  */}
              <UPCDisplayDetails
                mode={catalogueMode}
                cataloguUpcDetails={catalogueDetails.upcMappings}
                updateUPCDetails={updateUPCDetails}
                type={catalogueDetails.category}
              />

              <FilesWrapper heading={"Product Images"}>
                <DisplayDocuments
                  documentsData={imageData}
                  isEdit={false}
                  isForm={false}
                // deleteDocument={deleteDocument}
                />
              </FilesWrapper>

              {!share && (
                <>
                  <div className="flex-1 relative font-semibold mt-5">Catalogue Whatsapp Status</div>
                  <CatalogueUserStatusTable
                    catalogueId={catalogueRecordId}
                  />
                </>)}
                </div>
            <div className='flex justify-end space-x-4 mr-9 mt-8'>
              <button
                className='rounded bg-white text-gray-700 border border-gray-300  overflow-hidden flex flex-row py-[0.81rem] px-[1.63rem] items-center justify-center text-[0.94rem]'
                onClick={() => { share ? nextStage(2) : navigateCatalogueTable() }}
              >
                <div className='relative leading-[1.38rem] font-semibold'>
                  {share ? 'Next' : 'Cancel'}
                </div>
              </button>
            </div>


          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <ProductCatalogueDetails
        catalogueDetails={catalogueDetails}
        catalogueMode={catalogueMode}
      />

    </div>
  );
};

export default ViewCatalogue;
