import React from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';

interface SocialMediaLink {
    key: string;
    link: string;
    logo: string;
}

interface SocialMediaLinksProps {
    schema: SocialMediaLink[];
    className?: string;
}

const useStyles = createUseStyles((theme: any) => ({
    icon: {
        color: theme.palette.button.primaryDark,
        width: "33px",
    }
}));

const SocialMediaLinks: React.FC<SocialMediaLinksProps> = ({ schema, className }) => {
    const classes = useStyles();
    return (
        <div className={`flex gap-x-2 ${className}`}>
            {schema.map((item) => (
                <Link key={item.key} to={item.link} target="_blank" rel="noopener noreferrer">
                    <img  src={item.logo} alt={item.key} className={`${classes.icon} cursor-pointer`} />
                </Link>
            ))}
        </div>
    );
};

export default SocialMediaLinks;
