import { useCallback, useState } from "react";
import cancelIcon from "../../../assets/icons/cancel.svg";
import viewEyeIcon from "../../../assets/icons/viewEyeIcon.svg";
import { ImageData } from "../../organisms/ImageUploader";


interface ImageProps {
    index: number;
    imageData: ImageData | File;
    isEditable: boolean;
    handleDeleteClick:(index: number ) => void ;
    handleViewClick: (path: string) => void;
}

const Image: React.FC<ImageProps> = ({ index, imageData , isEditable, handleDeleteClick, handleViewClick }) => {

    const [isHovered, setIsHovered] = useState(false);
    const onHandleDeleteClick = (index: number) => () => {   
        handleDeleteClick(index);
    };
    const onHandleViewClick = (path: string) => () => {
        handleViewClick(path);
    };
    const onMouseEnter = useCallback(() => {
        setIsHovered(true);
    }, []);
    const onMouseLeave = useCallback(() => {
        setIsHovered(false);
    }, []);

    return (
        <div
            className="relative w-32 h-[142px]"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <img
                className="absolute h-full w-full top-0 right-0 bottom-0 left-0 max-w-full overflow-hidden max-h-full object-cover"
                alt=""
            src={(imageData instanceof File ? URL.createObjectURL(imageData) : imageData.path )}
            />
            <div
                className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center"
            >
                {isEditable && (
                    <button
                        type="button"
                        className="w-2 h-2 blue"
                        onClick={onHandleDeleteClick(index)}
                    >
                        <div className="absolute top-1 right-1 bg-orange-500 p-1 flex items-center justify-center">
                            <img className="w-2 h-2" alt="calcelEyeIcon" src={cancelIcon} />
                        </div>
                    </button>
                )}
                {isHovered && (
                    <button
                    type="button"
                        className="w-7 h-7"
                        onClick={onHandleViewClick(imageData instanceof File ? URL.createObjectURL(imageData) : imageData.path )}
                    >
                        <img className="w-7 h-7" alt="viewEyeIcon" src={viewEyeIcon} />
                    </button>
                )}
            </div>
        </div>
    );
};

export default Image;