import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import noDataImage from "../../../assets/images/noDataImage.svg"
import ButtonV2 from "../../atoms/ButtonV2";
import { ADMIN_ROUTES, initialPaginationValues } from "../../../utils/constant";
import { HTTP_STATUS, IPagination } from "../../../utils/types";
import { ICustomerGroupMasterQueryParams, useCustomerGroupMasterService } from "../../../services/useCustomerGroupMasterService";
import CustomerGroupMasterListTemplate from "../../templates/CustomerGroupMaster/CustomerGroupMasterList.template";

const initialCustomerGroupMasterFilters: ICustomerGroupMasterQueryParams = {
    groupIds: [],
    groupType: [],
    groupOwner: [],
    city: [],
    businessClassification: [],
    turnOver: [],
};

export interface ICustomerGroupMaster {
    groupId: number;
    groupCode: string;
    groupName: string;
    groupOwner: string;
    productCategoryName: string;
    productCategoryId: number;
    customerCount: number;
    description: string;
    createdBy: number;
    creatorName: string;
    groupType: string;
    industryType: string;
    createdAt: Date; 
    updatedAt: Date; 
}

export interface ICutomerGroupSearchFilter {
    sort: string;
    groupName: string;
}

const initialCutomerGroupSearchFiltersValues: ICutomerGroupSearchFilter = {
    sort: "createdAt,desc",
    groupName: "",
}

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary900,
    },
    noData: {
        backgroundColor: "#EBEFFA"
    },
    description: {
        color: theme.palette.textV2.secondary.secondary800,
    }
}));

const CustomerGroupMasterListPage: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const customerGroupService = useCustomerGroupMasterService()
    const [customerGroupMasters, setCustomerGroupMastersTo] = useState<ICustomerGroupMaster[]>([]);
    const [customerGroupMasterFilters, setCustomerGroupMasterFiltersTo] = useState<any>(initialCustomerGroupMasterFilters);
    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);
    const [cutomerGroupSearchFilters, setCutomerGroupSearchFiltersTo] = useState<ICutomerGroupSearchFilter>(initialCutomerGroupSearchFiltersValues);

    const refreshCustomerGroupMasters = async (page: number, size: number) => {
        try {
            const payload: ICustomerGroupMasterQueryParams = {
                groupIds: customerGroupMasterFilters?.groupIds?.length > 0 ? customerGroupMasterFilters?.groupIds : null,
                groupType: customerGroupMasterFilters?.groupType?.length > 0 ? customerGroupMasterFilters?.groupType : null,
                groupOwner: customerGroupMasterFilters?.groupOwner?.length > 0 ? customerGroupMasterFilters?.groupOwner : null,
                city: customerGroupMasterFilters?.city?.length > 0 ? customerGroupMasterFilters?.city : null,
                businessClassification: customerGroupMasterFilters?.businessClassification?.length > 0 ? customerGroupMasterFilters?.businessClassification : null,
                turnOver: customerGroupMasterFilters?.turnOver?.length > 0 ? customerGroupMasterFilters?.turnOver : null,
              };
            const customerGroupMasterResponse = await customerGroupService.getAllCustomerGroup(
                {
                    page: page,
                    size: size,
                    sort: "createdAt,desc",
                    groupName: cutomerGroupSearchFilters?.groupName.trim(),
                },
                payload
            );
            if (
                customerGroupMasterResponse?.status === HTTP_STATUS.OK &&
                customerGroupMasterResponse?.data?.data?.content?.length
            ) {
                setCustomerGroupMastersTo(customerGroupMasterResponse.data.data.content);
                setPaginationTo((prevPagination: IPagination) => ({
                    ...prevPagination,
                    totalRecords: customerGroupMasterResponse.data.data.totalElements,
                    totalPages: customerGroupMasterResponse.data.data.totalPages,
                }));
            } else {
                throw new Error("Customer Group fetch failed");
            }
        } catch (error) {
            setCustomerGroupMastersTo([]);
        }
    };

    const handleFiltersChange = (event: any) => {
        const { name, value } = event.target;
        setPaginationTo({...pagination, currentPage: 0})
        setCutomerGroupSearchFiltersTo({ ...cutomerGroupSearchFilters, [name]: value ?? "" });
    };

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage
        }));
    };

    useEffect(() => {
        refreshCustomerGroupMasters(pagination.currentPage, pagination.pageSize);
    }, [pagination.currentPage, pagination.pageSize, customerGroupMasterFilters, cutomerGroupSearchFilters.groupName]);

    const handleCreate = () => {
        navigate(`${ADMIN_ROUTES.CUSTOMER_GROUP_MASTER_CREATE}`);
    };

    return (
        <div className="grid gap-y-8">
            <div className={` ${classes.mainHeading} text-2xl font-medium`}>Customer Group Master List</div>

            {customerGroupMasters.length >= 0 ?
                (
                    <CustomerGroupMasterListTemplate
                        customerGroupMasters={customerGroupMasters}
                        pagination={pagination}
                        handlePaginationChange={handlePaginationChange}
                        handleRowsPerPageChange={handleRowsPerPageChange}
                        handleFiltersChange={ handleFiltersChange }
                        cutomerGroupSearchFilters = { cutomerGroupSearchFilters }
                        customerGroupMasterFilters = { customerGroupMasterFilters }
                        setCustomerGroupMasterFiltersTo={setCustomerGroupMasterFiltersTo}
                        
                    />
                )
                :
                (
                    <div className={`${classes.noData} py-14 px-6 grid gap-y-4 justify-center rounded-lg`}>
                        <div className="grid gap-y-8 items-center">
                            <img className="mx-auto" src={noDataImage} alt="noDataImage" />
                            <div className={` ${classes.description} text-lg`}>You haven't provided Customer Group master list for your business. </div>
                        </div>
                        <div className="flex justify-center">
                            <ButtonV2 variant="primaryContained" size="large" label={"+ Create New Customer Group"} onClick={handleCreate} />
                        </div>
                    </div>
                )
            }             
        </div>
    );
};

export default CustomerGroupMasterListPage;