import React, { useState } from "react";
import { AUTH_STATES, HTTP_STATUS } from "../../../utils/types";
import { IconButton, InputAdornment, } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { useAuthService } from "../../../services/useAuthService";
import { useSnackbar } from "../../../hooks/useSnackBar";
import * as Yup from "yup";
import { useFormik } from "formik";
import { REGEX } from "../../../utils/constant";
import { Classes } from 'jss';
import TextField from "../../atoms/TextField";
import AuthFooter from "../../molecules/AuthFooter/AuthFooter";
import Button from "../../atoms/Button";

interface LoginWithEmailTemplateProps {
    setAuthState: (state: AUTH_STATES) => void;
    styles: Classes<any>;
}

const initialValues = {
    email: '',
    password: ''
}

const LoginWithEmailTemplate: React.FC<LoginWithEmailTemplateProps> = ({ setAuthState, styles }) => {
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const { setAuthenticatedUser } = useAuthenticatedUser();
    const authService = useAuthService();
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const validationSchema = Yup.object({
        email: Yup.string()
            .email("Invalid email address")
            .required("Email is required")
            .matches(REGEX.EMAIL, 'Please enter valid email'),
        password: Yup.string().required("Password is required"),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            authService.login(values)
                .then((res) => {
                    if (res?.status === HTTP_STATUS.OK) {
                        let { user, token } = res?.data?.data ?? {};
                        setAuthenticatedUser({ ...user, token })
                    } else if (res?.status === HTTP_STATUS.BAD_REQUEST) {
                        showSnackbar('error', res?.data?.statusMessage);
                    } else showSnackbar('error', 'Server not responding, Please try again later')
                }).catch(error => {
                    console.error("Login with Email:", error)
                }).finally(() => {
                    setSubmitting(false);
                })
        }
    });

    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const setAuthStateTo = (authState: AUTH_STATES) => () => setAuthState(authState);
    
    return (
        <div className="auth-right-child-container p-10 flex flex-col h-full">
            {SnackBarComponent}
            <div className="form-header mb-9">
                <h1 className="text-3xl font-bold sm:text-fs-24 md:text-fs-24">Sign In</h1>
                <p className="text-base font-normal sm:text-sm md:text-sm">Stay updated on your professional world</p>
            </div>
            <div className="form-container flex flex-col grow">
                <form className="flex flex-col gap-y-5" onSubmit={formik.handleSubmit}>
                    <div className="flex flex-col">
                        <TextField
                            label="Email Address"
                            variant="outlined"
                            type="text"
                            error={
                                formik.touched.email && Boolean(formik.errors.email)
                            }
                            {...formik.getFieldProps("email")}
                            autoComplete='false'
                        />
                        {formik.touched.email && formik.errors.email && (
                            <div className={` ${styles.errorMessage}`}>
                                <small>{formik.errors.email}</small>
                            </div>
                        )}
                    </div>
                    <div className="flex flex-col">
                        <TextField
                            label="Password"
                            variant="outlined"
                            type={showPassword ? "text" : "password"}
                            autoComplete='false'
                            error={
                                formik.touched.password && Boolean(formik.errors.password)
                            }
                            {...formik.getFieldProps("password")}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>,
                            }}
                        />
                        {formik.touched.password && formik.errors.password && (
                            <div className={` ${styles.errorMessage}`}>
                                <small>{formik.errors.password}</small>
                            </div>
                        )}
                    </div>
                    <Button type="submit" variant="contained" label="Sign in" isLoading={formik.isSubmitting} />
                </form>
                <div className='flex justify-end items-center mt-4 w-full'>
                    {/* // TODO - Feature : Forget Password*/}
                    {/* <span onClick={setAuthStateTo(AUTH_STATES.FORGOT_PASSWORD)} className='text-secondary font-normal cursor-pointer '>Forgot Password?</span> */}
                </div>
                <AuthFooter setAuthStateTo={setAuthStateTo} loginType={AUTH_STATES.LOGIN_WITH_MOBILE} isLogin={true}/>
            </div>
        </div>
    );
}

export default LoginWithEmailTemplate;