import React, { useEffect, useState } from 'react'
import { CLASS_TYPE, IPagination } from '../../../utils/types';
import { useNavigate } from 'react-router-dom';
import Table, { ColumnType } from '../../organisms/Table';
import { ADMIN_ROUTES, sortOptions, statusOptions } from '../../../utils/constant';
import { createUseStyles } from "react-jss";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import TextField from '../../atoms/TextField';
import Select from '../../atoms/Select';
import moment from 'moment';
import { enumToString, makeRoute } from '../../../utils/helper';
import ResourceStatus from '../../atoms/ResourceStatus/ResourceStatus';
import { IUpcFilter, IUpcs } from '../../pages/UPC/UpcList.page';
import { useSecondaryInventoryService } from '../../../services/useSecondaryInventoryService';

interface IUpcTemplateProps {
    upcs: IUpcs[];
    pagination: IPagination;
    handleFiltersChange: (event: any) => void;
    handlePaginationChange: any;
    upcFilters: IUpcFilter;
    handleRowsPerPageChange: any;
}

const useStyles = createUseStyles((theme: any) => ({
    viewIconContainer: {
      color: theme.palette.icon.primaryLight,
    },
    editIconContainer: {
      color: theme.palette.icon.secondryLight,
    }
  }));

const UpcListTemplate: React.FC<IUpcTemplateProps> = ({ upcs, pagination, handleFiltersChange, handlePaginationChange, upcFilters, handleRowsPerPageChange }) => {

    const classes = useStyles();

    const navigate = useNavigate();

    const schema = {
        id: "1",
        title: "",
        pagination: {
            total: pagination.totalRecords,
            currentPage: pagination.currentPage,
            isVisible: true,
            limit: pagination.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange,
        },
        columns: [
            { label: "Sr No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "UPC Code", key: "upcCode", type: "string" as ColumnType, props: { className: '' } },
            { label: "Product Category", key: "productCategory", type: "number" as ColumnType, props: { className: '' } },
            { label: "Created Date", key: "createdAt", type: "datetime" as ColumnType, props: { className: '' } },
            { label: "Last Modified", key: "updatedAt", type: "datetime" as ColumnType, props: { className: '' } },
            { label: "Status", key: "status", component: ({ value }: { value: string }) => <ResourceStatus status={value} />, type: "custom" as ColumnType, props: {} }, 
            { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } },
        ],
    };

    const handleEditClick = async (id: number) => {
        navigate(makeRoute(ADMIN_ROUTES.UPC_UPDATE, {params:  { id: id } }));
    };

    const handleViewClick = async (id: number) => {
        navigate(makeRoute(ADMIN_ROUTES.UPC_VIEW, {params:  { id: id } }));
    };

    const Action = (id: number) => {
        return <div className="flex gap-x-2 justify-end">
            <RemoveRedEyeIcon className={ `${ classes.viewIconContainer } text-secondary cursor-pointer`} onClick={ (e) => handleViewClick(id) } />
            <ModeEditOutlineIcon className={ `${ classes.editIconContainer } text-secondary cursor-pointer`} onClick={ (e) => handleEditClick(id) } /> 
        </div>
    }

    const records = upcs.map((upc: IUpcs, index: number) => [
        pagination.currentPage * pagination.pageSize + index + 1,
        upc.upcCode,
        upc.productCategory,
        moment(upc.createdAt).format("YYYY-MM-DD HH:mm:ss"),
        moment(upc.updatedAt).format("YYYY-MM-DD HH:mm:ss"),
        enumToString(upc.status),
        Action(upc.id)
    ]);

    const secondaaryInventoryService = useSecondaryInventoryService();
    const [productCategories, setProductCategoriesTo] = useState<any[]>([]);

    let productCategoryFilterValues: { value: any, label: string }[] = [{ value: "ALL", label: "All" }];
    productCategories?.forEach((productCategory: any) => {
        productCategoryFilterValues.push({ value: productCategory.id, label: productCategory.name });
    });

    const getAllCategories = () => {
        let queryParams = {
            name: '',
            level: 2,
            parentId: null,
            page: 0,
            size: 100
        };
        secondaaryInventoryService.getCategories(queryParams)
            .then((response) => {
                if (response.data) {
                    setProductCategoriesTo(response?.data?.data?.content);
                }
                else {
                    setProductCategoriesTo([]);
                }
            })
            .catch((error) => {
                console.error("product fetch failed - ", error);
                setProductCategoriesTo([]);
            });
    };

    useEffect(() => {
        getAllCategories();
    }, [])

    return (
		<div className='grid gap-y-1.5'>
			<div className="flex justify-between">
			    <div className="flex gap-x-3">
                   <div>
                        <Select
                            variant="outlined"
                            className="w-44"
                            label="Status"
                            name="status"
                            id="status"
                            value={ upcFilters.status }
                            onChange={ (e) => handleFiltersChange(e) }
                            options={ statusOptions }
                        />
                    </div>
                    <div>
                        <Select
                            variant="outlined"
                            className='w-fit'
                            id="sort"
                            label="Sort"
                            name="sort"
                            value={ upcFilters.sort }
                            onChange={ (e) => handleFiltersChange(e) }
                            options={[
                                { value: "createdAt,asc", label: "Created Date (Ascending)" },
                                { value: "createdAt,desc", label: "Created Date (Decending)" }
                            ]}
                        />
                    </div>
                    <div>
                        <Select
                            variant="outlined"
                            className="w-44"
                            label="Product Category"
                            name="productCategory"
                            id="productCategory"
                            value={upcFilters.productCategory || ""}
                            onChange={ (e) => handleFiltersChange(e) }
                            options={ productCategoryFilterValues }
                        />
                    </div>
				</div>
				<TextField
					className="w-72"
					type="text"
					label="Search by UPC Code"
					variant="outlined"
					name="search"
					value={ upcFilters.search }
					onChange={ handleFiltersChange }
				/>
			</div>
			<Table schema={ schema } records={ records } />
		</div>
	)
}

export default UpcListTemplate;
