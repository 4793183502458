import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const DisplayImage: React.FC<any> = ({ selectedImage, imageDialogOpen, setImageDialogOpenTo, handleBackdropClickAway }) => {
    const handleCloseDialog = () => {
        setImageDialogOpenTo(false);
    };
    return (
        <Dialog
            open={imageDialogOpen}
            onClose={handleCloseDialog}
            maxWidth="sm"
            fullWidth
        >
            <ClickAwayListener onClickAway={handleBackdropClickAway}>
                <DialogContent>
                    <img className="max-w-full max-h-full" alt="selectedImage" src={selectedImage} />
                </DialogContent>
            </ClickAwayListener>
        </Dialog>
    );
};
export default DisplayImage;