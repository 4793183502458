import React, { useEffect, useState } from 'react'

const CountDownTimer = ({ timeInMinutes, isActive, setIsActive }) => {
    const [timer, setTimer] = useState(timeInMinutes * 60);

    const startTimer = () => {
        setIsActive(true);
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return minutes ? `${minutes}:${seconds} mins` : `${seconds} secs`;
    };

    useEffect(() => {
        let interval = null;

        if (isActive) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (!isActive && timer !== 0) {
            clearInterval(interval);
        }

        if (timer === 0) {
            clearInterval(interval);
            setIsActive(false);
        }

        return () => clearInterval(interval);
    }, [isActive, timer]);

    return (
        <span>{formatTime(timer)}</span>
    );
}

export default CountDownTimer