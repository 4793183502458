import CategorySearch from './CategorySearch';
import ManufactureSearch from './ManufacturerBrandSearch';

const CategorySectionIndex = ({
  updateSearchParams,
  searchPayload,
  handleAttributeData,
  defaultText,
  showCategoryNote = true,
}) => {
  return (
    <div className='self-stretch bg-whitesmoke flex flex-col p-6 items-start justify-start gap-[18px] text-base text-text-color'>
      <div className='relative text-lg leading-[20px] font-semibold text-black'>
        Category
      </div>
      <CategorySearch
        updateSearchParams={updateSearchParams}
        searchPayload={searchPayload}
        handleAttributeData={handleAttributeData}
        defaultText={defaultText}
      />
      {showCategoryNote && (
      <span className='text-gray-500 italic text-sm'>* Select Super, Main, and Product categories for applying the category search filter</span> 
      )}
      <ManufactureSearch
        updateSearchParams={updateSearchParams}
        searchPayload={searchPayload}
        defaultText={defaultText}
      />
    </div>
  );
};
export default CategorySectionIndex;
