import { useContext, useState } from 'react';
import { CustomButton } from '../../../Shared/Button/CustomButton';
import { ProductAttributeHeading } from '../../../Shared/CustomHeading/ProductAttributeHeading';
import moment from 'moment';
import ProductCardWithDescription from './ProductCardWithDescription';
import InsightCard from './InsightCard';
import {
  ProductDataType,
  ProductFormData,
} from '../../../Constants/Procurement/PurchaseRequisition/types';
import { PROCUREMENT_TEXT } from '../../../Constants/Procurement/PurchaseRequisition/constants';
import { WareHouseContext } from '../../../../contexts/WareHouseDetailContext';
import { PACKAGING_TYPES } from '../../../../utils/constant';

interface ReviewCardProps {
  item: ProductDataType;
  values: ProductFormData;
  index: number;
  handleEdit?: () => void;
  handleBack?: () => void;
}

const ReviewCard = ({
  item,
  values,
  index,
  handleEdit,
  handleBack,
}: ReviewCardProps) => {
  const [insight, setInsight] = useState(false);
  const toggleInsight = () => setInsight(!insight);
  const { warehouseDetails } = useContext(WareHouseContext);
  return (
    <div className="border border-cgray-4 rounded-lg gap-4 my-4 w-full">
      <div className={`p-4 ${handleBack ? 'border-b border-cgray-4' : ''}`}>
        <ProductCardWithDescription
          item={item}
          toggleInsight={toggleInsight}
          index={index}
        />
      </div>
      {insight ? <InsightCard /> : ''}
      <div className="px-4">
        <div className="flex justify-between items-center py-4 px-4 my-4 bg-cgray-8 ">
          <div className="flex gap-x-8">
            <ProductAttributeHeading
              title={'Quantity'}
              value={values.quantity + ' ' + 'MT'}
              key={'Quantity' + values.quantity}
              className={'text-sm mt-0.5'}
            />
            <ProductAttributeHeading
              title={'Packaging'}
              value={
                PACKAGING_TYPES.find((i) => values.packagingType === i.value)
                  ?.label || ''
              }
              key={values.packagingType}
              className={'text-sm mt-0.5'}
            />
            <ProductAttributeHeading
              title={'Maximum Delivery Date'}
              value={
                values.deliveryTime
                  ? moment.unix(+values.deliveryTime).format('DD/MM/YYYY')
                  : ''
              }
              key={values.deliveryTime}
              className={'text-sm mt-0.5'}
            />
            <ProductAttributeHeading
              title={'Consignee Location'}
              value={
                warehouseDetails.find(
                  (warehouse) => warehouse.id == values.warehouseId
                )?.name || ' '
              }
              key={values.warehouseId}
              className={'text-sm mt-0.5'}
            />
          </div>
          {handleEdit ? (
            <CustomButton variant="Primary" onClick={handleEdit}>
              Edit
            </CustomButton>
          ) : (
            ''
          )}
        </div>
        {values.remarks ? (
          <div className="py-4 px-4 my-4 bg-cgray-8">
            <div className="font-semibold text-lg my-4">
              {PROCUREMENT_TEXT.reviewCardProductSpecificRequirementLabel}
            </div>
            <div>{values.remarks}</div>
          </div>
        ) : (
          ''
        )}

        {values.tnc ? (
          <div className="py-4 px-4 my-4 bg-cgray-8">
            <div className="font-semibold text-lg my-4">
              {PROCUREMENT_TEXT.reviewCardTnCLabel}
            </div>
            <div dangerouslySetInnerHTML={{ __html: values.tnc }}></div>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="flex justify-end w-full px-8 pb-20">
        {handleBack ? (
          <CustomButton variant="Primary" onClick={handleBack}>
            back
          </CustomButton>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default ReviewCard;
