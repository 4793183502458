import React, { useEffect, useState } from 'react'
import CustomHeading from '../../atoms/CustomHeading/CustomHeading'
import Button from '../../atoms/Button'
import { CLASS_TYPE, IPagination, IWarehouse } from '../../../utils/types';
import Select from '../../atoms/Select';
import { useNavigate } from "react-router-dom";
import { useSecondaryInventoryService } from '../../../services/useSecondaryInventoryService';
import { ISecondaryInventory, ISecondaryInventoryFilter } from '../../pages/SecondaryInventory/SecondaryInventoryList.page';
import viewEyeIcon from "./../../../assets/icons/viewEyeIcon.svg";
import editPenIcon from "./../../../assets/icons/editPenIcon.svg";
import Table, { ColumnType } from '../../organisms/Table';
import moment from 'moment';
import { ADMIN_ROUTES, BULK_UPLOAD_TEMPLATE_TYPE, RESOURCE_TYPE, SHAPE_TYPES, secondaryInventorySortOptions } from '../../../utils/constant';
import TextField from '../../atoms/TextField';
import { makeRoute } from '../../../utils/helper';
import excelIcon from "../../../assets/icons/excel.svg";
import uploadIcon from "../../../assets/icons/upload.svg";
import { useBulkUploadService } from "../../../services/useBulkUploadService";
import BulkUploadTemplateDownload from '../../organisms/BulkUpload/BulkUploadTemplateDownload';
import BulkUploadTemplate from '../../organisms/BulkUpload/BulkUploadTemplate';

interface SecondaryInventoryList {
    secondaryInventories: ISecondaryInventory[];
    pagination: IPagination;
    handlePaginationChange: any;
    secondaryInventoryFilter: ISecondaryInventoryFilter;
    handleFiltersChange: (event: any) => void;
    handleRowsPerPageChange: any;
}

const SecondaryInventoryListTemplate: React.FC<SecondaryInventoryList> = ({ secondaryInventories, pagination, handlePaginationChange, secondaryInventoryFilter, handleRowsPerPageChange, handleFiltersChange, }) => {

    const navigate = useNavigate();
    const secondaaryInventoryService = useSecondaryInventoryService();
    const bulkUploadService = useBulkUploadService();
    const [warehouses, setWarehousesTo] = useState<IWarehouse[]>([]);
    const [productCategories, setProductCategoriesTo] = useState<any[]>([]);

    let warehousesFilterValues: { value: any, label: string }[] = [{ value: "ALL", label: "All" }];
    warehouses.forEach((warehouse: IWarehouse) => {
        warehousesFilterValues.push({ value: warehouse.id, label: warehouse.name });
    });

    let productCategoryFilterValues: { value: any, label: string }[] = [{ value: "ALL", label: "All" }];
    productCategories.forEach((productCategory: any) => {
        productCategoryFilterValues.push({ value: productCategory.id, label: productCategory.name });
    });

    const handleAddClick = () => {
        navigate(`${ADMIN_ROUTES.SECONDARY_INVENTORY_CREATE}`);
    };

    const handleViewClick = (id: number) => (e: any) => {
        navigate(makeRoute(ADMIN_ROUTES.SECONDARY_INVENTORY_VIEW, {params:  { id: id } }));
    };

    const handleEditClick = (id: number) => (e: any) => {
        navigate(makeRoute(ADMIN_ROUTES.SECONDARY_INVENTORY_UPDATE, {params:  { id: id } }));
    };

    const secondaryInventoryTableSchema = {
        id: "1",
        title: "",
        pagination: {
            total: pagination.totalRecords,
            currentPage: pagination.currentPage,
            isVisible: true,
            limit: pagination.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange
        },
        columns: [
            { label: "Sr No.", key: "id", type: "number" as ColumnType, props: {} },
            { label: "SKU Code", key: "skuCode", type: "string" as ColumnType, props: {} },
            { label: "Product", key: "name", type: "string" as ColumnType, props: {} },
            { label: "Quantity", key: "quantity", type: "number" as ColumnType, props: {} },
            { label: "Warehouse", key: "warehouseName", type: "string" as ColumnType, props: {} },
            { label: "Created Date", key: "createdAt", type: "datetime" as ColumnType, props: {} },
            { label: "Last Modified", key: "updatedAt", type: "datetime" as ColumnType, props: {} },
            { label: "Action", key: "action", type: "custom" as ColumnType, props: {} },
        ],
    };

    const Action = (id: number) => {
        return <div>
            <button onClick={handleViewClick(id)}><img src={viewEyeIcon} alt={viewEyeIcon} /></button>
            <button onClick={handleEditClick(id)}><img src={editPenIcon} alt={editPenIcon} /></button>
        </div>
    }

    const records = secondaryInventories.map((secondaryInventory: ISecondaryInventory, index: number) => [
        pagination.pageSize*pagination.currentPage+index + 1,
        secondaryInventory.skuCode,
        secondaryInventory.name,
        secondaryInventory.quantity.toFixed(3),
        secondaryInventory.warehouseName,
        moment(secondaryInventory.createdAt).format("YYYY-MM-DD HH:mm:ss"),
        moment(secondaryInventory.updatedAt).format("YYYY-MM-DD HH:mm:ss"),
        Action(secondaryInventory.id),
    ]);

    const getAllWarehouses = () => {
        let queryParams = {
            search: "AVIGHNA",
            page: 0,
            size: 10
        };
        secondaaryInventoryService.getAllWarehouses(queryParams)
            .then((response) => {
                if (response.data) {
                    setWarehousesTo(response?.data);
                }
                else {
                    setWarehousesTo([]);
                }
            })
            .catch((error) => {
                console.error("Warehouses fetch failed - ", error);
                setWarehousesTo([]);
            });
    };

    const getAllCategories = () => {
        let queryParams = {
            name: '',
            level: 2,
            parentId: null,
            page: 0,
            size: 100
        };
        secondaaryInventoryService.getCategories(queryParams)
            .then((response) => {
                if (response.data) {
                    setProductCategoriesTo(response?.data?.data?.content);
                }
                else {
                    setProductCategoriesTo([]);
                }
            })
            .catch((error) => {
                console.error("product fetch failed - ", error);
                setProductCategoriesTo([]);
            });
    };

    const getTemplateFile = () => {
        if (!parseInt(secondaryInventoryFilter.productCategory)) {
            return false;
        }
        return bulkUploadService.getBulkUploadTemplate({
            categoryId: parseInt(secondaryInventoryFilter.productCategory),
            shapes: SHAPE_TYPES.map(item => item.id),
            classes:[CLASS_TYPE.SECONDARY],
        },
            { resourceType: RESOURCE_TYPE.SECONDARY_INVENTORY });
    }

    useEffect(() => {
        getAllWarehouses();
        getAllCategories();
    }, [])

    return (
        <div className="grid gap-y-3">
            <div className="flex justify-between">
                <CustomHeading text={"Secondary"} status="" />
                <div className='flex gap-x-3'>
                    <BulkUploadTemplateDownload icon={excelIcon} getTemplateFile={getTemplateFile} type={BULK_UPLOAD_TEMPLATE_TYPE.APPLICATION_OCTET_STREAM} productCategory = {secondaryInventoryFilter.productCategory}  />
                    <BulkUploadTemplate icon={uploadIcon} section = {RESOURCE_TYPE.SECONDARY_INVENTORY}/>
                    <Button variant="contained" label="Add Secondary" onClick={handleAddClick} />
                </div>
            </div>

            <div className='grid gap-y-1.5'>
                <div className='flex justify-between '>
                    <div className={`flex gap-3`} >
                        <div>
                            <Select
                                className='w-40'
                                variant="outlined"
                                label="Warehouses"
                                value={secondaryInventoryFilter.warehouse || ""}
                                onChange={handleFiltersChange}
                                name="warehouse"
                                id="warehouse"
                                options={warehousesFilterValues}
                            />
                        </div>
                        <div>
                            <Select
                                className='w-fit'
                                variant="outlined"
                                id="sort"
                                label="Sort"
                                name="sort"
                                value={secondaryInventoryFilter.sort}
                                onChange={(e) => handleFiltersChange(e)}
                                options={secondaryInventorySortOptions}
                            />
                        </div>
                        <div>
                            <Select
                                className='w-48'
                                variant="outlined"
                                label="Product Category"
                                value={secondaryInventoryFilter.productCategory || ""}
                                onChange={handleFiltersChange}
                                name="productCategory"
                                id="productCategory"
                                options={productCategoryFilterValues}
                            />
                        </div>
                    </div>
                    <TextField
                        className='w-56'
                        type="text"
                        label="Search By SKU Code"
                        variant="outlined"
                        name="skuCode"
                        value={secondaryInventoryFilter.skuCode}
                        onChange={handleFiltersChange}
                    />
                </div>

                <Table schema={secondaryInventoryTableSchema} records={records} />
            </div>
        </div>
    )
}

export default SecondaryInventoryListTemplate;