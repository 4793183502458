import { createUseStyles } from "react-jss";

interface IToggleProps {
	title: string;
	currentState: boolean;
	handleToggleChange: any;
	disabled: boolean;
}

const useStyles = createUseStyles((theme: any) => ({
	textColor:{
		color: theme.palette.text.primaryDarkLight
	}
}));

const Toggler: React.FC<IToggleProps> = ({ title,  currentState, handleToggleChange, disabled = false }) => {

	const classes = useStyles();

	const handleChange = (e: any) => {
		handleToggleChange('status', e.target.checked);
	};

	return (
		<div className='flex flex-col gap-2'>
			<div className='flex gap-5'>
				<h3 className={`font-semibold text-base my-auto ${ classes.textColor }` }>{ title }</h3>
				<label className='switch'>
					<input
						name='status'
						type='checkbox'
						onChange={ handleChange }
						checked={ currentState }
						disabled={ disabled }
					/>
					<span className='slider round'></span>
				</label>
			</div>
			<p className={ `text-xs font-normal ${ classes.textColor }`}>{ title } is now <span className="font-bold">{ currentState ? 'Active' : 'Inactive' }</span>, if you want { currentState ? 'Inactive' : 'Active' } use toggle</p>
		</div>
	);
}

export default Toggler;
