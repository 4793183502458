import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export const UPC_URLS = {
  SEARCH_UPC: "/search/upc",
  GET_PRODUCT_UPC: "/product-upcs",
  GET_PRODUCT_UPC_BY_ID: "/product-upc/:id",
  CREATE_PRODUCT_UPC: "/product-upc",
  UPDATE_PRODUCT_UPC: "/product-upc/:id"
};

export interface IProductUPCRequest{
  classType?: string;
  status?: string;
  upcCode?: string;
  page?: number;
  size?: number;
  sort?: string;
  categoryId?: number;
}

export interface IProductUpcFilterRequest{
  upcId?: number;
  upcCode?: string;
  superCategory?: string;
  mainCategory?: string;
  productCategory?: string;
  manufacturer?: string;
  brand?: string;
  standard?: string;
  grade?: string;
  shape?: string;
  classType?: string;
  upcIdentifier?: string;
  status?: string
}

interface IProduct {
  title: string;
  categoryId: number | null;
  productCode: string;
  keywords: string;
  gradeId: number | null;
  productProperties: any; 
}
export interface IProductUpcCreateRequest {
  product: IProduct
  upcCode: string;
  classType: string;
  shape: string;
  attributes: any
  status: string;
  brandIds: number[];
}

export const useUpcService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const searchUpc = (data: IProductUpcFilterRequest) => {
    return request(API_METHOD.POST, UPC_URLS.SEARCH_UPC, authenticatedUser, data);
  };

  const getProductUPCs = (params: IProductUPCRequest) => {
    return request(API_METHOD.GET, UPC_URLS.GET_PRODUCT_UPC, authenticatedUser, null, { params } );
  };

  const getProductUPC = (id: number) => {
    return request(API_METHOD.GET, replaceUrlParams( UPC_URLS.GET_PRODUCT_UPC_BY_ID, { id } ), authenticatedUser);
  };

  const createProductUPC = (data: IProductUpcCreateRequest) => {
    return request(API_METHOD.POST, UPC_URLS.CREATE_PRODUCT_UPC, authenticatedUser, data);
  };

  const updateProductUPC = (id: number, data: any) => {
    return request(API_METHOD.PUT, replaceUrlParams( UPC_URLS.UPDATE_PRODUCT_UPC, { id } ), authenticatedUser, data);
  };

  return {
    searchUpc,
    getProductUPCs,
    getProductUPC,
    createProductUPC,
    updateProductUPC,
  };
};
