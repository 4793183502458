import { createUseStyles } from "react-jss";
import ButtonV2 from "../../atoms/ButtonV2";
import { getProfileDisplayData } from "../../../utils/helper";
import { IBusinessProfile } from "../../pages/CustomerManagement/ProfileOverview.page";
import { useMemo } from "react";

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.primarylightAccent}`,
    },
    primaryContainer: {
        border: `1px solid ${theme.palette.border.primarylightAccent}`,
    },
    heading: {
        color: theme.palette.text.primaryLight,
    },
    title: {
        "& span": {
            color: theme.palette.text.primaryLight,
        },
        "& p": {
            color: theme.palette.text.primaryLight,
        }
    }
}));

interface IGSTViewTemplateProps {
    businessProfile: IBusinessProfile | null;
    setIsEditing: (value: boolean) => void;
    isEditButtonVisible?: boolean;
}

const GSTViewTemplate: React.FC<IGSTViewTemplateProps> = ({ businessProfile, setIsEditing, isEditButtonVisible = true }) => {

    const classes = useStyles();

    const editButtonComponent = useMemo(() => (
        <>
            {/* {isEditButtonVisible && (
                <ButtonV2 variant={'tertiaryContained'} label={'Edit'} onClick={() => setIsEditing(true)} />
            )} */}
        </>
    ), [isEditButtonVisible]);

    return (
        <div className={`${classes.container} p-6 rounded-lg w-full`}>
            <div className='flex justify-between items-center pb-6 border-b border-grey-500'>
                <h2 className={`${classes.heading} text-2xl font-medium`}>GST Details</h2>
                {editButtonComponent}
            </div>
            {businessProfile?.address && (
                <div>
                    <div className='flex gap-x-9 py-6'>
                        <div className={`${classes.title} flex gap-2`}>
                            <span >GST Registered Number:</span>
                            <p className='font-medium'>{getProfileDisplayData(businessProfile?.gstin)}</p>
                        </div>
                        <div className={`${classes.title} flex gap-2`}>
                            <span>PAN Number:</span>
                            <p className='font-medium'>{getProfileDisplayData(businessProfile?.pan)}</p>
                        </div>
                    </div>
                    <div className={`${classes.primaryContainer} p-4 rounded-xl w-1/2`}>
                        <h5 className={`${classes.heading} font-medium text-base mb-8`}>Principal Place of Business</h5>
                        <div className={`${classes.title} max-w-lg mb-3 break-all`}>
                            <span>Address Line 1</span>
                            <p className='font-medium'>{getProfileDisplayData(businessProfile?.address?.line1)}</p>
                        </div>
                        <div className={`${classes.title} max-w-lg mb-3 break-all`}>
                            <span>Address Line 2</span>
                            <p className='font-medium'>{getProfileDisplayData(businessProfile?.address?.line2)}</p>
                        </div>
                        <div className='grid grid-cols-3 gap-3'>
                            <div className={classes.title}>
                                <span>Pincode</span>
                                <p className='font-medium'>{getProfileDisplayData(businessProfile?.address?.pincode)}</p>
                            </div>
                            <div className={classes.title}>
                                <span>City</span>
                                <p className='font-medium'>{getProfileDisplayData(businessProfile?.address?.city)}</p>
                            </div>
                            <div className={classes.title}>
                                <span>State</span>
                                <p className='font-medium'>{getProfileDisplayData(businessProfile?.address?.state)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default GSTViewTemplate;
