import { createUseStyles } from "react-jss";
import { getProfileDisplayData } from "../../../utils/helper";
import ButtonV2 from "../../atoms/ButtonV2";
import { IBusinessProfile } from "../../pages/CustomerManagement/ProfileOverview.page";

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.primarylightAccent}`,
    },
    title: {
        color: theme.palette.text.secondaryDark,
    },
    content: {
        "& span": {
            color: theme.palette.text.primaryLight,
        },
        "& p": {
            color: theme.palette.text.primaryLight,
        }
    }
}));

interface IUdhyamViewTemplateProps {
    businessProfile: IBusinessProfile | null;
    setIsEditing: (value: boolean) => void;
}

const UdhyamViewTemplate: React.FC<IUdhyamViewTemplateProps> = ({ businessProfile, setIsEditing }) => {
    const classes = useStyles();

    return (
        <div className={`${classes.container} p-6 rounded-lg w-full`}>
            <div className='flex justify-between items-center pb-6 border-b border-grey-500'>
                <h2 className={`${classes.title} text-2xl font-medium`}>Udhyam Details</h2>
                <ButtonV2 variant={'tertiaryContained'} label={'Edit'} onClick={() => setIsEditing(true)} />
            </div>
            <div className='flex gap-x-9 pt-6'>
                <div className={classes.content}>
                    <span>Business Classification</span>
                    <p className='font-medium'>{getProfileDisplayData(businessProfile?.udhyam?.businessType)}</p>
                </div>
                <div className={classes.content}>
                    <span>Udhyam No.</span>
                    <p className='font-medium'>{getProfileDisplayData(businessProfile?.udhyam?.udhyamNumber)}</p>
                </div>
            </div>
        </div>
    )
}

export default UdhyamViewTemplate;
