import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import CustomLabel from './CustomLabel';
import React from 'react';

interface CustomSelectFieldProps {
  name: string;
  label: string;
  value: string | undefined;
  options: Item[];
  error?: boolean;
  helperText?: string | false;
  disabled?: boolean;
  defaultBorder?: boolean;
  rounded?: 'lg' | 'md' | 'sm' | 'none' | 'full' | [string];
  onChange?: (event: SelectChangeEvent) => void;

  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

interface Item {
  id: string | number;
  value: string;
}

const CustomSelectField: React.FC<CustomSelectFieldProps> = ({
  name,
  label,
  value,
  options,
  error = false,
  helperText,
  rounded = 'none',
  disabled = false,
  onChange,
  defaultBorder,
  onBlur,
}) => {
  return (
    <FormControl
      sx={{
        pointerEvents: disabled ? 'none' : 'auto',
        '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': defaultBorder
          ? {
              borderColor: '#dddddd',
            }
          : {
              borderColor: '#1d3679',
            },
      }}
      variant="outlined"
      className="w-full"
    >
      <CustomLabel
        name={name}
        label={label}
        disabled={disabled}
        error={error}
        value={Boolean(value)}
      />
      <Select
        id={`outlined-${name}-input`}
        value={value}
        error={error}
        onBlur={onBlur}
        className={`${
          disabled ? 'bg-cgray-17' : 'bg-white'
        } w-full h-12 rounded-${rounded}    
        `}
        name={name}
        onChange={onChange}
      >
        {options?.map((item, index) => (
          <MenuItem key={index} value={item.id}>
            {item.value}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default React.memo(CustomSelectField);