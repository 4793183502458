import { useNavigate, useParams } from "react-router-dom";
import { useBrandService } from "../../../../services/useBrandService";
import { useSnackbar } from "../../../../hooks/useSnackBar";
import { useEffect, useState } from "react";
import { IBrand, ICategory } from "./BrandList.page";
import BrandFormTemplate from "../../../templates/AdminMaster/Brand/BrandForm.template";
import { ADMIN_ROUTES, MODE, RESOURCE_TYPE } from "../../../../utils/constant";
import { ImageData } from "../../../organisms/ImageUploader";
import { HTTP_STATUS } from "../../../../utils/types";
import { useFileService } from "../../../../services/useFileService";

const BrandViewPage: React.FC = () => {
    
    const navigate = useNavigate();

    const params = useParams();
    const brandId = Number(params.id);

    const [brandDetails, setBrandDetailsTo] = useState<IBrand | null>(null);
    const [productCategories, setProductCategroiesTo] = useState<number[]>([]);

    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const fileService = useFileService();
    const [image, setImagesTo] = useState<(ImageData | File)[]>([]);

    const brandService = useBrandService();

    const navigateToBrandTable = () => {
        navigate(ADMIN_ROUTES.BRAND_LIST);
    };

    const fetchBrandById = async () =>{
        if(brandId){
            try {
                const brandResponse = await brandService.getBrandById(brandId);
                if(brandResponse.status===HTTP_STATUS.OK){
                    setBrandDetailsTo(brandResponse?.data?.data);
                    const productCategoryIds = brandResponse?.data?.data?.categories?.map((productCategory: ICategory) => productCategory?.id) ?? [];
                    setProductCategroiesTo(productCategoryIds);
                }
                const fileResponse = await fileService.getFilesById(brandId, RESOURCE_TYPE.BRAND);
                (fileResponse.status === HTTP_STATUS.OK) && setImagesTo(fileResponse?.data?.data);
            } catch (error) {
                showSnackbar("error", `Brand Not fetching${error}`) 
            }
        }
    }

    useEffect(() => {
        fetchBrandById()
    }, [brandId]);

    return (
        <div>
            { SnackBarComponent }
            <BrandFormTemplate brand={ brandDetails } categoryIds={ productCategories } onBrandSubmit={ navigateToBrandTable } mode={ MODE.VIEW } image={image} setImagesTo={setImagesTo}/>
        </div>
    )
}

export default BrandViewPage;
