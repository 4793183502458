import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useNavigate, useParams } from "react-router-dom";
import AddCustomerGroupFormTemplate from "../../templates/CustomerGroupMaster/AddCustomerGroup.template";
import { ICustomerGroupMasterRequestBody, useCustomerGroupMasterService } from "../../../services/useCustomerGroupMasterService";
import { HTTP_STATUS } from "../../../utils/types";
import { ADMIN_ROUTES } from "../../../utils/constant";
import { ICustomerGroupMaster } from "./CustomerGroupMasterList.page";
import { useSnackbar } from "../../../hooks/useSnackBar";

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary900,
    },
}));

const CustomerGroupMasterUpdatePage: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const params = useParams();
    const customerGroupMasterId = Number(params.id);
    const customerGroupService = useCustomerGroupMasterService()
    const [customerGroupMaster, setCustomerGroupMasterTo] =  useState<ICustomerGroupMaster | null>(null)
    const [customerIds, setCustomerIds] = useState<number[]>([])
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const loadCustomerGroup = async () => {
        try {
            const customerGroupMasterResponse = await customerGroupService.getCustomerGroupById(customerGroupMasterId);
            if (
                customerGroupMasterResponse?.status === HTTP_STATUS.OK && customerGroupMasterResponse?.data?.data
            ) {
                setCustomerGroupMasterTo(customerGroupMasterResponse.data.data);
            } else {
                setCustomerIds([]);
            }
        } catch (error) {
            showSnackbar("error","Customer Group Master update failed");

            setCustomerGroupMasterTo(null);
        }
    };

    useEffect(() => {
        loadCustomerGroup();
    }, [customerGroupMasterId]);

    const refreshCustomerUnderGroup = async () => {
        try {  
            const customerGroupMasterResponse = await customerGroupService.getCustomerUnderGroupById(customerGroupMasterId, {
                    page: 0,
                    size: 100,
                    sort: "createdAt,desc",
            });
            if (
                customerGroupMasterResponse?.status === HTTP_STATUS.OK &&
                customerGroupMasterResponse?.data?.data?.content?.length
            ) {
                setCustomerIds(customerGroupMasterResponse.data.data.content.map((customer:any) => ({
                    label: customer.customerName,
                    id: customer.customerId
                })));
            } else {
                setCustomerIds([]);
            }
        } catch (error) {
            showSnackbar("error","Customer Group Master update failed");

            setCustomerIds([]);
        }
    };

    useEffect(() => {
        refreshCustomerUnderGroup();
    }, [customerGroupMasterId]);

    const updateCustomerGroupMaster = async (requestBody: any) => {
        const payload: ICustomerGroupMasterRequestBody = {
            groupName: requestBody?.groupName,
            groupOwner: requestBody?.groupOwner,
            productCategoryId: requestBody?.productCategoryId,
            description: requestBody?.description,
            groupType: requestBody?.groupType,
            industryType: requestBody?.industryType,
            customerIds: requestBody?.customerIds,
        };
        try {
            const enquiryResponse = await customerGroupService.updateCustomerGroup(customerGroupMasterId, payload);
            if (enquiryResponse?.status === HTTP_STATUS.OK) {
                navigate(`${ADMIN_ROUTES.CUSTOMER_GROUP_MASTER_LIST}`);
            } else {
                throw new Error("Customer Group Master update failed");
            }
        } catch (error) {
            showSnackbar("error","Customer Group Master update failed");
            return false;
        }
    };

    return (
        <div className="grid gap-y-8">
            <div className={` ${classes.mainHeading} text-2xl font-medium`}>Update Customer Group</div>
            <AddCustomerGroupFormTemplate onSubmit={updateCustomerGroupMaster} mode="UPDATE" customerGroupMaster = {customerGroupMaster} customerIds = {customerIds}/>
        </div>
    );
};

export default CustomerGroupMasterUpdatePage;