import React from 'react'
import { createUseStyles } from "react-jss";
import AddIcon from '@mui/icons-material/Add';
import AddNewKeyPersonDetilsTemplate from './AddNewKeyPersonDetails.template';
import TextFieldV2 from '../../atoms/TextFieldV2';
import ButtonV2 from '../../atoms/ButtonV2';
import { ADMIN_ROUTES } from '../../../utils/constant';
import { useNavigate } from 'react-router-dom';
import { KEY_PERSON_DETAIL_STATES } from '../../../utils/types';
import { PocSectionProps } from './KeyPersonDetaills.template';


const useStyles = createUseStyles((theme: any) => ({

    container: {
        borderRadius: "8px",
        border: `1px solid ${theme.palette.textV2.secondary.secondary50}`,
    },
    text: {
        color: theme.palette.text.primaryLightAccent
    },
    label: {
        color: theme.palette.buttonV2.secondaryText.text.secondaryDark
    },
    helperText: {
        position: 'absolute',
        bottom: '-20px',
        color: theme.palette.action.danger,

    },
    icon: {
        backgroundColor: "#FD6202",
        color: "white",
        width: "48px",
        height: "48px",
        borderRadius: "10px",
    }
}));

const GetNumberOfKeyPersonDetails: React.FC<PocSectionProps> = ({ formik, setCurrentSectionTo }) => {

    const classes = useStyles();
    const navigate = useNavigate();

    const handleNext = (event: React.FormEvent) => {
        event.preventDefault();
        const requiredFields = ["noOfRows"];
        let fieldError = false;
        for (const key of requiredFields) {
            if (key in formik.errors) {
                formik.setFieldTouched(key, true);
                fieldError = true;
            }
        }
        if (fieldError === true) return;
        const numberOfRows = formik.values.noOfRows ?? 0;
        const emptyPoc = { name: '', email: '', mobile: '', department: '', designation: '', profilePic: '', countryCode: '+91', socialLinks: {}, isAuthorized: false, alternateEmail: '', alternateMobile: '', prefix: '', modeOfCommunication: '', whatsAppLink: '' };
        const newPocValues = Array.from({ length: numberOfRows }, () => ({ ...emptyPoc }));
        formik.setFieldValue('newPocs', newPocValues)
    };

    const keyDetailProps: PocSectionProps = {
        setCurrentSectionTo: setCurrentSectionTo,
        formik: formik
    };

    const handleBackToDashboard = () => {
        navigate(ADMIN_ROUTES.VENDOR_MANAGEMENT_LIST);
    };

    const handleBackToViewPage = () => {
        setCurrentSectionTo(KEY_PERSON_DETAIL_STATES.VIEW_KEY_PERSON_DETAILS);
    };

    return (
        <>
            <div className={` rounded-2xl p-6 mt-6 ${classes.container}`}>
                <div className='flex  gap-4 items-end  '>
                    <div className={`grid  gap-y-2 w-1/3 `}>
                        <TextFieldV2
                            {...formik.getFieldProps("noOfRows")}
                            placeholder="Enter number"
                            label=" Enter no. of Key Person"
                            variant="outlined"
                            inputProps={{ min: 1 }}
                            type="number"
                            error={
                                formik.touched.noOfRows &&
                                Boolean(formik.errors.noOfRows)
                            }
                            helperText={formik.touched.noOfRows && formik.errors.noOfRows}

                            FormHelperTextProps={{ className: `${classes.helperText}` }}
                        />
                    </div>
                    <AddIcon className={`${classes.icon} w-full cursor-pointer`} onClick={handleNext} />
                </div>
            </div>
            {(!(formik?.values?.newPocs.length > 0)) && (
                <div className="flex mt-6 relative">
                    <div className='!absolute right-0 top-0 flex items-center gap-2'>
                        <ButtonV2
                            className='justify-self-end'
                            variant="tertiaryContained"
                            label="Back"
                            onClick={handleBackToViewPage}
                        />
                        <ButtonV2
                            className='justify-self-end'
                            variant="secondaryContained"
                            label="Back to Dashboard"
                            onClick={handleBackToDashboard}
                        />
                    </div>
                </div>
            )}
            {
                formik?.values?.newPocs.length > 0 && <AddNewKeyPersonDetilsTemplate {...keyDetailProps} />
            }
        </>
    )
}
export default GetNumberOfKeyPersonDetails