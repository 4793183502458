import { useContext, useEffect, useState } from 'react';
import '../../../Components/Procurement/Indent/indent.css';
import {
  BillToAddress,
  FormData,
  Procurement,
} from '../../../Constants/Procurement/Indent/types';
import CommonIndentTnCReview from '../Indent/CommonIndentTnCReview';
import CommonProductDetailReview from '../Indent/CommonProductDetailReview';
import { AUCTION_TEXT } from '../../../Constants/Procurement/ReverseAuctionRFQ/constants';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import { WareHouseContext } from '../../../../contexts/WareHouseDetailContext';
import { useWarehouseService } from '../../../../services/useWarehouseService';
import { usePurchaseRequisition } from '../../../../services/usePurchaseRequisition';

interface IndentDetailsProps {
  indentDetails: FormData;
}

const IndentDetails: React.FC<IndentDetailsProps> = ({ indentDetails }) => {
  // call ship to bill to APIs
  const { warehouseDetails } = useContext(WareHouseContext);
  const [billToAddress, setBillToAddress] = useState<BillToAddress>();
  const [purchaseReqDetails, setPurchaseReqDetails] = useState<Procurement[]>();
  const { user }: any = useAuthenticatedUser();

  const { getBillAddress } = useWarehouseService();
  const { getPurchaseRequistions } = usePurchaseRequisition();
  const fetchBillToAddress = async () => {
    try {
      const billToResponse = await getBillAddress();
      if (billToResponse.data.data) {
        setBillToAddress(billToResponse.data.data);
      }
    } catch (e) {
      console.log('Error in fetchBillToAddress', e);
    }
  };

  const fetchPRs = async () => {
    const PRs = indentDetails.items;
    const PRIds = PRs.map((pr) => pr.purchase_requisition_id);
    const queryParams = {
      pageSize: PRs.length,
      pageNumber: 0,
      prIDs: PRIds.join(','),
    };

    try {
      const response = await getPurchaseRequistions(queryParams);
      if (response?.data?.data?.content.length) {
        setPurchaseReqDetails(response.data.data.content);
      }
    } catch (e) {
      console.log('Error in fetchPRs', e);
    }
  };

  useEffect(() => {
    fetchPRs();
    fetchBillToAddress();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseDetails]);

  return (
    <div className="grid">
      <div className="border-solid border-b border-cgray-3 flex flex-row justify-between w-full my-4 py-2">
        <div
          id="Heading"
          className="text-2xl font-semibold  text-text-color mb-4"
        >
          {AUCTION_TEXT.reviewIndentDetailProductHeading}
        </div>
      </div>
      <div className="my-2">
        {/*  details  */}
        {purchaseReqDetails && (
          <CommonProductDetailReview
            formData={indentDetails}
            selectedPurchaseRequisition={purchaseReqDetails}
          />
        )}
      </div>
      {/* Indent T&C */}
      <div className="  ">
        <div className="border-solid border-b border-cgray-3 flex flex-row justify-between w-full my-4 py-2">
          <div id="Heading" className="text-2xl font-semibold  text-blue mb-4">
            {AUCTION_TEXT.reviewIndentTNCProductHeading}
          </div>
        </div>
        <div className="my-2">
          <CommonIndentTnCReview
            billToAddress={billToAddress}
            shipToData={warehouseDetails.find(
              (shipToLoaction) =>
                shipToLoaction.id === parseInt(indentDetails?.warehouse_id)
            )}
            formData={indentDetails}
          />
        </div>
      </div>
    </div>
  );
};

export default IndentDetails;
