import { Navigate, Route, Routes } from 'react-router-dom';
import CatalogueRoutes from './App/Core/Routes/CatalogueRouter';
import InventoryRoutes from './App/Core/Routes/InventoryRouter';
import LeadRoutes from './App/Core/Routes/LeadRouter';
import DashBoardRouter from './App/Core/Routes/DashBoardRouter';
import ManufacturerRoutes from './App/Core/Routes/ManufacturerRouter';
import BrandRoutes from './App/Core/Routes/BrandRouter';
import StandardRoutes from './App/Core/Routes/StandardRouter';
import CategoryRoutes from './App/Core/Routes/CategoryRouter';
import WhatsappTemplateRouter from './App/Core/Routes/WhatsappTemplateRouter';
import UserProfileRouter from './App/Core/Routes/UserProfileRouter';
import Standards from './App/Pages/AdminMaster/Product Standard/Standard/Standards';
import AddGradeFormik from './App/Pages/AdminMaster/Product Standard/Grade/AddGradeFormik';
import AddTypeAttribute from './App/Pages/AdminMaster/Attributes/AddTypeAttribute';
import AddNewAttribute from './App/Pages/AdminMaster/Attributes/AddNewAttribute';
import Attributes from './App/Pages/AdminMaster/Attributes/Attributes';
import OrderRouter from './App/Core/Routes/OrderRouter';
import AdminLayout from './components/organisms/AdminLayout';
import UpcRouter from './App/Core/Routes/UpcRouter';
import { ProcurementRouter } from './App/Core/Routes/ProcurementRouter';
import axiosRetry from './axios-retry';
import axios from 'axios';
import BulkUpload from './components/pages/BulkUpload/BulkUploadList.page';
import { useSnackbar } from './hooks/useSnackBar';
import ImageMasterRoutes from './App/Core/Routes/ImageMasterRouter';
import MpcRouter from './App/Core/Routes/MpcRouter';
import CustomerManagementRouter from './App/Core/Routes/CustomerManagementRouter';
import CustomerGroupMasterRoutes from './App/Core/Routes/CustomerGroupMasterRoutes';
import VendorManagementRoutes from './App/Core/Routes/VendorManagementRoutes';
import UPCManagementRouter from './App/Core/Routes/UPCManagementRouter';
import { QuoteCreatePage } from './components/pages/ManageQuotation/QuoteCreate.page';
import { InstantQuotationRoutes } from './App/Core/Routes/InstantQuotationRoutes';
import VASMasterServicesRoutes from './App/Core/Routes/VASMasterServicesRoutes';
import VASMasterUSCsRoutes from './App/Core/Routes/VASMasterUSCsRoutes';
import VASMasterMachinesRoutes from './App/Core/Routes/VASMasterMachinesRoutes';

export const AdminRouter: React.FC = () => {
  const { showSnackbar, SnackBarComponent } = useSnackbar();
  axiosRetry(axios, {
    retryDelay: () => {
      return 1000;
    },

    retryCondition: (error:any) => {
      if (error.response) {
        if (error.response.status >= 500 && error.response.status < 600) {
          return true;
        } else if (
          (error.response.status >= 400 && !!!error.response?.data.exceptionCode ) &&
          error.response.status < 500
        ) {
          showSnackbar('error', (error.response?.data as any).statusMessage);
        }
      }
      return false;
    },
    onMaxRetryTimesExceeded: (error) => {
      const status = error.response && error.response.status;
      if (
        status === 301 ||
        status === 302 ||
        status === 307 ||
        status === 308
      ) {
        // CORS error
        showSnackbar(
          'error',
          'CORS Error: Access to the resource  is blocked by  CORS policy.'
        );
      } else if (error.response?.data) {
        showSnackbar(
          'error',
          (error.response?.data as any).statusMessage ||
            (error.response?.data as any)
        );
      } else {
        showSnackbar('error', 'Something went wrong!');
      }
    },
  });

  return (
    <>
      {SnackBarComponent}
      <Routes>
        <Route path="/*" element={<AdminLayout />}>
          {/* <Route path="customer-management/*" element={<DashBoardRouter />} /> */}
          <Route path="instant-quotations/*" element={<InstantQuotationRoutes />} />
          <Route path="customer-management/customers/*" element={<CustomerManagementRouter />} />
          <Route path="vendors/*" element={<VendorManagementRoutes />} />
          <Route path="inventory/*" element={<InventoryRoutes />} />
          <Route path="catalogue/*" element={<CatalogueRoutes />} />
          <Route path="lead/*" element={<LeadRoutes />} />
          <Route path="order/*" element={<OrderRouter />} />
          <Route
            path="whatsapp-template/*"
            element={<WhatsappTemplateRouter />}
          />
          <Route path="manufacturer/*" element={<ManufacturerRoutes />} />
          <Route path="brand/*" element={<BrandRoutes />} />
          <Route path="standard/*" element={<StandardRoutes />} />
          <Route path="category/*" element={<CategoryRoutes />} />
          <Route path="user-profile/*" element={<UserProfileRouter />} />
          <Route path="attribute" element={<Attributes />} />
          <Route path="attribute/add" element={<AddNewAttribute />} />
          <Route path="attribute-type/add" element={<AddTypeAttribute />} />
          <Route path="procurement/*" element={<ProcurementRouter />} />
          <Route path="bulk-upload" element={<BulkUpload />} />
          <Route path='grade/' element={<Standards />} />
          <Route path='grade/add' element={<AddGradeFormik type='ADD' onClose={() => {}} gradeId={null} updateRecord={() => {}} />} /> 
          <Route path='upc/*' element={<UpcRouter />} />
          <Route path='mpc/*' element={<MpcRouter />} />
          <Route path='upc-listing-page/*' element={<UPCManagementRouter />} />
          <Route path='image-master/*' element={<ImageMasterRoutes />} />
          <Route path="customer-group-master/*" element={<CustomerGroupMasterRoutes />} />
          <Route path="vas-master-service/*" element={<VASMasterServicesRoutes />} />
          <Route path="vas-master-USCs/*" element={<VASMasterUSCsRoutes />} />
          <Route path="vas-master-machines/*" element={<VASMasterMachinesRoutes />} />
          <Route path="*" element={<Navigate to={"customer-management/customers"} replace />}/></Route>
        </Routes>
    </>
  );
};
