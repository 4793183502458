import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate, } from "react-router-dom";
import { FormControl } from "@mui/material";
import JoditEditor from "jodit-react";
import FormGroup from "@mui/material/FormGroup";
import CloseIcon from "@mui/icons-material/Close";
import { IStandardRequest } from "../../../services/useStandardService";
import Toggler from "../../atoms/Toggler";
import Button from "../../atoms/Button";
import { createUseStyles } from "react-jss";
import { IStandard } from "../../pages/Standard/StandardList.page";
import TextField from "../../atoms/TextField";
import { ADMIN_ROUTES, DOCUMENT_TYPES, MODE, REGEX, RESOLUTIONS, RESOURCE_TYPE, STANDARD_SECTION_TYPE, STATUS } from "../../../utils/constant";
import TagInputGroup from "../../molecules/TagInputGroup/TagInputGroup";
import ImageUploader, { ImageData } from "../../organisms/ImageUploader";
import UploadImages from "../../organisms/UploadImages";
import { useFileService } from "../../../services/useFileService";
import { toCamelCase, titleModification } from "../../../utils/helper";

const useStyles = createUseStyles((theme: any) => ({
	textColor: {
		color: theme.palette.text.primaryDarkLight
	},
	errorMessage: {
		color: theme.palette.action.danger,
	},
}));

interface IStandardFormProps {
	onStandardSubmit?: (standardRequestBody: IStandardRequest) => void;
	standard: IStandard | null;
	mode: string;
	image: (ImageData | File)[];
	// setImagesTo: (images:(ImageData | File)[]) => void;     **** todo have to ask woking fine with local state show error when get from props
	setImagesTo: (images: any) => void;
}

export interface IStandardForm {
	id?: number;
	name: string;
	prefix?: string;
	yearInUse: string;
	yearLastUpdated: string;
	hsnCodes: string[];
	bisHeading: string;
	equivalentTags: string[];
	status: boolean;
	application: string;
	features: string;
	description: string;
}

const getStandardHeader = (mode: string) => {
	switch (mode) {
		case MODE.VIEW:
			return STANDARD_SECTION_TYPE.VIEW_STANDARD;
		case MODE.UPDATE:
			return STANDARD_SECTION_TYPE.EDIT_STANDARD;
		case MODE.ADD:
			return STANDARD_SECTION_TYPE.ADD_STANDARD;
		default:
			return null;
	}
};

const StandardFormTemplate: React.FC<IStandardFormProps> = ({ standard, onStandardSubmit = () => { }, mode, image, setImagesTo }) => {

	const navigate = useNavigate();
	const classes = useStyles();
	const standardApplication = useRef(null);
	const standardFeature = useRef(null);
	const descriptionEditor = useRef(null);
	const fileService = useFileService();
	const [imageIdsToDelete, setImageIdsToDelete] = useState<number[]>([]);

	// const [image, setImagesTo] = useState<(ImageData | File)[]>([]);     To Do: fix this later

	const navigateToStandardTable = () => {
		navigate(ADMIN_ROUTES.STANDARD_LIST);
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().required("Standard Name is required"),
		yearInUse: Yup.number().typeError("Year in use must be a number").required("Year in use is required")
		.test('is-valid-year', 'Year in use must be a 4-digit number', val => val.toString().length === 4),
		yearLastUpdated: Yup.number().typeError("Year Last updated must be a number").required("Year Last updated is required")
		.test('is-valid-year', 'Year in use must be a 4-digit number', val => val.toString().length === 4),
		bisHeading: Yup.string().required('Bis Heading is required'),
		equivalentTags: Yup.array().of(Yup.string().required("Equivalent tags is required"))
			.required('Equivalent tags is required')
			.min(1, 'Equivalent tags is required'),
		hsnCodes: Yup.array().of(Yup.string().required('HSN Code is required')
			.matches(/^[0-9]{8}$/, 'Invalid HSN Code Provided, it should be an 8-digit number (Eg: 34232322)'))
			.required('HSN Code is required')
			.min(1, 'HSN Code is required'),
		description: Yup.string().required("Standard Description is required"),
		status: Yup.boolean().required("Status is required"),
		application: Yup.string().required("Application is required"),
		features: Yup.string().required("Features is required"),
	});

	const initialValues: IStandardForm = {
		name: standard?.name ?? '',
		yearInUse: standard?.yearInUse ?? '',
		yearLastUpdated: standard?.yearLastUpdated ?? '',
		bisHeading: standard?.bisHeading ?? '',
		hsnCodes: standard?.hsnCodes.split(",") ?? [],
		equivalentTags: standard?.equivalentTags.split(",") ?? [],
		application: standard?.application ?? '',
		features: standard?.features ?? '',
		status: standard?.status !== STATUS.INACTIVE,
		description: standard?.description ?? ''
	}

	const formik = useFormik<IStandardForm>({
		initialValues,
		validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			if (mode !== MODE.VIEW) {
				setSubmitting(true);
				const standardRequestBody: IStandardRequest = {
					name: values.name.trim(),
					yearInUse: values.yearInUse,
					yearLastUpdated: values.yearLastUpdated,
					bisHeading: values.bisHeading,
					application: values.application,
					hsnCodes: values.hsnCodes.join(','),
					equivalentTags: values.equivalentTags.join(','),
					features: values.features,
					status: values.status ? "ACTIVE" : "INACTIVE",
					description: values.description,
				}
				onStandardSubmit(standardRequestBody);
			} else {
				navigate(ADMIN_ROUTES.STANDARD_LIST);
			}
		}
	})

	const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
		if (e.key === 'Enter') {
			e.preventDefault();
		}
	};

	useEffect(() => {
		if (standard) {
			const hsnCodesArray = Array.isArray(standard.hsnCodes) ? standard.hsnCodes : standard.hsnCodes.split(",");
			const equivalentTagsArray = Array.isArray(standard.equivalentTags) ? standard.equivalentTags : standard.equivalentTags.split(",");

			formik.setValues({
				...formik.values,
				...standard,
				status: standard.status === "ACTIVE",
				hsnCodes: hsnCodesArray,
				equivalentTags: equivalentTagsArray
			});
		}
	}, [standard, formik.setValues]);

	const cancelButton = useMemo(() => {
		return (
			<Button
				variant="outlined"
				fullWidth
				label="Cancel"
				onClick={navigateToStandardTable}
			/>
		);
	}, [mode]);

	const config = useMemo(() => {
		return {
			readonly: mode === MODE.VIEW,
			placeholder: "Enter Description...",
		};
	}, []);

	const updateBisEquivalentValues = (data: string[]) => {
		formik.setFieldValue('equivalentTags', data);
	}

	const updateHsnValues = (data: string[]) => {
		formik.setTouched({ ...formik.touched, hsnCodes: true });
		formik.setFieldValue('hsnCodes', data);
	};
	const removeImage = useCallback((id: number) => {
		const updatedImages = image.filter((_, currentIndex) => currentIndex !== id);
		const imageData = image[id] as ImageData;
		setImageIdsToDelete((prevImageIds: number[]) => [...prevImageIds,imageData.id as number]);
		setImagesTo(updatedImages);
	}, [image]);

	const updatedImages = useCallback((images: any) => {
		setImagesTo((prevImages: any) => [...prevImages, ...images]);
	}, [image]);

	// const updatedImages = useCallback((images: (ImageData | File)[]) => {                   *** to remove any and make this function like this : fix this later
	// 	setImagesTo((prevImages: (ImageData | File)[]) => [...prevImages, ...images]);
	// }, [setImagesTo]);

	const imageUploaderComponent = useMemo(() => (
		<ImageUploader
			mode={mode}
			images={image}
			removeImage={removeImage}
			isSubmitting = {formik.isSubmitting}
			imageIdsToDelete = {imageIdsToDelete}
		/>
	), [mode, image, removeImage, formik.isSubmitting]);

	const uploadImagesComponent = useMemo(() => (
		mode !== MODE.VIEW ? (
			<UploadImages
				title="Upload Images"
				currentImageCount = {image.length}
				updateImages={updatedImages}
				configuration={{
					maxImageCount: 4,
					maxfilesize: 200,             
					documentTypes: [DOCUMENT_TYPES.IMAGE_JPEG, DOCUMENT_TYPES.IMAGE_PNG],
					documentResolution: RESOLUTIONS.SECONDARY_INVENTORY,
				}}
			/>
		) : null
	), [mode, updatedImages]);

	return (
		<div>
			<form className=" w-full" onSubmit={formik.handleSubmit} onKeyDown={handleKeyDown}>
				<div className="grid gap-y-4 w-full">
					<div className=" flex justify-between items-center">
						<div className={`font-semibold text-2xl text-blue ${classes.textColor}`}>{getStandardHeader(mode)}</div>
						<div>
							<CloseIcon
								className="close-icon cursor-pointer"
								onClick={navigateToStandardTable}
							/>
						</div>
					</div>
					<div className="grid gap-y-3">
						<div>
							<TextField
								fullWidth
								{...formik.getFieldProps('name')}
								label="Enter Standard Name"
								name="name"
								variant="outlined"
								onBlur={(event) => {
									const newValue = titleModification(event.target.value);
									formik.setFieldValue('name', newValue);
								}}
								inputProps={{
									readOnly: mode === MODE.VIEW ? true : false,
									maxLength: 100,
								}}
							/>
							{formik.touched && formik.touched?.name && formik.errors?.name && (
								<div className={classes.errorMessage}>
									<small>{formik.errors.name}</small>
								</div>
							)}
						</div>
						<div className="gap-4 flex">
							<div className="grid w-1/2">
								<TextField
									fullWidth
									{...formik.getFieldProps('yearInUse')}
									label="Select Year (In Use)"
									name="yearInUse"
									variant="outlined"
									onChange={(event: any) => {
										formik.handleChange({
											target: { name: 'yearInUse', value: toCamelCase(event.target.value) },
										});
									}}
									inputProps={{
										readOnly: mode === MODE.VIEW ? true : false,
										maxLength: 100,
									}}
								/>
								{formik.touched && formik.touched?.yearInUse && formik.errors?.yearInUse && (
									<div className={classes.errorMessage}>
										<small>{formik.errors.yearInUse}</small>
									</div>
								)}
							</div>


							<div className="grid w-1/2">
								<TextField
									fullWidth
									{...formik.getFieldProps('yearLastUpdated')}
									label="Select Year (Last Updated)"
									name="yearLastUpdated"
									variant="outlined"
									onChange={(event: any) => {
										formik.handleChange({
											target: { name: 'yearLastUpdated', value: toCamelCase(event.target.value) },
										});
									}}
									inputProps={{
										readOnly: mode === MODE.VIEW ? true : false,
										maxLength: 100,
									}}
								/>
								{formik.touched && formik.touched?.yearLastUpdated && formik.errors?.yearLastUpdated && (
									<div className={classes.errorMessage}>
										<small>{formik.errors.yearLastUpdated}</small>
									</div>
								)}
							</div>
						</div>

						<div>
							<TagInputGroup
								onTagChange={updateHsnValues}
								displayLabel={'HSN Codes'}
								tags={formik.values.hsnCodes}
								disabled={mode === MODE.VIEW}
							/>
							{formik.touched?.hsnCodes && formik.errors?.hsnCodes?.length && (
								<div className={classes.errorMessage}>
									<small>{Array.isArray(formik.errors.hsnCodes) ? formik.errors.hsnCodes[formik.errors.hsnCodes.length-1] : formik.errors.hsnCodes}</small>
								</div>
							)}
						</div>


						<div>
							<TextField
								fullWidth
								{...formik.getFieldProps('bisHeading')}
								label="Heading as per as BIS Standard"
								name="bisHeading"
								variant="outlined"
								onChange={(event: any) => {
									formik.handleChange({
										target: { name: 'bisHeading', value: toCamelCase(event.target.value) },
									});
								}}
								inputProps={{
									readOnly: mode === MODE.VIEW ? true : false,
									maxLength: 100,
								}}
							/>
							{formik.touched && formik.touched?.bisHeading && formik.errors?.bisHeading && (
								<div className={classes.errorMessage}>
									<small>{formik.errors.bisHeading}</small>
								</div>
							)}
						</div>
						<div>
							<TagInputGroup
								onTagChange={updateBisEquivalentValues}
								displayLabel={'Add Bis Equivalent'}
								tags={formik.values.equivalentTags}
								disabled={mode === MODE.VIEW}
							/>
							{formik.touched && formik.touched?.equivalentTags && formik.errors?.equivalentTags && (
								<div className={classes.errorMessage}>
									<small>{formik.errors.equivalentTags}</small>
								</div>
							)}
						</div>


						<div className="mt-3 mb-3">
							<FormGroup>
								<Toggler
									title="Status"
									currentState={formik.values.status}
									handleToggleChange={(type: any, value: string) =>
										formik.setFieldValue("status", value)
									}
									disabled={mode === MODE.VIEW ? true : false}
								/>
							</FormGroup>
						</div>
						<h3 className={`font-semibold text-base ${classes.textColor}`}>Enter Standard Application</h3>
						<div className="w-full">
							<FormControl fullWidth>
								<JoditEditor
									ref={standardApplication}
									value={formik.values.application}
									onBlur={(newContent) =>
										formik.setFieldValue(
											"application",
											newContent
										)
									}
									config={config}
								/>
								{formik.errors.application && formik.touched.application && (
									<div className={classes.errorMessage}>
										{formik.errors.application}
									</div>
								)}
							</FormControl>
						</div>

						<h3 className='font-medium font-semibold'>Enter Standard Features</h3>
						<div className="w-full">
							<FormControl fullWidth>
								<JoditEditor
									ref={standardFeature}
									value={formik.values.features}
									onBlur={(newContent) =>
										formik.setFieldValue("features", newContent)}
									config={config}
								/>
								{formik.errors.features && formik.touched.features && (
									<div className={classes.errorMessage}>{formik.errors.features}</div>
								)}
							</FormControl>
						</div>
						{/* add bis images */}
						<h3 className='font-medium font-semibold'>Description</h3>
						<div className='flex flex-row w-full items-center'>
							<FormControl fullWidth>
								<JoditEditor
									ref={descriptionEditor}
									value={formik.values.description}
									config={config}
									onBlur={(newContent) => formik.setFieldValue('description', newContent)}
								/>
								{formik.errors.description && formik.touched.description && (
									<div className={classes.errorMessage}>{formik.errors.description}</div>
								)}
							</FormControl>
						</div>
					</div>
				</div>

				{imageUploaderComponent}
				{uploadImagesComponent}

				<div className="flex justify-end w-full space-x-4 mr-9 pt-2">
					<div>{cancelButton}</div>
					{mode !== MODE.VIEW && (
						<Button
							variant="contained"
							type="submit"
							label="Submit"
						/>
					)}
				</div>
			</form>
		</div>
	)
}
export default StandardFormTemplate