import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { ICustomerMappingForm } from '../../../pages/CustomerManagement/CustomerMapping.page';
import { useUserService } from '../../../../services/useUserService';
import { useCustomerGroupMasterService } from '../../../../services/useCustomerGroupMasterService';
import FilterChip, { IMultiSelectOption } from '../../../molecules/FilterChip/FilterChip';
import { ADMIN_ROUTES } from '../../../../utils/constant';
import ButtonV2 from '../../../atoms/ButtonV2';
import EmptyBussinessDocumentTemplate from '../../Dashboard/EmptyBussinessDocument.template';
import { IVendorBrandCenterDetailProps } from './VendorBrandCenter.template';
import { useBrandService } from '../../../../services/useBrandService';
import EmptySelectBrandTemplate from './EmptySelectBrand.template';


const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.secondaryLightAccent}`
    },
    heading: {
        color: theme.palette.textV2.secondary.secondary800,
    },
    content: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    headingtext: {
        color: theme.palette.textV2.tertiary.tertiary700
    },
    labelcolor: {
        color: theme.palette.textV2.primary.primary850
    },
    textCustomer: {
        color: theme.palette.textV2.primary.primary500
    },
    seprator: {
        color: theme.palette.textV2.primary.primary1000
    },
    headingText: {
        color: theme.palette.text.primaryDarkAccent
    },

}));

const SelectBrandCenterTemplate: React.FC<IVendorBrandCenterDetailProps> = ({ formik }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [searchGroupName, setSearchGroupName] = useState<string>('');
    const userService = useUserService();
    const brandService = useBrandService();
    const customerGroupMasterService = useCustomerGroupMasterService();
    const [relationManagerOptions, setRelationManagerOptions] = useState<IMultiSelectOption[]>([]);
    const [customerTypeOptions, setCustomerTypeOptions] = useState<IMultiSelectOption[]>([]);
    const [selectedValues, setSelectedValues] = useState<IMultiSelectOption[]>([]);
    const [isCustomerTypeEditing, setIsCustomerTypeEditing] = useState<boolean>(false);
    const [isRelatationEditing, setIsRelationEditing] = useState<boolean>(false);

    useEffect(() => {
            setSelectedValues(
                formik.values.brandIds.map((brand: { brandName: string; brandId: number }) => ({
                    label: brand.brandName,
                    value: brand.brandId,
                }))
            );
    }, [formik.values.brandIds]);

    const handleSave = () => {
        isCustomerTypeEditing && formik.handleSubmit();
        setIsCustomerTypeEditing(!isCustomerTypeEditing)
    };

    const handleSelectionChange = (selected: IMultiSelectOption[]) => {
        if (selected?.length > 0 && selected[0].label === "N/A") {
            return;
        }
        setSelectedValues(selected);
        formik.setFieldValue('newBrandIds', selected);
    };

    const fetchAllBrand = async () => {
        const params = {
            name: searchTerm ?? '',
            page: 0,
            size: 10,
            sort: 'createdAt,desc',
        };

        const brands = await brandService.getAllBrands(params);
        let options = brands?.data?.data?.content?.map((brand: { name: string; id: number }) => ({
            label: brand.name,
            value: brand.id,
        })) || [];
        selectedValues?.forEach(selectedValue => {
            if (!options.some((option: any) => option.value === selectedValue.value)) {
                options.push(selectedValue);
            }
        });
        if (options.length === 0) {
            options.push({ label: 'N/A', value: 'N/A' });
        }
        setCustomerTypeOptions(options);
    };

    useEffect(() => {
        fetchAllBrand();
    }, [searchTerm])

    const brandNames = formik?.values?.brandIds?.length > 0 
    ? formik.values.brandIds.map((brands, index) => (
        <span key={index}>
            {brands?.brandName}
            {index < formik.values.brandIds.length - 1 && (
                <span className={`${classes.seprator} px-2`}> | </span>
            )}
        </span>
    ))
    : null;
    return (
        formik.values.brandIds.length != 0 || isCustomerTypeEditing ?
            <div className=' '>
                <div className=' flex justify-between'>
                    <text className={`${classes.headingText} self-center text-2xl font-medium`}>Select your brand </text>
                </div>
                <div className="border-t border-gray-300 my-2"></div>
                <div className={`${classes.container} p-6 mt-5 flex justify-between border items-end rounded-lg`}>
                    <div className='w-1/2 '>
                        {isCustomerTypeEditing ?
                            <FilterChip
                                options={customerTypeOptions}
                                label="Brands "
                                value={selectedValues}
                                onchange={handleSelectionChange}
                                placeholder="Search Customer Group"
                                ButtonComponent={null}
                                textFieldPlaceholder='Enter Name'
                                textFieldLabel="Enter Group Name"
                                searchTerm={searchTerm}
                                setSearchTerm={setSearchTerm}
                            /> :
                            <div className='grid gap-3'>
                                <div className={`text-base font-medium ${classes.labelcolor}`}>Selected Brand : </div>
                                <div className={`text-base ${classes.textCustomer}`}>  {brandNames ? brandNames : "N/A" }</div>
                            </div>
                        }
                    </div>
                    <div className='flex gap-6'>
                       { isCustomerTypeEditing && <ButtonV2
                            variant={'tertiaryContained'}
                            label={'Cancel'}
                            onClick={() => setIsCustomerTypeEditing(!isCustomerTypeEditing)}
                        />}
                        <ButtonV2
                            variant={isCustomerTypeEditing ? 'contained' : 'tertiaryContained'}
                            label={isCustomerTypeEditing ? 'Save' : 'Edit'}
                            onClick={handleSave}
                            disabled={formik?.values?.newBrandIds?.length > 0 ? false : true && isCustomerTypeEditing}
                        />
                    </div>
                </div>
            </div>
            :
            <EmptySelectBrandTemplate setEdit={setIsCustomerTypeEditing} />
    )
}

export default SelectBrandCenterTemplate