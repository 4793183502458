import React, { useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "@mui/material";
import CustomHeading from "../CustomHeading/CustomHeading";
import closeSvg from "../../Assets/Images/close.svg";
import ImageButton from "../ImageButton/ImageButton";
import { CustomButton } from "../Button/CustomButton";
import { FormControl } from "@mui/base";
import JoditEditor from "jodit-react";

const TermsModal = ({ open, onClose, mode, setContent, termsAndCondition }) => {
  const [contentData, setContentData] = useState("");
  const handleCancel = () => {
    onClose();
  };
  const handleUpdateContent = (data) => {
    setContentData(data);
    setContent(data);
  };
  const descriptionEditor = useRef(null);
  const config1 = useMemo(() => {
    return {
      placeholder: "Start typings...",
    };
  }, []);

  useEffect(() => {
    setContentData(termsAndCondition);
  }, [termsAndCondition]);

  return (
    <Modal open={open} onClose={onClose} closeAfterTransition>
      <div className="w-2/3 m-auto mt-16 bg-white grid gap-y-6 p-6">
        <div className="flex justify-between pb-4">
          <CustomHeading text="Terms and Conditions" status="" />
          <ImageButton
            name="pageBack"
            onClick={onClose}
            className="overflow-hidden"
            imageSrc={closeSvg}
          />
        </div>

        <div className="p-4">
          <FormControl fullWidth>
            <JoditEditor
              ref={descriptionEditor}
              value={contentData}
              config={config1}
              onBlur={(newContent) => handleUpdateContent(newContent)}
            />
          </FormControl>
        </div>
        <div className="flex justify-between">
          <div></div>
          <div className="flex gap-x-5">
           {/* <CustomButton onClick={handleCancel}>Cancel</CustomButton> */}
            {mode !== "VIEW" && (
              <>
                <CustomButton variant="Primary" onClick={onClose}>
                  {mode === "EDIT" ? "Update" : "Create"}
                </CustomButton>
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TermsModal;
