import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import ChangeNameDialog from "./ChangeNameDialog";
import EditNumberDialog from "./EditNumberDialog";
import EditEmailDialog from "./EditEmailDialog";
import ChangePasswordDialog from "./ChangePasswordDialog";
import VerifyNumberDialog from "./VerifyNumberDialog";
import VerifyEmailDialog from "./VerifyEmailDialog";
import useCreate from "../../Core/CustomHooks/useCreate";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";

function createData(name, desc) {
  return { name, desc };
}

function BasicTable() {
  const { user } = useAuthenticatedUser();
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [nameDialogOpen, setNameDialogOpen] = useState(false);
  const [isChangePasswordDialogOpen, setChangePasswordDialogOpen] =
    useState(false);
  const [numberDialogOpen, setNumberDialogOpen] = useState(false);
  const [otpDialogOpen, setOtpDialogOpen] = useState(false);
  const [otpDialogOpenMail, setOtpDialogOpenMail] = useState(false);
  const[fullNum,setfullNum]=useState(user.mobileNumber )
  const { loading, putRequest } = useCreate();

  const rows = [
    createData("Name", user.fullName),
    createData("E-mail", user.email),
    createData("Mobile number", user.mobileNumber),
    createData("Password", "********"),

    // createData("Password",userDetailsState.user_newPassword),
  ];
  const handledummyFunction = () => {
    setOtpDialogOpenMail(!otpDialogOpenMail);
    return;
  };

  const hangleOpenOtp = () => {
    setOtpDialogOpenMail(false);
    setEmailDialogOpen(true);
    return;
  };
  const handleCloseOtptab = () => {
    setOtpDialogOpen(false);
    setNumberDialogOpen(true);
  };

  const openNumberDialog = () => {
    setNumberDialogOpen(true);
  };

  const closeNumberDialog = () => {
    setNumberDialogOpen(false);
  };
  const [isDialogOpen, setDialogOpen] = useState(false); // Combined state for both dialogs

  const closeDialog = () => {
    setDialogOpen(false);
  };
  const handleOpenOtpDialog = () => {
    setOtpDialogOpen(true);
    setNumberDialogOpen(false);
    closeDialog();
  };
  const handleOpenOtpMailDialog = async () => {
    setOtpDialogOpenMail(true);
    setEmailDialogOpen(false);
  };

  const handleOpenEmailDialog = () => {
    setEmailDialogOpen(true);
    setNameDialogOpen(false);
    setNumberDialogOpen(false);
    closeDialog();
    setChangePasswordDialogOpen(false);
  };

  const handleOpenNameDialog = () => {
    setEmailDialogOpen(false);
    setNameDialogOpen(true);
    setNumberDialogOpen(false);

    setChangePasswordDialogOpen(false);
  };

  const handleOpenNumberDialog = () => {
    setEmailDialogOpen(false);
    setNameDialogOpen(false);
    setNumberDialogOpen(true);
    setChangePasswordDialogOpen(false);



    
  };
  const handleOpenPasswordDialog = () => {
    setEmailDialogOpen(false);
    setNameDialogOpen(false);
    setNumberDialogOpen(false);
    setChangePasswordDialogOpen(true);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                <TableRow>
                  <strong>{row.name}</strong>
                </TableRow>
                <TableRow>
                  <div>
                    {row?.desc?.split("\n").map((pars, index) => (
                      <>
                        {pars}
                        <br />
                      </>
                    ))}
                  </div>
                </TableRow>
              </TableCell>
              <TableCell align="right">
                {row.name === "Name" && (
                  <>
                    <Button style={{ color: '#1D3679' }}onClick={handleOpenNameDialog} variant="outlined">
                      Edit
                    </Button>
                    {nameDialogOpen && (
                      <ChangeNameDialog
                        isOpen={nameDialogOpen}
                        onClose={() => setNameDialogOpen(false)}
                        previousData = {user.fullName}
                      />
                    )}
                  </>
                )}
                {row.name === "E-mail" && (
                  <>
                    <Button style={{ color: '#1D3679' }} onClick={handleOpenEmailDialog} variant="outlined">
                      Edit
                    </Button>
                    {emailDialogOpen && (
                      <EditEmailDialog
                        open={emailDialogOpen}
                        openOtpmail={handleOpenOtpMailDialog}
                        onClose={() => setEmailDialogOpen(false)}
                        // previousDat={userDetailsState.user_emailId}
                      />
                    )}
                    {otpDialogOpenMail && (
                      <VerifyEmailDialog
                        open={otpDialogOpenMail}
                        onClose={handledummyFunction}
                        openOtpmail={hangleOpenOtp}
                      />
                    )}
                  </>
                )}
                {row.name === "Mobile number" && (
                  <>
                    <Button  style={{ color: '#1D3679' } } onClick={openNumberDialog} variant="outlined">
                      Edit
                    </Button>

                    {numberDialogOpen && (
                      <EditNumberDialog
                        open={numberDialogOpen}
                        openOtpDialog={handleOpenOtpDialog}
                        onClose={closeNumberDialog}
                        setfullNum={setfullNum}
                        previousDa={user.mobileNumber}
                      />
                    )}

                    {otpDialogOpen && (
                      <VerifyNumberDialog
                        open={otpDialogOpen}
                        // fullNumber={setfullNumber}
                        fullNum={fullNum}
                        onChangeClick={handleCloseOtptab}
                        onClose={() => {
                          setOtpDialogOpen(false) && setNumberDialogOpen(false);
                        }}
                      />
                    )}
                  </>
                )}

                {row.name === "Password" && (
                  <>
                    <Button style={{ color: '#1D3679' }}
                      onClick={handleOpenPasswordDialog}
                      variant="outlined"
                    >
                      Edit
                    </Button>
                    {isChangePasswordDialogOpen && (
                      <ChangePasswordDialog
                        isOpen={isChangePasswordDialogOpen}
                        onClose={() => setChangePasswordDialogOpen(false)}
                      />
                    )}
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default BasicTable;
