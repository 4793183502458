import { createUseStyles } from "react-jss";
import ButtonV2 from "../../atoms/ButtonV2"
import { convertDate, getIndustryType, getProfileDisplayData } from "../../../utils/helper";
import { IBusinessProfile } from "../../pages/CustomerManagement/ProfileOverview.page";

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.primarylightAccent}`,
    },
    title: {
        color: theme.palette.text.primaryLight,
    },
    value: {
        "& span": {
            color: theme.palette.text.primaryLight,
        },
        "& p": {
            color: theme.palette.text.primaryLight,
        }
    }
}));

interface IOtherInformationViewTemplateProps {
    businessProfile: IBusinessProfile | null;
    setIsEditing: (value: boolean) => void;
}

const OtherInformationViewTemplate: React.FC<IOtherInformationViewTemplateProps> = ({ businessProfile, setIsEditing }) => {

    const classes = useStyles();

    return (
        <div className={`${classes.container} p-6 rounded-lg w-full`}>
            <div className='flex justify-between items-center pb-6 border-b border-grey-500'>
                <h2 className={`${classes.title} text-2xl font-medium`}>Other Information</h2>
                <ButtonV2 variant={'tertiaryContained'} label={'Edit'} onClick={() => setIsEditing(true)} />
            </div>
            <div className='grid grid-cols-3 gap-y-9 pt-6'>
                <div className={classes.value}>
                    <span>Year of Establishment</span>
                    <p className='font-medium'>{convertDate(businessProfile?.dateOfEstablishment)}</p>
                </div>
                <div className={classes.value}>
                    <span>Number of Employees</span>
                    <p className='font-medium'>{getProfileDisplayData(businessProfile?.totalEmployees)}</p>
                </div>
                <div className={`${classes.value} break-all`}>
                    <span>Website URL</span>
                    <p className='font-medium'>
                        <a href={businessProfile?.websiteUrl} target="_blank" rel="noopener noreferrer">
                            {getProfileDisplayData(businessProfile?.websiteUrl)}
                        </a>
                    </p>
                </div>
                <div className={classes.value}>
                    <span>Industry Type</span>
                    <p className='font-medium'>{getProfileDisplayData(getIndustryType(businessProfile?.industry ?? ""))}</p>
                </div>
                <div className={classes.value}>
                    <span>Brand</span>
                    <p className='font-medium'>{getProfileDisplayData(businessProfile?.brand)}</p>
                </div>
                <div className={classes.value}>
                    <span>Business Type</span>
                    <p className='font-medium'>{getProfileDisplayData(businessProfile?.businessType)}</p>
                </div>
            </div>
        </div>
    )
}

export default OtherInformationViewTemplate;
