// InventoryRoutes.js
import React from "react";
import { Route, Routes } from "react-router-dom";

import Preview from "../../Pages/Inventory/preview/Preview";
import FormStep2 from "../../Pages/Inventory/formStep2/FormStep2";
import InventoryTable from "../../Pages/Inventory/inventoryTable/InventoryTable";
import InventoryTable2 from "../../Pages/Inventory/inventoryTable/InventoryTable2";
import UploadDocs from "../../Pages/Inventory/UploadDocs/UploadDocs";
import FinalPreview from "../../Pages/Inventory/finalPreview/FinalPreview";
import FinalUpc from "../../Pages/Inventory/upcCode/FinalUpc";

import SecondaryInventoryCreate from "../../../components/pages/SecondaryInventory/SecondaryInventoryCreate.page";
import SecondaryInventoryListPage from "../../../components/pages/SecondaryInventory/SecondaryInventoryList.page";
import SecondaryInventoryView from "../../../components/pages/SecondaryInventory/SecondaryInventoryView.page";
import SecondaryInventoryUpdatePage from "../../../components/pages/SecondaryInventory/SecondaryInventoryUpdate.page";

const InventoryRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<InventoryTable />} />
      <Route exact path="/standard-prime" element={<InventoryTable />} />
      <Route exact path="/non-standard-prime" element={<InventoryTable2 />} />
      <Route exact path="/step1" element={<FinalUpc />} />
      <Route exact path="/step2" element={<FormStep2 />} />
      <Route exact path="/step3" element={<UploadDocs />} />
      <Route exact path="/step4" element={<FinalPreview />} />
      <Route exact path="/preview" element={<Preview />} />

      <Route path="/secondary" element={<SecondaryInventoryListPage />} />
      <Route path="/secondary/add" element={<SecondaryInventoryCreate />} />
      <Route path="/secondary/:id" element={<SecondaryInventoryView />} />
      <Route path="/secondary/:id/update" element={<SecondaryInventoryUpdatePage />} />
    </Routes>
  );
};

export default InventoryRoutes;
