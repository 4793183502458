import React, { useEffect, useState } from "react";
import { Button, CircularProgress, DialogContent } from "@mui/material";
import OtpInputs from "../../Shared/OtpValidation/OtpInputs";
import useCreate from "../../Core/CustomHooks/useCreate";
import { Dialog } from "@mui/material";
import CountDownTimer from "../../Shared/CountDownTimer/CountDownTimer";
import { DialogActions } from "@mui/material";
import { DialogTitle } from "@mui/material";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";

const VerifyNumberDialog = ({ open, onChangeClick, onClose,fullNum }) => {
  const [isphoneNumberEditActive, setIsphoneNumberEditActive] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState();
  const [isTimerActive, setIsTimerActive] = useState(true);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const { user, setAuthenticatedUser } = useAuthenticatedUser();
  const [otpError, setOtpError] = useState(false);
  const { loading, putRequest } = useCreate();

  const phoneNumber = user.mobileNumber;
  
  const handleCancel = () => {
    onClose();
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    onClose();
  };

  useEffect(() => {
    setOtp(["", "", "", ""]);
  }, [8779843398]);

  async function handleResendOtp() {
    setIsTimerActive(true);
    setOtp(["", "", "", ""]);
    setOtpError(false);
    await putRequest("/user/mobileNumber", { phoneNumber });
  }

  function handlePhoneNumberEdit() {
    setIsphoneNumberEditActive(!isphoneNumberEditActive);
  }

  const handleVerify = async () => {
    const otpCode = otp.join("");

    if (otpCode.length !== 4) {
      setOtpError(true);
      setOtpErrorMessage("Enter valid OTP");
      return;
    }

    let apiRes = await putRequest("/user/mobileNumber", {
      mobileNumber:fullNum,
      otp: otpCode,
    });
  
    if (apiRes === undefined) {
      console.log("Internal server error occured");
      return;
    }
    if (apiRes.status === 400) {
      setOtpError(true);
      setOtpErrorMessage("Enter correct OTP");
      return;
    } else if (apiRes.status === 200) {
      setAuthenticatedUser({ ...user, mobileNumber: fullNum });
      // updateUserDetailsState(true, "SET_IS_PHONE_NUMBER_VERIFIED");
   
    } else {
      console.log("Something went wrong");
    }
    onClose(); // Close the dialog after verification
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <div style={{ padding: "1.5rem 2.5rem 2.5rem 2.5rem" }}>
        <DialogTitle style={{ color: "#1D3679" }}>
          Verify Mobile Number
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              +91 <span>{fullNum}</span>
             
              <a
                style={{ color: "orange" }}
                onClick={onChangeClick}
              >
                &nbsp; Change
              </a>
              
        </div>
            <p>
              {" "}
              We've sent a One Time Password (OTP) to your mobile number. Please
              enter it below.
            </p>
            <br />
            <span>Enter OTP</span>
            <div className="mb-5">
              <OtpInputs
                otp={otp}
                setOtp={setOtp}
                otpError={otpError}
                setOtpError={setOtpError}
                otpErrorMessage={otpErrorMessage}
              />
            </div>
           
            <div>
              <span
                className={`orange-link-normal ${
                  isTimerActive ? "disable" : ""
                }`}
                onClick={handleResendOtp}
              >
                Resend OTP
                {isTimerActive && (
                  <span>
                    {" "}
                    <span>(</span>{" "}
                    <CountDownTimer
                      timeInMinutes={1}
                      isActive={isTimerActive}
                      setIsActive={setIsTimerActive}
                    />{" "}
                    )
                  </span>
                )}
              </span>
            </div>
          </form>
        </DialogContent>

        <DialogActions>
          <Button
            style={{ color: "#1D3679" }}
            variant="outlined"
            onClick={handleCancel}
            // onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            onClick={handleVerify}
            variant="contained"
            style={{ color: "#fff", background: "#1D3679" }}
            type="submit"
          >
            {loading ? <CircularProgress /> : "Verify"}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default VerifyNumberDialog;
