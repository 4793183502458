import * as yup from "yup";
import { createUseStyles } from 'react-jss';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { FormikErrors, useFormik } from 'formik';
import SelectV2 from '../../atoms/SelectV2';
import ButtonV2 from '../../atoms/ButtonV2';
import { IBusinessProfile } from "../../pages/CustomerManagement/ProfileOverview.page";
import { BUSINESS_PROFILE_SECTIONS, TURNOVER_DROP_DOWN_OPTIONS } from "../../../utils/constant";
import { generateYearAmountObjects } from "../../../utils/helper";

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.primarylightAccent}`,
    },
    title: {
        color: theme.palette.text.primaryLight,
    },
}));

export interface ITurnOver {
    year: string;
    amount: number;
}

export interface IFinance {
    turnOvers: ITurnOver[];
}

interface ITurnOverUpdateTemplateProps {
    updateBusinessProfileSection: (section: string, values: any) => void;
    businessProfile: IBusinessProfile | null;
    setIsEditing: (value: boolean) => void;
}

const TurnOverUpdateTemplate: React.FC<ITurnOverUpdateTemplateProps> = ({ updateBusinessProfileSection, businessProfile, setIsEditing }) => {
    const classes = useStyles();
    const { showSnackbar } = useSnackbar();

    const turnOverSchema = yup.array().of(
        yup.object().shape({
            year: yup.string().required('Year is required'),
            amount: yup.number().required('Amount is required').positive(),
        })
    );

    const initialTurnOvers = businessProfile?.finance?.turnOvers || generateYearAmountObjects(3);

    const turnoverFormik = useFormik<IFinance>({
        initialValues: {
            turnOvers: initialTurnOvers,
        },
        validationSchema: turnOverSchema,
        onSubmit: async (values: any) => {
            try {
                turnoverFormik.setSubmitting(true);
                const requestBody = {
                    turnOvers: values?.turnOvers
                }
                await updateBusinessProfileSection(BUSINESS_PROFILE_SECTIONS.TURN_OVERS, requestBody);
                setIsEditing(false);
            } catch (error) {
                showSnackbar("error", `Business profile update failed: ${error}`);
            } finally {
                turnoverFormik.setSubmitting(false);
            }
        },
    });

    return (
        <form onSubmit={turnoverFormik.handleSubmit}>
            <div className={`${classes.container} p-6 w-full rounded-lg`}>
                <div className='flex justify-between items-center pb-6 border-b border-grey-500'>
                    <h2 className={`${classes.title} text-2xl font-medium`}>Turn over Details</h2>
                    <div className='flex gap-4'>
                        <ButtonV2 variant={'tertiaryContained'} label={'Back'} type="button" onClick={() => setIsEditing(false)} />
                        <ButtonV2 variant={'primaryContained'} label={'Save'} type="submit" />
                    </div>
                </div>
                <div className='grid grid-cols-3 gap-x-6 pt-6 w-full '>
                    {turnoverFormik?.values?.turnOvers?.map((turnOver, index) => (
                        <div className='grid gap-y-1 w-full' key={index}>
                            <SelectV2
                                variant={'outlined'}
                                label={`FY ${turnOver.year}`}
                                fullWidth
                                {...turnoverFormik.getFieldProps(`turnOvers[${index}].amount`)}
                                error={turnoverFormik.touched?.turnOvers?.[index]?.amount &&
                                    Boolean((turnoverFormik?.errors?.turnOvers as FormikErrors<ITurnOver>[])?.[index]?.amount)}
                                options={TURNOVER_DROP_DOWN_OPTIONS.map((option) => ({
                                    label: option.label,
                                    value: option.value
                                }))} />
                            {turnoverFormik?.touched?.turnOvers && turnoverFormik?.touched?.turnOvers[index] && turnoverFormik?.errors?.turnOvers && (
                                <div>
                                    <small>{(turnoverFormik?.errors?.turnOvers as { [key: string]: number }[])?.[index]?.amount}</small>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </form>
    );
};

export default TurnOverUpdateTemplate;
