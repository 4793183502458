import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { ITabsSchema } from '../../../molecules/TabsV2/TabsV2';
import { VAS_MASTER_CATEGORY_TYPE } from '../../../../utils/types';
import VASMasterCategoriesListTableTemplate from '../../../templates/VASMaster/Categories/VASMasterCategoriesListTable.template';
import TabsV3 from '../../../molecules/TabsV3/TabsV3';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    heading:{
        color: theme.palette.textV2.primary.primary950,
    },
}));

const ServiceListPage: React.FC = () => {
    const classes = useStyles();
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState<VAS_MASTER_CATEGORY_TYPE>(VAS_MASTER_CATEGORY_TYPE[searchParams.get('type') as keyof typeof VAS_MASTER_CATEGORY_TYPE] ?? VAS_MASTER_CATEGORY_TYPE.SUPER_SERVICE);

    useEffect(() => {
        if (isNaN(activeTab)) return;
        const level = Object.keys(VAS_MASTER_CATEGORY_TYPE).filter(key => isNaN(parseInt(key)))[activeTab];
        setSearchParams((params: URLSearchParams) => {
            params.set('type', level)
            return params;
        })
    }, [activeTab])

    useEffect(() => {
        setActiveTab(VAS_MASTER_CATEGORY_TYPE[searchParams.get('type') as keyof typeof VAS_MASTER_CATEGORY_TYPE] ?? VAS_MASTER_CATEGORY_TYPE.SUPER_SERVICE)
    }, [searchParams.get('type')])

    const schema: ITabsSchema[] = [
        { label: 'Super Service', component: <VASMasterCategoriesListTableTemplate level={VAS_MASTER_CATEGORY_TYPE.SUPER_SERVICE} /> },
        { label: 'Main Service', component: <VASMasterCategoriesListTableTemplate level={VAS_MASTER_CATEGORY_TYPE.MAIN_SERVICE} /> },
        { label: 'Service Category', component: <VASMasterCategoriesListTableTemplate level={VAS_MASTER_CATEGORY_TYPE.SERVICE_CATEGORY} /> }
    ]

    return (
        <div className='grid gap-y-4'>
            <div className={`${classes.heading} text-base font-semibold`}>VAS Master Categories</div>
            <TabsV3 schema={schema} value={activeTab} setValue={setActiveTab} />
        </div>
    )
}

export default ServiceListPage