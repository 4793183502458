import { useState } from 'react';
import { graphTabs } from '../../../Constants/Procurement/PurchaseRequisition/constants';
import CustomTab from '../../../Shared/CustomTabs/CustomTab';
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import moment from 'moment';

const initialWeeklyData = [
  { name: '01', price: 50 },
  { name: '02', price: 25 },
  { name: '03', price: 15 },
  { name: '04', price: 35 },
  { name: '05', price: 45 },
  { name: '06', price: 30 },
  { name: '07', price: 10 },
];
const initialMonthlyData = [
  { name: '01', price: 20 },
  { name: '02', price: 25 },
  { name: '03', price: 30 },
  { name: '04', price: 35 },
  { name: '05', price: 40 },
  { name: '06', price: 45 },
  { name: '07', price: 50 },
  { name: '08', price: 40 },
  { name: '09', price: 50 },
  { name: '10', price: 50 },
  { name: '11', price: 50 },
  { name: '12', price: 75 },
];

const initialQuaterlyData = [
  { name: 'Quarter - 01', price: 20 },
  { name: 'Quarter - 02', price: 25 },
  { name: 'Quarter - 03', price: 30 },
  { name: 'Quarter - 04', price: 35 },
];
const initialHalfYearlyData = [
  { name: '01', price: 20 },
  { name: '02', price: 25 },
];
const initialAnnualData = [
  { name: '2010', price: 40 },
  { name: '2011', price: 45 },
  { name: '2012', price: 50 },
  { name: '2013', price: 55 },
  { name: '2014', price: 60 },
  { name: '2015', price: 65 },
  { name: '2016', price: 70 },
  { name: '2017', price: 75 },
  { name: '2018', price: 80 },
  { name: '2019', price: 85 },
  { name: '2020', price: 90 },
  { name: '2021', price: 95 },
  { name: '2022', price: 100 },
  { name: '2023', price: 105 },
  { name: '2024', price: 110 },
];
const graphData = [
  initialWeeklyData,
  initialMonthlyData,
  initialQuaterlyData,
  initialHalfYearlyData,
  initialAnnualData,
];

const PurchaseGraph = () => {
  const [tab, setTab] = useState<number>(0);

  const [data, setData] = useState(graphData[0]);

  const handleTabChange = (tab: number) => {
    setTab(tab);
    setData(graphData[tab]);
  };

  const CustomTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<number, string>) => {
    const TooltipLabel = moment()
      .subtract(7 - +label, 'day')
      .format('DD MMM hh:mm');

    if (active && payload && payload.length) {
      return (
        <div className="bg-white border-[0.93px] border-cgray-4 rounded-[7.4px] p-3">
          <p className="text-graph-text1 text-[9.25px] font-normal">{`${TooltipLabel}`}</p>
          <span className="text-graph-text2 font-medium flex gap-4 items-center">
            <span>{`₹${payload[0].value},000`}</span>
            <span className="bg-graph-bg text-graph-text3 text-sm px-2 py-1 rounded">
              +3.4%
            </span>
          </span>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="w-full h-[300px]">
      <div className="py-4">
        <CustomTab
          Tabs={graphTabs}
          tab={tab}
          setTab={(tab) => handleTabChange(tab)}
        />
      </div>
      <div className="h-full w-full">
        <ResponsiveContainer width="100%" height={'100%'}>
          <LineChart
            data={data}
            margin={{
              top: 10,
              right: 10,
              left: -10,
              bottom: 10,
            }}
          >
            <XAxis
              dataKey="name"
              padding={{ right: 10 }}
              className="lineText"
              strokeDasharray={'2,2'}
            />
            <YAxis padding={{ top: 10 }} strokeDasharray={'2,2'} />
            <Tooltip content={<CustomTooltip />} cursor={false} />
            <Line
              type="monotone"
              dataKey="price"
              stroke="#1D3679"
              strokeWidth={3}
              dot={{ r: 0 }}
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default PurchaseGraph;
