import React from "react";
import { useNavigate } from "react-router-dom";
import Table, { ColumnType } from "../../organisms/Table";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { createUseStyles } from "react-jss";
import { ADMIN_ROUTES } from "../../../utils/constant";
import { IOrder } from "../../pages/Order/OrderList.page";
import { enumToString } from "../../../utils/helper";
import ResourceStatus from "../../atoms/ResourceStatus/ResourceStatus";

interface LeadListTemplateProps {
	orders: IOrder[];
	setStatusUpdate: any;
	statusUpdate: any;
	handleUpdateOrder: any;
	handleSubmit: any;
	orderMasterId: number;
}

const useStyles = createUseStyles((theme: any) => ({
	viewIconContainer: {
		color: theme.palette.icon.primaryLight,
	},
	editIconContainer: {
		color: theme.palette.icon.secondryLight,
	},
	updateIconContainer: {
		color: theme.palette.icon.primaryDark,
	},
}));

const OrderListTemplate: React.FC<LeadListTemplateProps> = ({ orders,
	setStatusUpdate,
	statusUpdate,
	handleUpdateOrder,
	handleSubmit,
	orderMasterId
}) => {
	const classes = useStyles();
	const schema = {
		id: "1",
		title: "",
		pagination: {
			total: 0,
			currentPage: 0,
			isVisible: false,
			limit: 0,
		},
		columns: [
			{ label: "Sr No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
			{ label: "Product Name", key: "catalogueName", type: "number" as ColumnType, props: { className: '' } },
			{ label: "Product Price", key: "totalPrice", type: "string" as ColumnType, props: { className: '' } },
			{ label: "Product Quantity", key: "quantity", type: "number" as ColumnType, props: { className: '' } },
			{ label: "Order Status", key: "status", type: "string" as ColumnType, props: { className: '' } },
			{ label: "Warehouse", key: "warehouse", type: "string" as ColumnType, props: { className: '' } },
			{ label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } },
		],
	};

	const statusInput = (id: number, status: string) => {
		return (
			<>
				{statusUpdate.id === id ? (
					<select
						className = "w-28"
						name = "status"
						value = { statusUpdate.status }
						onChange = { (event) => handleStatusUpdateChange(event) }
					>
						<option value = "" disabled>Select Status</option>
						<option value = "ONGOING">ONGOING</option>
						<option value = "COMPLETED">COMPLETED</option>
						<option value = "CANCELLED">CANCELLED</option>
					</select>
				) : (
					<div>
						<ResourceStatus status={enumToString(status)} />
					</div>
				)}
			</>
		);
	};

	const Action = (id: number) => {
		return (
			<div className="flex gap-x-2 justify-end">
				{statusUpdate.id === id ? (
					<>
						<DoneIcon className={ `${ classes.updateIconContainer } text-secondary cursor-pointer` } onClick={() => handleSubmit()} />
						<CloseIcon className={ `${ classes.updateIconContainer } text-secondary cursor-pointer` } onClick={() => {
							setStatusUpdate({
								id: null,
								status: "",
							});
						}}
						/>
					</>
				) : (
					<>
						<RemoveRedEyeIcon className={ `${ classes.viewIconContainer } text-secondary cursor-pointer` } onClick={(e) => handleViewClick(id)} />
						<ModeEditOutlineIcon className={ `${ classes.editIconContainer } text-secondary cursor-pointer` } onClick={(e) => handleUpdateOrder(id)} />
					</>
				)}
			</div>
		);
	};

	const handleStatusUpdateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = event.target;
		if (name === "status") {
			setStatusUpdate({ ...statusUpdate, status: value });
		}
	};

	const records = orders.map((order: IOrder, index: number) => [
		order.id,
		order.catalogueName,
		order.totalPrice,
		order.quantity.toFixed(3),
		statusInput(order.id, order.status),
		order.warehouse.replace(/,\s*$/, ''),
		Action(order.id),
	]);

	const navigate = useNavigate();

	const handleViewClick = (id: number) => {
		navigate(`${ ADMIN_ROUTES.ORDER_LIST }/${orderMasterId}/${ id }`);
	};

	return (
		<div className="grid gap-y-4">
			<Table schema={schema} records={records} />
		</div>
	);
};

export default OrderListTemplate;