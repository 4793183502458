import { useEffect } from 'react';
import { Card, Typography, IconButton, Button  } from "@mui/material";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const CatalogueSelection = ({ selectedRecordsCount, onButtonClick }) => {
    useEffect(() => {
    }, [selectedRecordsCount])

    const textStyle = {
        fontWeight: 'bold',
        color: 'darkblue',
        fontSize: '1.2rem'
    };

    const countStyle = {
        fontStyle: 'italic',
        color: 'darkgreen',
        fontSize: '1.2rem'
    };

    const resultText = selectedRecordsCount === 1 ? "catalogue" : "catalogues";

    return (
        <Card style={{ padding: '10px', boxShadow: '0 0 15px rgba(0, 0, 0, 0.15)', borderRadius: '12px', margin: "5px 0px 15px 0px" }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="subtitle1">
                    <span style={textStyle}>{"Selected"}: </span> 
                    <span style={countStyle}>
                            {selectedRecordsCount}
                        {' '}
                        {resultText}
                    </span> 
                </Typography>
                
                <IconButton color={selectedRecordsCount === 0 ? "diabled"  : "success" } onClick={onButtonClick} disabled={selectedRecordsCount === 0}>
                    <WhatsAppIcon />
                    <Typography variant="h6" style={{ marginLeft: '8px' }}>
                        Share on Whatsapp
                    </Typography>
                </IconButton>
            </div>
        </Card>
    );
};

export default CatalogueSelection;
