import React, { useState } from "react";
import { Select as MuiSelect, MenuItem, SelectProps as MuiSelectProps } from "@mui/material";
import Box from "@mui/material/Box";
import { createUseStyles } from "react-jss";
import ErrorMessage from "./ErrorMessage/ErrorMessage";
import { capitalizeFirstLetter } from "../../utils/helper";
import { ReactComponent as DownArrow } from "../../assets/icons/downArrowFilled.svg";

interface Option {
    value: string | number;
    label: string;
}
interface SelectProps extends Omit<MuiSelectProps, "label"> {
    options: Option[];
    label: string;
    helperText?: string;
}
const useStyles = createUseStyles((theme: any) => ({
    input: {
        border: "1px solid",
        borderColor: theme.palette.borderV2.tertiary.tertiary200,
        fontSize: "16px",
        fontWeight: 500,
        borderRadius: 8,
        
        "&:hover": {
            borderColor: theme.palette.border.secondaryLight,
            background: theme.palette.textV2.primary.primary50,
            borderWidth: 2,
            outline: "none",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: "#DDD",
        },
        "&:focus-within": {
            outline: "none",
            borderColor: `#FD6202 !important`,
            borderWidth: "2px !important",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: `${theme.palette.border.secondaryLight} !important`,
        },
        "& .Mui-disabled": {
            // backgroundColor: "#E6E6E6",
            fontWeight: 500,
            borderColor: theme.palette.textV2.tertiary.tertiary100,
            fontSize: "16px",
            "&:hover": {
                borderColor: theme.palette.textV2.tertiary.tertiary100,
                outline: "none",
            }
        },
    },
    label: {
        color: theme.palette.textV2.tertiary.tertiary600,
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "16.1px"
    },
    placeholder: {
        color: `${theme.palette.textV2.tertiary.tertiary400} !important`,
        fontWeight: 400
    },
    icon: {
        right: 8,
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        pointerEvents: "none",
        transition: "transform 0.3s ease",
    },
    iconOpen: {
        transform: "translateY(-50%) rotate(180deg)",
    }
}));

const SelectV2: React.FC<SelectProps> = ({ options, label, helperText, ...props }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    return (
        <div className={`flex flex-col w-full relative gap-2 ${props.disabled ? 'pointer-events-none select-none': ''}`}>
            {label && (
                <div className={classes.label}>
                    {label}
                </div>
            )}
            <MuiSelect
                id={`select-${label}`}
                label=""
                defaultValue=""
                defaultChecked={true}
                {...props}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                className={classes.input}
                IconComponent={(props) => (
                    <DownArrow {...props} className={`${classes.icon} ${open ? classes.iconOpen : ''}`} />
                )}
                displayEmpty
                renderValue={() => {
                    return props.value ? (props.value as string)?.split("_")?.map((el: string) => capitalizeFirstLetter(el)).join(" ") : <span className={`${classes.placeholder} !font-normal `}>{props.placeholder}</span>
                }}
            >
                {options.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </MuiSelect>
            {
                props.error === true && <ErrorMessage message={helperText} />
            }
        </div>
    );
};
export default SelectV2;