import { Chip } from "@mui/material";

export type StatusType =
  | "success"
  | "pending"
  | "warning"
  | "ongoing"
  | "cancelled"
  | "auto"
  | "manual"
  | "default"
  | "default2";


// Todo

const StatusChips = ({ text, type }: { text: string; type: StatusType }) => {
  const styles = {
    success: "bg-badge-success",
    pending: "bg-badge-pending",
    warning: "bg-badge-warning",
    ongoing: "bg-badge-ongoing",
    auto: "bg-badge-auto",
    manual: "bg-cgray-1",
    cancelled: "bg-badge-cancelled",
    default: "bg-blue",
    default2: "bg-badge-default",
  };
  const label: string =
    typeof text === "string" ? text.replaceAll("_", "-").toLowerCase() : "";
  return (
    <Chip
      label={label}
      size="small"
      className={`capitalize text-white ${styles[type]}`}
    />
  );
};

export default StatusChips;
