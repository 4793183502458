import React from "react";
import { SkuData } from "../../pages/Lead/QuotationView.page";
import { createUseStyles } from "react-jss";
import { getShapeName } from "../../../utils/helper";

interface SecondaryProductDetailTemplateProps {
	orderProductUpc: any;
	skuData: SkuData[];
}

const useStyles = createUseStyles((theme: any) => ({
	title: {
		color: theme.palette.text.primaryDarkLight,
	},
	property: {
		color: theme.palette.text.secondaryDarkAccent,
		background: theme.palette.background.secondaryDark,
	}
}));

const SecondaryProductDetailTemplate: React.FC<SecondaryProductDetailTemplateProps> = ({ orderProductUpc, skuData }) => {
	const classes = useStyles();
	const productDetail = [
		{ label: "Class", value: "SECONDARY" },
		{ label: "Shape", value: getShapeName(orderProductUpc?.shape) },
		{ label: "Grade", value: orderProductUpc?.grade },
	];

	return (
		<div className="flex gap-x-5 ">
			<div className="w-1/5">
				{orderProductUpc?.catalogueImages && orderProductUpc.catalogueImages.length > 0 ? (
					<img src={ orderProductUpc?.catalogueImages?.[0]?.path } alt="ProductImage" />
				) : (
					<span>No Image Available</span>
				)}
			</div>
			<div className="w-4/5 grid gap-y-4">
				<div className={ `${ classes.title } flex gap-x-12 w-fit` }>
					{productDetail.map((detail, index) => (
						<div className="grid gap-y-1.5 text-sm" key={ index }>
							<p className="font-normal">{ detail.label }</p>
							<p className="font-medium">{ detail.value }</p>
						</div>
					))}
				</div>

				<div className={ `${ classes.property } w-fit flex gap-x-6 p-3 rounded` }>
					<div className={ `flex gap-x-6` }>
						{orderProductUpc?.attributes && orderProductUpc?.attributes.productSpecification?.map((property: any, i: any) => (
							<div className="grid text-sm" key={i}>
								<p className="font-normal">{ property?.name }</p>
								<p className="font-medium">{ property?.minValue } - { property?.maxValue }</p>
							</div>
						)
						)}
					</div>
				</div>
				<div className={ `${ classes.title } w-fit flex gap-x-8  `}>
					<div className="grid gap-y-3">
						<p className="text-base font-normal">Packaging Types</p>
						<p className="text-base font-medium">{ skuData.map((e: any) => e.packaging) }</p>
					</div>
					<div className="grid gap-y-3">
						<p className="text-base font-normal">Warehouse</p>
						<p className="text-base font-medium">{ skuData.map((e: any) => e.warehouse) }</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SecondaryProductDetailTemplate;
