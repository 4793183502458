import { useEffect, useState } from "react";
import { CLASS_TYPE, IPagination, RESOURCE_TYPE } from "../../../utils/types";
import { ADMIN_ROUTES, BULK_UPLOAD_TEMPLATE_TYPE, SHAPE_TYPES } from "../../../utils/constant";
import TitleHeading from "../../atoms/CustomHeading/TitleHeading";
import Button from "../../atoms/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { IProductUPCRequest, useUpcService } from "../../../services/useUpcService";
import UpcListTemplate from "../../templates/UPC/UpcList.template";
import BulkUploadTemplateDownload from "../../organisms/BulkUpload/BulkUploadTemplateDownload";
import excelIcon from "../../../assets/icons/excel.svg";
import uploadIcon from "../../../assets/icons/upload.svg";
import { useBulkUploadService } from "../../../services/useBulkUploadService";
import BulkUploadTemplate from "../../organisms/BulkUpload/BulkUploadTemplate";

export interface IUpcs {
    id: number;
    upcCode: string
    productId: number;
    productCategory: string;
    classType: string;
    shape: string;
    status: string;
    attributes: string;
    createdAt: string;
    updatedAt: string;
}

export interface IUpcFilter {
    status: string;
    sort: string;
    search: string;
    productCategory: string;
}

const UpcListPage: React.FC = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search); 
    const initialPaginationValues: IPagination = {
        currentPage:  parseInt(queryParams.get('page') || '0') || 0,
        pageSize: parseInt(queryParams.get('size') || '10') || 10,
        totalPages: 1,
        totalRecords: 0,
    }
    const initialProductUPCFiltersValues: IUpcFilter = {
        status: queryParams.get('status') || "ALL",
        sort: queryParams.get('sort') || "createdAt,desc",
        search: queryParams.get('search') || "",
        productCategory: queryParams.get('productCategory') || "ALL",
    }

    const [upcs, setUpcsTo] = useState<IUpcs[]>([]);
    const [upcFilters, setUpcFiltersTo] = useState<IUpcFilter>(initialProductUPCFiltersValues);
    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);

    const upcService = useUpcService();
    const bulkUploadService = useBulkUploadService();

    const refreshUpc = (page: number, size: number) => {
        let params: IProductUPCRequest = {
            upcCode: upcFilters?.search === "ALL" ? "" : upcFilters?.search.trim(),
            status: upcFilters?.status === "ALL" ? "" : upcFilters?.status,
            page: page,
            size: size,
            sort: upcFilters?.sort,
            categoryId: upcFilters.productCategory === "ALL" ? undefined : parseInt(upcFilters.productCategory, 10),
            classType: CLASS_TYPE.STANDARD
        }
        upcService.getProductUPCs(params)
            .then((upcResponse) => {
                if (upcResponse.data.data) {
                    const { totalElements, totalPages } = upcResponse?.data?.data;
                    setPaginationTo({
                        ...pagination,
                        totalPages: totalPages,
                        totalRecords: totalElements
                    });
                    setUpcsTo(upcResponse?.data?.data?.content);
                }
                else {
                    setUpcsTo([]);
                }
            })
            .catch((error) => {
                console.error("Brands fetching error - ", error);
            });
    }

    const handleFiltersChange = (event: any) => {
        const { name, value } = event.target;
        setPaginationTo({...pagination, currentPage: 0})
        setUpcFiltersTo({ ...upcFilters, [name]: value ?? "" });
    }

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage
        }));
    };

    useEffect(() => {
        refreshUpc(pagination.currentPage, pagination.pageSize);
    }, [pagination.currentPage, pagination.pageSize, upcFilters.search, upcFilters.status, upcFilters.sort, upcFilters.productCategory]);


    const navigate = useNavigate();
    
    const handleCreateUpc = () => {
		navigate(`${ ADMIN_ROUTES.UPC_CREATE }`);
	};

    const getTemplateFile = () => {
        if (!parseInt(upcFilters.productCategory)) {
            return false;
        }
        return bulkUploadService.getBulkUploadTemplate({
            categoryId: parseInt(upcFilters.productCategory),
            shapes: SHAPE_TYPES.map(item => item.id),
            classes: [CLASS_TYPE.STANDARD],
        },
            { resourceType: RESOURCE_TYPE.PRODUCT_UPCS });
    }

    useEffect(() => {
        const params = new URLSearchParams();
    
        pagination.pageSize && params.set('size', pagination.pageSize.toString());
        pagination.currentPage && params.set('page', pagination.currentPage.toString());
        upcFilters.status && params.set('status', upcFilters.status);
        upcFilters.sort && params.set('sort', upcFilters.sort);
        upcFilters.search && upcFilters.search && params.set('search', upcFilters.search);
        upcFilters.productCategory && params.set('productCategory', upcFilters.productCategory);
    
        navigate(`${location.pathname}?${params.toString()}`, { replace: true });
    }, [pagination, upcFilters]);

    return (
        <div className="grid gap-y-3">
            <div className="flex justify-between">
                <TitleHeading text="Universal Product Code" />
                <div className="flex gap-3">
                    <BulkUploadTemplateDownload icon={excelIcon} getTemplateFile={getTemplateFile} type={BULK_UPLOAD_TEMPLATE_TYPE.APPLICATION_OCTET_STREAM} productCategory = {upcFilters.productCategory}  />
                    <BulkUploadTemplate icon={uploadIcon} section = {RESOURCE_TYPE.PRODUCT_UPCS} />
                    <Button variant="contained" label="Add UPC" onClick={handleCreateUpc} />
                </div>
            </div>
            <UpcListTemplate upcs={ upcs } pagination={ pagination } handlePaginationChange={ handlePaginationChange } handleRowsPerPageChange = { handleRowsPerPageChange }  handleFiltersChange={ handleFiltersChange } upcFilters={ upcFilters } />
        </div>
    )
}

export default UpcListPage;
