
import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function SearchTags({ updateFilters, searchPayload }) {
  const [selectedValues, setSelectedValues] = useState({});

  const convertSearchPayload = (searchData) => {
    const outputObject = {};
    for (const key in searchPayload) {
      if (key !== 'attributes') {
        if (searchPayload[key] && searchPayload[key].label !== undefined) {
          outputObject[key] = searchPayload[key].label;
        } else {
          outputObject[key] = searchPayload[key];
        }
      }

    }
    return outputObject;
  };


  useEffect(() => {
    setSelectedValues(convertSearchPayload(searchPayload))
  }, [searchPayload])


  const handleTagClick = (field) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      [field]: null,
    }));
    updateFilters({ ...selectedValues, [field]: null });
  };


  const stackStyles = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  };

  const chipStyles = {
    margin: '4px',
    textAlign: 'left',
    whiteSpace: 'nowrap',
  };

  return (
    <Stack style={stackStyles}>
      {Object.keys(selectedValues).map((field) => {
        const value = selectedValues[field];
        return value ? (
          <Chip
            key={field}
            label={value}
            onDelete={() => handleTagClick(field)}
            style={chipStyles}
          />
        ) : null;
      })}
    </Stack>
  );
}
