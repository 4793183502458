import React from 'react'
import viewEyeIcon from "./../../Assets/Icons/viewEyeIcon.svg";
import editPenIcon from "./../../Assets/Icons/editPenIcon.svg";
import deleteBinIcon from "./../../Assets/Icons/deleteBinIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Checkbox from '@mui/material/Checkbox';

import axios from 'axios';
import { formatDate } from '../../Core/Handlers/DateFormat';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
const BaseUrl = process.env.REACT_APP_API_V1_URL;

const SecondaryCatalogueRecord = ({ catalogueDetails,catalogueType,category, updateCatalogueDetails, setSecondaryCatalogueRefresh, curIndex=0, updateSelectedCustomers, selectedCustomers, enableCatalogueShare, selectedCatalogueName, updateCatalogueName }) => {
  const { user } = useAuthenticatedUser();
  const navigate = useNavigate();

  if (!catalogueDetails) {
    return <div>Loading...</div>;
  }


  const handleViewClick = (catalogueId) => {
    const mode = "VIEW";
    navigate(`/catalogue/secondary-view?id=${catalogueId}&mode=${mode}`, { state: { category: category, mode: mode, catalogueType: catalogueType,catalogueId: catalogueId } });

  };

  const handleEditClick = (catalogueId) => {
    const mode = "EDIT";
    navigate(`/catalogue/secondary-edit?id=${catalogueId}&mode=${mode}`, { state: { category: category, mode: mode, catalogueType: catalogueType ,catalogueId: catalogueId } });
  };

  const handleDeleteClick = (catalogueId) => {
    const confirmed = window.confirm("Are you sure you want to delete this secodary catalogue?");
    if (confirmed) {
      axios
        .delete(`${BaseUrl}/secondary-catalogue/${catalogueId}`, {
          headers: {
            Authorization: `${user.token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(response => {

          setSecondaryCatalogueRefresh(response.data.data);
          // Handle success, if needed
        })
        .catch(error => {
          console.error('Error during API call:', error);
        });
    }
  };

  const handleCustomerCheck = (customerId, catalogueName) => {
    // const updatedCustomers = { ...selectedCustomers };
    const updatedCustomers = {  };
    if (updatedCustomers[customerId]) {
      delete updatedCustomers[customerId];
    } else {
      updatedCustomers[customerId] = true;
    }
    if(!selectedCatalogueName){
      updateCatalogueName(catalogueName)
    }
    updateSelectedCustomers(updatedCustomers)
  }


  return (
    <div>
      {catalogueDetails.length > 0 &&
        catalogueDetails.map((item, index) => (
          <div key={index} className="catalogue-details self-stretch flex flex-row p-[0.75rem] items-center justify-start gap-[0.75rem] border-b border-gray-300">

            <div className="flex-1 flex flex-col items-start justify-left">
              <div className="flex items-center justify-center w-full sm:w-[calc(11rem / 1.6)]">
                {enableCatalogueShare && (
                  <div className="relative text-left mr-2">
                    <Checkbox key={index} checked={selectedCustomers[item?.id] || false} onClick={(event) => handleCustomerCheck(item?.id, item?.catalogueName)} disabled={item.status === "UNPUBLISHED"} />
                  </div>)}
                  <div className="relative text-left">{curIndex + index + 1}</div>
              </div>
            </div>

            <div className="flex-1 flex flex-col items-center justify-start">
              <div className="relative  w-full sm:w-[calc(11rem / 1.6)]  text-left">{item.catalogueName}</div>
            </div>

            <div className="flex-1 flex flex-col items-center justify-start">
              <div className="relative  w-full sm:w-[calc(11rem / 1.6)]  text-left">{item.categoryName}</div>
            </div>

            <div className="flex-1 flex flex-col items-center justify-start">
              <div className="relative  w-full sm:w-[calc(11rem / 1.6)]  text-center">{formatDate(item.createdAt)}</div>
            </div>
            
            <div className="flex-1 flex flex-col items-center justify-start">
              <div className="relative  w-full sm:w-[calc(11rem / 1.6)] text-center">{formatDate(item.updatedAt)}</div>
            </div>

            <div
              className={`flex-1 flex flex-col items-center justify-start ${item.status === "ACTIVE" ? "text-green" : "text-inactive"
                }`}
            >
              <div className="flex-1 flex flex-col items-center justify-start">
                <div className="relative  w-full sm:w-[calc(11rem / 1.6)]  text-center">
                  {item.status === 'ACTIVE' ? 'Active' : 'Inactive'}
                </div>
              </div>
            </div>



            <div className="flex-1 flex flex-col items-center justify-start text-center ">
              <div className="relative  w-full sm:w-[calc(11rem / 1.6)]  text-center">
                {/* <div className="flex flex-row items-center justify-start text-center   w-[11rem]"> */}
                <button
                  className="relative w-[1.13rem] h-[1.13rem] overflow-hidden shrink-0 text-center mr-2"
                  onClick={(e) => handleViewClick(item.id)}
                >
                  <img className="w-full h-full" alt="" src={viewEyeIcon} />
                </button>

                <button
                  className="relative w-[1.13rem] h-[1.13rem] overflow-hidden shrink-0 text-center mr-2 "
                  onClick={(e) => handleEditClick(item.id)}
                >
                  <img className="w-full h-full" alt="" src={editPenIcon} />
                </button>

                <button
                  className="relative w-[1.13rem] h-[1.13rem] overflow-hidden shrink-0 text-center "
                  onClick={(e) => handleDeleteClick(item.id)}
                >
                  <img className="w-full h-full" alt="" src={deleteBinIcon} />
                </button>
              </div>
            </div>

          </div>
        ))}
    </div>
  )
}

export default SecondaryCatalogueRecord

