import React, { useEffect, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import { FormControl } from '@mui/material';
import ButtonV2 from "../../atoms/ButtonV2";
import AutoCompleteTextField, { AutoCompleteOption } from '../../molecules/AutoCompleteInput/AutoCompleteInputV2';
import ChipsV2, { ChipItem } from "../../molecules/Chips/ChipsV2";
import { IUpcSearchQueryParams, useCustomerGroupMasterService } from "../../../services/useCustomerGroupMasterService";
import { CUSTOMER_GROUP_SECTIONS, HTTP_STATUS } from "../../../utils/types";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { IMaterialFilterData } from "./GenericSerachDialog.template";
import { IProductUpcFilterRequest, IProductUPCRequest, useUpcService } from "../../../services/useUpcService";
import { CLASS_TYPE } from "../../../utils/types";
import { PRODUCT_SECTION, STATUS } from "../../../utils/constant";
import { useSecondaryInventoryService } from "../../../services/useSecondaryInventoryService";
import FilterChip from "../../molecules/FilterChip/FilterChip";
import { IMultiSelectOption } from "../../atoms/MultiSelectInput2";

interface ProductCodeSearchFormTemplateProps {
    materialFilters: IMaterialFilterData;
    sectionType: string;
    setFilterData: (materialFilters: IMaterialFilterData) => void;
    onFilterSubmit: () => void;
    handleClose: () => void;
    handleFilterClear: () => void;
}

interface IUpcsByFilter {
    id: number;
    upcCode: string
    productId: number;
    productCategory: string;
    classType: string;
    shape: string;
    status: string;
    attributes: string;
    createdAt: string;
    updatedAt: string;
}

interface IBrandUpcsByFilter {
    upcCode: string;
    status: string;
    brand: string;
    upcId: number;
}

interface ISecondaryInventory {
    id: number;
    skuCode: string;
    name: string;
    warehouseName: string;
    quantity: number;
    createdAt: string;
    updatedAt: string;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid #EBEFFA`
    },
    filterHeading: {
        color: theme.palette.textV2.primary.primary900,
    },
    selectLabel: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    configure: {
        color: theme.palette.textV2.primary.primary500,
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600,
    },
    activeProgressBar: {
        backgroundColor: "#CA4F02"
    },
    inActiveProgressBar: {
        backgroundColor: "#FD6202"
    },
    input: {
        "& .MuiInputBase-root": {
            borderRadius: "8px !important",
        },
    },
    errorMessage: {
        color: theme.palette.textV2.failure.failure600,
    },
}));

const materialFilterData = {
    productCodeArray: [],
    productCodeValue: {
        label: "",
        id: ""
    },
}

const ProductCodeSearchFormTemplate: React.FC<ProductCodeSearchFormTemplateProps> = ({ materialFilters, sectionType, setFilterData, onFilterSubmit, handleClose, handleFilterClear }) => {
    const classes = useStyles();
    const [upcs, setUpcsTo] = useState<IUpcsByFilter[]>([]);
    const [brandUpcs, setBrandUpcsTo] = useState<IBrandUpcsByFilter[]>([]);

    const [secondaryInventories, setSecondaryInventoriesTo] = useState<ISecondaryInventory[]>([]);
    const upcService = useUpcService();
    const secondaryInventoryService = useSecondaryInventoryService();
    const customerGroupService = useCustomerGroupMasterService();

    const formik = useFormik<any>({
        initialValues: materialFilterData,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            onFilterSubmit()
            handleClose()

        }
    });

    useEffect(() => {
        formik.setFieldValue("productCodeArray", materialFilters.productCode ?? []);
    }, [materialFilters.productCode])

    const refreshUpc = async (inputValue?: string) => {
        try {
            if ( sectionType === PRODUCT_SECTION.MASTER_UPC) {
                let params: IProductUPCRequest = {
                    page: 0,
                    size: 10,
                    status: '',
                    upcCode: inputValue ?? '',
                    sort: "createdAt,desc",
                    classType: CLASS_TYPE.STANDARD
                }
                const upcFilterResponse = await upcService.getProductUPCs(params);
                if (
                    upcFilterResponse?.status === HTTP_STATUS.OK &&
                    upcFilterResponse?.data?.data?.content?.length
                ) {
                    setUpcsTo(upcFilterResponse.data.data.content);
                } else {
                    throw new Error("Upcs fetch failed");
                }
            }
            else {
                if(sectionType === PRODUCT_SECTION.BRAND_UPC)
                {
                    const requestBody: IProductUpcFilterRequest = {
                      status: STATUS.ACTIVE,
                        upcCode: inputValue ?? '',
                        classType: CLASS_TYPE.STANDARD
                    };
        
                    const upcFilterResponse = await customerGroupService.getUpcByMaterialFilter({
                        page: 0,
                        size: 10,
                        sort: "createdAt,desc",
                        upcCode: inputValue ?? ''
                    },
                    {
                        classes:[CLASS_TYPE.STANDARD]    
                    }
                    );
                    if (
                        upcFilterResponse?.status === HTTP_STATUS.OK &&
                        upcFilterResponse?.data?.data
                    ) {
                        setBrandUpcsTo(upcFilterResponse.data.data.content);
                    } else {
                        throw new Error("Upcs fetch failed");
                    }
                }
            }
        } catch (error) {
            setBrandUpcsTo([]);
            setUpcsTo([]);
        }
    };

    const getAllSecondaryInventories = async (inputValue?: string) => {
        let params = {
            skuCode: inputValue ?? '',
            page: 0,
            size: 10,
            sort: "createdAt,desc",
        }

        const skuFilterResponse = await secondaryInventoryService.getAllSecondaryInventories({}, params);
        if (
            skuFilterResponse?.status === HTTP_STATUS.OK &&
            skuFilterResponse?.data?.data?.content?.length
        ) {
            setSecondaryInventoriesTo(skuFilterResponse.data.data.content);
        } else {
            throw new Error("SKU fetch failed");
        }
    }


    const upcOptions = useMemo(() => {
        return upcs?.map((upc: IUpcsByFilter) => ({ label: upc.upcCode, value: upc.id })) ?? []
    }, [upcs])

    const brandUpcOptions = useMemo(() => {
        return brandUpcs?.map((upc: IBrandUpcsByFilter) => ({ label: upc.upcCode, value: upc.upcId })) ?? []
    }, [brandUpcs])

    const skuOptions = useMemo(() => {
        return secondaryInventories?.map((sku: ISecondaryInventory) => ({ label: sku.skuCode, value: sku.id })) ?? []
    }, [secondaryInventories])


    useEffect(() => {
        if ((sectionType === PRODUCT_SECTION.BRAND_UPC || sectionType === PRODUCT_SECTION.MASTER_UPC)) {
            refreshUpc();
        } else if (sectionType === PRODUCT_SECTION.SECONDARY) {
            getAllSecondaryInventories();
        }
    }, []);

    const updateSelectedUpc = (key: string) => (option: IMultiSelectOption[] | null) => {
        if (option) {
            formik.setFieldValue(`${key}Array`, option);
            setFilterData({
                ...materialFilters,
                productCode: option
            })
        }
    };

    const handleClearClick = (key: string) => {
        formik.setFieldValue(`${key}Array`, []);
        setFilterData({
            ...materialFilters,
            productCode: []
        })

    };


    return (
        <div className={`${classes.container} m-4 p-4 grid rounded-lg  border-b`}>
            <div className="flex justify-between">
                <div className={`${classes.filterHeading} text-lg font-medium my-auto`}>Search By Product Code</div>
            </div>
            <div className={`grid gap-y-2 pt-2`}>
                {(sectionType === PRODUCT_SECTION.BRAND_UPC || sectionType === PRODUCT_SECTION.MASTER_UPC) && <div className="grid gap-y-2 w-2/3">
                    <FilterChip
                        options={sectionType === PRODUCT_SECTION.MASTER_UPC ? upcOptions : brandUpcOptions}
                        label=""
                        value={formik.values.productCodeArray}
                        onchange={updateSelectedUpc('productCode')}
                        placeholder="Search by UPC"
                        ButtonComponent={ButtonV2}
                        buttonLabel="Clear All"
                        buttonOnClick={() => handleClearClick('productCode')}
                        textFieldPlaceholder='Search...'
                        setSearchTerm={refreshUpc}
                        optionToShow={1}
                    />

                </div>
                }
                {sectionType === PRODUCT_SECTION.SECONDARY && <div className="grid gap-y-2 w-2/3">
                    <FilterChip
                        options={skuOptions}
                        label=""
                        value={formik.values.productCodeArray}
                        onchange={updateSelectedUpc('productCode')}
                        placeholder="Search by SKU"
                        ButtonComponent={ButtonV2}
                        buttonLabel="Clear All"
                        buttonOnClick={() => handleClearClick('productCode')}
                        textFieldPlaceholder='Search...'
                        setSearchTerm={getAllSecondaryInventories}
                        optionToShow={1}
                    />


                </div>
                }
            </div>
        </div>
    );
};

export default ProductCodeSearchFormTemplate;