import React, { useState } from 'react';
import MasterRelatedMasterUpcTemplate from './MasterRelatedMasterUpc.template';
import MasterRelatedBrandsTemplate from './MasterRelatedBrands.template';

export interface IMasterRelatedUpc{
    id: number;
    upcCode: string;
    upcTitle: string;
}

const MasterRelatedUpcTemplate: React.FC<IMasterRelatedUpc> = ({id, upcCode, upcTitle}) => {
   

    return (
        <div>
                <MasterRelatedBrandsTemplate id ={id} upcCode ={upcCode} upcTitle ={upcTitle}/>
        </div>
    )
}
export default MasterRelatedUpcTemplate