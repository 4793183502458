import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export const STANDARD_URLS = {
  GET_ALL_STANDARD: "/standards",
  GET_STANDARD: "/standard/:id",
  CREATE_STANDARD: "/standard",
  UPDATE_STANDARD: "/standard/:id",
};

 export interface IStandardFilterRequest{
  search?: string;
  status?: string;
  page: number;
  size: number;
  sort?: string;
}

export interface IStandardRequest{
  name: string;
  prefix?: string;
  yearInUse: string |  number;
  yearLastUpdated: string| number;
  hsnCodes?: string;
  bisHeading: string;
  equivalentTags: string;
  status: string;
  application: string;
  features: string;
  description: string;
}

export interface Standard {
  id: number;
  name: string;
  prefix: string;
  yearInUse: number;
  yearLastUpdated: number;
  hsnCodes: string;
  bisHeading: string;
  equivalentTags: string;
  status: string;
  createdBy: string | null;
  updatedBy: string | null;
  application: string;
  features: string;
  description: string;
  createdAt: string;
  updatedAt: string;
}

export const useStandardService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const getStandardById = (id: number) => {
    return request(API_METHOD.GET, replaceUrlParams( STANDARD_URLS.GET_STANDARD, { id } ), authenticatedUser);
  };

  const getAllStandards = (params: IStandardFilterRequest) => {
    return request(API_METHOD.GET, STANDARD_URLS.GET_ALL_STANDARD, authenticatedUser, null, { params } );
  };

  const createStandard = (data: IStandardRequest) => {
    return request(API_METHOD.POST, STANDARD_URLS.CREATE_STANDARD, authenticatedUser, data);
  };

  const updateStandard = (id: number, data: IStandardRequest) => {
    return request(API_METHOD.PUT, replaceUrlParams( STANDARD_URLS.UPDATE_STANDARD, { id } ), authenticatedUser, data);
  };

  return {
    getStandardById,
    createStandard,
    updateStandard,
    getAllStandards,
  };
};
