import { useContext, useEffect } from 'react';
import CustomHeading from '../../../Shared/CustomHeading/CustomHeading';
import ProductCardDetails from './ProductCardDetails';
import { FieldArray, Form, Formik, FormikValues } from 'formik';

import * as Yup from 'yup';
import { PROCUREMENT_TEXT } from '../../../Constants/Procurement/PurchaseRequisition/constants';
import {
  ProductDataType,
  ProductFormData,
} from '../../../Constants/Procurement/PurchaseRequisition/types';
import FormNavigationButton from './FormNavigationButton';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import { WareHouseContext } from '../../../../contexts/WareHouseDetailContext';

export interface AddPRDetailsProps {
  products: ProductDataType[];
  setProducts: (product: ProductDataType[]) => void;
  setCompletedTab: (tab: number) => void;
  formData: ProductFormData[];
  onSubmit: (values: FormikValues) => void;
}

const validationSchema = Yup.object().shape({
  items: Yup.array().of(
    Yup.object().shape({
      itemId: Yup.string().required('Item ID is required'),
      quantity: Yup.number().required('Quantity is required'),
      packagingType: Yup.string().required('Packaging type is required'),
      warehouseId: Yup.string().required('Consignee location is required'),
      deliveryTime: Yup.string().required('Delivery time is required'),
      remarks: Yup.string().required('Remarks are required'),
      tnc: Yup.string(),
    })
  ),
});

const AddPRDetails = ({
  products,
  setProducts,
  setCompletedTab,
  formData,
  onSubmit,
}: AddPRDetailsProps) => {
  const { user }: any = useAuthenticatedUser();

  const handleDelete = (product: ProductDataType) => {
    const newList = products.filter((item) => item !== product);
    setProducts(newList);
  };
  const { locations } = useContext(WareHouseContext);

  const initialValues = {
    items: products.map((product, index) => ({
      itemId: product.id,
      quantity: (formData.length > 0 && formData[index].quantity) || '',
      packagingType:
        (formData.length > 0 && formData[index].packagingType) || '',
      warehouseId: (formData.length > 0 && formData[index].warehouseId) || '',
      deliveryTime: (formData.length > 0 && formData[index].deliveryTime) || '',
      remarks: (formData.length > 0 && formData[index].remarks) || '',
      tnc: (formData.length > 0 && formData[index].tnc) || '',
      uom: (formData.length > 0 && formData[index].uom) || 'MT',
      docURL: (formData.length > 0 && [product.productCategoryImageURL]) || [],
      productRequirement:
        (formData.length > 0 && formData[index].productRequirement) || '',
    })),
  };

  useEffect(() => {}, [locations]);

  return (
    <div>
      <div className="flex bg-cgray-2 justify-between ">
        <div className="text-blue py-6 px-4">
          <CustomHeading text="Enter Requirement Details" status="" />
          <p className="text-cgray-1 text-xs">
            {PROCUREMENT_TEXT.AddPRDetailsDescription}
          </p>
        </div>
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          onSubmit(values);
        }}
        validationSchema={validationSchema}
      >
        {({ values }) => (
          <Form>
            <FieldArray name="items" validateOnChange={false}>
              {({ remove }) => (
                <div className="border-cgray-4 ">
                  {values.items.length > 0 &&
                    values.items.map(
                      (formData: ProductFormData, index: number) => (
                        <ProductCardDetails
                          key={'productCard' + index}
                          index={index}
                          item={products[index]}
                          formData={formData}
                          locations={locations}
                          handleDelete={() => {
                            handleDelete(products[index]);
                            remove(index);
                          }}
                        />
                      )
                    )}
                </div>
              )}
            </FieldArray>
            <div className="shadow-pagination px-4 py-3 sticky mt-4 z-50 bg-white bottom-4 w-full text-blue flex justify-between items-center rounded-lg">
              <div className="w-full"></div>
              <FormNavigationButton
                onBackClick={() => setCompletedTab(1)}
                isLastStep={false}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddPRDetails;
