import { createUseStyles } from 'react-jss';
import { RESOURCES } from '../../../utils/constant';
import { useSnackbar } from '../../../hooks/useSnackBar';

interface BulkUploadTemplateDownloadProps {
  icon: string;
  getTemplateFile: () => Promise<any> | boolean; 
  type: string;
  productCategory?: string;
}

const useStyles = createUseStyles((theme: any) => ({
  downloadButtonWrapper: {
    display: "flex",
    padding: "13px 26px",
    justifyContent: "center",
    alignItems: "center",
    gap: "12px",
    borderRadius: "4px",
    border: `1px solid ${theme.palette.border.primaryLightAccent}`,
    cursor: "pointer",
  },
  downloadWrapper: {
    color: theme.palette.text.primaryDarkLight,
    fontFamily: "Roboto",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "600",
    textTransform: "capitalize",
  }
}))

const BulkUploadTemplateDownload: React.FC<BulkUploadTemplateDownloadProps> = ({ icon, getTemplateFile, type ,productCategory}) => {
  const classes = useStyles();
  const { showSnackbar, SnackBarComponent } = useSnackbar();

  const handleDownload = () => {
    if (productCategory && productCategory === "ALL") {
      showSnackbar("error", `Please select a Product category first`);
    } else {
      const response = getTemplateFile();
      if (typeof response === 'boolean') {
        return false;
      }
      response.then(response => {
        const byteCharacters = atob(response?.data?.data?.data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: type });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = response?.data?.data?.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch((error) => {
        console.error('Error fetching file data:', error);
      })
    }
  }; 

  return (
    <>
      {SnackBarComponent}
      <div onClick={handleDownload}>
      <button className={classes.downloadButtonWrapper} >
        <img src={icon} alt="excel" />
        <div className={classes.downloadWrapper}>{RESOURCES.DOWNLOAD_FORMAT}</div>
      </button>
      </div>
    </>
  );
}

export default BulkUploadTemplateDownload;


