import React, { ReactNode } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { MODE } from '../../../utils/constant';
import { createUseStyles } from 'react-jss';

interface CategoryFormTemplateProps {
    mode: string,
    onClose: () => void,
    component: ReactNode
}

const useStyles = createUseStyles((theme: any) => ({
    textColor: {
        color: theme.palette.text.primaryDarkLight
    },
    border: {
        borderBottom: `1px solid ${theme.palette.border.primaryDark}`
    },
    errorMessage: {
        color: theme.palette.action.danger,
    }
}));

const CategoryFormTemplate: React.FC<CategoryFormTemplateProps> = ({ component, mode, onClose }) => {
    const classes = useStyles();
    return (
        <div className="p-6 border flex flex-col gap-6" >
            <div className={`flex justify-between w-full pb-6 items-center text-2xl font-semibold ${classes.textColor} ${classes.border}`}>
                <h3 className='select-none'>{mode === MODE.UPDATE ? "Update" : mode === MODE.VIEW ? "View" : "Add New"} Product Categories</h3>
                <CloseIcon className='close-icon' onClick={() => { onClose() }} />
            </div>
            <div>
                {component}
            </div>
        </div>
    )
}

export default CategoryFormTemplate