import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';

import deleteIcon from '../../../../assets/icons/deleteIcon.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { LocationSectionProps } from './VendorLocation.template';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { ADMIN_ROUTES } from '../../../../utils/constant';
import ButtonV2 from '../../../atoms/ButtonV2';
import { useBusinessProfileService } from '../../../../services/useBusinessProfileService';
import GntnDetailModalTemplate from '../../Dashboard/GstnDetailModalTemplate.template';
import GetNumberOfVendorLocationTemplate from './GetNumberOfVendorLocation.template';
import SuccessFailurePopup from '../../../molecules/SuccessFailurePopup/SuccessFailurePopup';


const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.tritiaryLight}`
    },
    titleText: {
        color: theme.palette.text.primaryLight
    },
    button: {
        color: theme.palette.border._secondaryDark
    },
}));

const ViewVendorLocationTemplate: React.FC<LocationSectionProps> = ({ formik, setCurrentSectionTo , currentSection }) => {
    const classes = useStyles();
    const [gstinModalDialogOpen, setGstinModalDialogOpen] = useState<boolean>(false);
    const [currentGstin, setCurrentGstin] = useState<string>('');
    const bussinessProfileService = useBusinessProfileService();
    const [buttonShow, setButtonShow] = useState<boolean>(true);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const params = useParams();
    const id = Number(params.id);
    const navigate = useNavigate();

    const handleBackToDashboard = ()=> {
        navigate(ADMIN_ROUTES.VENDOR_MANAGEMENT_LIST);
     };

    const locationSectionProps: LocationSectionProps = {
        setCurrentSectionTo: setCurrentSectionTo,
        formik: formik,
        setButtonShow: setButtonShow,
        currentSection: currentSection,
    };

    const deleteLocation = async (index: number) => {
        setIsDeleteModalOpen(true);
        setDeleteIndex(index);
    };
    const onClickModal = (gstin: string) => {
        setGstinModalDialogOpen(true);
        setCurrentGstin(gstin);
    };

    const handleDeleteDialogClose = async (event: boolean) => {
        setIsDeleteModalOpen(false);
        if (event && deleteIndex != null) {
        const locations = [...formik.values.locations];
        locations.splice(deleteIndex, 1);
        formik.setFieldValue('locations', locations);
        try {
            await bussinessProfileService.updateBusinessProfile(id, locations, 'LOCATIONS');
        } catch (error) {
            showSnackbar("error", `Business profile update failed ${error}`)
        }
        }
    };
    return (
        <>
            {formik.values?.locations?.map((location: any, index: number) => (
                <div key={index} className={`${classes.container} p-6 rounded-lg`}>
                    <div className='flex gap-6 items-end'>
                        <div>
                            <ButtonV2
                                variant="contained"
                                label={String(index + 1)}
                                onClick={() => { }}
                                size='small'
                            />
                        </div>
                        <div className='grid gap-y-2 w-1/3'>
                            <text className={`${classes.titleText} font-normal text-sm`}>Gst Number</text>
                            <div className={`${classes.titleText} font-medium text-base`}>{location.gstin}</div>
                        </div>
                        <div className='grid gap-y-2 w-1/3'>
                            <text className={`${classes.titleText} font-normal text-sm`}>Location</text>
                            <div className={`${classes.titleText} font-medium text-base`}>{location.state}</div>
                        </div>
                        <div>
                            <ButtonV2
                                className={`${classes.button}`}
                                variant="tertiaryContained"
                                label="View Details"
                                onClick={() => { onClickModal(location.gstin) }}
                                size='small'
                            />
                        </div>
                        <div className='mb-2 pl-20'>
                            <img src={deleteIcon} alt="deleteicon" className="blink-on-hover blink-on-click" onClick={() => deleteLocation(index)} />
                        </div>
                    </div>
                </div>
            ))}
             {
                    isDeleteModalOpen &&
                    <SuccessFailurePopup
                    width="458px"
                    height="400px"
                    status={false}
                    heading="Delete Location?"
                    description="Are you sure you want to delete the Location?"
                    setShowPopup={setIsDeleteModalOpen}
                    button1={{
                        text: 'Yes',
                        variant: "tertiaryContained",
                        size: "large",
                        onClick: () => handleDeleteDialogClose(true),
                    }}
                    button2={{
                        text: 'No',
                        variant: "secondaryContained",
                        size: "large",
                        onClick: () => handleDeleteDialogClose(false)
                    }}
                />
                }
            {gstinModalDialogOpen && <GntnDetailModalTemplate dialogOpen={gstinModalDialogOpen} setDialogOpen={setGstinModalDialogOpen} gstin={currentGstin} />}
            {buttonShow &&
            <div className='flex justify-self-end gap-3'>
                 <ButtonV2
                    className={`${classes.button}`}
                    variant="secondaryContained"
                    label="Back to DashBoard "
                    onClick={handleBackToDashboard}
                />
                <ButtonV2
                    className={`${classes.button}`}
                    variant="tertiaryContained"
                    label="Add New Location"
                    onClick={() => { setButtonShow(false) }}
                />
            </div>}
            {
               !buttonShow && <GetNumberOfVendorLocationTemplate {...locationSectionProps} />
            }
            
        </>
    );
}
export default ViewVendorLocationTemplate;