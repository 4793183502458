import React, { useEffect, useState } from 'react';
import { FormData, Indent } from '../../../Constants/Procurement/Indent/types';
import { usePRContext } from '../../../../hooks/useProcurementContext'
import { useNavigate } from 'react-router-dom';
import IndentDetails from '../../../Components/Procurement/ReverseActionRFQ/IndentDetails';
import { Button } from '@mui/material';
import ReviewAddActionDetails from '../../../Components/Procurement/ReverseActionRFQ/ReviewAddActionDetails';
import {
  Auction,
  AuctionFormData,
} from '../../../Constants/Procurement/ReverseAuctionRFQ/types';
import moment from 'moment';
import { PAYMENT_MODE } from '../../../Constants/Procurement/Indent/constants';

const ReviewReverseAuctionRFQ = () => {
  const { vendorDiscoveryParams } = usePRContext();
  const navigate = useNavigate();
  const [indentDetails, setIndentDetails] = useState<FormData>();
  const [formData, setFormData] = useState<AuctionFormData>();
  const InviteVendorsHandler = () => {
    navigate('/procurement/reverse-auction/vendor-discovery/select-vendor');
  };

  // check for auction id
  // if not present go back to auction list
  useEffect(() => {
    if (!vendorDiscoveryParams.selectedAuction) {
      navigate('/procurement/reverse-auction/vendor-discovery/');
    } else {
      const { auction, ...Indentdetails } =
        vendorDiscoveryParams.selectedAuction as Auction;

      setIndentDetails(indentToFormData(Indentdetails));
      if (auction) {
        const startDate = moment.unix(auction.startDateTime).startOf('day');
        const startTime = moment
          .unix(auction.startDateTime)
          .diff(startDate, 'seconds');
        const endDate = moment.unix(auction.endDateTime).startOf('day');
        const endTime = moment
          .unix(auction.endDateTime)
          .diff(endDate, 'seconds');

        const payload: AuctionFormData = {
          id: auction.id,
          auctionName: auction.auctionName,
          startDate: moment(startDate).unix(),
          endDate: moment(endDate).unix(),
          startTime: startDate.add(startTime, 'seconds').unix(),
          endTime: endDate.add(endTime, 'seconds').unix(),
          auctionStartPrice: auction.auctionStartAmount,
          auctionReversedPrice: auction.reserveAmount,
          auctionTargetPrice: auction.targetAmount,
          decrementBidAmount: auction.minBidStep,
          bestPrice: auction.showBestPriceToBidders,
          winnerName: auction.showWinnerNameToBidders,
          autoBidAllow: auction.autoBiddingEnabled,
          dynamicTimeExt: auction.dynamicTimeExtensionInSec,
          dynamicTime: Boolean(auction.dynamicTimeExtensionInSec),
          maxAutoBidAllow: auction.autoBiddingEnabled,
          maxAutoBid: auction.minBidStep,
          tnc: auction.tnc,
        };
        setFormData(payload);
      }
    }
  }, []);

  const handleBack = () => {
    navigate('/procurement/vendor-discovery/');
  };

  const indentToFormData = (formData: Indent) => {
    return {
      id: formData.id,
      items: formData.items.map((procurement) => ({
        purchase_requisition_id: procurement.purchaseRequisitionId,
        quantity: procurement.quantity,
        uom: procurement.uom,
      })),
      cess: formData.cess,
      gst: formData.gst,
      tcs: formData.tcs,
      payment_terms:
        PAYMENT_MODE.find((mode) => mode.value === formData?.paymentTerms?.days)
          ?.name || '',
      quality_check: formData.qualityCheck,
      purchase_type: formData.purchaseType,
      tnc: formData.tnc,
      transportation_mode: formData.transportationMode,
      warehouse_id: formData.warehouseId,
      buyer_id: formData.buyerID,
    };
  };

  return (
    <div className="grid  px-3">
      <div className="text-xl font-semibold text-blue my-6">
        Reverse Auction RFQ
      </div>
      <div className="flex text-blue rounded-md bg-cgray-2 justify-between items-center text-xl font-semibold px-[16px] py-[10px]  mb-5">
        <div>Auction RFQ</div>
        <Button
          variant="outlined"
          className="flex items-center h-[48px] w-[119px] bg-blue text-white normal-case hover:bg-blue rounded-none"
        >
          Download
        </Button>
      </div>
      <div className="grid p-6">
        <div className="flex text-blue rounded-md bg-cgray-2 justify-between items-center text-xl font-semibold px-[10px] py-[16px]">
          <div>Indent ID : {indentDetails && indentDetails.id}</div>
          <div className="flex items-center">
            {' '}
            {`Quantity :  ${
              indentDetails &&
              indentDetails.items
                .reduce((total, item) => total + item.quantity, 0)
                ?.toFixed(2)
            } MT`}
          </div>
        </div>
        <div className="mb-8">
          {indentDetails && <IndentDetails indentDetails={indentDetails} />}
        </div>
        {formData && (
          <div className="flex flex-col gap-6 mx-6">
            <div className="text-2xl font-semibold text-blue ">
              Action Details
            </div>
            <div className="border-solid border-b border-cgray-3 flex flex-row justify-between w-full  pt-1 py-5">
              <div className="text-2xl font-semibold  text-text-color">
                Auction ID : RAC12345
              </div>
            </div>
            <ReviewAddActionDetails formData={formData} />
            {/* down Button */}
            <div className="flex justify-end mt-4">
              <div className="flex ml-auto gap-3  mb-7">
                <button
                  className="text-sm font-semibold text-black border border-blue-900 flex flex-row justify-center py-3 px-8 rounded"
                  onClick={handleBack}
                >
                  Back
                </button>
                <button
                  className="text-sm font-semibold text-white bg-blue flex flex-row justify-center py-3 px-8 rounded"
                  onClick={InviteVendorsHandler}
                >
                  Invite Vendors
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewReverseAuctionRFQ;
