import React from "react";
import { Grid } from "@mui/material";

interface Cards {
  backgroundImg: string;
  icon: React.ReactNode;
  title: string;
  value: number | string;
}
interface CardListProps {
  cards: Cards[];
}

const RecordCard: React.FC<CardListProps> = ({ cards }) => {
  return (
    <Grid container spacing={2}>
      {cards.map((leadCard, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <div className="w-full flex gap-x-2 p-5 rounded-2xl" style={ { backgroundImage: `url(${leadCard.backgroundImg})`, backgroundSize: "cover" } } >
            {leadCard.icon}
            <div>
              <div className="text-white text-base font-medium"> {leadCard.title}</div>
              <div className="text-white text-2xl font-medium"> {leadCard.value}</div>
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};
export default RecordCard;