import BidProductCard from './BidProductCard';
import { Grid } from '@mui/material';
import { AUCTION } from '../../../Constants/Procurement/LiveAuction/text';
import CustomHeading from '../../../Shared/CustomHeading/CustomHeading';
import productImg from '../../../Assets/Images/product.png';
import { AuctionProductList } from '../../../Constants/Procurement/LiveAuction/constants';

interface Props {
  indentId: string;
  quantity: number;
  products: AuctionProductList[];
  onClick: () => void;
}

const ProductDetails = ({ products, quantity, indentId, onClick }: Props) => {
  return (
    <div className="bg-variant-cyan p-3 rounded-md my-4">
      <div className="flex justify-between mb-2">
        <CustomHeading text={AUCTION.IndentId + ' ' + indentId} status="" />
        <CustomHeading
          text={AUCTION.TotalQty + ' ' + quantity.toString() + ' MT'}
          status=""
        ></CustomHeading>
        <div
          onClick={onClick}
          className="text-[20px] font-semibold text-blue text-right underline cursor-pointer"
        >
          View all {products.length} products
        </div>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={9.5}>
          <div className="gap-5 flex">
            {products.map((product, i) =>
              i < 2 ? (
                <BidProductCard {...product} key={i + product.item.id + '00'} />
              ) : (
                ''
              )
            )}
          </div>
        </Grid>
        {products.length > 2 ? (
          <Grid item xs={2.5}>
            <div className="relative flex justify-center border h-full rounded-md px-3 border-cgray-14 bg-white items-center">
              <div>
                <img
                  src={products[2]?.item?.productCategoryImageURL}
                  className="rounded-md w-[67px] h-[63px]"
                />
              </div>
              {products.map((item, i) =>
                i > 2 && i < 4 ? (
                  <div
                    key={'productImg' + i + item.item.id}
                    className="-ml-5 rounded-md  pl-1 bg-white"
                  >
                    <img
                      src={item?.item?.productCategoryImageURL}
                      alt=""
                      className="rounded-md w-[67px] h-[63px]"
                    />
                  </div>
                ) : (
                  ''
                )
              )}
              {products.length > 5 ? (
                <div className="-ml-5 rounded-md bg-white pl-1 cursor-pointer">
                  <span className="rounded-md px-2 h-[63px] bg-variant-cyan2 text-blue text-sm flex items-center justify-center text-nowrap font-semibold">
                    {products.length - 5} more
                  </span>
                </div>
              ) : (
                ''
              )}
            </div>
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </div>
  );
};

export default ProductDetails;
