import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CustomButton } from '../../Shared/Button/CustomButton';
import JoditEditor from "jodit-react";
import axios from 'axios';
import CustomHeading from '../../Shared/CustomHeading/CustomHeading';
import { FormControl, TextField } from "@mui/material";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import uploadIcon from "../../Assets/Icons/uploadIcon.svg";
import Typography from "@mui/material/Typography";
import { FilesWrapper } from "../../../App/Shared/Files/DisplayFiles.jsx";
import { DisplayDocuments } from "../../../App/Shared/Files/FileItem.jsx";
import SecondaryCataloguePreview from './SecondaryCataloguePreview';
import SecondarySearch from './SecondarySearch';
import FormHelperText from "@mui/material/FormHelperText";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { CLASS_TYPE as CATALOGUE_CLASS_TYPE } from '../../../utils/types';
import { CATALOGUE_TYPES, CLASS_TYPE, ADMIN_ROUTES, MODE } from "./../../../utils/constant";
import { makeRoute,getKeyByValue, cleanString } from "../../../utils/helper";
import CataloguePackingTypes from '../Catalogue/CataloguePackingTypes';


const BaseUrl = process.env.REACT_APP_API_V1_URL;

const AddSecondaryCatalogue = () => {

    const { user } = useAuthenticatedUser();
    const navigate = useNavigate();
    const location = useLocation();
    const mode = location.state?.mode;
    const getbyId = location.state?.catalogueId;
    const category = location?.state.category ?? {};
    const catalogueType = location?.state.catalogueType ?? {};
    const [secondaryProductIdList, setSecondaryProductIds] = useState([]);
    const [currentState, setCurrentState] = useState(1);
    const [catalogueName, setCatalogueName] = useState();
    const [minimumQuantity, setMinimumQuantity] = useState(0);
    const [availableQuantity, setAvailableQuantity] = useState();
    const [application, setApplication] = useState([]);
    const [additionalProductDetails, setAdditionalProductDetails] = useState("");
    const [keywords, setKeywords] = useState([]);
    const [status, setStatus] = useState("ACTIVE");
    const [defectDetails, setDefectDetails] = useState("");
    const [price, setPrice] = useState();
    const [packagingType, setPackagingType] = useState([]);
    const [termsConditions, setTermsConditions] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [imageData, setImageData] = useState([]);
    const [formImageData, setFormImageData] = useState([]);
    const [variablePrices, setVariablePrices] = useState([]);
    const [imageIdList, setImageId] = useState([]);
    const [selectedPackagingTypes, setSelectedPackingTypes] = useState([]);


    const [formErrors, setFormErrors] = useState({});

    const fetchSecondaryCatalogueDetail = async () => {
        if (mode === "EDIT") {
            const response = await axios.get(`${BaseUrl}/secondary-catalogue/${getbyId}`, {
                headers: {
                    Authorization: `${user.token}`,
                    "Content-Type": "application/json",
                }
            });
            if (response.status === 200) {
                setSecondaryProductIds(response.data.data.secondaryProductIds);
                setCatalogueName(response.data.data.catalogueName);
                setMinimumQuantity(response.data.data.minimumQuantity);
                setAvailableQuantity(response.data.data.availableQuantity);
                setApplication(response.data.data.application.split(',').map(keyword => keyword.trim()));
                setAdditionalProductDetails(response.data.data.additionalProductDetails);
                setKeywords(response.data.data.keywords.split(',').map(keyword => keyword.trim()));
                setDefectDetails(response.data.data.defectDetails);
                setPackagingType(response.data.data.packagingType);
                setTermsConditions(response.data.data.termsConditions);
                setPrice(response.data.data.price.default);
                setStatus(response.data.data.status);
                setSelectedPackingTypes(response.data.data.packagingType);
            } else {
                console.error('API call failed:');
            }
        }
    }

    const fetchDocuments = async (resId, resType) => {
        if (mode === "EDIT") {
            try {
                if (!resId || !resType) {
                    return;
                }
                const apiUrl = `${BaseUrl}/files/${resType}/${resId}`;

                const response = await axios.get(apiUrl, {
                    headers: {
                        Authorization: `${user.token}`
                    }
                });
                setImageData(response.data.data);
            } catch (err) {
                // Handle the error, if needed
                console.error("Error fetching documents:", err);
            }
        }
    };

    useEffect(() => {
        fetchSecondaryCatalogueDetail();
        fetchDocuments(getbyId, "SECONDARY_CATALOGUE");
    }, [getbyId]);

    const initialValuesstep1 = {
        secondaryProductIds: secondaryProductIdList ? secondaryProductIdList : []
    };

    const validatestep1 = (values) => {
        const errors = {};
        let flag = false;
        if (values.secondaryProductIds.length === 0) {
            errors.secondaryProductId = true;
            flag = true;
        }
        setFormErrors(errors);
        return flag;
    };

    const initialValuesstep2 = {
        catalogueName: catalogueName ? catalogueName : "",
        minimumQuantity: minimumQuantity ? minimumQuantity : "",
        availableQuantity: availableQuantity ? availableQuantity : "",
        keywords: keywords ? keywords : "",
        application: application ? application : "",
        defectDetails: defectDetails ? defectDetails : "",
        additionalProductDetails: additionalProductDetails ? additionalProductDetails : "",
    };

    const validatestep2 = (values) => {
        const errors = {};
        let flag = false;
        if (!values.catalogueName) {
            errors.catalogueName = "Please Enter CatalogueName";
            flag = true;
        }
        if (!values.minimumQuantity) {
            errors.minimumQuantity = "Please Enter minimumQuantity";
            flag = true;
        }
        if (!values.application || values.application.length === 0) {
            errors.application = true;
            flag = true;
        }
        if (!values.defectDetails) {
            errors.defectDetails = true;
            flag = true;
        }
        if (!values.additionalProductDetails) {
            errors.additionalProductDetails = true;
            flag = true;
        }
        if (!values.keywords || values.keywords.length === 0) {
            errors.keywords = true;
            flag = true;
        }

        setFormErrors(errors);
        return flag;
    };

    const initialValuesstep3 = {
        variablePrices: variablePrices ? variablePrices : [],
        price: price ? price : "",
        packagingType: packagingType ? packagingType : "",
        termsConditions: termsConditions ? termsConditions : "",
    };

    const validatestep3 = (values) => {
        const errors = {};
        let flag = false;

        if (!values.price) {
            errors.price = true;
            flag = true;
        } else if (typeof values.price === 'object') {
            if ('default' in values.price) {
                const defaultPrice = values.price.default.trim();

                if (defaultPrice === '' || isNaN(Number(defaultPrice)) || Number(defaultPrice) <= 0) {
                    errors.price = true;
                    flag = true;
                }
            } else {
                const variablePriceEntries = Object.entries(values.price);
                const isValidVariablePrice = variablePriceEntries.every(
                    ([quantity, price]) =>
                        quantity.trim() !== '' &&
                        price.trim() !== '' &&
                        !isNaN(Number(quantity)) &&
                        !isNaN(Number(price)) &&
                        Number(price) > 0
                );
                if (!isValidVariablePrice) {
                    errors.price = true;
                    flag = true;
                }
                const variablePrices = values?.variablePrices.map(({ quantity, price }) => ({
                    quantity: quantity?.trim(),
                    price: price?.trim(),
                }));

                const isValidEntry = (entry) =>
                    entry.quantity !== '' &&
                    entry.price !== '' &&
                    !isNaN(Number(entry.quantity)) &&
                    !isNaN(Number(entry.price)) &&
                    Number(entry.price) > 0;

                if (!variablePrices.every(isValidEntry)) {
                    errors.price = true;
                    flag = true;
                }
            }
        }

        if (!values.packagingType || values.packagingType.length === 0) {
            errors.packagingType = true;
            flag = true;
        }
        if (!values.termsConditions) {
            errors.termsConditions = true;
            flag = true;
        }

        setFormErrors(errors);
        return flag;
    };

    const initialValuesstepPreview = {
        status: status ? status : " ",

    };

    const validatestepPreview = (values) => {
        const errors = {};
        let flag = false;
        if (!values.status) {
            errors.status = true;
            flag = true;
        }

        setFormErrors(errors);
        return flag;
    };

    const handleSubmitSecondaryCatalogue = async () => {

        const isImagePresent = imageData.length > 0 || formImageData.length > 0; 
        // if (!isImagePresent) {
        //     alert("Please upload image for the catalogue.");
        //     return;
        // }
        if (imageIdList.length > 0) {
          deleteImages();
        }

        let packagingType = [];
        if (selectedPackagingTypes.length > 0) {
            packagingType.push(selectedPackagingTypes[0]?.value);
        }
        const applicationString = application.join(', ');
        const keywordString = keywords.join(', ');
        const body = {
            secondaryProductId: catalogueType === getKeyByValue(CATALOGUE_TYPES, "currentInventory") ? (mode === MODE.EDIT  ? null : secondaryProductIdList[0]) : null,
            catalogueName,
            minimumQuantity,
            availableQuantity,
            application: applicationString,
            defectDetails,
            additionalProductDetails,
            keywords: keywordString,
            status,
            packagingType,
            termsConditions,
            price: {"default":price},
            catalogueType: catalogueType,
            secondaryProductIds: ( mode === MODE.EDIT || catalogueType === getKeyByValue(CATALOGUE_TYPES, "makeToOrder") ) ? secondaryProductIdList : null
        }

        if (mode === "EDIT") {
            axios
                .put(`${BaseUrl}/secondary-catalogue/${getbyId}`, body, {
                    headers: {
                        Authorization: `${user.token}`,
                        'Content-Type': 'application/json',
                    },
                })
                .then(response => {
                    var resourceId = response.data.data.id;
                    var resourceType = "SECONDARY_CATALOGUE";
                    var createdBy = response.data.data.createdBy;
                    if (
                        handleCatalogueImageData(
                            formImageData,
                            resourceId,
                            resourceType,
                            createdBy
                        )
                    ) {
                        navigate(-1); 
                        return;
                    }
                    navigate(-1);

                })
                .catch(error => {
                    if (error.response.status === 500) {
                        alert("Catalogue already exists or something went wrong. Please try again");
                      } else {
                        alert("Error in Creating the Catalogue");
                      }
                      return
                });
        } else {
            axios
                .post(`${BaseUrl}/secondary-catalogue`, body, {
                    headers: {
                        Authorization: `${user.token}`,
                        'Content-Type': 'application/json',
                    },
                })
                .then(response => {
                    var resourceId = response.data.data.id;
                    var resourceType = "SECONDARY_CATALOGUE";
                    var createdBy = response.data.data.createdBy;

                    if (
                        handleCatalogueImageData(
                            imageData,
                            resourceId,
                            resourceType,
                            createdBy
                        )
                    ) {
                        navigate(makeRoute(ADMIN_ROUTES.CATALOGUE, { query: { "catalogueType": CATALOGUE_TYPES[catalogueType], "category": CLASS_TYPE[category] } }));


                        return;
                    }

                })
                .catch(error => {
                    if (error.response.status === 500) {
                        alert("Catalogue already exists or something went wrong. Please try again");
                      } else {
                        alert("Error in Creating the Catalogue");
                      }
                      return
                });
        }

    };

    const deleteImages = async () => {
        try {
          const combinedimageIds = imageIdList.join(", ");
          const apiUrl = `${BaseUrl}/files/${combinedimageIds}`;
          const response = await axios.delete(apiUrl, {
            headers: {
              Authorization: `${user.token}`,
            },
          });
        } catch (error) {
          if (error.response.status === 500) {
            alert("Something went wrong. Please try again");
          } else {
            alert("Error in Deleting the Image");
          }
        }
    };

    const handleCatalogueImageData = async (
        imageData,
        resourceId,
        resourceType,
        createdBy
    ) => {

        if (!resourceId || !resourceType) {
            return false;
        }

        const formData = new FormData();

        for (let i = 0; i < imageData.length; i++) {
            formData.append(`files`, imageData[i]);
        }

        const uploadFilesData = {
            resourceType: resourceType,
            resourceId: resourceId,
            createdBy: createdBy,
        };

        formData.append(
            "resource",
            new Blob([JSON.stringify(uploadFilesData)], {
                type: "application/json",
            })
        );

        try {
            const response = await axios.post(`${BaseUrl}/files/`, formData, {
                headers: {
                    Authorization: `${user.token}`,
                    // Do not set Content-Type manually, let the browser handle it
                },
            });
            return true;
        } catch (error) {
            console.error("error", error);
            return false;
        }
    };

    const handleNextClick = () => {
        setCurrentState((prev) => {
            let nextState = prev;
            if (prev === 1) {
                (!validatestep1(initialValuesstep1)) &&
                    nextState++;
            } else if (prev === 2) {
                (!validatestep2(initialValuesstep2)) &&
                    nextState++;
            } else if (prev === 3) {
                if (!validatestep3(initialValuesstep3)) {
                    nextState++;
                }
            } else if (prev === 4) {
                const isImagePresent = imageData.length > 0 || formImageData.length > 0;  
                // if (!isImagePresent) {
                //     alert("Please upload image for the catalogue.");
                // } else {
                    nextState++;
                // }
            } else if (prev === 5) {
                (!validatestepPreview(initialValuesstepPreview)) && setIsButtonDisabled(true);
                nextState++;
            } else {
                nextState++;
            }
            return nextState;
        });
    };
    const handlePrevClick = () => {
        setCurrentState((prev) => {
            let nextState = prev;
            if (prev === 1) {
                setImageId([]);
                navigate(-1)
            }else {
                nextState--;
            }
            return nextState;
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentState]);

    const renderContent = () => {
        switch (currentState) {
            case 1:
                return (<HandleCaseOne
                    secondaryProductIds={secondaryProductIdList}
                    setSecondaryProductIds={setSecondaryProductIds}
                    formErrors={formErrors}
                    catalogueType={catalogueType}
                    setMinimumQuantity={setMinimumQuantity}
                    setIsButtonDisabled={setIsButtonDisabled}
                />);
            case 2:
                return (
                    <HandleCaseTwo
                        catalogueName={catalogueName}
                        catalogueType={catalogueType}
                        setCatalogueName={setCatalogueName}
                        minimumQuantity={minimumQuantity}
                        setMinimumQuantity={setMinimumQuantity}
                        availableQuantity={availableQuantity}
                        setAvailableQuantity={setAvailableQuantity}
                        application={application}
                        setApplication={setApplication}
                        additionalProductDetails={additionalProductDetails}
                        setAdditionalProductDetails={setAdditionalProductDetails}
                        keywords={keywords}
                        setKeywords={setKeywords}
                        defectDetails={defectDetails}
                        setDefectDetails={setDefectDetails}
                        formErrors={formErrors}
                        setFormErrors={setFormErrors}
                    />
                );
            case 3:
                return (<HandleCaseThree
                    termsConditions={termsConditions}
                    setTermsConditions={setTermsConditions}
                    price={price}
                    setPrice={setPrice}
                    packagingType={packagingType}
                    setPackagingType={setPackagingType}
                    variablePrices={variablePrices}
                    setVariablePrices={setVariablePrices}
                    formErrors={formErrors}
                    setFormErrors={setFormErrors}
                    selectedPackagingTypes={selectedPackagingTypes}
                    setSelectedPackingTypes={setSelectedPackingTypes}
                    
                />);
            case 4:
                return (<UploadDocs
                    imageData={imageData}
                    setImageData={setImageData}
                    mode={mode}
                    formImageData={formImageData}
                    setFormImageData={setFormImageData}
                    setImageId={setImageId}

                />);
            case 5:
                return (<SecondaryCataloguePreview
                    status={status === 'ACTIVE' ? true : false}
                    secondaryProductIds={secondaryProductIdList}
                    setStatus={setStatus}
                    catalogueName={catalogueName}
                    minimumQuantity={minimumQuantity}
                    availableQuantity={availableQuantity}
                    application={application.join(',')}
                    additionalProductDetails={additionalProductDetails}
                    keywords={keywords.join(',')}
                    defectDetails={defectDetails}
                    price={price}
                    packagingType={packagingType}
                    termsConditions={termsConditions}
                    formErrors={formErrors}
                    imageData={imageData}
                    imageDataform={formImageData}
                    mode={mode}
                    formImageData={formImageData}
                    catalogueType={catalogueType}
                    category={category}
                />);
            default:
                return (<null />);
        }
    };
    return (
        <>
            <CustomHeading text={mode === MODE.EDIT ? "Edit Catalogue" : "Add New Catalogue"} status="" />
            <div>{renderContent()}</div>
            <div className="flex justify-between">
                <div className="m-5"></div>
                <div className="flex gap-x-3 py-6 px-10">
                    <CustomButton onClick={handlePrevClick}>Back</CustomButton>
                    <CustomButton variant="Primary" onClick={currentState === 5 ? handleSubmitSecondaryCatalogue : handleNextClick} isDisabled={mode === 'EDIT' ? false : isButtonDisabled} >{currentState === 5 ? "Submit" : "Next"}</CustomButton>
                </div>
            </div>
        </>

    )
}

const HandleCaseOne = ({ secondaryProductIds, setSecondaryProductIds, formErrors, setIsButtonDisabled, catalogueType, setMinimumQuantity }) => {
    return (
        <div className=' rounded bg-white py-6 px-10 '>

            <SecondarySearch
                type={"SECONDARY"}
                isCheckBoxView={true}
                setMinimumQuantity={setMinimumQuantity}
                secondaryProductIds={secondaryProductIds}
                setSecondaryProductIds={setSecondaryProductIds}
                formErrors={formErrors}
                catalogueType={catalogueType}
                setIsButtonDisabled={setIsButtonDisabled}
            />
        </div>

    )
}

const HandleCaseTwo = ({
    setDefectDetails,
    defectDetails,
    catalogueName,
    catalogueType,
    setCatalogueName,
    minimumQuantity,
    setMinimumQuantity,
    availableQuantity,
    setAvailableQuantity,
    application,
    setApplication,
    additionalProductDetails,
    setAdditionalProductDetails,
    keywords,
    setKeywords,
    formErrors,
    setFormErrors
}) => {
    const descriptionEditor = useRef(null);
    const AdditionalEditor = useRef(null);
    const [tags, setTags] = useState('');
    const [keyTags, setKeyTags] = useState('');
    const config1 = useMemo(() => {
        return {
            placeholder: "Start typings...",
        };
    }, []);
    const config2 = useMemo(() => {
        return {
            placeholder: "Start typings...",
        };
    }, []);

    const onDefectDetailsChange = (data) => {
        setFormErrors((prevFormErrors) => ({
            ...prevFormErrors,
            defectDetails: null,
        }));
        setDefectDetails(cleanString(data));
    }
    const onAdditionalDetailsChange = (data) => {
        setFormErrors((prevFormErrors) => ({
            ...prevFormErrors,
            additionalProductDetails: null,
        }));
        setAdditionalProductDetails(cleanString(data));
    }

    const handleApplicationsChange = (event) => {
        setFormErrors((prevFormErrors) => ({
            ...prevFormErrors,
            application: null,
        }));
        setTags(event.target.value);
    };
    const handleKeywordsChange = (event) => {
        setFormErrors((prevFormErrors) => ({
            ...prevFormErrors,
            keywords: null,
        }));
        setKeyTags(event.target.value);
    };

    const handleAddTag = () => {
        if (tags.trim() !== '') {
            setApplication([...application, tags.trim()]);
            setTags('');
        }
    };
    const handleAddKeyTag = () => {
        if (keyTags.trim() !== '') {
            setKeywords([...keywords, keyTags.trim()]);
            setKeyTags('');
        }
    };

    const handleQuantityChange = (event, value, type) => {
        event.preventDefault();
        const floatRegex = /^(\d{0,4}(\.\d{0,3})?)$/;
        if (!floatRegex.test(value)) {
            if (type === "min") {
                setFormErrors((prevFormErrors) => ({
                    ...prevFormErrors,
                    minimumQuantity: "Please enter a valid number between 0 and 9999.999",
                }));
            } else {
                setFormErrors((prevFormErrors) => ({
                    ...prevFormErrors,
                    availableQuantity: "Please enter a valid number between 0 and 9999.999",
                }));
            }
            return
        } else if (floatRegex.test(value) || value !== '') {
            if (type === "min") {
                setFormErrors((prevFormErrors) => ({
                    ...prevFormErrors,
                    minimumQuantity: null,
                }));
            } else {
                setFormErrors((prevFormErrors) => ({
                    ...prevFormErrors,
                    availableQuantity: null,
                }));
            }
            type === "min" ? setMinimumQuantity(value) : setAvailableQuantity(value)
            return
        }
        type === "min" ? setMinimumQuantity("") : setAvailableQuantity("")

    };


    const handleRemoveTag = (tagToRemove) => {
        setApplication(application.filter((tag) => tag !== tagToRemove));
    };
    const handleRemoveKeyTag = (tagToRemove) => {
        setKeywords(keywords.filter((tag) => tag !== tagToRemove));
    };
    return (


        <div className=' rounded bg-white py-6 px-10 '>
            <div className='relative font-semibold text-xl'>
                Product Information
            </div>
            <div className='flex space-x-5 mt-4 '>

                <TextField sx={{ width: "50%" }}
                    label="Enter Catalogue Name*"
                    variant="outlined"
                    value={catalogueName}
                    onChange={(e) => setCatalogueName(e.target.value)}
                    error={formErrors?.catalogueName ? true : false}
                    helperText={formErrors?.catalogueName || ''}
                />

                <TextField sx={{ width: "50%" }}
                    label="Minimum Order Quantity (MT) *"
                    variant="outlined"
                    type="text"
                    value={minimumQuantity ?? ''}
                    onChange={(e) => handleQuantityChange(e, e.target.value, "min")}
                    error={formErrors.minimumQuantity ? true : false}
                    helperText={formErrors?.minimumQuantity || ''}
                    disabled={true}
                />

                {/* <TextField sx={{ width: "32%" }}
                    label="Maximum Available Quantity (MT) "
                    variant="outlined"
                    type="text"
                    value={availableQuantity != null ? availableQuantity : ''}
                    onChange={(e) => handleQuantityChange(e, e.target.value, "max")}
                    error={formErrors.availableQuantity ? true : false}
                    helperText={formErrors?.availableQuantity || ''}
                /> */}

            </div>

            <div className='  mt-4'>
                <TextField sx={{ width: "100%" }}
                    label="Add Keywords"
                    variant="outlined"
                    value={keyTags}
                    onChange={handleKeywordsChange}
                    error={formErrors.keywords}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleAddKeyTag();
                        }
                    }}

                />
                <Stack direction="row" spacing={1} marginTop={2}>
                    {keywords.map((keyTag, index) => (
                        <Chip
                            key={index}
                            label={keyTag}
                            onDelete={() => handleRemoveKeyTag(keyTag)}
                        />
                    ))}
                </Stack>
            </div>
            {formErrors.keywords && (
                <FormHelperText style={{ color: '#d32f2f' }}>
                    please enter keywords
                </FormHelperText>
            )}

            <div className='relative font-semibold text-base m-4'>
                Defects *
            </div>
            <FormControl fullWidth>
                <JoditEditor
                    ref={descriptionEditor}
                    value={defectDetails}
                    config={config1}
                    onBlur={(newContent) => onDefectDetailsChange(newContent)}
                    error={formErrors.defectDetails}
                />
                {formErrors.defectDetails && (
                    <FormHelperText style={{ color: '#d32f2f' }}>
                        please write something in box
                    </FormHelperText>
                )}

                <div className='relative font-semibold text-base m-4'>
                    Additional Product Details
                </div>
            </FormControl>
            <FormControl fullWidth>
                <JoditEditor
                    ref={AdditionalEditor}
                    value={additionalProductDetails}
                    config={config2}
                    onBlur={(newContent) => onAdditionalDetailsChange(newContent)}
                    error={formErrors.additionalProductDetails}
                />
                {formErrors.additionalProductDetails && (
                    <FormHelperText style={{ color: '#d32f2f' }}>
                        please write something in box
                    </FormHelperText>
                )}
            </FormControl>

            <div className='  mt-4'>
                <TextField sx={{ width: "100%" }}
                    label="Enter Applications"
                    variant="outlined"
                    value={tags}
                    error={formErrors.application}
                    onChange={handleApplicationsChange}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleAddTag();
                        }
                    }}
                />
                <Stack direction="row" spacing={1} marginTop={2}>
                    {application.map((tag, index) => (
                        <Chip
                            key={index}
                            label={tag}
                            onDelete={() => handleRemoveTag(tag)}
                        />
                    ))}
                </Stack>

            </div>
            {formErrors.application && (
                <FormHelperText style={{ color: '#d32f2f' }}>
                    please enter applications
                </FormHelperText>
            )}


        </div>

    )
}

const HandleCaseThree = ({
    termsConditions,
    setTermsConditions,
    price,
    setPrice,
    packagingType,
    setPackagingType,
    variablePrices,
    setVariablePrices,
    formErrors,
    setFormErrors,
    setSelectedPackingTypes,
    selectedPackagingTypes
}) => {

    const [priceType, setPriceType] = useState('uniform');
    const [uniformPrice, setUniformPrice] = useState(price);
    const descriptionEditor = useRef(null);
    const { user } = useAuthenticatedUser();
    const errorMessageColor = '#D32F2F'
    const [packingTypes, setPackingTypes] = useState([]);
    const getPackagingTypes = (ConfigurationData) => {
        try {
            const packagingData = ConfigurationData[0]?.data;
            if (selectedPackagingTypes.some(item => typeof item === 'string')) {
                const selectedType = packagingData.filter(item => selectedPackagingTypes.includes(item.value));
                setSelectedPackingTypes(selectedType);
            } 
            return packagingData;
        } catch {
            return []
        }
    }   
    useEffect(() => {
        selectedPackagingTypes && selectedPackagingTypes.length && setPackagingType([selectedPackagingTypes[0]?.name]);
    }, [selectedPackagingTypes]);  

    useEffect(() => {
        selectedPackagingTypes && selectedPackagingTypes.length && setPackagingType([selectedPackagingTypes[0]?.name]);
    }, [selectedPackagingTypes]); 

    const config1 = useMemo(() => {
        return {
            placeholder: "Start typings...",
        };
    }, []);
    const onDefectDetailsChange = (data) => {
        setFormErrors((prevFormErrors) => ({
            ...prevFormErrors,
            defectDetails: null,
        }));

        setTermsConditions(data);
    }


    const handleUniformPriceChange = (event, value) => {
        setUniformPrice(event.target.value);
        handlePriceChange(event, value);
    };


    const handlePriceChange = (event, value) => {
        if (priceType === 'uniform') {
            handleDefaultPriceChange(event, value)
        } else if (priceType === 'variable') {
            const variablePriceObject = value.reduce((acc, entry) => {
                acc[entry.quantity] = entry.price;
                return acc;
            }, {});
            setPrice(variablePriceObject);
        }

    };

    const handleChange = (event) => {
        setFormErrors((prevFormErrors) => ({
            ...prevFormErrors,
            packagingType: null,
        }));

        const value = event.target.value;
            setPackagingType([value]);
    };

    const handleRemovePackagingTag = (tagToRemove) => {
        setFormErrors((prevFormErrors) => ({
            ...prevFormErrors,
            packagingType: null,
        }));

        setPackagingType(packagingType.filter((tag) => tag !== tagToRemove));
    };

    const checkPriceChange = (event) => {
        if (event) {
            if (event.default) {
                setPriceType('uniform');
                setUniformPrice(event.default);
            } else {
                setPriceType('variable');
                const reversedArray = Object.keys(event).map(quantity => ({
                    quantity,
                    price: event[quantity],
                }));
                setVariablePrices(reversedArray);
            }
        }
    }
    const handleDefaultPriceChange = (event, value) => {
        event.preventDefault();
        const floatRegex = /^(\d{0,8}(\.\d{0,3})?)$/;
        if (!floatRegex.test(value)) {
            setFormErrors((prevFormErrors) => ({
                ...prevFormErrors,
                price: "Please enter a valid number between 0 and 10000000",
            }));
            return
        } else if (floatRegex.test(value) || value !== '') {
            setFormErrors((prevFormErrors) => ({
                ...prevFormErrors,
                price: null,
            }));

            setUniformPrice(value)
            setPrice(value);

            return
        }
        setUniformPrice("")
        setPrice('');


    };

    useEffect(() => {
        checkPriceChange(price);
    }, []);

    const fetchConfiguration = () => {
        const baseUrl = `${BaseUrl}/configurations/`;
        axios.get(baseUrl,
            {
                params: {
                    contexts: "PACKAGING"
                },
                headers: {
                    Authorization: `${user.token}`,
                    "Content-Type": "application/json",
                },
            }
        ).then((response) => {
            if (response.data && response.data.data) {
                const packagingTypeList = getPackagingTypes(response.data.data);
                setPackingTypes(packagingTypeList);
                return;
            }
        })
            .catch((err) => {
                return
            });
    }
    useEffect(() => {
        fetchConfiguration();
    }, []);

    return (
        <div className=' rounded bg-white py-6 px-10 '>
            <div>
                <div className='relative font-semibold text-base m-2 mt-8'>
                    Price
                </div>


                <TextField
                    label="Default Price"
                    variant="outlined"
                    type="text"
                    fullWidth={true}
                    value={uniformPrice != null ? uniformPrice : ''}
                    onChange={(e) => handleDefaultPriceChange(e, e.target.value)}
                    error={formErrors.price ? true : false}
                />
                {formErrors.price && (
                    <FormHelperText style={{ color: '#d32f2f' }}>
                        {formErrors.price}
                    </FormHelperText>
                )}

                <div className='relative font-semibold text-base m-2 mt-8'>
                    Select Packaging Type
                </div>

                <div className="flex gap-x-5">
                    <FormControl fullWidth>
                        <CataloguePackingTypes packingTypesData={packingTypes} formErrors={formErrors} initialValues={selectedPackagingTypes} updateFunction={setSelectedPackingTypes} mode={"ADD"} />
                    </FormControl>
                </div>
                {formErrors?.packagingType && selectedPackagingTypes.length === 0 && (
                    <FormHelperText style={{ color: '#d32f2f' }}>
                        Please select the Packaging Type
                    </FormHelperText>
                )}

                <div className='relative font-semibold text-base m-2 mt-10'>
                    Terms and Conditions
                </div>

                <FormControl fullWidth>
                    <JoditEditor
                        ref={descriptionEditor}
                        value={termsConditions}
                        config={config1}
                        onBlur={(newContent) => onDefectDetailsChange(newContent)}
                        error={formErrors.termsConditions}
                    />
                    {formErrors.termsConditions && (
                        <FormHelperText style={{ color: '#d32f2f' }}>
                            please write something in box
                        </FormHelperText>
                    )}
                </FormControl>


            </div>

        </div>
    )
}

const UploadProductImages = ({ updateImages, currentImageCount = 0, maxImageCount }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const fileInputRef = useRef(null);

    const handleFileSelect = (event) => {
        if (!event.target.files) return;
        const newFiles = Array.from(event.target?.files);

        setSelectedFiles(newFiles);
        if (newFiles.length + currentImageCount > maxImageCount) {
            alert(`Maximum of ${maxImageCount} images allowed`);
            return;
        }
        updateImages(newFiles);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleDropDragOver = (event) => {
        event.preventDefault();
        const newFiles = Array.from(event.dataTransfer?.files);

        setSelectedFiles(newFiles);
        if (newFiles.length + currentImageCount <= maxImageCount) {
            updateImages(newFiles);
        }
        else {
            alert(`Maximum of ${maxImageCount} images allowed`);
        }
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    return (
        <div className="relative w-full flex flex-col gap-3 text-left text-text-color font-roboto my-2">
            <div className="relative w-full flex flex-row items-center justify-start gap-2 text-left text-2xl text-blue font-roboto">
                <div className="font-semibold text-base md:text-lg lg:text-xl">
                    Upload Product Images*{" "}
                </div>
                <Typography
                    style={{
                        color: "#A9A5A5",
                        fontSize: "18px",
                        margin: "0px",
                    }}
                >
                    {`(Maximum-${maxImageCount})`}
                </Typography>
            </div>

            <div
                className="relative w-full h-[16.13rem] border-[2px] border-dashed border-gainsboro flex items-center justify-center"
                onDrop={handleDropDragOver}
                onDragOver={handleDropDragOver}
            >
                <div className="flex flex-col items-center gap-4">
                    <div className="flex flex-col items-center w-[10.56rem] h-[4.94rem]">
                        <img
                            className="w-[2.25rem] h-[2.25rem] overflow-hidden"
                            alt=""
                            src={uploadIcon}
                        />
                        <div className="mt-3 text-[0.8rem] tracking-[0.3px] font-medium text-text-color">
                            Drag your files here - there
                        </div>
                    </div>
                    <div className="text-[1rem] tracking-[0.3px]">or</div>

                    <div className="flex flex-col items-center rounded bg-blue flex items-center justify-center text-[0.94rem] text-white font-semibold">
                        <input
                            type="file"
                            multiple
                            className="hidden"
                            onChange={handleFileSelect}
                            id="fileInput"
                            ref={fileInputRef}
                        />
                        <label htmlFor="fileInput" className="cursor-pointer  py-[0.81rem] px-[1.63rem]">
                            Browse
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};
const UploadDocs = ({ imageData, setImageData, mode, formImageData, setFormImageData, setImageId}) => {
    const { user } = useAuthenticatedUser();
    const deleteDocumentState = (id, documentData) => {
        const updatedDetails = documentData.filter((_, index) => index !== id);
        return updatedDetails;
    };
    const deleteDocument = async (documentIndex, isForm) => {
        try {
            if (isForm) {
                setFormImageData(deleteDocumentState(documentIndex, formImageData));
            } else {
                    setImageId((prevImageIds) => [...prevImageIds, imageData[documentIndex].id]);
                    setImageData(deleteDocumentState(documentIndex, imageData));
            }
        } catch (err) {
            return
        }
    };

    const handleRemoveImage = (id, isForm) => {
        const updatedDetails = imageData.filter(
            (detail, currentIndex) => currentIndex !== id
        );
        setImageData(updatedDetails);
    };
    const updateImages = (images) => {
        const invalidImages = [];
        images?.forEach((image) => {
          if (image.type === 'image/webp') {
            invalidImages.push(image.name);
          } else {
            setImageData((prevImages) => [...prevImages, image]);
          }
        });
        if (invalidImages.length > 0) {
          const invalidImageNames = invalidImages.join(', ');
          alert(`${invalidImageNames} files are of type 'webp' which is not allowed. Please select PNG or JPEG files.`);
        }
    };
    const updateImagesEdit = (images) => {
        const invalidImages = [];
        images?.forEach((image) => {
          if (image.type === 'image/webp') {
            invalidImages.push(image.name);
          } else {
            setFormImageData((prevImages) => [...prevImages, image]);
          }
        });
        if (invalidImages.length > 0) {
          const invalidImageNames = invalidImages.join(', ');
          alert(`${invalidImageNames} files are of type 'webp' which is not allowed. Please select PNG or JPEG files.`);
        }
    };
    return (
        <>
            <div className="">
                <div className="grid product-description m-auto gap-y-2">
                    {/* Display Uploaded Files */}
                    {
                        mode === 'EDIT' ?
                            <>
                                <FilesWrapper heading={"Product Images"}>
                                    <DisplayDocuments
                                        documentsData={imageData}
                                        isForm={false}
                                        deleteDocument={deleteDocument}
                                    />
                                    <DisplayDocuments
                                        documentsData={formImageData}
                                        isEdit={true}
                                        isForm={true}
                                        deleteDocument={deleteDocument}
                                    />
                                </FilesWrapper>
                                <UploadProductImages updateImages={updateImagesEdit} currentImageCount={imageData.length + formImageData.length} maxImageCount={4}/>
                            </>
                            :
                            <>
                                <FilesWrapper heading={"Product Images"}>
                                    <DisplayDocuments
                                        documentsData={imageData}
                                        isEdit={true}
                                        isForm={true}
                                        deleteDocument={handleRemoveImage}
                                    />
                                </FilesWrapper>

                                <UploadProductImages updateImages={updateImages} currentImageCount={imageData.length + formImageData.length} maxImageCount={4}/>
                            </>
                    }

                    {/*<h2>Drag your file here</h2>*/}
                    <div className="grid product-description m-auto ">
                        <h2>Drag & drop any file here </h2>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddSecondaryCatalogue