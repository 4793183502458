import React, { useCallback, useEffect, useState } from 'react';
import Select from '../../atoms/Select';
import { ADMIN_ROUTES, CATALOGUE_TYPES, RESOURCE_TYPE, CLASS_TYPES, RESOURCES, CLASS_TYPE, BRANDS, SHAPE_TYPES } from '../../../utils/constant';
import { HTTP_STATUS, option } from '../../../utils/types';
import { ImageData } from '../../organisms/ImageUploader';
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import { useImageMasterService } from '../../../services/useImageMasterService';
import { useFileService } from '../../../services/useFileService';
import { BrandTable } from './ImageMasterBrandTable.template';
import { createUseStyles } from 'react-jss';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useBrandService } from '../../../services/useBrandService';
import { useSecondaryInventoryService } from '../../../services/useSecondaryInventoryService';
import Button from '../../atoms/Button';
import { catalogueClassType, createImageName, getKeyByValue } from '../../../utils/helper';
import { Checkbox } from '@mui/material';

interface IbrandRow {
    brand: number;
    images: (File | ImageData)[];
    availableBrands: option[];
}
interface ICreatePreSignedUrl {
    productCategoryId: number;
    shape: string;
    isRepresentationImage: boolean;
    brandId: number;
    catalogueType: string;
    classType: string;
    fileNames: string[];
}

interface IImagePreSignedUrls {
    imageMasterId: number;
    brandId: number;
    url: { [imageName: string]: string };
}

interface IImageMasterCreatePage {
    mode: string;
}

export interface IImageMasterForm {
    productCategory: number | null;
    isRepresentationImage: boolean;
    shape: string;
    catalogueType: string;
    brandRow: IbrandRow[];
}

export interface IBrandTableProp {
    formik: FormikProps<IImageMasterForm>;
    brands: option[];
}
const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.text.primaryDarkLight,
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
}));


const ImageMasterCreatePageTemplate: React.FC<IImageMasterCreatePage> = ({ mode }) => {
    const [catalogueType, setCatalogueType] = useState<string>('');
    const [classType, setClassType] = useState<string>('');
    const [productCategories, setProductCategoriesTo] = useState<option[]>([]);
    const [brands, setbrandsTo] = useState<option[]>([]);
    const [shapes, setShapesTo] = useState<option[]>(SHAPE_TYPES.map(shapes => ({ value: shapes.id, label: shapes.label })));
    const imageMasterService = useImageMasterService();
    const fileService = useFileService();
    const [imageIdsToDelete, setImageIdsToDelete] = useState<number[]>([]);
    const [imageIdsToUpdate, setImageIdsToUpdate] = useState<number[]>([]);
    const classes = useStyles();
    const params = useParams();
    const imageMasterId = parseInt(params.id ?? '0', 10);
    const navigate = useNavigate();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const secondaaryInventoryService = useSecondaryInventoryService();
    const brandService = useBrandService();


    const initialValues: IImageMasterForm = {
        productCategory: null,
        shape: '',
        isRepresentationImage: false,
        catalogueType: '',
        brandRow: [],
    };

    const validationSchema = Yup.object().shape({
        productCategory: Yup.number().required('product category is required'),
        shape: Yup.string().required('shape is required'),
        catalogueType: Yup.string().required('catalogue type is required'),
        brandRow: Yup.array().of(
            Yup.object().shape({
                brand: Yup.number().required('Name is required').min(1),
                images: Yup.array().min(1).required('Atleast one image is required'),
            })
        )
    });


    const formik = useFormik<IImageMasterForm>({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            let imageIdsToUpdate: number[] = [];
            const presignedUrlRequestBody: ICreatePreSignedUrl[] = values.brandRow.map(row => {
                const brandId = row.brand;
                imageIdsToUpdate = row.images
                    .filter(image => !(image instanceof File) && 'id' in image)
                    .map(image => image instanceof File ? null : image.id ?? 0)
                    .filter(id => id !== null) as number[];

                const fileNames = row.images.filter(image => image instanceof File).map(image => image instanceof File ? createImageName(values.productCategory ?? 0, brandId, values.shape, image.name) : "N/A");
                return {
                    productCategoryId: values.productCategory ?? 0,
                    isRepresentationImage: values.isRepresentationImage,
                    brandId: brandId,
                    catalogueType: catalogueType,
                    classType: classType,
                    shape: values.shape,
                    fileNames: fileNames
                };
            });

            const handleImageUpload = async (responseData: any) => {
                if (responseData?.data?.data) {
                    const imagePreSignedUrls: IImagePreSignedUrls[] = responseData.data.data;
                    if (imageIdsToDelete.length > 0 && mode === "EDIT") {
                        await fileService.deleteImagesByIds(imageIdsToDelete.join(","));
                    }
                    for (const preSignedUrl of imagePreSignedUrls) {
                        const { imageMasterId, brandId, url } = preSignedUrl;
                        const brandRow = values.brandRow.find(brandData => brandData.brand === brandId);
                        if (brandRow) {
                            for (let i = 0; i < brandRow?.images?.length; i++) {
                                const image = brandRow?.images[i];
                                if (image instanceof File) {
                                    const imageName = createImageName(values.productCategory ?? 0, brandId, values.shape, image.name);
                                    const response = await imageMasterService.uploadImageUsingPreSignedUrls(image, url[imageName]);
                                    if (response?.status === HTTP_STATUS.OK) {
                                        const requestBody = {
                                            resourceType: 'IMAGE_MASTER',
                                            resourceId: imageMasterId,
                                            path: `image_master/${imageMasterId}/${imageName}`,
                                            mimeType: image.type
                                        };
                                        const fileResponse = await fileService.saveFile(requestBody);
                                        imageIdsToUpdate.splice(i, 0, fileResponse.data.data.id);
                                    }
                                }
                            }
                        }
                        if (mode === "EDIT") {
                            await fileService.updateImagesTime(imageIdsToUpdate.join(","));
                        }

                    }
                    navigate(`${ADMIN_ROUTES.IMAGE_MASTER_LIST}`);
                }
            };

            if (mode === "ADD") {
                imageMasterService.createPreSignedUrls(presignedUrlRequestBody)
                    .then(async (response) => {
                        await handleImageUpload(response);
                    })
                    .catch((error) => {
                        showSnackbar("error", 'Error in uploading image');

                    });
            }
            else {
                imageMasterService.updateImageMaster(presignedUrlRequestBody, imageMasterId)
                    .then(async (response) => {
                        await handleImageUpload(response);
                    })
                    .catch((error) => {
                        showSnackbar("error", 'Error in uploading image');
                    });
            }
        },
    });

    const getRemainingShapes = () => {
        const remainingShapes = SHAPE_TYPES.map(shapes => ({ value: shapes.id, label: shapes.label }));
        setShapesTo(remainingShapes);

    };

    const getRemainingBrands = () => {
        imageMasterService.getRemainingBrands({ catalougeType: catalogueType, classType: classType, productCategoryId: formik.values.productCategory ?? 0, shape: formik.values.shape })
            .then((response) => {
                if (response?.data) {
                    let brandFilterValues: { value: any, label: string }[] = [];
                    response?.data?.data?.forEach((brand: any) => {
                        brandFilterValues.push({ value: brand.id, label: brand.name });
                    });
                    if (classType.toLowerCase() === CLASS_TYPE.SECONDARY) {
                        brandFilterValues = brandFilterValues?.filter((brand: any) => brand.label.toLowerCase() === BRANDS.SECONDARY_BRAND)
                    } else {
                        brandFilterValues = brandFilterValues?.filter((brand: any) => brand.label.toLowerCase() !== BRANDS.SECONDARY_BRAND)
                    }
                    setbrandsTo(brandFilterValues);
                }
            })
            .catch((error) => {
                setbrandsTo([]);
            });
    };

    const getImageMaster = async () => {
        try {
            const imageMasterResponse = await imageMasterService.getImageMasterById(imageMasterId);
            if (imageMasterResponse.status === HTTP_STATUS.OK) {
                formik.setFieldValue('catalogueType', catalogueClassType(imageMasterResponse?.data?.data?.catalogueType, imageMasterResponse?.data?.data?.classType));
                formik.setFieldValue('productCategory', imageMasterResponse?.data?.data?.productCategoryId);
                formik.setFieldValue('shape', imageMasterResponse?.data?.data?.shape);
                formik.setFieldValue('isRepresentationImage', imageMasterResponse?.data?.data?.isRepresentationImage);
            }
            const fileResponse = await fileService.getFilesById(imageMasterId, RESOURCE_TYPE.IMAGE_MASTER);
            (fileResponse.status === HTTP_STATUS.OK) &&
                formik.setFieldValue('brandRow', [
                    {
                        brand: imageMasterResponse.data.data.brandId,
                        images: fileResponse?.data?.data
                    }
                ]);
        } catch (error) {
            showSnackbar("error", 'Secondary Inventory fetch failed');
        };
    }

    const onCatalogueTypeChange = (event: any) => {
        formik.setFieldValue('catalogueType', event.target.value);
        formik.setFieldValue('productCategory', null);
        formik.setFieldValue('brandRow', []);
    }

    const onProductCategoryChange = (event: any) => {
        formik.setFieldValue('productCategory', event.target.value);
        formik.setFieldValue('shape', null);
        formik.setFieldValue('brandRow', []);
    }

    const onShapeChange = (event: any) => {
        formik.setFieldValue('shape', event.target.value);
        formik.setFieldValue('brandRow', []);
    }

    const onCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue('isRepresentationImage', event.target.checked);
    };

    useEffect(() => {
        formik.setFieldValue('brandRow', [
            {
                brand: 0,
                images: [],
                availableBrands: brands
            }
        ]);
    }, [brands])

    useEffect(() => {
        if (formik.values.productCategory !== null && mode !== "EDIT") {
            getRemainingShapes();
        }
    }, [formik.values.productCategory])

    useEffect(() => {
        if (formik.values.shape !== null && mode !== "EDIT") {
            getRemainingBrands();
        }
    }, [formik.values.shape])


    const getAllCategories = () => {
        let queryParams = {
            name: '',
            level: 2,
            parentId: null,
            page: 0,
            size: 100
        };
        secondaaryInventoryService.getCategories(queryParams)
            .then((response) => {
                if (response.data) {
                    let productCategoryFilterValues: option[] = [];
                    response?.data?.data?.content?.forEach((productCategory: any) => {
                        productCategoryFilterValues.push({ value: productCategory.id, label: productCategory.name });
                    });
                    setProductCategoriesTo(productCategoryFilterValues);
                }
            })
            .catch((error) => {
                setProductCategoriesTo([]);
            });
    };

    const getAllBrands = () => {
        let params = {
            page: 0,
            size: 100,

        }
        brandService.getAllBrands(params)
            .then((brandsResponse) => {
                if (brandsResponse?.data?.data) {
                    let brandFilterValues: { value: any, label: string }[] = [];
                    brandsResponse?.data?.data?.content?.forEach((brand: any) => {
                        brandFilterValues.push({ value: brand.id, label: brand.name });
                    });
                    setbrandsTo(brandFilterValues);
                }
            })
            .catch((error) => {
                setbrandsTo([]);
            });
    }

    useEffect(() => {
        const { catalogueType } = formik.values;
        const getClassType = () => {
            if (catalogueType.includes(getKeyByValue(RESOURCES, RESOURCES.SECONDARY) ?? "")) {
                return getKeyByValue(CLASS_TYPE, CLASS_TYPE.SECONDARY);
            } else if (catalogueType.includes("NON")) {
                return getKeyByValue(CLASS_TYPE, CLASS_TYPE.NONSTANDARD);
            }
            return getKeyByValue(CLASS_TYPE, CLASS_TYPE.STANDARD);
        };
        const getCatalogueType = () => {
            if (catalogueType === getKeyByValue(RESOURCES, RESOURCES.SECONDARY_LOTS) || catalogueType === getKeyByValue(CATALOGUE_TYPES, CATALOGUE_TYPES.MAKE_TO_ORDER)) {
                return getKeyByValue(CATALOGUE_TYPES, CATALOGUE_TYPES.MAKE_TO_ORDER);
            }
            return getKeyByValue(CATALOGUE_TYPES, CATALOGUE_TYPES.CURRENT_INVENTORY);

        };
        setClassType(getClassType() ?? "");
        setCatalogueType(getCatalogueType() ?? "");
    }, [formik.values.catalogueType])

    useEffect(() => {
        if (catalogueType !== "" && mode !== "EDIT") {
            getAllCategories();
        }
    }, [classType, catalogueType])

    useEffect(() => {
        if (mode === "EDIT") {
            getImageMaster();
            getAllCategories();
            getAllBrands();
        }
    }, [])

    const handleRedirect = () => {
        navigate(`${ADMIN_ROUTES.IMAGE_MASTER_LIST}`);
    };

    return (
        <div className='pt-8 grid gap-8'>
            {SnackBarComponent}
            <div> <Select
                variant="outlined"
                className="w-44"
                label="Catalogue Type"
                name="catalogueType"
                id="catalogue-type"
                value={formik.values.catalogueType}
                disabled={mode === "EDIT"}
                onChange={onCatalogueTypeChange}
                options={CLASS_TYPES}
                error={
                    formik.touched.catalogueType &&
                    Boolean(formik.errors.catalogueType)
                }
            />
                {formik.touched.catalogueType && formik.errors && formik.errors.catalogueType && (
                    <div className={classes.errorMessage}>
                        <small>{formik.errors.catalogueType}</small>
                    </div>
                )}
            </div>

            {formik.values.catalogueType && (
                <div className='flex items-start'>
                    <div className='flex items-start'>
                        <Select
                            variant="outlined"
                            className="w-44"
                            label="Product Category"
                            name="product-category"
                            id="product-category"
                            value={formik.values.productCategory}
                            onChange={onProductCategoryChange}
                            disabled={mode === "EDIT"}
                            options={productCategories}
                            error={
                                formik.touched.productCategory &&
                                Boolean(formik.errors.productCategory)
                            }
                        />

                        <div className='flex items-center gap-x-3 w-full'>
                            <Checkbox
                                checked={formik.values.isRepresentationImage}
                                onChange={(e) => { onCheckBoxChange(e) }}
                                color="primary"
                            />
                            <div className='w-full'>
                                Disclamer: These Images are for Representation Purpose only
                            </div>
                        </div>

                    </div>
                    {formik.touched.productCategory && formik.errors.productCategory && (
                        <div className={classes.errorMessage}>
                            <small>{formik.errors.productCategory}</small>
                        </div>
                    )}
                </div>
            )}

            {formik.values.productCategory && (
                <div className='flex items-start'>
                    <div className='flex items-start'>
                        <Select
                            variant="outlined"
                            className="w-44"
                            label="Shape"
                            name="shape"
                            id="shape"
                            value={formik.values.shape}
                            onChange={onShapeChange}
                            disabled={mode === "EDIT"}
                            options={shapes}
                            error={
                                formik.touched.shape &&
                                Boolean(formik.errors.shape)
                            }
                        />
                    </div>
                    {formik.touched.shape && formik.errors.shape && (
                        <div className={classes.errorMessage}>
                            <small>{formik.errors.shape}</small>
                        </div>
                    )}
                </div>
            )}

            {(formik.values.catalogueType && formik.values.productCategory && formik.values.shape) && <div>   <BrandTable formik={formik} brands={brands} mode={mode} setImageIdsToDelete={setImageIdsToDelete} />
            </div>}

            <div className="flex justify-start ">
                <Button variant="outlined" label="Cancel" onClick={handleRedirect} />
            </div>

        </div>
    );
};

export default ImageMasterCreatePageTemplate;
