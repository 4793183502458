import React, { useCallback, useMemo, useState } from "react";
import { secondaryInventorySectionProp } from './SecondaryInventoryForm.template';
import Button from '../../atoms/Button';
import {DOCUMENT_TYPES, MODE, RESOLUTIONS } from '../../../utils/constant';
import { SECONDARY_INVENTORY_SECTIONS } from '../../../utils/types';
import { useFileService } from '../../../services/useFileService';
import ImageUploader, { ImageData } from '../../organisms/ImageUploader';
import UploadImages from '../../organisms/UploadImages';


const ImageDetailTemplate: React.FC<secondaryInventorySectionProp> = ({ formik, id, mode, setCurrentSectionTo, image, setImagesTo }) => {
  const fileService = useFileService();
  const [imageIdsToDelete, setImageIdsToDelete] = useState<number[]>([]);
  
  const redirectToWarehouseDetail = () => {
		setCurrentSectionTo(SECONDARY_INVENTORY_SECTIONS.WAREHOUSE);
	};
	const handleSubmit = (event: React.FormEvent) => {	   
      formik.handleSubmit();	
	};


	const removeImage = useCallback((id: number) => {
		const updatedImages = image.filter((_, currentIndex) => currentIndex !== id);
		const imageData = image[id] as ImageData;
        setImageIdsToDelete((prevImageIds: number[]) => [...prevImageIds,imageData.id as number]);
		setImagesTo(updatedImages);
	}, [image]);

	const updatedImages = useCallback((images: any) => {
		setImagesTo((prevImages:any) => [...prevImages, ...images]);
	}, [image]);
  const imageUploaderComponent = useMemo(() => (
		<ImageUploader
			mode={mode}
			images={image}
			removeImage={removeImage}
			isSubmitting = {formik.isSubmitting}
			imageIdsToDelete = {imageIdsToDelete}
		/>
	), [mode, image, removeImage, formik.isSubmitting]);

	const uploadImagesComponent = useMemo(() => (
		mode !== MODE.VIEW ? (
			<UploadImages
				title="Upload Images"
				currentImageCount = {image.length}
				updateImages={updatedImages}
				configuration={{
					maxImageCount: 4,
					maxfilesize: 200,           
					documentTypes: [DOCUMENT_TYPES.IMAGE_JPEG, DOCUMENT_TYPES.IMAGE_PNG,DOCUMENT_TYPES.IMAGE_WEBP],
					documentResolution: RESOLUTIONS.SECONDARY_INVENTORY,
				}}
			/>
		) : null
	), [mode, updatedImages]);

  return (
    <>
    <div className="">
      <div className="grid product-description m-auto gap-y-2">
      {imageUploaderComponent}
		{uploadImagesComponent} 
      </div>
      <div className="flex justify-between gap-x-3">		
			<Button variant="outlined" label="Back" onClick={redirectToWarehouseDetail} />
			<Button variant="contained" label="Submit" onClick={handleSubmit} />	
	  </div>
    </div>
  </>
  )
}
export default ImageDetailTemplate