// to  do deleted this file //
import React from "react";
import { createUseStyles } from "react-jss";

interface TitleHeadingProps {
  text: string;
}

const useStyles = createUseStyles((theme :any) => ({
  main: {
    color: theme.palette.button.primaryDark,
    fontFamily: "Roboto",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
}));

const TitleHeading: React.FC<TitleHeadingProps> = ({ text }) => {
  const classes = useStyles(); 
  return <h1 className={classes.main}>{text}</h1>;
};

export default TitleHeading;

//*********************************TODO- To Be Deleted************************************ */