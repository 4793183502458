
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState, useContext, useRef } from "react";
import { DisplayDocuments } from "../../Shared/Files/FileItem";
import uploadIcon from "./../../Assets/Icons/uploadIcon.svg";
import { FilesWrapper } from "../../Shared/Files/DisplayFiles";
import UpcDetailItem from "./UpcDetailsDisplay";
import FindUpcSearchComponent from "./FindUpcSearch";
import CatalogueEnum from "./CatalogueEnum";
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import CataloguePackingTypes from "./CataloguePackingTypes";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { makeRoute } from "../../../utils/helper";
import { CATALOGUE_TYPES, CLASS_TYPE ,ADMIN_ROUTES} from "./../../../utils/constant";

const BaseUrl = process.env.REACT_APP_API_V1_URL;
const UploadProductImages = ({ updateImages, currentImageCount = 0, maxImageCount }) => {
  const { user } = useAuthenticatedUser();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);

  const handleFileSelect = (event) => {
    if (!event.target.files) return;
    const newFiles = Array.from(event.target.files);
    setSelectedFiles(newFiles);
    if (newFiles.length + currentImageCount > maxImageCount) {
      alert(`Maximum of ${maxImageCount} images allowed`);
      return;
    }
    updateImages(newFiles);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
  }
  };

  const handleDropDragOver = (event) => {
    event.preventDefault();
    const newFiles = Array.from(event.dataTransfer.files);
    setSelectedFiles(newFiles);
    if (newFiles.length + currentImageCount <= maxImageCount) {
      updateImages(newFiles);
    }
    else {
      alert(`Maximum of ${maxImageCount} images allowed`);
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
  }
  };

  const handleUpload = async () => {
    const formData = new FormData();

    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append(`files`, selectedFiles[i]);
    }

    const uploadFilesData = {
      resourceType: "CATALOGUE",
      resourceId: 123,
      createdBy: 1,
    };

    formData.append(
      "resource",
      new Blob([JSON.stringify(uploadFilesData)], {
        type: "application/json",
      })
    );

    try {
      await axios
        .post(`${BaseUrl}/files/`, formData,{
          headers: {
             Authorization: `${user.token}`
          }
       })
        .then((data) => {
          return
        });
      // Handle success
    } catch (error) {
      // Handle error
      return
    }
  };

  return (
    <div className="relative w-full flex flex-col gap-3 text-left text-text-color font-roboto my-2">
      <div className="relative w-full flex flex-row items-center justify-start gap-2 text-left text-2xl text-blue font-roboto">
        <div className="font-semibold text-base md:text-lg lg:text-xl">Upload Product Images* </div>
        <Typography style={{
          color: '#A9A5A5',
          fontSize: '18px',
          margin: '0px'
        }}>{`(Maximum-${maxImageCount})`}</Typography>
      </div>
      <div
        className="relative w-full h-[16.13rem] border-[2px] border-dashed border-gainsboro flex items-center justify-center "
        onDrop={handleDropDragOver}
        onDragOver={handleDropDragOver}
      >
        <div className="flex flex-col items-center gap-4">
          <div className="flex flex-col items-center w-[10.56rem] h-[4.94rem]">
            <img
              className="w-[2.25rem] h-[2.25rem] overflow-hidden"
              alt=""
              src={uploadIcon}
            />
            <div className="mt-3 text-[0.8rem] tracking-[0.3px] font-medium text-text-color">
              Drag your files here - there
            </div>
          </div>
          <div className="text-[1rem] tracking-[0.3px]">or</div>

          <div className="flex flex-col items-center rounded bg-blue flex items-center justify-center text-[0.94rem] text-white font-semibold">
            <input
              type="file"
              multiple
              className="hidden"
              onChange={handleFileSelect}
              id="fileInput"
              ref={fileInputRef}
            />
            <label htmlFor="fileInput" className="cursor-pointer  py-[0.81rem] px-[1.63rem]">
              Browse
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
// border-gainsboro
const UPCDisplayDetails = ({
  mode,
  catalogueUpcDetails,
  updateUPCDetails,
  updateUPCErrors,
  formErrors,
  type
}) => {

  useEffect(() => {
  }, []);


  const handleMarginChange = (id, key, value) => {
    const floatRegex = key === 'profitMargin' ? /^(\d{1,3}(\.\d{0,2})?|\.\d{1,2})$/ : /^(\d*\.?\d{0,3})?$/;
    if (floatRegex.test(value) || value === '') {

      const floatValue = value;
      const updatedErrors = { ...formErrors };

      const updatedDetails = catalogueUpcDetails.map((detail, index) =>
        index === id ? { ...detail, [key]: floatValue } : detail
      );
      if (floatValue === '') {
        updatedErrors[id] = { [key]: true };
      } else {
        updatedErrors[id] = { [key]: false };
      }

      updateUPCErrors(updatedErrors);
      updateUPCDetails(updatedDetails);
    }

  };
  const handleMoqChange = (id, key, value) => {
    const floatRegex =/^(\d{0,3}(\.\d{0,3})?)$/;
    if (floatRegex.test(value) || value === '') {

      const floatValue = value;
      const updatedErrors = { ...formErrors };

      const updatedDetails = catalogueUpcDetails.map((detail, index) =>
        index === id ? { ...detail, [key]: floatValue } : detail
      );
      if (floatValue === '') {
        updatedErrors[id] = { [key]: true };
      } else {
        updatedErrors[id] = { [key]: false };
      }

      updateUPCErrors(updatedErrors);
      updateUPCDetails(updatedDetails);
    }

  };

  const handleStatusChange = (index, value) => {
    const updatedDetails = [...catalogueUpcDetails];
    updatedDetails[index] = { ...updatedDetails[index], status: value };
    updateUPCDetails(updatedDetails);
  };

  const handleRemoveUPCDetails = (id) => {
    const updatedDetails = catalogueUpcDetails.filter((detail, index) => index !== id);
    updateUPCDetails(updatedDetails);
  };

  return (
    <>
      {catalogueUpcDetails &&
        catalogueUpcDetails.map((upcDetail, index) => (

          <UpcDetailItem
            mode={mode}
            index={index}
            upcDetail={upcDetail}
            priceDetails={upcDetail.priceRange}
            formErrors={formErrors}
            handleRemoveUPCDetails={handleRemoveUPCDetails}
            handleStatusChange={handleStatusChange}
            handleMarginChange={handleMarginChange}
            handleMoqChange={handleMoqChange}
            type={type}
          />

        ))}
    </>
  );
};


const EditCatalogue = () => {
  const { user } = useAuthenticatedUser();
  const errorMessageColor = '#D32F2F'
  const catalogueMode = "EDIT";
  const location = useLocation();
  const {catalogueRecordId, category , catalogueType} = location.state || {};
  const [selectedCategory, setSelectedCatagory] = useState(category || "STANDARD");
  const [imageIdList, setImageId] = useState([]);


  const navigate = useNavigate();
  const [imageData, setImageData] = useState([]);
  const [formImageData, setFormImageData] = useState([]);
  const [upcErrorState, setErrorState] = useState(false);

  const [formErrors, setFormErrors] = useState({
    catalogueTitle: false,
    catalogueDescription: false,
    upcMappings: {},
  });
  // Access the context data using useContext
  const [catalogueId, setCatalogueId] = useState( catalogueRecordId );
  const [catalogueDetails, setCatalogueDetails] = useState([]);
  const [packingTypes, setPackingTypes] = useState([]);
  const [selectedPackagingTypes, setSelectedPackingTypes] = useState([]);

  const navigateCatalogueTable = () => {
    navigate(-1)
  };

  const fetchDocuments = async (resId, resType) => {
    try {
      if (!resId || !resType) {
        return;
      }
      const apiUrl = `${BaseUrl}/files/${resType}/${resId}`;

      const response = await axios.get(apiUrl,{
        headers: {
           Authorization: `${user.token}`
        }
     }).then((res) => {
        if (res && res.data && res.data.data) {
          setImageData(res.data.data);
        }

        return;
      });
    } catch (err) {
      return;
    }
  };

  const deleteDocumentState = (id, documentData) => {
    const updatedDetails = documentData.filter((_, index) => index !== id);
    return updatedDetails;
  };

  const deleteDocument = async (documentIndex, isForm) => {
    try {
      if (isForm) {
        setFormImageData(deleteDocumentState(documentIndex, formImageData));
      } else {
        setImageId((prevImageIds) => [...prevImageIds,imageData[documentIndex].id]);
        setImageData(deleteDocumentState(documentIndex, imageData));
      }
    } catch (err) {
      return
    }
  };

  const getPackagingTypes = (initialCatalogueList, ConfigurationData) => {
    const packagingData = ConfigurationData[0]?.data;
    if (initialCatalogueList === null) {
      initialCatalogueList = [];
    }
    if (Array.isArray(packagingData) && packagingData.length > 0) {
      const compareMap = {};
      const cataloguePackagingTypes = new Map(initialCatalogueList.map(item => [item, true]));
      const matchedList = [];
      packagingData.forEach(packaging => {
          if (cataloguePackagingTypes.has(packaging.value)) {
              matchedList.push(packaging);
          }
      });
      setSelectedPackingTypes(matchedList);
      return packagingData;
    }
    return [];
  } 

  const fetchConfiguration = async (initialList) => {
    const baseUrl = `${BaseUrl}/configurations/`;
    axios.get(baseUrl,
      {
        params: {
          contexts: "PACKAGING"
        },
        headers: {
          Authorization: `${user.token}`,
          "Content-Type": "application/json",
        },
      }
    ).then((response) => {
      if (response.data && response.data.data) {
        const packagingTypeList = getPackagingTypes(initialList, response.data.data);
        setPackingTypes(packagingTypeList);
        return;
      }
    })
      .catch((err) => {
        return
      });
  }

  const fetchCatalogueDetails = async (catalogueId) => {
    try {
      if (!catalogueId) {
        return;
      }

      const apiUrl = `${BaseUrl}/catalogue/${catalogueId}`
      const response = await axios.get(apiUrl,{
        headers: {
           Authorization: `${user.token}`,
           "Content-Type": "application/json",
        }
     }).then(async (res) => {
        const fetchedCatalogueData = res.data.data
        setCatalogueDetails(fetchedCatalogueData);
        await fetchConfiguration(fetchedCatalogueData?.packagingTypes);
        return;
      });
    } catch (err) {
      return;
    }
  };



  useEffect(() => {
    fetchCatalogueDetails(catalogueId);
    fetchDocuments(catalogueId, "CATALOGUE");
  }, []);

  const mapCatalogueDetailsToServer = (catalogueMapDetails) => {
    // Create a new object to store the mapped data
    const mappedData = {
      id: catalogueMapDetails.id,
      name: catalogueMapDetails.name?.trim(),
      description: catalogueMapDetails.description?.trim(),
      status: catalogueMapDetails.status,
      category: catalogueMapDetails.category,
      createdBy: catalogueMapDetails.createdBy,
      updatedBy: catalogueMapDetails.updatedBy,
      upcMappings: [],
      catalogueType: catalogueType,
      packagingTypes:convertPackagingTypeDetails()
    };

    for (const upcMapping of catalogueMapDetails.upcMappings) {
      const mappedUpcMapping = {
        id: upcMapping.id ? upcMapping.id : null,
        catalogueId: catalogueMapDetails.id,
        upcId: upcMapping.upcId,
        status: upcMapping.status,
        profitMargin: upcMapping.profitMargin,
        upcPrice: upcMapping.upcPrice === '' ? 0 : parseFloat(upcMapping.upcPrice),
        minimumSellingPrice: upcMapping.minimumSellingPrice === '' ? 0 : parseFloat(upcMapping.minimumSellingPrice),
        minimumOrderQuantity: upcMapping.minimumOrderQuantity === '' ? 0 : parseFloat(upcMapping.minimumOrderQuantity),
      };

      mappedData.upcMappings.push(mappedUpcMapping);
    }
    return mappedData;
  };

  const identifyFieldsErrors = () => {
    let flag = false;
    const errors = {};
    const fieldsToValidate = [
      "name",
      "description",
      "upcMappings",
    ];

    fieldsToValidate.forEach((fieldValue) => {
      if (fieldValue !== "upcMappings" && catalogueDetails[fieldValue] !== undefined && catalogueDetails[fieldValue].trim().length === 0) {
        errors[fieldValue] = true;
        flag = true;
      } else if (fieldValue === "upcMappings" && Array.isArray(catalogueDetails[fieldValue])) {
        const upcListErrors = catalogueDetails[fieldValue].map((upc) => {
          const upcErrors = {};
          if (upc.upcPrice === '' || parseFloat(upc.upcPrice)===0 ) {
            upcErrors.upcPrice = true;
            flag = true;
          }
          if (upc.minimumSellingPrice === '' || parseFloat(upc.minimumSellingPrice)===0 ) {
            upcErrors.minimumSellingPrice = true;
            flag = true;
          }
          if (upc.minimumOrderQuantity === '' || parseFloat(upc.minimumOrderQuantity)===0 ) {
            upcErrors.minimumOrderQuantity = true;
            flag = true;
          }
          return upcErrors;
        });
        errors[fieldValue] = upcListErrors;
      }
    });

    if(selectedPackagingTypes.length === 0){
      errors['packagingType'] = true
      flag = true;
    }
    if (flag) {
      setFormErrors(errors);
    }
    return flag;
  };

  const handleCatalogueImageData = async (
    imageData,
    resourceId,
    resourceType,
    createdBy
  ) => {
    if (!resourceId || !resourceType) {
      return false;
    }

    const formData = new FormData();

    for (let i = 0; i < imageData.length; i++) {
      formData.append(`files`, imageData[i]);
    }

    const uploadFilesData = {
      resourceType: resourceType,
      resourceId: resourceId,
      createdBy: createdBy,
    };

    formData.append(
      "resource",
      new Blob([JSON.stringify(uploadFilesData)], {
        type: "application/json",
      })
    );

    try {
      await axios
        .post(`${BaseUrl}/files/`, formData, {
          headers: {
             Authorization: `${user.token}`
          }
       })
        .then((data) => {
          return true;
        });
    } catch (error) {
      return false;
    }
  };

  function areObjectsEqual(obj1, obj2) {
    let compareDetails = [
      'brand',
      'classType',
      'grade',
      'mainCategory',
      'manufactureName',
      'productCategory',
      'superCategory',
      'standard',
    ]
    for (const key of compareDetails) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
    return true;

  }

  const validateUpcDetails = (catalogueData) => {
    const upcDataList = catalogueData?.upcMappings;

    if (!upcDataList || upcDataList.length === 0) {
      (selectedCategory === 'NONSTANDARD' ? alert("No MPC data found.") : alert("No UPC data found."));
      return false;
    }
    return false;  }


  const convertPackagingTypeDetails = () => {
    var packaginTypeDetails = [];
    for (const packagingTypeIndex in selectedPackagingTypes) {
        packaginTypeDetails.push(selectedPackagingTypes[packagingTypeIndex]?.value)
    }
    return packaginTypeDetails;
  };
  

  const handleUpdateCatalog = async () => {
    if (!catalogueId) {
      return
    }

    if (validateUpcDetails(catalogueDetails)) {
      return
    }

    if (identifyFieldsErrors()) {
      return;
    }

    if (catalogueDetails.upcMappings.length === 0) {
      setErrorState(true);
      return;
    }

    const isImagePresent = imageData.length > 0 || formImageData.length > 0;  
    // if (!isImagePresent) {
    //   alert("Please upload image for the catalogue.");
    //   return;
    // }

    if (imageIdList.length > 0) {
      try {
        const combinedimageIds = imageIdList.join(", ");
        const apiUrl = `${BaseUrl}/files/${combinedimageIds}`;
        const response = await axios
          .delete(apiUrl, {
            headers: {
              Authorization: `${user.token}`,
            },
          })
          .then((res) => {
            return;
          });
      } catch (error) {
        if (error.response.status === 500) {
          alert("Image cannot be edited or something went wrong. Please try again");
        } else {
          alert("Error in Deleting the Image");
        }
      }
    }

    var modifiedCatalogueDetails = mapCatalogueDetailsToServer(catalogueDetails);
    const id = catalogueId;
      try {
        const response = await axios.put(
          `${BaseUrl}/catalogue/${id}`,
          modifiedCatalogueDetails,{
            headers: {
               Authorization: `${user.token}`,
               "Content-Type": "application/json",
            }
         }
        );
        if (response) {
          if (response.status === 200) {
           // Upload the Images
          var resourceId = response.data.data.id;
          var resourceType = "CATALOGUE";
          var createdBy = response.data.data.createdBy;

          if (
            handleCatalogueImageData(
              formImageData,
              resourceId,
              resourceType,
              createdBy
            )
          ) {
            navigateCatalogueTable();
            return
          }
          navigateCatalogueTable();
          return
          } 
        } 
        return 
      } catch (error) {
        console.log(error)
        if (error.response.status === 400) {
          alert("Catalogue cannot be Created - Products exists in other Catalogue");
        } else if (error.response.status === 500) {
          alert("Catalogue already exists or something went wrong. Please try again");
        } else {
          alert("Error in Creating the Catalogue");
        }
      }
  };

  const handleCatalogueTitleChange = (event) => {
    setCatalogueDetails((prevCatalogueDetails) => ({
      ...prevCatalogueDetails,
      name: event.target.value,
    }));

    if (event.target.value.trim().length) {
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        catalogueTitle: event.target.value.trim() === "",
      }));
    }
  };

  const handleCatalogueDescriptionChange = (event) => {
    setCatalogueDetails((prevCatalogueDetails) => ({
      ...prevCatalogueDetails,
      description: event.target.value,
    }));

    if (event.target.value.trim().length) {
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        catalogueDescription: event.target.value.trim() === "",
      }));
    }
  };

  const handleCatalogueStatusChange = (event) => {
    setCatalogueDetails((prevCatalogueDetails) => ({
      ...prevCatalogueDetails,
      status: event.target.value,
    }));
  };


  const updateUPCDetails = (updatedDetails) => {
    if (updatedDetails.length === 0) {
      setErrorState(true)
    } else {
      setErrorState(false)
    }
    if (Array.isArray(updatedDetails) && updatedDetails.length > 0) {
      setCatalogueDetails((prevFormData) => ({
        ...prevFormData,
        upcMappings: updatedDetails,
      }));
    } else {
      setCatalogueDetails((prevFormData) => ({
        ...prevFormData,
        upcMappings: [],
      }));
    }
  };


  const updateUPCErrors = (updatedErrors) => {
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      upcMappings: updatedErrors,
    }));
  };

  const DisplayCatalogueStatus = ({ statusValue }) => {
    return (
      <FormControl fullWidth className="bg-white">
        <InputLabel id="label">Select Status</InputLabel>
        <Select
          labelId="label"
          id="select"
          label="Select Warehouse*"
          value={statusValue}
          onChange={(e) => handleCatalogueStatusChange(e)}
          disabled={catalogueMode === "view"}
        >
          <MenuItem value={"PUBLISHED"}>Published</MenuItem>
          <MenuItem value={"UNPUBLISHED"}>Unpublished</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const updateImages = (images) => {
    const invalidImages = [];
    images?.forEach((image) => {
      if (image.type === 'image/webp') {
        invalidImages.push(image.name);
      } else {
        setFormImageData((prevImages) => [...prevImages, image]);
      }
    });
    if (invalidImages.length > 0) {
      const invalidImageNames = invalidImages.join(', ');
      alert(`${invalidImageNames} files are of type 'webp' which is not allowed. Please select PNG or JPEG files.`);
    }
  };

  const imageCount = imageData.length + formImageData.length;

  return (
    <div className="relative w-full flex flex-col items-start justify-start text-left text-xl text-blue font-input-label">
      <div className="self-stretch flex flex-col items-end justify-start">
        <div className="self-stretch bg-white shadow-[0px_1px_4px_rgba(0,_0,_0,_0.12)] flex flex-col pt-6 px-10 pb-10 items-start justify-start gap-[18px]">
          <div className="self-stretch flex flex-col items-start justify-start">
            <div className="self-stretch flex flex-col items-start justify-start gap-[22px]">
              <div className="self-stretch flex flex-row items-center justify-start gap-[22px] text-[20px] text-blue2">
                <div className="flex-1 relative font-semibold">
                  Edit {CatalogueEnum[catalogueDetails.category]} Catalogue
                </div>
                <div className="w-[196px] h-12 flex flex-row py-[8.5px] px-3.5 items-center justify-start gap-[8px] text-base text-text-color ">
                  <DisplayCatalogueStatus
                    statusValue={catalogueDetails.status}
                  />
                </div>
              </div>

              <div className="self-stretch flex flex-row items-start justify-start text-base text-text-color">
                <div className="flex-1 flex flex-row flex-wrap items-start justify-start">
                  <div className="flex-1 flex flex-col items-start justify-start gap-[12px]">
                    <div className="self-stretch bg-white flex flex-col items-center justify-start text-sm">
                      <TextField
                        className={`block w-full p-2 border  ${formErrors.catalogueTitle
                          ? "border-red-500"
                          : "border-gray-300"
                          } rounded-md focus:outline-none ${formErrors.catalogueTitle
                            ? "ring-red-500"
                            : "ring-blue-500"
                          } focus:border-blue-500`}
                        id="outlined-basic"
                        label="Enter Title"
                        variant="outlined"
                        fullWidth
                        value={catalogueDetails.name}
                        onChange={handleCatalogueTitleChange}
                        error={formErrors.catalogueTitle}
                        InputLabelProps={{
                          shrink: catalogueDetails.name !== ""
                        }}
                        required
                      />

                      <div className="self-stretch flex flex-col py-0 px-3.5 items-start justify-start">
                        <div className="relative bg-white w-[42px] h-0 overflow-hidden shrink-0" />
                      </div>
                    </div>

                    {/* Description  */}
                    <TextField
                      id="outlined-textarea"
                      label="Description"
                      placeholder="Write Description about the Catalogue ..."
                      multiline
                      fullWidth
                      rows={4}
                      variant="outlined"
                      value={catalogueDetails.description}
                      name="catalogueDescription"
                      onChange={(event) =>
                        handleCatalogueDescriptionChange(event)
                      }
                      error={formErrors.catalogueDescription}
                      InputProps={{
                        classes: {
                          root: "h-[7.7rem] p-2",
                          /*notchedOutline: "border-0",*/
                          focused: "focus:ring-blue-500",
                        },
                        disableUnderline: true,
                      }}
                      InputLabelProps={{
                        shrink: catalogueDetails.description !== ""
                      }}
                      required
                    />
                      <FormControl fullWidth>
                        {formErrors?.packagingType && selectedPackagingTypes.length === 0 && ( <div style={{ color: errorMessageColor }}>{"Please Select Packaging type"}</div>)}
                        <CataloguePackingTypes packingTypesData={packingTypes} formErrors={formErrors} initialValues={selectedPackagingTypes} updateFunction={setSelectedPackingTypes} mode={"ADD"} />
                        
                      </FormControl>

                    {/* Display All UPC Codes */}
                    <UPCDisplayDetails
                      mode={catalogueMode}
                      catalogueUpcDetails={catalogueDetails.upcMappings}
                      updateUPCDetails={updateUPCDetails}
                      updateUPCErrors={updateUPCErrors}
                      formErrors={formErrors.upcMappings}
                      type={catalogueDetails.category}
                    />

                    {/* Display validation errors */}
                    {upcErrorState && (
                      <div style={{ color: errorMessageColor }}>{"Please Add Universal Product Code"}</div>
                    )}

                    <FindUpcSearchComponent
                      type={selectedCategory}
                      catalogueId={catalogueId}
                      catalogueUpcDetails={catalogueDetails.upcMappings}
                      updateUPCDetails={updateUPCDetails}
                      isCheckBoxView={true}
                      catalogueType={catalogueType}
                    />


                  </div>
                </div>
              </div>
            </div>
          </div>

          {(imageData.length!==0 || formImageData.length!==0) && <FilesWrapper heading={"Product Images"}>
              <DisplayDocuments
              documentsData={imageData}
              isForm={false}
              deleteDocument={deleteDocument}
            />

            <DisplayDocuments
              documentsData={formImageData}
              isEdit={true}
              isForm={true}
              deleteDocument={deleteDocument}
            />
          </FilesWrapper>}

          {/* Upload Image Section */}
          <UploadProductImages updateImages={updateImages} currentImageCount={imageData.length + formImageData.length} maxImageCount={4}/>

          <div className='flex justify-end space-x-4 mr-9 '>
            <button
              className='rounded bg-white text-gray-700 border border-gray-300  overflow-hidden flex flex-row py-[0.81rem] px-[1.63rem] items-center justify-center text-[0.94rem]'
              onClick={navigateCatalogueTable}
            >
              <div className='relative leading-[1.38rem] font-semibold'>
                Cancel
              </div>
            </button>


            <button
              className="outlined rounded bg-blue overflow-hidden flex flex-row py-[0.81rem] px-[1.63rem] items-center justify-center text-[0.94rem] text-white"
              onClick={handleUpdateCatalog}
              type="submit"
            >
              Update Catalogue
            </button>


          </div>

        </div>
      </div>
    </div>
  );
};

export default EditCatalogue;

