import { Procurement } from '../../../Constants/Procurement/Indent/types';
import { productDescription } from '../../../Constants/Procurement/Indent/constants';
import { ProductAttributeHeading } from '../../../Shared/CustomHeading/ProductAttributeHeading';
import ProductAttributeList from './ProductAttributeList';
import CustomImageComponent from '../../../Shared/CustomInputs/CustomImageComponent';
import { WareHouseContext } from '../../../../contexts/WareHouseDetailContext';
import { useContext } from 'react';
interface ProductDetailProps {
  productDetail: Procurement;
}
const ProductDetail: React.FC<ProductDetailProps> = ({ productDetail }) => {
  const { warehouseDetails } = useContext(WareHouseContext);
  return (
    <div className="border border-gray-300 p-2 rounded-md">
      <div className="flex gap-2 w-full  items-center">
        <div className="w-16 h-16 overflow-hidden flex justify-center min-w-16 max-w-16 flex-shrink-0">
          <CustomImageComponent
            imageUrl={productDetail?.item?.productCategoryImageURL}
            className="object-cover h-16 w-16 max-w-full"
          />
        </div>
        <div className="flex items-center">
          <ProductAttributeHeading
            headingClass={'w-[120px] flex-shrink-0'}
            title={'UPC Code'}
            value={productDetail?.item?.upcCode}
            className={'text-blue font-medium text-base'}
          />
          <ProductAttributeHeading
            title={'UPC Description'}
            value={productDescription(productDetail?.item) || '--'}
            headingClass={'w-full'}
            className={'text-sm  font-medium text-text-color'}
          />
        </div>
        <div className="ml-auto">
          <div className="flex justify-between gap-3 items-center">
            <div className="flex flex-row gap-3 bg-cgray-5  rounded-md px-[8px] py-[16px] justify-start">
              <ProductAttributeList
                attributeMap={productDetail?.item?.attributesMap?.SPECIFICATION}
              />
            </div>
            <div className="bg-cgray-13 flex flex-col gap-1 p-2 rounded-md w-[130px] whitespace-nowrap">
              <div className="flex flex-col gap-1 w-full">
                <div className="text-xs text-cgray-1">Asked Quantity</div>
                <div className="text-sm font-medium text-text-color">
                  {`${productDetail?.quantity} MT`}
                </div>
              </div>
              <div className="flex flex-col gap-1 w-full">
                <div className=" text-xs text-cgray-1">Consignee Location</div>
                <div className=" text-sm font-medium text-text-color">
                  {warehouseDetails.find(
                    (warehouse) =>
                      warehouse.id.toString() === productDetail?.warehouseId
                  )?.name || '--'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
