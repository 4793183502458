

export const NavigationURL = {
  indent: '/procurement/indent',
  purchaseRequisition: '/procurement/purchase-requisition',
  createIndent: 'creating-new-indent',
  createPurchaseRequisition: 'creating-new-requisition',
  liveAuction: '/procurement/reverse-auction/live-auction',
  reverseAuction: '/procurement/reverse-auction/reverse-auction-rfq',
  vendorDiscovery: '/procurement/reverse-auction/vendor-discovery',
};

export const LiveAuctionEndpoints = {
  auction: 'procurement/indent/auction',
};
