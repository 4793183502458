
import { useEffect, useMemo, useState } from "react";
import axios from "axios";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField} from "@mui/material";
import debounce from 'lodash/debounce';
import TablePagination from "../../../AdminMaster/Product Standard/Grade/TablePagination";
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { useAuthenticatedUser } from "../../../../../hooks/useAuthenticatedUser";
import AutoCompleteTextField from "../../../AdminMaster/Product Standard/Standard/AutoCompleteInputField";
const BaseUrl = process.env.REACT_APP_API_V1_URL;

const CustomerTableHeadings = () => {

  
  return (
    <div className="relative bg-blue w-full flex flex-row p-[0.75rem] box-border items-end justify-start gap-[0.75rem] text-left text-[0.88rem] text-white font-roboto">
      <div className="flex-1 flex flex-col items-start justify-start">
        <div className="flex items-center justify-left w-full sm:w-[calc(11rem / 1.6)]">
          <div className="relative font-semibold text-left mr-2">
          </div>
          <div className="relative font-semibold text-left">Sr.no</div>
        </div>
      </div>

      <div className="flex-1 flex flex-col items-start justify-start">
        <div className="relative font-semibold text-left w-full sm:w-[calc(24rem / 1.6)]">Customer Name</div>
      </div>

      <div className="flex-1 flex flex-col items-center justify-start">
        <div className="relative font-semibold text-center w-full sm:w-[calc(21rem / 1.6)]">Company Name</div>
      </div>

      <div className="flex-1 flex flex-col items-center justify-start">
        <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">Mobile Number</div>
      </div>
      <div className="flex-1 flex flex-col items-center justify-start">
        <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">Email</div>
      </div>
    </div>
  );
};

const CustomerRecords = ({ catalogueDetails, updateSelectedCustomers, selectedCustomers, curIndex = 0 ,loader}) => {
  const { user } = useAuthenticatedUser();
  const [expandedRows, setExpandedRows] = useState({});

  if (!catalogueDetails) {
    return <div>Loading...</div>;
  }

  const handleCustomerCheck = (customerId) => {
    const updatedCustomers = { ...selectedCustomers };
    if (updatedCustomers[customerId]) {
      delete updatedCustomers[customerId];
    } else {
      updatedCustomers[customerId] = true;
    }
    updateSelectedCustomers(updatedCustomers)
  }
  
  return (
    <div>
      {catalogueDetails.length > 0 &&
        catalogueDetails.map((item, index) => (
          <div key={index} className="catalogue-details self-stretch flex flex-row p-[0.75rem] items-center justify-start gap-[0.75rem] border-b border-gray-300">

            <div className="flex-1 flex flex-col items-start justify-start">
              <div className="flex items-center justify-left w-full sm:w-[calc(11rem / 1.6)]">
                <div className="relative text-left mr-2"><Checkbox key={index} checked={selectedCustomers[item?.id] || false} onClick = {() => handleCustomerCheck(item?.id)} disabled={loader}/>
                 </div>
                <div className="relative text-left">{curIndex + index + 1}</div>
              </div>
            </div>

            <div className="flex-1  flex flex-col items-start justify-start">
              <div className="relative inline-block  w-full sm:w-[calc(30rem / 1.6)] text-left ">
                {item.fullName}
              </div>
            </div>
            <div className="flex-1 flex flex-col items-center justify-start">
              <div className="relative w-full sm:w-[calc(21rem / 1.6)]  text-center">{item.businessName}</div>
            </div>

            <div className="flex-1 flex flex-col items-center justify-start">
              <div className="relative  w-full sm:w-[calc(11rem / 1.6)]  text-center">{item.mobileNumber}</div>
            </div>
            <div className="flex-1 flex flex-col items-center justify-start">
              <div className="relative  w-full sm:w-[calc(11rem / 1.6)]  text-center">{item.email}</div>
            </div>
          </div>
        ))}
    </div>
  );

};

const CustomerTableDisplay = ({selectedCustomers, updateCustomers, loader, setUserTemplateStatus}) => {
  const { user } = useAuthenticatedUser();
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalRecords, setTotalRecords] = useState(1);
  const [sorting, setSorting] = useState('createdAt,desc');
  const [customerData, setCustomerData] = useState([]);
  const [selectedCustomerData, setSelectedCustomerData] = useState({});
  const [selectedGroupData, setSelectedGroupData] = useState([]);
  const [customerGroups, setCustomerGroups] = useState([]);
  const [customerSearchQuery, setCustomerSearchQuery] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const handleSortingChange = (e) => {
    setSorting(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    debouncedSearch(e.target.value);
  };

  const updateCatalogueDetails = (data) => {
    setCustomerData(data);
  };

  const updateSelectedCustomers = (data) => {
    setSelectedCustomerData(data);
    updateCustomers(data);
  };

  const handleCustomerGroupSearch = (event) => {
    setCustomerSearchQuery(event.target.value.trim())
  }
  const debouncedSearch = debounce(() => {
    fetchCustomerData(pageNumber, pageSize);
  }, 300);


  useEffect(() => {
    fetchCustomerGroups();
  }, [])

  const fetchCustomerGroups = () => {
    const baseUrl = `${BaseUrl}/customer-groups`;
    let queryParams = `?groupName=${customerSearchQuery}&page=0&size=10&sort=createdAt,desc`;
    const url = `${baseUrl}${queryParams}`;
    axios
      .post(url,{}, {
        headers: {
          Authorization: `${user.token}`,
          "Content-Type": "application/json",
        }
       }
      )
      .then((response) => {
        if ((response.data && response.data.data && response.data.data.content)) {
          setCustomerGroups(response.data.data.content);
          return;
        }
      })
      .catch(() => {
        return
      });
  };

  const fetchCustomerData = (page, size) => {
    const baseUrl = `${BaseUrl}/customers`;
    var sortingValue = sorting;
    if (sortingValue === "All") {
      sortingValue = '';
    }
    let queryParams = `?search=${searchQuery}&page=${page}&size=${size}&sort=${sorting}`;
    const url = `${baseUrl}${queryParams}`;
    const customerIds = Object.keys(selectedCustomers).map(x => parseInt(x));
    axios
      .post(url,{
        groupIds: [...selectedGroupData],
        customerIds: customerIds,
        relationManagersIds: []
      }, {
        headers: {
          Authorization: `${user.token}`,
          "Content-Type": "application/json",
        }
      }
      )
      .then((response) => {
        if ((response.data && response.data.data && response.data.data.content)) {
          setTotalRecords(response.data.data.totalElements);
          setCustomerData(response.data.data.content);
          setUserTemplateStatus(response.data.data.content.length === 0)
          return;
        }
      })
      .catch(() => {
        return
      });
  };

  useEffect(() => {
    const initialSelectedCustomers = { ...selectedCustomerData }; 
    customerData.forEach(customer => {
      if (customer.checked === 1 && !initialSelectedCustomers[String(customer.id)]) {
        initialSelectedCustomers[String(customer.id)] = true; 
      }
    });
    setSelectedCustomerData(initialSelectedCustomers);
    updateCustomers(initialSelectedCustomers);
  }, [customerData, selectedCustomerData]); 
  

  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery, pageNumber, pageSize, sorting, selectedGroupData]);

  const customerGroupOptions = useMemo(() => {
    return customerGroups?.map((group) => ({ label: group.groupName, id: group.groupId })) ?? []
  }, [customerGroups])

  const handleDelete = (groupId) => {
    setSelectedGroupData((prevGroups) => prevGroups.filter(id => id !== groupId));
  };
  
  const customerSelectedGroupOptions = useMemo(() => {
    return customerGroups
      ?.filter((group) => selectedGroupData.includes(group.groupId))
      .map((group) => ({
        label: group.groupName,
        id: group.groupId
      })) ?? [];
  }, [ selectedGroupData]);


  const handleGroupOnChange = (groupDetails, groupId) => {
    if (groupDetails && groupId) {
      const uniqueGroupIds = new Set([...selectedGroupData, groupId]);
      const groupIdsArray = Array.from(uniqueGroupIds);
      setSelectedGroupData(groupIdsArray);
    }
  };
  
  const handleOnGroupSearch = (value) => {
      setCustomerSearchQuery(value);
  };


  const activeComponent =  useMemo(() => {
    return (
      <>
        <CustomerTableHeadings
          customerData={customerData}
          selectedCustomerData={selectedCustomerData}
          updateSelectedCustomers={updateSelectedCustomers}
        />
        <CustomerRecords
          catalogueDetails={customerData}
          updateCatalogueDetails={updateCatalogueDetails}
          updateSelectedCustomers={updateSelectedCustomers}
          selectedCustomers={selectedCustomerData}
          curIndex={pageSize * pageNumber}
          loader={loader}
        />
      </>
     );
    }, [selectedCustomers]);

  return (
    <div className="grid gap-y-3 gap-x-4 m-t-10 p-x-10">
      <div>
        Selected: {Object.entries(selectedCustomerData).length || 0} Customers
      </div>
      <div className="flex gap-x-5 justify-between">
        <div className="grid gap-y-3 w-full">
          <div className="flex gap-x-4 w-full">
            <FormControl sx={{ width: "100%", maxWidth: "250px" }}>
              <InputLabel id="sorting-label">Sort</InputLabel>
              <Select
                labelId="sorting-label"
                id="sorting-select"
                label="Sort"
                value={sorting}
                onChange={handleSortingChange}
                sx={{ width: "180%", maxWidth: "250px" }}
                disabled={loader}
              >
                <MenuItem value="fullName,asc">Name (Ascending)</MenuItem>
                <MenuItem value="fullName,desc">Name (Descending)</MenuItem>
                <MenuItem value="createdAt,asc">
                  Created Date(Ascending)
                </MenuItem>
                <MenuItem value="createdAt,desc">
                  Created Date(Descending)
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl sx={{ width: "100%", maxWidth: "250px" }}>
              <TextField
                className={`h-[3rem] block w-full p-1 border border-gray-500 rounded-md focus:outline-none ring-blue-500 focus:border-blue-500`}
                id="outlined-basic"
                label="Search"
                variant="outlined"
                fullWidth
                value={searchQuery}
                onChange={handleSearchChange}
                disabled={loader}
              />
            </FormControl>

            <FormControl sx={{ width: "100%", maxWidth: "250px" }}>
            <AutoCompleteTextField
                suggestions={customerGroupOptions}
                textLable="Customer Groups"
                onChange={(e) => handleCustomerGroupSearch(e)}
                onSearch={handleOnGroupSearch}
                updateDataSelection={handleGroupOnChange}
                displayValue="x"
            />
            </FormControl>

          </div>
               {customerSelectedGroupOptions && customerSelectedGroupOptions.length > 0 && (
            <div className="flex">
              <div className="h-fit my-auto">Customer Groups: </div>
              <Box>
                {customerSelectedGroupOptions.map((group) => (
                  <Chip
                    key={group.id}
                    label={group.label}
                    onDelete={() => handleDelete(group.id)}
                    variant="outlined"
                    style={{ margin: '4px' }}
                  />
                ))}
              </Box>
            </div>
          )}
        </div>
        <div>
        </div>
      </div>
      <div>
        {activeComponent}
        <TablePagination
          pageNumber={pageNumber}
          pageSize={pageSize}
          handlePageSize={setPageSize}
          handlePageNumber={setPageNumber}
          totalRecordsData={totalRecords}
        />
      </div>
    </div>
  );
};

const CustomersTable = ({selectedCustomers, updateCustomers, loader, setUserTemplateStatus}) => {
  return (
    <div className="mt-5 mb-5" >
      <CustomerTableDisplay 
        selectedCustomers={selectedCustomers} 
        updateCustomers={updateCustomers} 
        loader={loader} 
        setUserTemplateStatus={setUserTemplateStatus}
      />
    </div>
  );
};

export default CustomersTable;

