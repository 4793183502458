import Tabs, { ITabsSchema } from '../../../molecules/Tabs/Tabs';
import RawMaterialListingTableTemplate from './RawMaterialListingTable.template';
import { useEffect, useState } from 'react';
import { useCategoryService } from '../../../../services/useCategoryService';
import { HTTP_STATUS, MY_MATERIAL_STATE } from '../../../../utils/types';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { ICategory } from './CategorySelection.template';
import TabsV2 from '../../../molecules/TabsV2/TabsV2';
import { createUseStyles } from 'react-jss';


interface Category {
  id: number, label: string, path: string
}

interface RawMaterialListingTemplateProps {
   updateMaterialStateTo: (value: MY_MATERIAL_STATE) => void;
}

const RawMaterialListingTemplate: React.FC<RawMaterialListingTemplateProps> = ({updateMaterialStateTo}) => {
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [activeTab, setActiveTab] = useState(1);
  const  categoryService = useCategoryService();
  const { showSnackbar, SnackBarComponent } = useSnackbar();
  const [tabSchema, setTabSchema] = useState<ITabsSchema[]>([]);

  const loadProductCategories = () => {
    categoryService.getAllCategories({ level: 2, page: 0, size: 10 })
      .then((res: any) => {
        if (res?.status === HTTP_STATUS.OK)
          setCategories(res?.data?.data?.content)
      }).catch((error:any) => {
        showSnackbar('error', "Error Fetching Category");
      })
  }

  useEffect(() => {
    loadProductCategories();
  }, [])

  useEffect(() => {
    setTabSchema(categories?.map(category => ({ label: category.name, component: <RawMaterialListingTableTemplate category={{ id: category.id, label: category.name }} updateMaterialStateTo={updateMaterialStateTo} /> })) ?? [])
  }, [categories])

  return (
    <div className='grid gap-y-6'>
      {SnackBarComponent}
      <div className='w-full'>
        <TabsV2 schema={tabSchema} value={(activeTab).toString()} setValue={setActiveTab} fullWidth={false} />
      </div>
    </div>
  )
}

export default RawMaterialListingTemplate
