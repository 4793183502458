import React, { useMemo } from "react";
import MuiButton, { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { createUseStyles, useTheme } from "react-jss";

type CustomVariant = "contained" | "outlined" | "transparent" | "text";

const useStyles = createUseStyles({
    contained: {
        color: 'white',
        backgroundColor: ({ theme }: any) => theme.palette.button.primaryDark,
        "&:hover": {
            backgroundColor: ({ theme }: any) => theme.palette.button.primaryLight,
        },
    },
    outlined: {
        color: 'black',
        border: ({ theme }: any) => `1px solid ${theme.palette.button.primaryDark}`,
    },
    transparent: {
        color: 'black',
        backgroundColor: 'transparent',
    },
    text: {
        color: 'black',
        backgroundColor: 'transparent',
    },
    iconButton: {
        color: ({ theme }: any) => theme.palette.button.primaryDark,
        width: 28,
        height: 28,
    },
});

interface ButtonProps extends Omit<MuiButtonProps, "variant"> {
    variant: CustomVariant;
    label: string;
    isLoading?: boolean;
    iconButton?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({
    variant,
    label,
    isLoading,
    iconButton,
    ...props
}) => {
    const theme = useTheme<Jss.Theme>();
    const styles = useStyles({ theme });

    const buttonView = useMemo(
        () => iconButton ? <span className={styles.iconButton}>{iconButton}</span> : label,
        [iconButton, label, styles]
    )

    return (
        <MuiButton
            className={styles[variant]}
            {...props}
            disabled={props.disabled || !!isLoading}
        >
            {isLoading ? <CircularProgress /> : buttonView}
        </MuiButton>
    );
};
export default Button;
