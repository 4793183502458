
import CustomersTable from "./WhatsAppIntegration/Users/CustomersTable";
import TemplateForm from "./WhatsAppIntegration/Templates/TemplateForm";
import { useState } from "react";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
const BaseUrl = process.env.REACT_APP_API_V1_URL;

const SelectTemplate = ({ nextStage, selectedCustomers, templateData, updateCustomers, updateTemplateDetails, catalogueDetails, updateTemplateUserStatus, catalogueName, classType}) => {
  const { user } = useAuthenticatedUser();
  const [loader, setLoader] = useState(false);
  const [disableTemplate, setDisableTemplate]  = useState(false);
  return (
    <>
      <div className="flex-1 relative font-semibold mt-5 mb-5">Select User Details </div>
        <CustomersTable 
          selectedCustomers={selectedCustomers} 
          updateCustomers={updateCustomers} 
          loader={loader}
          setUserTemplateStatus={setDisableTemplate}
        />
      {Object.entries(selectedCustomers).length > 0 && (
        <>
            <div className="flex-1 relative font-semibold mt-5 mb-5">Select Template</div>
              <TemplateForm
                selectedCustomers={selectedCustomers}
                templateData={templateData}
                updateTemplateDetails={updateTemplateDetails}
                catalogueDetails={catalogueDetails}
                nextStage={nextStage}
                updateTemplateUserStatus={updateTemplateUserStatus}
                setLoader={setLoader}
                loader={loader}
              />
        </>
      )}

    </>
  );
};

export default SelectTemplate;
