import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";

export const ATTRIBUTE_URLS = {
    GET_ALL_ATTRIBUTE: "/attributeV2",
};

export interface IAttributeFilterRequest {
    status?: string;
    attributeType?: string;
    name?: string;
    page?: number;
    size?: number;
    sort?: string;
}

export interface SubAttribute {
    id: number;
    parentId: number;
    minValue: number;
    maxValue: number;
    status: string;
    minTolerance: number;
    maxTolerance: number;
    createdBy: number;
    updatedBy: number;
    createdAt: string;
    updatedAt: string
}

export interface Attribute {
    id: number;
    name: string;
    uom: string;
    type: string;
    status: string;
    subAttributes: SubAttribute[];
}

export const useAttributeServiceV2 = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();

    const getAllAttributes = (params: IAttributeFilterRequest) => {
        return request(API_METHOD.GET, ATTRIBUTE_URLS.GET_ALL_ATTRIBUTE, authenticatedUser, null, { params });
    };

    return {
        getAllAttributes,
    };
};
