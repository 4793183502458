import { IBrandUpcs, IMasterUpcs } from "../components/pages/UpcListing/UpcListing.page";

export enum AUTH_STATES {
	LOGIN_WITH_EMAIL = "LOGIN_WITH_EMAIL",
	LOGIN_WITH_MOBILE = "LOGIN_WITH_MOBILE",
	USER_SIGNUP = "USER_SIGNUP",
	USER_FORM = "USER_FORM",
	FORGOT_PASSWORD = "FORGET_PASSWORD",
	OTP_VERIFICATION = "OTP_VERIFICATION"
}

export enum HTTP_STATUS {
	OK = 200,
	ACCEPTED = 202,
	NO_CONTENT = 204,
	BAD_REQUEST = 400,
	UNAUTHORIZED = 401,
	FORBIDDEN = 403,
	NOT_FOUND = 404,
	METHOD_NOT_ALLOWED = 405,
	INTERNAL_SERVER_ERROR = 500
}

  export enum KEY_PERSON_DETAIL_STATES {
    VIEW_KEY_PERSON_DETAILS = "VIEW_KEY_PERSON_DETAILS",
	ADD_KEY_PERSON_NO = "ADD_KEY_PERSON_NO",
	ADD_KEY_PERSON_DETAILS = "ADD_KEY_PERSON_DETAILS",
}

export enum INVENTORY_SECTIONS {
	UPC_CODE = "UPC_CODE",
	INVENTORY_DETAILS = "INVENTORY_DETAILS",
	IMAGES_UPLOAD = "IMAGES_UPLOAD",
	PREVIEW = "PREVIEW",
}

export enum SECONDARY_INVENTORY_SECTIONS {
	CATEGORY = "CATEGORY",
	DEFECT_DETAILS = "DEFECT_DETAILS",
	WAREHOUSE = "WAREHOUSE",
	IMAGES_UPLOAD = "IMAGES_UPLOAD",
}

export enum SECONDARY_CATALOGUE_SECTIONS {
	SECONDARY_INVENTORY = "SECONDARY_INVENTORY",
	CATALOGUE_DETAILS = "CATALOGUE_DETAILS",
	PRICE_DETAILS = "PRICE_DETAILS",
	IMAGES_UPLOAD = "IMAGES_UPLOAD",
	PREVIEW = "PREVIEW",
}
export interface IWarehouse {
	id: number;
	name: string;
	shortName: string;
	address: string;
	type: string;
	updatedAt: string;
	updatedBy: number;
}

export enum HONORIFICS {
    MR= "Mr.",
    MRS= "Mrs."
}

export enum WHATSAPP_LINK {
    PRIMARY= "primary",
    SECONDARY= "secondary"
}

export interface IPagination {
	currentPage: number;
	pageSize: number;
	totalPages: number;
	totalRecords: number;
};

export enum MANUFACTURER_SECTIONS {
	MANUFACTURER_DETAILS = "MANUFACTURER_DETAILS",
	MANUFACTURING_LOCATION_DETAILS = "MANUFACTURING_LOCATION_DETAILS",
	POINT_OF_CONTACT = "POINT_OF_CONTACT"
}

export enum PRODUCT_CATEGORY_FORM_STATE {
	PRODUCT_CATEGORY = 1,
	ATTRIBUTE_MANAGEMENT = 2,
	STANDARD_MANAGEMENT = 3
}
export interface option {
	value: string;
	label: string;
}

export type MakeRouteParams = {
	params?: { [key: string]: any } | null;
	query?: { [key: string]: any } | null;
};

export enum CLASS_TYPE  {
    STANDARD = "STANDARD",
    SECONDARY = "SECONDARY",
	MAKE_TO_ORDER = "MAKE_TO_ORDER",
	NONSTANDARD = "NONSTANDARD"
}

export enum CATEGORY_TYPE  {
    SUPER_CATEGORY = 0,
    MAIN_CATEGORY = 1,
	PRODUCT_CATEGORY = 2
}

export enum RESOURCE_TYPE {
	PRODUCT_UPCS = "PRODUCT_UPCS",
	PRODUCT_MPCS = 'PRODUCT_MPCS'
}

export enum LOCATION_STATES {
    VIEW_LOCATIONS = "VIEW_LOCATIONS",
	ADD_LOCATION_NO = "ADD_LOCATION_NO",
	ADD_LOCATIONS = "ADD_LOCATIONS",
	PRODUCT_UPCS = "PRODUCT_UPCS",
	PRODUCT_MPCS = "PRODUCT_MPCS"

}

export enum CUSTOMER_GROUP_SECTIONS {
	CUSTOMER_GROUP_MASTER_FORM = "CUSTOMER_GROUP_MASTER_FORM",
	CUSTOMER_GROUP_CONFIGURATION_FORM = "CUSTOMER_GROUP_CONFIGURATION_FORM",
	UPC_SEARCH_FORM = "UPC_SEARCH_FORM",
	ADDITIONAL_CUSTOMER_FORM = "ADDITIONAL_CUSTOMER_FORM",
	CUSTOMER_GROUP_PREVIEW = "CUSTOMER_GROUP_PREVIEW"
}

export enum GST_STATUS_CODE {
	DUPLICATE_GST = "DUPLICATE_GST",
	INVALID_GST = "INVALID_GST"
}

export enum MY_MATERIAL {
	MY_MATERIAL_LISTING="MY_MATERIAL_LISTING",
	CATEGORY_SELECTION="CATEGORY_SELECTION",
	MY_MATERIAL_FORM="MY_MATERIAL_FORM",
	PRODUCT_REQUIREMENT="PRODUCT_REQUIREMENT",
	MY_MATERIAL_PRODUCT="MY_MATERIAL_PRODUCT",
	FINISHED_PRODUCT_LISTING = "FINISHED_PRODUCT_LISTING",
	MAIN_RAW_MATERIAL = "MAIN_RAW_MATERIAL",
	FINISHED_PRODUCT = "FINISHED_PRODUCT",
	SCRAP = "SCRAP",
	SCRAP_LISTING = "SCRAP_LISTING",
	MY_MATERIAL_VIEW = "MY_MATERIAL_VIEW"
 }

export enum MY_MATERIAL_STATE {
	MY_MATERIAL_VIEW = "MY_MATERIAL_VIEW",
	MY_MATERIAL_CREATE = "MY_MATERIAL_CREATE",
	SCRAP_LISTING = "SCRAP_LISTING",
	SCRAP_CREATE = "SCRAP_CREATE",
	SCRAP_UPDATE = "SCRAP_UPDATE",
	FINISHED_PRODUCT_LISTING = "FINISHED_PRODUCT_LISTING",
	FINISHED_PRODUCT_CREATE = "FINISHED_PRODUCT_CREATE",
	FINISHED_PRODUCT_UPDATE = "FINISHED_PRODUCT_UPDATE"
}

export enum SCRAP_STATE {
	SCRAP_LISTING = "SCRAP_LISTING",
	SCRAP_CREATE = "SCRAP_CREATE",
	SCRAP_UPDATE = "SCRAP_UPDATE"
}
 
 export enum MY_MATERIAL_LISTING {
	 MAIN_RAW_MATERIAL = 1,
	 FINISHED_PRODUCT = 2,
	 SCRAP = 3
 }
 
 export enum MODE {
	 VIEW = "VIEW",
	 ADD = "ADD",
	 UPDATE = "UPDATE",
	 EDIT = "EDIT",
 }
 
 export enum CLASS_STATES {
    STANDARD= "STANDARD",
    NONSTANDARD= "NONSTANDARD",
    SECONDARY= "SECONDARY"
}

export enum USER_DETAILS_DUPLICATION_CODE {
	MOBILE_NUMBER_DUPLICATION = "MOBILE_NUMBER_DUPLICATION",
	EMAIL_DUPLICATION = "EMAIL_DUPLICATION",
	VENDOR_EXISTS_WITH_DIFFERENT_MOBILE_NUMBER = "VENDOR_EXISTS_WITH_DIFFERENT_MOBILE_NUMBER",
	VENDOR_EXISTS_WITH_DIFFERENT_EMAIL = "VENDOR_EXISTS_WITH_DIFFERENT_EMAIL"
}

export enum VendorTypeEnum {
	PRIMARY_MANUFACTURER = 'PRIMARY_MANUFACTURER',
	SECONDARY_MANUFACTURER = 'SECONDARY_MANUFACTURER',
	FINISHED_PRODUCT_MANUFACTURER = 'FINISHED_PRODUCT_MANUFACTURER',
	PROCESSOR = 'PROCESSOR',
	FABRICATORS = 'FABRICATORS',
	CHANNEL_PARTNER = 'CHANNEL_PARTNER',
	BRAND_DISTRIBUTOR_STOCKIST_IMPORTANT = 'BRAND_DISTRIBUTOR_STOCKIST_IMPORTANT',
  }

  export enum VAS_MASTER_CATEGORY_TYPE {
	SUPER_SERVICE = 0,
	MAIN_SERVICE = 1,
	SERVICE_CATEGORY = 2
}

export enum PRODUCT_SERVICE_FORM_STATE {
	SERVICE_CATEGORY_DETAILS = 1,
	ATTRIBUTE_MANAGEMENT_FORM_1 = 2,
	ATTRIBUTE_MANAGEMENT_FORM_2 = 3,
	REVIEW = 4
}