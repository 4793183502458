import { FormHelperText, OutlinedInput } from '@mui/material'
import React, { useRef } from 'react'
import './OtpInputs.css'
// Accepts 2 states: otp, setOtp, otpError, setOtpError

const OtpInputs = ({ otp, setOtp, otpError, setOtpError, otpErrorMessage }) => {
    const otpInputRefs = useRef([]);
    const handleOtpChange = (index, event) => {
        const value = event.target.value;

        // Check if the entered value is a number
        if (!isNaN(value)) {
            // Update the OTP value at the specified index
            const updatedOtp = [...otp];
            updatedOtp[index] = value;
            setOtp(updatedOtp);
            setOtpError(false);

            // Focus on the next input field
            if (value !== '' && index < otp.length - 1) {
                otpInputRefs.current[index + 1].focus();
            }
        }
    };

    const handleOtpPaste = (event) => {

        const pasteData = event.clipboardData.getData('text/plain');
        const otpArray = pasteData.slice(0, 4).split('');

        // Update the OTP values with the pasted data
        const updatedOtp = [...otp];
        otpArray.forEach((value, index) => {
            if (!isNaN(value)) {
                updatedOtp[index] = value;
            }
        });
        setOtp(updatedOtp);
        setOtpError(false);

        // Focus on the next input field
        otpInputRefs.current[otpArray.length].focus();
    };
    return (
        <div className="otp-input-container flex justify-start gap-x-4">
            {otp.map((value, index) => (
                <OutlinedInput
                    key={index}
                    inputRef={(ref) => (otpInputRefs.current[index] = ref)}
                    type="text"
                    inputProps={{ maxLength: 1 }}
                    value={value}
                    onChange={(event) => handleOtpChange(index, event)}
                    onPaste={handleOtpPaste}
                    className='otp-input'
                    placeholder='-'
                    error={otpError}
                />
            ))}
            {otpError && (
                <FormHelperText error className='otp-error'>{otpErrorMessage}</FormHelperText>
            )}
        </div>
    )
}

export default OtpInputs