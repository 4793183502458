import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: 0, // Remove padding
  },
}));

export default function PropertiesDialogPopUp({ open, onClose, children }) {
  const handleClose = () => {
    onClose(false);
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={false}
      sx={{ zIndex: 1000 }} 
    >
      <DialogContent dividers>
        {children}
      </DialogContent>
    </BootstrapDialog>
  );
}
