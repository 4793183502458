import React from 'react'
import { Route, Routes } from 'react-router-dom'
import BasicTable from '../../Components/UserProfile/BasicTable'
import EditNumberDialog from '../../Components/UserProfile/EditNumberDialog'
const UserProfileRouter = () => {
    return (
        <Routes>
            <Route path="/" element={<BasicTable />} />
            {/* <Route path="/edit" Component={EditNumberDialog } /> */}
        </Routes>
    )
}

export default UserProfileRouter