import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useWhatsappTemplateService } from '../../../services/useWhatsappTemplateService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { IWhatsappTemplate } from './WhatsappTemplateList.page';
import WhatsappTemplateFormTemplate from '../../templates/WhatsappTemplate/WhatsappTemplateForm.template';
import { MODE } from '../../../utils/constant';

const WhatsappTemplateViewPage: React.FC = () => {

    const params = useParams();
    const templateId = Number(params.id);

    const whatsappTemplateService = useWhatsappTemplateService();

    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const [template, setTemplateTo] = useState<IWhatsappTemplate | null>(null);

    const fetchTemplate = async () => {
        if (templateId) {
            const templateResponse = await whatsappTemplateService.getTemplateById(templateId)
            if (templateResponse.data?.data) {
                setTemplateTo(templateResponse?.data?.data)
            }
            else {
                throw new Error("Template fetch failed");
            }
        }
        else {
            showSnackbar('error', 'Template fetch failed')
            setTemplateTo(null);
        }
    }

    useEffect(() => {
        fetchTemplate();
    }, [templateId]);

    return (
        <div>
            {SnackBarComponent}
            <WhatsappTemplateFormTemplate template={template} mode={MODE.VIEW} />
        </div>
        )
}
export default WhatsappTemplateViewPage