import {
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  ReactNode,
  FC,
  useEffect,
} from 'react';
import { PRParamsType } from '../App/Constants/Procurement/PurchaseRequisition/types';
import { IndentContextType } from '../App/Constants/Procurement/Indent/types';
import moment from 'moment';
import { VendorDiscoveryContextType } from '../App/Constants/Procurement/VendorDiscovery/types';
import { RFQContextTypes } from '../App/Constants/Procurement/ReverseAuctionRFQ/types';
import { AuctionParamsType } from '../App/Constants/Procurement/LiveAuction/constants';
import { useLocation } from 'react-router-dom';
import { NavigationURL } from '../App/Constants/EndPoints';

type ProcurementContextTypes = {
  PRParams: PRParamsType;
  setPRParams: Dispatch<SetStateAction<PRParamsType>>;
  indentParams: IndentContextType;
  setIndentParams: Dispatch<SetStateAction<any>>;
  liveAuctionParams: AuctionParamsType;
  setliveAuctionParams: Dispatch<SetStateAction<AuctionParamsType>>;
  vendorDiscoveryParams: VendorDiscoveryContextType;
  setVendorDiscoveryParams: Dispatch<SetStateAction<any>>;
  RFQParams: RFQContextTypes;
  setRFQParams: Dispatch<SetStateAction<any>>;
};

const defaultPRParams: PRParamsType = {
  prIDs: '',
  searchTerm: '',
  statuses: 'All',
  pageSize: 10,
  pageNumber: 0,
  isUserPRs: false,
  fromDate: moment().subtract(3, 'months').startOf('day').unix(),
  toDate: moment().endOf('day').unix(),
  isUserDrafts: false,
  warehouseId: '',
};

const defaultIndentParams: IndentContextType = {
  indentIds: '',
  statuses: 'All',
  pageSize: 10,
  pageNumber: 0,
  isUserIndents: false,
  fromDate: moment().subtract(3, 'months').startOf('day').unix(),
  toDate: moment().endOf('day').unix(),
  isUserDrafts: false,
};

const defaultVendorDiscoveryParams: VendorDiscoveryContextType = {
  searchTerm: '',
  statuses: 'APPROVED',
  pageSize: 10,
  pageNumber: 0,
  isUserAuction: false,
  auctionFromDate: moment().subtract(3, 'months').startOf('day').unix(),
  auctionToDate: moment().endOf('day').unix(),
  fromDate: moment().subtract(3, 'months').startOf('day').unix(),
  toDate: moment().endOf('day').unix(),
  isUserDrafts: false,
  selectedAuction: undefined,
};

const defaultRFQParams: RFQContextTypes = {
  searchTerm: '',
  statuses: 'All',
  pageSize: 10,
  pageNumber: 0,
  isUserAuction: false,
  auctionFromDate: moment().subtract(3, 'months').startOf('day').unix(),
  auctionToDate: moment().endOf('day').unix(),
  fromDate: moment().subtract(3, 'months').startOf('day').unix(),
  toDate: moment().endOf('day').unix(),
  isUserDrafts: false,
  selectedAuction: '',
};

const defaultAuctionParams: AuctionParamsType = {
  searchTerm: '',
  isUserAuctions: false,
  statuses: 'All',
  pageSize: 10,
  pageNumber: 0,
  fromDate: moment().subtract(3, 'months').startOf('day').unix(),
  toDate: moment().endOf('day').unix(),
  isUserDrafts: false,
};

export const PRContext = createContext<ProcurementContextTypes>({
  PRParams: defaultPRParams,
  setPRParams: () => {},
  indentParams: defaultIndentParams,
  setIndentParams: () => {},
  liveAuctionParams: defaultAuctionParams,
  setliveAuctionParams: () => {},
  vendorDiscoveryParams: defaultVendorDiscoveryParams,
  setVendorDiscoveryParams: () => {},
  RFQParams: defaultRFQParams,
  setRFQParams: () => {},
});

export const ProcurementParamsContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const location = useLocation();
  const [PRParams, setPRParams] = useState<PRParamsType>(defaultPRParams);
  const [indentParams, setIndentParams] = useState<any>(defaultIndentParams);
  const [liveAuctionParams, setliveAuctionParams] =
    useState<AuctionParamsType>(defaultAuctionParams);
  const [vendorDiscoveryParams, setVendorDiscoveryParams] =
    useState<VendorDiscoveryContextType>(defaultVendorDiscoveryParams);
  const [RFQParams, setRFQParams] = useState<RFQContextTypes>(defaultRFQParams);
  useEffect(() => {
    if (!location.pathname.includes(NavigationURL.purchaseRequisition)) {
      setPRParams(defaultPRParams);
    } else if (!location.pathname.includes(NavigationURL.liveAuction)) {
      setliveAuctionParams(defaultAuctionParams);
    } else if (!location.pathname.includes(NavigationURL.reverseAuction)) {
      setRFQParams(defaultRFQParams);
    } else if (!location.pathname.includes(NavigationURL.indent)) {
      setIndentParams(defaultIndentParams);
    } else if (!location.pathname.includes(NavigationURL.vendorDiscovery)) {
      setVendorDiscoveryParams(defaultVendorDiscoveryParams);
    }
  }, [location.pathname]);
  return (
    <PRContext.Provider
      value={{
        PRParams,
        setPRParams,
        indentParams,
        setIndentParams,
        liveAuctionParams,
        setliveAuctionParams,
        vendorDiscoveryParams,
        setVendorDiscoveryParams,
        RFQParams,
        setRFQParams,
      }}
    >
      {children}
    </PRContext.Provider>
  );
};
