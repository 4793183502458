import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import ButtonV2 from '../../../atoms/ButtonV2';
import USCListTemplate from '../../../templates/VASMaster/USC/USCList.template';
import { IMachineRequestParams, IMachineSearchParams, IUSCRequestParams, IUSCSearchParams, useVASMasterCategoryService } from '../../../../services/useVASMasterCategoryService';
import { HTTP_STATUS, IPagination } from '../../../../utils/types';
import { ADMIN_ROUTES, initialPaginationValues } from '../../../../utils/constant';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { useNavigate } from 'react-router-dom';
import MachineListTemplate from '../../../templates/VASMaster/Machine/MachineList.template';

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.textV2.primary.primary950,
    },
}));

const initialMachineFiltersValues: IMachineSearchParams = {
    search: "",
    status: "ALL",
    sort: "createdAt,desc",
}

const initialMachineRequestFilters: IMachineRequestParams = {
    uscIds: [],
    serviceCategoryIds: [],
};

const MachineListPage: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const vasMasterCategoryService = useVASMasterCategoryService();
    const [filters, setFilters] = useState<IMachineSearchParams>(initialMachineFiltersValues);
    const [machineRequestFilters, setMachineRequestFiltersTo] = useState<any>(initialMachineRequestFilters);
    const [pagination, setPagination] = useState<IPagination>(initialPaginationValues);
    const [machines, setMachines] = useState<any>([])
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const refreshMachine = async (page: number, size: number) => {
        const payload: IMachineRequestParams = {
            uscIds: machineRequestFilters?.uscIds?.length > 0 ? machineRequestFilters?.uscIds : null,
            serviceCategoryIds: machineRequestFilters?.serviceCategoryIds?.length > 0 ? machineRequestFilters?.serviceCategoryIds : null,
          };
        await vasMasterCategoryService.getMachines(
            {
                page: page,
                size: size,
                sort: filters?.sort,
                search: filters?.search?.trim(),
                status: filters?.status === "ALL" ? "" : filters?.status,
            },
            payload
        )
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    const { totalElements, totalPages } = res?.data?.data;
                    setPagination({
                        ...pagination,
                        totalPages: totalPages,
                        totalRecords: totalElements
                    });
                    setMachines(res?.data?.data?.content)
                }
            }).catch(error => {
                showSnackbar('error', error);
                setMachines([]);
            })
    }

    const handleFiltersChange = (name: string, value: any) => {
        setPagination({ ...pagination, currentPage: 0 });
        setFilters({ ...filters, [name]: value ?? "" });
    };

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPagination((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage
        }));
    };

    useEffect(() => {
        refreshMachine(pagination.currentPage, pagination.pageSize);
    }, [pagination.currentPage, pagination.pageSize, filters, machineRequestFilters]);

    const handleCreate = () => {
        navigate(`${ADMIN_ROUTES.VAS_MASTER_MACHINES_CREATE}`);
    };

    return (
        <div className='grid gap-y-4'>
            <div className='flex justify-between'>
                <div className={`${classes.heading} text-base font-semibold my-auto`}>VAS Master Machine</div>
                <ButtonV2 variant='primaryContained' label={"+ Add New Machine"} onClick={handleCreate}/>
            </div>
            {SnackBarComponent}

            <MachineListTemplate
                machines={machines}
                pagination={pagination}
                handlePaginationChange={handlePaginationChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                handleFiltersChange={handleFiltersChange}
                filters={filters}
                machineRequestFilters={machineRequestFilters}
                setMachineRequestFiltersTo= {setMachineRequestFiltersTo}
            />
        </div>
    )
}

export default MachineListPage