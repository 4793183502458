import { PurchaseHistoryCard } from "./PurchaseHistoryCard";
import Card from "./Card";
import PurchaseGraph from "./PurchaseGraph";

const InsightCard = () => {
  return (
    <div className="border-b-[1px] border-cgray-4">
      <div className="p-8">
        <div className="bg-cgray-7 p-4">
          <div className="grid grid-cols-4 gap-8">
            <Card
              title={"Present Stock Position"}
              value={"1000 MT"}
              size={"large"}
              border={true}
            />
            <Card
              title={"Present Stock Valuation"}
              value={"₹ 2,30,000"}
              size={"large"}
              border={true}
            />
            <Card
              title={"Replenishment Level"}
              value={"100 MT"}
              size={"large"}
              border={true}
            />
            <Card
              title={"General Lead Time"}
              value={"14-15 days"}
              size={"large"}
              border={true}
            />
            <Card title={"Inventory Importance"} size={"large"} border={true}>
              <div className=" rounded-full py-1 px-3 bg-insight-red text-white w-max text-center mt-0.5">
                A
              </div>
            </Card>
            <Card title={"Demand Forecasting"} size={"large"} border={true}>
              <div className=" rounded-full py-1 px-3 bg-insight-red text-white w-max text-center mt-0.5">
                High
              </div>
            </Card>
          </div>
          <div className="my-8 grid grid-cols-2 gap-8">
            <div>
              <div className="text-text-color font-semibold text-2xl">
                Last Purchase History
              </div>
              {[1, 2, 3, 4].map((id) => (
                <div className="my-4" key={id}>
                  <PurchaseHistoryCard />
                </div>
              ))}
            </div>
            <div>
              <div className="font-semibold text-2xl">Price vs Time graph</div>
              <PurchaseGraph />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsightCard;
