import { createUseStyles } from "react-jss";
import { CUSTOMIZTION_TYPE, SELECT_VAS_CUSTOMIZATION } from "../../../../utils/constant";
import { useEffect, useRef, useState } from "react";
import { useConfigurationService } from "../../../../services/useConfigurationService";
import ShearingCustomizationPopup, { IShearing } from "./ShearingCustomizationPopup";
import { isNaN } from "lodash";
import SlittingCustomizationPopup, { ISlitting } from "./SlittingCustomizationPopup";
import ButtonV2 from "../../../atoms/ButtonV2";
import { HTTP_STATUS } from "../../../../utils/types";
import { Dialog } from "@mui/material";
import { IInstantQuotationVASProductDetails } from "../../../pages/ManageQuotation/QuoteCreate.page";
import CloseIcon from '@mui/icons-material/Close';


interface SelectCustomizationTemplateProps {
    quantity: number;
    selectedUpc?: any | null;
    valueAddedService: IValueAddedService;
    setValueAddedServiceTo: any;
    isCustomizationOpen: any,
    setIsCustomizationOpen: any, 
    vasDetails: IInstantQuotationVASProductDetails;
    setVasDetails: (details: any) => void;
}

export interface ICustomization {
    uom: string;
    name: string;
    price: number;
    value: string;
    discription: string;
    minLength: number;
    maxLength: number;
    minThickness: number;
    maxThickness: number;
    minWidth: number;
    maxWidth: number;
}

export interface IValueAddedService {
    shearing: IShearing[];
    slitting: ISlitting[];
}

const useStyles = createUseStyles((theme: any) => ({
    mainContainer: {
        gridTemplateColumns: "repeat(4, minmax(0, 1fr))"
    },
    container: {
        padding: "20px 16px",
        border: "1px solid #D2D7E4"
    },
    box: {
        "& .MuiDialog-paper": {
            width: "894px !important",
            maxWidth: "894px !important",
            borderRadius: "16px",
            padding: '24px'
        },
    },
    cardHeading: {
        color: "black",
        fontSize: "18px"
    },
    cardDescription: {
        color: "black",
        lineHeight: "19px",
        letterSpacing: "0.014px"
    },
    header: {
        color: "black"
    },
    "@media (max-width: 480px)": {
        mainContainer: {
            gridTemplateColumns: "repeat(1, minmax(0, 1fr))"
        },
        cardHeading: {
            fontSize: "16px"
        },
        container: {
            padding: "16px 12px",
        },
    },
    closeIcon: {
        color: '#696E7C'
    },
    text: {
        color: theme.palette.textV2.primary.primary900
    },

}))

const SelectCustomizationTemplate: React.FC<SelectCustomizationTemplateProps> = ({ quantity, selectedUpc, valueAddedService, setValueAddedServiceTo, isCustomizationOpen,setIsCustomizationOpen,setVasDetails, vasDetails  }) => {

    const classes = useStyles();
    const configurationService = useConfigurationService();

    const [customization, setCustomizationTo] = useState<ICustomization[]>([]);
    const [shearingCustomization, setShearingCustomization] = useState<ICustomization>(
        {
            uom: "MT",
            name: "Shearing (CTL)",
            price: 0,
            value: "SHEARING",
            discription: "Cutting large sheets of steel into smaller, manageable sizes.",
            minLength: 0,
            maxLength: Infinity,
            minThickness: 0,
            maxThickness: Infinity,
            minWidth: 0,
            maxWidth: Infinity,
        }
    );

    const [slittingCustomization, setSlittingCustomization] = useState<ICustomization>(
        {
            uom: "MT",
            name: "Slitting (CTW)",
            price: 0,
            value: "SLITTING",
            discription: "Longitudinally cutting into narrower strips with uniform width.",
            minLength: 0,
            maxLength: Infinity,
            minThickness: 0,
            maxThickness: Infinity,
            minWidth: 0,
            maxWidth: Infinity,
        }
    );

    const [balanceWeight, setBalanceWeight] = useState<number | null>(null)

    const [slittingDialogOpen, setSlittingDialogOpen] = useState<boolean>(false);
    const [shearingDialogOpen, setShearingDialogOpen] = useState<boolean>(false);

    const handleSelectCustomization = (value: string) => {
        if (value === CUSTOMIZTION_TYPE.SHEARING) {
            setShearingDialogOpen(true)
        } else if (value === CUSTOMIZTION_TYPE.SLITTING) {
            setSlittingDialogOpen(true)
        }
    }

    const [editState, setEditState] = useState<boolean>(false)
    
    const loadConfiguration = (customizationArray: any) => {
        configurationService.getConfiguration1({ contexts: "CUSTOMIZATION" })
            .then(res => {
                if (res?.status === HTTP_STATUS.OK) {
                    const filteredCustomizations = res?.data?.data[0]?.data.filter((option: any) => {
                        return customizationArray.includes(option.value);
                    });
                    setCustomizationTo(filteredCustomizations);
                    setShearingCustomization(filteredCustomizations?.find((attr: any) => attr.value === CUSTOMIZTION_TYPE.SHEARING));
                    setSlittingCustomization(filteredCustomizations?.find((attr: any) => attr.value === CUSTOMIZTION_TYPE.SLITTING));
                }
            })
            .catch((error) => {
                console.error('Error', error);
                setCustomizationTo([]);
            });
    }

    useEffect(() => {
        const customizationArray = ["SHEARING", "SLITTING"]
            loadConfiguration(customizationArray);
    }, []);

    const previousQuantity = useRef<number>(0);

    useEffect(() => {
        if (isNaN(quantity) || quantity === null) return
        const quantityDifference = quantity - (isNaN(previousQuantity.current) ? 0 : previousQuantity.current);
        setBalanceWeight(prevBalanceWeight => (prevBalanceWeight === null ? 0 : prevBalanceWeight) + quantityDifference);
        previousQuantity.current = quantity;
    }, [quantity]);


    const isButtonDisabled = (item: ICustomization) => {
        if (selectedUpc?.attributes?.SPECIFICATION !== undefined) {
            const thicknessSpec = selectedUpc?.attributes.SPECIFICATION.find((attr: any) => attr.name.trim().toLowerCase() === 'thickness')?.attributeOptions;
            const thickness = thicknessSpec && typeof thicknessSpec === 'string' ? parseFloat(thicknessSpec) : NaN;

            const lengthSpec = selectedUpc?.attributes.SPECIFICATION.find((attr: any) => attr.name.trim().toLowerCase() === 'length')?.attributeOptions;
            const length = lengthSpec && typeof lengthSpec === 'string' ? parseFloat(lengthSpec) : NaN;

            const widthSpec = selectedUpc?.attributes.SPECIFICATION.find((attr: any) => attr.name.trim().toLowerCase() === 'width')?.attributeOptions;
            const width = widthSpec && typeof widthSpec === 'string' ? parseFloat(widthSpec) : NaN;
            const isWarehouseDetailsAvailable = true;

            if (item.value === CUSTOMIZTION_TYPE.SHEARING && shearingCustomization) {
                if (lengthSpec === "COIL") {
                    return thickness === null || length === null || width === null ||
                        thickness < (shearingCustomization?.minThickness) || thickness > (shearingCustomization?.maxThickness) ||
                        width < (shearingCustomization?.minWidth) || width > (shearingCustomization?.maxWidth) || 
                        !isWarehouseDetailsAvailable ||
                        isNaN(quantity) || quantity <= 0;
                } else {
                    return thickness === null || length === null || width === null ||
                        thickness < (shearingCustomization?.minThickness) || thickness > (shearingCustomization?.maxThickness) ||
                        length < (shearingCustomization?.minLength) || length > (shearingCustomization?.maxLength) ||
                        width < (shearingCustomization?.minWidth) || width > (shearingCustomization?.maxWidth) ||
                        !isWarehouseDetailsAvailable ||
                        isNaN(quantity) || quantity <= 0;
                }
            } else if (item.value === CUSTOMIZTION_TYPE.SLITTING && slittingCustomization) {
                return thickness === null || width === null ||
                    thickness < (slittingCustomization?.minThickness) || thickness > (slittingCustomization?.maxThickness) ||
                    width < (slittingCustomization?.minWidth) || width > (slittingCustomization?.maxWidth) ||
                    !isWarehouseDetailsAvailable ||
                    lengthSpec !== "COIL" ||
                    (isNaN(quantity) || quantity <= 0);
            } else if (item.value === CUSTOMIZTION_TYPE.BLANKING) {
                return true;
            } else if (item.value === CUSTOMIZTION_TYPE.METAL_OFFSET_PRINTING) {
                return true;
            }
        } else if (selectedUpc?.attributes?.SPECIFICATION === undefined) {
            return true
        }
        return false;
    }

    const handleCustomizationPopUp = () => {
        setIsCustomizationOpen(false)
    }

    const handleDialogClose = () => {
        setIsCustomizationOpen(false);
    };

    return (
        <div className="grid gap-y-6">
            <Dialog
                fullWidth
                className={`${classes.box}`}
                PaperProps={{ style: { height: 'auto' } }}
                open={isCustomizationOpen}
                onClose={() => setIsCustomizationOpen(false)}
            >
                <div>
                <div className="flex justify-between cursor-pointer" onClick={handleDialogClose}>
                        <span className={`text-2xl font-semibold ${classes.text}`}>Select Product for VAS</span>
                        <CloseIcon className={classes.closeIcon} />
                    </div>
               
                <span className={`${classes.header} text-lg font-bold`}>{SELECT_VAS_CUSTOMIZATION.SELECT_CUSTOMIZATION}</span>
                <div className={`${classes.mainContainer} grid gap-3`}>
                    {customization.map((item: ICustomization) => (
                        <div className={`${classes.container} flex flex-col gap-y-3 rounded-xl w-full`} key={item.value}>
                            <div>
                                <span className={`${classes.cardHeading} font-semibold`}>{item.name}</span>
                                <p className={`${classes.cardDescription} text-sm font-normal`}>{item.discription}</p>
                            </div>
                            <div>
                                <ButtonV2
                                    className={`!px-3 leading-none`}
                                    variant="tertiaryContained"
                                    label="Select"
                                    size="large"
                                    onClick={() => handleSelectCustomization(item.value)}
                                    disabled={isButtonDisabled(item)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                </div>
            </Dialog>

            {shearingDialogOpen && (
                <ShearingCustomizationPopup
                    dialogOpen={shearingDialogOpen}
                    setDialogOpen={setShearingDialogOpen}
                    selectedUpc={selectedUpc}
                    selectedWeight={quantity}
                    setVasDetails ={setVasDetails}
                    vasDetails={vasDetails}
                    customization={shearingCustomization}
                    valueAddedService={valueAddedService}
                    setValueAddedServiceTo={setValueAddedServiceTo}
                    balanceWeight={balanceWeight}
                    setBalanceWeight={setBalanceWeight}
                    handleCustomizationPopUp={handleCustomizationPopUp}
                />
            )}

            {slittingDialogOpen && (
                <SlittingCustomizationPopup
                    dialogOpen={slittingDialogOpen}
                    setDialogOpen={setSlittingDialogOpen}
                    selectedUpc={selectedUpc}
                    selectedWeight={quantity}
                    setVasDetails ={setVasDetails}
                    vasDetails={vasDetails}
                    customization={slittingCustomization}
                    valueAddedService={valueAddedService}
                    setValueAddedServiceTo={setValueAddedServiceTo}
                    balanceWeight={balanceWeight}
                    setBalanceWeight={setBalanceWeight}
                    editState={editState}
                    setEditState={setEditState}
                    handleCustomizationPopUp={handleCustomizationPopUp}
                />
            )}
        </div>
    );
};
export default SelectCustomizationTemplate;