import { FormikProps } from 'formik';
import React, { useState } from 'react'
import { createUseStyles } from 'react-jss';
import { IStatutoryDetailsForm } from '../../pages/Dashboard/Statutory/StatutoryDetail.page';
import UploadCertificatesDocumentTemplate from './UploadCertificatesDocument.template';
import UploadBankDetailsTemplate from './UploadBankDetails.template';
import ButtonV2 from '../../atoms/ButtonV2';
import { useNavigate } from 'react-router-dom';
import { ADMIN_ROUTES } from '../../../utils/constant';

export interface IStatutoryDetailProps {
    formik: FormikProps<IStatutoryDetailsForm>;
}
const useStyles = createUseStyles((theme: any) => ({
    titleText: {
        color: theme.palette.textV2.secondary.secondary800
    }
}));

const StatutoryDetailTemplate: React.FC<IStatutoryDetailProps> = ({ formik }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleBackToDashboard = () => {
        navigate(ADMIN_ROUTES.CUSTOMER_MANAGEMENT_LIST);
    };
    return (
        <div className='grid gap-6 justify-items-stretch'>
            <div className={`${classes.titleText} font-semibold text-lg`}>Statutory Documents</div>
            <UploadCertificatesDocumentTemplate formik={formik} />
            <UploadBankDetailsTemplate formik={formik} />
            <div className=' grid justify-items-stretch'>
                <ButtonV2
                    className={` justify-self-end`}
                    variant="secondaryContained"
                    label="Back to Dashboard"
                    onClick={handleBackToDashboard}
                />
            </div>
        </div>
    )
}
export default StatutoryDetailTemplate