import { useMemo, useState } from 'react';
import noData from '../../../assets/images/noDataImage.svg';
import BusinessProfileNoDataTemplate from './BusinessProfileNoData.template';
import OtherInformationUpdateTemplate from './OtherInformationUpdate.template';
import { IBusinessProfile } from '../../pages/CustomerManagement/ProfileOverview.page';
import OtherInformationViewTemplate from './OtherInformationView.template';

interface IOtherInformationTemplateProps {
    businessProfile: IBusinessProfile | null;
    updateBusinessProfileSection: (section: string, values: any) => void;
}

const OtherInformationTemplate: React.FC<IOtherInformationTemplateProps> = ({ businessProfile, updateBusinessProfileSection }) => {

    const [isEditing, setIsEditing] = useState(false);

    const OtherInformationSectionView = useMemo(() => {
        if (isEditing) {
            return (
                <OtherInformationUpdateTemplate
                    businessProfile={businessProfile}
                    updateBusinessProfileSection={updateBusinessProfileSection}
                    setIsEditing={setIsEditing} />
            );
        }
        if (businessProfile?.dateOfEstablishment != null) {
            return (
                <OtherInformationViewTemplate
                    setIsEditing={setIsEditing}
                    businessProfile={businessProfile}
                />
            );
        }
        return (
            <BusinessProfileNoDataTemplate
                heading="You haven't provided Other Information for your business."
                setIsEditing={setIsEditing}
                noDataImg={noData}
                label="Add Other Information"
            />
        );
    }, [isEditing, businessProfile, updateBusinessProfileSection]);

    return <>{OtherInformationSectionView}</>;
};

export default OtherInformationTemplate;
