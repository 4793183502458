import React, { useMemo, useState } from 'react'
import { FormikProps } from 'formik';
import ButtonV2 from '../../atoms/ButtonV2';
import { KEY_PERSON_DETAIL_STATES } from '../../../utils/types';
import { IkeyDetailForm } from '../../pages/CustomerManagement/KeyPersonDetails.page';
import GetNumberOfKeyPersonDetails from './GetNumberOfKeyPersonDetails.template';
import { HeaderKeyPersonDetailsTemplate } from './HeaderKeyPersonDetails.template';
import AddNewKeyPersonDetilsTemplate from './AddNewKeyPersonDetails.template';

export interface IKeyDetailProps {
    formik: FormikProps<IkeyDetailForm>;
}

export interface PocSectionProps {
    setCurrentSectionTo: (section: KEY_PERSON_DETAIL_STATES) => void;
    formik: FormikProps<IkeyDetailForm>;
}

const KeyPersonDetailsTemplate: React.FC<IKeyDetailProps> = ({ formik }) => {

    const [currentSection, setCurrentSection] = useState<KEY_PERSON_DETAIL_STATES>(KEY_PERSON_DETAIL_STATES.VIEW_KEY_PERSON_DETAILS);

    const keyDetailProps: PocSectionProps = {
        setCurrentSectionTo: setCurrentSection,
        formik: formik
    };

    const secondaryInventorySectionView = useMemo(() => {
        switch (currentSection) {
            case KEY_PERSON_DETAIL_STATES.ADD_KEY_PERSON_NO:
                return <GetNumberOfKeyPersonDetails {...keyDetailProps} />;
            case KEY_PERSON_DETAIL_STATES.ADD_KEY_PERSON_DETAILS:
                return <AddNewKeyPersonDetilsTemplate {...keyDetailProps} />;
            default:
                return formik.values?.pocs?.length > 0 ? (
                    <HeaderKeyPersonDetailsTemplate {...keyDetailProps} />
                ) : null;
        }
    }, [currentSection, formik]);

    return (
        <div>
            {formik.values.newPocs.length === 0 && (
                <div className='grid justify-items-stretch'>
                    {currentSection !== KEY_PERSON_DETAIL_STATES.ADD_KEY_PERSON_NO && (
                        <ButtonV2
                            variant="tertiaryContained"
                            label="Add New Key Person Details"
                            size='medium'
                            className={` justify-self-end`}
                            onClick={() => { setCurrentSection(KEY_PERSON_DETAIL_STATES.ADD_KEY_PERSON_NO) }}
                        />
                    )}
                </div>
            )}
            {secondaryInventorySectionView}
        </div>
    )
}
export default KeyPersonDetailsTemplate;