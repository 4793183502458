import React, { useEffect } from 'react'
import { IInstantQuotation } from '../../pages/ManageQuotation/QuoteCreate.page';
import { FormikProps } from 'formik';
import { createUseStyles } from 'react-jss';
import TextFieldV2 from '../../atoms/TextFieldV2';

export interface IGstTaxDetailProps {
    formik: FormikProps<IInstantQuotation>;
    isEditable: boolean;
    isSameStateGST: boolean;
}

const useStyles = createUseStyles((theme: any) => ({
    text: {
        color: theme.palette.textV2.tertiary.tertiary900
    }
}));

const particulars = [
    'Products',
    'Services',
    'Charges',
];

export const GstTaxDetailsTemplate: React.FC<IGstTaxDetailProps> = ({ formik, isEditable, isSameStateGST }) => {
    const classes = useStyles();

    useEffect(() => {
        particulars?.forEach((label, index) => {
            formik.setFieldValue(`gstDetails[${index}].particulars`, label);
        });
    }, []);

    return (
        <div>
            <span className={`text-base font-medium ${classes.text}`}>GST Tax Details</span>
            {particulars.map((label, index) => {
                
                    return <div className='grid gap-4 mb-3' key={index}>
                    <div className='flex gap-4'>
                        <div className='flex flex-col gap-3'>
                            <span>Particular Details</span>
                            <div className='flex gap-3 items-end'>
                                <TextFieldV2
                                    name={`gstDetails[${index}].particulars`}
                                    value={formik?.values?.gstDetails[index]?.particulars || label}
                                    onChange={formik.handleChange}
                                    disabled
                                />
                                <TextFieldV2
                                    label="Taxable Amount"
                                    type='number'
                                    placeholder='Enter Taxable Amount'
                                    name={`gstDetails[${index}].taxableAmount`}
                                    value={formik?.values?.gstDetails[index]?.taxableAmount || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                />
                            </div>
                        </div>
                        {isSameStateGST && <div className='flex flex-col gap-3'>
                            <span>CGST Details</span>
                            <div className='flex gap-3'>
                                <TextFieldV2
                                    label="CGST Rate"
                                    placeholder='Enter CGST Rate'
                                    name={`gstDetails[${index}].cgstRate`}
                                    value={formik?.values?.gstDetails[index]?.cgstRate || ''}
                                    onChange={formik.handleChange}
                                    inputProps={{ min: 0 }}
                                    disabled={!isEditable || !isSameStateGST}
                                />
                                <TextFieldV2
                                    label="CGST Amount"
                                    placeholder='Enter CGST Amount'
                                    name={`gstDetails[${index}].cgstAmount`}
                                    value={formik?.values?.gstDetails[index]?.cgstAmount || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                />
                            </div>
                        </div>}
                        {isSameStateGST && <div className='flex flex-col gap-3'>
                            <span>SGST Details</span>
                            <div className='flex gap-3'>
                                <TextFieldV2
                                    label="SGST Rate"
                                    placeholder='Enter SGST Rate'
                                    name={`gstDetails[${index}].sgstRate`}
                                    value={formik?.values?.gstDetails[index]?.cgstRate || ''}
                                    onChange={formik.handleChange}
                                    disabled

                                />
                                <TextFieldV2
                                    label="SGST Amount"
                                    placeholder='Enter SGST Amount'
                                    name={`gstDetails[${index}].sgstAmount`}
                                    value={formik?.values?.gstDetails[index]?.sgstAmount || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                />
                            </div>
                        </div>}
                        {!isSameStateGST && <div className='flex flex-col gap-3'>
                            <span>IGST Details</span>
                            <div className='flex gap-3' >
                                <TextFieldV2
                                    label="IGST Rate"
                                    placeholder='Enter IGST Rate'
                                    name={`gstDetails[${index}].igstRate`}
                                    value={formik?.values?.gstDetails[index]?.igstRate || ''}
                                    onChange={formik.handleChange}
                                    inputProps={{ min: 0 }}
                                    disabled={!isEditable || isSameStateGST}
                                />
                                <TextFieldV2
                                    label="IGST Amount"
                                    placeholder='Enter IGST Amount'
                                    name={`gstDetails[${index}].igstAmount`}
                                    value={formik?.values?.gstDetails[index]?.igstAmount || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                />
                            </div>
                        </div>}
                    </div>
                    <div className='flex  gap-4'>
                        <div className='flex flex-col gap-3' >Total {label} Tax Amount
                            <TextFieldV2
                                label={`Total ${label} Tax Amount`}
                                placeholder='Enter Total Tax Amount'
                                name={`gstDetails[${index}].totalTaxAmount`}
                                value={formik?.values?.gstDetails[index]?.totalTaxAmount || ''}
                                onChange={formik.handleChange}
                                disabled
                            />
                        </div>
                    </div>
                </div>
            })}
            <div className='py-3'>
                <TextFieldV2
                    label="Grand Total Price"
                    placeholder='Grand'
                    type="number"
                    value={formik?.values?.totalCharges?.totalTaxAmount || ''}
                    InputProps={{ readOnly: true }}
                    fullWidth
                    disabled
                />
            </div>
        </div>
    )
}
