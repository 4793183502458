import { Route, Routes } from 'react-router-dom';
import MasterUpcListingPage from '../../../components/pages/MasterUPCListingPage/MasterUpcListing.page';
import UpcListingPage from '../../../components/pages/UpcListing/UpcListing.page';
const UPCManagementRouter = () => {
  return (
    <div>
      <Routes>
        <Route path="" element={<UpcListingPage />} />
        <Route path="/:id" element={<MasterUpcListingPage />} />
      </Routes>
    </div>
  )
}
export default UPCManagementRouter