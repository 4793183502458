import React, { useEffect, useState } from 'react'
import { FormikProps } from 'formik';
import { createUseStyles } from 'react-jss';
import { HTTP_STATUS, PRODUCT_SERVICE_FORM_STATE } from '../../../../utils/types';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { ServicePayload } from '../../../../services/useVASMasterCategoryService';
import ButtonV2 from '../../../atoms/ButtonV2';
import { Attribute, useAttributeServiceV2 } from '../../../../services/useAttributeServiceV2';
import { Checkbox } from '@mui/material';

interface AttributeManagementFormTemplateProps {
    mode: string;
    onClose: () => void;
    formik: FormikProps<ServicePayload>;
    setFormStateTo: (state: PRODUCT_SERVICE_FORM_STATE) => () => void;
}

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
    },
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    previousProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary600
    },
    activeProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary400
    },
    inActiveProgressBar: {
        backgroundColor: theme.palette.backgroundV2.tertiary.tertiary100
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    checkBox: {
        color: theme.palette.textV2.tertiary.tertiary300,
        '&.Mui-checked': {
            color: theme.palette.textV2.primary.primary400,
        },
    },
    value: {
        color: theme.palette.textV2.primary.primary950
    },
    errorMessage: {
        color: theme.palette.action.danger,
    }
}));

const AttributeManagementForm1Template: React.FC<AttributeManagementFormTemplateProps> = ({ mode, formik, setFormStateTo }) => {
    const classes = useStyles();
    const attributeService = useAttributeServiceV2();
    const [attribute, setAttribute] = useState<Attribute[] | null>(null);
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const loadAttribute = () => {
        attributeService.getAllAttributes({ page: 0, size: 10, sort: 'created_at,desc', status: 'ACTIVE' })
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setAttribute(res?.data?.data)
            }).catch((error) => {
                console.error("Error Fetching Attribute: ", error);
                showSnackbar('error', "Error while fetching Attribute data");
            })
    }

    useEffect(() => {
        loadAttribute()
    }, [])

    const [selectedAttributes, setSelectedAttributes] = useState<Attribute[]>(formik.values?.attributes);
    const handleAttributeChange = (item: Attribute) => (event: React.ChangeEvent<HTMLInputElement>) => {
        let updatedAttributes = [...selectedAttributes];
        if (event.target.checked) {
            if (!updatedAttributes.some(attr => attr.id === item.id)) {
                updatedAttributes.push(item);
            }
        } else {
            updatedAttributes = updatedAttributes.filter(attr => attr.id !== item.id);
        }
        setSelectedAttributes(updatedAttributes);
        formik.setFieldValue("attributes", updatedAttributes);
    };

    const handleNext = () => {
        formik.setTouched({ superService: true, mainService: true, parentId: true, name: true, description: true, attributes: true }, true)
        const errors = Object.keys(formik.errors).find(key => ['superService', 'mainService', 'parentId', 'name', 'description', "attributes"].includes(key))
        if (errors) return;
        setFormStateTo(PRODUCT_SERVICE_FORM_STATE.ATTRIBUTE_MANAGEMENT_FORM_2)();
    }

    return (
        <div className="grid gap-y-8" >
            {SnackBarComponent}

            <div className={`w-full m-auto flex justify-between gap-x-2 text-center text-sm `}>
                <div className="grid gap-y-3 w-1/3">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.previousProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto text-sm`}>Service Category Details</div>
                    </div>
                    <div className={`${classes.previousProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/3">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} text-sm my-auto font-semibold`}>Attribute Management</div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/3">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.inActiveProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} text-sm my-auto`}>Review</div>
                    </div>
                    <div className={`${classes.inActiveProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>

            <div className='grid gap-y-4'>

                <div className='flex justify-between'>
                    <div className='grid gap-y-0.5'>
                        <div className={`${classes.mainHeading} text-base font-medium`}>Attribute Management</div>
                        <div className={`${classes.barHeading} text-xs`}>Please select the attributes for the service category you want</div>
                    </div>
                    <div className={`${classes.mainHeading} text-base font-medium`}>
                        Part 1/2
                    </div>
                </div>

                <div className={`${classes.border} grid gap-y-4 p-6 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Select the attributes you want to link with this service</div>
                    <div className='grid'>
                        <div className='flex flex-wrap gap-x-8'>
                            {attribute && attribute.map((item: any) => (
                                <div className="flex gap-x-2" key={item.id}>
                                    <div className=''>
                                        <Checkbox
                                            sx={{ padding: 0, width: "18px", height: "18px", }}
                                            className={classes.checkBox}
                                            checked={selectedAttributes.some(attr => attr.id === item.id)}
                                            onChange={handleAttributeChange(item)}
                                        />
                                    </div>
                                    <div className={`${classes.value} my-auto text-base font-normal`}>{item.name}</div>
                                </div>
                            ))}
                        </div>
                        {formik.touched && formik.touched?.attributes && formik.errors?.attributes && (
                            <div className={`${classes.errorMessage}`}>
                                {/* <small>{formik.errors?.attributes}</small> */}
                                <small>
                                    {typeof formik.errors.attributes === 'string'
                                        ? formik.errors.attributes
                                        : Array.isArray(formik.errors.attributes)
                                            ? formik.errors.attributes.join(', ')
                                            : 'Invalid error format'
                                    }
                                </small>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="flex justify-end gap-4">
                <ButtonV2 variant="tertiaryContained" label='Back' onClick={setFormStateTo(PRODUCT_SERVICE_FORM_STATE.SERVICE_CATEGORY_DETAILS)} />
                <ButtonV2 variant="primaryContained" onClick={handleNext} label={"next"} />
            </div>
        </div>
    )
}

export default AttributeManagementForm1Template