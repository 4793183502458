import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { createUseStyles } from "react-jss";
import { Grid } from "@mui/material";
import { Charges } from "../../pages/Lead/QuotationView.page";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import PaymentInfoModalTemplate from "./PaymentInfoModal.template";
import { CLASS_TYPE } from "../../../utils/types";
import { useSecondaryLotsService } from "../../../services/useSecondaryLotsService";

interface MTOViewQuotationProps {
	quotationItem: any | null;
	charges: Charges | null;
	classType: CLASS_TYPE;
}

const useStyles = createUseStyles((theme: any) => ({
	container: {
		background: theme.palette.background.secondaryLight
	},
	productDetailContainer: {
		border: `1px solid ${ theme.palette.background.primaryGrey }`,
		boxShadow: `0px 4px 45px 1px ${theme.palette.boxShadow.primaryLight}`,
	},
	paymentContainer: {
		boxShadow: `0px 1px 4px 0px ${theme.palette.boxShadow.primaryDarkAccent}`,
		text: theme.palette.text.darkGrey,
	},
	mainHeading: {
		color: theme.palette.text.primaryDarkLight,
	}
}));

const MTOQuotationViewTemplate: React.FC<MTOViewQuotationProps> = ({ quotationItem, charges, classType}) => {
	const classes = useStyles();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedQuotation, setSelectedQuotation] = useState<number | null>(null);
	const handleOpenModal = (quotationId: number) => {
		setSelectedQuotation(quotationId);
		setIsModalOpen(true);
	};

	const handleCloseModal = () => {
		setSelectedQuotation(null);
		setIsModalOpen(false);
	};

	const secondaryLotsService = useSecondaryLotsService();
	const [productUpcData, setProductUpcData] = useState<any>([]);

	const fetchAllSecondaryProduct = async (catalogueNames: string[]) => {
		const data = { catalogueNames };
		try {
			const response = await secondaryLotsService.getSecondaryLotsByName(data);
			if (response?.status === 200 && response?.data?.data) {
				setProductUpcData(response?.data?.data);
			} else {
				console.error("Failed to fetch secondary product upc data:", response);
			}
		} catch (error) {
			setProductUpcData([]);
		}
	};
	
	useEffect(() => {
		if (quotationItem?.length) {
			const catalogueNames = quotationItem.map((quotation: any) => quotation.catalogueName);
			fetchAllSecondaryProduct(catalogueNames);
		}
	}, [quotationItem]);

	function calculateProfitLossPercentage(basePrice: number, offerededPrice: number) {
		return (((offerededPrice - basePrice) / basePrice) * 100);
	}

	return (
		<div className="grid gap-y-3 ">
			{quotationItem && quotationItem?.map((quotation: any, index: any) => (
					<div key={ quotation?.id }>
						<div className={ `${ classes.container } ${ classes.mainHeading } flex justify-between p-3` }>
							<div className="text-xl font-semibold">
								Catalogue Name : {quotation?.catalogueName}
							</div>
							<div className="flex justify-between gap-x-6">
								<div className="font-semibold text-base">
									Require Order Quantity : {quotation?.quantity} /MT
								</div>
								
								<RemoveRedEyeIcon className="cursor-pointer" onClick={() => handleOpenModal(quotation.id)} />
								{isModalOpen && selectedQuotation === quotation.id && (
									<PaymentInfoModalTemplate
										open={isModalOpen}
										charges={charges}
										upcSkuEditDetails={quotation.skuData}
										onClose={handleCloseModal}
									/>
								)}			
							</div>
						</div>

						<div className={ `${ classes.productDetailContainer } bg-white p-3 flex justify-between gap-x-3`}>
							{productUpcData && productUpcData.filter((item: any) => quotation.catalogueName === item?.catalogueName).map((item:any, index:any) => (
								<div className="grid gap-y-2 w-1/4 mt-0 m-auto">
									<img className="rounded w-full" src={item?.catalogueImages[0]?.path} alt="productImage" />
									<div className={`${classes.container} grid grid-cols-3 gap-x-3 rounded bg-gray-300 h-fit p-3 w-full mt-0`}>
										{(Object.entries(item?.attributes) as [string, string[]][]).map(([attributeName, attributeValues], i) => (
											<div className="flex flex-col gap-y-1" key={attributeName}>
												<div className="text-gray-500 font-roboto text-sm font-normal">{attributeName}</div>
												<div className="text-gray-600 font-roboto text-sm font-medium flex">
													{attributeValues.length === 3 && attributeValues[0] === attributeValues[1]
														? attributeValues[2]
															? `${attributeValues[0]}-${attributeValues[2]}`
															: `${attributeValues[0]}`
														: attributeValues.filter((value: string) => value !== "").join(" - ")}
												</div>
											</div>
									    ))}
									</div>
								</div>
							))}
							
							<div className="w-3/4 mt-0 m-auto grid gap-y-3">
								{quotation.skuData &&
									quotation.skuData.map((sku:any, index:any) => {
										return (
											<div className={ `${ classes.productDetailContainer } grid gap-y-2 p-3 rounded bg-white` } key={ index }>
												<div className={ `flex justify-between gap-x-8 ${ classes.mainHeading }` }>
													<div className="text-base font-medium">
														SKU Code:{" "}
														<span className="font-normal">
															{ sku.skuCode }
														</span>
													</div>
													<div className="text-base font-medium">
														Warehouse:{" "}
														<span className="font-normal">
															{ sku.warehouse }
														</span>
													</div>
													<div className="text-base font-medium">
														BasePrice:{" "}
														<span className="font-normal">
															{ sku.basePrice }
														</span>
													</div>
												</div>
												<div className=" flex justify-between gap-x-2">
													<div className="flex w-1/4  ">
														<div className="w-full">
															<TextField
																required
																type="number"
																name="quantity"
																id="outlined-basic"
																label=" Enter Quantity"
																variant="outlined"
																fullWidth
																className="pointer-events-none"
																value={ sku?.quantity }
															/>
														</div>
														<div className={ `h-full rounded py-3 px-5 text-2xl ${ classes.container }` }>MT</div>
													</div>
													<div className="flex w-1/4 ">
														<div className="w-full">
															<TextField
																required
																type="number"
																name="profit"
																id="outlined-basic"
																label="Price /MT"
																variant="outlined"
																fullWidth
																className="pointer-events-none"
																value={ sku?.price }
															/>
														</div>
														<div className={ `h-full rounded py-3 px-5 text-2xl  ${ classes.container }` }>%</div>
													</div>
													<div className="w-1/4 ">
														<TextField
															required
															name="price"
															id="outlined-basic"
															label="Profit % /MT"
															variant="outlined"
															fullWidth
															className="pointer-events-none"
															value={ calculateProfitLossPercentage(sku?.basePrice, sku?.price).toFixed(2) }
														/>
													</div>
													<div className="w-1/4 ">
														<TextField
															name="packaging"
															id="outlined-basic"
															label="Packaging"
															variant="outlined"
															fullWidth
															className="pointer-events-none"
															value={ sku?.packaging }
														/>
													</div>
												</div>
											</div>
										);
									})}
							</div>
						</div>
					</div>
				))}


			<div className={` ${ classes.paymentContainer } grid rounded p-3 bg-white gap-4` }>
				<div className={` ${ classes.mainHeading } text-2xl font-semibold border-b-2 border-solid pb-3` } >
					Additional Charges
				</div>

				<Grid container spacing={ 2 }>
					<Grid item xs={ 12 } sm={ 6 } md={ 4 }>
						<TextField
							type="number"
							name="warehouseCharges"
							id="outlined-basic"
							label="Warehouse Charges /MT"
							variant="outlined"
							fullWidth
							className="pointer-events-none"
							value={ charges?.warehouseCharges ?? 0 }
						/>
					</Grid>
					<Grid item xs={ 12 } sm={ 6 } md={ 4 }>
						<TextField
							type="number"
							name="handlingCharges"
							id="outlined-basic"
							label="Handling Charges /MT"
							variant="outlined"
							value={ charges?.handlingCharges ?? 0 }
							className="pointer-events-none"
							fullWidth
						/>
					</Grid>
					<Grid item xs={ 12 } sm={ 6 } md={ 4 }>
						<TextField
							type="number"
							name="packagingCharges"
							id="outlined-basic"
							label="Packaging Charges /MT"
							variant="outlined"
							value={ charges?.packagingCharges ?? 0 }
							className="pointer-events-none"
							fullWidth
						/>
					</Grid>
					<Grid item xs={ 12 } sm={ 6 } md={ 4 }>
						<TextField
							type="number"
							name="otherCharges"
							id="outlined-basic"
							label="Other Charges"
							variant="outlined"
							className="pointer-events-none"
							value={ charges?.otherCharges ?? 0 }
							fullWidth
						/>
					</Grid>
					<Grid item xs={ 12 } sm={ 6 } md={ 4 }>
						<TextField
							type="number"
							name="gst"
							id="outlined-basic"
							label="GST %"
							variant="outlined"
							value={ charges?.gst ?? 0 }
							className="pointer-events-none"
							fullWidth
						/>
					</Grid>
					<Grid item xs={ 12 } sm={ 6 } md={ 4 }>
						<TextField
							type="number"
							name="tcs"
							id="outlined-basic"
							label="TCS %"
							variant="outlined"
							value={ charges?.tcs ?? 0 }
							className="pointer-events-none"
							fullWidth
						/>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

export default MTOQuotationViewTemplate;