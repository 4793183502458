import React, { useState } from "react";
import { AUTH_STATES, HTTP_STATUS } from "../../../utils/types";
import { useAuthService } from "../../../services/useAuthService";
import { InputAdornment } from '@mui/material';
import { validatePhoneNumber } from "../../../utils/helper";
import { Classes } from 'jss';
import Button from "../../atoms/Button";
import TextField from "../../atoms/TextField";
import { useSnackbar } from "../../../hooks/useSnackBar";
import AuthFooter from "../../molecules/AuthFooter/AuthFooter";

interface LoginWithMobileTemplateProps {
    mobileNumber: string;
    setAuthState: (state: AUTH_STATES) => void;
    setMobileNumber: (state: string) => void;
    styles: Classes<any>;
}

const LoginWithMobileTemplate: React.FC<LoginWithMobileTemplateProps> = ({ setAuthState, mobileNumber, setMobileNumber, styles }) => {
    
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const authService = useAuthService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length > 10) return;
        setMobileNumber(event.target.value);
        if (!validatePhoneNumber(event.target.value))
            setErrorMessage('Please enter a valid 10-digit phone number');
        else setErrorMessage('');
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (errorMessage) return;
        if (mobileNumber.length !== 10) 
            return setErrorMessage('Please enter a valid phone number');            
        
        setIsLoading(true)
        authService.login({ mobileNumber })
            .then((res => {
                if (res?.status === HTTP_STATUS.BAD_REQUEST) 
                    setErrorMessage('Please enter a registered phone number');
                else if (res?.status === HTTP_STATUS.OK) 
                    setAuthState(AUTH_STATES.OTP_VERIFICATION)
                else showSnackbar('error', 'Server not responding, Please try again later')
            })).catch(error => {
                console.error("Error in LoginWithMobile:", error)
            }).finally(() => setIsLoading(false))
    };

    const setAuthStateTo = (authState: AUTH_STATES) => () => setAuthState(authState);
    
    return (
        <div className="auth-right-child-container p-10 flex flex-col h-full">
            {SnackBarComponent}
            <div className="form-header mb-9">
                <h1 className="text-3xl font-bold sm:text-fs-24 md:text-fs-24">Login With OTP</h1>
                <p className="text-base font-normal sm:text-sm md:text-sm">Please enter your registered number</p>
            </div>
            <div className="form-container flex flex-col grow h-full">
                <form className="flex flex-col gap-y-5 mb-4" onSubmit={handleSubmit}>
                    <TextField
                        label="Phone Number"
                        variant="outlined"
                        type="tel"
                        placeholder="Enter Your Phone Number"
                        value={mobileNumber}
                        onChange={handlePhoneNumberChange}
                        error={!!errorMessage}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                        }}
                        helperText={errorMessage}
                    />  
                    <Button type="submit" variant="contained" label='Get OTP' isLoading={isLoading}/>
                </form>
                <AuthFooter setAuthStateTo={setAuthStateTo} loginType={AUTH_STATES.LOGIN_WITH_EMAIL} isLogin={true}/>
            </div>
        </div>
    );
}

export default LoginWithMobileTemplate;