import { Tab, Box } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import React, { ReactNode } from 'react'

export interface ITabsSchema {
    label: string, 
    component: ReactNode
}

interface TabsProps {
    schema: ITabsSchema[],
    value: string,
    setValue: (value: any) => void;
}

const Tabs:React.FC<TabsProps> = ({schema, value, setValue}) => {
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(parseInt(newValue)-1);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {schema.map((tab, index) => (
                <Tab label={tab['label']} value={(index+1).toString()} key={index}/>
            ))}
          </TabList>
        </Box>
        {schema.map((tab, index) => (
                <TabPanel className={"!px-0 !py-4"} value={(index+1).toString()} key={index}>{tab['component']}</TabPanel>
            ))}
      </TabContext>
    </Box>
  );
}

export default Tabs