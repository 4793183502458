
// import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
    Button,
  } from "@mui/material";

import { useContext, useEffect, useState } from "react";
import React from "react";
import CatalogueFilter from './CatalogueFilters';
import SecondaryCatalogueFilters from './SecondaryCatalogueFilters';
import InventoryFilters from './InventoryFilters';

export const FILTERS_TYPE = {
  CATALOGUE: 1,
  SECONDARY_CATALOGUE: 2,
  INVENTORY: 3,
}

const FiltersIndex = ({ filtersData, filterType, updateFilters}) => {

  const [open, setOpen] = React.useState(false);
  const [activeComponent, setActiveComponent] = React.useState(1);

  const handleClose = () => {
    setOpen(prev => !prev);
  };

  let activeContent = null;

  if (filterType === FILTERS_TYPE.CATALOGUE) {
    activeContent = <CatalogueFilter updateFilters={updateFilters} filtersData={filtersData} onClose={handleClose}/>;
  }
  if (filterType === FILTERS_TYPE.SECONDARY_CATALOGUE) {
    activeContent = <SecondaryCatalogueFilters updateFilters={updateFilters} filtersData={filtersData} onClose={handleClose}/>;
  }
  if (filterType === FILTERS_TYPE.INVENTORY) {
    activeContent = <InventoryFilters updateFilters={updateFilters} filtersData={filtersData} onClose={handleClose}/>;
  }

  return (
    <div className="h-full">
    <Button
      className='h-full'
      variant="outlined"
      color="primary"
      startIcon={<FilterListIcon />}
      onClick={handleClose}
    >
      Filters
    </Button>
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      sx={{
        width: '23%',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '23%',
          boxSizing: 'border-box',
        },
      }}
    >
      {activeContent}
    </Drawer>
    </div>
  );
}

export default FiltersIndex;



