import { createUseStyles } from "react-jss";
import ButtonV2 from "../../atoms/ButtonV2";

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.primarylightAccent}`,
    },
    title: {
        color: theme.palette.text.primaryLight,
    },
    property: {
        color: theme.palette.textV2.tertiary.tertiary700
    }
}));

interface IAboutUsViewTemplateProps {
    setIsEditing: (value: boolean) => void;
    description: string;
}

const AboutUsViewTemplate: React.FC<IAboutUsViewTemplateProps> = ({ setIsEditing, description }) => {

    const classes = useStyles();

    return (
        <div className={`${classes.container} p-6 rounded-lg w-full`}>
            <div className='flex justify-between items-center pb-6 border-b border-grey-500'>
                <h2 className={`${classes.title} text-2xl font-medium`}>About Us</h2>
                <ButtonV2 variant={'tertiaryContained'} label={'Edit'} onClick={() => setIsEditing(true)} />
            </div>
            <div className='rounded-lg mt-3'>
                <p className={`font-medium text-base ${classes.property}`}>{description}</p>
            </div>
        </div>
    )
}

export default AboutUsViewTemplate;
