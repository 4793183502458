import React, { useState } from 'react';
import CustomDatePicker from '../../../../Shared/CustomDateTimePicker/CustomDatePicker';
import { AUCTION } from '../../../../Constants/Procurement/LiveAuction/text';
import { Button } from '@mui/material';
import CustomTimePicker from '../../../../Shared/CustomDateTimePicker/CustomTimePicker';

interface ReScheduleModalProps {
  isEnd?: boolean;
  value?: Date;
  onChange: (value: Date) => void;
  handleBack?: () => void;
}

const ReScheduleModal = ({
  value,
  isEnd,
  onChange,
  handleBack,
}: ReScheduleModalProps) => {
  const [date, setDate] = useState<Date | undefined>(value);
  const [time, setTime] = useState<Date | undefined>(value || new Date());
  const handleDate = () => {
    if (date && time) {
      const combinedDateTime = new Date(date);
      combinedDateTime.setHours(
        time.getHours(),
        time.getMinutes(),
        time.getSeconds()
      );
      onChange(combinedDateTime);
    }
  };
  return (
    <div className="flex flex-col gap-y-6 pt-6 items-center">
      <CustomDatePicker
        value={date}
        onChange={(value) => setDate(value)}
        minDate={new Date()}
      />

      <div>
        <div className="text-blue font-bold mb-1">
          {isEnd ? AUCTION.AddEndTime : AUCTION.AddStartTime}
        </div>
        <CustomTimePicker value={time} onChange={(val) => setTime(val)} />
        <div className="flex justify-end gap-x-4 mt-4">
          {isEnd ? (
            <Button
              onClick={handleBack}
              variant={'outlined'}
              className="border w-[104px] border-blue text-blue h-12 normal-case rounded hover:bg-white"
            >
              {AUCTION.Back}
            </Button>
          ) : (
            ''
          )}
          <Button
            onClick={handleDate}
            className="rounded h-12 normal-case text-white bg-blue hover:bg-blueHover w-[104px]"
          >
            {isEnd ? AUCTION.Done : AUCTION.Next}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReScheduleModal;
