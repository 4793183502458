import React, { useState } from 'react';
import { INDENT_TEXT } from '../../../Constants/Procurement/Indent/constants';
import { createUseStyles } from 'react-jss';
import { Comment } from '../../../Constants/Procurement/Indent/types';
import CustomCheckBox from '../../../Shared/CustomInputs/CustomCheckBox';

interface RemarkComponentProps {
  type: 'MANAGER' | 'USER';
  moduleType: 'auction' | 'indent';
  uploadedComments: Comment[];
  localComments?: any[];
  onResolve?: (id: string) => void;
  onAddNew?: (commment: string) => void;
  onRemove?: (id: string, uploaded: boolean) => void;
}

const useStyles = createUseStyles({
  reviewCheckbox: {
    '& input[type="checkbox"]': {
      width: '50px',
      height: '50px',
    },
  },
});

const RemarkComponent: React.FC<RemarkComponentProps> = ({
  type,
  moduleType,
  uploadedComments,
  localComments,
  onResolve,
  onAddNew,
  onRemove,
}) => {
  const classes = useStyles();
  const [commentData, setCommentData] = useState<string>();
  const [commentError, setCommentError] = useState<boolean>(false);
  // manager will add the comment.
  const addComment = () => {
    if (commentData && onAddNew) {
      onAddNew(commentData);
      setCommentData('');
    } else {
      setCommentError(true);
    }
  };
  // user will resolve the comment.
  const handleResolve = (id: string) => {
    if (id && onResolve) {
      onResolve(id);
    }
  };

  const handleDelete = (id: string, uploaded: boolean) => {
    if (onRemove && id) {
      onRemove(id, uploaded);
    }
  };
  return (
    <>
      <div
        className={`flex flex-col gap-4 justify-center ${classes.reviewCheckbox}`}
      >
        {/* FOR USER THIS WILL NOT BE VISIBLE */}
        <div className="text-[5B5B5B] font-normal text-base">
          {INDENT_TEXT.RemarkComponentheading}{' '}
          {type === 'USER' ? 'from Manager' : ''}
        </div>
        {/* adding component for manager*/}
        <div className="flex flex-row gap-4 justify-center h-[50px] items-stretch">
          <div className="">
            <CustomCheckBox
              // className="h-[50px] w-[full] border-text-color rounded-4px"
              height="50px"
              width="50px"
              disabled={true}
              checked={false}
              name="commentCheckbox"
            />
          </div>
          <div className={`w-full`}>
            <textarea
              style={{ resize: 'none', height: '50px' }}
              className={`w-full border p-2 ${
                commentError === true
                  ? 'border-red-500'
                  : 'border-gray  text-base text-text-color'
              }`}
              onChange={(e) => {
                setCommentError(false);
                setCommentData(e.target.value);
              }}
              onClick={(e) => {
                setCommentError(false);
              }}
              value={commentData}
              disabled={type === 'USER'}
            />
          </div>
          <div
            className="rounded-[4px] bg-blue font-semibold h-[50px] w-[50px] cursor-pointer text-white flex justify-center items-center text-3xl"
            onClick={() => addComment()}
          >
            +
          </div>
        </div>
        {/* Past Reviews added */}
        {/* VISIBLE FOR USER AND MANAGER */}
        {uploadedComments &&
          uploadedComments.map((comment, id) => (
            <div
              className="flex flex-row gap-4 justify-center h-[50px] items-stretch"
              key={id}
            >
              <div className="">
                <CustomCheckBox
                  height="50px"
                  width="50px"
                  handleChange={() => {
                    handleResolve(comment.id);
                  }}
                  // disabled={type === 'MANAGER'}
                  checked={comment.resolved}
                  name="commentCheck"
                />
              </div>
              <div className={`w-full`}>
                <textarea
                  disabled={true}
                  style={{ resize: 'none', height: '50px' }}
                  className={`w-full border p-2`}
                  value={comment.commentText}
                  readOnly={true}
                />
              </div>
              <div
                className={`rounded-[4px] bg-blue font-semibold h-[50px] w-[50px] cursor-pointer text-white flex justify-center items-center text-3xl ${
                  type === 'USER' ? 'hidden' : ''
                }`}
                onClick={() => handleDelete(comment.id, true)}
              >
                -
              </div>
            </div>
          ))}
        {localComments &&
          localComments.map((comment, id) => (
            <div
              className="flex flex-row gap-4 justify-center h-[50px] items-stretch"
              key={id}
            >
              <div className="">
                <CustomCheckBox
                  height="50px"
                  width="50px"
                  // className="h-[50px] w-full border-text-color rounded-4px p-2"
                  handleChange={() => {
                    handleResolve(comment.id);
                  }}
                  // disabled={type === 'MANAGER'}
                  checked={false}
                  name="commentCheck2"
                />
              </div>
              <div className={`w-full`}>
                <textarea
                  disabled={true}
                  style={{ resize: 'none', height: '50px' }}
                  className={`w-full border p-2`}
                  value={comment.commentText}
                  readOnly={true}
                />
              </div>
              <div
                className="rounded-[4px] bg-blue font-semibold h-[50px] w-[50px] cursor-pointer text-white flex justify-center items-center text-3xl"
                onClick={() => handleDelete(comment.id, false)}
              >
                -
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default RemarkComponent;
