import React from "react";
import { createUseStyles } from "react-jss";

interface GroupDescriptionPreviewTemplateProps {
    formik: any;
}

interface ChipProps {
    value: string;
};

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid #EBEFFA`
    },
    filterContainer: {
        borderTop: `1px solid #EBEFFA`
    },
    filterHeading: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    label: {
        color: theme.palette.textV2.tertiary.tertiary600,
        fontSize: "14px",
        fontWeight: 400,
    },
    configure: {
        color: theme.palette.textV2.primary.primary500,
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600,
    },
    chipBackgroundColor: {
        backgroundColor: theme.palette.textV2.tertiary.tertiary50,
    },
    activeProgressBar: {
        backgroundColor: "#FD6202"
    },
    inActiveProgressBar: {
        backgroundColor: "#EBEFFA"
    },
    input: {
        "& .MuiInputBase-root": {
            borderRadius: "8px !important",
        },
    },
    errorMessage: {
        color: theme.palette.textV2.failure.failure600,
    },
}));

const GroupDescriptionPreviewTemplate: React.FC<GroupDescriptionPreviewTemplateProps> = ({ formik }) => {
    const classes = useStyles();
    const Chip = ({ value }: ChipProps) => {
        return (
            <div className={`${classes.chipBackgroundColor} py-1.5 px-3 rounded-lg text-xs font-medium h-min`}>
                {value}
            </div>
        );
    };

    return (
        <div className={`${classes.container} p-6 grid gap-y-6 rounded-lg`}>
            <div className="flex gap-x-6">
                <div className="grid content-start gap-y-2 w-44">
                    <div className={`${classes.label} text-base`}>Group Name</div>
                    <div className="flex flex-wrap gap-2">
                        <Chip value={formik.values.groupName} />
                    </div>
                </div>
                <div className="grid content-start gap-y-2 w-44">
                    <div className={`${classes.label} text-base`}>Main Product Category</div>
                    <div className="flex flex-wrap gap-2">
                        <Chip value={formik.values.productCategory.label} />
                    </div>
                </div>
            </div>
            <div className="flex gap-x-6 ">
                <div className="grid gap-y-2">
                    <div className={`${classes.label} text-base`}>Description</div>
                    <div className={`grid py-4 px-4 rounded-lg border text-sm leading-5 font-medium`}>
                        {formik.values.description}
                    </div>
                </div>
            </div>
            <div className="flex gap-x-6 ">
                <div className="grid content-start gap-y-2 w-44">
                    <div className={`${classes.label} text-base`}>Group Type</div>
                    <div className="flex flex-wrap gap-2">
                        <Chip value={formik.values.groupType} />
                    </div>
                </div>
                <div className="grid content-start gap-y-2 w-44">
                    <div className={`${classes.label} text-base`}>Industry Type</div>
                    <div className="flex flex-wrap gap-2">
                        <Chip value={formik.values.industryType} />
                    </div>
                </div>
                <div className="grid content-start gap-y-2 w-44">
                    <div className={`${classes.label} text-base`}>Group Owner</div>
                    <div className="flex flex-wrap gap-2">
                        <Chip value={formik.values.groupOwner.label} />
                    </div>
                </div>

            </div>
        </div>
    );
};

export default GroupDescriptionPreviewTemplate;