import React, { useEffect, useMemo, useState } from 'react'
import viewEyeIcon from "../../../../assets/images/viewOutlined.svg";
import { ADMIN_ROUTES, MODE, initialPaginationValues, sortOptions, statusOptions } from '../../../../utils/constant';
import { enumToString, makeRoute } from '../../../../utils/helper';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { HTTP_STATUS, IPagination, VAS_MASTER_CATEGORY_TYPE } from '../../../../utils/types';
import { useNavigate } from 'react-router-dom';
import { ColumnType } from '../../../organisms/Table';
import moment from 'moment';
import TableV2 from '../../../organisms/TableV2';
import ButtonV2 from '../../../atoms/ButtonV2';
import TextFieldV2 from '../../../atoms/TextFieldV2';
import { IServiceRequestParams, IServiceSearchParams, Service, Services, useVASMasterCategoryService } from '../../../../services/useVASMasterCategoryService';
import FilterChipV2 from '../../../molecules/FilterChip/FilterChipV2';
import { IMultiSelectOption } from '../../../atoms/MultiSelectInput2';
import FilterChipV3 from '../../../molecules/FilterChip/FilterChipV3';
import { createUseStyles } from 'react-jss';
import ResourceStatusV2 from '../../../atoms/ResourceStatus/ResourceStatusV2';

interface ServicesListTableTemplateProps {
    level: number
}

const initialServicesFiltersValues = {
    name: "",
    status: "ALL",
    sort: "createdAt,desc",
}

const initialUscRequestFilters: IServiceRequestParams = {
    superServiceIds: [],
    mainServiceIds: [],
    serviceCategoryIds: [],
};

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.textV2.primary.primary950,
    },
}));

const categoryColumns = [
    [
        { label: "Super Service ID", key: "serviceCode", type: "string" as ColumnType, props: { className: '' } },
        { label: "Super Service", key: "name", type: "string" as ColumnType, props: { className: '' } }
    ],
    [
        { label: "Main Service ID", key: "serviceCode", type: "string" as ColumnType, props: { className: '' } },
        { label: "Main Service", key: "name", type: "string" as ColumnType, props: { className: '' } },
        { label: "Super Service", key: "parentService", type: "string" as ColumnType, props: { className: '' } }
    ],
    [
        { label: "Service Category ID", key: "serviceCode", type: "string" as ColumnType, props: { className: '' } },
        { label: "Service Category", key: "name", type: "string" as ColumnType, props: { className: '' } },
        { label: "Main Service", key: "parentService", type: "string" as ColumnType, props: { className: '' } },
        { label: "Super Service", key: "superParentService", type: "string" as ColumnType, props: { className: '' } }
    ]
]

const categoryRecord = ((service: Services, level: number) => [
    [service.serviceCode, service.name],
    [service.serviceCode, service.name, service.parentService],
    [service.serviceCode, service.name, service.parentService, service.superParentService]
][level])

const VASMasterCategoriesListTableTemplate: React.FC<ServicesListTableTemplateProps> = ({ level }) => {
    const classes = useStyles();
    const vasMasterCategoryService = useVASMasterCategoryService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [filters, setFilters] = useState(initialServicesFiltersValues);
    const [serviceRequestFilters, setServiceRequestFiltersTo] = useState<any>(initialUscRequestFilters);
    const [pagination, setPagination] = useState<IPagination>(initialPaginationValues);
    const [tableContent, setTableContent] = useState<Services[] | []>([]);
    const navigate = useNavigate();

    const fetchTableContent = async (params: any) => {
        const payload: IServiceRequestParams = {
            superServiceIds: serviceRequestFilters?.superServiceIds?.length > 0 ? serviceRequestFilters?.superServiceIds : null,
            mainServiceIds: serviceRequestFilters?.mainServiceIds?.length > 0 ? serviceRequestFilters?.mainServiceIds : null,
            serviceCategoryIds: serviceRequestFilters?.serviceCategoryIds?.length > 0 ? serviceRequestFilters?.serviceCategoryIds : null,
        };
        await vasMasterCategoryService.getAllService(params,
            payload)
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    const { totalElements, totalPages } = res?.data?.data;
                    setPagination({
                        ...pagination,
                        totalPages: totalPages,
                        totalRecords: totalElements
                    });
                    setTableContent(res?.data?.data?.content)
                }
            }).catch(error => {
                showSnackbar('error', error);
                setTableContent([]);
            })
    }

    const handleFiltersChange = (name: string, value: any) => {
        setPagination({ ...pagination, currentPage: 0 });
        setFilters({ ...filters, [name]: value ?? "" });
    };

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPagination((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage
        }));
    };

    const handleActionRoute = (id: number, mode: string) => () => {
        navigate(makeRoute(ADMIN_ROUTES.VAS_MASTER_CATEGORIES_VIEW(id), { query: { mode } }));
    }

    const Action = (id: number) => (
        <div>
            <ButtonV2 variant='primaryText' size='small' label={"View"} startIcon={<img src={viewEyeIcon} alt={viewEyeIcon} />} onClick={handleActionRoute(id, MODE.VIEW)} />
        </div>
    )

    useEffect(() => {
        const params: IServiceSearchParams = {
            level,
            name: filters.name.trim(),
            status: filters.status === "ALL" ? "" : filters.status,
            page: pagination.currentPage,
            size: pagination.pageSize,
            sort: filters.sort
        }
        fetchTableContent(params)
    }, [level, filters, pagination.currentPage, pagination.pageSize, serviceRequestFilters])

    const getRecords = () => tableContent?.map((service: Services, index) => [
        pagination.currentPage * pagination.pageSize + index + 1,
        ...categoryRecord(service, service.level),
        moment(service.createdAt).format("DD-MM-YYYY HH:mm:ss"),
        service.createdBy,
        enumToString(service.status),
        Action(service.id)
    ])

    const getTableColumns = () => [
        { label: "Sr No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
        ...categoryColumns[level],
        { label: "Created On", key: "createdAt", type: "datetime" as ColumnType, props: { className: '' } },
        { label: "Created By", key: "createdBy", type: "string" as ColumnType, props: { className: '' } },
        { label: "Status", key: "status", component: ({ value }: { value: string }) => <ResourceStatusV2 status={value} />, type: "custom" as ColumnType, props: {} },
        { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } },
    ]

    const getSchema = () => ({
        id: "1",
        pagination: {
            total: pagination.totalRecords,
            currentPage: pagination.currentPage,
            isVisible: true,
            limit: pagination.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange
        },
        columns: getTableColumns() ?? []
    })

    const handleAddCategory = () => {
        navigate(makeRoute(ADMIN_ROUTES.VAS_MASTER_CATEGORIES_CREATE, { query: { type: Object.keys(VAS_MASTER_CATEGORY_TYPE).filter(key => isNaN(parseInt(key)))[level] } }));
    }

    const initialSelections = {
        status: [{ label: 'ALL', value: 'ALL' }],
        sortDate: [{ label: 'createdAt,desc', value: 'createdAt,desc' }]
    };
    const initialServiceRequestFilters: any = {
        superServiceIds: [],
        mainServiceIds: [],
        serviceCategoryIds: [],
    };

    const [superServiceData, setSuperServiceData] = useState<Service[] | null>(null);
    const [mainServiceData, setMainServiceData] = useState<Service[] | null>(null);
    const [productServiceData, setProductServiceData] = useState<Service[] | null>(null);
    const [status, setStatus] = useState<IMultiSelectOption[]>(initialSelections.status);
    const [sortDate, setSortDate] = useState<IMultiSelectOption[]>(initialSelections.sortDate);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedfilters, setSelectedFilters] = useState<any>(initialServiceRequestFilters);

    const fetchSuperServices = (inputValue?: string) => {
        vasMasterCategoryService.getAllService({ level: VAS_MASTER_CATEGORY_TYPE.SUPER_SERVICE, page: 0, size: 10, sort: 'createdAt,desc', name: inputValue ?? '', status: 'ACTIVE' }, {})
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setSuperServiceData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Service: ", error);
                showSnackbar('error', "Error while fetching Super Service data");
            })
    }

    const fetchMainServices = (inputValue?: string) => {
        vasMasterCategoryService.getAllService({ level: VAS_MASTER_CATEGORY_TYPE.MAIN_SERVICE, page: 0, size: 10, sort: 'createdAt,desc', name: inputValue ?? '', status: 'ACTIVE' }, {})
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setMainServiceData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Service: ", error);
                showSnackbar('error', "Error while fetching Super Service data");
            })
    }

    const fetchProductServices = (inputValue?: string) => {
        vasMasterCategoryService.getAllService({ level: VAS_MASTER_CATEGORY_TYPE.SERVICE_CATEGORY, page: 0, size: 10, sort: 'createdAt,desc', name: inputValue ?? '', status: 'ACTIVE' }, {})
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setProductServiceData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Service: ", error);
                showSnackbar('error', "Error while fetching Super Service data");
            })
    }

    useEffect(() => {
        fetchSuperServices(searchTerm);
    }, [searchTerm]);

    useEffect(() => {
        fetchMainServices(searchTerm);
    }, [searchTerm]);

    useEffect(() => {
        fetchProductServices(searchTerm);
    }, [searchTerm]);

    const superServiceOptions = useMemo(() => {
        return superServiceData?.map((mainService: Service) => ({ label: mainService.name, value: mainService.id })) ?? []
    }, [superServiceData])

    const mainServiceOptions = useMemo(() => {
        return mainServiceData?.map((mainService: Service) => ({ label: mainService.name, value: mainService.id })) ?? []
    }, [mainServiceData])

    const productServiceOptions = useMemo(() => {
        return productServiceData?.map((mainService: Service) => ({ label: mainService.name, value: mainService.id })) ?? []
    }, [productServiceData])



    const handleSelectionChangeStatus = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setStatus(selected);
        clearWithCrossIcon && handleStatusSaveClick(selected);
    };

    const handleSelectionChangeSort = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setSortDate(selected);
        clearWithCrossIcon && handleSortSaveClick(selected);
    };

    const handleSelectionChange = (name: string, selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setSelectedFilters((previousSelectedFilters: any) => ({
            ...previousSelectedFilters,
            [name]: selected
        }));
        clearWithCrossIcon && handleDeleteClick(name, selected);
    };

    const handleStatusSaveClick = (status: IMultiSelectOption[]) => {
        const valueString = status.length > 0 ? status[0].value : 'ALL';
        handleFiltersChange("status", valueString);
    };

    const handleSortSaveClick = (sortDate: IMultiSelectOption[]) => {
        const sortString = sortDate.length > 0 ? sortDate[0].value : 'createdAt,desc';
        handleFiltersChange("sort", sortString);
    };

    const handleSaveClick = (name: string, selectedValues: IMultiSelectOption[]) => {
        const updatedFilters = { ...serviceRequestFilters };
        const newValues = selectedValues.map(option => option.value);

        if (updatedFilters[name]) {
            updatedFilters[name] = Array.from(new Set([...updatedFilters[name], ...newValues]));
        } else {
            updatedFilters[name] = newValues;
        }
        setServiceRequestFiltersTo(updatedFilters);
    };

    const handleDeleteClick = (name: string, selectedValues: IMultiSelectOption[]) => {
        const selectedValueIds = selectedValues.map(option => option.value);
        const updatedFilters = {
            ...serviceRequestFilters,
            [name]: serviceRequestFilters[name].filter((id: number) => selectedValueIds.includes(id))
        };
        setServiceRequestFiltersTo(updatedFilters);
    };

    const handleClearClick = (filterType: string) => {
        switch (filterType) {
            case 'status':
                setStatus([{ label: 'ALL', value: 'ALL' }]);
                handleFiltersChange("status", 'ALL');
                break;
            case 'sortDate':
                setSortDate([{ label: 'createdAt,desc', value: 'createdAt,desc' }]);
                handleFiltersChange("sort", 'createdAt,desc');
                break;
            case 'superServiceIds':
                setSelectedFilters((previousSelectedFilters: any) => ({
                    ...previousSelectedFilters,
                    superServiceIds: []
                }));
                setServiceRequestFiltersTo((prevState: any) => ({
                    ...prevState,
                    superServiceIds: []
                }));
                break;
            case 'mainServiceIds':
                setSelectedFilters((previousSelectedFilters: any) => ({
                    ...previousSelectedFilters,
                    mainServiceIds: []
                }));
                setServiceRequestFiltersTo((prevState: any) => ({
                    ...prevState,
                    mainServiceIds: []
                }));

                break;
            case 'serviceCategoryIds':
                setSelectedFilters((previousSelectedFilters: any) => ({
                    ...previousSelectedFilters,
                    serviceCategoryIds: []
                }));
                setServiceRequestFiltersTo((prevState: any) => ({
                    ...prevState,
                    serviceCategoryIds: []
                }));
                break;
            default:
                setStatus([{ label: 'ALL', value: 'ALL' }]);
                setSortDate([{ label: 'createdAt,desc', value: 'createdAt,desc' }]);
                setSelectedFilters(initialServiceRequestFilters);
        }
    };

    return (
        <div className='grid gap-y-3'>
            {SnackBarComponent}
            <div className='flex justify-between'>
                <div className={`${classes.heading} text-base font-medium my-auto`}>{`${level === VAS_MASTER_CATEGORY_TYPE.SUPER_SERVICE ? "Super Service" : level === VAS_MASTER_CATEGORY_TYPE.MAIN_SERVICE ? "Main Service" : "Service Category"}`}</div>
                <ButtonV2 label={`${level === VAS_MASTER_CATEGORY_TYPE.SUPER_SERVICE ? "+Add New Super Service" : level === VAS_MASTER_CATEGORY_TYPE.MAIN_SERVICE ? "+Add New Main Service" : "+Add New Service Category"}`} variant='primaryContained' onClick={handleAddCategory} />
            </div>
            <div className='flex justify-between mb-3'>
                <div className='flex gap-x-4 flex-wrap'>
                    <FilterChipV2
                        options={statusOptions}
                        label=" "
                        value={status}
                        onchange={handleSelectionChangeStatus}
                        placeholder="Status"
                        ClearButtonComponent={ButtonV2}
                        buttonLabel={"Clear all"}
                        buttonOnClick={() => handleClearClick('status')}
                        SaveButton={ButtonV2}
                        saveButtonLabel='Apply'
                        saveButtonOnClick={() => handleStatusSaveClick(status)}
                        isSingleSelect={true}
                    />
                    <FilterChipV2
                        options={sortOptions}
                        label=" "
                        value={sortDate}
                        onchange={handleSelectionChangeSort}
                        placeholder="Sort Date"
                        ClearButtonComponent={ButtonV2}
                        buttonLabel={"Clear all"}
                        buttonOnClick={() => handleClearClick('sortDate')}
                        SaveButton={ButtonV2}
                        saveButtonLabel='Apply'
                        saveButtonOnClick={() => handleSortSaveClick(sortDate)}
                        isSingleSelect={true}
                    />
                    {
                        level === VAS_MASTER_CATEGORY_TYPE.MAIN_SERVICE && <FilterChipV3
                            options={superServiceOptions}
                            label=" "
                            value={selectedfilters?.superServiceIds}
                            onchange={handleSelectionChange}
                            name="superServiceIds"
                            placeholder="Super Service"
                            ClearButtonComponent={ButtonV2}
                            textFieldPlaceholder='Search...'
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                            buttonLabel={"Clear all"}
                            buttonOnClick={() => handleClearClick('superServiceIds')}
                            SaveButton={ButtonV2}
                            saveButtonLabel='Apply'
                            saveButtonOnClick={() => handleSaveClick('superServiceIds', selectedfilters?.superServiceIds)}
                        />
                    }
                    {
                        level === VAS_MASTER_CATEGORY_TYPE.SERVICE_CATEGORY &&
                        <>
                            <FilterChipV3
                                options={superServiceOptions}
                                label=" "
                                value={selectedfilters?.superServiceIds}
                                onchange={handleSelectionChange}
                                name="superServiceIds"
                                placeholder="Super Service"
                                ClearButtonComponent={ButtonV2}
                                textFieldPlaceholder='Search...'
                                searchTerm={searchTerm}
                                setSearchTerm={setSearchTerm}
                                buttonLabel={"Clear all"}
                                buttonOnClick={() => handleClearClick('superServiceIds')}
                                SaveButton={ButtonV2}
                                saveButtonLabel='Apply'
                                saveButtonOnClick={() => handleSaveClick('superServiceIds', selectedfilters?.superServiceIds)}
                            />
                            <FilterChipV3
                                options={mainServiceOptions}
                                label=" "
                                value={selectedfilters?.mainServiceIds}
                                onchange={handleSelectionChange}
                                name="mainServiceIds"
                                placeholder="Main Service"
                                ClearButtonComponent={ButtonV2}
                                textFieldPlaceholder='Search...'
                                searchTerm={searchTerm}
                                setSearchTerm={setSearchTerm}
                                buttonLabel={"Clear all"}
                                buttonOnClick={() => handleClearClick('mainServiceIds')}
                                SaveButton={ButtonV2}
                                saveButtonLabel='Apply'
                                saveButtonOnClick={() => handleSaveClick('mainServiceIds', selectedfilters?.mainServiceIds)}
                            />
                        </>
                    }
                </div>
                <div className='w-[324px]'>
                    <TextFieldV2
                        className="w-full"
                        placeholder='Search Service'
                        type="text"
                        name="name"
                        value={filters.name}
                        onChange={(e) => handleFiltersChange('name', e.target.value)}
                    />
                </div>
            </div>

            <TableV2 schema={getSchema()} records={getRecords()} />
        </div>
    )
}

export default VASMasterCategoriesListTableTemplate