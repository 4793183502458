import React, { useEffect, useState } from 'react';
import CustomTab from '../../../Shared/CustomTabs/CustomTab';
import { FormControl } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import TableFilters from '../../../Components/Procurement/Indent/TableFilters';
import AuctionListTable from '../../../Components/Procurement/ReverseActionRFQ/AuctionListTable';
import {
  AUCTION_LIST_PAGINATION_TEXT,
  AUCTION_STATUS,
  REVERSE_AUCTION_TABS,
} from '../../../Constants/Procurement/ReverseAuctionRFQ/constants';
import { useNavigate } from 'react-router-dom';
import ListPaginationButton from '../../../Components/Procurement/Indent/ListPaginationButton';
import { usePRContext } from '../../../../hooks/useProcurementContext';
import {
  AuctionEntity,
  RFQContextTypes,
} from '../../../Constants/Procurement/ReverseAuctionRFQ/types';
import { Auction } from './../../../Constants/Procurement/ReverseAuctionRFQ/types';
import NoDataFound from '../../../../components/atoms/NoDataFound/NoDataFound';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import { useReverseAuctionService } from '../../../../services/useReverseAuctionService';

const ReverseAuctionRFQ: React.FC = () => {
  const { user }: any = useAuthenticatedUser();
  const { RFQParams, setRFQParams } = usePRContext();

  const [tab, setTab] = useState<number>(
    RFQParams.isUserAuction ? 1 : RFQParams.isUserDrafts ? 2 : 0
  );
  const [startDate, setStartDate] = useState<Moment>();
  const [endDate, setEndDate] = useState<Moment>();
  const [toDate, setToDate] = useState<Moment>(moment.unix(RFQParams.toDate));
  const [fromDate, setFromDate] = useState<Moment>(
    moment.unix(RFQParams.fromDate)
  );
  const [auctionId, setAuctionId] = useState<string>(RFQParams.searchTerm);
  const [searchPage, setSearchPage] = useState<number>(RFQParams.pageNumber);
  const [auctionStatus, setAuctionStatus] = useState<string>(
    RFQParams.statuses
  );
  const [auctionList, setAuctionList] = useState<AuctionEntity[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(RFQParams.pageNumber);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const navigate = useNavigate();

  const { getAuctions, getIndentPlusAuction } = useReverseAuctionService();

  const openAuction = async (auctionId: string) => {
    try {
      const response = await getIndentPlusAuction(auctionId);
      if (response.data && response.data.data) {
        const params = {
          ...RFQParams,
          selectedAuction: response.data.data as Auction,
        };
        setRFQParams(params);

        if (params.selectedAuction?.auction?.status === 'INIT') {
          navigate(
            `/procurement/reverse-auction/reverse-auction-rfq/creating-reverse-auction`
          );
        } else {
          navigate(
            `/procurement/reverse-auction/reverse-auction-rfq/review-auction-details`
          );
        }
      }
    } catch (error) {
      console.log('Error while getting auction/indent details - \n', error);
    }
  };

  const searchHandler = (value: string) => {
    setAuctionId(value);
    setPageNumber(0);
    const params = {
      ...RFQParams,
      ...{
        pageNumber: 0,
        searchTerm: value,
      },
    };
    fetchAuctions(params);
  };

  const fetchAuctions = async (params: RFQContextTypes): Promise<void> => {
    setRFQParams(params);
    if (pageNumber >= 0) {
      const queryParams = {
        pageSize: params.pageSize,
        pageNumber: params.pageNumber,
        isUserAuctions: params.isUserAuction,
        auctionFromDate: params.auctionFromDate,
        auctionToDate: params.auctionToDate,
        fromDate: params.fromDate,
        isUserDrafts: params.isUserDrafts,
        searchTerm: params.searchTerm,
        statuses:
          params.statuses === 'All' ? '' : params.statuses.toUpperCase(),
        toDate: params.toDate,
      };

      try {
        const response = await getAuctions(queryParams);
        if (response?.data?.data?.content) {
          const content: AuctionEntity[] = response?.data?.data?.content;
          const auctions = content.map((auction) => {
            return {
              id: auction.id,
              auctionName: auction.auctionName,
              entityID: auction.entityID,
              startDateTime: auction.startDateTime,
              endDateTime: auction.endDateTime,
              status:
                AUCTION_STATUS.find((i) => i.value === auction.status)?.name ||
                '',
              createdBy: auction.createdBy,
              createdAt: auction.createdAt,
              purchaseRequistionCount: auction.purchaseRequistionCount,
              quantity: auction.quantity,
            };
          });
          setTotalRecords(response.data.data?.totalElements);
          setTotalPages(response.data.data.totalPages);
          setPageNumber(response.data.data.pageable.pageNumber);
          setAuctionList(auctions);
          setSearchPage(response.data.data.pageable.pageNumber);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setTotalRecords(0);
        setTotalPages(0);
        setPageNumber(0);
        setAuctionList([]);
        setSearchPage(0);
      }
    }
  };
  const handlePagination = (type: 'prev' | 'next' | 'search'): void => {
    if (type === 'prev') {
      if (pageNumber > 0) {
        setPageNumber((prev) => prev - 1);
        const params = {
          ...RFQParams,
          ...{
            pageNumber: pageNumber - 1,
          },
        };
        fetchAuctions(params);
      }
    } else if (type === 'next') {
      if (pageNumber < totalPages) {
        setPageNumber((prev) => prev + 1);
        const params = {
          ...RFQParams,
          ...{
            pageNumber: pageNumber + 1,
          },
        };
        fetchAuctions(params);
      }
    } else if (type === 'search') {
      if (searchPage >= 0 && pageNumber !== searchPage) {
        setPageNumber(searchPage);
        const params = {
          ...RFQParams,
          ...{
            pageNumber: searchPage,
          },
        };
        fetchAuctions(params);
      }
    }
  };

  const statusChangeHandler = (value: string) => {
    if (value === auctionStatus) return;
    setAuctionStatus(value);
    const params = {
      ...RFQParams,
      ...{
        pageNumber: 0,
        statuses: value,
      },
    };
    fetchAuctions(params);
  };

  useEffect(() => {
    let params = RFQParams;
    if (
      RFQParams.fromDate !== fromDate?.startOf('day').unix() ||
      RFQParams.toDate !== toDate?.endOf('day').unix() ||
      RFQParams.auctionFromDate !== startDate?.endOf('day').unix() ||
      RFQParams.auctionToDate !== endDate?.endOf('day').unix()
    ) {
      params = {
        ...RFQParams,
        pageNumber: 0,
        auctionFromDate: startDate?.startOf('day').unix(),
        auctionToDate: endDate?.endOf('day').unix(),
        fromDate: fromDate.startOf('day').unix(),
        toDate: toDate.endOf('day').unix(),
      };
    }
    fetchAuctions(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, startDate, endDate]);

  const handleTabChange = (tab: number) => {
    const params: RFQContextTypes = {
      ...RFQParams,
      pageNumber: 0,
      isUserAuction: tab === 1,
      isUserDrafts: tab === 2,
    };
    setTab(tab);
    setPageNumber(0);
    fetchAuctions(params);
  };

  const handleSearchPage = (pageNo: number) => {
    if (pageNo + 1 === pageNumber) return;
    const params = {
      ...RFQParams,
      pageNumber: pageNo,
    };
    setPageNumber(pageNo);
    fetchAuctions(params);
  };

  return (
    <div className="grid gap-y-3">
      <div className="flex justify-between mt-2">
        <CustomTab
          Tabs={REVERSE_AUCTION_TABS}
          tab={tab}
          setTab={handleTabChange}
        />
        <div className="flex gap-6">
          <div className="flex items-center gap-x-3">
            <div className="text-blue font-semibold text-base text-nowrap">
              Start Date
            </div>
            <FormControl sx={{ minWidth: '150px', maxWidth: '170px' }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                  }}
                  format="DD/MM/YYYY"
                  value={startDate}
                  name="createdDate"
                  onChange={(date) => {
                    if (date) {
                      setStartDate(date);
                    }
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </div>
          <div className="flex items-center gap-x-3">
            <div className="text-blue font-semibold text-base text-nowrap">
              End Date
            </div>
            <FormControl sx={{ minWidth: '150px', maxWidth: '170px' }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                  }}
                  format="DD/MM/YYYY"
                  value={endDate}
                  name="createdDate"
                  onChange={(date) => {
                    if (date) {
                      setEndDate(date);
                    }
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </div>
        </div>
      </div>
      <TableFilters
        selectLabel={'Action'}
        searchHandler={searchHandler}
        fromDateHandler={setFromDate}
        toDateHandler={setToDate}
        statusList={AUCTION_STATUS}
        statusChange={statusChangeHandler}
        fromDate={fromDate}
        searchText={auctionId}
        status={auctionStatus}
        toDate={toDate}
      />
      {auctionList.length <= 0 ? (
        <div className="py-32">
          <NoDataFound />
        </div>
      ) : (
        <>
          <AuctionListTable
            auctionList={auctionList}
            idHandler={openAuction}
            currentPage={pageNumber}
          />
          <ListPaginationButton
            PaginationText={AUCTION_LIST_PAGINATION_TEXT.showingItems(
              pageNumber,
              totalRecords
            )}
            pageNumber={pageNumber}
            handlePagination={handlePagination}
            searchPage={searchPage}
            setSearchPage={setSearchPage}
            totalPages={totalPages}
            totalRecords={totalRecords}
          />
        </>
      )}
    </div>
  );
};

export default ReverseAuctionRFQ;
