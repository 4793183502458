import Button from '@mui/material/Button';
import React, { useEffect, useState } from "react";

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import cancelIcon from "./../../../../Assets/Icons/Vector.svg";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from "axios";
import { useAuthenticatedUser } from '../../../../../hooks/useAuthenticatedUser';
const BaseUrl = process.env.REACT_APP_API_V1_URL;
const StandardsDisplayItems = ({ standardItems, handleCheckBoxSelection, checkedItem }) => {
    return (
        <div className="self-stretch flex flex-col items-start justify-start gap-[1px] text-base">
            {standardItems.map((item) => (
                <div key={item.id} className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[24px]">
                    <div className="flex flex-col items-start justify-start gap-[12px]">
                        <div className="flex flex-row items-center justify-start gap-[7px]">
                            <div className="flex flex-row items-start justify-start">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkedItem === item.id}
                                            onChange={() => handleCheckBoxSelection(item.id)}
                                            color="primary"
                                            size="small"
                                        />
                                    }
                                />
                            </div>
                            <div className="relative leading-[23px]">{item.label}</div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
const StandardSearchList = ({ onClose, updateStandard }) => {
    const { user } = useAuthenticatedUser();
    const items = [{ label: 'IS 13955', id: 1 }, { label: 'IS 12345', id: 2 }, { label: 'IS 56789', id: 3 }];
    const [standardOptions, setStandardOptions] = useState([]);
    const convertToOptions = (data) => {
        if (!Array.isArray(data)) {
            throw new Error('Input data should be an array.');
        }

        return data.map(item => {
            if (!item || typeof item !== 'object' || !item.hasOwnProperty('id') || !item.hasOwnProperty('name')) {
                throw new Error('Invalid item in data array.');
            }

            return {
                label: item.name,
                id: item.id
            };
        });
    };

    useEffect(() => {

        axios.get(`${BaseUrl}/standards`, {
            params: {
                search: ''
            },
            headers: {
                Authorization: `${user.token}`,
                "Content-Type": "application/json",
            }

        }).then(response => {
            setStandardOptions(convertToOptions(response.data.data.content));
        })
            .catch(error => {
                console.error('Error fetching super categories:', error);
            });
    }, []);

    const [checkedItem, setCheckedItem] = useState(0);

    // Function to handle checkbox changes
    const handleCheckBoxChange = (id) => {
        setCheckedItem(id);
    };

    const handleAddStandard = () => {
        // Find the selected standard based on the checkedItem
        const selectedStandard = standardOptions.find((item) => item.id === checkedItem);

        if (selectedStandard) {
            // Update the standard using the updateStandard function
            updateStandard('standardName', selectedStandard.label);
            updateStandard('standardId', selectedStandard.id);
        }

        // Close the dialog or perform any other necessary actions
        onClose();
    };

    return (
        <div className="relative bg-white w-full flex flex-col p-6 box-border items-start justify-start gap-[24px] text-left text-3xl text-text-color font-small-text">
            <div className="self-stretch flex flex-row pt-0 px-0 pb-[18px] items-center justify-start gap-[10px] border-b-[1px] border-solid border-input-border">
                <div className="flex-1 relative leading-[20px] font-semibold">
                    Select Standard
                </div>
                <button
                    className="w-30 h-21"
                    onClick={onClose}
                >
                    <img
                        className="relative w-5 h-5 overflow-hidden shrink-0 object-cover"
                        alt=""
                        src={cancelIcon}
                    />
                </button>
            </div>
            <StandardsDisplayItems standardItems={standardOptions} handleCheckBoxSelection={handleCheckBoxChange} checkedItem={checkedItem} />


            <div className="self-stretch flex flex-row items-center justify-end gap-[22px] text-mini text-text-color">
                <Button
                    sx={{
                        border: '1px solid blue',
                        padding: '0.71rem .63rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                    }}
                    variant="outlined"
                    onClick={onClose}
                >
                    <div className="relative leading-[1.38rem] font-semibold text-blue">Cancel</div>
                </Button>
                <button
                    className="outlined rounded bg-blue overflow-hidden flex flex-row py-[0.81rem] px-[1.63rem] items-center justify-center text-[0.94rem] text-white"
                    onClick={handleAddStandard}
                >
                    <div className="relative leading-[1.38rem] font-semibold">
                        Add Standard
                    </div>
                </button>
            </div>


        </div>
    );
};

export default StandardSearchList;
