import React, { useContext, useEffect, useState } from 'react';
import '../../../Components/Procurement/Indent/indent.css';
import {
  Procurement,
  FormData,
  ShipToLocation,
  BillToAddress,
} from '../../../Constants/Procurement/Indent/types';
import AddProductDetails from '../../../Components/Procurement/Indent/AddProductDetails';
import ReviewDetailPage from '../../../Components/Procurement/Indent/ReviewDetailPage';
import PurchaseRequisitionSelect from '../../../Components/Procurement/Indent/PurchaseRequisitionSelect';
import { WareHouseContext } from '../../../../contexts/WareHouseDetailContext';
import { useWarehouseService } from '../../../../services/useWarehouseService';

const AddIndent: React.FC = () => {
  const [step, setStep] = useState<number>(1);

  // formData
  const [formData, setFormData] = useState<FormData>({
    items: [],
    cess: '',
    gst: '',
    tcs: '',
    payment_terms: '',
    quality_check: '',
    purchase_type: '',
    tnc: '',
    transportation_mode: '',
    buyerGstApplicable: false,
    buyerTcsApplicable: false,
    buyerCessApplicable: false,
    warehouse_id: '',
  });

  // for document upload, in addProduct Details
  const [fileArray, setFileArray] = useState<File[]>([]);

  // selected procurement
  const [selectedPurchaseRequisition, setSelectedPurchaseRequisition] =
    useState<Procurement[]>([]);

  // bill to details here because need to used in Review Page
  const [billToAddress, setBillToAddress] = useState<BillToAddress>();
  // bill to details here because need to used in Review Page
  const [shipToData, setShipToData] = useState<ShipToLocation>();

  // handles step
  const handleNext = (): void => {
    if (step === 1) {
    }
    setStep((prev) => prev + 1);
  };

  const handleBack = (): void => {
    if (step === 3) {
      // Do something if needed
    }
    if (step === 2) {
      setFileArray([]);
    }
    setStep((prev) => prev - 1);
  };

  const { locations, warehouseDetails: shipToAddress } =
    useContext(WareHouseContext);

  const { getBillAddress } = useWarehouseService();

  const fetchBillToAddress = async () => {
    try {
      const billToResponse = await getBillAddress();
      if (billToResponse.data.data) {
        setBillToAddress(billToResponse.data.data);
      }
    } catch (e) {
      console.log('Error in fetchBillToAddress', e);
    }
  };

  useEffect(() => {
    fetchBillToAddress();
  }, [shipToAddress]);

  return (
    <div className="grid">
      {step === 1 && (
        <PurchaseRequisitionSelect
          handleNext={handleNext}
          selectedPurchaseRequisition={selectedPurchaseRequisition}
          setSelectedPurchaseRequisition={setSelectedPurchaseRequisition}
          locations={locations}
        />
      )}
      {step === 2 && (
        <AddProductDetails
          handleNext={handleNext}
          handleBack={handleBack}
          formData={formData}
          setFormData={setFormData}
          fileArray={fileArray}
          wareHouseLocationList={locations}
          setFileArray={setFileArray}
          selectedPurchaseRequisition={selectedPurchaseRequisition}
          setSelectedPurchaseRequisition={setSelectedPurchaseRequisition}
          // ship to / bill to details
          shipToAddress={shipToAddress}
          billToAddress={billToAddress}
          setBillToAddress={setBillToAddress}
          shipToData={shipToData}
          setShipToData={setShipToData}
        />
      )}
      {step === 3 && (
        <ReviewDetailPage
          handleBack={handleBack}
          selectedPurchaseRequisition={selectedPurchaseRequisition}
          formData={formData}
          fileArray={fileArray}
          billToAddress={billToAddress}
          shipToData={shipToData}
        />
      )}
    </div>
  );
};

export default AddIndent;
