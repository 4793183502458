import React from 'react'
import { IInstantQuotation, IInstantQuotationDeliveryDTO, IInstantQuotationPaymentInfo, IInstantQuotationPaymentTerms } from '../../pages/ManageQuotation/QuoteCreate.page';
import { FormikErrors, FormikProps } from 'formik';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import TextFieldV2 from '../../atoms/TextFieldV2';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { createUseStyles } from 'react-jss';

export interface IDeliveryInformationProps {
    formik: FormikProps<IInstantQuotation>;
    isEditable: boolean;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.primaryDark}`,
    },
    errorMessage: {
		color: theme.palette.action.danger,
	},
    field: {
        "& .MuiInputBase-root" :{
            height: '150px'
        }
    },
    datePicker: {
        border: "1px solid",
        borderColor: "#DDD",
        fontSize: "16px",
        fontWeight: 500,
        borderRadius: 8,
        "&:hover": {
            borderColor: "#FD6202",
            borderWidth: 2,
            outline: "none",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: "#DDD",
        },
        "&:focus-within": {
            outline: "none",
            borderColor: `#FD6202 !important`,
            borderWidth: "2px !important",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: `#FD6202 !important`,
        },
    },
}));

export const DeliveryInformationTemplate: React.FC<IDeliveryInformationProps> = ({ formik, isEditable }) => {

    const classes = useStyles();

    return (
        <div className='grid gap-6'>
            <div>
                <span>Delivery Information</span>
                <div className='flex pt-3 gap-3'>
                    <div className=' w-1/6'>
                        <TextFieldV2
                            {...formik.getFieldProps("deliveryInformation.timeFrame")}
                            className=" text-base font-medium"
                            type="text"
                            label="Delivery Time Frame"
                            placeholder="Enter Data"
                            fullWidth
                            required
                            disabled={!isEditable}
                            error={formik.touched?.deliveryInformation?.timeFrame &&
                                Boolean((formik.errors?.deliveryInformation as FormikErrors<IInstantQuotationDeliveryDTO>)?.timeFrame)
                            }
                            helperText={formik.touched?.deliveryInformation?.timeFrame &&
                                Boolean((formik.errors?.deliveryInformation as FormikErrors<IInstantQuotationDeliveryDTO>)?.timeFrame) ? (formik.errors?.deliveryInformation as FormikErrors<IInstantQuotationDeliveryDTO>)?.timeFrame : ''}
                        />
                    </div>
                    <div className='flex flex-col'>
                        <label>Estimated Delivery Date</label>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                value={formik?.values?.deliveryInformation?.deliveryDate ? moment(formik.values.deliveryInformation?.deliveryDate, 'YYYY-MM-DD') : null}
                                format="DD-MM-YYYY"
                                onChange={(newValue) => {
                                    const formattedDate = moment(newValue).format('YYYY-MM-DD');
                                    formik.setFieldValue('deliveryInformation.deliveryDate', formattedDate);
                                }}
                                className={classes.datePicker}
                                disabled={!isEditable}
                            />
                        </LocalizationProvider>
                        {formik.touched && formik.touched?.deliveryInformation?.deliveryDate && formik.errors?.deliveryInformation?.deliveryDate && (
								<div className={classes.errorMessage}>
									<small>{(formik.errors?.deliveryInformation as FormikErrors<IInstantQuotationDeliveryDTO>)?.timeFrame}</small>
								</div>
							)}                        
                    </div>
                    <div className=' w-1/6'>
                        <TextFieldV2
                            {...formik.getFieldProps("deliveryInformation.deliveryMode")}
                            className=" text-base font-medium"
                            type="text"
                            label="Delivery Mode"
                            placeholder="Enter Data"
                            fullWidth
                            required
                            disabled={!isEditable}
                            error={formik.touched?.deliveryInformation?.deliveryMode &&
                                Boolean((formik.errors?.deliveryInformation as FormikErrors<IInstantQuotationDeliveryDTO>)?.deliveryMode)
                            }
                            helperText={formik.touched?.deliveryInformation?.deliveryMode &&
                                Boolean((formik.errors?.deliveryInformation as FormikErrors<IInstantQuotationDeliveryDTO>)?.deliveryMode) ? (formik.errors?.deliveryInformation as FormikErrors<IInstantQuotationDeliveryDTO>)?.deliveryMode : ''}

                        />
                    </div>
                    <div className=' w-1/6'>
                        <TextFieldV2
                            {...formik.getFieldProps("deliveryInformation.transportArrangement")}
                            className=" text-base font-medium"
                            type="text"
                            label="Transport Arrangement"
                            placeholder="Enter Data"
                            fullWidth
                            required
                            disabled={!isEditable}
                            error={formik.touched?.deliveryInformation?.transportArrangement &&
                                Boolean((formik.errors?.deliveryInformation as FormikErrors<IInstantQuotationDeliveryDTO>)?.transportArrangement)
                            }
                            helperText={formik.touched?.deliveryInformation?.transportArrangement &&
                                Boolean((formik.errors?.deliveryInformation as FormikErrors<IInstantQuotationDeliveryDTO>)?.transportArrangement) ? (formik.errors?.deliveryInformation as FormikErrors<IInstantQuotationDeliveryDTO>)?.transportArrangement : ''}

                        />
                    </div>
                    <div className=' w-1/6'>
                        <TextFieldV2
                            {...formik.getFieldProps("deliveryInformation.materialLifting")}
                            className=" text-base font-medium"
                            type="text"
                            label="Material Lifting"
                            placeholder="Enter Data"
                            fullWidth
                            required
                            disabled={!isEditable}
                            error={formik.touched?.deliveryInformation?.materialLifting &&
                                Boolean((formik.errors?.deliveryInformation as FormikErrors<IInstantQuotationDeliveryDTO>)?.materialLifting)
                            }
                            helperText={formik.touched?.deliveryInformation?.materialLifting &&
                                Boolean((formik.errors?.deliveryInformation as FormikErrors<IInstantQuotationDeliveryDTO>)?.materialLifting) ? (formik.errors?.deliveryInformation as FormikErrors<IInstantQuotationDeliveryDTO>)?.materialLifting : ''}

                        />
                    </div>
                    <div className=' w-1/6'>
                        <TextFieldV2
                            {...formik.getFieldProps("deliveryInformation.inco")}
                            className=" text-base font-medium"
                            type="text"
                            label="INCO/ Shipping Terms"
                            placeholder="Enter Data"
                            fullWidth
                            required
                            disabled={!isEditable}
                            error={formik.touched?.deliveryInformation?.inco &&
                                Boolean((formik.errors?.deliveryInformation as FormikErrors<IInstantQuotationDeliveryDTO>)?.inco)
                            }
                            helperText={formik.touched?.deliveryInformation?.inco &&
                                Boolean((formik.errors?.deliveryInformation as FormikErrors<IInstantQuotationDeliveryDTO>)?.inco) ? (formik.errors?.deliveryInformation as FormikErrors<IInstantQuotationDeliveryDTO>)?.inco : ''}

                        />
                    </div>
                </div>
            </div>
            <div className='grid'>
            <div className='w-full'>
                <span>Payment Terms</span>
                <div className='flex py-3 gap-3'>
                    <div className=' w-1/2'>
                        <TextFieldV2
                            {...formik.getFieldProps("paymentTerms.paymentTerms")}
                            className=" text-base font-medium"
                            type="text"
                            label="Payment Terms"
                            placeholder="Enter Data"
                            required
                            disabled={!isEditable}
                            error={formik.touched?.paymentTerms?.paymentTerms &&
                                Boolean((formik.errors?.paymentTerms as FormikErrors<IInstantQuotationPaymentTerms>)?.paymentTerms)
                            }
                            helperText={formik.touched?.paymentTerms?.paymentTerms &&
                                Boolean((formik.errors?.paymentTerms as FormikErrors<IInstantQuotationPaymentTerms>)?.paymentTerms) ? (formik.errors?.paymentTerms as FormikErrors<IInstantQuotationPaymentTerms>)?.paymentTerms : ''}
                        />
                    </div> <div className=' w-1/2'>
                        <TextFieldV2
                            {...formik.getFieldProps("paymentTerms.paymentMethods")}
                            className=" text-base font-medium"
                            type="text"
                            label="Payment Methods"
                            placeholder="Enter Data"
                            required
                            disabled={!isEditable}
                            error={formik.touched?.paymentTerms?.paymentMethods &&
                                Boolean((formik.errors?.paymentTerms as FormikErrors<IInstantQuotationPaymentTerms>)?.paymentMethods)
                            }
                            helperText={formik.touched?.paymentTerms?.paymentMethods &&
                                Boolean((formik.errors?.paymentTerms as FormikErrors<IInstantQuotationPaymentTerms>)?.paymentMethods) ? (formik.errors?.paymentTerms as FormikErrors<IInstantQuotationPaymentTerms>)?.paymentMethods : ''}
                        />
                    </div>
                </div>
            </div>
            <div className='w-full'>
                <span>Upfront Payment</span>
                <div className='flex py-3 gap-3'>
                    <div className=' w-1/3'>
                        <TextFieldV2
                            {...formik.getFieldProps("upfrontPaymentInformation.percentage")}
                            className=" text-base font-medium"
                            type="text"
                            label="Enter Percentage"
                            placeholder="Enter Data"
                            inputProps={{ min: 0 }}
                            required
                            disabled={!isEditable}
                            error={formik.touched?.upfrontPaymentInformation?.percentage &&
                                Boolean((formik.errors?.upfrontPaymentInformation as FormikErrors<IInstantQuotationPaymentInfo>)?.percentage)
                            }
                            helperText={formik.touched?.upfrontPaymentInformation?.percentage&&
                                Boolean((formik.errors?.upfrontPaymentInformation as FormikErrors<IInstantQuotationPaymentInfo>)?.percentage) ? (formik.errors?.upfrontPaymentInformation as FormikErrors<IInstantQuotationPaymentInfo>)?.percentage : ''}
                        />
                    </div>
                    <div className=' w-1/3'>
                        <TextFieldV2
                            {...formik.getFieldProps("upfrontPaymentInformation.amount")}
                            className=" text-base font-medium"
                            type="text"
                            label="Amount"
                            placeholder="Enter Data"
                            inputProps={{ min: 0 }}
                            required
                            disabled
                        />
                    </div>
                    <div className=' w-1/3'>
                        <div className='flex flex-col'>
                            <label>Due Date</label>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    value={formik?.values?.upfrontPaymentInformation?.dueDate ? moment(formik.values.upfrontPaymentInformation?.dueDate, 'YYYY-MM-DD') : null}
                                    format="DD-MM-YYYY"
                                    onChange={(newValue) => {
                                        const formattedDate = moment(newValue).format('YYYY-MM-DD');
                                        formik.setFieldValue('upfrontPaymentInformation.dueDate', formattedDate);
                                    }}
                                    className={classes.datePicker}
                                    disabled={!isEditable}
                                />
                            </LocalizationProvider>
                            {formik.touched && formik.touched?.upfrontPaymentInformation?.dueDate && formik.errors?.upfrontPaymentInformation?.dueDate && (
								<div className={classes.errorMessage}>
									<small>{(formik.errors?.upfrontPaymentInformation as FormikErrors<IInstantQuotationPaymentInfo>)?.dueDate}</small>
								</div>
							)}                        

                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full mt-2'>
                <span>Delivery Payment</span>
                <div className='flex pt-3 gap-3'>
                    <div className=' w-1/3'>
                        <TextFieldV2
                            {...formik.getFieldProps("deliveryPaymentInformation.percentage")}
                            className=" text-base font-medium"
                            type="text"
                            label="Enter Percentage"
                            placeholder="Enter Data"
                            inputProps={{ min: 0 }}
                            required
                            disabled={!isEditable}
                            error={formik.touched?.deliveryPaymentInformation?.percentage &&
                                Boolean((formik.errors?.deliveryPaymentInformation as FormikErrors<IInstantQuotationPaymentInfo>)?.percentage)
                            }
                            helperText={formik.touched?.deliveryPaymentInformation?.percentage &&
                                Boolean((formik.errors?.deliveryPaymentInformation as FormikErrors<IInstantQuotationPaymentInfo>)?.percentage) ? (formik.errors?.deliveryPaymentInformation as FormikErrors<IInstantQuotationPaymentInfo>)?.percentage : ''}
                            />
                    </div>
                    <div className=' w-1/3'>
                        <TextFieldV2
                            {...formik.getFieldProps("deliveryPaymentInformation.amount")}
                            className=" text-base font-medium"
                            type="text"
                            label="Amount"
                            placeholder="Enter Data"
                            fullWidth
                            required
                            disabled
                        />
                    </div>
                    <div className='w-1/3'>
                        <div className='flex flex-col'>
                            <label>Due Date</label>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    value={formik?.values?.deliveryPaymentInformation?.dueDate ? moment(formik.values.deliveryPaymentInformation?.dueDate, 'YYYY-MM-DD') : null}
                                    format="DD-MM-YYYY"
                                    onChange={(newValue) => {
                                        const formattedDate = moment(newValue).format('YYYY-MM-DD');
                                        formik.setFieldValue('deliveryPaymentInformation.dueDate', formattedDate);
                                    }}
                                    className={classes.datePicker}
                                    disabled={!isEditable}
                                />
                            </LocalizationProvider>
                            {formik.touched && formik.touched?.deliveryPaymentInformation?.dueDate && formik.errors?.deliveryPaymentInformation?.dueDate && (
								<div className={classes.errorMessage}>
									<small>{(formik.errors?.deliveryPaymentInformation as FormikErrors<IInstantQuotationPaymentInfo>)?.dueDate}</small>
								</div>
							)}
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div className='w-full'>
                <span>Packaging Terms</span>
                <div className='flex py-3 gap-3'>
                    <div className=' w-1/2'>
                        <TextFieldV2
                            {...formik.getFieldProps("packaging.packagingTerms")}
                            className=" text-base font-medium"
                            type="text"
                            label="Packaging Type"
                            disabled={!isEditable}
                            placeholder="Enter Data"
                        />
                    </div> <div className=' w-1/2'>
                        <TextFieldV2
                            {...formik.getFieldProps("packaging.packagingMaterials")}
                            className=" text-base font-medium"
                            type="text"
                            label="Packaging Material"
                            disabled={!isEditable}
                            placeholder="Enter Data"
                        />
                    </div>
                    <div className=' w-1/2'>
                        <TextFieldV2
                            {...formik.getFieldProps("packaging.packagingDimensions")}
                            className=" text-base font-medium"
                            type="text"
                            label="Packaging Dimensions"
                            placeholder="Enter Data"
                            disabled={!isEditable}
                            />
                    </div>
                </div>
            </div>
            <div className='w-full'>
                <span>Inspection and Acceptance Terms</span>
                <div className='flex py-3 gap-3'>
                    <div className=' w-1/2'>
                        <TextFieldV2
                            {...formik.getFieldProps("inspectionAndAcceptanceTerm.inspectionPeriod")}
                            className=" text-base font-medium"
                            type="text"
                            label="Inspection Period"
                            placeholder="Enter Data"
                            fullWidth
                            required
                            disabled={!isEditable}
                            />
                    </div> <div className=' w-1/2'>
                        <TextFieldV2
                            {...formik.getFieldProps("inspectionAndAcceptanceTerm.inspectionLocation")}
                            className=" text-base font-medium"
                            type="text"
                            label="Inspection Location"
                            placeholder="Enter Data"
                            fullWidth
                            required
                            disabled={!isEditable}
                        />
                    </div>
                    <div className=' w-1/2'>
                        <TextFieldV2
                            {...formik.getFieldProps("inspectionAndAcceptanceTerm.acceptanceCriteria")}
                            className=" text-base font-medium"
                            type="text"
                            label="Acceptance Criteria"
                            placeholder="Enter Data"
                            fullWidth
                            required
                            disabled={!isEditable}
                        />
                    </div>
                    <div className=' w-1/2'>
                        <TextFieldV2
                            {...formik.getFieldProps("inspectionAndAcceptanceTerm.notification")}
                            className=" text-base font-medium"
                            type="text"
                            label="Notification of Non-Conformance"
                            placeholder="Enter Data"
                            fullWidth
                            required
                            disabled={!isEditable}
                        />
                    </div>   <div className=' w-1/2'>
                        <TextFieldV2
                            {...formik.getFieldProps("inspectionAndAcceptanceTerm.remedies")}
                            className=" text-base font-medium"
                            type="text"
                            label="Remedies for Non-Conformance"
                            placeholder="Enter Data"
                            fullWidth
                            required
                            disabled={!isEditable}
                        />
                    </div>
                </div>
            </div>
            <div >
                <TextFieldV2
                    {...formik.getFieldProps("termAndCondition")}
                    className=" text-base font-medium"
                    type="text"
                    label="Terms And Conditions"
                    placeholder="Enter Data"
                    fullWidth
                    multiline
                    required
                    disabled={!isEditable}
                />
            </div>
        </div>
    )
}
