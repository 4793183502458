import React from "react";
import { createUseStyles } from "react-jss";
import ButtonV2 from "../../atoms/ButtonV2";
import { CUSTOMER_GROUP_SECTIONS } from "../../../utils/types";
import { useNavigate } from "react-router-dom";
import { ADMIN_ROUTES } from "../../../utils/constant";
import GroupDescriptionPreviewTemplate from "./GroupDescriptionPreview.template";
import CustomerGroupUpcsPreviewTemplate from "./CustomerGroupUpcsPreview.template";
import OtherFilterPreviewTemplate from "./OtherFiltersPreview.template";
import AdditionalCustomerPreviewTemplate from "./AdditionalCustomerPreview.template";
import { changeSectionTo } from "./AddCustomerGroup.template";

interface CustomerGroupPreviewTemplateProps {
    setCurrentSectionTo: (section: CUSTOMER_GROUP_SECTIONS) => void;
    formik: any;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid #EBEFFA`
    },
    filterContainer: {
        borderTop: `1px solid #EBEFFA`
    },
    filterHeading: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    label: {
        color: theme.palette.textV2.tertiary.tertiary600,
        fontSize: "14px",
        fontWeight: 400,
    },
    configure: {
        color: theme.palette.textV2.primary.primary500,
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600,
    },
    chipBackgroundColor: {
        backgroundColor: theme.palette.textV2.tertiary.tertiary50,
    },
    activeProgressBar: {
        backgroundColor: "#CA4F02"
    },
    inActiveProgressBar: {
        backgroundColor: "#FD6202"
    },
    input: {
        "& .MuiInputBase-root": {
            borderRadius: "8px !important",
        },
    },
    errorMessage: {
        color: theme.palette.textV2.failure.failure600,
    },
}));

const CustomerGroupPreviewTemplate: React.FC<CustomerGroupPreviewTemplateProps> = ({ setCurrentSectionTo, formik }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        formik.handleSubmit(); 
    };

    const handleCancel = () => {
        navigate(`${ADMIN_ROUTES.CUSTOMER_GROUP_MASTER_LIST}`);
    };

    return (
        <div className="grid gap-y-4">
            <div className={`w-full m-auto flex justify-between gap-x-2 text-var(--black, #4D4D4D) text-center text-sm `}>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-1 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-2 h-2 mx-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto font-semibold`}>Customer Group Master</div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-1 mx-auto'>
                        <div className={`${classes.inActiveProgressBar} w-2 h-2 mx-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Customer Group Configuration</div>
                    </div>
                    <div className={`${classes.inActiveProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>

            <div className={`${classes.container} p-6 grid gap-y-5 rounded-lg`}>
                <div className="flex justify-between">
                    <div className={`${classes.filterHeading} text-lg font-bold my-auto`}>Review Customer</div>
                </div>

                <GroupDescriptionPreviewTemplate formik={formik} />

                {(formik.values.customersFromUpcs?.length > 0) && <CustomerGroupUpcsPreviewTemplate formik={formik} />}
                
                {(formik.values.customersFromUpcs?.length > 0) && <OtherFilterPreviewTemplate formik={formik} />}

                {(formik.values.additionalCustomers?.length > 0) && <AdditionalCustomerPreviewTemplate formik={formik} />}

                <div className="grid gap-y-5 rounded-lg" >
                    <div className="flex justify-between">
                        <ButtonV2 variant="secondaryContained" size="large" label={"Cancel"} onClick={handleCancel} />
                        <div className="flex gap-x-5">
                            <ButtonV2 variant="tertiaryContained" size="large" label={"Back"} onClick={changeSectionTo(setCurrentSectionTo)(CUSTOMER_GROUP_SECTIONS.CUSTOMER_GROUP_CONFIGURATION_FORM)} />
                            <ButtonV2 variant="primaryContained" size="large" label={"Save"} onClick={handleSubmit} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomerGroupPreviewTemplate;