import { useEffect, useState } from 'react';
import { FormikValues } from 'formik';
import {
  ProductDataType,
  ProductFormData,
} from '../../../Constants/Procurement/PurchaseRequisition/types';
import { useNavigate } from 'react-router-dom';
import AddPRDetails from '../../../Components/Procurement/PurchaseRequisition/AddPRDetails';
import ProductSelect from '../../../Components/Procurement/PurchaseRequisition/ProductSelect';
import ReviewDetails from '../../../Components/Procurement/PurchaseRequisition/ReviewDetails';
import CustomHeading from '../../../Shared/CustomHeading/CustomHeading';
import { NavigationURL } from '../../../Constants/EndPoints';
import { purchaseRequisitionFormTabs } from '../../../Constants/Procurement/PurchaseRequisition/constants';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import { usePurchaseRequisition } from '../../../../services/usePurchaseRequisition';

export const AddPurchaseRequisition = () => {
  const { user }: any = useAuthenticatedUser();
  const navigate = useNavigate();

  const [completedTabs, setCompletedTabs] = useState(1);
  const [selectedItems, setSelectedItems] = useState<ProductDataType[]>([]);
  const [formData, setFormData] = useState<ProductFormData[]>([]);

  const { createPurchaseRequisition } = usePurchaseRequisition();

  const handleSelectedItems = (items: ProductDataType[]) => {
    if (items.length === 0) {
      alert('Please select at least one product');
    } else {
      localStorage.setItem('selectProducts', JSON.stringify(items));
      setSelectedItems(items);
      const initialValue = items.map((product) => ({
        itemId: product.id,
        quantity: 0,
        packagingType: '',
        warehouseId: '',
        deliveryTime: '',
        consigneeLocation: '',
        remarks: '',
        tnc: '',
        uom: 'MT',
        imageURLs: [],
        productRequirement: '',
      }));
      setFormData(initialValue);
      setCompletedTabs(2);
    }
  };

  const handleFormData = (values: FormikValues) => {
    // add consignee Location here

    setFormData(values.items);
    setCompletedTabs(3);
  };

  const handleProducts = (products: ProductDataType[]) => {
    localStorage.setItem('selectProducts', JSON.stringify(products));
    setSelectedItems(products);
  };

  useEffect(() => {
    if (selectedItems.length === 0) {
      setCompletedTabs(1);
    }
  }, [selectedItems]);

  const handleCreatePRID = async () => {
    const items = formData.map((item) => ({
      itemId: +item.itemId,
      packagingType: item.packagingType,
      status: 'CREATED',
      quantity: Number(item.quantity).toFixed(1),
      uom: item.uom,
      warehouseId: item.warehouseId,
      deliveryTime: item.deliveryTime,
      remarks: item.remarks,
      tnc: item.tnc,
      productRequirement: item.productRequirement,
    }));

    try {
      await createPurchaseRequisition(items);
      alert('PRID created successfully');
      setFormData([]);
      setSelectedItems([]);
      localStorage.removeItem('selectProducts');
      navigate(NavigationURL.purchaseRequisition);
    } catch (error) {
      console.log(error);
      alert('Error creating PRID');
    }
  };

  return (
    <div className="grid gap-y-3 m-auto">
      <div className="flex justify-between mt-4 ">
        <CustomHeading text={'New Purchase Requisition'} status="" />
      </div>
      <div className="flex items-center gap-2 w-full my-4 ">
        {purchaseRequisitionFormTabs.map((tab, tabIndex) => (
          <div className="w-full" key={tab + tabIndex}>
            <div className="text-center text-blue font-medium">{tab}</div>
            <div
              className={`h-3 w-full  rounded-full mt-2 ${
                completedTabs > tabIndex ? 'bg-blue' : 'bg-cgray-5'
              }`}
            ></div>
          </div>
        ))}
      </div>
      <div>
        {completedTabs === 1 && (
          <ProductSelect onSubmit={handleSelectedItems} />
        )}
        {completedTabs === 2 && (
          <AddPRDetails
            products={selectedItems}
            setProducts={(products) => handleProducts(products)}
            formData={formData}
            setCompletedTab={setCompletedTabs}
            onSubmit={handleFormData}
          />
        )}
        {completedTabs === 3 && (
          <ReviewDetails
            products={selectedItems}
            formData={formData}
            setCompletedTabs={setCompletedTabs}
            onSubmit={handleCreatePRID}
          />
        )}
      </div>
    </div>
  );
};
