import { Route, Routes } from 'react-router-dom';
import USCListPage from '../../../components/pages/VASMaster/USC/USCList.page';
import USCCreatePage from '../../../components/pages/VASMaster/USC/USCCreate.page';
import USCViewPage from '../../../components/pages/VASMaster/USC/USCView.page';
import USCUpdatePage from '../../../components/pages/VASMaster/USC/USCUpdate.page';

const VASMasterUSCsRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<USCListPage />} />
            <Route path="/add" element={<USCCreatePage />} />
            <Route path="/:id" element={<USCViewPage />} />
            <Route path="/:id/update" element={<USCUpdatePage />} />
        </Routes>
    )
}

export default VASMasterUSCsRoutes