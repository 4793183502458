export const VENDOR_DISCOVERY_LIST_PAGINATION_TEXT = {
  showingItems: (pageNumber: number, totalItems: number) =>
    `${totalItems > 0 ? pageNumber * 10 + 1 : 0}-${
      totalItems < pageNumber * 10 + 10 ? totalItems : pageNumber * 10 + 10
    } from ${totalItems} Auction`,
};

export const VENDOR_DISCOVERY_TABS = ['All Auction'];

export const SELECT_VENDOR_LIST_PAGINATION_TEXT = {
  showingItems: (pageNumber: number, totalItems: number) =>
    `${totalItems > 0 ? pageNumber * 10 + 1 : 0}-${
      totalItems < pageNumber * 10 + 10 ? totalItems : pageNumber * 10 + 10
    } from ${totalItems} Vendors`,
};


export const VendorTypeList = [
  { name: 'All', value: 'All' },
  { name: 'Super Stockiest', value: 'super stockiest' },
  { name: 'Stockiest', value: 'stockiest' },
  { name: 'Dealer', value: 'dealer' },
  { name: 'Trader', value: 'trader' },
  { name: 'Manufacturer', value: 'manufacturer' },
];

export const VendorGroupList = [
  { name: 'All', value: 'All' },
  { name: 'CRNO', value: 'CRNO' },
  { name: 'PPGL', value: 'PPGL' },
  { name: 'CRFH', value: 'CRFH' },
  { name: 'Electrolytic Tinplate', value: 'Electrolytic Tinplate' },
];