import { FC } from 'react';
import { PRODUCT_LIST_PAGINATION_TEXT } from '../../Constants/Procurement/PurchaseRequisition/constants';

interface PaginationButtonProps {
  pageNumber: number;
  totalItems: number;
  totalPages: number;
  text?: string;
  setPageNumber: (page: number) => void;
}

// Todo

const PaginationButton: FC<PaginationButtonProps> = ({
  pageNumber,
  setPageNumber,
  totalItems,
  totalPages,
  text,
}) => {
  return (
    <div className="flex items-center w-max">
      <button
        className="rounded p-3 bg-cgray-10 group"
        disabled={pageNumber === 0}
        onClick={() => pageNumber > 0 && setPageNumber(pageNumber - 1)}
      >
        <svg
          width="7"
          height="12"
          viewBox="0 0 7 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.49656 0.709778C6.88656 1.09978 6.88656 1.72978 6.49656 2.11978L2.61656 5.99978L6.49656 9.87978C6.88656 10.2698 6.88656 10.8998 6.49656 11.2898C6.10656 11.6798 5.47656 11.6798 5.08656 11.2898L0.496562 6.69978C0.106563 6.30978 0.106563 5.67978 0.496562 5.28978L5.08656 0.699778C5.46656 0.319778 6.10656 0.319778 6.49656 0.709778Z"
            className="group-disabled:fill-cgray-11 fill-blue"
          />
        </svg>
      </button>
      <span className="mx-4 font-bold text-cgray-12">{pageNumber + 1}</span>
      <button
        className="rounded p-3 bg-cgray-10 group"
        disabled={pageNumber === totalPages - 1}
        onClick={() => {
          pageNumber >= 0 &&
            pageNumber < totalPages - 1 &&
            setPageNumber(pageNumber + 1);
        }}
      >
        <svg
          width="7"
          height="12"
          viewBox="0 0 7 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.503437 0.709778C0.113438 1.09978 0.113438 1.72978 0.503437 2.11978L4.38344 5.99978L0.503437 9.87978C0.113438 10.2698 0.113438 10.8998 0.503437 11.2898C0.893438 11.6798 1.52344 11.6798 1.91344 11.2898L6.50344 6.69978C6.89344 6.30978 6.89344 5.67978 6.50344 5.28978L1.91344 0.699778C1.53344 0.319778 0.893438 0.319778 0.503437 0.709778Z"
            className="group-disabled:fill-cgray-11 fill-blue"
          />
        </svg>
      </button>
      <span className="ml-4 text-nowrap font-bold">
        {totalItems > 0 && (
          <span>
            {PRODUCT_LIST_PAGINATION_TEXT.showingItems(
              pageNumber,
              totalItems,
              text
            )}
          </span>
        )}
      </span>
    </div>
  );
};

export default PaginationButton;
