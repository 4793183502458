import React, { useState } from "react";

interface DateHeaderProps {
  monthIndex: number;
  year: number;
  setMonthIndex: (index: number) => void;
  setYear: (year: number) => void;
}

const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// Todo

const DateHeader = ({
  monthIndex,
  year,
  setMonthIndex,
  setYear,
}: DateHeaderProps) => {
  const [currentMonth, setCurrentMonth] = useState<string>(Months[monthIndex]);

  const handleMonthChange = (index: number) => {
    const newMonthIndex = monthIndex + index;
    updateMonth(newMonthIndex);
  };

  const updateMonth = (newMonthIndex: number) => {
    if (newMonthIndex > 11) {
      setYear(year + 1);
      newMonthIndex = 0;
    } else if (newMonthIndex < 0) {
      setYear(year - 1);
      newMonthIndex = 11;
    }

    setMonthIndex(newMonthIndex);
    setCurrentMonth(Months[newMonthIndex]);
  };

  return (
    <div className="flex w-full justify-between items-center my-4">
      <span
        className="bg-[#E8E8E8] w-6 h-6 flex justify-center items-center rounded-full shadow-datebody cursor-pointer"
        onClick={() => handleMonthChange(-1)}
      >
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.99729 13.7787L5.65062 9.43208C5.13729 8.91875 5.13729 8.07875 5.65062 7.56542L9.99729 3.21875"
            stroke="#1D3679"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
      <span className="text-[20px] text-blue font-medium text-center ">
        {currentMonth}
      </span>
      <span
        className="bg-[#E8E8E8] w-6 h-6 flex justify-center items-center rounded-full shadow-datebody cursor-pointer"
        onClick={() => handleMonthChange(1)}
      >
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.9375 13.7787L10.2842 9.43208C10.7975 8.91875 10.7975 8.07875 10.2842 7.56542L5.9375 3.21875"
            stroke="#1D3679"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    </div>
  );
};

export default DateHeader;
