import { Button, ButtonProps } from '@mui/material';
import { ReactNode } from 'react';

interface VariantButtonProps extends ButtonProps {
  children: ReactNode;
}

const VariantButton = ({
  children,
  variant,
  className,
  ...props
}: VariantButtonProps) => {
  return (
    <Button
      {...props}
      variant={variant || 'contained'}
      className={`${
        variant && variant !== 'contained'
          ? 'hover:bg-none'
          : 'bg-blue hover:bg-blue'
      } ${className} normal-case `}
    >
      {children}
    </Button>
  );
};

export default VariantButton;
