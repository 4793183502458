import { Route, Routes } from 'react-router-dom';
import UpcListPage from '../../../components/pages/UPC/UpcList.page';
import UpcCreatePage from '../../../components/pages/UPC/UpcCreate.page';
import UpcUpdatePage from '../../../components/pages/UPC/UpcUpdate.page';
import UpcViewPage from '../../../components/pages/UPC/UpcView.page';

const UpcRouter = () => {
  return (
    <div>
      <Routes>
        <Route path="" element={<UpcListPage />} />
        <Route path='/add' element={<UpcCreatePage />} />
        <Route path='/:id/update' element={<UpcUpdatePage />} />
        <Route path='/:id' element={<UpcViewPage />} />
      </Routes>
    </div>
  )
}
export default UpcRouter