import { request } from "."
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export const USER_URLS = {
    UPDATE_USER: "/user",
    GET_ALL_USER:"/users",
    GET_USER: "/user",
    UPDATE_CUSTOMER_PASSWORD: "/customer/:userId/password"
}
export interface IUserFilterRequest{
    userType?: string;
    search?: string;
}  
export const useUserService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();

    const updateUser = (data: { email: string ; password: string ; mobileNumber: string; fullName: string  }) => {
        return request(API_METHOD.PUT, USER_URLS.UPDATE_USER, authenticatedUser, data);
    }

    const getAllUser = (params: IUserFilterRequest) =>{
        return request(API_METHOD.GET, USER_URLS.GET_ALL_USER, authenticatedUser, null, { params });
    }
        
    const updateCustomerPassword = (data: {newPassword: string}, userId: number) => {
        return request(API_METHOD.PUT, replaceUrlParams(USER_URLS.UPDATE_CUSTOMER_PASSWORD, { userId }), authenticatedUser, data);
    }

    const getUser = () => {
        return request(API_METHOD.GET, USER_URLS.GET_USER, authenticatedUser);
    };

    return {
        updateUser,
        getAllUser,
        updateCustomerPassword,
        getUser
    }
};