import React from 'react'
import { IStandard, IStandardFilter } from '../../pages/Standard/StandardList.page';
import { IPagination } from '../../../utils/types';
import Table, { ColumnType } from "../../organisms/Table";
import { useNavigate } from 'react-router-dom';
import viewEyeIcon from "./../../../assets/icons/viewEyeIcon.svg"
import editPenIcon from "./../../../assets/icons/editPenIcon.svg";
import { ADMIN_ROUTES } from "../../../utils/constant";
import { enumToString, makeRoute } from "../../../utils/helper";
import moment from "moment";
import TextField from "../../atoms/TextField";
import Select from '../../atoms/Select';
import ResourceStatus from '../../atoms/ResourceStatus/ResourceStatus';

interface IStandardListTemplateProps {
    standards: IStandard[];
    pagination: IPagination;
    handleFiltersChange?: any;
    handlePaginationChange: any;
    handleRowsPerPageChange: any;
    standardFilter: IStandardFilter
}

const StandardListTemplate: React.FC<IStandardListTemplateProps> = ({ standards, pagination, handlePaginationChange, handleRowsPerPageChange, handleFiltersChange, standardFilter }) => {

    const navigate = useNavigate();

    const schema = {
        id: 'standard-list',
        pagination: {
            total: pagination.totalRecords,
            currentPage: pagination.currentPage,
            isVisible: true,
            limit: pagination.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange
        },
        columns: [

            { label: "Sr No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "Standard Name", key: "name", type: "string" as ColumnType, props: { className: '' } },
            { label: "Standard Code", key: "prefix", type: "string" as ColumnType, props: { className: '' } },
            { label: "Heading", key: "heading", type: "string" as ColumnType, props: { className: '' } },
            { label: "Year(Created in)", key: "yearInUse", type: "number" as ColumnType, props: { className: '' } },
            { label: "Year(Last Updated)", key: "yearLastUpdated", type: "number" as ColumnType, props: { className: '' } },
            { label: "Created Date", key: "createdAt", type: "datetime" as ColumnType, props: { className: '' } },
            { label: "Last Modified", key: "updatedAt", type: "datetime" as ColumnType, props: { className: '' } },
            { label: "Status", key: "status", component: ({ value }: { value: string }) => <ResourceStatus status={value} />, type: "custom" as ColumnType, props: {} },
            { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } },
        ]
    }

    const handleViewClick = (id: number) => (e: any) => {
        navigate(makeRoute(ADMIN_ROUTES.STANDARD_VIEW, {params:  { id: id } }));
    };

    const handleEditClick = (id: number) => (e: any) => {
        navigate(makeRoute(ADMIN_ROUTES.STANDARD_UPDATE, {params:  { id: id } }));
    };

    const Action = (id: number) => {
        return (<div>
            <button onClick={handleViewClick(id)}><img src={viewEyeIcon} alt={viewEyeIcon} /></button>
            <button onClick={handleEditClick(id)}><img src={editPenIcon} alt={editPenIcon} /></button>
        </div>)

    }

    const records = standards.map((standard: IStandard, index: number) => [
        pagination.currentPage * pagination.pageSize + index + 1,
        standard.name,
        standard.prefix !== null ? standard.prefix : "",
        standard.bisHeading,
        standard.yearInUse,
        standard.yearLastUpdated,
        moment(standard.createdAt).format("YYYY-MM-DD HH:mm:ss"),
        moment(standard.updatedAt).format("YYYY-MM-DD HH:mm:ss"),
        enumToString(standard.status),
        Action(standard.id)
    ]);

    return (
        <div className='grid gap-y-3'>
            <div className="flex justify-between">
                <div className="flex gap-x-3">
                    <div>
                        <Select
                            variant="outlined"
                            className="w-44"
                            label="Status"
                            name="status"
                            id="status"
                            value={standardFilter.status}
                            onChange={(e) => handleFiltersChange(e)}
                            options={[
                                { value: "ALL", label: "All" },
                                { value: "ACTIVE", label: "ACTIVE" },
                                { value: "INACTIVE", label: "INACTIVE" },

                            ]}
                        />
                    </div>
                    <div>
                        <Select
                            variant="outlined"
                            className='w-64'
                            id="sort"
                            label="Sort"
                            name="sort"
                            value={standardFilter.sort}
                            onChange={(e) => handleFiltersChange(e)}
                            options={[
                                { value: "name,asc", label: "Name (Ascending)" },
                                { value: "name,desc", label: "Name (Descending)" },
                                { value: "createdAt,asc", label: "Created Date(Ascending)" },
                                { value: "createdAt,desc", label: "Created Date(Descending)" },
                            ]}
                        />
                    </div>                    
                </div>
                <TextField
                    className="w-72"
                    type="text"
                    label="Search by Name"
                    variant="outlined"
                    name="search"
                    value={standardFilter.search}
                    onChange={handleFiltersChange}
                />
            </div>
            <Table schema={schema} records={records} />
        </div>
    )
}

export default StandardListTemplate
