import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomHeading from "../../../Shared/CustomHeading/CustomHeading";
import { CustomButton } from "../../../Shared/Button/CustomButton";
import TermsModal from "../../../Shared/TermsAndCondition/TermsModal";
import ImageButton from "../../../Shared/ImageButton/ImageButton";
import viewBlackSvg from "../../../Assets/Images/viewBlack.svg";
import closeBlackSvg from "../../../Assets/Images/closeBlack.svg";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import axios from "axios";
import QuotationDetailsModal from "./QuotationDetailsModal";
import { capitalizeFirstLetter } from "./CreateQuotation";
import { useAuthenticatedUser } from "../../../../hooks/useAuthenticatedUser";

const CreateMTOQuotation = () => {
  const { user } = useAuthenticatedUser();
  const params = useParams();

  //--------------------Fetch LeadUpc data--------------------

  const [leadUpcData, setLeadUpcData] = useState([]);
  const [comapanyName, setCompanyName] = useState("");
  const [actorId, setActorId] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [skuIds, setSkuIds] = useState(null);
  const [secondaryLotsCatalogueNames, setSecondaryLotsCatalogueNames] = useState([]);
  const [secondaryLots, setSecondaryLots] = useState([]);
  const [leadQuantity, setLeadQuantity] = useState(0);
  const [newleadUpcData, setNewLeadUpcData] = useState([]);
  const fetchLeadUpc = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_V1_URL}/lead/${Number(params.id)}`,
        {
          headers: {
            Authorization: `${user.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      var upcData = response.data.data.leadUpcResponseDTO;
      setLeadUpcData(upcData);
      setNewLeadUpcData(upcData)
      setCompanyName(response.data.data.companyName);
      setActorId(response.data.data.actorId);
      setQuantity(
        response.data.data.leadUpcResponseDTO.reduce(
          (total, item) => total + item.quantity,
          0
        )
      );
      setSkuIds(
        response?.data?.data?.leadUpcResponseDTO.map((item) => item.secondarySkuId)
      );
      const catalogueNames = new Set();
      response?.data?.data?.leadUpcResponseDTO.forEach((item) => {
        if (item && item.catalogueName) {
          catalogueNames.add(item.catalogueName);
        }
      });
      setSecondaryLotsCatalogueNames(Array.from(catalogueNames));

    } catch (error) {
      console.error("Error fetching lead details:", error);
    }
  };

  //-----------Terms and Condition Modal------------------

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //---------------------Fetch UPC Data-------------------------------------

  const [deleteUpcId, setDeleteUpcId] = useState();

  const handleDeleteLeadUpc = async (leadUpcId) => {
    setDeleteUpcId(leadUpcId);
    const confirmed = window.confirm(
      "Are you sure you want to remove this Product ?"
    );

    if (confirmed) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_V1_URL}/lead-upc/${leadUpcId}`,
          {
            headers: {
              Authorization: `${user.token}`,
              "Content-Type": "application/json",
            },
          }
        );
        window.alert("Product removed successfully!");
      } catch (error) {
        console.error("Error deleting leadUpc:", error);
      }
    }
  };

  // -----------------------------------------------------------------

  const fetchSecondaryLots = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_V1_URL}/search/secondary-products`,
        { catalogueNames: secondaryLotsCatalogueNames },
        {
          headers: {
            Authorization: `${user.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setSecondaryLots(response.data.data);
    } catch (error) {
      console.error(`Error fetching lead details for upcId `, error);
    }
  };


  useEffect(() => {
    fetchSecondaryLots();
  }, [secondaryLotsCatalogueNames])

  useEffect(() => {
    fetchLeadUpc();
  }, [params.id, deleteUpcId]);

  const navigate = useNavigate();

  const handleRedirectBack = () => {
    navigate((-1));
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = (id) => {
    setIsModalOpen(id);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const [upcSkuEditDetails, setUpcSkuEditDetails] = useState({});
  const [content, setContent] = useState("");
  const editor = useRef(null);
  const config = useMemo(() => {
    return {
      readonly: false,
      placeholder: "Start typings...",
    };
  }, []);

  const [charges, setCharges] = useState([
    {
      warehouseCharges: "",
      handlingCharges: "",
      packagingCharges: "",
      otherCharges: "",
      gst: "",
      tcs: "",
    },
  ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (value >= 0) {
      setCharges([{ ...charges[0], [name]: +value }]);
    }
  };

  const handleUpdateDetails = (payload, catalogueName) => {
    setUpcSkuEditDetails(prevDetails => {
      const catalogueData = groupedData.find(item => item.catalogueName === catalogueName);
      if (!catalogueData) return { ...prevDetails, skuData: [] }; // Provide a default value for skuData if not present

      const updatedSkuData = (catalogueData.secondarySkuId || []).map(skuId => {
        const quotationItem = leadUpcData.find(q => q.secondarySkuId === skuId);
        const basePrice = secondaryLots.find(item => item.catalogueName === catalogueName)?.secondaryProductResponse?.[0]?.basePrice || 1
        return quotationItem ? { ...quotationItem, price: payload.target.value, basePrice: basePrice } : null;
      });

      const updatedSkuIds = new Set(updatedSkuData.map(sku => sku?.id).filter(Boolean));

      const mergedSkuData = (prevDetails.skuData ?? []).map(prevSku => updatedSkuData.find(sku => sku?.id === prevSku.id) || prevSku);
      updatedSkuData.filter(sku => !mergedSkuData.some(prevSku => prevSku.id === sku.id) && updatedSkuIds.has(sku.id)).forEach(sku => mergedSkuData.push(sku));
      return { ...prevDetails, skuData: mergedSkuData };
    });
    // }
  };

  const formatDataForPost = () => {
    const quotationUpcRequestBodyDTO = [];
    if (upcSkuEditDetails?.skuData === undefined) {
      alert("Cannot publish without any SKU data. Please fill all the details.");
      return;
    }
    const allUpcData = upcSkuEditDetails?.skuData.map((upcSkuEditValue, key) => {
      const skuDataLotData = secondaryLots?.[0].secondaryProductResponse;
      let skuStack = [];
      for(const valData of skuDataLotData) {
        let tempData = {
          skuId: valData.id,
          skuCode: valData.skuCode,
          quantity: valData.netWeight,
          profit: null, // You need to specify how to fill this
          price: upcSkuEditValue?.price || 1, // You need to specify how to fill this
          packaging: upcSkuEditValue.packagingType,
          warehouse: upcSkuEditValue.warehouse,
          basePrice: valData?.basePrice
        }
        skuStack.push(tempData);
      }
      const upcData = {
        upcId: null,
        upcCode: null,
        quantity: upcSkuEditValue.quantity || 0,
        skuData: skuStack,
        secondarySkuId: upcSkuEditValue.secondarySkuId,
        catalogueName: upcSkuEditValue.catalogueName
      };
      quotationUpcRequestBodyDTO.push(upcData);
    })
    if (charges[0].otherCharges === "") {
      charges[0].otherCharges = 0;
    }
    return {
      leadId: Number(params.id),
      actorId: actorId,
      termsAndCondition: content,
      companyName: comapanyName,
      publishStatus: "PUBLISHED",
      charges: charges,
      quotationUpcRequestBodyDTO: quotationUpcRequestBodyDTO,
      catalogueType: 'MAKE_TO_ORDER'
    };
  };


  const isPriceAndPackagingSelected = (skuDataArray) => {
    return skuDataArray.every((upcData) => {
      return upcData.skuData.every((sku) =>
        sku.price !== undefined && sku.price !== "" &&
        sku.packaging !== undefined && sku.packaging !== ""
      );
    });
  };


  const handlePublish = async () => {
    const formattedData = formatDataForPost();
    if (!formattedData) return;
    if(isFieldsValid(formattedData)){
    formattedData.publishStatus = "PUBLISHED";
    try {
      await axios.post(
        `${process.env.REACT_APP_API_V1_URL}/quotation`,
        formattedData,
        {
          headers: {
            Authorization: `${user.token}`,
            "Content-Type": "application/json",
          },
        }
      ).then((response) => {
        if (response.status === 200) {
          handleRedirectBack();
          return
        }
        alert("Error in creating quotation - Please try again");
        return
      })
    } catch (error) {
      if (error.response.status === 500) {
        alert("Something went wrong, Please try again");
      }
    }
  }
  };

  function isFieldsValid(formattedData) {
    const isAnyChargeEmpty = Object.values(charges[0]).some(charge => charge === "");

    if (isAnyChargeEmpty) {
      alert("Please fill in all the charges before publishing.");
      return false;
    }
    if (!formattedData) {
      return false;
    }
    if (formattedData.quotationUpcRequestBodyDTO.length === 0) {
      alert("Cannot publish without any SKU data. Please add SKUs before publishing.");
      return false;
    }

    if (!isPriceAndPackagingSelected(formattedData.quotationUpcRequestBodyDTO)) {
      alert("Please enter price for all SKUs before publishing.");
      return false;
    }

    const isQuantityNonZero = formattedData.quotationUpcRequestBodyDTO.every(upcData =>
      upcData.skuData.every(sku => parseInt(sku.quantity) !== 0)
    );

    if (!isQuantityNonZero) {
      alert("Please ensure that quantity for all SKUs is greater than zero before publishing.");
      return false;
    }
    return true;
  }

  const handleSave = async () => {
    const formattedData = formatDataForPost();
    if (isFieldsValid(formattedData)) {
      formattedData.publishStatus = "NOTPUBLISHED";
      try {
        await axios.post(
          `${process.env.REACT_APP_API_V1_URL}/quotation`,
          formattedData,
          {
            headers: {
              Authorization: `${user.token}`,
              "Content-Type": "application/json",
            },
          }
        );
      } catch (error) {
        console.error("Error saving quotation:", error);
      }
      handleRedirectBack();
    }
  };

  function calculateProfitLossPercentage(basePrice, offerededPrice) {
    return (((offerededPrice - basePrice) / basePrice) * 100).toFixed(2);
  }

  function calculateSubTotal(item) {
    const quantity = parseFloat(item?.quantity || 0);
    const pricePerMT = parseFloat(item?.price || 0);
    return (quantity * pricePerMT);
  }

  const DisplayLotAttributes = ({ catalogueName }) => {
    const secondaryLotDetails = secondaryLots.find((secondaryLot) => secondaryLot.catalogueName === catalogueName);
    if (!secondaryLotDetails) {
      return <></>;
    }

    return (
      <div className=" flex flex-col gap-y-2 w-3/12 ">
        <img
          className="w-full rounded"
          src={secondaryLotDetails?.catalogueImages[0]?.path}
          alt="productImage"
        />
        <div
          className="flex rounded bg-gray-300  h-fit w-full"
          key={secondaryLotDetails?.id}
        >
          <div
            className={`grid gap-2 grid-cols-3 p-2 w-full`}
          >
            {secondaryLotDetails?.attributes && Object.keys(secondaryLotDetails.attributes).map((key, i) => (
              <div className="flex flex-col gap-y-1" key={i}>
                <div className="text-gray-500 font-roboto text-sm font-normal">
                  {key}
                </div>
                <div className="text-gray-500 font-roboto text-sm font-medium flex">
                  {secondaryLotDetails.attributes[key][0] === secondaryLotDetails.attributes[key][1] ?
                    `${secondaryLotDetails.attributes[key][0]} ${secondaryLotDetails.attributes[key][2]}`
                    : `${secondaryLotDetails.attributes[key][0]} - ${secondaryLotDetails.attributes[key][1]} ${secondaryLotDetails.attributes[key][2]}`
                  }
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

    );
  };


  const DisplaySecondaryLots = ({ catalogueName }) => {
    const secondaryLotsList = secondaryLots.find((secondaryLot) => secondaryLot.catalogueName === catalogueName)?.secondaryProductResponse;
    if (!secondaryLotsList) {
      return <></>;
    }

    return (
      <div>
        {secondaryLotsList &&
          secondaryLotsList?.map((lotItem, index) => (
            <div
              className="lead-management-tablebody flex justify-between items-start gap-x-2"
              key={lotItem.id}
            >
              <div className="lead-management-tablebody-heading w-40 ml-2 py-3">
                {lotItem?.skuCode}
              </div>
              <div className="lead-management-tablebody-heading w-48 py-3">
                {lotItem?.warehouseName}
              </div>
              <div className="lead-management-tablebody-heading w-48 py-3">
                {lotItem?.basePrice}
              </div>
              <div className="lead-management-tablebody-heading w-20 mr-2 py-3">
                {lotItem?.netWeight}
              </div>
            </div>
          ))}
      </div>
    );
  };

  const [pricingData, setPricingData] = useState({
    productsSubTotal: 0,
    warehousePrice: 0,
    handlingPrice: 0,
    packagingPrice: 0,
    otherPrice: 0,
    subTotal: 0,
    totalGst: 0,
    totalTcs: 0,
  });

  useEffect(() => {
    let productsSubTotal = 0
    let quotationQuantity = 0
    let calculatedPrices = {
      productsSubTotal: 0,
      warehousePrice: 0,
      handlingPrice: 0,
      packagingPrice: 0,
      otherPrice: 0,
      subTotal: 0,
      totalGst: 0,
      totalTcs: 0,
      totalQuantity: 0,
      grandTotal: 0
    }
    for (const key in upcSkuEditDetails.skuData) {
      productsSubTotal += calculateSubTotal(upcSkuEditDetails.skuData[key]);
      quotationQuantity += parseFloat(upcSkuEditDetails.skuData[key]?.quantity) || 0

    }
    calculatedPrices.productsSubTotal = productsSubTotal;
    calculatedPrices.totalQuantity = quotationQuantity;
    calculatedPrices.warehousePrice = charges[0].warehouseCharges * quotationQuantity;
    calculatedPrices.handlingPrice = charges[0].handlingCharges * quotationQuantity;
    calculatedPrices.packagingPrice = charges[0].packagingCharges * quotationQuantity;
    calculatedPrices.otherPrice = +charges[0].otherCharges;

    calculatedPrices.subTotal =
      productsSubTotal +
      calculatedPrices.warehousePrice +
      calculatedPrices.handlingPrice +
      calculatedPrices.packagingPrice +
      calculatedPrices.otherPrice;
    calculatedPrices.totalGst = ((charges[0]?.gst || 0) * (calculatedPrices.subTotal)) / 100;
    calculatedPrices.totalTcs = ((charges[0]?.tcs || 0) * (calculatedPrices.subTotal)) / 100;
    calculatedPrices.grandTotal = calculatedPrices.subTotal + calculatedPrices.totalGst + calculatedPrices.totalTcs;
    setLeadQuantity(quotationQuantity);
    setPricingData(calculatedPrices);
  }, [upcSkuEditDetails, charges])

  const groupData = () => {
    const groupedData = {};
    leadUpcData.forEach(obj => {
      const { catalogueName } = obj;
      if (!groupedData[catalogueName]) {
        groupedData[catalogueName] = { ...obj, id: [obj.id], secondarySkuId: [obj.secondarySkuId], skuCode: [obj.skuCode] };
      } else {
        groupedData[catalogueName].id.push(obj.id);
        groupedData[catalogueName].secondarySkuId.push(obj.secondarySkuId);
        groupedData[catalogueName].skuCode.push(obj.skuCode);
        groupedData[catalogueName].quantity += obj.quantity;
      }
    });
    return Object.values(groupedData);
  };
  const groupedData = groupData();

  return (
    <div className="grid gap-y-3 mt-4 m-auto">
      <div className="flex justify-between">
        <CustomHeading text="Create Secondary Lot Quotation" status="" />
        <div className="m-auto mr-0 flex gap-x-3">
          <CustomButton onClick={handleOpen}>Terms and Condition</CustomButton>
          <TermsModal
            open={open}
            editor={editor}
            config={config}
            content={content}
            setContent={setContent}
            onClose={handleClose}
          />
        </div>
      </div>

      {groupedData &&
        groupedData.map((leadUpc, index) => {
          return (
            <div className="" key={leadUpc?.catalogueName}>
              <div className="flex justify-between bg-gray-300 p-3">
                <div className="m-auto ml-0">
                  <div className="text-gray-600 font-roboto text-xl font-semibold">
                    Catalogue Name : {leadUpc?.catalogueName}
                  </div>
                </div>
                <div className="flex justify-between gap-x-6">
                  <div className="m-auto text-gray-500 font-roboto font-semibold text-base">
                    Request Price : {leadUpc?.requestPrice || 0}/MT
                  </div>
                  <div className="m-auto text-gray-500 font-roboto font-semibold text-base">
                    Require Order Quantity : {leadUpc?.quantity || 0} MT
                  </div>
                  <div className="m-auto text-gray-500 font-roboto font-semibold text-base">
                    Packaging Required : {capitalizeFirstLetter(leadUpc?.packagingType)}
                  </div>
                  <div className="rounded hidden">
                    <ImageButton
                      className="p-1.5 bg-white rounded"
                      name="delete-lead-upc"
                      imageSrc={closeBlackSvg}
                      onClick={() => handleDeleteLeadUpc(leadUpc?.id)}
                    />
                  </div>
                </div>
              </div>


              <div className="">
                <div className="product-detail-container bg-white p-3 flex justify-between gap-x-3  w-full">
                  <DisplayLotAttributes catalogueName={leadUpc?.catalogueName} />
                  <div className=" grid gap-y-4 w-9/12 mt-0 m-auto">
                    <div className=" flex justify-between gap-x-2">
                      <div className="flex w-1/4  ">
                        <div className="w-full">
                          <TextField
                            required
                            type="text"
                            name="quantity"
                            id="outlined-basic"
                            label="Quantity"
                            variant="outlined"
                            fullWidth
                            value={leadUpc?.quantity}
                          />
                        </div>
                        <div className="rounded py-4 px-5 bg-gray-300">
                          MT
                        </div>
                      </div>
                      <div className="w-1/4 ">

                        <TextField
                          required
                          type="number"
                          name="price"
                          id="outlined-basic"
                          label="Offered Price /MT"
                          variant="outlined"
                          fullWidth
                          value={
                            (upcSkuEditDetails.skuData &&
                              upcSkuEditDetails.skuData.find(item => item.catalogueName === leadUpc.catalogueName)?.price) || ""
                          }
                          onChange={(event) =>
                            handleUpdateDetails(event, leadUpc.catalogueName)
                          }
                        />
                      </div>

                      <div className="flex w-1/4 ">
                        <div className="w-full">
                          <TextField
                            className="pointer-events-none"
                            type="number"
                            name="profit"
                            id="outlined-basic"
                            label="Profit % /MT"
                            variant="outlined"
                            fullWidth
                            value={
                              calculateProfitLossPercentage(
                                (
                                  (secondaryLots && secondaryLots.find(item => item.catalogueName === leadUpc.catalogueName)?.secondaryProductResponse?.[0]?.basePrice.toFixed(2)) || 0
                                ),
                                (
                                  (upcSkuEditDetails.skuData && !isNaN(parseFloat(upcSkuEditDetails.skuData.find(item => item.catalogueName === leadUpc.catalogueName)?.price))) ?
                                    parseFloat(upcSkuEditDetails.skuData.find(item => item.catalogueName === leadUpc.catalogueName)?.price).toFixed(2) :
                                    0
                                )
                              )
                            }
                          />
                        </div>
                        <div className="rounded py-4 px-5 bg-gray-300">
                          %
                        </div>
                      </div>
                      <div className="w-1/4 ">
                        <TextField
                          required
                          type="text"
                          name="packaging"
                          id="outlined-basic"
                          label="Packaging"
                          variant="outlined"
                          fullWidth
                          value={capitalizeFirstLetter(leadUpc?.packagingType)}

                        />

                      </div>
                    </div>

                    <div>
                      <div className="lead-management-tablehead flex justify-between items-start  pt-3 pb-3 border gap-x-2">

                        <div className="lead-management-tablehead-heading ml-2  w-40">
                          SKU Code.
                        </div>
                        <div className="lead-management-tablehead-heading  w-48">
                          Warehouse
                        </div>
                        <div className="lead-management-tablehead-heading  w-48">
                          Base price
                        </div>
                        <div className="lead-management-tablehead-heading mr-2  w-20">
                          Net weight
                        </div>
                      </div>
                      <DisplaySecondaryLots catalogueName={leadUpc?.catalogueName} />
                    </div>

                  </div>


                </div>


              </div>
            </div>
          );
        })}


      <div className="product-detail-container grid gap-3  p-3  text-[#2A2A2A] ">
        <div className="font-medium text-xl ">
          <div className="pb-3">Additional Charges</div>
          <div className="border"></div>
        </div>

        <div className="flex gap-2">
          <TextField
            required
            type="number"
            name="warehouseCharges"
            id="outlined-basic"
            label="Warehouse Charges /MT"
            variant="outlined"
            value={charges[0].warehouseCharges || ""}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            required
            type="number"
            name="handlingCharges"
            id="outlined-basic"
            label="Handling Charges /MT"
            variant="outlined"
            value={charges[0].handlingCharges || ""}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            required
            type="number"
            name="packagingCharges"
            id="outlined-basic"
            label="Packaging Charges /MT"
            variant="outlined"
            value={charges[0].packagingCharges || ""}
            onChange={handleInputChange}
            fullWidth
          />
        </div>
        <div className="flex gap-2">
          <TextField
            type="number"
            name="otherCharges"
            id="outlined-basic"
            label="Other Charges"
            variant="outlined"
            value={charges[0].otherCharges || ""}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            required
            type="number"
            name="gst"
            id="outlined-basic"
            label="GST %"
            variant="outlined"
            value={charges[0].gst || ""}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            required
            type="number"
            name="tcs"
            id="outlined-basic"
            label="TCS %"
            variant="outlined"
            value={charges[0].tcs || ""}
            onChange={handleInputChange}
            fullWidth
          />
        </div>
      </div>

      <div className="product-detail-container grid gap-6  p-3  text-[#2A2A2A] ">
        <div className=" grid gap-2">
          <div className="">
            <div className="font-medium text-xl pb-3 flex justify-start w-full ">
              <p>Payment Information</p>
            </div>
            <div className="border"></div>
          </div>
          <div className="grid gap-3 text-[#2A2A2A]  pb-2">
            <div className="flex justify-between text-base font-medium">
              <p>Sub Total</p>
              <p>&#8377; {new Intl.NumberFormat().format(pricingData?.productsSubTotal || 0)}</p>
            </div>
            <div className="flex justify-between text-base font-normal ">
              <p>
                Warehousing charges{" "}
                <span className="text-sm">({leadQuantity.toFixed(3)} MT)</span>
              </p>
              <p>&#8377; {new Intl.NumberFormat().format(pricingData?.warehousePrice || 0)}</p>
            </div>
            <div className="flex justify-between text-base font-normal ">
              <p>
                Handling charges{" "}
                <span className="text-sm">({leadQuantity.toFixed(3)} MT)</span>
              </p>
              <p>&#8377; {new Intl.NumberFormat().format(pricingData?.handlingPrice || 0)}</p>
            </div>
            <div className="flex justify-between text-base font-normal ">
              <p>
                Packaging charges{" "}
                <span className="text-sm">({leadQuantity.toFixed(3)} MT)</span>
              </p>
              <p>&#8377; {new Intl.NumberFormat().format(pricingData?.packagingPrice || 0)}</p>
            </div>
            <div className="flex justify-between text-base font-normal ">
              <p>Other charges</p>
              <p>&#8377; {new Intl.NumberFormat().format(pricingData?.otherPrice || 0)}</p>
            </div>
            <div className="flex justify-between text-base font-semibold  ">
              <p>Sub Total (Incl Charges)</p>
              <p>&#8377; {new Intl.NumberFormat().format(pricingData?.subTotal || 0)}</p>
            </div>
            <div className="border"></div>
            <div className="flex justify-between text-base font-normal ">
              <p>GST</p>
              <p>&#8377; {new Intl.NumberFormat().format(pricingData?.totalGst) || 0}</p>
            </div>
            <div className="flex justify-between text-base font-normal ">
              <p>TCS</p>
              <p>&#8377; {new Intl.NumberFormat().format(pricingData?.totalTcs) || 0}</p>
            </div>
            <div className="border"></div>
          </div>
          <div className="">
            <div className="flex justify-between text-[#2A2A2A] text-xl font-medium">
              <p>Total amount</p>
              <p>
                &#8377;{" "}
                {new Intl.NumberFormat().format(pricingData?.grandTotal || 0)}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="m-auto mr-0 flex gap-x-3">
        <CustomButton onClick={handleRedirectBack}>Cancel</CustomButton>
        <CustomButton
          onClick={() => {
            handleSave();
          }}
        >
          Save
        </CustomButton>
        <CustomButton
          variant="Primary"
          onClick={() => {
            handlePublish();
          }}
        >
          Publish
        </CustomButton>
      </div>
    </div>
  );
};

export default CreateMTOQuotation;
