import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export const MANUFACTURER_URLS = {
  GET_ALL_MANUFACTURER: "/manufacturers",
  GET_MANUFACTURER: "/manufacturer/:id",
  CREATE_MANUFACTURER: "/manufacturer",
  UPDATE_MANUFACTURER: "/manufacturer/:id",
};

export interface IManufacturerFilterRequest {
  search?: string;
  status?: string;
  name?: string;
  manufacturerType?: string;
  page: number;
  size: number;
  sort?: string;
}

export interface IAddress {
  id?: number;
  type: string;
  email: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
  gmapUrl: string;
  line1: string;
  line2: string;
  countryCode: string;
  mobileNumber: string;
  stdCode: number;
  landline: string;
  resourceType?: string;
  resourceId?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface IPointOfContact {
  salutation: string;
  name: string;
  designation: string;
  email: string;
  altEmail: string;
  countryCode: string;
  mobileNumber: string;
  whatsapp: string;
  stdCode: string;
  landline: string;
  linkedInProfile: string;
  profilePicture: number;
}

export interface IManufacturerRequest {
  id?: number;
  name: string;
  shortName: string;
  code: string;
  logoPath: number;
  status: string;
  manufacturerType: string;
  createdBy: number;
  updatedBy: number;
  categoryIds: number [];
  registeredAddress: IAddress;
  manufacturingLocation: IAddress [];
  poc: IPointOfContact [];
}

export const useManufacturerService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const getManufacturerById = (id: number) => {
    return request(API_METHOD.GET, replaceUrlParams( MANUFACTURER_URLS.GET_MANUFACTURER, { id } ), authenticatedUser);
  };

  const getAllManufacturers = (params: IManufacturerFilterRequest) => {
    return request(API_METHOD.GET, MANUFACTURER_URLS.GET_ALL_MANUFACTURER, authenticatedUser, null, { params } );
  };

  const createManufacturer =(data: IManufacturerRequest)=>{
    return request(API_METHOD.POST, MANUFACTURER_URLS.CREATE_MANUFACTURER, authenticatedUser, data);
  };

  const updateManufacturer = (id: number, data: IManufacturerRequest) => {
    return request(API_METHOD.PUT, replaceUrlParams( MANUFACTURER_URLS.UPDATE_MANUFACTURER, { id } ), authenticatedUser, data);
  };

  return {
    getManufacturerById,
    getAllManufacturers,
    createManufacturer,
    updateManufacturer
  };
};
