
import { useEffect, useState } from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
  } from '@mui/material';


const ParametersForm = ({noOfParameters, parameterData, updateParameterData, loader}) => {
    const [numberOfFields, setNumberOfFields] = useState(0);
    const [formData, setFormData] = useState([]);
    const dynamicParameterData = [
        {
            label: "Customer Name",
            value: "fullName"
        },
        {
            label: "Email ",
            value: "email"
        },
        {
            label: "Phone number",
            value: "mobileNumber"
        },
        {
            label: "Customer Business Name",
            value: "businessName"
        },
        {
            label: "Catalogue Name",
            value: "catalogueName"
        }
    ]

    useEffect(() => {
        setFormData(parameterData)
    }, [parameterData])

    useEffect(() => {
        const parameters = parseInt(noOfParameters,10)
        if(parameterData.length === 0) {
            const updateFormData = new Array(parameters || 0).fill({ type: "STATIC", value: '',fallBackValue: ''});
            setFormData(updateFormData)
            updateParameterData(updateFormData)
        } else {
            if(numberOfFields > parameters){
                const slicedData = parameterData.slice(0, parameters);
                setFormData(slicedData);
                updateParameterData(slicedData)
            } else if(numberOfFields < parameters){
                const existingData = [...parameterData];
                for (let i = parameterData.length; i < parameters; i++) {
                    existingData.push({ type: "STATIC", value: '',fallBackValue: '' });
                }
                setFormData(existingData);
                updateParameterData(existingData)
            }
        }
        setNumberOfFields(parameters || 0);
    }, [noOfParameters])

    const handleInputChange = (index, valueType, event) => {
      const trimmedValue = event.target.value;
      const values = [...formData];
      values[index] = { ...values[index], [valueType]: trimmedValue };
      setFormData(values);
      updateParameterData(values)
    };
    
    const renderFields = () => {
        const fields = [];
        for (let i = 0; i < numberOfFields; i++) {
            fields.push(
                <div key={i} className='flex mt-2 mb-2' style={{ width:"100%"}}>
                    <FormControl fullWidth sx={{ margin: '2px 6px 2px 6px', width:"32%"}}>
                        <InputLabel id='status-label'>Select Parameter Type</InputLabel>
                        <Select
                            labelId='parameter-label'
                            id='parameter-select'
                            label='Select Parameter Type'
                            sx={{ color: 'black' }}
                            value={formData[i]?.type || "STATIC"}
                            onChange={(e) => handleInputChange(i, "type", e)}
                            disabled={loader}
                            inputProps={{ readOnly: true }}
                        >
                            <MenuItem value='STATIC'>Static</MenuItem>
                            <MenuItem value='DYNAMIC'>Dynamic</MenuItem>
                        </Select>
                    </FormControl>
                    {
                        formData[i]?.type === "STATIC" ? (
                            <FormControl fullWidth sx={{ margin: '2px 6px 2px 6px', width:"32%"}}>
                                <TextField
                                    label='Select Parameter Value'
                                    variant='outlined'
                                    fullWidth
                                    name='parameterValue'
                                    value={formData[i]?.value || ''}
                                    onChange={(e) => handleInputChange(i, "value", e)}
                                    required
                                    disabled={loader}
                                />
                             </FormControl>

                        ) : (
                            <FormControl fullWidth sx={{ margin: '2px 6px 2px 6px', width:"32%"}}>
                                <InputLabel id='status-label'>Select Parameter Value</InputLabel>
                                <Select
                                    labelId='parameter-value-label'
                                    id='parameter-value'
                                    label='Select Parameter Value'
                                    sx={{ color: 'black' }}
                                    value={formData[i]?.value || ''}
                                    onChange={(e) => handleInputChange(i, "value", e)}
                                    required
                                    inputProps={{ readOnly: true }}
                                    disabled={loader}
                                >
                                    {dynamicParameterData.map((option, index) => (
                                        <MenuItem key={index} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        )
                    }
                    {
                        formData[i]?.type !== "STATIC" && (
                        <FormControl fullWidth sx={{ margin: '2px 6px 2px 6px', width:"32%" }}>
                            <TextField
                                label='Enter Fallback Value'
                                variant='outlined'
                                fullWidth
                                name='parameterValue'
                                value={formData[i]?.fallBackValue || ''}
                                onChange={(e) => handleInputChange(i, "fallBackValue", e)}
                                required
                                disabled={loader}
                            />
                        </FormControl>)
                    }
                </div>
            );
        }
        return fields;
    };

    return (
        <div style={{ width:"100%"}}>
            {renderFields()}
        </div>
    );
};

export default ParametersForm;