import React from "react";
import { Grid } from "@mui/material";
import { createUseStyles } from 'react-jss';

interface Cards {
  icon: string;
  title: string;
  value: number | string;
}
interface CardListProps {
  cards: Cards[];
}

const useStyles = createUseStyles((theme: any) => ({

   title: {
    color: theme.palette.textV2.primary.primary900
   },
   value: {
    color: theme.palette.textV2.tertiary.tertiary900
   },
}));


const StatusCard: React.FC<CardListProps> = ({ cards }) => {

    const classes = useStyles();
  return (
    <Grid container spacing={3}>
      {cards.map((customerCard, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <div className="w-full p-4 rounded-2xl" style={ { backgroundColor: '#FFF3EC', backgroundSize: "cover" } } >
          <div className={`${classes.title} text-base font-medium mb-6`}> {customerCard.title}</div>
            <div className="flex justify-between items-center" >
              <span className={`${classes.value} text-2xl font-bold	`}> {customerCard.value}</span>
              <img src={customerCard.icon} alt="icon" />
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};
export default StatusCard;