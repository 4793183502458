import Button from "@mui/material/Button";
import cancelIcon from "./../../../../Assets/Icons/Vector.svg";
import React, { useEffect, useState } from "react";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { formatDate } from "../../../../Core/Handlers/DateFormatter";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { useAuthenticatedUser } from "../../../../../hooks/useAuthenticatedUser";
import { toCamelCase } from "../../../../../utils/helper";

const BaseUrl = process.env.REACT_APP_API_V1_URL;
const ChemicalCompositionDetails = ({
  type,
  onClose,
  record,
  handleAction,
  associatedGrade = null,
  itemindex = null,
  fullData = []
}) => {
  const { user } = useAuthenticatedUser();
  const maxId = fullData.reduce((max, obj) => (obj.id > max ? obj.id : max), 0);
  const [formData, setFormData] = useState({
    type: "CHEMICAL",
    id: record ? record?.id : maxId + 1,
    name: record ? record?.name : "",
    maximum: record ? record?.maximum : "",
    minimum: record ? record?.minimum : "",
    createdAt: record ? record?.createdAt : formatDate(new Date()),
    updatedAt: record ? record?.updatedAt : formatDate(new Date()),
  });

  const chemicalCompositionInitialValues = {
    type: "CHEMICAL",
    id: record?.id || maxId + 1,
    name: record?.name || "",
    maximum: record?.maximum || "",
    minimum: record?.minimum || "",
    createdAt: record?.createdAt || formatDate(new Date()),
    updatedAt: formatDate(new Date()),
  };

  // FORMIK CODE
  const validateFormikFields = yup.object({
    name: yup.string().required("Name is required"),

    maximum: yup
      .number()
      .typeError("Maximum value must be a number")
      .required("Maximum value is required"),
    minimum: yup
      .number()
      .typeError("Minimum value must be a number")
      .required("Minimum value is required"),
  });

  const chemicalCompositionFormik = useFormik({
    initialValues: chemicalCompositionInitialValues,
    validationSchema: validateFormikFields,
    onSubmit: (values) => {
      handleAction(values, values?.id);
    },
  });

  const displayTitle = (data) => {
    if (data === "ADD") {
      return "Add";
    } else if (data === "VIEW") {
      return "View";
    } else if (data === "EDIT") {
      return "Edit";
    }
  };

  const disableComponent = (data) => {
    if (data === "VIEW") {
      return true;
    } else {
      return false;
    }
  };

  const displayNewKeyWord = (data) => {
    if (data === "ADD") {
      return "New";
    }
  };

  const handleInputChange = (key, event) => {
    setFormData({ ...formData, [key]: event.target.value });
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };


  return (
    <form onSubmit={chemicalCompositionFormik.handleSubmit} onKeyDown={handleKeyDown}    >
      <div className="relative bg-white w-[900px]  flex flex-col p-6 box-border items-start justify-start gap-[24px] text-left text-3xl text-text-color font-small-text">
        <div className="self-stretch flex flex-row pt-0 px-0 pb-[18px] items-center justify-start gap-[10px] border-b-[1px] border-solid border-input-border">
          <div className="flex-1 relative leading-[20px] font-semibold">
            {displayTitle(type)} {displayNewKeyWord(type)} Element
          </div>

          <Button onClick={onClose}>
            <img
              className="relative w-4 h-4 overflow-hidden shrink-0 object-cover"
              alt=""
              src={cancelIcon}
            />
          </Button>
        </div>

        <div className="self-stretch flex flex-col items-start justify-start gap-[18px] text-base">
          <div className="self-stretch  flex flex-col items-center justify-start text-sm">
            <FormControl sx={{ width: "100%" }}>
              <TextField
                className={`h-[3rem] block w-full p-1 border border-gray-500 rounded-md focus:outline-none ring-blue-500 focus:border-blue-500 bg-gray`}
                id="outlined-basic"
                label="Grade"
                variant="outlined"
                fullWidth
                value={associatedGrade.name}
                disabled={true}
              />
            </FormControl>
          </div>

          <div className="self-stretch  flex flex-col items-center justify-start text-sm">
            <FormControl sx={{ width: "100%" }}>
              <TextField
                className={`h-[3rem] block w-full p-1 border border-gray-500 rounded-md focus:outline-none ring-blue-500 focus:border-blue-500`}
                id="outlined-basic"
                label="Element Name/Code"
                variant="outlined"
                fullWidth
                name="name"
                value={chemicalCompositionFormik.values.name}
                onChange={chemicalCompositionFormik.handleChange}
                onBlur={(event) => {
                  const camelCasedValue = toCamelCase(
                    event.target.value
                  );
                  chemicalCompositionFormik.setFieldValue("name", camelCasedValue);
                  chemicalCompositionFormik.handleBlur(event);
                }}
                error={
                  chemicalCompositionFormik.touched.name &&
                  Boolean(chemicalCompositionFormik.errors.name)
                }
                InputProps={{
                  readOnly: disableComponent(type),
                }}
              />
            </FormControl>
          </div>

          <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
            <div className="relative leading-[23px] font-medium mb-1">
              Enter % Value
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-[22px]">
              <div className="flex-1 h-12 flex flex-col items-center justify-center">
                <FormControl sx={{ width: "100%" }}>
                  <TextField
                    className={`h-[3rem] block w-full p-1 border border-gray-500 rounded-md focus:outline-none ring-blue-500 focus:border-blue-500`}
                    id="outlined-basic"
                    label="Minimum (0 - 99.9999)"
                    variant="outlined"
                    fullWidth
                    name="minimum"
                    value={chemicalCompositionFormik.values.minimum}
                    onChange={(event) => {
                      if (/^\d{0,2}(\.\d{0,4})?$/.test(event.target.value)) {
                        chemicalCompositionFormik.handleChange(event);
                      } else {
                        chemicalCompositionFormik.setErrors({
                          ...chemicalCompositionFormik.errors,
                          [event.target.name]: 'Invalid format',
                        });
                      }
                    }}
                    onBlur={chemicalCompositionFormik.handleBlur}
                    error={
                      chemicalCompositionFormik.touched.minimum &&
                      Boolean(chemicalCompositionFormik.errors.minimum)
                    }
                    helperText={
                      chemicalCompositionFormik.touched.minimum ? chemicalCompositionFormik.errors.minimum : ""
                    }
                    InputProps={{
                      readOnly: disableComponent(type),
                    }}
                  />
                </FormControl>
              </div>
              <div className="flex-1 h-12 flex flex-col items-center justify-center">
                <FormControl sx={{ width: "100%" }}>
                  <TextField
                    className={`h-[3rem] block w-full p-1 border border-gray-500 rounded-md focus:outline-none ring-blue-500 focus:border-blue-500`}
                    id="outlined-basic"
                    label="Maximum (0 - 99.9999)"
                    variant="outlined"
                    fullWidth
                    name="maximum"
                    value={chemicalCompositionFormik.values.maximum}
                    onChange={(event) => {
                      if (/^\d{0,2}(\.\d{0,4})?$/.test(event.target.value)) {
                        chemicalCompositionFormik.handleChange(event);
                      } else {
                        chemicalCompositionFormik.setErrors({
                          ...chemicalCompositionFormik.errors,
                          [event.target.name]: 'Invalid format',
                        });
                      }
                    }}
                    onBlur={chemicalCompositionFormik.handleBlur}
                    error={
                      chemicalCompositionFormik.touched.maximum &&
                      Boolean(chemicalCompositionFormik.errors.maximum)
                    }
                    helperText={
                      chemicalCompositionFormik.touched.maximum ? chemicalCompositionFormik.errors.maximum : ""
                    }

                    InputProps={{
                      readOnly: disableComponent(type),
                    }}
                  />
                </FormControl>
              </div>
            </div>
          </div>
        </div>

        <div className="self-stretch flex flex-row items-center justify-end gap-[22px] text-mini text-text-color">
          <Button
            sx={{
              border: "1px solid blue",
              padding: "0.71rem .63rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
            }}
            variant="outlined"
            onClick={onClose}
          >
            <div className="relative leading-[1.38rem] font-semibold text-blue">
              Cancel
            </div>
          </Button>

          {type !== "VIEW" && (
            <button
              className="outlined rounded bg-blue overflow-hidden flex flex-row py-[0.81rem] px-[1.63rem] items-center justify-center text-[0.94rem] text-white"
              type="submit"
            >
              <div className="relative leading-[1.38rem] font-semibold">
                {type === "ADD" ? "Add Element" : "Update Element"}
              </div>
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default ChemicalCompositionDetails;
