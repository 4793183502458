import searchIcon from "./../../Assets/Icons/searchIcon.svg";
import axios from 'axios';
import cancelIcon from "./../../Assets/Icons/cancelIcon.svg";
import {
    TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Autocomplete from '@mui/material/Autocomplete';
import FormHelperText from "@mui/material/FormHelperText";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { showAttributesUom } from "../../../utils/helper";
import { SHAPE_TYPES } from "../../../utils/constant";

const BaseUrl = process.env.REACT_APP_API_V1_URL;

const SecondarySearch = ({ type, existingUpcCode = "", isCheckBoxView = false, setMinimumQuantity, secondaryProductIds, setSecondaryProductIds, formErrors, setIsButtonDisabled, catalogueType }) => {
    const { user } = useAuthenticatedUser();
    const [searchUPC, setSearchUPC] = useState(existingUpcCode);
    const [searchUPCId, setSearchUPCId] = useState("");
    const [isInvalid, setIsInvalid] = useState(false);
    const [suggestionsList, setSuggestionsList] = useState([]);
    const [productDetails, setProductDetails] = useState([]);
    const [stateMode, setStateMode] = useState("EDIT");
    useEffect(() => {
        fetchSecondaryDetails();
    }, [secondaryProductIds, stateMode]);

    useEffect(() => {
        let totalNetWeight = 0;
        productDetails.forEach(productDetail => {
            if (productDetail?.netWeight > 0) {
                totalNetWeight += productDetail?.netWeight;
            }
        });
        setMinimumQuantity(totalNetWeight);
    }, [productDetails]);

    const filterUpcCodesFromList = (data) => {

        if (!data || data.length === 0) {
            console.error('Data array is empty or invalid');
            return [];
        }

        const filteredList = [];

        for (const item of data) {
            if (!item.skuCode) {
                console.error('secondaryCode is missing in an element:', item);
                continue;
            }
            filteredList.push(item.skuCode);

        }

        return filteredList;

    }
    const handleRemoveSKUDetails = (skuCodeToDelete, id) => {
        const updatedDetails = productDetails.filter((detail) => detail.skuCode !== skuCodeToDelete);
        setSecondaryProductIds(prevDetails => prevDetails.filter(item => item !== id));
        setProductDetails(updatedDetails);
    };

    const fetchUpcDetails = async () => {
        await axios.post(`${BaseUrl}/secondary-products`, { catalogueTypes: [catalogueType] },
            {
                headers: {
                    Authorization: `${user.token}`,
                    "Content-Type": "application/json",
                }
            }
        ).then(response => {
            setSuggestionsList(filterUpcCodesFromList(response.data.data.content));
        })
            .catch(error => {

            });
    };

    useEffect(() => {
        fetchUpcDetails()
    }, []);

    const fetchSecondaryDetails = async () => {
        if (stateMode === 'EDIT') {
            for (const secondaryProductId of secondaryProductIds) {
                try {
                    const response = await axios.get(`${BaseUrl}/secondary-product/${secondaryProductId}`, {
                        headers: {
                            Authorization: `${user.token}`,
                            "Content-Type": "application/json",
                        }
                    });
                    const isDetailPresent = productDetails.some((existingDetail) => (existingDetail.skuCode === response.data.data.skuCode || areObjectsEqual(existingDetail, response.data.data)));
                    if (!isDetailPresent) {
                        setProductDetails(prevDetails => [response.data.data, ...prevDetails]);
                    }
                    setIsInvalid(false);
                } catch (err) {
                    return false;
                }
            }
        }
    };

    function areObjectsEqual(obj1, obj2) {
        let compareDetails = [
            'shape',
            'productCategoryId',
            'warehouseId'
        ]
        for (const key of compareDetails) {
            if (obj1[key] !== obj2[key]) {
                return true;
            }
        }
        return false;

    }
    function alertMessage() {
        window.confirm(`Ensure that the chosen SKUs are distinct while belonging to the same category`);
    }

    const handleUpcSearch = async () => {
        setIsButtonDisabled(false);
        if (searchUPC.trim() === "") {
            setIsInvalid(true);
            return false;
        }
        try {
            const response = await axios.get(`${BaseUrl}/secondary-product/${searchUPCId}`, {
                headers: {
                    Authorization: `${user.token}`,
                    "Content-Type": "application/json",
                }
            });
            if (catalogueType === "CURRENT_INVENTORY") {
                setSecondaryProductIds([response.data.data.id]);
                setProductDetails([response.data.data]);
            } else {
                const isDetailPresent = productDetails.some((existingDetail) => (existingDetail.skuCode === response.data.data.skuCode || areObjectsEqual(existingDetail, response.data.data)));
                if (!isDetailPresent) {
                    setSecondaryProductIds(prevDetails => [response.data.data.id, ...prevDetails]);
                    setProductDetails(prevDetails => [response.data.data, ...prevDetails]);
                } else {
                    alertMessage()
                    return false;
                }

            }
            setIsInvalid(false);
        } catch (err) {
            console.log(err)
            return false;
        }
    };
    useEffect(() => {
    }, [productDetails])

    const handleSearchUPCChange = async (value) => {
        setSearchUPC(value);
        setIsInvalid(false);
        if (value.trim()) {
            fetchUpcDetails2(value);
        } else if (searchUPC.trim()) { 
            fetchUpcDetails2("");
        }
    };
    const fetchUpcDetails2 = async (value) => {
        await axios
            .post(
                `${BaseUrl}/secondary-products?skuCode=${value}`,  { catalogueTypes: [catalogueType] },
                {
                    headers: {
                        Authorization: `${user.token}`,
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((response) => {
                setSuggestionsList(
                    filterUpcCodesFromList(response.data.data.content)
                );
            })
            .catch((error) => { });
    };
    const handleSearchUPCChange2 = async (value) => {
        value &&
            (await axios
                .post(
                    `${BaseUrl}/secondary-products?skuCode=${value}`,
                    { catalogueTypes: [catalogueType] },
                    {
                        headers: {
                            Authorization: `${user.token}`,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((response) => {
                    setStateMode("ADD");
                    setSearchUPCId(response.data.data.content[0].id);
                })
                .catch((error) => { }));
        setSearchUPC(value);
        setIsInvalid(false);
    };
    return (
        <div className="">
            <div className="flex-1 flex flex-row flex-wrap items-start justify-start">
                <div className="flex-1 h-[3rem] flex flex-row items-start justify-start">
                    <div className="flex-1 h-[3rem] flex flex-row items-center justify-start">
                        <Autocomplete
                            className={`flex-1 rounded-md focus:outline-none ${isInvalid ? 'ring-red-500' : 'ring-blue-500'
                                } focus:border-blue-500`}
                            id="upc-autocomplete"
                            options={suggestionsList}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={`flex-1 border ${isInvalid ? 'border-red-500' : 'border-gray-300'
                                        } rounded-md focus:outline-none ${isInvalid ? 'ring-red-500' : 'ring-blue-500'
                                        } focus:border-blue-500`}
                                    label={"Enter Secondary Product Code"}
                                    variant="outlined"
                                    fullWidth
                                    value={searchUPC}
                                    onChange={(e) => handleSearchUPCChange(e.target.value)}
                                    required
                                    error={isInvalid}
                                />
                            )}
                            onInputChange={(event, newValue) => handleSearchUPCChange2(newValue)}
                            inputValue={searchUPC}
                            error={formErrors.secondaryProductId}
                        />

                        <button
                            className="h-[3.3rem] bg-gainsboro-200 w-[4rem] flex items-center justify-center border-[1px] border-solid border-gainsboro-200"
                            onClick={handleUpcSearch}
                        >
                            <img
                                className="max-w-full max-h-full"
                                alt=""
                                src={searchIcon}
                            />
                        </button>
                    </div>

                </div>


            </div>
            {formErrors.secondaryProductId && (
                <FormHelperText style={{ color: '#d32f2f' }}>
                    please enter Secondary Product
                </FormHelperText>
            )}
            {
                (productDetails && productDetails.length > 0) &&

                productDetails.map((productDetail) =>
                    <div className="grid gap-y-4 p-6 mt-4  bg-gray-300 bg-opacity-30 rounded " key={productDetail?.id}>
                        <div className="self-stretch flex flex-row items-center justify-start gap-[24px]">
                            <div className="flex-1 relative font-semibold text-16px">
                                {'SKU Code'} - {productDetail?.skuCode}
                            </div>

                            {(
                                <div className="bg-oreng flex flex-row p-1.5 items-center justify-center">
                                    <button
                                        className="relative w-3 h-3"
                                        onClick={() => handleRemoveSKUDetails(productDetail?.skuCode, productDetail.id)}
                                    >
                                        <img className="relative w-3 h-3" alt="" src={cancelIcon} />
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[12px] border-[1px] border-solid border-input-border">
                            <div className="self-stretch flex-1 flex flex-row p-3 items-start justify-start border-r-[1px] border-solid border-input-border">
                                <div className="flex-1 flex flex-col items-start justify-start">
                                    <div className="self-stretch flex flex-col items-start justify-center gap-[16px]">
                                        <div className="flex flex-col items-start justify-start gap-[6px]">
                                            <div className="relative font-semibold text-16px">Shape</div>
                                            <div className="relative ">{SHAPE_TYPES.find(shape => shape.id === productDetail?.shape)?.label}
                                        </div>
                                        </div>
                                        <div className="flex flex-col items-start justify-start gap-[6px]">
                                            <div className="relative font-semibold text-16px">Warehouse Placement</div>
                                            <div className="relative ">{productDetail?.warehousePlacement}</div>
                                        </div>
                                        <div className="flex flex-col items-start justify-start gap-[6px]">
                                            <div className="relative font-semibold text-16px">quantity</div>
                                            <div className="relative">{productDetail?.quantity}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch flex-1 flex flex-row p-3 items-start justify-start border-r-[1px] border-solid border-input-border">
                                <div className="flex-1 flex flex-col items-start justify-start">
                                    <div className="self-stretch grid grid-cols-2 items-start justify-center gap-[16px]">
                                        {productDetail.attributes?.productSpecification.map((attribute) => (
                                        <div className="flex flex-col items-start justify-start gap-[6px]" key={attribute.name}>
                                            <div className="relative font-semibold text-16px">{attribute.name}</div>
                                                <div className="relative">
                                                {showAttributesUom(attribute.minValue, attribute.maxValue , attribute.uom )}
                                                </div>
                                        </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch flex-1 flex flex-row p-3 items-start justify-start border-r-[1px] border-solid border-input-border">
                                <div className="flex-1 flex flex-col items-start justify-start">
                                    <div className="self-stretch flex flex-col items-start justify-center gap-[16px]">
                                        <div className="flex flex-col items-start justify-start gap-[6px]">
                                            <div className="relative font-semibold text-16px">Gross Weight</div>
                                            <div className="relative ">{productDetail?.grossWeight}</div>
                                        </div>
                                        <div className="flex flex-col items-start justify-start gap-[6px]">
                                            <div className="relative font-semibold text-16px">Net Weight</div>
                                            <div className="relative ">{productDetail?.netWeight}</div>
                                        </div>
                                        <div className="flex flex-col items-start justify-start gap-[6px]">
                                            <div className="relative font-semibold text-16px">Base Price</div>
                                            <div className="relative">{productDetail?.basePrice}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

        </div>

    );
};

export default SecondarySearch;