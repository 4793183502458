import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../atoms/Button";
import TitleHeading from "../../atoms/CustomHeading/TitleHeading";
import LeadQuotationListTemplate from "../../templates/Lead/LeadQuotationList.template";
import { useQuotationService } from "../../../services/useQuotationService";
import { useLeadService } from "../../../services/useLeadService";
import { IDetail, initialDetails } from "./LeadView.page";
import { ADMIN_ROUTES } from "../../../utils/constant";
import { CLASS_TYPE } from "../../../utils/types";
import { makeRoute } from "../../../utils/helper";

export interface IQuotation {
	id: number;
	companyName: string;
	quantity: number;
	status: string;
	createdAt: Date;
	updatedAt: Date;
	length: number;
	quotationUpcResponseDTO: any;
	catalogueType: string;
}

const LeadQuotationList: React.FC = () => {
	const params = useParams();
	const leadService = useLeadService();
	const quotationService = useQuotationService();
	const [quotation, setQuotationTo] = useState<IQuotation[]>([]);

	const loadQuotations = async () => {
		try {
			const queryParams = {
				id: Number(params.id),
				sort: "createdAt,desc",
				page: 0,
				size: 100,
			};
			const quotationResponse = await quotationService.getAllQuotations(
				queryParams
			);
			if (quotationResponse.data?.data?.content?.length) {
				const formattedLeadData: IQuotation[] =
					quotationResponse.data.data.content.map((lead: IQuotation) => ({
						...lead,
						length: lead.quotationUpcResponseDTO.length,
					}));
				setQuotationTo(formattedLeadData);
			} else {
				throw new Error("Quotations fetch failed");
			}
		} catch (error) {
			setQuotationTo([]);
		}
	};

	const [detail, setDetailTo] = useState<IDetail>(initialDetails);
	const [classType, setClassType] = useState<CLASS_TYPE>(CLASS_TYPE.STANDARD)

	const loadLead = async () => {
		try {
			const leadResponse = await leadService.getLeadById(Number(params.id));
			if (leadResponse.data?.data) {
				setDetailTo(leadResponse.data.data);
				setClassType(leadResponse?.data?.data?.catalogueType);
			} else {
				throw new Error("Lead fetch failed");
			}
		} catch (error) {
			setDetailTo(initialDetails);
		}
	};

	useEffect(() => {
		loadLead();
		loadQuotations();
	}, [params.id]);

	const navigate = useNavigate();

	const handleLeadView = () => {
		navigate(`${ ADMIN_ROUTES.LEAD_VIEW }/${ Number(params.id) }`);
	};

	const handleCreateQuoation = () => {
		if (classType === CLASS_TYPE.STANDARD) {
			navigate(makeRoute(ADMIN_ROUTES.QUOTATION_CREATE, { params:  { id: params.id } }));
        } else if (classType === CLASS_TYPE.SECONDARY) {
            navigate(makeRoute(ADMIN_ROUTES.SECONDARY_QUOTATION_CREATE, { params:  { id: params.id } }));
        } else if (classType === CLASS_TYPE.MAKE_TO_ORDER) {
            navigate(makeRoute(ADMIN_ROUTES.MTO_QUOTATION_CREATE, { params:  { id: params.id } }));
        }
	};

	return (
		<div className="grid gap-y-3">
			<div className="flex justify-between">
				<TitleHeading text={ `Lead Id - ${ Number(params.id) }` } />
				<Button variant="outlined" label="Back" onClick={ handleLeadView } />
			</div>
			<LeadQuotationListTemplate 
				quotation = { quotation } 
				details = { detail } 
				handleCreateQuoation = { handleCreateQuoation } 
				classType = { classType }
			/>
		</div>
	);
};

export default LeadQuotationList;
