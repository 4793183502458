import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import searchIcon from "./../../../../Assets/Icons/searchIcon.svg";

const AutoCompleteTextField = ({ textLable, suggestions, onSearch, updateDataSelection, enableFlag, displayValue = ''}) => {
  const [searchValue, setSearchValue] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  useEffect(() => {
    setSearchValue(displayValue);
  }, [displayValue]);

  const handleInputChange = (selectedData) => {
    if (selectedData) {
      updateDataSelection(selectedData, selectedData.id);
      setSearchValue('');
    }
  };

  const handleSearch = (value) => {
    if (value.trim() !== '' || value === '') {
      onSearch(value);
      setSearchValue(value);
      return;
    }
  };
  const onBlurFunction = ( ) => {
    if(displayValue){
      setIsInvalid(false);
    }else{
      setIsInvalid(true);
    }
  }

  return (
    <div className="flex flex-row items-start justify-start gap-[1.38rem]">
      <div className="flex-1 flex flex-row items-center justify-start">
        <Autocomplete
        className={`flex-1 rounded-md focus:outline-none ${isInvalid ? 'ring-red-500' : 'ring-blue-500'
      } focus:border-blue-500`}
                id="autocomplete-input"
          options={suggestions}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              className={`flex-1 border ${isInvalid ? 'border-red-500' : 'border-gray-300'
            } rounded-md focus:outline-none ${isInvalid ? 'ring-red-500' : 'ring-blue-500'
            } focus:border-blue-500`}
              label={textLable}
              variant="outlined"
              fullWidth
              onChange={(event) => handleSearch(event.target.value)}
               error={isInvalid}
               disabled={enableFlag}
              onBlur={onBlurFunction}
            />
          )}
         
          value={suggestions.find(option => option.label === searchValue) || null}
          onChange={(event, newValue) => handleInputChange(newValue)}
          disabled={enableFlag}
        />
      </div>
    </div>
  );
};

export default AutoCompleteTextField;
