import { Dispatch, SetStateAction } from "react";
import CustomHeading from "../../../Shared/CustomHeading/CustomHeading";
import ReviewCard from "./ReviewCard";
import {
  ProductDataType,
  ProductFormData,
} from "../../../Constants/Procurement/PurchaseRequisition/types";
import FormNavigationButton from "./FormNavigationButton";
import { PROCUREMENT_TEXT } from "../../../Constants/Procurement/PurchaseRequisition/constants";

interface ReviewDetailsProps {
  products: ProductDataType[];
  formData: ProductFormData[];
  setCompletedTabs: Dispatch<SetStateAction<number>>;
  onSubmit: () => void;
}

const ReviewDetails = ({
  products,
  formData,
  setCompletedTabs,
  onSubmit,
}: ReviewDetailsProps) => {
  return (
    <div>
      <div className="flex bg-cgray-2 justify-between ">
        <div className="text-blue py-6 px-4">
          <CustomHeading text="Review Requirement Details" status="" />
          <p className="text-cgray-1 text-xs">
            {PROCUREMENT_TEXT.ReviewPRDetailPageHeading}
          </p>
        </div>
      </div>
      <div>
        {products.map((item, index) => (
          <ReviewCard
            key={item.id + "ReviewCard"}
            index={index}
            item={item}
            values={formData[index]}
            handleEdit={() => setCompletedTabs(2)}
          />
        ))}
      </div>
      <div className="shadow-pagination px-4 py-3 sticky mt-4 z-50 bg-white bottom-4 w-full text-blue flex justify-between items-center rounded-lg">
        <div className="w-full"></div>
        <FormNavigationButton
          onSubmitClick={onSubmit}
          onBackClick={() => setCompletedTabs(2)}
          isLastStep={true}
        />
      </div>
    </div>
  );
};

export default ReviewDetails;
