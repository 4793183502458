import { Dialog } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TextFieldV2 from '../../atoms/TextFieldV2'
import { createUseStyles } from 'react-jss';
import CloseIcon from '@mui/icons-material/Close';
import ButtonV2 from '../../atoms/ButtonV2';
import { IInstantQuotation, IInstantQuotationVASProductDetails } from '../../pages/ManageQuotation/QuoteCreate.page';
import { FormikProps } from 'formik';
import Radio from '@mui/material/Radio';
import SelectCustomizationTemplate, { IValueAddedService } from './Customization/SelectCustomization.template';
import { IMasterUpcDetailsRequestBody, useMasterUpcService } from '../../../services/useMasterUpcService';
import { HTTP_STATUS } from '../../../utils/types';
import { IMasterUpcs } from '../../pages/UpcListing/UpcListing.page';
import { useSnackbar } from '../../../hooks/useSnackBar';

interface IVasDetailsProps {
    formik: FormikProps<IInstantQuotation>;
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
    triggerButton: React.ReactNode;
    setVasDetails: (details: any) => void;
    vasDetails: IInstantQuotationVASProductDetails;
    setBrandUpcIdTo: (details: any) => void;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        borderBottom: `1px solid ${theme.palette.border.tritiaryLight}`
    },
    box: {
        "& .MuiDialog-paper": {
            width: "894px !important",
            maxWidth: "894px !important",
            borderRadius: "16px",
            padding: '24px'
        },
    },
    dialogBox: {
        border: `1px solid ${theme.palette.border.primarylightAccent}`,
        borderRadius: '8px'
    },
    closeIcon: {
        color: '#696E7C'
    },
    text: {
        color: theme.palette.textV2.primary.primary900
    },
    header: {
        background: theme.palette.background._primaryLightAccent,
    },
}));

export const AddVasDetailsTemplate: React.FC<IVasDetailsProps> = ({ dialogOpen, setDialogOpen, formik, triggerButton, setVasDetails, vasDetails, setBrandUpcIdTo }) => {
    const classes = useStyles();
    const masterUpcService = useMasterUpcService();

    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const [masterUpc, setMasterUpcTo] = useState<IMasterUpcs>();
    const [selectedProductCode, setSelectedProductCode] = useState<string | null>(null);
    const [isCustomizationOpen, setIsCustomizationOpen] = useState<boolean>(false);
    const [currentCartItem, setCurrentCartItem] = useState<{ quantity: number, id: number } | null>(null);
    const [valueAddedService, setValueAddedServiceTo] = useState<IValueAddedService>({
        shearing: [],
        slitting: [],
    })

    useEffect(() => {
        setValueAddedServiceTo({
            shearing: formik.values?.vasDetails?.find(product => product.productCode?.split(' ')?.[0] === selectedProductCode?.split(' ')?.[0])?.shearingDetails ?? [],
            slitting: formik.values?.vasDetails?.find(product => product.productCode?.split(' ')?.[0] === selectedProductCode?.split(' ')?.[0])?.slittingDetails ?? []
        });
    }, [selectedProductCode,formik.values?.vasDetails])

    useEffect(() => {
        setVasDetails({});
    }, [selectedProductCode])

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleProductSelect = (productCode: string) => {
        setSelectedProductCode(productCode);
    };

    const handleNextClick = () => {
        if (selectedProductCode !== null) {
            const selectedProduct = formik.values.productDetails.find(product => product.productCode?.split(' ')?.[0] === selectedProductCode?.split(' ')?.[0]);
            const preSelectedVasProduct = formik.values.vasDetailsExtracted.find(product => product.productId === selectedProduct?.productId);
            if (selectedProduct) {
                const remainingQuantity = (selectedProduct?.quantity ?? 0) - (preSelectedVasProduct?.quantity ?? 0);

                const cartItem = {
                    quantity: preSelectedVasProduct ? Number(remainingQuantity.toFixed(2)) : Number((selectedProduct?.quantity ?? 0).toFixed(2)),
                    id: selectedProduct.productId
                };
                setCurrentCartItem(cartItem);
                fetchUpc(cartItem.id);
                setBrandUpcIdTo(selectedProduct);
            }
        }
        setDialogOpen(false);
    };

    const fetchUpc = async (productId: number) => {
        if (productId) {
            try {
                let params: IMasterUpcDetailsRequestBody = {
                    upcTypes: "BRAND_UPC",
                }
                const upcResponse = await masterUpcService.getProductUpcByIdV2(productId, params);
                if (upcResponse?.status === HTTP_STATUS.OK) {
                    const productDetails = upcResponse.data.data;
                    setMasterUpcTo(productDetails)
                    setIsCustomizationOpen(true);
                }
                else {
                    showSnackbar("error", "UPC fetch failed");
                }
            } catch (error) {
                console.error("error", `UPC fetch failed`)
            }
        }
    }

    const columnNames = [
        'Product description',
        'Location',
        'HSN Code',
        'Qty.(MT)',
        'Unit Price/MT',
    ];

    const details = formik.values.productDetails;

    return (
        <>
            {React.cloneElement(triggerButton as React.ReactElement)}
            <Dialog
                fullWidth
                className={`${classes.box}`}
                PaperProps={{ style: { height: 'auto' } }}
                open={dialogOpen}
                onClose={handleDialogClose}
            >
                <div>
                    <div className="flex justify-between cursor-pointer" onClick={handleDialogClose}>
                        <span className={`text-2xl font-semibold ${classes.text}`}>Select Product for VAS</span>
                        <CloseIcon className={classes.closeIcon} />
                    </div>

                    <div className="border rounded-2xl mt-4">
                        <div className={`text-sm text-center p-4 font-medium rounded-lg ${classes.header}`}>
                            <div className="grid grid-cols-6 text-center gap-4">
                                {columnNames.map((columnName, index) => (
                                    <div key={index} className={index === 0 ? 'col-span-2' : ''}>{columnName}</div>
                                ))}
                            </div>
                        </div>

                        <div className="p-4">
                            {details?.map((detail, index) => (
                                <div key={index}>
                                    <div className="grid grid-cols-6 gap-4 p-2">
                                        <div className='flex col-span-2'>
                                            <Radio
                                                checked={selectedProductCode === detail.productCode}
                                                onChange={() => handleProductSelect(detail.productCode)}
                                                value={detail.productCode}
                                                name="product-select"
                                                color="primary"
                                            />
                                            <span className='text-sm'>{formik.values.productDetails[index]?.productCode || ''}</span>
                                        </div>

                                        <TextFieldV2
                                            name={`productDetails[${index}].location`}
                                            value={formik.values.productDetails[index]?.location || ''}
                                            onChange={formik.handleChange}
                                            variant="outlined"
                                            disabled
                                        />
                                        <TextFieldV2
                                            name={`productDetails[${index}].hsnCode`}
                                            value={formik.values.productDetails[index]?.hsnCode || ''}
                                            onChange={formik.handleChange}
                                            variant="outlined"
                                            disabled
                                        />
                                        <TextFieldV2
                                            type="number"
                                            name={`productDetails[${index}].quantity`}
                                            value={formik.values.productDetails[index]?.quantity || ''}
                                            onChange={formik.handleChange}
                                            variant="outlined"
                                            inputProps={{ min: 0 }}
                                            disabled
                                        />
                                        <TextFieldV2
                                            type="number"
                                            name={`productDetails[${index}].unitPrice`}
                                            value={formik.values.productDetails[index]?.unitPrice || ''}
                                            onChange={formik.handleChange}
                                            variant="outlined"
                                            inputProps={{ min: 0, step: "0.01", pattern: "^[0-9]*[.,]?[0-9]{0,2}$" }}
                                            disabled
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex gap-2 justify-end pt-4">
                        <ButtonV2
                            variant={'tertiaryContained'}
                            label={'Cancel'}
                            onClick={handleDialogClose}
                        />
                        <ButtonV2
                            variant={'primaryContained'}
                            label={'Next'}
                            onClick={handleNextClick}
                        />
                    </div>
                </div>
            </Dialog>
            {isCustomizationOpen && currentCartItem && (
                <div>
                    <SelectCustomizationTemplate
                        quantity={currentCartItem.quantity}
                        selectedUpc={masterUpc}
                        setVasDetails={setVasDetails}
                        vasDetails={vasDetails}
                        valueAddedService={valueAddedService}
                        setValueAddedServiceTo={setValueAddedServiceTo}
                        isCustomizationOpen={isCustomizationOpen}
                        setIsCustomizationOpen={setIsCustomizationOpen}
                    />
                </div>
            )}
        </>
    );
};
