import axios from 'axios';
import { PRParamsType } from '../App/Constants/Procurement/PurchaseRequisition/types';
import { useAuthenticatedUser } from '../hooks/useAuthenticatedUser';
import { API_METHOD } from '../utils/constant';
import { createQueryString, removeEmptyFields } from '../utils/helper';
import { request } from './index';

const PURCHASE_REQUISITION_URLS = {
  UPC_SEARCH: 'search/upc',
  GET_ALL_PURCHASE_REQUISITIONS: 'purchase-requisition',
  CREATE_PURCHASE_REQUISITION: 'purchase-requisition',
  GET_CATEGORIES: 'categories',
};

export const usePurchaseRequisition = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const getPurchaseRequistions = (params: Partial<PRParamsType>) => {
    params = removeEmptyFields(params);
    return request(
      API_METHOD.GET,
      PURCHASE_REQUISITION_URLS.GET_ALL_PURCHASE_REQUISITIONS,
      authenticatedUser,
      null,
      { params }
    );
  };

  const createPurchaseRequisition = (items: any) => {
    return request(
      API_METHOD.POST,
      PURCHASE_REQUISITION_URLS.CREATE_PURCHASE_REQUISITION,
      authenticatedUser,
      { items }
    );
  };

  // @yash V2 url is used here to be removed later
  const searchUpcByParams = (params: any) => {
    return axios({
      method: API_METHOD.POST,
      headers: {
        Authorization: `${authenticatedUser?.token}`,
        'Content-Type': 'application/json',
      },
      url: `${process.env.REACT_APP_API_V2_URL}/${
        PURCHASE_REQUISITION_URLS.UPC_SEARCH
      }?${createQueryString(params)}`,
      data: {}
    });
  };

  const getProductCategory = () => {
    return request(
      API_METHOD.GET,
      PURCHASE_REQUISITION_URLS.GET_CATEGORIES,
      authenticatedUser
    );
  };

  return {
    getPurchaseRequistions,
    createPurchaseRequisition,
    searchUpcByParams,
    getProductCategory,
  };
};
