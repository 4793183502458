import React from "react";

interface Props {
  headings: string[];
}

const Heading: React.FC<Props> = ({ headings }) => {
  return (
   
    <div className="lead-management-tablehead flex gap-x-2 justify-between items-start  pt-3 pb-3">
      {headings.map((heading, index) => (
        <div
        key={index}
        className={`lead-management-tablehead-heading
        ${index === 0 ? "w-14 ml-3  " : ``}
        ${heading === "SKU Code" ? " w-36" : ``}
        ${heading === "SKUCode" ? " w-36" : ``}
        ${heading === "Sales Representative" ? " w-36" : ``}
        ${heading === "UPC Code" ? " w-32 " : ``}
        ${heading === "Product Code" ? " w-32 " : ``}
        ${heading === "MPC Code" ? " w-28 " : ``}
        ${heading === "Product" ? " w-32" : ``}
        ${heading === "Quantity" ? " w-20 " : ``}
        ${heading === "Warehouse" ? " w-28 " : ``}
        ${heading === "Lead ID" ? " w-24  " : ``}
          ${heading === "No.Of Item" ? " w-20 " : ``}
          ${heading === "Customer Name" ? " w-28 "  : ``}
          ${heading === "Company Name" ? " w-28   " : ``}
          ${heading === "Lead Status" ? " w-28 " : ``}
          ${heading === "Status" ? " w-28 " : ``}
          ${heading === "Created Date" ? " w-36 " : ``}
          ${heading === "Modified Date" ? " w-36" : ``}
          ${heading === "Updated Date" ? " w-36 " : ``}
          ${heading === "Quotation ID" ? " w-24  " : ``}
          ${heading === "Publish Status" ? " w-28 " : ``}
          ${heading === "Total UPC" ? " w-20  " : ``}
          ${heading === "Quotation Status" ? " w-28  " : ``}
          ${heading === "UPC ID" ? " w-28  " : ``}
          ${heading === "Product Quantity" ? " w-28" : ``}
          ${heading === "Product Name" ? "w-96" : ``}


         ${index === headings.length - 1 ? "w-16 mr-3  flex justify-end" : ``}`}
      >
          {heading}
        </div>
      ))}
    </div>
  );
};

export default Heading;
