import { useEffect, useRef, useState } from "react";
import {  MY_MATERIAL_STATE } from "../../../../utils/types";
import { useSnackbar } from "../../../../hooks/useSnackBar";
import { createUseStyles } from "react-jss";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Carousel from 'react-material-ui-carousel'
import deleteIcon from "../../../../assets/images/deleteOutlinedSmall.svg";
import { useNavigate } from "react-router-dom";
import { useScrapProductService } from "../../../../services/useScrapProductService";
import dummyCardImage from '../../../../assets/images/dummyCardImage.png'
import outlinedEdit from "../../../../assets/icons/editOutline.svg";
import { ScrapProductsProps } from "./ScrapProduct.template";
import { capitalizeFirstLetter } from "../../../../utils/helper";
import { SCRAP_PRODUCTS, SCRAP_PRODUCTS_CATEGORY, SCRAP_PRODUCTS_TYPE } from "../../../../utils/constant";
import { IOTHER_PROPS } from '../../../pages/CustomerManagement/MyMaterial.page';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import { TablePagination, Input } from "@mui/material";
import { HTTP_STATUS, IPagination } from '../../../../utils/types';
import { TablePaginationActionsProps } from "../FinishedProducts/FinishedProductListing.template";

interface ScrapProductListingTemplateProps {
    selectedFile?: (string | null)[];
    loadScrapProduct: () => void;
    scrapProducts: ScrapProductsProps[];
    updateMaterialStateTo: (value: MY_MATERIAL_STATE, other: IOTHER_PROPS) => void;
    setPaginationTo: (value: any) => void;
    pagination: IPagination;
}

const useStyles = createUseStyles((theme: any) => ({
    contentWrapper: {
        marginTop: '-100px',
        background: theme.palette.backgroundV2.tertiary.tertiary500,
        borderRadius: "16px 16px 10px 10px",
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
    },
    wrapper: {
        borderBottom: `1px solid ${theme.palette.backgroundV2.secondary.secondary100}`,
    },
    name: {
        color: theme.palette.textV2.secondary.secondary900,
    },
    description: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    applicationType: {
        color: theme.palette.textV2.primary.primary500,
        background: theme.palette.backgroundV2.primary.primary50,
    },
    customButtonContainer: {
        position: "relative",
        bottom: "167px !important",
    },
    customArrow: {
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        cursor: "pointer",
        color: theme.palette.backgroundV2.secondary.secondary500,
        transition: "box-shadow 0.3s ease-in-out",
        boxShadow: `0px 0px 6px 0px ${theme.palette.boxShadow.secondaryDark}`,
        "&:hover": {
            boxShadow: `0px 0px 6px 0px ${theme.palette.boxShadow.primaryDark}`
        }
    },
    customLeftArrow: {
        paddingLeft: "4px",
        background: 'rgba(255,255,255,0.6) !important',

    },
    customRightArrow: {
        paddingLeft: "4px",
        background: 'rgba(255,255,255,0.6) !important',
    },
    paginationTable: {
        "& .MuiTablePagination-selectLabel": {
            color: "#333",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "20.3px",
            letterSpacing: "0.21px"
        },
        "& .MuiTablePagination-input": {
            borderRadius: '8px',
            border: '1px solid #E6E6E6',
            width: '80px',
            paddingRight: '10px',
            marginRight: "24px",
            height: "30px"
        },
        "& .MuiTablePagination-displayedRows": {
            color: "#333",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "20.3px",
            letterSpacing: "0.21px"
        },
        "& .MuiTablePagination-spacer": {
            flex: 0
        },
        "& .MuiToolbar-root": {
            paddingLeft: "0px !important",
            paddingRight: "0px",
            width: "100%"
        },
    },
    paginationComponent: {
        color: theme.palette.pagination.text.secondaryLight,
        fontWeight: 500,
        fontSize: "14px",
        width: "100%"
    },
    customDots: {
        "& .react-multi-carousel-dot-list": {
            bottom: '65px !important'
        },
        "& .react-multi-carousel-dot > button": {
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            border: 'none',
            background: theme.palette.backgroundV2.alphaLight.alphaLight60
        },
        "& .react-multi-carousel-dot--active > button": {
            width: '12px',
            height: '12px',
            borderRadius: '50%',
            border: 'none',
            background: theme.palette.backgroundV2.tertiary.tertiary500
        }
    },
    actionButton: {
        background: 'rgba(255,255,255,0.6)',
        padding: '10px'
    },
    imageContainer: {
        // width: '258px',
        height: '197px'
    },
    cardContainer: {
        width: '258px'
        // width: '100%'
    },
    mode: {
        color: theme.palette.textV2.primary.primary500,
        background: theme.palette.backgroundV2.primary.primary50,
    },
    quantity: {
        background: theme.palette.backgroundV2.tertiary.tertiary500
    },
}))

const ScrapProductListingTemplate: React.FC<ScrapProductListingTemplateProps> = ({ loadScrapProduct, scrapProducts, updateMaterialStateTo, setPaginationTo, pagination }) => {

    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const scrapProductService = useScrapProductService();
    const [hovered, setHovered] = useState<number | null>(null);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination: IPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPaginationTo((prevPagination: IPagination) => ({
            ...prevPagination,
            currentPage: 0,
            pageSize: parseInt(event.target.value, 10)
        }));
    };

    const LeftButton = ({ next, previous }: any) => {
        return (
            <div className={`${classes.customButtonContainer} flex items-center w-full px-3 z-10`}>
                <div className="flex w-full justify-between">
                    <button className={`${classes.customArrow} ${classes.customLeftArrow} flex items-center`} onClick={previous}>
                        <KeyboardArrowLeft sx={{ fontSize: 30 }} />
                    </button>
                </div>
            </div>
        );
    };

    const RightButton = ({ next, previous }: any) => {
        return (
            <div className={`${classes.customButtonContainer} flex items-center w-full px-3 z-10`}>
                <div className="flex w-full justify-between">
                    <button className={`${classes.customArrow} ${classes.customRightArrow} flex items-center mr-0`} onClick={next}>
                        <KeyboardArrowRight sx={{ fontSize: 30 }} />
                    </button>
                </div>
            </div>
        );
    }
    
    const getImageIds = (id: number) => {
        return scrapProducts.filter(scrapProduct => scrapProduct.id === id)?.[0].images.map(image => image.id) ?? [];
    }

    const handleDeleteItem = async (id: number) => {
        try {
            const imageIds = getImageIds(id);
            const deletedItemResponse = await scrapProductService.deleteScrapProductItem(id, imageIds.join(","));
            if (deletedItemResponse.status === HTTP_STATUS.OK) {
                await loadScrapProduct();
            showSnackbar("success", "Successfully deleted Scrap");

            }
        } catch (error) {
            showSnackbar("error", "Unable to delete item");
        }
    }

    const handleUpdateItem = async (id: number) => {
        updateMaterialStateTo(MY_MATERIAL_STATE.SCRAP_UPDATE, {id});
    }

    function TablePaginationActions(props: TablePaginationActionsProps) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;
        const [inputPage, setInputPage] = useState(page + 1);
      
        const handleFirstPageButtonClick = (
          event: React.MouseEvent<HTMLButtonElement>,
        ) => {
          onPageChange(event, 0);
        };
      
        const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          onPageChange(event, page - 1);
        };
      
        const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          onPageChange(event, page + 1);
        };
      
        const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };
    
        const handleInputPageChange = (event: any) => {
          setInputPage(parseInt(event.target.value, 10));
          
        };
        const handleInputBlur = (event: any) => {
            onPageChange(event, inputPage-1);
        };
      
        return (
          <div className={`flex gap-x-6 justify-end  ${classes.paginationComponent}`}  >
            <div className="flex gap-x-2.5">
              <div className='my-auto'>Page</div>
              <div className='my-auto'>
                <Input
                  type="number"
                  value={inputPage}
                  onChange={handleInputPageChange}
                  onBlur={handleInputBlur}
                  disableUnderline={true}
                  size="small"
                  inputProps={{ min: 1, max: Math.ceil(count / rowsPerPage) }}
                  style={{ width: '60px', borderRadius: '8px', border: '1px solid #D6DEF5', paddingLeft: '20px' }}
                />
              </div>
    
              <div className='my-auto'>of</div>
              <div className='my-auto'>{Math.ceil(count / rowsPerPage)}</div>
            </div>
    
            <div className='flex'>
              <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
              >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
              </IconButton>
              <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
              >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              </IconButton>
              <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
              >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              </IconButton>
              <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
              >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
              </IconButton>
            </div>
          </div>
    
        );
      }

    return (
        <>
            {SnackBarComponent}
            <div className="flex flex-wrap gap-4">
                {scrapProducts?.length && scrapProducts.map(({ id, scrapCategory, type, monthlyProduction, saleMode }, index) => {
                    const scrapCategoryValue = SCRAP_PRODUCTS_CATEGORY.find((item) => item.value === scrapCategory);
                    const scrapTypeValue = SCRAP_PRODUCTS_TYPE.find((item) => item.value === type);
                    return (
                        <div className={`relative ${classes.cardContainer}`} key={id} onMouseEnter={() => setHovered(id)} onMouseLeave={() => setHovered(null)}>
                            <div className={`!h-fit !w-full ${classes.imageContainer}`} >
                                <Carousel
                                    className={`${classes.customDots} z-0 relative`}
                                    navButtonsWrapperProps={{ style: { bottom: '-25px', top: 'unset' } }}
                                    indicators={true}
                                    fullHeightHover={false}
                                    navButtonsAlwaysVisible={scrapProducts?.[index]?.images?.length > 1}
                                    cycleNavigation
                                    NextIcon={<RightButton />}
                                    PrevIcon={<LeftButton />}
                                    animation="slide"
                                    autoPlay={false}
                                >
                                    {
                                        !!scrapProducts[index].images.length ? scrapProducts[index].images.map((file) => (
                                            <div className={`overflow-hidden rounded-t-2xl`}>
                                                <img src={file?.path ?? dummyCardImage} alt="icon" className="w-[258px] h-[258px]" />
                                            </div>
                                        )) : <div className={`overflow-hidden rounded-t-2xl`}>
                                            <img src={dummyCardImage} alt="loading" className="w-full h-full" />
                                        </div>
                                    }
                                </Carousel>
                            </div>
                            <div className={`p-4 ${classes.contentWrapper} z-10 relative`}>
                                <div className={`flex flex-col mb-4 ${classes.wrapper}`}>
                                    <span className={`text-base font-semibold mb-1 ${classes.name}`}>{scrapTypeValue?.label}</span>
                                    <span className={`text-sm font-medium mb-4 ${classes.description}`}>{scrapCategoryValue?.label}</span>
                                </div>
                                <div className="flex flex-col">
                                    <span className={`text-sm font-semibold ${classes.name}`}>{SCRAP_PRODUCTS.CURRENT_SALES_MODE}</span>
                                    <div key={index} className="mt-2">
                                        <span className={`rounded-3xl px-3 py-1.5 text-xs font-medium text-center w-fit ${classes.mode}`}>{saleMode?.split("_").map(el => capitalizeFirstLetter(el)).join(" ")}</span>
                                    </div>
                                </div>
                            </div>
                            {hovered === id && <div className="flex justify-end z-1 absolute top-0 right-0 mt-4 mr-4 gap-4">
                                <div className={`${classes.actionButton} rounded-lg cursor-pointer`}>
                                    <img src={outlinedEdit} alt="icon" onClick={() => handleUpdateItem(id)} />
                                </div>
                                <div className={`${classes.actionButton} rounded-lg cursor-pointer`}>
                                    <img src={deleteIcon} alt="icon" onClick={() => handleDeleteItem(id)} />
                                </div>
                            </div>}
                            <div className={`absolute top-0 left-0 mt-4 ml-4 text-xs rounded-xl px-2 py-1 ${classes.quantity}`}>
                                {monthlyProduction} MT
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="w-full">
                <TablePagination
                    className={`${classes.paginationTable} w-full flex mt-6`}
                    component="div"
                    rowsPerPageOptions={[3, 5, 10]}
                    count={pagination.totalRecords}
                    page={pagination.currentPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={pagination.pageSize}
                    ActionsComponent={TablePaginationActions}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </>
    )
}

export default ScrapProductListingTemplate;