import React, { useEffect, useState } from 'react';
import Select from '../../atoms/Select'; 
import { HTTP_STATUS, option } from '../../../utils/types';
import { useBrandService } from '../../../services/useBrandService';
import { IBrandUpcs } from './MasterbrandListModal.Template';
import { IRelatedBrands } from '../MasterUPCListing/MasterRelatedBrands.template';
import { IMasterUpcDetailsRequestBody, useMasterUpcService } from '../../../services/useMasterUpcService';
import { IMasterUpcs } from '../MasterUPCListing/MasterUpcOverview.template';
import SelectV2 from '../../atoms/SelectV2';
import { createUseStyles } from 'react-jss';
import FilterChip, { IMultiSelectOption } from '../../molecules/FilterChip/FilterChip';

interface AddMasterBrandTemplateProps {
    brandUpcs: IBrandUpcs[] | IRelatedBrands[];
    setSelectedOption: (option: IMultiSelectOption[]) => void; 
    selectedOption: IMultiSelectOption[];
    masterUpcId: number;
}


const useStyles = createUseStyles((theme: any) => ({
    subHeading: {
        color: theme.palette.textV2.primary.primary870
    },
}));

const AddMasterBrandTemplate: React.FC<AddMasterBrandTemplateProps> = ({ brandUpcs, setSelectedOption, selectedOption, masterUpcId }) => {
    const [brands, setBrands] = useState<option[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const masterUpcService = useMasterUpcService();
    const [masterUpc, setMasterUpcTo] = useState<IMasterUpcs>();

    const brandService = useBrandService();
    const classes = useStyles();

    const getAllBrands = async () => {
        const params = {
            page: 0,
            size: 10,
            name: searchTerm,
            productCategoryId: masterUpc?.product?.categoryId
        };
    
        try {
            const brandsResponse = await brandService.getAllBrands(params);
    
            if (brandsResponse.status === HTTP_STATUS.OK && brandsResponse.data?.data) {
                let brandFilterValues: { value: any, label: string }[] = [];
                brandsResponse.data.data.content.forEach((brand: any) => {
                        brandFilterValues.push({ value: brand.id, label: brand.name });
                });
                setBrands(brandFilterValues);
            } else {
                setBrands([]);
            }
        } catch (error) {
            setBrands([]);
        }
    };

    const fetchUpc = async () => {
        if (masterUpcId) {
            try {
                let params: IMasterUpcDetailsRequestBody = {
                    upcTypes: "MASTER_UPC",
                }
                const upcResponse = await masterUpcService.getProductUpcById(masterUpcId, params);
                if (upcResponse?.status === HTTP_STATUS.OK) {
                    setMasterUpcTo(upcResponse?.data?.data);
                }
                else {
                    throw new Error("UPC fetch failed");
                }
            } catch (error) {
                console.error("error", `UPC fetch failed`)
            }
        }
    }

    useEffect(() => {
        fetchUpc()
    }, [masterUpcId]);


    useEffect(() => {
        getAllBrands();
    }, [searchTerm, masterUpc]);

    const handleSelectChange = (selected: IMultiSelectOption[]) => {
        setSelectedOption(selected);
    };

    return (
        <div>
            <FilterChip
                options={brands}
                label="Search Brand"
                value={selectedOption}
                onchange={handleSelectChange}
                placeholder=""
                ButtonComponent={null}
                textFieldPlaceholder='Type here'
                textFieldLabel="Enter Brand Name"
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                maxHeight="40%"
                isSingleSelect={true}
            />
        </div>
    );
};

export default AddMasterBrandTemplate;
