import React from 'react'
import { createUseStyles } from 'react-jss';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import ButtonV2 from '../../../atoms/ButtonV2';
import StatusV2 from '../../../atoms/Status/StatusV2';
import { MODE, SERVICE_STATUS_OPTIONS } from '../../../../utils/constant';
import { capitalizeFirstLetter, titleModification } from '../../../../utils/helper';
import EquipmentFormTemplate from './EquipmentForm.template';
import MachineOverviewFormTemplate from './MachineOverviewForm.template';
import CapacityDetailsFormTemplate from './CapacityDetailsForm.template';
import ServicePriceFormTemplate from './ServicePriceFrom.template';
import ImageUploadFormTemplate from './ImageUploadForm.template';
import UscAndServiceFormTemplate from './UscAndServiceForm.template';
import AttributeLinkedFormTemplate from './AttributeLinkedForm.template';
import MachineDescriptionFormTemplate from './MachineDescriptionForm.template';

interface MainServiceFormTemplateProps {
    mode: string,
    formik: any;
    onBack: () => void,
}

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
    },
    textColor: {
        color: theme.palette.text.primaryDarkLight
    },
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
    icon: {
        backgroundColor: "#FD6202",
        color: "white",
        width: "48px",
        height: "48px",
        borderRadius: "10px",
    },

}));

const MachineFormTemplate: React.FC<MainServiceFormTemplateProps> = ({ mode, onBack, formik }) => {
    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const handleSubmit = () => formik.handleSubmit();

    return (
        <form className="grid gap-y-8" >
            {SnackBarComponent}
            <div className={`${classes.mainHeading} text-lg font-semibold`}>{mode === MODE.UPDATE ? "Update" : mode === MODE.VIEW ? "View" : "Add New"} Machine</div>
            <div className='grid gap-y-4'>
                <UscAndServiceFormTemplate mode={mode} formik={formik} />
                <AttributeLinkedFormTemplate mode={mode} formik={formik} />
                <div className={`${classes.border} grid gap-y-4 p-6 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Machinery Information</div>
                    <MachineOverviewFormTemplate mode={mode} formik={formik} />
                    <EquipmentFormTemplate mode={mode} formik={formik} />
                    <CapacityDetailsFormTemplate mode={mode} formik={formik} />
                    <ServicePriceFormTemplate mode={mode} formik={formik} />
                    <MachineDescriptionFormTemplate mode={mode} formik={formik} />
                    <ImageUploadFormTemplate mode={mode} formik={formik} />
                </div>
                <StatusV2 heading="Service Status" options={SERVICE_STATUS_OPTIONS} formik={formik} name="status" />
            </div>

            <div className="flex justify-end gap-4">
                <ButtonV2 variant="tertiaryContained" label='Cancel' onClick={onBack} />
                {mode !== MODE.VIEW && <ButtonV2 variant="secondaryContained" onClick={handleSubmit} label={`+${capitalizeFirstLetter(mode)}`} />}
            </div>
        </form>
    )
}

export default MachineFormTemplate;