import { Route, Routes } from 'react-router-dom';
import { CustomerDetailsViewPage } from '../../../components/pages/CustomerManagement/CustomerDetailsView.page';
import CustomerListingPage from '../../../components/pages/CustomerManagement/CustomerListing.page';
import CustomerDetailsPage from '../../../components/pages/CustomerManagement/CustomerDetails.page';

const CustomerManagementRouter = () => {
  return (
    <div>
      <Routes>
      <Route path="/" element={<CustomerListingPage />} />
        <Route path="/:id" element={<CustomerDetailsViewPage />} />
        <Route path="/add" element={<CustomerDetailsPage />} />
      </Routes>
    </div>
  )
}
export default CustomerManagementRouter