import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CachedIcon from "@mui/icons-material/Cached";
import ListAltIcon from "@mui/icons-material/ListAlt";
import completedBackgroundPng from "../../../assets/images/cardBackgroundRed.png";
import listBackgroundPng from "../../../assets/images/cardBackgroundGreen.png";
import ongoingBackgroundPng from "../../../assets/images/cardBackgroundOrange.png";
import cancelBackgroundPng from "../../../assets/images/cardBackgroundTurquoise.png";
import { useLeadService } from "../../../services/useLeadService";
import TitleHeading from "../../atoms/CustomHeading/TitleHeading";
import LeadListTemplate from "../../templates/Lead/LeadList.template";
import QuotationListTemplate from "../../templates/Lead/QuotationList.template";
import { IPagination } from "../../../utils/types";
import RecordCard from "../../atoms/Cards/RecordCard";
import { initialPaginationValues } from "../../../utils/constant";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";

export interface ILead {
    id: number;
    userId: number;
    actorName: string;
    customerName: string;
    companyName: string;
    status: string;
    createdAt: Date;
    updatedAt: Date;
    leadUpcResponseDTO: {
        id: number;
        upcId: number;
        quantity: number;
        leadId: number;
        upcCode: string;
    }[];
    noOfItem: number;
    catalogueType: string;
}

interface ILeadCounts {
    totalLead: number;
    ongoingLead: number;
    completedLead: number;
    cancelLead: number;
}

export interface ILeadFilter {
    customerName: string;
    status: string;
    catalogueType: string;
}

const initialLeadFiltersValues: ILeadFilter = {
    customerName: "",
    status: "ALL",
    catalogueType: "ALL",
};

const useStyles = createUseStyles((theme: any) => ({
    leadTogglerNotActive: {
        color: theme.palette.text.primaryLight,
    },
    leadTogglerActive: {
        color: theme.palette.button.primaryDark,
        borderBottom: `1px solid ${theme.palette.button.primaryDark}`,
    },
}));

const LeadList: React.FC = () => {
    const classes = useStyles();
    const leadService = useLeadService();
    const { user } = useAuthenticatedUser();
    const [leads, setLeadsTo] = useState<ILead[]>([]);
    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);
    const [leadFilters, setLeadFiltersTo] = useState<ILeadFilter>(initialLeadFiltersValues);
    const [leadCount, setLeadCountTo] = useState<ILeadCounts>({
        totalLead: 0,
        ongoingLead: 0,
        completedLead: 0,
        cancelLead: 0,
    });

    const refreshLeads = async (page: number, size: number) => {
        try {
            const params = {
                page: page,
                size: size,
                actorId: Number(user?.id),
                status: leadFilters?.status === "ALL" ? "" : leadFilters?.status,
                search: leadFilters?.customerName.trim(),
                sort: "createdAt,desc",
                catalogueType: leadFilters?.catalogueType === "ALL" ? "" : leadFilters?.catalogueType,
            };
            const leadResponse = await leadService.getAllLeads(params);
            if (
                leadResponse?.status === 200 &&
                leadResponse?.data?.data?.leadPage?.content?.length
            ) {
                const { leadPage, leadCountDTO } = leadResponse.data.data;
                const formattedLeadData = leadPage.content.map((lead: ILead) => ({
                    ...lead,
                    noOfItem: lead.leadUpcResponseDTO.length,
                }));
                setLeadsTo(formattedLeadData);
                setPaginationTo((prevPagination: IPagination) => ({
                    ...prevPagination,
                    totalRecords: leadPage.totalElements,
                    totalPages: leadPage.totalPages,
                }));
                setLeadCountTo(leadCountDTO);
            } else {
                throw new Error("Leads fetch failed");
            }
        } catch (error) {
            setLeadsTo([]);
        }
    };

    const leadStats = [
        {
            backgroundImg: listBackgroundPng,
            icon: <ListAltIcon className="text-white" sx={{ fontSize: 50 }} />,
            title: "All Leads",
            value: leadCount.totalLead ? leadCount.totalLead : 0,
        },
        {
            backgroundImg: ongoingBackgroundPng,
            icon: <CachedIcon className="text-white" sx={{ fontSize: 50 }} />,
            title: "Ongoing Leads",
            value: leadCount.ongoingLead ? leadCount.ongoingLead : 0,
        },
        {
            backgroundImg: completedBackgroundPng,
            icon: <CheckCircleIcon className="text-white" sx={{ fontSize: 50 }} />,
            title: "Completed Leads",
            value: leadCount.completedLead ? leadCount.completedLead : 0,
        },
        {
            backgroundImg: cancelBackgroundPng,
            icon: <CancelIcon className="text-white" sx={{ fontSize: 50 }} />,
            title: "Cancel Leads",
            value: leadCount.cancelLead ? leadCount.cancelLead : 0,
        },
    ];

    const handleFiltersChange = (event: any) => {
        const { name, value } = event.target;
        setPaginationTo({...pagination, currentPage: 0})
        setLeadFiltersTo({ ...leadFilters, [name]: value ?? "" });
    };

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage
        }));
    };

    useEffect(() => {
        refreshLeads(pagination.currentPage, pagination.pageSize);
    }, [pagination.currentPage, pagination.pageSize, leadFilters.status, leadFilters.customerName, leadFilters.catalogueType]);

    const LEAD_SECTIONS = { LEAD: "Lead", QUOTATION: "Quotation" };

    const [activeCategory, setActiveCategory] = useState(LEAD_SECTIONS.LEAD);
    const handleToggleClick = (category: any) => () => {
        setActiveCategory(category);
    };

    return (
        <div className="grid gap-y-3">
            <div className="flex justify-between">
                <TitleHeading text="Lead Management" />
            </div>
            <div className="grid gap-y-4">
                <RecordCard cards = { leadStats } />
                <div className="grid gap-y-3">
                    <div className="flex gap-x-6">
                        <span onClick={ handleToggleClick(LEAD_SECTIONS.LEAD) } className={ activeCategory === LEAD_SECTIONS.LEAD ? `${ classes.leadTogglerActive } text-base font-medium pb-3` : `${ classes.leadTogglerNotActive }` } >
                            Lead
                        </span>
                        <span onClick={ handleToggleClick(LEAD_SECTIONS.QUOTATION) } className={ activeCategory === LEAD_SECTIONS.QUOTATION ? `${ classes.leadTogglerActive } text-base font-medium pb-3` : `${ classes.leadTogglerNotActive } font-medium text-base cursor-pointer` } >
                            Quotation
                        </span>
                    </div>
                    {activeCategory === LEAD_SECTIONS.LEAD && 
                        <LeadListTemplate 
                            leads = { leads } 
                            pagination = { pagination } 
                            handlePaginationChange = { handlePaginationChange } 
                            handleRowsPerPageChange = { handleRowsPerPageChange } 
                            leadFilters = { leadFilters } 
                            handleFiltersChange = { handleFiltersChange } />}
                    {activeCategory === LEAD_SECTIONS.QUOTATION && <QuotationListTemplate />}
                </div>
            </div>
        </div>
    );
};

export default LeadList;