import React, { useState, useEffect } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { HTTP_STATUS } from '../../../utils/types';
import { createUseStyles } from 'react-jss';
import * as Yup from 'yup';
import { useSnackbar } from '../../../hooks/useSnackBar';
import FormSubmit from '../../molecules/FormSubmit/FormSubmit';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonV2 from '../../atoms/ButtonV2';
import { ADMIN_ROUTES } from '../../../utils/constant';
import PaymentPreferenceTemplate from '../../templates/VendorManagement/Preferences/PaymentPreference.template';
import CommunicationPreferenceTemplate from '../../templates/VendorManagement/Preferences/CommunicationPreference.template';
import { useVendorServiceV2 } from '../../../services/useVendorServiceV2';
import CommunicationPreferenceViewTemplate from '../../templates/VendorManagement/Preferences/CommunicationPreferenceView.template';
import PaymentPreferenceViewTemplate from '../../templates/VendorManagement/Preferences/PaymentPreferenceView.template';

interface FormValues {
    communication: string;
    contact: string;
    language: string;
    payments: string;
    creditDays: string;
    itemCheckbox: number[];
}
interface ICommunicationRequestBody {
    communicationChannel: string;
    language: string;
    contactMode: string;
}

interface IPaymentRequestBody {
    creditDays: string;
    paymentMode: string;
    whatsappUpdates: boolean;
    whatsappCommunity: boolean;
    emailUpdates: boolean;
    docsAccess: boolean;
}

const communicationValidationSchema = Yup.object().shape({
    communication: Yup.string().required("Communication preference is required"),
    contact: Yup.string().required("Contact preference is required"),
    language: Yup.string().required("Language preference is required"),
});

const paymentValidationSchema = Yup.object().shape({
    payments: Yup.string().required("Payment preference is required"),
    creditDays: Yup.string().required("Credit period days is required"),
});

const PreferencesPage: React.FC = () => {
    const [showEditForm, setShowEditForm] = useState(false);
    const [showEditFormOther, setShowEditFormOther] = useState(false);
    const vendorService = useVendorServiceV2();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const [initialValues, setInitialValues] = useState<FormValues>({
        communication: "",
        contact: "",
        language: "",
        payments: "",
        creditDays: "",
        itemCheckbox: [],
    });

    const params = useParams();
    const id = Number(params.id);
    const navigate = useNavigate();

    const getVendor = async () => {
        try {
            const businessProfileResponse = await vendorService.getVendor(id);
            if (businessProfileResponse.status === HTTP_STATUS.OK) {
                const profileData = businessProfileResponse?.data?.data;
                setInitialValues({
                    communication: profileData.communicationChannel || "",
                    contact: profileData.contactMode || "",
                    language: profileData.language || "",
                    payments: profileData.paymentMode || "",
                    creditDays: profileData.creditDays || "",
                    itemCheckbox: [
                        profileData.whatsappUpdates ? 1 : 0,
                        profileData.whatsappCommunity ? 2 : 0,
                        profileData.emailUpdates ? 3 : 0,
                        profileData.docsAccess ? 4 : 0,
                    ],
                });
            }
        } catch (error) {
            showSnackbar('error', 'Business Profile fetch failed');
        } finally {

        }
    };

    const handleBackToDashboard = () => {
        navigate(ADMIN_ROUTES.VENDOR_MANAGEMENT_LIST);
    };

    useEffect(() => {
        getVendor();
    }, []);

    useEffect(() => {
        if (initialValues.communication !== "" && initialValues.language !== "" && initialValues.contact !== "") {
            setShowEditForm(true);
        }
        if (initialValues.payments !== "") {
            setShowEditFormOther(true);
        }
    }, [initialValues]);

    const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
        try {
            if (showEditForm) {
                setShowEditForm(false);
                setSubmitting(false);
                return;
            }
            setShowEditForm(true);
            const communicationRequestBody: ICommunicationRequestBody = {
                communicationChannel: values.communication,
                language: values.language,
                contactMode: values.contact,
            };

            const communicationResponse = await vendorService.updateVendorSections(id, communicationRequestBody, 'COMMUNICATION_PREFERENCES');
            if (communicationResponse?.status === HTTP_STATUS.OK) {
                setShowEditForm(true);
                return true;
            } else {
                throw new Error("Updating the Communication preference failed");
            }
        } catch (error) {
            console.error(error);
        } finally {
            setSubmitting(false);
        }
    };

    const handleSubmitOther = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
        try {
            if (showEditFormOther) {
                setShowEditFormOther(false);
                setSubmitting(false);
                return;
            }
            setShowEditFormOther(true);
            const paymentRequestBody: IPaymentRequestBody = {
                creditDays: values.creditDays,
                paymentMode: values.payments,
                whatsappUpdates: values.itemCheckbox.includes(1),
                whatsappCommunity: values.itemCheckbox.includes(2),
                emailUpdates: values.itemCheckbox.includes(3),
                docsAccess: values.itemCheckbox.includes(4),
            };

            const paymentResponse = await vendorService.updateVendorSections(id, paymentRequestBody, 'PAYMENT_PREFERENCES');
            if (paymentResponse?.status === HTTP_STATUS.OK) {
                setShowEditFormOther(true);
                return true;
            } else {
                throw new Error("Updating the Payment preference failed");
            }
        } catch (error) {
            console.error(error);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div>
            {SnackBarComponent}
            <div>
                <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={communicationValidationSchema} enableReinitialize>
                    {(formikProps) => (
                        <Form>
                            <FormSubmit
                                buttonText={showEditForm ? "Edit" : "Save"}
                                header={false}
                                onSubmit={formikProps.submitForm}
                                disabled={!formikProps.isValid || formikProps.isSubmitting || !formikProps.values.communication}
                            >
                                {showEditForm ? (
                                    <CommunicationPreferenceViewTemplate
                                        communication={formikProps.values.communication}
                                        contact={formikProps.values.contact}
                                        language={formikProps.values.language}
                                    />
                                ) : (
                                    <CommunicationPreferenceTemplate
                                        setFieldValue={formikProps.setFieldValue}
                                        formik={formikProps}
                                    />
                                )}
                            </FormSubmit>
                        </Form>
                    )}
                </Formik>
            </div>
            <div className="mt-6">
                <Formik initialValues={initialValues} onSubmit={handleSubmitOther} validationSchema={paymentValidationSchema} enableReinitialize>
                    {(formikProps) => (
                        <Form>
                            <FormSubmit
                                buttonText={showEditFormOther ? "Edit" : "Save"}
                                header={false}
                                onSubmit={formikProps.submitForm}
                                disabled={!formikProps.isValid || formikProps.isSubmitting || !formikProps.values.payments}
                            >
                                {showEditFormOther ? (
                                    <PaymentPreferenceViewTemplate payments={formikProps.values.payments} creditDays={formikProps.values.creditDays} itemCheckbox={formikProps.values.itemCheckbox} />
                                ) : (
                                    <PaymentPreferenceTemplate setFieldValue={formikProps.setFieldValue} values={formikProps.values} formik={formikProps} />
                                )}
                            </FormSubmit>
                        </Form>
                    )}
                </Formik>
            </div>
            <div className='flex justify-end mt-8'>
                <ButtonV2
                    className={` justify-self-end`}
                    variant="secondaryContained"
                    label="Back to Dashboard"
                    onClick={handleBackToDashboard}
                />
            </div>
        </div>
    );
};

export default PreferencesPage;
