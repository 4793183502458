import React from 'react';
import { Route, Routes } from 'react-router-dom';
import OrderMasterList from '../../../components/pages/Order/OrderMasterList.page';
import OrderList from '../../../components/pages/Order/OrderList.page';
import OrderView from '../../../components/pages/Order/OrderView.page';

const OrderRouter = () => {
    return (
        <Routes>
            <Route exact path="" element={<OrderMasterList />} />
            <Route exact path="/:id" element={<OrderList />} />
            <Route exact path="/:id/:orderId" element={<OrderView />} />
        </Routes>
    )
}
export default OrderRouter