import React, { useEffect, useState } from 'react';

import SecondaryInventoryListingTemplate from '../../templates/SecondaryInventory/SecondaryInventoryList.template';
import { IPagination } from '../../../utils/types';
import { useSecondaryInventoryService } from '../../../services/useSecondaryInventoryService';
import { useLocation, useNavigate } from 'react-router-dom';

export interface ISecondaryInventoryFilter {
  warehouse: string;
  skuCode: string;
  sort: string;
  productCategory: string;
}
export interface ISecondaryInventory {
  id: number;
  skuCode: string;
  name: string;
  warehouseName: string;
  quantity: number;
  createdAt: string;
  updatedAt: string;
}

const SecondaryInventoryListPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search); 

  const initialPaginationValues: IPagination = {
    currentPage: parseInt(queryParams.get('page') || '0') || 0,
    pageSize: parseInt(queryParams.get('size') || '10') || 10,
    totalPages: 1,
    totalRecords: 0,
  }
  
  const initialSecondaryInventoriesFiltersValues: ISecondaryInventoryFilter = {
    warehouse: queryParams.get('warehouse') || 'ALL',
    skuCode: queryParams.get('skuCode') || '',
    sort: queryParams.get('sort') || 'createdAt,desc',
    productCategory: queryParams.get('productCategory') || 'ALL',
  }

  const secondaryInventoryService = useSecondaryInventoryService();
  const [secondaryInventories, setSecondaryInventoriesTo] = useState<ISecondaryInventory[]>([]);
  const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);
  const [secondaryInventoryFilter, setSecondaryInventoryFiltersTo] = useState<ISecondaryInventoryFilter>(initialSecondaryInventoriesFiltersValues);

  const getAllSecondaryInventories = (page: number, size: number, filterData?: any) => {
    let params = {
      warehouse: secondaryInventoryFilter.warehouse === "ALL" ? "" : secondaryInventoryFilter.warehouse,
      skuCode: secondaryInventoryFilter.skuCode,
      page: page,
      size: size,
      sort: secondaryInventoryFilter.sort,
      categoryId:  secondaryInventoryFilter.productCategory === "ALL" ? undefined : parseInt(secondaryInventoryFilter.productCategory, 10),
    }
  
    secondaryInventoryService.getAllSecondaryInventories({}, params)
      .then((secondaryInventoriesResponse) => {
        if (secondaryInventoriesResponse.data.data) {
          const { totalElements, totalPages } = secondaryInventoriesResponse?.data?.data;
          setPaginationTo({
            ...pagination,
            totalPages: totalPages,
            totalRecords: totalElements
          });
          setSecondaryInventoriesTo(secondaryInventoriesResponse?.data?.data?.content);
        }
        else {
          setSecondaryInventoriesTo([]);
        }

      })
      .catch((error) => {
        console.error("Inventories fetching error - ", error);
      });
  }

  const handleFiltersChange = (event: any) => {
    const { name, value } = event.target;
    setPaginationTo({...pagination, currentPage: 0})
    setSecondaryInventoryFiltersTo({ ...secondaryInventoryFilter, [name]: value ?? "" });
  }

  const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPaginationTo((prevPagination) => ({
      ...prevPagination,
      currentPage: newPage
    }));
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setPaginationTo((prevPagination) => ({
      ...prevPagination,
      pageSize: newRowsPerPage
    }));
  }

  useEffect(() => {
    const params = new URLSearchParams();

    pagination.currentPage && params.set('page', pagination.currentPage.toString());
    pagination.pageSize && params.set('size', pagination.pageSize.toString());
    secondaryInventoryFilter.warehouse && params.set('warehouse', secondaryInventoryFilter.warehouse);
    secondaryInventoryFilter.skuCode && params.set('skuCode', secondaryInventoryFilter.skuCode);
    secondaryInventoryFilter.sort && params.set('sort', secondaryInventoryFilter.sort);
    secondaryInventoryFilter.productCategory && params.set('productCategory', secondaryInventoryFilter.productCategory);

    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  }, [
    pagination.currentPage, pagination.pageSize,
    secondaryInventoryFilter.warehouse, secondaryInventoryFilter.skuCode,
    secondaryInventoryFilter.sort, secondaryInventoryFilter.productCategory
  ]);

    useEffect(() => {
    getAllSecondaryInventories(pagination.currentPage, pagination.pageSize);
  }, [pagination.currentPage, pagination.pageSize, secondaryInventoryFilter.warehouse, secondaryInventoryFilter.skuCode, secondaryInventoryFilter.sort, secondaryInventoryFilter.productCategory]);

  return (
    <div>
      <SecondaryInventoryListingTemplate secondaryInventories={secondaryInventories} pagination={pagination} handlePaginationChange={handlePaginationChange} secondaryInventoryFilter={secondaryInventoryFilter} handleFiltersChange={handleFiltersChange} handleRowsPerPageChange={handleRowsPerPageChange} />
    </div>
  )
}

export default SecondaryInventoryListPage