import React, { useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { FormikProps } from 'formik';
import { FormControl } from '@mui/material';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import ButtonV2 from '../../../atoms/ButtonV2';
import TextFieldV2 from '../../../atoms/TextFieldV2';
import StatusV2 from '../../../atoms/Status/StatusV2';
import AutoCompleteInputV2, { AutoCompleteOption } from '../../../molecules/AutoCompleteInput/AutoCompleteInputV2';
import { ServicePayload, Services, useVASMasterCategoryService } from '../../../../services/useVASMasterCategoryService';
import { MODE, SERVICE_STATUS_OPTIONS } from '../../../../utils/constant';
import { capitalizeFirstLetter, titleModification } from '../../../../utils/helper';
import { HTTP_STATUS, VAS_MASTER_CATEGORY_TYPE } from '../../../../utils/types';

interface MainServiceFormTemplateProps {
    mode: string,
    formik: FormikProps<ServicePayload>;
    onBack: () => void,
}

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
    },
    textColor: {
        color: theme.palette.text.primaryDarkLight
    },
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.action.danger,
    }
}));

const MainServiceFormTemplate: React.FC<MainServiceFormTemplateProps> = ({ mode, onBack, formik }) => {
    const classes = useStyles();
    const vasMasterCategoryService = useVASMasterCategoryService();
    const [superServices, setSuperServices] = useState<Services[] | null>(null);
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    useEffect(() => {
        fetchSuperServices();
    }, [])

    const updateSelectedService = (key: string) => (option: AutoCompleteOption | null) => {
        if(option){
            formik.setFieldValue(key, option);
            formik.setFieldValue('parentId', option.id);
        }
    };

    const fetchSuperServices = (searchText?: string) => {
        vasMasterCategoryService.getAllService({ level: VAS_MASTER_CATEGORY_TYPE.SUPER_SERVICE, page: 0, size: 10, sort: 'createdAt,desc', name: searchText ?? '', status: 'ACTIVE' }, {})
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                setSuperServices(res?.data?.data?.content)
            }).catch((error) => {
                showSnackbar('error', "Error while fetching Super Service data");
            })
    }

    const superServiceOptions = useMemo(() => (
        superServices?.map((superService: Services) => ({ label: superService.name, id: superService.id })) ?? []
    ), [superServices])


    const handleSubmit = () => formik.handleSubmit();

    return (
        <form className="grid gap-y-8" >
            {SnackBarComponent}
            <div className={`${classes.mainHeading} text-lg font-semibold`}>{mode === MODE.UPDATE ? `Edit Main Service - ${formik?.values?.serviceCode}` : mode === MODE.VIEW ? "View Main Service" : "Add New Main Service"}</div>
            <div className='grid gap-y-4'>
                <div className={`${classes.border} grid gap-y-4 p-6 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Select Super Service to be Linked with the Main Service</div>
                    <div className='grid grid-cols-2 gap-x-4'>
                        <div className="grid gap-y-1">
                            {(mode === MODE.ADD || superServices) && <FormControl fullWidth>
                                <AutoCompleteInputV2
                                    options={superServiceOptions}
                                    label="Select Super Service"
                                    onChange={updateSelectedService('superService')}
                                    onSearch={fetchSuperServices}
                                    isDisabled={mode === MODE.VIEW}
                                    value={formik.values?.superService}
                                    error={!!formik.errors.superService && formik.touched.superService}
                                />
                                {formik.errors.parentId && formik.touched.parentId && (
                                    <div className={`${classes.errorMessage}`}>
                                        <small>{formik?.errors?.parentId}</small>
                                    </div>
                                )}
                            </FormControl>}
                        </div>
                    </div>
                </div>
                <div className={`${classes.border} grid gap-y-4 p-6 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Main Service Details</div>
                    <div className='grid grid-cols-2 gap-x-4'>
                        <div className="grid gap-y-1">
                            <TextFieldV2
                                label="Main Service Name"
                                placeholder='Enter Main Service Name'
                                {...formik.getFieldProps("name")}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                inputProps={{
                                    readOnly: mode === MODE.VIEW,
                                    maxLength: 100,
                                }}
                                onBlur={(event) => {
                                    const newValue = titleModification(event.target.value);
                                    formik.setFieldValue('name', newValue);
                                }}
                            />
                            {formik.errors.name && formik.touched.name && (
                                <div className={`${classes.errorMessage} text-xs`}>
                                    {formik.errors.name}
                                </div>
                            )}
                        </div>
                        {
                            mode === MODE.UPDATE &&
                            <div className="grid gap-y-1">
                                <TextFieldV2
                                    label="Main Service Code"
                                    {...formik.getFieldProps("serviceCode")}
                                    error={formik.touched.name && Boolean(formik.errors.serviceCode)}
                                    inputProps={{ readOnly: mode === MODE.UPDATE }}
                                    onBlur={(event) => {
                                        const newValue = titleModification(event.target.value);
                                        formik.setFieldValue('serviceCode', newValue);
                                    }}
                                    disabled
                                />
                                {formik.errors.serviceCode && formik.touched.serviceCode && (
                                    <div className={`${classes.errorMessage} text-xs mt-1`}>
                                        {formik.errors.serviceCode}
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                    <div className="grid gap-y-1">
                        <TextFieldV2
                            variant="outlined"
                            label="Add Description"
                            placeholder='Enter Here'
                            multiline
                            maxRows={4}
                            {...formik.getFieldProps("description")}
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            inputProps={{ readOnly: mode === MODE.VIEW, maxLength: 500 }}
                            onBlur={(event) => {
                                const newValue = titleModification(event.target.value);
                                formik.setFieldValue('description', newValue);
                            }}
                        />
                        {formik.errors.description && formik.touched.description && (
                            <div className={`${classes.errorMessage} text-xs`}>
                                {formik.errors.description}
                            </div>
                        )}
                    </div>
                </div>
                <StatusV2 heading="Service Status" options={SERVICE_STATUS_OPTIONS} formik={formik} name="status" />
            </div>

            <div className="flex justify-end gap-4">
                <ButtonV2 variant="tertiaryContained" label='Cancel' onClick={onBack} />
                {mode !== MODE.VIEW && <ButtonV2 variant="primaryContained" onClick={handleSubmit} label={`+${capitalizeFirstLetter(mode)}`} />}
            </div>
        </form>
    )
}

export default MainServiceFormTemplate;