import { Button } from '@mui/material';
import { useState } from 'react';
import { AUCTION } from '../../../../Constants/Procurement/LiveAuction/text';
import RemainingTime from '../RemainingTime';
import CustomTimeAdder from '../../../../Shared/CustomDateTimePicker/CustomTimeAdder';
import moment from 'moment';
import { ModalType } from '../../../../Constants/Procurement/LiveAuction/constants';

interface ExtendTimeModalProps {
  remainingTime: number;
  startDateTime: number;
  isLive: boolean;
  open: ModalType | null;
  isPause?: boolean;
  handleExtendTime: (value: Date) => void;
}

interface Time {
  hour: number;
  minute: number;
}

const ExtendTimeModal = ({
  handleExtendTime,
  remainingTime,
  startDateTime,
  isLive,
  isPause,
  open,
}: ExtendTimeModalProps) => {
  const [time, setTime] = useState<Time>({ hour: 0, minute: 0 });

  const handleDate = () => {
    const currentTime = moment.unix(remainingTime);
    const setTimeMoment = currentTime
      .clone()
      .add(time.hour, 'hours')
      .add(time.minute, 'minutes');
    handleExtendTime(setTimeMoment.toDate());
  };

  const getDifference = (time: number) => {
    const now = moment();
    const end = moment.unix(time);

    const duration = moment.duration(end.diff(now));

    return now < end ? duration.minutes() : 0;
  };

  return (
    <div className="h-[450px] flex flex-col justify-center items-center">
      <div>
        <div className="text-blue font-bold my-4">
          <div className="mb-1">
            {AUCTION.RemainingTime} {time ? getDifference(remainingTime) : 0}{' '}
            min
          </div>
          <RemainingTime
            startTime={startDateTime}
            type={'hr mm'}
            endTime={remainingTime}
            isLive={isLive}
            isPause={isPause}
            open={open}
          />
        </div>
        <div className="text-blue font-bold mb-1">{AUCTION.AddTime}</div>
        <CustomTimeAdder value={time} onChange={(val) => setTime(val)} />
        <div className="text-blue font-bold my-4">
          {AUCTION.TotalTime} {time ? getDifference(remainingTime) : 0} min
        </div>
        <div className="flex justify-end mt-4">
          <Button
            onClick={handleDate}
            className="rounded h-12 normal-case text-white bg-blue hover:bg-blueHover w-[104px]"
          >
            {AUCTION.Done}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ExtendTimeModal;
