import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { PRICE_DETAILS } from "../../../utils/constant";
import PricingInfo from "../../organisms/PricingInfo";
import { toInr } from "../../../utils/helper";

export interface IChargesProps {
	title: string;
	subTitle?: string | null;
	price: number;
	icon?: React.ReactNode;
	iconInfo?: string | null;
	showFullQuantity: boolean;
}

export interface IPriceDetails {
    subTotal: IChargesProps;
    warehouseCharges: IChargesProps;
    handlingCharges: IChargesProps;
    packagingCharges: IChargesProps;
    otherCharges: IChargesProps;
    GST: IChargesProps;
    TCS: IChargesProps;
}

export interface IPaymentInfoProps {
	label: string;
	status?: string;
	showStatus?: boolean;
	priceDetails: IPriceDetails;
	quantity: number;
	uom: string;
	totalPrice: number;
}

const useStyles = createUseStyles((theme: any) => ({
	priceDetailsHeading: {
		boxShadow: `0px 4px 45px 0px ${ theme.palette.boxShadow.primaryLight }`,
	},
	grandTotal: {
		color: theme.palette.text.darkGrey,
	},
	paymentContainer: {
		boxShadow: `0px 1px 4px 0px ${ theme.palette.boxShadow.primaryDarkAccent }`,
		text: theme.palette.text.darkGrey,
	},
	mainHeading: {
		color: theme.palette.text.grey,
	},
}));

const PaymentInfoTemplate: React.FC<IPaymentInfoProps> = ({ label, showStatus, priceDetails, quantity, uom, totalPrice }) => {

  const classes = useStyles();
  const [totalQuantity, setTotalQuantityTo] = useState<number>(quantity || 0);
  const [grandTotal, setGrandTotalTo] = useState<number>(0);
  const [UOM, setUOMTo] = useState<string>(uom || "MT");
  const [paymentInfoDetails, setPaymentInfoDetailsTo] = useState<IPriceDetails>(() => PRICE_DETAILS({}));

  useEffect(() => {
    setUOMTo(uom);
    setTotalQuantityTo(quantity);
    setGrandTotalTo(totalPrice);
    setPaymentInfoDetailsTo(priceDetails);
  }, [priceDetails]);

  return (
    <div className={ `${ classes.paymentContainer } grid rounded bg-white gap-y-2 p-6` } >
		<div className={ `border-b-2 border-solid pb-3 flex justify-start w-full` }>
			<div className={ `${ classes.mainHeading } font-semibold text-2xl`}>{ label }</div>
		</div>
		<div className={`grid gap-y-4 pb-3 border-b-2 border-solid`}>
			{Object.entries(paymentInfoDetails).map(([key, value]) => (
				<PricingInfo
					key={ key }
					data={{
					...value,
					// icon: <InfoOutlinedIcon sx={{ fontSize: 20, marginX: "5px" }} />
					icon: null
					}}
					totalQuantity={ totalQuantity }
					uom={ UOM }
				/>
			))}
		</div>
		<div className={ `${ classes.grandTotal } pb-4 flex justify-between text-lg font-medium` }>
			<div>Grand Total</div>
			<div>{ toInr(grandTotal) }</div>
		</div>
    </div>
  );
};

export default PaymentInfoTemplate;
