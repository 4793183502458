import React from 'react'
import { AUTH_STATES } from '../../../utils/types';
import { createUseStyles } from 'react-jss';

export interface AuthFooterProps {
  setAuthStateTo: (state: AUTH_STATES) => () => void;
  loginType?: AUTH_STATES;
  isLogin: boolean;
}

const useStyles = createUseStyles((theme: any) => ({
  sectionText: {
    color: theme.palette.text.primaryDarkAccent
  },
  content: {
    color: theme.palette.button.primaryLight
  },
  separator: {
    color: theme.palette.general.seperator,
    '& > span:nth-child(odd)': {
      flex: 1,
      border: `1px solid ${theme.palette.general.seperator}`,
      height: '1px',
    },
  }
}));

const AuthFooter: React.FC<AuthFooterProps> = ({ setAuthStateTo, loginType = AUTH_STATES.LOGIN_WITH_EMAIL, isLogin }) => {
  const classes = useStyles();

  return (
    <>
      <div className={`${classes.separator} flex justify-center items-center gap-x-5`}>
        <span></span>
        <span>Or</span>
        <span></span>
      </div>
      {isLogin ?
        <span onClick={setAuthStateTo(loginType)} className={`cursor-pointer font-normal text-lg text-center my-2 ${classes.sectionText}`}>
          Or Login via {loginType === AUTH_STATES.LOGIN_WITH_EMAIL ? "email" : "OTP"}</span>
        : <div className={`text-center pt-4 ${classes.content}`}>
          Already have an account <span onClick={setAuthStateTo(AUTH_STATES.LOGIN_WITH_EMAIL)} className={`text-secondary cursor-pointer font-normal text-lg ${classes.sectionText}`}>Log In</span>
        </div>
      }
    </>
  )
}

export default AuthFooter