import React from 'react'
import { createUseStyles } from 'react-jss';
import ButtonV2 from '../../atoms/ButtonV2';

const useStyles = createUseStyles((theme: any) => ({
    primaryContainer: {
        border: `1px solid ${theme.palette.border.primarylightAccent}`,
        background: theme.palette.background._secondaryDarkAccent,
    },
    headingText: {
        color: theme.palette.textV2.tertiary.tertiary900,
    },
}));

interface IBusinessProfileNoDataProps {
    heading: string;
    setIsEditing?: any;
    noDataImg: any;
    handleEditClick?: any;
    label: string;
}

const BusinessProfileNoDataTemplate: React.FC<IBusinessProfileNoDataProps> = ({ heading, setIsEditing, noDataImg, label }) => {

    const classes = useStyles();

    return (
        <div className={`${classes.primaryContainer} py-14 px-6 flex justify-center w-full rounded-lg`}>
            <div className='flex flex-col items-center'>
                <img src={noDataImg} alt="No data" className='mb-8' />
                <p className={`${classes.headingText} mb-4 text-lg`}>{heading}</p>
                <ButtonV2 className='rounded-lg' variant={'primaryContained'} onClick={() => setIsEditing(true)} label={label} />
            </div>
        </div>
    )
}

export default BusinessProfileNoDataTemplate;
