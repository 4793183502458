import { Button, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { FormikErrors, useFormikContext } from 'formik';

import ProductDetail from './ProductDetail';
import {
  FormData,
  Procurement,
  ProcurementItem,
} from '../../../Constants/Procurement/Indent/types';
import { INDENT_TEXT } from '../../../Constants/Procurement/Indent/constants';
import CustomTextField from './../../../Shared/CustomInputs/CustomTextField';
import moment from 'moment';
import React, { useContext } from 'react';
import { PACKAGING_TYPES } from '../../../../utils/constant';
import { WareHouseContext } from '../../../../contexts/WareHouseDetailContext';

interface Props {
  item: Procurement;
  formData: ProcurementItem;
  index: number;
  consigneeLocationList: any[];
  handleDelete: () => void;
}

const ProductCardDetails: React.FC<Props> = ({ item, index, handleDelete }) => {
  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext<FormData>();
  const { warehouseDetails } = useContext(WareHouseContext);

  return (
    <div className="  rounded-lg gap-4 my-4 w-[100%]">
      <div className="p-4  ">
        <ProductDetail productDetail={item} />
      </div>

      <div className="mt-4 p-4">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CustomTextField
              name={`items.${index}.quantity`}
              value={values.items[index].quantity}
              label={'Enter Quantity in MT'}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                !!touched?.items?.[index] &&
                !!touched?.items?.[index].quantity &&
                !!errors?.items?.[index] &&
                Boolean(
                  (errors?.items?.[index] as FormikErrors<ProcurementItem>)
                    ?.quantity
                )
              }
              helperText={
                !!touched?.items?.[index] &&
                // !!touched?.items?.[index].quantity &&
                !!errors?.items?.[index] &&
                (errors?.items?.[index] as FormikErrors<ProcurementItem>)
                  .quantity
              }
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              name={`items.${index}.packagingType`}
              label="Packaging"
              value={
                PACKAGING_TYPES.filter(
                  (i) => values?.items?.[index].packagingType === i.value
                )[0].label
              }
              disabled={true}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              name={`items.${index}.warehouseId`}
              label="Consignee Location"
              value={
                warehouseDetails.find(
                  (warehouse) =>
                    warehouse.id.toString() ==
                    values?.items?.[index].warehouseId
                )?.name || ' '
              }
              disabled={true}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label="Maximum Delivery Date"
              name={`items.${index}.deliveryTime`}
              value={moment
                .unix(values?.items?.[index]?.deliveryTime ?? 0)
                ?.format('DD/MM/YYYY')}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              name={`items.${index}.remarks`}
              label="Any Product Specific Requirements"
              value={values?.items?.[index].remarks}
              disabled={true}
            />
          </Grid>
        </Grid>
      </div>
      <div className="flex justify-end w-full mb-4 px-4 pb-4">
        <Button
          variant="outlined"
          color="error"
          size="large"
          startIcon={<DeleteIcon />}
          sx={{
            textTransform: 'none',
            fontWeight: 'semibold',
          }}
          onClick={handleDelete}
        >
          {INDENT_TEXT.PRAddDeleteProcurementButtonLabel}
        </Button>
      </div>
    </div>
  );
};

export default ProductCardDetails;
