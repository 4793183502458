import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { HTTP_STATUS, VAS_MASTER_CATEGORY_TYPE } from '../../../../utils/types';
import SuperServiceUpdatePage from './SuperServiceUpdate.page';
import { Service, useVASMasterCategoryService } from '../../../../services/useVASMasterCategoryService';
import { ADMIN_ROUTES, MODE } from '../../../../utils/constant';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import MainServiceUpdatePage from './MainServiceUpdate.page';
import ServiceCategoryUpdatePage from './ServiceCategoryUpdate.page';

const ServiceUpdatePage = () => {
    const params = useParams();
    const [searchParams] = useSearchParams();

    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const vasMasterCategoryService = useVASMasterCategoryService();
    const [service, setService] = useState<Service | null>(null);
    const navigate = useNavigate();
    const mode: string = searchParams.get('mode') ?? MODE.VIEW;
    const serviceId = Number(params.id);

    useEffect(() => {
        vasMasterCategoryService.getServiceById(serviceId)
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    let category = res.data.data
                    setService({
                        ...category,
                        superParent: category?.ancestors?.superParentCategory,
                        parentCategory: category?.ancestors?.parentCategory
                    })
                }
            }).catch((error) => {
                setService(null);
                console.error("Error Fetching Service: ", error);
                showSnackbar('error', "Error while fetching service data");
            })
    }, [serviceId])

    const categoryComponent = useMemo(() => {
        if (!service) { 
            navigate(ADMIN_ROUTES.VAS_MASTER_CATEGORIES_LIST) 
            return; 
        }
        switch (service.level) {
            case VAS_MASTER_CATEGORY_TYPE.MAIN_SERVICE: return <MainServiceUpdatePage mode={mode} service={service} />
            case VAS_MASTER_CATEGORY_TYPE.SERVICE_CATEGORY: return <ServiceCategoryUpdatePage mode={mode} service={service} />
            default: return <SuperServiceUpdatePage mode={mode} service={service} />;
        }
    }, [service])

    return <>
        {SnackBarComponent}
        {categoryComponent}
    </>
}

export default ServiceUpdatePage