import { useState, useMemo } from 'react';
import noData from '../../../assets/images/noDataImage.svg';
import BusinessProfileNoDataTemplate from './BusinessProfileNoData.template';
import UdhyamUpdateTemplate from './UdhyamUpdate.template';
import { IBusinessProfile } from '../../pages/CustomerManagement/ProfileOverview.page';
import UdhyamViewTemplate from './UdhyamView.template';

interface IUdhyamTemplateProps {
    businessProfile: IBusinessProfile | null;
    updateBusinessProfileSection: (section: string, values: any) => void;
}

const UdhyamTemplate: React.FC<IUdhyamTemplateProps> = ({ businessProfile, updateBusinessProfileSection }) => {

    const [isEditing, setIsEditing] = useState(false);

    const UdhyamSectionView = useMemo(() => {
        if (isEditing) {
            return (
                <UdhyamUpdateTemplate
                    businessProfile={businessProfile}
                    updateBusinessProfileSection={updateBusinessProfileSection}
                    setIsEditing={setIsEditing}
                />
            );
        }
        if (businessProfile?.udhyam) {
            return (
                <UdhyamViewTemplate
                    setIsEditing={setIsEditing}
                    businessProfile={businessProfile}
                />
            );
        }
        return (
            <BusinessProfileNoDataTemplate
                heading="You haven't provided Udhyam Details for your business."
                setIsEditing={setIsEditing}
                noDataImg={noData}
                label="Add Udhyam Details"
            />
        );
    }, [isEditing, businessProfile, updateBusinessProfileSection]);


    return <>{UdhyamSectionView}</>;
};

export default UdhyamTemplate;
