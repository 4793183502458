import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';

import Tabs, { ITabsSchema } from '../../molecules/Tabs/Tabs';
import { createUseStyles } from 'react-jss';
import MainRawMaterialsTemplate from '../../templates/Customer-Management/MyMaterials/MainRawMaterials.template';
import FinishedProductTemplate from '../../templates/Customer-Management/FinishedProducts/FinishedProduct.template';
import ScrapProductTemplate from '../../templates/Customer-Management/ScrapProducts/ScrapProduct.template';
import { MY_MATERIAL_LISTING, MY_MATERIAL, MY_MATERIAL_STATE } from '../../../utils/types';
import TabsV2 from '../../molecules/TabsV2/TabsV2';

const useStyles = createUseStyles((theme: any) => ({
  section: {
    color: theme.palette.textV2.secondary.secondary800,
  },
  selectedTabColor: {
    "&.Mui-selected": {
      color: `${theme.palette.textV2.primary.primary500} !important`,
    },
  },
}));

interface MyMaterialListingPage {
  updateMaterialStateTo: (value: MY_MATERIAL_STATE) => void;
}

const MyMaterialListingPage: React.FC<MyMaterialListingPage> = ({ updateMaterialStateTo }) => {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(MY_MATERIAL_LISTING[searchParams.get('type') as keyof typeof MY_MATERIAL_LISTING] ?? MY_MATERIAL_LISTING.MAIN_RAW_MATERIAL);
  useEffect(() => {
    if (isNaN(activeTab)) return;
    const level = Object.keys(MY_MATERIAL_LISTING).filter(key => isNaN(parseInt(key)))[activeTab - 1];
    setSearchParams((params: URLSearchParams) => {
      params.set('type', level)
      return params;
    })
  }, [activeTab])

  const schema: ITabsSchema[] = [
    { label: 'Main Raw Material', component: <MainRawMaterialsTemplate updateMaterialStateTo={updateMaterialStateTo} /> },
    { label: 'Finished Product', component: <FinishedProductTemplate updateMaterialStateTo={updateMaterialStateTo} /> },
    { label: 'Scrap', component: <ScrapProductTemplate updateMaterialStateTo={updateMaterialStateTo}/> }
  ]

  return (
    <div className={"grid gap-y-6"}>
      <div className={`${classes.section} text-lg font-semibold`}>My Material</div>
      <div className=''>
        <TabsV2 schema={schema} value={(activeTab).toString()} setValue={setActiveTab} selectedTabStyle={classes.selectedTabColor}/>
      </div>
    </div>
  )
}

export default MyMaterialListingPage