import React, { useEffect, useRef, useState } from 'react'
import { ISecondaryCatalogueForm, secondaryCatalogueSectionProps } from './SecondaryCatalogueForm.template'
import Button from '../../atoms/Button'
import CustomHeading from '../../atoms/CustomHeading/CustomHeading'
import JoditEditor from 'jodit-react'
import { SECONDARY_CATALOGUE_SECTIONS } from '../../../utils/types'
import Select from '../../atoms/Select'
import { PACKAGING_TYPES } from '../../../utils/constant'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '../../atoms/TextField'
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
    textColor: {
        color: theme.palette.text.primaryDarkLight
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
}));

interface VariablePrice {
    quantity: string;
    price: string;
}

const PriceFormTemplate: React.FC<secondaryCatalogueSectionProps> = ({ formik, id, mode, setCurrentSectionTo }) => {
    const [priceType, setPriceType] = useState<string>('uniform');
    const [uniformPrice, setUniformPrice] = useState<string>('');
    const [variablePrices, setVariablePrices] = useState<any[]>([]);
    const classes = useStyles();

    const handlePriceTypeChange = (event: any) => {
        setPriceType(event.target.value);
    };

    const handleUniformPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUniformPrice(event.target.value);
        handlePriceChange({ default: event.target.value });
    };

    // const handleVariablePriceChange = (index: number, event: any) => {
    //     const field = event.target.name;
    //     const value = event.target.value;
    //     const newVariablePrices = [...variablePrices];
    //     newVariablePrices[index][field] = value;
    //     setVariablePrices(newVariablePrices);
    //     handlePriceChange(newVariablePrices);
    // };

    const handlePriceChange = (value: any) => {
        if (priceType === 'uniform') {
            formik.setFieldValue("price", value);
        } else if (priceType === 'variable') {
            const variablePriceObject = value.reduce((acc: any, entry: VariablePrice) => {
                acc[entry.quantity] = entry.price;
                return acc;
            }, {});
            formik.setFieldValue("price", variablePriceObject);
        }
    };

    // const handleAddVariablePrice = () => {
    //     setVariablePrices([...variablePrices, { quantity: '', price: '' }]);
    // };

    // const handleRemoveVariablePrice = (index: number) => {
    //     const newVariablePrices = [...variablePrices];
    //     newVariablePrices.splice(index, 1);
    //     setVariablePrices(newVariablePrices);
    // };

    const handleChange = (event: any) => {
        const value = event.target.value;
        if (!formik.values.packagingType.includes(value)) {
            formik.setFieldValue("packagingType", [...formik.values.packagingType, value]);
        }
    };

    const handleRemovePackagingTag = (tagToRemove: string) => {
        let packageTypeTag = formik.values.packagingType.filter((tag: string) => tag !== tagToRemove)
        formik.setFieldValue("packagingType", packageTypeTag);
    };

    const checkPriceChange = (event: any) => {
        if (event) {
            if (event.default) {
                setPriceType('uniform');
                setUniformPrice(event.default);
            } else {
                // setPriceType('variable');
                const reversedArray = Object.keys(event).map((quantity: string) => ({
                    quantity,
                    price: event[quantity],
                }));
                setVariablePrices(reversedArray);
            }
        }
    };

    useEffect(() => {
        checkPriceChange(formik.values.price);
        console.log("formik", formik.values)
    }, [formik]);

    const handleBack = () => {
        setCurrentSectionTo(SECONDARY_CATALOGUE_SECTIONS.CATALOGUE_DETAILS);
    };
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const requiredFields = ["price", "packagingType", "termsConditions"];
        let fieldError = false;
        for (const key of requiredFields) {
            if (key in formik.errors) {
                formik.setFieldTouched(key, true);
                fieldError = true;
            }
        }
        formik.setValues((prevValues: ISecondaryCatalogueForm) => ({
            ...prevValues,

        }));
        if (fieldError === true) return;
        setCurrentSectionTo(SECONDARY_CATALOGUE_SECTIONS.IMAGES_UPLOAD);
    };
    const descriptionEditor = useRef(null);
    const onTermsConditionChange = (data: any) => {
        formik.setFieldValue("termsConditions", data);
    }

    useEffect(() => {
        console.log("formik", formik)
    }, [formik]);
    return (
        <div className=' rounded bg-white py-6 px-10 '>
            <div className='mt-4  gap-2 grid'>
                <CustomHeading text={"Price"} status="" />
                <RadioGroup
                    row
                    aria-label="price-type"
                    name="price-type"
                    value={priceType}
                    onChange={handlePriceTypeChange}
                >
                    <FormControlLabel value="uniform" control={<Radio />} label="Set Uniform Price" />
                    <FormControlLabel disabled={true} value="variable" control={<Radio />} label="Set Variable Price" />
                </RadioGroup>
                {priceType === 'uniform' && (
                    <TextField
                        sx={{ width: '100%' }}
                        label="Default Price"
                        type="number"
                        variant="outlined"
                        value={uniformPrice}
                        onChange={handleUniformPriceChange}
                    />
                )}
                {/*   ----------to be done later variable pricing it is not needed now --------- */}
                {/* {priceType === 'variable' && (
                    <div >
                        <Stack className=" flex gap-x-5">
                            {variablePrices.map((variablePrice, index) => (
                                <div key={index} className=" flex gap-x-6 mb-4">
                                    <TextField
                                        fullWidth
                                        label="Quantity"
                                        name="quantity"
                                        variant="outlined"
                                        value={variablePrice.quantity}
                                        onChange={(e) => handleVariablePriceChange(index, e)}
                                        style={{ marginRight: '8px' }}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Price"
                                        name="price"
                                        type="number"
                                        variant="outlined"
                                        value={variablePrice.price}
                                        onChange={(e) => handleVariablePriceChange(index, e)}
                                        style={{ marginRight: '8px' }}
                                    />
                                    <button onClick={() => handleRemoveVariablePrice(index)} className='bg-zinc-600 rounded text-white px-5   text-5xl'> - </button>
                                </div>
                            ))}
                        </Stack>
                        <button onClick={handleAddVariablePrice} className='bg-zinc-600 rounded text-white px-5  text-5xl '>+</button>
                    </div>
                )} */}
               {formik.touched && formik.touched?.price && formik.errors?.price && (
                    <div className={classes.errorMessage}>
                        <small>PackagingType is required </small>
                    </div>
                )}  
            </div>

            <div className='mt-4  gap-2 grid'>
                <CustomHeading text={"Select Packaging Type"} status="" />
                <Select
                    variant="outlined"
                    label="Select Packaging Type"
                    value={""}
                    onChange={handleChange}
                    name="packagingType"
                    id="packagingType"
                    options={PACKAGING_TYPES}
                />
                <Stack direction="row" spacing={1} marginTop={2}>
                    {formik.values?.packagingType?.map((keyTag: string, index: number) => (
                        <Chip
                            key={index}
                            label={keyTag}
                            onDelete={() => handleRemovePackagingTag(keyTag)}
                        />
                    ))}
                </Stack>
                {formik.touched && formik.touched?.packagingType && formik.errors?.packagingType && (
                    <div className={classes.errorMessage}>
                        <small>PackagingType is required </small>
                    </div>
                )}
            </div>
            <div className='mt-4  gap-2 grid'>
                <CustomHeading text={"Terms and Conditions"} status="" />
                <JoditEditor
                    ref={descriptionEditor}
                    value={formik.values.termsConditions}
                    onBlur={(newContent) => onTermsConditionChange(newContent)}
                />
                {formik.touched.termsConditions && formik.errors.termsConditions ? (
                    <div className={classes.errorMessage}>
                        <small>{formik.errors.termsConditions}</small>
                    </div>
                ) : null}
            </div>

            <div className="flex justify-end mt-4">
                <div className="flex gap-x-3">
                    <Button variant="outlined" label="Back" onClick={handleBack} />
                    <Button variant="contained" label="Next" onClick={handleSubmit} />
                </div>
            </div>
        </div>
    )
}

export default PriceFormTemplate