import { createUseStyles } from 'react-jss';
import { AuctionEntity } from '../../../Constants/Procurement/ReverseAuctionRFQ/types';
import moment from 'moment';

interface Props {
  forVendor?: boolean;
  auctionList: AuctionEntity[];
  idHandler: (id: string) => void;
  currentPage: number;
}

const useStyles = createUseStyles({
  tableBody: {
    '& tr:nth-child(even)': {
      background: '#ededed',
    },
    '& tr:nth-child(odd)': {
      background: '#f4f5fa',
    },
  },
});

const AuctionListTable: React.FC<Props> = ({
  auctionList,
  idHandler,
  forVendor = false,
  currentPage,
}) => {
  const classes = useStyles();

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
        <div className="py-2 inline-block min-w-full">
          <div className="overflow-hidden">
            <table className="min-w-full">
              <thead className="bg-blue">
                <tr>
                  <th
                    scope="col"
                    className="text-sm font-semibold text-left  text-white px-6 py-4 "
                  >
                    Sr No.
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-semibold text-left  text-white px-6 py-4 "
                  >
                    {forVendor ? 'Auction RFQ IDs' : 'Auction IDs'}
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-semibold text-left  text-white px-6 py-4 "
                  >
                    Indent IDs
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-semibold text-center  text-white px-6 py-4 "
                  >
                    No of PRIDs
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-semibold text-center  text-white px-6 py-4 "
                  >
                    Auction Qty
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-semibold text-center  text-white px-6 py-4 "
                  >
                    Start Date
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-semibold text-center  text-white px-6 py-4 "
                  >
                    End Date
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-semibold text-center  text-white px-6 py-4 "
                  >
                    Created Date
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-semibold text-center  text-white px-6 py-4 "
                  >
                    Created By
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-semibold text-center  text-white px-6 py-4 "
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className={`${classes.tableBody} capitalize`}>
                {auctionList.map((auction, i: number) => {
                  const statusClass = `${
                    (
                      {
                        created: 'warning-tab',
                        approved: 'success-tab',
                        completed: 'success-tab',
                        draft: 'warning-tab',
                        'remarks-incorporated': 'info-tab',
                        'approval-required': 'info-tab',
                        rejected: 'danger-tab',
                        'not started': 'danger-tab',
                        closed: 'danger-tab',
                        cancelled: 'danger-tab',
                      } as Record<string, string>
                    )[auction?.status?.toLowerCase()] || 'info-tab'
                  }`;
                  return (
                    <tr className="text-text-color font-normal" key={i}>
                      <td className="px-6 py-4 whitespace-nowrap text-left text-sm">
                        {currentPage ? currentPage * 10 + (i + 1) : i + 1}
                      </td>
                      <td className="text-sm text-gray-900  px-6 py-4 whitespace-nowrap text-left">
                        <span
                          className="font-semibold  text-blue  underline cursor-pointer"
                          onClick={() => idHandler(auction.id)}
                        >
                          {auction?.id}
                        </span>

                        {auction?.createdAt &&
                        moment().diff(moment.unix(auction.createdAt), 'days') <=
                          3 ? (
                          <span className="new-tag">New</span>
                        ) : (
                          ''
                        )}
                      </td>
                      <td className="text-sm  px-6 py-4 whitespace-nowrap text-center">
                        {auction?.entityID}
                      </td>
                      <td className="text-sm  px-6 py-4 whitespace-nowrap text-center">
                        {auction?.purchaseRequistionCount || ''}
                      </td>
                      <td className="text-sm  px-6 py-4 whitespace-nowrap text-center">
                        {auction?.quantity?.toFixed(2)
                          ? auction?.quantity?.toFixed(2) + ' MT'
                          : ''}
                      </td>
                      <td className="text-sm  px-6 py-4  text-center ">
                        {(auction?.startDateTime &&
                          moment
                            .unix(auction?.startDateTime)
                            .format('DD/MM/YYYY (hh:mm A)')) ||
                          ' '}
                      </td>
                      <td className="text-sm  px-6 py-4  text-center ">
                        {(auction?.endDateTime &&
                          moment
                            .unix(auction?.endDateTime)
                            .format('DD/MM/YYYY (hh:mm A)')) ||
                          ' '}
                      </td>
                      <td className="text-sm  px-6 py-4  text-center ">
                        {moment
                          .unix(auction?.createdAt)
                          .format('DD/MM/YYYY (hh:mm A)') || ' '}
                      </td>
                      <td className="text-sm  px-6 py-4 whitespace-nowrap text-center ">
                        {auction?.createdBy || ''}
                      </td>
                      <td className="text-sm  px-6 py-4 whitespace-nowrap text-center ">
                        <span className={statusClass}>{auction?.status}</span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuctionListTable;
