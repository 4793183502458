import React, { SyntheticEvent } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ClearIcon } from '@mui/x-date-pickers';

export interface AutoCompleteOption {
  label: string;
  id: number;
}

interface AutoCompleteInputProps  {
  label: string;
  options: AutoCompleteOption[];
  onSearch: (value: string) => void;
  onChange: (option: AutoCompleteOption | null) => void;
  isDisabled: boolean;
  value?: AutoCompleteOption | null;
  error?: boolean;
  helperText?: string;
  id?: string;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  placeholder?: string,
  onBlur?: ()=>void
}

const AutoCompleteInput: React.FC<AutoCompleteInputProps> = ({
  label,
  options,
  onSearch,
  onChange,
  isDisabled,
  value,
  error = false,
  helperText = '',
  onKeyDown,
  id
}) => {
  const handleInputChange = (e: SyntheticEvent<Element, Event>, value: AutoCompleteOption | null, reason: string) =>  onChange(value);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => onSearch(e.target.value);
  
  const handleClearValue = () => onSearch("");

  const defaultProps = {
    options: options,
    getOptionLabel: (option: AutoCompleteOption) => option.label,
  };
  return (
    <Autocomplete
      id={id ?? label}
      {...defaultProps}
      fullWidth
      disabled={isDisabled}
      clearText=''
      onFocus={handleClearValue}
      clearIcon={value?.label === '' ? '' : <ClearIcon onClick={handleClearValue} />}
      renderInput={(params) => (
        <TextField
          {...params}
          className='bg-white'
          label={label}
          variant="outlined"
          onChange={handleSearch}
          error={error}
          helperText={error && helperText}
          onKeyDown={onKeyDown}
        />
      )}
      onChange={handleInputChange}
      value={value}
    />
  );
};

export default AutoCompleteInput;
