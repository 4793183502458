import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from "yup";
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import { useFormik } from 'formik';
import { HTTP_STATUS } from '../../../utils/types';
import CustomerMappingTemplate from '../../templates/Customer-Management/CustomerMapping.template';
import { useCustomerGroupMasterService } from '../../../services/useCustomerGroupMasterService';

export interface ICustomerTypes {
    id:number;
    groupName:string;
  }
  export interface IRelationshipManagers {
    id:number;
    fullName:string;
    department:string;
    designation:string;
  }
export interface ICustomerMappingForm {
    relationshipManager: IRelationshipManagers[];
    customerType: ICustomerTypes[];
    newRelationshipManager: {label:string, value:number}[];
    newCustomerType : number[];
}
const initialValues = {
    relationshipManager:[],
    customerType: [],
    newRelationshipManager: [],
    newCustomerType: [],
}

const CustomerMappingPage: React.FC = () => {
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const bussinessProfileService = useBusinessProfileService();
    const customerGroupMasterService = useCustomerGroupMasterService();
    const [userId, setUserId] = useState<number | null>(null);
    const [bussinessMapping, setBussinessMappingTo] = useState<ICustomerMappingForm>(initialValues)
    const params = useParams();
    const id = Number(params.id);
    const validationSchema = Yup.object().shape({
      });

      const getBusinessProfile = async () => {
        try {
          const businessProfileResponse = await  bussinessProfileService.getBusinessProfile(id);
          if (businessProfileResponse.status === HTTP_STATUS.OK) {
            const profileData = businessProfileResponse?.data?.data;
            setBussinessMappingTo({ relationshipManager: profileData.relationshipManager || [], customerType: JSON.parse(profileData.customerType) || [], newRelationshipManager: [], newCustomerType: [] });
            setUserId(profileData.userId);
          }
        } catch (error) {
          showSnackbar('error', 'Business Profile fetch failed');
        }
    };
      const formik = useFormik<ICustomerMappingForm>({
        initialValues,
        validationSchema,
        onSubmit: async () => {
          if(formik.values.newRelationshipManager.length >0){
            const body = {
              relationshipManager: formik.values.newRelationshipManager.map((item : any )=> item.value)
            }
          try {
           await bussinessProfileService.updateBusinessProfile(id, body, 'CUSTOMER_MAPPING');
          } catch (error) {
            showSnackbar("error", `Business profile update  failed ${error}`)
          }
          }
  
       if(formik.values.newCustomerType.length > 0 &&  userId != null){
        const customertypebody = formik.values.newCustomerType.map((item : any )=> item.value)
        try {
         await customerGroupMasterService.updateCustomerGroupMapping(userId, customertypebody);
        } catch (error) {
          showSnackbar("error", `Business profile update  failed ${error}`)
        }
       }

        },
      });
      
      useEffect(() => {
        formik.setValues({
          ...formik.values,
          ...bussinessMapping
        })
      }, [bussinessMapping])
     
      useEffect(() => {
        getBusinessProfile();
      }, [formik.isSubmitting]);

  return (
    <CustomerMappingTemplate formik={formik} />
  )
}

export default CustomerMappingPage