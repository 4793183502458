import React, { useEffect, useState } from 'react';
import DropDownGroup from '../../molecules/DropDownGroup/DropDownGroup';
import { Category, ICategoryFilterRequest, useCategoryService } from '../../../services/useCategoryService';
import { ICategoriesSearch, ICategoriesSelectedByManufacturer } from '../AdminMaster/Brand/BrandForm.template';
import { CATEGORY_TYPE } from '../../../utils/types';

interface ICategoryFilterTemplateProps {
	updateSearchParams: any;
	searchPayload: ICategoriesSearch;
	handleAttributeData?: any;
	defaultText?: any;
	superCategories: ICategoriesSelectedByManufacturer[];
	mainCategories: ICategoriesSelectedByManufacturer[];
	productCategoriesManufacturerDealsIn: ICategoriesSelectedByManufacturer[];
}

interface ICategoryKeyLabel {
	label: string;
	id: number;
}

interface ICategoryOptions {
	superCategories: ICategoryKeyLabel[] | [];
	mainCategories: ICategoryKeyLabel[] | [];
	productCategories: ICategoryKeyLabel[] | [];
}

interface ISelectedCategories {
	id?: number | null,
	superCategory: {
		label: string | null;
		id: number | null;
	} | null;
	mainCategory: {
		label: string | null;
		id: number | null;
	} | null;
	productCategory: {
		label: string | null;
		id: number | null;
	} | null;
}

const CategoryFilterTemplate: React.FC<ICategoryFilterTemplateProps> = ({ updateSearchParams, searchPayload, handleAttributeData, defaultText, superCategories, mainCategories, productCategoriesManufacturerDealsIn }) => {

	const categoryService = useCategoryService();

	const [productCategories, setProductCategoriesTo] = useState<Category[]>([]);
	const [categoryOptions, setCategoryOptionsTo] = useState<ICategoryOptions>({
		superCategories: [],
		mainCategories: [],
		productCategories: []
	});
	const [selectedCategories, setSelectedCategoriesTo] = useState<ISelectedCategories>({
		superCategory: searchPayload?.superCategory ?? null,
		mainCategory: searchPayload?.mainCategory ?? null,
		productCategory: searchPayload?.productCategory ?? null,
	});

	const updateCategoryOptions = (categoryType: string, options: ICategoryKeyLabel[]) => {
		setCategoryOptionsTo((prevState: ICategoryOptions) => ({
			...prevState,
			[categoryType]: options
		}));
	};

	const updateCategoryInput = (categoryType: string, event: any, options: ICategoryKeyLabel[]) => {
		if (Array.isArray(options)) {
			const selectedCategory = options.find((item) => item.id === event.target.value);
			if (typeof handleAttributeData === 'function') {
				const productAttributes = productCategories.find((productCategory: Category) => {
					return productCategory?.id === event.target.value;
				});
				handleAttributeData(productAttributes);
			}
			if (selectedCategory) {
				setSelectedCategoriesTo((prevState: ISelectedCategories) => ({
					...prevState,
					[categoryType]: selectedCategory,
				}));
				updateSearchParams(categoryType, selectedCategory);
			}
			if (categoryType === "superCategory") {
				setSelectedCategoriesTo((prevState: ISelectedCategories) => ({
					...prevState,
					mainCategory: { label: '', id: null },
					productCategory: { label: '', id: null }
				}));
				updateSearchParams("mainCategory", { label: '', id: null });
				updateSearchParams("productCategory", { label: '', id: null });
				setSelectedCategoriesTo((prevState: ISelectedCategories) => ({
					...prevState,
					productCategories: []
				}));
			} else if (categoryType === "mainCategory") {
				setSelectedCategoriesTo((prevState: ISelectedCategories) => ({
					...prevState,
					productCategories: { label: '', id: null }
				}));
				updateSearchParams("productCategory", { label: '', id: null });
			}
		}
	};

	const convertToOptions = (categories: Category[], categoriesManufacturerDealsWith: any[]) => {
		if (!Array.isArray(categories)) {
			throw new Error('Input categories should be arrays.');
		}
		if (categoriesManufacturerDealsWith.length === 0) {
			return categories.map(item => ({ label: item.name, id: item.id }));
		}
		const updatedCategoriesIds = new Set(categoriesManufacturerDealsWith.map(item => item.id));
		const filteredCategories = categories.filter(item => updatedCategoriesIds.has(item.id));
		const options = filteredCategories.map(item => ({ label: item.name, id: item.id }));
		return options;
	};

	const handleCategoryChange = (categoryType: string, options: ICategoryKeyLabel[]) => (event: any) => {
		updateCategoryInput(categoryType, event, options);
	}

	useEffect(() => {
		setSelectedCategoriesTo(prevState => ({
			...prevState,
			superCategory: searchPayload?.superCategory ?? null,
			mainCategory: searchPayload?.mainCategory ?? null,
			productCategory: searchPayload?.productCategory ?? null,
		}));
	}, [searchPayload])

	useEffect(() => {
		let queryParams: ICategoryFilterRequest = {
			name: '',
			level: CATEGORY_TYPE.SUPER_CATEGORY,
			parentId: '',
			page: 0,
			size: 100,
			sort: "created_at,desc",
			id: searchPayload?.superCategory?.id
		};
		categoryService.getAllCategories(queryParams)
			.then((response) => {
				updateCategoryOptions(
					'superCategories',
					convertToOptions(response.data.data.content, superCategories)
				);
			})
			.catch((error) => {
				console.error('Error fetching super categories:', error);
			});
	}, [searchPayload?.superCategory?.id]);

	useEffect(() => {
		if (selectedCategories?.superCategory?.id) {
			let queryParams: ICategoryFilterRequest = {
				name: '',
				level: CATEGORY_TYPE.MAIN_CATEGORY,
				parentId: selectedCategories?.superCategory?.id,
				page: 0,
				size: 100,
				sort: "created_at,desc"
			};
			categoryService.getAllCategories(queryParams)
				.then((response) => {
					updateCategoryOptions(
						'mainCategories',
						convertToOptions(response.data.data.content, mainCategories)
					);
				})
				.catch((error) => {
					console.error('Error fetching main categories:', error);
				});
		}
	}, [selectedCategories?.superCategory]);

	useEffect(() => {
		if (selectedCategories?.mainCategory?.id) {
			let queryParams: ICategoryFilterRequest = {
				name: '',
				level: CATEGORY_TYPE.PRODUCT_CATEGORY,
				parentId: selectedCategories?.mainCategory?.id,
				page: 0,
				size: 100,
				sort: "created_at,desc"
			};
			categoryService.getAllCategories(queryParams)
				.then((response) => {
					setProductCategoriesTo(response.data.data.content);
					updateCategoryOptions(
						'productCategories',
						convertToOptions(response.data.data.content, productCategoriesManufacturerDealsIn)
					);
				})
				.catch((error) => {
					console.error('Error fetching product categories:', error);
				});
		}
	}, [selectedCategories?.mainCategory]);

	return ( 
		<div className='w-full flex gap-3'>
				<DropDownGroup
					defaultValue={ defaultText ? defaultText.superCategory : null }
					label='Super Category'
					value={ selectedCategories?.superCategory?.id }
					onChange={ handleCategoryChange('superCategory', categoryOptions.superCategories) }
					options={ categoryOptions.superCategories }
					disabled={ false }
				/>
				<DropDownGroup
					defaultValue={ defaultText ? defaultText.mainCategory : null }
					label='Main Category'
					value={ selectedCategories?.mainCategory?.id }
					onChange={ handleCategoryChange('mainCategory', categoryOptions.mainCategories) }
					options={ categoryOptions.mainCategories }
					disabled={ !selectedCategories.superCategory }
				/>
				<DropDownGroup
					defaultValue={ defaultText ? defaultText.productCategory : null }
					label='Product Category'
					value={ selectedCategories?.productCategory?.id }
					onChange={ handleCategoryChange('productCategory', categoryOptions.productCategories) }
					options={ categoryOptions.productCategories }
					disabled={ !selectedCategories.mainCategory }
				/>
		</div>
	);
}

export default CategoryFilterTemplate;
