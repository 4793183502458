import Button from '@mui/material/Button';
import cancelIcon from "./../../../Assets/Icons/Vector.svg";
import CategorySectionIndex from './CategorySection/CategorySectionIndex';
import StandardSectionIndex from './StandardSection/StandardSectionIndex';
import ProductClassificationSectionIndex from './ProductClassification/ProductClassificationSectionIndex';
import React, { useEffect, useState } from "react";
const SearchUpcIndex = ({ type='STANDARD', switchComponent, onClose, updateSearchDetails, searchPayload }) => {

  const [isSearchValid, setIsSearchValidTo] = useState(true)
  const searchUpcDetails = () => {
    switchComponent(2);
    return 
  }

  const displayProductCode = (data) => {
    if (data === "STANDARD") {
      return "Universal Product Code"
    } else {
      return "Master Product Code"
    }
  }
  const searchValidation = () => {
    try {
      if( searchPayload.superCategory?.id != null ) {
        if( searchPayload.mainCategory?.id == null || searchPayload.productCategory?.id == null) {
          return false
        }
      }
      return true;
    } catch (error) {
      return false;
    }
  }
  useEffect(() => {
    const isSearchValid = searchValidation();
    setIsSearchValidTo(isSearchValid)
  }, [searchPayload])
  
  return (
    <div className="absolute top-[5px] left-[5px] bg-white w-full flex flex-col p-6 box-border items-start justify-start gap-[24px] text-4xl text-text-color">
      <div className="self-stretch flex flex-row pt-0 px-0 pb-[18px] items-center justify-start gap-[10px] text-2xl text-text-color border-b-[1px] border-solid border-input-border">
        <div className="flex-1 relative leading-[20px] font-semibold">
          Search {displayProductCode(type)}
        </div>
        <button
          className="w-30 h-21"
          onClick={onClose}
        >
          <img
            className="relative w-37 h-37 overflow-hidden shrink-0"
            alt=""
            src={cancelIcon}
          />
        </button>
      </div>
      
      <CategorySectionIndex updateSearchParams={updateSearchDetails} searchPayload={searchPayload}/>
      <StandardSectionIndex updateSearchParams={updateSearchDetails} searchPayload={searchPayload}/>
      <ProductClassificationSectionIndex updateSearchParams={updateSearchDetails} searchPayload={searchPayload} type={type}/>
      {/* <GeneralInfoSectionIndex updateSearchParams={updateSearchDetails} searchPayload={searchPayload}/> */}
      {/* <ProductSpecificationSectionIndex updateSearchParams={updateSearchDetails} searchPayload={searchPayload}/> */}

      <div className="self-stretch flex flex-row items-center justify-end gap-[22px] text-mini text-text-color">
        <Button
          sx={{
            border: '1px solid blue',
            padding: '0.71rem .63rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
          }}
          variant="outlined"
          onClick={onClose}
        >
          <div className="relative leading-[1.38rem] font-semibold text-blue">Cancel</div>
        </Button>
        <button
          className="outlined rounded bg-blue overflow-hidden flex flex-row py-[0.81rem] px-[1.63rem] items-center justify-center text-[0.94rem] text-white"
          onClick={searchUpcDetails}
          disabled={!isSearchValid}
        >
          <div className="relative leading-[1.38rem] font-semibold">
            Search
          </div>
        </button>
      </div>
    </div>
  );
};

export default SearchUpcIndex;
