import React, { useState, useEffect } from 'react'
import SecondaryCataloguePreview from './SecondaryCataloguePreview';
import { useLocation } from "react-router-dom";
import axios from 'axios';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
const BaseUrl = process.env.REACT_APP_API_V1_URL;

const ViewSecondaryCatalogue = () => {
    const { user } = useAuthenticatedUser();
    const location = useLocation();
    const catalogueMode = location.state?.mode;
    const getbyId = location.state?.catalogueId;
    const formerror = location.state?.formError;
    const category = location?.state.category ?? {};
    const catalogueType = location?.state.catalogueType ?? {};
    const [catalogueName, setCatalogueName] = useState();
    const [minimumQuantity, setMinimumQuantity] = useState(0);
    const [availableQuantity, setAvailableQuantity] = useState();
    const [application, setApplication] = useState([]);
    const [additionalProductDetails, setAdditionalProductDetails] = useState();
    const [keywords, setKeywords] = useState([]);
    const [status, setStatus] = useState("ACTIVE");
    const [defectDetails, setDefectDetails] = useState();
    const [price, setPrice] = useState();
    const [packagingType, setPackagingType] = useState();
    const [termsConditions, setTermsConditions] = useState();
    const [imageData, setImageData] = useState([]);
    const [formData, setFormData] = useState([]);
    const [gradeName, setGradeName] = useState();
    const [productCategoryName, setProductCategoryName] = useState();
    const [mainCategoryName, setMainCategoryName] = useState();
    const [superCategoryName, setSuperCategoryName] = useState();
    const [warehouseDetails, setWarehouseDetails] = useState();


    const fetchSecondaryCatalogueDetail = async () => {
        if (catalogueMode === "VIEW") {
            const url = `${BaseUrl}/secondary-catalogue/${getbyId}`
            axios.get(url, {
                headers: {
                    Authorization: `${user.token}`,
                    "Content-Type": "application/json",
                }
            })
                .then((response) => {
                    setCatalogueName(response.data.data?.catalogueName);
                    setMinimumQuantity(response.data.data?.minimumQuantity);
                    setAvailableQuantity(response.data.data?.availableQuantity);
                    setApplication(response.data.data?.application);
                    setAdditionalProductDetails(response.data.data?.additionalProductDetails);
                    setKeywords(response.data.data?.keywords);
                    setDefectDetails(response.data.data?.defectDetails);
                    setPackagingType(response.data.data?.packagingType);
                    setTermsConditions(response.data.data?.termsConditions);
                    setPrice(response.data.data?.price.default);
                    setStatus(response.data.data?.status);
                    fetchSecondaryProductDetail(response.data.data?.secondaryProductIds);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }

    const fetchSecondaryProductDetail = async (ids) => {
        for(const id of ids){
            const url = `${BaseUrl}/secondary-product/${id}`
            axios.get(url, {
                headers: {
                    Authorization: `${user.token}`,
                    "Content-Type": "application/json",
                }
            })
                .then((response) => {
                    setFormData(prevData => [response.data.data, ...prevData]);
                    fetchGrade(response.data.data?.gradeId);
                    fetchCategory(response.data.data?.productCategoryId);
                    fetchWarehouseDetails(response.data.data?.warehouseId);
                })
                .catch((error) => {
                    console.error('Error:', error);
    
                });
        }
    }
    const fetchGrade = (id) => {
        axios.get(`${BaseUrl}/quality-grade/${id}`, {
            headers: {
                Authorization: `${user.token}`,
                "Content-Type": "application/json",
            }
        })
            .then(response => {
                setGradeName(response.data.data?.name);
            })
            .catch(error => {
                console.error('Error fetching main categories:', error);
            });
    }
    const fetchCategory = (id) => {
        axios
            .get(`${BaseUrl}/category/${id}`, {
                headers: {
                    Authorization: `${user.token}`,
                    "Content-Type": "application/json",
                }
            })
            .then((response) => {
                setProductCategoryName(response.data.data?.name);
                fetchMainCategory(response.data.data?.parentId);
            })
            .catch((error) => {
                console.error('Error fetching super categories:', error);
            });
    }
    const fetchMainCategory = (id) => {
        axios
            .get(`${BaseUrl}/category/${id}`, {
                headers: {
                    Authorization: `${user.token}`,
                    "Content-Type": "application/json",
                }
            })
            .then((response) => {
                setMainCategoryName(response.data.data?.name)
                fetchSuperCategory(response.data.data?.parentId);
            })
            .catch((error) => {
                console.error('Error fetching super categories:', error);
            });
    }
    const fetchSuperCategory = (id) => {
        axios
            .get(`${BaseUrl}/category/${id}`, {
                headers: {
                    Authorization: `${user.token}`,
                    "Content-Type": "application/json",
                }
            })
            .then((response) => {
                setSuperCategoryName(response.data.data?.name)
            })
            .catch((error) => {
                console.error('Error fetching super categories:', error);
            });
    }
    const fetchWarehouseDetails = (warehouseId) => {
        axios
            .get(`${BaseUrl}/warehouse/${warehouseId}`, {
                headers: {
                    Authorization: `${user.token}`,
                    "Content-Type": "application/json",
                }
            })
            .then((response) => {
                setWarehouseDetails(response?.data?.name);
            })
            .catch((error) => {
                console.error("Error fetching UPC details:", error);
                setWarehouseDetails(null);
            });

    };

    const fetchDocuments = async (resId, resType) => {
        try {
            if (!resId || !resType) {
                return;
            }
            const apiUrl = `${BaseUrl}/files/${resType}/${resId}`;

            const response = await axios.get(apiUrl, {
                headers: {
                    Authorization: `${user.token}`
                }
            });
            setImageData(response.data.data);
        } catch (err) {
            // Handle the error, if needed
            console.error("Error fetching documents:", err);
        }
    };

    useEffect(() => {
        fetchSecondaryCatalogueDetail();
        fetchDocuments(getbyId, "SECONDARY_CATALOGUE");
    }, [getbyId]);


    return (
        <SecondaryCataloguePreview
            status={status === 'ACTIVE' ? true : false}
            setStatus={setStatus}
            catalogueName={catalogueName}
            minimumQuantity={minimumQuantity}
            availableQuantity={availableQuantity}
            application={application}
            additionalProductDetails={additionalProductDetails}
            keywords={keywords}
            defectDetails={defectDetails}
            price={price}
            packagingType={packagingType}
            termsConditions={termsConditions}
            formErrors={formerror}
            catalogueMode={catalogueMode}
            imageData={imageData}
            mode={'View'}
            formDatas={formData}
            gradeName={gradeName}
            productCategoryName={productCategoryName}
            mainCategoryName={mainCategoryName}
            superCategoryName={superCategoryName}
            warehouseDetails={warehouseDetails}
            catalogueType={catalogueType}
            category={category}                

        />
    );
}

export default ViewSecondaryCatalogue