import React, { useEffect, useState } from 'react'
import TabsV2, { ITabsSchema } from '../../molecules/TabsV2/TabsV2';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { makeRoute } from '../../../utils/helper';
import { ADMIN_ROUTES } from '../../../utils/constant';
import { createUseStyles } from 'react-jss';
import CustomerId from '../../organisms/CustomerDetails/CustomerId';
import KeyPersonDetailsPage from './KeyPersonDetail.page';
import VendorLocationPage from './VendorLocation.page';
import VendorStatutoryDetailPage from './VendorStatutoryDetail.page';
import VendorMappingPage from './VendorMapping.page';
import PreferencesPage from './Preferences.page';
import VendorProfilePage from './VendorProfile.page';
import VendorProfileOverviewPage from './VendorProfileOverview.page';

import VendorBrandCenterPage from './VendorBrandCenter.page';

import { useUserService } from '../../../services/useUserService';
import { useVendorServiceV2 } from '../../../services/useVendorServiceV2';
import { HTTP_STATUS } from '../../../utils/types';
import ChangePasswordPage from './ChangePassword.page';

const useStyles = createUseStyles((theme: any) => ({
  container: {
    borderBottom: `1px solid ${theme.palette.border.tritiaryLight}`
  },
  text: {
    color: theme.palette.textV2.primary.primary900,
    borderBottom: `1px solid ${theme.palette.border.tritiaryLight}`
  }
}));

export const VendorDetailsViewPage: React.FC = () => {

  const classes = useStyles();
  const vendorService = useVendorServiceV2();
  const userService = useUserService();
  const [schema, setSchema] = useState<ITabsSchema[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get('tab') || "1";
  const params = useParams();
  const id = Number(params.id);
  const [activeTab, setActiveTab] = useState<string>("1");
  const navigate = useNavigate();

  const getSchema = async () => {
    let isDisabled = false;
    try {
      const businessProfileResponse = await vendorService.getVendor(id);
      if (businessProfileResponse.status === HTTP_STATUS.OK) {
        isDisabled = businessProfileResponse?.data?.data.user.email == null;
      }
    } catch (error) {
      console.error('Error while fetching vendor details');
    }
    return [
      { label: 'Profile', component: <VendorProfileOverviewPage /> },
      { label: 'Business Profile', component: <VendorProfilePage /> },
      { label: 'Business Location', component: <VendorLocationPage /> },
      { label: 'Key Person Detail', component: <KeyPersonDetailsPage /> },
      // { label: 'My Materials', component: <>scrap</> },
      { label: 'Statutory Documents', component: <VendorStatutoryDetailPage/> },
      { label: 'Preferences', component: <PreferencesPage/> },
      { label: 'Change Password', component:<ChangePasswordPage />, disabled: isDisabled },
      { label: 'Vendor Mapping', component: <VendorMappingPage/> },
      { label: 'Brand Center', component: <VendorBrandCenterPage /> },
    ];
  };

  useEffect(() => {
    const fetchSchema = async () => {
      const schema = await getSchema();
      setSchema(schema);
    };

    fetchSchema();
  }, [id]);

  const handleTabChange = (tab: string | number) => {
    setActiveTab(tab.toString());
    const route = makeRoute(ADMIN_ROUTES.VENDOR_MANAGEMENT_VIEW, { query: { tab: tab.toString() }, params: { id: id } });
    navigate(`${route}`);
  };

  useEffect(() => {
    setActiveTab(currentTab);
  }, [currentTab])

  return (
    <div>
      <div className={`flex justify-between ${classes.text}`}>
        <div className={`text-2xl font-medium pb-6`}>Vendor Management</div>
        <div><CustomerId customerId={id} title="Vendor ID" /></div>
      </div>
      <div className='pt-6'>
        <TabsV2 schema={schema} value={activeTab} setValue={handleTabChange} />
      </div>
    </div>
  )
}
