import JoditEditor from 'jodit-react'
import { FormControl } from "@mui/material";
import React, { useMemo, useRef } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import { ISecondaryInventoryForm, secondaryInventorySectionProp } from './SecondaryInventoryForm.template';
import Button from '../../atoms/Button';
import { SECONDARY_INVENTORY_SECTIONS } from '../../../utils/types';
import { createUseStyles } from 'react-jss';
import { MODE } from '../../../utils/constant';

const useStyles = createUseStyles((theme: any) => ({
	errorMessage: {
		color: theme.palette.action.danger,
	},
	container: {
		backgroundColor: theme.palette.background.primaryLight,
	},
	heading: {
		color: theme.palette.text.primaryDarkLight,
	},
}));

const DefectDescriptionTemplate: React.FC<secondaryInventorySectionProp> = ({ formik, id, mode, setCurrentSectionTo }) => {
	const classes = useStyles()
	const descriptionEditor = useRef(null);
	const redirectToSecondaryInventoryList = () => {
		setCurrentSectionTo(SECONDARY_INVENTORY_SECTIONS.CATEGORY);
	};
	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		const requiredFields = ["defectDetails"];
		let fieldError = false;
		for (const key of requiredFields) {
			if (key in formik.errors) {
				formik.setFieldTouched(key, true);
				fieldError = true;
			}
		}
		if (fieldError === true) return;
		formik.setValues((prevValues: ISecondaryInventoryForm) => ({
			...prevValues,
			defectDetails: formik.values.defectDetails,
		}));
		setCurrentSectionTo(SECONDARY_INVENTORY_SECTIONS.WAREHOUSE);
	};
	const onDefectDetailsChange = (data: any) => {
		formik.setFieldValue("defectDetails", data);
	}

	const config = useMemo(() => {
		return {
			readonly: mode === MODE.UPDATE,
			placeholder: "Enter Description...",
		};
	}, []);

	return (
		<div className='grid gap-y-4'>
			<div className={`${classes.container} grid gap-y-4 p-6 rounded`}>
				<div className={` ${classes.heading} text-lg font-semibold`}>
					Description of the Defects
				</div>
				<FormControl fullWidth>
					<JoditEditor
						ref={descriptionEditor}
						value={formik.values.defectDetails}
						onBlur={(newContent) => onDefectDetailsChange(newContent)}
						config={config}
					/>
					{formik.touched.defectDetails && formik.errors.defectDetails ? (
						<FormHelperText>{formik.errors.defectDetails}</FormHelperText>
					) : null}
				</FormControl>
			</div>

			<div className="flex justify-between gap-x-3">
				<Button variant="outlined" label="Back" onClick={redirectToSecondaryInventoryList} />
				<Button variant="contained" label="Next" onClick={handleSubmit} />
			</div>
		</div>
	)
}

export default DefectDescriptionTemplate