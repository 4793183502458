import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { HTTP_STATUS } from '../../../../utils/types';
import { ADMIN_ROUTES, EXCEPTION_CODE, MODE, STATUS } from '../../../../utils/constant';
import { makeRoute } from '../../../../utils/helper';
import SuperServiceFormTemplate from '../../../templates/VASMaster/Categories/SuperServiceForm.template';
import { ServicePayload, useVASMasterCategoryService } from '../../../../services/useVASMasterCategoryService';

const validationSchema = Yup.object().shape({
    name: Yup.string().max(30, "Super Service Name cannot be more than 30 characters").required("Super Service Name is required"),
    description: Yup.string().max(500, "Description cannot be more than 500 characters").required("Description is required"),
});

const SuperServiceCreatePage: React.FC = () => {
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const vasMasterCategoryService = useVASMasterCategoryService();
    const navigate = useNavigate();

    const onBack = () => {
        navigate(makeRoute(ADMIN_ROUTES.VAS_MASTER_CATEGORIES_LIST, { query: { type: 'SUPER_SERVICE' } }));
    }

    const createService = async (service: ServicePayload) => {
        vasMasterCategoryService.createService(service)
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    showSnackbar('success', "New Super Service Created");
                    onBack();
                } else if (res.data.exceptionCode === EXCEPTION_CODE.DUPLICATE_ENTRY) {
                    showSnackbar('error', "Super Service already exists. Please try again");
                }
            }).catch((error: any) => {
                showSnackbar('error', "Failed to create Super Service");
            })
    }

    const formik = useFormik<ServicePayload>({
        initialValues: {
            name: "",
            status: STATUS.ACTIVE,
            description: "",
            level: 0,
            parentId: null
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            createService({ ...values, status: values.status })
        }
    })

    return (
        <div>
            {SnackBarComponent}
            <SuperServiceFormTemplate mode={MODE.ADD} onBack={onBack} formik={formik} />
        </div>
    )
}

export default SuperServiceCreatePage