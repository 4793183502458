import React, { ReactNode } from "react";
import { createUseStyles } from "react-jss";

interface CustomerDetailTemplateProps {
	icon: ReactNode;
	value: string | number;
}

const useStyles = createUseStyles((theme: any) => ({
	userDetails: {
		color: theme.palette.text.grey,
	},
}));

const CustomerDetailTemplate: React.FC<CustomerDetailTemplateProps> = ({ icon, value }) => {
	const classes = useStyles();
	return (
		<div className="flex gap-1">
			{ icon }
			<p className={ `${ classes.userDetails }` }>{ value }</p>
		</div>
	);
};

export default CustomerDetailTemplate;
