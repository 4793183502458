import React, { useState, useRef, useContext, useEffect } from "react";

import { FilesWrapper } from "../../../Shared/Files/DisplayFiles.jsx";
import { DisplayDocuments } from "../../../Shared/Files/FileItem.jsx";
import { useNavigate } from "react-router-dom";
import InventoryContext from "../../../Core/Context/inventory/NewInventoryContext";
import { SET_IMAGE_DATA, SET_IMAGE_IDS_DELETE } from "../../../Core/Context/inventory/NewInventoryProvider.tsx";
import { CustomButton } from "../../../Shared/Button/CustomButton";
import uploadIcon from "../../../Assets/Icons/uploadIcon.svg";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useAuthenticatedUser } from "../../../../hooks/useAuthenticatedUser";
const BaseUrl = process.env.REACT_APP_API_V1_URL;
const UploadProductImages = ({ updateImages, currentImageCount = 0, maxImageCount }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);

  const handleFileSelect = (event) => {
    if (!event.target.files) return;
    const newFiles = Array.from(event.target?.files);

    setSelectedFiles(newFiles);
    if (newFiles.length + currentImageCount > maxImageCount) {
      alert(`Maximum of ${maxImageCount} images allowed`);
      return;
    }
    updateImages(newFiles);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
  }
  };

  const handleDropDragOver = (event) => {
    event.preventDefault();
    const newFiles = Array.from(event.dataTransfer?.files);

    setSelectedFiles(newFiles);
    if (newFiles.length + currentImageCount <= maxImageCount) {
      updateImages(newFiles);
    }
    else {
      alert(`Maximum of ${maxImageCount} images allowed`);
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
  }
  };

  return (
    <div className="relative w-full flex flex-col gap-3 text-left text-text-color font-roboto my-2">
      <div className="relative w-full flex flex-row items-center justify-start gap-2 text-left text-2xl text-blue font-roboto">
        <div className="font-semibold text-base md:text-lg lg:text-xl">
          Upload Product Images{" "}
        </div>
        <Typography
          style={{
            color: "#A9A5A5",
            fontSize: "18px",
            margin: "0px",
          }}
        >
          {`(Maximum-${maxImageCount})`}
        </Typography>
      </div>

      <div
        className="relative w-full h-[16.13rem] border-[2px] border-dashed border-gainsboro flex items-center justify-center"
        onDrop={handleDropDragOver}
        onDragOver={handleDropDragOver}
      >
        <div className="flex flex-col items-center gap-4">
          <div className="flex flex-col items-center w-[10.56rem] h-[4.94rem]">
            <img
              className="w-[2.25rem] h-[2.25rem] overflow-hidden"
              alt=""
              src={uploadIcon}
            />
            <div className="mt-3 text-[0.8rem] tracking-[0.3px] font-medium text-text-color">
              Drag your files here - there
            </div>
          </div>
          <div className="text-[1rem] tracking-[0.3px]">or</div>

          <div className="flex flex-col items-center rounded bg-blue flex items-center justify-center text-[0.94rem] text-white font-semibold">
            <input
              type="file"
              multiple
              className="hidden"
              onChange={handleFileSelect}
              id="fileInput"
              ref={fileInputRef}
            />
            <label htmlFor="fileInput" className="cursor-pointer  py-[0.81rem] px-[1.63rem]">
              Browse
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

const UploadDocs = () => {
  const navigate = useNavigate();
  const { user } = useAuthenticatedUser();
  const [imageData, setImageData] = useState([]);
  const [imageFormData, setImageFormData] = useState([]);
  const inventoryContextData = useContext(InventoryContext);
  const [skuMode, setSkuMode] = useState();
	const [imageIdsToDelete, setImageIdsToDelete] = useState([]);    	
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const id = urlParams.get("id");
  const skMode = urlParams.get("skuMode");
  const mode = urlParams.get("mode");

  useEffect(() => {
    if (
      Object.keys(inventoryContextData.inventoryDetailState.inventoryFormData)
        .length === 0
    ) {
      navigate(
        `/inventory/step1?id=${id}&skuMode=${skMode}&mode=${mode}`
      );
    }
  }, []);
  const handleRemoveImage = async (id) => {
    if (id === undefined || id === null) {
      return;
    }

    if (imageData.length < 0) {
      return;
    }
    setImageIdsToDelete((prevImageIds) => [...prevImageIds, imageData[id].id]);
    const updatedDetails = imageData.filter(
      (detail, currentIndex) => currentIndex !== id
    );
    setImageData(updatedDetails);
  };

  const handleRemoveFormImage = (id, isForm) => {
    const updatedDetails = imageFormData.filter(
      (detail, currentIndex) => currentIndex !== id
    );

    setImageFormData(updatedDetails);
  };

  const updateImages = (images) => {
    // setImageData(images);
    setImageFormData((prevImages) => [...prevImages, ...images]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    inventoryContextData.updateInventoryDetailsState(
      imageFormData,
      SET_IMAGE_DATA,
    );
    inventoryContextData.updateInventoryDetailsState(
      imageIdsToDelete,
      SET_IMAGE_IDS_DELETE,
    );

    navigate(
      `/inventory/step4?id=${id}&skuMode=${urlParams.get(
        "skuMode"
      )}&mode=${mode}`
    );
  };
  const fetchDocuments = async (resId, resType) => {
    try {
      if (!resId || !resType) {
        return;
      }
      const apiUrl = `${BaseUrl}/files/${resType}/${resId}`;

      const response = await axios.get(apiUrl,{
        headers: {
           Authorization: `${user.token}`
        }
     }).then((res) => {
        setImageData(res.data.data);
        return;
      });
    } catch (err) {
      console.log("Image Fetch Error - ", err);
      return;
    }
  };

  useEffect(() => {
    setImageFormData(inventoryContextData?.inventoryDetailState?.imageData);
    setSkuMode(inventoryContextData?.inventoryDetailState?.mode);
    fetchDocuments(
      inventoryContextData?.inventoryDetailState?.inventoryFormData?.id,
      "INVENTORY"
    );
  }, []);
  return (
    <div className="mt-3 sku-background grid gap-y-5 py-5">
      <div className="flex justify-center text-label font-roboto text-5xl font-semibold">
        {inventoryContextData?.inventoryDetailState?.mode === "ADD"
          ? "Add New"
          : "Edit"}
        SKU
      </div>
      <div className="w-4/5 mt-4 m-auto flex justify-between gap-x-0.5 text-var(--black, #4D4D4D) text-center font-roboto text-xs font-normal leading-normal">
        <div className="grid gap-y-1 w-1/4">
          <div className="h-3 bg-blue"></div>
        </div>
        <div className="grid gap-y-1 w-1/4">
          <div className="h-3 bg-blue"></div>
        </div>
        <div className="grid gap-y-1 w-1/4">
          <div className="h-3 bg-blue"></div>
        </div>
        <div className="grid gap-y-1 w-1/4">
          <div className="h-3 bg-gray-300"></div>
        </div>
      </div>
      <div className="search-upc-container grid w-1/2 gap-y-4 p-6  m-auto  my-8  bg-white">
        <form className="flex flex-col gap-y-5" onSubmit={handleSubmit}>
          <>
            <div className="">
              <div className="grid product-description m-auto gap-y-2">
                {/* Display Uploaded Files */}
                <FilesWrapper heading={"Product Images"}>
                  <DisplayDocuments
                    documentsData={imageFormData}
                    isEdit={true}
                    isForm={true}
                    deleteDocument={handleRemoveFormImage}
                  />
                  <DisplayDocuments
                    documentsData={imageData}
                    isEdit={true}
                    isForm={false}
                    deleteDocument={handleRemoveImage}
                  />
                </FilesWrapper>

                {/* Upload Image Section */}
                <UploadProductImages updateImages={updateImages} currentImageCount={imageData.length + imageFormData.length} maxImageCount={4}/>

                {/*<h2>Drag your file here</h2>*/}
                <div className="grid product-description m-auto ">
                  <h2>Drag & drop any file here </h2>
                </div>
              </div>
            </div>
          </>

          <div className="text-center flex gap-x-4  ">
            <CustomButton
              className="w-full"
              onClick={() => {
                navigate(
                  `/inventory/step2?id=${urlParams.get(
                    "id"
                  )}&skuMode=${urlParams.get("skuMode")}&mode=${urlParams.get(
                    "mode"
                  )}`
                );
              }}
            >
              Back
            </CustomButton>
            <CustomButton
              className="w-full"
              variant="Primary"
              type="submit"
              onClick={handleSubmit}
            >
              Preview
            </CustomButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UploadDocs;
