import { useState, useEffect } from 'react';
import Chip from '@mui/material/Chip';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@mui/material";
const BaseUrl = process.env.REACT_APP_API_V1_URL;
const CataloguePackingTypes = ({ packingTypesData, formErrors, initialValues, updateFunction, mode = 'ADD' }) => {
    const [packingTypes, setPackingTypes] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const [selectedValue, setSelectedValue] = useState({});

    const handleSelectChange = (event) => {
        let selectedValue = event.target.value
        if (!selectedValues.some((option) => option.value === selectedValue.value)) {
            // const updatedValues = [...selectedValues, selectedValue];/
            const updatedValues = [selectedValue];
            setSelectedValues(updatedValues);
            updateFunction(updatedValues);
            setSelectedValue('');
        }
    };

    const handleChipDelete = (deletedValue) => {
        const updatedValues = selectedValues.filter((packageItem) => packageItem.value !== deletedValue.value);
        setSelectedValues(updatedValues);
        updateFunction(updatedValues);
    };

    useEffect(() => {
        setPackingTypes(packingTypesData)
        if (initialValues && initialValues.length) {
            setSelectedValues(packingTypesData.filter(item => item.value === initialValues?.[0]?.value));
        }}, 
        [packingTypesData]);

    return (
        <div>

            {/* Select Packing Types */}
            <div className="flex-1 flex flex-col items-center justify-start mt-2">
                <div className="self-stretch h-12 flex flex-row py-[3.5px]  items-center justify-start gap-[2px] ">
                    {mode !== "VIEW" ? (

                        <FormControl fullWidth className="bg-white">
                        <InputLabel id="label">Select Packing Types</InputLabel>
                        <Select
                            className={`block w-full border  ${formErrors?.packagingTypes
                                ? "border-red-500"
                                : "border-gray-300"
                                } rounded-md focus:outline-none ${formErrors?.packagingTypes
                                    ? "ring-red-500"
                                    : "ring-blue-500"
                                } focus:border-blue-500 `}
                            labelId="label"
                            id="select"
                            label="Select Packing Types"
                            value={selectedValue} 
                            onChange={handleSelectChange}
                            disabled={mode === "VIEW"}
                            error={formErrors?.packagingTypes}
                        >
                            {packingTypes && packingTypes.length > 0 && packingTypes.map((option) => (
                                <MenuItem key={option.value} value={option}>
                                    {option.name}
                                </MenuItem>
                            ))}

                        </Select>
                    </FormControl>
                       ) : (<div className="flex flex-col items-start justify-start">
                                            <div className="relative font-semibold inline-block shrink-0">
                                                Packaging Types
                                            </div>
                                        </div>)
                }
                </div>
            </div>

            <div className="mt-5 mb-5 items-start justify-start">
                {selectedValues.map((option) => (
                    <Chip
                        key={option.value}
                        label={option.name}
                        onDelete={mode !== "VIEW" ? () => handleChipDelete(option) : undefined}
                        style={{ marginRight: '5px' }}
                    />
                ))}
            </div>
        </div>
    );
};

export default CataloguePackingTypes;


