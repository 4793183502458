import React, { useEffect, useMemo, useState } from 'react'
import { FormikProps, useFormik } from "formik";
import * as Yup from 'yup';
import { HTTP_STATUS, SECONDARY_INVENTORY_SECTIONS } from '../../../utils/types';
import CategoryDetailTemplate from './CategoryDetail.template';
import DefectDescriptionTemplate from './DefectDescription.template';
import WareHouseDetailTemplate from './WareHouseDetail.template';
import ImageDetailTemplate from './ImageDetail.template';
import { ISecondaryInventoryResponse } from '../../pages/SecondaryInventory/SecondaryInventoryUpdate.page';
import { useSecondaryInventoryService } from '../../../services/useSecondaryInventoryService';
import { ADMIN_ROUTES, MODE, RESOURCE_TYPE } from '../../../utils/constant';
import { useNavigate } from 'react-router-dom';
import { useFileService } from '../../../services/useFileService';
import { ImageData } from '../../organisms/ImageUploader';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useCategoryService } from '../../../services/useCategoryService';
import { useQualtiyGradeService } from '../../../services/useQualityGradesService';

export interface secondaryInventorySectionProp {
  setCurrentSectionTo: (section: SECONDARY_INVENTORY_SECTIONS) => void;
  formik: any;
  id: number | null;
  mode: string;
  image: (ImageData | File)[];  
  setImagesTo: (images:any) => void;
}

interface ISecondaryInventoryFormProps {
  // onInventorySubmit: (inventoryRequestBody: IInvetoryRequest) => void;
  secondaryInventory: ISecondaryInventoryResponse | null;
  mode: string;
  image: (ImageData | File)[];  
  setImagesTo: (images:any) => void;
}

interface IProductSpecificationAttributes {
  name: string;
  maxValue: string;
  minValue: string;
}
interface IproductSpecification {
  productSpecification: IProductSpecificationAttributes[];
}
export interface ISecondaryInventoryForm {
  superCategory: {
		label: string;
		id: number | null;
	};
	mainCategory: {
		label: string;
		id: number | null;
	};
	productCategory: {
		label: string;
		id: number | null;
	};
  grade: {
		label: string;
		id: number | null;
	};
  shape: string;
  defectDetails: string;
  warehouseId: number | null;
  warehousePlacement: string;
  basePrice: number | null;
  netWeight: number | null;
  grossWeight: number | null;
  quantity: number | null;
  skuCode: string;
  attributes: IproductSpecification | null;

}

const SecondaryInventoryFormTemplate: React.FC<ISecondaryInventoryFormProps> = ({ secondaryInventory, mode, image, setImagesTo }) => {
  const navigate = useNavigate();
  const secondaaryInventoryService = useSecondaryInventoryService();
  const fileService = useFileService();
  const { showSnackbar, SnackBarComponent } = useSnackbar();
  const [currentSection, setCurrentSectionTo] = useState<SECONDARY_INVENTORY_SECTIONS>(SECONDARY_INVENTORY_SECTIONS.CATEGORY);
  const categoryService = useCategoryService();
  const qualityGradeService = useQualtiyGradeService();

  const initialValues: ISecondaryInventoryForm = {
    superCategory:
    {
      id: null,
      label: ""
    },
    mainCategory:
    {
      id: null,
      label:  ""
    },
    productCategory:
    {
      id: secondaryInventory?.productCategoryId ?? null,
      label: ""
    },
    grade:
    {
      id: secondaryInventory?.gradeId ?? null,
      label: ""
    },
    shape: secondaryInventory?.shape ?? '',
    defectDetails: secondaryInventory?.defectDetails ?? '',
    warehouseId: secondaryInventory?.warehouseId ?? null,
    warehousePlacement: secondaryInventory?.warehousePlacement ?? '',
    basePrice: secondaryInventory?.basePrice ?? null,
    netWeight: secondaryInventory?.netWeight ?? null,
    grossWeight: secondaryInventory?.grossWeight ?? null,
    quantity: secondaryInventory?.quantity ?? null,
    skuCode: secondaryInventory?.skuCode ?? '',
    attributes: secondaryInventory?.attributes ?? null,
  };

  const validationSchema = Yup.object().shape({
    superCategory: Yup.object().shape({
			id: Yup.number().required('Super Category is required'),
			label: Yup.string().required('Super Category is required')
		}).required('Super Category is required'),
		mainCategory: Yup.object().shape({
			id: Yup.number().required('Main Category is required'),
			label: Yup.string().required('Main Category is required')
		}).required('Main Category is required'),
		productCategory: Yup.object().shape({
			id: Yup.number().required('Product Category is required'),
			label: Yup.string().required('Product Category is required')
		}).required('Product Category is required'),
    grade: Yup.object().shape({
			id: Yup.number().required('Grade is required'),
			label: Yup.string().required('Grade is required')
		}).required('Grade is required'),
    shape: Yup.string().required('Shape is required'),
    defectDetails: Yup.string().required('Defect details are required').test('no-p-br-tags', 'Defect details cannot be Empty', ((value:any)=> value !== '<p><br></p>')),
    warehouseId: Yup.number().required('Warehouse  is required'),
    warehousePlacement: Yup.string().required('Warehouse placement is required'),
    basePrice: Yup.number().required('Base price is required').positive('Base price must be positive'),
    netWeight: Yup.number().required('Net weight is required').min(0, 'Net weight must be greater than or equal to zero'),
    grossWeight: Yup.number().required('Gross weight is required').min(0, 'Gross weight must be greater than or equal to zero'),
    // quantity: Yup.number().required('Quantity is required').positive('Quantity must be positive').integer('Quantity must be an integer'),
    skuCode: Yup.string(),
    attributes: Yup.object().shape({
      productSpecification : Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('Name is required'),
          minValue: Yup.string().required('Min Value is required'),
          // uom: Yup.string().required('UOM is required'),
          // maxValue: Yup.string().required('Max Value is required')
        })
      )
    })
  });
  const formik = useFormik<ISecondaryInventoryForm>({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async () => {
      const attributes = { productSpecification: formik.values.attributes?.productSpecification || [] };
  
      attributes.productSpecification.forEach((specification, index) => {
        if (specification.maxValue === "") {
          attributes.productSpecification[index].maxValue = attributes.productSpecification[index].minValue;
        }
      });
  
      const body = {
        productCategoryId: formik.values.productCategory.id,
        gradeId: formik.values.grade.id,
        shape: formik.values.shape,
        attributes: attributes,
        defectDetails: formik.values.defectDetails,
        warehouseId: formik.values.warehouseId,
        warehousePlacement: formik.values.warehousePlacement,
        basePrice : formik.values.basePrice,
        netWeight: formik.values.netWeight,
        grossWeight: formik.values.grossWeight,
        quantity: 1,
      }
      try {
        const secondaryInventoryResponse = await (mode === MODE.ADD ? secondaaryInventoryService.createSecondaryInventory(body) : secondaaryInventoryService.updateSecondaryInventory(secondaryInventory?.id as number, body));
        if (secondaryInventoryResponse.status === HTTP_STATUS.OK) {
          const resourceId = mode === MODE.ADD ? secondaryInventoryResponse.data.data.id : secondaryInventory?.id as number;
          await fileService.uploadImage(image,resourceId,RESOURCE_TYPE.SECONDARY_INVENTORY);
          mode === MODE.ADD  ? navigate(ADMIN_ROUTES.SECONDARY_INVENTORY_LIST) : navigate(-1);
        }
      } catch (error) {
        showSnackbar("error", `Secondary Inventory creation failed ${error}`)
      }
    },
  });

//   useEffect(() => {
//     if (secondaryInventory && mode === MODE.UPDATE) {
//       formik.setValues({
//         superCategory: {
//           id:  null,
//           label: ""
//         },
//         mainCategory: {
//           id:  null,
//           label: ""
//         },
//         productCategory: {
//           id: secondaryInventory?.productCategoryId ?? null,
//           label: ""
//         },
//         grade: {
//           id: secondaryInventory?.gradeId ?? null,
//           label: ""
//         },
//         shape: secondaryInventory?.shape ?? '',
//         defectDetails: secondaryInventory?.defectDetails ?? '',
//         warehouseId: secondaryInventory?.warehouseId ?? null,
//         warehousePlacement: secondaryInventory?.warehousePlacement ?? '',
//         basePrice: secondaryInventory?.basePrice ?? null,
//         netWeight: secondaryInventory?.netWeight ?? null,
//         grossWeight: secondaryInventory?.grossWeight ?? null,
//         quantity: secondaryInventory?.quantity ?? null,
//         skuCode: secondaryInventory?.skuCode ?? '',
//         attributes: secondaryInventory?.attributes ?? null,
//       });
//     }
//   }, [secondaryInventory, mode]);


//   useEffect(() => {
//       if (secondaryInventory?.productCategoryId && mode === MODE.UPDATE) {
//         categoryService.getCategoryById(secondaryInventory?.productCategoryId)
//           .then(res => {
//             if (res.status === HTTP_STATUS.OK) {
//               let category = res.data.data;
//               console.log("res code = ", category)
//               console.log("Product Formik Data ", formik);
//               formik.setValues(prevValues => ({
//                 ...prevValues,
//                 superCategory: {
//                   id: category?.ancestors?.superParentCategory?.id ?? null,
//                   label: category?.ancestors?.superParentCategory?.name ?? ""
//                 },
//                 mainCategory: {
//                   id: category?.ancestors?.parentCategory?.id ?? null,
//                   label: category?.ancestors?.parentCategory?.name ?? ""
//                 },
//                 productCategory: {
//                   id: category?.id ?? null,
//                   label: category?.name ?? ""
//                 },
//               }));
//             }
//           })
//           .catch((error) => {
//             console.error("Error Fetching Category: ", error);
//             showSnackbar('error', "Error while fetching category data");
//           });
//       }
//   // }, [formik?.values?.productCategory.id, mode]);
// }, [secondaryInventory?.productCategoryId , mode]);
//   useEffect(() => {
//     if (secondaryInventory?.gradeId && mode === MODE.UPDATE) {
//       qualityGradeService.getQualityGrade(secondaryInventory?.gradeId)
//         .then(res => {
//           if (res.status === HTTP_STATUS.OK) {
//             let grade = res.data.data;
//             console.log("Grade Formik Data ", formik);
//             formik.setValues(prevValues => ({
//               ...prevValues,
//               grade:
//               {
//                 id: grade?.id ?? null,
//                 label: grade?.name ??""
//               },
//             }));
//           }
//         })
//         .catch((error) => {
//           console.error("Error Fetching Quality Grade: ", error);
//           showSnackbar('error', "Error while fetching Quality Grade data");
//         });
//     }
// }, [secondaryInventory?.gradeId, mode]);


useEffect(() => {
  if (secondaryInventory && mode === MODE.UPDATE) {
    const updateFormikValues = async () => {
      const newValues = {
        superCategory: {
          id: null,
          label: ""
        },
        mainCategory: {
          id: null,
          label: ""
        },
        productCategory: {
          id: secondaryInventory?.productCategoryId ?? null,
          label: ""
        },
        grade: {
          id: secondaryInventory?.gradeId ?? null,
          label: ""
        },
        shape: secondaryInventory?.shape ?? '',
        defectDetails: secondaryInventory?.defectDetails ?? '',
        warehouseId: secondaryInventory?.warehouseId ?? null,
        warehousePlacement: secondaryInventory?.warehousePlacement ?? '',
        basePrice: secondaryInventory?.basePrice ?? null,
        netWeight: secondaryInventory?.netWeight ?? null,
        grossWeight: secondaryInventory?.grossWeight ?? null,
        quantity: secondaryInventory?.quantity ?? null,
        skuCode: secondaryInventory?.skuCode ?? '',
        attributes: secondaryInventory?.attributes ?? null,
      };

      if (newValues.productCategory.id) {
        try {
          const categoryRes = await categoryService.getCategoryById(newValues.productCategory.id);
          if (categoryRes.status === HTTP_STATUS.OK) {
            const category = categoryRes.data.data;
            newValues.superCategory.id = category?.ancestors?.superParentCategory?.id ?? null;
            newValues.superCategory.label = category?.ancestors?.superParentCategory?.name ?? "";
            newValues.mainCategory.id = category?.ancestors?.parentCategory?.id ?? null;
            newValues.mainCategory.label = category?.ancestors?.parentCategory?.name ?? "";
            newValues.productCategory.label = category?.name ?? "";
          }
        } catch (error) {
          console.error("Error Fetching Category: ", error);
          showSnackbar('error', "Error while fetching category data");
        }
      }

      if (newValues.grade.id) {
        try {
          const gradeRes = await qualityGradeService.getQualityGrade(newValues.grade.id);
          if (gradeRes.status === HTTP_STATUS.OK) {
            const grade = gradeRes.data.data;
            newValues.grade.label = grade?.name ?? "";
          }
        } catch (error) {
          console.error("Error Fetching Quality Grade: ", error);
          showSnackbar('error', "Error while fetching Quality Grade data");
        }
      }

      formik.setValues(prevValues => ({
        ...prevValues,
        ...newValues
      }));
    };

    updateFormikValues();
  }
}, [secondaryInventory, mode]);

  const secondaryInventorySectionProps: secondaryInventorySectionProp = {
    setCurrentSectionTo: setCurrentSectionTo,
    formik: formik,
    id: secondaryInventory?.id || null,
    mode: mode,
    image: image,
    setImagesTo: setImagesTo,
  };

  const secondaryInventorySectionView = useMemo(() => {
    switch (currentSection) {
      case SECONDARY_INVENTORY_SECTIONS.CATEGORY:
        return <CategoryDetailTemplate {...secondaryInventorySectionProps} />;

      case SECONDARY_INVENTORY_SECTIONS.DEFECT_DETAILS:
        return <DefectDescriptionTemplate {...secondaryInventorySectionProps} />;

      case SECONDARY_INVENTORY_SECTIONS.WAREHOUSE:
        return <WareHouseDetailTemplate {...secondaryInventorySectionProps} />;

      case SECONDARY_INVENTORY_SECTIONS.IMAGES_UPLOAD:
        return <ImageDetailTemplate {...secondaryInventorySectionProps} />;

      default:
        return <CategoryDetailTemplate {...secondaryInventorySectionProps} />;
    }
  }, [currentSection, formik]);


  return (
    <div>{secondaryInventorySectionView}</div>
  )
}

export default SecondaryInventoryFormTemplate