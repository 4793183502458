import { ProductDataType } from './types';

export const ProductAttributeFields = [
  {
    name: 'Length (MM)',
    property: 'Length',
  },
  {
    name: 'Width (MM)',
    property: 'Width',
  },
  {
    name: 'Thickness (MM)',
    property: 'Thickness',
  },
  {
    name: 'Temper',
    property: 'Temper',
  },

  {
    name: 'Color',
    property: 'Color',
  },
  {
    name: 'Surface Finish',
    property: 'surfaceFinish',
  },
];
export const PROCUREMENT_TEXT = {
  AddPRDetailsDescription:
    'Please Fill Requirement Details In Selected Products',
  ReviewPRDetailPageHeading: `Please select products from directory.`,
  productSelectedText: `Products Selected`,
  back: `Back`,
  next: `Next`,
  productSelectedCategoryFilterLabel: `Product Category :`,
  reviewCardProductSpecificRequirementLabel: `Product Specific Requirements`,
  reviewCardTnCLabel: `Terms and Condition`,
  insightButtonText: `Insights`,
  paginationText: 'Products',
};
export const productDescription = (item: ProductDataType) => {
  if (item) {
    return (
      item.brand +
      ' ' +
      item.productCategory +
      ' ' +
      item.shape +
      ' ' +
      item.standard +
      ' ' +
      item.grade
    );
  } else {
    return '';
  }
};

export const StatusType: { name: string; value: string }[] = [
  { name: 'All', value: 'All' },
  { name: 'Created', value: 'CREATED' },
  { name: 'In Progress', value: 'INPROGRESS' },
  { name: 'Completed', value: 'COMPLETED' },
  { name: 'Partially Completed', value: 'PARTIALLY_COMPLETED' },
  { name: 'Partially In Progress', value: 'PARTIALLY_INPROGRESS' },
  { name: 'Cancelled', value: 'CANCELLED' },
];

export const PurchaseRequisitionTableHeadings = [
  { name: 'Sr. No.', align: 'left' },
  { name: 'PRID', align: 'left' },
  { name: 'Product Name' },
  { name: 'Quantity' },
  { name: 'Delivery Location' },
  { name: 'Created Date' },
  { name: 'Created By' },
  { name: 'Status' },
  { name: 'Download', align: 'left' },
];
export const PurchaseRequisitionFields = [
  { name: 'itemId', align: 'left' },
  { name: 'id', align: 'left' },
  { name: 'productName' },
  { name: 'quantity' },
  { name: 'consigneeLocation' },
  { name: 'createdAt' },
  { name: 'createdBy' },
  { name: 'status' },
  { name: 'downloadUrl' },
];
export const RequitionTabs = ['All Requisition', 'My Requisition', 'My Drafts'];
export const purchaseRequisitionFormTabs = [
  'Select Product',
  'Fill Requisition Details',
  'Review',
];

export const PRODUCT_LIST_PAGINATION_TEXT = {
  showingItems: (pageNumber: number, totalItems: number, text?: string) =>
    `${pageNumber * 10 + 1}-${
      totalItems < pageNumber * 10 + 10 ? totalItems : pageNumber * 10 + 10
    } from ${totalItems} ${text}`,
};

export const graphTabs = [
  'Weekly',
  'Monthly',
  'Quarterly',
  'Half yearly',
  'Annually',
];
