import React from "react";
import { useLocation } from "react-router-dom";
import { default as MuiBreadCrumbs } from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { capitalizeFirstLetter } from "../../../utils/helper";
import { createUseStyles } from "react-jss";

interface BreadCrumbsProps {
  crumbs?: { link: string, label: string }[]
}

const useStyles = createUseStyles((theme: any) => ({
  title: {
    fontSize: "16px",
  },
  "@media (max-width: 476px)": {
    title: {
      fontSize: "12px",
    },
  },
}));

const BreadcrumbItem = ({ name, isLast, routeTo }: { name: string; isLast: boolean; routeTo: string }) => {
  const classes = useStyles();
  const linkStyle = {
    fontWeight: isLast ? "500" : "400",
  };

  const crumbLabelFormater = (label: string) => {
    return label.split('-').map(label => capitalizeFirstLetter(label)).join(" ")
  }

  return (
    <Link className={`${classes.title} ${isLast && "pointer-events-none"} select-none`} style={linkStyle} underline="none" color={isLast ? "textPrimary" : "inherit"} href={routeTo}>
      {crumbLabelFormater(name)}
    </Link>
  );
};

const Breadcrumbs: React.FC<BreadCrumbsProps> = ({ crumbs }) => {

  const location = useLocation();

  const getCrumbsFromLocation = () => {
    const pathnames = location.pathname.split("/").filter((x) => x );
    return pathnames.map((name, index) => ({
      link: `/${pathnames.slice(0, index + 1).join("/")}`,
      label: name
    }));
  }

  const crumbsData = crumbs?.length ? crumbs : getCrumbsFromLocation();

  return (
    <section className="my-3">
      <MuiBreadCrumbs separator={'/'} aria-label="breadcrumb">
        {crumbsData?.length > 1 ? crumbsData.map((crumb, index) => {
          const isLast = index === crumbsData.length - 1;
          return (
            <BreadcrumbItem key={crumb.link} name={crumb.label} isLast={isLast} routeTo={crumb.link} />
          );
        }) : null}
      </MuiBreadCrumbs>
    </section>
  );
};

export default Breadcrumbs;
