import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { ITemplateRequest, useWhatsappTemplateService } from '../../../services/useWhatsappTemplateService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { IWhatsappTemplate } from './WhatsappTemplateList.page';
import { HTTP_STATUS } from '../../../utils/types';
import { ADMIN_ROUTES, MODE } from '../../../utils/constant';
import WhatsappTemplateFormTemplate from '../../templates/WhatsappTemplate/WhatsappTemplateForm.template';

const WhatsappTemplateUpdatePage: React.FC = () => {

  const params = useParams();
  const templateId = Number(params.id);

  const navigate = useNavigate();
  const whatsappTemplateService = useWhatsappTemplateService();
  const { showSnackbar, SnackBarComponent } = useSnackbar();

  const [templateDetails, setTemplateDetailsTo] = useState<IWhatsappTemplate | null>(null);

  const updateTemplateById = async (templateRequestBody: ITemplateRequest) => {
    if (templateId) {
      await whatsappTemplateService.updateTemplate(templateId, templateRequestBody)
        .then((response) => {
          if (response.status === HTTP_STATUS.OK) {
            navigate(ADMIN_ROUTES.WHATSAPP_TEMPLATE_LIST);
          }
        })
        .catch((error) => {
          showSnackbar('error', 'Template update failed')
        })
    }
    else {
      showSnackbar('error', 'Template update failed')
    }
  }

  const fetchTemplateById = async () => {
    if (templateId) {
      whatsappTemplateService.getTemplateById(templateId)
        .then((templateResponse) => {
          setTemplateDetailsTo(templateResponse?.data?.data)
        })
        .catch((error) => {
          showSnackbar(error, 'Template fetch failed')
        });
    }
  }

  useEffect(() => {
    fetchTemplateById()
  }, [templateId])

  return (
    <div>
      {SnackBarComponent}     
      <WhatsappTemplateFormTemplate  template={templateDetails} onTemplateSubmit={updateTemplateById} mode={MODE.UPDATE} />
    </div>
  )
}
export default WhatsappTemplateUpdatePage