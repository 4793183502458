import React, { useMemo, useState } from 'react'
import { useSnackbar } from '../../../hooks/useSnackBar';
import { CategoryPayload, useCategoryService } from '../../../services/useCategoryService';
import { useNavigate } from 'react-router-dom';
import { ADMIN_ROUTES, MODE, RESOURCE_TYPE, STATUS } from '../../../utils/constant';
import { CATEGORY_TYPE, HTTP_STATUS, PRODUCT_CATEGORY_FORM_STATE } from '../../../utils/types';
import { useFormik } from 'formik';
import * as Yup from "yup";
import AttributeManagementFormTemplate from '../../templates/Category/AttributeManagementForm.template';
import StandardManagementFormTemplate from '../../templates/Category/StandardManagementForm.template';
import ProductCategoryDetailsFormTemplate from '../../templates/Category/ProductCategoryDetailsForm.template';
import { makeRoute } from '../../../utils/helper';
import CategoryFormTemplate from '../../templates/Category/CategoryForm.template';
import { ImageData } from '../../organisms/ImageUploader';
import { useFileService } from '../../../services/useFileService';

const ProductCategoryCreatePage: React.FC = () => {
	const { showSnackbar, SnackBarComponent } = useSnackbar();
	const categoryService = useCategoryService();
	const navigate = useNavigate();

	const [formState, setFormState] = useState<PRODUCT_CATEGORY_FORM_STATE>(1);
	const [image, setImagesTo] = useState<(ImageData | File)[]>([]);
	const [imageIdsToDelete, setImageIdsToDelete] = useState<number[]>([]);
	const fileService = useFileService();

	const onClose = () => {
		navigate(makeRoute(ADMIN_ROUTES.CATEGORY, { query: { type: 'PRODUCT_CATEGORY' } }));
	}

	const validationSchema = Yup.object().shape({
		name: Yup.string().required("Category Name is required"),
		description: Yup.string().required("Category Description is required")
		.test('no-p-br-tags', 'Defect details cannot be Empty', ((value:any)=> value !== '<p><br></p>')),
		parentId: Yup.number().required("Main Category is required"),
		superParentId: Yup.number().required("Super Category is required"),
		prefix: Yup.string().required('Product Category Code is required').matches(/^[A-Z]{2}$/, 'Code must be exactly two alphabetic characters'),
		customization: Yup.array().of(Yup.string()).min(1, 'At least one Customization is required').required('Customization are required'),
	});

	const createProductCategory = async (category: CategoryPayload) => {
		try {
			const productCategoryResponse = await categoryService.createCategory(category);
			if (productCategoryResponse.status === HTTP_STATUS.OK) {
				const resourceId: number = productCategoryResponse.data.data.id;
				await fileService.uploadImage(image, resourceId, RESOURCE_TYPE.PRODUCT_CATEGORY);
				onClose();
			} else if (productCategoryResponse.status === HTTP_STATUS.BAD_REQUEST) {
				setFormState(PRODUCT_CATEGORY_FORM_STATE.PRODUCT_CATEGORY)
				showSnackbar('error', productCategoryResponse.data.statusMessage);
			}
		} catch (error) {
			showSnackbar('error', "Failed to create Product Category");
		}
	}

	const formik = useFormik<CategoryPayload>({
		initialValues: {
			name: "",
			status: STATUS.ACTIVE,
			description: "",
			parentId: null,
			superParentId: null,
			level: CATEGORY_TYPE.PRODUCT_CATEGORY,
			prefix: "",
			superParentCategory: null,
			parentCategory: null,
			attributes: {},
			standards: [],
			standardOptions: null,
			customization: [],
		},
		validationSchema,
		validateOnMount: true,
		onSubmit: (values) => { createProductCategory(values) }
	})

	const setFormStateTo = (formState: PRODUCT_CATEGORY_FORM_STATE) => () => {
		setFormState(formState)
	}

	const activeFormStep = useMemo(() => {
		const props = { mode: MODE.ADD, onClose, formik, setFormStateTo, image, setImagesTo, imageIdsToDelete, setImageIdsToDelete }
		switch (formState) {
			case PRODUCT_CATEGORY_FORM_STATE.PRODUCT_CATEGORY: return <ProductCategoryDetailsFormTemplate {...props} />;
			case PRODUCT_CATEGORY_FORM_STATE.ATTRIBUTE_MANAGEMENT: return <AttributeManagementFormTemplate {...props} />;
			default: return <StandardManagementFormTemplate {...props} />;
		}
	}, [formState, formik])

	return (
		<div>
			{SnackBarComponent}
			<CategoryFormTemplate {...{ mode: MODE.ADD, onClose, component: activeFormStep }} />
		</div>
	)
}

export default ProductCategoryCreatePage