import { useAuthenticatedUser } from '../hooks/useAuthenticatedUser';
import { API_METHOD } from '../utils/constant';
import { request } from ".";
import { replaceUrlParams } from '../utils/helper';

export const TEMPLATE_URLS = {
   GET_ALL_TEMPLATE: "/templates",
   GET_TEMPLATE: "/template/:id",
   CREATE_TEMPLATE: "/template",
   UPDATE_TEMPLATE: "/template/:id",
   DELETE_TEMPLATE: "template/:id",
};

interface IWhatsappTemplateFilterRequest {
   search?: string;
   status?: string;
   page?: number;
   size?: number
}

export interface ITemplateRequest {
   templateName: string;
   language: string;
   status: string;
   attachmentType: string;
   // parameterValues: { value: string; fallBackValue: string; }[] ;
   parameterValues: any;
}

export const useWhatsappTemplateService = () => {
   const { user: authenticatedUser } = useAuthenticatedUser();

   const getTemplateById = (id: number) => {
      return request(API_METHOD.GET, replaceUrlParams(TEMPLATE_URLS.GET_TEMPLATE, { id }), authenticatedUser)
   }

   const getTemplates = (params: IWhatsappTemplateFilterRequest) => {
      return request(API_METHOD.GET, TEMPLATE_URLS.GET_ALL_TEMPLATE, authenticatedUser, null, { params })
   }

   const createTemplate = (data: ITemplateRequest) => {
      return request(API_METHOD.POST, TEMPLATE_URLS.CREATE_TEMPLATE, authenticatedUser, data)
   }

   const updateTemplate = (id: number, data: ITemplateRequest) => {
      return request(API_METHOD.PUT, replaceUrlParams(TEMPLATE_URLS.UPDATE_TEMPLATE, { id }), authenticatedUser, data)
   }

   const deleteTemplate = (id: number) => {
      return request(API_METHOD.DELETE, replaceUrlParams(TEMPLATE_URLS.DELETE_TEMPLATE, { id }), authenticatedUser)
   }

   return {
      getTemplateById,
      getTemplates,
      createTemplate,
      updateTemplate,
      deleteTemplate
   }

}
