import React, { FC, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../../atoms/Button";
import { Modal } from "@mui/material";
import { useSecondaryUpcService } from "../../../services/useSecondaryUpcService";
import { UPCContainer, UPCContainerProps } from "./ViewUpcModal.template";
import { CATALOGUE_TYPES, LEAD_CATALOGUE_TYPES } from "../../../utils/constant";
import { getKeyByValue } from "../../../utils/helper";

interface ViewSecondaryUpcModalTemplateProps {
	item: any;
	classType: string;
	onUpcClose: () => void;
}

interface SecondaryUpc {
	id: number;
	secondaryCatalogueId: number;
	superCategory: string;
	mainCategory: string;
	productCategory: string;
	grade: string;
	shape: string;
	classType: string;
	attributes?: Attribute;
	skuCode: string;
	warehouse: string;
	defectDetails: string;
	catalogueName: string;
}

interface Attribute {
	productSpecification: any[];
}

const useStyles = createUseStyles((theme: any) => ({
	subContainer: {
		backgroundColor: theme.palette.background.primaryLight,
	},
	heading: {
		color: theme.palette.text.black,
	},
	subHeading: {
		color: theme.palette.text.grey,
	},
	values: {
		color: theme.palette.text.grey,
	},
	mainHeading: {
		color: theme.palette.text.grey,
	},
}));

const ViewSecondaryUpcModalTemplate: FC<ViewSecondaryUpcModalTemplateProps> = ({ item, classType, onUpcClose }) => {

	const classes = useStyles();
	const secondaryUpcService = useSecondaryUpcService();
	const [secondaryUpc, setSecondaryUpcTo] = useState<SecondaryUpc | null>(null);

	const loadSecondaryUpc = async () => {
		if(item.id)
		try {
			const catalogueClass = classType === LEAD_CATALOGUE_TYPES.MAKE_TO_ORDER ? LEAD_CATALOGUE_TYPES.MAKE_TO_ORDER : getKeyByValue(CATALOGUE_TYPES,CATALOGUE_TYPES.CURRENT_INVENTORY);
			const response = await secondaryUpcService.getSecondaryProductUpc(item.id,{classType: catalogueClass});
			if (response?.status === 200 && response?.data?.data) {
				setSecondaryUpcTo(response.data.data);
			} else {
				console.error("Failed to fetch product upc data:", response);
			}
		} catch (error) {
			throw error;
		}
	};

	useEffect(() => {
		loadSecondaryUpc();
	}, [item.id]);

	const secondaryUpcSchema: UPCContainerProps[] = [
		{
			heading: "Category",
			items: [
				{ subHeading: "Super Category", values: secondaryUpc?.superCategory },
				{ subHeading: "Main Category", values: secondaryUpc?.mainCategory },
				{ subHeading: "Product Category", values: secondaryUpc?.productCategory },
			],
		},
		{
			heading: "Standard",
			items: [
				{ subHeading: "Grade", values: secondaryUpc?.grade },
			],
		},
		{
			heading: "Product Classification",
			items: [
				{ subHeading: "Shape", values: secondaryUpc?.shape },
				{ subHeading: "Class", values: secondaryUpc?.classType },
			],
		}
	];

	return (
		<Modal open={ true } onClose={ onUpcClose } closeAfterTransition>
			<div className="bg-white m-auto mt-16 rounded w-3/5 h-4/5">
				<div className="grid gap-6 h-full p-6 overflow-y-auto">
					<div className="grid gap-3">
						<div className="pb-3 flex justify-between border-b-2 border-solid">
							<p className={` ${ classes.mainHeading } text-2xl font-semibold` }>
								SKU Code : <span>{ secondaryUpc?.skuCode }</span>
							</p>
							<CloseIcon className="cursor-pointer" onClick={ onUpcClose } />
						</div>

						{secondaryUpcSchema.map((schema, index) => (
							<UPCContainer key={ index } heading={ schema.heading } items={ schema.items.map(item => ({
								subHeading: item.subHeading,
								values: item.values,
							}))} />
						))}

						<div className={ `${ classes.subContainer } grid gap-y-4 p-6 rounded` }>
							<div className={ `${ classes.heading } text-lg font-semibold` }>Product Specification</div>
							<div className="grid grid-cols-5 gap-x-6 gap-y-3">
								{secondaryUpc?.attributes?.productSpecification.map(
									(item: any, index: number) => (
										<div className="grid gap-y-1.5" key={index}>
											<div className={` ${ classes.subHeading } text-base font-normal m-auto` }>{ item.name }</div>
											<div className={` ${classes.values} w-fit mx-auto px-3 py-auto h-fit py-1 text-base font-medium rounded-full border border-input-border`}>{item.minValue === item.maxValue ? `${item.minValue}` : `${item.minValue} - ${item.maxValue}`} </div>
										</div>
									)
								)}
							</div>
						</div>

					</div>

					<div className="flex justify-end">
						<Button variant="outlined" onClick={ onUpcClose } label="Cancel" />
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ViewSecondaryUpcModalTemplate;