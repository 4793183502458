import React, { ReactNode } from 'react'

interface ServiceCategoryFormTemplateProps {
    mode: string,
    onClose: () => void,
    component: ReactNode
}

const ServiceCategoryFormTemplate: React.FC<ServiceCategoryFormTemplateProps> = ({ component, mode, onClose }) => {
    return (
        <div>{component}</div>
    )
}

export default ServiceCategoryFormTemplate