import React, { useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { useNavigate, useParams } from 'react-router-dom';
import { useImageMasterService } from '../../../services/useImageMasterService';
import { useFileService } from '../../../services/useFileService';
import ImageUploader, { ImageData } from '../../organisms/ImageUploader';
import Button from '../../atoms/Button';
import { ADMIN_ROUTES, MODE, RESOURCE_TYPE } from '../../../utils/constant';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { HTTP_STATUS } from '../../../utils/types';
import { useCategoryService } from '../../../services/useCategoryService';
import { useBrandService } from '../../../services/useBrandService';
import { catalogueClassType } from '../../../utils/helper';


export interface IImageMasterResponse {
    id: number;
    productCategoryId: string;
    brandId: string;
    catalogueType: string;
    classType: string;
    shape: string;
    createdBy: number;
    updatedBy: number;
    createdAt: string;
    updatedAt: string;
}

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.text.primaryDark,
        fontFamily: "Roboto",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "20px",
    },
    subHeading: {
        color: theme.palette.text.secondaryDark,
        fontFamily: "Roboto",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
    },
    values: {
        color: theme.palette.text.secondaryDark,
        fontFamily: "Roboto",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
    },
}));

const ImageMasterViewTemplate = () => {
    const classes = useStyles();
    const params = useParams();
    const navigate = useNavigate();
    const imageMasterId = parseInt(params.id ?? '0', 10);
    const fileService = useFileService();
    const imageMasterService = useImageMasterService();
    const categoryService = useCategoryService();
    const brandService = useBrandService();

    const [imageMaster, setImageMasterTo] = useState<IImageMasterResponse>();
    const [productCategoryName, setProductCategoryNameTo] = useState();
    const [brandName, setBrandNameTo] = useState();
    const [image, setImagesTo] = useState<(ImageData | File)[]>([]);
    const [catalogueType, setCatalogueTypeTo] = useState();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const handleRedirect = () => {
        navigate(`${ADMIN_ROUTES.IMAGE_MASTER_LIST}`);
    };

    const getImageMasterById = async (imageMasterId: number) => {
        try {
            const imageMasterResponse = await imageMasterService.getImageMasterById(imageMasterId);
            if (imageMasterResponse.status === HTTP_STATUS.OK) {
                setImageMasterTo(imageMasterResponse?.data?.data);
                getProductCategory(imageMasterResponse?.data?.data?.productCategoryId);
                getBrandbyId(imageMasterResponse?.data?.data?.brandId);
            }
            const fileResponse = await fileService.getFilesById(imageMasterId, RESOURCE_TYPE.IMAGE_MASTER);
            (fileResponse.status === HTTP_STATUS.OK) && setImagesTo(fileResponse?.data?.data);
        } catch (error) {
            showSnackbar("error", 'Secondary Inventory fetch failed');
            setImagesTo([]);
        }
    };

    const getProductCategory = (produtCategoryId: number) => {
        if (produtCategoryId) {
            categoryService.getCategoryById(produtCategoryId)
                .then((response) => {
                    setProductCategoryNameTo(response?.data?.data?.name);
                })
                .catch((error) => {
                    showSnackbar("error", 'Error in fetching categories');

                });
        }
    }
    const getBrandbyId = (brandId: number) => {
        if (brandId) {
            brandService.getBrandById(brandId)
                .then((response) => {
                    setBrandNameTo(response?.data?.data?.name);
                })
                .catch((error) => {
                    showSnackbar("error", 'Error in fetching brands');

                });
        }
    }

    useEffect(() => {
        getImageMasterById(imageMasterId);
    }, [imageMasterId]);

    const imageUploaderComponent = useMemo(() => (
        <ImageUploader
            mode={MODE.VIEW}
            images={image}
        />
    ), [image]);

    return (
        <div className="search-upc-container1  mt-4 m-auto">
            <div className="grid gap-y-3 mx-6 pb-6">
                <div className="search-upc-heading flex justify-between mb-5 py-5">
                    <h1 className="text-black">ImageMaster: {imageMaster?.id}</h1>
                </div>
                <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
                    <div className={classes.heading}>CatalogueType Name</div>
                    <div className="flex gap-x-6">
                        <div className="grid gap-y-1.5">
                            <div className={classes.subHeading}>{imageMaster && catalogueClassType(imageMaster.catalogueType, imageMaster.classType)}</div>
                        </div>
                    </div>
                </div>
                <div className="grid gap-y-4">
                    <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
                        <div className={classes.heading}>Product Category</div>
                        <div className="flex gap-x-6">
                            <div className="grid gap-y-1.5">
                                <div className={classes.subHeading}> {productCategoryName}</div>
                            </div>

                        </div>
                    </div>
                    <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
                        <div className={classes.heading}>Shape</div>
                        <div className="flex gap-x-6">
                            <div className="grid gap-y-1.5">
                                <div className={classes.subHeading}> {imageMaster && imageMaster.shape}</div>
                            </div>

                        </div>
                    </div>
                    <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
                        <div className={classes.heading}>Brand Name</div>
                        <div className="flex gap-x-6">
                            <div className="grid gap-y-1.5">
                                <div className={classes.subHeading}>{brandName}</div>
                            </div>
                        </div>
                    </div>
                    <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
                        <div className={classes.heading}>Images</div>
                        <div className="flex gap-x-6">
                            {imageUploaderComponent}
                        </div>
                    </div>
                    <div className="flex justify-end w-full m-auto">
                        <Button variant="outlined" label="Cancel" onClick={handleRedirect} />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ImageMasterViewTemplate