import { useNavigate, useParams } from "react-router-dom";
import { useBusinessProfileService } from "../../../services/useBusinessProfileService";
import { useEffect, useState } from "react";
import { HTTP_STATUS } from "../../../utils/types";
import { useSnackbar } from "../../../hooks/useSnackBar";
import { createUseStyles } from "react-jss";
import TurnOverTemplate from "../../templates/CustomerManagement/TurnOver.template";
import OtherInformationTemplate from "../../templates/CustomerManagement/Otherinformation.template";
import GSTTemplate from "../../templates/CustomerManagement/GST.template";
import CINTemplate from "../../templates/CustomerManagement/CIN.template";
import UdhyamTemplate from "../../templates/CustomerManagement/Udhyam.template";
import AboutUsTemplate from "../../templates/CustomerManagement/AboutUs.template";
import { ADMIN_ROUTES, BUSINESS_PROFILE_SECTIONS } from "../../../utils/constant";
import ButtonV2 from "../../atoms/ButtonV2";
import { IBusinessProfile } from "../CustomerManagement/ProfileOverview.page";
import { useVendorServiceV2 } from "../../../services/useVendorServiceV2";

const VendorProfilePage: React.FC = () => {

    const params = useParams();
    const businessProfileId = Number(params.id);
    const navigate = useNavigate();

    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const vendorService = useVendorServiceV2();

    const [businessProfile, setBusinessProfileTo] = useState<IBusinessProfile | null>(null);

    const getBusinessProfile = async () => {
        try {
            const businessProfileResponse = await vendorService.getVendor(businessProfileId);
            if (businessProfileResponse.status === HTTP_STATUS.OK) {
                const profileData = businessProfileResponse?.data?.data;
                setBusinessProfileTo(profileData);
            }
        } catch (error) {
            showSnackbar('error', 'Business Profile fetch failed');
        }
    };

    const updateBusinessProfileSection = async (type: string, data: any) => {
        let payload: any = {};
        switch (type) {
            case BUSINESS_PROFILE_SECTIONS.GST:
                payload = {
                    gstin: data?.gstin,
                    pan: data?.pan,
                };
                break;
            case BUSINESS_PROFILE_SECTIONS.UDHYAM:
                payload = {
                    udhyamNumber: data?.udhyamNumber,
                    businessType: data?.businessType,
                };
                break;
            case BUSINESS_PROFILE_SECTIONS.CIN:
                payload = {
                    cin: data?.cin,
                    entityType: data?.entityType,
                    dateOfIncorportation: data?.dateOfIncorportation
                };
                break;
            case BUSINESS_PROFILE_SECTIONS.TURN_OVERS:
                payload = data?.turnOvers ?? [];
                break;
            case BUSINESS_PROFILE_SECTIONS.OTHER_INFORMATION:
                payload = {
                    yearOfEstablishment: data?.dateOfEstablishment != null ? data?.dateOfEstablishment : businessProfile?.dateOfEstablishment,
                    numberOfEmployees: data?.totalEmployees != null ? data?.totalEmployees : businessProfile?.totalEmployees,
                    websiteUrl: data?.websiteUrl != null ? data?.websiteUrl : businessProfile?.websiteUrl,
                    industryType: data?.industryType != null ? data?.industryType : businessProfile?.industry,
                    businessType: data?.businessType != null ? data?.businessType : businessProfile?.businessType,
                    brand: data?.brand != null ? data?.brand : businessProfile?.brand,
                    description: data?.description != null ? data?.description : businessProfile?.description,
                };
                break;
        }
        const businessProfileResponse = await vendorService.updateVendorSections(businessProfileId, payload, type);
        if (businessProfileResponse.status === HTTP_STATUS.OK) {
            showSnackbar("success", `Successfully updated Business Profile.`)
            getBusinessProfile();
        }
        else {
            showSnackbar("error", `Failed to update Business Profile.`)
        }
    };

    const handleBackToDashboard = () => {
        navigate(ADMIN_ROUTES.VENDOR_MANAGEMENT_LIST);
    };

    useEffect(() => {
        if (businessProfileId) {
            getBusinessProfile();
        }
    }, [businessProfileId]);

    return (
        <div>
            {SnackBarComponent}
            <div className='grid gap-y-6 w-full'>
                <div className='grid gap-y-3'>
                    <GSTTemplate businessProfile={businessProfile} updateBusinessProfileSection={updateBusinessProfileSection} isEditButtonVisible={false} />
                    <CINTemplate businessProfile={businessProfile} updateBusinessProfileSection={updateBusinessProfileSection} />
                    <UdhyamTemplate businessProfile={businessProfile} updateBusinessProfileSection={updateBusinessProfileSection} />
                    <TurnOverTemplate businessProfile={businessProfile} updateBusinessProfileSection={updateBusinessProfileSection} />
                    <OtherInformationTemplate businessProfile={businessProfile} updateBusinessProfileSection={updateBusinessProfileSection} />
                    <AboutUsTemplate businessProfile={businessProfile} updateBusinessProfileSection={updateBusinessProfileSection} />
                    <div className='flex justify-end'>
                        <ButtonV2
                            className={` justify-self-end`}
                            variant="secondaryContained"
                            label="Back to Dashboard"
                            onClick={handleBackToDashboard}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VendorProfilePage;
