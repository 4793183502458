import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BillToAddress,
  FormData,
  Procurement,
  ShipToLocation,
} from '../../../Constants/Procurement/Indent/types';
import {
  INDENT_TEXT,
  PAYMENT_MODE,
} from '../../../Constants/Procurement/Indent/constants';
import { NavigationURL } from '../../../Constants/EndPoints';
import IndentStepper from './IndentStepper';
import CommonProductDetailReview from './CommonProductDetailReview';
import CommonIndentTnCReview from './CommonIndentTnCReview';
import { uploadFiles } from '../../../Services/fileuploadutil';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import { ViewUploadFiles } from './ViewUploadFiles';
import { useIndentService } from '../../../../services/useIndentService';

interface ReviewDetailPageProps {
  formData: FormData;
  handleBack: () => void;
  fileArray: File[];
  billToAddress: BillToAddress | undefined;
  shipToData: ShipToLocation | undefined;
  selectedPurchaseRequisition: Procurement[];
}

const ReviewDetailPage: FC<ReviewDetailPageProps> = ({
  formData,
  handleBack,
  fileArray,
  billToAddress,
  shipToData,
  selectedPurchaseRequisition,
}) => {
  const { user }: any = useAuthenticatedUser();
  const navigate = useNavigate();

  const { createIndent } = useIndentService();

  // save draft button
  const handleSaveDraft = () => {
    localStorage.setItem(
      'indent',
      JSON.stringify({ ...formData, fileArray: [fileArray] })
    );
    navigate(NavigationURL.indent);
  };

  // submit indent handler
  const handleSubmitIndent = async () => {
    const payload: any = {
      cess: parseFloat(String(formData.cess)) || null,
      gst: parseFloat(String(formData.gst)) || null,
      tcs: parseFloat(String(formData.tcs)) || null,
      items:
        formData.items.map((item: any) => {
          return {
            purchaseRequisitionId: item.purchase_requisition_id,
            quantity: item.quantity,
          };
        }) || null,
      paymentTerms: {
        term:
          formData.payment_terms === 'Advanced'
            ? 'BEFORE_DELIVERY'
            : 'AFTER_DELIVERY',
        days: PAYMENT_MODE.find(({ name }) => name === formData.payment_terms)
          ?.value,
      },
      attachmentLinks: [],
      qualityCheck: formData.quality_check,
      purchaseType: formData.purchase_type,
      tnc: formData.tnc,
      transportationMode: formData.transportation_mode,
      warehouseId: formData.warehouse_id,
    };

    try {
      // uploading files
      const links = await uploadFiles(fileArray, user.token, 'INDENT_UPLOAD');
      payload.attachmentLinks = links;

      // creating indent
      const apiResponse = await createIndent(payload);

      if (apiResponse.data && apiResponse?.data?.status === 'OK') {
        // navigate to review Page......
        navigate(NavigationURL.indent);
      }
    } catch (error) {
      alert(`ERROR IN SUBMITTING INDENT ${error}`);
    }
  };

  return (
    <div className="grid gap-y-3 gap-x-4 m-t-10 p-x-10">
      <div className="text-2xl font-bold text-blue mt-3">
        {INDENT_TEXT.createIndentPageHeading}
      </div>
      <IndentStepper step={3} />
      <div>
        <div className="text-xl font-semibold text-blue mt-3">
          {INDENT_TEXT.PRReviewHeading}
        </div>
        <div className="text-xs  font-medium text-cgray-1">
          {INDENT_TEXT.PRReviewSummary}
        </div>
      </div>
      {/* <div className="bg-cgray-2flex flex-row w-full rounded-md p-4">
            <div className="text-xl font-semibold text-blue">
              Indent ID: 1234254
            </div>
          </div> */}
      <div className="border-solid border-b border-cgray-3 flex flex-row justify-between w-full mt-3 py-2">
        <div id="Heading" className="text-2xl font-semibold  text-cgray-18">
          {INDENT_TEXT.PRProductDetailHeading}
        </div>
        <button
          className="text-sm font-semibold leading-[22px]  text-white bg-blue flex flex-row justify-center px-6 py-3 rounded-md"
          onClick={handleBack}
        >
          {INDENT_TEXT.PREditButtonLabel}
        </button>
      </div>

      {/*  details  */}
      <CommonProductDetailReview
        formData={formData}
        selectedPurchaseRequisition={selectedPurchaseRequisition}
      />

      {/* Indent T&C */}
      <div className="">
        <div className="text-xl font-semibold text-blue mb-4">
          {INDENT_TEXT.ReviewTnCHeading}
        </div>
        <CommonIndentTnCReview
          billToAddress={billToAddress}
          formData={formData}
          shipToData={shipToData}
        />
        {fileArray.length > 0 && (
          <div className="mt-4">
            <ViewUploadFiles
              heading={INDENT_TEXT.IndentReviewAttachmentHeading}
              fileList={fileArray}
            />
          </div>
        )}
      </div>
      <div className="flex flex-row justify-end gap-4 w-full items-start">
        <button
          className="text-sm font-semibold  text-text-color border-solid border-blue flex flex-row justify-center py-3  px-6 items-start border rounded-md"
          onClick={() => {
            handleSaveDraft();
          }}
        >
          {INDENT_TEXT.IndentSaveAsDraftLabel}
        </button>
        <button
          className="text-sm font-semibold  text-white bg-blue flex flex-row justify-center py-3  px-6 items-start rounded-md"
          onClick={() => {
            handleSubmitIndent();
          }}
        >
          {INDENT_TEXT.IndentSubmitLabel}
        </button>
      </div>
    </div>
  );
};

export default ReviewDetailPage;
