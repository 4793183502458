import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { IManufacturerRequest } from '../../../../services/useManufacturerService';
import { IManufacturer } from '../../../pages/AdminMaster/Manufacturer/ManufacturerUpdate.page';
import { MANUFACTURER_SECTIONS } from '../../../../utils/types';
import { FormikProps, useFormik } from 'formik';
import * as Yup from "yup";
import { ADMIN_ROUTES, MODE, REGEX } from '../../../../utils/constant';
import ManufacturerDetailsTemplate from './ManufacturerDetails.template';
import ManufacturingLocationDetailsTemplate from './ManufacturingLocationDetails.template';
import PointOfContactTemplate from './PointOfContact.template';
import { useNavigate } from 'react-router-dom';
import { ImageData } from '../../../organisms/ImageUploader';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { useFileService } from '../../../../services/useFileService';

interface IManufacturerFormProps {
	manufacturer: IManufacturer | null;
	onManufacturerSubmit: (manufacturerRequestBody: IManufacturerRequest) => void;
	categoryIds: number[] | [];
	mode: string;
	image: (ImageData | File)[]; 
	setImagesTo: (images:any) => void;
	imageIdsToDelete: number[];
    setImageIdsToDelete: (id: number[]) => void;
}

export interface IPOC {
	name: string;
	mobileNumber: string;
	email: string;
	whatsapp: string;
}

export interface IManufacturingLocation {
	id: number | null;
	manufacturingAddressLine1: string;
	manufacturingAddressLine2: string;
	manufacturingCountry: string;
	manufacturingState: string;
	manufacturingCity: string;
	manufacturingPinCode: string;
}

export interface IManufacturerForm {
	manufacturerType: string;
	manufacturerName: string;
	shortName: string;
	manufacturerCode: string;
	status: string;
	addressLine1: string;
	addressLine2: string;
	country: string;
	state: string;
	city: string;
	pinCode: string;
	pocs: IPOC[];
	manufacturingLocations: IManufacturingLocation[];
	categoryIds: number[] | [];
}

interface ManufacturerSectionProps {
	id: number | null;
	setCurrentSectionTo: (section: MANUFACTURER_SECTIONS) => void;
	formik: FormikProps<IManufacturerForm>;
	mode: string;
	categoryIds: number[] | [];
	setCategoriesTo: Dispatch<SetStateAction<number[]>>;
	image: (ImageData | File)[]; 
	setImagesTo: (images:any) => void;
	imageIdsToDelete: number[];
    setImageIdsToDelete: (id: number[]) => void;
}

const ManufacturerFormTemplate: React.FC<IManufacturerFormProps> = ({ manufacturer, categoryIds = [], onManufacturerSubmit, mode, image, setImagesTo, imageIdsToDelete, setImageIdsToDelete  }) => {

	const navigate = useNavigate();
	const [currentSection, setCurrentSectionTo] = useState<MANUFACTURER_SECTIONS>(MANUFACTURER_SECTIONS.MANUFACTURER_DETAILS);
	const [categories, setCategoriesTo] = useState<number[]>(categoryIds);
    const { showSnackbar, SnackBarComponent } = useSnackbar();
	const fileService = useFileService();

	const redirectToManufacturerList = () => {
        navigate(ADMIN_ROUTES.MANUFACTURER_LIST);
    }

	const initialManufacturerNameValues = {
		manufacturerType: manufacturer?.manufacturerType ?? "",
		manufacturerName: manufacturer?.name ?? "",
		shortName: manufacturer?.shortName ?? "",
		manufacturerCode: manufacturer?.code ?? "",
		status: manufacturer?.status ?? "",
		addressLine1: manufacturer?.registeredAddress?.line1 ?? "",
		addressLine2: manufacturer?.registeredAddress?.line2 ?? "",
		country: manufacturer?.registeredAddress?.country ?? "",
		state: manufacturer?.registeredAddress?.state ?? "",
		city: manufacturer?.registeredAddress?.city ?? "",
		pinCode: manufacturer?.registeredAddress?.pincode ?? "",
	};

	const initialLocationValues: IManufacturingLocation[] = (manufacturer?.manufacturingLocationAddress?.map(
		(location: any) => ({
			id: location?.id,
			manufacturingAddressLine1: location?.line1,
			manufacturingAddressLine2: location?.line2,
			manufacturingCountry: location?.country,
			manufacturingState: location?.state,
			manufacturingCity: location?.city,
			manufacturingPinCode: location?.pincode,
		})
	) || [{
		id: null,
		manufacturingAddressLine1: "",
		manufacturingAddressLine2: "",
		manufacturingCountry: "",
		manufacturingState: "",
		manufacturingCity: "",
		manufacturingPinCode: "",
	}]);

	const initialPOC = {
		pocs: manufacturer?.poc?.map((curPoc) => ({
			name: curPoc.name || "",
			mobileNumber: curPoc.mobileNumber || "",
			email: curPoc.email || "",
			whatsapp: curPoc.whatsapp || "",
		})) || [
				{
					name: "",
					mobileNumber: "",
					email: "",
					whatsapp: "",
				},
			],
	};

	const validationSchema = Yup.object().shape({
		manufacturerType: Yup.string().required('Manufacturer Type is required'),
		manufacturerName: Yup.string().required('Manufacturer Name is required').matches(/^(?=.*[a-zA-Z])[\w\s!@#$%^&*()_+{}[\]:;<>,.?~\\/-]+$/, "Enter valid Manufacturer name"),
		shortName: Yup.string().required("Manufacturer short name is required").matches(/^(?=.*[a-zA-Z])[\w\s!@#$%^&*()_+{}[\]:;<>,.?~\\/-]+$/, "Enter valid short name"),
		manufacturerCode: Yup.string().required('Manufacturer code is required'),
		status: Yup.string().required('Manufacturer status is required'),
		addressLine1: Yup.string().required('Registered AddressLine1 is required'),
		addressLine2: Yup.string().required('Registered AddressLine2 is required'),
		country: Yup.string().required("Registered Country is required").matches(/^[A-Za-z\s]+$/, "Country must only contain letters"),
		state: Yup.string().required("Registered State is required").matches(/^[A-Za-z\s]+$/, "State must only contain letters"),
		city: Yup.string().required("City is required").matches(/^[A-Za-z\s]+$/, "City must only contain letters"),
		pinCode: Yup.number().min(100000, 'Enter a valid PINCODE').max(999999, 'Enter a valid PINCODE').required('Pincode is required'),
		manufacturingLocations: Yup.array().of(Yup.object().shape({
			manufacturingAddressLine1: Yup.string().required("Manufacturing Address Line 1 is required"),
			manufacturingAddressLine2: Yup.string().required("Manufacturing Address Line 2 is required"),
			manufacturingCountry: Yup.string().required("Manufacturing Country is required"),
			manufacturingState: Yup.string().required("Manufacturing State is required"),
			manufacturingCity: Yup.string().required("Manufacturing City is required"),
			manufacturingPinCode: Yup.number().min(100000, 'Enter a valid PINCODE').max(999999, 'Enter a valid PINCODE').required('Pincode is required'),
		})).required("At least one Manufacturing Location is required"),
		pocs: Yup.array().of(Yup.object().shape({
			name: Yup.string().required("Name is required").matches(/^[A-Za-z\s]+$/, "Country must only contain letters"),
			email: Yup.string().email("Invalid email format").required("Email is required").matches(REGEX.EMAIL),
			whatsapp: Yup.string().required("WhatsApp is required").matches(/^[6-9]\d{9}$/, "Whatsapp number must start with either 6,7,8,ot 9 and must be 10 digits only"),
			mobileNumber: Yup.string().required("Mobile Number is required").matches(/^[6-9]\d{9}$/, "Mobile number must start with either 6,7,8,ot 9 and must be 10 digits only"),
		})).required("At least one Manufacturing Location is required")
	});

	const convertToRegisteredAddress = (registeredAddresses: any) => {
		return registeredAddresses.map((values: any) => ({
			id: values?.id,
			type: "Manufacturing",
			email: "manufacturing@abc.com",
			city: values.manufacturingCity,
			state: values.manufacturingState,
			country: values.manufacturingCountry,
			pincode: values.manufacturingPinCode,
			gmapUrl: "https://maps.google.com/...",
			line1: values.manufacturingAddressLine1,
			line2: values.manufacturingAddressLine2,
			countryCode: "+1",
			mobileNumber: "9876543210",
			stdCode: "321",
			landline: "1234567890",
		}));
	}

	const convertToPOC = (pocs: any)=>{
		return pocs.map((item: any) => ({
			salutation: "Mr.",
			name: item.name,
			designation: "CEO",
			email: item.email,
			altEmail: "",
			countryCode: "+1",
			mobileNumber: item.mobileNumber,
			whatsapp: item.whatsapp,
			stdCode: "+91",
			landline: "1111",
			linkedInProfile: "hhhh",
			profilePicture: 0,
		  }));
	}

	const formik = useFormik<IManufacturerForm>({
		validateOnMount: true,
		enableReinitialize: true,
		initialValues: {
			...initialManufacturerNameValues,
			manufacturingLocations: initialLocationValues,
			pocs: initialPOC.pocs,
			categoryIds: categoryIds
		},
		validationSchema: validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			if (mode !== MODE.VIEW) {
				if(imageIdsToDelete.length){
				 fileService.deleteImagesByIds(imageIdsToDelete.join(","));
				}
				setSubmitting(true);
				const manufacturerRequestBody: IManufacturerRequest = {
					name: values.manufacturerName.trim(),
					shortName: values.shortName,
					code: values.manufacturerCode,
					logoPath: 123,
					status: values.status,
					manufacturerType: values.manufacturerType,
					createdBy: 1,
					updatedBy: 1,
					registeredAddress: {
						id: manufacturer?.registeredAddressId ?? 0,
						type: "Registered",
						email: "abc@gmail.com",
						city: values.city,
						state: values.state,
						country: values.country,
						pincode: values.pinCode,
						gmapUrl: "https://maps.google.com/...",
						line1: values.addressLine1,
						line2: values.addressLine2,
						countryCode: "+1",
						mobileNumber: "1234567890",
						stdCode: 123,
						landline: "9876543210",
					},
					manufacturingLocation: convertToRegisteredAddress(values.manufacturingLocations!),
					poc: convertToPOC(values.pocs!),
					categoryIds: categories!
				}
				if(categories.length !== new Set(categories).size)
					return showSnackbar('error', "Duplicate categories exist");
				onManufacturerSubmit(manufacturerRequestBody)
			} else 
			redirectToManufacturerList();
		}
	});

	const manufacturerSectionProps: ManufacturerSectionProps = {
		setCurrentSectionTo: setCurrentSectionTo,
		formik: formik,
		id: manufacturer?.id || null,
		categoryIds: categories,
		setCategoriesTo: setCategoriesTo,
		mode: mode,
		image: image,
		setImagesTo: setImagesTo,
        imageIdsToDelete: imageIdsToDelete,
		setImageIdsToDelete: setImageIdsToDelete
	}

	useEffect(() => {
		setCategoriesTo(formik.values.categoryIds);
	}, [formik.values.categoryIds])

	const manufacturerSectionView = useMemo(() => {
		switch (currentSection) {
			case MANUFACTURER_SECTIONS.MANUFACTURER_DETAILS:
				return <ManufacturerDetailsTemplate { ...manufacturerSectionProps } />;

			case MANUFACTURER_SECTIONS.MANUFACTURING_LOCATION_DETAILS:
				return <ManufacturingLocationDetailsTemplate { ...manufacturerSectionProps } />;

			case MANUFACTURER_SECTIONS.POINT_OF_CONTACT:
				return <PointOfContactTemplate { ...manufacturerSectionProps } />;

			default:
				return <ManufacturerDetailsTemplate { ...manufacturerSectionProps } />;
		}
	}, [currentSection, formik]);

	return <div> {SnackBarComponent} { manufacturerSectionView } </div>;
}

export const changeSectionTo = (setCurrentSectionTo: (section: MANUFACTURER_SECTIONS) => void) => (section: MANUFACTURER_SECTIONS) => {
	return (event: React.MouseEvent<HTMLButtonElement>) => {
		setCurrentSectionTo(section);
	};
};

export default ManufacturerFormTemplate;
