import React, { useEffect, useState } from 'react';
import axios from 'axios';
import viewEyeIcon from "./../../../../Assets/Icons/viewEyeIcon.svg";
import editPenIcon from "./../../../../Assets/Icons/editPenIcon.svg";
import deleteBinIcon from "./../../../../Assets/Icons/deleteBinIcon.svg";


import rightArrowIcon from "./../../../../Assets/Icons/rightArrowIcon.svg";
import leftArrowIcon from "./../../../../Assets/Icons/leftArrowicon.svg";

import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import TablePagination from '../Grade/TablePagination';
import { mapStandardStatusEnumToString } from '../../../../Constants/Enums/StandardStatusEnum';
import debounce from 'lodash/debounce';
import { formatDate } from '../../../../Core/Handlers/DateFormat';
import { useAuthenticatedUser } from '../../../../../hooks/useAuthenticatedUser';
const BaseUrl = process.env.REACT_APP_API_V1_URL;
const Pagination = ({ pageNumber, pageSize, handlePageSize, handlePageNumber, totalRecordsData }) => {

    const [currentPage, setCurrentPage] = useState(pageNumber);
    const [totalRecords, setTotalRecords] = useState(totalRecordsData);

    const handlePageSizeLocalUpdate = (e) => {
        const newPageSize = parseInt(e.target.value);
        setCurrentPage(0);
        handlePageNumber(0);
        handlePageSize(newPageSize);
    };

    const handlePageNumberLocalUpdate = (type) => {
        if (type === "pageBack" && currentPage > 0) {
            setCurrentPage(currentPage - 1);
            handlePageNumber(currentPage - 1);
        } else if (type === "pageForward" && currentPage < Math.ceil(totalRecords / pageSize) - 1) {
            setCurrentPage(currentPage + 1);
            handlePageNumber(currentPage + 1);
        }
    };

    const CalculatePaginationDetails = () => {
        const totalPages = totalRecords > 0 ? Math.ceil(totalRecords / pageSize) : 1;
        const currentPageNumber = currentPage + 1; // Add 1 to make it 1-based index
        return (
            <>{` Page ${currentPage + 1} of ${totalPages}`}</>
        );
    };

    useEffect(() => {
        setTotalRecords(totalRecordsData);
    }, [totalRecordsData]);


    return (
        <div className="relative w-full flex flex-row py-[0.5rem] px-[0rem] box-border items-center justify-end gap-[1.5rem] text-left text-[1rem] text-text-color font-roboto mr-6">
            <div className="flex flex-row items-start justify-start gap-[0.75rem]">
                <div className="relative">Rows per page</div>
                <div className="flex flex-row items-center justify-start gap-[0.25rem]">
                    <div className="relative">
                        <select
                            onChange={handlePageSizeLocalUpdate}
                            value={pageSize}
                            // value={2}
                            name="pageSize"
                        >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value={25}>25</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="flex flex-row items-start justify-start">
                <div className="relative">
                    <CalculatePaginationDetails />
                </div>

            </div>
            <button
                name="pageBack"
                onClick={() => handlePageNumberLocalUpdate("pageBack")}
            >
                <img
                    className="relative w-[0.31rem] h-[0.81rem] overflow-hidden shrink-0"
                    alt=""
                    src={leftArrowIcon}
                />
            </button>
            <button
                name="pageForward"
                onClick={() => handlePageNumberLocalUpdate("pageForward")}
                disabled={currentPage === Math.ceil(totalRecords / pageSize)}
            >
                <img
                    className="relative w-[0.31rem] h-[0.81rem] overflow-hidden shrink-0"
                    alt=""
                    src={rightArrowIcon}
                />
            </button>
        </div>
    );
};


const StandardsTableIndex = () => {
    const { user } = useAuthenticatedUser();
    const navigate = useNavigate();
    // State to store the fetched data
    const [standardsData, setStandardsData] = useState([]);

    // Filters and Pagination
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalRecords, setTotalRecords] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [status, setStatus] = useState('All');
    const [yearInUseValue, setYearInUseValue] = useState('All');
    const [yearLastUpdatedValue, setYearLastUpdatedValue] = useState('All');
    const [sorting, setSorting] = useState('createdAt,desc');


    useEffect(() => {
        fetchData(searchQuery);
    }, [searchQuery, status, yearInUseValue, yearLastUpdatedValue, sorting, pageSize, pageNumber]);

    const fetchData = (search) => {
        const baseUrl = `${BaseUrl}/standards`;

        let yearInUse = yearInUseValue;
        if (yearInUseValue === "All") {
            yearInUse = '';
        }
        let yearLastUpdated = yearLastUpdatedValue;
        if (yearLastUpdatedValue === "All") {
            yearLastUpdated = '';
        }
        // &status=${statusValue}
        var queryParams = `?search=${search}&page=${pageNumber}&size=${pageSize}&sort=${sorting}&yearInUse=${yearInUse}&yearLastUpdated=${yearLastUpdated}`;

        let statusValue = status
        if (status !== "All") {
            queryParams += `&status=${statusValue}`;
        }
        const url = `${baseUrl}${queryParams}`;

        axios
            .get(url,{
                headers: {
                   Authorization: `${user.token}`,
                   "Content-Type": "application/json",
                }
             })
            .then((response) => {

                if (response.data && response.data.data) {
                    setTotalRecords(response.data.data.totalElements);
                    setStandardsData(response.data.data.content);
                }
            })
            .catch((err) => {
                return
            });
    };


    const handleViewClick = (standardId) => {
        navigate("/standard/list/add",
            {
                state: {
                    type: 'VIEW',
                    itemId: standardId,
                },
            }
        );
    }

    const handleEditClick = (standardId) => {
        navigate("/standard/list/add",
            {
                state: {
                    type: 'EDIT',
                    itemId: standardId,
                },
            }
        );
    }


    const StandardsTableHeadings = () => {
        return (

            <div className="relative bg-blue w-full flex flex-row p-[0.75rem] box-border items-end justify-start gap-[0.75rem] text-left text-[0.88rem] text-white font-roboto">
                <div className="flex-1 flex flex-col items-start justify-start">
                    <div className="relative font-semibold text-left w-full sm:w-[calc(11rem / 1.6)]">Sr No.</div>
                </div>
                <div className="flex-1 flex flex-col items-center justify-start">
                    <div className="relative font-semibold text-left w-full sm:w-[calc(12rem / 1.6)]">Standard Name</div>
                </div>
                <div className="flex-1 flex flex-col items-center justify-start">
                    <div className="relative font-semibold text-left w-full sm:w-[calc(11rem / 1.6)]">Heading</div>
                </div>
                <div className="flex-1 flex flex-col items-center justify-start">
                    <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">Year(Created In)</div>
                </div>

                <div className="flex-1 flex flex-col items-center justify-start">
                    <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">Year(Last Updated)</div>
                </div>
                <div className="flex-1 flex flex-col items-center justify-start">
                    <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">Created Date</div>
                </div>
                <div className="flex-1 flex flex-col items-center justify-start">
                    <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">Last Modified</div>
                </div>
                <div className="flex-1 flex flex-col items-center justify-start">
                    <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">Status</div>
                </div>
                <div className="flex-1 flex flex-col items-center justify-start">
                    <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">Action</div>
                </div>
            </div>

        );
    };


    const debouncedSearch = debounce((value) => {
        fetchData(value);
    }, 300);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        debouncedSearch(e.target.value);
    };

    const StandardDetailsRecords = ({ records, curIndex=0 }) => {
        if (!records) {
            return null;
        }
        return (
            <>
                {records.map((standard, index) => (
                    <div key={index} className="catalogue-details self-stretch flex flex-row p-[0.75rem] items-center justify-start gap-[0.75rem] border-b border-gray-300">

                        <div className="flex-1 flex flex-col items-center justify-start">
                            <div className="relative  w-full sm:w-[calc(11rem / 1.6)]   text-left ">{curIndex + index + 1}</div>
                        </div>

                        <div className="flex-1 flex flex-col items-center justify-start">
                            <div className="relative  w-full sm:w-[calc(11rem / 1.6)]   text-left ">{standard.name}</div>
                        </div>

                        <div className="flex-1 flex flex-col items-center justify-start">
                            <div className="relative  w-full sm:w-[calc(11rem / 1.6)]   text-left ">{standard.bisHeading}</div>
                        </div>

                        <div className="flex-1 flex flex-col items-center justify-start">
                            <div className="relative  w-full sm:w-[calc(11rem / 1.6)]   text-center "> {standard.yearInUse}</div>
                        </div>

                        <div className="flex-1 flex flex-col items-center justify-start">
                            <div className="relative  w-full sm:w-[calc(11rem / 1.6)]   text-center ">{standard.yearLastUpdated}</div>
                        </div>

                        <div className="flex-1 flex flex-col items-center justify-start">
                            <div className="relative  w-full sm:w-[calc(11rem / 1.6)]   text-center ">{formatDate(standard.createdAt)}</div>
                        </div>

                        <div className="flex-1 flex flex-col items-center justify-start">
                            <div className="relative  w-full sm:w-[calc(11rem / 1.6)]   text-center ">{formatDate(standard.updatedAt)}</div>
                        </div>

                        <div className={`flex-1 flex flex-col items-center justify-start ${standard.status === "ACTIVE" ? "text-green" : "text-inactive"}`}>
                            <div className="relative  w-full sm:w-[calc(11rem / 1.6)]   text-center "> {mapStandardStatusEnumToString(standard.status)}</div>
                        </div>

                        <div className="flex-1 flex flex-col items-center justify-start text-center ">
                            <div className="relative  w-full sm:w-[calc(11rem / 1.6)]  text-center">

                                <button
                                    className="relative w-[1.13rem] h-[1.13rem] overflow-hidden shrink-0 text-center mr-2"
                                    onClick={(e) => handleViewClick(standard.id)}
                                >
                                    <img className="w-full h-full" alt="" src={viewEyeIcon} />
                                </button>

                                <button
                                    className="relative w-[1.13rem] h-[1.13rem] overflow-hidden shrink-0 text-center mr-2 "
                                    onClick={(e) => handleEditClick(standard.id)}
                                >
                                    <img className="w-full h-full" alt="" src={editPenIcon} />
                                </button>

                                {/* <button
                                className="relative w-[1.13rem] h-[1.13rem] overflow-hidden shrink-0 text-center "
                                onClick={(e) => handleEditClick(standard.id)}
                            >
                                <img className="w-full h-full" alt="" src={deleteBinIcon} />
                            </button> */}

                            </div>
                        </div>

                    </div>

                ))
                }

            </>


        )
    }
    return (
        <div className="w-full">

            <div className="flex justify-between mb-3">
                <div className="flex gap-x-5">

                    {/* <FormControl sx={{ width: "170px" }}>
                        <InputLabel id="status-label">Year(Created in)</InputLabel>
                        <Select
                            labelId="status-label"
                            id="status-select"
                            value={yearInUseValue}
                            // onChange={handleStatusChange}
                            label="Year(Created in)"
                            sx={{ color: "black" }}
                        >
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="PUBLISHED">Published</MenuItem>
                            <MenuItem value="UNPUBLISHED">Unpublished</MenuItem>
                        </Select>
                    </FormControl>



                    <FormControl sx={{ width: "200px" }}>
                        <InputLabel id="status-label">Year(Last Updated)</InputLabel>
                        <Select
                            labelId="status-label"
                            id="status-select"
                            value={yearLastUpdatedValue}
                            // onChange={handleStatusChange}
                            label="Year(Last Updated)"
                            sx={{ color: "black" }}
                        >
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="PUBLISHED">Published</MenuItem>
                            <MenuItem value="UNPUBLISHED">Unpublished</MenuItem>
                        </Select>
                    </FormControl> */}



                    <FormControl sx={{ width: "170px" }}>
                        <InputLabel id="status-label">Status</InputLabel>
                        <Select
                            labelId="status-label"
                            id="status-select"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            label="Status"
                            sx={{ color: "black" }}
                        >
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="ACTIVE">Active</MenuItem>
                            <MenuItem value="INACTIVE">Inactive</MenuItem>
                        </Select>
                    </FormControl>



                    <FormControl sx={{ width: "250px" }}>
                        <InputLabel id="sorting-label">Sort</InputLabel>
                        <Select
                            labelId="sorting-label"
                            id="sorting-select"
                            label="Sort"
                            value={sorting}
                            onChange={(e) => setSorting(e.target.value)}
                            sx={{ width: "180%", maxWidth: "250px" }}
                        // sx={{ width: "250px" }}
                        >

                            <MenuItem value="name,asc">Name (Ascending)</MenuItem>
                            <MenuItem value="name,desc">Name (Descending)</MenuItem>
                            <MenuItem value="createdAt,asc">Created Date(Ascending)</MenuItem>
                            <MenuItem value="createdAt,desc">Created Date(Descending)</MenuItem>

                        </Select>
                    </FormControl>

                </div>

                <div>
                    <FormControl sx={{ width: "345px" }}
                    // sx={{ width: "345px" }}
                    >
                        <TextField
                            className={`h-[3rem] block w-full p-1 border border-gray-500 rounded-md focus:outline-none ring-blue-500 focus:border-blue-500`}
                            id="outlined-basic"
                            label="Search by Name"
                            variant="outlined"
                            fullWidth
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                    </FormControl>
                </div>
            </div>



            <StandardsTableHeadings />
            <StandardDetailsRecords records={standardsData} curIndex={ pageSize*pageNumber }/>
            {/* ---------------------Pagination-------------------------- */}
            <Pagination
                pageNumber={pageNumber}
                pageSize={pageSize}
                handlePageSize={setPageSize}
                handlePageNumber={setPageNumber}
                totalRecordsData={totalRecords}
            />
        </div>
    );

};

export default StandardsTableIndex;
