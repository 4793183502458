import * as yup from "yup";
import { createUseStyles } from 'react-jss';
import { useFormik } from 'formik';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { BUSINESS_PROFILE_SECTIONS, BUSINESS_TYPES, INDUSTRY_TYPES, REGEX, SOCIAL_LINKS } from '../../../utils/constant';
import { convertDate } from '../../../utils/helper';
import TextFieldV2 from '../../atoms/TextFieldV2';
import SelectV2 from '../../atoms/SelectV2';
import ButtonV2 from '../../atoms/ButtonV2';
import { IBusinessProfile } from "../../pages/CustomerManagement/ProfileOverview.page";
import { useEffect } from "react";

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.primarylightAccent}`,
    },
    title: {
        color: theme.palette.text.primaryLight,
    },
    errorMessage: {
        color: theme.palette.action.danger,
    }
}));

export interface IOtherInformationRequestBody {
    dateOfEstablishment: number | string;
    totalEmployees: number;
    websiteUrl: string;
    industryType: string;
    businessType: string;
    brand: string;
}

interface IOtherInformationUpdateTemplateProps {
    updateBusinessProfileSection: (section: string, values: any) => void;
    businessProfile: IBusinessProfile | null;
    setIsEditing: (value: boolean) => void;
}

const OtherInformationUpdateTemplate: React.FC<IOtherInformationUpdateTemplateProps> = ({ updateBusinessProfileSection, businessProfile, setIsEditing }) => {

    const classes = useStyles();
    const { showSnackbar } = useSnackbar();

    const otherInformationSchema = yup.object().shape({
        dateOfEstablishment: yup.string(),
        totalEmployees: yup.number().typeError('Number of Employees must be a number').required('Number of Employees is required').moreThan(0, 'Number of Employees must be greater than zero').positive('Number of Employees must be a positive number'),
        industryType: yup.string().required('Industry Type is required'),
        businessType: yup.string().required('Business Type is required'),
        brand: yup.string().required('Brand is required'),
        websiteUrl: yup.string().url('Please enter a valid URL')
    });

    const otherInformationFormik = useFormik<IOtherInformationRequestBody>({
        initialValues: {
            dateOfEstablishment: businessProfile?.dateOfEstablishment || 0,
            totalEmployees: businessProfile?.totalEmployees,
            websiteUrl: businessProfile?.websiteUrl || '',
            industryType: businessProfile?.industry || '',
            businessType: businessProfile?.businessType || '',
            brand: businessProfile?.brand || '',
        },
        validationSchema: otherInformationSchema,
        validateOnChange: true,
        validateOnMount: true,
        validateOnBlur: true,
        onSubmit: async (values: any, { setSubmitting }) => {
            try {
                setSubmitting(true);
                const requestBody = {
                    dateOfEstablishment: values?.dateOfEstablishment,
                    totalEmployees: values?.totalEmployees,
                    websiteUrl: values?.websiteUrl,
                    industryType: values?.industryType,
                    businessType: values?.businessType,
                    brand: values?.brand,
                }
                await updateBusinessProfileSection(BUSINESS_PROFILE_SECTIONS.OTHER_INFORMATION, requestBody);
                setIsEditing(false);
            } catch (error) {
                showSnackbar("error", `Business profile update failed: ${error}`);
            } finally {
                otherInformationFormik.setSubmitting(false);
            }
        },
    });

    return (
        <form onSubmit={otherInformationFormik.handleSubmit}>
            <div className={`${classes.container} p-6 rounded-lg flex flex-col gap-y-6 w-full`}>
                <div className='flex justify-between items-center pb-6 border-b border-grey-500'>
                    <h2 className={`${classes.title} text-2xl font-medium`}>Other Information</h2>
                    <div className='flex gap-4'>
                        <ButtonV2 variant={'tertiaryContained'} label={'Back'} type="button" onClick={() => setIsEditing(false)} />
                        <ButtonV2 variant={'primaryContained'} label={'Save'} type="submit" />
                    </div>
                </div>
                <div className='grid gap-y-6'>
                    <div className='grid grid-cols-2 gap-x-4'>
                        <TextFieldV2
                            label="Year of Establishment"
                            fullWidth
                            placeholder="Select Year"
                            value={convertDate(businessProfile?.dateOfEstablishment)}
                            error={
                                otherInformationFormik.touched?.dateOfEstablishment &&
                                Boolean(otherInformationFormik.errors?.dateOfEstablishment)
                            }
                            inputProps={{
                                readOnly: true
                            }}
                        />
                        <TextFieldV2
                            label="Number of Employees"
                            fullWidth
                            {...otherInformationFormik.getFieldProps("totalEmployees")}
                            placeholder="Select Number of Employees"
                            error={
                                otherInformationFormik.touched?.totalEmployees &&
                                Boolean(otherInformationFormik.errors?.totalEmployees)
                            }
                            helperText={(otherInformationFormik.touched?.totalEmployees &&
                                Boolean(otherInformationFormik.errors?.totalEmployees)) && otherInformationFormik.errors?.totalEmployees}
                        />
                    </div>
                    <div className='grid grid-cols-3 gap-x-4'>
                        <SelectV2
                            variant={'outlined'}
                            label='Industry Type'
                            fullWidth
                            placeholder="Select Industry Typer"
                            {...otherInformationFormik.getFieldProps("industryType")}
                            error={otherInformationFormik?.touched?.industryType &&
                                Boolean(otherInformationFormik?.errors?.industryType)}
                            options={INDUSTRY_TYPES?.map((industryData) => ({
                                value: String(industryData.value),
                                label: industryData.label,
                            })) || []}
                            helperText={otherInformationFormik?.errors?.industryType}
                        />
                        <SelectV2
                            variant={'outlined'}
                            label='Business Type'
                            fullWidth
                            placeholder="Select Business Type"
                            {...otherInformationFormik.getFieldProps("businessType")}
                            error={otherInformationFormik.touched?.businessType &&
                                Boolean(otherInformationFormik.errors?.businessType)}
                            options={BUSINESS_TYPES?.map((businessType) => ({
                                value: String(businessType.id),
                                label: businessType.name,
                            })) || []}
                            helperText={otherInformationFormik?.errors?.businessType}
                        />
                        <TextFieldV2
                            label="Brand"
                            fullWidth
                            placeholder="Enter Brand"
                            {...otherInformationFormik.getFieldProps("brand")}
                            error={
                                otherInformationFormik.touched?.brand &&
                                Boolean(otherInformationFormik.errors?.brand)
                            }
                            helperText={(otherInformationFormik.touched?.brand &&
                                Boolean(otherInformationFormik.errors?.brand)) && otherInformationFormik.errors?.brand}
                        />
                    </div>
                    <TextFieldV2
                        label="Website URL"
                        fullWidth
                        placeholder="Enter URL"
                        {...otherInformationFormik.getFieldProps("websiteUrl")}
                        error={otherInformationFormik.touched?.websiteUrl && Boolean(otherInformationFormik.errors?.websiteUrl)}
                        helperText={(otherInformationFormik.touched?.websiteUrl &&
                            Boolean(otherInformationFormik.errors?.websiteUrl)) && otherInformationFormik.errors?.websiteUrl}
                    />
                </div>
            </div>
        </form>
    );
};

export default OtherInformationUpdateTemplate;
