import React, { useEffect, useState } from 'react';
import CustomTab from '../../../Shared/CustomTabs/CustomTab';
import { FormControl } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import ListPaginationButton from '../../../Components/Procurement/Indent/ListPaginationButton';
import {
  VENDOR_DISCOVERY_LIST_PAGINATION_TEXT,
  VENDOR_DISCOVERY_TABS,
} from '../../../Constants/Procurement/VendorDiscovery/constants';
import moment, { Moment } from 'moment';
import AuctionListTable from '../../../Components/Procurement/ReverseActionRFQ/AuctionListTable';
import { useNavigate } from 'react-router-dom';
import { usePRContext } from '../../../../hooks/useProcurementContext';
import VendorTableFilter from '../../../Components/Procurement/VendorDiscovery/VendorTableFilter';
import {
  Auction,
  AuctionEntity,
} from '../../../Constants/Procurement/ReverseAuctionRFQ/types';
import { VendorDiscoveryContextType } from '../../../Constants/Procurement/VendorDiscovery/types';
import NoDataFound from '../../../../components/atoms/NoDataFound/NoDataFound';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import { AUCTION_STATUS } from '../../../Constants/Procurement/ReverseAuctionRFQ/constants';
import { useReverseAuctionService } from '../../../../services/useReverseAuctionService';

const VendorDiscovery = () => {
  const { vendorDiscoveryParams, setVendorDiscoveryParams } = usePRContext();
  const { user }: any = useAuthenticatedUser();

  const [tab, setTab] = useState<number>(
    vendorDiscoveryParams.isUserAuction
      ? 1
      : vendorDiscoveryParams.isUserDrafts
      ? 2
      : 0
  );
  const [startDate, setStartDate] = useState<Moment>();
  const [endDate, setEndDate] = useState<Moment>();
  const [toDate, setToDate] = useState<Moment>(
    moment.unix(vendorDiscoveryParams.toDate)
  );
  const [fromDate, setFromDate] = useState<Moment>(
    moment.unix(vendorDiscoveryParams.fromDate)
  );
  const [auctionId, setAuctionId] = useState<string>(
    vendorDiscoveryParams.searchTerm
  );
  const [searchPage, setSearchPage] = useState<number>(
    vendorDiscoveryParams.pageNumber
  );

  const [auctionList, setAuctionList] = useState<AuctionEntity[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(
    vendorDiscoveryParams.pageNumber
  );
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const navigate = useNavigate();

  const { getIndentPlusAuction, getAuctions } = useReverseAuctionService();

  const openAuction = async (auctionId: string) => {
    try {
      const response = await getIndentPlusAuction(auctionId);
      if (response.data && response.data.data) {
        const params = {
          ...vendorDiscoveryParams,
          selectedAuction: response.data.data as Auction,
        };
        setVendorDiscoveryParams(params);
        navigate(
          '/procurement/reverse-auction/vendor-discovery/reverse-auction-rfq'
        );
        // for manager
        // navigate('/admin/procurement/vendor-discovery/select-vendor');
      }
    } catch (error) {
      console.log('Error while getting auction/indent details -\n', error);
    }
  };

  const searchHandler = (value: string) => {
    setAuctionId(value);
    setPageNumber(0);
    const params = {
      ...vendorDiscoveryParams,
      ...{
        pageNumber: 0,
        searchTerm: value,
      },
    };
    fetchAuctions(params);
  };

  const fetchAuctions = async (
    params: VendorDiscoveryContextType
  ): Promise<void> => {
    setVendorDiscoveryParams(params);
    if (pageNumber >= 0) {
      const queryParams = {
        pageSize: params.pageSize,
        pageNumber: params.pageNumber,
        isUserAuctions: params.isUserAuction,
        auctionFromDate: params.auctionFromDate,
        auctionToDate: params.auctionToDate,
        fromDate: params.fromDate,
        isUserDrafts: params.isUserDrafts,
        searchTerm: params.searchTerm,
        statuses:
          params.statuses === 'All' ? '' : params.statuses.toUpperCase(),
        toDate: params.toDate,
      };

      try {
        const response = await getAuctions(queryParams);
        if (response?.data?.data?.content) {
          const content: AuctionEntity[] = response?.data?.data?.content;
          const auctions = content.map((auction) => {
            return {
              id: auction.id,
              auctionName: auction.auctionName,
              entityID: auction.entityID,
              startDateTime: auction.startDateTime,
              endDateTime: auction.endDateTime,
              status:
                AUCTION_STATUS.find((i) => i.value === auction.status)?.name ||
                '',
              createdBy: auction.createdBy,
              createdAt: auction.createdAt,
              purchaseRequistionCount: auction.purchaseRequistionCount,
              quantity: auction.quantity,
            };
          });
          setTotalRecords(response.data.data?.totalElements);
          setTotalPages(response.data.data.totalPages);
          setPageNumber(response.data.data.pageable.pageNumber);
          setAuctionList(auctions);
          setSearchPage(response.data.data.pageable.pageNumber);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setTotalRecords(0);
        setTotalPages(0);
        setPageNumber(0);
        setAuctionList([]);
        setSearchPage(0);
      }
    }
  };
  const handlePagination = (type: 'prev' | 'next' | 'search'): void => {
    if (type === 'prev') {
      if (pageNumber > 0) {
        setPageNumber((prev) => prev - 1);
        const params: VendorDiscoveryContextType = {
          ...vendorDiscoveryParams,
          ...{
            pageNumber: pageNumber - 1,
          },
        };
        fetchAuctions(params);
      }
    } else if (type === 'next') {
      if (pageNumber < totalPages) {
        setPageNumber((prev) => prev + 1);
        const params: VendorDiscoveryContextType = {
          ...vendorDiscoveryParams,
          ...{
            pageNumber: pageNumber + 1,
          },
        };
        fetchAuctions(params);
      }
    } else if (type === 'search') {
      if (searchPage >= 0 && pageNumber !== searchPage) {
        setPageNumber(searchPage);
        const params = {
          ...vendorDiscoveryParams,
          ...{
            pageNumber: searchPage,
          },
        };
        fetchAuctions(params);
      }
    }
  };

  useEffect(() => {
    let params = vendorDiscoveryParams;
    if (
      vendorDiscoveryParams.fromDate !== fromDate.startOf('day').unix() ||
      vendorDiscoveryParams.toDate !== toDate.endOf('day').unix() ||
      vendorDiscoveryParams.auctionFromDate !==
        startDate?.endOf('day').unix() ||
      vendorDiscoveryParams.auctionToDate !== endDate?.endOf('day').unix()
    ) {
      params = {
        ...vendorDiscoveryParams,
        pageNumber: 0,
        auctionFromDate: startDate?.startOf('day').unix(),
        auctionToDate: endDate?.endOf('day').unix(),
        fromDate: fromDate.startOf('day').unix(),
        toDate: toDate.endOf('day').unix(),
      };
    }
    fetchAuctions(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, startDate, endDate]);

  return (
    <div className="grid gap-y-3">
      <div className="flex justify-between mt-2">
        <CustomTab Tabs={VENDOR_DISCOVERY_TABS} tab={tab} setTab={setTab} />
        <div className="flex gap-6">
          <div className="flex items-center gap-x-3">
            <div className="text-blue font-semibold text-base text-nowrap">
              Start Date
            </div>
            <FormControl sx={{ minWidth: '150px', maxWidth: '170px' }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                  }}
                  format="DD/MM/YYYY"
                  value={startDate}
                  name="createdDate"
                  onChange={(date) => {
                    if (date) {
                      setStartDate(date);
                    }
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </div>
          <div className="flex items-center gap-x-3">
            <div className="text-blue font-semibold text-base text-nowrap">
              End Date
            </div>
            <FormControl sx={{ minWidth: '150px', maxWidth: '170px' }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                  }}
                  format="DD/MM/YYYY"
                  value={endDate}
                  name="createdDate"
                  onChange={(date) => {
                    if (date) {
                      setEndDate(date);
                    }
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </div>
        </div>
      </div>
      <VendorTableFilter
        searchHandler={searchHandler}
        fromDateHandler={setFromDate}
        toDateHandler={setToDate}
        toDate={toDate}
        fromDate={fromDate}
        searchText={auctionId}
      />
      {auctionList.length <= 0 ? (
        <div className="py-32">
          <NoDataFound />
        </div>
      ) : (
        <>
          <AuctionListTable
            auctionList={auctionList}
            idHandler={openAuction}
            currentPage={pageNumber}
          />
          <ListPaginationButton
            PaginationText={VENDOR_DISCOVERY_LIST_PAGINATION_TEXT.showingItems(
              pageNumber,
              totalRecords
            )}
            pageNumber={pageNumber}
            handlePagination={handlePagination}
            searchPage={searchPage}
            setSearchPage={setSearchPage}
            totalPages={totalPages}
            totalRecords={totalRecords}
          />
        </>
      )}
    </div>
  );
};

export default VendorDiscovery;
