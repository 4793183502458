import React, { useEffect, useState } from "react";
import TitleHeading from "../../atoms/CustomHeading/TitleHeading";
import { useOrderService } from "../../../services/useOrderService";
import { useParams } from "react-router-dom";
import RecordCard from "../../atoms/Cards/RecordCard";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CachedIcon from "@mui/icons-material/Cached";
import ListAltIcon from "@mui/icons-material/ListAlt";
import completedBackgroundPng from "../../../assets/images/cardBackgroundRed.png";
import listBackgroundPng from "../../../assets/images/cardBackgroundGreen.png";
import ongoingBackgroundPng from "../../../assets/images/cardBackgroundOrange.png";
import cancelBackgroundPng from "../../../assets/images/cardBackgroundTurquoise.png";
import gstSvg from "../../../assets/icons/gst.svg";
import Button from "../../atoms/Button";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import ApartmentIcon from "@mui/icons-material/Apartment";
import TermsConditionModalTemplate from "../../templates/Order/TermsConditionModal.template";
import CustomerDetailTemplate from "../../templates/Lead/CustomerDetail.template";
import OrderListTemplate from "../../templates/Order/OrderList.template";
import ConsigneeFormModalTemplate from "../../templates/Order/ConsigneeFormModal.template";
import { createUseStyles } from "react-jss";
import { Charges, SkuData } from "../Lead/QuotationView.page";

export interface IOrderMaster {
	id: number;
	orderNo: string;
	customerId: number;
	orderTotal: number;
	orderStatus: string;
	paymentStatus: string;
	productItem: number;
	paymentMode: string;
	createdBy: number;
	createdAt: string;
	updatedBy: number;
	customerName: string;
	companyName: string;
	totalQuantity: number;
	consigneeDetails: ICustomerDetails;
	customerDetails: ICustomerDetails;
	vehicleDetails: string;
	quotationId: number;
	termsAndCondition: string;
	charges: Charges
	product: IOrder;
}

export interface IOrder {
	id: number;
	parentId: number;
	orderMaster: number;
	orderNo: string;
	customerId: number;
	upcId: number;
	skuId: number;
	uomId: number;
	productName: string;
	warehouse: string;
	unitPrice: number;
	totalPrice: number;
	quantity: number;
	status: string;
	createdBy: number;
	updatedBy: number;
	secondarySkuId: number;
	catalogueName: string;
	skuData: SkuData;
};

export interface ICustomerDetails {
	name: string;
	phoneNumber: number;
	companyName: string;
	email: string;
	gstn: string;
}

export interface IStatusUpdate {
	id: number | null,
	status: string,
};

const initialStatusUpdateValues: IStatusUpdate = {
	id: null,
	status: "",
};

const useStyles = createUseStyles((theme: any) => ({
	title: {
		color: theme.palette.text.primaryDarkLight,
	},
	detailContainer: {
		border: `1px solid ${ theme.palette.border.primaryDark }`
	}
}));

const OrderList: React.FC = () => {
	const params = useParams();
	const classes = useStyles();
	const orderService = useOrderService();
	const [orderMaster, setOrderMasterTo] = useState<IOrderMaster | null>(null);
	const [orders, setOrdersTo] = useState<IOrder[]>([]);
	const [statusUpdate, setStatusUpdate] = useState<IStatusUpdate>(initialStatusUpdateValues);

	const loadOrderMaster = async () => {
		try {
			const orderMasterResponse = await orderService.getOrderMasterById(Number(params.id));
			if (orderMasterResponse?.data?.data) {
				setOrderMasterTo(orderMasterResponse.data?.data);
				setOrdersTo(orderMasterResponse.data?.data?.product)
			} else {
				throw new Error("Order Master fetch failed");
			}
		} catch (error) {
			setOrderMasterTo(null);
			setOrdersTo([]);
		}
	};

	const customerDetailItem = [
		{ icon: <PersonIcon />, value: orderMaster?.customerDetails?.name },
		{ icon: <ApartmentIcon />, value: orderMaster?.customerDetails?.companyName },
		{ icon: <img className="w-4" src={ gstSvg } alt="gstIcon" />, value: orderMaster?.customerDetails?.gstn },
		{ icon: <EmailIcon />, value: orderMaster?.customerDetails?.email },
		{ icon: <PhoneIcon />, value: orderMaster?.customerDetails?.phoneNumber },
	];

	const consigneeDetailItem = [
		{ icon: <PersonIcon />, value: orderMaster?.consigneeDetails?.name },
		{ icon: <ApartmentIcon />, value: orderMaster?.consigneeDetails?.companyName },
		{ icon: <img className="w-4" src={ gstSvg } alt="gstIcon" />, value: orderMaster?.consigneeDetails?.gstn },
		{ icon: <EmailIcon />, value: orderMaster?.consigneeDetails?.email },
		{ icon: <PhoneIcon />, value: orderMaster?.consigneeDetails?.phoneNumber },
	];

	const orderStats = [
		{
			backgroundImg: listBackgroundPng,
			icon: <ListAltIcon className="text-white" sx = {{ fontSize: 50 }} />,
			title: "Total Amount",
			value: orderMaster?.orderTotal ? orderMaster?.orderTotal.toFixed(2) : 0,
		},
		{
			backgroundImg: ongoingBackgroundPng,
			icon: <CachedIcon className="text-white" sx = {{ fontSize: 50 }} />,
			title: "Received Amount",
			value: 0,
		},
		{
			backgroundImg: completedBackgroundPng,
			icon: <CheckCircleIcon className="text-white" sx = {{ fontSize: 50 }} />,
			title: "Pending Amount",
			value: 0,
		},
		{
			backgroundImg: cancelBackgroundPng,
			icon: <CancelIcon className="text-white" sx = {{ fontSize: 50 }} />,
			title: "Last Pay Date",
			value: 0,
		},
	];

	const [open, setOpen] = useState<boolean>(false);
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const [formModalOpen, setFormModalOpen] = useState<boolean>(false);
	const handleFormOpen = () => {
		setFormModalOpen(true);
	}
	const handleFormClose = () => {
		setFormModalOpen(false);
	}

	const handleUpdateOrder = (id: number) => {
		setStatusUpdate({ ...statusUpdate, id: id });
	};

	const handleSubmit = async () => {
		const data = {
			status: statusUpdate.status,
		};
		if (statusUpdate.id !== null && statusUpdate.status !== "") {
			try {
				const updateOrderStatusResponse = await orderService.updateOrder(statusUpdate.id, data);
				if (updateOrderStatusResponse?.status === 200 && updateOrderStatusResponse?.data?.data) {
					setStatusUpdate({
						id: null,
						status: "",
					});
					loadOrderMaster();
				} else {
					console.error("Failed to update Order data:", updateOrderStatusResponse);
				}
			} catch (error) {
				throw error;
			}
		}
	};

	useEffect(() => {
		loadOrderMaster();
	}, [params.id]);

	return (
		<div className="grid gap-y-4">
			<div className="flex justify-between">
				<TitleHeading text = {`Order Id - ${ Number(params.id)}` } />
			</div>

			<div className="grid gap-y-4">
				<RecordCard cards = { orderStats } />

				<div className="flex justify-end">
					<div className="flex  justify-between gap-x-3">
						<Button variant="outlined" onClick={ handleOpen } label="Terms and Condition" />
						<TermsConditionModalTemplate open={ open } onClose={ handleClose } termsAndCondition={ orderMaster && orderMaster?.termsAndCondition } />
						{orderMaster && orderMaster?.consigneeDetails != null ? (
							<Button variant = "contained" onClick = { handleFormOpen } label = "Update Consignee" />
						) : (
							<Button variant = "contained" onClick = { handleFormOpen } label = "Add Consignee" />
						)}
						<ConsigneeFormModalTemplate open = { formModalOpen } onClose = { handleFormClose } id = { Number(params.id) } consignee = { orderMaster?.consigneeDetails } loadOrderMaster= {loadOrderMaster} />
					</div>
				</div>

				<div className={ `${ classes.title } py-3 flex gap-x-3` }>
					<div className={ `${ classes.detailContainer } rounded p-3 grid gap-y-1 w-1/3` }>
						<div className="text-base font-semibold ">Customer Details</div>
						<div className="grid gap-y-1 ">
							{customerDetailItem.map((item: any) => (
								<CustomerDetailTemplate
									key = { item.key }
									icon = { item.icon }
									value = { item.value }
								/>
							))}
						</div>
					</div>
					<div className={ `${ classes.detailContainer } rounded p-3 grid gap-y-1 w-1/3` }>
						<div className="text-base font-semibold ">Consignee Details</div>
						<div className="grid gap-y-1 ">
							{consigneeDetailItem.map((item: any) => (
								<CustomerDetailTemplate
									key = { item.key }
									icon = { item.icon }
									value = { item.value }
								/>
							))}
						</div>
					</div>
				</div>

				<OrderListTemplate
					orders = { orders }
					setStatusUpdate = { setStatusUpdate }
					statusUpdate = { statusUpdate }
					handleUpdateOrder = { handleUpdateOrder }
					handleSubmit = { handleSubmit }
					orderMasterId = { Number(params.id) }
				/>
			</div>
		</div>
	);
};

export default OrderList;