import React, { useEffect, useMemo, useState } from 'react'
import { IPagination } from '../../../utils/types';
import Table, { ColumnType } from '../../organisms/Table';
import { createUseStyles } from "react-jss";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { MODE } from '../../../utils/constant';

export interface IUpc {
    id: number;
    masterUpcCode: string
    brandName: string;
    upcCode: string;
    brandCode?: string;
}

interface IUpcTemplateProps {
    upcs: IUpc[];
    mode: string;
    setUpcBrandMappingTo: any;
    pagination: IPagination;
    handlePaginationChange: any;
    handleRowsPerPageChange: any;
}

const useStyles = createUseStyles((theme: any) => ({
    editIconContainer: {
        color: "red",
    }
}));

const UPCMappingTemplate: React.FC<IUpcTemplateProps> = ({ upcs, mode, setUpcBrandMappingTo, pagination, handlePaginationChange, handleRowsPerPageChange }) => {

    const classes = useStyles();

    const [updatedUpcs, setUpdatedUpcs] = useState<IUpc[]>(upcs);

    useEffect(() => {
        setUpdatedUpcs(upcs)
    }, [upcs])

    const schema = {
        id: "brand-mapping-list",
        title: "",
        pagination: {
            total: pagination.totalRecords,
            currentPage: pagination.currentPage,
            isVisible: true,
            limit: pagination.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange,
        },
        columns: [
            { label: "Sr No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "16 digit  Code", key: "masterUpcCode", type: "string" as ColumnType, props: { className: '' } },
            { label: "Brand", key: "brandName", type: "string" as ColumnType, props: { className: '' } },
            { label: "19 digit  Code", key: "upcCode", type: "string" as ColumnType, props: { className: '' } },
        ],
    };
    if (mode !== MODE.VIEW) {
        schema.columns.push({ label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } });
    }

    const handleEditClick = async (id: number) => {
        const updatedData = updatedUpcs.filter((upc) => upc.id !== id);
        setUpdatedUpcs(updatedData);
        setUpcBrandMappingTo(updatedData);
    };

    const Action = ({ id }: { id: number }) => {
        return <div className="flex gap-x-2 justify-end">
            <RemoveCircleIcon className={`${classes.editIconContainer} text-secondary cursor-pointer`} onClick={(e) => handleEditClick(id)} />
        </div>
    }

    const records = useMemo(() => {
        return updatedUpcs?.map((upc: IUpc, index: number) => [
            pagination.currentPage * pagination.pageSize + index + 1,
            upc.masterUpcCode,
            upc.brandName !== null ? upc.brandName : "",
            upc.upcCode,
            mode !== MODE.VIEW && <Action id={upc.id} />
        ]);
    }, [updatedUpcs, pagination.currentPage, pagination.pageSize, mode]);

    return (
        <div className='grid gap-y-1.5'>
            <Table schema={schema} records={records} />
        </div>
    )
}

export default UPCMappingTemplate;


