import React, { useEffect, useMemo, useState } from "react";
import { useCategoryService } from "../../../../services/useCategoryService";
import { IStandardFilterRequest, Standard, useStandardService } from "../../../../services/useStandardService";
import { IQualityGradeFilter, useQualtiyGradeService } from "../../../../services/useQualityGradesService";
import { createUseStyles } from "react-jss";
import { MODE } from "../../../../utils/constant";
import Select from "../../../atoms/Select";
import { FormControl } from '@mui/material';
import AutoCompleteTextField, { AutoCompleteOption } from "../../../molecules/AutoCompleteInput/AutoCompleteInput";
import { sortByDate } from "../../../../utils/helper";

interface StandardGradeSearchTemplateProps {
	formik: any;
	mode: String;
}

const useStyles = createUseStyles((theme: any) => ({
	errorMessage: {
		color: theme.palette.action.danger,
	},
}));

const StandardGradeSearchTemplate: React.FC<StandardGradeSearchTemplateProps> = ({ formik, mode }) => {
	const classes = useStyles();
	const categoryService = useCategoryService();
	const standardService = useStandardService();
	const qualityGradeService = useQualtiyGradeService();
	const [categoryOptions, setCategoryOptions] = useState({
		grades: []
	});
	const [standardData, setStandardData] = useState<Standard[]>([]);

	const updateCategoryOptions = (type: any, data: any) => {
		setCategoryOptions(prevState => ({
			...prevState,
			[type]: data,
		}));
	};

	const convertToOptions = (data: any) => {
		if (!Array.isArray(data)) {
			throw new Error('Input data should be an array.');
		}
		return data.map(item => {
			if (!item || typeof item !== 'object' || !item.hasOwnProperty('id') || !item.hasOwnProperty('name')) {
				throw new Error('Invalid item in data array.');
			}
			return {
				label: item.name,
				id: item.id
			};
		});
	};

	const loadStandard = async () => {
		if (formik?.values?.categoryId) {
			const categoryId = formik.values.categoryId;
			try {
				const response = await categoryService.getCategoryById(categoryId);
				const sortedStandards = sortByDate(response.data.data.standardDetails, 'createdAt');
				setStandardData(sortedStandards)
			} catch (error) {
				console.error('Error fetching product categories:', error);
			}
		} else {
			try {
				const params: IStandardFilterRequest = {
					search: '',
					sort: 'createdAt,desc',
					page: 0,
					size: 10,
				}
				const response = await standardService.getAllStandards(params);
				setStandardData(response?.data?.data?.content)
			} catch (error) {
				console.error('Error fetching standards:', error);
			}
		}
	};

	const updateStandards = (key: string) => (option: AutoCompleteOption | null) => {
		formik.setFieldValue(key, option);
	};

	const standardOptions = useMemo(() => {
		return standardData.map((option: Standard) => ({ label: option.name, id: option.id })) ?? []
	}, [standardData])

	useEffect(() => {
		loadStandard()
	}, [formik?.values?.categoryId]);
	
	const loadGrade = async () => {
		if (formik?.values?.standard) {
			const params: IQualityGradeFilter = {
				search: '',
				type: 'GRADE',
				standardId: formik?.values?.standard?.id,
				sort: 'createdAt,desc',
				page: 0,
				size: 100
			}
			try {
				const response = await qualityGradeService.getAllQualityGrades(params);
				updateCategoryOptions('grades', convertToOptions(response.data.data.content));
			} catch (error) {
				console.error('Error fetching grades:', error);
			}
		}
	};
	useEffect(() => {
		loadGrade()
	}, [formik?.values?.standard]);

	return (
		<div className="grid grid-cols-2 gap-x-3">
			<div className='grid'>
				<FormControl fullWidth>
					<AutoCompleteTextField
						options={standardOptions}
						label="Select Standard"
						onChange={updateStandards("standard")}
						onSearch={loadStandard}
						isDisabled={mode !== MODE.ADD}
						value={formik.values.standard}
						error={!!formik.errors.standard && formik.touched.standard}
					/>
				</FormControl>
				{formik.errors.standard?.id && formik.touched.standard?.id && (
					<div className={`${classes.errorMessage} text-xs`}>
						{formik.errors.standard?.id}
					</div>
				)}
			</div>
			<div className='grid'>
				<Select
					className="bg-white"
					variant="outlined"
					label="Select Grade"
					fullWidth
					{...formik.getFieldProps("gradeId")}
					error={
						formik.touched.gradeId &&
						Boolean(formik.errors.gradeId)
					}
					inputProps={{ readOnly: mode !== MODE.ADD ? true : false }}
					options={
						categoryOptions.grades?.map((option: any) => ({
							value: (option.id),
							label: option.label,
						})) || []
					}
				/>
				{formik.touched.gradeId && formik.errors && formik.errors.gradeId && (
					<div className={classes.errorMessage}>
						<small>{formik.errors.gradeId}</small>
					</div>
				)}
			</div>
		</div>
	)
};

export default StandardGradeSearchTemplate;
