import React, { useEffect, useState } from 'react'
import ButtonV2 from '../../atoms/ButtonV2';
import { IPagination } from '../../../utils/types';
import { ADMIN_ROUTES, VENDOR_TYPE, initialPaginationValues } from '../../../utils/constant';
import { useSnackbar } from '../../../hooks/useSnackBar';
import activeCustomers from '../../../assets/images/activeCustomers.svg'
import inactiveCustomers from '../../../assets/images/inactiveCustomers.svg'
import totalCustomers from '../../../assets/images/totalCustomers.svg'
import newCustomers from '../../../assets/images/newCustomers.svg'
import { Dayjs } from 'dayjs';
import StatusCard from '../../atoms/Cards/StatusCard';
import { useNavigate } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import GenericDateRangePicker from '../../molecules/DatePicker/CustomizedDatePicker';
import { VendorListTemplate } from '../../templates/VendorManagement/VendorList.template';
import { ICustomerFilterRequest, useVendorServiceV2 } from '../../../services/useVendorServiceV2';
import TextFieldV2 from '../../atoms/TextFieldV2';
import FilterChip, { IMultiSelectOption } from '../../molecules/FilterChip/FilterChip';
import { InputAdornment } from '@mui/material';
import search from '../../../assets/images/search.svg'
import EmptyVendorListingTemplate from '../../templates/VendorManagement/EmptyVendorListing.template';

const useStyles = createUseStyles((theme: any) => ({
  container: {
    borderBottom: `1px solid ${theme.palette.border.tritiaryLight}`
  },
  text: {
    color: theme.palette.textV2.primary.primary900
  }
}));

export interface ICustomerTemplate {
  id: number;
  customerID: string;
  companyName: string;
  customerType: string;
  location: string;
  relationshipManager: {
    fullName: string;
    designation: string | null;
    department: string | null;
  }[] | [];
  createdBy: string;
  status: string;
  vendorType: string;
}

export interface ICustomerFilter {
  search: string;
  sort: string;
  startDate: string;
  endDate: string;
  customerType: string;
  vendorTypes: string[];
}

const initialCustomerFilterValues: ICustomerFilter = {
  search: "",
  sort: "createdAt,desc",
  startDate: '',
  endDate: '',
  customerType: '',
  vendorTypes: [],
}

interface ICustomerCounts {
  totalUsers: number,
  activeUsers: number,
  inActiveUsers: number,
  newUsers: number,
}

const VendorListingPage: React.FC = () => {

  const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);
  const [vendors, setVendorsTo] = useState<ICustomerTemplate[]>([]);
  const [vendorFilters, setVendorFiltersTo] = useState<ICustomerFilter>(initialCustomerFilterValues);
  const { showSnackbar, SnackBarComponent } = useSnackbar();
  const [selectedVendorTypes, setSelectedVendorTypes] = useState<IMultiSelectOption[]>([]);
  const [loading, setLoading] = useState(true); 

  const vendorService = useVendorServiceV2();
  const navigate = useNavigate();
  const classes = useStyles();

  const [customerCount, setCustomerCountTo] = useState<ICustomerCounts>({
    totalUsers: 0,
    activeUsers: 0,
    inActiveUsers: 0,
    newUsers: 0,
  });

  const vendorTypeOptions: IMultiSelectOption[] = Object.values(VENDOR_TYPE).map(type => ({
    label: type?.label,
    value: type?.value
  }));

  const handleVendorTypeChange = (selectedOptions: IMultiSelectOption[]) => {
    setSelectedVendorTypes(selectedOptions);
  };

  const handleApplyClick = (selectedVendorTypes: IMultiSelectOption[]) => {
    const selectedValue = selectedVendorTypes.length > 0 ? selectedVendorTypes.map((option) => option.value) : null;
    setVendorFiltersTo({ ...vendorFilters, vendorTypes: selectedValue as string[] ?? [] })
  };

  const handleClearClick = () => {
    setSelectedVendorTypes([]);
    setVendorFiltersTo({ ...vendorFilters, vendorTypes: [] })
  };

  const customerStatus = () => {
    const params = {
      startDate: vendorFilters?.startDate,
      endDate: vendorFilters?.endDate
    }
    vendorService.getVendorStats(params)
      .then((statusResponse) => {
        if (statusResponse?.data?.data) {
          setCustomerCountTo(statusResponse?.data?.data);
        } else {
          setCustomerCountTo(customerCount);
        }
      })
      .catch((error) => {
        setCustomerCountTo(customerCount);
        showSnackbar('error', 'Users fetch failed');
      })
      .finally(() => {
        setLoading(false); 
      });
  };

  const fetchProfile = () => {

    const params: ICustomerFilterRequest = {
      search: vendorFilters?.search.trim(),
      page: pagination.currentPage,
      size: pagination.pageSize,
      customerType: vendorFilters?.customerType,
      sort: vendorFilters?.sort,
      startDate: vendorFilters?.startDate,
      endDate: vendorFilters?.endDate,
      vendorTypes: (!vendorFilters.vendorTypes || vendorFilters.vendorTypes.length == 0) ? null : vendorFilters?.vendorTypes.join(',')
    }
    vendorService.getAllVendors(params)
      .then((vendorResponse) => {
        if (vendorResponse?.data?.data) {
          const { totalElements, totalPages, content } = vendorResponse?.data?.data;
          setPaginationTo({
            ...pagination,
            totalPages: totalPages,
            totalRecords: totalElements
          });
          setVendorsTo(content)
        }
        else {
          setVendorsTo([]);
        }
      })
      .catch((error) => {
        setVendorsTo([]);
        showSnackbar('error', 'Users fetch failed')
      });
  }

  useEffect(() => {
    fetchProfile()
  }, [pagination.currentPage, pagination.pageSize, vendorFilters])

  useEffect(() => {
    customerStatus()
  }, [vendorFilters.startDate, vendorFilters.endDate])


  const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPaginationTo((prevPagination) => ({
      ...prevPagination,
      currentPage: newPage
    }));
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setPaginationTo((prevPagination) => ({
      ...prevPagination,
      pageSize: newRowsPerPage
    }));
  };

  const handleFiltersChange = (event: any) => {
    const { name, value } = event.target;
    setPaginationTo({ ...pagination, currentPage: 0 })
    setVendorFiltersTo({ ...vendorFilters, [name]: value ?? "" });
  }

  const handleDataFilter = (startDate: Dayjs, endDate: Dayjs) => {
    setVendorFiltersTo((prevFilters: any) => ({
      ...prevFilters,
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    }));
  };

  const handleAddNewVendors = () => {
    navigate(ADMIN_ROUTES.VENDOR_MANAGEMENT_CREATE)
  }

  const customStats = [
    {
      icon: totalCustomers,
      title: "Total Vendors",
      value: customerCount.totalUsers ? customerCount.totalUsers : 0,
    },
    {
      icon: activeCustomers,
      title: "Active Vendors",
      value: customerCount.activeUsers ? customerCount.activeUsers : 0,
    },
    {
      icon: inactiveCustomers,
      title: "Inactive Vendors",
      value: customerCount.inActiveUsers ? customerCount.inActiveUsers : 0,
    },
    {
      icon: newCustomers,
      title: "New Vendors",
      value: customerCount.newUsers ? customerCount.newUsers : 0,
    },
  ];

  if (loading) {
    return null;
  }

  return (
    <>
      {customerCount.totalUsers === 0 ? (
        <EmptyVendorListingTemplate />
      ) : (
        <>
          <div className={`flex justify-between py-8 items-center ${classes.container}`}>
            <span className={`font-medium text-2xl	${classes.text}`}>Vendor Management</span>
            <ButtonV2
              label="+ Add New Vendor"
              variant='primaryContained'
              onClick={handleAddNewVendors}
            />
          </div>

          <div className='py-8'>
            <GenericDateRangePicker
              label=""
              onDataFilter={handleDataFilter}
            />
          </div>
          <div className='grid'>
            <StatusCard cards={customStats} />
          </div>
          <div className='flex justify-between items-center'>
            <div className={`!w-80 py-8`}>
              <TextFieldV2
                label=''
                variant="outlined"
                placeholder="Search.."
                name='search'
                value={vendorFilters.search}
                onChange={handleFiltersChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start" > <img src={search} alt="icon" /></InputAdornment>,
                }}
              />
            </div>
            <div className={`w-1/4`} >
              <FilterChip
                options={vendorTypeOptions}
                label="Vendor Type"
                value={selectedVendorTypes}
                onchange={handleVendorTypeChange}
                placeholder="Select Vendor Types"
                buttonLabel={"Clear all"}
                buttonOnClick={handleClearClick}
                SaveButton={ButtonV2}
                saveButtonLabel='Apply'
                saveButtonOnClick={() => handleApplyClick(selectedVendorTypes)}
                isSearchable={false}
              />
            </div>
          </div>
          <VendorListTemplate customerTemplate={vendors} pagination={pagination} handleFiltersChange={handleFiltersChange} handleRowsPerPageChange={handleRowsPerPageChange} handlePaginationChange={handlePaginationChange} customerFilter={vendorFilters} />
        </>
      )}

    </>
  )
}
export default VendorListingPage;