import React, { useEffect, useMemo, useState } from 'react';
import Images from './Images';
import { MODE } from '../../utils/constant';
import { useFileService } from '../../services/useFileService';

interface ImageUploaderProps {
    title?: string;
    mode: string;
    images: (ImageData | File)[];
    removeImage?: (id: number) => void;
    isSubmitting?: Boolean;
    imageIdsToDelete?: number[];
}
export interface ImageData {
	id?: number | null;
	location: string;
	path: string;
	resourceId?: number | null;
	resourceType: string;
	mimeType: string;
	createdAt: string;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ mode, title, images, removeImage, isSubmitting = false, imageIdsToDelete = undefined}) => {
    const fileService = useFileService();
    
    const RenderImages = useMemo(() => {
        switch (mode) {
            case MODE.VIEW:
                return (
                    <Images
                        title={title}
                        images={images}
                        isEditable={false}
                    />
                );
            case MODE.ADD:
                return (
                    <Images
                        title={title}
                        images={images}
                        isEditable={true}
                        removeImage={removeImage}
                    />
                );
            case MODE.UPDATE:
                return (
                        <Images
                            title={title}
                            images={images}
                            isEditable={true}
                            removeImage={removeImage}
                        />
                );
            default:
                return null;
        }
    }, [title, mode, images, removeImage]);

    useEffect(() => {
        if (imageIdsToDelete!==undefined && imageIdsToDelete.length) { 
            fileService.deleteImagesByIds(imageIdsToDelete.join(","));
        }
    }, [isSubmitting]);

    return (
        <div className="flex flex-col gap-3 mt-5">
            <div className="flex gap-9">
                {RenderImages}
            </div>
        </div>
    );
}
export default ImageUploader;
