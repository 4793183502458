import { useRef, FC } from 'react';
import uploadIcon from '../../../Assets/Icons/uploadIcon.svg';

interface UploadButtonProps {
  setFileArray?: React.Dispatch<React.SetStateAction<File[]>>;
  acceptFiles?: string;
  buttonText?: string;
  disabledUpload?: boolean;
  fileList?: File[];
  hideUploadButton?: boolean;
}

const UploadButton: FC<UploadButtonProps> = ({
  setFileArray,
  acceptFiles,
  buttonText = 'Upload Attachment (Optional)',
  disabledUpload = false,
  fileList = [],
  hideUploadButton,
}) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && setFileArray) {
      const newFiles = Array.from(selectedFiles).filter(
        (file) =>
          !fileList.some((existingFile) => existingFile.name === file.name)
      );
      setFileArray((prevFiles) => [...prevFiles, ...newFiles]);
      event.target.value = '';
    }
  };

  const handleClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  return (
    <div className="flex flex-col gap-3 bg-white mt-4">
      {!hideUploadButton && (
        <>
          <input
            type="file"
            id="file-upload"
            multiple
            accept={acceptFiles}
            style={{ display: 'none' }}
            onChange={handleFileSelect}
            ref={hiddenFileInput}
            disabled={disabledUpload}
          />
          <div
            className={`border-solid border-blue flex flex-row justify-center w-full gap-5 border rounded items-center p-3 ${
              disabledUpload ? '' : 'cursor-pointer'
            }`}
            onClick={handleClick}
          >
            <img src={uploadIcon} alt="Upload" className="w-6" />
            <div className="font-semibold text-text-color">{buttonText}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default UploadButton;
