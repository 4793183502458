import React from "react";
import { theme } from "../../../utils/theme";
import { RESOURCE_STATUS } from "../../../utils/constant";

interface ResourceStatusProps {
    status: string;
    colourMap?: { [key: string]: string }
}

const STATUS_COLOR = {
    [RESOURCE_STATUS.ACTIVE]: theme.palette.resourceStatus.primaryLight,
    [RESOURCE_STATUS.PENDING]: theme.palette.textV2.warning.warning800,
    [RESOURCE_STATUS.INACTIVE]: theme.palette.resourceStatus.primaryDark,
    [RESOURCE_STATUS.REJECTED]:  theme.palette.textV2.primary.primary500,
    [RESOURCE_STATUS.BLACK_LISTED]:  theme.palette.textV2.tertiary.tertiary700,
    [RESOURCE_STATUS.CLOSED_WIN]: theme.palette.resourceStatus.primaryLight,
    [RESOURCE_STATUS.DRAFT]: theme.palette.textV2.warning.warning800,
    [RESOURCE_STATUS.IN_PROGRESS]: theme.palette.resourceStatus.primaryDark,
    [RESOURCE_STATUS.REVISED]:  theme.palette.textV2.primary.primary500,
    [RESOURCE_STATUS.SENT]:  theme.palette.textV2.tertiary.tertiary700,
    [RESOURCE_STATUS.CLOSED_LOST]: theme.palette.resourceStatus.primaryLight,
};

const STATUS_BACKGROUND_COLOR = {
    [RESOURCE_STATUS.ACTIVE]: theme.palette.textV2.success.success100,
    [RESOURCE_STATUS.PENDING]: theme.palette.textV2.warning.warning100,
    [RESOURCE_STATUS.INACTIVE]: theme.palette.textV2.failure.failure50,
    [RESOURCE_STATUS.REJECTED]: theme.palette.textV2.primary.primary50,
    [RESOURCE_STATUS.BLACK_LISTED]:  theme.palette.backgroundV2.tertiary.tertiary50,
    [RESOURCE_STATUS.CLOSED_WIN]: theme.palette.textV2.success.success100,
    [RESOURCE_STATUS.DRAFT]: theme.palette.textV2.success.success100,
    [RESOURCE_STATUS.IN_PROGRESS]: theme.palette.textV2.warning.warning100,
    [RESOURCE_STATUS.REVISED]: theme.palette.textV2.failure.failure50,
    [RESOURCE_STATUS.SENT]: theme.palette.textV2.primary.primary50,
    [RESOURCE_STATUS.CLOSED_LOST]: theme.palette.textV2.success.success100,
};

const ResourceStatusV2: React.FC<ResourceStatusProps> = ({ status, colourMap }) => {
    const getStatusColor = (status: string) => {
        return colourMap ? colourMap[status] : STATUS_COLOR[status];
    };

    const getBackgroundColor = (status: string) => {
        return colourMap ? colourMap[status] : STATUS_BACKGROUND_COLOR[status];
    };

    return (
        <span 
            style={{ 
                color: `${getStatusColor(status)}`, 
                backgroundColor: `${getBackgroundColor(status)}`, 
                borderRadius: '9999px', 
                padding: '6px 12px' 
            }}
        >
            {status}
        </span>
    );
}

export default ResourceStatusV2;
