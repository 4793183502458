import React from 'react';
import { INDENT_TEXT } from '../../../Constants/Procurement/Indent/constants';
import { Button } from '@mui/material';
import { createUseStyles } from 'react-jss';

interface ListPaginationButtonProps {
  pageNumber: number;
  totalRecords: number;
  totalPages: number;
  PaginationText: string;
  handlePagination: (type: 'prev' | 'next' | 'search') => void;
  setSearchPage: (pageNumber: number) => void;
  searchPage: number;
}

const useStyles = createUseStyles({
  paginationShadow: {
    boxShadow: '0px 0px 10px 0px #0000001a',
  },
});

const ListPaginationButton: React.FC<ListPaginationButtonProps> = ({
  pageNumber,
  totalRecords,
  searchPage,
  totalPages,
  PaginationText,
  setSearchPage,
  handlePagination,
}) => {
  const classes = useStyles();

  return (
    <div
      className={`flex justify-between items-center rounded-lg  ${classes.paginationShadow} w-full h-[60px] p-4`}
    >
      <div className="w-full"></div>
      <div className=" flex justify-between items-center bg-white w-full">
        <div className=" justify-center items-center gap-4 inline-flex  text-base">
          <div className="justify-start items-center gap-1.5 flex">
            <div
              className={`w-8 h-8 p-2.5 bg-slate-100 rounded justify-center items-center  flex  ${
                pageNumber !== 0 ? 'cursor-pointer' : ''
              } `}
              onClick={
                pageNumber !== 0 ? () => handlePagination('prev') : undefined
              }
            >
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`${
                  pageNumber !== 0 ? 'text-blue' : 'text-cgray-11'
                }`}
              >
                <path
                  fillRule="evenodd"
                  d="M6.49656 0.710754C6.88656 1.10075 6.88656 1.73075 6.49656 2.12075L2.61656 6.00075L6.49656 9.88075C6.88656 10.2708 6.88656 10.9008 6.49656 11.2908C6.10656 11.6808 5.47656 11.6808 5.08656 11.2908L0.496562 6.70075C0.106563 6.31075 0.106563 5.68075 0.496562 5.29075L5.08656 0.700754C5.46656 0.320754 6.10656 0.320754 6.49656 0.710754Z"
                  fill={pageNumber !== 0 ? '#1D3679' : '#a9acc4'}
                />
              </svg>
            </div>
            <div className="w-11 px-3 py-2 bg-white  justify-center items-center gap-1 flex ">
              <div className="text-black text-base font-bold">
                {totalRecords > 0
                  ? pageNumber < 10
                    ? '0' + (pageNumber + 1)
                    : pageNumber
                  : 0}
              </div>
            </div>
            <div
              className={`w-8 h-8 p-2.5 bg-slate-100 rounded justify-center items-center  flex ${
                pageNumber < totalPages - 1 ? 'cursor-pointer' : ''
              } `}
              onClick={
                pageNumber < totalPages - 1
                  ? () => handlePagination('next')
                  : undefined
              }
            >
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M0.503437 0.710754C0.113438 1.10075 0.113438 1.73075 0.503437 2.12075L4.38344 6.00075L0.503437 9.88075C0.113438 10.2708 0.113438 10.9008 0.503437 11.2908C0.893438 11.6808 1.52344 11.6808 1.91344 11.2908L6.50344 6.70075C6.89344 6.31075 6.89344 5.68075 6.50344 5.29075L1.91344 0.700754C1.53344 0.320754 0.893438 0.320754 0.503437 0.710754Z"
                  fill={pageNumber < totalPages - 1 ? '#1D3679' : '#a9acc4'}
                />
              </svg>
            </div>
          </div>
          <div className="text-blue-900 text-base font-bold text-blue">
            <span>{PaginationText}</span>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center gap-4">
        <div className="flex justify-center items-center gap-2">
          <div>Page</div>
          <div className="">
            <input
              className=" p-2 rounded-[4px] border border-solid border-gray-300 w-[51px]"
              value={searchPage + 1}
              onChange={(e: any) => {
                setSearchPage(parseInt(e.target.value) - 1);
              }}
              type="number"
            />
          </div>
        </div>
        <Button
          className={`px-4 py-5 bg-blue text-white rounded justify-center items-center flex w-full h-[36px] font-bold text-sm ${
            searchPage > 0 && searchPage < totalPages - 1
              ? 'hover:bg-none '
              : 'cursor-pointer bg-blue hover:bg-blue'
          } `}
          disabled={searchPage < 0 || searchPage > totalPages - 1}
          onClick={() => {
            handlePagination('search');
          }}
        >
          <div className="text-white normal-case">
            {INDENT_TEXT.indentListSearchPageText}
          </div>
        </Button>
      </div>
    </div>
  );
};

export default ListPaginationButton;
