import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "../../../hooks/useSnackBar";
import { HTTP_STATUS } from "../../../utils/types";
import { ADMIN_ROUTES, MODE } from "../../../utils/constant";
import { useEffect, useState } from "react";
import { useUpcService } from "../../../services/useUpcService";
import UpcFormTemplate from "../../templates/UPC/UpcForm.template";
import { Category } from "../../../services/useCategoryService";
import { IStandard } from "../Standard/StandardList.page";
import { IManufacturer } from "../AdminMaster/Manufacturer/ManufacturerList.page";
import { IBrand } from "../AdminMaster/Brand/BrandList.page";

export interface IUpc {
    id: number;
    upcCode: string
    productId: number;
    classType: string;
    shape: string;
    status: string;
    attributes: any;
    product: IProduct;
    brandIds: number[] | [];
}

export interface IProduct {
    id: number;
    categoryId: number;
    productCategory: Category;
    productCode: string;
    brand: IBrand;
    keywords: string;
    gradeId: number;
    title: string;
    standard:  IStandard;
    manufacturer: IManufacturer;
    mainCategory: Category;
    superCategory: Category;
}

const UpcUpdatePage: React.FC = () => {

    const params = useParams();
    const navigate = useNavigate();
    const upcService = useUpcService();

    const upcId = Number(params.id);
    const [upc, setUpcTo] = useState<IUpc | null>(null);
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const updateUpc = async (upcUpdateRequestBody: any) => {
        if (upcId) {
            try {
                const upcResponse = await upcService.updateProductUPC(upcId, upcUpdateRequestBody);
                if(upcResponse.status === HTTP_STATUS.OK ){
                    navigate(-1);
                }
            } catch (error) {
                showSnackbar("error", `UPC updation failed`)
            }
        }
    }

    const fetchUpc = async () =>{
        if(upcId){
            try {
                const upcResponse = await upcService.getProductUPC(upcId);
                if(upcResponse?.status === HTTP_STATUS.OK){
                    setUpcTo(upcResponse?.data?.data);
                }
                else {
                    throw new Error("UPC fetch failed");
                }
            } catch (error) {
                showSnackbar("error", `UPC fetch failed`) 
                setUpcTo(null);
            }
        }
    }

    useEffect(() => {
        fetchUpc()
    }, [upcId]);

    return (
        <div>
            { SnackBarComponent }
            <UpcFormTemplate upc={ upc }  onUpcSubmit={ updateUpc } mode={ MODE.EDIT}/>
        </div>
    )
}

export default UpcUpdatePage;