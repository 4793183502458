import React, { useEffect, useState } from 'react'
import { ISecondaryCatalogue, ISecondaryCatalogueFilter } from '../../pages/SecondaryCatalogue/SecondaryCatalogueList.page';
import { IPagination } from '../../../utils/types';
import { useNavigate } from 'react-router-dom';
import { ADMIN_ROUTES, CATALOGUE_TYPES, secondaryCatalogueSortOptions, statusOptions } from '../../../utils/constant';
import Table, { ColumnType } from '../../organisms/Table';
import moment from 'moment';
import CustomHeading from '../../atoms/CustomHeading/CustomHeading';
import Button from '../../atoms/Button';
import Select from '../../atoms/Select';
import FiltersIndex, { FILTERS_TYPE } from '../../../App/Pages/Catalogue/Filters/FiltersIndex';
import TextField from '../../atoms/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { createUseStyles } from 'react-jss';
import { useSecondaryInventoryService } from '../../../services/useSecondaryInventoryService';
import { getKeyByValue, makeRoute } from '../../../utils/helper';

interface SecondaryCatalogueList {
  catalogueType: string;
  secondaryCatalogues: ISecondaryCatalogue[];
  pagination: IPagination;
  handlePaginationChange: any;
  secondaryCatalogueFilter: ISecondaryCatalogueFilter;
  handleFiltersChange: (event: any) => void;
  handleDeleteClick: (id: number) => (e: any) => void
  handleRowsPerPageChange: any;
  selectedIds: any;
  handleApplyFiltersCatalogue: any;
}

const useStyles = createUseStyles((theme: any) => ({
  viewIconContainer: {
    color: theme.palette.icon.primaryLight,
    fontSize: 'medium',
  },
  editIconContainer: {
    color: theme.palette.icon.secondryLight,
    fontSize: 'medium',
  },
  updateIconContainer: {
    color: theme.palette.icon.primaryDark,
    fontSize: 'medium',
  },
}));

const SecondaryCatalogueListTemplate: React.FC<SecondaryCatalogueList> = ({catalogueType, secondaryCatalogues, pagination, handlePaginationChange, secondaryCatalogueFilter, handleRowsPerPageChange, handleFiltersChange, selectedIds, handleApplyFiltersCatalogue, handleDeleteClick }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const secondaaryInventoryService = useSecondaryInventoryService();
  const [productCategories, setProductCategoriesTo] = useState<any[]>([]);

  let productCategoryFilterValues: { value: any, label: string }[] = [{ value: "ALL", label: "All" }];
  productCategories.forEach((productCategory: any) => {
    productCategoryFilterValues.push({ value: productCategory.id, label: productCategory.name });
  });

  const handleAddClick = () => {
    navigate(makeRoute(ADMIN_ROUTES.SECONDARY_CATALOGUE_CREATE,{query:{"catalogueType":getKeyByValue(CATALOGUE_TYPES,catalogueType)}}));
  };

  const handleViewClick = (id: number) => (e: any) => {
    navigate(makeRoute(ADMIN_ROUTES.SECONDARY_CATALOGUE_VIEW, {params:  { id: id } }));
  };

  const handleEditClick = (id: number) => (e: any) => {
    navigate(makeRoute(ADMIN_ROUTES.SECONDARY_CATALOGUE_UPDATE, {params:  { id: id } }));
  };

  const secondaryCatalogueTableSchema = {
    id: "1",
    title: "",
    pagination: {
      total: pagination.totalRecords,
      currentPage: pagination.currentPage,
      isVisible: true,
      limit: pagination.pageSize,
      handleChangePage: handlePaginationChange,
      handleChangeRowsPerPage: handleRowsPerPageChange
    },
    columns: [
      { label: "Sr No.", key: "id", type: "number" as ColumnType, props: {} },
      { label: "Catalogue Name", key: "catalogueName", type: "string" as ColumnType, props: {} },
      { label: "Created Date", key: "createdAt", type: "datetime" as ColumnType, props: {} },
      { label: "Last Modified", key: "updatedAt", type: "datetime" as ColumnType, props: {} },
      { label: "Status", key: "status", type: "string" as ColumnType, props: {} },
      { label: "Action", key: "action", type: "custom" as ColumnType, props: {} },
    ],
  };

  const getAllCategories = () => {
    let queryParams = {
      name: '',
      level: 2,
      parentId: null,
      page: 0,
      size: 100
    };
    secondaaryInventoryService.getCategories(queryParams)
      .then((response) => {
        if (response.data) {
          setProductCategoriesTo(response?.data?.data?.content);
        }
        else {
          setProductCategoriesTo([]);
        }
      })
      .catch((error) => {
        console.error("product fetch failed - ", error);
        setProductCategoriesTo([]);
      });
  };

  useEffect(() => {
    getAllCategories();
  }, [])

  const Action = (id: number) => {
    return <div className="flex gap-x-1 justify-end ">
      <RemoveRedEyeIcon className={`${classes.viewIconContainer} text-secondary cursor-pointer`} onClick={handleViewClick(id)} />
      <ModeEditOutlineIcon className={`${classes.editIconContainer} text-secondary cursor-pointer`} onClick={handleEditClick(id)} />
      <DeleteIcon className={`${classes.updateIconContainer} text-secondary cursor-pointer`} onClick={handleDeleteClick(id)} />
    </div>
  }

  const records = secondaryCatalogues.map((secondaryCatalogue: ISecondaryCatalogue, index: number) => [
    index + 1,
    secondaryCatalogue.catalogueName,
    moment(secondaryCatalogue.createdAt).format("YYYY-MM-DD HH:mm:ss"),
    moment(secondaryCatalogue.updatedAt).format("YYYY-MM-DD HH:mm:ss"),
    secondaryCatalogue.status,
    Action(secondaryCatalogue.id),
  ]);
  return (
    <div className="grid gap-y-3">
      <div className="flex justify-between">
        <CustomHeading text={"Secondary"} status="" />
        <Button variant="contained" label="Add Secondary Catalogue" onClick={handleAddClick} />
      </div>
      <div className='grid gap-y-1.5'>
        <div className="grid grid-flow-col justify-stretch  gap-2">
          <Select
            variant="outlined"
            label="Status"
            value={secondaryCatalogueFilter.status || ""}
            onChange={handleFiltersChange}
            name="status"
            id="status"
            options={statusOptions}
          />
          <Select
            variant="outlined"
            id="sort"
            label="Sort"
            name="sort"
            value={secondaryCatalogueFilter.sort}
            onChange={(e) => handleFiltersChange(e)}
            options={secondaryCatalogueSortOptions}
          />
          <Select
            variant="outlined"
            label="Product Category"
            value={secondaryCatalogueFilter.productCategory || ""}
            onChange={handleFiltersChange}
            name="productCategory"
            id="productCategory"
            options={productCategoryFilterValues}
          />
          <TextField
            type="text"
            label="Search By Name"
            variant="outlined"
            name="catalogueName"
            value={secondaryCatalogueFilter.catalogueName}
            onChange={handleFiltersChange}
          />
          <FiltersIndex
            filterType={FILTERS_TYPE.INVENTORY}
            updateFilters={handleApplyFiltersCatalogue}
            filtersData={selectedIds}
          />
        </div>
        <Table schema={secondaryCatalogueTableSchema} records={records} />
      </div>
    </div>
  )
}

export default SecondaryCatalogueListTemplate