import React, { useEffect, useState } from 'react';
import { HTTP_STATUS, IPagination } from '../../../utils/types';
import TableV2 from '../../organisms/TableV2';
import { ColumnType } from '../../organisms/Table';
import ButtonV2 from '../../atoms/ButtonV2';
import { IBrandUpcs } from './MasterbrandListModal.Template';
import AddMasterBrandModalTemplate from './AddMasterBrandModal.Template';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteDialogBox from '../../atoms/DeleteDialogBox';
import { IUpdateBrandMasterUpcCreateRequest, useMasterUpcService } from '../../../services/useMasterUpcService';
import FilterChipV2, { IMultiSelectOption } from '../../molecules/FilterChip/FilterChipV2';
import { IBrand } from '../Customer-Management/MyMaterials/MaterialBrandManufacturer.template';
import { useBrandService } from '../../../services/useBrandService';
import deleteBinIcon2 from '../../../assets/icons/deleteBinIcon2.svg';


interface IBrandUpcTemplateProps {
    brandUpcs: IBrandUpcs[];
    pagination: IPagination;
    handleFiltersChange: (name: string, value: any) => void;
    handlePaginationChange: any;
    setRequestBodyValuesTo: any;
    handleRowsPerPageChange: any;
    selectedMasterUpcId: number;
    selectedMasterUpcCode: string;
    selectedMasterUpcTitle: string;
    refreshBrands: (page: number, size: number) => void;
}

const MasterBrandListTemplate: React.FC<IBrandUpcTemplateProps> = ({ brandUpcs, pagination, handlePaginationChange, handleRowsPerPageChange, selectedMasterUpcId, selectedMasterUpcTitle, selectedMasterUpcCode, refreshBrands, setRequestBodyValuesTo, handleFiltersChange }) => {
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [selectedBrandId, setSelectedBrandId] = useState<number>(0);
    const masterUpcService = useMasterUpcService();
    const [brandData, setBrandData] = useState<IBrand[] | null>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedValues, setSelectedValues] = useState<IMultiSelectOption[]>([]);
    const brandService = useBrandService();


    const loadBrands = async (inputValue?: string) => {
        try {
            const response = await brandService.getAllBrandsByName({
                page: 0,
                size: 10,
                sort: 'createdAt,desc',
                name: inputValue ?? ''
            });
    
            if (response.status === HTTP_STATUS.OK) {
                setBrandData(response?.data?.data?.content);
            } else {
                console.error(`Unexpected status code: ${response.status}`);
            }
        } catch (error) {
            console.error("Error Fetching Brands: ", error);
        }
    }
    


    const openAddBrandDialog = () => {
        setDialogOpen(true);
    }

    const handleDeleteBrand = (id: number) => {
        setSelectedBrandId(id);
        setDeleteDialogOpen(true);
    }

    const handleSelectionChange = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setSelectedValues(selected);
        clearWithCrossIcon && handleApplyClick(selected);
    };

    const handleOnDelete = () => {
        if (selectedBrandId > 0) {
            const params: IUpdateBrandMasterUpcCreateRequest = {
                masterUpcId: selectedMasterUpcId
            };
            masterUpcService.deleteBrandFromMasterUpc(selectedBrandId, params)
                .then((upcResponse) => {
                    if (upcResponse.data.data) {
                        refreshBrands(0, 10);
                        setDeleteDialogOpen(false);
                    } else {
                        console.error("Error while deleting Brand")
                    }
                })
                .catch((error) => {
                    console.error("Brands Deleting error - ", error);
                });
        } else {
            console.error("Invalid selected option");
        }
    }


    const schema = {
        id: "1",
        title: "",
        pagination: {
            total: pagination.totalRecords,
            currentPage: pagination.currentPage,
            isVisible: true,
            limit: pagination.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange,
        },
        columns: [
            { label: "S No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "Brand UPC", key: "upcCode", type: "string" as ColumnType, props: { className: '' } },
            { label: "Brand", key: "brandName", type: "string" as ColumnType, props: { className: '' } },
            { label: "Current UPC Base Price", key: "brandUpcPrice", type: "string" as ColumnType, props: { className: '' } },
            { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } },
        ],
    };

    const Action = (id: number) => {
        return (
            <div className={`flex gap-x-2 justify-center`}>
                <ButtonV2 variant="tertiaryText"
                    iconButton={<img src={deleteBinIcon2} alt="Delete Icon" />}
                    label="Remove"
                    buttonWithImg={true}
                    size="small"
                    onClick={(e) => handleDeleteBrand(id)}
                />
            </div>
        );
    };

    const records = brandUpcs.map((brandUpc, index) => [
        pagination.currentPage * pagination.pageSize + index + 1,
        brandUpc.upcCode,
        brandUpc.brandName,
        brandUpc.brandUpcPrice ?? "-",
        Action(brandUpc.id),
    ]);

    const handleClearClick = () => {
        setSelectedValues([]);
    };

    const handleApplyClick = (selectedValues : IMultiSelectOption[]) => {
        const selectedValuesOnly = selectedValues.map(option => option.value);
        setRequestBodyValuesTo({ upcIds: [], brandIds: selectedValuesOnly });
    };

    useEffect(() => {
        loadBrands(searchTerm)
    }, [searchTerm]);


    return (
        <div>
            <div className='flex justify-between items-center mb-6'>
                <div className='flex'>
                    <FilterChipV2
                        options={brandData ? brandData.map(brand => ({ label: brand.name, value: brand.id })) : []}
                        label=" "
                        value={selectedValues}
                        onchange={handleSelectionChange}
                        placeholder="Brand"
                        ClearButtonComponent={ButtonV2}
                        textFieldPlaceholder='Search...'
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        buttonLabel={"Clear all"}
                        buttonOnClick={handleClearClick}
                        SaveButton={ButtonV2}
                        saveButtonLabel='Apply'
                        saveButtonOnClick={() => handleApplyClick(selectedValues)}
                    />
                </div>
                <div className='flex'>
                    <ButtonV2
                        variant="primaryContained"
                        onClick={openAddBrandDialog}
                        label={"+Add Another Brand"}
                    />
                </div>
            </div>
            <TableV2 schema={schema} records={records} />
            <AddMasterBrandModalTemplate dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} selectedMasterUpcId={selectedMasterUpcId} selectedMasterUpcTitle={selectedMasterUpcTitle} selectedMasterUpcCode={selectedMasterUpcCode} brandUpcs={brandUpcs} refreshBrands={refreshBrands} masterUpcId={selectedMasterUpcId}/>
            <DeleteDialogBox deleteDialogOpen={deleteDialogOpen} setDeleteDialogOpen={setDeleteDialogOpen} textInput='Brand' onSubmitYes={handleOnDelete} />
        </div>
    )
}
export default MasterBrandListTemplate;
