import React from "react";
import { useNavigate } from "react-router-dom";
import { createUseStyles } from "react-jss";
import moment from "moment";
import Select from "../../atoms/Select";
import TextField from "../../atoms/TextField";
import Table, { ColumnType } from "../../organisms/Table";
import { IOrder, IOrderMasterFilter } from "../../pages/Order/OrderMasterList.page";
import { ADMIN_ROUTES, ORDER_STATUS } from "../../../utils/constant";
import { IPagination } from "../../../utils/types";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ResourceStatus from "../../atoms/ResourceStatus/ResourceStatus";
import { enumToString } from "../../../utils/helper";

interface OrderMasterListTemplateProps {
	data: IOrder[];
	pagination: IPagination;
	handlePaginationChange: any;
	handleRowsPerPageChange: any;
	handleFiltersChange?: any;
	orderMasterFilters: IOrderMasterFilter;
	setStatusUpdate: any;
	statusUpdate: any;
	handleUpdateQuotation: any;
	handleSubmit: () => void;
}

const useStyles = createUseStyles((theme: any) => ({
	viewIconContainer: {
		color: theme.palette.icon.primaryLight,
	},
	editIconContainer: {
		color: theme.palette.icon.secondryLight,
	},
	updateIconContainer: {
		color: theme.palette.icon.primaryDark,
	},
}));

const OrderMasterListTemplate: React.FC<OrderMasterListTemplateProps> = ({
	data,
	pagination,
	handlePaginationChange,
	handleRowsPerPageChange,
	orderMasterFilters,
	handleFiltersChange,
	setStatusUpdate,
	statusUpdate,
	handleUpdateQuotation,
	handleSubmit,
}) => {
	const classes = useStyles();
	const schema = {
		id: "1",
		title: "",
		pagination: {
			total: pagination.totalRecords,
			currentPage: pagination.currentPage,
			isVisible: true,
			limit: pagination.pageSize,
			handleChangePage: handlePaginationChange,
			handleChangeRowsPerPage: handleRowsPerPageChange,
		},
		columns: [
			{ label: "Sr No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
			{ label: "Order ID", key: "id", type: "number" as ColumnType, props: { className: '' } },
			{ label: "Quotation ID", key: "quotationId", type: "string" as ColumnType, props: { className: '' } },
			{ label: "Customer Name", key: "customerName", type: "string" as ColumnType, props: { className: '' } },
			{ label: "Company Name", key: "companyName", type: "string" as ColumnType, props: { className: '' } },
			{ label: "Order Status", key: "orderStatus", type: "string" as ColumnType, props: { className: '' } },
			{ label: "Total Price", key: "orderTotal", type: "number" as ColumnType, props: { className: '' } },
			{ label: "Payment Status", key: "paymentStatus", type: "number" as ColumnType, props: { className: '' } },
			{ label: "Created Date", key: "createdAt", type: "datetime" as ColumnType, props: { className: '' } },
			{ label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } },
		],
	};

	const statusInput = (id: number, status: string) => {
		return (
			<>
				{statusUpdate.id === id ? (
					<select
						className="w-28"
						name="orderStatus"
						value={ statusUpdate.orderStatus }
						onChange={(event) => handleStatusUpdateChange(event)}
					>
						<option value="" disabled>Select Status</option>
						<option value="ONGOING">ONGOING</option>
						<option value="COMPLETED">COMPLETED</option>
						<option value="CANCELLED">CANCELLED</option>
					</select>
				) : (
					<div>
						<ResourceStatus status={enumToString(status)} />
					</div>
				)}
			</>
		);
	};

	const paymentStatusInput = (id: number, paymentStatus: string) => {
		return (
			<>
				{statusUpdate.id === id ? (
					<select
						className="w-28"
						name="paymentStatus"
						value={ statusUpdate.paymentStatus }
						onChange={(event) => handleStatusUpdateChange(event)}
					>
						<option value="" disabled>Select Publish Status</option>
						<option value="COMPLETED">COMPLETED</option>
						<option value="PENDING">PENDING</option>
					</select>
				) : (
					<div>
						<ResourceStatus status={enumToString(paymentStatus)} />
					</div>
				)}
			</>
		);
	};

	const Action = (id: number) => {
		return (
			<div className="flex gap-x-2 justify-end">
				{statusUpdate.id === id ? (
					<>
						<DoneIcon className={ `${ classes.updateIconContainer } text-secondary cursor-pointer` } onClick={() => handleSubmit()} />
						<CloseIcon className={ `${ classes.updateIconContainer } text-secondary cursor-pointer` } onClick={() => {
							setStatusUpdate({
								id: null,
								orderStatus: "",
								paymentStatus: "",
							});
						}}
						/>
					</>
				) : (
					<>
						<RemoveRedEyeIcon className={ `${ classes.viewIconContainer } text-secondary cursor-pointer` } onClick={ (e) => handleViewClick(id) } />
						<ModeEditOutlineIcon className={ `${ classes.editIconContainer } text-secondary cursor-pointer` } onClick={ (e) => handleUpdateQuotation(id) } />
					</>
				)}
			</div>
		);
	};

	const handleStatusUpdateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = event.target;
		if (name === "orderStatus") {
			setStatusUpdate({ ...statusUpdate, orderStatus: value });
		} else if (name === "paymentStatus") {
			setStatusUpdate({ ...statusUpdate, paymentStatus: value });
		}
	};

	const records = data.map((order: IOrder, index: number) => [
		pagination.currentPage * pagination.pageSize + index + 1,
		order.id,
		order.quotationId,
		order.customerName,
		order.companyName,
		statusInput(order.id, order.orderStatus),
		order.orderTotal.toFixed(2),
		paymentStatusInput(order.id, order.paymentStatus),
		moment(order.createdAt).format("YYYY-MM-DD HH:mm:ss"),
		Action(order.id),
	]);

	const navigate = useNavigate();

	const handleViewClick = (orderId: number) => {
		navigate(`${ ADMIN_ROUTES.ORDER_LIST }/${ orderId }`);
	};

	return (
		<div className="grid gap-y-1.5">
			<div className="flex justify-between ">
				<Select
				    variant="outlined"
					className="w-44 h-full"
					label="Order Status"
					value={ orderMasterFilters.status }
					onChange={(event) => handleFiltersChange(event)}
					name="status"
					id="status"
					options={[
						{ value: ORDER_STATUS.ALL, label: ORDER_STATUS.ALL },
						{ value: ORDER_STATUS.ONGOING, label: ORDER_STATUS.ONGOING },
						{ value: ORDER_STATUS.COMPLETED, label: ORDER_STATUS.COMPLETED },
						{ value: ORDER_STATUS.CANCELLED, label: ORDER_STATUS.CANCELLED },
					]}
				/>
				<TextField
					className="w-72"
					label="Search By Customer Name..."
					variant="outlined"
					type="text"
					name="customerName"
					value={ orderMasterFilters.customerName }
					onChange={ handleFiltersChange }
				/>
			</div>
			<Table schema={ schema } records={ records } />
		</div>
	);
};

export default OrderMasterListTemplate;
