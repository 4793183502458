import { PROCUREMENT_TEXT } from "../../../Constants/Procurement/PurchaseRequisition/constants";
import VariantButton from "../../../Shared/Button/VariantButton";

interface Props {
  isLastStep?: boolean;
  onSubmitClick?: () => void;
  onBackClick: () => void;
}

const FormNavigationButton = (props: Props) => {
  return (
    <div className="flex items-center justify-end w-full gap-x-4">
      <VariantButton
        variant="outlined"
        className="text-text-color"
        size="large"
        onClick={props.onBackClick}
      >
        {PROCUREMENT_TEXT.back}
      </VariantButton>
      <VariantButton size="large" type="submit" onClick={props.onSubmitClick}>
        {props.isLastStep ? "Create PRID" : "Next"}
      </VariantButton>
    </div>
  );
};

export default FormNavigationButton;
