import Button from '@mui/material/Button';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import ProductAttributesEnum from '../ProductAttributesEnum';
import { convertToReadableFormat } from '../../AdminMaster/MPCTEMP/PreviewMPC_refactor/PreviewMPC_refactor';
import { showAttributesUom } from '../../../../utils/helper';
const DisplayUpcItem = ({ type, index, updateSelection, upcDetails, isCheckBoxView = false }) => {

    const [selectedUpcDetails, setSelectedUpcDetails] = useState(upcDetails);
    const [isChecked, setIsChecked] = useState(false);

    const AttributeData = ({ type, value }) => {
        return (
            <div className="flex flex-col items-start justify-start gap-[6px]">
                <div className="relative font-medium">{type}</div>
                <div className="relative ">{value}</div>
            </div>
        );
    };
    const displayTitleName = () => {
        if (type === "STANDARD") {
            return "Universal Product Code"
        } else {
            return "Master Product Code"
        }
    }
    const ProductPropertiesAttributes = ({ propertiesAttributes }) => {
        try {
            const jsonArray = JSON.parse(propertiesAttributes);

            return (

                <Box className="product-properties-attributes">
                    {jsonArray.map((jsonObject, index) => (
                        <div key={index} className="json-tag-display">
                            {Object.entries(jsonObject).map(([key, value]) => (
                                <Chip
                                    key={key}
                                    label={`${key}: ${value}`}
                                    sx={{
                                        margin: '4px',
                                        wordBreak: 'break-word',
                                    }}
                                />
                            ))}
                        </div>
                    ))}
                </Box>

            );
        } catch (error) {
            console.error('Error parsing JSON:', error);
            return <></>;
        }
    };

    const handleUpcSelection = (index) => {
        setIsChecked(prev => !prev)
        updateSelection(index)

    };


    const DisplayProductSpecificationDetails = ({ productSpecificationDetails }) => {
        try {
            const attributes = JSON.parse(productSpecificationDetails);

            if (attributes?.SPECIFICATION) {
                return attributes.SPECIFICATION.map((specificationItem, index) => (

                    <div key={index} className="grid gap-y-1.5">

                        <div className="m-auto">{specificationItem.name}</div>
                        <div className="mx-4 px-1.5 rounded-full border border-input-border">
                        {showAttributesUom(specificationItem.attributeOptions, specificationItem.attributeOptions , specificationItem.uom )}
                        </div>
                    </div>
                ));
            } else {
                return <div>No specification data available.</div>;
            }
        } catch (error) {
            console.error('Error parsing attributes:', error);
            return <div>No Specification Details</div>;
        }
    };




    return (
        <>

            {selectedUpcDetails && Object.entries(selectedUpcDetails).length > 0 && (
                <div className="self-stretch flex flex-col items-start justify-start text-lg text-black">
                    <div className="self-stretch bg-white flex flex-col items-end justify-start gap-[16px]">

                        <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
                            <div className="self-stretch bg-whitesmoke flex flex-col p-6 box-border items-start justify-start gap-[18px] min-w-[498px]">

                                {/* Upc Code  */}
                                <div className="relative leading-[20px] font-semibold" key={index}>

                                    <FormControlLabel
                                        control={
                                            isCheckBoxView ? (
                                                <Checkbox
                                                    checked={isChecked}
                                                    onChange={() => handleUpcSelection(index)}
                                                    className="mr-5"
                                                />
                                            ) : (<div className='mr-3'></div>)
                                        }
                                        label={
                                            <Typography variant="body1" fontWeight="bold">
                                                {displayTitleName()} - {upcDetails.upcCode}
                                            </Typography>
                                        }
                                    />

                                </div>

                                {/* Category  */}
                                <div className="relative leading-[20px] font-semibold">
                                    Category
                                </div>
                                <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[24px] text-base text-text-color">
                                    <AttributeData type={ProductAttributesEnum.superCategory} value={selectedUpcDetails.superCategory} />
                                    <AttributeData type={ProductAttributesEnum.mainCategory} value={selectedUpcDetails.mainCategory} />
                                    <AttributeData type={ProductAttributesEnum.productCategory} value={selectedUpcDetails.productCategory} />
                                    <AttributeData type={ProductAttributesEnum.manufactureName} value={selectedUpcDetails.manufactureName} />
                                    <AttributeData type={ProductAttributesEnum.brand} value={selectedUpcDetails.brand} />
                                </div>


                                {/* Standard */}


                                <div className="relative leading-[20px] font-semibold">
                                    Standard
                                </div>
                                <div className="self-stretch flex flex-row items-start justify-start gap-[24px] text-base">
                                    <AttributeData type={ProductAttributesEnum.standard} value={selectedUpcDetails.standard} />
                                    <AttributeData type={ProductAttributesEnum.grade} value={selectedUpcDetails.grade} /> 
                                </div>

                                {/* General info */}
                                {/* <div className="relative leading-[20px] font-semibold">
                                    General Info
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start text-base text-text-color">
                                    <div className="self-stretch flex flex-row items-start justify-start gap-[24px]">
                                        <AttributeData type={ProductAttributesEnum.superCategory} value={'Double Reduced'} />
                                        <AttributeData type={ProductAttributesEnum.superCategory} value={'IS 1993'} />

                                    </div>
                                </div> */}

                                {/* Product classification */}
                                <div className="relative leading-[20px] font-semibold">
                                    Product Classification
                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start text-base text-text-color">
                                    <div className="self-stretch flex flex-row items-start justify-start gap-[24px]">
                                        <AttributeData type={ProductAttributesEnum.shape} value={ convertToReadableFormat(selectedUpcDetails.shape) } />
                                        <AttributeData type={ProductAttributesEnum.classType} value={selectedUpcDetails.classType} />

                                    </div>
                                </div>

                                {/* Product Specification  */}
                                <div className="flex flex-row items-center justify-start gap-[12px]">
                                    <div className="flex flex-row items-start justify-start">
                                        <div className="relative leading-[20px] font-semibold">
                                            Product Specifications
                                        </div>
                                    </div>

                                </div>
                                <div className="self-stretch flex flex-col items-start justify-start text-sm text-text-color">
                                    <div className="flex gap-x-6">
                                        <DisplayProductSpecificationDetails productSpecificationDetails={selectedUpcDetails?.attributes} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DisplayUpcItem;
