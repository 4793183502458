import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "@mui/material";
import InventoryContext from "../../../Core/Context/inventory/NewInventoryContext";
import {
  SET_INVENTORY_FORM_DATA,
  SET_MODE,
  SET_SKU_MODE,
  SET_INVENTORY_ID,
} from "../../../Core/Context/inventory/NewInventoryProvider";
import { useNavigate } from "react-router-dom";
import FindUpcSearchComponent from "../../Catalogue/FindUpcSearch";
import { CustomButton } from "../../../Shared/Button/CustomButton";
import DisplayUpcItem from "../../Catalogue/UpcSearchComponents/DisplayUpcItem";
import { useAuthenticatedUser } from "../../../../hooks/useAuthenticatedUser";
import { makeRoute } from "../../../../utils/helper";
import { ADMIN_ROUTES, LOCAL_PREVIOUS_URL_SECTIONS } from "../../../../utils/constant";

const BaseUrl = process.env.REACT_APP_API_V1_URL;

const FinalUpc = () => {
  const { user } = useAuthenticatedUser();
  const inventoryContextData = useContext(InventoryContext);
  const [formData, setFormData] = useState([]);
  var inventoryData = inventoryContextData.inventoryDetailState.inventoryFormData;
  let [skuMode, setSkuMode] = useState();
  const navigate = useNavigate();

  const [selectedUpcDetails, setSelectedUpcDetails] = useState([]);
  const [upcDetailsForEdit, setUpcDetailsForEdit] = useState([]);
  const [handleSubmitStatus, setHandleSubmitStatus] = useState(false);
  const [activeStatus, setActiveStatus] = React.useState(false);
  const [upcErrorState, setErrorState] = useState(false);
  const errorMessageColor = "#D32F2F";

  const updateUPCDetails = (updatedDetails) => {
    if (selectedUpcDetails) {
      setErrorState(false);
    } else {
      setErrorState(true);
    }

    if (Array.isArray(updatedDetails) && updatedDetails.length > 0) {
      const lastElement = updatedDetails[updatedDetails.length - 1];

      setSelectedUpcDetails([lastElement?.upcDetails]);
      setActiveStatus(true);
    } else {
      setSelectedUpcDetails([]);
      setActiveStatus(false);
    }
  };

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");
  const skMode = urlParams.get("skuMode");
  const mode = urlParams.get("mode");

  const handleEdit = async (id) => {
    if (inventoryData && inventoryData?.upcId && inventoryData) {
      fetchUpcDetails(inventoryData?.upcId);
      return;
    }
    if (mode === "EDIT") {
      let res = await axios.get(`${BaseUrl}/inventory/${id}`,{
        headers: {
           Authorization: `${user.token}`,
           "Content-Type": "application/json",
        }
     });
      let formData = res?.data?.data;
      inventoryContextData.updateInventoryDetailsState(
        formData,
        SET_INVENTORY_FORM_DATA
      );

      inventoryContextData.updateInventoryDetailsState("EDIT", SET_MODE);
      inventoryContextData.updateInventoryDetailsState(id, SET_INVENTORY_ID);
      setFormData(formData);
      fetchUpcDetails(formData?.upcId);
    }
   
  };
  useEffect(() => {
    handleEdit(id);
    // setSkuMode(urlParams.get("skuMode"));
    inventoryContextData.updateInventoryDetailsState(
      urlParams.get("mode"),
      SET_MODE
    );
    inventoryContextData.updateInventoryDetailsState(
      urlParams.get("skuMode"),
      SET_SKU_MODE
    );
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedUpcDetails.length === 0) {
      setErrorState(true);
      return;
    }
    if (selectedUpcDetails.length > 0) {
      setFormData((prevFormData) => {
        setHandleSubmitStatus(true);
        const updatedFormData = { ...prevFormData };
        updatedFormData.upcId = selectedUpcDetails[0]?.id;
        inventoryContextData.updateInventoryDetailsState(
          updatedFormData,
          SET_INVENTORY_FORM_DATA
        );

        navigate(
          `/inventory/step2?skuMode=${urlParams.get(
            "skuMode"
          )}&id=${null}&mode=${urlParams.get("mode")}`
        );
        return updatedFormData;
      });
    }
    if (inventoryContextData?.inventoryDetailState?.mode === "EDIT") {
      setHandleSubmitStatus(true);

      navigate(
        `/inventory/step2?id=${id}&skuMode=${skMode}&mode=${mode}`
      );
    }
  };

  useEffect(() => {
    setFormData(inventoryData || "");

    setSkuMode(inventoryContextData?.inventoryDetailState?.skuMode);
  }, [inventoryData, selectedUpcDetails]);

  useEffect(() => {
    fetchUpcDetails(
      inventoryContextData?.inventoryDetailState?.inventoryFormData?.upcId
    );
  }, []);

  const handleRedirect = () => {
    if (urlParams.get("skuMode") === "STANDARD") {
      const lastVisitedUrl = localStorage.getItem(LOCAL_PREVIOUS_URL_SECTIONS.STANDARD_INVENTORY_PREVIOUS_URL);
       navigate(`/inventory/standard-prime${lastVisitedUrl}`);
    } else if (urlParams.get("skuMode") === "NONSTANDARD") {
      const lastVisitedUrl = localStorage.getItem(LOCAL_PREVIOUS_URL_SECTIONS.NON_STANDARD_INVENTORY_PREVIOUS_URL);
      navigate(`/inventory/non-standard-prime${lastVisitedUrl}`);
    }
  };

  const fetchUpcDetails = (upcId) => {
    if (upcId !== null && upcId !== undefined) {
      axios
        .post(`${BaseUrl}/search/upc`, {
          upcId: upcId,
          classType: skuMode,
        },{
          headers: {
             Authorization: `${user.token}`,
             "Content-Type": "application/json",
          }
       })
        .then((response) => {
          setSelectedUpcDetails(response?.data?.data);
        })
        .catch((error) => {
          console.error("Error fetching UPC details:", error);
        });
    }
    return;
  };
  const DummyFunction = (data) => {
    return;
  };

  const TempComponent = () => {
    if (selectedUpcDetails && selectedUpcDetails.length >= 1) {
      setHandleSubmitStatus(true);
    }
    if (selectedUpcDetails && selectedUpcDetails.length >= 1)
      return (
        <DisplayUpcItem
          type={skuMode}
          index={0}
          updateSelection={DummyFunction}
          upcDetails={selectedUpcDetails[0]}
          // isCheckBoxView={true}
        />
      );
    else if (inventoryContextData.inventoryDetailState.mode === "EDIT")
      return (
        <DisplayUpcItem
          type={skuMode}
          index={0}
          updateSelection={DummyFunction}
          upcDetails={selectedUpcDetails[0]}
          // isCheckBoxView={true}
        />
      );
    return null;
  };

  return (
    <div className="mt-3 sku-background w-full grid gap-y-5 py-5">
      <div className="flex justify-center text-label font-roboto text-5xl font-semibold">
        {urlParams.get("mode") === "ADD" ? "Add New " : "Edit"}
        SKU
      </div>
      <div className="w-4/5  m-auto flex justify-between gap-x-0.5 text-var(--black, #4D4D4D) text-center font-roboto text-xs font-normal leading-normal">
        <div className="grid gap-y-1 w-1/4">
          <div className="h-3 bg-blue"></div>
        </div>
        <div className="grid gap-y-1 w-1/4">
          <div className="h-3 bg-gray-300"></div>
        </div>
        <div className="grid gap-y-1 w-1/4">
          <div className="h-3 bg-gray-300"></div>
        </div>
        <div className="grid gap-y-1 w-1/4">
          <div className="h-3 bg-gray-300"></div>
        </div>
      </div>
      <div className="w-4/5  m-auto px-10 pb-10 pt-6 bg-white shadow-md">
        <div className="grid gap-y-9">
          <div className="grid gap-y-6">
            <div className="grid gap-y-4 p-6">
              {}
              <div className="text-label font-roboto text-18 font-bold">
                Enter {urlParams.get("skuMode") === "STANDARD" ? "UPC" : "MPC"}{" "}
                Code.
              </div>
              <div className="flex justify-between gap-x-3">
                <div className="w-full">
                  <FindUpcSearchComponent
                    type={urlParams.get("skuMode")}
                    catalogueId={null}
                    catalogueUpcDetails={[]}
                    updateUPCDetails={updateUPCDetails}
                    existingUpcCode={selectedUpcDetails?.upcCode}
                    isCheckBoxView={true}
                  />
                  {/* Display validation errors */}
                  {upcErrorState && (
                    <div className="mt-2" style={{ color: errorMessageColor }}>
                      {`Please Add ${
                        skMode === "STANDARD" ? "Universal" : "Master"
                      } Product Code`}
                    </div>
                  )}
                </div>
              </div>
              <div></div>
            </div>

            {/**     <div className="grid gap-y-4 p-6 bg-gray-300 bg-opacity-30 rounded">
              <p>If You Don’t Know then enter Manually</p>
              <SearchUpcIndex
                switchComponent={null}
                onClose={null}
                updateSearchDetails={handleActiveStatus}
              />
              
            </div> */}
          </div>
          <TempComponent />

          <div className="flex justify-between">
            <div className="m-5"></div>
            <div className="flex gap-x-3">
              <CustomButton onClick={handleRedirect}>Back</CustomButton>
              {/* <CustomButton>Preview</CustomButton> */}
              {/* <CustomButton>Save Draft</CustomButton> */}
              { selectedUpcDetails.length !== 0 ? <CustomButton variant="Primary" onClick={handleSubmit}>Next</CustomButton>:<Button
              disabled={selectedUpcDetails.length === 0}
            >
              Next
            </Button>}
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalUpc;
