import React, { useCallback, useEffect, useState } from 'react';
import { timeToLocale } from '../../utils/helper';

interface CountDownTimerProps {
    timeInSeconds: number;
    onComplete: () => void;
}

const CountDownTimer: React.FC<CountDownTimerProps> = ({ timeInSeconds, onComplete }) => {

    const [ remainingTime, setRemainingTime ] = useState<number>(timeInSeconds);
    const [ intervalId, setIntervalId ] = useState<NodeJS.Timeout | null>(null);

    const completeTimer = useCallback(() => {
        clearInterval(intervalId ?? undefined);
        onComplete();
    }, [ intervalId, onComplete ]);

    useEffect(() => {
        if (remainingTime > 0) {
            setIntervalId(setInterval(() => {
                setRemainingTime(currentTime => currentTime - 1);
            }, 1000));
        } else {
            completeTimer();   
        }

        return completeTimer;
        
    }, [ remainingTime, onComplete, completeTimer ]);

    return (
        <span>{timeToLocale(remainingTime, 'year')}</span>
    );
}

export default CountDownTimer;
