import React, { useState } from 'react';
import noImage from '../../Assets/Images/no_image.svg';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  imageUrl?: string;
}

function CustomImageComponent({ imageUrl = '', ...rest }: Props) {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  const handleImageLoad = () => {
    setLoaded(true);
  };

  const handleImageError = () => {
    setError(true);
  };

  const style: React.CSSProperties = {
    display: loaded && !error ? 'block' : 'none',
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <img
        src={imageUrl || noImage}
        style={{ ...style }}
        onLoad={handleImageLoad}
        onError={handleImageError}
        alt="productImage"
        {...rest}
      />
      {!loaded && !error && (
        <img
          src={noImage}
          style={{ width: '100%', height: '100%' }}
          alt="placeholder"
        />
      )}
    </div>
  );
}

export default React.memo(CustomImageComponent);
