import React, { useEffect, useState } from 'react'
import { ISecondaryCatalogueForm, secondaryCatalogueSectionProps } from './SecondaryCatalogueForm.template'
import { SECONDARY_CATALOGUE_SECTIONS } from '../../../utils/types';
import CustomHeading from '../../atoms/CustomHeading/CustomHeading';
import Button from '../../atoms/Button';
import { ADMIN_ROUTES } from '../../../utils/constant';
import { useNavigate } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useSecondaryInventoryService } from '../../../services/useSecondaryInventoryService';
import { createUseStyles } from 'react-jss';
import { formatJoeditData } from '../../atoms/JoeditDiplayFormatter';

const useStyles = createUseStyles((theme: any) => ({
	heading: {
		color: theme.palette.text.primaryDark,
		fontFamily: "Roboto",
		fontSize: "18px",
		fontStyle: "normal",
		fontWeight: 600,
		lineHeight: "20px",
	},
	subHeading: {
		color: theme.palette.text.secondaryDark,
		fontFamily: "Roboto",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: 400,
		lineHeight: "normal",
	},
	values: {
		color: theme.palette.text.secondaryDark,
		fontFamily: "Roboto",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: 500,
		lineHeight: "normal",
	},
}));

const ProductSelectionTemplate: React.FC<secondaryCatalogueSectionProps> = ({ formik, id, mode, setCurrentSectionTo }) => {
	const navigate = useNavigate();
	const classes = useStyles();
	const secondaryInventoryService = useSecondaryInventoryService();

	const [suggestionsList, setSuggestionsList] = useState<string[]>([]);
	const [secondaryInventory, setSecondaryInventoryTo] = useState<any>();
	const [warehouseName, setWarehouseName] = useState<string>('');
	const [productCategoryName, setProductCategoryNameTo] = useState<string>('');
	const [mainCategoryName, setMainCategoryNameTo] = useState<string>('');
	const [superCategoryName, setSuperCategoryNameTo] = useState<string>('');
	const [gradeName, setGradeName] = useState<string >('');

	const handleRedirectCatalogueList = () => {
		navigate(`${ADMIN_ROUTES.SECONDARY_CATALOGUE_LIST}`);
	};
	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		const requiredFields = ["secondaryProductId"];
		let fieldError = false;
		for (const key of requiredFields) {
			if (key in formik.errors) {
				formik.setFieldTouched(key, true);
				fieldError = true;
			}
		}
		formik.setValues((prevValues: ISecondaryCatalogueForm) => ({
			...prevValues,

		}));
		if (fieldError === true) return;
		setCurrentSectionTo(SECONDARY_CATALOGUE_SECTIONS.CATALOGUE_DETAILS);
	};

	const filterUpcCodesFromList = (data: any): string[] => {
		if (!data || data.length === 0) {
			console.error('Data array is empty or invalid');
			return [];
		}
		const filteredList: string[] = [];
		for (const item of data) {
			if (!item.skuCode) {
				console.error('secondaryCode is missing in an element:', item);
				continue;
			}
			filteredList.push(item.skuCode);
		}
		return filteredList;
	};

	const fetchSecondaryInventoryDetails = () => {
		let params = {
			page: 0,
			size: 100,
			sort: "createdAt,Asc",
		}
		let body = {

		}
		secondaryInventoryService.getAllSecondaryInventories(body, params)
			.then(response => {
				setSuggestionsList(filterUpcCodesFromList(response.data.data.content));
			})
			.catch(error => {

			});
	};

	const fetchSecondaryInventory = (value: string | null) => {
		if (value !== null) {
			let params = {
				skuCode: value,
				page: 0,
				size: 100,
				sort: "createdAt,Asc",
			}
			let body = {

			}
			secondaryInventoryService.getAllSecondaryInventories(body, params)
				.then(response => {
					formik.setFieldValue('secondaryProductId', response.data.data.content[0].id);
					setWarehouseName(response.data.data.content[0].warehouseName);
					fetchSecondaryInventorybyId(response.data.data.content[0].id);
				})
				.catch(error => {

				});
		}
	};

	const fetchSecondaryInventorybyId = (id: number) => {
		secondaryInventoryService.getSecondaryInventoryById(id)
			.then(response => {
				setSecondaryInventoryTo(response.data.data);
				getWarehouseById(response.data.data.warehouseId);
				getProductCategory(response.data.data.productCategoryId);
				getGradeById(response.data.data.gradeId);
			})
			.catch(error => {

			});
	}
	const getWarehouseById = (warehouseId: number) => {
		if (warehouseId) {
			secondaryInventoryService.getWarehouseById(warehouseId)
			.then((response) => {
			  setWarehouseName(response?.data?.name);
			})
			.catch((error) => {
			  console.error("Error fetching warehouse details ", error);
			  setWarehouseName('');
			});
		}
	  };

	  const getGradeById = (gradeId: number) => {
		if (gradeId) {
			secondaryInventoryService.getGradeById(gradeId)
			.then((response) => {
			  setGradeName(response?.data?.data?.name);
			})
			.catch((error) => {
			  console.error("Error fetching warehouse details ", error);
			  setGradeName('');
			});
		}
	  }

	  const getProductCategory = (produtCategoryId: number) => {
		if (produtCategoryId) {
		  secondaryInventoryService.getCategoryById(produtCategoryId)
			.then((response) => {
			  setProductCategoryNameTo(response.data.data.name);
			  getMainCategory(response.data.data.parentId);
			})
			.catch((error) => {
			  console.error("Error fetching warehouse details ", error);
	
			});
		}
	  }
	
	  const getMainCategory = (mainCategoryId: number) => {
		if (mainCategoryId) {
		  secondaryInventoryService.getCategoryById(mainCategoryId)
			.then((response) => {
			  setMainCategoryNameTo(response.data.data.name);
			  getSuperCategory(response.data.data.parentId);
			})
			.catch((error) => {
			  console.error("Error fetching warehouse details ", error);
			});
		}
	  }
	
	  const getSuperCategory = (superCategoryId: number) => {
		if (superCategoryId) {
		  secondaryInventoryService.getCategoryById(superCategoryId)
			.then((response) => {
			  setSuperCategoryNameTo(response.data.data.name);
			})
			.catch((error) => {
			  console.error("Error fetching warehouse details ", error);
			});
		}
	  }  

	useEffect(() => {
		fetchSecondaryInventoryDetails()
		formik.values.secondaryProductId &&
			fetchSecondaryInventorybyId(formik.values.secondaryProductId);
	}, [formik]);
	return (
		<div className=' rounded bg-white py-6 px-10 '>
			<div className='mt-4  gap-2 grid'>
				<CustomHeading text={" Select Secondary Product"} status="" />
				<Autocomplete
					id="upc-autocomplete"
					options={suggestionsList}
					freeSolo
					onChange={(event, value) => {
						fetchSecondaryInventory(value)
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Enter Secondary Product Code"
							variant="outlined"
							fullWidth
							error={Boolean(formik.errors.secondaryProductId) && formik.touched.secondaryProductId}
							required
						/>
					)}
				/>
			</div>

			{secondaryInventory && <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded mt-8">
				<div className={classes.heading}>{secondaryInventory?.skuCode}</div>

				<div className={classes.heading}>Product Category</div>
				<div className="flex gap-x-6">
					<div className="grid gap-y-1.5">
						<div className={classes.subHeading}>Super Category</div>
						<div className={classes.values}>{superCategoryName}</div>
					</div>
					<div className="grid gap-y-1.5">
						<div className={classes.subHeading}>Main Category</div>
						<div className={classes.values}>{mainCategoryName}</div>
					</div>
					<div className="grid gap-y-1.5">
						<div className={classes.subHeading}>Product Category</div>
						<div className={classes.values}>{productCategoryName}</div>
					</div>
				</div>


				<div className={classes.heading}>Product Standard</div>
				<div className="flex gap-x-6">
					<div className="grid gap-y-1.5">
						<div className={classes.subHeading}>Shape</div>
						<div className={classes.values}>{secondaryInventory?.shape}</div>
					</div>
					<div className="grid gap-y-1.5">
						<div className={classes.subHeading}>Class</div>
						<div className={classes.values}>Secondary</div>
					</div>
					<div className="grid gap-y-1.5">
						<div className={classes.subHeading}>Grade</div>
						<div className={classes.values}>{gradeName}</div>
					</div>
				</div>

				<div className={classes.heading}>General Info</div>
				<div className="flex gap-x-6">
					{/* <DisplayProductGeneralizationDetails productSpecificationDetails={ selectedUpc?.attributes }/> */}
				</div>

				<div className={classes.heading}>Defect Details</div>
				<div className="flex gap-x-6">
					<div className="grid gap-y-1.5">
						<div className={classes.subHeading}>{secondaryInventory?.defectDetails ? formatJoeditData(secondaryInventory?.defectDetails) : 'No Data Found'}</div>
					</div>
				</div>

				<div className={classes.heading}>warehouse</div>
				<div className="flex gap-x-6">
					<div className="grid gap-y-1.5">
						<div className={classes.subHeading}>Warehouse</div>
						<div className={classes.values}>{warehouseName}</div>
					</div>
					<div className="grid gap-y-1.5">
						<div className={classes.subHeading}>Warehouse Placement</div>
						<div className={classes.values}>{secondaryInventory?.warehousePlacement}</div>
					</div>
				</div>
				<div className="flex gap-x-6">
					<div className="grid gap-y-1.5">
						<div className={classes.subHeading}>Net Weight</div>
						<div className={classes.values}>{secondaryInventory?.netWeight}</div>
					</div>
					<div className="grid gap-y-1.5">
						<div className={classes.subHeading}>Gross Weight</div>
						<div className={classes.values}>{secondaryInventory?.grossWeight}</div>
					</div>
				</div>
				<div className="flex gap-x-6">
					<div className="grid gap-y-1.5">
						<div className={classes.subHeading}>Total Price</div>
						<div className={classes.values}>{new Intl.NumberFormat().format(secondaryInventory?.basePrice! * secondaryInventory?.netWeight!)} Rs</div>
					</div>
				</div>
			</div>}
			<div className="flex justify-end mt-4">
				<div className="flex gap-x-3">
					<Button variant="outlined" label="Back" onClick={handleRedirectCatalogueList} />
					<Button variant="contained" label="Next" onClick={handleSubmit} />
				</div>
			</div>
		</div>
	)
}

export default ProductSelectionTemplate