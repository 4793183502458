import {
  FormControl,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
} from '@mui/material';
import CustomLabel from './CustomLabel';
import React from 'react';

interface CustomTextFieldProps {
  name: string;
  label?: string;
  placeholder?: string;
  value: string | undefined | number;
  adornment?: string;
  adornmentPostion?: 'start' | 'end';
  disabled?: boolean;
  error?: boolean;
  helperText?: string | false;
  defaultBorder?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  rounded?: 'lg' | 'md' | 'sm' | 'none' | 'full' | [string];
}

const CustomTextField: React.FC<CustomTextFieldProps> = ({
  name,
  label,
  value,
  adornment,

  adornmentPostion = 'end',
  placeholder,
  rounded = 'none',
  error = false,
  helperText = '',
  disabled = false,
  onChange,
  defaultBorder,
  onBlur,
}) => {
  return (
    <FormControl
      sx={{
        pointerEvents: disabled ? 'none' : 'auto',
        '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': defaultBorder
          ? {
              borderColor: '#dddddd',
            }
          : {
              borderColor: error ? '#d32f2f' : '#1d3679',
            },
      }}
      variant="outlined"
      className="w-full"
    >
      {label ? (
        <CustomLabel
          name={name}
          label={label}
          disabled={disabled}
          error={error}
          value={Boolean(value)}
        />
      ) : (
        ''
      )}
      <OutlinedInput
        id={`outlined-${name}-input`}
        name={name}
        error={error}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        autoFocus={false}
        className={`${disabled ? 'bg-cgray-17' : 'bg-white'} ${
          adornment
            ? adornmentPostion === 'start'
              ? 'pl-12 pr-1'
              : 'pr-10 pl-1'
            : ''
        } w-full h-12 rounded-${rounded}
        ${adornment ? 'border-r-0' : ''}
        `}
        placeholder={placeholder}
        readOnly={disabled}
        startAdornment={
          adornment && (
            <InputAdornment position={adornmentPostion}>
              <span
                className={`bg-gainsboro-200 flex items-center w-12 justify-center absolute h-12 text-black ${
                  defaultBorder
                    ? ''
                    : error
                    ? `border-[1px] border-l-[#d32f2f]`
                    : `border-[1px] border-blue`
                } ${adornmentPostion === 'start' ? 'left-0' : 'right-0'}`}
              >
                {adornment}
              </span>
            </InputAdornment>
          )
        }
      />
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default React.memo(CustomTextField);
