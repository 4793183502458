import { FormikErrors, FormikProps, useFormik } from "formik";
import React, { useEffect, useMemo, useRef, useState } from 'react'
import * as yup from "yup";
import { ADMIN_ROUTES, GENERIC_FILTER_SECTIONS, MODE, STATUS_CONST, PRODUCT_SECTION, REGEX, TERMS_CONDITION_CONSTANT } from '../../../utils/constant';
import { QuotationDetailsTemplate } from "../../templates/InstantQuotation/QuotationDetails.template";
import { AdditionalChargesTemplate } from "../../templates/InstantQuotation/AdditionalCharges.template";
import { DeliveryInformationTemplate } from "../../templates/InstantQuotation/DeliveryInformation.template";
import { PointOfContacTemplate } from "../../templates/InstantQuotation/PointOfContact.template";
import { useSnackbar } from "../../../hooks/useSnackBar";
import { useNavigate, useParams } from "react-router-dom";
import { useInstantQuotationService } from "../../../services/useInstantQuotationService";
import { HTTP_STATUS } from "../../../utils/types";
import ButtonV2 from "../../atoms/ButtonV2";
import { GstTaxDetailsTemplate } from "../../templates/InstantQuotation/GstTaxDetails.template";
import DetailsTableTemplate from "../../templates/InstantQuotation/DetailsTable.template";
import TextFieldV2 from "../../atoms/TextFieldV2";
import UpcMpcSearchDialogModal, { IMaterialFilterData } from "../../templates/GenericSearchDialog/GenericSerachDialog.template";
import HoverButton from "../../atoms/HoverButton";
import { useMasterUpcService } from "../../../services/useMasterUpcService";
import deleteIcon from '../../../assets/icons/deleteIcon.svg';
import ErrorMessage from "../../atoms/ErrorMessage/ErrorMessage";
import { AddVasDetailsTemplate } from "../../templates/InstantQuotation/AddVasDetails.template";
import { ISlitting } from "../../templates/InstantQuotation/Customization/SlittingCustomizationPopup";
import { IShearing } from "../../templates/InstantQuotation/Customization/ShearingCustomizationPopup";
import { cond } from "lodash";
import { createUseStyles } from "react-jss";
import { getShapeName } from "../../../utils/helper";
import { QuotationReviewPage } from "../../templates/InstantQuotation/QuoteReview.template";
import { useStandardService } from "../../../services/useStandardService";

const useStyles = createUseStyles((theme: any) => ({
    textHeader: { color: theme.palette.textV2.tertiary.tertiary900 },
}));
export interface IInstantQuotationAddressDetails {
    companyName?: string | null;
    addressLine1?: string | null;
    addressLine2?: string | null;
    pincode?: string | null;
    city?: string | null;
    state?: string | null;
    emailId?: string | null;
    phoneNumber?: string | null;
    gstIn?: string | null;
}

interface IInstantQuotationProductDetails {
    productId: number;
    productCode: string;
    location: string;
    hsnCode: string;
    quantity: number | null;
    unitPrice: number | null;
    discount: number | null;
    taxableAmount: number | null;
}

interface IInstantQuotationVASExtractedDetails {
    type: string;
    productId: number;
    productCode: string;
    location: string;
    hsnCode: string;
    quantity: number | null;
    unitPrice: number | null;
    discount: number | null;
    taxableAmount: number | null;
}


export interface IInstantQuotationVASProductDetails {
    productId: number;
    productCode: string;
    location: string;
    hsnCode: string;
    quantity: number | null;
    unitPrice: number | null;
    discount: number | null;
    taxableAmount: number | null;
    slittingDetails: ISlitting[];
    shearingDetails: IShearing[];
}

interface IInstantQuotationCharges {
    particulars: string;
    quantity: number;
    charge: number;
    totalAmount: number;
}

interface IInstantQuotationTaxDetails {
    particulars: string;
    taxableAmount: number;
    cgstRate: number;
    cgstAmount: number;
    sgstRate: number;
    sgstAmount: number;
    igstRate: number;
    igstAmount: number;
    totalTaxAmount: number;
}

export interface IInstantQuotationDeliveryDTO {
    timeFrame: string;
    deliveryDate: string;
    deliveryMode: string;
    transportArrangement: string;
    materialLifting: string;
    inco: string;
}

export interface IInstantQuotationPaymentTerms {
    paymentTerms: string;
    paymentMethods: string;
}

export interface IInstantQuotationPaymentInfo {
    percentage: number | null;
    amount: number | null;
    dueDate: string;
}

interface IInstantQuotationPackaging {
    packagingTerms: string;
    packagingMaterials: string;
    packagingDimensions: string;
}

interface IInstantQuotationInspection {
    inspectionPeriod: string;
    inspectionLocation: string;
    acceptanceCriteria: string;
    notification: string;
    remedies: string;
}

interface IInstantQuotationPOC {
    name: string;
    number: string;
    emailId: string;
}

interface IInstantQuotationTotalCharges {
    totalProductQuantity: number;
    totalProductTaxableCharges: number;
    totalVASQuantity: number;
    totalVASTaxableCharges: number;
    totalAdditionalCharges: number;
    totalTaxableAmount: number;
    totalCGST: number;
    totalSGST: number;
    totalIGST: number;
    totalTaxAmount: number;
    totalProductAndVASAmount: number;
    tcs: number;
    grandTotal: number;
}

export interface IInstantQuotation {
    id?: number;
    billerGst: string;
    quotationCode: string | null;
    leadSource: string;
    leadPriority: string;
    version?: number;
    quotationDate: string;
    status: string;
    closureDate: string;
    validityDate: string;
    billToAddressDetails?: IInstantQuotationAddressDetails | null;
    shipToAddressDetails?: IInstantQuotationAddressDetails | null;
    message: string;
    productDetails: IInstantQuotationProductDetails[] | [];
    vasDetails: IInstantQuotationVASProductDetails[] | [];
    vasDetailsExtracted: IInstantQuotationVASExtractedDetails[] | [];
    additionalCharges: IInstantQuotationCharges[] | [];
    gstDetails: IInstantQuotationTaxDetails[] | [];
    deliveryInformation: IInstantQuotationDeliveryDTO;
    paymentTerms: IInstantQuotationPaymentTerms;
    upfrontPaymentInformation: IInstantQuotationPaymentInfo;
    deliveryPaymentInformation: IInstantQuotationPaymentInfo;
    packaging: IInstantQuotationPackaging | null;
    inspectionAndAcceptanceTerm: IInstantQuotationInspection | null;
    termAndCondition: any;
    totalCharges: IInstantQuotationTotalCharges | null;
    pocInformation: IInstantQuotationPOC[] | [];
}

export const QuoteCreatePage: React.FC = () => {

    const instantQuotationService = useInstantQuotationService();
    const params = useParams();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const navigate = useNavigate();
    const masterUpcService = useMasterUpcService();
    const standardService = useStandardService();
    const classes = useStyles();

    const [filterSection, setFilterSections] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [upcFilterData, setUpcFilterData] = useState<IMaterialFilterData>({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [vasDetails, setVasDetails] = useState<any>(null);
    const [brandUpc, setBrandUpcTo] = useState<any>();

    const instantQuotationId = params?.id ? parseInt(params.id ?? '0', 10) : null;
    const instantQuotationMode = params?.mode ? (params.mode ?? MODE.ADD) : null;
    const [currentStatus, setCurrentStatus] = useState();
    const [currentStep, setCurrentStep] = useState(MODE.ADD);

    const initialValues: IInstantQuotation = {
        quotationCode: null,
        billerGst: '',
        version: 1,
        leadSource: '',
        leadPriority: '',
        quotationDate: '',
        closureDate: '',
        status: '',
        validityDate: '',
        billToAddressDetails: null,
        shipToAddressDetails: null,
        message: '',
        productDetails: [],
        vasDetails: [],
        vasDetailsExtracted: [],
        additionalCharges: [],
        gstDetails: [],
        deliveryInformation: {
            timeFrame: '',
            deliveryDate: '',
            deliveryMode: '',
            transportArrangement: '',
            materialLifting: '',
            inco: ''
        },
        paymentTerms: { paymentTerms: '', paymentMethods: '' },
        upfrontPaymentInformation: {
            percentage: null,
            amount: null,
            dueDate: '',
        },
        deliveryPaymentInformation: {
            percentage: null,
            amount: null,
            dueDate: '',
        },
        packaging: null,
        inspectionAndAcceptanceTerm: null,
        termAndCondition: TERMS_CONDITION_CONSTANT,
        totalCharges: null,
        pocInformation: []
    };
    const addressDetailsSchema = yup.object().shape({
        companyName: yup.string().required('Company name is required'),
        addressLine1: yup.string().required('Address line 1 is required'),
        addressLine2: yup.string().required('Address line 2 is required'),
        pincode: yup.string().required('Pincode is required'),
        city: yup.string().required('City is required'),
        state: yup.string().required('State is required'),
        emailId: yup.string().required("Email is required").matches(REGEX.EMAIL, 'Entered Invalid Email ID'),
        phoneNumber: yup.string().required("Phone Number is required").matches(REGEX.PHONE_NUMBER, 'Entered Invalid phone number'),
        gstIn: yup.string().required("GST Number is required").matches(REGEX.GST, 'Entered Invalid GST number.'),
    });

    const pocSchema = yup.object().shape({
        name: yup.string(),
        emailId: yup.string().matches(REGEX.EMAIL, 'Entered Invalid Email ID'),
        number: yup.string().matches(REGEX.PHONE_NUMBER, 'Entered Invalid phone number'),
    });

    const productSchema = yup.object().shape({
        location: yup.string().required('Location name is required'),
        hsnCode: yup.string().required('HSN Code name is required').matches(REGEX.HSN_VALIDATION, 'Enter a valid HSN code'),
        quantity: yup.number().required('Quantity is required'),
        unitPrice: yup.number().required('Unit Price is required')
    });

    const deliveryInformationSchema = yup.object().shape({
        timeFrame: yup.string().required('Time frame is required'),
        deliveryDate: yup.string().required('Delivery date is required'),
        deliveryMode: yup.string().required('Delivery mode is required'),
        transportArrangement: yup.string().required('Transport arrangement is required'),
        materialLifting: yup.string().required('Material lifting is required'),
        inco: yup.string().required('INCO/ Shipping Terms is required')

    })

    const paymentTermsSchema = yup.object().shape({
        paymentTerms: yup.string().required('Payment terms is required'),
        paymentMethods: yup.string().required('Payment methods is required'),
    })

    const upfrontPaymentInformationSchema = yup.object().shape({
        percentage: yup.number().required('Percentage is required'),
        amount: yup.number().required('Amount is required'),
        dueDate: yup.string().required('Due date is required'),

    })

    const deliveryPaymentInformationSchema = yup.object().shape({
        percentage: yup.number().required('Percentage is required'),
        amount: yup.number().required('Amount is required'),
        dueDate: yup.string().required('Due date is required'),

    })

    const validationSchema = yup.object().shape({
        leadSource: yup.string().required('Lead source is required'),
        leadPriority: yup.string().required('Lead priority is required'),
        status: yup.string().required('Status is required'),
        productDetails: yup.array().of(productSchema),
        deliveryInformation: deliveryInformationSchema,
        paymentTerms: paymentTermsSchema,
        upfrontPaymentInformation: upfrontPaymentInformationSchema,
        deliveryPaymentInformation: deliveryPaymentInformationSchema,
        quotationDate: yup.date()
            .required('Quotation Date is required')
            .nullable(),
        closureDate: yup.date()
            .min(yup.ref('quotationDate'), 'Closure Date must be after Quotation Date')
            .required('Closure Date is required')
            .nullable(),
        validityDate: yup.date()
            .min(yup.ref('closureDate'), 'Validity Date must be after Closure Date')
            .required('Validity Date is required')
            .nullable(),
        billToAddressDetails: addressDetailsSchema,
        shipToAddressDetails: addressDetailsSchema,
        pocInformation: yup.array().of(pocSchema)
    });

    const formik = useFormik<IInstantQuotation>({
        initialValues,
        validationSchema,
        validateOnChange: true,
        validateOnMount: true,
        validateOnBlur: true,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            setIsLoading(true);
            if (values.productDetails.length === 0) {
                showSnackbar('error', 'At least one product is required')
                return;
            } else if (values.pocInformation.length === 0) {
                showSnackbar('error', 'At least one POC is required')
                return;
            }
            setIsLoading(false);
            if (isLoading) return;
            setCurrentStep(MODE.REVIEW);
        }
    });

    const saveInstantQuotation = async () => {
        setIsLoading(true);
        const requestBody = {
            quotationCode: formik.values?.quotationCode,
            leadSource: formik.values?.leadSource,
            billerGst: formik.values?.billerGst,
            leadPriority: formik.values?.leadPriority,
            quotationDate: formik.values?.quotationDate,
            closureDate: formik.values?.closureDate,
            validityDate: formik.values?.validityDate,
            status: formik.values?.status,
            billToAddressDetails: {
                companyName: formik.values?.billToAddressDetails?.companyName,
                addressLine1: formik.values?.billToAddressDetails?.addressLine1,
                addressLine2: formik.values?.billToAddressDetails?.addressLine2,
                pincode: formik.values?.billToAddressDetails?.pincode,
                city: formik.values?.billToAddressDetails?.city,
                state: formik.values?.billToAddressDetails?.state,
                emailId: formik.values?.billToAddressDetails?.emailId,
                phoneNumber: formik.values?.billToAddressDetails?.phoneNumber,
                gstIn: formik.values?.billToAddressDetails?.gstIn,
            },
            shipToAddressDetails: {
                companyName: formik.values?.shipToAddressDetails?.companyName,
                addressLine1: formik.values?.shipToAddressDetails?.addressLine1,
                addressLine2: formik.values?.shipToAddressDetails?.addressLine2,
                pincode: formik.values?.shipToAddressDetails?.pincode,
                city: formik.values?.shipToAddressDetails?.city,
                state: formik.values?.shipToAddressDetails?.state,
                emailId: formik.values?.shipToAddressDetails?.emailId,
                phoneNumber: formik.values?.shipToAddressDetails?.phoneNumber,
                gstIn: formik.values?.shipToAddressDetails?.gstIn,
            },
            message: formik.values?.message,
            productDetails: formik.values?.productDetails.map((product) => ({
                productId: product?.productId,
                productCode: product?.productCode,
                quantity: product?.quantity,
                location: product?.location,
                hsnCode: product?.hsnCode,
                unitPrice: product?.unitPrice,
                discount: product?.discount,
                taxableAmount: product?.taxableAmount
            })),
            vasDetails: formik.values?.vasDetails.map((vasDetail) => ({
                productId: vasDetail?.productId,
                productCode: vasDetail?.productCode,
                quantity: vasDetail?.quantity,
                location: vasDetail?.location,
                hsnCode: vasDetail?.hsnCode,
                unitPrice: vasDetail?.unitPrice,
                discount: vasDetail?.discount,
                taxableAmount: vasDetail?.taxableAmount,
                slittingDetails: vasDetail?.slittingDetails,
                shearingDetails: vasDetail?.shearingDetails
            })),
            vasDetailsExtracted: formik.values?.vasDetailsExtracted.map((product) => ({
                type: product?.type,
                productId: product?.productId,
                productCode: product?.productCode,
                quantity: product?.quantity,
                location: product?.location,
                hsnCode: product?.hsnCode,
                unitPrice: product?.unitPrice,
                discount: product?.discount,
                taxableAmount: product?.taxableAmount
            })),
            additionalCharges: formik.values?.additionalCharges?.map((charge) => ({
                particulars: charge?.particulars,
                quantity: charge?.quantity,
                charge: charge?.charge,
                totalAmount: charge?.totalAmount
            })),
            gstDetails: formik.values?.gstDetails.map((gst) => ({
                cgstAmount: gst?.cgstAmount,
                cgstRate: gst?.cgstRate,
                igstAmount: gst?.igstAmount,
                igstRate: gst?.igstRate,
                particulars: gst?.particulars,
                sgstAmount: gst?.sgstAmount,
                sgstRate: gst?.cgstRate,
                taxableAmount: gst?.taxableAmount,
                totalTaxAmount: gst?.totalTaxAmount,
            })),
            deliveryInformation: {
                deliveryDate: formik.values?.deliveryInformation?.deliveryDate,
                deliveryMode: formik.values?.deliveryInformation?.deliveryMode,
                inco: formik.values?.deliveryInformation?.inco,
                materialLifting: formik.values?.deliveryInformation?.materialLifting,
                timeFrame: formik.values?.deliveryInformation?.timeFrame,
                transportArrangement: formik.values?.deliveryInformation?.transportArrangement,
            },
            paymentTerms: {
                paymentMethods: formik.values?.paymentTerms?.paymentMethods,
                paymentTerms: formik.values?.paymentTerms?.paymentTerms,
            },
            upfrontPaymentInformation: {
                amount: formik.values?.upfrontPaymentInformation?.amount,
                dueDate: formik.values?.upfrontPaymentInformation?.dueDate,
                percentage: formik.values?.upfrontPaymentInformation?.percentage,
            },
            deliveryPaymentInformation: {
                amount: formik.values?.deliveryPaymentInformation?.amount,
                dueDate: formik.values?.deliveryPaymentInformation?.dueDate,
                percentage: formik.values?.deliveryPaymentInformation?.percentage,
            },
            packaging: {
                packagingDimensions: formik.values?.packaging?.packagingDimensions ?? '',
                packagingMaterials: formik.values?.packaging?.packagingMaterials ?? '',
                packagingTerms: formik.values?.packaging?.packagingTerms ?? '',
            },
            inspectionAndAcceptanceTerm: {
                inspectionPeriod: formik.values?.inspectionAndAcceptanceTerm?.inspectionPeriod ?? '',
                inspectionLocation: formik.values?.inspectionAndAcceptanceTerm?.inspectionLocation ?? '',
                acceptanceCriteria: formik.values?.inspectionAndAcceptanceTerm?.acceptanceCriteria ?? '',
                notification: formik.values?.inspectionAndAcceptanceTerm?.notification ?? '',
                remedies: formik.values?.inspectionAndAcceptanceTerm?.remedies ?? '',

            },
            termAndCondition: formik.values?.termAndCondition,
            totalCharges: {
                totalProductQuantity: formik.values?.totalCharges?.totalProductQuantity,
                totalProductTaxableCharges: formik.values?.totalCharges?.totalProductTaxableCharges,
                totalVASQuantity: formik.values?.totalCharges?.totalVASQuantity,
                totalVASTaxableCharges: formik.values?.totalCharges?.totalVASTaxableCharges,
                totalAdditionalCharges: formik.values?.totalCharges?.totalAdditionalCharges,
                totalTaxableAmount: formik.values?.totalCharges?.totalTaxableAmount,
                totalCGST: formik.values?.totalCharges?.totalCGST,
                totalSGST: formik.values?.totalCharges?.totalSGST,
                totalIGST: formik.values?.totalCharges?.totalIGST,
                totalTaxAmount: formik.values?.totalCharges?.totalTaxAmount,
                totalProductAndVASAmount: formik.values?.totalCharges?.totalProductAndVASAmount,
                tcs: formik.values?.totalCharges?.tcs,
                grandTotal: formik.values?.totalCharges?.grandTotal
            },
            pocInformation: formik.values.pocInformation.map((poc) => ({
                emailId: poc.emailId,
                name: poc.name,
                number: poc.number,
            })),
        };

        try {
            if (instantQuotationMode === MODE.UPDATE.toLowerCase() && formik.values.status === STATUS_CONST.IN_PROGRESS && instantQuotationId) {
                requestBody.status = STATUS_CONST.REVISED;
                const quotationResponse = await instantQuotationService.updateInstantQuotation(requestBody, instantQuotationId);
                if (quotationResponse.status === HTTP_STATUS.OK) {
                    showSnackbar("success", `Quotation updated`);
                } else if (quotationResponse.status === HTTP_STATUS.BAD_REQUEST) {
                    showSnackbar('error', quotationResponse.data.statusMessage)
                }
                requestBody.status = formik.values?.status;
            } else if ((instantQuotationMode === MODE.UPDATE.toLowerCase() && instantQuotationId)) {
                const quotationResponse = await instantQuotationService.updateInstantQuotation(requestBody, instantQuotationId);
                if (quotationResponse.status === HTTP_STATUS.OK) {
                    showSnackbar("success", `Quotation updated`);
                    navigate(ADMIN_ROUTES.INSTANT_QUOTATION_LIST);
                } else if (quotationResponse.status === HTTP_STATUS.BAD_REQUEST) {
                    showSnackbar('error', quotationResponse.data.statusMessage)
                }
                return;
            }

            const quotationResponse = await instantQuotationService.createInstantQuotation(requestBody);
            if (quotationResponse.status === HTTP_STATUS.OK) {
                showSnackbar("success", `New Quotation added.`);
                navigate(ADMIN_ROUTES.INSTANT_QUOTATION_LIST);
            } else if (quotationResponse.status === HTTP_STATUS.BAD_REQUEST) {
                showSnackbar('error', quotationResponse.data.statusMessage)
            }
        } catch (error) {
            showSnackbar("error", `Quotation creation failed: ${error}`);
        }
        finally {
            setIsLoading(false);
        }

    }

    const additionalChargesRef = useRef<IInstantQuotationCharges[]>(formik.values.additionalCharges);
    const productDetailsRef = useRef<IInstantQuotationProductDetails[]>(formik.values.productDetails);
    const vasDetailsExtractedRef = useRef<IInstantQuotationVASExtractedDetails[]>(formik.values.vasDetailsExtracted);
    const vasDetailsRef = useRef<IInstantQuotationVASProductDetails[]>(formik.values.vasDetails);
    const gstDetailsRef = useRef<IInstantQuotationTaxDetails[]>(formik.values.gstDetails);

    useEffect(() => {
        const productDetails = formik.values.productDetails as IInstantQuotationProductDetails[];
        const updatedProductDetails = productDetails.map(product => {
            const taxableAmount = Number((((product?.quantity ?? 0) * (product?.unitPrice ?? 0)) - (product?.discount ?? 0)).toFixed(2));
            return { ...product, taxableAmount };
        });

        if (JSON.stringify(updatedProductDetails) !== JSON.stringify(productDetailsRef.current)) {
            productDetailsRef.current = updatedProductDetails;
            formik.setFieldValue('productDetails', updatedProductDetails);
        }

        const totalProductQuantity = updatedProductDetails.reduce((total, product) => total + (product?.quantity ?? 0), 0);
        const totalProductTaxableCharges = Number(updatedProductDetails.reduce((total, product) => total + (product?.taxableAmount ?? 0), 0).toFixed(2));
        formik.setFieldValue('totalCharges.totalProductQuantity', totalProductQuantity);
        formik.setFieldValue('totalCharges.totalProductTaxableCharges', totalProductTaxableCharges);
        formik.setFieldValue('gstDetails[0].taxableAmount', totalProductTaxableCharges);
    }, [formik.values.productDetails]);

    useEffect(() => {
        if (vasDetails) {
            const existingVasDetailIndex = formik.values.vasDetails?.findIndex(
                (vasDetail) => vasDetail?.productCode?.split(' ')?.[0] === brandUpc?.productCode?.split(' ')?.[0]
            );

            let updatedVasDetails: IInstantQuotationVASProductDetails[] = [];
            if (existingVasDetailIndex && existingVasDetailIndex !== -1) {
                updatedVasDetails = formik.values.vasDetails?.map((vasDetail, index) => {
                    if (index === existingVasDetailIndex) {
                        return {
                            ...vasDetail,
                            shearingDetails: vasDetails?.shearingDetails || vasDetail?.shearingDetails,
                            slittingDetails: vasDetails?.slittingDetails || vasDetail?.slittingDetails,
                        };
                    }
                    return vasDetail;
                });
            } else {
                const newVasDetail: IInstantQuotationVASProductDetails = {
                    productCode: brandUpc?.productCode,
                    productId: brandUpc?.productId,
                    location: brandUpc?.location,
                    hsnCode: brandUpc?.hsnCode,
                    discount: brandUpc?.discount,
                    quantity: brandUpc?.quantity,
                    unitPrice: brandUpc?.unitPrice,
                    taxableAmount: brandUpc?.taxableAmount,
                    shearingDetails: vasDetails?.shearingDetails || [],
                    slittingDetails: vasDetails?.slittingDetails || [],
                };
                if (formik.values.vasDetails) {
                    updatedVasDetails = [...formik.values.vasDetails, newVasDetail];
                } else {
                    updatedVasDetails = [newVasDetail];
                }
            }
            if (JSON.stringify(updatedVasDetails) !== JSON.stringify(vasDetailsRef.current)) {
                vasDetailsRef.current = updatedVasDetails;
                formik.setFieldValue('vasDetails', updatedVasDetails);
            }
            updateVasDetailsExtracted(updatedVasDetails);
        }
    }, [vasDetails]);

    const updateVasDetailsExtracted = (vasDetails: IInstantQuotationVASProductDetails[]) => {
        const vasDetailsListToShow: any[] = [];
        vasDetails?.map(vas => {
            if (vas?.shearingDetails && vas?.shearingDetails?.length > 0) {
                const shearing = {
                    type: 'shearing',
                    hsnCode: vas?.hsnCode,
                    productCode: `${vas?.productCode.split(' ').splice(1).join(' ')} (${vas.shearingDetails?.map(detail => `L-${detail.length} mm of ${detail.pieces} pieces`).join(', ')})`,
                    productId: vas?.productId,
                    location: vas?.location,
                    quantity: 0,
                    unitPrice: vas?.shearingDetails?.[0]?.chargesPerMT,
                    discount: vas?.shearingDetails?.[0]?.discount,
                    taxableAmount: 0
                };
                const totalShearingWeight = vas.shearingDetails?.reduce(
                    (total: number, item: { weight: number }) => total + Number(item.weight.toFixed(2)), 0
                );
                //DO NOT REMOVE
                // const totalDiscount = vas.shearingDetails?.reduce(
                //     (total: number, item: { discount: number }) => total + item.discount, 0
                // );

                const taxableAmount = Number((((totalShearingWeight ?? 0) * (shearing?.unitPrice ?? 0)) - (shearing?.discount ?? 0)).toFixed(2));
                shearing.quantity = Number(totalShearingWeight.toFixed(2));
                shearing.taxableAmount = Number(taxableAmount.toFixed(2));
                //DO NOT REMOVE
                // shearing.discount = Number(totalDiscount.toFixed(2));
                vasDetailsListToShow.push(shearing);
            }
            if (vas?.slittingDetails && vas?.slittingDetails?.length > 0) {
                const slitting = {
                    type: 'slitting',
                    hsnCode: vas?.hsnCode,
                    productCode: `${vas?.productCode?.split(' ')?.splice(1)?.join(' ')} (${vas?.slittingDetails?.map(detail => `W-${detail?.width} of ${detail?.weightOfCoil} MT`).join(', ')})`,
                    productId: vas?.productId,
                    location: vas?.location,
                    quantity: 0,
                    unitPrice: vas?.slittingDetails?.[0]?.chargesPerMT,
                    discount: vas?.slittingDetails?.[0]?.discount,
                    taxableAmount: 0
                };
                const totalSlittingWeight = vas.slittingDetails?.reduce(
                    (total: number, item: { weightOfCoil: number }) => total + Number(item.weightOfCoil.toFixed(2)), 0
                );
                //DO NOT REMOVE
                // const totalDiscount = vas.slittingDetails?.reduce(
                //     (total: number, item: { discount: number }) => total + item.discount, 0
                // );

                const taxableAmount = Number((((totalSlittingWeight ?? 0) * (slitting?.unitPrice ?? 0)) - (slitting?.discount ?? 0)).toFixed(2));
                slitting.quantity = Number(totalSlittingWeight.toFixed(2));
                slitting.taxableAmount = Number(taxableAmount.toFixed(2));
                //DO NOT REMOVE
                // slitting.discount = Number(totalDiscount.toFixed(2));
                vasDetailsListToShow.push(slitting);
            }
        });

        if (JSON.stringify(vasDetailsListToShow) !== JSON.stringify(vasDetailsExtractedRef.current)) {
            vasDetailsExtractedRef.current = vasDetailsListToShow;
            formik.setFieldValue('vasDetailsExtracted', vasDetailsListToShow);
        }

        const totalVASQuantity = Number((vasDetailsListToShow.reduce((total, product) => total + (product?.quantity ?? 0), 0)).toFixed(2));
        const totalVASTaxableCharges = Number(vasDetailsListToShow.reduce((total, product) => total + (product?.taxableAmount ?? 0), 0).toFixed(2));
        formik.setFieldValue('totalCharges.totalVASQuantity', totalVASQuantity);
        formik.setFieldValue('totalCharges.totalVASTaxableCharges', totalVASTaxableCharges);
        formik.setFieldValue('gstDetails[1].taxableAmount', totalVASTaxableCharges);

        return;
    }

    useEffect(() => {
        const updatedVasDetails = formik.values.vasDetails?.map(vasDetail => {
            if (vasDetail?.shearingDetails && vasDetail?.shearingDetails?.length > 0) {
                const matchingShearingDetail = formik.values.vasDetailsExtracted.find(
                    extractedDetail =>
                        extractedDetail?.productId === vasDetail?.productId &&
                        extractedDetail?.type === 'shearing'
                );

                if (matchingShearingDetail) {
                    const updatedShearingDetails = vasDetail?.shearingDetails.map(detail => {
                        //DO NOT REMOVE
                        // const discount = ((matchingShearingDetail?.discount ?? 0) * (detail.weight / (matchingShearingDetail?.quantity ?? 0))) ?? 0;
                        const discount = matchingShearingDetail?.discount ?? 0;
                        const quantity = matchingShearingDetail?.quantity ?? 0;
                        const unitPrice = matchingShearingDetail?.unitPrice ?? 0;
                        const taxableAmount = Number(((quantity * unitPrice) - discount).toFixed(2));
                        return { ...detail, discount, taxableAmount };
                    });

                    vasDetail = { ...vasDetail, shearingDetails: updatedShearingDetails };
                }
            }

            if (vasDetail?.slittingDetails && vasDetail?.slittingDetails?.length > 0) {
                const matchingSlittingDetail = formik.values.vasDetailsExtracted.find(
                    extractedDetail =>
                        extractedDetail?.productId === vasDetail?.productId &&
                        extractedDetail?.type === 'slitting'
                );

                if (matchingSlittingDetail) {
                    const updatedSlittingDetails = vasDetail.slittingDetails.map(detail => {
                        //DO NOT REMOVE
                        // const discount = ((matchingSlittingDetail?.discount ?? 0) * (detail.weightOfCoil / (matchingSlittingDetail?.quantity ?? 0))) ?? 0;
                        const discount = matchingSlittingDetail?.discount ?? 0;
                        const quantity = matchingSlittingDetail?.quantity ?? 0;
                        const unitPrice = matchingSlittingDetail?.unitPrice ?? 0;
                        const taxableAmount = Number(((quantity * unitPrice) - discount).toFixed(2));
                        return { ...detail, discount, taxableAmount };
                    });

                    vasDetail = { ...vasDetail, slittingDetails: updatedSlittingDetails };
                }
            }

            return vasDetail;
        });

        const vasDetailsListToShow: any[] = formik.values?.vasDetailsExtracted ?? [];
        const totalVASQuantity = Number((vasDetailsListToShow?.reduce((total: number, product: IInstantQuotationVASExtractedDetails) => total + (product?.quantity ?? 0), 0)).toFixed(2));
        const totalVASTaxableCharges = Number(vasDetailsListToShow?.reduce((total: number, product: IInstantQuotationVASExtractedDetails) => total + (product?.taxableAmount ?? 0), 0).toFixed(2));
        formik.setFieldValue('totalCharges.totalVASQuantity', totalVASQuantity);
        formik.setFieldValue('totalCharges.totalVASTaxableCharges', totalVASTaxableCharges);
        formik.setFieldValue('gstDetails[1].taxableAmount', totalVASTaxableCharges);


        if (JSON.stringify(updatedVasDetails) !== JSON.stringify(vasDetailsRef.current)) {
            vasDetailsRef.current = updatedVasDetails;
            formik.setFieldValue('vasDetails', updatedVasDetails);
            return;
        }

    }, [formik.values.vasDetailsExtracted]);

    useEffect(() => {
        const additionalCharges = formik.values.additionalCharges as IInstantQuotationCharges[];
        const updatedAdditionalCharges = additionalCharges.map(additionalCharge => {
            const totalAmount = Number(((additionalCharge?.quantity ?? 0) * (additionalCharge?.charge ?? 0)).toFixed(2));
            return { ...additionalCharge, totalAmount };
        });

        if (JSON.stringify(updatedAdditionalCharges) !== JSON.stringify(additionalChargesRef.current)) {
            additionalChargesRef.current = updatedAdditionalCharges;
            formik.setFieldValue('additionalCharges', updatedAdditionalCharges);
            return;
        }

        const totalAdditionalCharges = Number(updatedAdditionalCharges.reduce((total, additionalCharge) => total + (additionalCharge?.totalAmount ?? 0), 0).toFixed(2));
        formik.setFieldValue('totalCharges.totalAdditionalCharges', totalAdditionalCharges);
        formik.setFieldValue('gstDetails[2].taxableAmount', totalAdditionalCharges);
    }, [formik.values.additionalCharges]);

    useEffect(() => {
        const gstDetails = formik.values.gstDetails as IInstantQuotationTaxDetails[];

        const updatedGstDetails = gstDetails.map(gst => {
            const cgstAmount = Number(((gst?.taxableAmount ?? 0) * (gst?.cgstRate ?? 0) / 100).toFixed(2));
            const sgstAmount = Number(((gst?.taxableAmount ?? 0) * (gst?.cgstRate ?? 0) / 100).toFixed(2));
            const igstAmount = Number(((gst?.taxableAmount ?? 0) * (gst?.igstRate ?? 0) / 100).toFixed(2));
            const totalTaxAmount = Number((cgstAmount + sgstAmount + igstAmount).toFixed(2));
            return { ...gst, cgstAmount, sgstAmount, igstAmount, totalTaxAmount };
        });

        if (JSON.stringify(updatedGstDetails) !== JSON.stringify(gstDetailsRef.current)) {
            gstDetailsRef.current = updatedGstDetails;
            formik.setFieldValue('gstDetails', updatedGstDetails);
        }

        const totalTaxableAmount = Number(updatedGstDetails.reduce((total, gst) => total + (gst?.totalTaxAmount > 0 ? (gst?.taxableAmount ?? 0) : 0), 0).toFixed(2));
        const totalCGSTTaxableAmount = Number(updatedGstDetails.reduce((total, gst) => total + (gst?.cgstAmount ?? 0), 0).toFixed(2));
        const totalSGSTTaxableAmount = Number(updatedGstDetails.reduce((total, gst) => total + (gst?.sgstAmount ?? 0), 0).toFixed(2));
        const totalIGSTTaxableAmount = Number(updatedGstDetails.reduce((total, gst) => total + (gst?.igstAmount ?? 0), 0).toFixed(2));
        const totalTaxAmount = Number(updatedGstDetails.reduce((total, gst) => total + (gst?.totalTaxAmount ?? 0), 0).toFixed(2));

        formik.setFieldValue('totalCharges.totalTaxableAmount', totalTaxableAmount);
        formik.setFieldValue('totalCharges.totalCGST', totalCGSTTaxableAmount);
        formik.setFieldValue('totalCharges.totalSGST', totalSGSTTaxableAmount);
        formik.setFieldValue('totalCharges.totalIGST', totalIGSTTaxableAmount);
        formik.setFieldValue('totalCharges.totalTaxAmount', totalTaxAmount);
    }, [formik.values.gstDetails]);

    useEffect(() => {
        const totalProductAndVASAmount = Number(((formik.values?.totalCharges?.totalProductTaxableCharges ?? 0) + (formik.values?.totalCharges?.totalVASTaxableCharges ?? 0)).toFixed(2));
        formik.setFieldValue('totalCharges.totalProductAndVASAmount', totalProductAndVASAmount);
    }, [formik.values?.totalCharges?.totalProductTaxableCharges, formik.values?.totalCharges?.totalVASTaxableCharges]);

    useEffect(() => {
        const upfrontAmount = Number((((formik.values.totalCharges?.grandTotal ?? 0) * (formik.values?.upfrontPaymentInformation?.percentage ?? 0)) / 100).toFixed(2));
        const deliveryPaymentAmount = Number((((formik.values.totalCharges?.grandTotal ?? 0) * (formik.values?.deliveryPaymentInformation?.percentage ?? 0)) / 100).toFixed(2));
        formik.setFieldValue('upfrontPaymentInformation.amount', upfrontAmount);
        formik.setFieldValue('deliveryPaymentInformation.amount', deliveryPaymentAmount);
    }, [formik.values.totalCharges, formik.values?.upfrontPaymentInformation?.percentage, formik.values?.deliveryPaymentInformation?.percentage]);

    useEffect(() => {
        const totalAmountWithoutTax = Number(((formik?.values?.totalCharges?.totalAdditionalCharges ?? 0) + (formik?.values?.totalCharges?.totalProductAndVASAmount ?? 0)).toFixed(2));
        const tcsAmount = Number((totalAmountWithoutTax * 0.01).toFixed(2));
        const grandTotal = Number((tcsAmount + (formik.values?.totalCharges?.totalProductAndVASAmount ?? 0) + (formik.values?.totalCharges?.totalAdditionalCharges ?? 0) + (formik.values?.totalCharges?.totalTaxAmount ?? 0)).toFixed(2));

        formik.setFieldValue('totalCharges.tcs', tcsAmount);
        formik.setFieldValue('totalCharges.grandTotal', grandTotal);
    }, [formik.values.totalCharges]);

    useEffect(() => {
        const fetchInstantQuotation = async () => {
            if (instantQuotationId != null) {
                try {
                    const instantQuotation = await instantQuotationService.getInstantQuotation(instantQuotationId);
                    setCurrentStatus(instantQuotation.data.data.status)
                    formik.setValues({
                        ...formik.values,
                        ...instantQuotation.data.data
                    });
                } catch (error) {
                    console.error("Error fetching instant quotation:", error);
                }
            }
        };

        fetchInstantQuotation();
    }, [instantQuotationId]);

    const handleSave = () => {
        formik.validateForm().then(errors => {
            if (Object.keys(errors).length === 0) {
                if (isLoading) return;
                setCurrentStep(MODE.REVIEW);
            } else {
                showSnackbar('error', 'Please fill all the required fields')
            }
        });
    };

    const handleBackToViewPage = () => {
        navigate(ADMIN_ROUTES.INSTANT_QUOTATION_LIST);
    };

    const isEditable = useMemo(() => {
        if (instantQuotationMode === MODE.VIEW.toLowerCase() || (instantQuotationMode === MODE.UPDATE.toLowerCase() && (formik.values.status !== STATUS_CONST.DRAFT && formik.values.status !== STATUS_CONST.IN_PROGRESS))) {
            return false;
        }
        return true;
    }, [instantQuotationMode, formik.values.status]);

    const isSameStateGST = useMemo(() => {
        const billToGST = formik.values?.billToAddressDetails?.gstIn;

        if (billToGST != null) {
            return billToGST.slice(0, 2) === '27';
        }
        return false;
    }, [formik.values?.billToAddressDetails?.gstIn]);

    useEffect(() => {
        if (formik.values?.billToAddressDetails?.companyName && formik.values?.message === '') {
            formik.setFieldValue('message', `Dear ${formik.values?.billToAddressDetails?.companyName ?? ''}`)
        }
    }, [formik.values?.billToAddressDetails?.companyName])

    useEffect(() => {
        setFilterSections([GENERIC_FILTER_SECTIONS.UPC, GENERIC_FILTER_SECTIONS.MATERIAL_FILTER, GENERIC_FILTER_SECTIONS.OTHER]);
    }, [])

    const handleFilterClear = () => {
        setUpcFilterData({});
    }

    const clearAllData = () => {
        setUpcFilterData({});
    }

    const fetchUpc = async () => {
        if (upcFilterData.productIds && upcFilterData.productIds.length > 0) {
            try {
                const params = { upcTypes: PRODUCT_SECTION.BRAND_UPC };
                const existingProductCodes = formik.values.productDetails.map(detail => detail.productId);
                const newProductIds = upcFilterData.productIds.filter(id => !existingProductCodes.includes(id));
                let newProductDetails: IInstantQuotationProductDetails[] = [...formik.values.productDetails];

                for (const productId of newProductIds) {
                    const response = await masterUpcService.getBrandUpcByIdV2(productId, params);
                    if (response?.status === HTTP_STATUS.OK) {
                        const productDetails = response.data.data;
                        const { upcCode, product, attributesMap, shape } = productDetails;
                        const thicknessAttr = attributesMap?.SPECIFICATION?.find((attr: any) => attr.name === 'Thickness');
                        const thickness = thicknessAttr ? `${thicknessAttr.attributeOptions} ${thicknessAttr.uom}` : '';

                        const lengthAttr = attributesMap?.SPECIFICATION?.find((attr: any) => attr.name === 'Length');
                        const length = lengthAttr ? `${lengthAttr.attributeOptions} ${lengthAttr.uom}` : '';

                        const widthAttr = attributesMap?.SPECIFICATION?.find((attr: any) => attr.name === 'Width');
                        const width = widthAttr ? `${widthAttr.attributeOptions} ${widthAttr.uom}` : '';

                        const standardResponse = await standardService.getAllStandards({ search: productDetails?.standard ?? '', page: 0, size: 1, sort: 'createdAt,desc' });
                        const HsnCode = standardResponse.data?.data?.content?.[0]?.hsnCodes?.split(',')[0] || '';
                        const shapes = shape ? getShapeName(shape) || shape : '';

                        const productCodeWithDetails = `${upcCode ?? ''} ${productDetails?.brand ?? ''} ${productDetails?.productCategory ?? ''} ${shapes ?? ''} ${thickness ? `Th: ${thickness}` : ''} ${length ? `L: ${length}` : ''} ${width ? `W: ${width}` : ''}`;
                        const productDetail: IInstantQuotationProductDetails = {
                            productId: productId,
                            productCode: productCodeWithDetails,
                            location: '',
                            hsnCode: HsnCode || '',
                            quantity: null,
                            unitPrice: null,
                            discount: null,
                            taxableAmount: null,
                        };
                        newProductDetails = [...newProductDetails, productDetail];
                    }
                }
                formik.setFieldValue('productDetails', newProductDetails);
            } catch (error) {
                console.error("Error fetching UPC details:", error);
            }
        }
    };

    const onFilterSubmit = () => { fetchUpc(); }

    const deleteProduct = async (index: number) => {
        const updatedProductDetails = [...formik.values.productDetails];
        updatedProductDetails.splice(index, 1);
        formik.setFieldValue('productDetails', updatedProductDetails);
    };

    const deleteVas = async (index: number) => {
        const updatedVasDetails = [...formik.values.vasDetails];
        const updatedVasExtractedDetails = [...formik.values.vasDetailsExtracted];
        const deletedDetails = formik.values.vasDetailsExtracted[index];
        const existingVasDetailIndex = formik.values.vasDetails?.findIndex(
            (vasDetail) => vasDetail?.productId === deletedDetails?.productId
        );
        if (deletedDetails.type === 'slitting') {
            if (formik.values.vasDetails[existingVasDetailIndex]?.shearingDetails?.length === 0) {
                updatedVasDetails.splice(existingVasDetailIndex, 1);
                formik.setFieldValue('vasDetails', updatedVasDetails);
            } else {
                formik.setFieldValue(`vasDetails[${existingVasDetailIndex}].slittingDetails`, []);
            }
        } else {
            if (formik.values.vasDetails[existingVasDetailIndex]?.slittingDetails?.length === 0) {
                updatedVasDetails.splice(existingVasDetailIndex, 1);
                formik.setFieldValue('vasDetails', updatedVasDetails);
            } else {
                formik.setFieldValue(`vasDetails[${existingVasDetailIndex}].shearingDetails`, []);
            }
        }
        updatedVasExtractedDetails.splice(index, 1);
        formik.setFieldValue('vasDetailsExtracted', updatedVasExtractedDetails);
    };

    const handleDiscountChange = (index: number, e: any) => {
        const discount = parseFloat(e.target.value) || 0;
        const quantity = formik.values.vasDetailsExtracted[index]?.quantity || 0;
        const unitPrice = formik.values.vasDetailsExtracted[index]?.unitPrice || 0;
        const taxableAmount = Number((((quantity ?? 0) * (unitPrice ?? 0)) - (discount ?? 0)).toFixed(2));;
        formik.setFieldValue(`vasDetailsExtracted[${index}].discount`, discount);
        formik.setFieldValue(`vasDetailsExtracted[${index}].taxableAmount`, taxableAmount);
    };

    if (currentStep === MODE.ADD) {
        return (
            <form onSubmit={formik.handleSubmit}>
                Create a New Quote
                <div className="grid gap-6">
                    <QuotationDetailsTemplate formik={formik} isEditable={isEditable} mode={instantQuotationMode ?? 'add'} currentStatus={currentStatus} />
                    <div className="grid gap-6">
                        <div className="grid gap-3">
                            <span className={`font-medium ${classes.textHeader}`}>Part A</span>
                            <DetailsTableTemplate
                                columnNames={[
                                    'Sr No',
                                    'Product description',
                                    'Location',
                                    'HSN Code',
                                    'Qty.(MT)',
                                    'Unit Price/MT',
                                    'Discount',
                                    'Taxable Amt.',
                                    'Actions',
                                ]}
                                triggerComponent={
                                    <UpcMpcSearchDialogModal
                                        sections={filterSection}
                                        productType={PRODUCT_SECTION.BRAND_UPC}
                                        filterData={upcFilterData}
                                        setFilterData={setUpcFilterData}
                                        handleFilterClear={handleFilterClear}
                                        onFilterSubmit={onFilterSubmit}
                                        triggerButton={
                                            <HoverButton
                                                variant="tertiaryContained"
                                                label="+ Add Details"
                                                buttonWithImg={true}
                                                handleCloseIcon={clearAllData}
                                                disabled={!isEditable}
                                            />
                                        }
                                    />
                                }
                                details={formik.values.productDetails}
                                renderRow={(detail, index) => (
                                    <>
                                        <div className="m-4">{index + 1}</div>
                                        <div className='col-span-2 text-sm'>{formik?.values?.productDetails[index]?.productCode || ''}</div>
                                        <div>

                                            <TextFieldV2
                                                {...formik.getFieldProps(`productDetails[${index}].location`)}
                                                className="h-fit"
                                                onChange={formik.handleChange}
                                                variant="outlined"
                                                disabled={!isEditable}
                                                error={formik.touched?.productDetails?.[index]?.location &&
                                                    Boolean((formik.errors?.productDetails as FormikErrors<IInstantQuotationProductDetails>[])?.[index]?.location)
                                                }
                                                helperText={formik.touched?.productDetails?.[index]?.location &&
                                                    Boolean((formik.errors?.productDetails as FormikErrors<IInstantQuotationProductDetails>[])?.[index]?.location) ? (formik.errors?.productDetails as FormikErrors<IInstantQuotationProductDetails>[])?.[index]?.location : ''}
                                            />
                                        </div>
                                        <div>
                                            <TextFieldV2
                                                className="h-fit"
                                                {...formik.getFieldProps(`productDetails[${index}].hsnCode`)}
                                                onChange={formik.handleChange}
                                                variant="outlined"
                                                disabled={!isEditable}
                                                error={formik.touched?.productDetails?.[index]?.hsnCode &&
                                                    Boolean((formik.errors?.productDetails as FormikErrors<IInstantQuotationProductDetails>[])?.[index]?.hsnCode)
                                                }
                                                helperText={formik.touched?.productDetails?.[index]?.hsnCode &&
                                                    Boolean((formik.errors?.productDetails as FormikErrors<IInstantQuotationProductDetails>[])?.[index]?.hsnCode) ? (formik.errors?.productDetails as FormikErrors<IInstantQuotationProductDetails>[])?.[index]?.hsnCode : ''}
                                            />
                                        </div>
                                        <div>
                                            <TextFieldV2
                                                type="number"
                                                {...formik.getFieldProps(`productDetails[${index}].quantity`)}
                                                onChange={formik.handleChange}
                                                variant="outlined"
                                                inputProps={{ min: 0 }}
                                                disabled={!isEditable}
                                                error={formik.touched?.productDetails?.[index]?.quantity &&
                                                    Boolean((formik.errors?.productDetails as FormikErrors<IInstantQuotationProductDetails>[])?.[index]?.quantity)
                                                }
                                                helperText={formik.touched?.productDetails?.[index]?.quantity &&
                                                    Boolean((formik.errors?.productDetails as FormikErrors<IInstantQuotationProductDetails>[])?.[index]?.quantity) ? (formik.errors?.productDetails as FormikErrors<IInstantQuotationProductDetails>[])?.[index]?.quantity : ''}
                                            />
                                        </div>
                                        <div>
                                            <TextFieldV2
                                                type="number"
                                                {...formik.getFieldProps(`productDetails[${index}].unitPrice`)}
                                                onChange={formik.handleChange}
                                                variant="outlined"
                                                inputProps={{ min: 0, step: "0.01", pattern: "^[0-9]*[.,]?[0-9]{0,2}$" }}
                                                disabled={!isEditable}
                                                error={formik.touched?.productDetails?.[index]?.unitPrice &&
                                                    Boolean((formik.errors?.productDetails as FormikErrors<IInstantQuotationProductDetails>[])?.[index]?.unitPrice)
                                                }
                                                helperText={formik.touched?.productDetails?.[index]?.unitPrice &&
                                                    Boolean((formik.errors?.productDetails as FormikErrors<IInstantQuotationProductDetails>[])?.[index]?.unitPrice) ? (formik.errors?.productDetails as FormikErrors<IInstantQuotationProductDetails>[])?.[index]?.unitPrice : ''}
                                            />
                                        </div>
                                        <TextFieldV2
                                            name={`productDetails[${index}].discount`}
                                            value={formik?.values?.productDetails[index]?.discount || ''}
                                            onChange={formik.handleChange}
                                            type="number"
                                            inputProps={{ min: 0 }}
                                            variant="outlined"
                                            disabled={!isEditable}
                                        />
                                        <TextFieldV2
                                            name={`productDetails[${index}].taxableAmount`}
                                            value={formik?.values?.productDetails[index]?.taxableAmount || ''}
                                            onChange={formik.handleChange}
                                            type="number"
                                            inputProps={{ min: 0 }}
                                            variant="outlined"
                                            disabled
                                        />
                                        <div className="m-4">
                                            <img
                                                src={deleteIcon}
                                                alt="Actions"
                                                className="cursor-pointer"
                                                onClick={() => isEditable && deleteProduct(index)}
                                            />
                                        </div>
                                    </>
                                )}
                            />
                        </div>
                        <div className="flex justify-end gap-x-8">
                            <TextFieldV2
                                label='Total Qty. (MT)'
                                placeholder='Enter Data'
                                name={`totalCharges.totalProductQuantity`}
                                value={formik?.values?.totalCharges?.totalProductQuantity || ''}
                                onChange={formik.handleChange}
                                disabled
                            />
                            <TextFieldV2
                                label='Sub-Total(A)'
                                placeholder='Enter Data'
                                name={`totalCharges.totalProductTaxableCharges`}
                                value={formik?.values?.totalCharges?.totalProductTaxableCharges || ''}
                                onChange={formik.handleChange}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="grid gap-6">
                        <span className={`font-medium ${classes.textHeader}`}>Part B</span>
                        <DetailsTableTemplate
                            columnNames={[
                                'Sr No',
                                'Product description',
                                'Location',
                                'HSN Code',
                                'Qty.(MT)',
                                'Unit Price/MT',
                                'Discount',
                                'Taxable Amt.',
                                'Actions',
                            ]}
                            triggerComponent={
                                <AddVasDetailsTemplate
                                    dialogOpen={isModalOpen}
                                    setDialogOpen={setIsModalOpen}
                                    formik={formik}
                                    setVasDetails={setVasDetails}
                                    vasDetails={vasDetails}
                                    setBrandUpcIdTo={setBrandUpcTo}
                                    triggerButton={
                                        <HoverButton
                                            variant="tertiaryContained"
                                            label="+ Add VAS"
                                            buttonWithImg={true}
                                            onClick={() => setIsModalOpen(true)}
                                            disabled={!isEditable}
                                        />
                                    }
                                />
                            }
                            details={formik.values?.vasDetailsExtracted}
                            renderRow={(detail, index) => (
                                <>
                                    <div className="m-4">{index + 1}</div>
                                    <div className='col-span-2 text-sm'>{formik.values?.vasDetailsExtracted?.[index]?.productCode || ''}</div>
                                    <TextFieldV2
                                        value={formik.values?.vasDetailsExtracted?.[index]?.location || ''}
                                        variant="outlined"
                                        disabled
                                    />
                                    <TextFieldV2
                                        value={formik.values?.vasDetailsExtracted?.[index]?.hsnCode || ''}
                                        variant="outlined"
                                        disabled
                                    />
                                    <TextFieldV2
                                        type="number"
                                        value={formik.values?.vasDetailsExtracted?.[index]?.quantity || ''}
                                        variant="outlined"
                                        inputProps={{ min: 0 }}
                                        disabled
                                    />
                                    <TextFieldV2
                                        type="number"
                                        value={formik.values?.vasDetailsExtracted?.[index]?.unitPrice || ''}
                                        variant="outlined"
                                        inputProps={{ min: 0, step: "0.01", pattern: "^[0-9]*[.,]?[0-9]{0,2}$" }}
                                        disabled
                                    />
                                    <TextFieldV2
                                        {...formik.getFieldProps(`vasDetailsExtracted[${index}].discount`)}
                                        onChange={(e) => {
                                            handleDiscountChange(index, e);
                                        }}
                                        type="number"
                                        inputProps={{ min: 0 }}
                                        variant="outlined"
                                        disabled={!isEditable}
                                    />
                                    <TextFieldV2
                                        value={formik.values?.vasDetailsExtracted?.[index]?.taxableAmount || ''}
                                        type="number"
                                        inputProps={{ min: 0 }}
                                        variant="outlined"
                                        disabled
                                    />
                                    <div className="m-4">
                                        <img
                                            src={deleteIcon}
                                            alt="Actions"
                                            className="cursor-pointer"
                                            onClick={() => isEditable && deleteVas(index)}
                                        />
                                    </div>
                                </>
                            )}
                        />
                        <div className="flex justify-end gap-x-8">
                            <TextFieldV2
                                label='Total Qty. (MT)'
                                placeholder='Enter Data'
                                name={`totalCharges.totalVASQuantity`}
                                value={formik?.values?.totalCharges?.totalVASQuantity || ''}
                                onChange={formik.handleChange}
                                disabled
                            />
                            <TextFieldV2
                                label='Sub-Total(B)'
                                placeholder='Enter Data'
                                name={`totalCharges.totalVASTaxableCharges`}
                                value={formik?.values?.totalCharges?.totalVASTaxableCharges || ''}
                                onChange={formik.handleChange}
                                disabled
                            />
                        </div>
                    </div>

                    <AdditionalChargesTemplate formik={formik} isEditable={isEditable} />
                    <GstTaxDetailsTemplate formik={formik} isEditable={isEditable} isSameStateGST={isSameStateGST} />
                    <DeliveryInformationTemplate formik={formik} isEditable={isEditable} />
                    <PointOfContacTemplate formik={formik} isEditable={isEditable} />
                </div>
                <div className="flex mt-6 relative">
                    <div className="!absolute right-0 top-0 flex items-center gap-2 pb-4">
                        <ButtonV2
                            variant="tertiaryContained"
                            label="Back"
                            onClick={handleBackToViewPage}
                        />
                        {instantQuotationMode !== MODE.VIEW.toLowerCase() && <ButtonV2
                            variant="contained"
                            label="Review"
                            onClick={() => { formik.handleSubmit() }}
                            disabled={isLoading}
                        />}
                    </div>
                </div>
            </form>
        )
    } else if (currentStep === MODE.REVIEW) {
        return (<QuotationReviewPage formik={formik} setCurrentStep={setCurrentStep} saveInstantQuotation={saveInstantQuotation}></QuotationReviewPage>)
    } else {
        return null;
    }
}