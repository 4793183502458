import { AUCTION } from '../../../Constants/Procurement/LiveAuction/text';
import LiveAuctionCustomHeading from './LiveAuctionCustomHeading';

interface PriceDetailsProps {
  live: boolean;
  auctionStartAmount: number;
  minBidStep: number;
  reserveAmount: number;
  targetAmount: number;
  currentBid: number | undefined;
}

const PriceDetails = ({
  live,
  auctionStartAmount,
  minBidStep,
  reserveAmount,
  targetAmount,
  currentBid,
}: PriceDetailsProps) => {
  const priceColor = currentBid
    ? currentBid <= targetAmount
      ? 'bg-approved'
      : currentBid <= reserveAmount
      ? 'bg-pending'
      : 'bg-badge-warning'
    : '';
  return (
    <div>
      <div className="text-text-color font-semibold text-2xl py-3 border-b border-cgray-3">
        {AUCTION.PriceDetails}
      </div>
      <div className="flex gap-4 items-center mt-4">
        <div className="flex w-full divide-x-2 p-3 divide-blue divide-opacity-10 gap-10 bg-cgray-17 rounded-md">
          <LiveAuctionCustomHeading
            label={AUCTION.BidIncrement}
            variant="small"
            value={'₹' + minBidStep}
          />
          <div className="pl-10">
            <LiveAuctionCustomHeading
              label={AUCTION.StartPrice}
              variant="small"
              value={'₹' + auctionStartAmount}
            />
          </div>
          <div className="pl-10">
            <LiveAuctionCustomHeading
              label={AUCTION.ReservePrice}
              variant="small"
              value={'₹' + reserveAmount}
            />
          </div>
          <div className="pl-10">
            <LiveAuctionCustomHeading
              label={AUCTION.TargetPrice}
              variant="small"
              value={'₹' + targetAmount}
            />
          </div>
        </div>
        {live && currentBid ? (
          <div
            className={`w-[186px] text-nowrap p-2 text-white rounded-md text-center ${priceColor}`}
          >
            <div className="text-sm">Current Bid</div>
            <div className="font-black text-2xl">₹ {currentBid}</div>
          </div>
        ) : (
          ''
        )}
      </div>
      {live && currentBid ? (
        <div
          className={`text-center mt-4 text-[20px] font-semibold p-3 text-white rounded-md ${priceColor}`}
        >
          {currentBid <= targetAmount
            ? AUCTION.PriceAchieved
            : currentBid <= reserveAmount
            ? AUCTION.ReserveAchieved
            : AUCTION.PriceNotAchieved}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default PriceDetails;
