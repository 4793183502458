import { useNavigate } from "react-router-dom";
import Table, { ColumnType } from "../../../organisms/Table";
import { IBrand, IBrandFilter } from "../../../pages/AdminMaster/Brand/BrandList.page";
import viewEyeIcon from "./../../../../assets/icons/viewEyeIcon.svg";
import editPenIcon from "./../../../../assets/icons/editPenIcon.svg";
import { IPagination } from "../../../../utils/types";
import { ADMIN_ROUTES, sortOptions, statusOptions } from "../../../../utils/constant";
import { enumToString, makeRoute } from "../../../../utils/helper";
import moment from "moment";
import TextField from "../../../atoms/TextField";
import Select from "../../../atoms/Select";
import ResourceStatus from "../../../atoms/ResourceStatus/ResourceStatus";

interface IBrandListTemplateProps {
	brands: IBrand[];
	pagination: IPagination;
	handleFiltersChange: (event: any) => void;
	handlePaginationChange: any;
	handleRowsPerPageChange: any;
	brandFilters: IBrandFilter;
}

const BrandListTemplate: React.FC<IBrandListTemplateProps> = ({ brands, pagination, handleFiltersChange, handlePaginationChange, handleRowsPerPageChange, brandFilters }) => {

	const navigate = useNavigate();

	const schema = {
		id: "1",
		title: "",
		pagination: {
			total: pagination.totalRecords,
			currentPage: pagination.currentPage,
			isVisible: true,
			limit: pagination.pageSize,
			handleChangePage: handlePaginationChange,
			handleChangeRowsPerPage: handleRowsPerPageChange
		},
		columns: [
			{ label: "Sr No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
			{ label: "Brand Name", key: "name", type: "string" as ColumnType, props: { className: '' } },
			{ label: "Manufacturer Name", key: "manufacturerId", type: "number" as ColumnType, props: { className: '' } },
			{ label: "Created Date", key: "createdAt", type: "datetime" as ColumnType, props: { className: '' } },
			{ label: "Last Modified", key: "updatedAt", type: "datetime" as ColumnType, props: { className: '' } },
			{ label: "Status", key: "status", component: ({ value }: { value: string }) => <ResourceStatus status={value} />, type: "custom" as ColumnType, props: {} }, 
			{ label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } },
		],
	};

	const handleViewClick = (id: number) => (e: any) => {
		navigate(makeRoute(ADMIN_ROUTES.BRAND_VIEW, {params:  { id: id } }));
	};

	const handleEditClick = (id: number) => (e: any) => {
		navigate(makeRoute(ADMIN_ROUTES.BRAND_UPDATE, {params:  { id: id } }));
	};
	  
	const Action = (id: number) => {
		return <div>
			<button onClick={ handleViewClick(id) }><img src={ viewEyeIcon } alt={ viewEyeIcon } /></button>
			<button onClick={ handleEditClick(id) }><img src={ editPenIcon } alt={ editPenIcon } /></button>
		</div>
	}

	const records = brands.map((brand: IBrand, index: number) => [
		pagination.currentPage * pagination.pageSize + index + 1,
		brand.name,
		brand.manufacturerName,
		moment(brand.createdAt).format("YYYY-MM-DD HH:mm:ss"),
		moment(brand.updatedAt).format("YYYY-MM-DD HH:mm:ss"),
		enumToString(brand.status),
		Action(brand.id)
	]);

	return (
		<div className='grid gap-y-1.5'>
			<div className="flex justify-between">
			    <div className="flex gap-x-4">
					<div>
						<Select
							variant="outlined"
							className="w-32"
							label="Status"
							name="status"
							id="status"
							value={ brandFilters.status }
							onChange={ (e) => handleFiltersChange(e) }
							options={ statusOptions }
						/>
					</div>
					<div>
						<Select
							variant="outlined"
							className='w-fit'
							id="sort"
							label="Sort"
							name="sort"
							value={ brandFilters.sort }
							onChange={ (e) => handleFiltersChange(e) }
							options={ sortOptions }
						/>
					</div>
				</div>
				<TextField
					className="w-96"
					type="text"
					label="Search by Brand or Manufacturer Name"
					variant="outlined"
					name="name"
					value={ brandFilters.name }
					onChange={ handleFiltersChange }
				/>
			</div>
			<Table schema={ schema } records={ records } />
		</div>
	)
}

export default BrandListTemplate;
