import Button from "@mui/material/Button";
import cancelIcon from "./../../../../Assets/Icons/Vector.svg";
import React, { useEffect, useState } from "react";
import { FormControl, TextField } from "@mui/material";
import { formatDate } from "../../../../Core/Handlers/DateFormatter";
import { useFormik } from "formik";
import * as yup from "yup";
import UOMJsonData from '../../Attributes/UOM.json'
import AutoCompleteTextField from "../Standard/AutoCompleteInputField";
import debounce from 'lodash/debounce';
import { toCamelCase } from "../../../../../utils/helper";

const MechanicalPropertiesDetails = ({
  type,
  onClose,
  record,
  handleAction,
  associatedGrade = null,
  itemindex = null,
  fullData = []
}) => {
  const maxId = fullData.reduce((max, obj) => (obj.id > max ? obj.id : max), 0);

  // const [formData, setFormData] = useState({
  //   type: "MECHANICAL",
  //   id: record ? record?.id : maxId + 1,
  //   name: record ? record.name : "",
  //   uom: record ? record.uom : "",
  //   roomTemperature: record ? record.roomTemperature : "",
  //   otherTemperature: record ? record.otherTemperature : "",
  //   createdAt: record ? record.createdAt : formatDate(new Date()),
  //   updatedAt: record ? record.updatedAt : formatDate(new Date()),
  // });

  const mpInitialValues = {
    type: "MECHANICAL",
    id: record ? record?.id : maxId + 1,
    name: record ? record.name : "",
    uom: record ? record.uom : "",
    roomTemperature: record ? record.roomTemperature : "",
    otherTemperature: record ? record.otherTemperature : "",
    createdAt: record ? record.createdAt : formatDate(new Date()),
    updatedAt: formatDate(new Date())
  };

  const validateFormikFields = yup.object({
    name: yup.string().required("Name is required"),
    uom: yup.string().required("UOM is required"),

    roomTemperature: yup
      .number()
      .typeError("Room Temperature must be a number")
      .required("Room Temperature is required"),
    otherTemperature: yup
      .number()
      .typeError("Other Temperature must be a number")
      .required("Other Temperature is required"),
  });

  const mpFormik = useFormik({
    initialValues: mpInitialValues,
    validationSchema: validateFormikFields,
    onSubmit: (values) => {
      handleAction(values, values?.id);
    },
  });
    
  const displayTitle = (data) => {
    if (data === "ADD") {
      return "Add";
    } else if (data === "VIEW") {
      return "View";
    } else if (data === "EDIT") {
      return "Edit";
    }
  };

  const disableComponent = (data) => {
    if (data === "VIEW") {
      return true;
    } else {
      return false;
    }
  };

  const displayNewKeyWord = (data) => {
    if (data === "ADD") {
      return "New";
    }
  };

  // const handleInputChange = (key, event) => {
  //   setFormData({ ...formData, [key]: event.target.value });
  // };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const fetchUOMData = async () => {
    try {
      const convertedDetails = convertUOMSuggestions(UOMJsonData)
      setUOMSuggestions(convertedDetails);
    } catch (error) {
      setUOMSuggestions([]);
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchUOMData()
  }, []);

  const convertUOMSuggestions = (suggestions) => {
    return Object.keys(suggestions).map((key, index) => ({
      label: key,
      id: index + 1,
      shortcode: suggestions[key].shortcode,
      fullDescription: suggestions[key].fullDescription,
    }));
  };


  const updateUOM = (uom) => {
    mpFormik.setFieldValue('uom', uom.label);
  }

  const [UOMSuggestions, setUOMSuggestions] = useState([]);
  const delayedSearchUOMFetch = debounce((value) => {
    fetchUOMData();
  }, 700);
 
  return (
    <form onSubmit={mpFormik.handleSubmit} onKeyDown={handleKeyDown} >
      <div className="relative bg-white w-[900px]  flex flex-col p-6 box-border items-start justify-start gap-[24px] text-left text-3xl text-text-color font-small-text">
        <div className="self-stretch flex flex-row pt-0 px-0 pb-[18px] items-center justify-start gap-[10px] border-b-[1px] border-solid border-input-border">
          <div className="flex-1 relative leading-[20px] font-semibold">
            {displayTitle(type)} {displayNewKeyWord(type)} Element
          </div>

          <Button onClick={onClose}>
            <img
              className="relative w-4 h-4 overflow-hidden shrink-0 object-cover"
              alt=""
              src={cancelIcon}
            />
          </Button>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[18px] text-base">
          <div className="self-stretch  flex flex-col items-center justify-start text-sm">
            <FormControl sx={{ width: "100%" }}>
              <TextField
                className={`h-[3rem] block w-full p-1 border border-gray-500 rounded-md focus:outline-none ring-blue-500 focus:border-blue-500 bg-gray`}
                id="outlined-basic"
                label="Grade"
                variant="outlined"
                fullWidth
                value={associatedGrade.name}
                disabled={true}
              />
            </FormControl>
          </div>

          <div className="self-stretch flex flex-row items-start justify-start gap-[18px]">
            <div className="flex-1 flex flex-col items-center justify-start">
              <FormControl fullWidth>
                <TextField
                  className={`h-[3rem] block w-full p-1 border border-gray-500 rounded-md focus:outline-none ring-blue-500 focus:border-blue-500 bg-gray`}
                  id="outlined-basic"
                  label="Select Property Name"
                  variant="outlined"
                  fullWidth

                  name="name"
                  value={mpFormik.values.name}
                  onChange={mpFormik.handleChange}
                  onBlur={(event) => {
                    const camelCasedValue = toCamelCase(
                      event.target.value
                    );
                    mpFormik.setFieldValue("name", camelCasedValue);
                    mpFormik.handleBlur(event);
                  }}
                  error={mpFormik.touched.name && Boolean(mpFormik.errors.name)}
                  InputProps={{
                    readOnly: disableComponent(type),
                  }}
                />
              </FormControl>
            </div>
            <div className="flex-1 flex flex-col items-center justify-start">
              <FormControl fullWidth>
                <AutoCompleteTextField
                  suggestions={UOMSuggestions}
                  updateDataSelection={updateUOM}
                  onSearch={delayedSearchUOMFetch}
                  textLable={"Select UOM"}
                  enableFlag={disableComponent(type)}
                  displayValue={mpFormik.values.uom}
                />
              </FormControl>
            </div>
          </div>

          <div className="self-stretch flex flex-row items-start justify-start gap-[18px]">
            <div className="flex-1 h-12 flex flex-col items-center justify-center">
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  className={`h-[3rem] block w-full p-1 border border-gray-500 rounded-md focus:outline-none ring-blue-500 focus:border-blue-500 bg-gray`}
                  id="outlined-basic"
                  label="Enter Room Temperature (0 - 9999.99)"
                  variant="outlined"
                  fullWidth

                  name="roomTemperature"
                  value={mpFormik.values.roomTemperature}
                  onChange={(event) => {
                    if (/^\d{0,4}(\.\d{0,2})?$/.test(event.target.value)) {
                      mpFormik.handleChange(event);
                    } else {
                      mpFormik.setErrors({
                        ...mpFormik.errors,
                        [event.target.name]: 'Invalid format',
                      });
                    }
                  }}
                  helperText={
                    mpFormik.touched.roomTemperature ? mpFormik.errors.roomTemperature : ""
                  }

                  onBlur={mpFormik.handleBlur}
                  error={
                    mpFormik.touched.roomTemperature &&
                    Boolean(mpFormik.errors.roomTemperature)
                  }
                  InputProps={{
                    readOnly: disableComponent(type),
                  }}
                />
              </FormControl>
            </div>
            <div className="flex-1 h-12 flex flex-col items-center justify-center">
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  className={`h-[3rem] block w-full p-1 border border-gray-500 rounded-md focus:outline-none ring-blue-500 focus:border-blue-500 bg-gray`}
                  id="outlined-basic"
                  label="Enter Other Temperature  (0 - 9999.99)"
                  variant="outlined"
                  fullWidth

                  name="otherTemperature"
                  value={mpFormik.values.otherTemperature}
                  onChange={(event) => {
                    if (/^\d{0,4}(\.\d{0,2})?$/.test(event.target.value)) {
                      mpFormik.handleChange(event);
                    } else {
                      mpFormik.setErrors({
                        ...mpFormik.errors,
                        [event.target.name]: 'Invalid format',
                      });
                    }
                  }}
                  helperText={
                    mpFormik.touched.otherTemperature ? mpFormik.errors.otherTemperature : ""
                  }
                  onBlur={mpFormik.handleBlur}
                  error={
                    mpFormik.touched.otherTemperature &&
                    Boolean(mpFormik.errors.otherTemperature)
                  }
                  InputProps={{
                    readOnly: disableComponent(type),
                  }}
                />
              </FormControl>
            </div>
          </div>
        </div>

        <div className="self-stretch flex flex-row items-center justify-end gap-[16px] text-mini text-text-color">
          <Button
            sx={{
              border: "1px solid blue",
              padding: "0.71rem .63rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
            }}
            variant="outlined"
            onClick={onClose}
          >
            <div className="relative leading-[1.38rem] font-semibold text-blue">
              Cancel
            </div>
          </Button>
          {type !== "VIEW" && (
            <button
              className="outlined rounded bg-blue overflow-hidden flex flex-row py-[0.81rem] px-[1rem] items-center justify-center text-[0.94rem] text-white"
              type="submit"
            >
              <div className="relative leading-[1.38rem] font-semibold">
                {type === "ADD" ? "Add Element" : "Update Element"}
              </div>
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default MechanicalPropertiesDetails;
