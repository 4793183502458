import React, { useEffect, useState } from 'react'
import { FormikProps } from 'formik';
import { createUseStyles } from 'react-jss';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { USCPayload } from '../../../../services/useVASMasterCategoryService';
import ButtonV2 from '../../../atoms/ButtonV2';
import { Attribute, SubAttribute } from '../../../../services/useAttributeServiceV2';
import StatusV2 from '../../../atoms/Status/StatusV2';
import { SERVICE_STATUS_OPTIONS } from '../../../../utils/constant';
import { USC_FORM_STATE } from '../../../pages/VASMaster/USC/USCCreate.page';
import { capitalizeFirstLetter } from '../../../../utils/helper';

interface ReviewFormTemplateProps {
    mode: string;
    onClose: () => void;
    formik: FormikProps<USCPayload>;
    setFormStateTo: (state: USC_FORM_STATE) => () => void;
}

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
    },
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    previousProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary600
    },
    activeProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary400
    },
    inActiveProgressBar: {
        backgroundColor: theme.palette.backgroundV2.tertiary.tertiary100
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    checkBox: {
        color: theme.palette.textV2.tertiary.tertiary300,
        '&.Mui-checked': {
            color: theme.palette.textV2.primary.primary400,
        },
    },
    value: {
        color: theme.palette.textV2.primary.primary950
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
    property: {
        color: theme.palette.textV2.tertiary.tertiary700,
    }
}));

const ReviewFormTemplate: React.FC<ReviewFormTemplateProps> = ({ mode, formik, setFormStateTo }) => {
    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const handleNext = () => {
        formik.setTouched({ superService: true, mainService: true, productServiceId: true, name: true, description: true, attributes: true, subAttributeIds: true, status: true }, true)
        const errors = Object.keys(formik.errors).find(key => ['superService', 'mainService', 'productServiceId', 'name', 'description', "attributes", "subAttributeIds", "status"].includes(key))
        if (errors) return;
        formik.handleSubmit()
    }

    return (
        <div className="grid gap-y-8" >
            {SnackBarComponent}

            <div className={`w-full m-auto flex justify-between gap-x-2 text-center text-sm `}>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.previousProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto text-sm`}>USC and Attributes Details</div>
                    </div>
                    <div className={`${classes.previousProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} text-sm my-auto`}>Review</div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>

            <div className='grid gap-y-4'>
                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>USC Details</div>
                    <div className='grid grid-cols-5 gap-x-4'>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>USC Title</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.name}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Linked Super Service</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.superService?.label}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Linked Main Service</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.mainService?.label}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Linked Service Category</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.productService?.label}</div>
                        </div>
                    </div>
                    <div className="grid gap-y-1">
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Description</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.description}</div>
                        </div>
                    </div>
                </div>

                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Attribute Management</div>
                    {
                        formik.values?.attributes.map((attribute: Attribute) => {
                            const hasValidSubAttributes = attribute.subAttributes.some(subAttribute =>
                                formik?.values?.subAttributeIds?.hasOwnProperty(subAttribute.id)
                            );
                            if (!hasValidSubAttributes) { return null; }
                            return (
                                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`} key={attribute.id}>
                                    <div className={`${classes.heading} text-base font-medium`}>{attribute.name}(Min-Max)</div>
                                    <div className='grid grid-cols-6 gap-4'>
                                        {
                                            attribute.subAttributes.filter(subAttribute => formik?.values?.subAttributeIds?.hasOwnProperty(subAttribute.id)).map((subAttribute: SubAttribute, index: number) => (
                                                <div className="grid gap-y-1" key={subAttribute.id}>
                                                    <div className={`${classes.property}`}>Selection {index + 1}</div>
                                                    <div className={`${classes.heading} text-base font-medium`}>{subAttribute.minValue}-{subAttribute.maxValue} {attribute.uom}</div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
                <StatusV2 heading="Service Status" options={SERVICE_STATUS_OPTIONS} formik={formik} name="status" />
            </div>

            <div className="flex justify-end gap-4">
                <ButtonV2 variant="tertiaryContained" label='Back' onClick={setFormStateTo(USC_FORM_STATE.USC_DETAILS)} />
                <ButtonV2 variant="primaryContained" onClick={handleNext} label={`+${capitalizeFirstLetter(mode)}`} />
            </div>
        </div>
    )
}

export default ReviewFormTemplate