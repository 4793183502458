import React, { useEffect, useState } from 'react'
import { FormControl, InputLabel, Select, MenuItem, TextField } from "@mui/material";
import { useSecondaryInventoryService } from '../../../services/useSecondaryInventoryService';
import { IWarehouse, SECONDARY_INVENTORY_SECTIONS } from '../../../utils/types';
import { ISecondaryInventoryForm, secondaryInventorySectionProp } from './SecondaryInventoryForm.template';
import Button from '../../atoms/Button';
import { MODE } from '../../../utils/constant';

const WareHouseDetailTemplate: React.FC<secondaryInventorySectionProp> = ({ formik, id, mode, setCurrentSectionTo }) => {
  const secondaaryInventoryService = useSecondaryInventoryService();
  const [warehousesFromDb, setWarehousesFromDb] = useState([]);
  let warehousesFilterValues: { value: any, label: string }[] = [];

  warehousesFromDb.forEach((warehouse: IWarehouse) => {
    warehousesFilterValues.push({ value: warehouse.id, label: warehouse.name });
  });
  const getAllWarehouses = () => {
    let queryParams = {
      search: "AVIGHNA",
      page: 0,
      size: 10
    };
    secondaaryInventoryService.getAllWarehouses(queryParams)
      .then((response) => {
        if (response.data) {
          setWarehousesFromDb(response?.data);
        }
        else {
          setWarehousesFromDb([]);
        }
      })
      .catch((error) => {
        console.error("Warehouses fetch failed - ", error);
        setWarehousesFromDb([]);
      });
  };

  const redirectToDescriptionDetail = () => {
		setCurrentSectionTo(SECONDARY_INVENTORY_SECTIONS.DEFECT_DETAILS);
	};
	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		const requiredFields = ["warehouseId", "warehousePlacement", "basePrice", "netWeight", "grossWeight"];
		let fieldError = false;
		for (const key of requiredFields) {
			if (key in formik.errors) {
				formik.setFieldTouched(key, true);
				fieldError = true;
			}
		}
		formik.setValues((prevValues: ISecondaryInventoryForm) => ({
			...prevValues,
			warehouseId: formik.values.warehouseId,
      warehousePlacement: formik.values.warehousePlacement,
      basePrice: formik.values.basePrice,
      netWeight: formik.values.netWeight,
      grossWeight: formik.values.grossWeight,
      quantity: formik.values.quantity,
		}));
    if (fieldError === true) return;
		setCurrentSectionTo(SECONDARY_INVENTORY_SECTIONS.IMAGES_UPLOAD);
	};
  useEffect(() => {
    getAllWarehouses();
  }, [])
  return (
    <div className=" search-upc-container grid w-3/4 gap-y-4 p-6   m-auto  my-8  bg-white">
      <div className="product-description">
        <h2>Please Enter Details</h2>
      </div>
      <div className="form-container flex flex-col grow">
        <FormControl fullWidth >
          <InputLabel id="label">Select Warehouse</InputLabel>
          <Select
            label="Select Warehouse*"
            {...formik.getFieldProps("warehouseId")}
            error={
              formik.touched.warehouseId &&
              Boolean(formik.errors.warehouseId)
            }
            id="warehouse"
            inputProps={{ readOnly: mode === MODE.UPDATE ? true : false }}
            >
            {warehousesFilterValues.map((value, index) => (
              <MenuItem key={index} value={value?.value}>
                {value?.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <TextField
        label=" Warehouse Placement*"
        id="outlined-basic"
        type="text"
        variant="outlined"
        {...formik.getFieldProps("warehousePlacement")}
        error={
          formik.touched.warehousePlacement &&
          Boolean(formik.errors.warehousePlacement)
        }
        fullWidth
      />

      <TextField
        id="outlined-basic"
        label=" Price on Invoice *"
        type="number"
        variant="outlined"
        {...formik.getFieldProps("basePrice")}
        error={
          formik.touched.basePrice &&
          Boolean(formik.errors.basePrice)
        }
        fullWidth
      />

      <TextField
        id="outlined-basic"
        type="number"
        label=" Net Weight *"
        variant="outlined"
        {...formik.getFieldProps("netWeight")}
        error={
          formik.touched.netWeight &&
          Boolean(formik.errors.netWeight)
        }
        fullWidth
        disabled={mode === MODE.UPDATE}
      />

      <TextField
        id="outlined-basic"
        type="number"
        label=" Gross Weight *"
        variant="outlined"
        {...formik.getFieldProps("grossWeight")}
        error={
          formik.touched.grossWeight &&
          Boolean(formik.errors.grossWeight)
        }
        fullWidth
        disabled={mode === MODE.UPDATE}
      />
      {/* <TextField
        id="outlined-basic"
        type="number"
        label="Quantity *"
        variant="outlined"
        {...formik.getFieldProps("quantity")}
        error={
          formik.touched.quantity &&
          Boolean(formik.errors.quantity)
        }
        fullWidth
      /> */}
   
			<div className="flex justify-between gap-x-3">
				<Button variant="outlined" label="Back" onClick={redirectToDescriptionDetail} />
				<Button variant="contained" label="Next" onClick={handleSubmit} />
			</div>
    </div>
  )
}

export default WareHouseDetailTemplate