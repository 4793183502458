import React, { useEffect, useMemo, useState } from 'react'
import { HTTP_STATUS, IPagination } from '../../../utils/types';
import TableV2, { ColumnType } from '../../organisms/TableV2';
import { createUseStyles } from 'react-jss';
import { Dialog, InputAdornment } from '@mui/material';
import ButtonV2 from '../../atoms/ButtonV2';
import TextFieldV2 from '../../atoms/TextFieldV2';
import CloseIcon from '@mui/icons-material/Close';
import { initialPaginationValues } from '../../../utils/constant';
import { useCustomerGroupMasterService } from '../../../services/useCustomerGroupMasterService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import searchIcon from '../../../assets/images/search.svg'

interface ICustomerListProps {
    viewId: number | null;
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        borderBottom: `1px solid ${theme.palette.border.tritiaryLight}`
    },
    customerCount: {
        borderRadius: '17px',
        background: theme.palette.textV2.failure.failure500,
        padding: '2px 6px'
    },
    box: {
        "& .MuiDialog-paper": {
            width: "894px !important",
            maxWidth: "894px !important",
            borderRadius: "16px",
            padding: '24px'
        },
    },
    dialogBox: {
        border: `1px solid ${theme.palette.border.primarylightAccent}`,
        borderRadius: '8px'
    },
    closeIcon: {
        color: '#696E7C'
    },
}));

const ViewGroupNameModalTemplate: React.FC<ICustomerListProps> = ({ dialogOpen, setDialogOpen, viewId }) => {

    const classes = useStyles();
    const customerGroupMasterService = useCustomerGroupMasterService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [customerGroupMasters, setCustomerGroupMastersTo] = useState<any>([]);
    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);
    const [search, setSearch] = useState<string>("");

    const handleDialogClose = () => {
        setDialogOpen(false);
        setSearch('');
    };

    const fetchAllGroupType = async (page: number, size: number) => {

        try {
            const groups = await customerGroupMasterService.getAllCustomerGroup(
                {
                    page: 0,
                    size: 10,
                    sort: "createdAt,desc",
                    groupName: search.trim(),
                },
                {
                    customerId: viewId,
                }
            );
            if (groups?.status === HTTP_STATUS.OK) {
                setCustomerGroupMastersTo(groups.data.data.content);
                setPaginationTo((prevPagination: IPagination) => ({
                    ...prevPagination,
                    totalRecords: groups.data.data.totalElements,
                    totalPages: groups.data.data.totalPages,
                }));
            } else {
                showSnackbar("error","Customer Group fetch failed");
            }
        }
        catch (error) {
            setCustomerGroupMastersTo([]);
        }

    }

    useEffect(() => {
        fetchAllGroupType(pagination.currentPage, pagination.pageSize);
    }, [pagination.currentPage, pagination.pageSize, viewId, search]);


    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage
        }));
    };

    const handleFiltersChange = (event: any) => {
        const { value } = event.target;
        setSearch(value);
        setPaginationTo({ ...pagination, currentPage: 0 })
    };

    const schema = {
        id: 'template-list',
        pagination: {
            total: pagination?.totalRecords,
            currentPage: pagination?.currentPage,
            isVisible: true,
            limit: pagination?.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange
        },
        columns: [
            { label: "Sr No.", key: "id", type: "number" as ColumnType, props: {} },
            { label: "Group Name", key: "groupName", type: "string" as ColumnType, props: {} },
        ]
    }

    const records = useMemo(() => {
        const customerTypes = customerGroupMasters ?? [];
        return customerTypes.map((type: any, index: number) => [
            index + 1,
            type.groupName
        ]);
    }, [viewId, customerGroupMasters]);

    return (
        <Dialog fullWidth className={`${classes.box}`} PaperProps={{ style: { height: 'auto' } }} open={dialogOpen} onClose={handleDialogClose}>
            <div className="pb-6 flex justify-end" onClick={handleDialogClose}>
                <CloseIcon className={classes.closeIcon} />
            </div>
            <div className={` ${classes.dialogBox} grid  p-6`}>
                <div className={`!w-80 py-4`}>
                    <TextFieldV2
                        label='Customer Under Group'
                        variant="outlined"
                        placeholder="search.."
                        name='groupName'
                        value={search}
                        onChange={handleFiltersChange}
                        InputProps={{
                            startAdornment: <InputAdornment position="start" > <img src={searchIcon} alt="icon" /></InputAdornment>,
                        }}
                    />
                </div>
                <TableV2 schema={schema} records={records} />
            </div>
            <div className="flex justify-end pt-6 pb-2">
                <ButtonV2
                    variant={'tertiaryContained'}
                    label={'Back'}
                    onClick={handleDialogClose}
                />
            </div>
        </Dialog>
    )
}
export default ViewGroupNameModalTemplate