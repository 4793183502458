export interface Field {
  name: string;
  align?: 'left' | 'center' | 'right';
}
export const TableColors = {
  blue: '#1d3679',
  gray: '#EDEDED',
  lightGray: '#F4F5FA',
  text: '#5B5B5B',
};
export const TableFont = {
  headerText: 14,
};

export interface ErrorResponce {
  status: string;
  statusMessage: string;
}
export const timeFormat = 'hh [Hr] mm [min] ss [sec]';
export const timeFormatShort = 'HH:mm:ss';
export const dateFormat = 'DD/MM/YYYY';
