
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
const SelectUpcAttributes = ({
  label,
  value,
  onChange,
  options,
  disabled,
  defaultValue,
  pageInfo = { pageSize: 10, pageNumber: 1 },
}) => {
  if (!options || options.length === 0) {
    options = [];
  }

  const pageSize = 10; // Number of items to show per page
  const [currentPage, setCurrentPage] = useState(pageInfo.pageNumber);
  const [currentPageSize, setCurrentPageSize] = useState(pageInfo.pageSize);
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const startIndex = (currentPage - 1) * currentPageSize;
  const endIndex = startIndex + currentPageSize;
  const displayedOptions = options.slice(startIndex, endIndex);

  return (
    <FormControl fullWidth className='bg-white'>
      <InputLabel id='label'>{label}</InputLabel>
      <Select
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 350
            },
          },
        }}

        labelId='label'
        id='select'
        label={label}
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.label}
          </MenuItem>
        ))}
      </Select>

    </FormControl>
  );
};

export default SelectUpcAttributes;
