import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PurchaseRequisition } from '../../Pages/Procurement/purchaseRequisition/PurchaseRequisition';
import { AddPurchaseRequisition } from '../../Pages/Procurement/purchaseRequisition/AddPurchaseRequisition';
import IndentTable from '../../Pages/Procurement/Indent/IndentTable';
import AddIndent from '../../Pages/Procurement/Indent/AddIndent';
import PurchaseRequisitionDetails from '../../Pages/Procurement/purchaseRequisition/PurchaseRequisitionDetails';
import { ProcurementParamsContextProvider } from '../../../contexts/ProcurementParamsContext';
import LiveAuction from '../../Pages/Procurement/LiveAuction/LiveAuction';
import LiveAuctionList from '../../Pages/Procurement/LiveAuction/LiveAuctionList';
import AddReverseAuction from '../../Pages/Procurement/reverseaction/AddReverseAuction';
import ReverseAuctionRFQ from '../../Pages/Procurement/reverseaction/ReverseAuctionRFQ';
import VendorDiscovery from '../../Pages/Procurement/VendorDiscovery/VendorDiscovery';
import ReviewReverseAuctionRFQ from '../../Pages/Procurement/VendorDiscovery/ReviewReverseAuctionRFQ';
import VendorSelection from '../../Pages/Procurement/VendorDiscovery/VendorSelection';
import ReviewActionDetails from '../../Pages/Procurement/reverseaction/ReviewActionDetails';
import ReviewIndent from '../../Pages/Procurement/Indent/ReviewIndent';
import { WareHouseDetailsContextProvider } from '../../../contexts/WareHouseDetailContext';

export const ProcurementRouter = () => {
  return (
    <ProcurementParamsContextProvider>
      <WareHouseDetailsContextProvider>
        <Routes>
          <Route path="/" element={<PurchaseRequisition />} />
          <Route
            path="/purchase-requisition"
            element={<PurchaseRequisition />}
          />
          <Route
            path="/purchase-requisition/creating-new-requisition"
            element={<AddPurchaseRequisition />}
          />
          <Route
            path="/purchase-requisition/:id"
            element={<PurchaseRequisitionDetails />}
          />
          <Route path="/reverse-auction/*" element={<ReverseAuctionRFQ />} />
          <Route
            path="/reverse-auction/reverse-auction-rfq"
            element={<ReverseAuctionRFQ />}
          />
          <Route
            path="/reverse-auction/reverse-auction-rfq/creating-reverse-auction"
            element={<AddReverseAuction />}
          />
          <Route
            path="/reverse-auction/reverse-auction-rfq/review-auction-details"
            element={<ReviewActionDetails />}
          />
          <Route
            path="/reverse-auction/vendor-discovery"
            element={<VendorDiscovery />}
          />
          <Route
            path="/reverse-auction/vendor-discovery/reverse-auction-rfq"
            element={<ReviewReverseAuctionRFQ />}
          />
          <Route
            path="/reverse-auction/vendor-discovery/select-vendor"
            element={<VendorSelection />}
          />
          <Route path="/indent" element={<IndentTable />} />
          <Route path="/indent/review-indent/" element={<ReviewIndent />} />
          <Route path="/indent/creating-new-indent" element={<AddIndent />} />
          <Route path="/indent/review-indent/:id" element={<ReviewIndent />} />
          <Route
            path="/reverse-auction/live-auction"
            element={<LiveAuctionList />}
          />
          <Route
            path="/reverse-auction/live-auction/:auctionId"
            element={<LiveAuction />}
          />
        </Routes>
      </WareHouseDetailsContextProvider>
    </ProcurementParamsContextProvider>
  );
};
