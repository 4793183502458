import { createUseStyles } from "react-jss";
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import ButtonV2 from "./ButtonV2";
import deleteDialog from '../../assets/images/deleteDialog.svg';

interface AddMasterBrandTemplateProps {
    deleteDialogOpen: boolean;
    setDeleteDialogOpen: (open: boolean) => void;
    textInput: string;
    onSubmitYes: () => void;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        "& .MuiDialog-paper": {
            width: "458px !important",
            maxWidth: "1297px !important",
            height: "365px",
            borderRadius: "24px"
        }
    },
    image: {
        height: "687px"
    },
    heading: {
        color: theme.palette.textV2.failure.failure500
    },
    subHeading: {
        color: theme.palette.buttonV2.tertiaryContained.background.primaryDark
    },
    closeIcon: {},
    button: {
        padding: "12px 72px",
    }
}));

const DeleteDialogBox: React.FC<AddMasterBrandTemplateProps> = ({ deleteDialogOpen, setDeleteDialogOpen, textInput, onSubmitYes }) => {
    const classes = useStyles();

    const handleDialogClose = () => {
        setDeleteDialogOpen(false);
    };

    return (
        <>
            <Dialog fullWidth className={classes.container}
                open={deleteDialogOpen} onClose={handleDialogClose}>
                <div style={{ position: 'relative', display: 'inline-block' }}>
                    <span>
                        <img src={deleteDialog} alt="deleteDialogBox" />
                    </span>
                    <div
                        className="cursor-pointer"
                        onClick={handleDialogClose}
                        style={{ position: 'absolute', top: '24px', right: '24px' }}
                    >
                        <CloseIcon className={classes.closeIcon} />
                    </div>
                </div>
                <div className={`mt-6`}>
                    <div className="text-center align-center">
                        <div className={`${classes.heading} text-4xl font-semibold`}>Delete {textInput}?</div>
                        <div className={`${classes.subHeading} text-sm mt-3`}>Are you sure you want to delete the {textInput}?</div>
                    </div>
                    <div className="flex justify-center mt-8 gap-4 w-w/2">
                        <ButtonV2 variant="tertiaryContained"
                            onClick={onSubmitYes}
                            label={"Yes"}
                            className={classes.button} />
                        <ButtonV2 variant="secondaryContained"
                            onClick={handleDialogClose}
                            label={"No"}
                            className={classes.button} />
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default DeleteDialogBox;
