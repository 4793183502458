import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { IPagination } from '../../../utils/types';
import { ADMIN_ROUTES, initialPaginationValues } from '../../../utils/constant';
import TitleHeading from '../../atoms/CustomHeading/TitleHeading';
import Button from '../../atoms/Button';
import ImageMasterListTemplate from '../../templates/ImageMaster/ImageMasterList.template';
import { IImageMasterFilterRequest, useImageMasterService } from '../../../services/useImageMasterService';

export interface IImageMasterFilter {
    productCategory: number | null;
    brand:number| null;
    searchName: string;
    sort: string;
}


export interface IImageMaster {
    id: number;
    productCategoryName: string;
    catalogueType: string;
    classType: string;
    brandName: string;
    shape: string;
    createdBy: string;
    createdAt: string;
    updatedBy: string;
    updatedAt: string;
    numberOfImages: number;
}

const initialImageMasterFiltersValues: IImageMasterFilter = {
    productCategory: null,
    brand:null,
    searchName: "",
    sort: "createdAt,desc",
}

const ImageMasterListPage: React.FC = () => {
    const imageMasterService = useImageMasterService();
    const navigate = useNavigate();
    const [imageMaster, setImageMasterTo] = useState<IImageMaster[]>([]);
    const [imageMasterFilters, setImageMasterFiltersTo] = useState<IImageMasterFilter>(initialImageMasterFiltersValues);
    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);

    const getAllImageMasters = (page: number, size: number, filterData?: any) => {
        let params = {
          searchByName: imageMasterFilters.searchName,
          page: page,
          size: size,
          sort: imageMasterFilters.sort,
          brandId: imageMasterFilters.brand,
          productCategoryId:  imageMasterFilters.productCategory,
        }
      
        imageMasterService.getAllImageMaster(params)
          .then((secondaryInventoriesResponse) => {
            if (secondaryInventoriesResponse.data.data) {
              const { totalElements, totalPages } = secondaryInventoriesResponse?.data?.data;
              setPaginationTo({
                ...pagination,
                totalPages: totalPages,
                totalRecords: totalElements
              });
              setImageMasterTo(secondaryInventoriesResponse?.data?.data?.content);
            }    
          })
          .catch((error) => {
            console.error("Inventories fetching error - ", error);
          });
      }
    
    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
          })); 
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationTo((prevPagination) => ({
          ...prevPagination,
          pageSize: newRowsPerPage
        }));
    };
    const handleFiltersChange = (event: any) => {
        const { name, value } = event.target;
        setPaginationTo({...pagination, currentPage: 0})
        setImageMasterFiltersTo({ ...imageMasterFilters, [name]: value ?? null });
    }

    const handleCreateBrand = () => {
		navigate(`${ ADMIN_ROUTES.IMAGE_MASTER_ADD }`);
	};

    useEffect(() => {
        getAllImageMasters(pagination.currentPage, pagination.pageSize);
      }, [pagination.currentPage, pagination.pageSize, imageMasterFilters.searchName, imageMasterFilters.brand, imageMasterFilters.sort, imageMasterFilters.productCategory]);

    return (
        <div className="grid gap-y-3">
            <div className="flex justify-between">
                <TitleHeading text="Image Master" />
                <Button variant="contained" label="Add Image" onClick={ handleCreateBrand } />
            </div>
            <ImageMasterListTemplate imageMasters={ imageMaster } pagination={ pagination } handlePaginationChange={ handlePaginationChange } handleRowsPerPageChange={ handleRowsPerPageChange } handleFiltersChange={ handleFiltersChange } imageMasterFilter={ imageMasterFilters }/>
        </div>
    )
}

export default ImageMasterListPage;
