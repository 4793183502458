import React, { ChangeEvent, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { theme } from "../../../utils/theme";
import { Checkbox } from "@mui/material";
import FilterChip, { IMultiSelectOption } from "../../molecules/FilterChip/FilterChip";
import ButtonV2 from "../../atoms/ButtonV2";
import { UPC_APPLICATIONS, VALUE_ADDING_SERVICES } from "../../../utils/constant";
import { IUpcApplicationsParams, IMasterUpcDetailsRequestBody, useMasterUpcService } from "../../../services/useMasterUpcService";
import { HTTP_STATUS } from "../../../utils/types";
import { IProduct } from "./MasterUpcOverview.template";
import { useSnackbar } from "../../../hooks/useSnackBar";

interface IAddMasterUpc {
    id: number;
}

interface IMasterApplications {
    id: number,
    applications: (string | number)[] | null;
    valueAddedServices: (string | number)[] | null;
    product: IProduct;
}

export interface IUpcApplicationsBodyValues {
    upcApplications: (string | number)[] | null;
    valueAddedServices: (string | number)[] | null;
}

const useStyles = createUseStyles({
    headingText: {
        color: theme.palette.textV2.primary900,
    },
    checkboxText: {
        "&.MuiCheckbox-root.Mui-checked": {
            color: theme.palette.textV2.primary.primary500,
        },
    },
    body: {
        border: `1px solid ${theme.palette.textV2.secondary.secondary50}`,
    },
    checkBox: {
        "& .MuiSvgIcon-root": {
            color: theme.palette.buttonV2.secondaryText.text.primaryLight,
        },
    },
    text: {
        color: theme.palette.textV2.primary.primary950 
    },
});

const AddMasterUpc: React.FC<IAddMasterUpc> = ({ id }) => {
    const classes = useStyles();
    const [selectedServices, setSelectedServices] = useState<string[]>([]);
    const [selectedValues, setSelectedValues] = useState<IMultiSelectOption[]>([]);
    const [searchApplication, setSearchApplication] = useState<string>('');
    const [masterApplications, setMasterApplicationsTo] = useState<IMasterApplications | null>(null);
    const [requestBodyValues, setRequestBodyValuesTo] = useState<IUpcApplicationsBodyValues>({ upcApplications: null, valueAddedServices: null });
    const [showEditForm, setShowEditFormTo] = useState<Boolean>(true);
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const masterUpcService = useMasterUpcService();

    const handleOnSaveButton = () => {
        const categoryId = masterApplications?.product?.categoryId;
        if(!categoryId){
            console.error("No Category ID Found")
            return;
        }
        let params: IUpcApplicationsParams = {
            categoryId: categoryId,
        };
        masterUpcService.addMasterUpcApplication(id, params, requestBodyValues)
            .then((upcResponse) => {
                if (upcResponse.data.data) {
                    requestBodyValues.upcApplications &&
                    showSnackbar("success", `Master UPC application was added successfully.`);

                } else {
                    console.error("Error while adding applications");
                }
            })
            .catch((error) => {
                console.error("Applications adding error - ", error);
            });
    };


    const filteredApplications = UPC_APPLICATIONS.filter(option =>
        option.label.toLowerCase().includes(searchApplication.toLowerCase())
    );

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked, value } = event.target;
        setSelectedServices((prevSelected: string[]) =>
            checked ? [...prevSelected, value] : prevSelected.filter((serviceLabel) => serviceLabel !== value)
        );
    };

    const handleSelectionChange = (selected: IMultiSelectOption[]) => {
        setSelectedValues(selected);
    };

    const handleApplicationSaveClick = () => {
        const selectedValuesOnly = selectedValues.map(option => option.value);
        setRequestBodyValuesTo({ upcApplications: selectedValuesOnly, valueAddedServices: null });
    };

    const handleVasSaveClick = () => {
        setRequestBodyValuesTo({ upcApplications: null, valueAddedServices: selectedServices });
        setShowEditFormTo(false);
    };

    const fetchUpc = async () => {
        if (id) {
            try {
                let params: IMasterUpcDetailsRequestBody = {
                    upcTypes: "MASTER_UPC",
                }
                const upcResponse = await masterUpcService.getProductUpcById(id, params);
                if (upcResponse?.status === HTTP_STATUS.OK) {
                    setMasterApplicationsTo(upcResponse?.data?.data);
                    const valueAddedServices = upcResponse?.data?.data?.valueAddedServices;
                    const services: string[] = valueAddedServices
                        ? valueAddedServices.map((service: string | number) => service.toString())
                        : [];
                    if (services) {
                        setShowEditFormTo(false);
                    }
                    setSelectedServices(services);
                    setSelectedValues(
                        upcResponse?.data?.data?.applications?.map((app: string | number) => ({ label: String(app), value: app })) || []
                    );
                }
                else {
                    throw new Error("Applications fetch failed");
                }
            } catch (error) {
                console.error("error", `Applications fetch failed`)
            }
        }
    }

    useEffect(() => {
        fetchUpc()
    }, [id]);

    useEffect(() => {
        handleOnSaveButton();
    }, [requestBodyValues])

     return (
        <>
            <div className={`${classes.body} rounded-lg p-4`}>
                <h1 className={`${classes.headingText} mb-6 font-semibold text-lg`}>UPC Application</h1>
                <div className="flex w-2/3">
                    <FilterChip
                        options={filteredApplications}
                        label="Map UPC Application"
                        value={selectedValues}
                        onchange={handleSelectionChange}
                        placeholder="Choose Application Type"
                        ButtonComponent={null}
                        textFieldPlaceholder='Search...'
                        searchTerm={searchApplication}
                        setSearchTerm={setSearchApplication}
                        maxHeight="30%"
                    />
                </div>
                <ButtonV2
                    className="mt-6"
                    variant={"primaryContained"}
                    label={"Save"}
                    onClick={handleApplicationSaveClick}
                    disabled={selectedValues.length === 0}
                />            </div>
            <div className={`${classes.body} p-4 rounded-lg`}>
                <h1 className={`${classes.headingText} mb-6 font-semibold text-lg`}>Value Adding Services</h1>
                {showEditForm ? (
                    <div>
                        <div className="flex gap-14">
                            {VALUE_ADDING_SERVICES.map((item) => (
                                <label key={item.label} className={`flex items-center`}>
                                    <Checkbox
                                        id={item.label}
                                        value={item.label}
                                        checked={selectedServices.includes(item.label)}
                                        onChange={handleCheckboxChange}
                                        className={`${classes.checkboxText}`}
                                    />
                                    <div className={`${classes.text} text-base`}>{item.value}</div>
                                </label>
                            ))}
                        </div>
                        <ButtonV2 className="mt-6" variant={"primaryContained"} label={"Save"} onClick={handleVasSaveClick} />
                    </div>
                ) : (
                    <div>
                        <div className="flex gap-14">
                            {VALUE_ADDING_SERVICES.map((item) => (
                                <label key={item.label} className={`flex items-center`}>
                                    <Checkbox
                                        id={item.label}
                                        disabled
                                        value={item.label}
                                        checked={selectedServices.includes(item.label)}
                                        onChange={handleCheckboxChange}
                                        className={`${classes.checkBox}`}
                                    />
                                    <div className={`${classes.text} text-base`}>{item.value}</div>
                                </label>
                            ))}
                        </div>
                        <ButtonV2 className="mt-6" variant={"primaryContained"} label={"Edit"} onClick={() => setShowEditFormTo(true)} />
                    </div>
                )}
            </div>
        </>
    );
};

export default AddMasterUpc;
