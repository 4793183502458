import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export const BRAND_URLS = {
  GET_ALL_BRAND: "/brands",
  GET_BRAND: "/brand/:id",
  CREATE_BRAND: "/brand",
  UPDATE_BRAND: "/brand/:id",
  GET_ALL_BRANDS_BY_MANUFACTURER_IDS: "/brands-by-manufacturer"
};

export interface IBrandFilterRequest{
  manufacturerId?: number | string;
  name?: string;
  status?: string;
  page: number;
  size: number;
  sort?: string;
  productCategoryId?: number;
}

export interface IBrandSearchRequest{
  name?: string;
  page: number;
  size: number;
  sort?: string;
}

export interface IBrandRequest{
  name?: string;
  prefix?: string;
  description: string;
  logoPath: number | null;
  status: string;
  categoryIds: number[];
  manufacturerId: number | null;
}

export const useBrandService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();
  const v2BaseUrl = process.env.REACT_APP_API_V2_URL;

  const getBrandById = (id: number) => {
    return request(API_METHOD.GET, replaceUrlParams( BRAND_URLS.GET_BRAND, { id } ), authenticatedUser);
  };

  const getAllBrands = (params: IBrandFilterRequest) => {
    return request(API_METHOD.GET, BRAND_URLS.GET_ALL_BRAND, authenticatedUser, null, { params }  );
  };

  const getAllBrandsByName = (params: IBrandSearchRequest) => {
    return request(API_METHOD.GET, BRAND_URLS.GET_ALL_BRAND, authenticatedUser, null, { params } ,null , v2BaseUrl);
  };


  const createBrand = (data: IBrandRequest) => {
    return request(API_METHOD.POST, BRAND_URLS.CREATE_BRAND, authenticatedUser, data);
  };

  const updateBrand = (id: number, data: IBrandRequest) => {
    return request(API_METHOD.PUT, replaceUrlParams( BRAND_URLS.UPDATE_BRAND, { id } ), authenticatedUser, data);
  };

  const getAllBrandsByManufacturerIds = (data: number[]) => {
    return request(API_METHOD.POST, BRAND_URLS.GET_ALL_BRANDS_BY_MANUFACTURER_IDS, authenticatedUser, data)
}

  return {
    getBrandById,
    getAllBrands,
    createBrand,
    updateBrand,
    getAllBrandsByName,
    getAllBrandsByManufacturerIds
  };
};
