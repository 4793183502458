import { useCallback, useEffect, useState } from 'react';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { MenuItem, sideMenuBarOptions } from './SideBarMenuSchema';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { createUseStyles } from 'react-jss';
import { RESOURCES } from '../../../utils/constant';
import Button from '../../atoms/Button';

const useStyles = createUseStyles((theme: any) => ({
    //Todo change active-0 active-1 active-2 as per the refactored style
    // "active-0": {
    //     backgroundColor: theme.pallete.sidebarBackground.primaryLight,
    // },
    // "active-1": {
    //     backgroundColor: theme.pallete.sidebarBackground.primaryDark,
    // },
    // "active-2": {
    //     backgroundColor: theme.palette.sidebarBackground.primaryLight,
    // },
    customListItemButton: {
        width: "236px",
    },
    logoutWrapper: {
        borderTop: `1px solid ${theme.palette.border.primaryLight}`,
        backgroundColor: theme.palette.border.primaryLight,
        marginTop: "8px",
        textAlign: "center",
        position: "sticky",
        bottom: 0,
        zIndex: 1,
    },
    logoutText: {
        color: theme.palette.text.primaryDarkLight,
    }
}));

const SideBarMenu = () => {

    const navigate = useNavigate();
    const classes = useStyles();
    const { user, setAuthenticatedUser } = useAuthenticatedUser();
    const [expandedMenu, setExpandedMenu] = useState<{ [key: string] : boolean}>({});

    useEffect(() => {
        if (user?.email == null && user?.token != null) {
            navigate('/client-details', { state: { flag: false } });
        } else if (user == null) {
            navigate('/sign-in');
        }
    }, [user]); 

    const handleMenuChange = (keys: string[]) => () => {
        if (keys.every(key => expandedMenu[key])) {
            delete expandedMenu[keys.pop() as string];
            setExpandedMenu({ ...expandedMenu });
        }
        else {
            const updatedExpandedMenu: { [key: string]: boolean } = keys.reduce(
                (previousValue: { [key: string]: boolean }, currentValue: string) => (
                    { ...previousValue, [currentValue]: true }
                ), {})
            setExpandedMenu(updatedExpandedMenu);
        }
    }; 
   
    const handleLogout = () => {
        setAuthenticatedUser(null);
    };

    const renderMenuItems = useCallback((menuItems: MenuItem[], parent: string[] = []) => {
        return menuItems.map(({ key, route, icon, subList, disabled }) => {
            return (
                <div key={key}>
                    <ListItemButton
                        component={route ? Link : "span"}
                        to={route}
                        selected={!!expandedMenu[key]}
                        onClick={handleMenuChange([...parent, key])} 
                        className={`${classes.customListItemButton}`}
                        //Todo need to refactor style
                        style={{
                            padding: "12px",
                            backgroundColor: expandedMenu[key] ? (
                                parent.length === 0 ? "#E9ECEF" :
                                    parent.length === 1 ? "#FFF1DB" :
                                        parent.length === 2 ? "#DDE5FF" :
                                            "none"
                            ) : ""
                        }}
                        disabled={disabled}
                    >
                        {icon && <ListItemIcon style={{ marginRight: "-28px" }}>
                            <img src={icon} alt="icon" />
                        </ListItemIcon>}
                        <ListItemText primary={key} />
                        {subList?.length && <div>{expandedMenu[key] ? <ExpandLess /> : <ExpandMore />}</div>}
                    </ListItemButton>
                    <Collapse className={parent.length === 1 ? "bg-slate-100" : ""} in={expandedMenu[key]} timeout="auto" unmountOnExit>
                        {subList && renderMenuItems(subList, [...parent, key])}
                    </Collapse>
                </div>
            );
        });
    }, [expandedMenu]);

    return (
        <>
            <List component="nav">
                {renderMenuItems(sideMenuBarOptions)}
            </List>
            <div className={classes.logoutWrapper}>
                <Button
                    onClick={handleLogout}
                    label={RESOURCES.LOG_OUT}
                    variant="outlined"
                    className={classes.logoutText}
                />
            </div>
        </>
    );
};
export default SideBarMenu;