import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom';
import CloseIcon from "@mui/icons-material/Close";
import TextField from "../../../atoms/TextField";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Button from '../../../atoms/Button';
import { MANUFACTURER_SECTIONS } from '../../../../utils/types';
import { IPOC, changeSectionTo } from './ManufacturerForm.template';
import { ADMIN_ROUTES, MODE } from '../../../../utils/constant';
import { createUseStyles } from "react-jss";

interface PointOfContactTemplateProps {
    setCurrentSectionTo: (section: MANUFACTURER_SECTIONS) => void;
    formik: any;
    id: number | null;
    categoryIds: number[] | null;
    mode: string;
    setCategoriesTo: any;
}

const useStyles = createUseStyles((theme: any) => ({
	errorMessage: {
		color: theme.palette.action.danger,
	},
    mainHeading:{
        color: theme.palette.text.primaryDarkLight
    },
    headingContainer: {
        borderBottom: `1px solid ${ theme.palette.border.primaryDark }`
    },
	formContainer: {
		border: `1px solid ${ theme.palette.border.primaryDark }`
	},
	buttonContainer: {
		background: theme.palette.background.primaryLightAccent
	}
}));

const PointOfContactTemplate: React.FC<PointOfContactTemplateProps> = ({ setCurrentSectionTo, categoryIds, formik, id, mode, setCategoriesTo }) => {

    const classes = useStyles();

    const navigate = useNavigate();

    const redirectToManufacturerList = () => {
        navigate(ADMIN_ROUTES.MANUFACTURER_LIST);
    }

    const addPOC = () => {
        const newPOC = {
            name: "",
            mobileNumber: "",
            email: "",
            whatsapp: "",
        };
        formik.setFieldValue("pocs", [...formik.values.pocs, newPOC]);
    };

    const removePOC = (index: number) => {
        formik.setFieldValue(
            "pocs",
            formik.values.pocs.filter((_: any, i: number) => i !== index)
        );
    };

    const removePointOfContact = (index: number) => () => {
		removePOC(index);
	};

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const requiredFields: (keyof IPOC)[] = ["name", "mobileNumber", "email", "whatsapp"];
        const pocErrorsExist = formik.values.pocs.some((poc: any) => {
            return requiredFields.some((fieldName: keyof typeof poc) => {
                return !poc[fieldName];
            });
        });
        if (pocErrorsExist) {
            const updatedTouched = {
                ...formik.touched,
                pocs: formik.values.pocs.map((poc: any) => {
                    const touchedFields: { [key: string]: boolean } = {};
                    requiredFields.forEach(fieldName => {
                        touchedFields[fieldName] = true;
                    });
                    return touchedFields;
                })
            };
            formik.setTouched(updatedTouched);
        } else {
            formik.handleSubmit();
        }
    };

    const AddPointOfContact = useMemo(() => {
        if (mode !== MODE.VIEW) {
            return (
                <div className={`${classes.buttonContainer} rounded-xl text-white py-3 px-4 cursor-pointer w-fit`} onClick={addPOC}>
                    <AddRoundedIcon />
                </div>
            );
        }
        return null;
    }, [mode, classes.buttonContainer, addPOC]);

    return (
        <div className="p-6 grid gap-y-6">
            <div className={ `${ classes.headingContainer } flex justify-between pb-4` }>
                <div className={ `${ classes.mainHeading } font-semibold text-2xl` }>Add Point of Contact</div>
                <CloseIcon
                    onClick={ redirectToManufacturerList }
                    className="cursor-pointer text-4xl"
                />
            </div>
            {/***************************	TODO- Make a Generic Component********************** */}
            <div className="w-full  m-auto flex justify-between gap-x-0.5 text-var(--black, #4D4D4D) text-center font-roboto text-xs font-normal leading-normal">
                <div className="grid gap-y-1 w-1/3">
                    <div>Manufacturer Details</div>
                    <div className="h-3 bg-gray-300"></div>
                </div>
                <div className="grid gap-y-1 w-1/3">
                    <div> Manufacturing Location details</div>
                    <div className="h-3 bg-gray-300"></div>
                </div>
                <div className="grid gap-y-1 w-1/3">
                    <div>Add Point of Contact</div>
                    <div className="h-3 bg-blue"></div>
                </div>
            </div>
            {/***************************	TODO- Make a Generic Component********************** */}
            <form className='grid gap-y-4' onSubmit={ handleSubmit }>
            
                <div className="grid gap-y-4" >
                        { formik.values.pocs &&
                            formik.values.pocs.map((poc: any, index: number) => (
                                <div className={ `${ classes.formContainer } grid gap-y-4 p-3 rounded` } key={ index }>
                                
                                    <div className="flex justify-between">
                                        <div className={` font-semibold text-base ${ classes.mainHeading }` }>Point Of Contact - { index + 1 }</div>
                                        { index > 0 && mode !== "VIEW" && (
                                            <div onClick={ removePointOfContact(index) }>
                                                <CloseIcon className="cursor-pointer text-4xl"/>
                                            </div>
                                        )}
							        </div>
                                    <div key={ index } className="grid grid-cols-2 gap-4" >
                                        <div className="grid">
                                            <TextField
                                                id={ `name-${ index }` }
                                                name={ `pocs[${ index }].name` }
                                                label="Name*"
                                                variant="outlined"
                                                { ...formik.getFieldProps(`pocs[${ index }].name`) }
                                                inputProps={{ readOnly: mode === MODE.VIEW ? true : false }}
                                            />
                                            { formik.touched.pocs &&
                                                formik.touched.pocs[index] &&
                                                formik.touched.pocs[index].name &&
                                                formik.errors.pocs &&
                                                formik.errors.pocs[index] &&
                                                formik.errors.pocs[index].name && (
                                                    <div className={ classes.errorMessage }>
                                                        <small>{ formik.errors.pocs[index].name }</small>
                                                    </div>
                                                )}
                                        </div>
                                        <div className="grid">
                                            <TextField
                                                id={ `mobileNumber-${ index }` }
                                                name={ `pocs[${ index }].mobileNumber` }
                                                type="number"
                                                label="Phone No.*"
                                                variant="outlined"
                                                { ...formik.getFieldProps(`pocs[${ index }].mobileNumber`) }
                                                inputProps={{ readOnly: mode === MODE.VIEW ? true : false }}
                                            />
                                            { formik.touched.pocs &&
                                                formik.touched.pocs[index] &&
                                                formik.touched.pocs[index].mobileNumber &&
                                                formik.errors.pocs &&
                                                formik.errors.pocs[index] &&
                                                formik.errors.pocs[index].mobileNumber && (
                                                    <div className={ classes.errorMessage }>
                                                        <small>
                                                            { formik.errors.pocs[index].mobileNumber }
                                                        </small>
                                                    </div>
                                                )}
                                        </div>
                                        <div className="grid">
                                            <TextField
                                                id={ `email-${ index }` }
                                                name={ `pocs[${ index }].email` }
                                                label="Email*"
                                                variant="outlined"
                                                { ...formik.getFieldProps(`pocs[${ index }].email`) }
                                                inputProps={{ readOnly: mode === MODE.VIEW ? true : false }}
                                            />
                                            { formik.touched.pocs &&
                                                formik.touched.pocs[index] &&
                                                formik.touched.pocs[index].email &&
                                                formik.errors.pocs &&
                                                formik.errors.pocs[index] &&
                                                formik.errors.pocs[index].email && (
                                                    <div className={ classes.errorMessage }>
                                                        <small>{ formik.errors.pocs[index].email }</small>
                                                    </div>
                                            )}
                                        </div>
                                        <div className="grid">
                                            <TextField
                                                id={ `whatsapp-${ index }` }
                                                name={ `pocs[${ index }].whatsapp` }
                                                type="number"
                                                label="Whatsapp No.*"
                                                variant="outlined"
                                                { ...formik.getFieldProps(`pocs[${ index }].whatsapp`) }
                                                inputProps={{ readOnly: mode === MODE.VIEW ? true : false }}
                                            />
                                            { formik.touched.pocs &&
                                                formik.touched.pocs[index] &&
                                                formik.touched.pocs[index].whatsapp &&
                                                formik.errors.pocs &&
                                                formik.errors.pocs[index] &&
                                                formik.errors.pocs[index].whatsapp && (
                                                    <div className={ classes.errorMessage }>
                                                        <small>{ formik.errors.pocs[index].whatsapp }</small>
                                                    </div>
                                                )}
                                        </div>
                                    </div>                 
                                </div>
                            ))}
                        { AddPointOfContact }
                </div>
                <div className="flex justify-end">
                    <div className="flex gap-x-5">
                        <Button
                            variant="outlined"
                            fullWidth
                            label="Back"
                            onClick={ changeSectionTo(setCurrentSectionTo)(MANUFACTURER_SECTIONS.MANUFACTURING_LOCATION_DETAILS) }
                        />
                        <Button
                            variant="contained"
                            fullWidth
                            label="Submit"
                            type="submit"
                        />
                    </div>
                </div>
            </form>
        </div>
    );
}

export default PointOfContactTemplate;
