import {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from 'react';
import { ShipToLocation } from '../App/Constants/Procurement/Indent/types';
import { useWarehouseService } from '../services/useWarehouseService';

type wareHouseContextType = {
  warehouseDetails: ShipToLocation[];
  locations: { id: string; value: string; name: string }[];
  setWarehouseDetails: Dispatch<SetStateAction<ShipToLocation[]>>;
};

export const WareHouseContext = createContext<wareHouseContextType>({
  warehouseDetails: [],
  locations: [],
  setWarehouseDetails: () => {},
});

export const WareHouseDetailsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [warehouseDetails, setWarehouseDetails] = useState<ShipToLocation[]>(
    []
  );
  const { getWarehouse } = useWarehouseService();
  const [locations, setLocations] = useState<
    { id: string; value: string; name: string }[]
  >([]);

  const fetchWarehouse = async () => {
    try {
      const response = await getWarehouse();

      const options = response.data.map((location: ShipToLocation) => {
        return {
          id: location.id,
          name: location.name,
          value: location.shortName,
        };
      });
      setLocations(options);
      setWarehouseDetails(response.data);
    } catch (err) {
      console.log('Error in fetch warehouse location - ', err);
    }
  };

  useEffect(() => {
    fetchWarehouse();
  }, []);

  return (
    <WareHouseContext.Provider
      value={{ warehouseDetails, setWarehouseDetails, locations }}
    >
      {children}
    </WareHouseContext.Provider>
  );
};
