import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export const UPC_URLS = {
  GET_SECONDARY_PRODUCT: "/search/secondary-product/:id",
};

export interface ISecondaryProductQuery{
  classType?: String
}

export const useSecondaryUpcService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const getSecondaryProductUpc = (id: number, data: ISecondaryProductQuery) => {
    return request(API_METHOD.GET, replaceUrlParams( UPC_URLS.GET_SECONDARY_PRODUCT,{ id }), authenticatedUser, null ,{params : data});
  };

  return {
    getSecondaryProductUpc,
  };
};