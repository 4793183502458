import React from "react";
import {
    FormControl,
    InputLabel,
    Select as MuiSelect,
    MenuItem,
    SelectProps as MuiSelectProps
} from "@mui/material";

interface Option {
    value: string;
    label: string;
}

interface SelectProps extends Omit<MuiSelectProps, "label"> {
    options: Option[];
    label?: string;
}

const Select: React.FC<SelectProps> = ({ options, label, ...props }) => {
    return (
        <FormControl fullWidth >
            <InputLabel id={`dropdown-label-${label}`}>{label}</InputLabel>
            <MuiSelect 
                {...props}
                id={`select-${label}`} 
                label={label} 
                labelId={`dropdown-label-${label}`}
                value={String(props.value)}>
                {options.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </MuiSelect>
        </FormControl>
    );
};

export default Select;