import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { HTTP_STATUS, PRODUCT_SERVICE_FORM_STATE, VAS_MASTER_CATEGORY_TYPE } from '../../../../utils/types';
import { ADMIN_ROUTES, EXCEPTION_CODE, MODE, STATUS } from '../../../../utils/constant';
import { makeRoute } from '../../../../utils/helper';
import ServiceCategoryFormTemplate from '../../../templates/VASMaster/Categories/ServiceCategoryForm.template';
import AttributeManagementForm1Template from '../../../templates/VASMaster/Categories/AttributeManagementForm1.template';
import ServiceCategoryDetailsFormTemplate from '../../../templates/VASMaster/Categories/ServiceCategoryDetails.template';
import { ServicePayload, useVASMasterCategoryService } from '../../../../services/useVASMasterCategoryService';
import AttributeManagementForm2Template from '../../../templates/VASMaster/Categories/AttributeManagementForm2.temlate';
import ServiceCategoryReviewTemplate from '../../../templates/VASMaster/Categories/ServiceCategoryReview.template';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
    },
}));

const ServiceCategoryCreatePage: React.FC = () => {
    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const vasMasterCategoryService = useVASMasterCategoryService();
    const navigate = useNavigate();

    const [formState, setFormState] = useState<PRODUCT_SERVICE_FORM_STATE>(1);

    const onClose = () => {
        navigate(makeRoute(ADMIN_ROUTES.VAS_MASTER_CATEGORIES_LIST, { query: { type: 'SERVICE_CATEGORY' } }));
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().max(30, "Service Category Name cannot be more than 30 characters").required("Service Category Name is required"),
        description: Yup.string().max(500, "Description cannot be more than 500 characters").required("Description is required"),
        parentId: Yup.number().required("Main Service is required"),
        superService: Yup.object().shape({
            id: Yup.number().required('Super Service is required'),
            label: Yup.string().required('Super Service is required')
        }).required('Super Service is required'),
        mainService: Yup.object().shape({
            id: Yup.number().required('Main Service is required'),
            label: Yup.string().required('Main Service is required')
        }).required('Main Service is required'),
        attributes: Yup.array().of(
            Yup.object().shape({
                id: Yup.number().required('Attribute ID is required'),
                name: Yup.string().required('Attribute Name is required'),
                uom: Yup.string().required('Unit of Measure (UOM) is required'),
                type: Yup.string().required('Attribute Type is required'),
                subAttributes: Yup.array().of(
                    Yup.object().shape({
                        id: Yup.number().required('Sub-Attribute ID is required'),
                        parentId: Yup.number().required('Parent ID is required'),
                        minValue: Yup.number().required('Minimum Value is required'),
                        maxValue: Yup.number().required('Maximum Value is required'),
                        status: Yup.string().oneOf(['ACTIVE', 'INACTIVE'], 'Status must be either ACTIVE or INACTIVE').required('Status is required')
                    })
                ).required('Sub-Attributes are required')
            })
        ).min(1, 'At least one attribute is required').required('Attributes are required'),
        subAttributeIds: Yup.object().test(
            'hasAtLeastOneKey',
            'Check at least one checkbox',
            value => value && Object.keys(value).length > 0
        )
            .required('Sub Attribute is required'),
    });

    const createServiceCategory = async (service: ServicePayload) => {
        const requestBody: ServicePayload = {
            name: service.name,
            status: service.status,
            description: service.description,
            level: service.level,
            parentId: service.parentId,
            subAttributeIds: service.subAttributeIds
        }
        vasMasterCategoryService.createService(requestBody)
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    showSnackbar('success', "New Service Category Created");
                    onClose();
                } else if (res.data.exceptionCode === EXCEPTION_CODE.DUPLICATE_ENTRY) {
                    showSnackbar('error', "Service Category already exists. Please try again");
                }
            }).catch((error: any) => {
                showSnackbar('error', "Failed to create Service Category");
            })
    }

    const formik = useFormik<ServicePayload>({
        initialValues: {
            name: "",
            serviceCode: "",
            status: STATUS.ACTIVE,
            description: "",
            level: VAS_MASTER_CATEGORY_TYPE.SERVICE_CATEGORY,
            parentId: null,
            superService: {
                label: "",
                id: ""
            },
            mainService: {
                label: "",
                id: ""
            },
            attributes: [],
            subAttributeIds: {}
        },
        validationSchema,
        validateOnMount: true,
        onSubmit: (values) => { createServiceCategory(values) }
    })

    const setFormStateTo = (formState: PRODUCT_SERVICE_FORM_STATE) => () => {
        setFormState(formState)
    }

    const activeFormStep = useMemo(() => {
        const props = { mode: MODE.ADD, onClose, formik, setFormStateTo }
        switch (formState) {
            case PRODUCT_SERVICE_FORM_STATE.SERVICE_CATEGORY_DETAILS: return <ServiceCategoryDetailsFormTemplate {...props} />;
            case PRODUCT_SERVICE_FORM_STATE.ATTRIBUTE_MANAGEMENT_FORM_1: return <AttributeManagementForm1Template {...props} />;
            case PRODUCT_SERVICE_FORM_STATE.ATTRIBUTE_MANAGEMENT_FORM_2: return <AttributeManagementForm2Template {...props} />;
            case PRODUCT_SERVICE_FORM_STATE.REVIEW: return <ServiceCategoryReviewTemplate {...props} />;
            default: return <ServiceCategoryDetailsFormTemplate {...props} />;
        }
    }, [formState, formik])

    return (
        <div>
            {SnackBarComponent}
            <div className="grid gap-y-6" >
                <div className={`${classes.mainHeading} text-lg font-semibold`}>Add New Service Category</div>
                <ServiceCategoryFormTemplate {...{ mode: MODE.ADD, onClose, component: activeFormStep }} />
            </div>
        </div>
    )
}

export default ServiceCategoryCreatePage