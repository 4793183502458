import {
  IndentContextType,
} from '../App/Constants/Procurement/Indent/types';
import { PRParamsType } from '../App/Constants/Procurement/PurchaseRequisition/types';
import { useAuthenticatedUser } from '../hooks/useAuthenticatedUser';
import { API_METHOD } from '../utils/constant';
import { removeEmptyFields, replaceUrlParams } from '../utils/helper';
import { request } from './index';

const INDENT_URLs = {
  GET_ALL_INDENTS: 'procurement/indent',
  REJECT_INDENT: 'procurement/indent/:indentId/reject',
  APPROVE_INDENT:
    'procurement/indent/:indentId/approve?approvedPurchaseType=:approvedPurchaseType',
  GET_ALL_PURCHASE_REQUISITIONS: 'purchase-requisition',
  CREATE_INDENT: 'procurement/indent',
};

interface CreateIndent {
  cess: number | null;
  gst: number | null;
  tcs: number | null;
  items:
    | {
        purchaseRequisitionId: string;
        quantity: number;
      }[]
    | null;
  paymentTerms: {
    term: 'BEFORE_DELIVERY' | 'AFTER_DELIVERY';
    days: number | undefined;
  };
  attachmentLinks: string[];
  qualityCheck: string;
  purchaseType: string;
  tnc: string;
  transportationMode: string;
  warehouseId: string;
}

export const useIndentService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const getIndents = (params: Partial<IndentContextType>) => {
    params = removeEmptyFields(params);
    return request(
      API_METHOD.GET,
      INDENT_URLs.GET_ALL_INDENTS,
      authenticatedUser,
      null,
      { params }
    );
  };

  const rejectIndent = (indentId: string) => {
    return request(
      API_METHOD.PUT,
      replaceUrlParams(INDENT_URLs.REJECT_INDENT, {
        indentId: indentId,
      }),
      authenticatedUser
    );
  };

  const approveIndent = (indentId: string, approvedPurchaseType: string) => {
    return request(
      API_METHOD.PUT,
      replaceUrlParams(INDENT_URLs.APPROVE_INDENT, {
        indentId: indentId,
        approvedPurchaseType: approvedPurchaseType,
      }),
      authenticatedUser
    );
  };

  const getPurchaseRequistions = (params: Partial<PRParamsType>) => {
    params = removeEmptyFields(params);
    return request(
      API_METHOD.GET,
      INDENT_URLs.GET_ALL_PURCHASE_REQUISITIONS,
      authenticatedUser,
      null,
      { params }
    );
  };

  const createIndent = (payload: CreateIndent) => {
    return request(
      API_METHOD.POST,
      INDENT_URLs.CREATE_INDENT,
      authenticatedUser,
      payload
    );
  };

  return {
    getIndents,
    rejectIndent,
    approveIndent,
    getPurchaseRequistions,
    createIndent,
  };
};
