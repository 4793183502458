import React, { useEffect, useMemo, useRef, useState } from 'react';
import Select from '../../atoms/Select';
import { DOCUMENT_TYPES, RESOLUTIONS } from '../../../utils/constant';
import { option } from '../../../utils/types';
import { ImageData } from '../../organisms/ImageUploader';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ImageComponent from '../../molecules/ImageUpload/Image';
import DisplayImage from '../../molecules/ImageUpload/DisplayImage';
import { FormikProps, useFormik } from 'formik';
import Button from '../../atoms/Button';
import { useNavigate } from 'react-router-dom';

interface IbrandRow {
    brand: number;
    images: (File | ImageData)[];
    availableBrands: option[];
}

export interface IImageMasterForm {
    productCategory: number | null;
    isRepresentationImage: boolean;
    catalogueType: string;
    shape: string;
    brandRow: IbrandRow[];
}

export interface IBrandTableProp {
    formik: FormikProps<IImageMasterForm>;
    brands: option[];
    mode: string;
    setImageIdsToDelete: any;
}

export const BrandTable: React.FC<IBrandTableProp> = ({ formik, brands, mode, setImageIdsToDelete }) => {
    const [selectedImage, setSelectedImageTo] = useState<string | null>(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [selectedBrands, setselectedBrandsTo] = useState<string[]>([]);
    const handleBackdropClickAway = () => {
        setImageDialogOpenTo(false);
    };

    const [imageDialogOpen, setImageDialogOpenTo] = useState(false);
    const handleViewClick = (imagePath: string) => {
        setSelectedImageTo(imagePath);
        setImageDialogOpenTo(true);
    };

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleAddRow = () => {
        formik.setFieldValue('brandRow', [...formik.values.brandRow, { brand: 0, images: [], availableBrands: brands.filter(brand => !selectedBrands.includes(brand.value.toString())) }])
    };

    const handleBrandChange = (index: number, brand: number) => {
        const newRows = [...formik.values.brandRow];
        const brandAlreadySelected = newRows.some(row => row.brand === brand);

        if (brandAlreadySelected) {
            alert("This brand is already selected.");
            return;
        }
        newRows[index].brand = brand;
        formik.setFieldValue('brandRow', newRows)
        const newSelectedBrands = formik.values.brandRow.map(row => row.brand.toString());
        setselectedBrandsTo(newSelectedBrands);
    };

    const removeImage = (index: number, id: number) => {
        const updatedRows = [...formik.values.brandRow];
        if (!(updatedRows[index].images[id] instanceof File)) {
            const image = updatedRows[index].images[id];
            if (!(image instanceof File) && 'id' in image) {
                setImageIdsToDelete((prevImageIds: number[]) => [...prevImageIds, image.id as number]);
            }
        }
        updatedRows[index].images = updatedRows[index].images.filter((_, currentIndex) => currentIndex !== id);

        formik.setFieldValue('brandRow', updatedRows)
    };

    const updateImages = (index: number, newImages: (File | ImageData)[]) => {
        const updatedRows = [...formik.values.brandRow];
        updatedRows[index] = {
            ...updatedRows[index],
            images: newImages
        };
        formik.setFieldValue('brandRow', updatedRows);
    };

    function handleDragEnd(result: any, index: number) {
        if (!result.destination) {
            return;
        }
        const reorderedImages = Array.from(formik.values.brandRow[index].images);
        const [movedImage] = reorderedImages.splice(result.source.index, 1);
        reorderedImages.splice(result.destination.index, 0, movedImage);
        const updatedRows = [...formik.values.brandRow];
        updatedRows[index] = {
            ...updatedRows[index],
            images: reorderedImages
        };
        formik.setFieldValue('brandRow', updatedRows);
    }

    const imageUploaderComponent = useMemo(() => (index: number) => (
        <DragDropContext onDragEnd={(result) => handleDragEnd(result, index)}>
            <Droppable droppableId="image-list" direction="horizontal">
                {(provided) => (
                    <div className="flex gap-5"
                        ref={provided.innerRef} {...provided.droppableProps}>
                        {formik.values.brandRow[index].images.map((image, imageIndex) => {
                            return (
                                <Draggable key={index + imageIndex} draggableId={index.toString() + imageIndex} index={imageIndex}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <ImageComponent
                                                key={index + imageIndex}
                                                index={index + imageIndex}
                                                imageData={image}
                                                isEditable={true}
                                                handleDeleteClick={() => removeImage(index, imageIndex)}
                                                handleViewClick={() => { handleViewClick(image instanceof File ? URL.createObjectURL(image) : image.path) }}
                                            />
                                        </div>

                                    )}
                                </Draggable>
                            );
                        })}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    ), [formik.values.brandRow]);

    const configuration = {
        maxImageCount: 4,
        maxfilesize: 200,
        documentTypes: [DOCUMENT_TYPES.IMAGE_JPEG, DOCUMENT_TYPES.IMAGE_PNG],
        documentResolution: RESOLUTIONS.SECONDARY_INVENTORY,
    };

    const handleUploadImage = (index: number, event: React.ChangeEvent<HTMLInputElement> | any) => {
        event.preventDefault();
        let uploadedImages: File[] = [];

        if (event.target.files) {
            uploadedImages = Array.from(event.target.files);
        } else if (event.dataTransfer.files) {
            uploadedImages = Array.from(event.dataTransfer.files);
        }

        const validImages = formik.values.brandRow[index].images;
        const invalidImages: string[] = [];
        const promises: Promise<void>[] = [];

        if (uploadedImages.length + formik.values.brandRow[index].images.length > 4) {
            alert(`Maximum of 4 images allowed`);
            return;
        }

        uploadedImages.forEach((uploadedImage: File) => {
            if (configuration.documentTypes.includes(uploadedImage.type) && uploadedImage.size <= (configuration.maxfilesize * 1024)) {
                const newImage = new Image();
                newImage.src = URL.createObjectURL(uploadedImage);
                const promise = new Promise<void>((resolve) => {
                    newImage.onload = function () {
                        // if (Math.abs(newImage.width - configuration.documentResolution.width) > configuration.documentResolution.tolerance ||
                        //     Math.abs(newImage.height - configuration.documentResolution.height) > configuration.documentResolution.tolerance) {
                        //     invalidImages.push(uploadedImage.name);
                        // } else {
                        validImages.push(uploadedImage);
                        // }
                        resolve();
                    };
                });
                promises.push(promise);
            } else {
                invalidImages.push(uploadedImage.name);
            }
        });

        Promise.all(promises).then(() => {
            updateImages(index, validImages);
            if (invalidImages.length > 0) {
                const invalidImage = invalidImages.join(', ');
                alert(`${invalidImage} files are not valid . Please check configuration . Size and Resolution should be less than ${configuration.maxfilesize} kb`);
                // alert(`${invalidImage} files are not valid . Please check configuration . Size and Resolution should be less than ${configuration.maxfilesize} kb , ${configuration.documentResolution.width} * ${configuration.documentResolution.height} `);
            }
        });

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const removeRow = (index: number, brand: number) => {
        const brandRow = formik.values.brandRow.filter((_, currentIndex) => currentIndex !== index);
        formik.setFieldValue('brandRow', brandRow);
        const newSelectedBrands = selectedBrands.filter(id => id !== brand.toString());
        setselectedBrandsTo(newSelectedBrands);
    }

    const handleSubmit = () => {
        const brandRows = formik.values.brandRow;
        const missingImages: number[] = [];

        brandRows.forEach((brandRow, index) => {
            if (!brandRow.images || brandRow.images.length === 0 || brandRow.brand === 0) {
                missingImages.push(brandRow.brand);
            }
        });

        if (missingImages.length > 0) {
            alert(`Please upload at least one image for each selected brands.`);
        } else {
            setIsButtonDisabled(true);
            formik.submitForm();
        }
    };

    return (
        <div className='flex flex-col gap-y-4 justify-start py-4'>
            {Array.isArray(formik.values.brandRow) && formik.values.brandRow.map((row, index) => (
                <div className='flex justify-start items-center gap-2.5' key={index}>
                    <div>
                        <Select
                            variant="outlined"
                            className='w-44'
                            label="Brand"
                            id={`brand-${index}`}
                            name="brand"
                            value={row.brand}
                            disabled={mode === "EDIT"}
                            onChange={(e: any) => handleBrandChange(index, e.target.value)}
                            options={mode !== "EDIT" ? formik.values.brandRow[index].availableBrands : brands}
                        />
                    </div>
                    {imageUploaderComponent(index)}
                    <>
                        {(() => {
                            const browseButtonRow = [];
                            for (let i = 0; i < 4-formik.values.brandRow[index].images.length; i++) {
                                browseButtonRow.push(
                                    <div className={`flex flex-col items-center rounded`}>
                                    <input
                                        type="file"
                                        className="hidden"
                                        onChange={(e) => handleUploadImage(index, e)}
                                        id={`fileInput-${index}`}
                                        ref={fileInputRef}
                                    />
                                    <label
                                        htmlFor={`fileInput-${index}`}
                                        className="cursor-pointer py-4 px-6"
                                    >
                                        {`Browse`}
                                    </label>
                                </div>                                );
                            }
                            return browseButtonRow;
                        })()}
                    </>

                    {imageDialogOpen && <DisplayImage selectedImage={selectedImage} imageDialogOpen={imageDialogOpen} setImageDialogOpenTo={setImageDialogOpenTo} handleBackdropClickAway={handleBackdropClickAway} />}
                    {mode !== "EDIT" && formik.values.brandRow.length > 1 && <div className="flex justify-end w-full m-auto">
                        <Button variant="outlined" label="Delete" onClick={(e) => removeRow(index, row.brand)} />
                    </div>}
                </div>
            ))}
            <div className='flex flex-row gap-x-4 justify-start'>
                {mode === "ADD" && <Button
                    variant="contained"
                    onClick={handleAddRow}
                    label="Add New Brand"
                />
                }
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    label="Submit"
                    disabled={isButtonDisabled}
                />
            </div>

        </div>
    );
};
