import { request } from ".";
import { ImageData } from "../components/organisms/ImageUploader";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";
import { HTTP_STATUS } from "../utils/types";

export const FILE_URLS = {
    GET_ALL_FILE: "/files/:resourceType/:resourceId",
    CREATE_FILES: "/files/",
    DELETE_FILE: "/files/:id",
    DELETE_IMAGE_IDS: "/files/:ids",
    UPDATE_IMAGE_IDS: "/files/:ids",
    SAVE_FILE: "/files",
    GET_UPLOAD_URL: "/file/upload/get_generated_presigned_url",
    GET_URL:"/file/:path"
}
interface ISaveFileRequestBody {
    resourceType: string;
    resourceId: number;
    path: string;
    mimeType: string;
    metaData?: any;
}


export const useFileService = () => {

    const { user: authenticatedUser } = useAuthenticatedUser();
    const v2BaseUrl = process.env.REACT_APP_API_V2_URL;

    const getFilesById = (resourceId: number, resourceType: string) => {
        return request(API_METHOD.GET, replaceUrlParams(FILE_URLS.GET_ALL_FILE, { resourceId, resourceType }), authenticatedUser);
    }

    const createFiles = (data: any) => {
        return request(API_METHOD.POST, FILE_URLS.CREATE_FILES, authenticatedUser, data);
    }
    const saveFile = (data: ISaveFileRequestBody) => {
        return request(API_METHOD.POST, FILE_URLS.SAVE_FILE, authenticatedUser, data, { params: {} }, null, process.env.REACT_APP_API_V2_URL);
    }

    const deleteFile = (id: number) => {
        return request(API_METHOD.DELETE, replaceUrlParams(FILE_URLS.DELETE_FILE, { id }), authenticatedUser, null);
    }

    const uploadImage = async (imageData: any, resourceId: number, resourceType: string) => {
        const formData = new FormData();
        for (let i = 0; i < imageData.length; i++) {
            if (imageData[i]) {
                formData.append(`files`, imageData[i]);
            }

        }
        const uploadFilesData = {
            resourceType: resourceType,
            resourceId: resourceId,
        };
        formData.append(
            "resource",
            new Blob([JSON.stringify(uploadFilesData)], {
                type: "application/json",
            })
        );

        try {
            if (imageData.length) {
                const Imageresponse = await createFiles(formData)
                if (Imageresponse.status === HTTP_STATUS.OK) {
                    return true;
                }
            }
        } catch (error) {
            return false;
        }
    }

    const deleteImagesByIds = (ids: string) => {
        return request(API_METHOD.DELETE, replaceUrlParams(FILE_URLS.DELETE_IMAGE_IDS, { ids }), authenticatedUser, null);
    };

    const updateImagesTime = (ids: string) => {
        return request(API_METHOD.PUT, replaceUrlParams(FILE_URLS.UPDATE_IMAGE_IDS, { ids }), authenticatedUser, null, { params: {} }, null, v2BaseUrl);
    };

    const generatePresignedUrl = (fileName: string, resourceType: string, resourceId: number) => {
        const url = FILE_URLS.GET_UPLOAD_URL;
        const params = { fileName, resourceType, resourceId };
        return request(API_METHOD.GET, url, authenticatedUser, null, { params });
    };

    const getImagePreSignedUrl = (path: string) => {
        const url = replaceUrlParams(FILE_URLS.GET_URL, { path });
        return request(API_METHOD.GET, url, authenticatedUser );
    }

    const uploadImageUsingPreSignedUrls = (image: File | ImageData, url : string) => {
        const headers = {
            'Content-Type': image instanceof File ? image.type : image.mimeType,
        };
        return request(API_METHOD.PUT, url, null ,image, null, undefined, process.env.REACT_APP_API_V1_URL ,headers);
    }

     const uploadPdfUsingPreSignedUrls = (image: File | ImageData, url: string) => {
        const headers = {
            'Content-Type': 'application/pdf',
            'Content-Disposition': 'inline'
        };
        return request(API_METHOD.PUT, url, null, image, null, undefined, process.env.REACT_APP_API_V1_URL ,headers);
    }

    return {
        getFilesById,
        createFiles,
        deleteFile,
        uploadImage,
        deleteImagesByIds,
        saveFile,
        updateImagesTime,
        generatePresignedUrl,
        getImagePreSignedUrl,
        uploadImageUsingPreSignedUrls,
        uploadPdfUsingPreSignedUrls
    };
}