import React, { useState } from 'react'
import { IStandardRequest, useStandardService } from '../../../services/useStandardService';
import { useNavigate } from 'react-router-dom';
import { HTTP_STATUS } from '../../../utils/types';
import { ADMIN_ROUTES, MODE, RESOURCE_TYPE } from '../../../utils/constant';
import { useSnackbar } from '../../../hooks/useSnackBar';
import StandardFormTemplate from '../../templates/Standard/StandardForm.template';
import { useFileService } from '../../../services/useFileService';
import { ImageData } from '../../organisms/ImageUploader';


const StandardCreatePage: React.FC = () => {

    const navigate = useNavigate();

    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const standardService = useStandardService();
    const fileService = useFileService();
    const [image, setImagesTo] = useState<(ImageData | File)[]>([]);

    const createStandard = async (standardRequestBody: IStandardRequest) => {
        try {
            const standardResponse = await standardService.createStandard(standardRequestBody);
            if (standardResponse.status === HTTP_STATUS.OK) {
                const resourceId: number = standardResponse.data.data.id ;
                const imageResponse =  await fileService.uploadImage(image,resourceId,RESOURCE_TYPE.STANDARD_GRADE);
                if(image.length>0){
                    imageResponse ? showSnackbar("success", `Image uploaded Success `) : showSnackbar("error", `Something went wrong. Please reupload image.`);
                }
                navigate(ADMIN_ROUTES.STANDARD_LIST);
            } else if(standardResponse.status === HTTP_STATUS.BAD_REQUEST){
                showSnackbar('error', standardResponse.data.statusMessage) 
            }
        } 
        catch (error) {
            showSnackbar("error", `Standard creation failed ${error}`);
        }
    }

    return (
        <div>
            {SnackBarComponent}
            <StandardFormTemplate standard={null} onStandardSubmit={createStandard} mode={MODE.ADD} image={image} setImagesTo={setImagesTo}  />
        </div>
    )
}

export default StandardCreatePage;