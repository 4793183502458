import React, { useEffect, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import { FormControl } from '@mui/material';
import ButtonV2 from "../../atoms/ButtonV2";
import AutoCompleteTextField, { AutoCompleteOption } from '../../molecules/AutoCompleteInput/AutoCompleteInputV2';
import ChipsV2, { ChipItem } from "../../molecules/Chips/ChipsV2";
import { IUpcSearchQueryParams, useCustomerGroupMasterService } from "../../../services/useCustomerGroupMasterService";
import { CUSTOMER_GROUP_SECTIONS, HTTP_STATUS } from "../../../utils/types";
import UpcMaterialFilterTemplate, { IUpcsByFilter } from "./UpcMaterialFilterForm.template";
import { changeSectionTo } from "./AddCustomerGroup.template";
import UpcCustomerListTemplate from "./UpcCustomerList.template";
import { useNavigate } from "react-router-dom";
import { ADMIN_ROUTES } from "../../../utils/constant";

interface UpcSearchFormTemplateProps {
    setCurrentSectionTo: (section: CUSTOMER_GROUP_SECTIONS) => void;
    formik: any;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid #EBEFFA`
    },
    filterContainer: {
        borderTop: `1px solid #EBEFFA`
    },
    filterHeading: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    selectLabel: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    configure: {
        color: theme.palette.textV2.primary.primary500,
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600,
    },
    activeProgressBar: {
        backgroundColor: "#CA4F02"
    },
    inActiveProgressBar: {
        backgroundColor: "#FD6202"
    },
    input: {
        "& .MuiInputBase-root": {
            borderRadius: "8px !important",
        },
    },
    errorMessage: {
        color: theme.palette.textV2.failure.failure600,
    },
}));

const UpcSearchFormTemplate: React.FC<UpcSearchFormTemplateProps> = ({ setCurrentSectionTo, formik }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const customerGroupService = useCustomerGroupMasterService();
    const [upcs, setupcsTo] = useState<IUpcsByFilter[]>([]);

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const requiredFields = ["upcCodes"];
        let fieldError = false;
        for (const key of requiredFields) {
            if (key in formik.errors) {
                formik.setFieldTouched(key, true);
                fieldError = true;
            }
        }
        if (fieldError === true) return;
        formik.setValues((prevValues: any) => ({
            ...prevValues,
            upcCodes: formik.values.upcCodes,
        }));
        setCurrentSectionTo(CUSTOMER_GROUP_SECTIONS.CUSTOMER_GROUP_PREVIEW);
    };

    const refreshUpc = async (inputValue?: string) => {
        try {
            const payload: IUpcSearchQueryParams = {};
            const upcFilterResponse = await customerGroupService.getUpcByMaterialFilter({
                page: 0,
                size: 10,
                sort: "createdAt,desc",
                upcCode: inputValue ?? ''
            },
                payload
            );
            if (
                upcFilterResponse?.status === HTTP_STATUS.OK &&
                upcFilterResponse?.data?.data?.content?.length
            ) {
                setupcsTo(upcFilterResponse.data.data.content);
            } else {
                throw new Error("Upcs fetch failed");
            }
        } catch (error) {
            setupcsTo([]);
        }
    };

    const upcOptions = useMemo(() => {
        return upcs?.map((upc: IUpcsByFilter) => ({ label: upc.upcCode, id: upc.upcId })) ?? []
    }, [upcs])

    useEffect(() => {
        refreshUpc();
    }, []);

    const updateSelectedUpc = (key: string) => (option: AutoCompleteOption | null) => {
        if (option) {
            const filterExists = formik?.values?.upcCodes?.some((filter: any) => filter?.label === option?.label);
            if (!filterExists) {
                formik.setFieldValue("upcCodes", [...formik?.values?.upcCodes, option]);
            }
        }
        formik.setFieldValue("upcCode", { label: "", id: "" });
    };

    const handleChipDelete = (value: ChipItem) => {
        formik.setFieldValue("upcCodes", formik.values.upcCodes.filter((upc: any) => upc.id !== value.id));
    }

    const handleCancel = () => {
        navigate(`${ADMIN_ROUTES.CUSTOMER_GROUP_MASTER_LIST}`);
    };

    const handleResetAll = () => {
        const fields = [
            "upcCodes",
            "otherFilter",
            "cityArray",
            "turnOverArray",
            "businessClassificationArray",
            "importExportArray",
            "superCategoryArray",
            "mainCategoryArray",
            "productCategoryArray",
            "manufacturerArray",
            "brandArray",
            "standardArray",
            "gradeArray",
            "shapeArray",
            "classesArray",
            "lengthArray",
            "thicknessArray",
            "widthArray"
        ];

        fields.forEach(field => formik.setFieldValue(field, []));
        formik.setFieldValue("materialFilterFlag", false);
    }

    return (
        <div className="grid gap-y-4">
            <div className={`w-full m-auto flex justify-between gap-x-2 text-var(--black, #4D4D4D) text-center text-sm `}>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-1 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-2 h-2 mx-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto font-semibold`}>Customer Group Master</div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-1 mx-auto'>
                        <div className={`${classes.inActiveProgressBar} w-2 h-2 mx-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Customer Group Configuration</div>
                    </div>
                    <div className={`${classes.inActiveProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>

            <div className={`${classes.container} p-6 grid gap-y-5 rounded-lg`}>
                <div className="flex justify-between">
                    <div className={`${classes.filterHeading} text-2xl font-medium my-auto`}>Add Customer Group</div>
                </div>

                <div className={`${classes.filterContainer} grid gap-y-2 pt-5`}>
                    <div className="grid gap-y-2 w-1/3">
                        <FormControl fullWidth className={classes.input}>
                            <AutoCompleteTextField
                                options={upcOptions}
                                label="Add UPC Directly"
                                onChange={updateSelectedUpc('upcCode')}
                                onSearch={refreshUpc}
                                value={formik.values.upcCode}
                                error={!!formik.errors.upcCode && formik.touched.upcCode}
                                isDisabled={false}
                                placeHolder="Add UPC"
                            />
                            {formik.errors.upcCodes && formik.touched.upcCodes && (
                                <div className={`${classes.errorMessage} text-xs mt-1 ml-4`}>
                                    {formik.errors.upcCodes}
                                </div>
                            )}
                        </FormControl>
                    </div>
                    <ChipsV2 values={formik?.values?.upcCodes ?? []} onDelete={handleChipDelete} />
                </div>

                <UpcMaterialFilterTemplate formik={formik} />

                {formik.values.upcCodes.length > 0 && <UpcCustomerListTemplate formik={formik} />}

                <div className="flex justify-between">
                    <ButtonV2 variant="secondaryContained" size="large" label={"Cancel"} onClick={handleCancel} />
                    <div className="flex gap-x-4">
                        <ButtonV2 variant="tertiaryContained" size="large" label={"Back"} onClick={changeSectionTo(setCurrentSectionTo)(CUSTOMER_GROUP_SECTIONS.CUSTOMER_GROUP_CONFIGURATION_FORM)} />
                        <ButtonV2 variant="tertiaryContained" size="large" label={"Reset All"} onClick={handleResetAll} />
                        <ButtonV2 variant="primaryContained" size="large" label={"Review"} disabled={formik.values.selectedCustomer <= 0} onClick={handleSubmit} />
                    </div>
                </div>
            </div>
        </div >
    );
};

export default UpcSearchFormTemplate;