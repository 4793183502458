import * as React from 'react';
// import React, { useContext, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import viewEyeIcon from "./../../../Assets/Icons/viewEyeIcon.svg";
import editPenIcon from "./../../../Assets/Icons/editPenIcon.svg";
import axios from 'axios';
import { formatJoeditData } from '../../../Core/Handlers/JoeditDisplayFormatter';
import { mapStandardStatusEnumToString } from '../../../Constants/Enums/StandardStatusEnum';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import TablePagination from '../Product Standard/Grade/TablePagination';
import debounce from 'lodash/debounce';
import { formatDate } from '../../../Core/Handlers/DateFormat';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
const BaseUrl = process.env.REACT_APP_API_V1_URL;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: '18px 0px' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const handleClick = () => {
  alert('Button clicked!');
};


const AttributeTypeTableIndex = () => {
  const { user } = useAuthenticatedUser();
  const [attributes, setAttributes] = useState([]);
  const [value, setValue] = React.useState(0);
  // Filters and Pagination
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalRecords, setTotalRecords] = useState(1);
  const [status, setStatus] = useState('All');
  const [sorting, setSorting] = useState('createdAt,desc');
  const [catalogueData, setCatalogueData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);

  };
  const navigate = useNavigate();

  const debouncedSearch = debounce((value) => {
    fetchData();
  }, 300);

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleSortingChange = (e) => {
    setSorting(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    debouncedSearch(e.target.value);
  };
  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery, pageNumber, pageSize, status, sorting]);



  // Fetch data from your API and update the state
  const fetchData = async () => {
    const baseUrl = `${BaseUrl}/attributes`;
    var attributeTypeValue = 'ATTRIBUTETYPE'
    var statusValue = status;
    var sortingValue = sorting;

    if (sortingValue === "All") {
      sortingValue = '';
    }
    let queryParams = `?name=${searchQuery}&page=${pageNumber}&size=${pageSize}&sort=${sorting}&attributeType=${attributeTypeValue}`;
    if (statusValue != "All") {
      queryParams += `&status=${statusValue}`;
    }
    const url = `${baseUrl}${queryParams}`;
    axios
      .get(url ,{
        headers: {
           Authorization: `${user.token}`,
           "Content-Type": "application/json",
        }
     }
   ).then((response) => {
        if ((response.data && response.data.data && response.data.data.content)) {
          setTotalRecords(response.data.data.totalElements);
          setAttributes(response.data.data.content);
          return;
        }
      })
      .catch((err) => {
        return
      });
  };


  const AttributeTableHeading = () => {
    return (
      <TableHead>
        <TableRow className='table-row'>
          <TableCell>Sr No.</TableCell>
          <TableCell>Attributes Type</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Created Date</TableCell>
          <TableCell>Last Modified</TableCell>
          <TableCell >Status</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const handleViewClick = (attributeId) => {
    navigate("/attribute-type/add",
      {
        state: {
          type: 'VIEW',
          itemId: attributeId,
        },
      }
    );
  }

  const handleEditClick = (attributeId) => {
    navigate("/attribute-type/add",
      {
        state: {
          type: 'EDIT',
          itemId: attributeId,
        },
      }
    );
  }


  const AttributeTableRecords = ({ records, curIndex=0 }) => {
    // Check if records is empty, return null if it is
    if (!records || records.length === 0) {
      return null;
    }

    return (
      <>
        {records.map((attribute, index) => (
          <TableRow key={attribute.id}>
            <TableCell component="th" scope="row">{curIndex + index + 1}</TableCell>
            <TableCell>{attribute.name}</TableCell>
            <TableCell>{formatJoeditData(attribute.description)}</TableCell>
            <TableCell>{formatDate(attribute.createdAt)}</TableCell>
            <TableCell>{formatDate(attribute.updatedAt)}</TableCell>
            <TableCell style={{ color: attribute.status === "ACTIVE" ? "green" : "red" }}>
              {mapStandardStatusEnumToString(attribute.status)}
            </TableCell>

            <TableCell>

              <button
                className="relative w-[1.13rem] h-[1.13rem] overflow-hidden shrink-0 text-center mr-2"
                onClick={(e) => handleViewClick(attribute.id)}
              >
                <img className="w-full h-full" alt="" src={viewEyeIcon} />
              </button>

              <button
                className="relative w-[1.13rem] h-[1.13rem] overflow-hidden shrink-0 text-center mr-2 "
                onClick={(e) => handleEditClick(attribute.id)}
              >
                <img className="w-full h-full" alt="" src={editPenIcon} />
              </button>

            </TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <>
      <Box className='category-main'>
        <CustomTabPanel value={value} index={0}>
          <div className="categories-table-wrapper">
            <div className="category-options">
              <div className="options-main-wrapper">
                <div className="options-main">
                  <FormControl sx={{ width: '190px' }}>
                    <InputLabel id='status-label'>Status</InputLabel>
                    <Select
                      labelId='status-label'
                      id='status-select'
                      label='Status'
                      value={status}
                      onChange={handleStatusChange}
                    >
                      <MenuItem value='All'>All</MenuItem>
                      <MenuItem value='ACTIVE'>Active</MenuItem>
                      <MenuItem value='INACTIVE'>Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="options-main">
                  <FormControl sx={{ width: '250px' }}>
                    <InputLabel id='status-label'>Sorting</InputLabel>
                    <Select
                      labelId='status-label'
                      id='status-select'
                      label='Status'
                      value={sorting}
                      onChange={handleSortingChange}
                    >
                      <MenuItem value="name,asc">Name (Ascending)</MenuItem>
                      <MenuItem value="name,desc">Name (Descending)</MenuItem>
                      <MenuItem value="createdAt,asc">Created Date(Ascending)</MenuItem>
                      <MenuItem value="createdAt,desc">Created Date(Descending)</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div>
                  <FormControl sx={{ width: "100%", maxWidth: "345px" }}
                  // sx={{ width: "345px" }}
                  >
                    <TextField
                      // className={`h-[3rem] block w-full p-1 border border-gray-500 rounded-md focus:outline-none ring-blue-500 focus:border-blue-500`}
                      id="outlined-basic"
                      label="Search by Attribute type"
                      variant="outlined"
                      fullWidth
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </FormControl>
                </div>
            </div>
            <TableContainer component={Paper}>
              <Table aria-label="customized table" className='custom-table'>
                <AttributeTableHeading />

                <TableBody className='table-data'>
                  <AttributeTableRecords records={attributes} curIndex={ pageNumber*pageSize }/>
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              pageNumber={pageNumber}
              pageSize={pageSize}
              handlePageSize={setPageSize}
              handlePageNumber={setPageNumber}
              totalRecordsData={totalRecords}
            />
          </div>
        </CustomTabPanel>

      </Box>
    </>
  );
}

export default AttributeTypeTableIndex;