import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import ProductListDisplay from '../ProductListItem';
import DisplayUpcItem from './DisplayUpcItem';
import cancelIcon from "./../../../Assets/Icons/Vector.svg";
import Typography from '@mui/material/Typography';
import SearchResultsCount from './SearchResults';
import SearchTags from './SearchedFilterTags';
import { Login } from '@mui/icons-material';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
const BaseUrl = process.env.REACT_APP_API_V1_URL;

const DisplaySearchedUpcDetails = ({ type, searchPayload, switchComponent, onClose, updateUpcSearchDetails, tagDeleteHandler, isCheckBoxView = false }) => {
    const { user } = useAuthenticatedUser();
    const [searchParams, setSearchParams] = useState(searchPayload);
    const [selectedUpcDetails, setSelectedUpcDetails] = useState(null);
    const [checkboxState, setCheckboxState] = useState([]);
    const [searchResults, setSearchResults] = useState(0);

    const convertSearchPayload = (searchData) => {
        const outputObject = {};
        for (const key in searchPayload) {
            if (key !== 'attributes') {
                if (searchPayload[key] && searchPayload[key].label !== undefined) {
                    outputObject[key] = searchPayload[key].label;
                    if (key === "shape"){
                        outputObject[key] = searchPayload[key].id;
                    }
                } else {
                    outputObject[key] = searchPayload[key];
                }
            }
            outputObject['classType']= type;
        }
        return outputObject;
    };


    useEffect(() => {
        fetchUpcDetails(convertSearchPayload(searchParams));
        // fetchUpcDetails(searchParams);
    }, [searchParams]);


    const fetchUpcDetails = (searchPayload) => {
        // return
        axios.post(`${BaseUrl}/search/upc`, searchPayload,
            {
                headers: {
                    Authorization: `${user.token}`,
                    "Content-Type": "application/json",
                }
            })
            .then(response => {

                setSelectedUpcDetails(response.data.data);
                setSearchResults(response.data.data.length)
                setCheckboxState(new Array(response.data.data.length).fill(false));
            })
            .catch(error => {
                console.error('Error fetching UPC details:', error);
                setSelectedUpcDetails(null);
            });
    };

    const handleTagDeleteFetch = (data) => {
        tagDeleteHandler(data);
        setSearchParams(data)
    }
    const addUpcDetailstoCatalogue = () => {
        const selectedDetailsToAddDirectly = selectedUpcDetails.filter((upcDetails, index) => {
            return checkboxState[index];
        });
        // return 
        updateUpcSearchDetails(selectedDetailsToAddDirectly);
        onClose();
    }

    const handleUpcSelection = (index) => {
        setCheckboxState((prev) => {
            const newState = [...prev];
            newState[index] = !newState[index];
            return newState;
        });
    };

    const displayButtonName = () => {
        if (type === "STANDARD") {
            return "UPCs"
        } else {
            return "MPCs"
        }
    }
    const displayTitleName = () => {
        if (type === "STANDARD") {
            return "Universal Product Code"
        } else {
            return "Master Product Code"
        }
    }

    return (
        <>
            <div className="absolute top-[1px] left-[2px] bg-white w-full flex flex-col p-6 box-border items-start justify-start gap-[24px] text-3xl text-text-color">
                <div className="self-stretch flex flex-row pt-0 px-0 pb-[10px] items-center justify-start gap-[3px] text-2xl text-text-color border-b-[1px] border-solid border-input-border">
                    <div className="flex-1 relative leading-[10px] font-semibold">
                        Search {displayTitleName()}
                    </div>

                    <button
                        className="w-30 h-21"
                        onClick={onClose}
                    >
                        <img
                            className="relative w-37 h-37 overflow-hidden shrink-0"
                            alt=""
                            src={cancelIcon}
                        />

                    </button>
                </div>
                <div className='flex justify-between w-full'>
                    <SearchTags updateFilters={handleTagDeleteFetch} searchPayload={searchPayload} />
                    <Button
                        sx={{
                            border: '1px solid #ID3679',
                            padding: '0.71rem .63rem',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                        }}
                        variant="outlined"
                        onClick={() => switchComponent(1)}
                    >
                        <div className="relative leading-[1.38rem] font-semibold text-blue">Back</div>
                    </Button>

                </div>
                <SearchResultsCount count={searchResults} />

                {selectedUpcDetails && selectedUpcDetails.length > 0 ? (
                    selectedUpcDetails.map((upcDetails, index) => (

                        <DisplayUpcItem
                            key={index}
                            index={index}
                            upcDetails={upcDetails}
                            updateSelection={handleUpcSelection}
                            isCheckBoxView={isCheckBoxView}
                            type={type}
                        />

                    ))
                ) : (
                    <Typography variant="h6" color="textSecondary" align="center">
                       {type === "STANDARD" ?  "No UPC details Found." :  "No MPC details Found." }
                    </Typography>
                )}


                <div className="self-stretch flex flex-row items-center justify-end gap-[22px] text-mini">
                    <Button
                        sx={{
                            border: '1px solid #ID3679',
                            padding: '0.71rem .63rem',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                        }}
                        variant="outlined"
                        onClick={() => switchComponent(1)}
                    >
                        <div className="relative leading-[1.38rem] font-semibold text-blue">Back</div>
                    </Button>


                    {selectedUpcDetails && selectedUpcDetails.length > 0 ? (
                        <button
                            className="outlined rounded bg-blue overflow-hidden flex flex-row py-[0.81rem] px-[1.63rem] items-center justify-center text-[0.94rem] text-white"
                            onClick={() => addUpcDetailstoCatalogue(selectedUpcDetails)}
                        >
                            <div className="relative leading-[1.38rem] font-semibold">
                                Add {displayButtonName()}
                            </div>
                        </button>

                    ) : (
                        null
                    )}



                </div>
            </div>
        </>
    );

};

export default DisplaySearchedUpcDetails;
