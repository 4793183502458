import * as yup from "yup";
import { createUseStyles } from 'react-jss';
import SearchIcon from '@mui/icons-material/Search';
import { useFormik } from 'formik';
import { HTTP_STATUS } from '../../../utils/types';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { BUSINESS_PROFILE_SECTIONS, ENTITY_TYPES, REGEX } from '../../../utils/constant';
import SelectV2 from '../../atoms/SelectV2';
import TextFieldV2 from '../../atoms/TextFieldV2';
import ButtonV2 from '../../atoms/ButtonV2';
import { IBusinessProfile } from "../../pages/CustomerManagement/ProfileOverview.page";

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.primarylightAccent}`,
        borderRadius: "8px",
    },
    addressContainer: {
        border: `1px solid ${theme.palette.border.tritiaryLight}`,
    },
    heading: {
        color: theme.palette.text.primaryLight,
    },
    title: {
        color: theme.palette.text.primaryLight,
    },
    gstSearch: {
        background: theme.palette.textV2.primary.primary500,
        color: "#FFF",
        padding: "14px",
        borderRadius: "10px",
        height: "56px",
    },
    datePicker: {
        border: "1px solid",
        borderColor: "#DDD",
        fontSize: "16px",
        fontWeight: 500,
        borderRadius: 8,
        "&:hover": {
            borderColor: "#FD6202",
            borderWidth: 2,
            outline: "none",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: "#DDD",
        },
        "&:focus-within": {
            outline: "none",
            borderColor: `#FD6202 !important`,
            borderWidth: "2px !important",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: `#FD6202 !important`,
        },
    },
    errorMessage: {
        color: theme.palette.action.danger,
    }
}));

export interface ICIN {
    entityType: string;
    dateOfIncorportation: string;
    cin: any;
    name: string,
    line1: string;
    line2: string;
    pincode: string;
    city: string;
    state: string;
}

interface ICINUpdateTemplateProps {
    updateBusinessProfileSection: (section: string, values: any) => void;
    businessProfile: IBusinessProfile | null;
    setIsEditing: (value: boolean) => void;
}

const CINUpdateTemplate: React.FC<ICINUpdateTemplateProps> = ({ updateBusinessProfileSection, businessProfile, setIsEditing }) => {

    const classes = useStyles();
    const bussinessProfileService = useBusinessProfileService();
    const { showSnackbar } = useSnackbar();

    const CINSchema = yup.object().shape({
        entityType: yup.string().required(' Entity Type is required'),
        dateOfIncorportation: yup
            .string()
            .test('complete-date', 'Invalid Date of incorporation.', function (value) {
                if (!value) return false;
                const date = moment(value, 'DD-MM-YYYY', true);
                return date.isValid() && date.year() > 1000;
            })
            .required('Date Of Incorporation is required'),
        cin: yup.string().matches(REGEX.CIN, 'Enter Valid CIN eg. L1234AB5678XYZ123456').required(' CIN is required'),
        name: yup.string(),
        line1: yup.string().required('Address line 1 is required'),
        line2: yup.string(),
        pincode: yup.string().required('Pincode is required'),
        city: yup.string().required('City is required'),
        state: yup.string().required('State is required'),
    });

    const cinFormik = useFormik<ICIN>({
        initialValues: {
            entityType: businessProfile?.cin?.entityType ?? "",
            dateOfIncorportation: businessProfile?.cin?.dateOfIncorportation ?? "",
            cin: businessProfile?.cin?.cin,
            name: businessProfile?.name ?? "",
            line1: businessProfile?.cinRegisteredAddress?.line1 ?? "",
            line2: businessProfile?.cinRegisteredAddress?.line2 ?? "",
            pincode: businessProfile?.cinRegisteredAddress?.pincode ?? "",
            city: businessProfile?.cinRegisteredAddress?.city ?? "",
            state: businessProfile?.cinRegisteredAddress?.state ?? "",
        },
        validationSchema: CINSchema,
        onSubmit: async (values: any) => {
            try {
                cinFormik.setSubmitting(true);
                const requestBody = {
                    cin: values?.cin,
                    entityType: values?.entityType,
                    dateOfIncorportation: values?.dateOfIncorportation,
                }
                await updateBusinessProfileSection(BUSINESS_PROFILE_SECTIONS.CIN, requestBody);
                setIsEditing(false);
            } catch (error) {
                showSnackbar("error", `Business profile update failed: ${error}`);
            }
        },
    });

    const getCINDetails = async (cin: string) => {
        try {
            const payload = {
                "cin": cin
            }
            const cinDetailsResponse = await bussinessProfileService.getCinDetails(payload);
            if (cinDetailsResponse.status === HTTP_STATUS.OK) {
                const details = cinDetailsResponse?.data?.data;
                cinFormik.setFieldValue("name", details.name);
                cinFormik.setFieldValue("line1", details.line1);
                cinFormik.setFieldValue("line2", details.line2);
                cinFormik.setFieldValue("pincode", details.pincode);
                cinFormik.setFieldValue("city", details.city);
                cinFormik.setFieldValue("state", details.state);

            }
        } catch (error) {
            showSnackbar('error', 'Failed to fetch CIN details');
        }
    };

    return (
        <form onSubmit={cinFormik.handleSubmit}>
            <div className={`${classes.container} p-6 rounded-lg w-full`}>
                <div className='flex justify-between items-center pb-6 border-b border-grey-500'>
                    <h2 className={`${classes.heading} text-2xl font-medium`}>CIN Details</h2>
                    <div className='flex gap-4'>
                        <ButtonV2 variant={'tertiaryContained'} label={'Back'} type="button" onClick={() => setIsEditing(false)} />
                        <ButtonV2 variant={'primaryContained'} label={'Save'} type="submit" />
                    </div>
                </div>
                <div className='flex flex-col gap-6 w-1/2'>
                    <div className='mb-2 mt-6'>
                        <SelectV2
                            variant={'outlined'}
                            label='Entity Type'
                            fullWidth
                            placeholder="Select Entity Type"
                            {...cinFormik.getFieldProps("entityType")}
                            error={cinFormik.touched.entityType &&
                                Boolean(cinFormik.errors?.entityType)}
                            options={ENTITY_TYPES?.map((entityType) => ({
                                value: String(entityType.id),
                                label: entityType.name,
                            })) || []}
                            helperText={cinFormik?.errors?.entityType}
                        />
                    </div>
                    <div>
                        <p className={`${classes.title} mb-2 text-base`}>Date Of Incorporation</p>
                        <div className='grid'>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    value={cinFormik?.values?.dateOfIncorportation ? moment(cinFormik.values.dateOfIncorportation, 'DD-MM-YYYY') : null}
                                    format="DD/MM/YYYY"
                                    onChange={(newValue) => {
                                        const formattedDate = moment(newValue).format('DD-MM-YYYY');
                                        cinFormik.setFieldValue('dateOfIncorportation', formattedDate);
                                    }}
                                    disableFuture
                                    className={classes.datePicker}
                                />
                            </LocalizationProvider>
                            {cinFormik?.errors?.dateOfIncorportation && cinFormik?.touched?.dateOfIncorportation && (
                                <div className={`${classes.errorMessage} text-xs`}>
                                    {cinFormik.errors.dateOfIncorportation}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="relative">
                        <p className={`${classes.title} mb-2 text-base`}>Enter the CIN number to fill out the details below</p>
                        <div className={`rounded-lg flex gap-4 w-full`}>
                            <div className='flex-1'>
                                <TextFieldV2
                                    fullWidth
                                    placeholder="Enter CIN"
                                    {...cinFormik.getFieldProps("cin")}
                                    onChange={(event: any) => {
                                        cinFormik.handleChange({
                                            target: { name: 'cin', value: (event.target.value).toUpperCase() },
                                        });
                                    }}
                                    error={cinFormik.touched.cin && Boolean(cinFormik.errors.cin)}
                                    helperText={cinFormik.touched.cin && cinFormik.errors.cin ? String(cinFormik.errors.cin) : ''}
                                />
                            </div>
                            <div className={`${classes.gstSearch}`} onClick={() => getCINDetails(cinFormik?.values?.cin)}>
                                <SearchIcon />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${classes.addressContainer} grid gap-y-8 w-full p-4 rounded-xl mt-6`}>
                    <p className={`${classes.heading} font-medium text-base`}>Registered Address (as per ROC)</p>
                    <div className='grid gap-y-4'>
                        <TextFieldV2
                            label="Enter Company Name"
                            fullWidth
                            placeholder="Enter Company"
                            {...cinFormik.getFieldProps("name")}
                            error={
                                cinFormik.touched.name &&
                                Boolean(cinFormik.errors?.name)
                            }
                            helperText={(cinFormik.touched?.name &&
                                Boolean(cinFormik.errors?.name)) && cinFormik.errors?.name}
                            inputProps={{ readOnly: true }}
                        />
                        <TextFieldV2
                            label="Address Line 1"
                            fullWidth
                            placeholder="Enter Address Line 1"
                            {...cinFormik.getFieldProps("line1")}
                            error={
                                cinFormik.touched.line1 &&
                                Boolean(cinFormik.errors.line1)
                            }
                            inputProps={{ readOnly: true }}
                        />
                        <TextFieldV2
                            label="Address Line 2"
                            fullWidth
                            placeholder="Enter Address Line 2"
                            {...cinFormik.getFieldProps("line2")}
                            error={
                                cinFormik.touched.line2 &&
                                Boolean(cinFormik.errors.line2)
                            }
                            inputProps={{ readOnly: true }}
                        />
                        <div className='grid grid-cols-3 gap-3'>
                            <TextFieldV2
                                label="Pincode"
                                fullWidth
                                placeholder="Enter Pincode"
                                {...cinFormik.getFieldProps("pincode")}
                                error={
                                    cinFormik.touched.pincode &&
                                    Boolean(cinFormik.errors.pincode)
                                }
                                inputProps={{ readOnly: true }}
                            />
                            <TextFieldV2
                                label="City"
                                fullWidth
                                placeholder="Enter City"
                                {...cinFormik.getFieldProps("city")}
                                error={
                                    cinFormik.touched?.city &&
                                    Boolean(cinFormik.errors?.city)
                                }
                                inputProps={{ readOnly: true }}
                            />
                            <TextFieldV2
                                label="State"
                                fullWidth
                                placeholder="Enter State"
                                {...cinFormik.getFieldProps("state")}
                                error={
                                    cinFormik.touched?.state &&
                                    Boolean(cinFormik.errors?.state)
                                }
                                inputProps={{ readOnly: true }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default CINUpdateTemplate;
