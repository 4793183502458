import { useEffect, useState } from 'react'
import { useStandardService } from '../../../services/useStandardService';
import { IPagination } from '../../../utils/types';
import { ADMIN_ROUTES, initialPaginationValues } from '../../../utils/constant';
import StandardListTemplate from '../../templates/Standard/StandardList.template';
import Button from '../../atoms/Button';
import { useNavigate } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import TitleHeading from '../../atoms/CustomHeading/TitleHeading';
import { useSnackbar } from '../../../hooks/useSnackBar';
import GradeTableIndex from '../../../App/Pages/AdminMaster/Product Standard/Grade/GradeTableIndex';

export interface IStandardFilter {
    status: string;
    search: string;
    sort: string;
}

export interface IStandard {
    id: number;
    name: string;
    prefix: string;
    yearInUse: string;
    yearLastUpdated: string;
    hsnCodes: string;
    bisHeading: string;
    equivalentTags: string;
    status: string;
    createdBy: string;
    updatedBy: string;
    application: string;
    features: string;
    description: string;
    createdAt: string;
    updatedAt: string;
}

const initialStandardFiltersValues: IStandardFilter = {
    search: "",
    sort: "createdAt,desc",
    status: "ALL",
}

const useStyles = createUseStyles((theme: any) => ({
    inActiveTab: {
        color: theme.palette.text.primaryLight,
    },
    activeTab: {
        color: theme.palette.button.primaryDark,
        borderBottom: `1px solid ${theme.palette.button.primaryDark}`,
    },
    title: {
        color: theme.palette.button.primaryDark,
    },
}));

const STANDARD_SECTION = {
    STANDARD: { label: 'Standard', value: 'STANDARD' },
    GRADE: { label: 'Grade', value: 'GRADE' }
};

const ADD_NEW_STANDARD_GRADE = {
    STANDARD: "Add New Standards",
    GRADE: "Add New Grade",
}

const StandardListPage: React.FC = () => {

    const navigate = useNavigate();
    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const standardService = useStandardService();

    const [activeCategory, setActiveCategory] = useState(STANDARD_SECTION.STANDARD);

    const [standards, setStandardsTo] = useState<IStandard[]>([]);
    const [standardFilters, setStandardFiltersTo] = useState<IStandardFilter>(initialStandardFiltersValues);
    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);

    const getAllStandards = (page: number, size: number, sort: string) => {
        const params = {
            search: standardFilters?.search.trim(),
            status: standardFilters?.status === "ALL" ? "" : standardFilters?.status,
            page: page,
            size: size,
            sort: sort,
        }
        standardService.getAllStandards(params)
            .then((standardResponse) => {
                if (standardResponse?.data?.data) {
                    const { totalElements, totalPages, content } = standardResponse?.data?.data;
                    setPaginationTo({
                        ...pagination,
                        totalPages: totalPages,
                        totalRecords: totalElements
                    });
                    setStandardsTo(content)
                }
                else {
                    setStandardsTo([]);
                }
            })
            .catch((error) => {
                setStandardsTo([]);
                showSnackbar('error', error)
            });
    }

    useEffect(() => {
        getAllStandards(pagination.currentPage, pagination.pageSize, standardFilters.sort);
    }, [pagination.currentPage, pagination.pageSize ,standardFilters])

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage
        }));
    };

    const handleCreateStandard = () => {
        navigate(ADMIN_ROUTES.STANDARD_CREATE);
    };

    const handleCreateGrade = () => {
        navigate(ADMIN_ROUTES.GRADE_CREATE);
    };

    const handleTabChange = (category: any) => () => {
        setActiveCategory(category);
        const categoryLabel = category.label;
        navigate(`/${categoryLabel.toLowerCase()}`, {
            state: {
                type: categoryLabel,
            },
        });
    };

    const handleFiltersChange = (event: any) => {
        const { name, value } = event.target;
        setPaginationTo({...pagination, currentPage: 0})
        setStandardFiltersTo({ ...standardFilters, [name]: value ?? "" });
    }

    return (
        <>
            {SnackBarComponent}
            <div className='grid gap-y-3'>
                <div className=''>
                    <TitleHeading text={activeCategory === STANDARD_SECTION.STANDARD ? STANDARD_SECTION.STANDARD.label : STANDARD_SECTION.GRADE.label} />
                    <div className='flex justify-between mt-3'>
                        <div className='flex gap-x-6'>
                            <span onClick={handleTabChange(STANDARD_SECTION.STANDARD)} className={activeCategory === STANDARD_SECTION.STANDARD ? `${classes.activeTab} text-base font-medium pb-3` : `${classes.inActiveTab} font-medium text-base cursor-pointer`} >
                                Standard
                            </span>
                            <span onClick={handleTabChange(STANDARD_SECTION.GRADE)} className={activeCategory === STANDARD_SECTION.GRADE ? `${classes.activeTab} text-base font-medium pb-3` : `${classes.inActiveTab} font-medium text-base cursor-pointer`} >
                                Grade
                            </span>
                        </div>
                        {activeCategory === STANDARD_SECTION.STANDARD
                            ? <Button variant="contained" label={ADD_NEW_STANDARD_GRADE.STANDARD} onClick={handleCreateStandard} />
                            : <Button variant="contained" label={ADD_NEW_STANDARD_GRADE.GRADE} onClick={handleCreateGrade} />}
                    </div>
                </div>
                {activeCategory === STANDARD_SECTION.STANDARD
                    ? <StandardListTemplate standards={standards} pagination={pagination} handleFiltersChange={handleFiltersChange} handlePaginationChange={handlePaginationChange} standardFilter={standardFilters} handleRowsPerPageChange={handleRowsPerPageChange} />
                    : <GradeTableIndex />}
            </div>
        </>
    )
}
export default StandardListPage;