import React, { useEffect, useState } from 'react'
import { IPagination } from '../../../utils/types';
import { useSecondaryCatalogueService } from '../../../services/useSecondaryCatalogueService';
import SecondaryCatalogueListTemplate from '../../templates/SecondaryCatalogue/SecondaryCatalogueList.template';
import { useParams, useSearchParams } from 'react-router-dom';
import { CATALOGUE_TYPES } from '../../../utils/constant';
import { getKeyByValue } from '../../../utils/helper';

export interface ISecondaryCatalogueFilter {
	status: string;
	sort: string;
	productCategory: string;
	catalogueName: string;
}

export interface ISecondaryCatalogue {
	id: number;
	catalogueName: string;
	status: string;
	createdAt: string;
	updatedAt: string;
}

const initialPaginationValues: IPagination = {
	currentPage: 0,
	pageSize: 10,
	totalPages: 1,
	totalRecords: 0,
}

const initialSecondaryCataloguesFiltersValues: ISecondaryCatalogueFilter = {
	status: "ALL",
	catalogueName: "",
	sort: "createdAt,desc",
	productCategory: "ALL",
}

const SecondaryCatalogueList: React.FC = () => {
	const [params,setParams] = useSearchParams();
	const catalogueType = params.get("catalogueType") || CATALOGUE_TYPES.CURRENT_INVENTORY;
	const secondaryCatalogueService = useSecondaryCatalogueService();
	const [secondaryCatalogues, setSecondaryCataloguesTo] = useState<ISecondaryCatalogue[]>([]);
	const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);
	const [secondaryCatalogueFilter, setSecondaryCatalogueFiltersTo] = useState<ISecondaryCatalogueFilter>(initialSecondaryCataloguesFiltersValues);
	const [selectedIds, setSelectedIdsTo] = useState({
		grades: [],
		shapes: []
	})
	
	useEffect(()=>{
		getAllSecondaryCatalogues(pagination.currentPage, pagination.pageSize, selectedIds);
	},[catalogueType])
   
	const handleApplyFiltersCatalogue = (filterData: any) => {
		setSelectedIdsTo(filterData);
		getAllSecondaryCatalogues(pagination.currentPage, pagination.pageSize, filterData);
	}

	const getAllSecondaryCatalogues = (page: number, size: number, filterData?: any) => {
		let params = {
			status: secondaryCatalogueFilter.status === "ALL" ? "" : secondaryCatalogueFilter.status,
			catalogueName: secondaryCatalogueFilter.catalogueName.trim(),
			page: page,
			size: size,
			sort: secondaryCatalogueFilter.sort,
			categoryId: secondaryCatalogueFilter.productCategory === "ALL" ? undefined : parseInt(secondaryCatalogueFilter.productCategory, 10),
			catalogueType: getKeyByValue(CATALOGUE_TYPES, catalogueType) 
		}
		let body = {}
		secondaryCatalogueService.getAllSecondaryCatalogues(body, params)
			.then((secondaryInventoriesResponse) => {
				if (secondaryInventoriesResponse.data.data) {
					const { totalElements, totalPages } = secondaryInventoriesResponse?.data?.data;
					setPaginationTo({
						...pagination,
						totalPages: totalPages,
						totalRecords: totalElements
					});
					setSecondaryCataloguesTo(secondaryInventoriesResponse?.data?.data?.content);
				}
				else {
					setSecondaryCataloguesTo([]);
				}
			})
			.catch((error) => {
				console.error("Secondary Catalogues fetching error - ", error);
			});
	}

	const handleFiltersChange = (event: any) => {
		const { name, value } = event.target;
		setSecondaryCatalogueFiltersTo({ ...secondaryCatalogueFilter, [name]: value ?? "" });
	}

	const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPaginationTo((prevPagination) => ({
			...prevPagination,
			currentPage: newPage
		}));
	};

	const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newRowsPerPage = parseInt(event.target.value, 10);
		setPaginationTo((prevPagination) => ({
			...prevPagination,
			pageSize: newRowsPerPage
		}));
	}

	const handleDeleteClick = (id: number) => (e: any) => {
		const confirmed = window.confirm("Are you sure you want to delete this secondary catalogue?");
		if (confirmed) {
			secondaryCatalogueService.deleteSecondaryCatalogue(id)
				.then((response) => {
					getAllSecondaryCatalogues(pagination.currentPage, pagination.pageSize)
				})
				.catch((error) => {
					console.error("Error fetching warehouse details ", error);
				});
		}
	}

	useEffect(() => {
		getAllSecondaryCatalogues(pagination.currentPage, pagination.pageSize);
	}, [pagination.currentPage, pagination.pageSize, secondaryCatalogueFilter.status, secondaryCatalogueFilter.sort, secondaryCatalogueFilter.productCategory, secondaryCatalogueFilter.catalogueName]);

	return (
		<SecondaryCatalogueListTemplate catalogueType={catalogueType} secondaryCatalogues={secondaryCatalogues} pagination={pagination} handlePaginationChange={handlePaginationChange} secondaryCatalogueFilter={secondaryCatalogueFilter} handleFiltersChange={handleFiltersChange} handleRowsPerPageChange={handleRowsPerPageChange} selectedIds={selectedIds} handleApplyFiltersCatalogue={handleApplyFiltersCatalogue} handleDeleteClick={handleDeleteClick} />
	)
}

export default SecondaryCatalogueList