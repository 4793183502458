import React, { useState } from "react";
import SecondaryInventoryFormTemplate from "../../templates/SecondaryInventory/SecondaryInventoryForm.template";
import { MODE } from "../../../utils/constant";
import { ImageData } from "../../organisms/ImageUploader";

const SecondaryInventoryCreate: React.FC = () => {
  const [image, setImagesTo] = useState<(ImageData | File)[]>([]);
  return (
    <SecondaryInventoryFormTemplate secondaryInventory={null} mode={MODE.ADD} image={image} setImagesTo={setImagesTo} />
  )
}
export default SecondaryInventoryCreate