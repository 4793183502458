import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export const VENDOR_URLS = {
    CREATE_VENDOR: "/vendor",
    GET_VENDOR_BY_ID: "/vendor/:id",
    UPDATE_SECTION: "/vendor/:section/:id",
    GET_ALL_VENDORS: "/vendors",
    UPDATE_VENDOR_STATUS_BY_ID: "/vendor/:id",
    GET_VENDOR_STATS: "/vendor/stats",
    UPDATE_VENDOR_BRAND_MAPPING: "/vendor/:id/brands",
    GET_VENDOR_BRAND_MAPPING: "/vendor/:id/brands"
};

export interface ICustomerFilterRequest {
    search?: string;
    customerType?: string;
    page?: number;
    size?: number;
    sort?: any;
    startDate?: string;
    endDate?: string;
    vendorTypes?: string | null;
}

export const useVendorServiceV2 = () => {
    const { user } = useAuthenticatedUser();
    const BASE_URL_V2 = process.env.REACT_APP_API_V2_URL;

    const getVendor = (id: number) => {
        return request(API_METHOD.GET, replaceUrlParams(VENDOR_URLS.GET_VENDOR_BY_ID, { id }), user, null, undefined, null, BASE_URL_V2);
    }

    const updateVendorSections = (id: number, data: any, section: string) => {
        return request(API_METHOD.PUT, replaceUrlParams(VENDOR_URLS.UPDATE_SECTION, { id, section }), user, data, undefined, null, BASE_URL_V2);
    }

    const createVendor = (data: any) => {
        return request(API_METHOD.POST, VENDOR_URLS.CREATE_VENDOR, user, data, undefined, null, BASE_URL_V2);
    }

    const updateVendorStatus = (id: number, data: any) => {
        return request(API_METHOD.PUT, replaceUrlParams(VENDOR_URLS.UPDATE_VENDOR_STATUS_BY_ID, { id }), user, data, undefined, null, BASE_URL_V2);
    }

    const getAllVendors = (params: ICustomerFilterRequest) => {
        return request(API_METHOD.GET, VENDOR_URLS.GET_ALL_VENDORS, user, null, { params }, null, BASE_URL_V2)
    }

    const getVendorStats = (params: ICustomerFilterRequest) => {
        return request(API_METHOD.GET, replaceUrlParams(VENDOR_URLS.GET_VENDOR_STATS, { params }), user, null, undefined, null, BASE_URL_V2)
    }

    const getVendorMappedBrands = (id: number) => {
        return request(API_METHOD.GET, replaceUrlParams(VENDOR_URLS.GET_VENDOR_BRAND_MAPPING, { id }), user, null, undefined, null, BASE_URL_V2);
    }

    const updateVendorMappedBrands = (id: number, data: {brandIds: number[]}) => {
        return request(API_METHOD.PUT, replaceUrlParams(VENDOR_URLS.UPDATE_VENDOR_BRAND_MAPPING, { id }), user, data, undefined, null, BASE_URL_V2);
    }

    return {
        getVendor, updateVendorSections, createVendor, getAllVendors, getVendorStats, updateVendorStatus, getVendorMappedBrands, updateVendorMappedBrands
    }
}