import { createUseStyles } from 'react-jss';
import { IRegisteredAddress } from './ProfileOverview.template';
import { getProfileDisplayData } from '../../../utils/helper';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.textV2.tertiary.tertiary100}`,
        width: "100%"
    },
    title: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    value: {
        color: theme.palette.text.primaryLight,
    },
    property: {
        color: theme.palette.text.primaryLight,
    },
}));

export interface IBusinessAddessTemplateProps {
    address: IRegisteredAddress;
    title: string;
}

const BusinessAddressTemplate: React.FC<IBusinessAddessTemplateProps> = ({ address, title }) => {

    const classes = useStyles();

    return (
        <div className={`p-4 flex flex-col gap-y-8 rounded-xl ${classes.container} h-full`}>
            <div className={`${classes.title} text-base	font-medium`}>{getProfileDisplayData(title)}</div>
            <div className='flex flex-col gap-y-3 flex-1'>
                <div className="grid gap-2">
                    <div className={`${classes.value} font-normal text-sm`}>Address Line 1</div>
                    <div className={`${classes.property} font-medium text-base break-all`}>{getProfileDisplayData(address?.addressLine1)}
                    </div>
                </div>
                <div className="grid gap-2">
                    <div className={`${classes.value} font-normal text-sm`}>Address Line 2</div>
                    <div className={`${classes.property} font-medium text-base`}>{getProfileDisplayData(address?.addressLine2)}</div>
                </div>
                <div className="flex flex-col gap-2 flex-1">
                    <div className={`${classes.value}  font-normal text-sm grid grid-cols-3 gap-x-6`}>
                        <div className='w-1/3 '>Pincode</div>
                        <div className='w-1/3 '>City</div>
                        <div className='w-1/3 '>State</div>
                    </div>
                    <div className={`${classes.property}  font-medium text-base grid grid-cols-3 gap-x-6 flex-1`}>
                        <div>{getProfileDisplayData(address?.pincode)}</div>
                        <div>{getProfileDisplayData(address?.city)}</div>
                        <div>{getProfileDisplayData(address?.state)}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BusinessAddressTemplate
