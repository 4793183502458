import React, { useEffect, useMemo, useState } from 'react'
import { FormControl, TextField } from "@mui/material";
import { useSecondaryInventoryService } from '../../../services/useSecondaryInventoryService';
import Button from '../../atoms/Button';
import { ISecondaryInventoryForm, secondaryInventorySectionProp } from './SecondaryInventoryForm.template';
import { ADMIN_ROUTES, MODE, RANGE_ATTRIBUTES, SHAPE_TYPES } from '../../../utils/constant';
import { useNavigate } from 'react-router-dom';
import { CATEGORY_TYPE, HTTP_STATUS, SECONDARY_INVENTORY_SECTIONS } from '../../../utils/types';
import { Checkbox } from '@mui/material';
import Select from '../../atoms/Select';
import { useAttributeService } from '../../../services/useAttributeService';
import { AttributeSpecification, Category, useCategoryService } from '../../../services/useCategoryService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { createUseStyles } from 'react-jss';
import AutoCompleteTextField, { AutoCompleteOption } from '../../molecules/AutoCompleteInput/AutoCompleteInput';

interface AttributeErrorSchema {
	productSpecification: {
		minValue: string;
		maxValue: string;
		name: string;
		uom: string;
	}[]
}

interface IGrade {
	id: number;
	name: string;
}
interface Attribute {
	[key: string]: AttributeSpecification[] | [];
};

const useStyles = createUseStyles((theme: any) => ({
	errorMessage: {
		color: theme.palette.action.danger,
	},
	container: {
		backgroundColor: theme.palette.background.primaryLight,
	},
	heading: {
		color: theme.palette.text.primaryDarkLight,
	},
}));

const CategoryDetailTemplate: React.FC<secondaryInventorySectionProp> = ({ formik, id, mode, setCurrentSectionTo }) => {
	const navigate = useNavigate();
	const classes = useStyles();
	const { showSnackbar, SnackBarComponent } = useSnackbar();

	const secondaaryInventoryService = useSecondaryInventoryService();
	const attributeService = useAttributeService();
	const categoryService = useCategoryService();

	const [attributeSpecification, setAttributeSpecification] = useState<boolean[]>(Array(formik.values.attributes?.productSpecification.length).fill(false));
	const handleAttributeSpecificationChange = (event: React.ChangeEvent<HTMLInputElement>, index: number): void => {
		attributeSpecification[index] = event.target.checked;
		setAttributeSpecification([...attributeSpecification]);
	};
	
	const [attributeValues, setAttributeValues] = useState<Attribute[]>([]);
	const [superCategoryData, setSuperCategoryData] = useState<Category[] | null>(null);
	const [mainCategoryData, setMainCategoryData] = useState<Category[] | null>(null);
	const [productCategoryData, setProductCategoryData] = useState<Category[] | null>([]);
	const [gradeData, setGradeData] = useState<IGrade[] | null>([]);

	const handleAttributeNameChange = (event: any, index: number) => {
		const selectedAttributeName = event.target.value;
		const selectedAttribute = attributeValues.find((attribute) => attribute.name === selectedAttributeName);
		const uom = selectedAttribute?.uom || '';
		formik.setValues((prevValues: any) => ({
			...prevValues,
			attributes: {
				...prevValues.attributes,
				productSpecification: prevValues.attributes.productSpecification.map((spec: any, idx: number) => {
					if (idx === index) {
						return {
							...spec,
							name: selectedAttributeName,
							uom: uom,
						};
					}
					return spec;
				}),
			},
		}));
	};


	const fetchAttributeValues = () => {
		attributeService.getAllAttributes({
			page: 0,
			size: 100,
		})
			.then((response) => {
				if (response.data.data) {
					setAttributeValues(response?.data?.data?.content);
				} else {
					setAttributeValues([]);
				}
			}).catch(error => {
				console.error("Error fetching attribute values: ", error);
				return [];
			})
	};

	const fetchProductCategoryById = async () => {

		if (formik?.values?.productCategory?.id) {
			fetchCategoryAttributeById(formik?.values?.productCategory?.id);
			if (formik.values.attributes?.productSpecification) {
				const updatedSpecification = formik.values.attributes.productSpecification.map((spec: any) => {
					return spec.minValue !== spec.maxValue && spec.maxValue !== "";
				});
				setAttributeSpecification(updatedSpecification);
			}
		}
	};

	const fetchCategoryAttributeById = async (productCategoryId: number) => {
		if (productCategoryId && mode !== "UPDATE") {
			try {
				const response = await secondaaryInventoryService.getCategoryById(productCategoryId);
				if (response?.data?.data?.attributeDetails?.SPECIFICATION) {
					const isSpecificationMinValuePresent = !!formik.values.attributes?.productSpecification?.[0]?.minValue;
					if (!isSpecificationMinValuePresent) {
						const updatedProductSpecification = response.data.data.attributeDetails.SPECIFICATION.map((specification: { name: string, uom: string }) => ({
							name: specification.name.trim(),
							minValue: '',
							maxValue: '',
							uom: specification.uom && specification.uom,
						}));
						formik.setValues({
							...formik.values,
							attributes: {
								...formik.values.attributes,
								productSpecification: updatedProductSpecification
							}
						});
					}
				}
			} catch (error) {
				console.error("Error fetching warehouse details ", error);
			}
		}
	};

	const handleAddSpecification = () => {
		formik.setValues((prevState: any) => ({
			...prevState,
			attributes: {
				...prevState.attributes,
				productSpecification: [
					...(prevState.attributes?.productSpecification || []),
					{ name: '', minValue: '', maxValue: '', uom: '' }
				]
			}
		}));
	};

	const handleRemoveSpecification = (index: number) => {
		const updatedAttributes = formik.values.attributes?.productSpecification.filter((_: any, specificationIndex: any) => specificationIndex !== index);
		formik.setValues((prevState: any) => ({
			...prevState,
			attributes: {
				...prevState.attributes,
				productSpecification: updatedAttributes
			}
		}));
	};

	const redirectToSecondaryInventoryList = () => {
		navigate(-1);
	};

const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		const requiredFields = ["superCategory", "mainCategory", "productCategory", "grade", "shape", "attributes.productSpecification"];
		let fieldError = false;
		for (const key of requiredFields) {
			if (key === 'attributes.productSpecification') {
				formik.values.attributes?.productSpecification.forEach((_: any, index: any) => {
					formik.setFieldTouched(`${key}[${index}].minValue`, true);
					formik.setFieldTouched(`${key}[${index}].uom`, true);
				})
				if ((formik.errors?.attributes as unknown as AttributeErrorSchema)?.productSpecification?.length) return;
			}
			else if (key in formik.errors) {
				formik.setFieldTouched(key, true);
				fieldError = true;
			}
		}
		const specNames = new Set<string>();
		let hasDuplicate = false;
		formik.values.attributes?.productSpecification.forEach((spec: any) => {
			if (specNames.has(spec.name)) {
				hasDuplicate = true;
			} else {
				specNames.add(spec.name);
			}
		});

		if (hasDuplicate) {
			return showSnackbar('error', "Duplicate Name in Product Specification exists");
		}
		formik.setValues((prevValues: ISecondaryInventoryForm) => ({
			...prevValues,
			superCategory: formik.values.superCategory,
			mainCategory: formik.values.mainCategory,
			productCategoryId: formik.values.productCategoryId,
			attributes: formik.values.attributes,
			gradeId: formik.values.gradeId,
			shape: formik.values.shape,
		}));
		if (fieldError === true) return;
		setCurrentSectionTo(SECONDARY_INVENTORY_SECTIONS.DEFECT_DETAILS);
        const updatedProductSpecifications = formik.values.attributes.productSpecification.map((spec : any) => {
			if (spec.minValue) {
			  spec.minValue = spec.name.toLowerCase() === 'thickness' ? parseFloat(spec.minValue).toFixed(2) : spec.minValue; 
			}
			if (spec.maxValue) {
			  spec.maxValue = spec.name.toLowerCase() === 'thickness' ? parseFloat(spec.maxValue).toFixed(2) : spec.maxValue; // Example manipulation
			}
		  
			return spec;
		  });
		formik.setFieldValue('attributes.productSpecification', updatedProductSpecifications);
	};
	
	useEffect(() => {
		if (!gradeOptions.length)
			fetchAttributeValues();
	}, []);

	useEffect(() => {
		fetchProductCategoryById();
	}, [formik?.values?.productCategory?.id]);

	const loadSuperCategory = (inputValue?: string) => {
		categoryService.getAllCategories({ level: CATEGORY_TYPE.SUPER_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', name: inputValue ?? '', status: 'ACTIVE' })
			.then(res => {
				if (res.status === HTTP_STATUS.OK)
					setSuperCategoryData(res?.data?.data?.content)
			}).catch((error) => {
				console.error("Error Fetching Category: ", error);
			})
	}

	const loadMainCategory = (inputValue?: string) => {
		if (formik?.values?.superCategory?.id) {
			categoryService.getAllCategories({ level: CATEGORY_TYPE.MAIN_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: formik?.values?.superCategory?.id ?? '', name: inputValue ?? '', status: 'ACTIVE' })
				.then(res => {
					if (res.status === HTTP_STATUS.OK)
						setMainCategoryData(res?.data?.data?.content)
				}).catch((error) => {
					console.error("Error Fetching Category: ", error);
				})
		}
	}

	const loadProductCategory = (inputValue?: string) => {
		if (formik?.values?.mainCategory?.id) {
			categoryService.getAllCategories({ level: CATEGORY_TYPE.PRODUCT_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: formik?.values?.mainCategory?.id ?? '', name: inputValue ?? '', status: 'ACTIVE' })
				.then(res => {
					if (res.status === HTTP_STATUS.OK)
						setProductCategoryData(res?.data?.data?.content)
				}).catch((error) => {
					console.error("Error Fetching Category: ", error);
				})
		}
	}

	const loadGrade = (inputValue?: string) => {
		secondaaryInventoryService.getAllGrades({ search: inputValue ?? '', type: 'GRADE', page: 0, size: 10, sort: 'createdAt,desc' })
			.then(res => {
				if (res.status === HTTP_STATUS.OK)
					setGradeData(res?.data?.data?.content)
			}).catch((error) => {
				console.error("Error Fetching Category: ", error);
			})
	}

	const updateSelectedCategory = (key: string) => (option: AutoCompleteOption | null) => {
		formik.setFieldValue(key, option);
		if (key === "superCategory") {
			formik.setFieldValue("mainCategory", { label: "", id: null });
			formik.setFieldValue("productCategory", { label: "", id: null });
		}
		if (key === "mainCategory") {
			formik.setFieldValue("productCategory", { label: "", id: null });
		}
	};

	const superCategoryOptions = useMemo(() => {
		return superCategoryData?.map((superCategory: Category) => ({ label: superCategory.name, id: superCategory.id })) ?? []
	}, [superCategoryData])

	const mainCategoryOptions = useMemo(() => {
		return mainCategoryData?.map((mainCategory: Category) => ({ label: mainCategory.name, id: mainCategory.id })) ?? []
	}, [mainCategoryData])

	const productCategoryOptions = useMemo(() => {
		return productCategoryData?.map((productCategory: Category) => ({ label: productCategory.name, id: productCategory.id })) ?? []
	}, [productCategoryData])

	const gradeOptions = useMemo(() => {
		return gradeData?.map((grade: IGrade) => ({ label: grade.name, id: grade.id })) ?? []
	}, [gradeData])

	useEffect(() => {
		loadSuperCategory();
		loadGrade();
	}, []);

	useEffect(() => {
		loadMainCategory();
	}, [formik?.values?.superCategory?.id]);

	useEffect(() => {
		loadProductCategory();
	}, [formik?.values?.mainCategory?.id]);

	return (
		<div className="grid gap-y-8">
			{SnackBarComponent}
			<div className={`${classes.container} grid gap-y-4 p-6 rounded`}>
				<div className={` ${classes.heading} text-lg font-semibold`}>
					Category
				</div>
				<div className='grid gap-y-4'>
					<div className='w-full flex gap-x-3'>
						<div className='w-full grid'>
							<FormControl fullWidth>
								<AutoCompleteTextField
									options={superCategoryOptions}
									label="Select Super Category"
									onChange={updateSelectedCategory('superCategory')}
									onSearch={loadSuperCategory}
									isDisabled={mode === MODE.UPDATE}
									value={formik?.values?.superCategory}
									error={!!formik.errors.superCategory && formik.touched.superCategory}
								/>
								{formik.errors.superCategory?.id && formik.touched.superCategory && (
									<div className={`${classes.errorMessage} text-xs`}>
										{formik.errors.superCategory?.id}
									</div>
								)}
							</FormControl>
						</div>
						<div className='w-full grid'>
							<FormControl fullWidth>
								<AutoCompleteTextField
									options={mainCategoryOptions}
									label="Select Main Category"
									onChange={updateSelectedCategory('mainCategory')}
									onSearch={loadMainCategory}
									isDisabled={mode === MODE.UPDATE}
									value={formik?.values?.mainCategory}
									error={!!formik.errors.mainCategory && formik.touched.mainCategory}
								/>
								{formik.errors.mainCategory?.id && formik.touched.mainCategory && (
									<div className={`${classes.errorMessage} text-xs`}>
										{formik.errors.mainCategory?.id}
									</div>
								)}
							</FormControl>
						</div>
						<div className='w-full grid'>
							<FormControl fullWidth>
								<AutoCompleteTextField
									options={productCategoryOptions}
									label="Select Product Category"
									onChange={updateSelectedCategory('productCategory')}
									onSearch={loadProductCategory}
									isDisabled={mode === MODE.UPDATE}
									value={formik?.values?.productCategory}
									error={!!formik.errors.productCategory && formik.touched.productCategory}
								/>
								{formik.errors.productCategory?.id && formik.touched.productCategory && (
									<div className={`${classes.errorMessage} text-xs`}>
										{formik.errors.productCategory?.id}
									</div>
								)}
							</FormControl>
						</div>
					</div>
				</div>
			</div>


			<div className={`${classes.container} grid gap-y-4 p-6 rounded`}>
				<div className={` ${classes.heading} text-lg font-semibold`}>
					Product Standard
				</div>
				<div className='grid gap-y-4'>
					<div className='w-full flex gap-x-3'>
						<div className='w-full grid'>
							<FormControl fullWidth>
								<AutoCompleteTextField
									options={gradeOptions}
									label="Select Grade"
									onChange={updateSelectedCategory('grade')}
									onSearch={loadGrade}
									isDisabled={mode === MODE.UPDATE}
									value={formik?.values?.grade}
									error={!!formik.errors.grade && formik.touched.grade}
								/>
								{formik.errors.grade?.id && formik.touched.grade && (
									<div className={`${classes.errorMessage} text-xs`}>
										{formik.errors.grade?.id}
									</div>
								)}
							</FormControl>
						</div>
						<div className='grid w-full'>
							<Select
								className="bg-white"
								variant="outlined"
								label="Select Shape"
								fullWidth
								{...formik.getFieldProps("shape")}
								error={
									formik.touched.shape &&
									Boolean(formik.errors.shape)
								}
								inputProps={{ readOnly: mode === MODE.UPDATE ? true : false }}
								options={
									SHAPE_TYPES?.map((option: any) => ({
										value: (option.id),
										label: option.label,
									})) || []
								}
							/>
							{formik.touched.shape && formik.errors && formik.errors.shape && (
								<div className={classes.errorMessage}>
									<small>{formik.errors.shape}</small>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>

			<div className={`${classes.container} grid gap-y-4 p-6 rounded`}>
				<div className={` ${classes.heading} text-lg font-semibold`}>
					Product Specification
				</div>
				<div className=" flex gap-x-5">
					<FormControl fullWidth variant="outlined" className="">
						{formik.values.attributes?.productSpecification?.map((product: any, index: any) => (
							<div key={index} className=" flex gap-x-6 mb-4">
								{(RANGE_ATTRIBUTES.includes(formik.values.attributes?.productSpecification[index]?.name?.toLowerCase() ?? ""))
									? <Checkbox
										key={index}
										checked={Boolean(attributeSpecification[index])}
										onChange={(event) => handleAttributeSpecificationChange(event, index)}
									/> : <Checkbox
										key={index}
										checked={false}
										disabled={true}
									/>}
								<Select
									className='bg-white'
									label="Name"
									variant="outlined"
									{...formik.getFieldProps(`attributes.productSpecification[${index}].name`)}
									error={
										formik.touched.attributes &&
										!!((formik.errors?.attributes as unknown as AttributeErrorSchema)?.productSpecification?.[index]?.name)
									}
									onChange={(event) => handleAttributeNameChange(event, index)}
									inputProps={{ readOnly: mode === MODE.UPDATE ? true : false }}
									options={
										attributeValues?.map((attribute: Attribute) => ({
											value: attribute.name.toString().trim(),
											label: attribute.name.toString(),
											uom: attribute.uom,
										}))
									}
									fullWidth
								/>
								{
									attributeSpecification[index] ?
										<>
											<TextField
												className='bg-white'
												label="MinValue"
												variant="outlined"
												{...formik.getFieldProps(`attributes.productSpecification[${index}].minValue`)}
												error={
													formik.touched.attributes && !!((formik.touched.attributes as unknown as AttributeErrorSchema).productSpecification?.[index]?.minValue) &&
													!!((formik.errors?.attributes as unknown as AttributeErrorSchema)?.productSpecification?.[index]?.minValue)
												}
												fullWidth
												disabled={mode === MODE.UPDATE}
											/>
											<TextField
												className='bg-white'
												label="MaxValue"
												variant="outlined"
												{...formik.getFieldProps(`attributes.productSpecification[${index}].maxValue`)}
												error={
													formik.touched.attributes &&
													!!((formik.errors?.attributes as unknown as AttributeErrorSchema)?.productSpecification?.[index]?.maxValue)
												}
												fullWidth
												disabled={mode === MODE.UPDATE}
											/>
										</>
										:
										<>
											<TextField
												className='bg-white'
												label={(RANGE_ATTRIBUTES.includes(formik.values.attributes?.productSpecification[index]?.name?.toLowerCase() ?? "")) ? "MinValue" : "Value"}
												variant="outlined"
												{...formik.getFieldProps(`attributes.productSpecification[${index}].minValue`)}
												error={
													formik.touched.attributes && !!((formik.touched.attributes as unknown as AttributeErrorSchema).productSpecification?.[index]?.minValue) &&
													!!((formik.errors?.attributes as unknown as AttributeErrorSchema)?.productSpecification?.[index]?.minValue)
												}
												fullWidth
												disabled={mode === MODE.UPDATE}
											/>
											{
												(RANGE_ATTRIBUTES.includes(formik.values.attributes?.productSpecification[index]?.name?.toLowerCase() ?? ""))
												&& (formik.values.attributes?.productSpecification[index]?.minValue?.trim() && formik.values.attributes?.productSpecification[index]?.maxValue?.trim())
												&& (formik.values.attributes?.productSpecification[index]?.minValue !== formik.values.attributes?.productSpecification[index]?.maxValue)
												&& <TextField
													className='bg-white'
													label="MaxValue"
													variant="outlined"
													{...formik.getFieldProps(`attributes.productSpecification[${index}].maxValue`)}
													error={
														formik.touched.attributes &&
														!!((formik.errors?.attributes as unknown as AttributeErrorSchema)?.productSpecification?.[index]?.maxValue)
													}
													fullWidth
													disabled={mode === MODE.UPDATE}
												/>
											}
										</>
								}
								{RANGE_ATTRIBUTES.includes(formik.values.attributes?.productSpecification[index]?.name?.toLowerCase() ?? "") && <TextField
									className='bg-white'
									label="UOM"
									variant="outlined"
									{...formik.getFieldProps(`attributes.productSpecification[${index}].uom`)}
									error={
										formik.touched.attributes && !!(formik.touched?.attributes as unknown as AttributeErrorSchema)?.productSpecification?.[index]?.uom &&
										!!((formik.errors?.attributes as unknown as AttributeErrorSchema)?.productSpecification?.[index]?.uom)
									}
									helperText={
										formik.touched.attributes &&
										((formik.touched?.attributes as unknown as AttributeErrorSchema)?.productSpecification?.[index]?.uom
											? (formik.errors?.attributes as unknown as AttributeErrorSchema)?.productSpecification?.[index]?.uom
											: '')
									}
									fullWidth
									disabled
								/>}
								{formik.values.attributes?.productSpecification && formik.values.attributes?.productSpecification?.length > 1 && (
									<div className="">
										{mode===MODE.ADD && (
										<Button variant="contained" type="button" onClick={() => handleRemoveSpecification(index)} label="Remove" />
										)}
									</div>
								)}
							</div>
						))}
						<div className="">
							{mode===MODE.ADD && (
							<Button variant="contained" type="button" onClick={() => handleAddSpecification()} label="Add" />
							)}
						</div>
					</FormControl>
				</div>
			</div>

			<div className="flex justify-between gap-x-3">
				<Button variant="outlined" label="Back" onClick={redirectToSecondaryInventoryList} />
				<Button variant="contained" label="Next" onClick={handleSubmit} />
			</div>
		</div>
	)
}

export default CategoryDetailTemplate