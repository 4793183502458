import { createSearchParams, generatePath } from "react-router-dom";
import { INDUSTRY_STATES, REGEX, SHAPE_TYPES } from "./constant";
import { HTTP_STATUS, MakeRouteParams } from "./types";
import moment from "moment";

export const capitalizeFirstLetter = (input: string) => {
  return input
    ? `${input.charAt(0).toUpperCase()}${input.slice(1).toLowerCase()}`
    : '';
};

export const convertToCamelCase = (input: string) => {
  return input.split(" ").map(el => capitalizeFirstLetter(el)).join(" ")
}

export const validatePhoneNumber = (phoneNumber: string) => {
  const isValidPhoneNumber = REGEX.PHONE_NUMBER.test(phoneNumber);
  return isValidPhoneNumber;
};

export const timeToLocale = (
  timeInSeconds: number,
  maximumUnitOfTime:
    | 'year'
    | 'month'
    | 'week'
    | 'day'
    | 'hour'
    | 'minute'
    | 'second'
) => {
  const timeUnits = [
    { unit: 'year', value: 31536000 }, // 365 days
    { unit: 'month', value: 2592000 }, // 30 days
    { unit: 'week', value: 604800 }, // 7 days
    { unit: 'day', value: 86400 }, // 24 hours
    { unit: 'hour', value: 3600 }, // 60 minutes
    { unit: 'minute', value: 60 }, // 60 seconds
    { unit: 'second', value: 1 },
  ];
  let result = '';
  const availableTimeUnits = timeUnits.slice(
    timeUnits.findIndex((timeUnit) => timeUnit.unit === maximumUnitOfTime)
  );

  for (const timeUnit of availableTimeUnits) {
    const unitCount = Math.floor(timeInSeconds / timeUnit.value);
    timeInSeconds %= timeUnit.value;
    if (unitCount > 0) {
      result += `${result ? ',' : ''}${unitCount} ${timeUnit.unit}${
        unitCount > 1 ? 's' : ''
      }`;
    }
  }

  return result;
};

export const replaceUrlParams = (
  url: string,
  params: { [key: string]: any }
) => {
  for (const key in params) {
    url = url.replace(`:${key}`, params[key]);
  }

  return url;
};

export const getKeyByValue = (
  object: { [key: string]: string },
  value: string
) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const keyToLabel = (key: string): string => {
  const words = key.split(/(?=[A-Z])|\s/);
  const capitalizedWords = words.map(
    (word: string) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  return capitalizedWords.join(' ');
};

export const currencyFormatter = (amount: string | number | undefined) => {
  return `₹ ${Number(amount || 0)
    .toFixed(2)
    .toLocaleString()}`;
};

export const toNumber = (value: any, defaultValue: any = 0) => {
  return value && !isNaN(Number(value)) ? Number(value) : defaultValue;
};

const Enum = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  PRIMARY_MANUFACTURER: "PRIMARY_MANUFACTURER",
  SECONDARY_MANUFACTURER: "SECONDARY_MANUFACTURER",
  END_MANUFACTURER: "END_MANUFACTURER",
  ONGOING: "ONGOING",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
  COMPLETED: "COMPLETED",
  PARTIALLY_COMPLETED: "PARTIALLY_COMPLETED",
  CANCELLED: "CANCELLED",
  FAILED: "FAILED",
  INVALID_SHEET: "INVALID_SHEET",
  PENDING: "PENDING",
  PUBLISHED: "PUBLISHED",
  UNPUBLISHED: "UNPUBLISHED",
  NOTPUBLISHED: "NOTPUBLISHED",
  STANDARD: "STANDARD",
  NONSTANDARD: "NONSTANDARD",
  BLACK_LISTED: "BLACK_LISTED",
  CLOSED_WIN: "CLOSED_WIN",
  DRAFT: "DRAFT",
  IN_PROGRESS: "IN_PROGRESS",
  REVISED: "REVISED",
  SENT: "SENT",
  CLOSED_LOST: "CLOSED_LOST"
};

export const CATALOGUE_SHAPES_TYPES = {
  SINGLE_LENGTH_COIL: 'Single Length Coil',
  MULTI_WELDED_COIL: 'Multi Welded Coil',
  LEADER_END_COIL: 'Leader End Coil',
  UNIFORM_ASSORTED_BUNDLE_SHEETS: 'Uniform Assorted Bundle',
  MIX_ASSORTED_BUNDLE_SHEETS: 'Mix Assorted Bundle',
  UNASSORTED_BUNDLE_SHEETS: 'Unassorted Bundle',
  SCROLL: 'Scroll',
  PLATE: 'Plate',
  BAR: 'Bar',
  STANDARD: 'Standard Prime',
  NON_STANDARD: 'Non Standard Prime',
  SECONDARY: 'Secondary',
};

export const getShapeName = (shapeId: string) => {
  const shape = SHAPE_TYPES.find(shape => shape.id === shapeId);
  return shape ? shape.label : null;
};

export const enumToString = (enumValue: string) => {
  switch (enumValue) {
    case Enum.ACTIVE:
      return "Active";
    case Enum.INACTIVE:
      return "Inactive";
    case Enum.PRIMARY_MANUFACTURER:
      return "Primary Manufacturer";
    case Enum.SECONDARY_MANUFACTURER:
      return "Secondary Manufacturer";
    case Enum.END_MANUFACTURER:
      return "End Manufacturer";
    case Enum.ONGOING:
      return "Ongoing";
    case Enum.ACCEPTED:
      return "Accepted";
    case Enum.REJECTED:
      return "Rejected";
    case Enum.COMPLETED:
      return "Completed";
    case Enum.PARTIALLY_COMPLETED:
      return "Partially Completed";
    case Enum.CANCELLED:
      return "Cancelled";
    case Enum.FAILED:
      return "Failed";
    case Enum.INVALID_SHEET:
      return "Invalid Sheet";
    case Enum.PENDING:
      return "Pending";
    case Enum.PUBLISHED:
      return "Published";
    case Enum.UNPUBLISHED:
      return "Unpublished";
    case Enum.NOTPUBLISHED:
      return "Not Published";
    case Enum.STANDARD:
      return "Standard";
    case Enum.NONSTANDARD:
      return "Non Standard";
    case Enum.BLACK_LISTED:
      return "Black Listed";
    case Enum.CLOSED_WIN:
      return "Closed Win";
    case Enum.CLOSED_LOST:
      return "Closed Lost";
    case Enum.DRAFT:
      return "Draft";
    case Enum.SENT:
      return "Sent";
    case Enum.REVISED:
      return "Revised";
    case Enum.IN_PROGRESS:
      return "In Progress";
    default:
      return "";
  }
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  return `${day}-${month}-${year}`;
};

export const toCamelCase = (text: string) => {
  return text.toLowerCase().replace(/\b[a-z]/g, character => character.toUpperCase());
}

export const formatNumber = (number: number) => {
  return number.toFixed(2).replace(/(\d)(?=(\d\d)+\d(\.\d{2})?$)/g, "$1,");
};

export const toInr = (number: number) => {
  return `₹${formatNumber(number)}`;
};

export const makeRoute = (
  baseRoute: string,
  { params, query }: MakeRouteParams
): string => {
  const queryString = createSearchParams(query ?? {});
  return `${generatePath(baseRoute, params ?? {})}${
    queryString ? `?${queryString}` : ''
  }`;
};

export const getErrorMessageByStatusCode = (
  statusCode: number,
  sectionName: string
) => {
  switch (statusCode) {
    case HTTP_STATUS.BAD_REQUEST:
      return 'It seems there was a problem with your request. Please check your input and try again.';
    case HTTP_STATUS.UNAUTHORIZED:
      return 'Access denied! You are not authorized to access this resource.';
    case HTTP_STATUS.INTERNAL_SERVER_ERROR:
      return `${sectionName} already exists or something went wrong. Please try again.`;
    default:
      return `Error in Creating the ${sectionName}`;
  }
};

export const snakeCaseToTitleCase = (inputString: string) => {
  return inputString
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export interface RequestParams {
  [key: string]: string | number | Date | undefined | boolean | undefined;
}

export const createQueryString = (params: RequestParams): string => {
  return Object.entries(params)
    .reduce((acc: string[], [key, value]) => {
      if (value !== undefined && value !== '') {
        acc.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
        );
      }
      return acc;
    }, [])
    .join('&');
};

//  remove empty, undefined, or null field
export const removeEmptyFields = (params: any): any => {
  for (const key in params) {
    if (
      params[key] === undefined ||
      params[key] === null ||
      params[key] === ''
    ) {
      delete params[key];
    }
  }
  return params;
};

// SINCE THERE IS NO PROPER TYPE DEFINE FOR ERROR RESPONSE WE HAVE TO DO THIS
export const ERROR_MESSAGE = (err: any): string => {
  const messgae =
    typeof err === 'string'
      ? err
      : !!err?.statusMessage
        ? err?.statusMessage
        : 'Something went wrong';
  return messgae;
};

export const catalogueClassType = (catalogueType: string, classType: string): string => {
  const typeMap: { [key: string]: string } = {
    'STANDARD_MAKE_TO_ORDER': "MAKE_TO_ORDER",
    'STANDARD_CURRENT_INVENTORY': 'STANDARD',
    'NONSTANDARD_CURRENT_INVENTORY': 'NON_STANDARD',
    'SECONDARY_CURRENT_INVENTORY': 'SECONDARY',
    'SECONDARY_MAKE_TO_ORDER': 'SECONDARY_LOTS'
  };
  const key = `${classType}_${catalogueType}`;
  return typeMap[key] || '';
}

export const showAttributesUom = (minOption: string, maxOption: string, attributeUom: string) => {
  const parsedValue = parseFloat(minOption);
  let uom = parsedValue ? attributeUom?.toLowerCase() : '';

  if (minOption !== maxOption) {
    return `${minOption} ${uom} - ${maxOption} ${uom}`;
  } else {
    return `${minOption} ${uom ? uom : " "}`;
  }
}

export function joinNameWithoutSpaces(name: string): string {
  const trimmedName = name.trim();
  const nameArray = trimmedName.split(' ');
  return nameArray.join('');
}

export const sortByDate = (array: any, key: any) => {
  return array.sort((a: any, b: any) => new Date(b[key]).getTime() - new Date(a[key]).getTime());
};

export const getFormattedDate = (dateString: any) => {
  try {
    return moment(dateString).format('YYYY-MM-DD HH:mm:ss');
  } catch (error) {
    return dateString;
  }
};

export const getProfileDisplayData = (data: any) => {
  if (data === undefined || data === null || (typeof data === 'string' && data.length === 0)) {
    return "No Data";
  }
  if (typeof data === 'object') {
    if (Array.isArray(data)) {
      if (data.length === 0) {
        return "No Data";
      }
    } else {
      const values = Object.values(data);
      if (values.length === 0 || values.every(value => typeof value === 'string' && value.length === 0)) {
        return "No Data";
      }
    }
  }
  return data;
};

export const convertDate = (inputDate: string): string => {
  if (!inputDate) {
    return 'N/A';
  }
  let year, month, day;
  if (inputDate.includes('/')) {
    [day, month, year] = inputDate.split('/');
  } else if (inputDate.includes('-')) {
    [year, month, day] = inputDate.split('-');
  } else {
    return 'Invalid date format';
  }
  return `${year}-${month}-${day}`;
};

export const getIndustryType = (value: string): string => {
  const industry = INDUSTRY_STATES[value as keyof typeof INDUSTRY_STATES];
  return industry !== undefined ? industry : value;
}

export const generateYearAmountObjects = (numberOfYears: number): { year: string, amount: number }[] => {
  const currentYear = new Date().getFullYear();
  const yearAmountObjects = [];
  for (let i = 0; i < numberOfYears; i++) {
    yearAmountObjects.push({ year: `${currentYear - i - 1}-${currentYear - i}`, amount: 0 });
  }
  return yearAmountObjects;
};

export const getLastThreeFiscalYears = () => {
  const currentYear = new Date().getFullYear();
  return [
    `${currentYear - 1}-${currentYear}`,
    `${currentYear - 2}-${currentYear - 1}`,
    `${currentYear - 3}-${currentYear - 2}`
  ];
};

export const createGstAddressLines = (gstinResponseDTO: any): string[] => {
  const addressLine1 = [
    gstinResponseDTO.principalPlaceFlatNo,
    gstinResponseDTO.principalPlaceBuildingNoFromGST,
    gstinResponseDTO.principalPlaceBuildingNameFromGST,
  ].join(" ");

  const addressLine2 = [
    gstinResponseDTO.principalPlaceStreet,
    gstinResponseDTO.principalPlaceLocality,
  ].join(" ");
  return [addressLine1, addressLine2];
};

export const createGstAdditionalAddressLines = (gstinResponseDTO: any): string[] => {
  const addressLine1 = [
    gstinResponseDTO.flatNo,
    gstinResponseDTO.buildingNo,
    gstinResponseDTO.buildingName,
  ].join(" ");
  const addressLine2 = [
    gstinResponseDTO.street,
    gstinResponseDTO.locality,
  ].join(" ");
  return [addressLine1, addressLine2];
};

export const useDebounce = <T extends any[]>(
  callback: (...args: T) => void,
  delay: number
) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return (...args: T) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};

export const getLabelByValue = (value: string, options: any[]): string | undefined => {
  const option = options.find(option => option.value === value || option.id === value);
  return option ? option.label : "";
};

export const createImageName = (productCategory: number, brandId: number, shape: string, originalName: string): string => {
  return `P${productCategory}_B${brandId}_${shape}_${originalName}`.replace(/\s+/g, '_');
};

export function cleanString(input: string) {
  let cleaned = input.replace(/&nbsp;/g, ' ');
  cleaned = cleaned.replace(/\s+/g, ' ');
  cleaned = cleaned.trim();
  return cleaned;
}

export const titleModification = (text: string): string => {
  return text.trim().replace(/\s+/g, ' ');
};
