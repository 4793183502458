import {
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField,
} from '@mui/material';
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  Field,
  Form,
  Formik,
  FormikErrors,
  FormikProps,
  FormikValues,
} from 'formik';
import JoditEditor from 'jodit-react';
import React, { useMemo, useRef } from 'react';
import * as Yup from 'yup';
import UploadButton from '../Indent/UploadButton';
import moment, { Moment } from 'moment';
import { useNavigate } from 'react-router-dom';
import { AUCTION_TEXT } from '../../../Constants/Procurement/ReverseAuctionRFQ/constants';
import {
  AuctionFormData,
  InitialValues,
} from '../../../Constants/Procurement/ReverseAuctionRFQ/types';
import CustomTextField from '../../../Shared/CustomInputs/CustomTextField';
import CustomCheckBox from '../../../Shared/CustomInputs/CustomCheckBox';
import { ViewUploadFiles } from '../Indent/ViewUploadFiles';

const validationSchema = Yup.object()
  .shape({
    auctionName: Yup.string().required('Auction Name is required'),
    startDate: Yup.string().required('Start Date is required'),
    endDate: Yup.string().required('End Date is required'),
    startTime: Yup.string().required('Start time is required'),
    endTime: Yup.string().required('End time is required'),
    bestPrice: Yup.boolean(),
    winnerName: Yup.boolean(),
    autoBidAllow: Yup.boolean(),
    dynamicTime: Yup.boolean(),
    maxAutoBid: Yup.number().when('autoBidAllow', {
      is: (value: boolean) => value === true,
      then: () => Yup.number().required('Max. Auto Bid Allow is required'),
      otherwise: () => Yup.number(),
    }),
    dynamicTimeExt: Yup.number().when('dynamicTime', {
      is: (value: boolean) => value === true,
      then: () => Yup.number().required('Dynamic Time Extension is required'),
      otherwise: () => Yup.number(),
    }),
    decrementBidAmount: Yup.number().required(
      'Decrement Bid Amount is required'
    ),
    auctionStartPrice: Yup.number()
      .required('Auction Start Price is required')
      .test(
        'start-reserve-validation',
        'Auction start amount must be greater than the reserve amount',
        function (value, { parent }) {
          const reserveAmount = parent.auctionReversedPrice;
          if (value <= reserveAmount) {
            return false;
          }
          return true;
        }
      ),
    auctionReversedPrice: Yup.number().required(
      'Auction Reverse Price is required'
    ),
    auctionTargetPrice: Yup.number()
      .required('Auction Target Price is required')
      .test(
        'target-reserve-validation',
        'Target amount must be less than or equal to reserve amount',
        function (value, { parent }) {
          const reserveAmount = parent.auctionReversedPrice;
          if (value > reserveAmount) {
            return false;
          }
          return true;
        }
      ),
  })
  .test(
    'start-end-time-validation',
    'End Date should be greater than Start Date',
    function (value) {
      const { startDate, endDate, startTime, endTime } = value;

      if (startDate && endDate && startTime && endTime) {
        const startDateTime =
          startTime &&
          startDate &&
          parseInt(startDate) +
            parseInt(startTime) -
            moment.unix(parseInt(startTime)).startOf('day').unix();

        const endDateTime =
          endTime &&
          endDate &&
          parseInt(endDate) +
            parseInt(endTime) -
            moment.unix(parseInt(endTime)).startOf('day').unix();

        if (endDateTime <= startDateTime) {
          return this.createError({
            path: 'start-end-time-validation',
            message:
              'End Date + End Time should be greater than Start Date + Start Time',
          });
        }
      }

      return true;
    }
  );

interface CustomFormikErrors extends FormikErrors<InitialValues> {
  'start-end-time-validation'?: string;
}

interface CustomFormikProps {
  errors: CustomFormikErrors;
}

interface AddAuctionDetailsProps {
  setStepper: (step: number) => void;
  formData: AuctionFormData;
  setFormData: (formData: any) => void;
  setFileArray: React.Dispatch<React.SetStateAction<File[]>>;
  fileArray: File[];
}

const AddAuctionDetails: React.FC<AddAuctionDetailsProps> = ({
  setStepper,
  formData,
  setFormData,
  fileArray,
  setFileArray,
}) => {
  const navigate = useNavigate();
  const initialValues: InitialValues = {
    auctionName: formData?.auctionName || '',
    startDate: formData?.startDate || null,
    endDate: formData?.endDate || null,
    startTime: formData?.startTime || null,
    endTime: formData?.endTime || null,
    bestPrice: formData?.bestPrice || false,
    winnerName: formData?.winnerName || false,
    maxAutoBid: formData?.maxAutoBid || '',
    dynamicTime: formData?.dynamicTime || false,
    autoBidAllow: formData?.autoBidAllow || false,
    dynamicTimeExt: formData?.dynamicTimeExt || '',
    decrementBidAmount: formData?.decrementBidAmount || '',
    auctionStartPrice: formData?.auctionStartPrice || '',
    auctionReversedPrice: formData?.auctionReversedPrice || '',
    auctionTargetPrice: formData?.auctionTargetPrice || '',
    tnc: formData?.tnc || '',
  };
  const editor = useRef<any>(null);

  const config = useMemo(() => {
    return {
      readonly: false,
      placeholder: 'Start typing...',
    };
  }, []);

  const handleSubmitForm = (values: any) => {
    setFormData(values);
    setStepper(2);
  };

  const handleBack = () => {
    navigate('/procurement/reverse-auction/reverse-auction-rfq');
  };
  const removeFile = (index: number) => {
    const newArray = fileArray.filter((_, i) => i !== index);
    if (setFileArray) {
      setFileArray(newArray);
    }
  };
  return (
    <div className="border border-cgray-4 p-4  rounded-md flex flex-col gap-4 bg-white  ">
      <div className="flex text-blue rounded-md bg-cgray-2 justify-between items-center text-xl font-semibold py-[10px] px-[16px]">
        <div className=" flex flex-col">
          <div className="text-xl font-semibold text-blue ">
            {AUCTION_TEXT.createAuctionHeading}
          </div>
          <div className="text-xs font-medium text-cgray-1">
            {AUCTION_TEXT.createAuctionHeadingSummary}
          </div>
        </div>
        <div className="flex items-center">Auction ID : {formData.id}</div>
      </div>
      <div>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            handleSubmitForm(values);
          }}
          validationSchema={validationSchema}
        >
          {({
            values,
            setFieldValue,
            touched,
            errors,
            handleBlur,
            handleChange,
            setFieldError,
          }: CustomFormikProps & FormikProps<FormikValues>) => (
            <Form>
              <div className="flex flex-col gap-5">
                <div className="w-full flex gap-4 my-1">
                  <CustomTextField
                    name="auctionName"
                    value={values?.auctionName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Name of Auction"
                    error={touched.auctionName && Boolean(errors.auctionName)}
                    helperText={touched.auctionName && errors.auctionName}
                  />
                </div>
                {/* Auction Schedule */}
                <div className="border border-cgray-3 p-4  rounded-md flex flex-col gap-5 bg-white  ">
                  <div className="text-xl font-semibold text-blue mb-[4px]">
                    {AUCTION_TEXT.addAuctionScheduleHeading}
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="w-full flex gap-4 my-1">
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            format="DD/MM/YYYY"
                            name="startDate"
                            label="Start Date"
                            minDate={moment(new Date())}
                            value={
                              values.startDate
                                ? moment.unix(values.startDate)
                                : null
                            }
                            onChange={(date: Moment | null) => {
                              setFieldValue('startDate', moment(date).unix());
                            }}
                            slotProps={{
                              textField: {
                                helperText:
                                  touched.startDate &&
                                  errors.startDate?.toString(),
                                error:
                                  Boolean(
                                    errors['start-end-time-validation']
                                  ) ||
                                  (touched.startDate &&
                                    Boolean(errors.startDate)),
                              },
                            }}
                            onError={(error) =>
                              error
                                ? setFieldError('startDate', error)
                                : setFieldError('startDate', '')
                            }
                          ></DatePicker>
                        </LocalizationProvider>
                      </FormControl>
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            format="DD/MM/YYYY"
                            name="endDate"
                            label="End Date"
                            minDate={moment(new Date())}
                            value={
                              values.endDate
                                ? moment.unix(values.endDate)
                                : null
                            }
                            onChange={(date: Moment | null) => {
                              setFieldValue('endDate', moment(date).unix());
                            }}
                            slotProps={{
                              textField: {
                                helperText:
                                  touched.endDate && errors.endDate?.toString(),
                                error:
                                  Boolean(
                                    errors['start-end-time-validation']
                                  ) ||
                                  (touched.endDate && Boolean(errors.endDate)),
                              },
                            }}
                            onError={(error) =>
                              error
                                ? setFieldError('endDate', error)
                                : setFieldError('endDate', '')
                            }
                          ></DatePicker>
                        </LocalizationProvider>
                      </FormControl>
                    </div>
                    <div className="w-full flex gap-4 my-1">
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <TimePicker
                            format="HH:mm"
                            ampm={false}
                            disablePast={true}
                            skipDisabled={true}
                            timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                            name="startTime"
                            label="Start Time"
                            value={
                              values.startTime
                                ? moment.unix(values.startTime)
                                : null
                            }
                            onChange={(date: Moment | null) => {
                              setFieldValue('startTime', moment(date).unix());
                            }}
                            slotProps={{
                              textField: {
                                helperText:
                                  touched.startTime &&
                                  errors.startTime?.toString(),
                                error:
                                  Boolean(
                                    errors['start-end-time-validation']
                                  ) ||
                                  (touched.startTime &&
                                    Boolean(errors.startTime)),
                              },
                            }}
                            onError={(error) =>
                              error
                                ? setFieldError('startTime', error)
                                : setFieldError('startTime', '')
                            }
                          ></TimePicker>
                        </LocalizationProvider>
                      </FormControl>
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <TimePicker
                            format="HH:mm"
                            ampm={false}
                            disablePast={true}
                            skipDisabled={true}
                            timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                            name="endTime"
                            label="End Time"
                            value={
                              values.endTime
                                ? moment.unix(values.endTime)
                                : null
                            }
                            onChange={(date: Moment | null) => {
                              setFieldValue('endTime', moment(date).unix());
                            }}
                            slotProps={{
                              textField: {
                                helperText:
                                  touched.endTime && errors.endTime?.toString(),
                                error:
                                  Boolean(
                                    errors['start-end-time-validation']
                                  ) ||
                                  (touched.endTime && Boolean(errors.endTime)),
                              },
                            }}
                            onError={(error) =>
                              error
                                ? setFieldError('endTime', error)
                                : setFieldError('endTime', '')
                            }
                          ></TimePicker>
                        </LocalizationProvider>
                      </FormControl>
                    </div>
                    {errors['start-end-time-validation'] && (
                      <FormHelperText
                        className="mt-1"
                        error={Boolean(errors['start-end-time-validation'])}
                      >
                        {errors['start-end-time-validation']}
                      </FormHelperText>
                    )}
                  </div>
                </div>
                {/* Auction Setting */}
                <div className="border border-cgray-3 p-4  rounded-md flex flex-col gap-5 bg-white  ">
                  <div className="text-xl font-semibold text-blue mb-[4px]">
                    {AUCTION_TEXT.addAuctionSettingHeading}
                  </div>
                  <div className="flex flex-col gap-4">
                    <div className="w-full flex justify-between items-center">
                      <div className="flex flex-col flex-nowrap items-start justify-start gap-1 mb-3 w-100">
                        <div>
                          <div className="flex flex-row gap-4 items-center">
                            <CustomCheckBox
                              checked={values.bestPrice}
                              name="bestPrice"
                              handleChange={handleChange}
                            />
                            <label
                              htmlFor="bestPrice"
                              className=" text-text-color font-semibold"
                            >
                              {AUCTION_TEXT.addAuctionCheckBoxBestPriceLabel}
                            </label>
                          </div>
                        </div>
                        <div className="text-text-color  text-xs">
                          {AUCTION_TEXT.addAuctionCheckBoxBestPriceSummary}
                        </div>
                      </div>
                      <div className="flex flex-col flex-nowrap items-start justify-start gap-1 mb-3 w-100">
                        <div>
                          <div className="flex flex-row gap-4 items-center">
                            <CustomCheckBox
                              checked={values.winnerName}
                              name="winnerName"
                              handleChange={handleChange}
                            />
                            <label
                              htmlFor="winnerName"
                              className=" text-text-color font-semibold"
                            >
                              {AUCTION_TEXT.addAuctionCheckBoxWinnerNameLabel}
                            </label>
                          </div>
                        </div>
                        <div className="text-text-color  text-xs">
                          {AUCTION_TEXT.addAuctionCheckBoxWinnerNamSummary}
                        </div>
                      </div>
                      <div className="flex flex-col flex-nowrap items-start justify-start gap-1 mb-3 w-100">
                        <div>
                          <div className="flex flex-row gap-4 items-center">
                            <CustomCheckBox
                              checked={values.autoBidAllow}
                              name="autoBidAllow"
                              handleChange={(e) => {
                                handleChange(e);
                                if (!e.target.checked) {
                                  setFieldValue('maxAutoBid', '');
                                }
                              }}
                            />
                            <label
                              htmlFor="autoBidAllow"
                              className=" text-text-color font-semibold"
                            >
                              {AUCTION_TEXT.addAuctionCheckBoxAllowBidLabel}
                            </label>
                          </div>
                        </div>
                        <div className="text-text-color  text-xs">
                          {AUCTION_TEXT.addAuctionCheckBoxAllowBidSummary}
                        </div>
                      </div>
                      <div className="flex flex-col flex-nowrap items-start justify-start gap-1 mb-3 w-100">
                        <div>
                          <div className="flex flex-row gap-4 items-center">
                            <CustomCheckBox
                              name="dynamicTime"
                              checked={values.dynamicTime}
                              handleChange={(e) => {
                                handleChange(e);
                                if (!e.target.checked) {
                                  setFieldValue('dynamicTimeExt', '');
                                }
                              }}
                            />
                            <label
                              htmlFor="dynamicTime"
                              className=" text-text-color font-semibold"
                            >
                              {AUCTION_TEXT.addAuctionCheckBoxDynamicTimeLabel}
                            </label>
                          </div>
                        </div>
                        <div className="text-text-color  text-xs">
                          {AUCTION_TEXT.addAuctionCheckBoxDynamicTimeSummary}
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <CustomTextField
                        name="maxAutoBid"
                        value={values?.maxAutoBid}
                        onBlur={handleBlur}
                        onChange={(event: any) => {
                          if (event.target.value.match(/[^0-9]/)) {
                            event.preventDefault();
                          } else {
                            handleChange(event);
                          }
                        }}
                        label="Max. Auto Bidding Allow"
                        disabled={!values.autoBidAllow}
                        error={
                          values.autoBidAllow && Boolean(errors.maxAutoBid)
                        }
                        helperText={values.autoBidAllow && errors.maxAutoBid}
                      />
                      <CustomTextField
                        name="dynamicTimeExt"
                        disabled={!values.dynamicTime}
                        label="Set Dynamic Time Extension"
                        value={values.dynamicTimeExt}
                        onBlur={handleBlur}
                        onChange={(event: any) => {
                          if (event.target.value.match(/[^0-9]/)) {
                            event.preventDefault();
                          } else {
                            handleChange(event);
                          }
                        }}
                        error={
                          values.dynamicTime && Boolean(errors.dynamicTimeExt)
                        }
                        helperText={values.dynamicTime && errors.dynamicTimeExt}
                      />
                    </div>
                  </div>
                </div>

                {/* Auction Price Management */}
                <div className="border border-cgray-3 p-4  rounded-md flex flex-col gap-5 bg-white  ">
                  <div className="text-xl font-semibold text-blue mb-[4px]">
                    {AUCTION_TEXT.addAuctionPriceManagmentHeading}
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="w-full flex gap-4 my-1">
                      <CustomTextField
                        name="auctionTargetPrice"
                        label="Set Auction Target Price / MT"
                        placeholder="Set Auction Target Price / MT"
                        value={values.auctionTargetPrice}
                        onBlur={handleBlur}
                        onChange={(event: any) => {
                          if (event.target.value.match(/[^0-9]/)) {
                            event.preventDefault();
                          } else {
                            handleChange(event);
                          }
                        }}
                        error={
                          touched.auctionTargetPrice &&
                          Boolean(errors.auctionTargetPrice)
                        }
                        helperText={
                          touched.auctionTargetPrice &&
                          errors.auctionTargetPrice
                        }
                        adornment="₹"
                        adornmentPostion="end"
                      />
                      <CustomTextField
                        name="auctionReversedPrice"
                        label="Set Auction Reserve Price / MT"
                        placeholder="Set Auction Reserve Price / MT"
                        value={values.auctionReversedPrice}
                        onBlur={handleBlur}
                        onChange={(event: any) => {
                          if (event.target.value.match(/[^0-9]/)) {
                            event.preventDefault();
                          } else {
                            handleChange(event);
                          }
                        }}
                        error={
                          touched.auctionReversedPrice &&
                          Boolean(errors.auctionReversedPrice)
                        }
                        helperText={
                          touched.auctionReversedPrice &&
                          errors.auctionReversedPrice
                        }
                        adornment="₹"
                        adornmentPostion="end"
                      />
                    </div>
                    <div className="w-full flex gap-4 my-1">
                      <CustomTextField
                        name="auctionStartPrice"
                        label="Set Auction Start Price / MT"
                        placeholder="Set Auction Start Price / MT"
                        value={values.auctionStartPrice}
                        onBlur={handleBlur}
                        onChange={(event: any) => {
                          if (event.target.value.match(/[^0-9]/)) {
                            event.preventDefault();
                          } else {
                            handleChange(event);
                          }
                        }}
                        error={
                          touched.auctionStartPrice &&
                          Boolean(errors.auctionStartPrice)
                        }
                        helperText={
                          touched.auctionStartPrice && errors.auctionStartPrice
                        }
                        adornment="₹"
                        adornmentPostion="end"
                      />
                      <CustomTextField
                        name="decrementBidAmount"
                        value={values.decrementBidAmount}
                        label="Add Decrement Bid Amount / MT"
                        placeholder="Add Decrement Bid Amount / MT"
                        onBlur={handleBlur}
                        onChange={(event: any) => {
                          if (event.target.value.match(/[^0-9]/)) {
                            event.preventDefault();
                          } else {
                            handleChange(event);
                          }
                        }}
                        error={
                          touched.decrementBidAmount &&
                          Boolean(errors.decrementBidAmount)
                        }
                        helperText={
                          touched.decrementBidAmount &&
                          errors.decrementBidAmount
                        }
                        adornment="₹"
                        adornmentPostion="end"
                      />
                    </div>
                  </div>
                </div>

                {/* Auction Terms & Conditions */}
                <div className="flex flex-col ">
                  <div className="text-base font-semibold text-text-color">
                    {AUCTION_TEXT.addAuctionTNCHeading}
                  </div>
                  <JoditEditor
                    className="w-full"
                    ref={editor}
                    value={values.tnc || ''}
                    onBlur={(newContent) => setFieldValue('tnc', newContent)}
                    config={config}
                  />
                </div>
                <ViewUploadFiles removeFile={removeFile} fileList={fileArray} />
                {/* upload document */}
                <UploadButton
                  fileList={fileArray}
                  setFileArray={setFileArray}
                  acceptFiles={'.jpeg, .jpg, .pdf, .doc, .docx'}
                />
                {/* down Button */}
                <div className="flex ml-auto gap-3  my-2">
                  <button
                    className="text-sm font-semibold text-black border border-blue-900 flex flex-row justify-center py-3 px-8 rounded"
                    onClick={handleBack}
                  >
                    {AUCTION_TEXT.addAuctionBackButtonLabel}
                  </button>
                  <button
                    className="text-sm font-semibold text-white bg-blue flex flex-row justify-center py-3 px-8 rounded"
                    type="submit"
                  >
                    {AUCTION_TEXT.addAuctionNextButtonLabel}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddAuctionDetails;
