import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "../../../hooks/useSnackBar";
import { HTTP_STATUS } from "../../../utils/types";
import { ADMIN_ROUTES, MODE } from "../../../utils/constant";
import { useEffect, useState } from "react";
import { useUpcService } from "../../../services/useUpcService";
import UpcFormTemplate from "../../templates/UPC/UpcForm.template";
import { IUpc } from "./UpcUpdate.page";

const UpcViewPage: React.FC = () => {

    const params = useParams();
    const navigate = useNavigate();
    const upcService = useUpcService();

    const upcId = Number(params.id);
    const [upc, setUpcTo] = useState<IUpc | null>(null);
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const updateUpc = async (upcUpdateRequestBody: any) => {
        if (upcId) {
            try {
                const upcResponse = await upcService.updateProductUPC(upcId, upcUpdateRequestBody);
                if(upcResponse.status === HTTP_STATUS.OK ){
                    navigate(ADMIN_ROUTES.UPC_LIST);
                }
            } catch (error) {
                showSnackbar("error", `UPC updation failed`)
            }
        }
    }

    const fetchUpc = async () =>{
        if(upcId){
            try {
                const upcResponse = await upcService.getProductUPC(upcId);
                if(upcResponse?.status === HTTP_STATUS.OK){
                    setUpcTo(upcResponse?.data?.data);
                }
                else {
                    throw new Error("UPC fetch failed");
                }
            } catch (error) {
                showSnackbar("error", `UPC fetch failed`) 
                setUpcTo(null);
            }
        }
    }

    useEffect(() => {
        fetchUpc()
    }, [upcId]);

    return (
        <div>
            { SnackBarComponent }
            <UpcFormTemplate upc={ upc }  onUpcSubmit={ updateUpc } mode={ MODE.VIEW}/>
        </div>
    )
}

export default UpcViewPage;