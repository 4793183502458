import React from 'react';
import { Vendor } from '../../../Constants/Procurement/VendorDiscovery/types';
import CustomCheckBox from '../../../Shared/CustomInputs/CustomCheckBox';

interface VendorViewComponentProps {
  handleToggle: (id: string | number) => void;
  selectedVendors: (string | number)[];
  vendorDetail: Vendor;
}

const VendorViewComponent: React.FC<VendorViewComponentProps> = ({
  selectedVendors,
  handleToggle,
  vendorDetail,
}) => {
  return (
    <div className="border-solid border-cgray-4 bg-white flex flex-row justify-between items-center w-full p-4 border rounded-md shadow-md ">
      <div className="flex gap-16 justify-between items-center w-full">
        <div className="flex flex-row justify-center items-center ">
          <div className="flex flex-row">
            <CustomCheckBox
              name="vendor"
              checked={
                selectedVendors.find(
                  (itemId: any) => itemId === vendorDetail.id
                )
                  ? true
                  : false
              }
              handleChange={() => handleToggle(vendorDetail.id)}
            />
          </div>
        </div>
        <div className="grid grid-cols-7 w-full">
          <div className="flex flex-col gap-1  whitespace-nowrap">
            <div className="text-sm font-normal text-cgray-1">Vendor Code</div>
            <div className=" text-base font-medium text-text-color">
              {vendorDetail.id}
            </div>
          </div>
          <div className="flex flex-col gap-1  whitespace-nowrap">
            <div className=" text-sm font-normal text-cgray-1">Vendor Name</div>
            <div className=" text-base font-medium text-text-color ">
              {vendorDetail?.name || ''}
            </div>
          </div>
          <div className="flex flex-col gap-1 col-span-2">
            <div className="text-sm font-normal text-cgray-1 ">Location</div>
            <div className=" text-base font-medium text-text-color">
              {vendorDetail?.address || ''}
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <div className=" text-sm font-normal text-cgray-1">
              Type of Vendor
            </div>
            <div className=" text-base font-medium text-text-color">
              {vendorDetail?.type || ''}
            </div>
          </div>
          <div className="flex flex-col gap-1 ">
            <div className=" text-sm font-normal text-cgray-1">
              Type of Category
            </div>
            <div className=" text-bavse font-medium text-text-color">
              {vendorDetail?.category || ''}
            </div>
          </div>
          <div className="flex flex-col gap-1 ">
            <div className="text-sm font-normal text-cgray-1">Rating</div>
            <div className="text-base font-medium text-text-color">
              <StarRating rating={vendorDetail?.ratings} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const StarRating = ({ rating }: any) => {
  const maxRating = 5;

  return (
    <div className="flex flex-row h-[18px]">
      {[...Array(maxRating)].map((star, index) => {
        const ratingValue = index + 1;
        return (
          <svg
            key={index}
            width="15"
            height="15"
            viewBox="0 0 15 15"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.68327 11.3841L6.89894 11.6678L6.68327 11.3841L3.17418 14.0522C3.06288 14.1369 2.90854 14.0288 2.95 13.8953L4.31391 9.50233C4.42066 9.15851 4.30097 8.78465 4.01439 8.56675L0.419222 5.83322C0.309688 5.74994 0.368585 5.57517 0.506183 5.57517H4.90244C5.27756 5.57517 5.60902 5.33102 5.72025 4.97276L7.06435 0.643648C7.10619 0.508879 7.29694 0.508881 7.33878 0.643647L8.68288 4.97276C8.79411 5.33101 9.12556 5.57517 9.50069 5.57517H13.8969C14.0345 5.57517 14.0934 5.74994 13.9839 5.83322L10.3887 8.56675C10.1022 8.78465 9.98247 9.15851 10.0892 9.50233L11.4531 13.8953C11.4946 14.0288 11.3402 14.1369 11.2289 14.0522L7.71985 11.3841C7.41357 11.1513 6.98956 11.1513 6.68327 11.3841Z"
              stroke="#FD6202"
              strokeWidth="0.712644"
              fill={ratingValue <= rating ? '#FD6202' : 'white'}
            />
          </svg>
        );
      })}
    </div>
  );
};

export default VendorViewComponent;
