import React from 'react'

const SecondaryCatalogueTableHeadings = () => {
  return (
    <div className="relative bg-blue w-full flex flex-row p-[0.75rem] box-border items-end justify-start gap-[0.75rem] text-left text-[0.88rem] text-white font-roboto">

    <div className="flex-1 flex flex-col items-center justify-start">
      <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">Sr.no</div>
    </div>
    <div className="flex-1 flex flex-col items-center justify-start">
      <div className="relative font-semibold text-left w-full sm:w-[calc(11rem / 1.6)]">Catalogue Name</div>
    </div>
    <div className="flex-1 flex flex-col items-center justify-start">
      <div className="relative font-semibold text-left w-full sm:w-[calc(11rem / 1.6)]">Category Name</div>
    </div>
    <div className="flex-1 flex flex-col items-center justify-start">
      <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">Created Date</div>
    </div>
    <div className="flex-1 flex flex-col items-center justify-start">
      <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">Updated Date</div>
    </div>
    <div className="flex-1 flex flex-col items-center justify-start">
        <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">Status</div>
      </div>
    <div className="flex-1 flex flex-col items-center justify-start">
      <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">Action</div>
    </div>
  </div>
  )
}

export default SecondaryCatalogueTableHeadings
