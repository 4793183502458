import React, { useEffect, useState } from "react";
import Button from "../../atoms/Button";
import { Modal } from "@mui/material";
import PaymentInfoTemplate, { IPriceDetails } from "./PaymentInfo.template";
import { PRICE_DETAILS } from "../../../utils/constant";
import { Charges } from "../../pages/Lead/QuotationView.page";

interface PaymentInfoModalTemplateProps {
	open: boolean;
	onClose: () => void;
	upcSkuEditDetails: Record<string, any>;
	charges: Charges | null;
}

const PaymentInfoModalTemplate: React.FC<PaymentInfoModalTemplateProps> = ({ open, onClose, upcSkuEditDetails, charges }) => {
	const [totalQuantity, setTotalQuantity] = useState<number>(0);
	const [grandTotal, setGrandTotalTo] = useState<number>(0);
	const [paymentInfoDetails, setPaymentInfoDetailsTo] = useState<IPriceDetails>(() => PRICE_DETAILS({}));
	const calculatePaymentInfoDetails = (upcSkuEditDetails: any) => {
		let grandTotal = 0;
		let totalQuantity = 0;
		let productSubTotal = 0;
		let paymentInfoDetails: IPriceDetails = PRICE_DETAILS({});
		const warehouseCharges = charges?.warehouseCharges ?? 0;
		const handlingCharges = charges?.handlingCharges ?? 0;
		const packagingCharges = charges?.packagingCharges ?? 0;
		const otherCharges = charges?.otherCharges ?? 0;
		for (const key in upcSkuEditDetails) {
			const item = upcSkuEditDetails[key];
			const quantity = parseFloat(item.quantity);
			const price = parseFloat(item.price);
			totalQuantity += quantity;
			const totalPrice = price * quantity;
			productSubTotal += totalPrice;
		}
		paymentInfoDetails.subTotal.price = productSubTotal;
		paymentInfoDetails.warehouseCharges.price = warehouseCharges * totalQuantity;
		paymentInfoDetails.handlingCharges.price = handlingCharges * totalQuantity;
		paymentInfoDetails.packagingCharges.price = packagingCharges * totalQuantity;
		paymentInfoDetails.otherCharges.price = otherCharges ;
		let totalProductPrice = paymentInfoDetails.subTotal.price + otherCharges + (warehouseCharges + handlingCharges + packagingCharges ) * totalQuantity;
		paymentInfoDetails.GST.price = ((charges?.gst ?? 0) * totalProductPrice) / 100;
		paymentInfoDetails.TCS.price = ((charges?.tcs ?? 0) * totalProductPrice) / 100;
		grandTotal = totalProductPrice + paymentInfoDetails.GST.price + paymentInfoDetails.TCS.price;
		setGrandTotalTo(grandTotal);
		setPaymentInfoDetailsTo(paymentInfoDetails);
		setTotalQuantity(totalQuantity);
	};

	useEffect(() => {
		if (upcSkuEditDetails) calculatePaymentInfoDetails(upcSkuEditDetails);
	}, [upcSkuEditDetails]);

	return (
		<Modal open={ open } onClose={ onClose } closeAfterTransition>
			<div className="w-2/3 m-auto mt-16 bg-white grid gap-y-6 p-6 rounded ">
				<PaymentInfoTemplate
					label = { "Payment Information" }
					showStatus = { false }
					priceDetails = { paymentInfoDetails }
					quantity = { totalQuantity }
					uom = { "MT" }
					totalPrice = { grandTotal }
				/>
				<div className=" flex justify-end">
					<Button onClick={ onClose } variant="contained" label="Cancel" />
				</div>
			</div>
		</Modal>
	);
};

export default PaymentInfoModalTemplate;
