import { useState } from 'react';
import IndentFilterOptions from './IndentFilterOptions';
import { useNavigate } from 'react-router-dom';
import {
  INDENT_TEXT,
  IndentTabs,
} from '../../../Constants/Procurement/Indent/constants';
import { CustomButton } from '../../../Shared/Button/CustomButton';
import CustomTab from '../../../Shared/CustomTabs/CustomTab';
import { NavigationURL } from '../../../Constants/EndPoints';

const IndentTable: React.FC = () => {
  const [indentTab, setIndentTab] = useState<number>(0);
  const navigate = useNavigate();

  return (
    <div className="grid gap-y-3 w-full">
      <div className="flex justify-between mt-2">
        <CustomTab Tabs={IndentTabs} tab={indentTab} setTab={setIndentTab} />
        <div>
          <CustomButton
            variant="Primary"
            onClick={() => navigate(NavigationURL.createIndent)}
          >
            {INDENT_TEXT.createReviewButtonText}
          </CustomButton>
        </div>
      </div>
      <IndentFilterOptions tabChange={indentTab} />
    </div>
  );
};

export default IndentTable;
