import React, { useEffect, useState } from 'react';
import { HTTP_STATUS, IPagination } from '../../../utils/types';
import { GENERIC_FILTER_SECTIONS, initialPaginationValues, PRODUCT_SECTION, secondaryInventorySortOptions, statusOptions } from '../../../utils/constant';
import MasterUpcListTemplate from '../../templates/MasterUPCList/MasterUpcList.template';
import { createUseStyles } from 'react-jss';
import { IMasterProductUPCRequest, useMasterUpcService } from '../../../services/useMasterUpcService';
import ButtonV2 from '../../atoms/ButtonV2';
import FilterChipV2, { IMultiSelectOption } from '../../molecules/FilterChip/FilterChipV2';
import UpcMpcSearchDialogModal, { IMaterialFilterData } from '../../templates/GenericSearchDialog/GenericSerachDialog.template';
import { Button } from '@mui/material';
import advanceFilterIcon from '../../../assets/icons/advanceFilterIcon.svg';
import HoverButton from '../../atoms/HoverButton';
import { useLocation, useNavigate } from 'react-router-dom';


export interface IBrandUpcs {
    id: number;
    masterUpcCode: string;
    brandUpcCode: string;
    brands: number;
    usnStocks: number;
    customers: number;
    status: string;
    orders: number;
    vendors: number;
}

export interface IMasterUpcs {
    upcId: number;
    upcCode: string;
    upcTitle: string;
    brandUpcCount: number;
    availableStock: number;
    customers: number;
    status: string;
    orders: number;
    vendors: number;
}

export interface IUpcListingFilter {
    status: string;
    sort: string;
    search: string;
}

const initialProductUPCListingFiltersValues: IUpcListingFilter = {
    status: "ALL",
    sort: "createdAt,desc",
    search: "",
}

const initialSelections = {
    status: [{ label: 'ALL', value: 'ALL' }],
    sortDate: [{ label: 'createdAt,desc', value: 'createdAt,desc' }]
  };

export interface IRequestBodyValues {
    upcIds: (string | number)[] | null;
    brandIds: (string | number)[] | null;
}

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
}));

const UpcListingPage: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const classes = useStyles();
    const [upcFilters, setUpcFiltersTo] = useState<IUpcListingFilter>(initialProductUPCListingFiltersValues);
    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);
    const [masterUpcs, setMasterUpcsTo] = useState<IMasterUpcs[]>([]);
    const [upcCode, setUpcCodeTo] = useState<IMasterUpcs[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedValues, setSelectedValues] = useState<IMultiSelectOption[]>([]);
    const [status, setStatus] = useState<IMultiSelectOption[]>(initialSelections.status);
    const [sortDate, setSortDate] = useState<IMultiSelectOption[]>(initialSelections.sortDate);
    const [requestBodyValues, setRequestBodyValuesTo] = useState<IRequestBodyValues>({ upcIds: null, brandIds: null });
    const masterUpcService = useMasterUpcService();
    const [upcFilterData, setUpcFilterData] = useState<IMaterialFilterData>({});
    const [filterSection, setFilterSections] = useState<string[]>([]);

    const handleFilterClear = () => {
        setUpcFilterData({});
    }

    const clearAllData = () => {
        setUpcFilterData({});
        setRequestBodyValuesTo({ upcIds: null, brandIds: null });
    }

    useEffect(() => {
        setFilterSections([GENERIC_FILTER_SECTIONS.UPC, GENERIC_FILTER_SECTIONS.MATERIAL_FILTER, GENERIC_FILTER_SECTIONS.OTHER]);
    }, [])

    const onFilterSubmit = () => {
        setRequestBodyValuesTo({ upcIds: upcFilterData?.productIds ?? null, brandIds: null });
    }

    const refreshMasterUpc = async (page: number, size: number) => {
        const params: IMasterProductUPCRequest = {
            status: upcFilters?.status === "ALL" ? null : upcFilters?.status,
            page: page,
            size: size,
            sort: upcFilters?.sort,
        };
        try {
            const upcResponse = await masterUpcService.getMasterProductUPCs(params, requestBodyValues);
            if (upcResponse.status === HTTP_STATUS.OK) {
                const { totalElements, totalPages, content } = upcResponse.data.data;
                setPaginationTo(prevPagination => ({
                    ...prevPagination,
                    totalPages: totalPages,
                    totalRecords: totalElements
                }));
                setMasterUpcsTo(content);
            } else {
                setMasterUpcsTo([]);
            }
        } catch (error) {
            console.error("Master Upc fetching error - ", error);
        }
    };

    const fetchUpcCodes = async (search: string) => {
        const params: IMasterProductUPCRequest = {
            page: 0,
            size: 10,
            sort: 'createdAt,desc',
            search: search
        };
        try {
            const upcResponse = await masterUpcService.getMasterProductUPCs(params, requestBodyValues);
            if (upcResponse.status === HTTP_STATUS.OK && upcResponse.data.data ) {
                const { content } = upcResponse.data.data;
                setUpcCodeTo(content);
            } else {
                setUpcCodeTo([]);
            }
        } catch (error) {
            console.error(" Upc codes fetching error - ", error);
        }
    };

    const handleFiltersChange = (name: string, value: any) => {
        setPaginationTo({ ...pagination, currentPage: 0 });
        setUpcFiltersTo({ ...upcFilters, [name]: value ?? "" });
    };

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPageNumber: number) => {
        setPaginationTo(prevPagination => ({
            ...prevPagination,
            currentPage: newPageNumber
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationTo(prevPagination => ({
            ...prevPagination,
            pageSize: newRowsPerPage,
            currentPage: 0
        }));
    };

    const handleSelectionChange = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setSelectedValues(selected);
        clearWithCrossIcon &&  handleSaveClick(selected);
    };

    const handleSelectionChangeStatus = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setStatus(selected);
        clearWithCrossIcon &&  handleStatusSaveClick(selected);
    };

    const handleSelectionChangeSort = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setSortDate(selected);
        clearWithCrossIcon &&  handleSortSaveClick(selected);
    };

    const handleClearClick = (filterType: string) => {
        switch (filterType) {
            case 'status':
                setStatus([{ label: 'ALL', value: 'ALL' }]);
                handleFiltersChange("status", 'ALL');
                break;
            case 'sortDate':
                setSortDate([{ label: 'createdAt,desc', value: 'createdAt,desc' }]);
                handleFiltersChange("sort", 'createdAt,desc');
                break;
            case 'selectedValues':
                setSelectedValues([]);
                setRequestBodyValuesTo({ upcIds: null, brandIds: null });
                break;
            default:
                setStatus([{ label: 'ALL', value: 'ALL' }]);
                setSortDate([{ label: 'createdAt,desc', value: 'createdAt,desc' }]);
                setSelectedValues([]);
        }
    };

    const handleStatusSaveClick = (status: IMultiSelectOption[]) => {
        const valueString = status.length > 0 ? status[0].value : 'ALL';
        handleFiltersChange("status", valueString);
    };
    
    const handleSaveClick = (selectedValues: IMultiSelectOption[]) => {
        const selectedValuesOnly = selectedValues.length > 0 ? selectedValues.map(option => option.value) : null;
        setRequestBodyValuesTo({ upcIds: selectedValuesOnly, brandIds: null });
    };

    const handleSortSaveClick = (sortDate: IMultiSelectOption[]) => {
        const sortString = sortDate.length > 0 ? sortDate[0].value : 'createdAt,desc';
        handleFiltersChange("sort", sortString);
    };

    useEffect(() => {
        const params = new URLSearchParams();
    
        if (pagination.pageSize !== initialPaginationValues.pageSize) {
            params.set('pageSize', pagination.pageSize.toString());
        }
        if (pagination.currentPage !== initialPaginationValues.currentPage) {
            params.set('pageNumber', pagination.currentPage.toString());
        }
        if (upcFilters.status !== initialProductUPCListingFiltersValues.status) {
            params.set('status', upcFilters.status);
        }
        if (upcFilters.sort !== initialProductUPCListingFiltersValues.sort) {
            params.set('sort', upcFilters.sort);
        }
        if (searchTerm) {
            params.set('search', searchTerm);
        }
    
        navigate(`${location.pathname}?${params.toString()}`, { replace: true });
    }, [pagination, upcFilters, searchTerm]);

    useEffect(() => {
        refreshMasterUpc(pagination.currentPage, pagination.pageSize);
    }, [pagination.currentPage, pagination.pageSize, requestBodyValues, upcFilters]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
    
        const pageSize = params.get('pageSize');
        const pageNumber = params.get('pageNumber');
        const status = params.get('status');
        const sort = params.get('sort');
        const search = params.get('search');
    
        if (pageSize) {
            setPaginationTo(prev => ({ ...prev, pageSize: parseInt(pageSize, 10) }));
        }
        if (pageNumber) {
            setPaginationTo(prev => ({ ...prev, currentPage: parseInt(pageNumber, 10) }));
        }
        if (status) {
            setUpcFiltersTo(prev => ({ ...prev, status }));
            setStatus([{ label: status, value: status }]);
        }
        if (sort) {
            setUpcFiltersTo(prev => ({ ...prev, sort }));
            setSortDate([{ label: sort, value: sort }]);
        }
        if (search) {
            setSearchTerm(search);
        }
    }, []);
    
    useEffect(() => {
        fetchUpcCodes(searchTerm)
    }, [searchTerm]);
    

    return (
        <div className="grid gap-y-6">
            <div className={`${classes.heading} text-2xl font-medium`}>UPC Management</div>
            <div className='flex gap-3'>
                <div className='w-w/3'>
                    <FilterChipV2
                        options={statusOptions}
                        label=" "
                        value={status}
                        onchange={handleSelectionChangeStatus}
                        placeholder="Status"
                        ClearButtonComponent={ButtonV2}
                        buttonLabel={"Clear all"}
                        buttonOnClick={() => handleClearClick('status')}
                        SaveButton={ButtonV2}
                        saveButtonLabel='Apply'
                        saveButtonOnClick={() => handleStatusSaveClick(status)}
                        isSingleSelect={true}
                    />
                </div>
                <div className='w-w/3'>
                    <FilterChipV2
                        options={secondaryInventorySortOptions}
                        label=" "
                        value={sortDate}
                        onchange={handleSelectionChangeSort}
                        placeholder="Sort Date"
                        ClearButtonComponent={ButtonV2}
                        buttonLabel={"Clear all"}
                        buttonOnClick={() => handleClearClick('sortDate')}
                        SaveButton={ButtonV2}
                        saveButtonLabel='Apply'
                        saveButtonOnClick={() =>handleSortSaveClick(sortDate)}
                        isSingleSelect={true}
                    />
                </div>
                <div className='w-w/3'>
                    <FilterChipV2
                        options={upcCode.map(upc => ({ label: upc.upcCode, value: upc.upcId }))}
                        label=" "
                        value={selectedValues}
                        onchange={handleSelectionChange}
                        placeholder="UPC Title or Code"
                        ClearButtonComponent={ButtonV2}
                        textFieldPlaceholder='Search...'
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        buttonLabel={"Clear all"}
                        buttonOnClick={() => handleClearClick('selectedValues')}
                        SaveButton={ButtonV2}
                        saveButtonLabel='Apply'
                        saveButtonOnClick={() =>handleSaveClick(selectedValues)}
                    />
                </div>
                <div className='ml-auto mt-2'>
                <UpcMpcSearchDialogModal
                sections={filterSection}
                productType={PRODUCT_SECTION.MASTER_UPC}
                filterData={upcFilterData}
                setFilterData={setUpcFilterData}
                handleFilterClear={handleFilterClear}
                onFilterSubmit={onFilterSubmit}
                triggerButton={
                    <HoverButton variant="tertiaryContained"
                    iconButton={<img src={advanceFilterIcon} alt="Filter Icon" />}
                    label="Advance Filters"
                    buttonWithImg = {true}
                    hoverButton={!!upcFilterData?.productIds?.length}
                    hoverButtonLabel={upcFilterData?.productIds?.length}
                    handleCloseIcon={clearAllData}
                            />
                }

            />
                </div>
            </div>
            
            <MasterUpcListTemplate
                masterUpcs={masterUpcs}
                pagination={pagination}
                handlePaginationChange={handlePaginationChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                refreshMasterUpc={refreshMasterUpc}
            />
        </div>
    );
};

export default UpcListingPage;