import React, { useEffect, useState } from "react";
import ChemicalCompositionTable from "./ChemicalCompositionTable";
import MechanicalPropertiesTable from "./MechanicalPropertiesTable";
import TensilePropertiesTable from "./TensilePropertiesTable";
import axios from 'axios';
import Typography from '@mui/material/Typography';
import { useAuthenticatedUser } from "../../../../../hooks/useAuthenticatedUser";

const BaseUrl = process.env.REACT_APP_API_V1_URL;
const GradePropertiesTableIndex = ({ gradeId = null }) => {
    const { user } = useAuthenticatedUser();
    const [gradeData, setGradeData] = useState(null);

    const fetchGradeData = async () => {
        try {
            const apiUrl = `${BaseUrl}/quality-grade/${gradeId}`;
            const response = await axios.get(apiUrl, {
                headers: {
                   Authorization: `${user.token}`,
                   "Content-Type": "application/json",
                }
             });

            if (response.status === 200) {
                const data = response.data.data;
                setGradeData(response.data.data);
            } else {
                // Handle error if needed
            }
        } catch (error) {
            console.error("Error fetching grade data:", error);
        }
    };

    useEffect(() => {
        if (gradeId) {
            fetchGradeData();
        }
    }, [gradeId]);

    const updateGradeProperties = (data) => {
        setGradeData(data);
    }
    function parseJsonArray(jsonArrayString) {
        try {
            const parsedArray = JSON.parse(jsonArrayString);
            return parsedArray !== null ? parsedArray : [];
        } catch (error) {
            console.error("Error parsing JSON array:", error);
            return []; // Return an empty array in case of an error
        }
    }

    return (
        <div>
            <Typography variant="h5" component="div" gutterBottom>
                <span className="font-bold mb-4">Grade :</span> {gradeData?.name}
            </Typography>

            {gradeId && gradeData && (<>

                <div className="w-full">
                    <ChemicalCompositionTable
                        records={parseJsonArray(gradeData?.chemicalComposition)}
                        associatedGrade={gradeData}
                        updateGradeProperties={updateGradeProperties}
                    />
                </div>

                <div className="w-full">
                    <MechanicalPropertiesTable
                        records={parseJsonArray(gradeData?.mechanicalProperties)}
                        associatedGrade={gradeData}
                        updateGradeProperties={updateGradeProperties}
                    />
                </div>

                {/* <div className="w-full">
                    <TensilePropertiesTable
                        records={parseJsonArray(gradeData?.tensileProperties)}
                        associatedGrade={gradeData}
                        updateGradeProperties={updateGradeProperties}
                    />
                </div> */}
            </>

            )}
        </div>
    );
};

export default GradePropertiesTableIndex;
