import React, { useEffect, useState } from 'react';
import ButtonV2 from '../../atoms/ButtonV2';
import { initialPaginationValues } from '../../../utils/constant';
import { HTTP_STATUS, IPagination } from '../../../utils/types';
import TableV2, { ColumnType } from '../../organisms/TableV2';
import { createUseStyles } from 'react-jss';
import { IMasterRelatedUpc } from './MasterRelatedUpc.template';
import { IRelatedBrandsRequest, useMasterUpcService } from '../../../services/useMasterUpcService';
import MasterBrandListModalTemplate from '../MasterUPCList/MasterbrandListModal.Template';
import AddMasterBrandModalTemplate from '../MasterUPCList/AddMasterBrandModal.Template';
import TextFieldV2 from '../../atoms/TextFieldV2';
import FilterChipV2, { IMultiSelectOption } from '../../molecules/FilterChip/FilterChipV2';
import { IRequestBodyValues } from '../../pages/UpcListing/UpcListing.page';
import { useBrandService } from '../../../services/useBrandService';
import { IBrand } from '../../pages/AdminMaster/Brand/BrandList.page';
import ResourceStatusV2 from '../../atoms/ResourceStatus/ResourceStatusV2';
import { enumToString } from '../../../utils/helper';
import { InputAdornment } from '@mui/material';
import search from '../../../assets/images/search.svg';
import downloadExcelIcon from '../../../assets/icons/downloadExcelIcon.svg';


export interface IRelatedBrands {
    id: number;
    upcCode: string;
    brand: string;
    availableInventory: number;
    status: string;
}

export interface IUpcRelatedBrandsFilter {
    search: string;
}

const initialUpcRelatedBrandsFiltersValues: IUpcRelatedBrandsFilter = {
    search: ""
}

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.buttonV2.secondaryContained.text.primaryDark
    }
}));

const MasterRelatedBrandsTemplate: React.FC<IMasterRelatedUpc> = ({ id, upcCode, upcTitle }) => {
    const classes = useStyles();
    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);
    const [brands, setBrand] = useState<IRelatedBrands[]>([]);
    const [brandsFilters, setBrandsFiltersTo] = useState<IUpcRelatedBrandsFilter>(initialUpcRelatedBrandsFiltersValues);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [brandData, setBrandData] = useState<IBrand[] | null>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedValues, setSelectedValues] = useState<IMultiSelectOption[]>([]);
    const [requestBodyValues, setRequestBodyValuesTo] = useState<IRequestBodyValues>({ upcIds: [], brandIds: [] });


    const masterUpcService = useMasterUpcService();
    const brandService = useBrandService();

    const fetchUpc = async (page: number, size: number) => {
        if (id) {
            try {
                let params: IRelatedBrandsRequest = {
                    search: brandsFilters?.search ?? null,
                    brandIds: requestBodyValues.brandIds?.join(','),
                    page: page,
                    size: size,
                    sort: "createdAt,desc"
                }
                const upcResponse = await masterUpcService.getRelatedBrandsById(id, params);
                if (upcResponse?.status === HTTP_STATUS.OK) {
                    const { totalElements, totalPages } = upcResponse.data.data;
                    setPaginationTo(prevPagination => ({
                        ...prevPagination,
                        totalPages: totalPages,
                        totalRecords: totalElements
                    }));
                    setBrand(upcResponse?.data?.data?.content);
                }
                else {
                    throw new Error("Related Brands fetch failed");
                }
            } catch (error) {
                console.error("error", `Related Brands fetch failed`)
            }
        }
    }

    const loadBrands = async (inputValue?: string) => {
        try {
            const response = await brandService.getAllBrandsByName({
                page: 0,
                size: 10,
                sort: 'createdAt,desc',
                name: inputValue ?? ''
            });
    
            if (response.status === HTTP_STATUS.OK) {
                setBrandData(response?.data?.data?.content);
            } else {
                console.error(`Unexpected status code: ${response.status}`);
            }
        } catch (error) {
            console.error("Error Fetching Brands: ", error);
        }
    }
    

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage
        }));
    };

    const handleFiltersChange = (name: string, value: any) => {
        setPaginationTo({ ...pagination, currentPage: 0 });
        setBrandsFiltersTo({ ...brandsFilters, [name]: value ?? "" });
    };

    const handleSelectionChange = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setSelectedValues(selected);
        clearWithCrossIcon && handleApplyClick(selected);
    };

    const handleClearClick = () => {
        setSelectedValues([]);
    };
    const handleApplyClick = (selectedValues: IMultiSelectOption[]) => {
        const selectedValuesOnly = selectedValues.map(option => option.value);
        setRequestBodyValuesTo({ upcIds: [], brandIds: selectedValuesOnly });
    };

    useEffect(() => {
        loadBrands(searchTerm)
    }, [searchTerm]);


    useEffect(() => {
        fetchUpc(pagination.currentPage, pagination.pageSize)
    }, [pagination.currentPage, pagination.pageSize, id, brandsFilters, requestBodyValues]);



    const schema = {
        id: "1",
        title: "",
        pagination: {
            total: pagination.totalRecords,
            currentPage: pagination.currentPage,
            isVisible: true,
            limit: pagination.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange,
        },
        columns: [
            { label: "S No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "Brand UPC", key: "upcCode", type: "string" as ColumnType, props: { className: '' } },
            { label: "Brand", key: "brand", type: "string" as ColumnType, props: { className: '' } },
            { label: "Current USN Stock (MT)", key: "availableInventory", type: "number" as ColumnType, props: { className: '' } },
            { label: "Status", key: "status", component: ({ value }: { value: string }) => <ResourceStatusV2 status={value} />, type: "custom" as ColumnType, props: {} },
        ],
    };
    const records = brands.map((brand: IRelatedBrands, index: number) => [
        pagination.currentPage * pagination.pageSize + index + 1,
        brand.upcCode,
        brand.brand,
        brand.availableInventory ?? 0,
        enumToString(brand.status),
    ]);

    const handleAddAnotherBrand = () => {
        setDialogOpen(true);
    };

    return (
        <div>
            <div className={`${classes.heading} text-lg font-semibold mt-5 mb-6`}>Related Brand UPC</div>
            <div className='flex justify-between'>
                <div className='flex items-center gap-3'>
                    <FilterChipV2
                        options={brandData ? brandData.map(brand => ({ label: brand.name, value: brand.id })) : []}
                        label=" "
                        value={selectedValues}
                        onchange={handleSelectionChange}
                        placeholder="Brand"
                        ClearButtonComponent={ButtonV2}
                        textFieldPlaceholder='Search...'
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        buttonLabel={"Clear all"}
                        buttonOnClick={handleClearClick}
                        SaveButton={ButtonV2}
                        saveButtonLabel='Apply'
                        saveButtonOnClick={() => handleApplyClick(selectedValues)}
                        maxHeight='40%'
                    />
                </div>
                <div className='flex items-center gap-x-4'>
                    <TextFieldV2
                        className="w-72"
                        type="text"
                        placeholder='Search By Brand...'
                        label=" "
                        variant="outlined"
                        name="search"
                        value={brandsFilters.search}
                        onChange={(e) => handleFiltersChange("search", e.target.value)}
                        InputProps={{
                            startAdornment: <InputAdornment position="start" > <img src={search} alt="icon" /></InputAdornment>,
                        }} />
                    <ButtonV2 variant={"tertiaryContained"} label={"Excel"} iconButton={<img src={downloadExcelIcon} alt="Excel Icon" />} buttonWithImg={true} disabled/>
                    <ButtonV2
                        variant="primaryContained"
                        onClick={handleAddAnotherBrand}
                        label={"+Add Another Brand"}
                    />
                </div>
            </div>
            <div className='mt-5'>
                <TableV2 schema={schema} records={records} />
            </div>
            <AddMasterBrandModalTemplate
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                selectedMasterUpcId={id}
                selectedMasterUpcCode={upcCode}
                selectedMasterUpcTitle={upcTitle}
                brandUpcs={brands}
                refreshBrands={fetchUpc}
                masterUpcId={id}
            />
        </div>
    )
}
export default MasterRelatedBrandsTemplate