import React, { useState } from 'react'
import { useSnackbar } from '../../../hooks/useSnackBar';
import ImageMasterCreatePageTemplate from '../../templates/ImageMaster/ImageMasterCreate.template';
import TitleHeading from '../../atoms/CustomHeading/TitleHeading';

const ImageMasterUpdatePage: React.FC = () => {

    const { showSnackbar, SnackBarComponent } = useSnackbar();
    return (
        <div>
            { SnackBarComponent }
            <TitleHeading text="Update Image Master" />
            <ImageMasterCreatePageTemplate mode='EDIT'/>
        </div>
    )
}

export default ImageMasterUpdatePage;
