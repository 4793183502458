import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export const QUOTATION_URLS = {
  POST_QUOTATION: "/quotation",
  GET_ALL_QUOTATION: "/quotations",
  GET_QUOTATION: "/quotation/:id",
  UPDATE_QUOTATION: "/quotation/:id",
};

interface IQuotationFilterRequest{
  id?: number;
  search?: string;
  status?: string;
  publishStatus?: string;
  actorId?: number;
  page: number;
  size: number;
  sort?: string;
}

export const useQuotationService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const createQuotation = (data: any) => {
    return request(API_METHOD.POST, QUOTATION_URLS.POST_QUOTATION, authenticatedUser, data);
  };

  const getQuotationById = (id: number) => {
    return request(API_METHOD.GET, replaceUrlParams( QUOTATION_URLS.GET_QUOTATION, { id } ), authenticatedUser);
  };

  const getAllQuotations = (params: IQuotationFilterRequest) => {
    return request(API_METHOD.GET, QUOTATION_URLS.GET_ALL_QUOTATION, authenticatedUser, null, { params } );
  };

  const updateQuotation = (id: number, data: any) => {
    return request(API_METHOD.PUT, replaceUrlParams( QUOTATION_URLS.UPDATE_QUOTATION, { id } ), authenticatedUser, data);
  };

  return {
    createQuotation,
    getQuotationById,
    getAllQuotations,
    updateQuotation,
  };
};
