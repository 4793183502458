import { Route, Routes } from 'react-router-dom';
import CustomerGroupMasterListPage from '../../../components/pages/CustomerGroupMaster/CustomerGroupMasterList.page';
import CustomerGroupMasterCreatePage from '../../../components/pages/CustomerGroupMaster/CustomerGroupMasterCreate.page';
import CustomerGroupMasterUpdatePage from '../../../components/pages/CustomerGroupMaster/CustomerGroupMasterUpdate.page';

const CustomerGroupMasterRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<CustomerGroupMasterListPage />} />
            <Route path="/add" element={<CustomerGroupMasterCreatePage />} />
            <Route path="/:id/update" element={<CustomerGroupMasterUpdatePage />} />
        </Routes>
    )
}

export default CustomerGroupMasterRoutes
