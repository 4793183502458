import axios from 'axios'
import { useState } from 'react'
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
const BaseUrl = process.env.REACT_APP_API_V1_URL;

/*
Accepts: url and authToken(optional);
Returns : data, loading, error
*/
const useCreate = () => {
    const { user } = useAuthenticatedUser();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps

    const postRequest = async (url, payload) => {
        if(url !== "/auth/sign-up" && url !== "/auth/sign-in"){
            axios.defaults.headers.common['Authorization'] =  user.token;
            ;
        }
        let res;
        setLoading(true)
        setError(false)
        await axios.post(`${BaseUrl}${url}`, payload).then((response) => {
            res = response;
        })
            .catch((err) => {
                res = err.response;
                setError(true)
            })
            .finally(() => setLoading(false))
        return res;
    }
    const putRequest = async (url, payload) => {
        axios.defaults.headers.common['Authorization'] = user.token;
        let res;
        setLoading(true)
        await axios.put(`${BaseUrl}${url}`, payload).then((response) => {
            res = response;
        })
            .catch((err) => {
                res = err.response;
                setError(true)
            })
            .finally(() => setLoading(false))
        return res;
    }
    return { loading, error, postRequest, putRequest }
}

export default useCreate


