interface HeadingProps {
  title: string;
  value: string;
  className?: string;
  headingClass?: string;
}

// Todo

export const ProductAttributeHeading = ({
  title,
  value,
  className,
  headingClass,
}: HeadingProps) => {
  return (
    <div className={headingClass} title={value}>
      <div className="text-cgray-1 text-xs">{title}</div>
      <div className={className}>{value}</div>
    </div>
  );
};
