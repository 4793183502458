

import SelectUpcAttributes from './../../SelectUpcAttributes';

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuthenticatedUser } from '../../../../../hooks/useAuthenticatedUser';
const BaseUrl = process.env.REACT_APP_API_V1_URL;
const ManufactureSearch = ({ updateSearchParams, searchPayload }) => {

  const { user } = useAuthenticatedUser();
  const [categoryOptions, setCategoryOptions] = useState({
    manufacturers: [],
    brands: []
  });

  const [selectedCategories, setSelectedCategories] = useState({
    manufacturer: searchPayload?.manufacturer ?? null,
    brand: searchPayload?.brand ?? null,
  });

  const updateCategoryOptions = (type, data) => {
    setCategoryOptions(prevState => ({
      ...prevState,
      [type]: data,
    }));
  };


  const updateCategoryInput = (type, event, options) => {

    if (Array.isArray(options)) {
      const foundData = options.find(item => item.id === event.target.value);
      if (foundData) {
        setSelectedCategories(prevState => ({
          ...prevState,
          [type]: foundData,
        }));
        updateSearchParams(type, foundData);
      }
    }

  };


  const convertToOptions = (data) => {
    if (!Array.isArray(data)) {
      throw new Error('Input data should be an array.');
    }

    return data.map(item => {
      if (!item || typeof item !== 'object' || !item.hasOwnProperty('id') || !item.hasOwnProperty('name')) {
        throw new Error('Invalid item in data array.');
      }

      return {
        label: item.name,
        id: item.id
      };
    });
  };

  useEffect(() => {

    axios.get(`${BaseUrl}/manufacturers?&page=0&size=100&sort=createdAt,desc`, {
      params: {
        search: ''
      },
      headers: {
        Authorization: `${user.token}`,
        "Content-Type": "application/json",
      }
    }).then(response => {
      updateCategoryOptions('manufacturers', convertToOptions(response.data.data.content));
    })
      .catch(error => {
        console.error('Error fetching super categories:', error);
      });
  }, []);

  useEffect(() => {
    if (selectedCategories?.manufacturer?.id) {
      axios.get(`${BaseUrl}/brands?&page=0&size=100&sort=createdAt,desc`, {
        params: {
          manufacturerId: selectedCategories.manufacturer.id,
          name:''
        },
        headers: {
          Authorization: `${user.token}`,
          "Content-Type": "application/json",
        }
      })
        .then(response => {
          
          updateCategoryOptions('brands', convertToOptions(response.data.data.content));
        })
        .catch(error => {
          console.error('Error fetching main categories:', error);
        });
    }
  }, [selectedCategories?.manufacturer]);

  return (
    <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[12px]">
      <div className="flex-1 flex flex-col items-center justify-start">
        <SelectUpcAttributes
          label="Manufacturer"
          value={selectedCategories?.manufacturer?.id}
          onChange={event => updateCategoryInput('manufacturer', event, categoryOptions.manufacturers)}
          options={categoryOptions.manufacturers}
        // disabled={false}
        />
      </div>


      <div className="flex-1 flex flex-col items-center justify-start">
        <SelectUpcAttributes
          label="Brand"
          value={selectedCategories?.brand?.id}
          onChange={event => updateCategoryInput('brand', event, categoryOptions.brands)}
          options={categoryOptions.brands}
          disabled={!selectedCategories.manufacturer}
        />
      </div>
    </div>
  )
};

export default ManufactureSearch;
