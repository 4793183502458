import React, { useContext, useEffect, useState } from "react";
import "./PreviewUPC_refactor.css";
import closePng from "./../../../../Assets/Images/close.png"
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { mapStandardStatusEnumToString } from "../../../../Constants/Enums/StandardStatusEnum";
import Chip from '@mui/material/Chip';
import StatusToggle from "../StatusToggle/StatusToggle";
import { useAuthenticatedUser } from "../../../../../hooks/useAuthenticatedUser";
import DisplayChips from "../DisplayChips/DisplayChips";
import Keywords from "../../UPC/KeyWords/KeyWords";
const BaseUrl = process.env.REACT_APP_API_V1_URL;

export const convertToReadableFormat = (inputString) => {
  if (!inputString) return null;
  const words = inputString.split('_');
  const result = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
  return result.join(' ');
};

const PreviewMPCRefactor = (props) => {
  const { user } = useAuthenticatedUser();
  const location = useLocation();
  const navigate = useNavigate();
  const ViewMode = location.state?.mode;
  const upcId = location.state?.upcId;  


  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");

  const [formData, setFormData] = useState({
    keywords: [],
    status: "",
  });
  const [errors, setErrors] = useState(true);
  const [upcDetail, setUpcDetail] = useState({});




  useEffect(() => {
    const fetchUpcDetailById = async (upcId) => {
      try {
        const { data } = await axios.get(
          `${BaseUrl}/product-upc/${upcId}`, {
          headers: {
            Authorization: `${user.token}`,
            "Content-Type": "application/json",
          }
        });
        handleSetFormData(data.data.product.keywords, data.data.status);
        setUpcDetail(data.data);  
      } catch (error) {
        console.log(error);
      }
    };
    fetchUpcDetailById(upcId);
  }, []);

  const handleSetFormData = (keywords, status) => {
    setFormData((prevData) => {
      return {
        ...prevData,
        keywords: keywords.split(","),
        status: status === "ACTIVE" ? true : false,
      };
    });
  };

  const handleCreate = async () => {
    if (formData.keywords.length === 0) {
      alert("Keywords cannot be empty. Please provide keywords.");
      return;
    }

    const updateUPCDetails = {
      ...upcDetail,
  
      status: formData.status === true ? "ACTIVE" : "INACTIVE",
      product: {
        ...upcDetail.product,
        keywords: formData.keywords.join(","),
      },
    };

    try {
      const response = await axios.put(
        `${BaseUrl}/product-upc/${upcId}`,
        updateUPCDetails, {
        headers: {
          Authorization: `${user.token}`,
          "Content-Type": "application/json",
        }
      });
      if (response) {
        if (response.status === 200) {
          handleRedirect();
        }
      } else {
        console.log("Error in Response - ", response);
      }
    } catch (error) {
      console.error("Error updating upc - ", error);
      return null;
    }
  };

  const handleUpdateUPCFormDetails = (key, payload) => {
    setFormData((prev) => ({
      ...prev,
      [key]: payload,
    }));
    checkIfFormContainsErrors(formData);
  };

  const checkIfFormContainsErrors = (formData) => {
    if (
      !formData.status ||
      formData.keywords.length === 0
    ) {
      setErrors(true);
      return true;
    }
    setErrors(false);
    return false;
  };

  const handleRedirect = () => {
    navigate('/mpc');
    return
  }

  function DisplayKeywords({ keywords }) {

    if (!keywords) {
      return null;
    }
    var arrayOfValues = []
    try {
      arrayOfValues = keywords.split(',');
    } catch (error) {
      return null;
    }

    return (
      <div className="flex gap-x-3">
        {arrayOfValues.map((keyword, index) => (
          <Chip
            variant="outlined"
            key={index}
            label={keyword}
          />
        ))}
      </div>
    );
  }

  const DisplayProductSpecificationDetails = ({ productSpecificationDetails }) => {
  try {
    if (!productSpecificationDetails?.SPECIFICATION || !Array.isArray(productSpecificationDetails?.SPECIFICATION)) {
      return <div>No specification data available.</div>;
    }

    return productSpecificationDetails?.SPECIFICATION.map((specificationItem, index) => (
      <div key={index} className="grid gap-y-1.5">
        <div className="m-auto">{specificationItem.name.toUpperCase()}</div>
        <div className="mx-4 px-1.5 rounded-full border border-input-border">
          {`${specificationItem.attributeOptions} ${specificationItem.uom ? specificationItem.uom.toLowerCase() : ''}`}
        </div>
      </div>
    ));
  } catch (error) {
    console.error('Error rendering specification details:', error);
    return <div>No Specification Details</div>;
  }
};

  const DisplayProductGeneralizationDetails = ({ productSpecificationDetails }) => {
    try {
      const attributes = JSON.parse(productSpecificationDetails);

      if (attributes?.GENERALIZATION) {
        return attributes.GENERALIZATION.map((specificationItem, index) => (

          <div key={index} className="grid gap-y-1.5">

            <div className="m-auto">{specificationItem.name}</div>
            <div className="mx-4 px-1.5 rounded-full border border-input-border">
              {specificationItem.attributeOptions}
            </div>
          </div>
        ));
      } else {
        return <div>No Generalization data available.</div>;
      }
    } catch (error) {
      console.error('Error parsing attributes:', error);
      return <div>No Generalization Details</div>;
    }
  };
  return (
    <div className="search-upc-container1  mt-4 m-auto">
      <div className="grid gap-y-3 mx-6 pb-6">

        <div className="self-stretch flex flex-row pt-0 px-0 pb-[18px] items-center justify-start gap-[10px] text-2xl text-text-color border-b-[1px] border-solid border-input-border">
          <div className="flex-1 relative leading-[20px] font-semibold">
            Master Product Code : {upcDetail?.upcCode}
          </div>
          <button
            className="w-30 h-21"
            onClick={handleRedirect}
          >
            <img
              className="relative w-37 h-37 overflow-hidden shrink-0"
              alt=""
              src={closePng}
            />
          </button>
        </div>

        <div className="grid gap-y-4">
          <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
            <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
              Category
            </div>
            <div className="flex gap-x-6">
              <div className="grid gap-y-1.5">
                <div className="text-text-color font-roboto text-base font-normal">
                  Super Category
                </div>
                <div className="text-text-color font-roboto text-base font-medium">
                {upcDetail?.product?.superCategory?.name}
                </div>
              </div>
              <div className="grid gap-y-1.5">
                <div className="text-text-color font-roboto text-base font-normal">
                  Main Category
                </div>
                <div className="text-text-color font-roboto text-base font-medium">
                {upcDetail?.product?.mainCategory?.name}
                </div>
              </div>
              <div className="grid gap-y-1.5">
                <div className="text-text-color font-roboto text-base font-normal">
                  Product Category
                </div>
                <div className="text-text-color font-roboto text-base font-medium">
                {upcDetail?.product?.productCategory?.name}
                </div>
              </div>
              <div className="grid gap-y-1.5">
                <div className="text-text-color font-roboto text-base font-normal">
                  Manufacturer
                </div>
                <div className="text-text-color font-roboto text-base font-medium">
                {upcDetail?.product?.manufacturer?.name}
                </div>
              </div>
              <div className="grid gap-y-1.5">
                <div className="text-text-color font-roboto text-base font-normal">
                  Brand
                </div>
                <div className="text-text-color font-roboto text-base font-medium">
                {upcDetail?.product?.brand?.name}
                </div>
              </div>
            </div>
          </div>

          <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
            <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
              Standard
            </div>
            <div className="flex gap-x-6">
            <div className="grid gap-y-1.5">
                <div className="text-text-color font-roboto text-base font-normal">
                  Standard
                </div>
                <div className="text-text-color font-roboto text-base font-medium">
                {upcDetail?.product?.standard?.name}
                </div>
              </div>
              <div className="grid gap-y-1.5">
                <div className="text-text-color font-roboto text-base font-normal">
                  Grade
                </div>
                <div className="text-text-color font-roboto text-base font-medium">
                {upcDetail?.product?.grade?.name}
                </div>
              </div>
            </div>
          </div>

          {/* <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
            <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
              General Info
            </div>
            <div className="flex gap-x-6">
              <DisplayProductGeneralizationDetails productSpecificationDetails={upcDetail?.attributes} />
            </div>
          </div> */}

          <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
            <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
              Product Classification
            </div>
            <div className="flex gap-x-6">
              <div className="grid gap-y-1.5">
                <div className="text-text-color font-roboto text-base font-normal">
                  Shape
                </div>
                <div className="text-text-color font-roboto text-base font-medium">
                {convertToReadableFormat(upcDetail?.shape)}
                </div>
              </div>
              <div className="grid gap-y-1.5">
                <div className="text-text-color font-roboto text-base font-normal">
                  Class
                </div>
                <div className="text-text-color font-roboto text-base font-medium">
                {upcDetail?.classType}
                </div>
              </div>
            </div>
          </div>

          <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
            <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
              Product Specification
            </div>
            <div className="flex gap-x-6">

              <DisplayProductSpecificationDetails productSpecificationDetails={upcDetail?.attributes} />

            </div>
          </div>


        {ViewMode === 'EDIT' ? <>
        <Keywords
            formData={formData}
            handleUpdateFormDetails={handleUpdateUPCFormDetails}
          />
          <DisplayChips
          formData={formData}
          handleUpdateFormDetails={handleUpdateUPCFormDetails}
        />
                  <p>
            The Keywords are to be used for easy and efficient searching of
            Product.
          </p>
        </>
        :
        <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
        <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
          Keywords
        </div>

        <DisplayKeywords keywords={upcDetail?.product?.keywords} />
      </div>}
          {
            ViewMode === 'EDIT' ?
              <StatusToggle
                formData={formData}
                handleUpdateFormDetails={handleUpdateUPCFormDetails}
              />
              :
              <div className="grid gap-y-4 p-6 bg-gray-300 bg-opacity-30 rounded">
                <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
                  Status:
                  <span className={upcDetail?.status === "ACTIVE" ? "text-green ml-4" : "text-inactive ml-4"}>
                    {mapStandardStatusEnumToString(upcDetail?.status)}
                  </span>
                </div>
              </div>
          }



        </div>


        {
          ViewMode === 'VIEW' ?
            <div className="flex gap-5 justify-end">
              <div className="grid   mt-1 ">
                <button
                  onClick={handleRedirect}
                  className="preview-draft1 px-6 py-3"
                  type="submit"
                >
                  Back
                </button>
              </div>
            </div>
            :
            <div className="flex justify-end space-x-4 ">
              <button
                className="rounded bg-blue overflow-hidden flex flex-row py-[0.81rem] px-[1.63rem] items-center justify-center text-[0.94rem] text-white"
                onClick={handleCreate}
              >
                <div className="relative leading-[1.38rem] font-semibold">
                  Update MPC
                </div>
              </button>
            </div>
        }
      </div>
    </div>
  );
};

export default PreviewMPCRefactor;
