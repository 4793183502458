import * as React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { Box } from '@mui/material';
import ButtonV2 from '../../atoms/ButtonV2';
import { createUseStyles } from 'react-jss';
import { NONAME } from 'dns';

interface GenericDateRangePickerProps {
  label: string;
  onDataFilter: (startDate: Dayjs, endDate: Dayjs) => void;
}

const useStyles = createUseStyles((theme: any) => ({
  button: {
    background: theme.palette.textV2.primary.primary100,
    color: theme.palette.textV2.primary.primary700,
        "&:hover": {
      backgroundColor: theme.palette.textV2.primary.primary300,
  },
  },
  text: {
    '& .MuiInputBase-input': {
      color: '#FD6202 ',
    },
    '& .MuiPaper-root': {
      background: theme.palette.textV2.primary.primary300,

    },
    '& .MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      background: theme.palette.textV2.primary.primary300,

      "&:hover": {
        background: theme.palette.textV2.primary.primary300,
    },
    },
   
  }, 
  '& .MuiTextField-root': {
    backgroundColor: theme.palette.textV2.primary.primary300,

  }
}));

const GenericDateRangePicker: React.FC<GenericDateRangePickerProps> = ({
  label,
  onDataFilter
}) => {
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(null);
  const [selectedEndDate, setSelectedEndDate] = React.useState<Dayjs | null>(null);
  const classes = useStyles();

  const currentYear = dayjs().year();
  const disabledYears = React.useMemo(() => {
    const years = [];
    for (let year = currentYear + 1; year <= currentYear + 10; year++) {
      years.push(year);
    }
    return years;
  }, [currentYear]);

  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date);
    setSelectedEndDate(null);
  };

  const handleEndDateChange = (date: Dayjs | null) => {
    setSelectedEndDate(date);
  };

  const handleApplyFilter = () => {
    if (selectedDate && !selectedEndDate) {
      onDataFilter(selectedDate.startOf('month'), selectedDate.endOf('month'));
    } else if (selectedDate && selectedEndDate) {
      onDataFilter(selectedDate.startOf('month'), selectedEndDate.endOf('month'));
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center' }}>
        <DatePicker
          label="Start Date"
          value={selectedDate}
          onChange={handleDateChange}
          views={['year', 'month']}
          className= {classes.text}
          disableFuture
          shouldDisableDate={(date) => date.year() > currentYear}
        slotProps={{
          desktopPaper: {
            sx: {
              '& .MuiPickersYear-yearButton.Mui-selected':{
                backgroundColor: '#FFEFE6',
                color: '#FD6202'
              },
              '& .MuiPickersMonth-monthButton.Mui-selected': {
                backgroundColor: '#FFEFE6',
                color: '#FD6202'
              },
              '& .MuiOutlinedInput-root': {
                backgroundColor: '#FFEFE6',
            
              }
            },
          },
        }}
        />
        <DatePicker
          label="End Date"
          value={selectedEndDate}
          onChange={handleEndDateChange}
          views={['year', 'month']}
          disableFuture
          className= {classes.text}
          shouldDisableDate={(date) => date.year() > currentYear}
          slotProps={{
            desktopPaper: {
              sx: {
                '& .MuiPickersYear-yearButton.Mui-selected':{
                  backgroundColor: '#FFEFE6',
                  color: '#FD6202'
                },
                '& .MuiPickersMonth-monthButton.Mui-selected': {
                  backgroundColor: '#FFEFE6',
                  color: '#FD6202'
                },
              },
            }, 
          }}
        />
        <ButtonV2 label="Done" variant="primaryContained" onClick={handleApplyFilter} className={classes.button}/>
      </Box>
    </LocalizationProvider>
  );
};

export default GenericDateRangePicker;

