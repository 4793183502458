import { ProductAttributeHeading } from '../../../Shared/CustomHeading/ProductAttributeHeading';
import { AuctionProductList } from '../../../Constants/Procurement/LiveAuction/constants';

const BidProductCard = ({
  warehouseId,
  quantity,
  item,
}: AuctionProductList) => {
  const consigneeLocation = warehouseId;

  return (
    <div className="flex gap-10 w-full items-center justify-between py-2 pl-3 pr-2 border border-cgray-4 rounded-md bg-white">
      <div className="flex h-full items-center gap-4">
        <img
          src={item?.productCategoryImageURL}
          alt="img"
          className="rounded-md w-[67px] h-[63px]"
        />
        <div>
          <ProductAttributeHeading
            title={'UPC Code'}
            value={item?.upcCode}
            className={'text-sm mt-0.5 text-blue font-semibold'}
          />
        </div>
      </div>

      <div className="flex h-full items-center gap-4 bg-cgray-13 p-2 rounded-md">
        <ProductAttributeHeading
          title={'Quantity'}
          value={quantity + ' MT'}
          className={'text-sm mt-0.5'}
        />
        <ProductAttributeHeading
          title={'Consignee Location'}
          value={consigneeLocation}
          className={'text-sm mt-0.5'}
        />
      </div>
    </div>
  );
};

export default BidProductCard;
