import React, { useEffect, useState } from 'react';
import { AUCTION_TEXT } from '../../../Constants/Procurement/ReverseAuctionRFQ/constants';
import { useNavigate } from 'react-router-dom';
import { usePRContext } from '../../../../hooks/useProcurementContext';
import IndentDetails from './../../../Components/Procurement/ReverseActionRFQ/IndentDetails';
import {
  Comment,
  FormData,
  Indent,
} from '../../../Constants/Procurement/Indent/types';
import {
  Auction,
  AuctionFormData,
} from '../../../Constants/Procurement/ReverseAuctionRFQ/types';
import moment from 'moment';
import ReviewAddActionDetails from '../../../Components/Procurement/ReverseActionRFQ/ReviewAddActionDetails';
import RemarkComponent from '../../../Components/Procurement/Indent/RemarkComponent';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import {
  COMMENT_RESOURCE_TYPE,
  PAYMENT_MODE,
} from '../../../Constants/Procurement/Indent/constants';
import { useCommentService } from '../../../../services/useComentService';
import { useReverseAuctionService } from '../../../../services/useReverseAuctionService';

const ReviewActionDetails = () => {
  const { RFQParams, setRFQParams } = usePRContext();
  const navigate = useNavigate();
  const { user }: any = useAuthenticatedUser();
  const [indentDetails, setIndentDetails] = useState<FormData>();
  const [showIndent, setShowIndent] = useState<boolean>(false);
  const [formData, setFormData] = useState<AuctionFormData>();
  const [auctionRemarks, setAuctionRemarks] = useState<Boolean>(false);
  const [showButton, setShowButton] = useState<Boolean>(true);

  const indentToFormData = (formData: Indent) => {
    return {
      id: formData.id,
      items: formData.items.map((procurement) => ({
        purchase_requisition_id: procurement.purchaseRequisitionId,
        quantity: procurement.quantity,
        uom: procurement.uom,
      })),
      cess: formData.cess,
      gst: formData.gst,
      tcs: formData.tcs,
      payment_terms:
        PAYMENT_MODE.find((mode) => mode.value === formData?.paymentTerms?.days)
          ?.name || '',
      quality_check: formData.qualityCheck,
      purchase_type: formData.purchaseType,
      tnc: formData.tnc,
      transportation_mode: formData.transportationMode,
      warehouse_id: formData.warehouseId,
      buyer_id: formData.buyerID,
    };
  };

  useEffect(() => {
    if (!RFQParams.selectedAuction) {
      navigate('/procurement/reverse-auction/reverse-auction-rfq');
    } else {
      const { auction, ...Indentdetails } =
        RFQParams.selectedAuction as Auction;
      setIndentDetails(indentToFormData(Indentdetails));
      setShowButton(
        auction.status === 'CREATED' ||
          auction.status === 'APPROVAL_REMARKS_RESOLVED' ||
          auction.status === 'APPROVAL_REMARKS'
      );
      if (auction) {
        const startDate = moment.unix(auction.startDateTime).startOf('day');
        const startTime = moment
          .unix(auction.startDateTime)
          .diff(startDate, 'seconds');
        const endDate = moment.unix(auction.endDateTime).startOf('day');
        const endTime = moment
          .unix(auction.endDateTime)
          .diff(endDate, 'seconds');

        const payload: AuctionFormData = {
          id: auction.id,
          auctionName: auction.auctionName,
          startDate: moment(startDate).unix(),
          endDate: moment(endDate).unix(),
          startTime: startDate.add(startTime, 'seconds').unix(),
          endTime: endDate.add(endTime, 'seconds').unix(),
          auctionStartPrice: auction.auctionStartAmount,
          auctionReversedPrice: auction.reserveAmount,
          auctionTargetPrice: auction.targetAmount,
          decrementBidAmount: auction.minBidStep,
          bestPrice: auction.showBestPriceToBidders,
          winnerName: auction.showWinnerNameToBidders,
          autoBidAllow: auction.autoBiddingEnabled,
          dynamicTimeExt: auction.dynamicTimeExtensionInSec,
          dynamicTime: Boolean(auction.dynamicTimeExtensionInSec),
          maxAutoBidAllow: auction.autoBiddingEnabled,
          maxAutoBid: auction.minBidStep,
          tnc: auction.tnc,
        };
        setFormData(payload);
      }
    }
  }, []);

  // comment hook
  const { getComments, sendComments, deleteComment, resolveComment } =
    useCommentService();
  const { approveAuction, rejectAuction } = useReverseAuctionService();

  // local comments id counter
  const [commentCounter, setCommentCounter] = useState<number>(1000);

  // comments
  const [comments, setComments] = useState<Comment[]>([]);
  // local comments
  const [localComments, setLocalComments] = useState<
    {
      id: string;
      commentText: string;
      resourceType: string;
      resourceId: string;
    }[]
  >([]);
  // send remarks
  // send all the local comments then redirect.
  const handleSendRemarks = async () => {
    if (auctionRemarks) {
      alert('Pls save comments');
      return;
    }
    if (localComments.length > 0) {
      try {
        const response = await sendComments(localComments);

        if (response?.data?.data) {
          setLocalComments([]);
        }
        navigate('/procurement/reverse-auction/reverse-auction-rfq');
      } catch (e) {
        console.log('ERROR IN THE SEND REMARKS', e);
      }
    } else {
      navigate('/procurement/reverse-auction/reverse-auction-rfq');
    }
  };

  const AddNewCommnet = (comment: string) => {
    if (formData) {
      const newComments = [
        ...localComments,
        {
          id: String(commentCounter),
          commentText: comment,
          resourceType: COMMENT_RESOURCE_TYPE.AUCTION,
          resourceId: formData.id,
        },
      ];
      setCommentCounter((prev) => prev + 1);
      setLocalComments(newComments);
    }
  };
  const fetchRemoteComments = async () => {
    if (formData) {
      try {
        const response = await getComments(
          formData.id,
          COMMENT_RESOURCE_TYPE.AUCTION
        );
        if (response?.data?.data?.length > 0) {
          setComments(response?.data?.data);
        } else {
          setComments([]);
        }
      } catch (e) {
        console.log('FETCH REVIEW API error', e);
      }
    }
  };

  const onRemove = async (id: string, uploaded: boolean) => {
    if (uploaded) {
      const filteredComments = comments.filter((comment) => comment.id !== id);
      try {
        const response = await deleteComment(id);
        if (response.data.data) {
        }
        setComments(filteredComments);
      } catch (e) {
        console.log('ERROR in the DELETE COMMENT error', e);
      }
    } else {
      // local comment delete
      setLocalComments(
        localComments.filter((comment: any) => comment.id !== id)
      );
    }
  };
  const handleResolve = async (id: string) => {
    const commentIndex = comments.findIndex((comment) => comment.id === id);
    if (commentIndex !== -1) {
      try {
        await resolveComment(id);
        const updatedComments = [...comments];
        updatedComments[commentIndex].resolved = true;
        setComments(updatedComments);
      } catch (e) {
        console.log('ERROR in RESOLVE COMMENT', e);
      }
    }
  };

  const saveComments = () => {
    if (auctionRemarks) {
      setAuctionRemarks((prev) => !prev);
    }
  };
  const cancelComments = () => {
    if (auctionRemarks) {
      setAuctionRemarks((prev) => !prev);
    }
  };
  const handleAddRemarks = async () => {
    await fetchRemoteComments();
    setAuctionRemarks((prev) => !prev);
  };

  // reject indent
  const handleReject = async () => {
    if (formData) {
      try {
        const response = await rejectAuction(formData.id);
        if (response.data && response.data.status === 'OK') {
          navigate('/procurement/reverse-auction/reverse-auction-rfq');
        }
      } catch (e) {
        console.log('ERRROR in the handleReject for operation', e);
      }
    }
  };

  const handleApprove = async () => {
    if (formData) {
      try {
        const response = await approveAuction(formData.id);
        if (response.data && response.data.status === 'OK') {
          navigate('/procurement/reverse-auction/reverse-auction-rfq');
        }
      } catch (e) {
        console.log('ERRROR in the handleApprove for operation', e);
      }
    }
  };

  return (
    <div className="grid ">
      <div className="">
        <div className="flex text-blue rounded-md  justify-between items-center text-xl font-semibold py-[10px] ">
          <div className=" flex flex-col">
            <div className="text-xl font-semibold text-blue ">
              {AUCTION_TEXT.reviewAuctionHeading}
            </div>
            <div className="text-xs font-medium text-cgray-1">
              {AUCTION_TEXT.reviewAuctionHeadingSummary}
            </div>
          </div>
        </div>
        <div className="p-6">
          <div className="flex text-blue rounded-md bg-cgray-2 justify-between items-center text-xl font-semibold px-[10px] py-[16px]  mb-4">
            <div>Indent ID : {indentDetails?.id}</div>
            <div className="flex items-center gap-6">
              {`Quantity :  ${
                indentDetails &&
                indentDetails.items
                  .reduce((total, item) => total + item.quantity, 0)
                  ?.toFixed(2)
              } MT`}
              <span
                className="cursor-pointer p-2"
                onClick={() => setShowIndent((prev) => !prev)}
              >
                {showIndent ? (
                  <svg
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 5.41699L5 0.625325L0 5.41699L10 5.41699Z"
                      fill="#5B5B5B"
                    />
                  </svg>
                ) : (
                  <svg
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 0.583008L5 5.37467L10 0.583008H0Z"
                      fill="#5B5B5B"
                    />
                  </svg>
                )}
              </span>
            </div>
          </div>
          {showIndent && (
            <div className="mb-4">
              {indentDetails && <IndentDetails indentDetails={indentDetails} />}
            </div>
          )}
        </div>

        <div className="flex flex-col gap-6 px-6">
          <div className="text-2xl font-semibold text-blue">Action Details</div>
          <div className="border-solid border-b border-cgray-3 flex flex-row justify-between w-full py-1">
            <div
              id="Heading"
              className="text-2xl font-semibold text-text-color"
            >
              Auction T&C
            </div>
            {showButton && (
              <div>
                {auctionRemarks ? (
                  <div className="flex gap-2">
                    <button
                      className="text-sm font-semibold leading-[22px]  text-white bg-blue flex flex-row justify-center px-6 py-3 rounded-md"
                      onClick={saveComments}
                    >
                      Save
                    </button>
                    <button
                      className="text-sm font-semibold leading-[22px]  text-white bg-blue flex flex-row justify-center px-6 py-3 rounded-md"
                      onClick={cancelComments}
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  <button
                    className="text-sm font-semibold leading-[22px]  text-white bg-blue flex flex-row justify-center px-6 py-3 rounded-md"
                    onClick={handleAddRemarks}
                  >
                    Add Remarks
                  </button>
                )}
              </div>
            )}
          </div>
          {/* Remarks Component */}
          {auctionRemarks && formData?.id && (
            <RemarkComponent
              type={'MANAGER'}
              moduleType={'indent'}
              uploadedComments={comments}
              localComments={localComments}
              onResolve={handleResolve}
              onAddNew={AddNewCommnet}
              onRemove={onRemove}
            />
          )}
          {formData && <ReviewAddActionDetails formData={formData} />}
          {/* down Button */}
          {showButton ? (
            <div className="flex justify-end my-4">
              <div className="flex ml-auto gap-3 ">
                <button
                  className="text-sm font-semibold text-text-color  border border-blue flex flex-row justify-center py-3 px-8 rounded"
                  onClick={handleSendRemarks}
                >
                  Send Remarks
                </button>
                <button
                  className="text-sm font-semibold text-badge-warning border border-badge-warning flex flex-row justify-center py-3 px-8 rounded"
                  onClick={handleReject}
                >
                  Reject
                </button>
                <button
                  className="text-sm font-semibold text-white bg-blue flex flex-row justify-center py-3 px-8 rounded"
                  onClick={handleApprove}
                >
                  Approve
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ReviewActionDetails;
