import React from "react";
import { Route, Routes } from "react-router-dom";
import UpdateQuotation from "../../Pages/LeadManagement/LeadManagementSingleTable/UpdateQuotation";
import CreateQuotation from "../../Pages/LeadManagement/LeadManagementSingleTable/CreateQuotation";
import CreateSecondaryQuotation from "../../Pages/LeadManagement/LeadManagementSingleTable/CreateSecondaryQuotation";
import UpdateSecondaryQuotation from "../../Pages/LeadManagement/LeadManagementSingleTable/UpdateSecondaryQuotation";
import LeadList from "../../../components/pages/Lead/LeadList.page";
import LeadView from "../../../components/pages/Lead/LeadView.page";
import LeadQuotationList from "../../../components/pages/Lead/LeadQuotationList.page";
import QuotationView from "../../../components/pages/Lead/QuotationView.page";
import CreateMTOQuotation from "../../Pages/LeadManagement/LeadManagementSingleTable/CreateMTOQuotation";
import UpdateMTOQuotation from "../../Pages/LeadManagement/LeadManagementSingleTable/UpdateMTOQuotation";

const LeadRoutes = () => {
  return (
    <Routes>
      <Route exact path="" element={<LeadList />} />
      <Route exact path="/:id" element={<LeadView />} />
      <Route exact path="/:id/quotation" element={<LeadQuotationList />} />
      <Route exact path="/quotation/:id" element={<QuotationView />} />

      <Route exact path="/:id/quotation/create" element={<CreateQuotation/>} />
      <Route exact path="/:id/quotation/secondary-create" element={<CreateSecondaryQuotation/>} />
      <Route exact path="/quotation/:id/update" element={<UpdateQuotation />} />
      <Route exact path="/quotation/:id/secondary-update" element={<UpdateSecondaryQuotation />} />
      <Route exact path="/:id/quotation/secondary-lot-create" element={<CreateMTOQuotation/>} />
      <Route exact path="/quotation/:id/secondary-lot-update" element={<UpdateMTOQuotation />} />
    </Routes>
  );
};

export default LeadRoutes;

