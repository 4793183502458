
import viewEyeIcon from "./../../Assets/Icons/viewEyeIcon.svg";
import React, { useEffect, useState } from "react";
import cancelIcon from "./../../Assets/Icons/cancelIcon.svg";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import ClickAwayListener from '@mui/material/ClickAwayListener';

export const DisplayDocuments = ({
  documentsData,
  isEdit = false,
  isForm = false,
  deleteDocument,
}) => {
  const [documents, setDocuments] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageDialogOpen, setImageDialogOpen] = useState(false);

  useEffect(() => {
    setDocuments(documentsData);
  }, [documentsData]);

  const handleBackdropClickAway = () => {
    setImageDialogOpen(false);
  };

  const DisplayImage = () => {
    return (
      <Dialog
        open={isImageDialogOpen}
        onClose={() => setImageDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <ClickAwayListener onClickAway={handleBackdropClickAway}>
          <DialogContent>
            <img className="max-w-full max-h-full" alt="" src={selectedImage} />
          </DialogContent>
        </ClickAwayListener>
      </Dialog>
    );
  };

  const handleImageClick = (imagePath) => {
    setSelectedImage(imagePath);
    setImageDialogOpen(true);
  };

  const handleDeleteClick = (index, isForm) => {
    deleteDocument(index, isForm);
  };


  const DocumentItem = ({ index, item, isEdit, isForm }) => {

    
    const [isHovered, setIsHovered] = useState(false);
  
    const handleMouseEnter = () => {
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };
  
    return (
      <div
        className="relative w-32 h-[142px]"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <img
          className="absolute h-full w-full top-0 right-0 bottom-0 left-0 max-w-full overflow-hidden max-h-full object-cover"
          alt=""
          src={!isForm ? item.path : URL.createObjectURL(item)}
        />
        <div
          className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center"
        >
          {isEdit && (
            <div className="absolute top-1 right-1 bg-orange-500 p-1 flex items-center justify-center">
              <button
                className="w-2 h-2 blue"
                type="button"
                onClick={() => handleDeleteClick(index, isForm)}
               >
                <img className="w-2 h-2" alt="" src={cancelIcon} />
              </button>
            </div>
          )}
          {isHovered  && (
            <button
              className="w-7 h-7"
              onClick={() =>
                handleImageClick(!isForm ? item.path : URL.createObjectURL(item))
              }
            >
              <img className="w-7 h-7" alt="" src={viewEyeIcon} />
            </button>
          )}
        </div>
      </div>
    );
  };
  

  return (
    <>
      {documents &&
        documents.map((imageData, index) => (
          <DocumentItem
            key={index}
            index={index}
            item={imageData}
            isEdit={isEdit}
            isForm={isForm}
          />
        ))}
      {isImageDialogOpen && <DisplayImage />}
    </>
  );
};

