// import * as React from 'react';
import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from "@mui/material/FormGroup";
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import * as yup from "yup";
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
// FORMIK CODE 

import DisplayDocumentStandard from '../../../Shared/Files/UploadSection';
import { FilesWrapper } from '../../../Shared/Files/DisplayFiles';
import JoditEditor from 'jodit-react';
import UploadDocumentIndex from '../../../Shared/Files/UploadDocumentsIndex';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import { getErrorMessageByStatusCode } from '../../../../utils/helper';
import { RESOURCES } from '../../../../utils/constant';

const BaseUrl = process.env.REACT_APP_API_V1_URL;

const AddTypeAttribute = () => {
    const { user } = useAuthenticatedUser();
    const navigate = useNavigate()
    const location = useLocation();

    const { type, itemId } = location.state || {};

    const [isActive, setIsActive] = useState(true);



    // Images Uploaded
    const [imageData, setImageData] = useState([]);
    const [formImageData, setFormImageData] = useState([]);
    // const [value, setValue] = React.useState(0);
    // const navigate = useNavigate();

    const displayTitle = (data) => {
        if (data === "ADD") {
            return "Add"
        } else if (data === "VIEW") {
            return "View"
        } else if (data === "EDIT") {
            return "Edit"
        }
    }

    const disableComponent = (data) => {
        if (data === "VIEW") {
            return true
        } else {
            return false
        }
    }

    const displayNewKeyWord = (data) => {
        if (data === "ADD") {
            return "New"
        }
    }

    // new code for formnik
    const errorMessageColor = '#D32F2F'
    const standardInitialValues = {
        name: '',
        upcCheck: false,
        mpcCheck: false,
        description: '',
        status: isActive
    };
    var validateFormikFields = yup.object({
        name: yup.string().typeError("Attribute Type must be a Stringr").required("Attribute Type is required"),
        description: yup.string().typeError("Description must be a string").required("Description is required"),
        upcCheck: yup.boolean(),
        mpcCheck: yup.boolean()
    });


    const standardFormik = useFormik({
        initialValues: standardInitialValues,
        validationSchema: validateFormikFields,
        onSubmit: (values, { setSubmitting, setStatus }) => {
            // Handle form submission here
            setSubmitting(true)
            handleCreate(values);
        },
    });

    const handleSwitchToggle = () => {
        // Invert the current status value and update the form field
        const updatedStatus = !standardFormik.values.status;
        standardFormik.setFieldValue('status', updatedStatus);
    };
    // Form Data
    const [formData, setFormData] = useState({
        name: '',
        upc: '',
        mpc: '',
        // status: '',
    });

    // Form Errors
    const [formErrors, setFormErrors] = useState({
        name: '',
        upc: '',
        mpc: '',
        // status: '',
    });

    useEffect(() => {
        if (type === 'VIEW' || type === 'EDIT') {
            // Call the fetchData function when type or itemId changes
            fetchData();
            fetchDocuments(itemId, "STANDARD_GRADE");
        }
    }, [type, itemId]);


    const fetchData = () => {
        axios.get(`${BaseUrl}/attribute/${itemId}` ,{
            headers: {
               Authorization: `${user.token}`,
               "Content-Type": "application/json",
            }
         }
       )
            .then((response) => {
                const responseData = response.data.data;

                // Set Formik values
                standardFormik.setValues({
                    name: responseData.name || '',
                    upcCheck: responseData.isUpc || false,
                    mpcCheck: responseData.isMpc || false,
                    description: responseData.description || '',
                    status: responseData.status === 'ACTIVE' ? true : false
                });

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    const fetchDocuments = async (resId, resType) => {
        try {
            if (!resId || !resType) {
                return;
            }
            const apiUrl = `${BaseUrl}/files/${resType}/${resId}`;
            const response = await axios.get(apiUrl ,{
                headers: {
                   Authorization: `${user.token}`
                }
             }
           ).then((res) => {
                if (res && res.data && res.data.data) {
                    setImageData(res.data.data);
                }

                return;
            });
        } catch (err) {
            return;
        }
    };



    const handleCatalogueImageData = async (
        imageData,
        resourceId,
        resourceType,
        createdBy
    ) => {

        if (!resourceId || !resourceType) {
            return false;
        }

        const formData = new FormData();

        for (let i = 0; i < imageData.length; i++) {
            formData.append(`files`, imageData[i]);
        }

        const uploadFilesData = {
            resourceType: resourceType,
            resourceId: resourceId,
            createdBy: createdBy,
        };

        formData.append(
            "resource",
            new Blob([JSON.stringify(uploadFilesData)], {
                type: "application/json",
            })
        );

        try {
            await axios
                .post(`${BaseUrl}/files/`, formData ,{
                    headers: {
                       Authorization: `${user.token}`
                    }
                 }
               )
                .then(() => {
                    return true;
                });
            // Handle success
        } catch (error) {
            // Handle error
            return false;
        }
    };


    const handleCreate = async (values) => {
        try {
            // Create an object containing the data you want to send to the API
            const postData = {
                name: values.name,
                attributeType: 'ATTRIBUTETYPE', // Set attributeType to 'ATTRIBUTETYPE'
                isUpc: values.upcCheck,
                isMpc: values.mpcCheck,
                description: values.description,
                status: values.status ? 'ACTIVE' : 'INACTIVE',
            };


            const apiUrl = type === "ADD" ? `${BaseUrl}/attribute` : `${BaseUrl}/attribute/${itemId}`;
            const httpMethod = type === "ADD" ? "POST" : "PUT";

            const response = await axios({
                method: httpMethod,
                url: apiUrl,
                data: postData,
                headers: {
                    Authorization: `${user.token}`,
                    "Content-Type": "application/json",
                  }
            });

            // Check if the response status is 200 (success)
            if (response.status === 200) {
                var resourceId = response.data.data.id;
                var resourceType = "STANDARD_GRADE";
                var createdBy = response.data.data.createdBy;

                if (
                    handleCatalogueImageData(
                        type === "ADD" ? imageData : formImageData,
                        resourceId,
                        resourceType,
                        createdBy
                    )
                ) {
                    navigate("/attribute");
                    return
                }
                // You can perform other actions based on the response, such as showing a success message.
                navigate("/attribute");
            }
        } catch (error) {
            const errorMessage = getErrorMessageByStatusCode(error.response.status, RESOURCES.ATTRIBUTE);
            alert(errorMessage);
        }
    };





    // const editor = useRef(null);
    const attributeApplication = useRef(null);
    const config = useMemo(() => {
        return {
            readonly: disableComponent(type),
            placeholder: 'Start typings...',
        };
    }, []);

    const [content, setContent] = useState('');

    const handleFormData = (key, event) => {
        setFormData((prev) => ({
            ...prev,
            [key]: event.target.value
        }))
    }

    // Image data handler functions
    const updateImages = (images) => {
        // setImageData(images);
        setImageData((prevImages) => [...prevImages, ...images]);
    };

    const handleRemoveImage = (id, isForm) => {
        const updatedDetails = imageData.filter(
            (detail, currentIndex) => currentIndex !== id
        );
        setImageData(updatedDetails);
    };

    const deleteDocumentState = (id, documentData) => {
        const updatedDetails = documentData.filter((_, index) => index !== id);
        return updatedDetails;
    };



    const deleteDocument = async (documentIndex, isForm) => {
        try {

            if (isForm) {
                // If there's no id, delete the document from imageData array
                setFormImageData(deleteDocumentState(documentIndex, formImageData));
            } else {

                const documentId = imageData[documentIndex].id;
                const apiUrl = `${BaseUrl}/files/${documentId}`;
                const response = await axios.delete(apiUrl ,{
                    headers: {
                       Authorization: `${user.token}`
                    }
                 }).then((res) => {
                    setImageData(deleteDocumentState(documentIndex, imageData));
                    return;
                }).Error(() => {
                    return
                });
            }
        } catch (err) {
            return
        }
    };

    const updateFormImages = (images) => {
        setFormImageData((prevImages) => [...prevImages, ...images]);
    };



    return (
        <form onSubmit={standardFormik.handleSubmit}>
            <Box className='common-pop-up'>
                <div className="common-pop-up-wrapper">
                    <div className="new-manufacture-header">
                        <h3>Add New Attribute Type</h3>
                        <CloseIcon className='close-icon' onClick={() => { navigate("/attribute") }} />
                    </div>
                </div>
                <Box>
                    <div className="manufacture-status-wrapper">

                        <div className="options-main pop-inputs">
                            <TextField sx={{ width: '100%' }}
                                className={`block w-full p-2 border border-gray-300 rounded-md focus:outline-none ring-blue-500 focus:border-blue-500`}
                                id='outlined-basic'
                                label='Enter Attribute Type'
                                variant='outlined'
                                fullWidth
                                name='name'
                                value={standardFormik.values.name}
                                onChange={standardFormik.handleChange}
                                onBlur={standardFormik.handleBlur}
                                error={standardFormik.touched.name && Boolean(standardFormik.errors.name)}
                                InputProps={{
                                    readOnly: disableComponent(type),
                                }}

                            />
                        </div>
                        <div className="attribute-options">
                            <div className="attribute-content">
                                <p>Do you need this attribute in UPC?</p>
                                <FormControlLabel
                                    control={<Checkbox
                                        // name='upcCheck'
                                        // checked={standardFormik.values.upcCheck}
                                        // onChange={standardFormik.handleChange}
                                        // onBlur={standardFormik.handleBlur}
                                        // disabled={disableComponent(type)}

                                        {...standardFormik.getFieldProps('upcCheck')}
                                        checked={standardFormik.values.upcCheck}
                                        color="primary"
                                        disabled={disableComponent(type)}


                                    />} label="Yes"
                                    // id='status-select'
                                    // name='upcCheck'
                                    // value={standardFormik.values.upcCheck}
                                    // onChange={standardFormik.handleChange}
                                    // onBlur={standardFormik.handleBlur}
                                    error={standardFormik.touched.upcCheck && Boolean(standardFormik.errors.upcCheck)}

                                // disabled={disableComponent(type)}
                                />
                            </div>
                            <div className="attribute-content">
                                <p>Do you need this attribute in MPC?</p>
                                <FormControlLabel control={<Checkbox

                                    // checked={standardFormik.values.mpcCheck}
                                    // onChange={standardFormik.handleChange}
                                    // onBlur={standardFormik.handleBlur}
                                    // disabled={disableComponent(type)}

                                    {...standardFormik.getFieldProps('mpcCheck')}
                                    checked={standardFormik.values.mpcCheck}
                                    color="primary"
                                    disabled={disableComponent(type)}



                                />} label="Yes"
                                // id='status-select'
                                // name='mpcCheck'
                                // value={standardFormik.values.mpcCheck}
                                // onChange={standardFormik.handleChange}
                                // onBlur={standardFormik.handleBlur}
                                // error={standardFormik.touched.mpcCheck && Boolean(standardFormik.errors.mpcCheck)}
                                // disabled={disableComponent(type)}
                                />
                            </div>
                        </div>

                        <div className="">
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={standardFormik.values.status}
                                            color="warning"
                                            onChange={handleSwitchToggle}
                                            disabled={disableComponent(type)}
                                        />
                                    }
                                    label="Status"
                                />
                            </FormGroup>
                            Status is Now {standardFormik.values.status ? "Active" : "Inactive"}. If u want Inactive
                            use Toggle
                        </div>


                        <FormControl fullWidth>
                            <JoditEditor
                                ref={attributeApplication}
                                value={standardFormik.values.description}
                                config={config}
                                onBlur={(newContent) => standardFormik.setFieldValue('description', newContent)}
                            />

                            {/* Display validation errors */}
                            {standardFormik.errors.description && standardFormik.touched.description && (
                                <div style={{ color: errorMessageColor }}>{standardFormik.errors.description}</div>
                            )}
                        </FormControl>



                        <FilesWrapper heading={"Upload Icon"}>

                            <DisplayDocumentStandard
                                mode={type}
                                imageData={imageData}
                                formImageData={formImageData}
                                deleteDocumentHandler={deleteDocument}
                                handleRemoveImage={handleRemoveImage}
                            />

                        </FilesWrapper>

                        {type !== "VIEW" ? (<UploadDocumentIndex displayTitle={'Upload Icon'} updateImages={type === "ADD" ? updateImages : updateFormImages} />) : null}



                    </div>
                </Box>
                <div className="page-btn-wrapper">
                    <div className="page-btn-1">
                        <Button variant="outlined" className='cancle-btn p-btn' onClick={() => { navigate("/attribute") }}>Cancel</Button>
                    </div>
                    {type !== 'VIEW' && (
                        <div className="page-btn-2">
                            <Button variant="contained" className='next-btn p-btn' type='submit'>

                                {type === 'ADD' ? 'Add Attribute Type' : 'Update Attribute Type'}

                            </Button>
                        </div>)}
                </div>
            </Box>
        </form>
    )
}

export default AddTypeAttribute;