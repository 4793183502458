import { LiveAuctionProductCard } from '../LiveAuctionProductCard';
import { AuctionProductList } from '../../../../Constants/Procurement/LiveAuction/constants';

interface Props {
  productList: AuctionProductList[];
}

const ProductModal = ({ productList }: Props) => {
  return (
    <div className=" h-[98%] py-8">
      <div className="h-full overflow-y-auto flex flex-col gap-y-4 px-6 pb-6 scrollbar">
        {productList.map((product, i) => (
          <LiveAuctionProductCard key={i} product={product} />
        ))}
      </div>
    </div>
  );
};

export default ProductModal;
