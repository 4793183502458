import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Tabs, { ITabsSchema } from '../../molecules/Tabs/Tabs';
import { createUseStyles } from 'react-jss';
import { TAB_MAPPING_MASTER_UPC } from '../../../utils/constant';
import TabsV2 from '../../molecules/TabsV2/TabsV2';
import MasterUpcOverviewTemplate, { IMasterUpcs } from '../../templates/MasterUPCListing/MasterUpcOverview.template';
import MasterPriceManagementTemplate from '../../templates/MasterUPCListing/MasterPriceManagement.template';
import MasterCustomerWithUpcTemplate from '../../templates/MasterUPCListing/MasterCustomerWithUpc.template';
import MasterVendorWithUpcTemplate from '../../templates/MasterUPCListing/MasterVendorWithUpc.template';
import MasterPurchaseOrderTemplate from '../../templates/MasterUPCListing/MasterPurchaseOrder.template';
import MasterRelatedUpcTemplate from '../../templates/MasterUPCListing/MasterRelatedUpc.template';
import MasterOtherTemplate from '../../templates/MasterUPCListing/MasterOther.template';
import MasterInventoryTemplate from '../../templates/MasterUPCListing/MasterInventory.template';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { IMasterUpcDetailsRequestBody, useMasterUpcService } from '../../../services/useMasterUpcService';
import { HTTP_STATUS } from '../../../utils/types';

const useStyles = createUseStyles((theme: any) => ({
    upcCodeContainer: {
        color: theme.palette.textV2.primary.primary900,
    },
}));

const MasterUpcListingPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const upcId = id ? parseInt(id, 10) : NaN;
    const initialTab = searchParams.get('tab') || TAB_MAPPING_MASTER_UPC[location.state?.tabName as TabType] || TAB_MAPPING_MASTER_UPC["UPC_OVERVIEW" as TabType];

    const [upcCode, setUpcCode] = useState("");
    const [upcTitle, setUpcTitle] = useState("");
    const [activeTab, setActiveTab] = useState(initialTab);
    const classes = useStyles();
    const { user } = useAuthenticatedUser();
    const [masterUpc, setMasterUpcTo] = useState<IMasterUpcs>();
    const masterUpcService = useMasterUpcService();

    const fetchUpc = async () => {
        if (id) {
            try {
                let params: IMasterUpcDetailsRequestBody = {
                    upcTypes: "MASTER_UPC",
                }
                const upcResponse = await masterUpcService.getProductUpcById(upcId, params);
                if (upcResponse?.status === HTTP_STATUS.OK) {
                    setMasterUpcTo(upcResponse?.data?.data);
                    setUpcCode(upcResponse?.data?.data?.upcCode);
                    setUpcTitle(upcResponse?.data?.data?.upcTitle)
                }
                else {
                    throw new Error("UPC fetch failed");
                }
            } catch (error) {
                console.error("error", `UPC fetch failed`)
            }
        }
    }

    useEffect(() => {
        fetchUpc()
    }, [id]);


    const schema: ITabsSchema[] = [
        { label: 'UPC Overview', component: <MasterUpcOverviewTemplate id={upcId} masterUpc={masterUpc}/> },
        { label: 'Price Management', component: <MasterPriceManagementTemplate /> },
        { label: 'Customer with UPC', component: <MasterCustomerWithUpcTemplate id={upcId} customerId={user?.id} /> },
        { label: 'Vendor with UPC', component: <MasterVendorWithUpcTemplate /> },
        { label: 'Purchase Orders', component: <MasterPurchaseOrderTemplate /> },
        { label: 'Related UPC', component: <MasterRelatedUpcTemplate id={upcId} upcCode={upcCode} upcTitle={upcTitle} /> },
        { label: 'Other', component: <MasterOtherTemplate id={upcId} /> },
        { label: 'Inventory', component: <MasterInventoryTemplate id={upcId} /> },
    ];

    type TabType = keyof typeof TAB_MAPPING_MASTER_UPC;

    const handleTabChange = (tab: string | number) => {
        setActiveTab(tab.toString());
        navigate(`?tab=${tab.toString()}`);
    };

    useEffect(() => {
        const currentTab = searchParams.get('tab') || initialTab;
        setActiveTab(currentTab);
    }, [searchParams, initialTab]);

    return (
        <div>
            <div className={`${classes.upcCodeContainer} text-2xl font-medium mt-10 mb-8`}>Master UPC: {upcCode}</div>
            <div>
                <TabsV2 schema={schema} value={activeTab} setValue={handleTabChange} />
            </div>
        </div>
    );
};

export default MasterUpcListingPage;
