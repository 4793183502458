import { request } from ".";
import { AutoCompleteOption } from "../components/molecules/AutoCompleteInput/AutoCompleteInput";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export const CATEGORY_URLS = {
  GET_ALL_CATEGORY: "/categories",
  GET_ALL_CATEGORY_BY_IDS: "/categories-by-ids",
  GET_CATEGORY: "/category/:id",
  CREATE_CATEGORY: "/category",
  UPDATE_CATEGORY: "/category/:id"
};

export interface ICategoryFilterRequest{
  name?: string;
  level: number;
  parentId?: number | string;
  status?: string;
  page: number;
  size: number;
  sort?: string;
  id?: number | null;
}

export interface AttributeSpecification {
  id: number;
  name: string;
  attributeType: string;
  description: string;
  status: string;
  imageId: number | null;
  createdBy: string; 
  updatedBy: string; 
  uom: string | null;
  fieldType: string;
  isUpc: boolean;
  isMpc: boolean;
  attributeOptions: string | string[]; 
  parentId: number | null;
  attributeTypeName: any; 
  createdAt: string;
  updatedAt: string;
}

export interface Category {
  id: number;
  name: string;
  status: string;
  description: string;
  prefix: string;
  level: number;
  imageId: number;
  createdBy: string; 
  updatedBy: string; 
  qualityGrades: number []; 
  standards: number[];
  parentId: number;
  createdAt: string;
  updatedAt: string;
  attributeDetails: {
    SPECIFICATION: AttributeSpecification[];
    GENERALIZATION: AttributeSpecification[];
  };
  parentCategory: Category;
  superParent: Category;
  superParentCategory: number;
  images: any; 
  standardDetails: Standards[]
  customization: string[]
}

export interface Standards {
  id: number;
  name: string;
  prefix: string;
  yearInUse: number;
  yearLastUpdated: number;
  hsnCodes: string;
  bisHeading: string;
  equivalentTags: string;
  status: string;
  createdBy: string | null; 
  updatedBy: string | null;
  application: string;
  features: string;
  description: string;
  createdAt: string; 
  updatedAt: string;
}

export interface CategoryPayload {
  name: string;
  status: string;
  description: string;
  prefix: string | null;
  level: number;
  imageId?: number; 
  qualityGrades?: number []; 
  standards?: number[];
  standardOptions?: AutoCompleteOption[] | null;
  parentId: number | null;
  superParentId?: number | null;
  attributes?: {
    SPECIFICATION?: AttributeSpecification[];
    GENERALIZATION?: AttributeSpecification[];
  };
  parentCategory?: AutoCompleteOption | null;
  superParentCategory?: AutoCompleteOption | null;
  images?: any; 
  customization?: string[];
}

export const useCategoryService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();
  const getAllCategories = (params: ICategoryFilterRequest) => {
    return request(API_METHOD.GET, CATEGORY_URLS.GET_ALL_CATEGORY, authenticatedUser, null, { params } );
  };

  const getCategoryById = (id: number) => {
    return request(API_METHOD.GET, replaceUrlParams( CATEGORY_URLS.GET_CATEGORY, { id } ), authenticatedUser);
  };

  const createCategory = (data: CategoryPayload) => {
    return request(API_METHOD.POST, CATEGORY_URLS.CREATE_CATEGORY, authenticatedUser, data);
  };

  const updateCategory = (id: number, data: CategoryPayload) => {
    return request(API_METHOD.PUT, replaceUrlParams( CATEGORY_URLS.UPDATE_CATEGORY, { id } ), authenticatedUser, data);
  };

  const getAllCategoriesByIds = (ids: number[]) => {
    return request(API_METHOD.POST, CATEGORY_URLS.GET_ALL_CATEGORY_BY_IDS, authenticatedUser, ids);
  };

  return {
    getAllCategories,
    getCategoryById,
    createCategory,
    updateCategory,
    getAllCategoriesByIds
  };
};
