import { useState, useRef } from 'react';
import Typography from '@mui/material/Typography';
import uploadIcon from './../../Assets/Icons/uploadIcon.svg';

const UploadDocumentIndex = ({ displayTitle='Upload Documents', updateImages }) => {

    const [selectedFiles, setSelectedFiles] = useState([]);
  
    const fileInputRef = useRef(null);
  
    const handleFileSelect = (event) => {
      const newFiles = Array.from(event.target.files);
      // const updatedFiles = [...selectedFiles, ...newFiles];
      setSelectedFiles(newFiles);
      updateImages(newFiles);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
    }
    };
  
    const handleDropDragOver = (event) => {
      event.preventDefault();
      const newFiles = Array.from(event.dataTransfer.files);
      // const updatedFiles = [...selectedFiles, ...newFiles];
      setSelectedFiles(newFiles);
      updateImages(newFiles);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
    }
    };
  
    return (
      <div className='relative w-full flex flex-col gap-3 text-left text-text-color font-roboto my-2'>
        <div className='relative w-full flex flex-row items-center justify-start gap-2 text-left text-2xl text-blue font-roboto'>
          <div className='font-semibold text-base md:text-lg lg:text-xl'>
            {displayTitle}
          </div>
          <Typography
            style={{
              color: '#A9A5A5',
              fontSize: '18px',
              margin: '0px',
            }}
          >
            (Maximum-3)
          </Typography>
        </div>
  
        <div
          className='relative w-full h-[16.13rem] border-[2px] border-dashed border-gainsboro flex items-center justify-center'
          onDrop={handleDropDragOver}
          onDragOver={handleDropDragOver}
        >
          <div className='flex flex-col items-center gap-4'>
            <div className='flex flex-col items-center w-[10.56rem] h-[4.94rem]'>
              <img
                className='w-[2.25rem] h-[2.25rem] overflow-hidden'
                alt=''
                src={uploadIcon}
              />
              <div className='mt-3 text-[0.8rem] tracking-[0.3px] font-medium text-text-color'>
                Drag your files here - there
              </div>
            </div>
            <div className='text-[1rem] tracking-[0.3px]'>or</div>
  
            <div className='flex flex-col items-center rounded bg-blue flex items-center justify-center text-[0.94rem] text-white font-semibold'>
              <input
                type='file'
                multiple
                className='hidden'
                onChange={handleFileSelect}
                id='fileInput'
                ref={fileInputRef}
              />
              <label htmlFor='fileInput' className='cursor-pointer  py-[0.81rem] px-[1.63rem]'>
                Browse
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default UploadDocumentIndex;