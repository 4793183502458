import { FC } from 'react';
import pdfIcon from '../../../Assets/Icons/pdfIcon.svg';
import docIcon from '../../../Assets/Icons/docIcon.svg';
import jpegIcon from '../../../Assets/Icons/jpegIcon.svg';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

interface ViewUploadFilesProps {
  heading?: string;
  disabledUpload?: boolean;
  fileList?: File[];
  removeFile?: (id: number) => void;
}

export const ViewUploadFiles: FC<ViewUploadFilesProps> = ({
  heading,
  disabledUpload = false,
  fileList = [],
  removeFile,
}) => {
  const formatBytes = (bytes: number): string => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${(bytes / Math.pow(k, i)).toFixed(2)} ${sizes[i]}`;
  };

  return (
    <div className={`${fileList.length > 0 ? '' : 'hidden'}`}>
      <div className="mb-3 text-text-color w-full font-semibold text-base mt-4">
        {heading || 'Attachments'}
      </div>
      <div className="flex flex-col gap-3">
        {fileList.map((file, i) => (
          <div
            className="border-solid border-cgray-4 flex flex-row justify-between items-center w-full py-4 px-5 border rounded"
            key={i}
          >
            <div className="flex flex-row justify-center items-center gap-4">
              <div className="bg-white flex flex-row justify-center rounded">
                {file.type === 'application/pdf' ? (
                  <img src={pdfIcon} alt="Pdf Icon" className="w-8" />
                ) : file.type?.startsWith('image/') ? (
                  <img src={jpegIcon} alt="Jpeg Icon" className="w-8" />
                ) : (
                  <img src={docIcon} alt="Doc Icon" className="w-8" />
                )}
              </div>
              <div className="flex flex-row gap-2">
                <div className="text-text-color">{file.name}</div>
                <div className="text-cgray-1">{formatBytes(file.size)}</div>
              </div>
            </div>
            {!disabledUpload && removeFile && (
              <div className="cursor-pointer" onClick={() => removeFile(i)}>
                <CloseOutlinedIcon />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
