import React from 'react'
import { createUseStyles } from 'react-jss';
import { FormikProps } from 'formik';
import { ADMIN_ROUTES, MODE, SERVICE_STATUS_OPTIONS } from '../../../../utils/constant';
import { makeRoute, titleModification } from '../../../../utils/helper';
import ButtonV2 from '../../../atoms/ButtonV2';
import StatusV2 from '../../../atoms/Status/StatusV2';
import { Attribute, SubAttribute } from '../../../../services/useAttributeServiceV2';
import { useNavigate, useParams } from 'react-router-dom';
import { USCPayload } from '../../../../services/useVASMasterCategoryService';
import editIcon from "../../../../assets/icons/editPenWhiteOutlinedIcon.svg"

interface ProductServiceViewTemplateProps {
    mode: string,
    formik: FormikProps<USCPayload>,
    onBack: () => void
}

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
    },
    textColor: {
        color: theme.palette.text.primaryDarkLight
    },
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
    property: {
        color: theme.palette.textV2.tertiary.tertiary700,
    }
}));

const USCViewTemplate: React.FC<ProductServiceViewTemplateProps> = ({ mode, onBack, formik }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const params = useParams();

    const handleActionRoute = (id: number) => () => {
        navigate(makeRoute(ADMIN_ROUTES.VAS_MASTER_USCS_UPDATE, { params: { id: id } }));
    };

    return (
        <form className="grid gap-y-8" >
            <div className='flex justify-between'>
                <div className={`${classes.mainHeading} text-lg font-semibold my-auto`}>{formik.values?.uscCode}</div>
                <ButtonV2 onClick={handleActionRoute(Number(params.id))} variant='primaryContained' label={"Edit Capability"} startIcon={<img src={editIcon} alt= "editIcon"/>} />
            </div>

            <div className='grid gap-y-4'>
                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>USC Details</div>
                    <div className='grid grid-cols-5 gap-x-4'>
                    <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>USC</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.name}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>USC Title</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.name}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Linked Super Service</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.superService?.label}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Linked Main Service</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.mainService?.label}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Linked Service Category</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.productService?.label}</div>
                        </div>
                    </div>
                    <div className="grid gap-y-1">
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Description</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.description}</div>
                        </div>
                    </div>
                </div>

                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Attribute</div>
                    {
                        formik.values?.attributes.map((attribute: Attribute) => (
                            <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`} key={attribute.id}>
                                <div className={`${classes.heading} text-base font-medium`}>{attribute.name}(Min-Max)</div>
                                <div className='grid grid-cols-6 gap-4'>
                                    {
                                        attribute.subAttributes.filter((subAttribute: any) => formik?.values?.subAttributeIds?.hasOwnProperty(subAttribute.id)).map((subAttribute: SubAttribute, index: number) => (
                                            <div className="grid gap-y-1" key={subAttribute.id}>
                                                <div className={`${classes.property}`}>Selection {index + 1}</div>
                                                <div className={`${classes.heading} text-base font-medium`}>
                                                    {subAttribute.minValue}-{subAttribute.maxValue} {attribute.uom}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                        ))}
                </div>
                <StatusV2 heading="Service Status" options={SERVICE_STATUS_OPTIONS} formik={formik} name="status" mode = {mode} />
            </div>

            <div className="flex justify-end gap-4">
                <ButtonV2 variant="tertiaryContained" label='Back' onClick={onBack} />
            </div>
        </form>
    )
}

export default USCViewTemplate