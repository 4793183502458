import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { FormikProps } from 'formik';
import { LOCATION_STATES } from '../../../utils/types';
import { ILocationGstinForm } from '../../pages/Dashboard/Location/BusinessLocation.page';
import ViewBusinessLocationTemplate from './ViewBusinessLocation.template';
import AddBusinessLocationTemplate from './AddBusinessLocation.template';
import GetNumberOfBusinessLocationTemplate from './GetNumberOfBusinessLocation.template';
import AddNewBusinessLocationTemplate from './AddNewBusinessLocation.template';

export interface IBusinessLocationProps {
    formik: FormikProps<ILocationGstinForm>;
}

export interface LocationSectionProps {
    currentSection? :  LOCATION_STATES;
    setCurrentSectionTo: (section: LOCATION_STATES) => void;
    formik: FormikProps<ILocationGstinForm>;
    setButtonShow? : Dispatch<SetStateAction<boolean>>;

}

const BusinessLocationTemplate: React.FC<IBusinessLocationProps> = ({ formik }) => {
    const [currentSection, setCurrentSection] = useState<LOCATION_STATES>(LOCATION_STATES.VIEW_LOCATIONS);

    const locationSectionProps: LocationSectionProps = {
        setCurrentSectionTo: setCurrentSection,
        formik: formik,
        currentSection: currentSection,
    };

    const secondaryInventorySectionView = useMemo(() => {
        switch (currentSection) {
            case LOCATION_STATES.VIEW_LOCATIONS:
                return formik.values?.locations?.length > 0 ? (
                    <ViewBusinessLocationTemplate {...locationSectionProps} />
                ) : (
                    <AddBusinessLocationTemplate {...locationSectionProps} />
                );
            case LOCATION_STATES.ADD_LOCATION_NO:
                return <GetNumberOfBusinessLocationTemplate {...locationSectionProps} />;
            case LOCATION_STATES.ADD_LOCATIONS:
                return <AddNewBusinessLocationTemplate {...locationSectionProps} />;
            default:
                return formik.values?.locations?.length > 0 ? (
                    <ViewBusinessLocationTemplate {...locationSectionProps} />
                ) : (
                    <AddBusinessLocationTemplate {...locationSectionProps} />
                );
        }
    }, [currentSection, formik]);
    return (
        <div className='grid gap-8  justify-items-stretch'>
            {secondaryInventorySectionView}
        </div>
    );
};
export default BusinessLocationTemplate
