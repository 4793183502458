
// import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import SearchUpcIndex from './UpcSearchComponents/SearchUpcIndex';
import DisplaySearchedUpcDetails from './UpcSearchComponents/DisplaySearchedUpcDetails';


import React, { useEffect, useState } from "react";
const searchPayloadDetails = {
  upcId: null,
  upcCode: null,
  superCategory: null,
  mainCategory: null,
  productCategory: null,
  manufacturer: null,
  brand: null,
  standard: null,
  grade: null,
  shape: null,
  classType: null,
  attributes: {
    'SPECIFICATION': [],
    'CLASSIFICATION': [],
    'GENERALIZATION': []
  }
}



export default function UpcDialogModal({ type, open, onClose, updateUpcSearchDetails, isCheckBoxView = false }) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [activeComponent, setActiveComponent] = React.useState(1);
  const [selectedUpcId, setSelectedUpcId] = React.useState(null);
  const [searchPayloadParams, setSearchPayloadParams] = React.useState(searchPayloadDetails);


  useEffect(() => {
    // if (activeComponent === 1) {
    //   setSearchPayloadParams(searchPayloadDetails);
    // }
    handleUpdateSearchDetails('classType', type);
  }, [activeComponent, type]);


  // Function to change the active component
  const switchComponent = (componentNumber) => {
    setActiveComponent(componentNumber);
  };

  const handleUpdateSearchDetails = (key, payload) => {
    setSearchPayloadParams((prev) => ({
      ...prev,
      [key]: payload
    }));
  };


  const handleTagDelete = (data) => {
    setSearchPayloadParams(data)
  }

  const handleClose = () =>{
    setSearchPayloadParams(searchPayloadDetails);
    setActiveComponent(1)
    onClose()
  }

  let activeContent = null;


  // Determine which component to display based on activeComponent state
  if (activeComponent === 1) {
    activeContent = <SearchUpcIndex
      type={type}
      switchComponent={switchComponent}
      onClose={handleClose}
      updateSearchDetails={handleUpdateSearchDetails}
      searchPayload={searchPayloadParams}
    />;
  } else {
    activeContent = <DisplaySearchedUpcDetails
      tagDeleteHandler={handleTagDelete}
      type={type}
      searchPayload={searchPayloadParams}
      switchComponent={switchComponent}
      onClose={handleClose}
      updateUpcSearchDetails={updateUpcSearchDetails}
      isCheckBoxView={isCheckBoxView}
    />;
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        width: '43%',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '43%',
          boxSizing: 'border-box',
        },
      }}

    >
      {activeContent}
    </Drawer>

  );
}

