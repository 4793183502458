import { ReverseAuctionSteps } from '../../../Constants/Procurement/ReverseAuctionRFQ/constants';

const ReverseAuctionStepper = ({ step }: { step: number }) => {
  return (
    <div className="w-full  m-auto flex justify-between gap-x-0.5 text-blue text-center  text-sm font-medium leading-normal mb-4">
      <div className="grid gap-y-1 w-1/2">
        <div>{ReverseAuctionSteps[0]}</div>
        <div
          className={`h-3  ${step >= 1 ? 'bg-blue' : 'bg-cgray-5'} rounded-xl`}
        ></div>
      </div>
      <div className="grid gap-y-1 w-1/2">
        <div>{ReverseAuctionSteps[1]}</div>
        <div
          className={`h-3 ${step >= 2 ? 'bg-blue' : 'bg-cgray-5'}  rounded-xl`}
        ></div>
      </div>
    </div>
  );
};

export default ReverseAuctionStepper;
