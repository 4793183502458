import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useSecondaryInventoryService } from '../../../services/useSecondaryInventoryService';
import { formatJoeditData } from '../../../App/Core/Handlers/JoeditDisplayFormatter';
import { createUseStyles } from 'react-jss';
import Button from '../../atoms/Button';
import { ISecondaryInventoryResponse } from '../../pages/SecondaryInventory/SecondaryInventoryUpdate.page';
import { ADMIN_ROUTES, MODE, RESOURCE_TYPE } from '../../../utils/constant';
import ImageUploader, { ImageData } from '../../organisms/ImageUploader';
import { HTTP_STATUS } from '../../../utils/types';
import { useFileService } from '../../../services/useFileService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { getShapeName, showAttributesUom } from '../../../utils/helper';

interface ProductSpec {
  name: string;
  minValue: string;
  maxValue: string;
  uom: string;
}

const useStyles = createUseStyles((theme: any) => ({
  heading: {
    color: theme.palette.text.primaryDark,
    fontFamily: "Roboto",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
  },
  subHeading: {
    color: theme.palette.text.secondaryDark,
    fontFamily: "Roboto",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  values: {
    color: theme.palette.text.secondaryDark,
    fontFamily: "Roboto",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },
}));

const SecondaryInventoryPreviewTemplate = () => {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const secondaryInventoryId = parseInt(params.id ?? '0', 10);
  const fileService = useFileService();
  
  const secondaaryInventoryService = useSecondaryInventoryService();
  const [secondaryInventory, setSecondaryInventoryTo] = useState<ISecondaryInventoryResponse>();
  const [warehouse, setWarehouseTo] = useState<string | null>(null);
  const [gradeName, setGradeName] = useState<string | null>(null);
  const [productCategoryName, setProductCategoryNameTo] = useState();
  const [mainCategoryName, setMainCategoryNameTo] = useState();
  const [superCategoryName, setSuperCategoryNameTo] = useState();
  const [image, setImagesTo] = useState<(ImageData | File)[]>([]);
  const { showSnackbar, SnackBarComponent } = useSnackbar();
  const [productSpecification, setProductSpecification] = useState<ProductSpec[]>([]);


  const handleRedirect = () => {
    navigate(-1);
  };

  const getSecondaryInventoryById = async (secondaryId: number) => {
    try {
      const secondaryInventoryResponse = await  secondaaryInventoryService.getSecondaryInventoryById(secondaryId);
      if(secondaryInventoryResponse.status === HTTP_STATUS.OK){
        setSecondaryInventoryTo(secondaryInventoryResponse?.data?.data);
        getWarehouseById(secondaryInventoryResponse.data.data.warehouseId);
        getGradeById(secondaryInventoryResponse.data.data.gradeId);
        getProductCategory(secondaryInventoryResponse.data.data.productCategoryId);
        setProductSpecification(secondaryInventoryResponse?.data?.data.attributes.productSpecification)
      }
      const fileResponse = await fileService.getFilesById(secondaryId, RESOURCE_TYPE.SECONDARY_INVENTORY);
      (fileResponse.status === HTTP_STATUS.OK) && setImagesTo(fileResponse?.data?.data);
    } catch (error) {
      showSnackbar("error", 'Secondary Inventory fetch failed');
			setImagesTo([]);
    }
  };

  const getWarehouseById = (warehouseId: number) => {
    if (warehouseId) {
      secondaaryInventoryService.getWarehouseById(warehouseId)
        .then((response) => {
          setWarehouseTo(response?.data?.name);
        })
        .catch((error) => {
          console.error("Error fetching warehouse details ", error);
          setWarehouseTo(null);
        });
    }
  };

  const getGradeById = (gradeId: number) => {
    if (gradeId) {
      secondaaryInventoryService.getGradeById(gradeId)
        .then((response) => {
          setGradeName(response?.data?.data?.name);
        })
        .catch((error) => {
          console.error("Error fetching warehouse details ", error);
          setGradeName(null);
        });
    }
  }

  const getProductCategory = (produtCategoryId: number) => {
    if (produtCategoryId) {
      secondaaryInventoryService.getCategoryById(produtCategoryId)
        .then((response) => {
          setProductCategoryNameTo(response.data.data.name);
          getMainCategory(response.data.data.parentId);
        })
        .catch((error) => {
          console.error("Error fetching warehouse details ", error);

        });
    }
  }

  const getMainCategory = (mainCategoryId: number) => {
    if (mainCategoryId) {
      secondaaryInventoryService.getCategoryById(mainCategoryId)
        .then((response) => {
          setMainCategoryNameTo(response.data.data.name);
          getSuperCategory(response.data.data.parentId);
        })
        .catch((error) => {
          console.error("Error fetching warehouse details ", error);
        });
    }
  }

  const getSuperCategory = (superCategoryId: number) => {
    if (superCategoryId) {
      secondaaryInventoryService.getCategoryById(superCategoryId)
        .then((response) => {
          setSuperCategoryNameTo(response.data.data.name);
        })
        .catch((error) => {
          console.error("Error fetching warehouse details ", error);
        });
    }
  }

  useEffect(() => {
    getSecondaryInventoryById(secondaryInventoryId);
  }, [secondaryInventoryId]);

  const imageUploaderComponent = useMemo(() => (
		<ImageUploader
			mode={MODE.VIEW}
			images={image}
		/>
	), [image]);

  return (
    <div className="search-upc-container1  mt-4 m-auto">
      <div className="grid gap-y-3 mx-6 pb-6">
        <div className="search-upc-heading flex justify-between mb-5 py-5">
          <h1 className="text-black">SKU CODE: {secondaryInventory?.skuCode}</h1>
        </div>
       
        <div className="grid gap-y-4">
          <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
            <div className={classes.heading}>Category</div>
            <div className="flex gap-x-6">
              <div className="grid gap-y-1.5">
                <div className={classes.subHeading}>Super Category</div>
                <div className={classes.values}>{superCategoryName}</div>
              </div>
              <div className="grid gap-y-1.5">
                <div className={classes.subHeading}>Main Category</div>
                <div className={classes.values}>{mainCategoryName}</div>
              </div>
              <div className="grid gap-y-1.5">
                <div className={classes.subHeading}>Product Category</div>
                <div className={classes.values}>{productCategoryName}</div>
              </div>
            </div>
          </div>

          <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
            <div className={classes.heading}>Product Standard</div>
            <div className="flex gap-x-6">
              <div className="grid gap-y-1.5">
                <div className={classes.subHeading}>Grade</div>
                <div className={classes.values}>{gradeName}</div>
              </div>
              <div className="grid gap-y-1.5">
                <div className={classes.subHeading}>Shape</div>
                <div className={classes.values}>{secondaryInventory && getShapeName(secondaryInventory.shape)}</div>
              </div>
              <div className="grid gap-y-1.5">
                <div className={classes.subHeading}>Class</div>
                <div className={classes.values}>Secondary</div>
              </div>
            </div>
          </div>

          <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
            <div className={classes.heading}>Product Specification</div>
            <div className="flex gap-x-6">
              {productSpecification.map((spec , index) => (
                <div key={index} className="grid gap-y-1.5 flex flex-col">
                  <div className={classes.subHeading}>{spec.name}</div>
                  <div className={classes.values}>
                            {showAttributesUom(spec.minValue, spec.maxValue  , spec.uom )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
            <div className={classes.heading}>Defect Details</div>
            <div className="flex gap-x-6">
              <div className="grid gap-y-1.5">
                <div className={classes.subHeading}>{secondaryInventory?.defectDetails ? formatJoeditData(secondaryInventory?.defectDetails) : 'No Data Found'}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          <div className="grid grid-cols-2 grid-rows-2 gap-6 my-6 ">
            <div>
              <div className={classes.subHeading}>Warehouse</div>
              <div className={classes.values}>{warehouse}</div>
            </div>
            <div>
              <div className={classes.subHeading}>Warehouse Placement</div>
              <div className={classes.values}>{secondaryInventory?.warehousePlacement}</div>
            </div>

            <div>
              <div className={classes.subHeading}>Net Weight in MT</div>
              <div className={classes.values}>{secondaryInventory?.netWeight}</div>
            </div>
            <div>
              <div className={classes.subHeading}>Gross Weight in MT</div>
              <div className={classes.values}>{secondaryInventory?.grossWeight}</div>
            </div>
            <div>
              <div className={classes.subHeading}>Per MT Price </div>
              <div className={classes.values}>{secondaryInventory?.basePrice}Rs</div>
            </div>
          </div>
          <div className=" px-14 py-14 bg-gray-300">
            <div className={classes.subHeading}>Total Price</div>
            <div className={classes.values}>
              {new Intl.NumberFormat().format(secondaryInventory?.basePrice! * secondaryInventory?.netWeight!)} Rs
            </div>
          </div>
        </div>

        <div>
				{imageUploaderComponent}
			</div>
        <div className="flex justify-end w-full m-auto">
          <Button variant="outlined" label="Cancel" onClick={handleRedirect} />
        </div>

      </div>
    </div>
  )
}
export default SecondaryInventoryPreviewTemplate