import { createUseStyles } from "react-jss";
import customerIcon from "../../../App/Assets/Icons/customerIcon.svg";

const useStyles = createUseStyles((theme: any) => ({
    container: {
        background: "#FFEFE6"
    },
    text: {
        color: "#FD6202"
    }
}));

export interface ICustomerIdProps {
    customerId: number;
    title: string;
}

const CustomerId: React.FC<ICustomerIdProps> = ({ customerId, title }) => {
    const classes = useStyles();
    return (
        <div className={`flex ${classes.container} py-2 px-4 gap-x-2 m-auto rounded-2xl`}>
            <div className="m-auto"><img src={customerIcon} alt={customerIcon} /></div>
            <div className={`${classes.text} font-medium text-base`}>{title}: {customerId}</div>
        </div>
    )
}

export default CustomerId;
