import React, { useEffect, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import ButtonV2 from "../../atoms/ButtonV2";
import TableV2, { ColumnType } from "../../organisms/TableV2";
import moment from "moment";
import { HTTP_STATUS, IPagination } from "../../../utils/types";
import TextFieldV2 from "../../atoms/TextFieldV2";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { ADMIN_ROUTES, businessClassificationOptions, cityOptions, groupTypeOptions, turnOverOptions } from "../../../utils/constant";
import editIcon from "../../../assets/images/editOutlined.svg"
import viewIcon from "../../../assets/images/viewOutlined.svg"
import { makeRoute } from "../../../utils/helper";
import { ICustomerGroupMasterQueryParams, useCustomerGroupMasterService } from "../../../services/useCustomerGroupMasterService";
import { FormControl } from '@mui/material';
import AutoCompleteTextField, { AutoCompleteOption } from '../../molecules/AutoCompleteInput/AutoCompleteInputV2';
import ChipsV2, { ChipItem } from "../../molecules/Chips/ChipsV2";
import { ICustomerGroupMaster, ICutomerGroupSearchFilter } from "../../pages/CustomerGroupMaster/CustomerGroupMasterList.page";
import CustomerGroupMasterViewTemplate from "./CustomerGroupMasterView.template";
import searchOutlinedIcon from "../../../assets/icons/searchOutlinedIcon.svg"
import { useSnackbar } from "../../../hooks/useSnackBar";

interface CustomerGroupMasterListTemplateProps {
	customerGroupMasters: ICustomerGroupMaster[];
	pagination: IPagination;
	handlePaginationChange: any;
	handleRowsPerPageChange: any;
	handleFiltersChange: (event: any) => void;
	cutomerGroupSearchFilters: ICutomerGroupSearchFilter;
	customerGroupMasterFilters: ICustomerGroupMasterQueryParams;
	setCustomerGroupMasterFiltersTo: any
}

interface IFilterForm {
	groupNameArray: any[];
	groupName?: {
		label: string;
		id: string;
	};
	groupTypeArray: any[],
	groupType?: {
		label: string;
		id: string;
	};
	groupOwnerArray: any[];
	groupOwner?: {
		label: string;
		id: string;
	};
	cityArray: any[];
	city?: {
		label: string;
		id: string;
	};
	businessClassificationArray: any[];
	businessClassification?: {
		label: string;
		id: string;
	};
	turnOverArray: any[];
	turnOver?: {
		label: string;
		id: string;
	},
	[key: string]: any;

}

const useStyles = createUseStyles((theme: any) => ({
	container: {
		border: `1px solid #EBEFFA`
	},
	filterContainer: {
		borderTop: `1px solid #EBEFFA`
	},
	filterHeading: {
		color: theme.palette.textV2.tertiary.tertiary700,
	},
	selectLabel: {
		color: theme.palette.textV2.tertiary.tertiary600
	},
	configure: {
		color: theme.palette.textV2.primary.primary500,
	},
	input: {
		"& .MuiInputBase-root": {
			borderRadius: "8px !important",
		},
	},
}));

const CustomerGroupMasterListTemplate: React.FC<CustomerGroupMasterListTemplateProps> = ({ customerGroupMasters, pagination, handlePaginationChange, handleRowsPerPageChange, handleFiltersChange, cutomerGroupSearchFilters, customerGroupMasterFilters, setCustomerGroupMasterFiltersTo }) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const customerGroupService = useCustomerGroupMasterService()
	const [customerGroupData, setCustomerGroupDataTo] = useState<any>()
	const [userData, setUserDataTo] = useState<any>()
    const { showSnackbar, SnackBarComponent } = useSnackbar();

	const filter = [
		{ label: "Group Name", value: "groupName" },
		{ label: "Group Type", value: "groupType" },
		{ label: "Group Owner", value: "groupOwner" },
		{ label: "City", value: "city" },
		{ label: "Business Classification", value: "businessClassification" },
		{ label: "Turn Over", value: "turnOver" },
	]

	const schema = {
		id: "1",
		title: "",
		pagination: {
			total: pagination?.totalRecords,
			currentPage: pagination?.currentPage,
			isVisible: true,
			limit: pagination?.pageSize,
			handleChangePage: handlePaginationChange,
			handleChangeRowsPerPage: handleRowsPerPageChange,
		},
		columns: [
			{ label: "S.No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
			{ label: "Group Code", key: "groupCode", type: "string" as ColumnType, props: { className: '' } },
			{ label: "Group Name", key: "groupName", type: "string" as ColumnType, props: { className: '' } },
			{ label: "Group Owner", key: "groupOwner", type: "number" as ColumnType, props: { className: '' } },
			{ label: "No. Of Customers", key: "customerCount", type: "string" as ColumnType, props: { className: '' } },
			{ label: "Main Product Category", key: "productCategoryName", type: "string" as ColumnType, props: { className: '' } },
			{ label: "Created By/ Created Date", key: "created", type: "custom" as ColumnType, props: { className: '' } },
			{ label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } },
		],
	};

	const handleCreate = () => {
		navigate(`${ADMIN_ROUTES.CUSTOMER_GROUP_MASTER_CREATE}`);
	};

	const handleEdit = (id: number) => () => {
		navigate(makeRoute(ADMIN_ROUTES.CUSTOMER_GROUP_MASTER_UPDATE, { params: { id: id } }));
	};

	const [dialogOpen, setDialogOpen] = useState<boolean>(false)
	const [viewId, setViewId] = useState<number | null>(null)

	const handleView = (id: number) => () => {
		setDialogOpen(true)
		setViewId(id)
	}

	const Action = (id: number) => {
		return (
			<div className={`flex items-center justify-center gap-x-3`}>
				<img className="cursor-pointer" src={viewIcon} alt="viewIcon" onClick={handleView(id)} />
				<img className="cursor-pointer" src={editIcon} alt="editIcon" onClick={handleEdit(id)} />
			</div>
		);
	};

	const Created = (creatorName: string, createdAt: string) => {
		return (
			<div className={`grid`}>
				<div>{creatorName}</div>
				<div>{createdAt}</div>
			</div>
		)
	}

	const records = customerGroupMasters?.map((customerGroupMaster: any, index: number) => [
		pagination.currentPage * pagination.pageSize + index + 1,
		customerGroupMaster?.groupCode,
		customerGroupMaster?.groupName,
		customerGroupMaster?.groupOwner,
		customerGroupMaster?.customerCount,
		customerGroupMaster?.productCategoryName,
		Created(customerGroupMaster?.creatorName, moment(customerGroupMaster?.createdAt).format("YYYY-MM-DD HH:mm:ss")),
		Action(customerGroupMaster?.groupId),
	]);

	const [showMoreFilters, setShowMoreFilters] = useState(false);
	const toggleMoreFilters = () => {
		setShowMoreFilters(prevState => !prevState);
	};

	const initialFormValues: IFilterForm = {
		groupNameArray: [],
		groupName: {
			label: "",
			id: "",
		},
		groupTypeArray: [],
		groupType: {
			label: "",
			id: "",
		},
		groupOwnerArray: [],
		groupOwner: {
			label: "",
			id: "",
		},
		cityArray: [],
		city: {
			label: "",
			id: "",
		},
		businessClassificationArray: [],
		businessClassification: {
			label: "",
			id: ""
		},
		turnOverArray: [],
		turnOver: {
			label: "",
			id: ""
		}
	};

	const formik = useFormik<IFilterForm>({
		enableReinitialize: true,
		initialValues: initialFormValues,
		validateOnMount: true,
		onSubmit: async (values, { setSubmitting, resetForm }) => {
			setSubmitting(true);
			const filterRequestBody: ICustomerGroupMasterQueryParams = {
				groupIds: values.groupNameArray.map(group => group.id),
				groupType: values.groupTypeArray.map(group => group.id),
				groupOwner: values.groupOwnerArray.map(group => group.id),
				city: values.cityArray.map(city => city.id),
				businessClassification: values.businessClassificationArray.map(businessClassification => businessClassification.id),
				turnOver: values.turnOverArray.map(turnOver => turnOver.id),
			}
			setCustomerGroupMasterFiltersTo(filterRequestBody)
		},
	});

	const loadGroupName = (inputValue?: string) => {
		customerGroupService.getAllCustomerGroup({ page: 0, size: 10, sort: 'createdAt,desc', groupName: "" }, {})
			.then(res => {
				if (res?.status === HTTP_STATUS.OK)
					setCustomerGroupDataTo(res?.data?.data?.content)
			}).catch((error) => {
				console.error("Error Fetching Category: ", error);
				showSnackbar("error","Error Fetching Category");
			})
	}

	useEffect(() => {
		loadGroupName();
	}, []);

	const loadGroupOwner = (inputValue?: string) => {
		customerGroupService.getAllUser({ page: 0, size: 10, sort: 'createdAt,desc', userType: 'ADMIN' })
			.then(res => {
				if (res.status === HTTP_STATUS.OK)
					setUserDataTo(res?.data?.data?.content)
			}).catch((error) => {
				console.error("Error Fetching Category: ", error);
			})
	}

	useEffect(() => {
		loadGroupOwner();
	}, []);

	const customerGroupOptions = useMemo(() => {
		return customerGroupData?.map((group: any) => ({ label: group.groupName, id: group.groupId })) ?? []
	}, [customerGroupData])


	const userOptions = useMemo(() => {
		return userData?.map((user: any) => ({ label: user.name, id: user.userId })) ?? []
	}, [userData])

	const optionsMap: { [key: string]: any[] } = {
		groupName: customerGroupOptions,
		groupType: groupTypeOptions,
		groupOwner: userOptions,
		city: cityOptions,
		businessClassification: businessClassificationOptions,
		turnOver: turnOverOptions,
	};

	const searchMap: { [key: string]: any } = {
		groupName: loadGroupName,
		groupType: loadGroupName,
		groupOwner: loadGroupOwner,
		city: loadGroupName,
		businessClassification: loadGroupName,
		turnOver: loadGroupName,
	};

	const updateSelected = (key: string) => (option: AutoCompleteOption | null) => {
		if (option) {
			const filterExists = formik.values[`${key}Array`].some((groupId: any) => groupId.id === option?.id);
			if (!filterExists) {
				formik.setFieldValue(`${key}Array`, [...formik.values[`${key}Array`], option]);
			}
		}
		formik.setFieldValue(key, { label: "", id: "" });
	};

	const handleFilterChipDelete = (value: ChipItem, key: string) => {
		formik.setFieldValue(`${key}Array`, formik.values[`${key}Array`].filter((e: any) => e.id !== value.id));
	}

	const handleResetAll = () => {
		formik.resetForm()
	}

	return (

		<div className="grid gap-y-4">
			<div className={`${classes.container} p-6 grid gap-y-5 rounded-lg`}>
				<div className="flex justify-between">
					<div className={`${classes.filterHeading} text-2xl font-medium my-auto`}>Search Group List</div>
					<div>
						<ButtonV2 variant="primaryContained" size="large" label={"+ Create New Group"} onClick={handleCreate} />
					</div>
				</div>

				<form onSubmit={formik.handleSubmit}>
					<div className={`${classes.filterContainer} pt-5 grid gap-y-5`}>
						<div className="grid grid-cols-3 gap-x-4 gap-y-5">
							{
								filter.slice(0, showMoreFilters ? filter.length : 3).map((filter: { value: string, label: string }) => (
									<div className="grid gap-y-2  h-fit">
										<FormControl fullWidth className={classes.input}>
											<AutoCompleteTextField
												options={optionsMap[filter.value]}
												label={`Select ${filter.label}`}
												onChange={updateSelected(filter.value)}
												onSearch={searchMap[filter.value]}
												value={formik.values[filter.value]}
												isDisabled={false}
												placeHolder={filter.label}
											/>
										</FormControl>
										<ChipsV2 values={formik.values[`${filter.value}Array`] ?? []} onDelete={(value) => handleFilterChipDelete(value, filter.value)}
										/>
									</div>
								))
							}
						</div>
						<div className="flex justify-end">
							<div className="flex gap-x-5">
								<ButtonV2 variant="primaryContained" size="large" label={"Search"} type="submit" startIcon={< img src={searchOutlinedIcon} alt="searchIcon" />} />
								<ButtonV2 variant="tertiaryContained" size="large" label={"Reset All"} disabled={!formik.dirty} onClick={handleResetAll} />
								{/* <ButtonV2 variant="tertiaryContained" size="large" label={showMoreFilters ? "Hide Filters" : "More Filters"} onClick={toggleMoreFilters} /> */}
							</div>
						</div>
					</div>
				</form>
			</div>
			<div className={`${classes.container} p-6 grid gap-y-6 rounded-lg`}>
				<div className="flex justify-between">
					<div className={`${classes.filterHeading} text-base font-medium my-auto`}>Group Master List</div>
					<div>
						<TextFieldV2
							className="w-80"
							placeholder="Search by group name"
							variant="outlined"
							type="text"
							name="groupName"
							value={cutomerGroupSearchFilters.groupName}
							onChange={handleFiltersChange}
						/>
					</div>
				</div>
				<div>
					<TableV2 schema={schema} records={records} />
				</div>
			</div>

			{dialogOpen && <CustomerGroupMasterViewTemplate dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} id={viewId} />}
		</div>
	);
};

export default CustomerGroupMasterListTemplate;