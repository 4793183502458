import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup";
import MainServiceFormTemplate from '../../../templates/VASMaster/Categories/MainServiceForm.template';
import { ADMIN_ROUTES, EXCEPTION_CODE, STATUS } from '../../../../utils/constant';
import { makeRoute } from '../../../../utils/helper';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { HTTP_STATUS } from '../../../../utils/types';
import { ServicePayload, useVASMasterCategoryService } from '../../../../services/useVASMasterCategoryService';

interface MainServiceUpdatePageProps {
    mode: string
    service: any
}

const validationSchema = Yup.object().shape({
    name: Yup.string().max(30, "Main Service Name cannot be more than 30 characters").required("Main Service Name is required"),
    description: Yup.string().max(500, "Description cannot be more than 500 characters").required("Description is required"),
    parentId: Yup.number().required("Super Service is required"),
    superService: Yup.object().shape({
        id: Yup.number().required('Super Service is required'),
        label: Yup.string().required('Super Service is required')
    }).required('Super Service is required'),
});

const MainServiceUpdatePage: React.FC<MainServiceUpdatePageProps> = ({ mode, service }) => {
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const vasMasterCategoryService = useVASMasterCategoryService();
    const navigate = useNavigate();
    const params = useParams();

    const serviceId = Number(params.id);

    const onBack = () => {
        navigate(makeRoute(ADMIN_ROUTES.VAS_MASTER_CATEGORIES_LIST, { query: { type: 'MAIN_SERVICE' } }));
    }

    const updateMainService = async (service: ServicePayload) => {
        const servicePayload = {
            name: service.name,
            status: service.status,
            description: service.description,
            parentId: service.parentId,
            level: service.level,
        };
        vasMasterCategoryService.updateCategory(serviceId, servicePayload)
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    showSnackbar('success', "Main Service Updated");
                    onBack();
                } else if (res.data.exceptionCode === EXCEPTION_CODE.DUPLICATE_ENTRY) {
                    showSnackbar('error', "Main Service already exists. Please try again");
                }
            }).catch((error) => {
                showSnackbar('error', "Failed to update Main Service");
            })
    }

    const formik = useFormik<ServicePayload>({
        initialValues: {
            name: service.name ?? "",
            status: service.status ?? STATUS.INACTIVE,
            description: service?.description ?? "",
            parentId: service?.parentId ?? null,
            level: 1,
            superService: { label: service?.ancestors?.parentService?.name, id: service?.ancestors?.parentService?.id },
            serviceCode: service.serviceCode,
        },
        validationSchema,
        onSubmit: async (values) => {
            updateMainService({ ...values, status: values.status })
        }
    })

    return (
        <div>
            {SnackBarComponent}
            <MainServiceFormTemplate mode={mode} onBack={onBack} formik={formik} />
        </div>
    )
}

export default MainServiceUpdatePage