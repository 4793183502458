import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { Select, MenuItem, TextField,  FormControl,
    InputLabel } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ParametersForm from "./ParameterForm";
import LinearProgressLoader from "./LinearProgressLoader";
import { useAuthenticatedUser } from "../../../../../hooks/useAuthenticatedUser";
import { useWhatsappTemplateService } from "../../../../../services/useWhatsappTemplateService";
import { getKeyByValue, makeRoute } from "../../../../../utils/helper";
import { ADMIN_ROUTES, CATALOGUE_TYPES, CLASS_TYPE, LANGUAGE_CODE } from "../../../../../utils/constant";
const BaseUrl = process.env.REACT_APP_API_V1_URL;

const TemplateForm = ({
    selectedCustomers,
    templateData,
    updateTemplateDetails,
    catalogueDetails,
    nextStage,
    updateTemplateUserStatus,
    setLoader,
    loader,
}) => {
    const location = useLocation();
    const { cataloguesIds, categoryId, category, catalogueName, catalogueType } = location?.state ?? {};
    const { user } = useAuthenticatedUser();
    const navigate = useNavigate();

    const whatsappTemplateService = useWhatsappTemplateService();
    const [templates, setTemplatesTo] = useState([]);
    const [template, setTemplate] = useState({
        templateName: "",
        language: "",
        parameterValues: [],
    });
    const [selectedTemplateId, setSelectedTemplateId] = useState("");

    var standardInitialValues = {
        templateName: "",
        catalogueTitle: catalogueName || "",
        noOfParameters: "0",
        languageCode: "en_GB",
        fileName: "",
        parameterData: [],
    };
    var validateFormikFields = yup.object({
        catalogueTitle: yup
            .string()
            .typeError("Catalogue Title is must be a string")
            .required("Catalogue Title is required"),
        fileName: yup
            .string()
            .typeError("File Name is must be a string")
            .required("File Name is required"),
    });

    const standardFormik = useFormik({
        initialValues: standardInitialValues,
        validationSchema: validateFormikFields,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true);
            setLoader(true);
            handleTemplateSharing(values);
        },
    });


    const handleTemplateSharing = async (templateData) => {
        try {
            if (Object.entries(selectedCustomers).length === 0) {
                alert("No Users Selected");
                return;
            }
            const templateRequestBody = {
                catalogueIds: Object.keys(cataloguesIds).map(Number),
                catalogueTitle: templateData.catalogueTitle?.trim(),
                classType: category,
                catalogueType: catalogueType,
                categoryId: categoryId,
                templateName: templateData.templateName?.trim(),
                fileName: templateData.fileName?.trim(),
                languageCode: templateData.languageCode,
                users: Object.keys(selectedCustomers).map(Number),
                parameters: templateData.parameterData.map((parameter) => ({
                    ...parameter,
                    value: parameter.value?.trim(),
                    fallBackValue: parameter.fallBackValue?.trim(),
                })),
            };
            let apiUrl = `${BaseUrl}/catalogue/share`;
            if (category === "SECONDARY") {
                apiUrl = `${BaseUrl}/secondary-catalogue/share`;
            }
            const httpMethod = "POST";
            const response = await axios({
                method: httpMethod,
                url: apiUrl,
                data: templateRequestBody,
                headers: {
                    Authorization: `${user.token}`,
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                updateTemplateUserStatus(response.data.data);
                nextStage(2);
            } else {
                setLoader(false);
                alert("We've encountered a problem sharing on whatsapp - Kindly verify Template Details/Inventory");
                return;
            }
        } catch (error) {
            setLoader(false);
            alert("We've encountered a problem sharing on whatsapp - Kindly verify Template Details/Inventory");
            return;
        }
    };

    const navigateToCatalogueTable = () => {
        navigate(makeRoute(ADMIN_ROUTES.CATALOGUE,{query:{"catalogueType":CATALOGUE_TYPES[catalogueType], "category": CLASS_TYPE[category]}}));
    };

    const handleParameterDataUpdate = (parameters) => {
        standardFormik.setValues({
            ...standardFormik.values,
            parameterData: parameters,
        });
    };

    const fetchTemplates = () => {
        const params = {
            search: "",
            status: "",
            page: "",
            size: 100,
        };
        whatsappTemplateService
            .getTemplates(params)
            .then((templateResponse) => {
                if (templateResponse?.data?.data) {
                    const { content } = templateResponse?.data?.data;

                    setTemplatesTo(content);
                } else {
                    setTemplatesTo([]);
                }
            })
            .catch((error) => {
                setTemplatesTo([]);
                error("error", "Templates fetch failed");
            });
    };

    useEffect(() => {
        fetchTemplates();
    }, []);

    const getLanguageLabel = (code) => {
        const language = LANGUAGE_CODE.find(lang => lang.value === code);
        return language ? language.label : '';
    };   


    const handleTemplateChange = (event) => {
        const templateId = event.target.value;
        setSelectedTemplateId(templateId);
        if (templateId) {
            whatsappTemplateService
                .getTemplateById(templateId)
                .then((templateResponse) => {
                    const {templateName,  language, parameterValues} = templateResponse.data.data;
                    standardFormik.setValues({
                        ...standardFormik.values,
                        templateName:templateName,
                        language: language,
                        noOfParameters: parameterValues.length,
                    });
                    setTemplate(templateResponse?.data?.data);
                })
                .catch((error) => {
                    console.error("Template fetch failed", error);
                });
        }
    };

    return (
        <form onSubmit={standardFormik.handleSubmit}>
            <div className="self-stretch rounded flex flex-col items-start justify-start ">
                <div className="flex-1 flex flex-col items-start justify-start gap-[1rem] m-2 w-1/2">

                <FormControl fullWidth>
                        <InputLabel id='templateName-label'>
                        Select Template
                        </InputLabel>
                    <Select
                        id="templateName-select"
                        labelId="templateName-label"
                        label="Select Template"
                        variant="outlined"
                        fullWidth
                        name="templateName"
                        value={selectedTemplateId}
                        onChange={handleTemplateChange}
                    >
                        {templates.map((template) => (
                            <MenuItem key={template.id} value={template.id}>
                                {template.templateName}
                            </MenuItem>
                        ))}
                    </Select>
                    </FormControl>
                </div>
                <div className="flex flex-col gap-4 m-2 w-1/2">
                    <TextField
                        fullWidth
                        labelId="status-label"
                        id="status-select"
                        label="Language"
                        value={getLanguageLabel(template.language)}
                        onChange={handleTemplateChange}
                        disabled={true}
                    ></TextField>
                </div>
                <div className="flex-1 flex flex-col items-start justify-start gap-[1rem] m-2 w-1/2">
                    <TextField
                        id="fileName"
                        label="Enter Catalogue Title"
                        variant="outlined"
                        fullWidth
                        name="catalogueTitle"
                        value={standardFormik.values.catalogueTitle}
                        onChange={(event) => {
                            const trimmedValue = event.target.value;
                            standardFormik.handleChange({
                                target: { name: "catalogueTitle", value: trimmedValue },
                            });
                        }}
                        onBlur={standardFormik.handleBlur}
                        error={
                            standardFormik.touched.catalogueTitle &&
                            Boolean(standardFormik.errors.catalogueTitle)
                        }
                        disabled={loader}
                    />
                </div>
                <div className="flex-1 flex flex-col items-start justify-start gap-[1rem] m-2 w-1/2">
                    <TextField
                        id="fileName"
                        label="Enter File Name"
                        variant="outlined"
                        fullWidth
                        name="fileName"
                        value={standardFormik.values.fileName}
                        onChange={(event) => {
                            const inputValue = event.target.value.slice(0, 30);
                            standardFormik.handleChange({
                                target: { name: "fileName", value: inputValue },
                            });
                        }}
                        onBlur={standardFormik.handleBlur}
                        error={
                            standardFormik.touched.fileName &&
                            Boolean(standardFormik.errors.fileName)
                        }
                        disabled={loader}
                    />
                </div>
                <div className="flex-1 flex flex-col items-start justify-start gap-[1rem] m-2 w-1/2">
                    <TextField
                        id="noOfParameters"
                        label="Enter number of parameters"
                        variant="outlined"
                        fullWidth
                        name="noOfParameters"
                        value={template.parameterValues.length}
                        onChange={handleTemplateChange}
                        disabled={true}
                    />
                </div>
                {template.parameterValues.length > 0 ? (
                    <div className="flex-1 flex flex-col items-start justify-start gap-[1rem] m-2 w-1/2">
                        <h3 className="font-medium font-semibold m-2">Parameters</h3>
                        <ParametersForm
                            noOfParameters={template.parameterValues.length}
                            parameterData={template.parameterValues}
                            updateParameterData={handleParameterDataUpdate}
                            loader={loader}
                        />
                    </div>
                ) : null}
                <div className="flex-1 flex flex-col items-start justify-start gap-[1rem] mt-3 mb-3 ml-2 w-1/2">
                    {loader && (
                        <LinearProgressLoader title={"Sending Template to Users..."} />
                    )}
                </div>
                <div className="flex justify-end space-x-4 mr-9 mt-2 ml-2">
                    <button
                        type="button"
                        className="rounded bg-white text-gray-700 border border-gray-300  overflow-hidden flex flex-row py-[0.81rem] px-[1.63rem] items-center justify-center text-[0.94rem]"
                        onClick={navigateToCatalogueTable}
                        disabled={loader}
                    >
                        <div className="relative leading-[1.38rem] font-semibold">
                            Cancel
                        </div>
                    </button>
                    <button
                        className={`rounded ${loader ? "bg-green" : "bg-blue"
                            } overflow-hidden flex flex-row py-[0.81rem] px-[1.63rem] items-center justify-center text-[0.94rem] text-white`}
                        type="submit"
                        disabled={loader}
                    >
                        <div className="relative leading-[1.38rem] font-semibold">
                            {loader ? "Sending Template..." : "Send Template"}
                        </div>
                    </button>
                </div>
            </div>
        </form>
    );
};
export default TemplateForm;
