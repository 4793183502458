import { createUseStyles } from "react-jss";
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { useState, useEffect } from "react";
import ImageUploadersvg from '../../../assets/icons/imageUploadersvg.svg';
import ButtonV2 from "../../atoms/ButtonV2";
import ProgressBarTemplate from "./ProgressBar.template";

interface IImageUpdateModalTemplateProps {
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
    onSubmit: (event:any)=> void;
    selectedFile: File|null;
    setSelectedFile: (file: File | null) => void;
}

const useStyles = createUseStyles((theme: any) => ({
    titleText: {
        color: theme.palette.textV2.secondary.secondary800
    },
    ligthText: {
        color: theme.palette.textV2.tertiary.tertiary400
    },
    container: {
        "& .MuiDialog-paper": {
            width: "894px !important",
            maxWidth: "894px !important",
            borderRadius: "16px"
        },
    },
    closeIcon: {
        color: theme.palette.textV2.secondary.secondary800
    },
    headingText: {
        color: theme.palette.text.primaryLight
    },
    dasedBorder: {
        borderColor: theme.palette.textV2.secondary.secondary300
    },
    progressContainer: {
        marginTop: 12
    }
}));

const ImageUploadModalTemplate: React.FC<IImageUpdateModalTemplateProps> = ({ dialogOpen, setDialogOpen, onSubmit, selectedFile, setSelectedFile }) => {
    const classes = useStyles();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isUploading, setIsUploading] = useState(false);
    const [resetProgress, setResetProgress] = useState(false);

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const validTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
            const maxSize = 10 * 1024 * 1024; // 10 MB

            if (!validTypes.includes(file.type)) {
                setErrorMessage('Invalid file type. Only PDF, JPEG, JPG, and PNG are allowed.');
                return;
            }

            if (file.size > maxSize) {
                setErrorMessage('File size exceeds the 10MB limit.');
                return;
            }

            setErrorMessage('');
            setSelectedFile(file);
            setIsUploading(true);

        }
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            const file = event.dataTransfer.files[0];
            const validTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
            const maxSize = 10 * 1024 * 1024; // 10 MB

            if (!validTypes.includes(file.type)) {
                setErrorMessage('Invalid file type. Only PDF, JPEG, JPG, and PNG are allowed.');
                return;
            }

            if (file.size > maxSize) {
                setErrorMessage('File size exceeds the 10MB limit.');
                return;
            }

            setErrorMessage('');
            setSelectedFile(file);
            setIsUploading(true);

        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleFormSubmit = (event: any) => {
        if (selectedFile) {
            onSubmit(event);
        } else {
            setErrorMessage('Please select a file before submitting.');
        }
    };

    return (
        <Dialog fullWidth className={classes.container} PaperProps={{ style: { height: 'auto' } }} open={dialogOpen} onClose={handleDialogClose}>
            <div className="flex mt-12 mx-10 justify-between">
                <p className={`${classes.headingText} self-center text-lg font-medium`}>Upload Certification /Document/Brochure</p>
                <div className="p-2" onClick={handleDialogClose}>
                    <CloseIcon className={classes.closeIcon} />
                </div>
            </div>
            <div className="mt-12 mx-10 grid gap-6">
                <div
                    className={`${classes.dasedBorder} border-2 p-12 justify-items-center rounded-2xl border-dashed grid gap-6`}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                >
                    <div className="relative w-[63.273px] h-[50.182px] flex-shrink-0 rounded-[13.666px] bg-gradient-to-r from-[#93B8FF] to-[#1F4690] backdrop-blur-[4.555464267730713px]">
                        <img
                            src={ImageUploadersvg}
                            alt="imageIcon"
                            className="absolute left-1 top-1 cursor-pointer"
                            onClick={() => document.getElementById('fileInput')?.click()}
                        />
                    </div>
                    <p className={`${classes.titleText} self-center text-2xl font-medium`}>Drag and Drop files here</p>
                    <div className="flex w-full items-center">
                        <div className="flex-grow border-t border-gray-400"></div>
                        <span className="mx-2 text-gray-500">or</span>
                        <div className="flex-grow border-t border-gray-400"></div>
                    </div>
                    <ButtonV2
                        variant={'tertiaryContained'}
                        label={'browse Files'}
                        onClick={() => document.getElementById('fileInput')?.click()}
                    />
                    <p className={`${classes.ligthText} self-center text-base font-normal`}>Support: jpg, jpeg, png, pdf | Maximum File Size is 10 MB.</p>
                    <input
                        type="file"
                        id="fileInput"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                </div>
                <div className={classes.progressContainer}>
                    {selectedFile && (
                        <p className="text-lg font-medium">{selectedFile.name}</p>
                    )}
                    {selectedFile && <ProgressBarTemplate isUploading={isUploading} reset={resetProgress} />}
                </div>
                {errorMessage && <p className="text-red-500">{errorMessage}</p>}
                <div className="flex justify-end gap-2 pb-2">
                    <ButtonV2
                        variant={'tertiaryContained'}
                        label={'Cancel'}
                        onClick={handleDialogClose}
                    />
                    <ButtonV2
                        variant={'primaryContained'}
                        label={'Submit Files'}
                        onClick={handleFormSubmit}
                    />
                </div>
            </div>
        </Dialog>
    );
};

export default ImageUploadModalTemplate;