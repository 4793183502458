import React from 'react'
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useNavigate, useParams } from 'react-router-dom';
import { IStandard } from './StandardList.page';
import { useEffect, useState } from "react";
import { IStandardRequest, useStandardService } from '../../../services/useStandardService';
import { HTTP_STATUS } from '../../../utils/types';
import { ADMIN_ROUTES, MODE, RESOURCE_TYPE } from '../../../utils/constant';
import StandardFormTemplate from '../../templates/Standard/StandardForm.template';
import { useFileService } from '../../../services/useFileService';
import { ImageData } from '../../organisms/ImageUploader';


const StandardUpdatePage: React.FC = () => {

    const params = useParams();
    const standardId = Number(params.id);

    const navigate = useNavigate();
    const standardService = useStandardService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const fileService = useFileService();

    const [image, setImagesTo] = useState<(ImageData | File)[]>([]);
    const [standardDetails, setStandardDetailsTo] = useState<IStandard | null>(null);

    const updateStandardById = async (standardRequestBody: IStandardRequest) => {
        if (standardId) {
            try {
                const standardResponse = await standardService.updateStandard(standardId, standardRequestBody);
                if (standardResponse.status === HTTP_STATUS.OK) {
                    const resourceId: number = standardResponse.data.data.id;
                        const imageResponse =  await fileService.uploadImage(image,resourceId,RESOURCE_TYPE.STANDARD_GRADE);
                        if(image.length>0 && imageResponse){
                            showSnackbar("success", `Image uploaded Success `);
                        }
                    navigate(ADMIN_ROUTES.STANDARD_LIST);
                } else if(standardResponse.status === HTTP_STATUS.BAD_REQUEST){
                    showSnackbar('error', standardResponse.data.statusMessage) 
                }
            } catch (error) {
                showSnackbar('error', 'Standard fetch failed')
            }
        }
    }

    const fetchStandardById = async () => {
        if (standardId) {
            try {
                const standardResponse = await standardService.getStandardById(standardId);
                (standardResponse.status === HTTP_STATUS.OK) && setStandardDetailsTo(standardResponse?.data?.data);
                const fileResponse = await fileService.getFilesById(standardId, RESOURCE_TYPE.STANDARD_GRADE);
                (fileResponse.status === HTTP_STATUS.OK) && setImagesTo(fileResponse?.data?.data);
            } catch (error) {
                showSnackbar("error", 'Standard fetch failed');
                setStandardDetailsTo(null);
                setImagesTo([]);
            }
        }
    }

    useEffect(() => {
        fetchStandardById()
    }, [standardId])

    return (
        <div>
            {SnackBarComponent}
            <StandardFormTemplate standard={standardDetails} onStandardSubmit={updateStandardById} mode={MODE.UPDATE} image={image} setImagesTo={setImagesTo} />
        </div>
    )
}

export default StandardUpdatePage;