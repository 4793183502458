import { FormControl } from '@mui/material';
import React from 'react';
import {
  INDENT_TEXT,
  PURCHASE_TYPE,
  QUALITY_CHECK,
  TRANSPORTATION_MODE,
} from '../../../Constants/Procurement/Indent/constants';
import sanitizeHtml from 'sanitize-html';
import {
  BillToAddress,
  FormData,
  ShipToLocation,
} from './../../../Constants/Procurement/Indent/types';
import CustomTextField from './../../../Shared/CustomInputs/CustomTextField';

interface CommonIndentTnCReviewProps {
  billToAddress: BillToAddress | undefined;
  shipToData: ShipToLocation | undefined;
  formData: FormData;
}

const CommonIndentTnCReview: React.FC<CommonIndentTnCReviewProps> = ({
  billToAddress,
  shipToData,
  formData,
}) => {
  return (
    <div className="">
      {/* Indent T&C */}
      <div className="flex flex-col gap-4">
        <div className="flex flex-row gap-5 md:flex-wrap lg:flex-nowrap p-6">
          {/* Buyer Company Detail */}
          <div className="border border-gray-300 p-4  rounded-md flex flex-col gap-3 bg-white w-full">
            <div className="text-l font-semibold text-text-color mb-2">
              {INDENT_TEXT.PRAddBillToDetailsHeading}
            </div>
            <div className="w-full flex gap-4 my-1">
              <FormControl className="w-full" variant="standard">
                <CustomTextField
                  name="buyerGstin"
                  value={billToAddress?.business?.gstin || ' '}
                  label="GSTIN"
                  disabled={true}
                />
              </FormControl>
            </div>
            <div className="w-full flex gap-4 my-1">
              <FormControl className="w-full" variant="standard">
                <CustomTextField
                  name="buyerCompanyName"
                  value={billToAddress?.business?.name || ' '}
                  label="Buyer's Company Name"
                  disabled={true}
                />
              </FormControl>
            </div>
            <div className="w-full flex gap-4 my-1">
              <FormControl className="w-full" variant="standard">
                <CustomTextField
                  name="buyerAddressLine1"
                  value={billToAddress?.address?.line1 || ' '}
                  label="Address Line 1"
                  disabled={true}
                />
              </FormControl>
            </div>
            <div className="w-full flex gap-4 my-1">
              <FormControl className="w-full" variant="standard">
                <CustomTextField
                  name="buyerAddressLine2"
                  value={billToAddress?.address?.line2 || ' '}
                  label="Address Line 2"
                  disabled={true}
                />
              </FormControl>
            </div>
            <div className="w-full flex gap-4 my-1">
              <FormControl className="w-full">
                <CustomTextField
                  name="buyer_pincode"
                  value={billToAddress?.address?.pincode || ' '}
                  label="Pincode"
                  disabled={true}
                />
              </FormControl>
              <FormControl className="w-full">
                <CustomTextField
                  name="buyer_city"
                  value={billToAddress?.address?.city || ' '}
                  label="City"
                  disabled={true}
                />
              </FormControl>
              <FormControl className="w-full">
                <CustomTextField
                  name="buyer_state"
                  value={billToAddress?.address?.state || ' '}
                  label="State"
                  disabled={true}
                />
              </FormControl>
            </div>
          </div>

          {/* Delivery Location */}

          <div className="border border-gray-300 p-4  rounded-md flex flex-col gap-3 bg-white w-full ">
            <div className="text-l font-semibold text-text-color mb-2">
              {INDENT_TEXT.PRAddShipToDetailsHeading}
            </div>
            <div className="w-full flex gap-4 my-1">
              <FormControl className="w-5/12" variant="standard">
                <CustomTextField
                  name="warehouse_id"
                  value={shipToData?.name || ''}
                  label="Warehouse Code"
                  disabled={true}
                />
              </FormControl>
              <FormControl className="w-full" variant="standard">
                <CustomTextField
                  name="buyer_delivery_gstin"
                  value={shipToData?.gstin || ' '}
                  label="GSTIN"
                  disabled={true}
                />
              </FormControl>
            </div>
            <div className="w-full flex gap-4 my-1">
              <FormControl className="w-full" variant="standard">
                <CustomTextField
                  name="buyer_delivery_warehouse_location"
                  value={shipToData?.name || ' '}
                  label="Consignee Name"
                  disabled={true}
                />
              </FormControl>
            </div>
            <div className="w-full flex gap-4 my-1">
              <FormControl className="w-full" variant="standard">
                <CustomTextField
                  name="buyer_delivery_address_line1"
                  value={shipToData?.address || ' '}
                  label="Address Line 1"
                  disabled={true}
                />
              </FormControl>
            </div>
            <div className="w-full flex gap-4 my-1">
              <FormControl className="w-full" variant="standard">
                <CustomTextField
                  name="buyer_delivery_address_line2"
                  value={shipToData?.address || ' '}
                  label="Address Line 2"
                  disabled={true}
                />
              </FormControl>
            </div>
            <div className="w-full flex gap-4 my-1">
              <FormControl className="w-full">
                <CustomTextField
                  name="buyer_pincode"
                  value={shipToData?.pincode || ' '}
                  label="Pincode"
                  disabled={true}
                />
              </FormControl>
              <FormControl className="w-full">
                <CustomTextField
                  name="buyer_city"
                  value={shipToData?.city?.name || ' '}
                  label="City"
                  disabled={true}
                />
              </FormControl>
              <FormControl className="w-full">
                <CustomTextField
                  name="buyer_state"
                  value={shipToData?.city?.state?.name || ' '}
                  label="State"
                  disabled={true}
                />
              </FormControl>
            </div>
          </div>
        </div>

        <div className="bg-cgray-8 flex flex-col justify-center p-6 gap-6 w-full ">
          <div className="font-semibold  text-text-color mb-6">
            {INDENT_TEXT.PRAddTaxDetailsHeading}
          </div>
          <div className="flex flex-row gap-12 items-stretch text-base">
            <div className="bg-cgray-5 flex flex-col   gap-4 p-4  rounded-md ">
              <div className="flex flex-col gap-1 ">
                <div className="text-text-color">GST applicable</div>
                <div
                  className={
                    formData.gst &&
                    formData.gst.toString().trim() &&
                    parseFloat(formData.gst.toString()) > 0
                      ? 'font-medium text-approved'
                      : 'font-medium text-rejected '
                  }
                >
                  {formData.gst &&
                  formData.gst.toString().trim() &&
                  parseFloat(formData.gst.toString()) > 0
                    ? 'Enable'
                    : 'Disable'}
                </div>
              </div>
              <div className="flex flex-col gap-1  ">
                <div className="text-text-color">GST %</div>
                <div className="font-medium text-text-color">
                  {formData.gst ? `${formData.gst} %` : '--'}
                </div>
              </div>
            </div>
            <div className="bg-cgray-5 flex flex-col   gap-4 p-4  rounded-md">
              <div className="flex flex-col gap-1  ">
                <div className="text-text-color">TCS Applicable</div>
                <div
                  className={
                    formData.tcs &&
                    formData.tcs.toString().trim() &&
                    parseFloat(formData.tcs.toString()) > 0
                      ? 'font-medium text-approved'
                      : 'font-medium  text-rejected '
                  }
                >
                  {formData.tcs &&
                  formData.tcs.toString().trim() &&
                  parseFloat(formData.tcs.toString()) > 0
                    ? 'Enable'
                    : 'Disable'}
                </div>
              </div>
              <div className="flex flex-col gap-1  ">
                <div className="text-text-color">TCS %</div>
                <div className="font-medium text-text-color">
                  {formData.tcs ? `${formData.tcs} %` : '--'}
                </div>
              </div>
            </div>
            <div className="bg-cgray-5 flex flex-col  gap-4  p-4   rounded-md">
              <div className="flex flex-col gap-1  ">
                <div className="text-text-color">Cess Applicable</div>
                <div
                  className={
                    formData.cess &&
                    formData.cess.toString().trim() &&
                    parseFloat(formData.cess.toString()) > 0
                      ? 'font-medium text-approved'
                      : 'font-medium  text-rejected '
                  }
                >
                  {formData.cess &&
                  formData.cess.toString().trim() &&
                  parseFloat(formData.cess.toString()) > 0
                    ? 'Enable'
                    : 'Disable'}
                </div>
              </div>
              <div className="flex flex-col  ">
                <div className="text-text-color">Cess %</div>
                <div className="font-medium text-text-color">
                  {formData.cess ? `${formData.cess} %` : '--'}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-cgray-8 flex flex-col justify-center p-6 gap-6 w-full items-start ">
          <div className="font-semibold  text-text-color">
            {INDENT_TEXT.PRAddPaymentDetailsHeading}
          </div>
          <div className="flex flex-row gap-12  items-start">
            <div className="flex flex-col gap-1 items-start">
              <div className="text-text-color">Payment Terms</div>
              <div className="font-medium text-text-color">
                {formData.payment_terms}
              </div>
            </div>
            <div className="flex flex-col gap-1  items-start">
              <div className="text-text-color">Transportation Mode</div>
              <div className="font-medium text-text-color">
                {
                  TRANSPORTATION_MODE.find(
                    (mode) => mode.value === formData.transportation_mode
                  )?.name
                }
              </div>
            </div>
          </div>
        </div>

        <div className="bg-cgray-8 flex flex-col gap-3 w-full  items-start p-6 ">
          <div className="text-lg font-semibold  text-text-color">
            {INDENT_TEXT.PRAddQcDetailsHeading}
          </div>
          <div className="font-medium text-text-color">
            {
              QUALITY_CHECK.find(
                (mode) => mode.value === formData.quality_check
              )?.name
            }
          </div>
        </div>
        <div className="bg-cgray-8 flex flex-col gap-3 w-full  items-start p-6 ">
          <div className="text-lg font-semibold  text-text-color">
            {INDENT_TEXT.PRAddRecommendedPurchaseTypeHeading}
          </div>
          <div className="font-medium text-text-color capitalize">
            {
              PURCHASE_TYPE.find(
                (type) => type.value === formData?.purchase_type
              )?.name
            }
          </div>
        </div>

        <div className="bg-cgray-8 flex flex-col gap-4 w-full p-6 my-4">
          <div className="text-lg font-semibold  text-text-color">
            {INDENT_TEXT.PRAddTnCHeading}
          </div>
          <div
            className="font-medium text-text-color w-full"
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(formData.tnc || ''),
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default CommonIndentTnCReview;
