import React, { ReactNode } from 'react'

interface USCFormTemplateProps {
    mode: string,
    onClose: () => void,
    component: ReactNode
}

const USCFormTemplate: React.FC<USCFormTemplateProps> = ({ component, mode, onClose }) => {
    return (
        <div>{component}</div>
    )
}

export default USCFormTemplate