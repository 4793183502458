import React, { useEffect, useState } from 'react'
import ButtonV2 from '../../atoms/ButtonV2';
import CustomerListTemplate from '../../templates/Customer-Management/CustomerList.template';
import { IPagination } from '../../../utils/types';
import { ADMIN_ROUTES, initialPaginationValues } from '../../../utils/constant';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import activeCustomers from '../../../assets/images/activeCustomers.svg'
import inactiveCustomers from '../../../assets/images/inactiveCustomers.svg'
import totalCustomers from '../../../assets/images/totalCustomers.svg'
import newCustomers from '../../../assets/images/newCustomers.svg'
import  { Dayjs } from 'dayjs';
import StatusCard from '../../atoms/Cards/StatusCard';
import { useNavigate } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import GenericDateRangePicker from '../../molecules/DatePicker/CustomizedDatePicker';

const useStyles = createUseStyles((theme: any) => ({
  container: {
      borderBottom: `1px solid ${theme.palette.border.tritiaryLight}`
  },
  text: {
    color: theme.palette.textV2.primary.primary900
  }
}));

export interface ICustomerTemplate {
  id: number;
  customerID: string;
  companyName: string;
  customerType: string;
  location: string;
  relationshipManager: {
    fullName: string;
    designation: string | null;
    department: string | null;
}[] | [];
  createdBy: string;
  status: string;
}

export interface ICustomerFilter {
  search: string;
  sort: string;
  startDate: string;
  endDate: string;
  customerType: string;
}

const initialCustomerFilterValues: ICustomerFilter = {
  search: "",
  sort: "createdAt,desc",
  startDate: '',
  endDate: '',
  customerType: ''
}

interface ICustomerCounts {
  totalUsers: number,
  activeUsers: number,
  inActiveUsers: number,
  newUsers: number,
}

const CustomerListingPage: React.FC = () => {

  const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);
  const [customers, setCustomersTo] = useState<ICustomerTemplate[]>([]);
  const [customerFilters, setCustomerFiltersTo] = useState<ICustomerFilter>(initialCustomerFilterValues);
  const { showSnackbar, SnackBarComponent } = useSnackbar();

  const businessProfileService = useBusinessProfileService();
  const navigate = useNavigate();
  const classes = useStyles();

  const [customerCount, setCustomerCountTo] = useState<ICustomerCounts>({
    totalUsers: 0,
    activeUsers: 0,
    inActiveUsers: 0,
    newUsers: 0,
});

const customerStatus = () => {
  const params = {
    startDate:customerFilters?.startDate,
    endDate: customerFilters?.endDate
  }
  businessProfileService.getCustomerStatus(params) 
  .then((statusResponse) => {
    if (statusResponse?.data?.data) {     
      setCustomerCountTo(statusResponse?.data?.data)
    }
    else {
    setCustomerCountTo(customerCount);
  }
  })
  .catch((error) => {
    setCustomerCountTo(customerCount);
    showSnackbar('error', 'Users fetch failed')
  });
}

  const fetchProfile = () => {

    const params = {
      search: customerFilters?.search.trim(),
      page: pagination.currentPage,
      size: pagination.pageSize,
      customerType: customerFilters?.customerType,
      sort: customerFilters?.sort,
      startDate:customerFilters?.startDate,
      endDate: customerFilters?.endDate
    }
    businessProfileService.getAllCustomers(params)
      .then((customerResponse) => {
                if (customerResponse?.data?.data) {
          const { totalElements, totalPages, content } = customerResponse?.data?.data;
          setPaginationTo({
            ...pagination,
            totalPages: totalPages,
            totalRecords: totalElements
          });
          setCustomersTo(content)
        }
        else {
          setCustomersTo([]);
        }
      })
      .catch((error) => {
        setCustomersTo([]);
        showSnackbar('error', 'Users fetch failed')
      });
  }

  useEffect(() => {
    fetchProfile()
  }, [pagination.currentPage, pagination.pageSize, customerFilters.search, customerFilters.sort, customerFilters.startDate,customerFilters.endDate])

  useEffect(() => {
    customerStatus()
  }, [ customerFilters.startDate,customerFilters.endDate])


  const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPaginationTo((prevPagination) => ({
      ...prevPagination,
      currentPage: newPage
    }));
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setPaginationTo((prevPagination) => ({
      ...prevPagination,
      pageSize: newRowsPerPage
    }));
  };

  const handleFiltersChange = (event: any) => {
    const { name, value } = event.target;
    setPaginationTo({ ...pagination, currentPage: 0 })
        setCustomerFiltersTo({ ...customerFilters, [name]: value ?? "" });
}

const handleDataFilter = (startDate: Dayjs, endDate: Dayjs) => {
  setCustomerFiltersTo((prevFilters: any) => ({
    ...prevFilters,
    startDate: startDate.format('YYYY-MM-DD'),
    endDate: endDate.format('YYYY-MM-DD'),
  }));
};

const handleAddNewCustomers = () => {
  navigate(ADMIN_ROUTES.CUSTOMER_MANAGEMENT_CREATE)
}

const customStats = [
  {
      icon: totalCustomers ,
      title: "Total Customers",
      value:  customerCount.totalUsers ? customerCount.totalUsers : 0,
  },
  {
    icon: activeCustomers ,
    title: "Active Customers",
    value:  customerCount.activeUsers ? customerCount.activeUsers : 0,
},
 {
  icon: inactiveCustomers ,
  title: "Inactive Customers",
  value:  customerCount.inActiveUsers ? customerCount.inActiveUsers : 0,
},
 {
  icon: newCustomers ,
  title: "New Customers",
  value:  customerCount.newUsers ? customerCount.newUsers : 0,
},
];

  return (
    <>
      <div className={`flex justify-between py-8 items-center ${classes.container}`}>
        <span className={`font-medium text-2xl	${classes.text}`}>Customer Management</span>
        <ButtonV2
          label="+ Add New Customer"
          variant='primaryContained' 
          onClick={handleAddNewCustomers}
          />
      </div>
      <div className='py-8'>
      <GenericDateRangePicker
        label=""
        onDataFilter={handleDataFilter}
      />
    </div>
    <div className='grid'>
    <StatusCard cards = { customStats } />
    </div>
      <CustomerListTemplate customerTemplate={customers} pagination={pagination} handleFiltersChange={handleFiltersChange} handleRowsPerPageChange={handleRowsPerPageChange} handlePaginationChange={handlePaginationChange} customerFilter={customerFilters} />
    </>
  )
}
export default CustomerListingPage;