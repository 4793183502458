import React, { useEffect, useMemo, useState } from 'react'
import { IPagination } from '../../../utils/types';
import { ICustomerFilter, ICustomerTemplate } from '../../pages/CustomerManagement/CustomerListing.page';
import { useNavigate } from 'react-router-dom';
import TableV2, { ColumnType } from '../../organisms/TableV2';
import viewEyeIcon from '../../../assets/icons/viewOutlinedIcon.svg'
import { enumToString, makeRoute } from '../../../utils/helper';
import { ADMIN_ROUTES, CUSTOMER_MANAGEMENT_TABS } from '../../../utils/constant';
import TextFieldV2 from '../../atoms/TextFieldV2';
import { InputAdornment } from '@mui/material';
import search from '../../../assets/images/search.svg'
import ResourceStatusV2 from '../../atoms/ResourceStatus/ResourceStatusV2';
import { createUseStyles } from 'react-jss';
import ViewGroupNameModalTemplate from './ViewGroupNameModal.template';

interface ICustomerListProps {
    customerTemplate: ICustomerTemplate[];
    pagination: IPagination;
    handleFiltersChange?: any;
    handlePaginationChange: any;
    handleRowsPerPageChange: any;
    customerFilter: ICustomerFilter;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        borderTop: `1px solid ${theme.palette.border.tritiaryLight}`
    },
    customerCount: {
        borderRadius: '17px',
        background: theme.palette.textV2.failure.failure500,
        padding: '2px 6px'
    }
}));

const CustomerListTemplate: React.FC<ICustomerListProps> = ({ customerTemplate, pagination, handleFiltersChange, handlePaginationChange, handleRowsPerPageChange, customerFilter }) => {

    const navigate = useNavigate();
    const classes = useStyles();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [viewId, setViewId] = useState<number | null>(null)
    const [customerGroups, setCustomerGroups] = useState<string>('');


    const handleOpenModal = (content: string, id: number) => {
        setCustomerGroups(content)
        setIsModalOpen(true);
        setViewId(id)
    };

    useEffect(() => {
        if (viewId != null) {
            handleOpenModal(customerGroups, viewId)
        }
    }, [viewId, customerGroups])

    const schema = {
        id: 'template-list',
        pagination: {
            total: pagination.totalRecords,
            currentPage: pagination.currentPage,
            isVisible: true,
            limit: pagination.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange
        },
        columns: [

            { label: "Sr No.", key: "id", type: "number" as ColumnType, props: {} },
            { label: "Customer ID", key: "CustomerID", type: "string" as ColumnType, props: {} },
            { label: "Company Name", key: "companyName", type: "string" as ColumnType, props: {} },
            { label: "Group Name", key: "customerType", type: "string" as ColumnType, props: {} },
            { label: "Location", key: "location", type: "string" as ColumnType, props: {} },
            { label: "Relationship Manager", key: "relationshipManager", type: "string" as ColumnType, props: {} },
            { label: "Created By", key: "createdBy", type: "number" as ColumnType, props: {} },
            { label: "Status", key: "status", component: ({ value }: { value: string }) => <ResourceStatusV2 status={value} />, type: "custom" as ColumnType, props: {} },
            { label: "Action", key: "action", type: "custom" as ColumnType, props: {} },
        ]
    }

    const handleViewClick = (id: number) => (e: any) => {
        const route = makeRoute(ADMIN_ROUTES.CUSTOMER_MANAGEMENT_VIEW, { query: { tab: CUSTOMER_MANAGEMENT_TABS.PROFILE }, params: { id: id } });
        navigate(`${route}`);
    };

    const Action = (id: number) => {
        return (<div>
            <button onClick={handleViewClick(id)}><img src={viewEyeIcon} alt={viewEyeIcon} /></button>
        </div>)
    }

    const records = useMemo(() => {
        return customerTemplate.map((customerTemplate: ICustomerTemplate, index: number) => {

            const customerTypes = customerTemplate?.customerType ? customerTemplate.customerType.split(',') : [];
            const firstCustomerType = customerTypes.length > 0 ? customerTypes[0] : '';
            const customerTypesCount = customerTypes.length;
            return [
                pagination.currentPage * pagination.pageSize + index + 1,
                customerTemplate.id,
                customerTemplate.companyName,
                <div className='cursor-pointer' onClick={() => handleOpenModal(customerTemplate.customerType, customerTemplate.id)}>
                    <span className='border-b'>{firstCustomerType}</span>
                    <span className={`${classes.customerCount} text-white`}>
                        {customerTypesCount}
                    </span>
                </div>,
                customerTemplate.location,
                customerTemplate?.relationshipManager[0]?.fullName ?? '',
                customerTemplate.createdBy,
                enumToString(customerTemplate.status),
                Action(customerTemplate.id)
            ];
        });
    }, [customerTemplate]);

    return (
        <div>
            <ViewGroupNameModalTemplate
                dialogOpen={isModalOpen}
                setDialogOpen={setIsModalOpen}
                viewId={viewId}
            />
            <div className={`!w-80 py-8`}>
                <TextFieldV2
                    label=''
                    variant="outlined"
                    placeholder="search.."
                    name='search'
                    value={customerFilter.search}
                    onChange={handleFiltersChange}
                    InputProps={{
                        startAdornment: <InputAdornment position="start" > <img src={search} alt="icon" /></InputAdornment>,
                    }}
                />
            </div>
            <div className={`${classes.container}  pt-8`}>
                <TableV2 schema={schema} records={records} />
            </div>
        </div>
    )
}
export default CustomerListTemplate