import { useContext, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import '../../../Components/Procurement/Indent/indent.css';
import {
  BillToAddress,
  Comment,
  FormData,
  Indent,
  Procurement,
  ShipToLocation,
} from '../../../Constants/Procurement/Indent/types';
import { useNavigate, useParams } from 'react-router-dom';
import RemarkComponent from '../../../Components/Procurement/Indent/RemarkComponent';
import { NavigationURL } from '../../../Constants/EndPoints';
import {
  COMMENT_RESOURCE_TYPE,
  INDENT_TEXT,
  PAYMENT_MODE,
} from '../../../Constants/Procurement/Indent/constants';
import CommonIndentTnCReview from '../../../Components/Procurement/Indent/CommonIndentTnCReview';
import CommonProductDetailReview from '../../../Components/Procurement/Indent/CommonProductDetailReview';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import { WareHouseContext } from '../../../../contexts/WareHouseDetailContext';
import { Modal } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { useCommentService } from '../../../../services/useComentService';
import { useWarehouseService } from '../../../../services/useWarehouseService';
import { useIndentService } from '../../../../services/useIndentService';

const useStyles = createUseStyles((theme: any) => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '450px',
    backgroundColor: 'white',
    borderRadius: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    gap: '30px',
    padding: '60px',
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface ReviewIndentProps {}

const ReviewIndent: React.FC<ReviewIndentProps> = () => {
  // get id
  const id = useParams().id || '';

  // for navigation
  const navigate = useNavigate();

  // for styles
  const classes = useStyles();

  // local comments id counter
  const [commentCounter, setCommentCounter] = useState<number>(1000);

  // call ship to bill to APIs
  const { user }: any = useAuthenticatedUser();
  const [shipToData, setShipToData] = useState<ShipToLocation>();
  const [indentData, setIndentData] = useState<Indent>();
  const [indentDetails, setIndentDetails] = useState<FormData>();
  const [billToAddress, setBillToAddress] = useState<BillToAddress>();
  const [purchaseReqDetails, setPurchaseReqDetails] = useState<Procurement[]>();
  const [showButton, setShowButton] = useState(true);
  // remarks
  const [productRemarks, setProductRemarks] = useState<boolean>(false);

  // comment hook
  const { getComments, sendComments, deleteComment, resolveComment } =
    useCommentService();

  // warehouse hook
  const { getBillAddress } = useWarehouseService();

  // indent hook
  const { getIndents, rejectIndent, approveIndent, getPurchaseRequistions } =
    useIndentService();

  // model
  const [openModel, setOpenModel] = useState<boolean>(false);
  const [openSecondModel, setOpenSecondModel] = useState<boolean>(false);

  // comments
  const [comments, setComments] = useState<Comment[]>([]);
  // local comments
  const [localComments, setLocalComments] = useState<
    {
      id: string;
      commentText: string;
      resourceType: string;
      resourceId: string;
    }[]
  >([]);
  // send remarks
  // send all the local comments then redirect.
  const handleSendRemarks = async () => {
    if (productRemarks) {
      alert('Pls save comments');
      return;
    }
    if (localComments.length > 0) {
      try {
        const response = await sendComments(localComments);

        if (response?.data?.data) {
          setLocalComments([]);
        }
        navigate(NavigationURL.indent);
      } catch (e) {
        console.log('ERROR IN THE SEND REMARKS', e);
      }
    } else {
      navigate(NavigationURL.indent);
    }
  };

  const AddNewCommnet = (comment: string) => {
    if (indentData) {
      const newComments = [
        ...localComments,
        {
          id: String(commentCounter),
          commentText: comment,
          resourceType: COMMENT_RESOURCE_TYPE.INDENT,
          resourceId: indentData.id,
        },
      ];
      setCommentCounter((prev) => prev + 1);
      setLocalComments(newComments);
    }
  };
  const fetchRemoteComments = async () => {
    if (indentData) {
      try {
        const response = await getComments(
          indentData.id,
          COMMENT_RESOURCE_TYPE.INDENT
        );
        if (response?.data?.data?.length > 0) {
          setComments(response?.data?.data);
        } else {
          setComments([]);
        }
      } catch (e) {
        console.log('FETCH REVIEW API error', e);
      }
    }
  };

  const onRemove = async (id: string, uploaded: boolean) => {
    if (uploaded) {
      const filteredComments = comments.filter((comment) => comment.id !== id);
      try {
        const response = await deleteComment(id);
        if (response.data.data) {
        }
        setComments(filteredComments);
      } catch (e) {
        console.log('ERROR in the DELETE COMMENT error', e);
      }
    } else {
      // local comment delete
      setLocalComments(
        localComments.filter((comment: any) => comment.id !== id)
      );
    }
  };
  // resolve comment for client
  const handleResolve = async (id: string) => {
    const commentIndex = comments.findIndex((comment) => comment.id === id);
    if (commentIndex !== -1) {
      try {
        await resolveComment(id);
        const updatedComments = [...comments];
        updatedComments[commentIndex].resolved = true;
        setComments(updatedComments);
      } catch (e) {
        console.log('ERROR in RESOLVE COMMENT', e);
      }
    }
  };

  const saveComments = () => {
    if (productRemarks) {
      setProductRemarks((prev) => !prev);
    }
  };

  const cancelComments = () => {
    if (productRemarks) {
      setProductRemarks((prev) => !prev);
    }
  };
  const handleAddRemarks = async () => {
    await fetchRemoteComments();
    setProductRemarks((prev) => !prev);
  };

  const fetchBillToAddress = async () => {
    try {
      const billToResponse = await getBillAddress();
      if (billToResponse.data.data) {
        setBillToAddress(billToResponse.data.data);
      }
    } catch (e) {
      console.log('Error in fetchBillToAddress', e);
    }
  };
  const { locations, warehouseDetails } = useContext(WareHouseContext);

  const fetchData = async () => {
    const queryParams = {
      indentIds: id,
      pageSize: 1,
      pageNumber: 0,
    };

    try {
      let indentResponse = await getIndents(queryParams);

      if (indentResponse?.data?.data?.content.length >= 0) {
        // Get Indent Data
        let indentData: Indent = indentResponse?.data?.data?.content[0];
        setIndentData(indentData);
        setShowButton(
          indentData.status === 'CREATED' ||
            indentData.status === 'APPROVAL_REMARKS_RESOLVED' ||
            indentData.status === 'APPROVAL_REMARKS'
        );
        const indentDetails = {
          id: indentData.id,
          items: indentData.items.map((procurement) => ({
            purchase_requisition_id: procurement.purchaseRequisitionId,
            quantity: procurement.quantity,
            uom: procurement.uom,
          })),
          cess: indentData.cess,
          gst: indentData.gst,
          tcs: indentData.tcs,
          payment_terms:
            PAYMENT_MODE.find(
              (mode) => mode.value === indentData?.paymentTerms?.days
            )?.name || '',
          quality_check: indentData.qualityCheck,
          purchase_type: indentData.purchaseType,
          tnc: indentData.tnc,
          transportation_mode: indentData.transportationMode,
          warehouse_id: indentData.warehouseId,
          buyer_id: indentData.buyerID,
        };
        setIndentDetails(indentDetails);

        // Get Procurement Data
        const PRs = indentDetails.items;
        const PRIds = PRs.map((pr) => pr.purchase_requisition_id);

        let queryParams: Record<string, string | number> = {
          pageSize: PRs.length,
          prIDs: PRIds.join(','),
          pageNumber: 0,
        };

        let response: AxiosResponse = await getPurchaseRequistions(queryParams);
        if (response.data.data?.content.length > 0) {
          let PRs = response.data.data.content;
          setPurchaseReqDetails(PRs);
        }
      }
    } catch (error) {
      console.log('Error in fetching Details', error);
      alert('Error in fetching Details');
    }
  };

  // reject indent
  const handleReject = async () => {
    if (indentData) {
      try {
        const response = await rejectIndent(indentData.id);
        if (response.data && response.data.status === 'OK') {
          navigate(NavigationURL.indent);
        }
      } catch (e) {
        console.log('ERRROR in the handleUpdate for operation', e);
      }
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
      fetchBillToAddress();
    } else {
      navigate(NavigationURL.indent);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      locations?.length > 0 &&
      warehouseDetails.length > 0 &&
      purchaseReqDetails &&
      purchaseReqDetails.length > 0 &&
      !shipToData
    ) {
      const PRs = purchaseReqDetails.map((pr: Procurement) => {
        return {
          ...pr,
          consigneeLocation:
            locations.find((location) => location.id === pr.warehouseId)
              ?.name || '',
        };
      });
      setPurchaseReqDetails(PRs);
      if (indentDetails) {
        const filterData = warehouseDetails.find(
          (item) => item.id === parseInt(indentDetails?.warehouse_id)
        );
        setShipToData(filterData);
      }
    }
  }, [locations, warehouseDetails, purchaseReqDetails]);

  const handleIndentApprove = async (type: 'DIRECT' | 'AUCTION') => {
    if (indentData) {
      try {
        const response = await approveIndent(indentData.id, type);
        if (response.data && response.data.status === 'OK') {
          setOpenModel(false);
          navigate(NavigationURL.indent);
        }
      } catch (e) {
        console.log('ERRROR in the handleUpdate for operation', type, e);
      }
    }
  };

  return (
    <div className="grid gap-y-3 gap-x-4 m-t-10 p-x-10">
      {/* DIRECT OR AUCTION selection */}
      <Modal open={openModel} onClose={() => setOpenModel(false)}>
        <div className={classes.container}>
          <div className="text-blue text-base">
            Do you want to purchase through{' '}
            <span className="text-blue font-semibold">“Auction”</span> or{' '}
            <span className="text-blue font-semibold">“Direct”</span> process?
          </div>
          <div>
            <div className="flex flex-row justify-end gap-4 w-full items-start">
              <button
                className="text-sm font-semibold  text-text-color border-solid border-blue flex flex-row justify-center py-3  px-6 items-start border rounded-md"
                onClick={() => {
                  setOpenModel(false);
                  setOpenSecondModel(true);
                }}
              >
                Direct
              </button>
              <button
                className="text-sm font-semibold  text-white bg-blue flex flex-row justify-center py-3  px-6 items-start rounded-md"
                onClick={() => {
                  handleIndentApprove('AUCTION');
                }}
              >
                Auction
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/* DIRECT CONFIRMATION */}
      <Modal open={openSecondModel} onClose={() => setOpenSecondModel(false)}>
        <div className={classes.container}>
          <div className="text-blue text-base">
            Are you sure you want to purchase{' '}
            <span className="text-blue font-semibold">“Direct”</span> process?
          </div>
          <div>
            <div className="flex flex-row justify-end gap-4 w-full items-start">
              <button
                className="text-sm font-semibold  text-text-color border-solid border-blue flex flex-row justify-center py-3  px-6 items-start border rounded-md"
                onClick={() => {
                  setOpenSecondModel(false);
                  setOpenModel(true);
                }}
              >
                No
              </button>
              <button
                className="text-sm font-semibold  text-white bg-blue flex flex-row justify-center py-3  px-6 items-start rounded-md"
                onClick={() => {
                  handleIndentApprove('DIRECT');
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div>
        <div className="text-2xl font-bold text-blue mt-3">
          {INDENT_TEXT.ReviewIndentHeading}
        </div>
      </div>
      <div className="bg-cgray-2 flex flex-row w-full rounded-md p-4">
        <div className="text-xl font-semibold text-blue">
          {`${INDENT_TEXT.IndentIDLabel} ${indentDetails && indentDetails.id}`}
        </div>
      </div>
      <div className="border-solid border-b border-cgray-3 flex flex-row justify-between w-full mt-3 py-2">
        <div id="Heading" className="text-2xl font-semibold  text-text-color">
          {INDENT_TEXT.PRProductDetailHeading}
        </div>
        {showButton && (
          <div>
            {productRemarks ? (
              <div className="flex gap-2">
                <button
                  className="text-sm font-semibold leading-[22px]  text-white bg-blue flex flex-row justify-center px-6 py-3 rounded-md"
                  onClick={saveComments}
                >
                  {INDENT_TEXT.ReviewSaveButtonLabel}
                </button>
                <button
                  className="text-sm font-semibold leading-[22px]  text-white bg-blue flex flex-row justify-center px-6 py-3 rounded-md"
                  onClick={cancelComments}
                >
                  {INDENT_TEXT.ReviewCancelButtonLabel}
                </button>
              </div>
            ) : (
              <button
                className="text-sm font-semibold leading-[22px]  text-white bg-blue flex flex-row justify-center px-6 py-3 rounded-md"
                onClick={handleAddRemarks}
              >
                {INDENT_TEXT.IndentAddRemarkButton}
              </button>
            )}
          </div>
        )}
      </div>
      {/* Remarks Component */}
      {productRemarks && indentData && (
        <RemarkComponent
          type={'MANAGER'}
          moduleType={'indent'}
          uploadedComments={comments}
          localComments={localComments}
          onResolve={handleResolve}
          onAddNew={AddNewCommnet}
          onRemove={onRemove}
        />
      )}
      {/*  details  */}
      {purchaseReqDetails && indentDetails && (
        <>
          <CommonProductDetailReview
            formData={indentDetails}
            selectedPurchaseRequisition={purchaseReqDetails}
          />
        </>
      )}

      {/* Indent T&C */}
      <div className="  ">
        <div className="border-solid border-b border-cgray-3 flex flex-row justify-between w-full mt-3 py-2">
          <div className="text-2xl font-semibold text-text-color mb-4">
            {INDENT_TEXT.ReviewTnCHeading}
          </div>
        </div>
        {indentDetails && (
          <CommonIndentTnCReview
            billToAddress={billToAddress}
            shipToData={shipToData}
            formData={indentDetails}
          />
        )}
      </div>
      {showButton && (
        <div className="flex flex-row justify-end gap-4 w-full items-start my-4">
          <button
            className="text-sm font-semibold  text-text-color border-solid border-blue flex flex-row justify-center py-3  px-6 items-start border rounded-md"
            onClick={handleSendRemarks}
          >
            {INDENT_TEXT.SendRemarkIndentButtonLabel}
          </button>
          <button
            className="text-sm font-semibold  text-badge-warning border-solid border-badge-warning flex flex-row justify-center py-3  px-6 items-start border rounded-md"
            onClick={handleReject}
          >
            {INDENT_TEXT.RejectIndentButtonLabel}
          </button>
          <button
            className="text-sm font-semibold  text-white bg-blue flex flex-row justify-center py-3  px-6 items-start rounded-md"
            onClick={() => {
              setOpenModel(true);
            }}
          >
            {INDENT_TEXT.ApproveIndentButtonLabel}
          </button>
        </div>
      )}
    </div>
  );
};

export default ReviewIndent;
