import React, { useEffect, useState } from 'react'
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import { HTTP_STATUS } from '../../../utils/types';
import { useParams } from 'react-router-dom';
import { IBusinessProfile } from '../CustomerManagement/ProfileOverview.page';
import { useVendorServiceV2 } from '../../../services/useVendorServiceV2';
import ProfileOverviewTemplate from '../../templates/VendorManagement/ProfileOverview.template';

const VendorProfileOverviewPage: React.FC = () => {

    const params = useParams();
    const businessProfileId = Number(params.id);

    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const vendorService = useVendorServiceV2();

    const [businessProfile, setBusinessProfileTo] = useState<IBusinessProfile | null>(null);
    const [userStatusDetails, setUserStatusDetailsTo] = useState<{ status: string; comments: string }>({ status: '', comments: '' })

    const getBusinessProfile = async () => {
        try {
            const businessProfileResponse = await vendorService.getVendor(businessProfileId);
            if (businessProfileResponse.status === HTTP_STATUS.OK) {
                const profileData = businessProfileResponse?.data?.data;
                setBusinessProfileTo(profileData);
            }
        } catch (error) {
            showSnackbar('error', 'Business Profile fetch failed');
        }
    };

    const updateStatus = async () => {
        const requestBody = {
            status: userStatusDetails?.status,
            comments: userStatusDetails?.comments
        }
        if (businessProfile?.id) {
            const businessProfileResponse = await vendorService.updateVendorStatus(businessProfile?.id, requestBody);
            if (businessProfileResponse?.status === HTTP_STATUS.OK) {
                showSnackbar("success", "Status updated successfully");
            }
            else{
                showSnackbar("error", "Status update failed");
            }
        }
    }

    useEffect(() => {
        getBusinessProfile();
    }, []);

    return (
        <div>
            {SnackBarComponent}
            {businessProfile && <ProfileOverviewTemplate businessProfile={businessProfile} updateStatus={updateStatus} statusDetails={userStatusDetails} setStatusDetailsTo={setUserStatusDetailsTo} />}
        </div>
    )
}

export default VendorProfileOverviewPage;
