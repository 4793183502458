import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Checkbox } from "@mui/material";
import TableV2, { ColumnType } from "../../organisms/TableV2";
import { initialPaginationValues } from "../../../utils/constant";
import { HTTP_STATUS, IPagination } from "../../../utils/types";
import { ICustomerQueryParams, useCustomerGroupMasterService } from "../../../services/useCustomerGroupMasterService";
import { ICustomer } from "./UpcCustomerList.template";
import { useSnackbar } from "../../../hooks/useSnackBar";

interface AdditionalCustomerPreviewTemplateProps {
    formik: any;
}

interface ChipProps {
    value: string;
};
interface MaterialFiltersProps {
    label: string;
    values: any[];
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid #EBEFFA`
    },
    filterContainer: {
        borderTop: `1px solid #EBEFFA`
    },
    filterHeading: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    label: {
        color: theme.palette.textV2.tertiary.tertiary600,
        fontSize: "14px",
        fontWeight: 400,
    },
    configure: {
        color: theme.palette.textV2.primary.primary500,
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600,
    },
    chipBackgroundColor: {
        backgroundColor: theme.palette.textV2.tertiary.tertiary50,
    },
    activeProgressBar: {
        backgroundColor: "#FD6202"
    },
    inActiveProgressBar: {
        backgroundColor: "#EBEFFA"
    },
    input: {
        "& .MuiInputBase-root": {
            borderRadius: "8px !important",
        },
    },
    errorMessage: {
        color: theme.palette.textV2.failure.failure600,
    },
}));

const Chip: React.FC<ChipProps> = ({ value }) => {
    const classes = useStyles();
    return (
        <div className={`${classes.chipBackgroundColor} py-1.5 px-3 rounded-lg text-xs font-medium h-min`}>
            {value}
        </div>
    );
};

const MaterialFilters: React.FC<MaterialFiltersProps> = ({ label, values }) => {
    const classes = useStyles();
    if (values.length === 0) return null;
    return (
        <div className="grid content-start gap-y-2 w-44">
            <div className={`${classes.label} text-base`}>{label}</div>
            <div className="flex flex-wrap gap-2">
                {values.map((item, index) => (
                    <Chip key={index} value={item.label} />
                ))}
            </div>
        </div>
    );
};

const AdditionalCustomerPreviewTemplate: React.FC<AdditionalCustomerPreviewTemplateProps> = ({ formik }) => {
    const classes = useStyles();
    const customerGroupService = useCustomerGroupMasterService()
    const [customers, setCustomersTo] = useState<ICustomer[]>([]);
    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const refreshCustomer = async (page: number, size: number) => {
        try {
            const payload: ICustomerQueryParams = {
                customerIds: formik.values.additionalCustomers?.length > 0 ? formik.values.additionalCustomers.map((customer: any) => customer.id) : [],              
            };
            const customerGroupMasterResponse = await customerGroupService.getCustomer({
                page: page,
                size: size,
                sort: "createdAt,desc",
            },
                payload
            );
            if (
                customerGroupMasterResponse?.status === HTTP_STATUS.OK &&
                customerGroupMasterResponse?.data?.data?.content?.length
            ) {
                setCustomersTo(customerGroupMasterResponse.data.data.content);
                setPaginationTo((prevPagination: IPagination) => ({
                    ...prevPagination,
                    totalRecords: customerGroupMasterResponse.data.data.totalElements,
                    totalPages: customerGroupMasterResponse.data.data.totalPages,
                }));
            } else {
                setCustomersTo([]);
            }
        } catch (error) {
            showSnackbar("error", "Customers fetch failed");

            setCustomersTo([]);
        }
    };

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage
        }));
    };

    useEffect(() => {
        refreshCustomer(pagination.currentPage, pagination.pageSize);
    }, [pagination.currentPage, pagination.pageSize, formik.values.upcCodes]);

    const schema = {
        id: "1",
        title: "",
        pagination: {
            total: pagination?.totalRecords,
            currentPage: pagination?.currentPage,
            isVisible: true,
            limit: pagination?.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange,
        },
        columns: [
            { label: "S.No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "Customer Name- ID", key: "name", type: "custom" as ColumnType, props: { className: '' } },
            { label: "Company Name", key: "companyName", type: "string" as ColumnType, props: { className: '' } },
            { label: "Location", key: "city", type: "string" as ColumnType, props: { className: '' } },
            { label: "Business Classification", key: "businessClassification", type: "string" as ColumnType, props: { className: '' } },
            { label: "Turnover", key: "turnover", type: "string" as ColumnType, props: { className: '' } },
            { label: "Select Customer", key: "action", type: "custom" as ColumnType, props: { className: '' } },
        ],
    };

    const Action = (name: string, id: number) => {
        return (
            <div className={`flex items-center justify-center gap-x-3`}>
                <Checkbox
                    sx={{ padding: 0, color: "#C4C4C4" }}
                    checked={formik.values.selectedCustomer.some((selected: any) => selected.label === name && selected.id === id)}
                    disabled
                />
            </div>
        );
    };

    const Name = (name:string, id:number) => {
        return (
            <div className={`flex items-center justify-center text-base`}>
               {name}-{id}
            </div>
        );
    }

    const records = customers?.map((customer: ICustomer, index: number) => [
        pagination.currentPage * pagination.pageSize + index + 1,
        Name(customer?.customerName, customer?.customerId),
        customer?.companyName,
        customer?.city,
        customer?.businessClassification,
        customer?.turnOver,
        Action(customer?.customerName, customer?.customerId),
    ]);


    return (
        <div>
            <div className="flex justify-between pb-4">
                <div className={`${classes.filterHeading} text-lg font-bold my-auto`}>Additional Customers</div>
            </div>
            <div className={`${classes.container} p-6 grid gap-y-8 rounded-lg`}>
                {formik.values.additionalCustomerFilters.length > 0 && <div className="flex gap-x-6">
                    <MaterialFilters label={'Other Filters'} values={formik.values.additionalCustomerFilters} />
                </div>}
                {(formik.values.additionalCustomerCityArray.length > 0 || formik.values.additionalCustomerBusinessClassificationArray.length > 0 ||
                    formik.values.additionalCustomerTurnOverArray.length > 0 || formik.values.additionalCustomerImportExportArray.length > 0) &&
                    <div className="flex gap-x-6 ">
                        <MaterialFilters label={'City'} values={formik.values.additionalCustomerCityArray} />
                        <MaterialFilters label={'Business Classification'} values={formik.values.additionalCustomerBusinessClassificationArray} />
                        <MaterialFilters label={'Turn Over'} values={formik.values.additionalCustomerTurnOverArray} />
                        <MaterialFilters label={'Import/Export'} values={formik.values.additionalCustomerImportExportArray} />
                    </div>}

                <TableV2 schema={schema} records={records} />
            </div>
        </div>

    );
};

export default AdditionalCustomerPreviewTemplate;