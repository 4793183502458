import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import gstSvg from "../../../assets/icons/gst.svg";
import Button from "../../atoms/Button";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CustomerDetailTemplate from "./CustomerDetail.template";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Table, { ColumnType } from "../../organisms/Table";
import { createUseStyles } from "react-jss";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { IQuotation } from "../../pages/Lead/LeadQuotationList.page";
import { IDetail } from "../../pages/Lead/LeadView.page";
import moment from "moment";
import { ADMIN_ROUTES, LEAD_STATUS } from "../../../utils/constant";
import { CLASS_TYPE } from "../../../utils/types";
import { enumToString, makeRoute } from "../../../utils/helper";
import ResourceStatus from "../../atoms/ResourceStatus/ResourceStatus";

interface LeadListTemplateProps {
    quotation: IQuotation[];
    details: IDetail;
    handleCreateQuoation: (event: any) => void;
    classType: CLASS_TYPE;
}

const useStyles = createUseStyles((theme: any) => ({
    viewIconContainer: {
        color: theme.palette.icon.primaryLight,
    },
    editIconContainer: {
        color: theme.palette.icon.secondryLight,
    },
}));

const LeadQuotationListTemplate: React.FC<LeadListTemplateProps> = ({ quotation, details, handleCreateQuoation, classType }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const detailItems = [
        { icon: <PersonIcon />, value: details?.customerName },
        { icon: <EmailIcon />, value: details?.email },
        { icon: <ApartmentIcon />, value: details?.companyName },
        { icon: <PhoneIcon />, value: details?.userMobileNumber },
        { icon: <img className="w-4" src={gstSvg} alt="gstIcon" />, value: details?.gstNumber },
    ];

    const schema = {
        id: "1",
        title: "",
        pagination: {
            total: 0,
            currentPage: 0,
            isVisible: false,
            limit: 0,
        },
        columns: [
            { label: "Sr No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "Quotation ID", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "Company Name", key: "companyName", type: "string" as ColumnType, props: { className: '' } },
            { label: "Total Products", key: "upcIdLength", type: "number" as ColumnType, props: { className: '' } },
            { label: "Created Date", key: "createdAt", type: "datetime" as ColumnType, props: { className: '' } },
            { label: "Updated Date", key: "updatedAt", type: "datetime" as ColumnType, props: { className: '' } },
            { label: "Status", key: "status", component: ({ value }: { value: string }) => <ResourceStatus status={value} />, type: "custom" as ColumnType, props: {} }, 
            { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } },
        ],
    };

    const handleViewQuotation = (quotationId: number) => {
        navigate(`${ ADMIN_ROUTES.QUOTATION }/${ quotationId }`);
    };

    const handleEditQuotation = (id: number, catalogueType: string) => () => {
		if (catalogueType === CLASS_TYPE.STANDARD) {
			navigate(makeRoute(ADMIN_ROUTES.QUOTATION_UPDATE, { params:  { id: id } }));
        } else if (catalogueType === CLASS_TYPE.SECONDARY) {
            navigate(makeRoute(ADMIN_ROUTES.SECONDARY_QUOTATION_UPDATE, { params:  { id: id } }));
        } else if (catalogueType === CLASS_TYPE.MAKE_TO_ORDER) {
            navigate(makeRoute(ADMIN_ROUTES.MTO_QUOTATION_UPDATE, { params:  { id: id } }));
        }
	};

    const Action = (id: number, status: string, catalogueType: string, index: number) => {
        return (
            <div className="flex gap-x-2 justify-end">
                <RemoveRedEyeIcon className={ `${ classes.viewIconContainer } text-secondary cursor-pointer` } onClick={ (e) => handleViewQuotation(id) } />
                {/* {status === LEAD_STATUS.ACCEPTED  ? null :
                    <ModeEditOutlineIcon className={ `${ classes.editIconContainer } text-secondary cursor-pointer`} onClick={ handleEditQuotation(id, catalogueType) } />
                }  */}
            </div>
        );
    };

    const records = quotation.map((quotation: IQuotation, index: number) => [
        index + 1,
        quotation.id,
        quotation.companyName,
        quotation.length,
        moment(quotation.createdAt).format("YYYY-MM-DD HH:mm:ss"),
        moment(quotation.updatedAt).format("YYYY-MM-DD HH:mm:ss"),
        enumToString(quotation.status),
		Action(quotation.id, quotation.status, quotation.catalogueType, index),
    ]);

    const createQuotationButton = useMemo(() => {
        if (quotation && quotation[0]?.status === LEAD_STATUS.ACCEPTED) {
            return;
        } else if (classType === CLASS_TYPE.STANDARD) {
            return (
                <Button variant="contained" onClick={ handleCreateQuoation } label="Create Quotation" />
            );
        } else if (classType === CLASS_TYPE.SECONDARY) {
            return (
                <Button variant="contained" onClick={ handleCreateQuoation } label="Create Secondary Quotation" />
            );
        } else if (classType === CLASS_TYPE.MAKE_TO_ORDER) {
            return (
                <Button variant="contained" onClick={ handleCreateQuoation } label="Create Secondary Lot Quotation" />
            );
        }
    }, [classType, quotation]);

    return (
        <div className="grid  gap-y-3 ">
            <div className="flex justify-between gap-x-3 py-3 ">
                <div className="grid grid-cols-2 gap-y-1 w-3/4 ">
                    {detailItems.map((item: any) => (
                        <CustomerDetailTemplate key = { item.key } icon = { item.icon } value = { item.value } />
                    ))}
                </div>
                <div className="w-1/4 my-auto flex justify-end">
                    { createQuotationButton }
                </div>
            </div>
            <div>
                <Table schema={ schema } records={ records } />
            </div>
        </div>
    );
};
export default LeadQuotationListTemplate;
