import React, { useEffect, useState } from 'react'
import SecondaryCatalogueFormTemplate from '../../templates/SecondaryCatalogue/SecondaryCatalogueForm.template'
import { MODE, RESOURCE_TYPE } from '../../../utils/constant'
import { useParams } from 'react-router-dom';
import { useSecondaryCatalogueService } from '../../../services/useSecondaryCatalogueService';
import { ISecondaryCatalogueResponse } from '../../templates/SecondaryCatalogue/SecondaryCatalogueView.template';
import { ImageData } from '../../organisms/ImageUploader';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useFileService } from '../../../services/useFileService';
import { HTTP_STATUS } from '../../../utils/types';

export interface ISecondaryCatalogueChangeResponse {
	id: number;
	secondaryProductId: number;
	catalogueName: string;
	minimumQuantity: number;
	availableQuantity: number;
	application: string[];
	defectDetails: string;
	additionalProductDetails: string;
	keywords: string[];
	price: {[key: string]: number;};
	quantity: number;
	gradeId: number;
	packagingType: string;
	termsConditions: string;
	status: string;
	createdAt: string;
	updatedAt: string;
}

const SecondaryCatalogueUpdate: React.FC = () => {
	const params = useParams();
	const secondaryCatalogueId = parseInt(params.id ?? '0', 10);
	const secondaaryCatalogueService = useSecondaryCatalogueService();
	const [secondaryCatalogue, setSecondaryCatalogueTo] = useState<ISecondaryCatalogueChangeResponse | null>(null);
	const [image, setImagesTo] = useState<(ImageData | File)[]>([]);
	const { showSnackbar, SnackBarComponent } = useSnackbar();
	const fileService = useFileService();

	const getSecondaryCatalogueById = async (secondaryCatalogueId: number) => {
		if (secondaryCatalogueId) {
			try {
				const secondaryCatalogueResponse = await secondaaryCatalogueService.getSecondaryCatalogueById(secondaryCatalogueId);
				if(secondaryCatalogueResponse.status === HTTP_STATUS.OK){
					const modifiedCatalogueResponse = modifyResponseData(secondaryCatalogueResponse?.data?.data);
				   setSecondaryCatalogueTo(modifiedCatalogueResponse);
				}
				const fileResponse = await fileService.getFilesById(secondaryCatalogueId, RESOURCE_TYPE.SECONDARY_CATALOGUE);
                (fileResponse.status === HTTP_STATUS.OK) && setImagesTo(fileResponse?.data?.data);
			} catch (error) {
				showSnackbar("error", 'Secondary Catalogue fetch failed');
				setSecondaryCatalogueTo(null);
				setImagesTo([]);
			}
		}
	};

	const modifyResponseData = (data: ISecondaryCatalogueResponse): ISecondaryCatalogueChangeResponse | null => {
		 if (data) {
        const modifiedData: ISecondaryCatalogueChangeResponse = {
			id: data.id,
			secondaryProductId: data.secondaryProductId,
			catalogueName: data.catalogueName,
			minimumQuantity: data.minimumQuantity,
			availableQuantity: data.availableQuantity,
			application: data.application.split(',').map(keyword => keyword.trim()),
			defectDetails: data.defectDetails,
			additionalProductDetails: data.additionalProductDetails,
			keywords: data.keywords.split(',').map(keyword => keyword.trim()),
			price: data?.price,
			quantity: data.quantity,
			gradeId: data.gradeId,
			packagingType: data.packagingType,
			termsConditions: data.termsConditions,
			status: data.status,
			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
		};
        return modifiedData;
    } else {
        return null;
    }
	};

	useEffect(() => {
		getSecondaryCatalogueById(secondaryCatalogueId);
	}, [secondaryCatalogueId]);
	return (
		<SecondaryCatalogueFormTemplate secondaryCatalogue={secondaryCatalogue} mode={MODE.UPDATE} image={image} setImagesTo={setImagesTo}/>
	)
}
export default SecondaryCatalogueUpdate