import * as React from 'react';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';

interface BpIconProps extends React.HTMLAttributes<HTMLSpanElement> {
  width?: number | string;
  height?: number | string;
}

const BpIcon = styled('span')<BpIconProps>(
  ({ theme, width = 24, height = 24 }) => ({
    borderRadius: 4,
    width: width,
    height: height,
    backgroundColor: '#fff',
    border: '1px solid rgba(170, 167, 167, 1)',
    '.Mui-focusVisible &': {
      outline: '1px auto rgba(170, 167, 167, 1)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(144, 145, 145,.5)',
    },
  })
);
const BpCheckedIcon = styled(BpIcon)(({ width = 24, height = 25 }) => ({
  width: width,
  height: height,
  backgroundImage: `url("data:image/svg+xml;charset=utf-8,${encodeURIComponent(`
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.33398 16.8151L11.8981 23.3337L26.6673 8.66699" stroke="#1D3679" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
`)}")`,
  backgroundRepeat: 'no-repeat',
  border: '1px solid rgba(170, 167, 167, 1)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(144, 145, 145,.5)',
  },
}));

interface CustomCheckboxProps {
  name: string;
  checked: boolean;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disableRipple?: boolean;
  disabled?: boolean;
  height?: string;
  width?: string;
  className?: string;
}

const CustomCheckBox: React.FC<CustomCheckboxProps> = ({
  name,
  checked = false,
  handleChange,
  disableRipple = false,
  disabled = false,
  className,
  height,
  width,
  ...props
}) => {
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
        margin: '0px',
        padding: '0px',
      }}
      className={className}
      name={name}
      disabled={disabled}
      disableRipple={disableRipple}
      color="default"
      checked={checked}
      checkedIcon={<BpCheckedIcon width={width} height={height} />}
      icon={<BpIcon width={width} height={height} />}
      onChange={handleChange}
      inputProps={{ 'aria-label': `Checkbox-${name}` }}
      {...props}
    />
  );
};

export default React.memo(CustomCheckBox);
