import React from 'react';
import ProductAttributeList from './ProductAttributeList';
import { Procurement } from '../../../Constants/Procurement/Indent/types';
import CustomCheckBox from '../../../Shared/CustomInputs/CustomCheckBox';

interface UPCViewComponentProps {
  handleToggle: (value: Procurement) => void;
  selectedProcurement: Procurement[];
  product: Procurement;
}

const UPCViewComponent: React.FC<UPCViewComponentProps> = ({
  selectedProcurement,
  handleToggle,
  product,
}) => {
  return (
    <div className="border-solid border-cgray-4 bg-white grid grid-cols-2 justify-between items-center w-full py-3 px-2 border rounded-md element-with-shadow ">
      <div className="flex flex-row items-center w-full gap-3 justify-start">
        <div className="flex flex-row justify-between gap-3 items-center">
          <div className="flex flex-row justify-start items-center   ">
            <div className="flex flex-row">
              <CustomCheckBox
                name="buyerGstApplicable"
                checked={
                  selectedProcurement.find((el) => el.id === product.id)
                    ? true
                    : false
                }
                handleChange={() => {
                  handleToggle(product);
                }}
              />
            </div>
          </div>
          <div className="flex flex-col gap-1  whitespace-nowrap">
            <div className="text-xs text-cgray-1">PR ID</div>
            <div className=" text-sm font-medium text-blue">{product.id}</div>
          </div>
          <div className="flex flex-col gap-1  whitespace-nowrap">
            <div className=" text-xs text-cgray-1">UPC Code</div>
            <div className=" text-sm font-medium text-blue ">
              {product?.item?.upcCode || '--'}
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <div className="text-xs text-cgray-1 ">UPC Description</div>
            <div className=" text-sm font-medium text-text-color">
              {product?.item?.brand +
                ' ' +
                product?.item?.productCategory +
                ' ' +
                product?.item?.shape +
                ' ' +
                product?.item?.standard +
                ' ' +
                product?.item?.grade || '--'}
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-flow-col items-center w-full gap-2">
        <div className="flex flex-row justify-start gap-2  flex-wrap items-start">
          <ProductAttributeList
            attributeMap={product?.item?.attributesMap?.SPECIFICATION}
          />
        </div>
        <div className="flex flex-row gap-3  whitespace-nowrap ml-auto">
          <div className="flex flex-col gap-1">
            <div className=" text-xs text-cgray-1 text-right">Quatity</div>
            <div className=" text-sm font-medium text-text-color">
              {`${product?.quantity} MT`}
            </div>
          </div>
          <div className="flex flex-col gap-1 ">
            <div className=" text-xs text-cgray-1 text-right">Created By</div>
            <div className=" text-sm font-medium text-text-color text-right">
              {product?.createdBy || '--'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UPCViewComponent;
