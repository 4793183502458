import React, { useEffect, useState } from 'react'
import TextField from '../../atoms/TextField';
import Select from '../../atoms/Select';
import { MODE } from '../../../utils/constant';

interface Parameter {
    type: string;
    value: string;
    fallBackValue: string;
}

interface ParameterValueProps {
    noOfParameters: number;
    parameterData: Parameter[];
    updateParameterData: any;
    mode: string;
}

export const ParameterValuesFormTemplate: React.FC<ParameterValueProps> = ({ noOfParameters, parameterData, updateParameterData, mode }) => {
    const [formData, setFormData] = useState<Parameter[]>([]);

    const dynamicParameterData = [
        { label: "Customer Name", value: "fullName" },
        { label: "Email", value: "email" },
        { label: "Phone number", value: "mobileNumber" },
        { label: "Customer Business Name", value: "businessName" },
        { label: "Catalogue Name", value: "catalogueName" }
    ]

    useEffect(() => {
        setFormData(parameterData);
    }, [parameterData]);

    useEffect(() => {
        if(noOfParameters > 30) noOfParameters = 0;
        const initialData: Parameter[] = Array.from({ length: noOfParameters }, (_, index) => {
            return parameterData[index] || { type: "STATIC", value: '', fallBackValue: '' };
        });
        updateParameterData(initialData);
        setFormData(initialData);
    }, [noOfParameters]);

    const handleInputChange = (index: number, field: string, event: any) => {
        const newFormData = [...formData];
        newFormData[index] = { ...newFormData[index], [field]: event.target.value };
        setFormData(newFormData);
        updateParameterData(newFormData);
    };

    return (
        <div>
            {formData.map((data, index) => (
                <div key={index} className='flex mb-4 gap-4'>
                    <div className='w-48'>
                        <Select
                            variant="outlined"
                            id='parameter-select'
                            label='Select Parameter Type'
                            value={data.type || "STATIC"}
                            onChange={(e) => handleInputChange(index, "type", e)}
                            options={[
                                { value: "STATIC", label: "STATIC" },
                                { value: "DYNAMIC", label: "DYNAMIC" },
                            ]}
                            inputProps={{
                                readOnly: mode === MODE.VIEW ? true : false,
                                maxLength: 100,
                            }}
                        />
                    </div>
                    {data.type === "STATIC" ? (
                        <div>
                            <TextField
                                fullWidth
                                label='Select Parameter Value'
                                variant='outlined'
                                required
                                name='parameterValue'
                                value={data.value || ''}
                                onChange={(e) => handleInputChange(index, "value", e)}
                                inputProps={{
                                    readOnly: mode === MODE.VIEW ? true : false,
                                    maxLength: 100,
                                }}
                            />
                        </div>
                    ) : (
                        <div className='flex gap-4 w-52'>
                            <Select
                                variant="outlined"
                                labelId='parameter-value-label'
                                id='parameter-value'
                                label='Select Parameter Value'
                                value={data.value || ''}
                                onChange={(e) => handleInputChange(index, "value", e)}
                                options={dynamicParameterData}
                                required
                                inputProps={{
                                    readOnly: mode === MODE.VIEW ? true : false,
                                    maxLength: 100,
                                }}
                            />
                        </div>
                    )}
                    {data.type !== "STATIC" && (
                        <div>
                            <TextField
                                label='Enter Fallback Value'
                                variant='outlined'
                                fullWidth
                                required
                                name='parameterValue'
                                value={data.fallBackValue || ''}
                                onChange={(e) => handleInputChange(index, "fallBackValue", e)}
                                inputProps={{
                                    readOnly: mode === MODE.VIEW ? true : false,
                                    maxLength: 100,
                                }}
                            />
                        </div>
                    )}
                </div>
            ))}
        </div>
    )
}
