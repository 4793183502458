import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom';
import SuperCategoryCreatePage from './SuperCategoryCreate.page';
import MainCategoryCreatePage from './MainCategoryCreate.page';
import ProductCategoryCreatePage from './ProductCategoryCreate.page';
import { CATEGORY_TYPE } from '../../../utils/types';

const CategoryCreatePage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const categoryType: CATEGORY_TYPE = CATEGORY_TYPE[(searchParams.get('type') ?? "SUPER_CATEGORY") as keyof typeof CATEGORY_TYPE];

    const categoryComponent = useMemo(() => {
        switch (categoryType) {
            case CATEGORY_TYPE.MAIN_CATEGORY : return <MainCategoryCreatePage />
            case CATEGORY_TYPE.PRODUCT_CATEGORY: return <ProductCategoryCreatePage />
            default: return <SuperCategoryCreatePage />
        }
    }, [categoryType])

    return <>
        {categoryComponent}
    </>
}

export default CategoryCreatePage