import { ReactNode } from "react";
import {
  ModalHeader,
  ModalType,
} from "../../../../Constants/Procurement/LiveAuction/constants";

interface Porps {
  type: ModalType | null;
  handleClose: () => void;
  children: ReactNode;
}

const ModalWrapper = ({ handleClose, type, children }: Porps) => {
  return (
    <div
      className={`p-6 rounded-3xl bg-white ${
        type === "product" ? "w-[1270px] h-[80vh]" : "w-[615px] min-h-[515px]"
      }`}
    >
      <div className="w-full flex justify-between items-center">
        <div className="text-blue font-bold text-2xl">{ModalHeader(type)}</div>
        <span className="cursor-pointer" onClick={handleClose}>
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.785 23.9507L23.4508 23.285C24.1831 22.5527 24.1831 21.3543 23.4508 20.622L15.3288 12.5L23.4508 4.37804C24.1831 3.64571 24.1831 2.44734 23.4508 1.71501L22.785 1.04925C22.0527 0.316916 20.8543 0.316916 20.122 1.04925L12 9.17121L3.87804 1.04925C3.14571 0.316916 1.94734 0.316916 1.21501 1.04925L0.549251 1.71501C-0.183084 2.44734 -0.183084 3.64571 0.549251 4.37804L8.67121 12.5L0.549251 20.622C-0.183084 21.3543 -0.183084 22.5527 0.549251 23.285L1.21501 23.9507C1.94734 24.6831 3.14571 24.6831 3.87804 23.9507L12 15.8285L20.122 23.9507C20.8543 24.6831 22.0527 24.6831 22.785 23.9507Z"
              fill="#1D3679"
            />
          </svg>
        </span>
      </div>
      {children}
    </div>
  );
};

export default ModalWrapper;
