import { ProductDataType, ProductFormData } from '../PurchaseRequisition/types';
import { Field } from '../constants';
import { AUCTION } from './text';

export interface LiveAuctionBid {
  id: string;
  auctionID: string;
  bidAmount: number;
  isWinnerBid: boolean;
  createdAt: string;
  bidUserID: string;
  bidUserName: string;
  bidType: 'AUTO' | 'MANUAL';
  rank: number;
}

export const LiveAuctionTabs = ['All Auctions'];

export const LiveAuctionTableHeadings: Field[] = [
  { name: 'Sr. No.', align: 'left' },
  { name: 'Auction IDs', align: 'left' },
  { name: 'Indent IDs', align: 'left' },
  { name: 'No of PRID' },
  { name: 'Created Date' },
  { name: 'Created By' },
  { name: 'Auction Status' },
];
export const LiveAuctionFields: Field[] = [
  { name: 'itemId', align: 'left' },
  { name: 'auctionIds', align: 'left' },
  { name: 'indentIds', align: 'left' },
  { name: 'noofPRID' },
  { name: 'createdAt' },
  { name: 'createdBy' },
  { name: 'status' },
];
export const LiveAuctionStatusType: { name: string; value: string }[] = [
  { name: 'All', value: 'READY_FOR_GO_LIVE,CANCELLED,PAUSED,CLOSED,COMPLETED' },
  { name: 'Vendor Invited', value: 'READY_FOR_GO_LIVE' },
  { name: 'Cancelled', value: 'CANCELLED' },
  { name: 'Paused', value: 'PAUSED' },
  { name: 'Closed', value: 'CLOSED' },
  { name: 'Awarded', value: 'COMPLETED' },
];
export const LiveAuctionBidTableHeadings: Field[] = [
  { name: 'Bid Sr No.', align: 'left' },
  { name: 'Vendor Code', align: 'left' },
  { name: 'Vendor Name', align: 'left' },
  { name: 'Last Bid Time' },
  { name: 'Bid Amount' },
  { name: 'Bid Type' },
];
export const LiveAuctionBidTableFields: Field[] = [
  { name: 'itemId', align: 'left' },
  { name: 'vendorCode', align: 'left' },
  { name: 'vendorName', align: 'left' },
  { name: 'lastBidTime' },
  { name: 'bidAmount' },
  { name: 'type' },
];

export interface LiveAuctionListType {
  auctionIds: string;
  indentIds: string;
  noofPRID: number;
  createdAt: string;
  createdBy: string;
  status:
    | 'NOT_STARTED'
    | 'ONGOING'
    | 'STA'
    | 'RE_SCHEDULE'
    | 'AWARDED'
    | 'CLOSED'
    | 'CANCELLED'
    | 'RESTART';
  new: boolean;
}

export type ModalType =
  | 'product'
  | 'award'
  | 'restart'
  | 'close'
  | 'cancel'
  | 'rescheduleStart'
  | 'rescheduleEnd'
  | 'extend';

const modalHeaders = {
  product: AUCTION.ProductDetails,
  award: AUCTION.Award,
  restart: AUCTION.RestartAuction,
  close: AUCTION.CloseAuctionMessage,
  cancel: AUCTION.CancelAuction,
  rescheduleStart: AUCTION.ReScheduleAuctionStart,
  rescheduleEnd: AUCTION.ReScheduleAuctionEnd,
  extend: AUCTION.ExtendTime,
};

export const ModalHeader = (type: ModalType | null) =>
  type ? modalHeaders[type] : '';

export const AuctionPutRequestBody = {
  id: null,
  auctionName: null,
  startDateTime: null,
  endDateTime: null,
  showBestPriceToBidders: null,
  showWinnerNameToBidders: null,
  autoBiddingEnabled: null,
  dynamicTimeExtensionInSec: null,
  dynamicTimeExtensionStartDurationInSec: null,
  auctionStartAmount: null,
  reserveAmount: null,
  targetAmount: null,
  minBidStep: null,
  attachments: null,
  tnc: null,
  status: null,
};

export interface Auction {
  id: string;
  auctionName: string;
  entityID: string;
  startDateTime: number;
  endDateTime: number;
  showBestPriceToBidders: boolean;
  showWinnerNameToBidders: boolean;
  autoBiddingEnabled: boolean;
  dynamicTimeExtensionInSec: number;
  dynamicTimeExtensionStartDurationInSec: number;
  auctionStartAmount: number;
  reserveAmount: number;
  targetAmount: number;
  minBidStep: number;
  attachments: string[];
  tnc: string;
  status: string;
  rfqDocURLs: string[] | null;
  createdBy: string;
  createdAt: number;
}
export interface AuctionProduct {
  id: string;
  purchaseRequisitionId: string;
  quantity: number;
  uom: string;
}
export type AuctionParamsType = {
  searchTerm: string;
  statuses: string;
  pageSize: number;
  pageNumber: number;
  isUserAuctions: boolean;
  fromDate: number;
  toDate: number;
  isUserDrafts: boolean;
};

export interface AuctionProductList extends ProductFormData {
  warehouseId: string;
  item: ProductDataType;
}

export interface Bidder {
  vendorId: string;
  vendorName: string;
  bidAmount: number;
  id: string;
  createdByName: string;
}
