import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
// import React, { useContext, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';

import Button from '@mui/material/Button';

import CloseIcon from '@mui/icons-material/Close';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControlLabel from '@mui/material/FormControlLabel';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from "@mui/material/FormGroup";

import * as yup from "yup";

// import './Manufacturer/Manufacture.css';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
// FORMIK CODE 

import DisplayDocumentStandard from '../../../Shared/Files/UploadSection';
import { FilesWrapper } from '../../../Shared/Files/DisplayFiles';
import JoditEditor from 'jodit-react';
import UploadDocumentIndex from '../../../Shared/Files/UploadDocumentsIndex';
import AutoCompleteTextField from '../Product Standard/Standard/AutoCompleteInputField';
import UOMJsonData from './UOM.json';
import CustomAttributeInputTags from '../../../Shared/Chips/CustomAttributeInputTags';
import StatusToggle from '../UPC/StatusToggle/StatusToggle';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import { getErrorMessageByStatusCode, toCamelCase } from '../../../../utils/helper';
import { MODE, RESOURCES } from '../../../../utils/constant';
import { request } from '../../../../services';
import { HTTP_STATUS } from '../../../../utils/types';
const BaseUrl = process.env.REACT_APP_API_V1_URL;

const convertSuggestions = (suggestions) => {
    return suggestions.map((item, index) => ({ label: item, id: index + 1 }));
};

const convertUOMSuggestions = (suggestions) => {
    return Object.keys(suggestions).map((key, index) => ({
        label: key,
        id: index + 1,
        shortcode: suggestions[key].shortcode,
        fullDescription: suggestions[key].fullDescription,
    }));
};
const AddNewAttribute = () => {
    const { user : authenticatedUser } = useAuthenticatedUser();
    const [value, setValue] = React.useState(0);
    const navigate = useNavigate();
    const location = useLocation();

    const { type, itemId } = location.state || {};
    const [isActive, setIsActive] = useState(true);
    // Images Uploaded
    const [imageData, setImageData] = useState([]);
    const [formImageData, setFormImageData] = useState([]);
    const [formData, setFormData] = useState({
        attributeTypes: [],
        uomValue: '',
        fieldType: [],
    });
    const [UOMSuggestions, setUOMSuggestions] = useState([]);
    const [isUomCheckSelected, setIsUomCheckSelected] = useState(false);
    const [attributeTypesSuggestions, setAttributeTypesSuggestions] = useState([]);
    const [existingTags, setExistingTags] = useState([]);

    const displayTitle = (data) => {
        if (data === "ADD") {
            return "Add"
        } else if (data === "VIEW") {
            return "View"
        } else if (data === "EDIT") {
            return "Edit"
        }
    }

    const disableComponent = (data) => {
        if (data === "VIEW") {
            return true
        } else {
            return false
        }
    }

    const disableUOM = (data) => {
        if (data === "VIEW" ) {
            return true
        } else {
            return false
        }
    }

    const displayNewKeyWord = (data) => {
        if (data === "ADD") {
            return "New"
        }
    }
    // Form Data


    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };



    const filterUpcCodesFromList = (data) => {
        var mapData = {};
        if (!data || data.length === 0) {
            console.error('Data array is empty or invalid');
            return [];
        }

        const filteredList = [];

        for (const item of data) {
            if (!item.name) {
                console.error('upcCode is missing in an element:', item);
                continue;
            }
            // mapData[item.name] = item.id;
            filteredList.push({ label: item.name, id: item.id });
        }
        setAttributeTypesSuggestions(filteredList)
        return filteredList;
    }


    // new code for formnik
    const errorMessageColor = '#D32F2F'
    const standardInitialValues = {
        name: '',
        attributeType: '',
        uomCheck: false,
        upcCheck: false,
        mpcCheck: false,
        description: '',
        attributeTypesId: '',
        uomValue: '',
        fieldType: '',
        status: isActive,
        attributeOptions: [],
        attributeRank: 1
    };
    var validateFormikFields = yup.object({
        name: yup.string().typeError("Name must be a string").required("Name is required"),
        attributeOptions: yup
            .array()
            .of(yup.string())
            .min(1, "Field Types must not be empty")
            .required("Field Types is required"),
        attributeType: yup.string().typeError("Attributes Type must be a string").required("Attributes Type is required"),
        uomValue: isUomCheckSelected && yup.object().required("Please select UOM value"),
        fieldType: yup.string().typeError("Feild Type must be a string").required("Feild Type is required"),
        description: yup.string().typeError("Description must be a string").required("Description is required").test('no-p-br-tags', 'Description cannot be Empty', ((value) => value !== '<p><br></p>')),
        upcCheck: yup.boolean(),
        mpcCheck: yup.boolean(),
        attributeRank: yup.number()
        .typeError('Attribute rank must be a number')
        .positive('Attribute rank must be a positive number') 
        .integer('Attribute rank must be an integer').required('Please Enter Attribute rank')
    });


    const standardFormik = useFormik({
        initialValues: standardInitialValues,
        validationSchema: validateFormikFields,
        onSubmit: (values, { setSubmitting, setStatus }) => {
            setSubmitting(true)
            handleCreate(values);        
        },
    });

    useEffect(() => {
        if (type === 'VIEW' || type === 'EDIT') {
            // Call the fetchData function when type or itemId changes
            fetchData();
            fetchDocuments(itemId, "STANDARD_GRADE");
        }
    }, [type, itemId])

    const transformData = (type, value) => {
        if (type === 'DROPDOWN') {
            return JSON.parse(value)
        } else {
            return JSON.parse(value)
        }
    }

    const fetchData = () => {
        axios.get(`${BaseUrl}/attribute/${itemId}`,{
            headers: {
               Authorization: `${authenticatedUser.token}`,
               "Content-Type": "application/json",
            }
         })
            .then((response) => {
                const responseData = response.data.data;
                // Set Formik values
                var UOMData = {
                    label: responseData.uom,
                    ...UOMJsonData[responseData.uom],
                }
                setExistingTags(transformData(responseData.fieldType, responseData.attributeOptions) || [])
                standardFormik.setValues({
                    name: responseData.name || '',
                    upcCheck: responseData.isUpc || false,
                    mpcCheck: responseData.isMpc || false,
                    description: responseData.description || '',
                    status: responseData.status === 'ACTIVE' ? true : false,
                    attributeType: responseData.attributeType,
                    // Set this value
                    uomCheck: responseData.uom !== null,
                    attributeTypesId: responseData.parentId || '',
                    attributeOptions: transformData(responseData.fieldType, responseData.attributeOptions) || [],
                    attributeRank : responseData.attributeRank, 
                    // Set teh value from Enum
                    uomValue: UOMData || '',
                    // Convert this
                    fieldType: responseData.fieldType || 'DROPDOWN'

                });
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    const fetchDocuments = async (resId, resType) => {
        try {
            if (!resId || !resType) {
                return;
            }
            const apiUrl = `${BaseUrl}/files/${resType}/${resId}`;
            const response = await axios.get(apiUrl,{
                headers: {
                   Authorization: `${authenticatedUser.token}`
                }
             }).then((res) => {
                if (res && res.data && res.data.data) {
                    setImageData(res.data.data);
                }

                return;
            });
        } catch (err) {
            return;
        }
    };
    const fetchAttributesTypes = async (searchValue = '') => {
        try {
            const response = await axios.get(`${BaseUrl}/attributes`, {
                params: {
                    attributeType: 'ATTRIBUTETYPE',
                    name: searchValue,
                    page: 0,
                    size: 10,
                },
                headers: {
                    Authorization: `${authenticatedUser.token}`,
                    "Content-Type": "application/json",
                  }
        
            });
            const data = response?.data?.data?.content;
            setAttributeTypesSuggestions(filterUpcCodesFromList(data));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    // Fetch data from your API and update the state
    const fetchUOMData = async () => {
        try {
            var convertedDetails = convertUOMSuggestions(UOMJsonData)
            setUOMSuggestions(convertedDetails);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    function arrayToCommaSeparatedString(arr, fieldType) {
        if (fieldType === "DROPDOWN") {
            return JSON.stringify(arr);
        } else {
            if (!Array.isArray(arr) || arr.length === 0) {
                throw new Error('Input is not a valid array');
            }

            const formattedString = arr.map((str) => {
                if (typeof str !== 'string') {
                    throw new Error('Array contains non-string values');
                }
                return str.replace(/'/g, '');
            }).join(',');
            return formattedString;
        }
    }

    useEffect(() => {
        fetchUOMData()
      }, []);

    const handleCreate = async (values) => {
        try {
            // Create an object containing the data you want to send to the API
            const postData = {
                name: values.name.trim(),
                attributeType: values.attributeType,
                fieldType: values.fieldType,
                // attributeOptions: arrayToCommaSeparatedString(values.attributeOptions, values.fieldType),
                attributeOptions: values.attributeOptions,
                isUpc: values.upcCheck,
                isMpc: values.mpcCheck,
                description: values.description,
                uom: values.uomValue?.label,
                status: values.status ? 'ACTIVE' : 'INACTIVE',
                parentId: null,
                attributeRank: values.attributeRank
            };
            // return
            const apiUrl = type === "ADD" ? `${BaseUrl}/attribute` : `${BaseUrl}/attribute/${itemId}`;
            const httpMethod = type === "ADD" ? "POST" : "PUT";

            const response = await request(
                httpMethod,
                apiUrl,
                authenticatedUser,
                postData,
                // headers: {
                //   Authorization: `${user.token}`,
                //   "Content-Type": "application/json",
                // }
              );
            if (response.status === HTTP_STATUS.OK) {
                var resourceId = response.data.data.id;
                var resourceType = "STANDARD_GRADE";
                var createdBy = response.data.data.createdBy;
                if (imageData.length > 0 || formImageData.length > 0) {
                    if (
                        handleCatalogueImageData(
                            type === "ADD" ? imageData : formImageData,
                            resourceId,
                            resourceType,
                            createdBy
                        )
                    ) {

                        navigate("/attribute", {
                            state: {
                                type: 'Attribute'
                            }
                        });
                        return
                    }

                }

                // You can perform other actions based on the response, such as showing a success message.
                navigate("/attribute", {
                    state: {
                        type: 'Attribute'
                    }
                });
            }else if(response.data.statusMessage === "DUPLICATE_ATTRIBUTE"){
                alert("Attribute already exists. Please try again");
            }


        } catch (error) {
            // Handle errors here, such as displaying an error message to the user
            const errorMessage = getErrorMessageByStatusCode(error.response.status, RESOURCES.ATTRIBUTE);
            alert(errorMessage);
        }
    };



    // const editor = useRef(null);
    const attributeApplication = useRef(null);
    const config = useMemo(() => {
        return {
            readonly: disableComponent(type),
            placeholder: 'Enter Attribute Description ...',
        };
    }, []);

    const [content, setContent] = useState('');

    // Image data handler functions
    const updateImages = (images) => {
        // setImageData(images);
        setImageData((prevImages) => [...prevImages, ...images]);
    };

    const handleRemoveImage = (id, isForm) => {
        const updatedDetails = imageData.filter(
            (detail, currentIndex) => currentIndex !== id
        );
        setImageData(updatedDetails);
    };

    const handleCatalogueImageData = async (
        imageData,
        resourceId,
        resourceType,
        createdBy
    ) => {

        if (!resourceId || !resourceType) {
            return false;
        }

        const formData = new FormData();

        for (let i = 0; i < imageData.length; i++) {
            formData.append(`files`, imageData[i]);
        }

        const uploadFilesData = {
            resourceType: resourceType,
            resourceId: resourceId,
            createdBy: createdBy,
        };

        formData.append(
            "resource",
            new Blob([JSON.stringify(uploadFilesData)], {
                type: "application/json",
            })
        );

        try {
            await axios
                .post(`${BaseUrl}/files/`, formData ,{
                    headers: {
                       Authorization: `${authenticatedUser.token}`
                    }
                 })
                .then((data) => {
                    return true;
                });
            // Handle success
        } catch (error) {
            // Handle error
            return false;
        }
    };

    const deleteDocumentState = (id, documentData) => {
        const updatedDetails = documentData.filter((_, index) => index !== id);
        return updatedDetails;
    };



    const deleteDocument = async (documentIndex, isForm) => {
        try {

            if (isForm) {
                // If there's no id, delete the document from imageData array
                setFormImageData(deleteDocumentState(documentIndex, formImageData));
            } else {

                const documentId = imageData[documentIndex].id;
                const apiUrl = `${BaseUrl}/files/${documentId}`;
                const response = await axios.delete(apiUrl ,{
                    headers: {
                       Authorization: `${authenticatedUser.token}`
                    }
                 }).then((res) => {
                    setImageData(deleteDocumentState(documentIndex, imageData));
                    return;
                }).Error(() => {
                    return
                });
            }
        } catch (err) {
            return
        }
    };

    const updateFormImages = (images) => {
        setFormImageData((prevImages) => [...prevImages, ...images]);
    };

    const delayedSearchFetch = debounce(fetchAttributesTypes, 700);
    const delayedSearchUOMFetch = debounce(fetchUOMData, 700);
    const handleAttributeTypeSearch = (searchValue) => {
        if (searchValue) {
            standardFormik.setFieldValue('attributeType', searchValue.label);
            standardFormik.setFieldValue('attributeTypesId', searchValue.id);
        } else {
            standardFormik.setFieldValue('attributeType', ''); // Clear the field if no match
            standardFormik.setFieldValue('attributeTypesId', ''); // Clear the field if no match
        }
    };

    const handleSwitchToggle = () => {
        // Invert the current status value and update the form field
        const updatedStatus = !standardFormik.values.status;
        standardFormik.setFieldValue('status', updatedStatus);
    };

    const handleUpdateUPCFormDetails = (key, payload) => {
        console.log('Updating status Form Data - ', key, payload);
        // setFormData((prev) => ({
        //   ...prev,
        //   [key]: payload,
        // }));
        standardFormik.setFieldValue('status', payload);
    
        
      };

    const updateFieldValues = (fieldsValues) => {
        standardFormik.setFieldValue('attributeOptions', fieldsValues);
    }

    const updateAttributeOptionText = (event) => {
        var addedValue = event.target.value
        standardFormik.setFieldValue('attributeOptions', [addedValue]);
    }

    const handleUomChange = () => {
        const uomCheckSelected = !standardFormik.values.uomCheck;
        standardFormik.setFieldValue('uomCheck', uomCheckSelected);
        standardFormik.setFieldValue('uomValue', '');
        setIsUomCheckSelected(uomCheckSelected);
    };
        
    const updateUOMValues = (uomData) => {
        standardFormik.setFieldValue('uomValue', uomData);
    }
    const AttributeData = ({ type, value }) => {
        return (
            <div className="flex flex-col items-start justify-start gap-[6px]">
                <div className="relative font-medium" style={{ textDecoration: 'underline', fontWeight: 'bold' }}>{type}</div>
                <div className="relative">{value}</div>
            </div>
        );
    };

    const handleBackAction = () => {
        navigate("/attribute", {
            state: {
                type: 'Attribute'
            }
        })
    }
    const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
		}
	};

    return (
        <form onSubmit={standardFormik.handleSubmit} onKeyDown={handleKeyDown}>
            <Box className='common-pop-up'>
                <div className="common-pop-up-wrapper">
                    <div className="new-manufacture-header">
                    <h3>{type === MODE.EDIT ? "Update" : type === MODE.VIEW ? "View" : "Add New"} Attribute</h3>
                    <CloseIcon className='close-icon' onClick={() => handleBackAction()} />
                    </div>
                </div>
                <Box>
                    <div className="manufacture-status-wrapper">

                        <div className="options-main pop-inputs">
                            <TextField
                                className={`block w-full p-2 border border-gray-300 rounded-md focus:outline-none ring-blue-500 focus:border-blue-500`}
                                id='outlined-basic'
                                label='Enter Attribute Name'
                                variant='outlined'
                                fullWidth
                                name='name'
                                value={standardFormik.values.name}
                                onChange={standardFormik.handleChange}
                                onBlur={(e) => {
                                    const camelCasedValue = toCamelCase(e.target.value);
                                    standardFormik.setFieldValue('name', camelCasedValue);
                                    standardFormik.handleBlur(e);
                                }}
                                error={standardFormik.touched.name && Boolean(standardFormik.errors.name)}
                                disabled={type !== MODE.ADD}
                            />
                            <FormControl fullWidth>
                                <InputLabel id='status-label'>Select Attribute Type</InputLabel>
                                <Select
                                    {...standardFormik.getFieldProps('attributeType')}
                                    labelId='status-label'
                                    id='status-select'
                                    label='Select Attribute Type'
                                    name="attributeType"
                                    value={standardFormik.values.attributeType}
                                    onChange={standardFormik.handleChange}
                                    onBlur={standardFormik.handleBlur}
                                    error={standardFormik.touched.attributeType && Boolean(standardFormik.errors.attributeType)}
                                    disabled={disableComponent(type)}
                                >
                                    <MenuItem value="SPECIFICATION">Specification</MenuItem>

                                </Select>
                            </FormControl>
                            <TextField
                                {...standardFormik.getFieldProps('attributeRank')}
                                className={`block p-2 border border-gray-300 rounded-md focus:outline-none ring-blue-500 focus:border-blue-500`}
                                id='attribute_rank'
                                label='Attribute Rank'
                                variant='outlined'
                                fullWidth
                                error={standardFormik.touched.attributeRank && !!standardFormik.errors.attributeRank}
                                disabled={type === MODE.VIEW}
                                helperText={standardFormik.touched.attributeRank && !!standardFormik.errors.attributeRank && standardFormik.errors.attributeRank}
                            />
                        </div>

                        <div className="options-main pop-inputs">
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id='status-label'>Select Field Type</InputLabel>
                                <Select
                                    {...standardFormik.getFieldProps('fieldType')}
                                    labelId='status-label'
                                    id='status-select'
                                    label='Select Attribute Type'
                                    name="fieldType"
                                    value={standardFormik.values.fieldType}
                                    onChange={standardFormik.handleChange}
                                    onBlur={standardFormik.handleBlur}
                                    error={standardFormik.touched.fieldType && Boolean(standardFormik.errors.fieldType)}
                                    disabled={disableComponent(type)}
                                >
                                    <MenuItem value="DROPDOWN">Drop Down</MenuItem>
                                    {/* <MenuItem value="TEXT">Text</MenuItem> */}
                                </Select>
                            </FormControl>
                        </div>



                        <div className="options-main pop-inputs">
                            <FormControl sx={{ width: '100%' }}>
                                {standardFormik.values.fieldType === 'TEXT' ? (
                                    <div>
                                        <TextField
                                            className={`block w-full p-2 border border-gray-300 rounded-md focus:outline-none ring-blue-500 focus:border-blue-500`}
                                            id='outlined-basic'
                                            label='Enter Attribute Options'
                                            variant='outlined'
                                            fullWidth
                                            name='attributeOptions'
                                            value={standardFormik.values.attributeOptions}
                                            onChange={(event) => updateAttributeOptionText(event)}
                                            onBlur={standardFormik.handleBlur}
                                            error={standardFormik.touched.attributeOptions && Boolean(standardFormik.errors.attributeOptions)}
                                        />
                                    </div>
                                ) : (
                                    <CustomAttributeInputTags
                                        updateFieldData={updateFieldValues}
                                        displayLabel={'Add Attribute Options'}
                                        initialValue={standardFormik.values}
                                        existingValues={existingTags}
                                        mode={disableComponent(type)}
                                        validationRegex={/^.*$/}
                                        error={{ error: standardFormik.touched.attributeOptions && standardFormik.values.attributeOptions.length === 0, msg: "Options is required" }}
                                        setError={()=>{}}
                                    />
                                )}
                            </FormControl>
                        </div>

                        <div className="attribute-options">
                            <div className="attribute-content">
                                <p>Whether Any UOM Parent attribute type?</p>
                                <FormControlLabel control={<Checkbox
                                    checked={standardFormik.values.uomCheck}
                                    onChange={handleUomChange}
                                    onBlur={standardFormik.handleBlur}
                                    disabled={disableUOM(type)}

                                />} label="Yes" labelId='status-label'
                                    name='uomCheck'
                                    error={standardFormik.touched.uomCheck && Boolean(standardFormik.errors.uomCheck)} />
                            </div>


                            {standardFormik.values.uomCheck && (
                                <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[24px] text-base text-text-color"
                                    style={{ marginTop: '10px', marginBottom: '10px', padding: "5px" }}>
                                    <AttributeData type={'Unit of Measurement(UOM)'} value={standardFormik.values.uomValue?.fullDescription} />
                                    <AttributeData type={'Short Code'} value={standardFormik.values.uomValue?.shortcode} />
                                    {standardFormik.touched.uomValue && standardFormik.errors.uomValue && (<div style={{ color: errorMessageColor }}>{standardFormik.errors.uomValue}</div>)}                                
                                    </div>
                            )}

                            {!disableUOM(type) && standardFormik.values.uomCheck && (
                                <div>
                                    <FormControl sx={{ width: '100%', margin: '2px 0px 10px 0px' }}>
                                        <AutoCompleteTextField
                                            suggestions={UOMSuggestions}
                                            updateDataSelection={updateUOMValues}
                                            onSearch={delayedSearchUOMFetch}
                                            textLable={"Select UOM"}
                                            enableFlag={disableUOM(type)}
                                            displayValue={standardFormik.values.uomValue?.label || ""}
                                        />
                                    </FormControl></div>
                            )}
                            {/* <div className="attribute-content">
                                <p>Do you need this attribute in UPC?</p>
                                <FormControlLabel
                                    control={<Checkbox
                                        {...standardFormik.getFieldProps('upcCheck')}
                                        checked={standardFormik.values.upcCheck}
                                        color="primary"
                                        disabled={disableComponent(type)}

                                    />}

                                    label="Yes"
                                />
                            </div>
                            <div className="attribute-content">
                                <p>Do you need this attribute in MPC?</p>

                                <FormControlLabel control={<Checkbox

                                    {...standardFormik.getFieldProps('mpcCheck')}
                                    checked={standardFormik.values.mpcCheck}
                                    color="primary"
                                    disabled={disableComponent(type)}
                                />} label="Yes"
                                />

                            </div> */}
                        </div>
                        {/* <div className="">
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            {...standardFormik.getFieldProps('status')}
                                            checked={standardFormik.values.status}
                                            color="primary"
                                            disabled={disableComponent(type)}
                                        />
                                    }
                                    label="Status"
                                />
                            </FormGroup>
                            Status is Now {standardFormik.values.status ? "Active" : "Inactive"}.


                            If u want Inactive use Toggle
                        </div> */}

                        <div className="mt-3 mb-3">
                            <FormGroup>
                                <StatusToggle
                                    formData={{ status: standardFormik.values.status }}
                                    handleUpdateFormDetails={(type, value) => standardFormik.setFieldValue('status', value)}
                                    disabled={disableComponent(type)}
                                />
                            </FormGroup>
                        </div>

                        <FormControl fullWidth>
                            <JoditEditor
                                ref={attributeApplication}
                                value={standardFormik.values.description}
                                config={config}
                                onBlur={(newContent) => standardFormik.setFieldValue('description', newContent)}
                            />

                            {/* Display validation errors */}
                            {standardFormik.errors.description && standardFormik.touched.description && (
                                <div style={{ color: errorMessageColor }}>{standardFormik.errors.description}</div>
                            )}
                        </FormControl>

{/* Discussed with Varshith upload image is not required in Attribute section */}

                        {/* <FilesWrapper heading={"Uploaded Documents"}>

                            <DisplayDocumentStandard
                                mode={type}
                                imageData={imageData}
                                formImageData={formImageData}
                                deleteDocumentHandler={deleteDocument}
                                handleRemoveImage={handleRemoveImage}
                            />

                        </FilesWrapper>

                        {type !== "VIEW" ? (<UploadDocumentIndex displayTitle={'Upload Icon'} updateImages={type === "ADD" ? updateImages : updateFormImages} />) : null} */}



                    </div>
                </Box>
                <div className="page-btn-wrapper">
                    <div className="page-btn-1">
                        <Button variant="outlined" className='cancle-btn p-btn' type='button' onClick={() => handleBackAction()}>Cancel</Button>
                    </div>
                    {type !== 'VIEW' && (
                        <div className="page-btn-2">
                            <Button variant="contained" className='next-btn p-btn' type='submit'>

                                {type === 'ADD' ? 'Add Attribute' : 'Update Attribute'}

                            </Button>
                        </div>)}
                </div>
            </Box>
        </form>

    )
}

export default AddNewAttribute;
