import React, { useState, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import useCreate from "../../Core/CustomHooks/useCreate";
import DialogActions from "@mui/material/DialogActions";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import { Typography, Button, TextField } from "@mui/material";

export default function ChangePasswordDialog({ isOpen, onClose }) {
  const [ oldPassword, setOldPassword ] = useState("");
  const [ oldPasswordError, setoldPasswordError ] = useState(false);
  const [ passwordError, setPasswordError ] = useState(false);
  const [ newPassword, setNewPassword ] = useState("");
  const [ showPassword, setShowPassword ] = useState(false);
  const [ reenterNewPassword, setReenterNewPassword ] = useState("");
  const [ reEnterNewPasswordError, setReEnterNewPasswordError ] = useState(false);
  const { loading, putRequest } = useCreate();
  const [errorMessage, setErrorMessage] = useState({ oldPassword: "", newPassword: "", reenterNewPassword: "" });

  const handleErrorUpdate = (fieldName, value) => {
    setErrorMessage((prevErrors) => ({
      ...prevErrors,
      [fieldName]: value,
    }));
  };

  const handleoldPasswordChange = (event) => {
    setOldPassword(event.target.value);
    if (event.target.value.length < 8) {
      setoldPasswordError(true);
    } else if (event.target.value.length > 8) {
      setoldPasswordError(false);
    }
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
    const isValidPassword = passwordRegex.test(event.target.value);
    if (!isValidPassword) {
      setPasswordError(true);
      handleErrorUpdate("newPassword",
        "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one special character, and one digit"
      );
    } else {
      setPasswordError(false);
    }
    setReEnterNewPasswordError(false);
    setReenterNewPassword("")
  };

  const handleReenterNewPasswordChange = (event) => {
    setReenterNewPassword(event.target.value);
    if (event.target.value !== newPassword) {
      setReEnterNewPasswordError(true);
      // setErrorMessage("Password does not match");
    } else {
      setReEnterNewPasswordError(false);
    }

  };

  const handleUpdate = async () => {

    if (passwordError || oldPasswordError || reEnterNewPasswordError) {
      return;
    }
    let flag = false;
    if (!reenterNewPassword) {
      setReEnterNewPasswordError(true);
      handleErrorUpdate("reenterNewPassword","Password field should not be empty");
      flag = true;
    }
    if (!oldPassword) {
      setoldPasswordError(true);
      handleErrorUpdate("oldPassword","Password field should not be empty");
      flag = true;
    }
    if (!newPassword) {
      // setPasswordError(true);
      handleErrorUpdate("newPassword","Password field should not be empty");
      flag = true;
    }
    if (oldPassword === newPassword) {
      setPasswordError(true);
      handleErrorUpdate("newPassword","New password should not match with existing one");
      flag = true;
    }

    if(flag) return;
    if (!reenterNewPassword || reenterNewPassword !== newPassword) {
      setReEnterNewPasswordError(true);
      handleErrorUpdate("Password does not  match")
      return;
    }
    // if (passwordError || oldPasswordError || reEnterNewPasswordError) {
    //   setReEnterNewPasswordError(true);
    //   setoldPasswordError(true);
    //   setPasswordError(true);
    //   setErrorMessage("Please Enter Required Fields");
    //   return; //prevents form submission
    // }
    let apiRes = await putRequest("/user/password", {
      oldPassword,
      newPassword,
    });
    if (apiRes === undefined) {
      console.log("Internal server error occured");
      return;
    }
    if (apiRes?.status === 200) {
      // success
      setOldPassword("");
      setNewPassword("********");
      setReenterNewPassword("********");
      setPasswordError({});
      onClose();
    } else if (apiRes?.status === 400) {
      setoldPasswordError(true)
      setErrorMessage("oldPassword","Incorrect old password")
      // return;
      console.log("Incorrect old password");
    } else {
      console.log("Something went wrong");
    }
    // onClose();
  };

  const handleCancel = () => {
    setOldPassword("");
    setNewPassword("");
    setReenterNewPassword("");
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div style={{ padding: "1.5rem 2.5rem 2.5rem 2.5rem" }}>
        <Typography style={{ color: "#1D3679" }} variant="h6" gutterBottom>
          Change Password
        </Typography>
        <Typography variant="body1" paragraph>
          Use the form below to change the password for your account
        </Typography>
        <form className="flex flex-col gap-y-5">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "1.375rem",
              alignSelf: "stretch",
              marginBottom: "16px",
            }}
          >
            <TextField
              required
              label="old Password"
              type="password"
              placeholder="Enter password"
              variant="outlined"
              fullWidth
              value={oldPassword}
              onChange={handleoldPasswordChange}
              error={oldPasswordError}
              helperText={oldPasswordError && errorMessage?.oldPassword}
            />

            <TextField
              required
              label="New Password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              // type="password"
              fullWidth
              placeholder="Enter new password"
              value={newPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              // autoComplete: "off",
              autoFocus={true}
              onChange={handleNewPasswordChange}
              error={passwordError}
              helperText={passwordError && errorMessage?.newPassword}
            />
            <TextField
              required
              label="Re-enter new Password"
              variant="outlined"
              type="password"
              fullWidth
              placeholder="Enter password again"
              value={reenterNewPassword}
              inputProps={{
                autoComplete: "off",
              }}
              onChange={handleReenterNewPasswordChange}
              error={reEnterNewPasswordError}
              helperText={reEnterNewPasswordError && errorMessage?.reenterNewPassword}
            />
          </div>
        </form>

        <DialogActions>
          <Button
            variant="outlined"
            onClick={onClose}
            style={{ color: "#1D3679" }}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            style={{ color: "#fff", background: "#1D3679" }}
            onClick={handleUpdate}
          >
            Update
          </Button>

        </DialogActions>
      </div>
    </Dialog>
  );
}
