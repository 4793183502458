import { FormikProps } from 'formik'
import React, { useEffect, useState } from 'react'
import { IMaterialOtherPreference } from './MaterialOtherPreferences.template';
import { createUseStyles } from 'react-jss';
import { HTTP_STATUS, MODE } from '../../../../utils/types';
import { capitalizeFirstLetter } from '../../../../utils/helper';
import ButtonV2 from '../../../atoms/ButtonV2';
import { IPreferredProductCategory } from './PreferredProductCategoy.template';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { ICategory } from './CategorySelection.template';
import { useCategoryService } from '../../../../services/useCategoryService';

interface PreferredProductCategoryViewTemplateProps {
    formik: FormikProps<IPreferredProductCategory>
    setModeTo: (value: MODE) => void;
    mode: MODE;
    setIsFormModeTo: (value: boolean) => void;
}

const useStyles = createUseStyles((theme: any) => ({
    primary500: {
        color: theme.palette.textV2.primary.primary500,
    },
    borderColor: {
        border: `1px solid ${theme.palette.backgroundV2.secondary.secondary50}`
    },
    heading: {
        color: theme.palette.textV2.tertiary.tertiary700
    },
    tertiary100Border: {
        borderColor: theme.palette.textV2.tertiary.tertiary100
    },
    sectionBackground: {
        background: theme.palette.backgroundV2.tertiary.tertiary500
    },
    tertiary700: {
        color: theme.palette.textV2.tertiary.tertiary700
    },
    primaryBorder: {
        borderColor: theme.palette.borderV2.primary.primary500
    }
}));

const PreferredProductCategoryViewTemplate: React.FC<PreferredProductCategoryViewTemplateProps> = ({ formik, setModeTo, mode, setIsFormModeTo }) => {
    const classes = useStyles();
    const [categories, setCategories] = useState<ICategory[]>([]);
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const categoryService = useCategoryService();
    const handleEdit = () => {
        setIsFormModeTo(true);
    }

    const fetchCategoriesByIds = async (ids: number[]) => {
        if (!!!ids) return;
        let res = await categoryService.getAllCategoriesByIds(ids);
        if (res.status === HTTP_STATUS.OK) {
            setCategories(res?.data?.data);
        } else {
            showSnackbar('error', 'Failed to fetch categories')
        }
    }

    useEffect(() => {
        fetchCategoriesByIds(formik.values.productCategories)
    }, [formik.values.productCategories])

    return (
        <div className={` p-6 grid gap-6 rounded-lg w-full ${classes.sectionBackground}`}>
            {SnackBarComponent}
            <div className={`grid gap-6 w-full`}>
                <div className={`w-full flex justify-between items-center`}>
                    <span className={`${classes.heading} text-2xl`}>Preferred Product Category</span>
                    <ButtonV2 variant='tertiaryContained' label={`${mode !== MODE.ADD ? 'Edit' : 'Add'}`} className='!font-normal' onClick={handleEdit} />
                </div>
                <div className={`${classes.tertiary100Border} border-b w-full`}></div>
                {mode !== MODE.ADD && <div className={`grid gap-6 w-full`}>
                    <div className='w-full'>
                        <div className={`${classes.tertiary700} font-normal text-base flex gap-4  `}>
                            <div className='flex  flex-wrap'>
                                {(categories)?.map((category, index) => (
                                    <div className='flex '>
                                        <span className={`${classes.primary500} font-medium`}>{category?.name}</span>
                                        {!(formik.values?.productCategories?.length - 1 === index) && <span className='mx-4'> | </span>}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default PreferredProductCategoryViewTemplate