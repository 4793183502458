import { useState, useEffect, useMemo } from 'react';
import noData from '../../../assets/images/noDataImage.svg';
import BusinessProfileNoDataTemplate from './BusinessProfileNoData.template';
import TurnOverUpdateTemplate from './TurnOverUpdate.template';
import { IBusinessProfile } from '../../pages/CustomerManagement/ProfileOverview.page';
import TurnOverViewTemplate from './TurnOverView.template';

interface ITurnOverTemplateProps {
    businessProfile: IBusinessProfile | null;
    updateBusinessProfileSection: (section: string, values: any) => void;
}

const TurnOverTemplate: React.FC<ITurnOverTemplateProps> = ({ businessProfile, updateBusinessProfileSection }) => {

    const [isEditing, setIsEditing] = useState(false);
    const [turnOverMap, setTurnOverMap] = useState<{ [key: string]: number }>({});

    const TurnOverSectionView = useMemo(() => {
        if (isEditing) {
            return (
                <TurnOverUpdateTemplate
                    businessProfile={businessProfile}
                    updateBusinessProfileSection={updateBusinessProfileSection}
                    setIsEditing={setIsEditing}
                />
            );
        }
        if (businessProfile?.finance) {
            return (
                <TurnOverViewTemplate
                    setIsEditing={setIsEditing}
                    turnOverMap={turnOverMap}
                />
            );
        }
        return (
            <BusinessProfileNoDataTemplate
                heading="You haven't provided Turn over Details for your business."
                setIsEditing={setIsEditing}
                noDataImg={noData}
                label="Add Turn over Details"
            />
        );
    }, [isEditing, businessProfile, updateBusinessProfileSection, turnOverMap]);

    useEffect(() => {
        if (businessProfile?.finance?.turnOvers) {
            const turnOverData = businessProfile.finance.turnOvers.reduce((acc: any, curr: any) => {
                acc[curr.year] = curr.amount;
                return acc;
            }, {} as { [key: string]: number });
            setTurnOverMap(turnOverData);
        }
    }, [businessProfile?.finance]);

    return <>{TurnOverSectionView}</>;
};

export default TurnOverTemplate;
