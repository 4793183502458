import React, { useState } from "react";
import useCreate from "../../Core/CustomHooks/useCreate";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, TextField, InputAdornment } from "@mui/material";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";

const EditNumberDialog = ({ open,openOtpDialog, onClose ,setfullNum}) => {
  const [fullNumber, setFullNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [showVerifyDialog, setShowVerifyDialog] = useState(false);
  const { user, setAuthenticatedUser } = useAuthenticatedUser();
  const [errorMessage, setErrorMessage] = useState("Please enter a valid mobile number");
  const { loading, putRequest } = useCreate();

  const handleNumberChange = (event) => {
    const { value } = event.target;
    const phoneNumberRegex = /^[0-9]{10}$/;
    if (value.length > 10) return;
    const isValidPhoneNumber = phoneNumberRegex.test(value);

    setErrorMessage("Please enter a valid phone number");
    setFullNumber(value);
    setPhoneNumberError(!isValidPhoneNumber);
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    if ((fullNumber.length < 10) | (fullNumber.length > 10)) {
      setPhoneNumberError(true);
      setErrorMessage("Please enter a valid mobile number");
      return;
    }

    let apiRes = await putRequest("/user/mobileNumber", {
      mobileNumber: fullNumber,
    });

    if (apiRes === undefined) {
      console.log("Internal server error occured");
      return;
    }
    if (apiRes.status === 400) {
      setErrorMessage("Phone number already registered");
      setPhoneNumberError(true);
    } else if (apiRes.status === 200) {
      setfullNum(fullNumber)
      setAuthenticatedUser({ ...user, mobileNumber: fullNumber });
      setShowVerifyDialog(true);
      openOtpDialog();
    } else {
      console.log("Something went wrong");
    }
  
  };
  const isContinueDisabled = phoneNumberError;

  const handleCancel = () => {
    setFullNumber("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <div style={{ padding: "1.5rem 2.5rem 2.5rem 2.5rem" }}>
        <DialogTitle style={{ color: "#1D3679" }}>
          Change Your Mobile Number
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Current mobile number:
            <br />
            IN +91 {user.mobileNumber}
          </DialogContentText>
          <br />
          <DialogContentText>
            By enrolling your mobile phone number, you consent to receive
            automated security notifications via text message from Avighna.
            Message and data rates may apply.
          </DialogContentText>
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <FormControl
              style={{ width: "100%", marginRight: "2%" }}
              
            >
             
              <TextField
                label="New Mobile Number"
                variant="outlined"
                type="tel"
                placeholder="Enter new mobile number"
                value={fullNumber}
                onChange={handleNumberChange}
                error={phoneNumberError}
                id="outlined-start-adornment"
                InputProps={{
                  autoComplete: "off",
                  startAdornment: (
                    <InputAdornment position="start">+91</InputAdornment>
                  ),
                }}
                helperText={phoneNumberError && errorMessage}
              />
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleCancel}
            color="primary"
            style={{ color: "#1D3679" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{ color: "#fff", background: "#1D3679" }}
        
            onClick={handleSubmit  }
            disabled={isContinueDisabled}

          >
            Continue
          </Button>
         
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default EditNumberDialog;
