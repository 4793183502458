import React, { useState } from 'react'
import { createUseStyles } from 'react-jss';
import { IStatutoryDetailProps } from './StatutoryDetail.template';
import Checkbox from '@mui/material/Checkbox';
import deleteIcon from '../../../assets/icons/deleteIcon.svg';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { IPagination } from '../../../utils/types';
import { initialPaginationValues } from '../../../utils/constant';
import ButtonV2 from '../../atoms/ButtonV2';
import AlertPopupModelTemplate from './AlertPopupModel.template';
import TableV2, { ColumnType } from '../../organisms/TableV2';
import EmptyBankDetailsTemplate from './EmptyBankDetails.template';
import { useParams } from 'react-router-dom';
import TextFieldV2 from '../../atoms/TextFieldV2';

interface Column {
  label: string;
  key: string;
  type: ColumnType;
  props: { className: string };
}
interface IBankAccounts {
    ifsc:string;
    branch:string;
    bankName:string;
    isDefault:boolean;
    accountName:string;
    accountNumber:string
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.primarylightAccent}`,
    },
    headingText: {
        color: theme.palette.text.primaryDarkAccent
    },
    primaryText: {
        color: theme.palette.textV2.primary.primary950
    },
    paginationTable: {
        paddingLeft: '0px',
        overflowX: "hidden",
        "& .MuiTablePagination-root": {

        },
        "& .MuiTablePagination-selectLabel": {
            color: theme.palette.textV2.secondary.secondary500,
            fontWeight: 500,
            fontSize: "14px",
            paddingLeft: 0,
        },
        "& .MuiTablePagination-input": {
            borderRadius: '8px',
            border: '1px solid #D6DEF5',
            width: '13%',
            paddingRight: '10px'
        },
        "& .MuiTablePagination-displayedRows": {
            color: theme.palette.textV2.secondary.secondary500,
            fontWeight: 500,
            fontSize: "14px",
        },
    },
    paginationComponent: {
        color: theme.palette.textV2.secondary.secondary500,
        fontWeight: 500,
        fontSize: "14px",
        marginLeft: '64%'
    },
    inputLabelText: {
        color: theme.palette.text.secondaryDark
    },
    tableHead: {
        padding: "12px 16px",
        background: theme.palette.background._primaryLightAccent,
        borderRadius:16,
    },
    tableHeadCell: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "23.3px",
        padding: "12px 0px",
        color: theme.palette.textV2.tertiary.tertiary800,
        textAlign: "center",
        border:'1px solid red',
        borderRadius: 5
       
    },
    tableCell: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "23.3px",
        padding: "12px 16px",
        color: theme.palette.textV2.tertiary.tertiary800,
        textAlign: "center",
    },
    input: {
        width: '60px',
        borderRadius: '8px',
        border: '1px solid #D6DEF5',
        paddingLeft: '20px'
    },
    headings:{
        border:'1px solid #D6DEF5'
    },
    primaryTextBackground:{
      color: theme.palette.buttonV2.primaryText.text.primaryDark,
      backgroundColor: theme.palette.textV2.primary.primary50,
    },
    errorMessage: {
        color: theme.palette.textV2.failure.failure500,
    },
}));

const UploadBankDetailsTemplate: React.FC<IStatutoryDetailProps> = ({ formik }) => {
    const classes = useStyles();
    const [onEdit, setOnEdit] = useState<boolean>(false);
    const [onPrimarychange, setOnPrimarychange] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const bussinessProfileService = useBusinessProfileService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [isAlertModalOpen, setIsAlertModalOpen] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [pagination, setPagination] = useState<IPagination>(initialPaginationValues);
    const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

    const params = useParams();
    const id = Number(params.id);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        if (checked) {
            const hasDefaultBankDetails = formik.values.bankDetails.some(detail => detail.isDefault === true);
            if(hasDefaultBankDetails){setIsAlertModalOpen(true)}
            formik.setFieldValue("isDefault", true);
        } else {
            formik.setFieldValue("isDefault", false);
        }
    };

    const handleDelete = async (index: number) => {
        setIsDeleteModalOpen(true);
        setDeleteIndex(index);
    };
    const reversedBankDetails = [...formik.values.bankDetails].reverse();
    const displayedBankDetails = reversedBankDetails.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

    const handleDeleteDialogClose = async (event: boolean) => {
        setIsDeleteModalOpen(false);
        if (event && deleteIndex != null) {
            const newBankDetails = [...formik.values.bankDetails].reverse();
            newBankDetails.splice(deleteIndex, 1);
            formik.setFieldValue('bankDetails', newBankDetails);
            const body = [
                ...newBankDetails,
            ];
            try {
                await bussinessProfileService.updateBusinessProfile(id, body, 'BANK_ACCOUNTS');
            } catch (error) {
                showSnackbar("error", `Business profile update  failed ${error}`)
            }
        }
    };
    const handleDialogClose = (event: boolean) => {
        if (event) {
            setIsAlertModalOpen(false);
            formik.setFieldValue("isDefault", true);
            
            const updatedBankDetails = formik.values.bankDetails.map(detail => ({
                ...detail,
                isDefault: false
            }));
            
            formik.setFieldValue("bankDetails", updatedBankDetails);
        } else {
            setIsAlertModalOpen(false);
            formik.setFieldValue("isDefault", false);
        }
    };
    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    const Action = (id: number) => (
        <div className='flex items-center justify-center gap-x-3'>
            <button onClick={() => handleDelete(id)}><img src={deleteIcon} alt={deleteIcon} /></button>
        </div>
    )

    const getTableColumns = (edit: boolean): Column[] => {
        const columns: Column[] = [
          { label: "S No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
          { label: "Account holder name", key: "accountName", type: "custom" as ColumnType, props: { className: '' } },
          { label: "Account Number", key: "accountNumber", type: "customm" as ColumnType, props: { className: '' } },
          { label: "IFSC Code", key: "ifsc", type: "custom" as ColumnType, props: { className: '' } },
          { label: "Bank Name", key: "bankName", type: "custom" as ColumnType, props: { className: '' } },
          { label: "Branch Name", key: "branch", type: "custom" as ColumnType, props: { className: '' } },
          { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } }
        ];
      
        if (edit) {
          return columns.slice(0, -1); 
        }
      
        return columns;
      };

    const getRecords = () => displayedBankDetails.map((displayedBankDetail: IBankAccounts, index) => [
        pagination.currentPage * pagination.pageSize + index + 1,
        <div key={index} className="flex items-center   justify-center ">
        {displayedBankDetail.accountName}
        {displayedBankDetail.isDefault && (
            <span className={`px-2 py-1 ml-2 rounded-3xl ${classes.primaryTextBackground}`}>
                primary
            </span>
        )}
        </div>,
        displayedBankDetail.accountNumber,
        displayedBankDetail.ifsc,
        displayedBankDetail.bankName,
        displayedBankDetail.branch,
        onEdit && Action(index)
    ])

    const getSchema = () => ({
        id: "1",
        pagination: {
            total: formik.values.bankDetails.length,
            currentPage: page,
            isVisible: true,
            limit: rowsPerPage,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange
        },
        columns: getTableColumns(!onEdit) ?? []
    })
    return (
        (formik.values.bankDetails.length != 0 || onEdit) ?
        <div className={`${classes.container} p-6 grid gap-6 border rounded-lg`}>
            <div className=' flex justify-between'>
                <text className={`${classes.headingText} self-center text-2xl font-medium`}>Bank Details</text>
                <div className='flex '>
                    <Checkbox
                        checked={formik.values.isDefault}
                        onChange={handleCheckboxChange}
                        name="isDefault"
                    />
                    <text className={`${classes.primaryText} self-center text-base font-normal mr-4`}>Primary Bank Account</text>
                    <ButtonV2
                        variant={onEdit ? 'primaryContained' : 'tertiaryContained'}
                        label={onEdit ? 'Save' : 'Edit'}
                        size='small'
                        onClick={() => setOnEdit(!onEdit)}
                    />
                </div>
                {
                    isAlertModalOpen &&
                    <AlertPopupModelTemplate
                        dialogOpen={isAlertModalOpen}
                        heading={"Change Primary Bank Account"}
                        text={"You have already added the primary bank account are you sure you want to change the primary bank account ?"}
                        handleDialogClose={handleDialogClose}
                    />
                }
            </div>
            <div className="border-t border-gray-300 my-2 "></div>
            {
                onEdit && <>
                    <div className='flex flex-wrap  '>
                        <div className='grid gap-1 w-1/4 pr-3'>
                            <TextFieldV2
                                className="bg-white rounded-lg"
                                variant="outlined"
                                label="Account Holder Name"
                                placeholder="Enter Name"
                                fullWidth
                                {...formik.getFieldProps('accountName')}
                                error={
                                    formik.touched.accountName &&
                                    Boolean(formik.errors.accountName)
                                }
                            />
                            {formik.touched && formik.touched.accountName && formik.errors?.accountName && (
                                    <div className={classes.errorMessage}>
                                        <small>{formik.errors?.accountName}</small>
                                    </div>
                                )}
                        </div>
                        <div className='grid gap-1 w-1/4 px-3'>
                            <TextFieldV2
                                className="bg-white rounded-lg"
                                variant="outlined"
                                label="Account Number"
                                placeholder="Enter Number"
                                fullWidth
                                {...formik.getFieldProps('accountNumber')}
                                error={
                                    formik.touched.accountNumber &&
                                    Boolean(formik.errors.accountNumber)
                                } 
                            />
                            {formik.touched && formik.touched.accountNumber && formik.errors?.accountNumber && (
                                    <div className={classes.errorMessage}>
                                        <small>{formik.errors?.accountNumber}</small>
                                    </div>
                                )}
                        </div>
                        <div className='grid gap-1 w-1/4 px-3'>
                            <TextFieldV2
                                className="bg-white rounded-lg"
                                variant="outlined"
                                label="IFSC Code"
                                placeholder="Enter IFSC Code"
                                fullWidth
                                {...formik.getFieldProps('ifsc')}
                                error={
                                    formik.touched.ifsc &&
                                    Boolean(formik.errors.ifsc)
                                }
                            />
                             {formik.touched && formik.touched.ifsc && formik.errors?.ifsc && (
                                <div className={classes.errorMessage}>
                                    <small>{formik.errors?.ifsc}</small>
                                </div>
                            )}
                        </div>
                        <div className='grid gap-1 w-1/4 pl-3'>
                            <TextFieldV2
                                className="bg-white rounded-lg"
                                variant="outlined"
                                label="Bank Name"
                                placeholder="Enter bank Name"
                                fullWidth
                                {...formik.getFieldProps('bankName')}
                                error={
                                    formik.touched.bankName &&
                                    Boolean(formik.errors.bankName)
                                }
                            />
                            {formik.touched && formik.touched.bankName && formik.errors?.bankName && (
                                    <div className={classes.errorMessage}>
                                        <small>{formik.errors?.bankName}</small>
                                    </div>
                                )}
                        </div>
                        <div className='grid gap-1 mt-6 w-1/4 pr-3'>
                             <TextFieldV2
                                className="bg-white rounded-lg"
                                variant="outlined"
                                label="Branch Name"
                                placeholder="Enter Branch Name"
                                fullWidth
                                {...formik.getFieldProps('branch')}
                                error={
                                    formik.touched.branch &&
                                    Boolean(formik.errors.branch)
                                }
                            />
                            {formik.touched && formik.touched.branch && formik.errors?.branch && (
                                    <div className={classes.errorMessage}>
                                        <small>{formik.errors?.branch}</small>
                                    </div>
                                )}
                        </div>
                    </div>
                    <div className='flex justify-end '>
                        <ButtonV2
                            variant={'primaryContained'}
                            label={'Add'}
                            onClick={() => formik.handleSubmit()}
                        />
                    </div>
                </>
            }
            { formik.values.bankDetails.length != 0 &&
             <div className='w-full'>
                <TableV2 schema={getSchema()} records={getRecords()} />
            </div>}
            {
                    isDeleteModalOpen &&
                    <AlertPopupModelTemplate
                        dialogOpen={isDeleteModalOpen}
                        heading={"Delete Bank Account"}
                        text={"Are you sure want to delete this bank account?"}
                        handleDialogClose={handleDeleteDialogClose}
                    />
                }

        </div>:
            <EmptyBankDetailsTemplate setEdit={setOnEdit} />
    )
}
export default UploadBankDetailsTemplate