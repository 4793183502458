import { Route, Routes } from 'react-router-dom';
import WhatsappTemplateList from '../../../components/pages/WhatsappTemplate/WhatsappTemplateList.page';
import WhatsappTemplateCreate from '../../../components/pages/WhatsappTemplate/WhatsappTemplateCreate.page';
import WhatsappTemplateView from '../../../components/pages/WhatsappTemplate/WhatsappTemplateView.page';
import WhatsappTemplateUpdate from '../../../components/pages/WhatsappTemplate/WhatsappTemplateUpdate.page';

const WhatsappTemplateRouter = () => {
  return (
    <div>
      <Routes>
        <Route path="" element={<WhatsappTemplateList />} />
        <Route path="/add" element={<WhatsappTemplateCreate />} />
        <Route path="/:id" element={<WhatsappTemplateView />} />
        <Route path="/:id/update" element={<WhatsappTemplateUpdate />} />
      </Routes>
    </div>
  )
}
export default WhatsappTemplateRouter