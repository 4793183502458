import React, { useEffect, useMemo, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup, FormControl } from '@mui/material';
import TextFieldV2 from '../TextFieldV2';
import ButtonV2 from '../ButtonV2';

interface CustomRadioProps {
    name: string;
    options: { value: string; label: string }[];
    initialStatusValue: string;
    onSubmit?: any;
    details: { status: string; comments: string };
    setDetailsTo: React.Dispatch<React.SetStateAction<{ status: string; comments: string }>>;
}

const Status: React.FC<CustomRadioProps> = ({ name, options, details, setDetailsTo, onSubmit, initialStatusValue }) => {

    const [showInput, setShowInput] = useState<boolean>(false);

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setDetailsTo((prevValue) => {
            const newStatus = prevValue.status === value ? '' : value;
            const newValue = { ...prevValue, status: newStatus };
            setShowInput(newValue.status !== '' && newValue.status !== initialStatusValue);
            return newValue;
        });
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setDetailsTo((prevValue) => {
            const newValue = { ...prevValue, comments: value };
            return newValue;
        });
    };

    const handleSubmit = () => {
        setShowInput(false);
        onSubmit();
    }

    const inputSection = useMemo(() => {
        return showInput && (
            <div className="grid gap-y-6">
                <TextFieldV2
                    label="Enter Specific Reason"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: '16px' }}
                    value={details?.comments}
                    onChange={handleInputChange}
                />
                <div className="flex justify-end">
                    <ButtonV2 variant="primaryContained" label="Save" type="submit" onClick={handleSubmit} />
                </div>
            </div>
        );
    }, [showInput, details]);

    return (
        <div className="p-4 grid gap-y-6">
            <FormControl component="fieldset" fullWidth>
                <RadioGroup
                    aria-label={name}
                    name={name}
                    value={details?.status}
                    onChange={handleRadioChange}
                    sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}
                >
                    {options.map((option, index) => (
                        <FormControlLabel
                            key={index}
                            value={option.value}
                            control={
                                <Radio
                                    sx={{
                                        '&.Mui-checked': {
                                            color: '#FD8235',
                                        },
                                        color: '#607D8B',
                                    }}
                                />
                            }
                            label={option.label}
                            sx={{
                                border: '1px solid #CCC',
                                borderRadius: '8px',
                                padding: '16px',
                                flex: '1',
                                marginRight: index !== options.length - 1 ? '24px' : '0',
                                marginLeft: '0px',
                                backgroundColor: details?.status === option.value ? '#FFEFE6' : 'transparent',
                            }}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
            <div>{inputSection}</div>
        </div>
    );
};

export default Status;
