import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";


export const SECONDARY_INVENTORY_URLS = {
  GET_ALL_SECONDARY_INVENTORY: "/secondary-products",
  GET_SECONDARY_INVENTORY: "/secondary-product/:id",
  CREATE_SECONDARY_INVENTORY: "/secondary-product",
  UPDATE_SECONDARY_INVENTORY: "/secondary-product/:id",
};

export const WAREHOUSE_URLS = {
  GET_ALL_WAREOUSE: "/warehouse",
  GET_WAREOUSE: "/warehouse/:id",
};

const QUALITY_GRADE_URLS ={
  GET_GRADE: "/quality-grade/:id",
  GET_ALL_GRADE: "/quality-grades",
}

const CATEGORY_URLS ={
  GET_CATEGORY: "/category/:id",
  GET_ALL_CATEGORY: "/categories",
}

interface ISecondaryInventoryFilterRequest{
  warehouse?: string;
  skuCode?: string;
  categoryId?: number;
  page: number;
  size: number;
  sort?: string;
}

interface ICategoryFilterRequest {
    name?: string;
    standardIds?: number[];
    gradeIds?: number[];
    brandIds?: number[];
    shapes?: string[];
    manufacturerIds?: number[];
    classes?: string[];
    categoryId?: number;
}

interface IWarehouseFilterRequest{
  search: string;
  page?: number;
  size?: number;
  sort?: string;
}

interface IGradesParamsRequest{
  search: string;
  type:string;
  page?: number;
  size?: number;
  sort?: string;
}
interface ICategoriesParamsRequest{
  name: string,
  level:number,
  parentId: number| null;
  page?: number;
  size?: number;
}

interface IProductSpecificationAttributes {
  name: string;
  maxValue: string;
  minValue: string;
}
interface IproductSpecification {
  productSpecification: IProductSpecificationAttributes[];
}
interface ISecondaryInventoryBody {
  productCategoryId: number | null;
  gradeId: number | null ;
  shape: string;
  defectDetails: string;
  warehouseId: number | null ;
  warehousePlacement: string;
  basePrice: number | null ;
  netWeight: number | null ;
  grossWeight: number | null ;
  quantity: number | null ;
  attributes: IproductSpecification | null;
}

export const useSecondaryInventoryService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const createSecondaryInventory = (data: ISecondaryInventoryBody) => {
    return request(API_METHOD.POST, SECONDARY_INVENTORY_URLS.CREATE_SECONDARY_INVENTORY, authenticatedUser, data);
  };

  const updateSecondaryInventory = (id: number, data: ISecondaryInventoryBody) => {
    return request(API_METHOD.PUT, replaceUrlParams( SECONDARY_INVENTORY_URLS.UPDATE_SECONDARY_INVENTORY, { id } ), authenticatedUser, data);
  };

  const getAllSecondaryInventories = (data: ICategoryFilterRequest, params: ISecondaryInventoryFilterRequest) => {
    return request(API_METHOD.POST, SECONDARY_INVENTORY_URLS.GET_ALL_SECONDARY_INVENTORY, authenticatedUser, data , { params } );
  };

  const getSecondaryInventoryById = (id: number) => {
    return request(API_METHOD.GET, replaceUrlParams( SECONDARY_INVENTORY_URLS.GET_SECONDARY_INVENTORY, { id } ), authenticatedUser);
  };

  const getAllWarehouses = (params: IWarehouseFilterRequest) => {
    return request(API_METHOD.GET,  WAREHOUSE_URLS.GET_ALL_WAREOUSE, authenticatedUser, null, { params } );
  };

  const getWarehouseById = (id: number) => {
    return request(API_METHOD.GET, replaceUrlParams( WAREHOUSE_URLS.GET_WAREOUSE, { id } ), authenticatedUser);
  };

  const getGradeById = (id: number) => {
    return request(API_METHOD.GET, replaceUrlParams( QUALITY_GRADE_URLS.GET_GRADE, { id } ), authenticatedUser);
  };

  const getAllGrades = (params: IGradesParamsRequest) => {
    return request(API_METHOD.GET,  QUALITY_GRADE_URLS.GET_ALL_GRADE, authenticatedUser, null, { params } );
  };

  const getCategoryById = (id: number) => {
    return request(API_METHOD.GET, replaceUrlParams( CATEGORY_URLS.GET_CATEGORY, { id } ), authenticatedUser);
  };

  const getCategories = (params: ICategoriesParamsRequest) => {
    return request(API_METHOD.GET,  CATEGORY_URLS.GET_ALL_CATEGORY, authenticatedUser, null, { params } );
  };

  return{
    createSecondaryInventory,
    updateSecondaryInventory,
    getAllSecondaryInventories,
    getSecondaryInventoryById,
    getAllWarehouses,
    getWarehouseById,
    getAllGrades,
    getGradeById,
    getCategoryById,
    getCategories
  }
  
};
