import React, { useMemo, useState } from 'react'
import TableV2, { ColumnType } from '../../organisms/TableV2';
import { useNavigate } from 'react-router';
import ResourceStatusV2 from '../../atoms/ResourceStatus/ResourceStatusV2';
import { IPagination } from '../../../utils/types';
import viewEyeIcon from '../../../assets/icons/viewOutlinedIcon.svg'
import downLoad from '../../../assets/icons/downLoad.svg'
import { createUseStyles } from 'react-jss';
import { IQuotationFilter, IQuotationTemplate } from '../../pages/ManageQuotation/QuotationLisiting.page';
import moment from 'moment';
import { useInstantQuotationService } from '../../../services/useInstantQuotationService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { enumToString, makeRoute } from '../../../utils/helper';
import { ADMIN_ROUTES, MODE, STATUS_CONST } from '../../../utils/constant';
import edit from '../../../assets/icons/edit.svg'

const useStyles = createUseStyles((theme: any) => ({
    container: {
        borderTop: `1px solid ${theme.palette.border.tritiaryLight}`
    },
    customerCount: {
        borderRadius: '17px',
        background: theme.palette.textV2.failure.failure500,
        padding: '2px 6px'
    }
}));

interface ICustomerListProps {
    quotation: IQuotationTemplate[];
    pagination: IPagination;
    handleFiltersChange?: any;
    handlePaginationChange: any;
    handleRowsPerPageChange: any;
    customerFilter: IQuotationFilter;
}

export const QuotationListTemplate: React.FC<ICustomerListProps> = ({ quotation, pagination, handleFiltersChange, handlePaginationChange, handleRowsPerPageChange, customerFilter }) => {

    const classes = useStyles();
    const instantQuotationService = useInstantQuotationService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const navigate = useNavigate();

    const schema = {
        id: 'template-list',
        pagination: {
            total: pagination.totalRecords,
            currentPage: pagination.currentPage,
            isVisible: true,
            limit: pagination.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange
        },
        columns: [

            { label: "Sr No.", key: "id", type: "number" as ColumnType, props: {} },
            { label: "Quote ID", key: "quotationCode", type: "string" as ColumnType, props: {} },
            { label: "Client Name", key: "billToAddressDetails", type: "string" as ColumnType, props: {} },
            { label: "Quote Version", key: "version", type: "number" as ColumnType, props: {} },
            { label: "Date Created", key: "quotationDate", type: "string" as ColumnType, props: {} },
            { label: "Expiry Date", key: "validityDate", type: "string" as ColumnType, props: {} },
            { label: "Status", key: "status", component: ({ value }: { value: string }) => <ResourceStatusV2 status={value} />, type: "custom" as ColumnType, props: {} },
            { label: "Action", key: "action", type: "custom" as ColumnType, props: {} },
        ]
    }

    const handleViewClick = (id: number) => {
        navigate(makeRoute(ADMIN_ROUTES.INSTANT_QUOTATION_MODE, { params: { id: id, mode: 'view' } }));
    };

    const handleDownLoadClick = async (id: number) => {
        try {
            const quotationResponse = await instantQuotationService.shareInstantQuotation(id);
            const quotationUrl = quotationResponse?.data?.data;
            if (quotationUrl) {
                window.open(quotationUrl, '_blank');
            } else {
                showSnackbar('error', 'Failed to retrieve quotation URL.');
            }

        } catch (error) {
            showSnackbar('error', 'Error downloading the quotation:');
        }
    };

    const handleEdit = (id: number) => () => {
        navigate(makeRoute(ADMIN_ROUTES.INSTANT_QUOTATION_MODE, { params: { id: id, mode: 'update' } }));
    };

    const Action = (id: number, status: string) => {
        return (<div className='flex gap-4 justify-center'>
            <button onClick={() => handleViewClick(id)}><img className="cursor-pointer" src={viewEyeIcon} alt={viewEyeIcon} /></button>
            {status !== STATUS_CONST.DRAFT && (
                <button onClick={() => handleDownLoadClick(id)}><img className="cursor-pointer" src={downLoad} alt={downLoad} /></button>
            )}
            {(status === STATUS_CONST.DRAFT || status === STATUS_CONST.SENT || status === STATUS_CONST.IN_PROGRESS) && (
                <button onClick={handleEdit(id)}><img className="cursor-pointer" src={edit} alt="Edit" /></button>
            )}
        </div>)
    }

    const records = useMemo(() => {
        return quotation.map((quotationTemplate: IQuotationTemplate, index: number) => {
            const billToAddressDetails = JSON.parse(quotationTemplate.billToAddressDetails);
            const clientName = billToAddressDetails.companyName || '';
            return [
                pagination.currentPage * pagination.pageSize + index + 1,
                quotationTemplate.quotationCode,
                clientName,
                `V ${quotationTemplate.version}`,
                moment(quotationTemplate.quotationDate).format("DD-MM-YYYY"),
                moment(quotationTemplate.validityDate).format("DD-MM-YYYY"),
                enumToString(quotationTemplate.status),
                Action(quotationTemplate.id, quotationTemplate.status)
            ];
        });
    }, [quotation]);

    return (
        <div>
            <div className={`${classes.container}  pt-8`}>
                <TableV2 schema={schema} records={records} />
            </div>
        </div>
    )
}
