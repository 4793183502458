import { Button } from '@mui/material';
import React from 'react';
import { AUCTION } from '../../../../Constants/Procurement/LiveAuction/text';

interface CloseAuctionProps {
  onClose: () => void;
  handleCloseAuction: () => void;
  isCancel?: boolean;
}

const CloseModal = ({
  onClose,
  handleCloseAuction,
  isCancel,
}: CloseAuctionProps) => {
  return (
    <div className="w-[452px] h-[256px] flex-col gap-y-10 rounded-2xl flex justify-center items-center text-center bg-white">
      <div className="text-blue">
        Do you really want to <strong>{isCancel ? 'Cancel' : 'Close'}</strong>{' '}
        the auction?
      </div>
      <div className="flex justify-center items-center gap-10">
        <Button
          onClick={onClose}
          variant={'outlined'}
          className="border w-[129.5px] border-blue text-blue h-12 normal-case rounded hover:bg-white"
        >
          {AUCTION.No}
        </Button>
        <Button
          onClick={handleCloseAuction}
          className="bg-blue w-[129.5px] h-12 normal-case text-white rounded hover:bg-blueHover"
        >
          {AUCTION.Yes}
        </Button>
      </div>
    </div>
  );
};

export default CloseModal;
