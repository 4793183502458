import React, { useEffect, useMemo, useState } from 'react'
import { ADMIN_ROUTES, sortOptions, statusOptions } from '../../../../utils/constant';
import { enumToString, makeRoute } from '../../../../utils/helper';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { HTTP_STATUS, IPagination, VAS_MASTER_CATEGORY_TYPE } from '../../../../utils/types';
import { useNavigate } from 'react-router-dom';
import { ColumnType } from '../../../organisms/Table';
import TableV2 from '../../../organisms/TableV2';
import ButtonV2 from '../../../atoms/ButtonV2';
import TextFieldV2 from '../../../atoms/TextFieldV2';
import viewEyeIcon from "../../../../assets/images/viewOutlined.svg";
import FilterChipV2 from '../../../molecules/FilterChip/FilterChipV2';
import { IMachines, IUSCs, Service, useVASMasterCategoryService } from '../../../../services/useVASMasterCategoryService';
import { IMultiSelectOption } from '../../../atoms/MultiSelectInput2';
import FilterChipV3 from '../../../molecules/FilterChip/FilterChipV3';
import moment from 'moment';
import ResourceStatusV2 from '../../../atoms/ResourceStatus/ResourceStatusV2';

interface MachineListTemplateProps {
    machines: any[];
    pagination: IPagination;
    handlePaginationChange: any;
    handleRowsPerPageChange: any;
    handleFiltersChange: (name: string, value: any) => void;
    filters: any;
    machineRequestFilters: any;
    setMachineRequestFiltersTo: any;
}

const MachineListTemplate: React.FC<MachineListTemplateProps> = ({ machines, pagination, handlePaginationChange, handleRowsPerPageChange, handleFiltersChange, filters, machineRequestFilters, setMachineRequestFiltersTo }) => {

    const navigate = useNavigate();

    const schema = {
        id: "1",
        title: "",
        pagination: {
            total: pagination?.totalRecords,
            currentPage: pagination?.currentPage,
            isVisible: true,
            limit: pagination?.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange,
        },
        columns: [
            { label: "S.No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "Machine Model Number", key: "modelNumber", type: "string" as ColumnType, props: { className: '' } },
            { label: "USC", key: "uscCode", type: "string" as ColumnType, props: { className: '' } },
            { label: "Service Category", key: "productService", type: "string" as ColumnType, props: { className: '' } },
            { label: "Created On", key: "createdAt", type: "string" as ColumnType, props: { className: '' } },
            { label: "Created By", key: "createdBy", type: "string" as ColumnType, props: { className: '' } },
            { label: "Status", key: "status", component: ({ value }: { value: string }) => <ResourceStatusV2 status={value} />, type: "custom" as ColumnType, props: {} },
            { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } },
        ],
    };

    const handleActionRoute = (id: number) => () => {
        navigate(makeRoute(ADMIN_ROUTES.VAS_MASTER_MACHINES_VIEW, { params: { id: id } }));
    }

    const Action = (id: number) => {
        return (
            <div>
                <ButtonV2 variant='primaryText' size='small' label={"View"} startIcon={<img src={viewEyeIcon} alt={viewEyeIcon} />} onClick={handleActionRoute(id)} />
            </div>
        );
    };

    const records = machines?.map((machine: IMachines, index: number) => [
        pagination.currentPage * pagination.pageSize + index + 1,
        machine?.modelNumber,
        machine?.uscCode,
        machine?.serviceCategory,
        moment(machine.createdAt).format("DD-MM-YYYY HH:mm:ss"),
        machine?.createdBy,
        enumToString(machine?.status),
        Action(machine?.id),
    ]);

    const vasMasterCategoryService = useVASMasterCategoryService();
    const [uscData, setUscData] = useState<IUSCs[] | null>(null);
    const [productServiceData, setProductServiceData] = useState<Service[] | null>(null);


    const initialSelections = {
        status: [{ label: 'ALL', value: 'ALL' }],
        sortDate: [{ label: 'createdAt,desc', value: 'createdAt,desc' }]
    };

    const initialmachineRequestFilters: any = {
        uscIds: [],
        serviceCategoryIds: [],
    };

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedfilters, setSelectedFilters] = useState<any>(initialmachineRequestFilters);

    const [status, setStatus] = useState<IMultiSelectOption[]>(initialSelections.status);
    const [sortDate, setSortDate] = useState<IMultiSelectOption[]>(initialSelections.sortDate);

    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const fetchUsc = (inputValue?: string) => {
        vasMasterCategoryService.getUSCs({ page: 0, size: 10, sort: 'createdAt,desc', name: inputValue ?? '', status: 'ACTIVE' }, {})
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    setUscData(res?.data?.data?.content)
                }
            }).catch(error => {
                console.error("Error Fetching USC: ", error);
                showSnackbar('error', "Error while fetching USC data");
            })
    }

    const fetchProductServices = (inputValue?: string) => {
        vasMasterCategoryService.getAllService({ level: VAS_MASTER_CATEGORY_TYPE.SERVICE_CATEGORY, page: 0, size: 10, sort: 'createdAt,desc', name: inputValue ?? '', status: 'ACTIVE' }, {})
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setProductServiceData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Service: ", error);
                showSnackbar('error', "Error while fetching Super Service data");
            })
    }

    useEffect(() => {
        fetchUsc(searchTerm);
    }, [searchTerm]);

    useEffect(() => {
        fetchProductServices(searchTerm);
    }, [searchTerm]);

    const uscOptions = useMemo(() => {
        return uscData?.map((usc: any) => ({ label: usc.uscCode, value: usc.id })) ?? []
    }, [uscData])

    const productServiceOptions = useMemo(() => {
        return productServiceData?.map((mainService: Service) => ({ label: mainService.name, value: mainService.id })) ?? []
    }, [productServiceData])

    const handleSelectionChangeStatus = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setStatus(selected);
        clearWithCrossIcon && handleStatusSaveClick(selected);
    };

    const handleSelectionChangeSort = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setSortDate(selected);
        clearWithCrossIcon && handleSortSaveClick(selected);
    };

    const handleSelectionChange = (name: string, selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setSelectedFilters((previousSelectedFilters: any) => ({
            ...previousSelectedFilters,
            [name]: selected
        }));
        clearWithCrossIcon && handleDeleteClick(name, selected);
    };

    const handleStatusSaveClick = (status: IMultiSelectOption[]) => {
        const valueString = status.length > 0 ? status[0].value : 'ALL';
        handleFiltersChange("status", valueString);
    };

    const handleSortSaveClick = (sortDate: IMultiSelectOption[]) => {
        const sortString = sortDate.length > 0 ? sortDate[0].value : 'createdAt,desc';
        handleFiltersChange("sort", sortString);
    };

    const handleSaveClick = (name: string, selectedValues: IMultiSelectOption[]) => {
        const updatedFilters = { ...machineRequestFilters };
        const newValues = selectedValues.map(option => option.value);

        if (updatedFilters[name]) {
            updatedFilters[name] = Array.from(new Set([...updatedFilters[name], ...newValues]));
        } else {
            updatedFilters[name] = newValues;
        }
        setMachineRequestFiltersTo(updatedFilters);
    };

    const handleDeleteClick = (name: string, selectedValues: IMultiSelectOption[]) => {
        const selectedValueIds = selectedValues.map(option => option.value);
        const updatedFilters = {
            ...machineRequestFilters,
            [name]: machineRequestFilters[name].filter((id: number) => selectedValueIds.includes(id))
        };
        setMachineRequestFiltersTo(updatedFilters);
    };

    const handleClearClick = (filterType: string) => {
        switch (filterType) {
            case 'status':
                setStatus([{ label: 'ALL', value: 'ALL' }]);
                handleFiltersChange("status", 'ALL');
                break;
            case 'sortDate':
                setSortDate([{ label: 'createdAt,desc', value: 'createdAt,desc' }]);
                handleFiltersChange("sort", 'createdAt,desc');
                break;
            case 'uscIds':
                setSelectedFilters((previousSelectedFilters: any) => ({
                    ...previousSelectedFilters,
                    uscIds: []
                }));
                setMachineRequestFiltersTo((prevState: any) => ({
                    ...prevState,
                    uscIds: []
                }));
                break;
            case 'serviceCategoryIds':
                setSelectedFilters((previousSelectedFilters: any) => ({
                    ...previousSelectedFilters,
                    serviceCategoryIds: []
                }));
                setMachineRequestFiltersTo((prevState: any) => ({
                    ...prevState,
                    serviceCategoryIds: []
                }));
                break;
            default:
                setStatus([{ label: 'ALL', value: 'ALL' }]);
                setSortDate([{ label: 'createdAt,desc', value: 'createdAt,desc' }]);
                setSelectedFilters(initialmachineRequestFilters);
        }
    };

    return (
        <div className='grid gap-y-6'>
            <div className='flex justify-between'>
                <div className='flex flex-wrap gap-x-3 my-auto'>
                    <FilterChipV2
                        options={statusOptions}
                        label=" "
                        value={status}
                        onchange={handleSelectionChangeStatus}
                        placeholder="Status"
                        ClearButtonComponent={ButtonV2}
                        buttonLabel={"Clear all"}
                        buttonOnClick={() => handleClearClick('status')}
                        SaveButton={ButtonV2}
                        saveButtonLabel='Apply'
                        saveButtonOnClick={() => handleStatusSaveClick(status)}
                        isSingleSelect={true}
                    />
                    <FilterChipV2
                        options={sortOptions}
                        label=" "
                        value={sortDate}
                        onchange={handleSelectionChangeSort}
                        placeholder="Sort Date"
                        ClearButtonComponent={ButtonV2}
                        buttonLabel={"Clear all"}
                        buttonOnClick={() => handleClearClick('sortDate')}
                        SaveButton={ButtonV2}
                        saveButtonLabel='Apply'
                        saveButtonOnClick={() => handleSortSaveClick(sortDate)}
                        isSingleSelect={true}
                    />
                    <FilterChipV3
                        options={uscOptions}
                        label=" "
                        value={selectedfilters?.uscIds}
                        onchange={handleSelectionChange}
                        name="uscIds"
                        placeholder="Univarsal Service Code"
                        ClearButtonComponent={ButtonV2}
                        textFieldPlaceholder='Search...'
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        buttonLabel={"Clear all"}
                        buttonOnClick={() => handleClearClick('uscIds')}
                        SaveButton={ButtonV2}
                        saveButtonLabel='Apply'
                        saveButtonOnClick={() => handleSaveClick('uscIds', selectedfilters?.uscIds)}
                    />
                    <FilterChipV3
                        options={productServiceOptions}
                        label=" "
                        value={selectedfilters?.serviceCategoryIds}
                        onchange={handleSelectionChange}
                        name="serviceCategoryIds"
                        placeholder="Service Category"
                        ClearButtonComponent={ButtonV2}
                        textFieldPlaceholder='Search...'
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        buttonLabel={"Clear all"}
                        buttonOnClick={() => handleClearClick('serviceCategoryIds')}
                        SaveButton={ButtonV2}
                        saveButtonLabel='Apply'
                        saveButtonOnClick={() => handleSaveClick('serviceCategoryIds', selectedfilters?.serviceCategoryIds)}
                    />
                </div>

                <div className='w-[324px]'>
                    <TextFieldV2
                        className="w-full"
                        placeholder='Search'
                        type="text"
                        name="search"
                        value={filters.search}
                        onChange={(e) => handleFiltersChange('search', e.target.value)}
                    />
                </div>
            </div>
            <TableV2 schema={schema} records={records} />
        </div>
    )
}

export default MachineListTemplate