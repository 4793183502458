import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { ADMIN_ROUTES, EXCEPTION_CODE, MODE, STATUS } from '../../../../utils/constant';
import { makeRoute } from '../../../../utils/helper';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { HTTP_STATUS } from '../../../../utils/types';
import { ServicePayload, useVASMasterCategoryService } from '../../../../services/useVASMasterCategoryService';
import ServiceCategoryViewTemplate from '../../../templates/VASMaster/Categories/ServiceCategoryView.template';

interface ServiceCategoryViewPageProps {
    mode: string
    service: any
}

const validationSchema = Yup.object().shape({
    name: Yup.string().max(30, "Service Category Name cannot be more than 30 characters").required("Service Category Name is required"),
    description: Yup.string().max(500, "Description cannot be more than 500 characters").required("Description is required"),
    parentId: Yup.number().required("Super Service is required"),
});

const ServiceCategoryViewPage: React.FC<ServiceCategoryViewPageProps> = ({ mode, service }) => {
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const vasMasterCategoryService = useVASMasterCategoryService();
    const navigate = useNavigate();
    const params = useParams();

    const serviceId = Number(params.id);

    const onBack = () => {
        navigate(makeRoute(ADMIN_ROUTES.VAS_MASTER_CATEGORIES_LIST, { query: { type: 'SERVICE_CATEGORY' } }));
    }

    const updateServiceCategory = async (service: ServicePayload) => {
        vasMasterCategoryService.updateCategory(serviceId, service)
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    showSnackbar('success', "Status Updated");
                    onBack();
                } else if (res.data.exceptionCode === EXCEPTION_CODE.DUPLICATE_ENTRY) {
                    showSnackbar('error', "Service Category already exists. Please try again");
                }
            }).catch((error) => {
                showSnackbar('error', "Failed to update Service Category");
            })
    }

    const subAttributeIds = service.attributes.reduce((acc: any, attribute: any) => {
        attribute.subAttributes.forEach((subAttr: any) => {
            acc[subAttr.id] = {
                minTolerance: 0,
                maxTolerance: 0
            };
        });
        return acc;
    }, {});

    const formik = useFormik<ServicePayload>({
        initialValues: {
            name: service.name ?? "",
            status: service.status ?? STATUS.INACTIVE,
            serviceCode: service?.serviceCode ?? "",
            description: service?.description ?? "",
            parentId: service?.parentId ?? null,
            level: 2,
            superService: { label: service?.ancestors?.superParentService?.name, id: service.ancestors?.superParentService?.id },
            mainService: { label: service?.ancestors?.parentService?.name, id: service.ancestors?.parentService?.id },
            attributes: service.attributes ?? [],
            subAttributeIds: subAttributeIds ?? {}
        },
        validationSchema,
        onSubmit: async (values) => {
            updateServiceCategory({ ...values, status: values.status })
        }
    })

    return (
        <div>
            {SnackBarComponent}
            <ServiceCategoryViewTemplate mode={MODE.VIEW} onBack={onBack} formik={formik} />
        </div>
    )
}

export default ServiceCategoryViewPage