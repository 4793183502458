import { Route, Routes } from 'react-router-dom';
import BrandListPage from '../../../components/pages/AdminMaster/Brand/BrandList.page';
import BrandViewPage from '../../../components/pages/AdminMaster/Brand/BrandView.page';
import BrandUpdatePage from '../../../components/pages/AdminMaster/Brand/BrandUpdate.page';
import BrandCreatePage from '../../../components/pages/AdminMaster/Brand/BrandCreate.page';

const BrandRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<BrandListPage />} />
            <Route path="/add" element={<BrandCreatePage />} />
            <Route path="/:id" element={<BrandViewPage />} />
            <Route path="/:id/update" element={<BrandUpdatePage />} />
        </Routes>
    )
}

export default BrandRoutes
