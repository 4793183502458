import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { IMasterUpcDetailsRequestBody, useMasterUpcService } from '../../../services/useMasterUpcService';
import { HTTP_STATUS } from '../../../utils/types';
import { Category } from '../../../services/useCategoryService';
import { IManufacturer } from '../../pages/AdminMaster/Manufacturer/ManufacturerList.page';
import { IStandard } from '../../pages/Standard/StandardList.page';
import { IBrand } from '../../pages/AdminMaster/Brand/BrandList.page';
import moment from 'moment';
import { CATALOGUE_SHAPES_TYPES, enumToString, getProfileDisplayData } from '../../../utils/helper';
interface IMasterUpcOverviewTemplateProps {
    id: number;
    masterUpc: IMasterUpcs | undefined;
}
export interface IGrade {
    id: number;
    name: string;
}
export interface IProduct {
    id: number;
    categoryId: number;
    productCategory: Category;
    productCode: string;
    brand: IBrand;
    keywords: string;
    gradeId: number;
    grade: IGrade;
    title: string;
    standard: IStandard;
    manufacturer: IManufacturer;
    mainCategory: Category;
    superCategory: Category;
}
export interface IMasterUpcs {
    id: number,
    upcCode: string,
    productId: number,
    classType: string,
    shape: string,
    status: string,
    createdAt: string,
    updatedAt: string,
    attributes: any,
    product: IProduct,
    upcTitle: string,
    createdAtIdAndName?: string,
    updatedAtIdAndName?: string
}
const useStyles = createUseStyles((theme: any) => ({
    border: {
        borderRadius: "12px",
        border: '1px solid #E6E6E6'
    },
    heading: {
        color: theme.palette.textV2.secondary.secondary800
    },
    heading1: {
        color: theme.palette.textV2.primary.primary950
    },
    subHeading: {
        color: theme.palette.textV2.primary.primary900
    },
    attribute: {
        color: theme.palette.text.primaryLight
    },
    value: {
        color: theme.palette.text.primaryLight
    },
    statusActive: {
        color: theme.palette.textV2.success.success500
    },
    statusInactive: {
        color: theme.palette.textV2.failure.failure500
    },
    statusActiveContainer: {
        borderRadius: "24px",
        backgroundColor: theme.palette.textV2.success.success50
    },
    statusInactiveContainer: {
        borderRadius: "24px",
        backgroundColor: theme.palette.textV2.failure.failure50
    }
}));

const MasterUpcOverviewTemplate: React.FC<IMasterUpcOverviewTemplateProps> = ({ id, masterUpc }) => {
    const classes = useStyles();

    return (
        <div className={`${classes.border} p-6 mt-5`}>
            <div>
                <div className={`${classes.heading} text-base`}>Master UPC</div>
                <div className={`${classes.heading1} text-lg font-semibold`}>{masterUpc?.upcCode}</div>
            </div>
            <div className={`${classes.border} p-6 mt-6`}>
                <div className={`${classes.subHeading} text-base font-medium`}>Category</div>
                <div className="flex gap-x-20 mt-4">
                    <div>
                        <div className={`${classes.attribute} text-sm`}>Super Category</div>
                        <div className={`${classes.value} text-base font-medium`}>{masterUpc?.product?.superCategory?.name}</div>
                    </div>
                    <div>
                        <div className={`${classes.attribute} text-sm`}>Main Category</div>
                        <div className={`${classes.value} text-base font-medium`}>{masterUpc?.product?.mainCategory?.name}</div>
                    </div>
                    <div>
                        <div className={`${classes.attribute} text-sm`}>Product Category</div>
                        <div className={`${classes.value} text-base font-medium`}>{masterUpc?.product?.productCategory?.name}</div>
                    </div>
                </div>
            </div>
            <div className='flex gap-x-6'>
                <div className={`${classes.border} p-6 mt-6 w-full`}>
                    <div className={`${classes.subHeading} text-base font-medium`}>Standard</div>
                    <div className="flex gap-x-20 mt-4">
                        <div>
                            <div className={`${classes.attribute} text-sm`}>Standard</div>
                            <div className={`${classes.value} text-base font-medium`}>{masterUpc?.product?.standard?.name}</div>
                        </div>
                        <div>
                            <div className={`${classes.attribute} text-sm`}>Grade</div>
                            <div className={`${classes.value} text-base font-medium`}>{masterUpc?.product?.grade?.name}</div>
                        </div>
                    </div>
                </div>
                <div className={`${classes.border} p-6 mt-6 w-full`}>
                    <div className={`${classes.subHeading} text-base font-medium`}>Product Classification</div>
                    <div className="flex gap-x-20 mt-4">
                        <div>
                            <div className={`${classes.attribute} text-sm`}>Shape</div>
                            <div className={`${classes.value} text-base font-medium`}>
                                {masterUpc?.shape ? CATALOGUE_SHAPES_TYPES[masterUpc.shape as keyof typeof CATALOGUE_SHAPES_TYPES] : ''}
                            </div>
                        </div>
                        <div>
                            <div className={`${classes.attribute} text-sm`}>Class</div>
                            <div className={`${classes.value} text-base font-medium`}>{enumToString(masterUpc?.classType ?? '')}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${classes.border} p-6 mt-6`}>
                <div className={`${classes.subHeading} text-base font-medium`}>Product Specification</div>
                <div className="flex gap-x-20 mt-4">
                    {masterUpc?.attributes?.SPECIFICATION?.map((item: any) => (
                        <div key={item.id}>
                            <div className={`${classes.attribute} text-sm`}> {item.name} {item.uom && `(${item.uom.toLowerCase()})`}</div>
                            <div className={`${classes.value} text-base font-medium`}>{item.attributeOptions}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={`${classes.border} p-6 mt-6`}>
                <div className={`${classes.subHeading} text-base font-medium`}>Others</div>
                <div className="flex gap-x-20 mt-4">
                    <div>
                        <div className={`${classes.attribute} text-sm`}>Status</div>
                        <div className={`${masterUpc?.status === 'INACTIVE' ? classes.statusInactiveContainer : classes.statusActiveContainer} flex justify-center`}>
                            <div className={`${masterUpc?.status === 'INACTIVE' ? classes.statusInactive : classes.statusActive} text-sm mx-4 my-2`}>{enumToString(masterUpc?.status ?? '')}</div>
                        </div>
                    </div>
                    <div>
                        <div className={`${classes.attribute} text-sm`}>Created By</div>
                        <div className={`${classes.value} text-base font-medium`}>{getProfileDisplayData(masterUpc?.createdAtIdAndName)}</div>
                    </div>
                    <div>
                        <div className={`${classes.attribute} text-sm`}>Created Date</div>
                        <div className={`${classes.value} text-base font-medium`}>{moment(masterUpc?.createdAt).format("YYYY-MM-DD HH:mm:ss")} </div>
                    </div>
                    <div>
                        <div className={`${classes.attribute} text-sm`}>Updated By</div>
                        <div className={`${classes.value} text-base font-medium`}>{getProfileDisplayData(masterUpc?.updatedAtIdAndName)}</div>
                    </div>
                    <div>
                        <div className={`${classes.attribute} text-sm`}>Updated Date</div>
                        <div className={`${classes.value} text-base font-medium`}>{moment(masterUpc?.updatedAt).format("YYYY-MM-DD HH:mm:ss")} </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MasterUpcOverviewTemplate