import { useMemo, useRef, useState } from 'react';
import { Button, FormControl, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import InsightCard from './InsightCard';
import { FormikErrors, useField, useFormikContext } from 'formik';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import ProductCardWithDescription from './ProductCardWithDescription';
import JoditEditor from 'jodit-react';
import {
  ProductDataType,
  ProductFormData,
} from '../../../Constants/Procurement/PurchaseRequisition/types';
import { PACKAGING_TYPES } from '../../../../../src/utils/constant';
import CustomTextField from '../../../Shared/CustomInputs/CustomTextField';
import CustomSelectField from '../../../Shared/CustomInputs/CustomSelectField';

interface ProductCardDetailsProps {
  item: ProductDataType;
  formData: ProductFormData;
  locations: { id: string; value: string; name: string }[];
  index: number;
  handleDelete: () => void;
}

interface FormData {
  items: ProductFormData[];
}

const ProductCardDetails = ({
  item,
  formData,
  index,
  locations,
  handleDelete,
}: ProductCardDetailsProps) => {
  const [dateError, setDateError] = useState<string>('');
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    useFormikContext<FormData>();

  const meta = useField(`items.${index}.deliveryTime`)[1];
  const [editorField] = useField(`items.${index}.tnc`);

  const handleDateChange = (date: Moment | null) => {
    const deliveryTime = moment(date).unix();
    setFieldValue(`items.${index}.deliveryTime`, deliveryTime);
  };

  const editor = useRef(null);
  const config = useMemo(() => {
    return {
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      placeholder: 'Start typings...',
    };
  }, []);

  const handleEditorChanges = (newContent: string) => {
    setFieldValue(`items.${index}.tnc`, newContent);
  };

  const [insight, setInsight] = useState(false);
  const toggleInsight = () => setInsight(!insight);

  return (
    <div className="border border-cgray-4  rounded-lg gap-4 my-4 w-[100%]">
      <div className="p-4 border-b border-cgray-4 ">
        <ProductCardWithDescription
          item={item}
          toggleInsight={toggleInsight}
          index={index}
        />
      </div>
      {insight ? <InsightCard /> : ''}

      <div className="mt-4 p-4">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CustomTextField
              name={`items.${index}.quantity`}
              value={values.items[index].quantity}
              label={'Enter Quantity in MT'}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.items &&
                touched.items[index]?.quantity &&
                Boolean(
                  errors.items &&
                    (errors.items[index] as FormikErrors<ProductFormData>)
                      ?.quantity
                )
              }
              helperText={
                touched.items &&
                touched.items[index]?.quantity &&
                errors.items &&
                (errors.items[index] as FormikErrors<ProductFormData>)?.quantity
              }
            />
          </Grid>
          <Grid item xs={6}>
            <CustomSelectField
              name={`items.${index}.packagingType`}
              value={values.items[index].packagingType}
              label="Packaging"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                touched.items &&
                touched.items[index]?.packagingType &&
                errors.items &&
                (errors.items[index] as FormikErrors<ProductFormData>)
                  ?.packagingType
              }
              error={
                touched.items &&
                touched.items[index]?.packagingType &&
                Boolean(
                  errors.items &&
                    (errors.items[index] as FormikErrors<ProductFormData>)
                      ?.packagingType
                )
              }
              options={PACKAGING_TYPES.map((item) => ({
                id: item.value,
                value: item.label,
              }))}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomSelectField
              name={`items.${index}.warehouseId`}
              value={String(values.items[index].warehouseId)}
              label="Consignee Location"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                touched.items &&
                touched.items[index]?.warehouseId &&
                errors.items &&
                (errors.items[index] as FormikErrors<ProductFormData>)
                  ?.warehouseId
              }
              error={
                touched.items &&
                touched.items[index]?.warehouseId &&
                Boolean(
                  errors.items &&
                    (errors.items[index] as FormikErrors<ProductFormData>)
                      ?.warehouseId
                )
              }
              options={locations.map((item) => ({
                id: item.id,
                value: item.name,
              }))}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  format="DD-MM-YYYY"
                  name={`items.${index}.deliveryTime`}
                  label="Maximum Delivery Date"
                  minDate={moment(new Date()).add(1, 'day')}
                  value={
                    formData.deliveryTime
                      ? moment.unix(+formData.deliveryTime)
                      : null
                  }
                  slotProps={{
                    textField: {
                      helperText:
                        meta.touched &&
                        (dateError !== '' ? dateError : meta.error),
                      error: meta.touched && Boolean(meta.error || dateError),
                    },
                  }}
                  onChange={handleDateChange}
                  onError={(error) =>
                    error ? setDateError(error) : setDateError('')
                  }
                ></DatePicker>
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              name={`items.${index}.remarks`}
              value={values.items[index].remarks}
              label={'Any Product Specific Requirements'}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                touched.items &&
                touched.items[index]?.remarks &&
                errors.items &&
                (errors.items[index] as FormikErrors<ProductFormData>)?.remarks
              }
              error={
                touched.items &&
                touched.items[index]?.remarks &&
                Boolean(
                  errors.items &&
                    (errors.items[index] as FormikErrors<ProductFormData>)
                      ?.remarks
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <div className="text-text-color mb-1">
              Terms & Conditions (Optional)
            </div>
            <div className="w-[100%]">
              <JoditEditor
                value={editorField.value}
                onChange={(val) => handleEditorChanges(val)}
                ref={editor}
                config={config}
              />
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="flex justify-end w-full mb-4 p-4">
        <Button
          variant="outlined"
          color="error"
          size="large"
          startIcon={<DeleteIcon />}
          sx={{
            color: '#E5252A',
            borderColor: '#E5252A',
            textTransform: 'none',
            fontWeight: 'semibold',
          }}
          onClick={handleDelete}
        >
          Delete Product
        </Button>
      </div>
    </div>
  );
};

export default ProductCardDetails;
