import { Button } from '@mui/material';
import VariantButton from '../../../Shared/Button/VariantButton';
import { AUCTION } from '../../../Constants/Procurement/LiveAuction/text';
import { LiveAuctionStatusType } from '../../../Constants/Procurement/LiveAuction/constants';

interface AuctionButtonProps {
  handleCancel: () => void;
  handleReSchedule: () => void;
  handleAward: () => void;
  handleClose: () => void;
  handleDownload: () => void;
  handleRestart: () => void;
  status?: string;
  hasBids: boolean;
  islive: boolean;
  hasEnded: boolean;
}

// Todo

const AuctionButtons = ({
  handleCancel,
  handleReSchedule,
  handleAward,
  handleClose,
  handleDownload,
  handleRestart,
  status,
  hasBids,
  islive,
  hasEnded,
}: AuctionButtonProps) => {
  return (
    <div className="my-4 flex justify-center gap-6">
      {islive ? (
        <VariantButton
          onClick={handleDownload}
          className="rounded-none hover:bg-blueHover"
        >
          {AUCTION.DownloadReport}
        </VariantButton>
      ) : (
        ''
      )}
      {hasEnded && !(status?.toLocaleLowerCase() === 'closed' || status?.toLocaleLowerCase() === 'completed') ? (
        <VariantButton
          onClick={handleRestart}
          className="rounded-none hover:bg-blueHover"
        >
          {AUCTION.Restart}
        </VariantButton>
      ) : (
        ''
      )}
      {islive && hasEnded && !(status?.toLocaleLowerCase() === 'closed' || status?.toLocaleLowerCase() === 'completed') ? (
        <VariantButton
          onClick={handleReSchedule}
          className="rounded-none hover:bg-blueHover"
        >
          {AUCTION.RescheduleAuction}
        </VariantButton>
      ) : (
        ''
      )}
      {!islive && status?.toLocaleLowerCase() !== 'closed' ? (
        <Button
          onClick={handleCancel}
          className="bg-badge-warning h-12 normal-case text-white rounded-none hover:bg-blueHover"
        >
          {AUCTION.CancelAuction}
        </Button>
      ) : !(status?.toLocaleLowerCase() === 'closed' || status?.toLocaleLowerCase() === 'completed') && hasEnded ? (
        <Button
          onClick={handleClose}
          className="bg-badge-warning h-12 normal-case text-white rounded-none hover:bg-blueHover"
        >
          {AUCTION.CloseAuction}
        </Button>
      ) : (
        ''
      )}
      {hasBids && hasEnded && !(status?.toLocaleLowerCase() === 'closed' || status?.toLocaleLowerCase() === 'completed') ? (
        <Button
          onClick={handleAward}
          className="rounded-none h-12 normal-case text-white bg-approved hover:bg-blueHover"
        >
          {AUCTION.AwardToLowest}
        </Button>
      ) : (
        ''
      )}
    </div>
  );
};

export default AuctionButtons;
