import React, { useEffect, useState } from "react";
import Image from "../molecules/ImageUpload/Image";
import DisplayImage from "../molecules/ImageUpload/DisplayImage";
import { ImageData } from "./ImageUploader";

interface ImagesProps {
    images: (ImageData | File)[];
    isEditable?: boolean;
    removeImage?: (index: number) => void;
    title?: string;
}

const Images: React.FC<ImagesProps> = ({ title, images, isEditable = false, removeImage }) => {
    const [selectedImage, setSelectedImageTo] = useState<string | null>(null);
    const [imageDialogOpen, setImageDialogOpenTo] = useState(false);

    const handleBackdropClickAway = () => {
        setImageDialogOpenTo(false);
    };

    const handleViewClick = (imagePath: string) => {
        setSelectedImageTo(imagePath);
        setImageDialogOpenTo(true);
    };

    const handleDeleteClick = (index: number) => {
        if (removeImage) {
            removeImage(index);
        }
    };

    return (
        <div className="self-stretch flex flex-col items-start justify-start gap-3 overflow-auto mt-5">
            <div className="relative font-semibold lg:text-xl">{title? title : ''}</div>
            <div className="flex flex-row items-start justify-start gap-9">
                {
                    images.map((imageData: ImageData | File, index: number) => (
                        <Image
                            key={index}
                            index={index}
                            imageData={imageData}
                            isEditable={isEditable}
                            handleDeleteClick={handleDeleteClick}
                            handleViewClick={handleViewClick}
                        />
                    ))}
                {imageDialogOpen && <DisplayImage selectedImage={selectedImage} imageDialogOpen={imageDialogOpen} setImageDialogOpenTo={setImageDialogOpenTo} handleBackdropClickAway={handleBackdropClickAway} />}
            </div>
        </div>
    );
};

export default Images;
