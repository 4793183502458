import React from 'react'
import { Route, Routes } from 'react-router-dom';
import { QuoteCreatePage } from '../../../components/pages/ManageQuotation/QuoteCreate.page';
import VendorListingPage from '../../../components/pages/VendorManagement/VendorListing.page';
import QuotationLisitingPage from '../../../components/pages/ManageQuotation/QuotationLisiting.page';

export const InstantQuotationRoutes = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<QuotationLisitingPage />} />
                <Route path="/add" element={<QuoteCreatePage />} />
                <Route path="/:id/:mode" element={<QuoteCreatePage />} />
            </Routes>
        </div>
    )
}
