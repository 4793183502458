import { useAuthenticatedUser } from '../hooks/useAuthenticatedUser';
import { API_METHOD } from '../utils/constant';
import { replaceUrlParams } from '../utils/helper';
import { request } from './index';

const LIVE_AUCTION_URLS = {
  CLOSE_AUCTION: 'procurement/indent/auction/:auctionId/close',
  CANCEL_AUCTION: 'procurement/indent/auction/:auctionId/cancel',
  RESCHEDULE_AUCTION:
    'procurement/indent/auction/:auctionId/reschedule?auction_from_date=:auctionFromDate&auction_to_date=:auctionToDate',
  EXTEND_AUCTION:
    'procurement/indent/auction/:auctionId/extend?auctionToDate=:auctionToDate',
  AWARD_AUCTION: 'procurement/indent/auction/:auctionId/bid/:bidderId/award',
  RESUME_AUCTION: 'procurement/indent/auction/:auctionId/resume',
  PAUSE_AUCTION: 'procurement/indent/auction/:auctionId/pause',
  FETCH_AUCTION: 'procurement/indent/auction/:auctionId',
  FETCH_BIDS: 'auction/:auctionId/bids',
};

export const useLiveAuctionService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const closeAuction = (auctionId: string) => {
    return request(
      API_METHOD.PUT,
      replaceUrlParams(LIVE_AUCTION_URLS.CLOSE_AUCTION, {
        auctionId: auctionId,
      }),
      authenticatedUser,
      {}
    );
  };

  const cancelAuction = (auctionId: string) => {
    return request(
      API_METHOD.PUT,
      replaceUrlParams(LIVE_AUCTION_URLS.CANCEL_AUCTION, {
        auctionId: auctionId,
      }),
      authenticatedUser,
      {}
    );
  };

  const rescheduleAuction = (
    auctionId: string,
    auctionFromDate: number,
    auctionToDate: number
  ) => {
    return request(
      API_METHOD.PUT,
      replaceUrlParams(LIVE_AUCTION_URLS.RESCHEDULE_AUCTION, {
        auctionId,
        auctionToDate,
        auctionFromDate,
      }),
      authenticatedUser,
      {}
    );
  };

  const extendAuction = (auctionId: string, auctionToDate: number) => {
    return request(
      API_METHOD.PUT,
      replaceUrlParams(LIVE_AUCTION_URLS.EXTEND_AUCTION, {
        auctionId,
        auctionToDate,
      }),
      authenticatedUser,
      {}
    );
  };

  const awardAuction = (auctionId: string, bidderId: string) => {
    return request(
      API_METHOD.PUT,
      replaceUrlParams(LIVE_AUCTION_URLS.AWARD_AUCTION, {
        auctionId,
        bidderId,
      }),
      authenticatedUser,
      {}
    );
  };

  const resumeAuction = (auctionId: string) => {
    return request(
      API_METHOD.PUT,
      replaceUrlParams(LIVE_AUCTION_URLS.RESUME_AUCTION, {
        auctionId,
      }),
      authenticatedUser,
      {}
    );
  };

  const pauseAuction = (auctionId: string) => {
    return request(
      API_METHOD.PUT,
      replaceUrlParams(LIVE_AUCTION_URLS.PAUSE_AUCTION, {
        auctionId,
      }),
      authenticatedUser,
      {}
    );
  };

  const fetchAuction = (auctionId: string) => {
    return request(
      API_METHOD.GET,
      replaceUrlParams(LIVE_AUCTION_URLS.FETCH_AUCTION, {
        auctionId,
      }),
      authenticatedUser
    );
  };

  const fetchBids = (auctionId: string) => {
    return request(
      API_METHOD.GET,
      replaceUrlParams(LIVE_AUCTION_URLS.FETCH_BIDS, {
        auctionId,
      }),
      authenticatedUser
    );
  };

  return {
    closeAuction,
    cancelAuction,
    rescheduleAuction,
    extendAuction,
    awardAuction,
    resumeAuction,
    pauseAuction,
    fetchAuction,
    fetchBids,
  };
};
