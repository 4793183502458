import { FormikProps } from 'formik';
import React, { useState } from 'react'
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { ADMIN_ROUTES } from '../../../../utils/constant';
import ButtonV2 from '../../../atoms/ButtonV2';
import SelectBrandCenterTemplate from './SelectBrandCenter.template';
import { IVendorBrandCenterForm } from '../../../pages/VendorManagement/VendorBrandCenter.page';

export interface IVendorBrandCenterDetailProps {
    formik: FormikProps<IVendorBrandCenterForm>;
}
const useStyles = createUseStyles((theme: any) => ({
    titleText: {
        color: theme.palette.textV2.secondary.secondary800
    },
    container: {
        border: `1px solid ${theme.palette.border.secondaryLightAccent}`
    },
}));

const VendorBrandCenterTemplate: React.FC<IVendorBrandCenterDetailProps> = ({ formik }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleBackToDashboard = () => {
        navigate(ADMIN_ROUTES.VENDOR_MANAGEMENT_LIST);
    };
    return (
        <div className='grid mt-6 gap-8'>
            <div className={`p-6 grid gap-5 rounded-lg ${classes.container}`}>
                <SelectBrandCenterTemplate formik={formik} />
            </div>
            <div className=' grid justify-items-stretch'>
                <ButtonV2
                    className={` justify-self-end`}
                    variant="secondaryContained"
                    label="Back to Dashboard"
                    onClick={handleBackToDashboard}
                />
            </div>
        </div>

    )
}
export default VendorBrandCenterTemplate