import { request } from '.';
import { LocalComment } from '../App/Constants/Procurement/Indent/types';
import { useAuthenticatedUser } from '../hooks/useAuthenticatedUser';
import { API_METHOD } from '../utils/constant';
import { replaceUrlParams } from '../utils/helper';

export const COMMENT_URLS = {
  GET_COMMENTS: 'comment?resourceId=:resourceId&resourceType=:resourceType',
  SEND_COMMENTS: 'comment',
  DELETE_COMMENT: 'comment/:commentId',
  RESOLVE_COMMENT: 'comment/:commentId/resolve',
};

export const useCommentService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const getComments = (resourceId: string, resourceType: string) => {
    return request(
      API_METHOD.GET,
      replaceUrlParams(COMMENT_URLS.GET_COMMENTS, {
        resourceId: resourceId,
        resourceType: resourceType,
      }),
      authenticatedUser
    );
  };

  const sendComments = (comments: LocalComment[]) => {
    return request(
      API_METHOD.POST,
      COMMENT_URLS.SEND_COMMENTS,
      authenticatedUser,
      comments
    );
  };

  const deleteComment = (commentId: string) => {
    return request(
      API_METHOD.DELETE,
      replaceUrlParams(COMMENT_URLS.DELETE_COMMENT, {
        commentId: commentId,
      }),
      authenticatedUser
    );
  };

  const resolveComment = (commentId: string) => {
    return request(
      API_METHOD.PUT,
      replaceUrlParams(COMMENT_URLS.RESOLVE_COMMENT, {
        commentId: commentId,
      }),
      authenticatedUser
    );
  };
  return {
    getComments,
    sendComments,
    deleteComment,
    resolveComment,
  };
};
