import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import StandardsTableIndex from './StandardTableIndex';
import GradeTableIndex from '../Grade/GradeTableIndex';

const Header = ({ handleAttributeClick, type }) => {
  const navigate = useNavigate();
  const navigateTo = () => {
    navigate(
      // `/standard/add`,
      `/${type.split(' ').join('').toLowerCase()}/add`,
      {
        state: {
          type: 'ADD', 
          itemId: null,
        },
      });
  };

  return (
    <div className='relative w-full items-center justify-start gap-4 text-left text-2xl text-blue font-roboto'>
      <h2 className='overflow-hidden flex items-center text-xl text-blue font-bold'>
        {type}
      </h2>

      <div className='self-stretch flex flex-row items-center justify-between'>
        <Box>
          <Tabs value={type} onChange={handleAttributeClick}>
            <Tab
              style={{
                padding: "0px",
                marginRight: "20px",
                textTransform: "capitalize",
                minWidth: "0px",
                fontSize: "1rem",
                color: type === 'Standard' && '#1D3679',
                borderBottom: type === 'Standard' && '1px solid #1D3679',
              }}
              component='a'
              label='Standard'
              onClick={() => handleAttributeClick('Standard')}
            />
            <Tab
              style={{
                padding: "0px",
                minWidth: "0px",
                fontSize: "1rem",
                textTransform: "capitalize",
                color: type === 'Grade' && '#1D3679',
                borderBottom: type === 'Grade' && '1px solid #1D3679',
              }}
              component='a'
              label='Grade'
              onClick={() => handleAttributeClick('Grade')}
            />
          </Tabs>
        </Box>

        <button
          className='rounded bg-customBlue overflow-hidden flex py-2 px-4 items-center justify-center text-lg text-white font-semibold'
          onClick={navigateTo}
        >
          Add New {type}
        </button>
      </div>
    </div>
  );
};


const Standards = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { type = 'Standard' } = location.state || {};
  const [index, setIndex] = useState(type);

  // useEffect(() => {
  //   setIndex(type)
  // })

  const handleIndex = (type) => {
    setIndex(type);
  };

  useEffect(() => {
    if (index === "Standard") {
      navigate("/standard");
    } else if (index === "Grade") {
      navigate("/grade", {
        state: {
          type: "Grade",
        },
      });
    }
  }, [index]);

  return (
    <div className='grid gap-y-3'>
        <Header type={index} handleAttributeClick={handleIndex} />

      {/* <DropDownOptions /> */}

      <div>
        {index === 'Standard' ? (
          <StandardsTableIndex />
        ) : (
          <GradeTableIndex />
        )}
      </div>
    </div>
  );
};

export default Standards;
