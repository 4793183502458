import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { ServicePayload, useVASMasterCategoryService } from '../../../../services/useVASMasterCategoryService';
import { makeRoute } from '../../../../utils/helper';
import { ADMIN_ROUTES, EXCEPTION_CODE, MODE, STATUS } from '../../../../utils/constant';
import { HTTP_STATUS, PRODUCT_SERVICE_FORM_STATE } from '../../../../utils/types';
import ServiceCategoryDetailsFormTemplate from '../../../templates/VASMaster/Categories/ServiceCategoryDetails.template';
import AttributeManagementForm1Template from '../../../templates/VASMaster/Categories/AttributeManagementForm1.template';
import AttributeManagementForm2Template from '../../../templates/VASMaster/Categories/AttributeManagementForm2.temlate';
import ServiceCategoryReviewTemplate from '../../../templates/VASMaster/Categories/ServiceCategoryReview.template';
import ServiceCategoryFormTemplate from '../../../templates/VASMaster/Categories/ServiceCategoryForm.template';
import { createUseStyles } from 'react-jss';

interface ServiceCategoryUpdatePageProps {
    mode: string
    service: any
}

const validationSchema = Yup.object().shape({
    name: Yup.string().max(30, "Service Name cannot be more than 30 characters").required("Service Name is required"),
    description: Yup.string().max(500, "Description cannot be more than 500 characters").required("Description is required"),
    parentId: Yup.number().required("Main Service is required"),
    superService: Yup.object().shape({
        id: Yup.number().required('Super Service is required'),
        label: Yup.string().required('Super Service is required')
    }).required('Super Service is required'),
    mainService: Yup.object().shape({
        id: Yup.number().required('Main Service is required'),
        label: Yup.string().required('Main Service is required')
    }).required('Main Service is required'),
    attributes: Yup.array().of(
        Yup.object().shape({
            id: Yup.number().required('Attribute ID is required'),
            name: Yup.string().required('Attribute Name is required'),
            uom: Yup.string().required('Unit of Measure (UOM) is required'),
            type: Yup.string().required('Attribute Type is required'),
            subAttributes: Yup.array().of(
                Yup.object().shape({
                    id: Yup.number().required('Sub-Attribute ID is required'),
                    parentId: Yup.number().required('Parent ID is required'),
                    minValue: Yup.number().required('Minimum Value is required'),
                    maxValue: Yup.number().required('Maximum Value is required'),
                    status: Yup.string().oneOf(['ACTIVE', 'INACTIVE'], 'Status must be either ACTIVE or INACTIVE').required('Status is required')
                })
            ).required('Sub-Attributes are required')
        })
    ).min(1, 'At least one attribute is required').required('Attributes are required'),
    subAttributeIds: Yup.object()
        .test(
            'hasAtLeastOneKey',
            'Check at least one checkbox',
            value => value && Object.keys(value).length > 0
        )
        .required('Sub Attribute is required'),
});

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
    },
}));

const ServiceCategoryUpdatePage: React.FC<ServiceCategoryUpdatePageProps> = ({ mode, service }) => {
    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const vasMasterCategoryService = useVASMasterCategoryService();
    const navigate = useNavigate();

    const params = useParams();
    const serviceId = Number(params.id);

    const [formState, setFormState] = useState<PRODUCT_SERVICE_FORM_STATE>(1);

    const onClose = () => {
        navigate(makeRoute(ADMIN_ROUTES.VAS_MASTER_CATEGORIES_LIST, { query: { type: 'SERVICE_CATEGORY' } }));
    }

    const updateServiceCategory = async (service: ServicePayload) => {
        vasMasterCategoryService.updateCategory(serviceId, service)
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    showSnackbar('success', "Service Category Updated");
                    onClose();
                } else if (res.data.exceptionCode === EXCEPTION_CODE.DUPLICATE_ENTRY) {
                    showSnackbar('error', "Service Category already exists. Please try again");
                }
            }).catch((error) => {
                console.error("Error updating Service: ", error);
                showSnackbar('error', "Error updating Service Category");
            })
    }

    const subAttributeIds = service.attributes.reduce((acc: any, attribute: any) => {
        attribute.subAttributes.forEach((subAttr: any) => {
            acc[subAttr.id] = {
                minTolerance: 0,
                maxTolerance: 0
            };
        });
        return acc;
    }, {});

    const formik = useFormik<ServicePayload>({
        initialValues: {
            name: service.name ?? "",
            status: service.status ?? STATUS.INACTIVE,
            serviceCode: service?.serviceCode ?? "",
            description: service?.description ?? "",
            parentId: service?.parentId ?? null,
            level: 2,
            superService: { label: service?.ancestors?.superParentService?.name, id: service.ancestors?.superParentService?.id },
            mainService: { label: service?.ancestors?.parentService?.name, id: service.ancestors?.parentService?.id },
            attributes: service.attributes ?? [],
            subAttributeIds: subAttributeIds ?? {}
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            updateServiceCategory({ ...values, status: values.status })
        }
    })

    const setFormStateTo = (formState: PRODUCT_SERVICE_FORM_STATE) => () => {
        setFormState(formState)
    }

    const activeFormStep = useMemo(() => {
        const props = { mode: mode, onClose, formik, setFormStateTo }
        switch (formState) {
            case PRODUCT_SERVICE_FORM_STATE.SERVICE_CATEGORY_DETAILS: return <ServiceCategoryDetailsFormTemplate {...props} />;
            case PRODUCT_SERVICE_FORM_STATE.ATTRIBUTE_MANAGEMENT_FORM_1: return <AttributeManagementForm1Template {...props} />;
            case PRODUCT_SERVICE_FORM_STATE.ATTRIBUTE_MANAGEMENT_FORM_2: return <AttributeManagementForm2Template {...props} />;
            case PRODUCT_SERVICE_FORM_STATE.REVIEW: return <ServiceCategoryReviewTemplate {...props} />;
            default: return <ServiceCategoryDetailsFormTemplate {...props} />;
        }
    }, [formState, formik])

    return (
        <div>
            {SnackBarComponent}
            <div className="grid gap-y-6" >
                <div className={`${classes.mainHeading} text-lg font-semibold`}>Edit Service Category - ${formik?.values?.serviceCode}</div>
                <ServiceCategoryFormTemplate {...{ mode: MODE.UPDATE, onClose, component: activeFormStep }} />
            </div>
        </div>
    )
}

export default ServiceCategoryUpdatePage