import React, { useEffect, useState } from 'react'
import {  useParams } from 'react-router-dom';
import SecondaryInventoryFormTemplate from '../../templates/SecondaryInventory/SecondaryInventoryForm.template';
import { useSecondaryInventoryService } from '../../../services/useSecondaryInventoryService';
import { MODE, RESOURCE_TYPE } from '../../../utils/constant';
import { ImageData } from '../../organisms/ImageUploader';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { HTTP_STATUS } from '../../../utils/types';
import { useFileService } from '../../../services/useFileService';

interface IProductSpecification {
  name: string;
  maxValue: string;
  minValue: string;
}
export interface ISecondaryInventoryResponse {
  id: number;
  basePrice: number;
  skuCode: string;
  netWeight: number;
  grossWeight: number;
  uom: string;
  warehouseId: number;
  warehouseName: string | null;
  quantity: number;
  productCategoryId: number;
  warehousePlacement: string;
  gradeId: number;
  shape: string;
  defectDetails: string;
  attributes: {
    productSpecification: IProductSpecification[];
  };
  createdAt: string;
  updatedAt: string;
}

const SecondaryInventoryUpdatePage: React.FC = () => {
  const params = useParams();
  const secondaryInventoryId = parseInt(params.id ?? '0', 10);
  const secondaaryInventoryService = useSecondaryInventoryService();
  const { showSnackbar, SnackBarComponent } = useSnackbar();
  const fileService = useFileService();
  const [image, setImagesTo] = useState<(ImageData | File)[]>([]);
  
  const [secondaryInventory, setSecondaryInventoryTo] = useState<ISecondaryInventoryResponse | null>(null);
  const getSecondaryInventoryById = async (secondaryId: number) => {
      try {
        const secondaryInventoryResponse = await  secondaaryInventoryService.getSecondaryInventoryById(secondaryId);
        (secondaryInventoryResponse.status === HTTP_STATUS.OK) && setSecondaryInventoryTo(secondaryInventoryResponse?.data?.data);
        const fileResponse = await fileService.getFilesById(secondaryId, RESOURCE_TYPE.SECONDARY_INVENTORY);
        (fileResponse.status === HTTP_STATUS.OK) && setImagesTo(fileResponse?.data?.data);
      } catch (error) {
        showSnackbar("error", 'Secondary Catalogue fetch failed');
        setSecondaryInventoryTo(null);
				setImagesTo([]);
      }
  };

  useEffect(() => {
    getSecondaryInventoryById(secondaryInventoryId);
  }, [secondaryInventoryId]);

  return (
    <SecondaryInventoryFormTemplate secondaryInventory={secondaryInventory} mode={MODE.UPDATE} image={image} setImagesTo={setImagesTo} />
  )
}

export default SecondaryInventoryUpdatePage