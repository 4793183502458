import { request} from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";
export const BULK_UPLOAD_URLS = {
  GET_ALL_BULK_UPLOAD: "/bulk-upload-registry",
  GET_BULK_UPLOAD: "/bulk-upload-registry/:id",
  GET_BULK_UPLOAD_TEMPLATE: "/excel/download",
  CREATE_BULK_UPLOAD_REGISTRY: "/bulk-upload-registry",
  READ_EXCEL_BULK_UPLOAD: "/excel/upload/bulk-upload-registry/:id"
};

interface IBulkUploadRequest {
  search?: string;
  status?: string;
  page: number;
  size: number;
  sort?: string;
}

interface IBulkUploadDownloadRequest {
  categoryId: number,
  shapes: string[],
  classes: string[],
  catalogueType?: string
}

interface IBulkUploadDownloadParams {
  resourceType: string,
}

export const useBulkUploadService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const getAllBulkUpload = (params: IBulkUploadRequest) => {
    return request(API_METHOD.GET, BULK_UPLOAD_URLS.GET_ALL_BULK_UPLOAD, authenticatedUser, null, { params });
  };

  const getBulkUploadById = (id: number) => {
    return request(API_METHOD.GET, replaceUrlParams(BULK_UPLOAD_URLS.GET_BULK_UPLOAD, { id }), authenticatedUser);
  };

  const getBulkUploadTemplate = (data: IBulkUploadDownloadRequest, params: IBulkUploadDownloadParams) => {
    return request(API_METHOD.POST, BULK_UPLOAD_URLS.GET_BULK_UPLOAD_TEMPLATE, authenticatedUser, data, { params });
  };

  const createBulkUploadRegistry = (data: FormData, onUploadProgress:any) => {
    return request(API_METHOD.POST, BULK_UPLOAD_URLS.CREATE_BULK_UPLOAD_REGISTRY, authenticatedUser, data, { params: {} }, onUploadProgress);
  }

  const readExcelBulkUpload = (id: number): Promise<any> => {
    return request(API_METHOD.GET, replaceUrlParams(BULK_UPLOAD_URLS.READ_EXCEL_BULK_UPLOAD, { id }), authenticatedUser);
  }

  return {
    getAllBulkUpload,
    getBulkUploadById,
    getBulkUploadTemplate,
    createBulkUploadRegistry,
    readExcelBulkUpload,
  };
};
