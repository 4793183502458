import React from "react";
import { useNavigate } from "react-router-dom";
import Select from "../../atoms/Select";
import TextField from "../../atoms/TextField";
import { ILead, ILeadFilter } from "../../pages/Lead/LeadList.page";
import { CLASS_TYPE, IPagination } from "../../../utils/types";
import Table, { ColumnType } from "../../organisms/Table";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { createUseStyles } from "react-jss";
import moment from "moment";
import { ADMIN_ROUTES, LEAD_STATUS } from "../../../utils/constant";
import { enumToString } from "../../../utils/helper";
import ResourceStatus from "../../atoms/ResourceStatus/ResourceStatus";

interface LeadListTemplateProps {
	leads: ILead[];
	pagination: IPagination;
	handlePaginationChange: any;
	handleRowsPerPageChange: any;
	handleFiltersChange?: any;
	leadFilters: ILeadFilter;
}

const useStyles = createUseStyles((theme: any) => ({
	iconContainer: {
		color: theme.palette.icon.primaryLight,
	},
}));

const LeadListTemplate: React.FC<LeadListTemplateProps> = ({ leads, pagination, handlePaginationChange, handleRowsPerPageChange, leadFilters, handleFiltersChange }) => {
	const classes = useStyles();
	const schema = {
		id: "1",
		title: "",
		pagination: {
			total: pagination.totalRecords,
			currentPage: pagination.currentPage,
			isVisible: true,
			limit: pagination.pageSize,
			handleChangePage: handlePaginationChange,
			handleChangeRowsPerPage: handleRowsPerPageChange,
		},
		columns: [
			{ label: "Sr No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
			{ label: "Lead ID", key: "id", type: "number" as ColumnType, props: { className: '' } },
			{ label: "Sales Representative", key: "actorName", type: "string" as ColumnType, props: { className: '' } },
			{ label: "No.Of Item", key: "leadUpcResponseDTOLength", type: "number" as ColumnType, props: { className: '' } },
			{ label: "Customer Name", key: "customerName", type: "string" as ColumnType, props: { className: '' } },
			{ label: "Company Name", key: "companyName", type: "string" as ColumnType, props: { className: '' } },
			{ label: "Status", key: "status", component: ({ value }: { value: string }) => <ResourceStatus status={value} />, type: "custom" as ColumnType, props: {} }, 
			{ label: "Created Date", key: "createdAt", type: "datetime" as ColumnType, props: { className: '' } },
			{ label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } },
		],
	};

	const Action = (id: number) => {
		return (
			<div className={ `${ classes.iconContainer } flex gap-x-2 justify-end` }>
				<RemoveRedEyeIcon className="text-secondary cursor-pointer" onClick={ (e) => handleViewClick(id) } />
			</div>
		);
	};

	const records = leads.map((lead: ILead, index: number) => [
		pagination.currentPage * pagination.pageSize + index + 1,
		lead.id,
		lead.actorName,
		lead.noOfItem,
		lead.customerName,
		lead.companyName,
		enumToString(lead.status),
		moment(lead.createdAt).format("YYYY-MM-DD HH:mm:ss"),
		Action(lead.id),
	]);

	const navigate = useNavigate();
	const handleViewClick = (leadId: number) => {
		navigate(`${ ADMIN_ROUTES.LEAD_VIEW }/${ leadId }`);
	};

	return (
		<div className="grid gap-y-1.5">
			<div className="flex justify-between ">
				<div className="flex gap-x-3 ">
					<Select
						variant="outlined"
						className="w-44 h-full"
						label="Status"
						value={ leadFilters.status }
						onChange={ (event) => handleFiltersChange(event) }
						name="status"
						id="status"
						options={[
							{ value: LEAD_STATUS.ALL, label: LEAD_STATUS.ALL },
							{ value: LEAD_STATUS.ONGOING, label: LEAD_STATUS.ONGOING },
							{ value: LEAD_STATUS.ACCEPTED, label: LEAD_STATUS.ACCEPTED },
							{ value: LEAD_STATUS.REJECTED, label: LEAD_STATUS.REJECTED },
						]}
					/>
					<Select
						variant="outlined"
						className="w-44 h-full"
						label="Catalogue Type"
						value={ leadFilters.catalogueType }
						onChange={ (event) => handleFiltersChange(event) }
						name="catalogueType"
						id="catalogueType"
						options={[
							{ value: "ALL", label: "ALL" },
							{ value: CLASS_TYPE.STANDARD, label: "Standard" },
							{ value: CLASS_TYPE.SECONDARY, label: "Secondary" },
							{ value: CLASS_TYPE.MAKE_TO_ORDER, label: "Secondary Lot" },
						]}
					/>
				</div>
				<TextField
					className="w-72"
					label="Search By Customer Name..."
					variant="outlined"
					type="text"
					name="customerName"
					value={ leadFilters.customerName }
					onChange={ handleFiltersChange }
				/>
			</div>
			<Table schema={ schema } records={ records } />
		</div>
	);
};

export default LeadListTemplate;