import React from "react";
import { Route, Routes } from "react-router-dom";
import CatalogueTable from "./../../Pages/Catalogue/CatalogueTable";
import AddCatalogue from "./../../Pages/Catalogue/AddCatalogue"; 
import EditCatalogue from "../../Pages/Catalogue/EditCatalogue";
import ViewCatalogue from "../../Pages/Catalogue/ViewCatalogue";
import AddSecondaryCatalogue from "../../Pages/SecondaryCatalogue/AddSecondaryCatalogue";
import ViewSecondaryCatalogue from "../../Pages/SecondaryCatalogue/ViewSecondaryCatalogue";
import ShareCatalogue from "../../Pages/Catalogue/ShareCatalogue";
import SecondaryCatalogueList from "../../../components/pages/SecondaryCatalogue/SecondaryCatalogueList.page";
import SecondaryCatalogueCreate from "../../../components/pages/SecondaryCatalogue/SecondaryCatalogueCreate.page";
import SecondaryCatalogueUpdate from "../../../components/pages/SecondaryCatalogue/SecondaryCatalogueUpdate.page";
import SecondaryCatalogueView from "../../../components/pages/SecondaryCatalogue/SecondaryCatalogueView.page";


const CatalogueRoutes = () => {

  return (
      <Routes>
        <Route path="/" element={<CatalogueTable />} />
        <Route path="/add" element={<AddCatalogue />} />   
        <Route path="/view" element={<ViewCatalogue />} /> 
        <Route path="/edit" element={<EditCatalogue />} />  
        <Route path="/share" element={<ShareCatalogue />} />  
        <Route path="/secondary-add" element={<AddSecondaryCatalogue />} />
        <Route path="/secondary-edit" element={<AddSecondaryCatalogue />} />
        <Route path="/secondary-view" element={<ViewSecondaryCatalogue />} />

        <Route path="/secondary" element={<SecondaryCatalogueList />} />
        <Route path="/secondary/add" element={<SecondaryCatalogueCreate />} />
        <Route path="/secondary/:id" element={<SecondaryCatalogueView />} />
        <Route path="/secondary/:id/update" element={<SecondaryCatalogueUpdate />} />
      </Routes>
  );
};

export default CatalogueRoutes;
