import React from "react";
import { createUseStyles } from "react-jss";
import moment from "moment";
import { IBulkUpload } from "./BulkUploadList.page";
import { IPagination } from "../../../utils/types";
import Table, { ColumnType } from "../../organisms/Table";
import { enumToString } from "../../../utils/helper";
import ResourceStatus from "../../atoms/ResourceStatus/ResourceStatus";
import DownloadLink from "../../atoms/DownloadLink/DownloadLink";
import bulkUploadDownloadIcon from "../../../assets/icons/bulkUploadDownload.svg";
import BulkUploadRefresh from "./BulkUploadRefresh";
import bulkUploadRefreshIcon from "../../../assets/icons/bulkUploadRefresh.svg";

interface BulkUploadListTemplateProps {
	bulkUploads: IBulkUpload[];
	setBulkUploadsTo: any;
	pagination: IPagination;
	handlePaginationChange: (event: any, newPage: number) => void;
	handleRowsPerPageChange: (event: any) => void;
}

const useStyles = createUseStyles((theme: any) => ({
	iconContainer: {
		color: theme.palette.icon.primaryLight,
	},
	fileNameWrapper: {
		color: theme.palette.text.primaryLightAccent,
		textAlign: "center",
		fontFamily: "Roboto",
		fontSize: "14px",
		fontStyle: "normal",
		fontWeight: "600",
	},
}));


const BulkUploadListTemplate: React.FC<BulkUploadListTemplateProps> = ({ bulkUploads, setBulkUploadsTo, pagination, handlePaginationChange, handleRowsPerPageChange }) => {
	
	const classes = useStyles();

	const schema = {
		id: "bulk-upload-tracking",
		title: "",
		pagination: {
			total: pagination.totalRecords,
			currentPage: pagination.currentPage,
			isVisible: true,
			limit: pagination.pageSize,
			handleChangePage: handlePaginationChange,
			handleChangeRowsPerPage: handleRowsPerPageChange,
		},
		columns: [
			{ label: "Sr No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
			{ label: "Batch ID", key: "id", type: "number" as ColumnType, props: { className: '' } },
			{ label: "File Name", key: "fileName", type: "string" as ColumnType, props: { className: classes.fileNameWrapper } },
			{ label: "Module Name", key: "moduleName", type: "string" as ColumnType, props: { className: '' } },
			{ label: "Upload Date", key: "createdAt", type: "datetime" as ColumnType, props: { className: '' } },
			{
				label: "Status",
				key: "status",
				type: "custom" as ColumnType,
				component: ({ value }: { value: string }) => <ResourceStatus status={value} />,
				props: {}
			},
			{
				label: "Action",
				key: "action",
				type: "custom" as ColumnType,
				component: ({ value }: { value: string }) => <DownloadLink icon={bulkUploadDownloadIcon} downloadUrl={value} />,
				props: {}
			},
			{
				label: "Refresh line item",
				key: "refresh",
				type: "custom" as ColumnType,
				component: ({ value }: { value: number }) => <BulkUploadRefresh icon={bulkUploadRefreshIcon} batchId={value} bulkUploads={bulkUploads} setBulkUploadsTo={setBulkUploadsTo} />,
				props: { className: '' }
			},
		],
	};

	const records = bulkUploads.map((bulkUpload: IBulkUpload, index: number) => [
		pagination.currentPage * pagination.pageSize + index + 1,
		bulkUpload.id,
		bulkUpload.fileName,
		bulkUpload.section,
		moment(bulkUpload.createdAt).format("YYYY-MM-DD HH:mm:ss"),
		enumToString(bulkUpload.status),
		bulkUpload.report,
		bulkUpload.id,
	]);

	return (
		<div className="grid gap-y-1.5">
			<Table schema={schema} records={records} />
		</div>
	);
};

export default BulkUploadListTemplate;