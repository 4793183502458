import React, { useEffect, useMemo, useState } from "react";
import TitleHeading from "../../atoms/CustomHeading/TitleHeading";
import { useNavigate, useParams } from "react-router-dom";
import QuotationViewTemplate from "../../templates/Lead/QuotationView.template";
import { useQuotationService } from "../../../services/useQuotationService";
import { ADMIN_ROUTES, LEAD_STATUS, PRICE_DETAILS } from "../../../utils/constant";
import PaymentInfoTemplate, { IPriceDetails } from "../../templates/Lead/PaymentInfo.template";
import Button from "../../atoms/Button";
import TermsModal from "../../templates/Lead/TermsModal.template";
import { makeRoute } from "../../../utils/helper";
import { CLASS_TYPE } from "../../../utils/types";
import MTOQuotationViewTemplate from "../../templates/Lead/MTOQuotationView.template";

export interface Quotation {
	id: number;
	quantity: number;
	status: string;
	charges: Charges[];
	quotationUpcResponseDTO: IProduct[]
	catalogueType: string;
}

export interface Charges {
	warehouseCharges?: number;
	handlingCharges?: number;
	packagingCharges?: number;
	otherCharges?: number;
	gst?: number;
	tcs?: number;
}

export interface IProduct {
	id: number;
	quotationId: number
	upcId: number;
	upcCode: string;
	secondarySkuId: number;
	skuCode: string;
	skuData: SkuData[];
	quantity: number;
	catalogueName: string;
}

export interface GroupedData {
	[key: string]: {
	  id: number[];
	  secondarySkuId: number[];
	  skuCode: string[];
	  quantity: number; // Assuming you have a quantity property
	  // Add any other properties here if necessary
	  skuData: any;
	};
  }
export interface SkuData {
	skuId: string;
	skuCode: string;
	quantity: number;
	profit: number;
	price: number;
	basePrice: number;
	packaging: string;
	warehouse: string;
}

const QuotationView: React.FC = () => {
	const params = useParams();
	const quotationService = useQuotationService();
	const [quotation, setQuotationTo] = useState<Quotation | null>(null);
	const [charges, setCharges] = useState<Charges | null>(null);
	const [totalQuantity, setTotalQuantity] = useState<number>(0);
	const [termsAndCondition, setTermsAndCondition] = useState<string>("");
	const [open, setOpen] = useState<boolean>(false);
	const [content, setContent] = useState<string>("");
	const [grandTotal, setGrandTotalTo] = useState<number>(0);
	const [UOM, setUOMTo] = useState<string>("MT");
	const [paymentInfoDetails, setPaymentInfoDetailsTo] = useState<IPriceDetails>(() => PRICE_DETAILS({}));
	const [classType, setClassType] = useState<CLASS_TYPE>(CLASS_TYPE.STANDARD)

	const loadQuotation = async () => {
		try {
			const quotationUpcResponse = await quotationService.getQuotationById(Number(params.id));
			if (quotationUpcResponse?.status === 200 && quotationUpcResponse?.data?.data) {
				setQuotationTo(quotationUpcResponse.data.data);
				setTotalQuantity(quotationUpcResponse.data.data.quotationUpcResponseDTO.reduce(
					(total: any, item: any) => {
						const skuDataTotal = item.skuData.reduce(
							(skuTotal: any, sku: any) => skuTotal + parseFloat(sku.quantity) || 0, 0);
						return total + skuDataTotal
					}, 0)
				);
				setTermsAndCondition(quotationUpcResponse.data.data.termsAndCondition);
				setCharges(quotationUpcResponse.data.data.charges[0]);
				setClassType(quotationUpcResponse.data.data.catalogueType)
			} else {
				console.error("Failed to fetch quotation data:", quotationUpcResponse);
				throw new Error("Failed to fetch quotation");
			}
		} catch (error) {
			setQuotationTo(null);
			setTotalQuantity(0);
			setTermsAndCondition("");
			setCharges(null);
		}
	};

	const calculatePaymentInfoDetails = (data: Quotation) => {
		let grandTotal = 0;
		let totalQuantity = 0
		let productSubTotal = 0;
		let UOM = "MT";
		let paymentInfoDetails: IPriceDetails = PRICE_DETAILS({});
		const charges = data?.charges?.[0]
		const warehouseCharges = charges?.warehouseCharges ?? 0;
		const handlingCharges = charges?.handlingCharges ?? 0;
		const packagingCharges = charges?.packagingCharges ?? 0;
		const otherCharges = charges?.otherCharges ?? 0;
		data?.quotationUpcResponseDTO?.forEach((item: any) => {
			item?.skuData.forEach((sku: any) => {
				const skuPrice = (sku.quantity * sku.price);
				productSubTotal += skuPrice;
				totalQuantity += sku.quantity;
			});
		});
		paymentInfoDetails.subTotal.price = productSubTotal;
		paymentInfoDetails.warehouseCharges.price = (warehouseCharges * totalQuantity);
		paymentInfoDetails.handlingCharges.price = (handlingCharges * totalQuantity);
		paymentInfoDetails.packagingCharges.price = (packagingCharges * totalQuantity);
		paymentInfoDetails.otherCharges.price = (otherCharges);
		let totalProductPrice = (paymentInfoDetails.subTotal.price + ((warehouseCharges + handlingCharges + packagingCharges) * totalQuantity)) + otherCharges;
		paymentInfoDetails.GST.price = ((charges?.gst ?? 0) * totalProductPrice) / 100;
		paymentInfoDetails.TCS.price = ((charges?.tcs ?? 0) * totalProductPrice) / 100;
		grandTotal = totalProductPrice + paymentInfoDetails.GST.price + paymentInfoDetails.TCS.price;
		setGrandTotalTo(grandTotal)
		setUOMTo(UOM)
		setPaymentInfoDetailsTo(paymentInfoDetails);
	};

	useEffect(() => {
		loadQuotation();
	}, [params.id]);

	useEffect(() => {
		if (quotation) {
			calculatePaymentInfoDetails(quotation);
		}
	}, [quotation])

	const navigate = useNavigate();
	const handleRedirect = () => {
		navigate((-1));
	};

	const handleEditQuotation = (id: number) => () => {
		if (classType === CLASS_TYPE.STANDARD) {
			navigate(makeRoute(ADMIN_ROUTES.QUOTATION_UPDATE, { params:  { id: params.id } }));
        } else if (classType === CLASS_TYPE.SECONDARY) {
            navigate(makeRoute(ADMIN_ROUTES.SECONDARY_QUOTATION_UPDATE, { params:  { id: params.id } }));
        } else if (classType === CLASS_TYPE.MAKE_TO_ORDER) {
            navigate(makeRoute(ADMIN_ROUTES.MTO_QUOTATION_UPDATE, { params:  { id: params.id } }));
        }
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};
	
	const groupData = () => {
		const groupedData: GroupedData = {};
		quotation?.quotationUpcResponseDTO.forEach(obj => {
			const { catalogueName } = obj;
			if (!groupedData[catalogueName]) {
				groupedData[catalogueName] = { ...obj, id: [obj.id], secondarySkuId: [obj.secondarySkuId], skuCode: [obj.skuCode], skuData: [] };
			} else {
				groupedData[catalogueName].id.push(obj.id);
				groupedData[catalogueName].secondarySkuId.push(obj.secondarySkuId);
			}
				groupedData[catalogueName].skuData.push({
					skuId: obj.secondarySkuId,
					skuCode: obj.skuData[0].skuCode,
					quantity: obj.quantity,
					profit: obj.skuData[0].profit,
					price: obj.skuData[0].price,
					basePrice: obj.skuData[0].basePrice,
					packaging: obj.skuData[0].packaging,
					warehouse: obj.skuData[0].warehouse
				});
		});
		return Object.values(groupedData);
	};
	const groupedData = groupData();

	const quotationViewTemplate = useMemo(() => {
		if (classType === CLASS_TYPE.STANDARD || classType === CLASS_TYPE.SECONDARY) {
			return (
				<QuotationViewTemplate quotationItem={quotation} charges={charges} classType={classType} />
			)
		} else if (classType === CLASS_TYPE.MAKE_TO_ORDER) {
			return (
				<MTOQuotationViewTemplate quotationItem={groupedData} charges={charges} classType={classType} />
			)
		}
	}, [classType, quotation, groupedData]);
	return (
		<div className="grid gap-y-3">
			<div className="flex justify-between">
				<TitleHeading text={ `Quotation Id - ${ params.id }` } />
				<div className="flex gap-x-2">
					<Button variant="outlined" onClick={ handleOpen } label="Terms and Condition" />
					{quotation?.status === LEAD_STATUS.ACCEPTED || classType === CLASS_TYPE.MAKE_TO_ORDER ? null : <Button variant="contained" onClick={ handleEditQuotation(Number(params.id)) } label="Edit" />}
				</div>
			</div>

			<TermsModal
				open = { open }
				mode = { "VIEW" }
				termsAndCondition = { termsAndCondition }
				setContent = { setContent }
				onClose = { handleClose }
			/>

			{quotationViewTemplate}

			<PaymentInfoTemplate
				label = { "Payment Information" }
				showStatus = { false }
				priceDetails = {paymentInfoDetails }
				quantity = {totalQuantity }
				uom = { UOM }
				totalPrice = { grandTotal }
			/>

			<div className="m-auto mr-0 flex gap-x-3">
				<Button variant="outlined" onClick={ handleRedirect } label="Back" />
			</div>
		</div>
	);
};

export default QuotationView;
