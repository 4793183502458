import { Grid } from '@mui/material';
import { ProductAttributeHeading } from '../../../Shared/CustomHeading/ProductAttributeHeading';
import { productDescription } from '../../../Constants/Procurement/PurchaseRequisition/constants';
import PurchaseAttributeCard from '../PurchaseRequisition/PurchaseAttributeCard';
import { AuctionProductList } from '../../../Constants/Procurement/LiveAuction/constants';

interface ProductCardProps {
  click?: () => void;
  product: AuctionProductList;
}

export const LiveAuctionProductCard = ({
  click,
  product,
}: ProductCardProps) => {
  const attributes = product.item?.attributesMap.CLASSIFICATION.concat(
    product.item?.attributesMap.SPECIFICATION,
    product.item?.attributesMap.GENERALIZATION
  );
  const img = product?.item?.productCategoryImageURL;
  return (
    <div className="border border-cgray-4 rounded-md px-3 py-4" onClick={click}>
      <Grid container alignItems="center" spacing={2} className="h-full">
        <Grid item xs={2}>
          <div className="flex items-center gap-x-2">
            <img src={img} alt="productImg" className="w-14 h-12 rounded" />
            <ProductAttributeHeading
              title="UPC Code"
              value={product?.item?.upcCode || ''}
              className="text-blue font-medium text-nowrap"
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <ProductAttributeHeading
            title="UPC Description"
            value={productDescription(product.item)}
            className="text-sm mt-0.5"
          />
        </Grid>
        <Grid item xs={5.5}>
          <PurchaseAttributeCard attributes={attributes} />
        </Grid>
        <Grid item xs={1.5}>
          <div className="bg-cgray-13 min-h-[92px] p-2 rounded-md">
            <ProductAttributeHeading
              title={'Quantity'}
              value={product.quantity + ' MT'}
              className={'text-sm mt-0.5'}
            />
            <ProductAttributeHeading
              title={'Consignee Location'}
              value={product.warehouseId}
              className={'text-sm mt-0.5'}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
