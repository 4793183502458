import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { FormikProps } from 'formik';
import { LOCATION_STATES } from '../../../../utils/types';
import { ILocationGstinForm } from '../../../pages/Dashboard/Location/BusinessLocation.page';
import ViewVendorLocationTemplate from './ViewVendorLocation.template';
import AddVendorLocationTemplate from './AddVendorLocation.template';
import GetNumberOfVendorLocationTemplate from './GetNumberOfVendorLocation.template';
import AddNewVendorLocationTemplate from './AddNewVendorLocation.template';

export interface IVendorLocationProps {
    formik: FormikProps<ILocationGstinForm>;
}

export interface LocationSectionProps {
    currentSection? :  LOCATION_STATES;
    setCurrentSectionTo: (section: LOCATION_STATES) => void;
    formik: FormikProps<ILocationGstinForm>;
    setButtonShow? : Dispatch<SetStateAction<boolean>>
}

const VendorLocationTemplate: React.FC<IVendorLocationProps> = ({ formik }) => {
    const [currentSection, setCurrentSection] = useState<LOCATION_STATES>(LOCATION_STATES.VIEW_LOCATIONS);

    const locationSectionProps: LocationSectionProps = {
        setCurrentSectionTo: setCurrentSection,
        formik: formik,
        currentSection: currentSection,
    };

    const secondaryInventorySectionView = useMemo(() => {
        switch (currentSection) {
            case LOCATION_STATES.VIEW_LOCATIONS:
                return formik.values?.locations?.length > 0 ? (
                    <ViewVendorLocationTemplate {...locationSectionProps} />
                ) : (
                    <AddVendorLocationTemplate {...locationSectionProps} />
                );
            case LOCATION_STATES.ADD_LOCATION_NO:
                return <GetNumberOfVendorLocationTemplate {...locationSectionProps} />;
            case LOCATION_STATES.ADD_LOCATIONS:
                return <AddNewVendorLocationTemplate {...locationSectionProps} />;
            default:
                return formik.values?.locations?.length > 0 ? (
                    <ViewVendorLocationTemplate {...locationSectionProps} />
                ) : (
                    <AddVendorLocationTemplate {...locationSectionProps} />
                );
        }
    }, [currentSection, formik]);
    return (
        <div className='grid gap-8  justify-items-stretch'>
            {secondaryInventorySectionView}
        </div>
    );
};
export default VendorLocationTemplate
