const StatusEnum = {
    PUBLISHED: "PUBLISHED",
    UNPUBLISHED: "UNPUBLISHED",
};

export const mapCatalogueStatusEnumToString = (enumValue) => {
    switch (enumValue) {
      case StatusEnum.PUBLISHED:
        return "Published";
      case StatusEnum.UNPUBLISHED:
        return "Unpublished";
      default:
        return "";
    }
};