import { createUseStyles } from "react-jss";
import Select from "../../../atoms/Select";
import { MODE, SHAPE_TYPES } from "../../../../utils/constant";
import { CLASS_TYPE } from "../../../../utils/types";

interface ProductClassificationSearchTemplateProps {
    formik: any;
    type: string;
    mode: string;
}

const UPC_CLASS_TYPE = [
    {
        label: "Standard",
        id: 'STANDARD'
    },
]

const MPC_CLASS_TYPE = [
    {
        label: "Non Standard",
        id: 'NONSTANDARD'
    },
]

const useStyles = createUseStyles((theme: any) => ({
    container: {
        backgroundColor: theme.palette.background.primaryLight,
    },
    heading: {
        color: theme.palette.text.primaryDarkLight,
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
}));

const ProductClassificationSearchTemplate: React.FC<ProductClassificationSearchTemplateProps> = ({ formik, type, mode }) => {

    const classes = useStyles()
    const classTypes = type === CLASS_TYPE.STANDARD ? UPC_CLASS_TYPE : MPC_CLASS_TYPE
   
    return (
        <div className={`${classes.container} grid gap-y-4 p-6 rounded`}>
            <div className={` ${classes.heading} text-lg font-semibold`}>
                Product Classification
            </div>

            <div className="grid grid-cols-2 gap-x-3">
                <div className='grid'>
                    <Select
                        className="bg-white"
                        variant="outlined"
                        label="Select Shape"
                        fullWidth
                        {...formik.getFieldProps("shape")}
                        error={
                            formik.touched.superCategory &&
                            Boolean(formik.errors.superCategory)
                        }
                        inputProps={{ readOnly: mode !== MODE.ADD ? true : false }}
                        options={
                            SHAPE_TYPES?.map((option: any) => ({
                                value: (option.id),
                                label: option.label,
                            })) || []
                        }
                    />
                    {formik.touched.shape && formik.errors && formik.errors.shape && (
                        <div className={classes.errorMessage}>
                            <small>{formik.errors.shape}</small>
                        </div>
                    )}
                </div>

                <div className='grid'>
                    <Select
                        className="bg-white"
                        variant="outlined"
                        label="Select Class"
                        fullWidth
                        {...formik.getFieldProps("classType")}
                        error={
                            formik.touched.classType &&
                            Boolean(formik.errors.classType)
                        }
                        inputProps={{ readOnly: mode !== MODE.ADD ? true : false }}
                        options={
                            classTypes?.map((option: any) => ({
                                value: (option.id),
                                label: option.label,
                            })) || []
                        }
                    />
                    {formik.touched.classType && formik.errors && formik.errors.classType && (
                        <div className={classes.errorMessage}>
                            <small>{formik.errors.classType}</small>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
export default ProductClassificationSearchTemplate;