import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { FormikProps } from 'formik';
import maleIcon from '../../../assets/icons/maleIcon.svg'
import femaleIcon from '../../../assets/icons/femaleIcon.svg'
import { IkeyDetailForm } from '../../pages/CustomerManagement/KeyPersonDetails.page';
import { KEY_PERSON_DETAIL_STATES, HONORIFICS } from '../../../utils/types';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { getProfileDisplayData } from '../../../utils/helper';
import { Link } from 'react-router-dom';
import SocialMediaLinks from '../../molecules/socialMediaLinks/SocialMediaLinks';

const useStyles = createUseStyles((theme: any) => ({
    text: {
        color: theme.palette.text.primaryLight
    },
    urlText: {
        color: theme.palette.textV2.secondary.secondary600

    },
    value: {
        color: theme.palette.textV2.tertiary.tertiary700
    },
}));

export interface IPocSectionProps {
    setCurrentSectionTo: (section: KEY_PERSON_DETAIL_STATES) => void;
    formik: FormikProps<IkeyDetailForm>;
    poc: any;
    index: number;
}

export const ViewKeyPersonDetailsTemplate: React.FC<IPocSectionProps> = ({ formik, setCurrentSectionTo, poc, index }) => {

    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const [data, setData] = useState<any>(formik.values?.pocs?.[index]);

    const transformSocialLinks = (links: { [key: string]: string } | null | undefined) => {
        if (!links) {
            return [];
        }
        return Object.entries(links).map(([type, url]) => ({ type, url }));
    };

    useEffect(() => {
        setData(formik.values?.pocs?.[index])
    }, [formik])

    const handleSocialMediaRedirection = data && data.socialLinks ? transformSocialLinks(data.socialLinks) : [];

    const onLinkClick = (link: any) => {
        const isValidLink = /^https?:\/\//i.test(link);
        if (isValidLink) {
            window.open(link, "_blank");
        } else {
            showSnackbar('error', "Invalid link. Please provide a complete URL.");
        }
    }

    if (!data) {
        return null;
    }

    return (
        <>
            <div key={123134}>
                <div className='flex mb-6'>
                    <div className='gap-y-1 gap-x-6 flex '>
                        <div className={`w-36 h-40`}>
                            <span className={`text-base font-medium flex pb-3 ${classes.text}`}>Key Person Images</span>
                            <div className={` w-full h-full rounded-lg flex relative justify-between`}>
                                <div>
                                    {poc?.profilePic ? (
                                        <img src={poc?.profilePic} alt="Profile" className="!w-full h-full  rounded-lg object-cover" />
                                    ) : (
                                        <div>
                                            {poc?.prefix === HONORIFICS.MR ? (
                                                <img src={maleIcon} alt="Profile" className="rounded-lg object-cover" />
                                            ) : (
                                                <img src={femaleIcon} alt="Profile" className="rounded-lg object-cover" />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='grid gap-y-6'>
                            <div className='grid grid-cols-2 gap-x-4 gap-y-1 '>
                                <div>
                                    <span className={`text-sm ${classes.text}`}>Prefix</span>
                                    <div className={`${classes.value} font-medium`}>{getProfileDisplayData(data?.prefix)}</div>
                                </div>
                                <div>
                                    <span className={`text-sm ${classes.text}`}>Name</span>
                                    <div className={`${classes.value} font-medium`}>{getProfileDisplayData(data?.name)}</div>
                                </div>
                            </div>
                            <div className='grid grid-cols-2 gap-x-4 gap-y-1 '>
                                <div>
                                    <span className={`text-sm ${classes.text}`}>Department</span>
                                    <div className={`${classes.value} font-medium`}>{getProfileDisplayData(data?.department)}</div>
                                </div>
                                <div>
                                    <span className={`text-sm ${classes.text}`}>Designation</span>
                                    <div className={`${classes.value} font-medium`}>{getProfileDisplayData(data?.designation)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='grid grid-cols-2 gap-x-4 gap-y-1 mb-6 pt-6'>
                    <div>
                        <span className={`text-sm ${classes.text}`}>Email ID</span>
                        <div className={`${classes.value} font-medium`}>{getProfileDisplayData(data?.email)}</div>
                    </div>
                    <div>
                        <span className={`text-sm ${classes.text}`}>Alternate Email ID</span>
                        <div className={`${classes.value} font-medium`}>{getProfileDisplayData(data?.alternateEmail)}</div>
                    </div>
                </div>
                <div className='grid grid-cols-2 gap-x-4 gap-y-1 mb-6'>
                    <div>
                        <text className={`text-sm ${classes.text}`}>Phone Number</text>
                        <div className={`${classes.value} font-medium`}>{data.mobile ? `(+91) ${data.mobile}` : 'No Data'}</div>
                    </div>
                    <div>
                        <text className={`text-sm ${classes.text}`}>Alternate Phone Number</text>
                        <div className={`${classes.value} font-medium`}>{data.alternateMobile ? `(+91) ${data.alternateMobile}` : 'No Data'}</div>
                    </div>
                </div>
                <div>
                    {handleSocialMediaRedirection.length > 0 ?
                        (
                            handleSocialMediaRedirection
                                .filter(link => link.url)
                                .map((link: any, index: number) => (
                                    <div key={index} className={index !== handleSocialMediaRedirection.filter(link => link.url).length - 1 ? 'mb-6' : ''}>
                                        <span className={`text-sm pb-1 ${classes.text}`}> Social Media Links ({link.type})</span><br />
                                        <div className={` ${classes.urlText} underline text-base font-medium cursor-pointer`} onClick={() => onLinkClick(link.url)}> {link.url}</div>
                                    </div>
                                ))
                        )
                        :
                        (
                            <span className={`text-sm ${classes.text}`}>No social media links available</span>
                        )}
                </div>

            </div>

        </>
    )
}
