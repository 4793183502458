import axios from 'axios';

const uploadFiles = async (
  fileArray: File[],
  token: string,
  useCase: string
): Promise<string[]> => {
  console.log(useCase);

  try {
    const links: string[] = [];
    for (const file of fileArray) {
      const { presignedUrl, objectKey } = await generatePresignedUrl(
        file.name,
        file.type,
        token,
        useCase
      );
      links.push(objectKey);
      await uploadFileToPresignedUrl(presignedUrl, file);
    }
    return links;
  } catch (error) {
    console.log('Error in uploadFiles genPresignedUrl', error);
    throw error;
  }
};

const generatePresignedUrl = async (
  fileName: string,
  fileType: string,
  token: string,
  useCase: string
): Promise<{ presignedUrl: string; objectKey: string }> => {
  try {
    const response = await axios.post(
      `${
        process.env.REACT_APP_API_V1_URL
      }/${'file/upload/generate_presigned_url'}?useCase=${useCase}&fileNamePrefix=${fileName}&fileExtension=${mimeTypeToFileExtension(
        fileType
      )}`,
      null,
      {
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};

const uploadFileToPresignedUrl = async (
  presignedUrl: string,
  file: File
): Promise<void> => {
  try {
    await axios.put(presignedUrl, file, {
      headers: {
        'Content-Type': file.type,
      },
    });
  } catch (error) {
    throw error;
  }
};

const mimeTypeToFileExtension = (mimeType: string): string => {
  switch (mimeType) {
    case 'image/jpeg':
    case 'image/pjpeg':
      return 'jpeg';
    case 'application/pdf':
      return 'pdf';
    case 'image/png':
      return 'png';
    case 'image/gif':
      return 'gif';
    case 'image/bmp':
      return 'bmp';
    case 'image/svg+xml':
      return 'svg';
    case 'audio/mpeg':
      return 'mp3';
    case 'audio/wav':
      return 'wav';
    case 'video/mp4':
      return 'mp4';
    default:
      return '';
  }
};

export { uploadFiles };
