import moment from "moment";
import { useEffect, useRef, useState } from "react";

interface DateValueProps {
  currentYear: number;
  setCurrentYear: (year: number) => void;
  date?: Date;
  minYear?: number;
  maxYear?: number;
}

// Todo

const DateValue = ({
  currentYear,
  setCurrentYear,
  date,
  minYear = new Date().getFullYear() - 30,
  maxYear = new Date().getFullYear() + 30,
}: DateValueProps) => {
  const dateValue = date ? moment(date).format("DD/MM/YYYY") : "DD/MM/YYYY";
  const [show, setShow] = useState<boolean>(false);

  const listRef = useRef<HTMLUListElement>(null);

  const toggleShow = () => {
    console.log("called");
    setShow(!show);
  };

  const handleClickOutside = (event: MouseEvent) => {
    const targetElement = event.target as HTMLElement;
    if (
      listRef.current &&
      !listRef.current.contains(targetElement) &&
      !targetElement.closest(".relative")
    ) {
      event.stopPropagation();
      toggleShow();
    }
  };
  const scrollToCurrentYear = () => {
    if (listRef.current) {
      const currentYearElement = listRef.current?.querySelector(
        `li[data-year='${currentYear}']`
      ) as HTMLElement | null;
      if (currentYearElement) {
        const scrollTop =
          currentYearElement.offsetTop -
          listRef.current.offsetHeight / 2 +
          currentYearElement.offsetHeight / 2;
        listRef.current.scrollTop = scrollTop;
      }
    }
  };

  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
      scrollToCurrentYear();
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  return (
    <div className="flex justify-between items-center gap-6">
      <div className=" bg-white shadow-dateinput w-full rounded-md h-[40px] flex items-center gap-4">
        <div className="px-1 border-r border-blue border-dashed">
          <span className="w-24 h-24">
            <svg
              width="36"
              height="39"
              viewBox="0 0 36 39"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_d_5505_196715)">
                <path
                  d="M12 7.5V10.5"
                  stroke="#1D3679"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M20 7.5V10.5"
                  stroke="#1D3679"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.5 14.5898H24.5"
                  stroke="#1D3679"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M25 14V22.5C25 25.5 23.5 27.5 20 27.5H12C8.5 27.5 7 25.5 7 22.5V14C7 11 8.5 9 12 9H20C23.5 9 25 11 25 14Z"
                  stroke="#1D3679"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.6976 19.1992H19.7066"
                  stroke="#1D3679"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.6976 22.1992H19.7066"
                  stroke="#1D3679"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.9945 19.1992H16.0035"
                  stroke="#1D3679"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.9945 22.1992H16.0035"
                  stroke="#1D3679"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.2914 19.1992H12.3004"
                  stroke="#1D3679"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.2914 22.1992H12.3004"
                  stroke="#1D3679"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_5505_196715"
                  x="-2"
                  y="-0.5"
                  width="40"
                  height="40"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="2" dy="2" />
                  <feGaussianBlur stdDeviation="4" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.960784 0 0 0 0 0.960784 0 0 0 0 0.960784 0 0 0 0.4 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_5505_196715"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_5505_196715"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </span>
        </div>
        <div className={`font-normal ${date ? "" : "text-datePicker-text"}`}>
          {dateValue}
        </div>
      </div>
      <div>
        <div
          defaultValue={currentYear.toString()}
          className="bg-white shadow-dateinput rounded-md w-[110px] py-2 px-3 relative"
          onClick={() => toggleShow()}
        >
          <div className="text-blue font-medium flex justify-between items-center ">
            <span>{currentYear}</span>
            <span
              className={`transition-transform transform duration-150 ${
                show ? "-rotate-180" : ""
              }`}
            >
              <svg
                width="13"
                height="7"
                viewBox="0 0 13 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.44 1.13477L5.78667 5.48143C6.3 5.99477 7.14 5.99477 7.65333 5.48143L12 1.13477"
                  stroke="#1D3679"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </div>
          {show ? (
            <ul
              ref={listRef}
              className="absolute h-52 overflow-y-auto w-full no-scrollbar bg-white left-0 top-[40px] rounded-md shadow-dateinput"
            >
              {Array.from({ length: maxYear - minYear + 1 }, (_, index) => {
                const year = minYear + index;
                return (
                  <li
                    className={`py-2 px-3 cursor-pointer ${
                      year === currentYear
                        ? "bg-variant-cyan3"
                        : "hover:bg-cgray-6 "
                    }`}
                    key={year}
                    data-year={year}
                    onClick={() => {
                      setCurrentYear(year);
                      toggleShow();
                    }}
                  >
                    {year}
                  </li>
                );
              })}
            </ul>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default DateValue;
