import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useNavigate, useLocation } from 'react-router-dom';

import AttributeTableIndex from './AttributeTableIndex';
import AttributeTypeTableIndex from './AttributeTypeTableIndex';

const Header = ({ handleAttributeClick, type }) => {
  const navigate = useNavigate();
  const navigateTo = () => {
    navigate(
      `/${type.split(' ').join('-').toLowerCase()}/add`,
      {
        state: {
          type: 'ADD', 
          itemId: null,
        },
      });
  };

  return (
    <div className='relative w-full flex items-center justify-between gap-4 text-left text-2xl text-blue font-roboto'>
      <h2 className='flex-shrink-0 overflow-hidden py-2 px-4 text-2xl text-blue font-bold'>
        {type}
      </h2>

      <div className='self-stretch flex flex-row items-center justify-between'>
        <Box>
          <Tabs value={type} onChange={handleAttributeClick}>
            {/* <Tab
              style={{
                color: type === 'Attribute Type' && '#1D3679',
                borderBottom: type === 'Attribute Type' && '1px solid #1D3679',
              }}
              component='a'
              label='Attribute Type'
              onClick={() => handleAttributeClick('Attribute Type')}
            /> */}
            {/* <Tab
              style={{
                color: type === 'Attribute' && '#1D3679',
                borderBottom: type === 'Attribute' && '1px solid #1D3679',
              }}
              component='a'
              label='Attribute'
              onClick={() => handleAttributeClick('Attribute')}
            /> */}
          </Tabs>
        </Box>

        <button
          className='rounded bg-customBlue overflow-hidden flex py-2 px-4 items-center justify-center text-lg text-white font-semibold'
          onClick={navigateTo}
        >
          Add New {type}
        </button>
      </div>
    </div>
  );
};


const Attributes = () => {
  const location = useLocation();
  const { type = 'Attribute' } = location.state || {};
  const [index, setIndex] = useState(type);

  // useEffect(() => {
  //   setIndex(type)
  // })

  const handleIndex = (type) => {
    setIndex(type);
  };

  return (
    <div className='grid gap-y-3 gap-x-4 m-t-10 p-x-10'>
      <div className='py-4'>
        <Header type={index} handleAttributeClick={handleIndex} />
      </div>

      {/* <DropDownOptions /> */}

      <div>
        {index === 'Attribute Type' ? (
          <AttributeTypeTableIndex />
        ) : (
          <AttributeTableIndex />
        )}
      </div>
    </div>
  );
};

export default Attributes;
