import React, { useEffect, useMemo, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import JoditEditor from "jodit-react";
import { Modal } from "@mui/material";
import { FormControl } from "@mui/base";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../../atoms/Button";

interface ConfigProps { }

interface TermsModalProps {
	open: boolean;
	mode: string;
	onClose: () => void;
	setContent: (data: string) => void;
	termsAndCondition: string;
}

const useStyles = createUseStyles((theme: any) => ({
	mainHeading: {
		color: theme.palette.text.grey,
	},
}));

const TermsModal: React.FC<TermsModalProps> = ({ open, onClose, mode, setContent, termsAndCondition }) => {
	const classes = useStyles();
	const [contentData, setContentData] = useState<string>("");
	const handleCancel = () => {
		onClose();
	};
	const handleUpdateContent = (data: string) => {
		setContentData(data);
		setContent(data);
	};
	const descriptionEditor = useRef(null);
	const config: ConfigProps = useMemo(() => {
		return {
			placeholder: "Start typing...",
		};
	}, []);

	useEffect(() => {
		setContentData(termsAndCondition);
	}, [termsAndCondition]);

	return (
		<Modal open={ open } onClose={ onClose } closeAfterTransition>
			<div className="w-2/3 m-auto mt-16 bg-white grid gap-y-6 p-6 rounded ">
				<div className="pb-3 flex justify-between border-b-2 border-solid ">
					<p className={` ${ classes.mainHeading } text-2xl font-semibold` }>Terms and Conditions</p>
					<CloseIcon className={` ${ classes.mainHeading } text-2xl font-semibold cursor-pointer` } onClick={ onClose } />
				</div>
				<FormControl>
					<JoditEditor
						ref={ descriptionEditor }
						value={ contentData }
						config={ config }
						onBlur={ (newContent) => handleUpdateContent(newContent) }
					/>
				</FormControl>
				<div className="flex justify-end gap-x-3">
					<Button onClick={ handleCancel } variant="outlined" label="Cancel" />
					{mode !== "VIEW" && (
						<>
							<Button onClick={ onClose } variant="contained" label={ mode === "EDIT" ? "Update" : "Create" } />
						</>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default TermsModal;
