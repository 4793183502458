import { useEffect, useState } from "react";
import { useBulkUploadService } from "../../../services/useBulkUploadService";
import { RESOURCES, initialPaginationValues } from "../../../utils/constant";
import { IPagination } from "../../../utils/types";
import TitleHeading from "../../atoms/CustomHeading/TitleHeading";
import BulkUploadListTemplate from "./BulkUploadList.template";

export interface IBulkUpload {
    id: number;
    fileName: string;
    section: string;
    report: string;
    status: string;
    createdAt: Date;
}

const BulkUploadListPage: React.FC = () => {

    const bulkUploadService = useBulkUploadService();
    const [bulkUploads, setBulkUploadsTo] = useState<IBulkUpload[]>([]);
    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);

    const refreshBulkUploads = async (page: number, size: number) => {
        try {
            const params = {
                page: page,
                size: size,
                sort: 'updatedAt,desc',
            };
            const bulkUploadResponse = await bulkUploadService.getAllBulkUpload(params);
            if (
                bulkUploadResponse?.status === 200 &&
                bulkUploadResponse?.data?.data?.content?.length
            ) {
                const bulkUploadPage = bulkUploadResponse.data.data;
                const formattedBulkUploadData = bulkUploadPage.content.map((bulkUpload: IBulkUpload) => ({
                    ...bulkUpload,
                    noOfItem: bulkUpload,
                }));
                setBulkUploadsTo(formattedBulkUploadData);
                setPaginationTo((prevPagination: IPagination) => ({
                    ...prevPagination,
                    totalRecords: bulkUploadPage.totalElements,
                    totalPages: bulkUploadPage.totalPages,
                }));
            } else {
                throw new Error("Bulk Uploads fetch failed");
            }
        } catch (error) {
            setBulkUploadsTo([]);
        }
    };

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage
        }));
    };

    useEffect(() => {
        refreshBulkUploads(pagination.currentPage, pagination.pageSize);
    }, [pagination.currentPage, pagination.pageSize]);

    return (
        <div className="grid gap-y-3">
            <div className="flex justify-between">
                <TitleHeading text={RESOURCES.BULK_UPLOAD_TRACKING} />
            </div>
            <div className="grid gap-y-4">
                <div className="grid gap-y-3">
                    <BulkUploadListTemplate
                        bulkUploads={bulkUploads}
                        setBulkUploadsTo={setBulkUploadsTo}
                        pagination={pagination}
                        handlePaginationChange={handlePaginationChange}
                        handleRowsPerPageChange={handleRowsPerPageChange} />
                </div>
            </div>
        </div>
    );

}

export default BulkUploadListPage;