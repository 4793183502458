import React, { useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { FormControl } from '@mui/material';
import * as Yup from "yup";
import { HTTP_STATUS, PRODUCT_SERVICE_FORM_STATE, VAS_MASTER_CATEGORY_TYPE } from '../../../../utils/types';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { MODE } from '../../../../utils/constant';
import { titleModification } from '../../../../utils/helper';
import ButtonV2 from '../../../atoms/ButtonV2';
import TextFieldV2 from '../../../atoms/TextFieldV2';
import { Service, useVASMasterCategoryService } from '../../../../services/useVASMasterCategoryService';
import AutoCompleteInputV2, { AutoCompleteOption } from '../../../molecules/AutoCompleteInput/AutoCompleteInputV2';

export interface ServiceCategoryDetailsFormTemplateProps {
    mode: string;
    onClose: () => void;
    formik: any;
    setFormStateTo: (state: PRODUCT_SERVICE_FORM_STATE) => () => void;
}

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
    },
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
    activeProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary400
    },
    inActiveProgressBar: {
        backgroundColor: theme.palette.backgroundV2.tertiary.tertiary100
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
}));

const ServiceCategoryDetailsFormTemplate: React.FC<ServiceCategoryDetailsFormTemplateProps> = ({ mode, onClose, formik, setFormStateTo }) => {
    const classes = useStyles();
    const vasMasterCategoryService = useVASMasterCategoryService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [mainServiceData, setMainServiceData] = useState<Service[] | null>(null);
    const [superServiceData, setSuperServiceData] = useState<Service[] | null>(null);

    const fetchMainServices = (inputValue?: string) => {
        if (formik.values?.superService?.id) {
            vasMasterCategoryService.getAllService({ level: VAS_MASTER_CATEGORY_TYPE.MAIN_SERVICE, page: 0, size: 10, sort: 'createdAt,desc', parentId: formik.values?.superService?.id ?? '', name: inputValue ?? '', status: 'ACTIVE' },{})
                .then(res => {
                    if (res.status === HTTP_STATUS.OK)
                        setMainServiceData(res?.data?.data?.content)
                }).catch((error) => {
                    console.error("Error Fetching Service: ", error);
                    showSnackbar('error', "Error while fetching Super Service data");
                })
        }
    }

    const fetchSuperServices = (inputValue?: string) => {
        vasMasterCategoryService.getAllService({ level: VAS_MASTER_CATEGORY_TYPE.SUPER_SERVICE, page: 0, size: 10, sort: 'createdAt,desc', name: inputValue ?? '', status: 'ACTIVE' },{})
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setSuperServiceData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Service: ", error);
                showSnackbar('error', "Error while fetching Super Service data");
            })
    }

    useEffect(() => {
        if (!superServiceData) {
            fetchSuperServices();
        }
    }, [superServiceData]);

    useEffect(() => {
        if (formik?.values?.superService?.id) {
            fetchMainServices();
        }
    }, [formik?.values?.superService?.id]);

    const superServiceOptions = useMemo(() => {
        return superServiceData?.map((superService: Service) => ({ label: superService.name, id: superService.id })) ?? []
    }, [superServiceData])

    const mainServiceOptions = useMemo(() => {
        return mainServiceData?.map((mainService: Service) => ({ label: mainService.name, id: mainService.id })) ?? []
    }, [mainServiceData])

    const updateSelectedService = (key: string) => (option: AutoCompleteOption | null) => {
        formik.setFieldValue(key, option);
        if (key === "superService") {
            formik.setFieldValue("mainService", { label: "", id: null });
            formik.setFieldValue("parentId", null);
        }
    };

    useEffect(()=>{
        formik.setFieldValue('parentId', formik?.values?.mainService?.id);
    },[formik?.values?.mainService?.id])

    const handleNext = () => {
        formik.setTouched({ superService: true, mainService: true, parentId: true, name: true, description: true }, true)
        const errors = Object.keys(formik.errors).find(key => ['superService', 'mainService', 'parentId', 'name', 'description'].includes(key))
        if (errors) return;
        setFormStateTo(PRODUCT_SERVICE_FORM_STATE.ATTRIBUTE_MANAGEMENT_FORM_1)();
    }

    return (
        <div className="grid gap-y-8" >
            {SnackBarComponent}
            <div className={`w-full m-auto flex justify-between gap-x-2 text-center text-sm `}>
                <div className="grid gap-y-3 w-1/3">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto text-sm font-semibold`}>Service Category Details</div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/3">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.inActiveProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} text-sm my-auto`}>Attribute Management</div>
                    </div>
                    <div className={`${classes.inActiveProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/3">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.inActiveProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} text-sm my-auto`}>Review</div>
                    </div>
                    <div className={`${classes.inActiveProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>
            <div className='grid gap-y-4'>

                <div className='grid gap-y-0.5'>
                    <div className={`${classes.mainHeading} text-base font-medium`}>Service Category Details</div>
                    <div className={`${classes.barHeading} text-xs`}>Please enter the details of the product service you want</div>
                </div>

                <div className={`${classes.border} grid gap-y-4 p-6 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Select Super Service to be Linked with the Main Service</div>
                    <div className='grid grid-cols-2 gap-x-4'>
                        <div className="grid gap-y-1">
                            {((superServiceData && mode === MODE.ADD) || (superServiceData)) && <FormControl fullWidth>
                                <AutoCompleteInputV2
                                    placeHolder='Select Super Service'
                                    options={superServiceOptions}
                                    label="Select Super Service"
                                    onChange={updateSelectedService('superService')}
                                    onSearch={fetchSuperServices}
                                    isDisabled={mode === MODE.VIEW}
                                    value={formik.values?.superService}
                                    error={!!formik.errors.superService && formik.touched.superService}
                                />
                                {formik.errors?.superService?.label && formik.touched.superService && (
                                    <div className={`${classes.errorMessage} text-xs`}>
                                        {formik.errors?.superService?.label}
                                    </div>
                                )}
                            </FormControl>}
                        </div>

                        <div className="grid gap-y-1">
                            {((mode === MODE.ADD) || (mainServiceData)) && <FormControl fullWidth>
                                <AutoCompleteInputV2
                                    placeHolder='Select Main Service'
                                    options={mainServiceOptions}
                                    label="Select Main Service"
                                    onChange={updateSelectedService('mainService')}
                                    onSearch={fetchMainServices}
                                    isDisabled={mode === MODE.VIEW}
                                    value={formik.values?.mainService}
                                    error={!!formik.errors.mainService && formik.touched.mainService}
                                />
                                {formik.errors?.mainService?.label && formik.touched.mainService && (
                                    <div className={`${classes.errorMessage} text-xs`}>
                                        {formik.errors?.mainService?.label}
                                    </div>
                                )}
                            </FormControl>}
                        </div>
                    </div>

                </div>
                <div className={`${classes.border} grid gap-y-4 p-6 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Service Category Details</div>
                    <div className='grid grid-cols-2 gap-x-4'>
                        <div className="grid gap-y-1">
                            <TextFieldV2
                                label="Service Category Name"
                                placeholder='Enter Service Category Name'
                                {...formik.getFieldProps("name")}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                inputProps={{
                                    readOnly: mode === MODE.VIEW,
                                    maxLength: 100,
                                }}
                                onBlur={(event) => {
                                    const newValue = titleModification(event.target.value);
                                    formik.setFieldValue('name', newValue);
                                }}
                            />
                            {formik.errors.name && formik.touched.name && (
                                <div className={`${classes.errorMessage} text-xs`}>
                                    {formik.errors.name}
                                </div>
                            )}
                        </div>
                        {
                            mode === MODE.UPDATE &&
                            <div className="grid gap-y-1">
                                <TextFieldV2
                                    label="Main Service Code"
                                    {...formik.getFieldProps("serviceCode")}
                                    error={formik.touched.name && Boolean(formik.errors.serviceCode)}
                                    inputProps={{ readOnly: mode === MODE.UPDATE }}
                                    onBlur={(event) => {
                                        const newValue = titleModification(event.target.value);
                                        formik.setFieldValue('serviceCode', newValue);
                                    }}
                                    disabled
                                />
                                {formik.errors.serviceCode && formik.touched.serviceCode && (
                                    <div className={`${classes.errorMessage} text-xs`}>
                                        {formik.errors.serviceCode}
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                    <div className="grid gap-y-1">
                        <TextFieldV2
                            variant="outlined"
                            label="Add Description"
                            placeholder='Enter Here'
                            multiline
                            maxRows={4}
                            {...formik.getFieldProps("description")}
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            inputProps={{ readOnly: mode === MODE.VIEW, maxLength: 500 }}
                            onBlur={(event) => {
                                const newValue = titleModification(event.target.value);
                                formik.setFieldValue('description', newValue);
                            }}
                        />
                        {formik.errors.description && formik.touched.description && (
                            <div className={`${classes.errorMessage} text-xs`}>
                                {formik.errors.description}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="flex justify-end gap-4">
                <ButtonV2 variant="tertiaryContained" label='Cancel' onClick={onClose} />
                {mode !== MODE.VIEW && <ButtonV2 variant="primaryContained" onClick={handleNext} label={"Next"} />}
            </div>
        </div>
    )
}

export default ServiceCategoryDetailsFormTemplate