import { Dialog } from '@mui/material'
import React from 'react'
import TextFieldV2 from '../../atoms/TextFieldV2'
import { createUseStyles } from 'react-jss';
import CloseIcon from '@mui/icons-material/Close';
import ButtonV2 from '../../atoms/ButtonV2';
import { IInstantQuotationAddressDetails } from '../../pages/ManageQuotation/QuoteCreate.page';
import { FormikProps } from 'formik';

interface IBillDetailsProps {
    formik: FormikProps<IInstantQuotationAddressDetails>;
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
    type: string;
    setFilledDetails: (details: any) => void;
    handleBack: () => void;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        borderBottom: `1px solid ${theme.palette.border.tritiaryLight}`
    },
    box: {
        "& .MuiDialog-paper": {
            width: "614px !important",
            height: "768px !important",
            borderRadius: "16px",
            padding: '24px'
        },
    },
    dialogBox: {
        border: `1px solid ${theme.palette.border.primarylightAccent}`,
        borderRadius: '8px'
    },
    closeIcon: {
        color: '#696E7C'
    },
    text: {
        color: theme.palette.textV2.primary.primary900
    }
}));

export const BillDetailsModalTemplate: React.FC<IBillDetailsProps> = ({ dialogOpen, setDialogOpen, formik, type, setFilledDetails, handleBack }) => {

    const classes = useStyles();

    const handleDialogClose = () => {
        handleBack();
    };

    const handleAddDetails = () => {
        const details = formik.values;
        setFilledDetails(details);
    };

    return (
        <Dialog fullWidth className={`${classes.box}`}  open={dialogOpen} onClose={handleDialogClose}>
            <div>
            <div className=" flex justify-between" onClick={handleDialogClose}>
                <span className={`text-2xl font-semibold	${classes.text}`}>
                    {type === 'billing' ? 'Bill to Details' : 'Ship to Details'}
                </span>
                <CloseIcon className={classes.closeIcon} />
            </div>
            <div className={`grid gap-5 py-8`}>
                <div >
                    <TextFieldV2
                        {...formik.getFieldProps(`companyName`)}
                        label='Enter Company Name'
                        variant="outlined"
                        placeholder="Enter Company"
                        error={
                            !!(formik.touched?.companyName) &&
                            Boolean(formik.errors?.companyName)
                        }
                        helperText={formik.errors?.companyName}
                    />
                </div>
                <div >
                    <TextFieldV2
                        {...formik.getFieldProps(`addressLine1`)}
                        label='Address Line 1'
                        variant="outlined"
                        placeholder="Enter Address"
                        error={!!(formik.touched?.addressLine1) &&
                            Boolean(formik.errors?.addressLine1)
                        }
                        helperText={formik.errors?.addressLine1}
                    />
                </div>
                <div >
                    <TextFieldV2
                        {...formik.getFieldProps(`addressLine2`)}
                        label='Address Line 2'
                        variant="outlined"
                        placeholder="Enter Address"
                        error={!!(formik.touched?.addressLine2) && Boolean(formik.errors?.addressLine2)}

                        helperText={formik.errors?.addressLine2}
                    />
                </div>
                <div className='flex w-full gap-4'>
                    <div className='w-1/3'>
                        <TextFieldV2
                            {...formik.getFieldProps(`pincode`)}
                            label='Pincode'
                            variant="outlined"
                            placeholder="Enter Pincode"
                            error={!!(formik.touched?.pincode) && Boolean(formik.errors?.pincode)}
                            helperText={formik.errors?.pincode}
                        />
                    </div> <div className='w-1/3'>
                        <TextFieldV2
                            {...formik.getFieldProps(`city`)}

                            label='City'
                            variant="outlined"
                            placeholder="Enter City"
                            error={!!(formik.touched?.city) && Boolean(formik.errors?.city)}
                            helperText={formik.errors?.city}
                        />
                    </div> <div className='w-1/3'>
                        <TextFieldV2
                            {...formik.getFieldProps(`state`)}
                            label='State'
                            variant="outlined"
                            placeholder="Enter State"
                            error={!!(formik.touched?.state) && Boolean(formik.errors?.state)}
                            helperText={formik.errors?.state}
                        />
                    </div>
                </div>
                <div className='flex w-full gap-4'>
                    <div className='w-1/2' >
                        <TextFieldV2
                            {...formik.getFieldProps(`emailId`)}
                            label='Email ID'
                            variant="outlined"
                            placeholder="Email ID"
                            error={!!(formik.touched?.emailId) && Boolean(formik.errors?.emailId)}
                            helperText={formik.errors?.emailId}

                        />
                    </div>
                    <div className='w-1/2'>
                        <TextFieldV2
                            {...formik.getFieldProps(`phoneNumber`)}
                            label='Phone Number'
                            variant="outlined"
                            placeholder="Phone Number"
                            error={!!(formik.touched?.phoneNumber) && Boolean(formik.errors?.phoneNumber)}
                            helperText={formik.errors?.phoneNumber}
                        />
                    </div>
                </div>
                <div >
                    <TextFieldV2
                        {...formik.getFieldProps(`gstIn`)}
                        label='GSTIN'
                        variant="outlined"
                        placeholder="Enter GST"
                        error={!!(formik.touched?.gstIn) &&
                            Boolean(formik.errors?.gstIn)
                        }
                        helperText={formik.errors?.gstIn}
                    />
                </div>
            </div>
            <div className="flex gap-2 justify-end">
                    <ButtonV2
                        variant={'tertiaryContained'}
                        label={'Back'}
                        onClick={handleDialogClose}
                    />
                    <ButtonV2
                        variant={'primaryContained'}
                        label={'Add'}
                        onClick={handleAddDetails}
                        />
                </div>
            </div>
        </Dialog>
    )
}
