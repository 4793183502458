import React from 'react'
import { createUseStyles } from 'react-jss';
import { FormikProps } from 'formik';
import { MODE, SERVICE_STATUS_OPTIONS } from '../../../../utils/constant';
import { capitalizeFirstLetter, convertToCamelCase, titleModification } from '../../../../utils/helper';
import ButtonV2 from '../../../atoms/ButtonV2';
import TextFieldV2 from '../../../atoms/TextFieldV2';
import StatusV2 from '../../../atoms/Status/StatusV2';
import { ServicePayload } from '../../../../services/useVASMasterCategoryService';

interface SuperServiceFormTemplateProps {
    mode: string,
    formik: FormikProps<ServicePayload>,
    onBack: () => void
}

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
    },
    textColor: {
        color: theme.palette.text.primaryDarkLight
    },
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.action.danger,
    }
}));

const SuperServiceFormTemplate: React.FC<SuperServiceFormTemplateProps> = ({ mode, onBack, formik }) => {
    const classes = useStyles();

    const handleSubmit = () => formik.handleSubmit();

    return (
        <form className="grid gap-y-8" >
            <div className={`${classes.mainHeading} text-lg font-semibold`}>{mode === MODE.UPDATE ? `Edit Super Service - ${formik?.values?.serviceCode}` : mode === MODE.VIEW ? "View Super Service" : "Add New Super Service"}</div>
            <div className='grid gap-y-4'>
                <div className={`${classes.border} grid gap-y-4 p-6 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Super Service Details</div>
                    <div className='grid grid-cols-2 gap-x-4'>
                        <div className="grid gap-y-1">
                            <TextFieldV2
                                label="Super Service Name"
                                placeholder='Enter Super Service Name'
                                {...formik.getFieldProps("name")}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                inputProps={{ readOnly: mode === MODE.VIEW }}
                                onBlur={(event) => {
                                    const newValue = titleModification(event.target.value);
                                    formik.setFieldValue('name', convertToCamelCase(newValue));
                                }}
                            />
                            {formik.errors.name && formik.touched.name && (
                                <div className={`${classes.errorMessage} text-xs`}>{formik.errors.name}</div>
                            )}
                        </div>
                        {
                            mode === MODE.UPDATE &&
                            <div className="grid gap-y-1">
                                <TextFieldV2
                                    label="Super Service Code"
                                    {...formik.getFieldProps("serviceCode")}
                                    error={formik.touched.name && Boolean(formik.errors.serviceCode)}
                                    inputProps={{ readOnly: mode === MODE.UPDATE }}
                                    onBlur={(event) => {
                                        const newValue = titleModification(event.target.value);
                                        formik.setFieldValue('serviceCode', newValue);
                                    }}
                                    disabled
                                />
                                {formik.errors.serviceCode && formik.touched.serviceCode && (
                                    <div className={`${classes.errorMessage} text-xs`}>{formik.errors.serviceCode}</div>
                                )}
                            </div>
                        }
                    </div>
                    <div className="grid gap-y-1">
                        <TextFieldV2
                            variant="outlined"
                            label="Add Description"
                            placeholder='Enter Here'
                            multiline
                            maxRows={4}
                            {...formik.getFieldProps("description")}
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            inputProps={{ readOnly: mode === MODE.VIEW, maxLength: 500, }}
                            onBlur={(event) => {
                                const newValue = titleModification(event.target.value);
                                formik.setFieldValue('description', newValue);
                            }}
                        />
                        {formik.errors.description && formik.touched.description && (
                            <div className={`${classes.errorMessage} text-xs`}>{formik.errors.description}</div>
                        )}
                    </div>
                </div>
                <StatusV2 heading="Service Status" options={SERVICE_STATUS_OPTIONS} formik={formik} name="status" />
            </div>

            <div className="flex justify-end gap-4">
                <ButtonV2 variant="tertiaryContained" label='Cancel' onClick={onBack} />
                {mode !== MODE.VIEW && <ButtonV2 variant="primaryContained" onClick={handleSubmit} label={`+${capitalizeFirstLetter(mode)}`} />}
            </div>
        </form>
    )
}

export default SuperServiceFormTemplate