import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";
import { ICatalogueAttributes } from "./useCustomerGroupMasterService";

export const PRODUCT_URLS = {
  SEARCH_PRODUCT: "/search/products",
};
export interface IProductSearchParams {
    page: number;
    size: number;
    sort?: string;
    sectionType?: string;
  }
  
export interface IProductFilterRequest{
  productCode?: string[] | null;
  superCategory?: number[] | null,
  mainCategory?: number[] | null,
  productCategory?: number[] | null,
  manufacturer?: number[] | null,
  brand?: number[] | null,
  standard?: number[] | null,
  grade?: number[] | null,
  shape?: number[] | null,
  classType?: string[] | null,
  customer?: number[] | null,
  status?: string | null,
  attributes?: { [key: string]: ICatalogueAttributes; } | null,
  productIds?: number[] | null,
}

export const useGenericSearchService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const searchProduct = (params: IProductSearchParams, data: IProductFilterRequest) => {
    return request(API_METHOD.POST, PRODUCT_URLS.SEARCH_PRODUCT, authenticatedUser, data, { params }, null);
  };

  return {
    searchProduct,
  };
};
