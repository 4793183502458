import { useMemo } from 'react'
import ClearIcon from '@mui/icons-material/Clear';
import bulkUploadPopupSuccess from '../../../../src/assets/images/bulkUploadPopupSuccess.png';
import bulkUploadPopupFailed from '../../../../src/assets/images/bulkUploadPopupFailed.png';
import Button from '../../atoms/Button';
import { createUseStyles } from 'react-jss';
import { BULK_UPLOAD_TEMPLATE_POPUP } from '../../../utils/constant';

interface UploadActionFeedbackTemplateProps {
    status: boolean;
    setShowPopup: (value: boolean) => void;
}

const useStyles = createUseStyles((theme: any) => ({
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: `${theme.palette.background.primaryLightAccent}80`,
        zIndex: 999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    primaryContainer: {
        width: "458px",
        background: theme.palette.background.primaryDark,
        zIndex: "1000",
    },
    btn: {
        width: "253px",
        border: `1px solid ${theme.palette.border.primaryLightAccent}`,
        color: theme.palette.text.primaryLightAccent,
    },
}));

const UploadActionFeedbackTemplate: React.FC<UploadActionFeedbackTemplateProps> = ({status, setShowPopup}) => {

    const classes = useStyles();

    const content = useMemo(() => {
        const imgSrc = status ? bulkUploadPopupSuccess : bulkUploadPopupFailed;
        const title = status ? BULK_UPLOAD_TEMPLATE_POPUP.SUCCESS : BULK_UPLOAD_TEMPLATE_POPUP.UPLOAD_FAILURE;
        const message = status ? BULK_UPLOAD_TEMPLATE_POPUP.UPLOAD_PROCESS_SUCCESS : BULK_UPLOAD_TEMPLATE_POPUP.FAILURE_ERROR;
        const additionalMessage = status ? BULK_UPLOAD_TEMPLATE_POPUP.TRACK_PROGRESS_SUCCESS : null;

        return (
            <>
                <img src={imgSrc} alt="img" className='mb-7' />
                <span className={status ? "!text-emerald-600 text-4xl font-semibold text-slate-50" : "!text-red-600 text-4xl font-semibold text-slate-50"}>{title}</span>
                <p className="text-sm text-gray-400">{message}</p>
                {additionalMessage && <p className="text-center text-sm font-semibold text-sky-800">{additionalMessage}</p>}
            </>
        );
    }, [status]);

    const handleClose = () => {
        setShowPopup(false);
    }

    return (
        <>
            {
                <div className={classes.overlay}>
                    <div className={`${classes.primaryContainer} rounded-3xl relative overflow-hidden`}>
                        <ClearIcon className='absolute right-3 top-3 cursor-pointer' onClick={handleClose} />
                        <div className='flex flex-col items-center gap-y-3'>
                            {content}
                            <div className='flex flex-col items-center p-6 pt-0 gap-y-3'>
                                <Button
                                    variant={'text'}
                                    label={BULK_UPLOAD_TEMPLATE_POPUP.DONE}
                                    className={`${classes.btn} !text-lg font-medium !mt-5 !px-6 !py-3 !capitalize !rounded-lg`}
                                    onClick={handleClose}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default UploadActionFeedbackTemplate