import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useWhatsappTemplateService } from '../../../services/useWhatsappTemplateService';
import { IPagination } from '../../../utils/types';
import { ADMIN_ROUTES, LANGUAGE_CODE, initialPaginationValues } from '../../../utils/constant';
import TitleHeading from '../../atoms/CustomHeading/TitleHeading';
import Button from '../../atoms/Button';
import WhatsappTemplateListTemplate from '../../templates/WhatsappTemplate/WhatsappTemplateList.template';

export interface IWhatsappTemplateFilter {
    status: string;
    search: string;
    sort: string;
}

export interface IWhatsappTemplate {
    id: number;
    templateName: string;
    language: string;
    createdBy: string;
    updatedBy: string;
    status: string;
    attachmentType: string;
    parameterValues: any;
    createdAt: string;
    updatedAt: string;
}

const initialTemplateFilterValues: IWhatsappTemplateFilter = {
    search: "",
    status: "ALL",
    sort: "createdAt,desc"
}

interface LanguageMap {
    [key: string]: string;
}

const WhatsappTemplateListPage: React.FC = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const whatsappTemplateService = useWhatsappTemplateService();

    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);
    const [templates, setTemplatesTo] = useState<IWhatsappTemplate[]>([]);
    const [templateFilters, setTemplateFiltersTo] = useState<IWhatsappTemplateFilter>(initialTemplateFilterValues);

    const refreshTemplates = () => {
        const params = {
            search: templateFilters?.search.trim(),
            status: templateFilters?.status === "ALL" ? "" : templateFilters?.status,
            page: pagination.currentPage,
            size: pagination.pageSize,
            sort: templateFilters?.sort
        }
        whatsappTemplateService.getTemplates(params)
            .then((templateResponse) => {
                if (templateResponse?.data?.data) {
                    const { totalElements, totalPages, content } = templateResponse?.data?.data;
                    setPaginationTo({
                        ...pagination,
                        totalPages: totalPages,
                        totalRecords: totalElements
                    });
                    const languageMap: LanguageMap = {};
                    LANGUAGE_CODE.forEach((lan) => {
                        languageMap[lan.value] = lan.label;
                    });
                    const updatedContent = content.map((template: any) => ({
                        ...template,
                        language: languageMap[template.language] || ""
                    }));
                    setTemplatesTo(updatedContent)
                }
                else {
                    setTemplatesTo([]);
                }
            })
            .catch((error) => {
                setTemplatesTo([]);
                showSnackbar('error', 'Templates fetch failed')
            });
    }

    useEffect(() => {
        refreshTemplates()
    }, [pagination.currentPage, pagination.pageSize, templateFilters.search, templateFilters.status, templateFilters.sort, location])

    const deleteTemplate = async (templatedId: number) => {
        try {
            await whatsappTemplateService.deleteTemplate(templatedId)
            refreshTemplates();
        }
        catch (error) {
            showSnackbar('error', 'Failed to delete template. Please try again later.!')
        }
    }

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };


    const handleCreateTemplate = () => {
        navigate(ADMIN_ROUTES.WHATSAPP_TEMPLATE_CREATE)
    }

    const handleFiltersChange = (event: any) => {
        const { name, value } = event.target;
        setPaginationTo({ ...pagination, currentPage: 0 })
        setTemplateFiltersTo({ ...templateFilters, [name]: value ?? "" });
    }

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage
        }));
    };

    return (
        <>
            {SnackBarComponent}
            <div className='grid gap-y-3'>
                <div className='flex justify-between '>
                    <TitleHeading text="Template" />
                    <Button variant="contained" label="CREATE TEMPLATE" onClick={handleCreateTemplate} />
                </div>
                <WhatsappTemplateListTemplate whatsappTemplates={templates} pagination={pagination} handleFiltersChange={handleFiltersChange} handlePaginationChange={handlePaginationChange} whatsappTemplateFilter={templateFilters} handleRowsPerPageChange={handleRowsPerPageChange} deleteTemplate={deleteTemplate} />
            </div>
        </>
    )
}

export default WhatsappTemplateListPage