import React from 'react'
import { FormikProps } from 'formik';
import { createUseStyles } from 'react-jss';
import plusIcon from "../../../../assets/icons/plus.svg"
import { useNavigate } from 'react-router-dom';
import TextFieldV2 from '../../../atoms/TextFieldV2';
import ButtonV2 from '../../../atoms/ButtonV2';
import { IMaterialRequirementFormik } from '../../../pages/CustomerManagement/MyMaterialProduct.page';
import { IMY_MATERIAL_STATE } from '../../../pages/CustomerManagement/MyMaterial.page';
import { ADMIN_ROUTES } from '../../../../utils/constant';
import ErrorMessage from '../../../atoms/ErrorMessage/ErrorMessage';

interface ProductRequirementTemplateProps {
  handleOnBack: () => void;
  handleOnNext: () => void;
  formik: FormikProps<IMaterialRequirementFormik>
  myMaterialState: IMY_MATERIAL_STATE
}

const useStyles = createUseStyles((theme: any) => ({

  container: {
    borderRadius: "8px",
    border: `1px solid ${theme.palette.textV2.secondary.secondary50}`,
  },
  heading: {
    color: theme.palette.textV2.primary.primary900
  },
  label: {
    color: theme.palette.buttonV2.secondaryText.text.secondaryDark
  },
  helperText: {
    position: 'absolute',
    bottom: '-20px',
    color: theme.palette.action.danger,
  },

  highlightedText: {
    color: theme.palette.textV2.primary.primary500
  }
}));

const ProductRequirementTemplate: React.FC<ProductRequirementTemplateProps> = ({ formik, handleOnBack, handleOnNext, myMaterialState }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleNext = (event: React.FormEvent) => {
    event.preventDefault();
    if (formik.touched.noOfRows && !Boolean(formik.errors.noOfRows))
      handleOnNext();
  }

  const handleBackToDashboard = () => {
    navigate(ADMIN_ROUTES.CUSTOMER_MANAGEMENT_LIST);
  };

  return (
    <div>
      <div className={`${classes.container} rounded-2xl p-6 gap-4 mb-4`}>
        <div className={`text-lg font-medium mb-6 ${classes.heading}`}>Product Category selected: <span className={classes.highlightedText}>{formik.values.categoryName ?? myMaterialState.other?.name}</span></div>
        <div className='relative'>
          <div className='flex w-1/2 gap-x-3 items-end'>
            <TextFieldV2
              {...formik.getFieldProps("noOfRows")}
              id="outlined-basic"
              label="Enter No of Products you want (Max. 10 nos.)"
              variant="outlined"
              placeholder='Enter Quantity'
              type="number"
              error={formik.touched.noOfRows && Boolean(formik.errors.noOfRows)}
              fullWidth
              helperText=""

            />
            <ButtonV2
              variant="contained"
              iconButton={<img src={plusIcon} />}
              size='small'
              onClick={handleNext}

            />
          </div>
          <div>
            {formik.touched.noOfRows && Boolean(formik.errors.noOfRows) && <ErrorMessage message={formik.errors.noOfRows as string} />}
          </div>
        </div>
      </div>
      <div className='flex justify-end gap-x-3'>
        <ButtonV2
          className={` justify-self-end`}
          variant="secondaryContained"
          label="Back to Dashboard"
          onClick={handleBackToDashboard}
        />
        <ButtonV2 label='Back' variant='tertiaryContained' onClick={handleOnBack} />
      </div>
    </div>
  )
}

export default ProductRequirementTemplate