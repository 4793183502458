import React, { useEffect, useState } from 'react'
import Tabs, { ITabsSchema } from '../../molecules/Tabs/Tabs'
import CategoriesListTableTemplate from '../../templates/Category/CategoriesListTable.template'
import { useSearchParams } from 'react-router-dom';
import { CATEGORY_TYPE } from '../../../utils/types';

const CategoryListPage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<CATEGORY_TYPE>( CATEGORY_TYPE[searchParams.get('type') as keyof typeof CATEGORY_TYPE] ?? CATEGORY_TYPE.SUPER_CATEGORY);

  useEffect(()=>{
    if(isNaN(activeTab)) return;
    const level = Object.keys(CATEGORY_TYPE).filter(key => isNaN(parseInt(key)))[activeTab];
    setSearchParams((params: URLSearchParams) => {
      params.set('type', level)
      return params;
    })
  },[activeTab])

  useEffect(()=>{
    setActiveTab(CATEGORY_TYPE[searchParams.get('type') as keyof typeof CATEGORY_TYPE] ?? CATEGORY_TYPE.SUPER_CATEGORY)
  },[searchParams.get('type')])

  const schema: ITabsSchema[] = [
    { label: 'Super Category', component: <CategoriesListTableTemplate level={CATEGORY_TYPE.SUPER_CATEGORY} /> },
    { label: 'Main Category', component: <CategoriesListTableTemplate level={CATEGORY_TYPE.MAIN_CATEGORY} /> },
    { label: 'Product Category', component: <CategoriesListTableTemplate level={CATEGORY_TYPE.PRODUCT_CATEGORY} /> }
  ]

  return (
  <Tabs schema={schema} value={(activeTab+1).toString()} setValue={setActiveTab} />
  )
}

export default CategoryListPage