import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import MechanicalPropertiesDetails from "./MechanicalPropertiesDetailsModal";
import PropertiesDialogPopUp from "./PropertiesDialogPopUp";
import viewEyeIcon from "./../../../../Assets/Icons/viewEyeIcon.svg";
import editPenIcon from "./../../../../Assets/Icons/editPenIcon.svg";
import deleteBinIcon from "./../../../../Assets/Icons/deleteBinIcon.svg";
import axios from "axios";
import { useAuthenticatedUser } from "../../../../../hooks/useAuthenticatedUser";
import { joinNameWithoutSpaces } from "../../../../../utils/helper";

const BaseUrl = process.env.REACT_APP_API_V1_URL;

const MechanicalPropertiesTableHeadings = () => {
  return (
    <div className="relative bg-blue w-full flex flex-row p-[0.75rem] box-border items-end justify-start gap-[0.75rem] text-left text-[0.88rem] text-white font-roboto">
      <div className="flex-1 flex flex-col items-start justify-start">
        <div className="relative font-semibold text-left w-full sm:w-[calc(11rem / 1.6)]">
          Sr No.
        </div>
      </div>
      <div className="flex-1 flex flex-col items-center justify-start">
        <div className="relative font-semibold text-left w-full sm:w-[calc(23rem / 1.6)]">
          Property Name
        </div>
      </div>
      <div className="flex-1 flex flex-col items-center justify-start">
        <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">
          RT - Value
        </div>
      </div>
      <div className="flex-1 flex flex-col items-center justify-start">
        <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">
          OT - Value
        </div>
      </div>
      {/* <div className="flex-1 flex flex-col items-center justify-start">
        <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">
          Associated Grade
        </div>
      </div> */}
      <div className="flex-1 flex flex-col items-center justify-start">
        <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">
          Created Date
        </div>
      </div>
      <div className="flex-1 flex flex-col items-center justify-start">
        <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">
          Last Modified
        </div>
      </div>
      <div className="flex-1 flex flex-col items-center justify-start">
        <div className="relative font-semibold text-center w-full sm:w-[calc(11rem / 1.6)]">
          Action
        </div>
      </div>
    </div>
  );
};

const MechanicalPropertiesTable = ({
  records = [],
  associatedGrade,
  updateGradeProperties,
}) => {
  const { user } = useAuthenticatedUser();
  const [open, setOpen] = useState(false);
  const [dialogType, setDialogType] = useState(0);
  const [mechanicalPropertiesData, setMechanicalPropertiesData] =
    useState(records);

  const [selectedMP, setSelectedMP] = useState({});
  const [recordIndex, setRecordIndex] = useState(null);

  // Filter and Paginatio
  const [sorting, setSorting] = useState("name,desc");
  const [searchQuery, setSearchQuery] = useState("");

  const handleOpenDialog = (type) => {
    setDialogType(type);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  useEffect(() => {
    const newData = customFilterFunction(records, sorting, searchQuery); 
    setMechanicalPropertiesData(newData);
  }, [sorting, searchQuery, associatedGrade]);

  const addFormDataToMPProperties1 = (
    formData,
    associatedGrade,
    index = null
  ) => {
    const { name } = formData;
    if (associatedGrade.mechanicalProperties === null) {
      associatedGrade.mechanicalProperties = [];
    } else {
      associatedGrade.mechanicalProperties = JSON.parse(
        associatedGrade.mechanicalProperties
      );
    }

    let flag = true;
    if (index === null) {
      const isNameAlreadyExists = associatedGrade.mechanicalProperties.some(
        (property) => property.name === name
      );

      if (!isNameAlreadyExists) {
        associatedGrade.mechanicalProperties.push(formData);
      } else {
        flag = false;
      }
    } else {
      if (index >= 0 && index < associatedGrade.mechanicalProperties.length) {
        associatedGrade.mechanicalProperties[index] = formData;
      }
    }
    associatedGrade.mechanicalProperties = JSON.stringify(
      associatedGrade.mechanicalProperties
    );

    return flag;
  };


  const addFormDataToMPProperties = (
    formData,
    associatedGrade,
    recordId = null
  ) => {
    const { name } = formData;
    const { id } = formData;
    if (associatedGrade.mechanicalProperties === null || associatedGrade.mechanicalProperties.length === 0) {
      associatedGrade.mechanicalProperties = [];
    } else {
      if (typeof (associatedGrade.mechanicalProperties) === "string") {

      associatedGrade.mechanicalProperties = JSON.parse(
        associatedGrade.mechanicalProperties
      );
    }}
    let flag = true;
/** TODO refactor the condition 
**/
    if (associatedGrade.mechanicalProperties.length === 0) {
      associatedGrade.mechanicalProperties.push(formData);
    } else {
      const isAlreadyExists = associatedGrade.mechanicalProperties.some(
        (property) => property.name === name || id === property.id
      );
      
      if (isAlreadyExists) {
        let index = associatedGrade.mechanicalProperties.findIndex((element) => element.id === id);
        if (index > -1 && dialogType === 2) {
          let indexFromNameAndId = associatedGrade.mechanicalProperties.findIndex((element) => element.name === name && element.id !== id);
          if (indexFromNameAndId > -1) {
            window.alert("Mechanical Properties already exists")
            return;
          } else {
            associatedGrade.mechanicalProperties[index] = formData;
          }
        } else if (dialogType === 0) {
          window.alert("Mechanical Properties already exists")
          return;
        } else {
          const maxId = associatedGrade.mechanicalProperties.reduce((max, obj) => (obj.id > max ? obj.id : max), 0);
          if ((maxId + 1) === recordId) {
            associatedGrade.mechanicalProperties.push(formData);
          }
        }
      } else {
        associatedGrade.mechanicalProperties.push(formData);
      }

    }
    associatedGrade.mechanicalProperties = JSON.stringify(
      associatedGrade.mechanicalProperties
    );

    return flag;
  };

  const handleViewElement = () => {};

  const updateMechanicalProperties = async (gradeData) => {
    const apiUrl = `${BaseUrl}/quality-grade/${gradeData.id}`;
    const httpMethod = "PUT";
    // return
    const response = await axios({
      method: httpMethod,
      url: apiUrl,
      data: gradeData,
      headers: {
        Authorization: `${user.token}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  };

  const handleEditElement = async (formData, index) => {
    try {
      const mechanicalComposition = JSON.parse(associatedGrade.mechanicalProperties);
      mechanicalComposition.forEach((composition) => {
        composition.name = joinNameWithoutSpaces(composition.name);
      });

      associatedGrade.mechanicalProperties = JSON.stringify(mechanicalComposition);
      if (!addFormDataToMPProperties(formData, associatedGrade, index)) {
        return;
      }
      var returnResponse = await updateMechanicalProperties(associatedGrade);

      if (returnResponse.status === 200) {
        updateGradeProperties(returnResponse.data.data);
        handleCloseDialog();
      }
    } catch (error) {
      return false;
    }
  };

  const deleteMechanicalProperty = async (
    mechanicalIndex,
    mechanicalPropertiesRecord
  ) => {
    try {
      const confirmDelete = window.confirm(
        "Are you sure you want to delete this Mechanical Property?"
      );
      if (confirmDelete) {
        const MechanicalPropertyArray = mechanicalPropertiesRecord;
        const updatedMechanicalProperty = MechanicalPropertyArray.filter(
          (record, index) => record.id !== mechanicalIndex
        );
        const updatedMechanicalPropertyString = JSON.stringify(
          updatedMechanicalProperty
        );

        const updatedGrade = {
          ...associatedGrade,
          mechanicalProperties: updatedMechanicalPropertyString,
        };

        var returnResponse = await updateMechanicalProperties(updatedGrade);
        if (returnResponse.status === 200) {
          updateGradeProperties(returnResponse.data.data);
        }
      }
    } catch (error) {
      return false;
    }
  };

  const MechanicalPropertiesTableRecords = ({ records }) => {
    if (typeof records === "string") {
      try {
        records = JSON.parse(records);
      } catch (error) {
        console.error("Error parsing string to array:", error);
        records = [];
      }
    }

    if (!Array.isArray(records) || records.length === 0) {
      return null;
    }
    return (
      <>
        {records.map((record, index) => (
          <div
            key={index}
            className="catalogue-details self-stretch flex flex-row p-[0.75rem] items-center justify-start gap-[0.75rem] border-b border-gray-300"
          >
            <div className="flex-1 flex flex-col items-center justify-start">
              <div className="relative  w-full sm:w-[calc(11rem / 1.6)]   text-left ">
                {index + 1}
              </div>
            </div>

            <div className="flex-1 flex flex-col items-center justify-start">
              <div className="relative  w-full sm:w-[calc(23rem / 1.6)]   text-left ">
                {record.name}{" "}
              </div>
            </div>

            <div className="flex-1 flex flex-col items-center justify-start">
              <div className="relative  w-full sm:w-[calc(11rem / 1.6)]   text-center ">
                {record.roomTemperature} {record.uom}
              </div>
            </div>

            <div className="flex-1 flex flex-col items-center justify-start">
              <div className="relative  w-full sm:w-[calc(11rem / 1.6)]   text-center ">
                {record.otherTemperature} {record.uom}
              </div>
            </div>

            {/* <div className="flex-1 flex flex-col items-center justify-start">
              <div className="relative  w-full sm:w-[calc(11rem / 1.6)]   text-center ">
                {" "}
                {associatedGrade.name}
              </div>
            </div> */}

            <div className="flex-1 flex flex-col items-center justify-start">
              <div className="relative  w-full sm:w-[calc(11rem / 1.6)]   text-center ">
                {record.createdAt}
              </div>
            </div>

            <div className="flex-1 flex flex-col items-center justify-start">
              <div className="relative  w-full sm:w-[calc(11rem / 1.6)]   text-center ">
                {record.updatedAt}
              </div>
            </div>

            <div className="flex-1 flex flex-col items-center justify-start text-center ">
              <div className="relative  w-full sm:w-[calc(11rem / 1.6)]  text-center">
                <button
                  className="relative w-[1.13rem] h-[1.13rem] overflow-hidden shrink-0  mr-2  text-center "
                  onClick={(e) => {
                    handleOpenDialog(1);
                    setSelectedMP(record);
                    setRecordIndex(record?.id);
                  }}
                >
                  <img className="w-full h-full" alt="" src={viewEyeIcon} />
                </button>

                <button
                  className="relative w-[1.13rem] h-[1.13rem] overflow-hidden shrink-0  mr-2 text-center "
                  onClick={(e) => {
                    handleOpenDialog(2);
                    setSelectedMP(record);
                    setRecordIndex(record?.id);
                  }}
                >
                  <img className="w-full h-full" alt="" src={editPenIcon} />
                </button>

                <button
                  className="relative w-[1.13rem] h-[1.13rem] overflow-hidden shrink-0 text-center "
                  onClick={(e) => deleteMechanicalProperty(record?.id, records)}
                >
                  <img className="w-full h-full" alt="" src={deleteBinIcon} />
                </button>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  const customFilterFunction = (data, sorting, searchQuery) => {
    if (data.length === 0) {
      return [];
    }
    const [field, order] = sorting.split(",");

    let filteredData = [...data];

    if (searchQuery) {
      filteredData = filteredData.filter((item) => {
        const nameMatches = item.name
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        return nameMatches;
      });
    }

    const sortedData = [...filteredData].sort((a, b) => {
      if (order === "asc") {
        return a[field].localeCompare(b[field]);
      } else {
        return b[field].localeCompare(a[field]);
      }
    });

    return sortedData;
  };

  const handleCustomSearch = (value) => {
    setSearchQuery(value);
  };
  const handleCustomSort = (value) => {
    setSorting(value);
  };

  return (
    <div className="w-full">
      {" "}
      <div className="self-stretch flex flex-row items-center justify-between gap-[12px] text-xl text-blue mt-5 mb-3">
        <div> Mechanical Properties</div>

        <button
          className="rounded bg-customBlue overflow-hidden flex py-2 px-4 items-center justify-center text-lg text-white font-semibold"
          onClick={(e) => handleOpenDialog(0)}
        >
          Add New Mechanical Properties
        </button>
      </div>
      <div className="flex justify-between mb-3">
        <div className="flex gap-x-5">
          <FormControl sx={{ width: "260px" }}>
            <InputLabel id="sorting-label">Sort</InputLabel>
            <Select
              labelId="sorting-label"
              id="sorting-select"
              label="Sort"
              value={sorting}
              onChange={(e) => handleCustomSort(e.target.value)}
              sx={{ width: "180%", maxWidth: "260px" }}
              // sx={{ width: "250px" }}
            >
              <MenuItem value="name,asc">Property Name(Ascending)</MenuItem>
              <MenuItem value="name,desc">Property Name(Descending)</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div>
          <FormControl
            sx={{ width: "345px" }}
          >
            <TextField
              className={`h-[3rem] block w-full p-1 border border-gray-500 rounded-md focus:outline-none ring-blue-500 focus:border-blue-500`}
              id="outlined-basic"
              label="Search by Name"
              variant="outlined"
              fullWidth
              value={searchQuery}
              onChange={(e) => handleCustomSearch(e.target.value)}
            />
          </FormControl>
        </div>
      </div>
      <MechanicalPropertiesTableHeadings />
      {mechanicalPropertiesData?.length === 0 && <div className="text-center pt-4">No Mechanical Properties Data Available</div>}
      <MechanicalPropertiesTableRecords records={mechanicalPropertiesData} />
      <PropertiesDialogPopUp open={open} onClose={handleCloseDialog}>
        {dialogType === 0 && (
          <MechanicalPropertiesDetails
            type={"ADD"}
            onClose={handleCloseDialog}
            record={null}
            itemindex={null}
            handleAction={handleEditElement}
            associatedGrade={associatedGrade}
            fullData={mechanicalPropertiesData}
          />
        )}

        {dialogType === 1 && (
          <MechanicalPropertiesDetails
            type={"VIEW"}
            onClose={handleCloseDialog}
            record={selectedMP}
            itemindex={recordIndex}
            handleAction={handleViewElement}
            associatedGrade={associatedGrade}
            fullData={mechanicalPropertiesData}
          />
        )}

        {dialogType === 2 && (
          <MechanicalPropertiesDetails
            type={"EDIT"}
            onClose={handleCloseDialog}
            record={selectedMP}
            itemindex={recordIndex}
            handleAction={handleEditElement}
            associatedGrade={associatedGrade}
            fullData={mechanicalPropertiesData}
          />
        )}
      </PropertiesDialogPopUp>
    </div>
  );
};

export default MechanicalPropertiesTable;
