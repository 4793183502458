import React, { useEffect, useState } from "react";
import SpecificationDropdownTemplate from "./SpecificationDropdown.template";
import { useAttributeService } from "../../../../services/useAttributeService";
import { AttributeSpecification, useCategoryService } from "../../../../services/useCategoryService";
import { ATTRIBUTES, MODE } from "../../../../utils/constant";
import { createUseStyles } from "react-jss";
import { AttributeDetails } from "../UpcForm.template";

interface ProductSpecificationSearchTemplateProps {
	updateSearchParams: any;
	attributes: any;
	formik: any;
	mode: string;
}

const useStyles = createUseStyles((theme: any) => ({

	errorMessage: {
		color: theme.palette.action.danger,
	},
}));


const ProductSpecificationSearchTemplate: React.FC<ProductSpecificationSearchTemplateProps> = ({ updateSearchParams, attributes, formik, mode }) => {
	const classes = useStyles();
	const attributeService = useAttributeService();
	const categoryService = useCategoryService();

	const [attributeValues, setAttributeValues] = useState({});
	
	const [productSpecification, setProductSpecificationOptions] = useState({
		'SPECIFICATION': [],
		'CLASSIFICATION': [],
		'GENERALIZATION': []
	});

	const [selectedProductSpecification, setSelectedProductSpecificationOptions] = useState({
		'SPECIFICATION': attributes?.SPECIFICATION || [],
		'CLASSIFICATION': attributes?.CLASSIFICATION || [],
		'GENERALIZATION': attributes?.GENERALIZATION || [],
	});

	const updateProductSpecificationsOptions = (type: any, data: any) => {
		const updatedList = [...selectedProductSpecification[type as keyof AttributeDetails]];
		const existingItemIndex = updatedList.findIndex((item) => item.id === data.id);
	
		if (existingItemIndex !== -1) {
			updatedList[existingItemIndex] = data;
		} else {
			updatedList.push(data);
		}

		setSelectedProductSpecificationOptions({
			...selectedProductSpecification,
			[type]: updatedList,
		});
		updateSearchParams('SPECIFICATION', updatedList);
	};
	
	useEffect(()=>{
		formik.values?.attributes?.SPECIFICATION?.sort((a: { id: number; }, b: { id: number; }) => a.id - b.id);
	},[productSpecification])

	const loadAttributes = async (attributeType: any) => {
		try {
			const params = {
				attributeType: attributeType
			}
			const response = await attributeService.getAllAttributes(params);
			if (response?.data?.data?.content) {
				const attributeValuesArray = response.data.data.content;
				setAttributeValues((prev) => ({
					...prev, [attributeType]: attributeValuesArray
				}))
				return attributeValuesArray;
			} else {
				return [];
			}
		} catch (error) {
			console.error("Error fetching attribute values: ", error);
			return [];
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			if (formik?.values?.categoryId) {
				loadAttributes(ATTRIBUTES.SPECIFICATION)
				try {
					const response = await categoryService.getCategoryById(formik?.values?.categoryId);
					if (response?.data?.data?.attributeDetails?.SPECIFICATION) {
						const specificationArray = response.data.data.attributeDetails.SPECIFICATION;
						specificationArray.sort((a: { id: number; }, b: { id: number; }) => a.id - b.id);
						setProductSpecificationOptions((prev) => ({
							...prev,
							SPECIFICATION: specificationArray
						}));
					}
				} catch (error) {
					console.error("Error fetching category:", error);
				}
			}
		};
		fetchData();
	}, [formik?.values?.categoryId]);

	return (
		<div className="w-full grid">
			<div className='w-full flex gap-x-3'>
				{productSpecification?.SPECIFICATION.map((specification: AttributeSpecification, index) => (
					<SpecificationDropdownTemplate
						type={'SPECIFICATION'}
						key={specification.id}
						specification={specification}
						value={formik.values?.attributes?.SPECIFICATION?.[index]?.attributeOptions ?? ''}
						onChange={updateProductSpecificationsOptions}
						mode= {mode}
					/>
				))}
			</div>
			{formik.touched && formik.touched?.attributes && formik.touched?.attributes.SPECIFICATION && formik.errors?.attributes?.SPECIFICATION && (
				<div className={classes.errorMessage}>
					<small>{formik.errors.attributes.SPECIFICATION}</small>
				</div>
			)}
		</div>
	)
};

export default ProductSpecificationSearchTemplate;
