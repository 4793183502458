import LiveAuctionCustomHeading from './LiveAuctionCustomHeading';
import { AUCTION } from '../../../Constants/Procurement/LiveAuction/text';
import { Button } from '@mui/material';
import VariantButton from '../../../Shared/Button/VariantButton';
import RemainingTime from './RemainingTime';
import moment from 'moment';
import { timeFormatShort } from '../../../Constants/Procurement/constants';
import { ModalType } from '../../../Constants/Procurement/LiveAuction/constants';
import { memo } from 'react';

interface StartEndTimeProps {
  pause: boolean;
  endDateTime: number;
  startDateTime: number;
  isLive: boolean;
  hasEnded: boolean;
  open: ModalType | null;
  handleExtendTime: () => void;
  handlePause: () => void;
}

const StartEndTime = ({
  pause,
  handleExtendTime,
  endDateTime,
  startDateTime,
  handlePause,
  isLive,
  hasEnded,
  open,
}: StartEndTimeProps) => {
  return (
    <div className="grid grid-cols-3 my-4 p-4 bg-cgray-2 rounded-md ">
      <div className="flex gap-6 items-center">
        <LiveAuctionCustomHeading
          label={AUCTION.StartTime}
          value={moment.unix(startDateTime).format(timeFormatShort)}
          variant="small"
        />
        <LiveAuctionCustomHeading
          label={AUCTION.EndTime}
          variant="small"
          value={moment.unix(endDateTime).format(timeFormatShort)}
        />
      </div>
      <div className="flex justify-center">
        {isLive && !hasEnded ? (
          <RemainingTime
            startTime={startDateTime}
            endTime={endDateTime}
            isPause={pause}
            isLive={isLive}
            open={open}
          />
        ) : (
          ''
        )}
      </div>
      {isLive && !hasEnded ? (
        <div className="flex items-center gap-4 justify-end">
          <Button
            onClick={handleExtendTime}
            variant={'outlined'}
            className="h-12 normal-case text-blue rounded border-blue hover:bg-cgray-2 font-semibold"
          >
            Extend Time
          </Button>
          <VariantButton onClick={handlePause} className="h-12 w-20">
            {pause ? AUCTION.Resume : AUCTION.Pause}
          </VariantButton>
        </div>
      ) : null}
    </div>
  );
};

export default memo(StartEndTime);
