import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "../../../../hooks/useSnackBar";
import { IBrandRequest, useBrandService } from "../../../../services/useBrandService";
import { HTTP_STATUS } from "../../../../utils/types";
import { ADMIN_ROUTES, MODE, RESOURCE_TYPE } from "../../../../utils/constant";
import BrandFormTemplate from "../../../templates/AdminMaster/Brand/BrandForm.template";
import { useEffect, useState } from "react";
import { IBrand, ICategory } from "./BrandList.page";
import { ImageData } from "../../../organisms/ImageUploader";
import { useFileService } from "../../../../services/useFileService";

const BrandUpdatePage: React.FC = () => {

    const navigate = useNavigate();

    const params = useParams();
    const brandId = Number(params.id);

    const [brandDetails, setBrandDetailsTo] = useState<IBrand | null>(null);
    const [productCategories, setProductCategroiesTo] = useState<number[]>([]);
    const fileService = useFileService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [image, setImagesTo] = useState<(ImageData | File)[]>([]);

    const brandService = useBrandService();

    const updateBrandById = async (brandRequestBody: IBrandRequest) => {
        if (brandId) {
            try {
                const brandResponse =  await brandService.updateBrand(brandId, brandRequestBody);
                if(brandResponse.status === HTTP_STATUS.OK ){
                    const resourceId: number = brandResponse.data.data.id;
                    await fileService.uploadImage(image,resourceId,RESOURCE_TYPE.BRAND);
                    navigate(ADMIN_ROUTES.BRAND_LIST);
                } else if (brandResponse.data.statusMessage === "DUPLICATE_BRAND") {
                    showSnackbar("error", `Brand already exists. Please try again`);
                }
            } catch (error) {
                showSnackbar("error", `Brand updation failed ${error}`)
            }
        }
    }

    const fetchBrandById = async () =>{
        if(brandId){
            try {
                const brandResponse = await brandService.getBrandById(brandId);
                if(brandResponse.status===HTTP_STATUS.OK){
                    setBrandDetailsTo(brandResponse?.data?.data);
                    const productCategoryIds = brandResponse?.data?.data?.categories?.map((productCategory: ICategory) => productCategory?.id) ?? [];
                    setProductCategroiesTo(productCategoryIds);
                }
                const fileResponse = await fileService.getFilesById(brandId, RESOURCE_TYPE.BRAND);
                (fileResponse.status === HTTP_STATUS.OK) && setImagesTo(fileResponse?.data?.data);
            } catch (error) {
                showSnackbar("error", `Brand Not fetching${error}`) 
            }
        }
    }

    useEffect(() => {
        fetchBrandById()
    }, [brandId]);
    return (
        <div>
            { SnackBarComponent }
            <BrandFormTemplate brand={ brandDetails } categoryIds={ productCategories } onBrandSubmit={ updateBrandById } mode={ MODE.UPDATE } image={image} setImagesTo={setImagesTo} />
        </div>
    )
}

export default BrandUpdatePage;