import React from "react";
import { createUseStyles } from "react-jss";
import { CLASS_TYPE } from "../../../utils/types";
import { showAttributesUom } from "../../../utils/helper";

const useStyles = createUseStyles((theme: any) => ({
	container: {
		background: theme.palette.background.secondaryLight
	},
}));

interface IAttribute {
    name: string;
    maxValue: string;
    minValue: string;
    attributeOptions: string;
    uom: string;
}

interface ProductDetailTemplateProps {
  attributes: any;
  imagePath: string;
  classType: CLASS_TYPE;
}

const ProductDetailTemplate: React.FC<ProductDetailTemplateProps> = ({ attributes, imagePath, classType }) => {
  const classes = useStyles();
  const transform = (data: any) => {
    try {
        if (data) {
            var specificationData = JSON.parse(data);
            specificationData = specificationData?.SPECIFICATION || [];
            return specificationData;
        } else {
            return [];
        }
    } catch (error) {
        console.error("Error parsing JSON:", error);
        return [];
    }
};

  return (
      <div className="grid gap-y-2 w-1/4 mt-0 m-auto">
          <img className="rounded w-full" src={imagePath} alt="productImage" />
          <div className={`${classes.container} grid grid-cols-3 gap-x-3 rounded bg-gray-300 h-fit p-3 w-full mt-0`}>
              {classType === CLASS_TYPE.STANDARD ? (
                  attributes && transform(attributes).map((property: IAttribute, i: any) => (
                      <div className="flex flex-col gap-y-1" key={property.name}>
                          <div className="text-gray-500 font-roboto text-sm font-normal">{property?.name}</div>
                          <div className="text-gray-600 font-roboto text-sm font-medium flex">
                            {showAttributesUom(property.attributeOptions, property.attributeOptions  , property.uom)}
                          </div>
                      </div>
                  ))
              ) : (
                  attributes?.productSpecification?.map((property: IAttribute, i: any) => (
                      <div className="flex flex-col gap-y-1" key={property.name}>
                          <div className="text-gray-500 font-roboto text-sm font-normal">{property?.name}</div>
                            {showAttributesUom(property?.minValue, property?.maxValue  , property.uom)}
                       </div>
                  ))
              )}
          </div>
      </div>
  );
};

export default ProductDetailTemplate;
