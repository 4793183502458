import React from 'react'
import { CategoryPayload } from '../../../services/useCategoryService'
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import { createUseStyles } from 'react-jss';
import { FormControl } from '@mui/material';
import FormGroup from "@mui/material/FormGroup";
import Button from '../../atoms/Button';
import JoditEditor from 'jodit-react';
import { MODE, STATUS } from '../../../utils/constant';
import { FormikProps } from 'formik';
import Toggler from '../../atoms/Toggler';
import { titleModification } from '../../../utils/helper';

interface SuperCategoryFormTemplateProps {
  mode: string,
  formik: FormikProps<CategoryPayload>,
  onBack: () => void
}

const useStyles = createUseStyles((theme: any) => ({
  textColor: {
    color: theme.palette.text.primaryDarkLight
  },
  border: {
    borderBottom: `1px solid ${theme.palette.border.primaryDark}`
  },
  errorMessage: {
    color: theme.palette.action.danger,
  }
}));

const SuperCategoryFormTemplate: React.FC<SuperCategoryFormTemplateProps> = ({ mode, onBack, formik }) => {
  const classes = useStyles();
  const editor = React.useRef(null);

  const config = React.useMemo(() => {
    return {
      readonly: mode === MODE.VIEW,
      placeholder: "Start typings...",
    };
  }, []);

  const handleToggleChange = (type: any, value: boolean) =>
    formik.setFieldValue("status", value ? STATUS.ACTIVE : STATUS.INACTIVE)

  const handleSubmit = () => formik.handleSubmit();

  return (
    <form className="p-6 border flex flex-col gap-6" >
      <div className={`flex justify-between w-full pb-6 items-center text-2xl font-semibold ${classes.textColor} ${classes.border}`}>
        <h3>{mode === MODE.UPDATE ? "Update" : mode === MODE.VIEW ? "View" : "Add New"} Super Categories</h3>
        <CloseIcon className='close-icon' onClick={() => { onBack() }} />
      </div>
      <div>
      <TextField
        className={`blank-feild ${mode === MODE.VIEW && 'pointer-events-none'}`}
        id="outlined-basic"
        label="Enter Super Category Name"
        variant="outlined"
        {...formik.getFieldProps("name")}
        error={formik.touched.name && Boolean(formik.errors.name)}
        inputProps={{
          readOnly: mode === MODE.VIEW,
          maxLength: 100,
        }}
        onBlur={(event) => {
          const newValue = titleModification(event.target.value);
          formik.setFieldValue('name', newValue);
      }}
        />
      {formik.errors.name && formik.touched.name && (
        <div className={`${classes.errorMessage} text-xs mt-1`}>
            {formik.errors.name}
          </div>
        )}
      </div>

      <div className={`${mode === MODE.VIEW && 'pointer-events-none'}`}>
        <FormGroup>
          <Toggler
            title="Status"
            currentState={formik.values.status === STATUS.ACTIVE}
            handleToggleChange={handleToggleChange}
            disabled={mode === MODE.VIEW}
          />
        </FormGroup>
      </div>
      <FormControl fullWidth>
        <JoditEditor
          ref={editor}
          value={formik.values.description}
          onBlur={(newContent) =>
            formik.setFieldValue(
              "description",
              newContent
            )
          }
          config={config}
        />
        {formik.errors.description && formik.touched.description && (
          <div className={`${classes.errorMessage} text-xs mt-1`}>
            {formik.errors.description}
          </div>
        )}
      </FormControl>
      <div className="flex justify-end gap-4">
        <Button variant="outlined" label='Cancel' onClick={onBack} />
        {mode !== MODE.VIEW && <Button variant="contained" onClick={handleSubmit} label={mode}/>}
      </div>
    </form>
  )
}

export default SuperCategoryFormTemplate