import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { Procurement } from '../../../Constants/Procurement/Indent/types';
import {
  INDENT_TEXT,
  PRID_LIST_PAGINATION_TEXT,
} from '../../../Constants/Procurement/Indent/constants';
import UPCViewComponent from './UPCViewComponent';
import { createUseStyles } from 'react-jss';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import { useIndentService } from '../../../../services/useIndentService';

interface PurchaseRequisitionSelectProps {
  handleNext: () => void;
  selectedPurchaseRequisition: Procurement[];
  setSelectedPurchaseRequisition: Dispatch<SetStateAction<Procurement[]>>;
  locations: { id: string; value: string; name: string }[];
}

const useStyles = createUseStyles({
  bottomIndentBar: {
    position: 'sticky',
    bottom: '15px',
    left: '0',
    right: '0',
  },
  paginationShadow: {
    boxShadow: '0px 0px 10px 0px #0000001a',
    '&:hover': {
      boxShadow: '0px 0px 15px 0px #0000001a',
    },
  },
});

const PurchaseRequisitionSelect: React.FC<PurchaseRequisitionSelectProps> = ({
  handleNext,
  selectedPurchaseRequisition,
  setSelectedPurchaseRequisition,
  locations,
}) => {
  const classes = useStyles();
  const { getPurchaseRequistions } = useIndentService();

  const { user }: any = useAuthenticatedUser();
  const [prid, setPrid] = useState<string>('');
  const [upcSearch, setUPCSearch] = useState<string>('');
  const [deliveryLocation, setDeliveryLocation] = useState<string>();
  const [PRList, setPRList] = useState<Procurement[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [filters, setFilters] = useState({
    pageSize: 10,
    pageNumber: 0,
    prIDs: '',
    searchTerm: '',
  });
  const fetchPRs = async (params: any): Promise<void> => {
    setSelectedPurchaseRequisition([]);
    let queryParams = {
      ...params,
      statuses: 'CREATED, PARTIALLY_COMPLETED, PARTIALLY_INPROGRESS',
      pageSize: pageSize,
    };

    try {
      const PRresponse = await getPurchaseRequistions(queryParams);

      if (PRresponse?.data?.data?.content.length > 0) {
        setTotalPages(PRresponse?.data?.data?.totalPages);
        setTotalRecords(PRresponse?.data?.data?.totalElements);
        setPageSize(PRresponse?.data.data.size);
        setPRList(PRresponse?.data.data.content);
      } else {
        setPRList([]);
        setTotalPages(0);
        setTotalRecords(0);
        setPageSize(10);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleDeliveryLocation = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    if (value) {
      setSelectedPurchaseRequisition([]);
      setDeliveryLocation(value.toString());
      setPageNumber(0);
      setPrid('');
      setUPCSearch('');
      const params = {
        ...filters,
        pageNumber: 0,
        searchTerm: '',
        prIDs: '',
        warehouseId: value.toString(),
      };
      setFilters(params);
      fetchPRs(params);
    }
  };

  const handlePagination = (type: 'prev' | 'next') => {
    if (type === 'prev') {
      if (pageNumber > 0) {
        setPageNumber((prev) => prev - 1);
        const params = {
          ...filters,
          pageNumber: pageNumber - 1,
        };
        setFilters(params);
        fetchPRs(params);
      }
    } else if (type === 'next') {
      if (pageNumber < totalPages) {
        setPageNumber((prev) => prev + 1);
        const params = {
          ...filters,
          pageNumber: pageNumber + 1,
        };
        setFilters(params);
        fetchPRs(params);
      }
    }
  };

  const handleToggle = (value: Procurement): void => {
    let newSelectedProcurement = [...selectedPurchaseRequisition];

    if (newSelectedProcurement.find((e) => e.id === value.id)) {
      newSelectedProcurement = newSelectedProcurement.filter(
        (item) => item.id !== value.id
      );
    } else {
      newSelectedProcurement.push(value);
    }
    setSelectedPurchaseRequisition(newSelectedProcurement);
  };

  const handleNextStep = (): void => {
    if (selectedPurchaseRequisition.length <= 0) {
      alert('SELECT ONE');
    } else {
      handleNext();
    }
  };
  let timeout: NodeJS.Timeout;
  const handleSearchChanges = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    if (name === 'purchaseRequestionSearch') {
      if (timeout) clearTimeout(timeout);
      setPrid(value);
      setUPCSearch('');
      timeout = setTimeout(() => {
        const params = {
          ...filters,
          searchTerm: '',
          prIDs: value,
        };
        setFilters(params);
        fetchPRs(params);
      }, 500);
    } else if (name === 'upcSearch') {
      if (timeout) clearTimeout(timeout);
      setUPCSearch(value);
      setPrid('');
      timeout = setTimeout(() => {
        const params = {
          ...filters,
          searchTerm: value,
          prIDs: '',
        };
        setFilters(params);
        fetchPRs(params);
      }, 500);
    }
  };

  useEffect(() => {
    if (locations.length > 0) {
      setDeliveryLocation(locations[0].id);
      const params = {
        ...filters,
        warehouseId: locations[0].id,
      };
      fetchPRs(params);
    }
  }, [locations]);

  return (
    <div className="">
      <div className="grid gap-y-3 gap-x-4 ">
        <div className="bg-cgray-2 flex flex-row justify-between w-full items-center py-3 px-4">
          <div className="text-xl font-semibold text-blue">
            {INDENT_TEXT.selectAndAddPRText}
          </div>
          <div className="flex flex-row gap-8 items-center justify-between">
            <div className="flex justify-center items-center gap-3  w-full ">
              <div className="text-blue font-semibold text-base ">
                {INDENT_TEXT.prEnterLabelText}
              </div>
              <div>
                <FormControl sx={{ minWidth: '150px', maxWidth: '170px' }}>
                  <TextField
                    className="border-solid border-cgray-3 bg-white rounded-lg"
                    name="purchaseRequestionSearch"
                    variant="outlined"
                    fullWidth
                    value={prid}
                    onChange={handleSearchChanges}
                    type="text"
                    placeholder={INDENT_TEXT.prEnterPlaceHolderText}
                  />
                </FormControl>
              </div>
            </div>
            <div className="flex p-2 item-center relative">
              <FormControl sx={{ width: '308px' }}>
                <input
                  name="upcSearch"
                  value={upcSearch}
                  onChange={handleSearchChanges}
                  type="text"
                  placeholder={INDENT_TEXT.productNameSearchPlaceHolderText}
                  className="bg-white  h-14 pl-2 pr-14 border border-gray border-cgray-3 rounded-lg focus:outline-blue"
                />
              </FormControl>
              <button
                type="submit"
                className="bg-blue flex items-center w-14 justify-center absolute right-0 h-14 rounded-r-lg"
                // onClick={() => fetchPRs(param)}
              >
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.3854 15.3781L22.5432 22.5452M17.8079 9.52309C17.8079 14.0997 14.0978 17.8099 9.52114 17.8099C4.94449 17.8099 1.23438 14.0997 1.23438 9.52309C1.23438 4.94644 4.94449 1.23633 9.52114 1.23633C14.0978 1.23633 17.8079 4.94644 17.8079 9.52309Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div>
              <button className=" w-12 border h-12 border-cgray-3 rounded-lg flex justify-center items-center bg-white">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 2.408C1 1.91515 1 1.66873 1.09591 1.48049C1.18028 1.31491 1.31491 1.18028 1.48049 1.09591C1.66873 1 1.91515 1 2.408 1H15.432C15.9248 1 16.1713 1 16.3595 1.09591C16.5251 1.18028 16.6597 1.31491 16.7441 1.48049C16.84 1.66873 16.84 1.91515 16.84 2.408V3.93679C16.84 4.15203 16.84 4.25965 16.8157 4.36092C16.7941 4.45072 16.7586 4.53656 16.7103 4.61529C16.6559 4.7041 16.5798 4.7802 16.4276 4.93239L11.0924 10.2676C10.9402 10.4198 10.8641 10.4959 10.8097 10.5847C10.7614 10.6634 10.7259 10.7493 10.7043 10.8391C10.68 10.9404 10.68 11.048 10.68 11.2632V13.32L7.16 16.84V11.2632C7.16 11.048 7.16 10.9404 7.13569 10.8391C7.11413 10.7493 7.07857 10.6634 7.03032 10.5847C6.9759 10.4959 6.8998 10.4198 6.74761 10.2676L1.41239 4.93239C1.2602 4.7802 1.1841 4.7041 1.12968 4.61529C1.08143 4.53656 1.04587 4.45072 1.02431 4.36092C1 4.25965 1 4.15203 1 3.93679V2.408Z"
                    stroke="#4D4D4D"
                    strokeWidth="1.76"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-row w-full items-start">
          <div className="border-solid border-cgray-3 bg-white flex flex-col justify-center items-center p-6 w-full border rounded-lg">
            <FormControl sx={{ width: '100%' }}>
              <div className="pb-[16px] font-semibold text-text-color text-base">
                {INDENT_TEXT.deliveryLocationRadioGroupLabelText}
              </div>
              {deliveryLocation && (
                <RadioGroup
                  row
                  value={deliveryLocation}
                  onChange={handleDeliveryLocation}
                  sx={{
                    '& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked':
                      { color: '#1d3679' },
                  }}
                >
                  {locations &&
                    locations.map((location, id) => (
                      <FormControlLabel
                        key={id}
                        value={location.id}
                        control={<Radio />}
                        label={location.name}
                      />
                    ))}
                </RadioGroup>
              )}
            </FormControl>
          </div>
        </div>

        {/* UPC LIST TABLE */}
        <div className="border border-gray-300 p-[10px] rounded gap-4 flex flex-col">
          {PRList.length > 0 &&
            PRList.map((product, id) => (
              <div key={id}>
                <UPCViewComponent
                  handleToggle={handleToggle}
                  product={product}
                  selectedProcurement={selectedPurchaseRequisition}
                />
              </div>
            ))}
        </div>

        {/* DOWN BUTTONS */}

        <div
          className={`flex justify-between items-center rounded-lg ${classes.paginationShadow} w-full h-[72px] p-4 ${classes.bottomIndentBar}  bg-white`}
        >
          <div className="w-full"></div>
          <div className=" flex justify-center items-center bg-white w-full">
            <div className=" justify-center items-center gap-4 inline-flex  text-base">
              <div className="items-center gap-1.5 flex">
                <div
                  className={`w-8 h-8 p-2.5 bg-slate-100 rounded justify-center items-center flex ${
                    pageNumber !== 0 ? 'cursor-pointer' : ''
                  } `}
                  onClick={
                    pageNumber !== 0
                      ? () => handlePagination('prev')
                      : undefined
                  }
                >
                  <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.49656 0.710754C6.88656 1.10075 6.88656 1.73075 6.49656 2.12075L2.61656 6.00075L6.49656 9.88075C6.88656 10.2708 6.88656 10.9008 6.49656 11.2908C6.10656 11.6808 5.47656 11.6808 5.08656 11.2908L0.496562 6.70075C0.106563 6.31075 0.106563 5.68075 0.496562 5.29075L5.08656 0.700754C5.46656 0.320754 6.10656 0.320754 6.49656 0.710754Z"
                      fill={pageNumber !== 0 ? '#1D3679' : '#a9acc4'}
                    />
                  </svg>
                </div>
                <div className="w-11 px-3 py-2 bg-white  justify-center items-center gap-1 flex ">
                  <div className="text-zinc-800 text-base font-bold">
                    {totalRecords > 0
                      ? pageNumber < 10
                        ? '0' + (pageNumber + 1)
                        : pageNumber
                      : 0}
                  </div>
                </div>
                <div
                  className={`w-8 h-8 p-2.5 bg-slate-100 rounded justify-center items-center  flex  ${
                    pageNumber < totalPages - 1 ? 'cursor-pointer' : ''
                  }`}
                  onClick={
                    pageNumber >= totalPages - 1
                      ? undefined
                      : () => handlePagination('next')
                  }
                >
                  <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.503437 0.710754C0.113438 1.10075 0.113438 1.73075 0.503437 2.12075L4.38344 6.00075L0.503437 9.88075C0.113438 10.2708 0.113438 10.9008 0.503437 11.2908C0.893438 11.6808 1.52344 11.6808 1.91344 11.2908L6.50344 6.70075C6.89344 6.31075 6.89344 5.68075 6.50344 5.29075L1.91344 0.700754C1.53344 0.320754 0.893438 0.320754 0.503437 0.710754Z"
                      fill={pageNumber < totalPages - 1 ? '#1D3679' : '#a9acc4'}
                    />
                  </svg>
                </div>
              </div>
              <div className="text-blue-900 text-base font-bold text-blue">
                {totalRecords > 0 && (
                  <span>
                    {PRID_LIST_PAGINATION_TEXT.showingItems(
                      pageNumber,
                      totalRecords
                    )}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-end items-center gap-6 w-full">
            <div className="flex justify-center items-center gap-2">
              <div className="text-oreng font-bold text-base">{`${
                selectedPurchaseRequisition.length || 0
              } PRIDs Selected`}</div>
            </div>
            <div
              className={`p-2 px-7 bg-blue text-white rounded justify-center items-center flex w-[122px]] h-[48px] ${'cursor-pointer'}`}
              onClick={
                selectedPurchaseRequisition.length >= 1
                  ? () => {
                      handleNextStep();
                    }
                  : undefined
              }
            >
              <div className="text-white normal-case">Next</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseRequisitionSelect;
