import { createUseStyles } from 'react-jss';
import { useBulkUploadService } from '../../../services/useBulkUploadService';
import { IBulkUpload } from './BulkUploadList.page';
import { useState } from 'react';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useSnackbar } from '../../../hooks/useSnackBar';


interface BulkUploadRefreshProps {
    icon: string;
    batchId: number;
    bulkUploads: IBulkUpload[];
    setBulkUploadsTo: any;
}

const useStyles = createUseStyles(() => ({
    refreshIconWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        paddingLeft: "164px",
        color: 'darkblue',
        fontSize: '32px',
    },
    rotating: {
        animation: "$spin 4s linear infinite",
    },
    "@keyframes spin": {
        "0%": {
            transform: "rotate(0deg)",
        },
        "100%": {
            transform: "rotate(360deg)",
        },
    },
}));

const BulkUploadRefresh: React.FC<BulkUploadRefreshProps> = ({ icon, batchId, bulkUploads, setBulkUploadsTo }) => {
    const classes = useStyles();
    const bulkUploadService = useBulkUploadService();
    const [isRotating, setIsRotating] = useState(false);
    const {showSnackbar, SnackBarComponent} = useSnackbar();

    const handleClick = async () => {
        setIsRotating(true);
        try {
            const bulkUploadResponse = await bulkUploadService.getBulkUploadById(batchId);
            if (bulkUploadResponse) {
                const index = bulkUploads.findIndex((upload) => upload.id === batchId);
                if (index !== -1) {
                    const updatedBulkUploads = [...bulkUploads];
                    updatedBulkUploads[index] = bulkUploadResponse.data.data;
                    setBulkUploadsTo(updatedBulkUploads)
                    setIsRotating(false);
                    showSnackbar("success", `Status Updated Successfully`);
                }
            } else {
                showSnackbar("error", `Error while updating status`);
                throw new Error("Bulk Upload fetch failed");
            }
        } catch (error) {
            console.log("Error: ", error)
        }
    }

    return (
        <>
            {SnackBarComponent}
            <div className={classes.refreshIconWrapper} onClick={handleClick}>
                <AutorenewIcon className={isRotating ? classes.rotating : ""} />
            </div>
        </>
    );
}

export default BulkUploadRefresh;