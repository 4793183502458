import React from 'react'
import { createUseStyles } from 'react-jss';
import TextFieldV2 from '../../../atoms/TextFieldV2';
import { MODE } from '../../../../utils/constant';
import { titleModification } from '../../../../utils/helper';

interface MachineOverviewFormTemplateProps {
    mode: string,
    formik: any;
}

const useStyles = createUseStyles((theme: any) => ({
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
    icon: {
        backgroundColor: "#FD6202",
        color: "white",
        width: "48px",
        height: "48px",
        borderRadius: "10px",
    },
}));

const MachineOverviewFormTemplate: React.FC<MachineOverviewFormTemplateProps> = ({ mode, formik }) => {
    const classes = useStyles();

    return (
        <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
            <div className={`${classes.heading} text-base font-medium`}>Machine Overview</div>

            <div className='grid grid-cols-4 gap-x-4'>
                <div className="grid gap-y-1">
                    <TextFieldV2
                        label="Machine Name"
                        placeholder='Enter Machine Name'
                        {...formik.getFieldProps("machineName")}
                        error={formik.touched.machineName && Boolean(formik.errors.machineName)}
                        inputProps={{ readOnly: mode === MODE.VIEW, maxLength: 30 }}
                        onBlur={(event) => {
                            const newValue = titleModification(event.target.value);
                            formik.setFieldValue('machineName', newValue);
                        }}
                    />
                    {formik.errors.machineName && formik.touched.machineName && (
                        <div className={`${classes.errorMessage} text-xs`}>{formik.errors.machineName}</div>
                    )}
                </div>
                <div className="grid gap-y-1">
                    <TextFieldV2
                        label="Brand/Manufacturer of Machine"
                        placeholder='Enter Here'
                        {...formik.getFieldProps("machineVendor")}
                        error={formik.touched.machineVendor && Boolean(formik.errors.machineVendor)}
                        inputProps={{ readOnly: mode === MODE.VIEW, maxLength: 30 }}
                        onBlur={(event) => {
                            const newValue = titleModification(event.target.value);
                            formik.setFieldValue('machineVendor', newValue);
                        }}
                    />
                    {formik.errors.machineVendor && formik.touched.machineVendor && (
                        <div className={`${classes.errorMessage} text-xs`}>{formik.errors.machineVendor}</div>
                    )}
                </div>
                <div className="grid gap-y-1">
                    <TextFieldV2
                        label="Model Number (Mandatory)"
                        placeholder='Enter Here'
                        {...formik.getFieldProps("modelNumber")}
                        error={formik.touched.modelNumber && Boolean(formik.errors.modelNumber)}
                        inputProps={{ readOnly: mode === MODE.VIEW, maxLength: 30 }}
                        onBlur={(event) => {
                            const newValue = titleModification(event.target.value);
                            formik.setFieldValue('modelNumber', newValue);
                        }}
                    />
                    {formik.errors.modelNumber && formik.touched.modelNumber && (
                        <div className={`${classes.errorMessage} text-xs`}>{formik.errors.modelNumber}</div>
                    )}
                </div>
                <div className="grid gap-y-1">
                    <TextFieldV2
                        label="Machine Location"
                        placeholder='Enter Here'
                        {...formik.getFieldProps("location")}
                        error={formik.touched.location && Boolean(formik.errors.location)}
                        inputProps={{ readOnly: mode === MODE.VIEW, maxLength: 100 }}
                        onBlur={(event) => {
                            const newValue = titleModification(event.target.value);
                            formik.setFieldValue('location', newValue);
                        }}
                    />
                    {formik.errors.location && formik.touched.location && (
                        <div className={`${classes.errorMessage} text-xs`}>{formik.errors.location}</div>
                    )}
                </div>
            </div>

            <div className='grid grid-cols-3 gap-x-4'>
                <div className="grid gap-y-1">
                    <TextFieldV2
                        label="Machine Type"
                        placeholder='Enter Machine Type'
                        {...formik.getFieldProps("machineType")}
                        error={formik.touched.machineType && Boolean(formik.errors.machineType)}
                        inputProps={{ readOnly: mode === MODE.VIEW, maxLength: 30 }}
                        onBlur={(event) => {
                            const newValue = titleModification(event.target.value);
                            formik.setFieldValue('machineType', newValue);
                        }}
                    />
                    {formik.errors.machineType && formik.touched.machineType && (
                        <div className={`${classes.errorMessage} text-xs`}>{formik.errors.machineType}</div>
                    )}
                </div>
                <div className="grid gap-y-1">
                    <TextFieldV2
                        label="Year of Manufacture"
                        placeholder='Enter Here'
                        {...formik.getFieldProps("manufactureYear")}
                        error={formik.touched.manufactureYear && Boolean(formik.errors.manufactureYear)}
                        type="number"
                        inputProps={{ readOnly: mode === MODE.VIEW }}
                    />
                    {formik.errors.manufactureYear && formik.touched.manufactureYear && (
                        <div className={`${classes.errorMessage} text-xs`}>{formik.errors.manufactureYear}</div>
                    )}
                </div>
                <div className="grid gap-y-1">
                    <TextFieldV2
                        label="Operational Speed"
                        placeholder='Enter Operational Speed'
                        {...formik.getFieldProps("operationalSpeed")}
                        error={formik.touched.operationalSpeed && Boolean(formik.errors.operationalSpeed)}
                        type="number"
                        inputProps={{ readOnly: mode === MODE.VIEW }}
                    />
                    {formik.errors.operationalSpeed && formik.touched.operationalSpeed && (
                        <div className={`${classes.errorMessage} text-xs`}>{formik.errors.operationalSpeed}</div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default MachineOverviewFormTemplate;