import React, { useEffect, useState } from 'react';
import ButtonV2 from '../../atoms/ButtonV2';
import { ADMIN_ROUTES, CUSTOMER_MANAGEMENT_TABS, initialPaginationValues } from '../../../utils/constant';
import { HTTP_STATUS, IPagination } from '../../../utils/types';
import TableV2, { ColumnType } from '../../organisms/TableV2';
import { createUseStyles } from 'react-jss';
import AddMasterCustomerModalTemplate from './AddMasterCustomerModal.template';
import { useMasterUpcService } from '../../../services/useMasterUpcService';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteDialogBox from '../../atoms/DeleteDialogBox';
import { useSnackbar } from '../../../hooks/useSnackBar';
import deleteBinIcon2 from '../../../assets/icons/deleteBinIcon2.svg';
import search from '../../../assets/images/search.svg'
import { FormControl, InputAdornment } from '@mui/material';
import downloadExcelIcon from '../../../assets/icons/downloadExcelIcon.svg';
import { IMultiSelectOption } from '../../molecules/FilterChip/FilterChip';
import moment from 'moment';
import TextFieldV2 from '../../atoms/TextFieldV2';
import { makeRoute } from '../../../utils/helper';
import { useNavigate } from 'react-router-dom';

interface IMasterCustomerWithUpc {
    id: number;
    customerId: string | undefined;
}

export interface IMasterCustomerWithUpcParams {
    search: string | null;
    sort?: string;
}

export interface ICustomerMasterUpcs {
    id: number;
    customerId: number;
    customerName: string;
    creationDate: string;
    userId: number;
}

export interface IDeleteCustomerWithUpc {
    masterUpcId: number;
    customerId: number;
}

export interface IUserDetailsParams {
    userType: string;
    search: string | null;
}

export interface ICustomerDetailsResponse {
    name: string;
    userId: string;
}

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.buttonV2.secondaryContained.text.primaryDark,
    },
    textField: {
        padding: "14px 12px"
    }
}));

const MasterCustomerWithUpcTemplate: React.FC<IMasterCustomerWithUpc> = ({ id, customerId }) => {
    const classes = useStyles();
    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);
    const [customers, setCustomersTo] = useState<ICustomerMasterUpcs[]>([]);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpenTo] = useState<boolean>(false);
    const [selectedCustomerId, setSelectedCustomerIdTo] = useState<number>(0);
    const masterUpcService = useMasterUpcService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [userNameOptionsTo, setUserNameOptionsTo] = useState<IMultiSelectOption[]>([]);
    const [selectedUserValues, setSelectedUserValuesTo] = useState<IMultiSelectOption[]>([]);
    const [searchTerm, setSearchTermTo] = useState<string>('');
    const navigate = useNavigate();


    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage,
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage,
        }));
    };


    const schema = {
        id: "1",
        title: "",
        pagination: {
            total: pagination.totalRecords,
            currentPage: pagination.currentPage,
            isVisible: true,
            limit: pagination.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange,
        },
        columns: [
            { label: 'S No.', key: 'id', type: 'number' as ColumnType, props: { className: '' } },
            { label: 'Customer Id', key: 'customerId', type: 'number' as ColumnType, props: { className: '' } },
            { label: 'Customer Name', key: 'customerName', type: 'string' as ColumnType, props: { className: '' } },
            { label: 'Date of Addition', key: 'dateOfAddition', type: 'string' as ColumnType, props: { className: '' } },
            { label: 'Action', key: 'action', type: 'string' as ColumnType, props: { className: '' } },
        ],
    };

    const handleDeleteCustomerUpc = (customerId: number) => {
        setSelectedCustomerIdTo(customerId);
        setDeleteDialogOpenTo(true);
    }

    const Action = (id: number, customerId: number) => {
        return (
            <div className={` flex gap-x-2 justify-center`}>
                <ButtonV2 variant="tertiaryText"
                    iconButton={<img src={deleteBinIcon2} alt="Delete Icon" />}
                    label="Remove"
                    buttonWithImg={true}
                    size="small"
                    onClick={(e) => handleDeleteCustomerUpc(customerId)}
                />
            </div>
        );
    };

    const RouteAction = (customerId: number) => {
        const handleNavigation = () => {
            const route = makeRoute(ADMIN_ROUTES.CUSTOMER_MANAGEMENT_VIEW, { query: { tab: CUSTOMER_MANAGEMENT_TABS.PROFILE }, params: { id: customerId } });
            navigate(`${route}`);
        };

        return (
            <div className="flex justify-center">
                <ButtonV2
                    variant="tertiaryText"
                    onClick={handleNavigation}
                    label={customerId.toString()}
                    size="small"
                />
            </div>
        );

    };


    const records = Array.isArray(customers)
        ? customers.map((customers: ICustomerMasterUpcs, index: number) => [
            pagination.currentPage * pagination.pageSize + index + 1,
            RouteAction(customers.customerId),
            customers.customerName,
            moment(customers.creationDate).format("YYYY-MM-DD HH:mm:ss"),
            Action(id, customers.customerId),
        ])
        : [];

    const handleAddNewCustomer = () => {
        setDialogOpen(true);
    };

    const fetchCustomerUpcDetails = async (id: number, inputString?: string) => {

        if (id) {
            try {
                let params: IMasterCustomerWithUpcParams = {
                    search: inputString ?? null,
                    sort: "createdAt,desc"
                };
                const upcResponse = await masterUpcService.getCustomerUpcDetails(id, params);

                if (upcResponse?.status === HTTP_STATUS.OK) {
                    const data = upcResponse?.data?.data?.content;
                    setCustomersTo(data);
                    setPaginationTo((prevPagination: IPagination) => ({
                        ...prevPagination,
                        totalRecords: upcResponse.data.data.totalElements,
                        totalPages: upcResponse.data.data.totalPages,
                    }));
                }
            } catch (error) {
                console.error('Error:', `UPC fetch failed`);
                setCustomersTo([]);
            }
        }
    };

    useEffect(() => {
        if (id && customerId) {
            fetchCustomerUpcDetails(id);
        }
    }, [id, customerId, pagination.currentPage, pagination.pageSize]);

    useEffect(() => {
        getAllUsersDetails();
    }, [customers, searchTerm]);

    const getAllUsersDetails = async () => {
        const userParams: IUserDetailsParams = {
            userType: "BUYER",
            search: searchTerm
        };
        try {
            const userdetailsResponse = await masterUpcService.getAllUsersDetails(userParams);

            const existingCustomerIds = customers?.map(c => c.userId);
            const options = userdetailsResponse?.data?.data?.content
                .filter((user: { userId: number }) => 
                    !existingCustomerIds.includes(user.userId)
                )
                .map((user: { name: string; userId: number }) => ({
                    label: `${user.name} - ${user.userId}`,
                    value: user.userId,
                }));
            setUserNameOptionsTo(options);
        } catch (error) {
            console.error('Error fetching user details:', error);
            showSnackbar("error", "Error while fetching user details");
        }
    };
    


    const handleUserSelectionChange = (selected: IMultiSelectOption[]) => {
        console.log("Handle User Selection Change" + JSON.stringify(selected));
        let filteredSelected = selected.filter(value =>
            value.label !== (selectedUserValues.length > 0 && selectedUserValues[0].label)
        )
        setSelectedUserValuesTo(filteredSelected);
    };

    const handleOnDelete = async () => {
        try {
            if (selectedCustomerId != 0) {
                const params: IDeleteCustomerWithUpc = {
                    masterUpcId: id,
                    customerId: selectedCustomerId
                };
                const deleteResponse = await masterUpcService.deleteCustomerUpcMapping(id, selectedCustomerId);
                if (deleteResponse?.status === HTTP_STATUS.OK && deleteResponse?.data) {
                    showSnackbar("success", `Customer Upc Status Updated Successfully`);
                    setDeleteDialogOpenTo(false);
                    const response = await fetchCustomerUpcDetails(id);
                }
                else {
                    showSnackbar("error", "Error while deleting customer upc");
                    setDeleteDialogOpenTo(false);
                }

            }
        } catch (error) {
            console.error("Exception while deleting customer mapped with upc");
        }
    }
    const mapNewCustomerWithUpc = async () => {
        try {
            const response = await masterUpcService.addNewCustomerWithUpc(id, selectedUserValues[0].value as string);
            if (response?.status === HTTP_STATUS.OK && response?.data) {
                fetchCustomerUpcDetails(id);
                setSelectedUserValuesTo([]);
                showSnackbar("success", "Customer Added Successfully");
                setDialogOpen(false);
            }
            else {
                if(response.data.exceptionCode === "DUPLICATE_ENTRY"){
                    showSnackbar("error", "Customer already Exists");
                }else{
                showSnackbar("error", "Error in mapping customer with upc successfully");
                }
            }

        } catch (error) {
            console.error("Exception while mapping new customer with upc");
        }
    }
    return (
        <div>
            <div className={`${classes.heading} text-lg font-semibold mt-6`}>Customer Mapped with Master UPC</div>
            <div className="flex justify-end items-center gap-x-4">
                <TextFieldV2
                    label=''
                    variant="outlined"
                    placeholder="Search By Name.."
                    name='search'
                    className={classes.textField}
                    onChange={(value) => {
                        fetchCustomerUpcDetails(id, value.target.value)
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start" > <img src={search} alt="icon" /></InputAdornment>,
                    }}
                />
                <ButtonV2 variant={"tertiaryContained"} label={"Excel"} iconButton={<img src={downloadExcelIcon} alt="Excel Icon" />} buttonWithImg={true} disabled/>
                <ButtonV2 variant="primaryContained" onClick={handleAddNewCustomer} label="+Add New Customer" />
            </div>
            <div className="mt-5">
                <TableV2 schema={schema} records={records} />
            </div>
            <AddMasterCustomerModalTemplate dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} userNameOptionsTo={userNameOptionsTo} setUserNameOptionsTo={setUserNameOptionsTo} handleUserSelectionChange={handleUserSelectionChange} selectedUserValues={selectedUserValues} setSelectedUserValuesTo={setSelectedUserValuesTo} searchTerm={searchTerm} setSearchTerm={setSearchTermTo} setCustomerDetails={setCustomersTo} mapNewCustomerWithUpc={mapNewCustomerWithUpc} />
            <DeleteDialogBox deleteDialogOpen={deleteDialogOpen} setDeleteDialogOpen={setDeleteDialogOpenTo} textInput='Customer' onSubmitYes={handleOnDelete} />

        </div>
    );
};

export default MasterCustomerWithUpcTemplate;
