import React from 'react'
import avighnaLogo from "../../../assets/images/avighnaLogo.png"
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    backgroundColor:{
        backgroundColor: theme.palette.button.primaryDark
    }
}));

const CompanyInformationTemplate: React.FC= () => {
    const classes = useStyles();
    return (
        <div className={`h-full w-full p-10 grid ${classes.backgroundColor}`}>
            <div className="auth-logo-container">
                <img src={avighnaLogo} alt="avighnaSteelLogo" />
            </div>
            <div className='text-white'>
                <h1 className='font-bold sm:text-3xl md:text-4xl lg:text-6xl mb-4' style={{ lineHeight: '100%' }}>
                    Start your journey with us.
                </h1>
                <p className='md:text-base xlg:text-lg font-normal mb-4' style={{ lineHeight: "187.5%" }}>Welcome to Avighna Steel Ltd. Your Trusted Partner for High-Quality Steel Solutions. Leading the Industry with Superior Steel Products and Unparalleled Service. Delivering Excellence in Steel Manufacturing and Customer Satisfaction.</p>
            </div>
            <div className='flex items-center mt-16 gap-x-5'>
                <span></span>
            </div>
        </div>
    )
}

export default CompanyInformationTemplate