export const AUCTION_LIST_PAGINATION_TEXT = {
  showingItems: (pageNumber: number, totalItems: number) =>
    `${totalItems > 0 ? pageNumber * 10 + 1 : 0}-${
      totalItems < pageNumber * 10 + 10 ? totalItems : pageNumber * 10 + 10
    } from ${totalItems} Auction`,
};

export const REVERSE_AUCTION_TABS = ['All Auction', 'My Auction', 'My Drafts'];
export const AUCTION_STATUS: { name: string; value: string }[] = [
  { name: 'All', value: 'All' },
  { name: 'Initiated', value: 'INIT' },
  { name: 'Created', value: 'CREATED' },
  { name: 'Approval Remarks', value: 'APPROVAL_REMARKS' },
  { name: 'Approval Remarks Resolved', value: 'APPROVAL_REMARKS_RESOLVED' },
  { name: 'Approved', value: 'APPROVED' },
  { name: 'Vendor Invited', value: 'READY_FOR_GO_LIVE' },
  { name: 'Rejected', value: 'REJECTED' },
  { name: 'Cancelled', value: 'CANCELLED' },
  { name: 'Paused', value: 'PAUSED' },
  { name: 'Closed', value: 'CLOSED' },
  { name: 'Awarded', value: 'COMPLETED' },
];

export const ReverseAuctionSteps = [
  'Fill Auction Details',
  'Review Auction Details',
];

export const AUCTION_TEXT = {
  addAuctionHeading: 'Creating Reverse Action RFQ',
  reviewIndentDetailProductHeading: 'Product Details',
  reviewIndentTNCProductHeading: 'Indent T&C',
  createAuctionHeading: 'Add Auction Details',
  createAuctionHeadingSummary:
    'Please auction details for the auction you’re creating.',
  reviewAuctionHeading: 'Review Auction',
  reviewAuctionHeadingSummary: 'Please review details of auction.',
  addAuctionScheduleHeading: 'Auction Schedule',
  addAuctionSettingHeading: 'Auction Settings',
  addAuctionCheckBoxBestPriceLabel: 'Best Price',
  addAuctionCheckBoxBestPriceSummary: 'Show Best Price To The Bidders',
  addAuctionCheckBoxWinnerNameLabel: 'Winner Name',
  addAuctionCheckBoxWinnerNamSummary: 'Display Winners Name',
  addAuctionCheckBoxAllowBidLabel: 'Auto Bid Allow',
  addAuctionCheckBoxAllowBidSummary: 'Enable Auto Bid With Minimum Price',
  addAuctionCheckBoxDynamicTimeLabel: 'Dynamic Time',
  addAuctionCheckBoxDynamicTimeSummary: 'Enable Dynamic Time Feature',
  addAuctionTNCHeading: 'Auction Terms & Conditions',
  addAuctionPriceManagmentHeading: 'Auction Price Management',
  addAuctionBackButtonLabel: 'Back',
  addAuctionNextButtonLabel: 'Next',
  reviewAuctionDecrementPriceHeading: 'Decrement Price / MT',
  reviewAuctionReservePriceHeading: 'Auction Reserve Price / MT',
  reviewAuctionTargetPriceHeading: 'Auction Target Price / MT',
  reviewAuctionStartPriceHeading: 'Start Price Per MT',
  reviewPriceManagementHeading: 'Price Management',
  reviewAuctionSettingHeading: 'Auction Settings',
  reviewAuctionScheduleHeading: 'Auction Schedule',
  reviewAuctionNameHeading: 'Auction Name',
};
