import React, { useState } from "react";
import { AUTH_STATES, HTTP_STATUS } from "../../../utils/types";
import {
    IconButton,
    InputAdornment,
    FormHelperText
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { useUserService } from "../../../services/useUserService";
import * as Yup from "yup";
import { useFormik } from "formik";
import { REGEX } from "../../../utils/constant";
import Button from "../../atoms/Button";
import TextField from "../../atoms/TextField";
import { useSnackbar } from "../../../hooks/useSnackBar";

interface UserDetailsFormTemplateProps {
    setAuthState: (state: AUTH_STATES) => void;
}

const initialValues = {
    fullName: '',
    email: '',
    password: '',
    confirmPassword: '',
}

const validationSchema = Yup.object().shape({
    fullName: Yup.string()
        .required('Please enter your full name')
        .matches(REGEX.FULL_NAME, 'Please enter a valid name'),
    email: Yup.string()
        .required('Please enter your email address')
        .matches(REGEX.EMAIL, 'Invalid email address'),
    password: Yup.string()
        .required('Please enter your password')
        .matches(
            REGEX.PASSWORD,
            'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one special character, and one digit'
        ),
    confirmPassword: Yup.string()
        .required('Please confirm your password')
        .oneOf([Yup.ref('password'), ""], 'Passwords do not match'),
});

const UserDetailsFormTemplate: React.FC<UserDetailsFormTemplateProps> = ({ setAuthState }) => {
    const { user: authenticatedUser, setAuthenticatedUser } = useAuthenticatedUser();
    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
    const userService = useUserService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting, setFieldError }) => { 
            setSubmitting(true);
            const { email, password, fullName } = values;
            if (authenticatedUser) {
                userService.updateUser({ email, password, fullName, mobileNumber: authenticatedUser?.mobileNumber })
                    .then(res => {
                        if (res?.status === HTTP_STATUS.OK) {
                            if (res?.data?.data?.user)
                                setAuthenticatedUser({ ...authenticatedUser, ...res?.data?.data?.user })
                            else
                                showSnackbar('error', 'Failed to update user details')
                        } else if (res?.status === HTTP_STATUS.BAD_REQUEST) {
                            setFieldError('email', res?.data?.statusMessage)
                        } else showSnackbar('error', 'Server not responding, Please try again later')
                    }).catch(error => {
                        console.error("User details form:", error)
                    }).finally(() => setSubmitting(false));
            }
        },
    });

    const handleLogout = () => {
        setAuthenticatedUser(null);
        setAuthState(AUTH_STATES.LOGIN_WITH_EMAIL);
    }

    const handlePasswordVisiblity = () => {
        setIsPasswordVisible((show) => !show);
    };

    return (
        <div className="auth-right-child-container p-10 flex flex-col h-full">
            {SnackBarComponent}
            <div className="form-header mb-8">
                <h1 className="text-3xl font-bold sm:text-fs-24 md:text-fs-24">Sign Up</h1>
                <p className="text-base font-normal sm:text-sm md:text-sm">Stay updated on your professional world</p>
            </div>
            <div className="form-container flex flex-col grow h-full">
                <form className="flex flex-col" onSubmit={formik.handleSubmit}>
                    <div className="flex flex-col gap-y-2 mb-8">
                        <div>
                            <TextField
                                label="Full Name"
                                type="text"
                                error={
                                    formik.touched?.fullName && Boolean(formik.errors?.fullName)
                                }
                                {...formik.getFieldProps("fullName")}
                                fullWidth
                                autoComplete="false"
                            />
                            {formik.touched?.fullName && formik.errors?.fullName &&
                                <FormHelperText error className='top-full'>{formik.errors?.fullName}</FormHelperText>
                            }
                        </div>
                        <div>
                            <TextField
                                label="Email"
                                type="text"
                                error={
                                    formik.touched?.email && Boolean(formik.errors?.email)
                                }
                                {...formik.getFieldProps("email")}
                                fullWidth
                                autoComplete="false"
                            />
                            {formik.touched?.email && formik.errors?.email &&
                                <FormHelperText error className='top-full'>{formik.errors?.email}</FormHelperText>
                            }
                        </div>
                        <div>
                            <TextField
                                fullWidth
                                label="Password"
                                variant="outlined"
                                type={isPasswordVisible ? "text" : "password"}
                                autoComplete='false'
                                error={
                                    formik.touched?.password && Boolean(formik?.errors?.password)
                                }
                                {...formik.getFieldProps("password")}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handlePasswordVisiblity}
                                            edge="end"
                                        >
                                            {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                            />
                            {formik.touched?.password && formik?.errors?.password &&
                                <FormHelperText error className='top-full'>{formik.errors?.password}</FormHelperText>
                            }
                        </div>
                        <div>
                            <TextField
                                label="confirm Password"
                                type="password"
                                error={
                                    formik.touched?.confirmPassword && Boolean(formik.errors?.confirmPassword)
                                }
                                {...formik.getFieldProps("confirmPassword")}
                                fullWidth
                                autoComplete="false"
                            />
                            {formik.touched?.confirmPassword && formik.errors?.confirmPassword &&
                                <FormHelperText error className='top-full'>{formik.errors?.confirmPassword}</FormHelperText>
                            }
                        </div>
                    </div>
                    <Button type="submit" variant="contained" label='Sign Up' isLoading={formik.isSubmitting}  />
                </form>
            </div>
            <div className="text-center mt-4">
                <span className="text-secondary cursor-pointer font-medium text-base" onClick={handleLogout}>
                    Logout
                </span>
            </div>
        </div>
    );
}

export default UserDetailsFormTemplate;

