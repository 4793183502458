import { Route, Routes } from 'react-router-dom';
import StandardListPage from '../../../components/pages/Standard/StandardList.page';
import StandardCreatePage from '../../../components/pages/Standard/StandardCreate.page';
import StandardViewPage from '../../../components/pages/Standard/StandardView.page';
import StandardUpdatePage from '../../../components/pages/Standard/StandardUpdate.page';

const StandardRoutes = () => {
    return (
        <div>
            <Routes>
                {/* <Route path="/list" element={<StandardListPage />} />
                <Route path='/add' element={<StandardCreatePage />} />
                <Route path='/update/:id' element={<StandardUpdatePage />} />
                <Route path='/view/:id' element={<StandardViewPage />} /> */}

                <Route path="" element={<StandardListPage />} />
                <Route path='/add' element={<StandardCreatePage />} />
                <Route path='/:id/update' element={<StandardUpdatePage />} />
                <Route path='/:id' element={<StandardViewPage />} />
            </Routes>
        </div>
    )
}
export default StandardRoutes;